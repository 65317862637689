import { Component, Injector, OnInit, Input } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ} from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session }  from 'src/app/services/session';
import { Language } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-pm-job-client-information',
  templateUrl: './pm-job-client-information.component.html',
  styleUrls: []
})
export class PmJobClientInformationComponent extends BasePage implements OnInit {
    @Input() set job( job ) {
      if (this.pageMode === JMENUM.JMPageMode.VIEW && job) {
        if (!this._job || (job.client && job.client != this._job.client)) {
          this.requestClientInformationSummarySingle(job.client);
        }
      }
      this._job = job;
    }
    @Input() pageMode : JMENUM.JMPageMode;

    clientsOptions: any = [];
    inputFieldSettings : {
      [key: string] : {
        editable?: boolean
        mandatory?: boolean
        failed?: boolean
        validation? : (value)=>boolean
      }
    };
    JMPageMode = JMENUM.JMPageMode;
    _job : JMOBJ.PmJob;
    jobClientNameParsed :string ;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.fieldsControl();
        if (this.pageMode !== JMENUM.JMPageMode.VIEW) {
          this.requestClientInformationSummary();
        }
    }

    private fieldsControl(){
      this.inputFieldSettings = {
        client: {
          editable: true,
          mandatory: true,
        },
        contactNumber: {
          editable: true,
          validation: (val) => {
            let pass = !val || /^\d{0,8}$/.test(val);
            if (!pass) {
              AppDelegate.openSnackBar(JMLanguage.translate('component.client-information.error.msg.contact-number'));
            }
            return pass;
          },
        },
        contactPerson: {
          editable: true,
        },
        email: {
          editable: true,
          validation: (val: string) => {
            let pass = !val || (val.includes('@') && !val.includes(' '));
            if (!pass) {
              AppDelegate.openSnackBar(JMLanguage.translate('component.client-information.error.msg.email'));
            }
            return pass;
          },
        },
      };
    }

    private async requestClientInformationSummary() {
        let request: JM.JMRequestClientsClientSummary = new JM.JMRequestClientsClientSummary();
        // if( JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.WORKCENTRE_ALL) ) {
        //   request.workCentre = undefined;
        // } else {
        //   request.workCentre = Session.userInfo.authorizations.workCenters;
        // }
    
        const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
    
        if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        
        if (response.payload.records && response.payload.records.length > 0) {
            this.clientsOptions = response.payload.records.map(client => { 
                return {
                    value: client.clientShortName, 
                    label: client.clientShortName+ ' - ' + (client.name[Session.selectedLanguage] ? client.name[Session.selectedLanguage] : client.name[Language.EN])
                };
            });
        }else{
          this.clientsOptions = [];
        }
    }

    public clearAll(){
        for (let field in this.inputFieldSettings) {
          if(this.inputFieldSettings[field].editable){
            this._job[field] = null;
            this.inputFieldSettings[field].failed = false;
          }
        }
    }

    public validate() {
      let hasErrorField = false;
      
      for (let field in this.inputFieldSettings) {
        let target = this.inputFieldSettings[field];
        if (target.editable) {

          if(target.mandatory) {
            let isFieldFailed = this._job[field] ? false : true;
            target.failed = isFieldFailed;
            if (isFieldFailed) {
              hasErrorField = true;
              AppDelegate.openSnackBar(JMLanguage.translate('pages.pm-plan.please-fill-in-mandatory-fields'));
              continue;
            }
          }

          if(target.validation){
            let isFieldFailed = !target.validation(this._job[field]);
            target.failed = isFieldFailed;
            if (isFieldFailed) {
              hasErrorField = true;
              continue;
            }
          }

        }
      }
      return !hasErrorField;
    }

    private async requestClientInformationSummarySingle(clientName : string) {
        let request = new JM.JMRequestClientsClientSummary();
        request.clientShortName = [clientName];

        const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);

        if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }

        if (response.payload.records && response.payload.records.length > 0) {
          let content = response.payload.records[0];
          this.jobClientNameParsed =
            content.clientShortName +
            ' - ' +
            (content.name[Session.selectedLanguage] ? content.name[Session.selectedLanguage] : content.name[Language.EN]);
        } else {
          this.jobClientNameParsed = clientName;
        }
    }

    inputNumberWithIndex(event: any, index) {
      event.target.value = event.target.value.replace(/[^\d]/g, '');
      this._job.contactNumber = event.target.value;
    }
}
