<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>
  <app-sidebar [type]="'contract'"></app-sidebar>
  <div id="content">
    <div class="heading-wrap d-block">
      <div class="button-container float-right" *ngIf="hasDummyTranUpdatePermission">
        <div class="add-btn-wrap mb-2 float-right">
          {{ 'pages.contract-dummy.add-dummy-contract' | translate }}
          <button type="button" class="rectangle brand-blue add-contract-btn ml-1" id="add-dummy-contract-btn" (click)="onAddDummyContractBtnClicked()">
            <i class="fas fa-plus"></i>
          </button>
        </div>
    
        <div class="add-btn-wrap">
          {{ 'pages.contract-dummy.add-transitional-contract' | translate }}
          <button type="button" class="rectangle brand-blue add-contract-btn ml-1" id="add-trans-contract-btn" (click)="onAddTransContractBtnClicked()">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    
      <span class="list-remarks d-inline-flex align-middle pr-4 font-size-xs mt-2">
        ^ = {{ 'pages.contractors.dummy-contrat-prefix' | translate }}<br>
        * = {{ 'pages.contractors.transitional-contrat-prefix' | translate }}
      </span>
    <br>
      <div class="checkbox-wrap custom-control custom-checkbox custom-control-inline">
          <input id="showExpiredContracts" class="checkbox custom-control-input" type="checkbox" 
          [(ngModel)]="uiIsShowExpired" (change)="requestDummyMainContract()" />
        <label for="showExpiredContracts" class="custom-control-label d-block">
          {{'pages.contract-dummy.show-expired-contract' | translate}}
        </label>
      </div>
    </div>

    <app-tablex id='trans_dummy_contract_table' #trans_dummy_contract_table [tablexParam]="tablexParam" ></app-tablex>

    <custom-slider-panel #edit_ccs_contract_panel [customClass]="'edit_ccs_contract_panel'">
      <contract-ccs-form-edit #editCcsContractForm [parameters]="editCcsContractParameters">
      </contract-ccs-form-edit>
    </custom-slider-panel>

    <custom-slider-panel #add_transitional_contract_panel [customClass]="'add_trans_contract_panel'">
      <add-transitional-contract-form #addTransitionalContractForm [parameters]="addTransContractParameters"></add-transitional-contract-form>
    </custom-slider-panel>

    <custom-slider-panel #add_dummy_contract_panel [customClass]="'add_dummy_contract_panel'">
      <add-dummy-contract-form #addDummyContractForm [parameters]="addContractParameters"></add-dummy-contract-form>
    </custom-slider-panel>

    
  </div>
</div>