<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings.dropdown.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'default'"></app-sidebar>

  <div id="content" class="row mx-0">
    <app-functional-item-list #dropdown_name_list [parameters]="dropdownNameListParam" [userInput]="dropdownNameListInput" [listItem]="dropdownNameList"
      class="col-md-3 col-12 dropdown-name-list-elem mb-md-0 mb-4" (submitClickListItem)="onClickDropdownName($event)"
    >
    </app-functional-item-list>
    
    <div class="row col-md-9 col-12 mx-0" [class.d-none]="(dropdownAttrInput | keyvalue)?.length <= 0">
      <app-custom-fields-form #dropdown_attr_form class="col-12 dropdown-attr-elem" [parameters]="dropdownAttrParam" [userInput]="dropdownAttrInput" >
      </app-custom-fields-form>

      <app-custom-dropdown-options #dropdown_options class=" col-12 dropdown-options-elem mt-4" [class.d-none]="!dropdownAttrInput._id" [attrParam]="dropdownAttrInput" [parameters]="dropdownOptionsParam" [optionList]="dropdownOptionList" 
      >
      </app-custom-dropdown-options>
    </div>
    
  </div>
</div>