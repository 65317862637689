<div class="row data-row">
  <div class="col-lg-4 label-name">{{'pages.sn.client' | translate}}</div>
  <div class="col-lg-6 text-content" id="sn-history_client_text">{{displaySn.client}}</div>
</div>
<div class="row data-row">
  <div class="col-lg-4 label-name">{{'pages.sn.district' | translate}}</div>
  <div *ngIf="displaySn.district" class="col-lg-6 text-content" id="sn-history_district_text">{{ 'district.' + displaySn.district | translate}}</div>
</div>
<div class="row data-row">
  <div class="col-lg-4 label-name">{{'pages.sn.location' | translate}}</div>
  <div class="col-lg-6 text-content" id="sn-history_location_text" [attr.data-content]="sn.location">{{displayLocation}}</div>
</div>
<div class="row data-row">
  <div class="col-lg-4 label-name">{{'pages.sn.equipment-category' | translate}}</div>
  <div class="col-lg-6 text-content" *ngIf="displaySn.equipmentCategory" id="sn-history_equipment-category_text" [attr.data-content]="displaySn.equipmentCategory">
    {{JM.JMConnector.getEquipmentCategory(displaySn.equipmentCategory).description[lang]}}
  </div>
</div>
<div class="row data-row">
  <div class="col-lg-4 label-name">{{'pages.sn.equipment-type' | translate}}</div>
  <div class="col-lg-6 text-content" *ngIf="displaySn.equipmentType" id="sn-history_equipment-type_text" [attr.data-content]="displaySn.equipmentType">
    {{JM.JMConnector.getEquipmentType(displaySn.equipmentType).description[lang]}}
  </div>
</div>
<div class="row data-row">
  <div class="col-lg-4 label-name">{{'pages.sn.priority' | translate}}</div>
  <div class="col-lg-6 text-content" id="sn-history_priority_text">{{ 'sn.priority.' + displaySn.priority | translate }}</div>
</div>
<div class="row data-row">
  <div class="col-lg-4 label-name">{{'pages.sn.equipment-tag' | translate}}</div>
  <div class="col-lg-6 text-content" id="sn-history_equipment-tag_text">{{displayEquipmentTag}}</div>
</div>