<div class="d-flex justify-content-between mb-2 align-items-center">
  <div class="font-size-xl align-self-end">
    {{ "pages.pm-plan-list.search-criteria" | translate }}
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn icon-btn brand-amber mr-2 img-btn" (click)="onClickReset()" [disabled]="isDisabled">
      <i class="fas fa-undo" ></i>
    </button>
    <button class="btn icon-btn brand-blue img-btn" (click)="onClickSearch()" [disabled]="isSearching || isDisabled">
      <div *ngIf="isSearching" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
      <i class="fas fa-search white font-size-l" ></i>
    </button>
    <button class="btn icon-btn ml-2 d-inline-block d-xl-none navbar-toggler"
      (click)="isCollapse = !isCollapse"
    >
      <i class="fas fa-chevron-up" [class.d-none]="isCollapse"></i>
      <i class="fas fa-chevron-down" [class.d-none]="!isCollapse"></i>
    </button>
  </div>
</div>
<hr class="underline" class="underline" />

<div class="navbar navbar-expand-xl px-0">
  <div class="row justify-content-between mx-0 collapse navbar-collapse align-items-start" id="search-field-wrap" [ngbCollapse]="isCollapse">
    <div class="col-12 col-md-6 col-xl-12  pl-0 pr-0 pr-md-3 pr-xl-0">
      <input class="form-control mb-3" placeholder="{{'components.search-criteria.plan-number' | translate}}"
        [(ngModel)]="planNumber" id="search-plan-number"
      >
      <hr class="d-block d-md-none d-xl-block" />
      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">{{ "components.search-criteria.period-status" | translate }}</span>
        <div class="d-inline-block" *ngIf="periodStatus">
            <label [for]="'search-period-status-'+status.key+'-checkbox'" *ngFor="let status of periodStatus | keyvalue"
              class="checkbox-tag brand-blue" [class.checked]="periodStatus[status.key]"
            >
              {{ "pm.status."+status.key | translate }}
              <input type="checkbox" hidden="true" name="period-status"
                [id]="'search-period-status-'+status.key+'-checkbox'"
                [(ngModel)]="periodStatus[status.key]" 
                (ngModelChange)="ngModelChangePeriodStatus($event)"
              />
          </label>
        </div>
      </div>
      <hr class="d-block d-md-none d-xl-block" />
      
      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">{{ "components.search-criteria.period-schedule" | translate }}</span>
        <div class="d-inline-block" *ngIf="planPeriod">
          <label [for]="'search-plan-period-'+period.key+'-checkbox'" *ngFor="let period of planPeriod | keyvalue"
            class="checkbox-tag brand-blue" [class.checked]="planPeriod[period.key]"
          >
            {{ "pm.plan-period."+period.key | translate }}
            <input type="checkbox" hidden="true" name="plan-period"
            [(ngModel)]="planPeriod[period.key]" [id]="'search-plan-period-'+period.key+'-checkbox'"
          />
          </label>
        </div>
      </div>
      <hr class="d-block d-md-none d-xl-block" />
    </div>

    <div class="col-12 col-md-6 col-xl-12  pr-0 pl-0 pl-md-3 pl-xl-0">
      <div class="d-flex d-md-block d-xl-flex mb-2 align-items-start">
        <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">{{ "components.search-criteria.start-date" | translate }}</span>
        <div class="d-inline-block">
          <div class="mr-2 btn-group btn-group-toggle" ngbRadioGroup name="start-range" [(ngModel)]="startRange">
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="on-or-before" id="search-start-on-or-before">{{ "components.search-criteria.on-or-before" | translate }}
            </label>
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="after" id="search-start-after">{{ "components.search-criteria.after" | translate }}
            </label>
          </div>
          <app-date-input class="mt-1 d-inline-block" #startDateElem
            [input]="ngbStartDate" [inputId]="'startDateInput'" [field]="'startDate'" 
            (onBlur)="onBlurDateInput($event)">
          </app-date-input>
        </div>
      </div>
      <div class="d-flex d-md-block d-xl-flex align-items-start mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">{{ "components.search-criteria.end-date" | translate }}</span>
        <div class="d-inline-block">
          <div class="mr-2 btn-group btn-group-toggle" ngbRadioGroup name="end-range" [(ngModel)]="endRange">
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="on-or-before" id="search-end-on-or-before">{{ "components.search-criteria.on-or-before" | translate }}
            </label>
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="after" id="search-end-after">{{ "components.search-criteria.after" | translate }}
            </label>
          </div>
          <app-date-input class="mt-1 d-inline-block" #endDateElem
            [input]="ngbEndDate" [inputId]="'endDateInput'" [field]="'endDate'" 
            (onBlur)="onBlurDateInput($event)">
          </app-date-input>
        </div>
      </div>

    </div>

  </div>

</div>
