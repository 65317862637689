export const SnReason = [
    { id: "2100", area: "sn", orderType: "cancel", descriptionEn: "The service was cancelled by client.", descriptionZh: "客戶取消此服務" },
    { id: "2110", area: "sn", orderType: "cancel", descriptionEn: "Out of EMSD's service scope", descriptionZh: "非EMSD服務範圍" },
    { id: "2120", area: "sn", orderType: "cancel", descriptionEn: "SN duplicated", descriptionZh: "重複的服務通知" },
    { id: "2230", area: "sn", orderType: "reject", descriptionEn: "The equipment was not handled by the assigned work centre.", descriptionZh: "非此工作中心負責的設備" },
    { id: "2240", area: "sn", orderType: "reject", descriptionEn: "The client was not handled by the assigned work centre.", descriptionZh: "非此工作中心負責的客戶" },
    { id: "2250", area: "sn", orderType: "reject", descriptionEn: "The location of the equipment was not covered by the assigned work centre.", descriptionZh: "非此工作中心負責的場地" },
    { id: "3100", area: "jobCard", orderType: "reject", descriptionEn: "The equipment was not handled by the assigned work centre.", descriptionZh: "非此工作中心負責的設備" },
    { id: "3110", area: "jobCard", orderType: "reject", descriptionEn: "The client was not handled by the assigned work centre.", descriptionZh: "非此工作中心負責的客戶" },
    { id: "3120", area: "jobCard", orderType: "reject", descriptionEn: "The location of the equipment was not covered by the assigned work centre.", descriptionZh: "非此工作中心負責的場地" },
    { id: "3200", area: "jobCard", orderType: "complete", descriptionEn: "Completed with resolution", descriptionZh: "問題已解決" },
    { id: "3210", area: "jobCard", orderType: "complete", descriptionEn: "Completed with no action required", descriptionZh: "已完成，無需行動" },
    { id: "3220", area: "jobCard", orderType: "complete", descriptionEn: "Completed with follow up required", descriptionZh: "需要跟進" },
    { id: "3230", area: "jobCard", orderType: "complete", descriptionEn: "Job Card duplicated", descriptionZh: "重複的工作卡" },
    { id: "3240", area: "jobCard", orderType: "complete", descriptionEn: "Out of EMSD's service scope", descriptionZh: "非EMSD服務範圍" },
    { id: "4100", area: "jobCard", orderType: "createFollowUp", descriptionEn: "Waiting for parts", descriptionZh: "等待配件" },
    { id: "4110", area: "jobCard", orderType: "createFollowUp", descriptionEn: "Change management party", descriptionZh: "由其他人員跟進" },
    { id: "4120", area: "jobCard", orderType: "createFollowUp", descriptionEn: "Re-visit", descriptionZh: "再到場跟進" }
];

export const HaSnCancelReason = [
    { id: 2121, description: { zh: '非機電署資產' , en: 'Asset not entrusted to EMSD.' }},
    { id: 2122, description: { zh: '重覆的醫管局柯打' , en: 'Duplicated Work Order from HA.' }},
    { id: 2123, description: { zh: '設備描述與服務通知不相符' , en: 'Equipment Description Not Match with Work Order.' }},
    { id: 2124, description: { zh: '設備號碼與服務通知不相符' , en: 'Equipment ID Not Match with Work Order.' }},
    { id: 2125, description: { zh: '資料不清晰' , en: 'Inadequate Information to Perform.' }},
    { id: 2126, description: { zh: '無效的服務通知資料' , en: 'Other Data in Work Order is Invalid.' }},
    { id: 2127, description: { zh: '其他原因' , en: 'Other Reasons.' }},
    { id: 2128, description: { zh: '醫管局職員取消要求' , en: 'Request Cancelled from HA Staff.' }},
    { id: 2129, description: { zh: '已重新安排工作' , en: 'Task Rescheduled.' }},
    { id: 2130, description: { zh: '已轉移服務通知' , en: 'Transfer Work Order.' }},
];

export const HaCompleteReason = [
    { id: 3250, jobNature: 'CM', canCreateFollowUpJob: false, description: { zh: '完成功能檢查', en: 'Functional Check accomplished' }},
    { id: 3251, jobNature: 'CM', canCreateFollowUpJob: false, description: { zh: '其他', en: 'Others' }},
    { id: 3252, jobNature: 'CM', canCreateFollowUpJob: false, description: { zh: '建議註銷', en: 'Recommended for Condemnation' }},
    { id: 3253, jobNature: 'CM', canCreateFollowUpJob: false, description: { zh: '完成修理', en: 'Repair completed' }},
    { id: 3254, jobNature: 'CM', canCreateFollowUpJob: false, description: { zh: '重置並恢復正常', en: 'Reset & Resume Normal' }},
    { id: 3255, jobNature: 'CM', canCreateFollowUpJob: true,  description: { zh: '待定 (設備無法使用)', en: 'Pending (Equipment not available)' }},
    { id: 3256, jobNature: 'CM', canCreateFollowUpJob: true,  description: { zh: '待定 (正等待零件)', en: 'Pending (Waiting for Parts)' }},
    { id: 3257, jobNature: 'CM', canCreateFollowUpJob: true,  description: { zh: '待定 (正等待客戶確認)', en: 'Pending (Waiting for User Confirm)' }},
    { id: 3270, jobNature: 'PM', canCreateFollowUpJob: false, description: { zh: '註銷', en: 'Condemn' }},
    { id: 3271, jobNature: 'PM', canCreateFollowUpJob: false, description: { zh: '取消', en: 'Delete' }},
    { id: 3272, jobNature: 'PM', canCreateFollowUpJob: false, description: { zh: '例行檢查完成', en: 'Preventive Maintenance Done' }},
    { id: 3273, jobNature: 'PM', canCreateFollowUpJob: false, description: { zh: '不適用', en: 'Not Available' }},
    { id: 3274, jobNature: 'PM', canCreateFollowUpJob: false, description: { zh: '其他', en: 'Others' }},
    { id: 3275, jobNature: 'PM', canCreateFollowUpJob: false, description: { zh: '設備不存在', en: 'Not Found' }},
    { id: 3276, jobNature: 'PM', canCreateFollowUpJob: true,  description: { zh: '待定 (設備無法使用)', en: 'Pending (Equipment not available)' }},
    { id: 3277, jobNature: 'PM', canCreateFollowUpJob: true,  description: { zh: '待定 (正等待零件)', en: 'Pending (Waiting for Parts)' }},
    { id: 3278, jobNature: 'PM', canCreateFollowUpJob: true,  description: { zh: '待定 (正等待客戶確認)', en: 'Pending (Waiting for User Confirm)' }},
];