import { Component, OnInit } from '@angular/core';
import { JMCONSTANT, JMENUM, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session } from 'src/app/services/session';

@Component({
  selector: 'app-costing-list',
  templateUrl: './costing-list.component.html',
  styleUrls: ['./costing-list.component.scss']
})
export class CostingListComponent implements OnInit {

  sectionItems: any[] = [];

  constructor() { }

  ngOnInit() {
    this.initSectionItems();
  }

  initSectionItems() {
    const costingItemsArr = this.getCostingItemsArray();

    this.sectionItems = [
      {
        sectionName: JMLanguage.translate('side-bar.costing'),
        theme: 'bg-blue',
        items: costingItemsArr
      }
    ];
  }

  getCostingItemsArray() {
    const jobCardTecoFeature = Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.JOB_CARD_TECO);
    const arr = new Array();

    if (jobCardTecoFeature && jobCardTecoFeature.enabled) {
      arr.push({
        id: 'teco-menu-item',
        name: JMLanguage.translate('side-bar.teco'),
        icon: '',
        iconPath: '/assets/svg/teco_white.svg',
        routing: '/teco',
        permission: JMUTILITY.hasPermissions(Session.userInfo, [
          JMENUM.Permission.JOBCARD_TECO,
          JMENUM.Permission.JOBCARD_TECO_ALL], false)
      });
      arr.push({
        id: 'un-teco-menu-item',
        name: JMLanguage.translate('side-bar.un-teco'),
        icon: '',
        iconPath: '/assets/svg/unteco_white.svg',
        routing: '/un-teco',
        permission: JMUTILITY.hasPermissions(Session.userInfo, [
          JMENUM.Permission.JOBCARD_ZS01_UNTECO,
          JMENUM.Permission.JOBCARD_ZS02_UNTECO,
          JMENUM.Permission.JOBCARD_ZS04_UNTECO,
          JMENUM.Permission.JOBCARD_UNTECO_ALL], false)
      });
    }

    return arr;
  }

}
