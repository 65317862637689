<div class="contract-form-wrap">

  <div class="title-wrap mx-0 mb-4">
    <h3 class="d-inline">{{ "component.ccs-contract-form.title.edit" | translate }}</h3>
    <div class="button-wrap text-right d-inline-block float-right"
      *ngIf="hasContractUpdatePermission">
      <app-submit-button [id]="'save_button'" [text]="'global.save'" [buttonClass]="' standard brand-amber mr-0'"
        [iconClass]="'fa-save'" [isDisabled]="!uiEnableSaveButton" [isLoading]="uiLoadingSaveButton"
        (onClick)="onSaveButtonClicked($event)"></app-submit-button>
      <!-- <ng-container>
        <app-submit-button 
          [id]="'dummy_contract_delete_button'"
          [text]="'global.delete'"
          [buttonClass]="'d-block standard brand-red mr-0 my-1'"
          [iconClass]="'fa-times'"
          [isDisabled]="!uiEnableDeleteButton"
          [isLoading]="uiLoadingDeleteButton"
          (onClick)="onDeleteButtonClicked($event)"
        ></app-submit-button>
      </ng-container> -->
    </div>
    <div class="alert-font font-size-xs d-inline-block w-70" *ngIf="uiErrorMessage.length > 0">
      <div *ngFor="let msg of uiErrorMessage;" class="other-error-alert mb-2">
        {{ msg }}
      </div>
    </div>
  </div>

  <form id="add-transitional-contract-form">
    <div class="bold mt-3">
      {{ 'add-transitional-contract-form.contract-number' | translate }}
    </div>
    <span class="alert-font font-size-xs" *ngIf="uiHaveSwitchOver">
      {{ 'pages.contract-dummy.error.cant-be-edited' | translate }}
    </span>

    <input id="id_new_contract_number" name="contractNumber" type="text" class="form-control" [disabled]="true"
      [(ngModel)]="uiContractNumber" (keyup)="onContractNumberKeyup($event)">

    <div class="bold mt-3">
      {{ "add-transitional-contract-form.contractor-number" | translate }}
    </div>
    <ng-select id="id_new_contractor_number" name="contractorNumber" class="align-middle"
      [(ngModel)]="uiContractorNumber" [items]="uiAllContractorNumberArray" [multiple]="false" [closeOnSelect]="true"
      [searchable]="true" [hideSelected]="true" [addTag]="true" [disabled]="!uiEnableContractorNumber || !hasContractUpdatePermission"></ng-select>

    <div class="bold mt-3">
      {{ "add-transitional-contract-form.contractor-name" | translate }}
    </div>
    <ng-select id="id_new_contractor_name" name="contractorName" class="align-middle" [(ngModel)]="uiContractorName"
      [items]="uiAllContractorNameArray" [multiple]="false" [closeOnSelect]="true" [searchable]="true"
      [hideSelected]="true" [addTag]="true" [disabled]="!uiEnableContractorName || !hasContractUpdatePermission"></ng-select>

    <div class="bold mt-3">
      <span class="mandatory">*</span>{{ "component.ccs-contract-form.contract-type" | translate }}
    </div>
    <ng-select id="ccs_contract_type" name="contractType" class="align-middle" bindLabel="name" bindValue="id"
      [(ngModel)]="uiContractType" [items]="uiAllContractTypeArray" [multiple]="false" [closeOnSelect]="true"
      [searchable]="false" [hideSelected]="false" [addTag]="false" [clearable]="false" [disabled]="!hasContractUpdatePermission"
      (change)="onContractTypeChanged($event)"></ng-select>
    <div class="bold mt-3">
      {{ "pages.contract-dummy.dummy-lift-contract" | translate }}
    </div>
    <div>
      <div class="mr-2 btn-group btn-group-toggle" id="lift-trapped" ngbRadioGroup name="liftTrapped"
        [(ngModel)]="uiLiftTrapped" [disabled]="!hasContractUpdatePermission">
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="true" id="lift-trapped-yes">
          {{ "global.yes" | translate }}
        </label>
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="false" id="lift-trapped-no">
          {{ "global.no" | translate }}
        </label>
      </div>
    </div>
    <div class="bold mt-3">
      {{ "pages.contract-dummy.dummy-find-equipment-by" | translate }}
    </div>
    <ng-select id="find_equipment_by" name="findEquipmentBy" class="align-middle" bindLabel="name" bindValue="id"
      [(ngModel)]="uiFindEquipmentBy" [items]="uiAllFindEquipmentByOptions" [multiple]="false" [closeOnSelect]="true"
      [searchable]="false" [hideSelected]="false" [addTag]="false" [clearable]="false" [disabled]="!hasContractUpdatePermission"></ng-select>
  </form>

  <p class="remarks mt-5">
    <span class="mandatory">*</span>{{ "add-transitional-contract-form.mandatory" | translate}}
  </p>
</div>