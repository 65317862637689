<ng-container>
  <div class="modal-header">
    <h5 class="modal-title">{{ modalTitle }}</h5>
    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss('Clicked cross')">
          <span aria-hidden="true">&times;</span>
        </button> -->
  </div>
  <div class="modal-body">
    <p>{{ modalDescription }}</p>
    <div *ngFor="let radio of radios; let i = index" class="custom-radio">
      <input
        type="radio"
        id="modalRadio{{ i }}"
        class="custom-control-input"
        name="modalGroupRadio"
        [checked]="i === 0"
        [value]="radio.value"
        (change)="onChangeRadio(i)"
      />
      <label class="custom-control-label" for="modalRadio{{ i }}">{{ radio.description }}</label>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="submit()" class="brand-amber">{{ closeBtnName }}</button>
    <button (click)="dismiss('Clicked close button')" class="brand-blue">
      {{ dismissBtnName }}
    </button>
  </div>
</ng-container>
