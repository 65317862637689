import { Component, Input, OnInit } from '@angular/core';
import { SnBatchCompleteStage } from '../sn-batch-complete.model';
import { Session } from 'src/app/services/session';
import { JMENUM, JMUTILITY } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'app-batch-complete-stage-header',
  templateUrl: './batch-complete-stage-header.component.html',
  styleUrls: ['./batch-complete-stage-header.component.scss']
})
export class BatchCompleteStageHeaderComponent implements OnInit {

  @Input() stage: SnBatchCompleteStage = SnBatchCompleteStage.DOWNLOAD_TEMPLATE;
  uploadHistoryAccess: {
    permission: boolean,
    routing: string
  } = {
      permission: false,
      routing: '/sn/batch-complete/upload-history'
    }
    
  constructor() { }

  ngOnInit() {
    this.checkUploadHistoryAccessPermission();
  }

  //==============================
  // get property
  get isSelectedDownloadTemplate(): boolean {
    return this.stage === SnBatchCompleteStage.DOWNLOAD_TEMPLATE;
  }
  get isSelectedUpload(): boolean {
    const stageList = [
      SnBatchCompleteStage.UPLOAD,
      SnBatchCompleteStage.VERIFICATION_SUCCESSFUL,
      SnBatchCompleteStage.VERIFICATION_FAILED,
    ]
    return stageList.includes(this.stage);
  }
  get isSelectedResult(): boolean {
    return this.stage === SnBatchCompleteStage.SUBMITTED;
  }

  checkUploadHistoryAccessPermission() {
    if (!JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.JOBCARD_COMPLETE, JMENUM.Permission.JOBCARD_COMPLETE_ALL], false)) {
      return
    }
    this.uploadHistoryAccess = {
      ...this.uploadHistoryAccess,
      permission: true
    }
  }

}
