import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {
  TablexColumnFilterOption,
  TablexColumnFilterType,
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from '@enum/tablexColumnType';
import { AppDelegate } from 'src/app/AppDelegate';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import * as moment from 'moment';
import { Session }  from 'src/app/services/session';
import { Constants } from 'src/constants';
import { PmJobListAdvSearchComponent } from '../../components/pm-job-list-adv-search/pm-job-list-adv-search.component';
import { pad, formatDate,stringToMoment ,ngbdatestructToDate,stringToNgbDate} from 'src/lib/presenter/Formatter';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pm-job-list',
  templateUrl: './pm-job-list.component.html',
  styleUrls: ['./pm-job-list.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class PmJobListComponent implements OnInit {

  @ViewChild(PmJobListAdvSearchComponent, { static: true }) pmJobListAdvSearchComponent: PmJobListAdvSearchComponent;

  tableXParam: any = {};
  tableXHeader;
  tableXContent: JMOBJ.PmJob[];
  showCreationTimeErrorMsg:boolean = false;
  constructor() {}

  ngOnInit() {
    const hasPermission = JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.PMPLAN_VIEW], false);  
    if (!hasPermission) {
      AppDelegate.navigate(['/']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    this.tableXInit();
  }

  ngAfterViewInit(){
    this.loadColumnSettingsFromLocal();
  }

  tableXInit() {
    this.tableXHeader = [
      {
        id: 'objId',
        name: 'pages.job-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none',
      },
      {
        id: 'pmJobNumber',
        name: 'pages.pm-job-list.table-column.pm-job-no',
        enableSort: true,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'pmPlan.pmPlanNumber',
        name: 'pages.pm-job-list.table-column.pm-plan-no',
        enableSort: true,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'taskDescription',
        name: 'pages.pm-job-list.table-column.description',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'period.periodStartDate',
        name: 'pages.pm-job-list.table-column.start-date',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'period.periodEndDate',
        name: 'pages.pm-job-list.table-column.end-date',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'status',
        name: 'pages.pm-job-list.table-column.status',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'scheduleType',
        name: 'pages.pm-job-list.table-column.schedule-type',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'frequency',
        name: 'pages.pm-job-list.table-column.frequency',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'handlingTeam',
        name: 'pages.pm-job-list.table-column.handling-team',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'updatedAt',
        name: 'pages.pm-job-list.table-column.updated-date',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'createdBy',
        name: 'pages.pm-job-list.table-column.created-by',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      // {
      //   id: 'remarks',
      //   name: 'pages.pm-job-list.table-column.remarks',
      //   enableSort: false,
      //   type: TablexColumnType.Text,
      //   horizontalAlign: TablexColumnHorizontalAlign.Center,
      //   verticalAlign: TablexColumnVerticalAlign.Bottom,
      //   class: 'col justify-content-center align-items-center d-flex',
      // },
    ];

    this.tableXParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      enableSort: true,
      minifyButton: true,
      sortOrder: -1,
      sortBy: 'updatedAt',
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      selectedColId: [
        'pmJobNumber',
        'pmPlan.pmPlanNumber',
        'taskDescription',
        'period.periodStartDate',
        'period.periodEndDate',
        'status',
        'scheduleType',
        'frequency',
        'handlingTeam',
        'updatedAt',
        // 'createdBy',
      ],
      fullColNameList: this.tableXHeader.map((col) => {
        return { id: col.id, name: col.name };
      }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      // enableStickyHeader: false,
      tableRow: 'row',
      filter: {},
    };
  }

  tableXRender() {
    this.tableXParam.headers = this.tableXHeader.filter((col) => {
      return this.tableXParam.selectedColId.includes(col.id);
    });
    this.tableXParam.content = this.tableXContent.map((element, index)=>{
      let {
        _id,
        pmJobNumber,
        pmPlan = {},
        jobDescription,
        period = {},
        status,
        team,
        updatedAt,
        createdBy
      } = element;
      
      let fullRow =  [
        _id,
        pmJobNumber ? {url: `/pm/job/view/${pmJobNumber}`, value: pmJobNumber} : '',
        pmPlan['pmPlanNumber'] ?  {url: `/pm/standard-plan/view/${pmPlan['pmPlanNumber']}`, value: pmPlan['pmPlanNumber']} : '',
        jobDescription,
        this.parseDateToString(period['periodStartDate']),
        this.parseDateToString(period['periodEndDate']),
        status ? JMLanguage.translate(`pm.status.${status}`) : '',
        pmPlan['scheduleType'] ? JMLanguage.translate(`pm.schedule-type.${pmPlan['scheduleType']}`) : '',
        pmPlan['frequency'] ? JMLanguage.translate(`pm.frequency.${pmPlan['frequency']}`) : '',
        team !== null && team !== undefined? team['name'] : JMLanguage.translate(`global.na`),
        this.parseDateToString(updatedAt, true),
        createdBy
      ];

      let rowFiltered = []
      fullRow.forEach((value, i) => {
        if (this.tableXParam.selectedColId.includes(this.tableXHeader[i].id)) {
          rowFiltered.push(fullRow[i]);
        }
      });

      return rowFiltered;
    })
  }

  async requestJobList(pageNumber?: number, pageSize?: number) {
    let request = new JM.JMRequestGetPmJobList();

    request = this.applyAdvSearchFilters(request);

    const fromDate = request.fromCreateTime;
    const toDate = request.toCreateTime;
    if(toDate!=null && fromDate!=null){
      if (ngbdatestructToDate(stringToNgbDate(toDate)).getTime() < ngbdatestructToDate(stringToNgbDate(fromDate)).getTime()) {
        this.showCreationTimeErrorMsg = true;
        return;
      }
    }

    if (this.tableXParam.sortBy) {
      request.sortBy = this.tableXParam.sortBy;
      request.sortOrder = this.tableXParam.sortOrder;
    }
    request.pageNumber = typeof pageNumber === 'number' ? pageNumber : this.tableXParam.currentPage;
    request.pageSize = typeof pageSize === 'number' ? pageSize : this.tableXParam.currentPageSize;
    this.tableXParam.isLoadingTable = true;
    const response: JM.JMResponseGetPmJobList = await AppDelegate.sendJMRequest(request);
    this.tableXParam.isLoadingTable = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    if (response.payload.records && response.payload.records.length > 0) {
      this.tableXContent = response.payload.records;
      this.tableXParam.pageCount = Math.ceil(response.payload.totalCount / this.tableXParam.currentPageSize);
      this.tableXParam.currentPage = request.pageNumber;
    }else{
      this.tableXContent = [];
      this.tableXParam.pageCount = 0;
      this.tableXParam.currentPage = 0;
    }

    this.tableXRender();
  }

  onAdvSearchComponentTriggerSearchAction(){
     this.requestJobList(1);
  }

  applyAdvSearchFilters(request:JM.JMRequestGetPmJobList) : JM.JMRequestGetPmJobList{
    let advSearchFilters = this.pmJobListAdvSearchComponent.searchSelections;

    if (!request.filter) {
      request.filter = {};
    }

    if(advSearchFilters.contractNumber && advSearchFilters.contractNumber.length > 0){
        request.pmPlanContractNumbers = advSearchFilters.contractNumber
    }
    if(advSearchFilters.pmPlanNumber){
        request.pmPlanNumbers = [advSearchFilters.pmPlanNumber]
    }
    if(advSearchFilters.pmPeriod){
        request.periodIds = [advSearchFilters.pmPeriod]
    }
    if(advSearchFilters.equipmentNumber){
       request.equipmentNumberList = [advSearchFilters.equipmentNumber]
    }
    
    if (advSearchFilters.pmJobNumber) {
      request.filter.pmJobNumber = advSearchFilters.pmJobNumber;
    }
    if (advSearchFilters.jobDescription) {
      request.filter.jobDescription = advSearchFilters.jobDescription;
    }
    if (advSearchFilters.createdBy) {
      request.filter.createdBy = advSearchFilters.createdBy;
    }
    if (advSearchFilters.handlingTeam && advSearchFilters.handlingTeam.length > 0) {
      request.teamId = advSearchFilters.handlingTeam;
    }

    let planStatus = Object.keys(advSearchFilters.planStatus).filter((status) => advSearchFilters.planStatus[status]);
    if (planStatus.length > 0) {
      request.status = planStatus;
    }

    if (advSearchFilters.ngbEndDate) {
      if (advSearchFilters.endRange == 'on-or-before') {
        request.toPeriodEndDate = this.parseDateToRequestFormat(advSearchFilters.ngbEndDate);
      } else if (advSearchFilters.endRange == 'after') {
        request.fromPeriodEndDate = this.parseDateToRequestFormat(advSearchFilters.ngbEndDate);
      }
    }

    if (advSearchFilters.ngbStartDate) {
      if (advSearchFilters.startRange == 'on-or-before') {
        request.toPeriodStartDate = this.parseDateToRequestFormat(advSearchFilters.ngbStartDate);
      } else if (advSearchFilters.startRange == 'after') {
        request.fromPeriodStartDate = this.parseDateToRequestFormat(advSearchFilters.ngbStartDate);
      }
    }
    if (advSearchFilters.createTimeFrom) {
      request.fromCreateTime = this.parseDateCreateTimeFromToRequestFormat(advSearchFilters.createTimeFrom)!=null?this.parseDateCreateTimeFromToRequestFormat(advSearchFilters.createTimeFrom): undefined;
    }
    if (advSearchFilters.createTimeTo) {
      request.toCreateTime = this.parseDateCreateTimeToToRequestFormat(advSearchFilters.createTimeTo)!=null?this.parseDateCreateTimeToToRequestFormat(advSearchFilters.createTimeTo): undefined;

    }
    return request;
  }
  private parseDateCreateTimeFromToRequestFormat = (dateData: string): string => {
    const format = "YYYY-MM-DD"
    try {
      let formattedDate= moment(dateData).format(format);
      let date = new Date(formattedDate + "T00:00:00.000Z").toISOString();
      return date;
    } catch (e) {
      return null;
    }
  }
  private parseDateCreateTimeToToRequestFormat = (dateData: string): string => {
    const format = "YYYY-MM-DD"
    try {
      let formattedDate= moment(dateData).format(format);
      let date = new Date(formattedDate+ "T23:59:59.999Z").toISOString();
      return date;
    } catch (e) {
      return null;
    }
  }
  onPageSizeClicked = (pageSize: number) => {
    this.requestJobList(1, pageSize);
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.requestJobList(pageIndex);
  };

  onColFiltered = (selectedColId) => {
    this.tableXParam.selectedColId = selectedColId;
    this.tableXRender();
    this.saveColumnSettingsToLocal();
  };

  onSortOrderChanged = (sortBy, sortOrder) =>{
    if(!sortBy){
      sortBy = "updatedAt";
    }
    this.tableXParam.sortBy = sortBy;
    this.tableXParam.sortOrder = sortOrder;
    this.requestJobList(1);
  }

  parseDateToString( date, time:boolean = false ) : string{
    try {
      let momentObj = moment(date);
      if(time){
        return momentObj.format(Constants.DATETIME_FORMAT_2);
      } else {
        return momentObj.format(Constants.DATE_FORMAT);
      }
      
    } catch (e) {
      return null;
    }
  }

  parseDateToRequestFormat = ( dateData : any ) : string => {
    try {
      let date = new Date(dateData.year, dateData.month - 1, dateData.day);
      return formatDate( date.toString() , 'YYYYMMDD');
    } catch ( e ) {
      return null;
    }
  }

  private saveColumnSettingsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.tableXParam.selectedColId));
      Session.setPmJobListSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }
  private loadColumnSettingsFromLocal = () : void => {
    try {
      let cache = Session.pmJobListSettingsColumns;
      if (cache) {
        this.tableXParam.selectedColId = JSON.parse(JSON.stringify(cache))
      }
    } catch (e) {
      console.warn(e);
    }
  }
    
}
