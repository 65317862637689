import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TablexColumnType } from 'src/app/entity/enum/tablexColumnType';

@Component({
  selector: 'app-modal-office-hours',
  templateUrl: './modal-office-hours.component.html',
  styleUrls: ['./modal-office-hours.component.scss']
})
export class ModalOfficeHoursComponent implements OnInit {

  @Input() public inhouseTeam   : any;       // TODO: use JMOjb.Team
  @Input() public contractorTeam: any;       // TODO: use JMOjb.Team
  @Input() public onCloseClicked: Function;

  timeIndex = {
    start: 1,
    end  : 2
  }

  inhouseOfficeHoursParam: {} = {};
  contractorOfficeHoursParam: {} = {};


  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.inhouseOfficeHoursParam = {};
    if (this.inhouseTeam && this.inhouseTeam.operationPeriod) {
      this.initInhouseTeamOfficeHoursTable(this.inhouseTeam.operationPeriod);
    }
    if (this.contractorTeam && this.contractorTeam.operationPeriod) {
      this.initContractorTeamOfficeHoursTable(this.contractorTeam.operationPeriod);
    }
  }

  private initTableHeader(): any {
    return [
      { name: 'global.day-of-week', type: TablexColumnType.Text, class: 'w-40', },
      { name: 'global.start', type: TablexColumnType.Input, class: 'w-30', },
      { name: 'global.end', type: TablexColumnType.Input, class: 'w-30', },
    ];
  }

  private initTableContent(): any {
    return [
      [
        'global.monday',
        { id: '', dayOfWeek: 1, name: 'global.monday', timeType: 'global.start', value: '' },
        { id: '', dayOfWeek: 1, name: 'global.monday', timeType: 'global.end', value: '' }
      ],
      [
        'global.tuesday',
        { id: '', dayOfWeek: 2, name: 'global.tuesday', timeType: 'global.start', value: '' },
        { id: '', dayOfWeek: 2, name: 'global.tuesday', timeType: 'global.end', value: '' }
      ],
      [
        'global.wednesday',
        { id: '', dayOfWeek: 3, name: 'global.wednesday', timeType: 'global.start', value: '' },
        { id: '', dayOfWeek: 3, name: 'global.wednesday', timeType: 'global.end', value: '' }
      ],
      [
        'global.thursday',
        { id: '', dayOfWeek: 4, name: 'global.thursday', timeType: 'global.start', value: '' },
        { id: '', dayOfWeek: 4, name: 'global.thursday', timeType: 'global.end', value: '' }
      ],
      [
        'global.friday',
        { id: '', dayOfWeek: 5, name: 'global.friday', timeType: 'global.start', value: '' },
        { id: '', dayOfWeek: 5, name: 'global.friday', timeType: 'global.end', value: '' }
      ],
      [
        'global.saturday',
        { id: '', dayOfWeek: 6, name: 'global.saturday', timeType: 'global.start', value: '' },
        { id: '', dayOfWeek: 6, name: 'global.saturday', timeType: 'global.end', value: '' }
      ],
      [
        'global.sunday',
        { id: '', dayOfWeek: 0, name: 'global.sunday', timeType: 'global.start', value: '' },
        { id: '', dayOfWeek: 0, name: 'global.sunday', timeType: 'global.end', value: '' }
      ],
      [
        'global.public-holidays',
        { id: '', dayOfWeek: 100, name: 'global.public-holidays', timeType: 'global.start', value: '' },
        { id: '', dayOfWeek: 100, name: 'global.public-holidays', timeType: 'global.end', value: '' }
      ]
    ];
  }

  initContractorTeamOfficeHoursTable(operationPeriod: any) {
    this.contractorOfficeHoursParam = {
      componentId: 'modal-office_contractor-office-hours',
      isLoadingTable: false,
      isEditMode: false,
      hasEditionPermission: false,
      onEditButtonClicked: () => {},
      headers: this.initTableHeader(),
      content: this.initTableContent()
    };

    if (operationPeriod && operationPeriod.operationPeriod && operationPeriod.operationPeriod.length > 0) {
      operationPeriod.operationPeriod.forEach(element => {
        let contentIndex = this.getContentIndex(element.startDayOfWeek);
        this.contractorOfficeHoursParam['content'][contentIndex][this.timeIndex.start]['value'] = element.startTime ? element.startTime : '';
        this.contractorOfficeHoursParam['content'][contentIndex][this.timeIndex.end]['value'] = element.endTime ? element.endTime : '';
      });
    }

    if (operationPeriod && operationPeriod.publicHoliday && operationPeriod.publicHoliday.length > 0) {
      let element = operationPeriod.publicHoliday[0];
      // index 7 == public holiday
      this.contractorOfficeHoursParam['content'][7][this.timeIndex.start]['value'] = element.startTime ? element.startTime : '';
      this.contractorOfficeHoursParam['content'][7][this.timeIndex.end]['value'] = element.endTime ? element.endTime : '';
    }
  }

  initInhouseTeamOfficeHoursTable(operationPeriod: any) {
    this.inhouseOfficeHoursParam = {
      componentId: 'modal-office_inhouse-office-hours',
      isLoadingTable: false,
      isEditMode: false,
      hasEditionPermission: false,
      onEditButtonClicked: () => {},
      headers: this.initTableHeader(),
      content: this.initTableContent()
    };

    if (operationPeriod && operationPeriod.operationPeriod && operationPeriod.operationPeriod.length > 0) {
      operationPeriod.operationPeriod.forEach(element => {
        let contentIndex = this.getContentIndex(element.startDayOfWeek);
        this.inhouseOfficeHoursParam['content'][contentIndex][this.timeIndex.start]['value'] = element.startTime ? element.startTime : '';
        this.inhouseOfficeHoursParam['content'][contentIndex][this.timeIndex.end]['value'] = element.endTime ? element.endTime : '';
      });
    }

    if (operationPeriod && operationPeriod.publicHoliday && operationPeriod.publicHoliday.length > 0) {
      let element = operationPeriod.publicHoliday[0];
      // index 7 == public holiday
      this.inhouseOfficeHoursParam['content'][7][this.timeIndex.start]['value'] = element.startTime ? element.startTime : '';
      this.inhouseOfficeHoursParam['content'][7][this.timeIndex.end]['value'] = element.endTime ? element.endTime : '';
    }
  }

  private getContentIndex(startDayOfWeek: number) {
    let contentIndex = 0;
    switch (startDayOfWeek) {
      case 0:
        contentIndex = 6;
        break;
      case 1:
        contentIndex = 0;
        break;
      case 2:
        contentIndex = 1;
        break;
      case 3:
        contentIndex = 2;
        break;
      case 4:
        contentIndex = 3;
        break;
      case 5:
        contentIndex = 4;
        break;
      case 6:
        contentIndex = 5;
        break;
      default:
        contentIndex = 0;
    }
    return contentIndex;
  }

  onClosed() {
    if (this.onCloseClicked) {
      this.onCloseClicked();
    } else {
      this.activeModal.dismiss();
    }
  }

}
