import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Attachment } from 'src/app/entity/data-model/attachment';
import { saveAs } from "file-saver";
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMCONSTANT, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AuthorizationService } from '@services/authorization.service';
import { AttachmentMimeType } from '@enum/attachment-file-type';
import { ActivatedRoute } from '@angular/router';
import { fileType, blobToBinary, splitPdfByPage, getPdfTotalPage } from '@services/utility';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
import { isEnabledFeature } from 'src/app/services/utility'; 

@Component({
  selector: 'app-sn-attachment',
  templateUrl: './sn-attachment.component.html',
  styleUrls: ['./sn-attachment.component.scss']
})
export class SnAttachmentComponent extends BasePage implements OnInit {
  @ViewChild('attachment_preview', { static: false }) attachmentPreview;
  @ViewChild('upload_previewer', { static: false }) uploadPreviewer;
  @ViewChild('ha_service_report_upload_previewer', { static: false }) haServiceReportUploadPreviewer;
  @ViewChild('attachmentTablex', { static: false }) attachmentTablex;
  @ViewChild('haServiceTablex', { static: false }) haServiceTablex;

  protected authorizationService: AuthorizationService;
  protected route: ActivatedRoute;

  snNumber: string;
  sn: JMOBJ.ServiceNotification;

  file: any;
  fileList: any = {};
  fileDetails: any = {};

  attachment: Attachment;
  selectedAttachment: any;
  attachmentList: any = [];

  uploadPreviewParam: any = {};
  haServiceReportUploadPreviewParam: any = {};
  
  isHSDServiceIntegrationEnabled:boolean = false;
  navbarTitle: string;
  breadcrumbs: any = [];
  tablexParam: any = {};
  haTablexParam: any = {};

  isReadyForAdd: boolean = false; // TEMP, wait for SN add attachment api
  isReadyForDelete: boolean = false;  // TEMP, wait for SN delete attachment api
  isDisabledAddAttachment: boolean = false;
  isPreviewerLoading: boolean = false;
  isSaving: boolean = false;
  hasViewPermission: boolean = false;
  hasEditPermission: boolean = false;
  hasHaServiceReportUploadPermission: boolean = false;
  hasHaServiceReportResendPermission: boolean = false;
  hasHaServiceReportDeletePermission: boolean = false;
  isShowUploadPreview: boolean = false;
  hasNotificationEmailHostories: boolean = false;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.checkHSDServiceIntegrationEnabled()
    this.checkViewPermission(JMENUM.Permission.SN_VIEW);
    this.snNumber = this.route.snapshot.paramMap.get('snNumber');
    this.breadcrumbs = [
      { id: 'breadcrumbs-sn-number', name: this.snNumber, route: '/sn/view/' + this.snNumber },
      { id: 'breadcrumbs-attachment', name: "Attachment", route: null, currentPage: true }
    ];
    this.requestSn();
    this.initTable();

  }

  checkHSDServiceIntegrationEnabled(){
    this.isHSDServiceIntegrationEnabled = isEnabledFeature(Session, JMCONSTANT.JMFeature.JM_HSD_SERVICE_INTEGRATION)
  }

  initPermission() {
    // check has user got SN_UPDATE & SN_UPDATE_ALL
    let hasSnUpdate = this.authorizationService.hasPermission(JMENUM.Permission.SN_UPDATE)
    let hasAdmin = this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)
    let haServiceReportUpload = this.authorizationService.hasPermission(JMENUM.Permission.HA_SERVICE_REPORT_UPLOAD)
    let haServiceReportResend = this.authorizationService.hasPermission(JMENUM.Permission.HA_SERVICE_REPORT_RESEND)
    let haServiceReportDelete = this.authorizationService.hasPermission(JMENUM.Permission.HA_SERVICE_REPORT_DELETE)

    let hasTeamWorkCentre = (this.sn && this.sn.team && this.sn.team.workCentre &&
                            this.authorizationService.hasAuthorizationForWorkCenter(this.sn.team.workCentre))
    // check has user got the right Workcenter rights
    let hasWorkCentre = (this.sn && this.sn.workCentre && // workCenter is not null
                         this.authorizationService.hasAuthorizationForWorkCenter(this.sn.workCentre)) 
    
    // check is user a team member 
    let isTeamMember = (this.sn.team &&
                        this.sn.team.workCentre &&
                        this.authorizationService.hasAuthorizationForWorkCenter(this.sn.team.workCentre.toString()))

    let isSubmitter = (this.sn.submittedBy &&
                        this.sn.submittedBy == Session.userInfo.name)
                        
                        
    let isAuthor = (this.sn.createdBy &&
                        this.sn.createdBy == Session.userInfo.name)

    if(haServiceReportUpload){
      this.hasHaServiceReportUploadPermission = true;
      this.hasViewPermission = true;
      this.hasEditPermission = true;
    }
    if(haServiceReportResend){
      this.hasHaServiceReportResendPermission = true;
      this.isReadyForAdd = true;
    }
    if(haServiceReportDelete){
      this.hasHaServiceReportDeletePermission = true;
      this.isReadyForDelete = true;
    }

   
    // view only 
    if ((isTeamMember || isSubmitter || isAuthor) && (hasWorkCentre || hasTeamWorkCentre) && hasSnUpdate) {
      this.hasViewPermission = true;
      this.hasEditPermission = true;
    }
  }

  // ----------- API ----------- //
  private async requestSn() {
    this.tablexParam.isLoadingTable = true;
    this.haTablexParam.isLoadingTable = true;
    let request: JM.JMRequestSnGetSn = new JM.JMRequestSnGetSn();
    request.snNumber = this.snNumber;

    const response : JM.JMResponseSnGetSn = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;
    this.haTablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload || response.error) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload) {
      this.updateSn(response.payload);
      this.initPermission();
      this.initUploadPreviewer();
      this.initHaServiceReportUploadPreviewer();
      this.renderTable();
      this.requestNotificationEmailBySnNumber().then(() => this.renderHaServiceReportTable())
    }
  }
  

//---------- Get HA Service Report submission History ---------- 
  private async requestNotificationEmailBySnNumber(){
    let request:JM.JMRequestNotificationEmailBySnNumber = new JM.JMRequestNotificationEmailBySnNumber
    request.snNumber = this.snNumber

    const response : JM.JMResponseNotificationEmailBySnNumber = await AppDelegate.sendJMRequest(request)
    if (!response || response.code !== 200 || !response.payload || response.error){
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload){
      if(!response.payload ||response.payload.length <= 0 ){
        this.hasNotificationEmailHostories = false
      } else {
        this.hasNotificationEmailHostories = true
      }
      return response.payload
    }
  }

//---------- Get HA Service Report submission History ---------- 



  //---------- HA Service Report submission ---------- 
  private async requestSnResendHaServiceReportSubmission(attachment){
    // filter HA Service Report => select attachmentId 
    let selectedHaServiceReport = this.filterAttachment(this.attachmentList, [JMENUM.SnAttachmentType.HA_SERVICER_REPORT])
      .find(a => a.attachmentId === attachment.attachmentId);
      
    if(selectedHaServiceReport){
      let request: JM.JMRequestSnResendHaServiceReportSubmission = new JM.JMRequestSnResendHaServiceReportSubmission();
      request.attachmentId = selectedHaServiceReport.attachmentId
      request.snNumber = this.snNumber


      const response : JM.JMResponseSnResendHaServiceReportSubmission = await AppDelegate.sendJMRequest(request)
      if (!response || response.code !== 200 || !response.payload || response.error){
        AppDelegate.openErrorBar(response);
        return;
      }
      
      if (response.payload){
        this.requestSn();
        this.openSnackBar(JMLanguage.translate("global.submitted"));
      }


    }

  }

  //---------- fileServer calls ---------- 
  
  // formData handler
  private async getUploadDataFromFileUploadParmas(type: JMENUM.SnAttachmentType, file?: File){
    let uploadData = new FormData();
    // HA Service Report - Data preparation
    if(type == JMENUM.SnAttachmentType.HA_SERVICER_REPORT){
      if(this.haServiceReportUploadPreviewParam && this.haServiceReportUploadPreviewParam.userInput){
          // Check file size 
            if(this.haServiceReportUploadPreviewParam.userInput['fileInput'].size > 10000000){
              this.openSnackBar(`${JMLanguage.translate("pages.sn.attachment.msg.error.max-size")}`);
              this.clearUploadPreviewerData(type)
              this.clearPreviewContent();
              this.haServiceReportUploadPreviewParam.isLoadingAddAttachment = false
              return null
            }
          // Check Input params has file & Only mimeType PDF
            if(this.haServiceReportUploadPreviewParam.userInput['fileInput'].type !== AttachmentMimeType.Pdf){
              this.openSnackBar(`${this.haServiceReportUploadPreviewParam.userInput['fileInput'].type + ': ' + JMLanguage.translate("pages.sn.invalid-file-format")}`);
              this.clearUploadPreviewerData(type)
              this.clearPreviewContent();
              this.haServiceReportUploadPreviewParam.isLoadingAddAttachment = false
              return null
            }
          let result;
          this.haServiceReportUploadPreviewParam.isLoadingAddAttachment = true
          // blob convert Binary
            let data = await blobToBinary(this.haServiceReportUploadPreviewParam.userInput['fileInput'], fileType.arrayBuffer)

            let totalPages = await getPdfTotalPage(data)
            let selectedPage = this.haServiceReportUploadPreviewParam.selectedPage 

            if(selectedPage > totalPages || selectedPage < 1){
              this.openSnackBar(`${JMLanguage.translate("pages.sn.invalid-page-number")}`);
              this.clearUploadPreviewerData(type)
              this.clearPreviewContent();
              this.haServiceReportUploadPreviewParam.isLoadingAddAttachment = false
              return null
            }
            // Using pdf-lib to split pdf by user selected page into two pdf
            result = await splitPdfByPage(data, [selectedPage - 1])
            // Check result return two pdf
            if(result.length > 1){
                let i = 0;
                let haServiceReportAttachmentName // prepare HA Service Report with HA Work Order number or Urgent reply
                if(this.sn.eamData && Object.keys(this.sn.eamData).length){
                    if(this.sn.eamData.HAWorkOrderNumber){
                      //with HA Work Order number
                        haServiceReportAttachmentName = `${JMCONSTANT.HA_SERVICE_REPORT_PREFIX + this.sn.eamData.HAWorkOrderNumber + '-' + this.sn.snNumber}`
                    } else {
                      //Urgent reply
                        haServiceReportAttachmentName = `${JMCONSTANT.HA_SERVICE_REPORT_PREFIX + this.sn.snNumber}`
                    }
                }
                while(i < result.length){
                   let fileName = i === 0 ? `${haServiceReportAttachmentName}.pdf` : 'otherSupplimentryDocs.pdf' 
                   let blob = new Blob([result[i]], {type: AttachmentMimeType.Pdf})
                   uploadData.append('file', blob, fileName); i++;
                }
            }
      }
    // Normal SN attachments - Data preparation
    } else if (type == JMENUM.SnAttachmentType.GENERAL){
        if(file){
          this.uploadPreviewParam.isLoadingAddAttachment = true
          uploadData.append('file',file, file.name)
        }
        else{
          this.uploadPreviewer.clearUploadTable();
          this.uploadPreviewer.closeUploadPreview();
          this.uploadPreviewParam.isLoadingAddAttachment = false
          return null
        }
   
   }
   return uploadData;
}

  //---------- Get files---------- 
  private async requestGetFile(attachmentId, description?, download?, button?) {
    if (button) button.isLoading = true;
    this.isPreviewerLoading = download ? false : true;
    let request: JM.JMRequestFilesGetFile = new JM.JMRequestFilesGetFile(attachmentId);
    const response : JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request)

    if (!response || response.code !== 200 || !response.payload){
      AppDelegate.openErrorBar(response.error);
      button.isLoading = false;
      return;
    }
    if(download){
      let fileName = description != null && description != undefined ? description : attachmentId
      saveAs(response.payload, fileName);
      button.isLoading = false;
    } else {
      this.setPreviewContent(response)
    }
  }

  //---------- Remove file---------- 
  private async requestRemoveAttachment(attachment, button?) {
    if (button) button.isLoading = true;
    let request: JM.JMRequestSnRemoveAttachment = new JM.JMRequestSnRemoveAttachment();
    request.attachmentIds = [attachment.attachmentId]
    request.version = this.sn.version
    request.snNumber = this.sn.snNumber
    const response : JM.JMResponseSnRemoveAttachment = await AppDelegate.sendJMRequest(request)

    if (button) button.isLoading = false;
    if (!response || response.code !== 200 || !response.payload){
      AppDelegate.openErrorBar(response.error);
      return;
    } 
    
    if (response.payload){
      this.updateSn(response.payload);
      this.renderTable();
      this.renderHaServiceReportTable();
      this.clearPreviewContent();
      this.openSnackBar(JMLanguage.translate("global.removed"));
    }
  }

  //---------- Add file---------- 
  private async requestUploadHAFile(type: JMENUM.SnAttachmentType,file?:File){

    let request: JM.JMRequestFilesUploadFile = new JM.JMRequestFilesUploadFile()
    
    let uploadData = await this.getUploadDataFromFileUploadParmas(type, file)
      if(!uploadData){
        this.clearUploadPreviewerData(type)
        this.clearPreviewContent();
        this.haServiceReportUploadPreviewer.closeUploadPreview();
        this.uploadPreviewParam.isLoadingAddAttachment = false
        return
      }
      const response : JM.JMResponseFilesUploadFile = await AppDelegate.sendJMFileRequestWithFileHost(request, uploadData)

      if (!response || response.code !== 200 || !response.payload) {
        AppDelegate.openErrorBar(response.error);
        return;
      }
      if (response.payload.length > 0) {
        let uploadedFiles = response.payload;
        let attachments = []
        for (let file of uploadedFiles){
          let attachment = new JMOBJ.Attachment();
          attachment.attachmentId =  file.attachmentId;
          // HA Service Report Submission - def attachmentType  
          let test = Boolean(file['originalname'].indexOf('otherSupplimentryDocs') < 0)
          attachment.attachmentType = (file['originalname'].indexOf('otherSupplimentryDocs') < 0) ? JMENUM.SnAttachmentType.HA_SERVICER_REPORT : JMENUM.SnAttachmentType.GENERAL;
          attachment.description = file['originalname']
          attachments.push(attachment)
        }
        // Add newly add attachment to SN
        this.requestAddAttachment(attachments, type);
      }
  };
  
  private async requestUploadGeneralFile(type: JMENUM.SnAttachmentType,files:Array<File>){
    if (!(Array.isArray(files) && files.length > 0)) return;
    for (let file of files) {
      try {
        let request: JM.JMRequestFilesUploadFile = new JM.JMRequestFilesUploadFile()
        let uploadData = await this.getUploadDataFromFileUploadParmas(type, file)
        if(!uploadData){
          this.uploadPreviewer.clearUploadTable();
          this.uploadPreviewer.closeUploadPreview();
          this.uploadPreviewParam.isLoadingAddAttachment = false
          return
        }
        const response : JM.JMResponseFilesUploadFile = await AppDelegate.sendJMFileRequestWithFileHost(request, uploadData)
        if (!response || response.code !== 200 || !response.payload) {
          AppDelegate.openErrorBar(response.error);
          return;
        }
        if (response.payload.length > 0) {
          let uploadedFiles = response.payload;
          let attachments = []
          for (let file of uploadedFiles){
            let attachment = new JMOBJ.Attachment();
            attachment.attachmentId =  file.attachmentId;
            attachment.attachmentType = JMENUM.SnAttachmentType.GENERAL;
            attachment.description = file.originalname != undefined && file.originalname != null?  file.originalname: JMLanguage.translate(`global.na`)
            attachments.push(attachment)
          }
          // Add newly add attachment to SN
          await this.requestAddAttachment(attachments, type);
        }
      } catch (err) { 
        console.error(err); 
      }
    }
  };
  //---------- Add attachment to SN---------- 

  private async requestAddAttachment(attachments, type: JMENUM.SnAttachmentType) {
    let request = new JM.JMRequestSnAddAttachment();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version.valueOf();
    request.newAttachments = attachments
    const response : JM.JMResponseSnAddAttachment = await AppDelegate.sendJMRequest(request) 

    if (!response || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(`failed to add Attachments to SN: ${this.snNumber}`);
      if(response.code == 21001){
        let invalidCommandMsg = response.payload.toString()
        this.openSnackBar(invalidCommandMsg);
        switch(type){
          case JMENUM.SnAttachmentType.GENERAL:
            this.uploadPreviewer.clearUploadTable();
            this.uploadPreviewer.closeUploadPreview();
            break;
          case JMENUM.SnAttachmentType.HA_SERVICER_REPORT:
            this.clearUploadPreviewerData(type)
            this.clearPreviewContent();
            this.haServiceReportUploadPreviewer.closeUploadPreview();
            break;
          default:
            break;
        }
        if(this.uploadPreviewParam.isLoadingAddAttachment == true) {this.uploadPreviewParam.isLoadingAddAttachment = false}
        if(this.haServiceReportUploadPreviewParam.isLoadingAddAttachment == true) {this.haServiceReportUploadPreviewParam.isLoadingAddAttachment = false}
  
        return
      }
    } 

    if(response.payload){
      if(this.uploadPreviewParam.isLoadingAddAttachment == true) {this.uploadPreviewParam.isLoadingAddAttachment = false}
      if(this.haServiceReportUploadPreviewParam.isLoadingAddAttachment == true) {this.haServiceReportUploadPreviewParam.isLoadingAddAttachment = false}

      switch(type){
        case JMENUM.SnAttachmentType.GENERAL:
          this.uploadPreviewer.clearUploadTable();
          this.uploadPreviewer.closeUploadPreview();
          break;
        case JMENUM.SnAttachmentType.HA_SERVICER_REPORT:
          this.clearUploadPreviewerData(type)
          this.clearPreviewContent();
          this.haServiceReportUploadPreviewer.closeUploadPreview();
          break;
        default:
          break;
      }
      this.updateSn(response.payload);
      this.renderTable();
      this.renderHaServiceReportTable();
      this.openSnackBar(JMLanguage.translate("pages.sn.saved"));

    }
  }

  // ----------- UI function ----------- //
  private setPreviewContent(response) {
    this.file = response.payload;
    this.fileDetails = {
      fileName: this.selectedAttachment.description,
      fileSize: '',
      uploadedBy: this.selectedAttachment.uploadedBy,
      uploadDate: formatDateTimeWithWeek(this.selectedAttachment.uploadedAt)
    };

    this.fileDetails.fileSize = this.attachmentPreview.getFileSize(this.file.size);
    this.isPreviewerLoading = false
    this.attachmentPreview.selectedPage = 1
  }

  private clearPreviewContent(){
    this.file = undefined;
    this.fileDetails = undefined
    this.isPreviewerLoading = false
    this.attachmentPreview.selectedPage = 1
  }

  private clearUploadPreviewerData(type: JMENUM.SnAttachmentType){
    if (this.haServiceReportUploadPreviewParam &&
        this.haServiceReportUploadPreviewParam.userInput &&
        this.haServiceReportUploadPreviewParam.userInput['fileInput'] &&
        type == JMENUM.SnAttachmentType.HA_SERVICER_REPORT)
       {
         this.haServiceReportUploadPreviewParam.userInput = {}
         if (this.haServiceReportUploadPreviewer !== undefined) {
          this.haServiceReportUploadPreviewer.closeUploadPreview();
        }
      }
  }
  
  // ----------- Button function ----------- //
  updateSn(sn) {
    this.sn = sn;
    if (this.sn.attachmentList) {
      this.attachmentList = this.sortAttachmentsByDate(
        this.filterAttachment(this.sn.attachmentList, [JMENUM.SnAttachmentType.GENERAL, JMENUM.SnAttachmentType.HA_SERVICER_REPORT])
      );
      this.selectedAttachment = {};
      
        if (this.attachmentList.length)  {

          if(this.isHSDServiceIntegrationEnabled){
            let filteredAttachment = this.filterAttachment(this.sn.attachmentList, [JMENUM.SnAttachmentType.GENERAL])
            this.requestGetFile(filteredAttachment[0].attachmentId);
            this.selectedAttachment = filteredAttachment[0];
          }else{
            this.requestGetFile(this.attachmentList[0].attachmentId);
            this.selectedAttachment = this.attachmentList[0];
          }
        }
      
    }
  }

  onClickSubmitAttachment = (files:Array<File>) => {
    this.requestUploadGeneralFile(JMENUM.SnAttachmentType.GENERAL, files);
  }

  onClickSumbitHaServiceReport = () => {
    this.requestUploadHAFile(JMENUM.SnAttachmentType.HA_SERVICER_REPORT);
  }

  onDownloadButtonClicked = (button) => {
    this.requestGetFile(button.attachment.attachmentId, button.attachment.description, true, button)
  }

  onDeleteButtonClicked = (button) => {
    if (!button || !button.attachment || !button.attachment.attachmentId) {
      AppDelegate.openErrorBar();
      return;
    }

    let buttons = [
      {
        name: (JMLanguage.translate("pages.sn.attachments.delete-the-file")),
        handler: () => {
           this.requestRemoveAttachment(button.attachment, button);
        }
      },
      { name: (JMLanguage.translate("global.no")) }
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.attachments.confirm-delete-attached"), "", buttons);
  }

  onResendButtonClicked = (button) => {
    if(!button || !button.attachment || !button.attachment.attachmentId){
      AppDelegate.openErrorBar();
      return;
    }

    let buttons = [
      {
        name: (JMLanguage.translate("global.ok")),
        handler: ()=> {
          this.requestSnResendHaServiceReportSubmission(button.attachment)
        }
      },
      { name:  (JMLanguage.translate("global.no"))}

    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.attachments.confirm-resend-ha-service-report"), "", buttons);
  }

  // ----------- Init Component ----------- //
  initUploadPreviewer() {
    this.uploadPreviewParam = {
      isLoadingAddAttachment: false,
      isDisabledAddAttachment: !this.hasEditPermission,
      hasEditPermission: this.hasEditPermission,
      customClass:'fa fa-plus blue',
      userInput: {},
      onSubmitAttachment: this.onClickSubmitAttachment
    }
  }

  initHaServiceReportUploadPreviewer() {
    this.haServiceReportUploadPreviewParam = {
      isLoadingAddAttachment: false,
      isDisabledAddAttachment: this.sn.eamData && this.hasHaServiceReportUploadPermission  ? false : true,
      hasEditPermission: this.sn.eamData && this.hasHaServiceReportUploadPermission  ? true : false,
      userInput: {},
      isHaSn: this.sn.eamData ? true : false,
      onSubmitAttachment: this.onClickSumbitHaServiceReport
    }
  }
  // ----------- Tablex UI function ----------- //
  onRowClicked = (index: number, row: any) => {
    this.selectedAttachment = this.filterAttachment(this.attachmentList, [JMENUM.SnAttachmentType.GENERAL])[index];
    this.requestGetFile(this.selectedAttachment.attachmentId);
    // reset Preview selected Page
    if(this.haServiceTablex != undefined){
      this.unselectTableRow(this.haServiceTablex);
    }
  }

  onHaServiceReportRowClicked = (index: number, row: any) => {
    this.selectedAttachment = this.filterAttachment(this.attachmentList, [JMENUM.SnAttachmentType.HA_SERVICER_REPORT])[index];
    this.requestGetFile(this.selectedAttachment.attachmentId);
    if(this.attachmentTablex != undefined){
      this.unselectTableRow(this.attachmentTablex);
    }
  }
  
  private unselectTableRow(table){
    table.tablexParam['selectedRowIndex'] = -1;
  }

  renderTable() {
    //other attachment
    this.tablexParam.content = this.filterAttachment(this.attachmentList, [JMENUM.SnAttachmentType.GENERAL]).map(attachment => {
      let buttons = [
        { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
      ];
      if (this.isReadyForDelete && this.hasEditPermission) {
        buttons = [
          { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
         // { "id": "delete-button_" + attachment._id, "name": "", "class": "delete-button btn p-1", "icon": "fas fa-times font-size-l", "onClicked": this.onDeleteButtonClicked, "attachment": attachment },
        ];
      }
      return [
        attachment._id,
        attachment.description,
        buttons
      ];
    });
  }
  
  renderHaServiceReportTable(){
    // HA Service Report attachment
    this.haTablexParam.content = this.filterAttachment(this.attachmentList, [JMENUM.SnAttachmentType.HA_SERVICER_REPORT]).map(attachment => {
      let buttons = [
        { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
      ];
      if (this.hasHaServiceReportResendPermission && this.hasHaServiceReportDeletePermission && this.hasNotificationEmailHostories){
        buttons = [
          { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
          { "id": "delete-button_" + attachment._id, "name": "", "class": "delete-button btn p-1", "icon": "fas fa-times font-size-l", "onClicked": this.onDeleteButtonClicked, "attachment": attachment },
          { "id": "resend-button_" + attachment._id, "name": "", "class": "resend-button btn p-1", "icon": "fas fa-sync font-size-l", "onClicked": this.onResendButtonClicked, "attachment": attachment }
        ];
      }
      else if (this.hasHaServiceReportResendPermission && this.hasNotificationEmailHostories) {
        buttons = [
          { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
          { "id": "resend-button_" + attachment._id, "name": "", "class": "resend-button btn p-1", "icon": "fas fa-sync font-size-l", "onClicked": this.onResendButtonClicked, "attachment": attachment }
        ];
      }
      return [
        attachment._id, // _id
        attachment.description, // filename
        attachment.uploadedAt ? formatDateTimeWithWeek(attachment.uploadedAt) : '',// uploadDate
        attachment.uploadedBy, // uploadedBy
        attachment.sendDate ? formatDateTimeWithWeek(attachment.sendDate) : '',// sendDate
        buttons // action
      ];
    })
  }

  private filterAttachment(attachmentList: any[], types: JMENUM.SnAttachmentType[]) {
    return attachmentList.filter(att => types.includes(att.attachmentType));
  }

  // ------ Tablex ------ //
  initTable() {
    this.tablexParam = {
      isLoadingTable: true,
      tableRow: "row",
      tableClass: "sn-attachment-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: false,
      enablePagination: false,
      onRowClicked: this.onRowClicked,
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.attachment.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 justify-content-around"
        }
      ],
      content: []
    };

    this.haTablexParam = {
      isLoadingTable: true,
      tableRow: "row",
      tableClass: "sn-ha-service-report-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: false,
      enablePagination: false,
      onRowClicked: this.onHaServiceReportRowClicked,
      headers:  [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.attachment.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'uploadDate',
          name: 'component.attachment.uploadDate',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'uploadedBy',
          name: 'component.attachment.uploadedBy',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'sendDate',
          name: 'component.attachment.sendDate',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 justify-content-around"
        }
      ],
      content: []
    }

  }
  // ------ Tablex End ------ //
  
  sortAttachmentsByDate(toSortAttachment: any[], isDesc = true) {
    return toSortAttachment.sort((a: any, b: any): number => {
      const aTime = moment(a.uploadedAt);
      const bTime = moment(b.uploadedAt);
      if(aTime && bTime) {
        if (isDesc) {
          return aTime.isAfter(bTime) ? -1 : 1;
        } else {
          return aTime.isAfter(bTime) ? 1 : -1;
        }
      } else {
        return 0;
      }
    });
  }
}
