<h2 class="title">{{ 'component.sn-batch-report-submission-steps-header.title' | translate }}
  <a *ngIf="uploadHistoryAccess.permission && isStageUpload" routerLink="{{uploadHistoryAccess.routing}}"><i
      class="fa fa-history blue"></i></a>
</h2>

<div class="d-flex flex-row align-items-center my-4">
    <!-- TODO: update layout -->
    <div class="step" [class.selected]="isStageUpload">
      {{ 'component.sn-batch-report-submission-steps-header.upload' | translate }}
    </div>
    <div class="fs-2 arrow-icon">
      <i class="fas fa-arrow-right"></i>
    </div>
    <div class="step" [class.selected]="isStageVerification">
      {{ 'component.sn-batch-report-submission-steps-header.verification' | translate }}
    </div>
    <div class="fs-2 arrow-icon">
      <i class="fas fa-arrow-right"></i>
    </div>
    <div class="step" [class.selected]="isStageResult">
      {{ 'component.sn-batch-report-submission-steps-header.result' | translate }}
    </div>
  </div>
  <hr class="underline">