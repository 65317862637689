<section class="range-slider">
  <div class="time-bubble time1 font-size-s" [ngStyle]="{'left':(staffTimeSheet.startTime/24*100).toFixed(1)+'%', 'right':'auto', 'top': staffTimeSheet.endTime - staffTimeSheet.startTime < 1? '0px':'12px'}">
    {{valueToTimeFormat(this.staffTimeSheet.startTime)}}
  </div>
  <div class="time-bubble time2 font-size-s" [ngStyle]="{'left':'auto', 'right':((24 - staffTimeSheet.endTime)/24 * 100).toFixed(1)+'%'}">
    {{valueToTimeFormat(this.staffTimeSheet.endTime)}}
  </div>
  <input *ngIf="!disabled" class="range-slider-input time1" (change)="onChangeTimeSheet('start')"
        [(ngModel)]="staffTimeSheet.startTime" min="0" max="24" step="0.25" type="range" >
  <input *ngIf="!disabled" class="range-slider-input time2" (change)="onChangeTimeSheet('end')"
        [(ngModel)]="staffTimeSheet.endTime" min="0" max="24" step="0.25" type="range">
  <div class="range-track" [ngStyle]="{'left':( staffTimeSheet.startTime/24*100).toFixed(1)+'%', 'right':((24 - staffTimeSheet.endTime)/24 * 100).toFixed(1)+'%'}"></div>
</section>