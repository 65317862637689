import { Component, Injector, Input, OnInit } from '@angular/core';
import { Permission } from '@enum/permission';
import { SnReason } from '@enum/sn-reason';
import { ContactGroupService } from '@services/contact-group.service';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JM, JMCONSTANT, JMENUM, JMUTILITY, JMREASON } from '@ccep/CCEPConnector-ts';
import * as utility from 'src/app/services/utility';
@Component({
  selector: 'complete-job-card-form',
  templateUrl: './complete-job-card-form.component.html',
  styleUrls: ['./complete-job-card-form.component.scss']
})

export class CompleteJobCardFormComponent extends BasePage implements OnInit {
  @Input() parameters = {
    jobCardNumber: null,
    ccsServiceOrderNumber: null,
    jobNature: null,
    orderType: null,
    workCentre: null,
    isHaEquipmentOwner: false,
    isAssignedPerson: false,
    isSnMember: false,
    isFirstAttendedJobCard: true,
    isBorrowedTeam: false,
    hasFollowUpJobCard: false,
    isLoading: false,
    workCentreAttribute: null,
    soObtainedByCcep: true,
    requireNonComplianceReason: false,
    nonComplianceReason: "",
    onSubmitClicked: (button) => { },
    onObtainSoClicked: () => { },
  };

  @Input() completeWithNoAction: boolean = false;
  @Input() idExtension: string = "";

  // callback return value
  completeReason: any;
  remark: string;
  haNonComplianceReason: string;
  confirmed: boolean;

  followUpInfo: any;

  // --
  needSoNumber: boolean;

  // timesheet
  isLoadingTimesheet = false;
  hasTimesheet = false;

  // for complete with follow up job
  createFollowUpJobCardFormParam = {} as any;

  // drop down options
  completeReasonOptions: any[];
  haNonComplianceReasonOptions: any[];

  // for approval
  hasApprovePermission: boolean = false;
  isLoadingApprover: boolean = false;
  approverListOptions: string[] = [];
  selectedApprover: string[];

  completeWithFollowUpReasonCode = "3220";

  // for complete with no action
  noActionList = [];

  constructor(
    private injector: Injector,
    private contactGroupService: ContactGroupService) {
    super(injector);
  }

  ngOnInit() {
    this.hasApprovePermission = JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.JOBCARD_COMPLETE_APPROVE)
      || JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.JOBCARD_COMPLETE_APPROVE_ALL);
    this.noActionList = JMREASON.JOB_COMPLETE_REASON_LIST.filterReason([JMREASON.Attribute.NO_ACTION_REQUIRED]).map(
      reason => reason.code + ""
    );
  }

  ngOnDestroy() { }

  ngOnChanges() {
    if (!this.parameters || !this.parameters.jobCardNumber) return;

    if (!this.parameters.workCentreAttribute) {
      this.parameters.workCentreAttribute = {};
    }

    this.needSoNumber = this.parameters.orderType == "ZS01" && this.parameters.workCentreAttribute && this.parameters.workCentreAttribute.requiredObtainSO;

    this.initDropDownOptions(this.parameters);
    this.requestTimesheet();

    if (!this.hasApprovePermission) {
      this.requestPostSummary();
    }

    if (this.parameters.nonComplianceReason) {
      this.haNonComplianceReason = this.parameters.nonComplianceReason;
    }
  }

  //===================================================================================================================
  // UI functions
  initDropDownOptions(parameters) {
    this.completeReasonOptions = [];
    this.haNonComplianceReasonOptions = [];

    if (parameters.isHaEquipmentOwner) {
      const jReasonAttrs = [JMREASON.Attribute.JM, JMREASON.Attribute.HA];
      switch (parameters.jobNature) {
        case 'CM':
          jReasonAttrs.push(JMREASON.Attribute.CM);
          break;
        case 'PM':
          jReasonAttrs.push(JMREASON.Attribute.PM);
          break;
      }
      const haCompleteReasons = JMREASON.JOB_COMPLETE_REASON_LIST.filterReason(jReasonAttrs);

      for (const haCompleteReason of haCompleteReasons) {
        const isReasonWithFollowUp = haCompleteReason.checkAttribute([JMREASON.Attribute.CREATE_FOLLOWUP_JOB]);
        const isValidReason = this.isValidReason(isReasonWithFollowUp);

        if (isValidReason) {
          let description = Session.selectedLanguage === JMENUM.Language.ZH ? haCompleteReason.description.zh : haCompleteReason.description.en
          let prefixKey = isReasonWithFollowUp ? 'component.complete-job-card-form.reason.interim' : 'component.complete-job-card-form.reason.final';
          let prefix = `[${JMLanguage.translate(prefixKey)}] `;

          const item = {
            value: haCompleteReason.code,
            description: `${prefix}${description}`
          };
          this.completeReasonOptions.push(item);
        }
      }

      const haNonComplianceReasons = JMREASON.HA_NON_COMPLIANCE_REASON_LIST.filterReason(jReasonAttrs);
      for (const haNonComplianceReason of haNonComplianceReasons) {
        this.haNonComplianceReasonOptions.push({
          value: haNonComplianceReason.code,
          description: haNonComplianceReason.description[JMLanguage.getCurrentLanguage()],
        });
      }

    } else if (parameters.jobNature == JMENUM.JobNature.STANDALONE) {
      const jReasonAttrs = [JMREASON.Attribute.JM, JMREASON.Attribute.STANDALONE];
      const completeReasons = JMREASON.JOB_COMPLETE_REASON_LIST.filterReason(jReasonAttrs);

      completeReasons.forEach(reason => {
        this.completeReasonOptions.push({
          value: reason.code,
          description: (Session.selectedLanguage === JMENUM.Language.ZH ? reason.description.zh : reason.description.en)
        });
      });

    } else {
      SnReason.forEach(reason => {
        if (reason.area === 'jobCard' && reason.orderType === 'complete') {
          const isCompleteWithFollowUpReason = reason.id == this.completeWithFollowUpReasonCode;
          const isValidReason = this.isValidReason(isCompleteWithFollowUpReason);

          if (isValidReason) {
            this.completeReasonOptions.push({
              value: reason.id,
              description: (Session.selectedLanguage === JMENUM.Language.ZH ? reason.descriptionZh : reason.descriptionEn)
            });
          }
        }
      });

      // first attended job can only choose "complete with follow up" if follow up job existing
      if (parameters.isFirstAttendedJobCard && parameters.hasFollowUpJobCard) {
        this.completeReason = this.completeWithFollowUpReasonCode;
        this.onCompleteReasonChanged();
      }
    }

    if (this.completeWithNoAction) {
      this.completeReasonOptions = this.completeReasonOptions.filter(option => this.noActionList.includes(option.value + ""));
    }

    if (this.completeReasonOptions.length == 1) {
      this.completeReason = this.completeReasonOptions[0].value;
    }
  }

  public checkHasNoActionOptions(parameters): boolean {
    if (parameters.isHaEquipmentOwner) {
      const jReasonAttrs = [JMREASON.Attribute.JM, JMREASON.Attribute.HA];
      switch (parameters.jobNature) {
        case 'CM':
          jReasonAttrs.push(JMREASON.Attribute.CM);
          break;
        case 'PM':
          jReasonAttrs.push(JMREASON.Attribute.PM);
          break;
      }
      const haCompleteReasons = JMREASON.JOB_COMPLETE_REASON_LIST.filterReason(jReasonAttrs);
      for (const haCompleteReason of haCompleteReasons) {
        const isReasonWithFollowUp = haCompleteReason.checkAttribute([JMREASON.Attribute.CREATE_FOLLOWUP_JOB]);
        const isValidReason = this.isValidReason(isReasonWithFollowUp);

        if (isValidReason) {
          if (haCompleteReason.checkAttribute([JMREASON.Attribute.NO_ACTION_REQUIRED])) {
            return true;
          }
        }
      }
    } else if (parameters.jobNature == JMENUM.JobNature.STANDALONE) {
      const jReasonAttrs = [JMREASON.Attribute.JM, JMREASON.Attribute.STANDALONE];
      const completeReasons = JMREASON.JOB_COMPLETE_REASON_LIST.filterReason(jReasonAttrs);
      for (const reason of completeReasons) {
        if (reason.checkAttribute([JMREASON.Attribute.NO_ACTION_REQUIRED])) {
          return true;
        }
      }
    } else {
      for (const reason of SnReason) {
        if (reason.area === 'jobCard' && reason.orderType === 'complete') {
          const isCompleteWithFollowUpReason = reason.id == this.completeWithFollowUpReasonCode;
          const isValidReason = this.isValidReason(isCompleteWithFollowUpReason, parameters);

          if (isValidReason) {
            if (this.noActionList.includes(reason.id)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  private isValidReason(isCompleteWithFollowUpReason: boolean, parameters?: any): boolean {
    const para = parameters || this.parameters;
    if (para) {
      // follow up job cannot complete with follow up
      if (isCompleteWithFollowUpReason && !para.isFirstAttendedJobCard) {
        return false;
      }

      // ad-hoc and transfer job cannot complete with follow up
      if (isCompleteWithFollowUpReason && para.orderType != "ZS01") {
        return false;
      }

      // first attended job can only choose "complete with follow up" if follow up job existing
      if (!isCompleteWithFollowUpReason && para.isFirstAttendedJobCard && para.hasFollowUpJobCard) {
        return false;
      }
    }

    // complete with follow up job only allow not borrowed team and (first attended or team member in SN)
    // const isOriginalTeamWithPermission = !this.parameters.isBorrowedTeam 
    //                                       && (this.parameters.isAssignedPerson || this.parameters.isSnMember || this.hasCompleteAllPermission());
    // if (isCompleteWithFollowUpReason && !isOriginalTeamWithPermission) {
    //   return false;
    // }

    return true;
  }

  //===================================================================================================================
  // API request functions
  private async requestTimesheet() {
    this.isLoadingTimesheet = true;

    const request = new JM.JMRequestTimesheetGetTimesheeByJobCardNumber();
    request.jobCardNumber = this.parameters.jobCardNumber;

    const response: JM.JMResponseTimesheetGetTimesheeByJobCardNumber = await AppDelegate.sendJMRequest(request);
    this.isLoadingTimesheet = false;

    if (!response || !response.code || response.code != 200) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.hasTimesheet = response.payload ? true : false;
  }

  private async requestPostSummary() {
    const request = new JM.JMRequestPostsPostSummary();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.systemName = Constants.SYSTEM_NAME;
    request.permissions = [JMENUM.Permission.JOBCARD_COMPLETE_APPROVE];
    request.authorizations = {
      workCenters: [this.parameters.workCentre]
    }

    this.isLoadingApprover = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingApprover = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records && response.payload.records.length > 0) {
      this.approverListOptions = response.payload.records.filter(post => post.name !== Session.userInfo.name).map(post => post.name);
    }
  }

  //===================================================================================================================

  requireCreateFollowUpJob() {
    if (this.parameters.isHaEquipmentOwner) { // HA SN

      const haCompleteReasonsRequireFollowup = JMREASON.JOB_COMPLETE_REASON_LIST.filterReason([
        JMREASON.Attribute.JM,
        JMREASON.Attribute.HA,
        JMREASON.Attribute.CREATE_FOLLOWUP_JOB
      ]);

      const foundCompleteWithFollowUpReason = haCompleteReasonsRequireFollowup.find(reason => reason.code === this.completeReason);
      return foundCompleteWithFollowUpReason && !this.parameters.hasFollowUpJobCard;

    } else {
      return this.completeReason == this.completeWithFollowUpReasonCode && !this.parameters.hasFollowUpJobCard;
    }
  }

  hasCreateJobCardPermission() {
    return this.authorizationService.hasPermission(Permission.jobcardCreate) || this.authorizationService.hasPermission(Permission.jobcardCreateAll);
  }

  hasCompleteAllPermission() {
    return this.authorizationService.hasPermission(Permission.jobcardCompleteAll);
  }

  checkFollowUpInfoCompleted() {
    return this.followUpInfo && this.followUpInfo.completed && this.hasCreateJobCardPermission();
  }

  isValidToSubmit() {
    let valid = this.completeReason && this.confirmed && (this.parameters.ccsServiceOrderNumber || !this.needSoNumber || this.completeWithNoAction);

    if (this.requireCreateFollowUpJob()) {
      valid = valid && this.checkFollowUpInfoCompleted();
    }

    if (!this.hasApprovePermission) {
      valid = valid && this.selectedApprover && this.selectedApprover.length > 0;
    }

    if (this.requireNonComplianceReason) {
      valid = valid && this.haNonComplianceReason;
    }

    return valid;
  }

  getCallbackData() {
    if (this.remark) this.remark = this.remark.trim();
    let result = {
      completeCode: this.completeReason,
      completeRemarks: this.remark,
      requireCreateFollowUpJob: this.requireCreateFollowUpJob()
    } as any;

    if (this.requireCreateFollowUpJob() && this.followUpInfo) {
      result.followUpReasonCode = this.followUpInfo.followUpReasonCode;
      result.followUpRemarks = this.followUpInfo.followUpRemarks;
      result.followUpJobassignedPersons = this.followUpInfo.assignedPersons;
      result.followUpJobOfficerInCharge = this.followUpInfo.officerInCharge;
    }

    if (!this.hasApprovePermission) {
      result.approver = this.selectedApprover
    }

    if (this.requireNonComplianceReason) {
      result.haNonComplianceReason = this.haNonComplianceReason;
    }

    return result;
  }

  //===================================================================================================================
  // Event callback
  onClickSubmit() {
    let buttons = [
      {
        name: this.translate("action.button.popup.confirm-complete-job"),
        handler: () => {
          if (this.parameters.onSubmitClicked)
            this.parameters.onSubmitClicked(this.getCallbackData());
        }
      },
      { name: (this.translateService.instant("global.no")) }
    ];

    let popUpMsg = this.requireCreateFollowUpJob() ? this.translate("action.button.popup.complete-job-with-follow-up") : this.translate("action.button.popup.complete-job");
    this.showPopUpAlert(popUpMsg, "", buttons);
  }

  onClickObtainSo() {
    if (this.parameters.onObtainSoClicked) this.parameters.onObtainSoClicked();
  }

  onClickTimeComplete() {
    if (!this.parameters.soObtainedByCcep) {
      AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.timesheet.alert-msg.designated-so"), "", [{
        name: JMLanguage.translate("global.ok")
      }]);
    } else {
      this.router.navigate(["/timesheet/job/", this.parameters.jobCardNumber]);
    }
  }

  onClickTimeCompleteV2() {
    if (!this.parameters.soObtainedByCcep) {
      AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.timesheet.alert-msg.designated-so"), "", [{
        name: JMLanguage.translate("global.ok")
      }]);
    } else {
      this.router.navigate(["/timesheet/job/v2", this.parameters.jobCardNumber]);
    }
  }

  onCompleteReasonChanged = () => {
    this.followUpInfo = null;

    if (this.requireCreateFollowUpJob()) {
      this.createFollowUpJobCardFormParam = {
        workCentre: this.parameters.workCentre,
        jobCardList: [this.parameters.jobCardNumber],
        onFollowUpInfoChanged: this.onFollowUpInfoChanged
      };

      if (!this.hasCreateJobCardPermission()) {
        this.openSnackBar(this.translate("popupError.no-permission"));
      }
    }
  }

  onFollowUpInfoChanged = (button) => {
    this.followUpInfo = button;
  }

  get showTimesheetButton(): boolean {
    if (!utility.isEnabledFeature( Session, JMCONSTANT.JMFeature.JOB_CARD_TIMESHEET)) {
      return false;
    }
    return this.parameters.jobNature == JMENUM.JobNature.STANDALONE ? true : this.parameters.orderType == 'ZS01' && !this.parameters.isBorrowedTeam;
  }

  get showTimesheetV2Button(): boolean {
    if (!utility.isEnabledFeature( Session, JMCONSTANT.JMFeature.JOB_CARD_TIMESHEET_V2)) {
      return false;
    }
    return this.parameters.jobNature == JMENUM.JobNature.STANDALONE ? true : this.parameters.orderType == 'ZS01' && !this.parameters.isBorrowedTeam;
  }

  get getMaxRemarksLength(): number {
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }

  get requireNonComplianceReason() {
    return this.parameters.requireNonComplianceReason && this.featureEnableNonCompliance;
  }

  get featureEnableNonCompliance(): boolean {
    if (!Session.featureList) return false;
    const feature = Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.HA_ORDER_REPLY_NON_COMPLIANCE_REASON);
    return feature ? feature.enabled == true : false;
  }
}
