import { Component, OnInit, ViewChild } from '@angular/core';
import { JM, JMCONSTANT, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JmUserSettings } from '@enum/jm-user-settings.enum';
import { TablexColumnFilterOption, TablexColumnFilterType, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Session } from '@services/session';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/AppDelegate';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { SpecialRequestAlertDetailComponent } from '../special-request-alert-detail/special-request-alert-detail.component';
import { isArray, isNumber } from 'underscore';
import { AuthorizationService } from '@services/authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-special-request-alert-list',
  templateUrl: './special-request-alert-list.component.html',
  styleUrls: ['./special-request-alert-list.component.scss']
})
export class SpecialRequestAlertListComponent implements OnInit {
  @ViewChild("createSRAPanel", { static: true }) createSRAPanel: any;
  @ViewChild("SRADetail", { static: true }) SRADetail: SpecialRequestAlertDetailComponent;
  // Table
  tablexFilter: any = {};
  tablexParam: {} = {};
  pageSizeOptions: any = [10, 25, 100];
  pageCount = 1;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];
  snActionOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  clientOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  districtOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  locationOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  equipmentCategoryOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  equipmentTypeOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  SRASummary: any[] = [];
  originalAPISRASummaryMap: Map<string, any> = new Map<string, any>();
  summaryPageSize: number = 10;
  summaryPageNumber: number = 1;

  selectedSRA: SRASummary = null;
  clearSRADetail = false;

  constructor(private authService: AuthorizationService, private router: Router) { }

  ngOnInit() {
    if (!this.authService.hasPermission(JMENUM.Permission.SPECIAL_REQUEST_UPDATE) || 
    !Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.VVIP_SRA)) {
      this.router.navigate(['']);
      return;
    }
    JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.JOBCARD_VIEW, JMENUM.Permission.JOBCARD_VIEW_ALL], false);
    this.initAllTableHeader();
    this.initFilterOptions();
    this.initSelectedCol();
    this.initTablex();
    this.requestSRAs();

  }

  createSRA() {
    if (this.selectedSRA || this.clearSRADetail) {
      this.SRADetail.initForm(null);
      this.selectedSRA = null;
      this.clearSRADetail = false;
    }
    this.createSRAPanel.toggle();
  }


  async renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter(col => {
      return this.selectedColId.includes(col.id);
    });

    this.tablexParam['content'] = this.SRASummary.map((oriData, index) => {
      const temp: any[] = [];
      const data: SRATableViewData = new SRATableViewData(oriData, this.originalAPISRASummaryMap,
        this.SRADetail.getEquipmentCategoriesOptions([oriData['equipmentCategory']]),
        this.SRADetail.getEquipmentTypesOptions([oriData['equipmentType']]));
      this.allColHeaders.forEach(col => {
        if (this.selectedColId.includes(col.id)) {
          if (col.id === "action") {
            const buttons = [{
              "class": "glyph brand-amber",
              "icon": "fas fa-edit",
              "onClicked": this.editSRA.bind(this, data)
            },
            {
              "class": "glyph brand-red",
              "icon": "fas fa-times",
              "onClicked": this.onDeleteRequestClicked.bind(this, data)
            }];
            temp.push(buttons);
          } else {
            let displayValue = data[col.id] || col.emptyValue;
            if (isArray(data[col.id]) && !data[col.id].length) {
              displayValue = col.emptyValue;
            }
            temp.push(displayValue);
          };
        }
      });
      return temp;
    });
    this.tablexParam['isLoadingTable'] = false;
  }

  editSRA(data: any) {
    if (!this.selectedSRA || this.selectedSRA.id !== data.id || this.selectedSRA.version !== data.version) {
      this.SRADetail.initForm(this.getSRADetailWithID(data.id));
      this.selectedSRA = data;
    }
    this.createSRAPanel.toggle();
  }

  getSRADetailWithID(id: string) {
    for (let i = 0; i < this.SRASummary.length; i++) {
      if (this.SRASummary[i].id === id) {
        return this.SRASummary[i];
      }
    }
    return null;
  }

  initFilterOptions() {
    this.snActionOptions.items = [];
    for (const key in JMENUM.SnTriggerSraActionList) {
      this.snActionOptions.items.push({
        label: JMLanguage.translate('special-request-alert.snAction.' + JMENUM.SnTriggerSraActionList[key]),
        value: JMENUM.SnTriggerSraActionList[key]
      });
    }
    this.snActionOptions.bindLabel = "label";
    this.snActionOptions.bindValue = "value";
    this.snActionOptions.multiple = true;
    this.snActionOptions.change = this.onOptionFilterChanged;

    this.equipmentCategoryOptions.items = this.getEquipmentCategoriesOptions();
    this.equipmentCategoryOptions.bindLabel = "label";
    this.equipmentCategoryOptions.bindValue = "value";
    this.equipmentCategoryOptions.multiple = true;
    this.equipmentCategoryOptions.change = this.onOptionFilterChanged;

    this.equipmentTypeOptions.items = this.getEquipmentTypesOptions();
    this.equipmentTypeOptions.bindLabel = "label";
    this.equipmentTypeOptions.bindValue = "value";
    this.equipmentTypeOptions.multiple = true;
    this.equipmentTypeOptions.change = this.onOptionFilterChanged;

    this.districtOptions.items = this.getDistrictsOptions();
    this.districtOptions.bindLabel = "label";
    this.districtOptions.bindValue = "value";
    this.districtOptions.multiple = true;
    this.districtOptions.change = this.onOptionFilterChanged;

  }

  initAllTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: 'id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none',
        emptyValue: "/"
      },
      {
        id: 'client',
        name: 'pages.special-request-alert.client',
        enableFilter: true,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "*"
      },
      {
        id: 'district',
        name: 'pages.special-request-alert.district',
        enableFilter: true,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.districtOptions,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "*"
      },
      {
        id: 'location',
        name: 'pages.special-request-alert.location',
        enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "*"
      },
      {
        id: 'equipmentCategory',
        name: 'pages.special-request-alert.equipmentCategory',
        enableFilter: true,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.equipmentCategoryOptions,
        showOptionTitle: true,
        multiple: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "*"
      },
      {
        id: 'equipmentType',
        name: 'pages.special-request-alert.equipmentType',
        enableFilter: true,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.equipmentTypeOptions,
        showOptionTitle: true,
        multiple: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "*"
      },
      {
        id: 'recipientEmailAddress',
        name: 'pages.special-request-alert.recipientEmail',
        enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "/"
      },
      {
        id: 'ccTo',
        name: 'pages.special-request-alert.ccTo',
        enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "/"
      },
      {
        id: 'remarks',
        name: 'pages.special-request-alert.remarks',
        enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "/"
      },
      {
        id: 'firstReminderEmail',
        name: 'pages.special-request-alert.firstReminderEmailHeader',
        enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "/"
      },
      {
        id: 'reminderEmailFrequency',
        name: 'pages.special-request-alert.reminderEmailFrequencyHeader',
        enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "/"
      },
      {
        id: 'snActionList',
        name: 'pages.special-request-alert.snAction',
        enableFilter: true,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.snActionOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "/"
      },
      {
        id: 'updatedBy',
        name: 'pages.special-request-alert.lastUpdatedBy',
        enableFilter: true,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "/"
      },
      {
        id: 'updatedAt',
        name: 'pages.special-request-alert.lastUpdatedDateTime',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: "/"
      },
      {
        id: 'action',
        name: 'pages.special-request-alert.action',
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
        emptyValue: ""
      },
    ];
    this.selectedCol = this.allColHeaders;
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: false,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: true,
      sortOrder: -1,
      sortBy: 'createdAt',
      minifyButton: false,
      tableRow: 'row',
      tableClass: 'sra-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.summaryPageSize,
      currentPage: this.summaryPageNumber,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
      customClass: 'expiry'
    };
  }

  private async requestSRAs() {
    this.tablexParam['isLoadingTable'] = true;
    let request = new JM.JMRequestSnGetSpecialRequestRuleList();
    request = this.renderRequestFilter(request);
    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];
    request.pageNumber = this.summaryPageNumber;
    request.pageSize = this.summaryPageSize;


    const response: JM.JMResponseSnGetSpecialRequestRuleList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.SRASummary = [];
    this.originalAPISRASummaryMap.clear();
    if (response.payload.records && response.payload.records.length > 0) {
      response.payload.records.forEach(record => {
        this.SRASummary.push(new SRASummary(record));
        if (record._id) {
          this.originalAPISRASummaryMap.set(record._id, record);
        }
      });
      this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.summaryPageSize);
    }
    this.renderTable();
  }

  // ----------- UI function ----------- //
  initSelectedCol() {
    this.selectedColId = [
      "objId",
      "client",
      "district",
      "location",
      "equipmentCategory",
      "equipmentType",
      "recipientEmailAddress",
      "ccTo",
      "firstReminderEmail",
      "reminderEmailFrequency",
      "snActionList",
      "updatedBy",
      "updatedAt",
      "action",
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[JmUserSettings.SRA_LIST_COLUMNS]) {
      Object.assign(this.selectedColId, cachedUserSettings[JmUserSettings.SRA_LIST_COLUMNS]);
    }
    this.onColFiltered(this.selectedColId);
  }

  renderRequestFilter(request) {
    request.filter = {};

    for (let key in this.tablexFilter) {
      if (this.tablexFilter[key]) {
        switch (key) {
          case "equipmentCategory":
            if (isArray(this.tablexFilter[key]) && this.tablexFilter[key].length === 0) {
              delete request.equipmentCategoryList;
            } else {
              request.equipmentCategoryList = this.tablexFilter[key];
            }
            break;
          case "equipmentType":
            if (isArray(this.tablexFilter[key]) && this.tablexFilter[key].length === 0) {
              delete request.equipmentTypeList;
            } else {
              request.equipmentTypeList = this.tablexFilter[key];
            }
            break;
          case "client":
            request.filter["client.clientShortName"] = this.tablexFilter[key];
            break;
          case "location":
            request.location = this.tablexFilter[key];
            break;
          case "district":
            if (isArray(this.tablexFilter[key]) && this.tablexFilter[key].length === 0) {
              delete request.districtList;
            } else {
              request.districtList = this.tablexFilter[key];
            }
            break;
          case "recipientEmailAddress":
            request.filter["recipientList.email"] = this.tablexFilter[key];
            break;
          case "ccTo":
            request.filter["ccList.email"] = this.tablexFilter[key];
            break;
          case "remarks":
            request.filter['remarks'] = this.tablexFilter[key];
            break;
          case "firstReminderEmail":
            if(isNumber(+this.tablexFilter[key])) {
              request.firstReminderEmailPeriod = +this.tablexFilter[key];
            }
            break;
          case "reminderEmailFrequency":
            if(isNumber(+this.tablexFilter[key])) {
              request.reminderEmailFrequency = +this.tablexFilter[key];
            }
            break;
          case "snActionList":
            if (isArray(this.tablexFilter[key]) && this.tablexFilter[key].length === 0) {
              delete request.snActionList;
            } else {
              request.snActionList = this.tablexFilter[key];
            }
            break;
          default:
            request.filter[key] = this.tablexFilter[key];
            break;
        }
      }
    }
    return request;
  }

  // ----------- Tablex UI function ----------- //
  onColFiltered = (selectedColId: any) => {
    this.selectedColId = selectedColId;
    this.renderTable();
    if (!Session.userInfo['userSettings'] || (Session.userInfo['userSettings'] && !arraysEqual(Session.userInfo.userSettings[JmUserSettings.SRA_LIST_COLUMNS], this.selectedColId))) {
      this.requestUpdateUserSettings();
      let userSettings = {};
      userSettings[JmUserSettings.SRA_LIST_COLUMNS] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  }

  onPageSizeClicked = (pageSize: number) => {
    this.summaryPageNumber = 1;
    this.summaryPageSize = pageSize;
    this.requestSRAs();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.summaryPageNumber = pageIndex;
    this.requestSRAs();
  }

  onSortOrderChanged = (header, sortOrder) => {
    this.tablexParam['sortBy'] = header ? header : 'updatedAt';
    this.tablexParam['sortOrder'] = sortOrder;
    this.requestSRAs();
  }

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }

    this.summaryPageNumber = 1;
    this.requestSRAs();
  }

  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }

    this.summaryPageNumber = 1;
    this.requestSRAs();
  }

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.requestSRAs();
  }

  // ------ API ------
  async requestUpdateUserSettings() {
    let userSettings = {};
    const request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[JmUserSettings.JOB_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
  }

  // delete request
  onDeleteRequestClicked = (requestData) => {
    let message = JMLanguage.translate("pages.special-request-alert.confirm-delete-request");
    let handler = () => { this.requestDeleteRequest(requestData.id, requestData.version); }
    let buttons = [
      {
        name: JMLanguage.translate("global.yes"),
        handler: handler
      },
      {
        name: JMLanguage.translate("global.no")
      }
    ]
    AppDelegate.showPopUpAlert(message, "", buttons);
  }

  private async requestDeleteRequest(requestId, version) {
    let request = new JM.JMRequestSnDeleteSpecialRequestRule();
    request.ruleId = requestId;
    request.version = version;
    const response: JM.JMResponseSnDeleteSpecialRequestRule = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate("message.success.delete"));
    this.tablexParam['isLoadingTable'] = true;
    this.requestSRAs();
    this.tablexParam['isLoadingTable'] = false;
  }


  // location functions
  getEquipmentCategoriesOptions(): any[] {

    const list = [];
    for (let key of JM.JMConnector.getAllEquipmentCategoryCode()) {
      let cat = JM.JMConnector.getEquipmentCategory(key);
      let item = {
        value: key,
        label: cat.code + ' - ' + cat.description[Session.selectedLanguage],
        typeList: cat.equipmentType || []
      };
      list.push(item);
    }
    list.sort((a, b) => {
      return (b.value > a.value) ? -1 : 1;
    });
    return list;
  }

  getEquipmentTypesOptions(): any[] {
    const list = [];
    for (let code of JM.JMConnector.getAllEquipmentTypeCode()) {
      let typeObj = JM.JMConnector.getEquipmentType(code);
      list.push({
        value: code,
        label: typeObj.code + ' - ' + typeObj.description[Session.selectedLanguage],
      });
    }
    list.sort(function (a, b) {
      return (b.value > a.value) ? -1 : 1;
    });
    return list;
  }

  getDistrictsOptions(): {}[] {
    let options = [];
    JM.JMConnector.getAllDistrictCode().map((code) => {
      options.push({
        label: JMLanguage.translate(`district.${code}`),
        value: code,
      });
    });
    return options;
  }

  onClosePanel(isSuccess: boolean): void {
    if (isSuccess) {
      this.requestSRAs();
      this.createSRAPanel.toggle();
      this.clearSRADetail = true;
    }
  }
}

class SRATableViewData {
  id: string;
  version: number;
  client: string;
  district: string;
  location: string;
  equipmentCategory: string;
  equipmentType: string;
  recipientEmailAddress: string[];
  ccTo: string[];
  remarks: string;
  firstReminderEmail: string;
  reminderEmailFrequency: string;
  snActionList: string[];
  updatedBy: string;
  updatedAt: string | moment.Moment;

  constructor(data: SRASummary, originalAPIDataMap: Map<string, any>, 
    equipmentCategoryList: any[], equipmentTypeList: any[]) {
    if (data.updatedAt) {
      this.updatedAt = moment(data.updatedAt).format('DD/MM/YYYY HH:mm');
    }
    if (data.district) {
      this.district = JMLanguage.translate(`district.${data.district}`);
    }
    if (data.location && data.id && originalAPIDataMap.has(data.id)) {
      const apiLocation = originalAPIDataMap.get(data.id)['location'];
      this.location = data.location;
      if (apiLocation && apiLocation['description'] && apiLocation['description'][JMLanguage.getCurrentLanguage()]) {
        this.location = `${apiLocation['description'][JMLanguage.getCurrentLanguage()]} (${this.location})` ;
      }
    }
    if (data.equipmentCategory) {
      if (equipmentCategoryList && isArray(equipmentCategoryList) && equipmentCategoryList.length === 1) {
        this.equipmentCategory = equipmentCategoryList[0].label;
      }
    }
    if (data.equipmentType) {
      if (equipmentTypeList && isArray(equipmentTypeList) && equipmentTypeList.length === 1) {
        this.equipmentType = equipmentTypeList[0].label;
      }
    }
    this.snActionList = [];
    if (data.snActionList) {
      if (isArray(data.snActionList)) {
        data.snActionList.forEach((action, i) => {
          this.snActionList.push(JMLanguage.translate('special-request-alert.snAction.' + action));
        }); 
      }
    }
    this.recipientEmailAddress = [];
    if (data.recipientEmailAddress && isArray(data.recipientEmailAddress)) {
      data.recipientEmailAddress.forEach(recipient => {
        this.recipientEmailAddress.push(recipient.type === "post" ? recipient.postName : recipient.email);
      });
    }
    this.ccTo = [];
    if (data.ccTo && isArray(data.ccTo)) {
      data.ccTo.forEach(recipient => {
        this.ccTo.push(recipient.type === "post" ? recipient.postName : recipient.email);
      });
    }
    this.id = data.id;
    this.version = data.version;
    this.client = data.client;
    this.updatedBy = data.updatedBy;
    this.remarks = data.remarks;
    this.firstReminderEmail = data.firstReminderEmail ? data.firstReminderEmail + "" : "---"; // suppose no 0
    this.reminderEmailFrequency = data.reminderEmailFrequency ? data.reminderEmailFrequency + "" : "---";  // suppose no 0
  }
}

export class SRASummary {
  id: string;
  version: number;
  client: any;
  district: string;
  location: string;
  equipmentCategory: string;
  equipmentType: string;
  recipientEmailAddress: any[];
  ccTo: any[];
  remarks: string;
  isReminderEmailEnabled: boolean;
  firstReminderEmail: number;
  firstReminderEmailUnit: string;
  reminderEmailFrequency: number;
  reminderEmailFrequencyUnit: string;
  snActionList: string[];
  lastUpdatedBy: string;
  lastUpdatedDateTime: string;
  createdAt: moment.Moment | Date;
  createdBy: string;
  updatedAt: moment.Moment | Date;
  updatedBy: string;
  constructor(data: any) {
    if (!data) {
      data = {};
    }
    this.id = data._id || null;
    this.version = data.version || null;
    this.client = (data.client && data.client.clientShortName) || null;
    this.district = (data.district !== null && data.district !== undefined) ? data.district : null;
    this.location = (data.location && data.location.code) || null;
    this.equipmentCategory = data.equipmentCategory || null;
    this.equipmentType = data.equipmentType || null;
    this.recipientEmailAddress = data.recipientList || null;
    this.ccTo = data.ccList || null;
    this.remarks = data.remarks || null;
    this.isReminderEmailEnabled = data.isReminderEmailEnabled;
    if (data.firstReminderEmailPeriod) {
      this.firstReminderEmail = data.firstReminderEmailPeriod.frequency || null;
      this.firstReminderEmailUnit = data.firstReminderEmailPeriod.unit || null;
    }
    if (data.reminderEmailFrequency) {
      this.reminderEmailFrequency = data.reminderEmailFrequency.frequency || null;
      this.reminderEmailFrequencyUnit = data.reminderEmailFrequency.unit || null;
    }
    this.lastUpdatedBy = data.lastUpdatedBy || null;
    this.lastUpdatedDateTime = data.lastUpdatedDateTime || null;
    this.snActionList = data.snActionList || null;
    this.createdAt = (data.createdAt && moment(data.createdAt)) || null;
    this.createdBy = data.createdBy || null;
    this.updatedAt = (data.updatedAt && moment(data.updatedAt)) || null;
    this.updatedBy = data.updatedBy || null;
  }
}
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
