import { Component } from '@angular/core';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Words } from 'src/wordDictionary';
import { PasswordError } from 'src/app/pages/user-profile/user-profile.component'
import { AuthorizationService } from '@services/authorization.service';
import { Session }  from 'src/app/services/session';
@Component({
  selector: 'app-password-error-list',
  templateUrl: './password-error-list.component.html',
  styleUrls: ['./password-error-list.component.scss']
})
export class PasswordErrorListComponent{
  readonly vaildPasswordLength = 6;
  readonly numberCharSequenceLength = 6;
  numberCharSequence:string[] = this.authorizationService.getNumCharSequence(this.numberCharSequenceLength);
  words = Words;
  passwordErrors:PasswordError[] = [
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.1")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.2")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.3")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.4")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.5")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.6")
    }
  ];
  constructor(
    private authorizationService: AuthorizationService,
  ) { }

  resetPasswordErrors(){
    for(let error of this.passwordErrors) {
      error.isError = false
    }
  }

  isPasswordVaildate = (password, userName = Session.userInfo.name) =>{
    let result = true
    

     if(!this.authorizationService.isPasswordLengthVaild(password, this.vaildPasswordLength)){
      this.passwordErrors[0].isError = true;
      result = false;
     }

     if(!this.authorizationService.isPasswordContainNumber(password)){
      this.passwordErrors[1].isError = true;
      result = false;
     }

     if(!this.authorizationService.isPasswordContainNonAlphanumericCharacters(password)){
      this.passwordErrors[2].isError = true;
      result = false;
     }

     if(this.authorizationService.isPasswordContainUserName(password,userName)){
      this.passwordErrors[3].isError = true;
      result = false;
     }

     if(password.length >= this.vaildPasswordLength){
      if(this.authorizationService.isPasswordContainSequence(password, this.numberCharSequence)){
        this.passwordErrors[4].isError = true;
        result = false;
       }
     }
     
     if(this.authorizationService.isPasswordContainSequence(password,this.words.filter(word => word.length <= password.length))){
      this.passwordErrors[5].isError = true;
      result = false;
     }

     return result
  }
}
