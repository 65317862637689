import { Component, Input, Output, OnInit, EventEmitter, Injector } from '@angular/core';
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { UISlaRow } from './UISlaRow'; 
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'add-sla-form',
  templateUrl: './add-sla-form.component.html',
  styleUrls: ['./add-sla-form.component.scss']
})

// const BATCH = 100;
// string: JMOBJ.MaintenanceTermContract
export class AddSlaFormComponent implements OnInit {
  @Input() parameters:{
    workCentreSlaArray: string[],
    slaDict: { [key:string]: JMOBJ.ServiceLevelAgreement },
    onSubmitButtonClicked: Function,

  } = {
    workCentreSlaArray: undefined,
    slaDict: {},
    onSubmitButtonClicked: undefined,
  };
  
  private searchTerms = new Subject<any[]>();

  uiEnableSubmitButton: boolean = true;
  uiNewSlaNumber: string = undefined;
  uiSlaArray: UISlaRow[] = undefined; // array of label and checked

  nextSlaArrayIndex = 0;

  constructor(){
  }

  ngOnInit() {
    this.searchTerms.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe((header) => {
      this.uiSlaArray = [];
      this.nextSlaArrayIndex = 0;
      if (this.uiNewSlaNumber && this.uiNewSlaNumber.length > 0) {
        this.updateView(Object.values(this.parameters.slaDict).length);        
      } else {
        this.updateView();
      }
    });
    this.clearView();
  }

  clearView() {
    this.enabletSubmitbutton(true);
    this.parameters.workCentreSlaArray = [];
    this.parameters.slaDict = {};
    this.uiNewSlaNumber = '';
    this.uiSlaArray = [];
    this.nextSlaArrayIndex = 0;
  }

  enabletSubmitbutton(enable:boolean) {
    this.uiEnableSubmitButton = enable;
  }

  // render all contract checkbox will be slow -> render 100 each
  // count is number of contract newly add to the array
  // ie show 'count' more rows 
  updateView(count?:number) {
    if (count == undefined || count == null) {
      count = 100;
    }
    // filter out the contracts which are already added to the work centre
    let initLength = this.nextSlaArrayIndex;
    let sla:JMOBJ.ServiceLevelAgreement;
    let slaValueArray = Object.values(this.parameters.slaDict);
    // this.uiSlaArray = [];
    for (let i=initLength; i<Math.min(slaValueArray.length, initLength + count); i++) {
      sla = slaValueArray[i];
      //console.log('looping sla:' + sla.agreementNumber)
      //console.log('workCentreSlaArray:' + this.parameters.workCentreSlaArray)
      let show = (this.parameters.workCentreSlaArray.includes(sla.agreementNumber) == false) &&
      ((this.uiNewSlaNumber && sla.agreementNumber.toLowerCase().includes(this.uiNewSlaNumber.toLowerCase())) 
      || (!this.uiNewSlaNumber || this.uiNewSlaNumber.length === 0));
      //console.log('show:' + show)
      if (show) {
        let type:string = '';
     
        this.uiSlaArray.push({
          key: sla.agreementNumber,
          label: sla.agreementNumber,
          checked: false,
        });
        this.nextSlaArrayIndex = i + 1;
      }
      //console.log('this.uiSlaArray:' + this.uiSlaArray)
    }
    
  }


  //-----------------------------------------------------------------------
  // button callback function
  onSubmitButtonClicked(event):void {
    this.enabletSubmitbutton(false);
    let newSlaNumberArray = []
    let row:UISlaRow;
    for (row of this.uiSlaArray) {
      if (row.checked) {
        newSlaNumberArray.push(row.key);
      }
    }
    
    if (this.parameters.onSubmitButtonClicked) {
      this.parameters.onSubmitButtonClicked(newSlaNumberArray);      
    }

  }

  onCheckBoxClicked(event, i, slaNumber:string) {
    // let slaRow:UISlaRow = this.uiSlaArray[i];
    // slaRow.checked = !slaRow.checked;
    // console.log("onCheckBoxClicked " + i + ' ' + slaRow.key + ' ' + slaRow.checked);
  }

  onNewSlaNumberKeyup(event) {
    this.searchTerms.next(event);
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    let buffer = 100;
    if (event.target.offsetHeight + event.target.scrollTop + buffer >= event.target.scrollHeight) {
      this.updateView(100); // get sla number by batch
    }
  } 
}