<!-- <ng-container *ngIf="currentPageMode==pageMode.VIEW"> -->
<div class="font-size-xl m-0 mb-2 px-2 d-flex align-items-center">
  <div>
    {{ "component.ha-information-detail.title" | translate}}
  </div>
  <div class="btn-container" *ngIf="hasHAOrderReplyPermission">
    <button class="standard brand-blue btn-sm" (click)="resendHAOrderReply()"  [disabled]="isResending">
        Resend
    </button>
    <button class="standard brand-blue btn-sm" (click)="regenerateHAOrderReply()" [disabled]="isRegenerating" >
        Regenerate
    </button>
  </div>
</div>
<hr class="underline" />
<div class="row align-items-center">
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.ha-work-order-no" | translate}}
  </div>
  <div class="col-4 m-0 text-left mb-4 overflow-auto"
    [ngClass]="{'empty-field':!haWorkOrderNumber,'font-size-s':haWorkOrderNumber,'font-size-xs':!haWorkOrderNumber }">
    <app-display-text-field id="ha-info-work-order-number" [textField]="haWorkOrderNumber" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.ha-asset-no" | translate}}
  </div>
  <div class="row col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <div class="m-0 p-0 col-12">
      <app-display-text-field id="ha-info-asset-number"
        [textField]="assetNumber" [emptyHandleDisplay]="emptyHandle">
      </app-display-text-field>
      <i class="fa fa-info-circle font-size-l blue ml-2 cursor-pointer" id="ha-info-details" (click)="onClickHADetails()"></i>
    </div>
    <div class="red m-0 p-0 col-12" *ngIf="haEquipmentContractExpired">{{"component.ha-information-detail.error-msg.contract-period-expired"| translate}}</div>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.hospital-code" | translate}}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-hospital-code" [textField]="hospitalCode">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.asset-description" | translate}}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-asset-description" [textField]="assetDescription">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.manufacturer" | translate}}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-manufacturer" [textField]="manufacturer">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.brand" | translate}}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-brandname" [textField]="brandName">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.model" | translate}}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-ha-model" [textField]="haModel">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.serial-number" | translate}}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-ha-serial-number" [textField]="serialNumber">
    </app-display-text-field>
  </div>
  
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.ha-contract-number" | translate}}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-ha-contract-number" [textField]="haContractNumber">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{"component.ha-information-detail.location-code" | translate}}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-location-code" [textField]="locationCode">
    </app-display-text-field>
  </div>

  <ng-container *ngIf="sn.maintenanceType == maintenanceType.CM">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{"component.ha-information-detail.cm-breakdown-time" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4" *ngIf="!editable.cmBreakdownDate">
      <app-display-text-field id="ha-info-breakdown-time" [textField]="cmBreakdownDate">
      </app-display-text-field>
    </div>

    <div *ngIf="editable.cmBreakdownDate" class="row col-10 align-items-center justify-content-end">
      <app-date-input class="col-8 mb-2" [isError]="errorFields.cmBreakdownDate" 
        [input]="dateInputSet['cmBreakdownDate']" [inputId]="'cmBreakdownDateInput'" [field]="'cmBreakdownDate'" 
        [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
      </app-date-input>
      <app-time-input class="col-4 mb-2" [isError]="errorFields.cmBreakdownDate" [customClass]="'form-control'" 
        [input]="timeInputSet['cmBreakdownDate']" [inputId]="'cmBreakdownTimeInput'" [field]="'cmBreakdownDate'" 
        [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
      </app-time-input>
    </div>
  </ng-container>

  <ng-container *ngIf="sn.maintenanceType == maintenanceType.PM">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{"component.ha-information-detail.pm-schedule-time" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4" *ngIf="!editable.pmScheduleDate">
      <app-display-text-field id="ha-info-pm-schedule-time" [textField]="pmScheduleDate">
      </app-display-text-field>
    </div>

    <div *ngIf="editable.pmScheduleDate" class="row col-10 align-items-center justify-content-end">
      <app-date-input class="col-8 mb-2" [isError]="errorFields.pmScheduleDate" 
        [input]="dateInputSet['pmScheduleDate']" [inputId]="'pmScheduleDateInput'" [field]="'pmScheduleDate'" 
        [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
      </app-date-input>
      <app-time-input class="col-4 mb-2" [isError]="errorFields.pmScheduleDate" [customClass]="'form-control'" 
        [input]="timeInputSet['pmScheduleDate']" [inputId]="'pmScheduleTimeInput'" [field]="'pmScheduleDate'" 
        [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
      </app-time-input>
    </div>

    <!-- <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{"component.ha-information-detail.reply-to-ha" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4">
      <app-display-text-field id="ha-info-reply-ha" [textField]="replyToHA ? textYes : textNo">
      </app-display-text-field>
    </div> -->
  </ng-container>

</div>
<!-- </ng-container> -->
