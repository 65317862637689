<div class="font-size-xl m-0 mb-2">
    <div class="overflow-hidden">
      <div class="d-inline-block">
        {{ "component.equipment-information.title" | translate}} 
      </div>
    </div>
</div>

<hr class="underline mb-0" />

<div class="row mx-0 align-items-center position-relative">
    <div class="col-12 row mx-0 px-0 align-items-center ">
        <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
            <span class="mandatory-mark">*</span>
            {{ "component.plan-particulars.number-of-equipment" | translate }}
        </div>
        <div class="col-6 col-sm-6 col-xl-4 text-left font-size-s m-0 mt-4 mb-4 overflow-auto">
            <app-display-text-field id="pm-job-description-pm-plan" 
                [textField]="equipmentText()">
            </app-display-text-field>
            <a 
                *ngIf="this.pageMode !== JMPageMode.CREATE"
                href="" 
                (click)="onDetailBtnClick($event)"> ({{ "component.pm-job-equipment-information.detail" | translate }})
            </a>
        </div>
    </div>
</div>
