<div class="col-md selection-box">
  <div class="input-border">
    <div class="d-flex justify-content-between align-items-center my-1 mr-1 ml-3 ">
      <div>
        <i class="fas fa-stopwatch ccc"></i>
        {{"component.response-time-table.title" | translate}}
      </div>
      <div>
        <button class="rectangle brand-amber"
          *ngIf="responseTimeParam.hasEditionPermission && !responseTimeParam.isEditMode"
          (click)="responseTimeParam.onEditButtonClicked()" id="{{settings-team_office-hour}}-edit_button">
          <i class="fas space fa-pen"></i>
          <span>{{ "global.edit" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="input-border">

    <table class="table table-condensed table-bordered table-striped">
      <tr class="table-header ">
        <th *ngFor="let header of responseTimeParam.headers; let i=index" class="align-middle {{header.class}}">
          <div class="text-center">
            <span>{{header.name | translate}}</span>
          </div>
        </th>
      </tr>

      <ng-container *ngFor="let content of responseTimeParam.content; let i=index">
        <tr *ngIf="!responseTimeParam.isLoadingTable">
          <td *ngFor="let header of responseTimeParam.headers; let j=index" class="align-middle">

            <!-- text -->
            <div *ngIf="header.type=='text'" class="text-center">
              {{content[j] | translate}}
            </div>

            <!-- input -->
            <input 
              *ngIf="header.type=='input'" 
              id="{{responseTimeParam.componentId}}_table_r{{j}}_{{content[j].timeType}}"
              type="text" 
              class="text-center" 
              maxlength="5" 
              [readOnly]="!responseTimeParam.isEditMode"
              [ngClass]="{'active': responseTimeParam.isEditMode}"
              [(ngModel)]="content[j].value"
              (blur)="checkValidation($event)" 
              (keyup)="inputNumber($event)">

          </td>
        </tr>
      </ng-container>
    </table>

    <div *ngIf="responseTimeParam.isLoadingTable">
      <div class="text-center m-5">
        <div class="spinner-border mt-5" role="status">
          <span class="sr-only">{{"global.loading" | translate}}</span>
        </div>
      </div>
    </div>
    <div
      *ngIf="!responseTimeParam.isLoadingTable && (responseTimeParam.content == null || responseTimeParam.content.length == 0)"
      class="no-record-label">
      {{"global.no-record-found" | translate}}
    </div>

  </div>
</div>