<!-- temp permission of currentPageMode==pageMode.EDIT -->
<!-- Please remove when sn-edit handle draft and reroute case -->
<ng-container *ngIf="currentPageMode==pageMode.VIEW || currentPageMode==pageMode.EDIT">
    <div class="font-size-xl m-0 mb-2 px-2">
        {{ 'component.routing-detail.title' | translate}}
    </div>
    <hr class="underline" />
    <div class="row">
        <div class="col-8">
            <div class="row align-items-center">
                <div class="col-3 text-right gray-title m-0 mb-4 px-0 pl-1 px-0">

                    {{"component.routing-detail.maintenance-team" | translate}}</div>
                <div class="col-9 text-left font-size-s m-0 mb-4 overflow-auto">
                    <app-display-text-field id="routing-detail-maintenance-team" [textField]="team" [emptyHandleDisplay]="emptyHandle"></app-display-text-field>
                    <i class="expired-icon fas fa-exclamation-circle ml-2" id="routing-detail-team-contract-expired"
                        *ngIf="sn?.team && sn?.team?.status == teamStatus.CONTRACT_EXPIRED"
                        title="{{'pages.sn.tooltip.team-contract-expired'|translate}}"></i>
                    <i class="expired-icon fas fa-exclamation-circle ml-2" id="routing-detail-team-contract-ineffective"
                        *ngIf="sn?.team && sn?.team?.status == teamStatus.CONTRACT_INEFFECTIVE"
                        title="{{'pages.sn.tooltip.team-contract-ineffective'|translate}}"></i>
                </div>

                <div class="col-3 text-right gray-title m-0 mb-4 px-0 pl-1 px-0">
                    {{"component.routing-detail.tag" |translate}}
                </div>
                <div class="col-9 text-left font-size-s m-0 mb-4 overflow-auto">
                    <app-display-text-field id="routing-detail-tag" [textField]="hashTagName" [emptyHandleDisplay]="emptyHandle"></app-display-text-field>
                </div>
                
                <ng-container *ngIf="sn.team.contractNumber">
                    <div class="col-3 text-right gray-title m-0 mb-4 px-0 pl-1 px-0">
                        {{"component.routing-detail.contractor" |translate}}
                    </div>
                    <div class="col-9 m-0 mb-4">
                        <app-display-text-field id="routing-detail-contractor" [textField]="contractor"></app-display-text-field>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="col-4">
            <div class="row align-items-center">
                <ng-container *ngIf="contactSequences">
                    <div class="col-7 text-right gray-title m-0 mb-4 px-0 pl-1 px-0">
                        {{"component.routing-detail.contact" |translate}}
                    </div>
                    <div class="col-5 m-0 mb-4">
                        <i class="fas fa-info-circle font-size-l blue cursor-pointer" id="routing-detail-more-info" (click)="onInformationButtonClicked()"></i>
                    </div>
                </ng-container>
        
                <ng-container *ngIf="manualInstructions">
                    <div class="col-7 text-right gray-title m-0 mb-4 px-0 pl-1 px-0">
                        {{"component.routing-detail.manual-instruction" | translate}}
                    </div>
                    <div class="col-5 m-0 mb-4">
                        <i class="fa fa-file-text font-size-l amber cursor-pointer" id="icon-manual-instructions" (click)="onManualInstructionButtonClicked()"></i>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<custom-slider-panel #manual_instruction_detail [widthPx]=400>
    <app-manual-instruction-detail-panel [manualInstructionDetail]="manualInstructionDetailData">
    </app-manual-instruction-detail-panel>
</custom-slider-panel>