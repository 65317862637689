<div class="row justify-content-between m-0">
  <h3 class="d-inline">{{ 'pages.sn.slider-panel.patch-ccs-so-number' | translate }}</h3>
  <div class="submit-wrap d-inline">
    <app-submit-button 
      [id]="'patch-ccs-so-number-form_submit_button'"
      [text]="'global.submit'"
      [buttonClass]="'standard brand-amber'"
      [iconClass]="'fa-check'"
      [isDisabled]="isLoading || ccsSoNumber.length === 0"
      [isLoading]="isLoading"
      (onClick)="onClickSubmit()" 
    ></app-submit-button>
  </div>
</div>

<div class="form-wrap">
  <div class="mt-3">
    <input
      type="text" 
      id="ccs-so-number_inputbox"
      class="form-control " 
      name="ccsSoNumber"
      maxlength="10"
      placeholder="{{'pages.sn.slider-panel.ccs-so-number-placeholder' | translate}}"
      (blur)="onCcsSoNumberInputboxKeyup($event);"
      (keyup)="onCcsSoNumberInputboxKeyup($event);"
      [(ngModel)]="ccsSoNumber"
      />
  </div>
  <div class="mt-3">
    <span class="red">*</span> {{'pages.sn.mandatory-fields' | translate}}
  </div>
</div>

