<div class="wrapper">
  <app-nav [navbarTitle]="'pages.user-management-role.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'default'"></app-sidebar>

  <div id="content">
    <div class="row">
      <div class="col-md-3">
          <div class="row">
            <div class="col-md selection-box">
              <div class="input-border">
                <div class="d-flex justify-content-between align-items-center my-1 mr-1 ml-3 ">
                  <div>
                  <i class="fas fa-user-friends ccc"></i>
                  {{'pages.admin.role.role' | translate}}
                </div>
                <!-- <div *ngIf="hasEditionPermission && !isLoading">
                  <button type="button" class="rectangle brand-blue" (click)="add()">
                    <i class="fas fa-plus"></i>
                  </button>
                </div> -->
              </div>
            </div>
            <div class="input-border" style="min-height: 600px;">
              <ng-container *ngIf="isLoadingAllRoles; else allRoleList">
                <div class="d-flex align-items-center justify-content-center" style="min-height: 600px;">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </ng-container>
              <ng-template #allRoleList>
                <ul class="list-group" *ngFor="let role of roles; let i = index">
                  <li class="list-group-item d-flex justify-content-between align-items-center active"
                    [ngClass]="{ 'active': role.roleId == selectedRole.roleId }"
                    (click)="onRoleChanged(i)">
                    <span> {{ role.name | translate}} </span>
                    <!-- <span class="badge badge-pill">{{ role.count }}</span> -->
                  </li>
                </ul>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <ng-container *ngIf="isLoadingAllPermissions || isLoadingAllRoles; else contentDetail">
          <div class="row mt-5">
            <div class="col d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #contentDetail>
          
          <div class="row">
            <div class="col-md">
              <h2 class="font-size-l">{{'pages.admin.role.given-permission' | translate}}</h2>
              <div cdkDropList #givenPermissionList="cdkDropList" class="permission-list"
                [cdkDropListDisabled]="!hasEditionPermission"
                [cdkDropListData]="givenPermission"
                [cdkDropListConnectedTo]="[availablePermissionList]" 
                (cdkDropListDropped)="drop($event)">
                <div class="permission-box" *ngFor="let item of givenPermission" cdkDrag>{{item}}</div>
              </div>
            </div>
  
            <div class="col-md">
              <h2 class="font-size-l">{{'pages.admin.role.available-permission' | translate}}</h2>
              <div cdkDropList #availablePermissionList="cdkDropList" class="permission-list"
                [cdkDropListDisabled]="!hasEditionPermission"
                [cdkDropListData]="availablePermission"
                [cdkDropListConnectedTo]="[givenPermissionList]"
                (cdkDropListDropped)="drop($event)">
                <div class="permission-box" *ngFor="let item of availablePermission" cdkDrag>{{item}}</div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="hasEditionPermission">
            <div class="row justify-content-center row-space">
              <app-submit-button 
                [id]="'settings-work-centre_save_button'"
                [text]="'global.save'"
                [buttonClass]="'standard brand-amber'"
                [iconClass]="'fa-save'"
                [isDisabled]="isDisabledSaveBtn"
                [isLoading]="isSaving"
                (onClick)="onSaveBtnClicked()" 
              ></app-submit-button>
            </div>
          </ng-container>

        </ng-template>
      </div>
    </div>
  </div>
</div>