import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AppDelegate } from 'src/app/AppDelegate';
import { JM, JMENUM, JMUTILITY, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AttachmentMimeType } from '@enum/attachment-file-type';
import { blobToBinary, fileType, splitAllPdfs, splitPdfWithSelectedPages } from '@services/utility';
import { TablexComponentParams } from 'src/app/ui/components/tablex/tablex.component';
import { isEnabledFeature } from 'src/app/services/utility';
import { Router } from '@angular/router';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from '@enum/tablexColumnType';

@Component({
    selector: 'app-sn-batch-submission',
    templateUrl: './sn-batch-report-submission.component.html',
    styleUrls: ['./sn-batch-report-submission.component.scss']
})

export class SnBatchReportSubmissionComponent implements OnInit {
    @Input() uploadIsLoading: boolean
    @Input() isPreviewerLoading: boolean
    @Input() submitHasError: boolean  
    @ViewChild('attachment_preview', { static: false }) attachmentPreview;
    stage: SnBatchReportSubmissionComponentStages = SnBatchReportSubmissionComponentStages.UPLOAD;
    batchSubmissionRecords: any = [];
    batchSignedOffId: string;
    pageSizeOptions = [10, 25, 50];
    currentPageSize = 10;
    currentPage = 1;
    pageCount = 0;
    uploadFile?: File;
    selectedPdf: any
    splitedPdfs?: any = [];
    ocrResultTablexParam: TablexComponentParams;
    submissionResultTablexParam: TablexComponentParams;
    file: any;
    isDisableUploadBtn: boolean
    constructor(private router: Router){}

    ngOnInit() {
      this.checkVisitable();
      this.initOcrResultTable();
      
       const allowPermissions = [JMENUM.Permission.HA_BATCH_SERVICE_REPORT_UPLOAD];
        const hasPermission = JMUTILITY.hasPermissions(Session.userInfo, allowPermissions, false);
        if (!hasPermission) {
          AppDelegate.navigate(['/']);
          AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
          return;
        }
    }

    checkVisitable(){

      const isVisitable = isEnabledFeature(Session, JMCONSTANT.JMFeature.JM_HSD_SERVICE_INTEGRATION)? true : false;

      if(isVisitable){
        this.router.navigate(['/dashboard']);
        return;
      }

    }

    initOcrResultTable(){
      this.ocrResultTablexParam = {
        pageSizeOptions: this.pageSizeOptions,
        currentPageSize: this.currentPageSize,
        currentPage: this.currentPage,
        pageCount: this.pageCount,
        enableSetPageSize: true,
        enablePagination: true,
        enableSort: false,
        enableColFilter: false,
        enableSelectedRowCount: false,
        isLoadingTable: false,
        content: [],
        highlightedRows: [],
        onRowClicked: this.onRowClicked,
        onPageNumberClicked: this.onPageNumberClicked,
        onPageSizeClicked: this.onPageSizeClicked,
        
      }
      this.ocrResultTablexParam.headers = ocrResultTableHeader;
    }

    // ----------- UI function ----------- //
    private setPreviewContent() {
      if(!this.selectedPdf && this.splitedPdfs.length){
        this.selectedPdf = this.splitedPdfs[0]
      }
      this.file = new Blob([this.selectedPdf], {type: AttachmentMimeType.Pdf})
      this.isPreviewerLoading = false
    }

    renderBatchSignedOffResultTable(batchSignedOffResult){
      this.batchSignedOffId = batchSignedOffResult.batchSignedOffResultId
    }

    renderOcrResultTable(){
      this.ocrResultTablexParam.totalRowCount = this.batchSubmissionRecords.length
      this.ocrResultTablexParam.pageCount = Math.ceil(this.batchSubmissionRecords.length / this.ocrResultTablexParam.currentPageSize)
      if(this.ocrResultTablexParam.pageCount < this.ocrResultTablexParam['currentPage']){
        this.ocrResultTablexParam['currentPage'] = this.ocrResultTablexParam.pageCount
      }
      let currentPage = this.ocrResultTablexParam['currentPage']
      for (let i = 0; i < this.batchSubmissionRecords.length; i++){
        // backend error Translation [CAPTURE_FAILURE, NO_MATCHING_SN, UNSUPPORTED_SN_STATUS]
        this.updateBatchErrorMessage(i)

        // handle duplicate HA Work Order number in the same submission batch
        this.updateDuplicationErrorMessage(i)
      }
   
      let arraysOfPaginateData = this.paginateData()
      // loop through all records to generate tablex content
      this.ocrResultTablexParam.content = arraysOfPaginateData.length ? arraysOfPaginateData[currentPage - 1].map((ocr) => this.generateTablexContent(ocr) ) : []
    }

    paginateData(){
      let arraysOfPaginateData = []
      for (let i = 0; i< this.batchSubmissionRecords.length; i+=this.currentPageSize){
        arraysOfPaginateData.push(this.batchSubmissionRecords.slice(i, i + this.currentPageSize))
      }
      return arraysOfPaginateData
    }
    updateBatchErrorMessage(rowIdx){
      // backend error Translation [CAPTURE_FAILURE, NO_MATCHING_SN, UNSUPPORTED_SN_STATUS]
      for (let e = 0; e < this.batchSubmissionRecords[rowIdx].error.length; e++){
        let currentError = this.batchSubmissionRecords[rowIdx].error[e]
        switch(currentError){
          case JMENUM.SnBatchSubmissionErrorCode.CAPTURE_FAILURE:
            this.batchSubmissionRecords[rowIdx].error[e] = JMLanguage.translate(JMENUM.SnBatchSubmissionErrorCode.CAPTURE_FAILURE)
            break;
          case JMENUM.SnBatchSubmissionErrorCode.NO_MATCHING_SN:
            this.batchSubmissionRecords[rowIdx].error[e] = JMLanguage.translate(JMENUM.SnBatchSubmissionErrorCode.NO_MATCHING_SN) + this.batchSubmissionRecords[rowIdx].HAWorkOrderNumber
            break;
          case JMENUM.SnBatchSubmissionErrorCode.UNSUPPORTED_SN_STATUS:
            this.batchSubmissionRecords[rowIdx].error[e] = JMLanguage.translate(JMENUM.SnBatchSubmissionErrorCode.UNSUPPORTED_SN_STATUS)
            break;
         case JMENUM.SnBatchSubmissionErrorCode.MISSING_WORKCENTER_PERMISSION:
            this.batchSubmissionRecords[rowIdx].error[e] = JMLanguage.translate(JMENUM.SnBatchSubmissionErrorCode.MISSING_WORKCENTER_PERMISSION)
            break;
        }
      }
    }

    updateDuplicationErrorMessage(rowIdx) {
  
      const record = this.batchSubmissionRecords[rowIdx]
  
      if (record.HAWorkOrderNumber) {
  
        //check any duplicated records with same HAWorkOrderNumber in same page
        let duplicatedRecords = this.batchSubmissionRecords.find(e => e.HAWorkOrderNumber === record.HAWorkOrderNumber && e.page !== record.page)
  
        if (duplicatedRecords) {
  
          //find duplicated and add new error message
          if (!record.error.includes(JMLanguage.translate(JMENUM.SnBatchSubmissionErrorCode.DUPLICATE_SUBMISSION))) {
            record.error.push(JMLanguage.translate(JMENUM.SnBatchSubmissionErrorCode.DUPLICATE_SUBMISSION))
          }
        } else {
          //clear duplicated error message             
          record.error = record.error.filter(e => e !== JMLanguage.translate(JMENUM.SnBatchSubmissionErrorCode.DUPLICATE_SUBMISSION))
        }
      }
    }
    renderOcrRowResult(rowIdx, page){
      this.updateBatchErrorMessage(rowIdx)
      this.updateDuplicationErrorMessage(rowIdx)
      let arraysOfPaginateData = this.paginateData()
      let updateIdx = arraysOfPaginateData[this.ocrResultTablexParam['currentPage'] - 1].findIndex(f=>f.page === page);
      this.ocrResultTablexParam.content[updateIdx] = this.generateTablexContent(arraysOfPaginateData[this.ocrResultTablexParam['currentPage'] - 1][updateIdx])
      this.validateOcrReading()
    }

    generateTablexContent(ocr){
        const _id = ocr[OcrResultParams.page]
        const page = ocr[OcrResultParams.page]
        const haWorkOrderNumber = {
          "id": `wo-${ocr[OcrResultParams.haWorkOrderNumber]}`,
          "class": '',
          "type": 'string',
          "placeholder": '',
          "value": ocr[OcrResultParams.haWorkOrderNumber],
          "enable": true,
          "maxlength": 20,
          "onChange": this.onWorkOrderNumberInputChangeHandler.bind(this),
          "onFocusout" : this.onWorkOrderNumberInputFocusoutHandler.bind(this),
        }
        const snNumber = [
          `<a href="/sn/view/${ocr[OcrResultParams.snNumber]}" target="_blank">${ocr[OcrResultParams.snNumber]}</a>`
        ]
        const error = ocr[OcrResultParams.error].length === 0 ? '' : '<div class="error-list">' + ocr[OcrResultParams.error].map(e=> `<div class="error-item">${e}</div>`).join('') + '</div>'
        const buttons = [{
          "id" : _id,
          "name" : "",
          "class" : "delete-button btn",
          "icon": "fas fa-times",
          "onClicked": this.onOcrResultDeleteButtonClicked.bind(this),
        }];

        return [_id, page, haWorkOrderNumber, snNumber, error, buttons]

    }

    // tablex function
    onPageSizeClicked = (pageSize) => {
      this.ocrResultTablexParam['currentPage'] = 1;
      this.currentPageSize = pageSize
      this.renderOcrResultTable()
      this.validateOcrReading()
    }
    
    onRowClicked = (index: number, row: any) => {
        this.isPreviewerLoading = true
        let pdfPreviewIdx = this.batchSubmissionRecords.map(p => p.page).indexOf(row[1])
        this.selectedPdf = this.splitedPdfs[pdfPreviewIdx]
        this.setPreviewContent()
    }

    onPageNumberClicked = (pageIndex: number) => {
      this.ocrResultTablexParam['currentPage'] = pageIndex;
      this.renderOcrResultTable()
      this.validateOcrReading()
    }

    onOcrResultDeleteButtonClicked(button){
      if(button.id){
        let deleteItemIdx = this.batchSubmissionRecords.map(p => p.page).indexOf(button.id)
        if(deleteItemIdx !== -1){
          this.batchSubmissionRecords.splice(deleteItemIdx, 1)
          this.splitedPdfs.splice(deleteItemIdx, 1)
          this.renderOcrResultTable()
          this.validateOcrReading()
        }
      }
       //If user deleted all record, disable submit button
       if (this.batchSubmissionRecords.length == 0) {
        this.submitHasError = true;
      }

    }

    onClickedUploadButton() {
      if (this.validateFile()) {   
        // check is ocrResult return data
        this.uploadIsLoading = true
        this.requestSnHaServiceReportOcrValidationByAttachment()
        //disable cancel button next to file name
        this.isDisableUploadBtn = true
      }
    }

    async onClickedSubmitButton() {
      if(this.batchSubmissionRecords.length){
        let remainPages = this.batchSubmissionRecords.map(r => r.page)
        let selectedPdfs = await this.splitByPages(this.batchSubmissionRecords.map(r => r.page))
        let batchSignedOffRecords = []
        let uploadPromise = []
        
        for (let i = 0; i < remainPages.length; i++){
          uploadPromise.push(new Promise( async (resolve) => {
            let request: JM.JMRequestFilesUploadFile = new JM.JMRequestFilesUploadFile()
            let uploadData = new FormData();
            let blob = new Blob([selectedPdfs[i]], {type: "application/pdf"});
            uploadData.append('file',blob, this.batchSubmissionRecords[i].snNumber + '.pdf') 
            const response : JM.JMResponseFilesUploadFile = await AppDelegate.sendJMFileRequestWithFileHost(request, uploadData)
            if (!response || response.code !== 200 || !response.payload) {
              batchSignedOffRecords.push({
                page: this.batchSubmissionRecords[i].page,
                snNumber: this.batchSubmissionRecords[i].snNumber,
                HAWorkOrderNumber:this.batchSubmissionRecords[i].HAWorkOrderNumber,
                error: response.error
              });
              return;
            } else {
              batchSignedOffRecords.push({
                page: this.batchSubmissionRecords[i].page,
                snNumber: this.batchSubmissionRecords[i].snNumber,
                HAWorkOrderNumber: this.batchSubmissionRecords[i].HAWorkOrderNumber,
                attachmentId: response.payload[0].attachmentId,
              })
            }
            resolve(response)
          }))
        }
        await Promise.all(uploadPromise)
        
        let request: JM.JMRequestCreateBatchSignedOffResult = new JM.JMRequestCreateBatchSignedOffResult();
        request.batchSubmission = batchSignedOffRecords
        const response : JM.JMResponse = await AppDelegate.sendJMRequest(request)
        if (!response || response.code !== 200 || !response.payload || response.payload.length < 1) {
          AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-report-submission.message.error-signedOff'))
        }
        else {
          this.switchStage(SnBatchReportSubmissionComponentStages.RESULT)
          this.renderBatchSignedOffResultTable(response.payload[0])
        }
      }
    }

    onUpdatedFile(file?: File){
        this.uploadFile = file;
        this.split()
    }

    private async requestSnHaServiceReportOcrValidationByAttachment(){
      if(this.uploadFile){
         let request: JM.JMRequestSnHaServiceReportOcrValidationByAttachment = new JM.JMRequestSnHaServiceReportOcrValidationByAttachment()
            let formData = new FormData();
            formData.append('file', this.uploadFile, this.uploadFile.name);

            const response: JM.JMResponse = await AppDelegate.sendJMRequestFile(request, formData);
            if( !response || !response.code || response.code != 200 || !response.payload ) {
                AppDelegate.openErrorBar(response);
                this.uploadIsLoading = false
                return;
            }
            this.batchSubmissionRecords = response.payload
            this.renderOcrResultTable()
            this.validateOcrReading()
            this.switchStage(SnBatchReportSubmissionComponentStages.VERIFICATION)
            this.setPreviewContent()
            this.uploadIsLoading = false
            return

        }
    }
    private async requestSnHaServiceReportOcrValidationBySn(rowData){
        let request: JM.JMRequestSnHaServiceReportOcrValidationBySn = new JM.JMRequestSnHaServiceReportOcrValidationBySn();
            request.ocrData = [rowData]
            const response: JM.JMResponse = await AppDelegate.sendJMRequest(request);
            if( !response || !response.code || response.code != 200 || !response.payload ) {
                AppDelegate.openErrorBar(response);
                return;
            }
            if(response.payload){
                this.updateBatchSubmissionRecords(response.payload)
            }
            return response
    }

    updateBatchSubmissionRecords(ocrData){
      if(ocrData.length === 1){
        let updateRow = ocrData[0]
        // find this.batchSubmissionRecords by page 
        let updateIdx = this.batchSubmissionRecords.findIndex(f=>f.page === updateRow.page);
        this.batchSubmissionRecords[updateIdx] = {
          page: updateRow.page,
          HAWorkOrderNumber: updateRow.HAWorkOrderNumber,
          snNumber: updateRow.snNumber,
          error: updateRow.error
        }
        return this.renderOcrRowResult(updateIdx, updateRow.page)
      }
    }
    
    private validateFile(){
      if (!this.uploadFile) {
        AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-complete.message.file-not-found'));
        return false;
      }
      
      // check file TablexColumnType.Texte
      if (this.uploadFile.type !== AttachmentMimeType.Pdf){
        AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-report-submission.message.upload-pdf-file'));
        return false;
      }
      
          // check file size
          const fileSizeMB = this.uploadFile.size / 1024 / 1024; // B to MB
          if (fileSizeMB >= 10) { // cannot larger than 10 MB
            AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-report-submission.message.file-size-too-large'));
            return false;
          }
      
          return true;
    }

    validateOcrReading(){
      // global error 
      if(this.batchSubmissionRecords.map(o=>o.error).filter(f=>f.length).length > 0){
          this.submitHasError = true
          AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-report-submission.message.error-remains'))
      } else {
          this.submitHasError = false
      }
    }

    async onWorkOrderNumberInputChangeHandler(event, row){
      const rowPage = row[1]
      const rowHAWorkOrderNumber = row[2].value
      const rowSnNumber = row[3].value
      const rowError = row[4].text 

      const validationPayload = {
        page: rowPage,
        HAWorkOrderNumber: rowHAWorkOrderNumber,
        snNumber: rowSnNumber,
        error: rowError
      }
      return await this.requestSnHaServiceReportOcrValidationBySn(validationPayload)
    }

    async onWorkOrderNumberInputFocusoutHandler(event, row){
      const rowPage = row[1]
      const rowHAWorkOrderNumber = row[2].value
      const rowSnNumber = row[3].value
      const rowError = row[4].text 

      const validationPayload = {
        page: rowPage,
        HAWorkOrderNumber: rowHAWorkOrderNumber,
        snNumber: rowSnNumber,
        error: rowError
      }
       await this.requestSnHaServiceReportOcrValidationBySn(validationPayload)
    }
        
    switchStage(targetStage: SnBatchReportSubmissionComponentStages){
          this.stage = targetStage
    }

    private async split(){
      if(this.uploadFile){
        const pdf = this.uploadFile
        const pdfBytes = await blobToBinary(pdf, fileType.arrayBuffer)
        this.splitedPdfs = await splitAllPdfs(pdfBytes)
      }
    }

    private async splitByPages(pages){
      if(this.uploadFile){
        const pdfBytes = await blobToBinary(this.uploadFile, fileType.arrayBuffer)
        return await splitPdfWithSelectedPages(pdfBytes, pages)
      }
    }

    get isStageUpload(): boolean {
      return this.stage === SnBatchReportSubmissionComponentStages.UPLOAD
    }
    get isStageVerification(): boolean {
      return this.stage === SnBatchReportSubmissionComponentStages.VERIFICATION
    }
    get isStageResult(): boolean {
      return this.stage === SnBatchReportSubmissionComponentStages.RESULT
    }

    get title() : string {
      return JMLanguage.translate('component.sn-batch-report-submission.main.title')
    }

    get uploadTitle() : string {
      if (this.isStageUpload) {
        return JMLanguage.translate('component.sn-batch-report-submission.main.upload-instruction')
      }
    }

}

enum OcrResultParams {
  _id = '_id',
  page = 'page',
  haWorkOrderNumber = 'HAWorkOrderNumber',
  snNumber = 'snNumber',
  error = "error",

} 

const ocrResultTableHeader = [
  {
    id: OcrResultParams._id,
    name: '_id',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'd-none',
  },
  {
    id: OcrResultParams.page,
    name: 'pages.sn-batch-report-submission.page',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: OcrResultParams.haWorkOrderNumber,
    name: 'pages.sn-batch-report-submission.ha-work-order-number',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Input,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: OcrResultParams.snNumber,
    name: 'pages.sn-batch-report-submission.sn-number',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Html,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: OcrResultParams.error,
    name: 'pages.sn-batch-report-submission.error',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Html,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: 'action',
    name: 'pages.sn-batch-report-submission.action',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Buttons,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
]

export enum SnBatchReportSubmissionComponentStages {
  UPLOAD,
  VERIFICATION,
  RESULT,
}



