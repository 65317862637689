<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'workCenter'"></app-sidebar>

  <div id="content" >
    <div class="content-wrap">
      <div>
        <div class="row my-4 ">
          <div class="row mx-0 col-md-6 services-coverage-wrap align-content-start">
            <div class="col-12 px-0 services-coverage-title">
              <h5 class="font-size-m mt-3 mb-0">{{ "pages.routing-rules.services_coverage" | translate }}</h5>
            </div>
            <div id="services-coverage-box" class="col-12 drop-box services-coverage-box p-1" 
              (drop)="onDropServiceCoverage($event)" (dragover)="allowDrop($event)"
            >
              <div class="drag-item d-inline-block text-break p-1 m-1" *ngFor="let service of servicesCoverageList; let i = index" 
                [attr.draggable]="hasEditionPermission" data-box="service" [attr.data-index]="i" id="tag-item_{{service.displayName}}"
                (dragstart)="onDragStartTag($event)"  
              >
                {{ service.displayName }}
              </div>
            </div>
          </div>

          <div class="row mx-0 col-md-6 available-equipment-tags-wrap align-content-start">
            <div class="col-12 mb-2 px-0 d-flex justify-content-between align-items-end">
              <h5 class="font-size-m mb-0">{{ "pages.routing-rules.available_equipment_tags" | translate }}</h5>

              <div class="col-auth float-right">
                <span class="pr-1">
                  <button
                    *ngIf="hasEditionPermission"  
                    id="saveRoutingRulesButton"
                    type="button"
                    class="standard brand-amber"
                    (click)="onSaveRoutingRuleClicked()"
                    [disabled]="isSavingRoutingRule"
                  >
                    <span *ngIf="isSavingRoutingRule">
                      <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                      {{'global.loading' | translate}}
                    </span>
                    <span *ngIf="!isSavingRoutingRule">
                      <i class="fas fa-save space" placement="bottom" ></i>
                      {{"pages.routing-rules.save"|translate}}
                      </span>
                  </button>
                </span>
                <span class="pl-1">
                  <button
                    id="closeRoutingRulesButton"
                    type="button"
                    class="standard brand-amber"
                    (click)="onCloseRoutingRuleClicked()"
                  >
                    {{"pages.routing-rules.close"|translate}}
                  </button>
                </span>
              </div>
            </div>

            <div id="equipment-tag-box" class="col-12 drop-box equipment-tag-box p-1" (drop)="onDropEquipmentTag($event)" (dragover)="allowDrop($event)">
              <div *ngFor="let tag of uiAvailableTagList; let i = index" 
                id="tag-item_{{tag.displayName}}"
                class="drag-item d-inline-block position-relative text-break p-1 m-1"
                data-box="tag"
                [attr.draggable]="hasEditionPermission"
                [attr.data-index]="i"
                (dragstart)="onDragStartTag($event)"
              >{{ tag.displayName }}
                <i class="amber-icon fas fa-exclamation-circle mx-2" 
                  *ngIf="tag.isUsedTagOtherServiceCoverage" 
                  [ngbTooltip]="equipTagUsedOtherServiceCoverageToolTip"
                  tooltipClass="amber"
                  placement="bottom">
                </i>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-4 ">
          <div class="row mx-0 px-0 col-md-6 mb-3 handling-teams-wrap align-content-start">
            <div class="col-12">
              <h5 class="font-size-m">{{ "pages.routing-rules.handling-teams-cm" | translate }}</h5>
            </div>

            <div class="col-4">
              <div class="priority-label non-urgent-label mx-0 d-flex align-items-center justify-content-center">
                <span>{{"priority.non-urgent" | translate}}</span>
              </div>
              <div id="non-urgent-box"  class="drop-box team-box p-1" (drop)="onDropTeam($event, priority.NonUrgent)" (dragover)="allowDrop($event)">
                <div *ngFor="let team of uiHandlingTeamList[priority.NonUrgent]; let i = index" 
                  (dragenter)="onDragEnterTeamIndex(priority.NonUrgent, i)"
                >
                  <div class="drag-item d-inline-block position-relative text-break p-1 m-1" 
                    [ngClass]="getTeamCssClass(team)" id="team-item_{{team.name}}"
                    [attr.draggable]="hasEditionPermission" [attr.data-team]="priority.NonUrgent" [attr.data-index]="i" 
                    (dragstart)="onDragStartTeam($event, team, priority.NonUrgent)"
                  >
                    <ng-container *ngIf="selectedWorkCenter==team.workCentre">
                      {{ i+1 + ") " + team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") }} 
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                        [ngbTooltip]="teamContractExpiredToolTip" 
                        placement="bottom" >
                      </i>
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                        [ngbTooltip]="teamContractIneffectiveToolTip" 
                        placement="bottom" >
                      </i>
                    </ng-container>
                    <ng-container *ngIf="selectedWorkCenter!=team.workCentre">
                      {{ i+1 + ") " + team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") + " <" + team.workCentre + ">"}} 
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                        [ngbTooltip]="teamContractExpiredToolTip" 
                        placement="bottom" >
                      </i>
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                        [ngbTooltip]="teamContractIneffectiveToolTip" 
                        placement="bottom" >
                      </i>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4" [ngClass]="{ 'disabledDiv': hasHATag }">
              <div class="priority-label urgent-label mx-0 d-flex align-items-center justify-content-center">
                <span>{{"priority.urgent" | translate}}</span>
              </div>
              <div id="urgent-box" class="drop-box team-box p-1" (drop)="onDropTeam($event, priority.Urgent)" (dragover)="allowDrop($event)">
                <div *ngFor="let team of uiHandlingTeamList[priority.Urgent]; let i = index"
                  (dragenter)="onDragEnterTeamIndex(priority.Urgent, i)"
                >
                  <div class="drag-item d-inline-block text-break p-1 m-1"  
                    [attr.draggable]="hasEditionPermission" [attr.data-team]="priority.Urgent" [attr.data-index]="i" 
                    [ngClass]="getTeamCssClass(team)" id="team-item_{{team.name}}"
                    (dragstart)="onDragStartTeam($event, team, priority.Urgent)"
                  >
                    <ng-container *ngIf="selectedWorkCenter==team.workCentre">
                      {{ i+1 + ") " + team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") }}
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                        [ngbTooltip]="teamContractExpiredToolTip" 
                        placement="bottom" >
                      </i>
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                        [ngbTooltip]="teamContractIneffectiveToolTip" 
                        placement="bottom" >
                      </i>
                    </ng-container>
                    <ng-container *ngIf="selectedWorkCenter!=team.workCentre">
                      {{ i+1 + ") " + team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") + " <" + team.workCentre + ">"}}
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                        [ngbTooltip]="teamContractExpiredToolTip" 
                        placement="bottom" >
                      </i>
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                        [ngbTooltip]="teamContractIneffectiveToolTip" 
                        placement="bottom" >
                      </i>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4" [ngClass]="{ 'disabledDiv': hasHATag }">
              <div class="priority-label emergency-label mx-0 d-flex align-items-center justify-content-center">
                <span>{{"priority.emergency" | translate}}</span>
              </div>
              <div id="emergency-box" class="drop-box team-box p-1" (drop)="onDropTeam($event, priority.Emergency)" (dragover)="allowDrop($event)">
                <div *ngFor="let team of uiHandlingTeamList[priority.Emergency]; let i = index" 
                  (dragenter)="onDragEnterTeamIndex(priority.Emergency, i)"
                >
                  <div class="drag-item d-inline-block text-break p-1 m-1" 
                    [attr.draggable]="hasEditionPermission" [attr.data-team]="priority.Emergency" [attr.data-index]="i" 
                    [ngClass]="getTeamCssClass(team)" id="team-item_{{team.name}}"
                    (dragstart)="onDragStartTeam($event, team, priority.Emergency)"
                  >
                    <ng-container *ngIf="selectedWorkCenter==team.workCentre">
                      {{ i+1 + ") " + team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") }}
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                        [ngbTooltip]="teamContractExpiredToolTip" 
                        placement="bottom" >
                      </i>
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                        [ngbTooltip]="teamContractIneffectiveToolTip" 
                        placement="bottom" >
                      </i>
                    </ng-container>
                    <ng-container *ngIf="selectedWorkCenter!=team.workCentre">
                      {{ i+1 + ") " + team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") + " <" + team.workCentre + ">"}}
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                        [ngbTooltip]="teamContractExpiredToolTip" 
                        placement="bottom" >
                      </i>
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                        [ngbTooltip]="teamContractIneffectiveToolTip" 
                        placement="bottom" >
                      </i>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-4">
              <h5 class="font-size-m">{{ "pages.routing-rules.handling-teams-pm" | translate }}</h5>
            </div>
            <div class="col-12 col-xl-4">
              <div id="pm-box" class="drop-box team-box px-1 py-3" (drop)="onDropTeam($event, handlingTeam.PM)" (dragover)="allowDrop($event)">
                <div *ngFor="let team of uiHandlingTeamList[handlingTeam.PM]; let i = index" 
                  (dragenter)="onDragEnterTeamIndex(handlingTeam.PM, i)"
                >
                  <div class="drag-item d-inline-block text-break p-1 m-1" 
                    [attr.draggable]="hasEditionPermission" [attr.data-team]="handlingTeam.PM" [attr.data-index]="i" 
                    [ngClass]="getTeamCssClass(team)" id="team-item_{{team.name}}"
                    (dragstart)="onDragStartTeam($event, team, handlingTeam.PM)"
                  >
                    <ng-container *ngIf="selectedWorkCenter==team.workCentre">
                      {{ i+1 + ") " + team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") }}
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                        [ngbTooltip]="teamContractExpiredToolTip" 
                        placement="bottom" >
                      </i>
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                        [ngbTooltip]="teamContractIneffectiveToolTip" 
                        placement="bottom" >
                      </i>
                    </ng-container>
                    <ng-container *ngIf="selectedWorkCenter!=team.workCentre">
                      {{ i+1 + ") " + team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") + " <" + team.workCentre + ">"}}
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                        [ngbTooltip]="teamContractExpiredToolTip" 
                        placement="bottom" >
                      </i>
                      <i class="expired-icon fas fa-exclamation-circle mx-2" 
                        *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                        [ngbTooltip]="teamContractIneffectiveToolTip" 
                        placement="bottom" >
                      </i>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row mx-0 col-md-6 available-teams-wrap align-content-start">
            <div class="col-12 px-0">
              <h5 class="font-size-m">{{ "pages.routing-rules.available_teams" | translate }}</h5>
            </div>
            <div id="available-team-box" class="col-12 drop-box team-box available-team p-1" (drop)="onDropTeam($event, handlingTeam.AVAILABLE)" (dragover)="allowDrop($event)">
              <div class="drag-item d-inline-block text-break p-1 m-1" *ngFor="let team of uiHandlingTeamList[handlingTeam.AVAILABLE]; let i = index" 
                [attr.draggable]="hasEditionPermission" [attr.data-team]="handlingTeam.AVAILABLE" [attr.data-index]="i" [ngClass]="getTeamCssClass(team)" id="team-item_{{team.name}}"
                (dragstart)="onDragStartTeam($event, team)"
              >
                <ng-container *ngIf="selectedWorkCenter==team.workCentre">
                  {{ team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") }}
                  <i class="expired-icon fas fa-exclamation-circle mx-2" 
                    *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                    [ngbTooltip]="teamContractExpiredToolTip" 
                    placement="bottom" >
                  </i>
                  <i class="expired-icon fas fa-exclamation-circle mx-2" 
                    *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                    [ngbTooltip]="teamContractIneffectiveToolTip" 
                    placement="bottom" >
                  </i>
                </ng-container>
                <ng-container *ngIf="selectedWorkCenter!=team.workCentre">
                  {{ team.name + (team.contractNumber != undefined && team.contractNumber != "" ? " (" + team.contractNumber + ")" : "") + " <" + team.workCentre + ">"}}
                  <i class="expired-icon fas fa-exclamation-circle mx-2" 
                    *ngIf="team.status === teamStatus.CONTRACT_EXPIRED" 
                    [ngbTooltip]="teamContractExpiredToolTip" 
                    placement="bottom" >
                  </i>
                  <i class="expired-icon fas fa-exclamation-circle mx-2" 
                    *ngIf="team.status === teamStatus.CONTRACT_INEFFECTIVE" 
                    [ngbTooltip]="teamContractIneffectiveToolTip" 
                    placement="bottom" >
                  </i>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="row d-flex align-items-center row-space-thin">
          <div class="col-md mt-2">
            <select
              class="browser-default custom-select"
              [(ngModel)]="selectedListType"
              (change)="updateScheduleTable()"
            >
              <option value="{{priority.NonUrgent}}">{{"priority.non-urgent" | translate}}</option>
              <option value="{{priority.Urgent}}">{{"priority.urgent" | translate}}</option>
              <option value="{{priority.Emergency}}">{{"priority.emergency" | translate}}</option>
              <option *ngIf="hasHATag" value="{{handlingTeam.PM}}">{{"pages.routing-rules.option-pm" | translate}}</option>
            </select>
          </div>
        </div>
        
        <schedule-table #teamScheduleTable [teamList]="selectedTeamList" [colorTable]="colorTable"></schedule-table>
      </div>

      <div class="ha-assets-wrap mt-4" *ngIf="hasHATag">
        <h5>{{ "pages.routing-rules.rr-covered-equipment" | translate }}</h5>


        
        <div class="row justify-content-between">
          <div class="col-auto align-self-center">
            <button
              id="existingRoutingRulesButton"
              type="button"
              class="standard active-tab"
              (click)="onCriteriaTabClicked(0)"
            >
              {{"pages.routing-rules.existing-routing-rule"|translate}}
            </button>
            <button
              id="newRoutingRulesButton"
              type="button"
              class="standard inactive-tab"
              (click)="onCriteriaTabClicked(1)"
            >
              {{"pages.routing-rules.new-routing-rule"|translate}}
            </button>
          </div>
        </div>
        <div class="content-div">
          <div class="row justify-content-between">
            <div class="col-auto align-self-center">
            </div>
            <div class="col-auto align-self-center float-right">
              <span class="px-1">
                <button
                  *ngIf="uiSelectedCriteriaTab==1"
                  type="button"
                  class="standard brand-blue"
                  (click)="onHAAssignButtonClicked()"
                  [disabled]="isAssigningAsset || selectedHAAssetList.length == 0"
                > 
                  <span *ngIf="isAssigningAsset">
                    <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                    {{'global.loading' | translate}}
                  </span>
                  <span *ngIf="!isAssigningAsset">
                    {{"routing-rules.assign-button"|translate}}
                  </span>
                </button>
              </span>
              <span class="px-1">
                <button
                  *ngIf="uiSelectedCriteriaTab==0"
                  type="button"
                  class="standard brand-blue px-1"
                  (click)="onHAUnssignButtonClicked()"
                  [disabled]="isUnassigningAsset || selectedHAAssetList.length == 0"
                > 
                  <span *ngIf="isUnassigningAsset">
                    <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                    {{'global.loading' | translate}}
                  </span>
                  <span *ngIf="!isUnassigningAsset">
                    {{"routing-rules.unassign-button"|translate}}
                  </span>
                </button>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-tablex id="ha_assets_table" [tablexParam]="haTablexParam" class="mt-3"></app-tablex>
            </div>
          </div>
        </div>


        

        <!-- <button type="button" class="standard brand-amber d-block mx-auto mt-5" (click)="onUpdateRoutingRuleClicked()">
          <i class="fas fa-save space"></i>
          {{ "global.save" | translate }}
        </button> -->
      </div>
      
      <div class="w-100 mx-0 px-0" *ngIf="!hasHATag">
        <div class="row justify-content-between">
          <div class="col-auto align-self-center">
            <button
              id="existingRoutingRulesButton"
              type="button"
              class="standard active-tab"
              (click)="onCriteriaTabClicked(0)"
            >
              {{"pages.routing-rules.existing-routing-rule"|translate}}
            </button>
            <button
              id="newRoutingRulesButton"
              type="button"
              class="standard inactive-tab"
              (click)="onCriteriaTabClicked(1)"
            >
              {{"pages.routing-rules.new-routing-rule"|translate}}
            </button>
          </div>
        </div>
        <div class="content-div">
          <div class="row justify-content-between">
            <div class="col-auto align-self-center">
              <button
                *ngIf="hasEditionPermission && uiSelectedCriteriaTab==0"
                id="add-routing-rule-button"
                type="button"
                class="standard brand-amber"
                (click)="onAddRoutingRuleClicked()"
              >
                {{"pages.routing-rules.add-routing-rule"|translate}}<br>
                {{"pages.routing-rules.add-routing-rule2"|translate}}
              </button>
              <!-- <button
                *ngIf="hasEditionPermission && uiSelectedCriteriaTab==1 "
                id="update-routing-rule-button"
                type="button"
                class="standard brand-amber"
                (click)="onUpdateRoutingRuleClicked()"
              >
                {{"pages.routing-rules.update-routing-rule"|translate}}<br>
                {{"pages.routing-rules.update-routing-rule2"|translate}}
              </button> -->
            </div>
            <div class="col-auto align-self-center float-right">
              <span class="routing-rule-subtitle">{{"pages.routing-rules.routing-rule-subtitle"|translate}}</span>
              <br>
              <span class="px-1">
                <button
                  *ngIf="hasEditionPermission && uiSelectedCriteriaTab==1"
                  type="button"
                  class="standard brand-blue"
                  (click)="onAssignCoverageClicked()"
                  [disabled]="isAssigningCoverages || selectedNewCoverageList.length == 0"
                > 
                  <span *ngIf="isAssigningCoverages">
                    <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                    {{'global.loading' | translate}}
                  </span>
                  <span *ngIf="!isAssigningCoverages">
                    {{"routing-rules.assign-button"|translate}}
                  </span>
                </button>
              </span>
              <span class="px-1">
                <button
                  *ngIf="hasEditionPermission && uiSelectedCriteriaTab==0"
                  type="button"
                  class="standard brand-blue px-1"
                  (click)="onUnassignCoverageClicked()"
                  [disabled]="isUnassigningCoverages || selectedCoverageList.length == 0"
                > 
                  <span *ngIf="isUnassigningCoverages">
                    <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                    {{'global.loading' | translate}}
                  </span>
                  <span *ngIf="!isUnassigningCoverages">
                    {{"routing-rules.unassign-button"|translate}}
                  </span>
                </button>
              </span>
              <span class="px-1 float-right">
                <button
                  type="button"
                  class="standard brand-blue"
                  (click)="onExportExcelClick()"
                  [disabled]="isExportExcelButtonLoading"
                > 
                  <span *ngIf="isExportExcelButtonLoading">
                    <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                    {{'global.loading' | translate}}
                  </span>
                  <span *ngIf="!isExportExcelButtonLoading">
                    <i class="fas fa-file-download space"></i>
                    {{"global.export-excel"|translate}}
                  </span>
                </button>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <app-tablex *ngIf="uiSelectedCriteriaTab==0" id='routing_rule_table' [tablexParam]='tablexParam' class='mt-3'></app-tablex>
                <app-tablex *ngIf="uiSelectedCriteriaTab==1" id='id_unassigned_coverage_table' [tablexParam]='unassignedCoverageTablexParam' class='mt-3'></app-tablex>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <custom-slider-panel #create_routing_rule_panel [customClass]="'create_routing_rule_panel'" [widthPx]=600>
      <routing-rule-criteria-form #createRoutingRuleForm (onSave)="onSaveRoutingRuleCriteriaForm($event)" (onClose)="onCloseRoutingRuleCriteriaForm($event)"></routing-rule-criteria-form>
    </custom-slider-panel>
  </div>
</div>

<ng-template #teamContractExpiredToolTip>
  <div class="warning-tooltip-wrap tooltip-inner text-center">
    {{ "tool-tip.team-contract-expired" | translate }}
  </div>
</ng-template>

<ng-template #teamContractIneffectiveToolTip>
  <div class="warning-tooltip-wrap tooltip-inner text-center">
    {{ "tool-tip.team-contract-ineffective" | translate }}
  </div>
</ng-template>

<ng-template #equipTagUsedOtherServiceCoverageToolTip>
  <div class="warning-tooltip-wrap tooltip-inner amber text-center">
    {{ "tool-tip.equipment-tag-used-other-service-coverage" | translate }}
  </div>
</ng-template>
