import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionButtonDefinition, ActionButtonPmPlan, ActionButtonType } from '@enum/action-button';
import { AppDelegate } from 'src/app/AppDelegate';
import * as moment from 'moment';
import { JM, JMCONSTANT, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session } from '@services/session';
import { PmJobEditComponentRouterStateI } from '../pm-job-edit/pm-job-edit.component';
import { Router } from '@angular/router';
import { CustomSliderPanelComponent } from '../../components/custom-slider-panel/custom-slider-panel.component';
@Component({
  selector: 'app-pm-plan-view',
  templateUrl: './pm-plan-view.component.html',
  styleUrls: ['./pm-plan-view.component.scss']
})
export class PmPlanViewComponent extends BasePage implements OnInit {
  @ViewChild("pmPlanSummaryElem", { static: false }) pmPlanSummaryElem;
  @ViewChild("pmPlanParticularsElem", { static: false }) pmPlanParticularsElem;
  @ViewChild("pmPlanRemindersElem", { static: false }) pmPlanRemindersElem;
  @ViewChild("pmPlanPeriodsElem", { static: false }) pmPlanPeriodsElem;
  @ViewChild("pmPlanRemarksElem", { static: false }) pmPlanRemarksElem;
  @ViewChild('cancel_pm_plan_panel', { static: true }) cancelPmPlanPanel: CustomSliderPanelComponent;

  pmPlanNumber = undefined;
  plan: JMOBJ.PmPlan = new JMOBJ.PmPlan;

  pageMode : JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;

  breadcrumbs: any = [];
  // action buttons
  actionButtons = [];
  uiIsLoading: boolean = false;
  disableActionSideBar: boolean = false;

  router : Router;

  isCancelPmPlanPanelLoading: boolean;

  hasPMReminderFeatureToggle = false;


  constructor(injector: Injector, private modalService: NgbModal, router: Router) {
    super(injector);
    this.router = router;
  }

  ngOnInit() {
    this.pmPlanNumber = this.route.snapshot.paramMap.get('pmNumber');
    this.requestPmPlan();
    this.initActionButtons();
    this.buttonHandling();

    this.initBreadcrumbs();

    const pmReminderFeature = Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.JM_PM_PLAN_REMINDER);
    if(pmReminderFeature) {
      this.hasPMReminderFeatureToggle = pmReminderFeature.enabled;
    }
  }

  initBreadcrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-pm-plan',
        name: JMLanguage.translate('pages.pm-plan.page-title'),
        route: '/pm/plan-list',
      },
      {
        id: 'breadcrumbs-pm-plan-number',
        name: this.pmPlanNumber,
        route: null,
        currentPage: true,
      }
    ];
  }

  // ----------- API ----------- //
  private async requestPmPlan() {
    if(!this.pmPlanNumber) return;

    const request: JM.JMRequestGetPmPlan = new JM.JMRequestGetPmPlan();
    request.pmPlanNumber = this.pmPlanNumber;

    const response: JM.JMResponseGetPmPlan = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
      this.plan = response.payload;
      this.initActionButtons();
      this.buttonHandling();
  }

  private async requestSubmitPmPlan(){
    const request: JM.JMRequestPmPlanSubmit = new JM.JMRequestPmPlanSubmit();
    request.pmPlanNumber = this.plan.pmPlanNumber ? this.plan.pmPlanNumber : undefined;
    request.workCentre = this.plan.workCentre;
    request.agreementNumber = this.plan.agreementNumber;
    request.periods = this.plan['periods'] ? this.plan['periods'] : undefined;
    request.teamId = this.plan.teamId;
    request.frequency = this.plan.frequency ? this.plan.frequency : undefined;
    request.planCoverage = this.plan.planCoverage;
    request.scheduleType = this.plan.scheduleType;
    request.startDate = this.plan.startDate;
    request.secondStartDate =  (this.plan.scheduleType == JMENUM.PMScheduleType.OVERHAUL || this.plan.scheduleType == JMENUM.PMScheduleType.NORMAL) && this.plan.secondStartDate ? this.plan.secondStartDate : undefined;
    request.endDate = this.plan.endDate;
    request.planDescription = this.plan.planDescription
    request.reminderRecipients = this.plan.reminderRecipients ? this.plan.reminderRecipients : undefined;
    request.remarks = this.plan.remarks ? this.plan.remarks : undefined;
    // request.toBeOverdueReminder
    // request.overduePeriodReminder
    // request.attachments
    request.version = this.plan.version;

    const response: JM.JMResponsePmPlanSubmit = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.submitted"));
    this.plan = response.payload;
    this.initActionButtons();
  }

  async requestCancelPmPlan({ cancelCode, cancelRemarks }: { cancelCode: number; cancelRemarks: string }){
    if (!cancelCode || !cancelRemarks) return;
    
    const request: JM.JMRequestPmPlanCancel = new JM.JMRequestPmPlanCancel();
    request.pmPlanNumber = this.plan.pmPlanNumber;
    request.cancelReason = cancelCode;
    request.cancelRemarks = cancelRemarks;
    request.version = this.plan.version;

    this.isCancelPmPlanPanelLoading = true;
    const response: JM.JMResponsePmPlanCancel = await AppDelegate.sendJMRequest(request);
    this.isCancelPmPlanPanelLoading = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.cancelled"));
    this.cancelPmPlanPanel.close();
    this.plan = response.payload;
    this.initActionButtons();
  }


  // ----------- UI function ----------- //
  
  initActionButtons(): void {
    this.actionButtons = [];
    if (!(this.plan.workCentre && this.plan.agreementNumber)) return; // no action button in vp created pm plan
    switch (this.plan.status) {
      case JMENUM.PMStatus.DRAFT: 
        this.addActionBtn(ActionButtonPmPlan.submit);
        this.addActionBtn(ActionButtonPmPlan.edit);
        this.addActionBtn(ActionButtonPmPlan.cancel);
        // this.addActionBtn(ActionButtonPmPlan.attachment);
        break;
      case JMENUM.PMStatus.OUTSTANDING: 
      case JMENUM.PMStatus.IN_PROGRESS: 
        this.addActionBtn(ActionButtonPmPlan.createPmJob);
        this.addActionBtn(ActionButtonPmPlan.edit);
        this.addActionBtn(ActionButtonPmPlan.cancel);
        // this.addActionBtn(ActionButtonPmPlan.attachment);
        break;
      case JMENUM.PMStatus.COMPLETED: 
        this.addActionBtn(ActionButtonPmPlan.createPmJob);
        // this.addActionBtn(ActionButtonPmPlan.attachment);
        break;
    }
  }

  addActionBtn(buttonStatus: ActionButtonPmPlan): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.pmPlan][buttonStatus];
    let buttonHandler = () => { };

    if(actionButton['permission'] && !JMUTILITY.hasPermission(Session.userInfo, actionButton['permission'])) return;

    switch (buttonStatus) {
      case ActionButtonPmPlan.submit:
        buttonHandler = () => {
          /* TODO:
            If Plan Coverage is “Equipment base”, at least one equipment is selected. 
            If Plan Coverage is “Location base” or “FS251”, at least one Location is selected.
          */ 
            if(this.pmPlanSummaryElem.validate() && this.pmPlanParticularsElem.validate() && (this.hasPMReminderFeatureToggle ? this.pmPlanRemindersElem.validate() : true)){
              this.requestSubmitPmPlan();
            }
        };
        break;
      case ActionButtonPmPlan.cancel:
        buttonHandler = () => {
          this.cancelPmPlanPanel.show();
        };
        break;
      case ActionButtonPmPlan.edit:
        buttonHandler = () => {
          this.router.navigate(['/pm/standard-plan/edit/'+this.pmPlanNumber]);
        };
        break;
      case ActionButtonPmPlan.attachment:
        buttonHandler = () => {

        };
        break;
      case ActionButtonPmPlan.createPmJob:
        buttonHandler = () => {
          this.createPmJob(this.pmPlanNumber);
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : JMLanguage.translate("global.yes"),
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : JMLanguage.translate("global.no"),
        handler: () => { this.uiIsLoading = false }
      }
    ]
    this.actionButtons.push(actionButton);
  }

  createPmJob( pmNumber : string ) {
    let pmJobEditComponentRouterStateI : PmJobEditComponentRouterStateI;
    pmJobEditComponentRouterStateI = {
      pmPlanId : pmNumber,
      pmPlanStatus : this.plan.status,
      periodId : null
    }
    this.router.navigateByUrl( '/pm/job/create' , { state : pmJobEditComponentRouterStateI })
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      AppDelegate.showPopUpAlert(JMLanguage.translate(actionButton.popupTitle), '', actionButton.buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  hideActionButton(button) {
    let actionButton = this.actionButtons.find(({ action }) => action == button);
    actionButton.isShow = false;
  }

  buttonHandling() {
    if(this.plan.status == JMENUM.PMStatus.DRAFT && !JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.PMPLAN_UPDATEDRAFT)){
      this.hideActionButton(ActionButtonPmPlan.edit);
    }
    if((this.plan.status == JMENUM.PMStatus.OUTSTANDING || this.plan.status == JMENUM.PMStatus.IN_PROGRESS) && !JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.PMPLAN_UPDATE)){
      this.hideActionButton(ActionButtonPmPlan.edit);
    }
  }

}
