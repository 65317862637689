<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [breadcrumbs]="breadcrumbs"
  ></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding">
    <div class="row mx-0">
      <div class="col-xl-6 col-12">
        <app-pm-plan-summary #pmPlanSummaryElem [plan]="plan" [pageMode]="pageMode" class="d-block mb-5"></app-pm-plan-summary>
        <app-pm-plan-particulars #pmPlanParticularsElem [plan]="plan" [pageMode]="pageMode"></app-pm-plan-particulars>
        <ng-container *ngIf="hasPMReminderFeatureToggle">
          <app-pm-plan-reminders #pmPlanRemindersElem [plan]="plan" [pageMode]="pageMode" ></app-pm-plan-reminders>
        </ng-container>
      </div>
      <div class="col-xl-6 col-12">
        <app-pm-plan-periods #pmPlanPeriodsElem [plan]="plan" [pageMode]="pageMode" class="d-block mb-5"></app-pm-plan-periods>
        <app-pm-plan-remarks #pmPlanRemarksElem [plan]="plan" [pageMode]="pageMode"></app-pm-plan-remarks>
        <!-- <app-attachment-detail #jobcardAttachmentDetailElem [jobCard]="jobCard" [page]="JMPage.JOBCARD" [currentPageMode]="currentPageMode" *ngIf="jobCard.attachments && jobCard.attachments.length > 0"> -->
      </div>
    </div>
    
    <custom-slider-panel #cancel_pm_plan_panel [widthPx]=600>
      <app-pm-plan-cancel-form
        [isLoading]="isCancelPmPlanPanelLoading"
        (onSubmit)="requestCancelPmPlan($event)"
      ></app-pm-plan-cancel-form>
    </custom-slider-panel>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionButtons && actionButtons.length > 0">
      <app-action-sidebar [buttonData]="actionButtons" [disabled]="disabledActionSideBar"
        (clickEvent)="onActionButtonClicked($event)">
      </app-action-sidebar>
    </ng-container>

  </div>
</div>