import { TablexColumnType, TablexColumnVerticalAlign, TablexColumnHorizontalAlign, TablexColumnFilterType, TablexColumnFilterOption } from 'src/app/entity/enum/tablexColumnType';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JMENUM } from '@ccep/CCEPConnector-ts';

const pageSizeOptions = [10, 20, 50, 100];
const currentPageSize = 10;
const currentPage = 1;
const pageCount = 1;

export class TablexParam {
    isLoadingTable = false;
    enableSetPageSize = true;
    enablePagination = true;
    enableColFilter = false;
    enableSelectedRowCount = false;
    enableClearFilter = true;
    minifyButton = true;
    tableRow = 'row';
    selectedRowIndex = -1;
    tableWrapperClass = 'table-min-width';
    pageSizeOptions = pageSizeOptions;
    currentPageSize = currentPageSize;
    currentPage = currentPage;
    pageCount = pageCount;
    enableStickyHeader = false;
    filterDebounceTime = Constants.DEBOUNCE_TIME;
    content = [];
    highlightedRows = [];
    displayColId = [];
    customClassRows = [];
    filter = {};
    statusOptions = new TablexColumnFilterOption();
    categoryOptions = new TablexColumnFilterOption();
    subCategoryOptions = new TablexColumnFilterOption();
    headers = [];

    constructor() {
        const initOptions = {
            bindLabel: 'label',
            bindValue: 'value',
            change: this.onOptionFilterChanged,
        }
        this.statusOptions = {
            ...this.statusOptions,
            ...initOptions,
            items: Object.keys(JMENUM.EnquiryStatus).map(key => (
                {value: key, label: JMLanguage.translate('enquiry.status.' + key)}
            ))
        };
        this.categoryOptions = {
            ...this.categoryOptions,
            ...initOptions,
            items: Object.keys(JMENUM.EnquiryCategory).map(key => (
                {value: key, label: JMLanguage.translate('enquiry.category.' + key)}
            ))
        };
        this.subCategoryOptions = {
            ...this.subCategoryOptions,
            ...initOptions,
            items: Object.keys(JMENUM.EnquirySubCategory).map(key => (
                {value: key, label: JMLanguage.translate('enquiry.subCategory.' + key)}
            ))
        };
        this.headers = [
            {
                id: 'enquiryNumber',
                name: 'pages.enquiry.list.th.refId',
                enableFilter: true,
                type: TablexColumnType.Text,
                horizontalAlign: TablexColumnHorizontalAlign.Center,
                verticalAlign: TablexColumnVerticalAlign.Bottom,
                class: 'col enquiryNumber',
            },
            {
                id: 'category',
                name: 'pages.enquiry.label.category',
                enableFilter: true,
                filterType: TablexColumnFilterType.Dropdown,
                filterDropdownOption: this.categoryOptions,
                type: TablexColumnType.Text,
                horizontalAlign: TablexColumnHorizontalAlign.Center,
                verticalAlign: TablexColumnVerticalAlign.Bottom,
                class: 'col',
            },
            {
                id: 'subCategory',
                name: 'pages.enquiry.label.subCategory',
                filterType: TablexColumnFilterType.Dropdown,
                filterDropdownOption: this.subCategoryOptions,
                enableFilter: true,
                type: TablexColumnType.Text,
                horizontalAlign: TablexColumnHorizontalAlign.Center,
                verticalAlign: TablexColumnVerticalAlign.Bottom,
                class: 'col',
            },
            {
                id: 'description',
                name: 'pages.enquiry.label.description',
                enableFilter: true,
                type: TablexColumnType.Text,
                horizontalAlign: TablexColumnHorizontalAlign.Center,
                verticalAlign: TablexColumnVerticalAlign.Bottom,
                class: 'col-4 desc',
            },
            {
                id: 'status',
                name: 'pages.enquiry.label.status',
                enableFilter: true,
                filterType: TablexColumnFilterType.Dropdown,
                filterDropdownOption: this.statusOptions,
                type: TablexColumnType.Text,
                horizontalAlign: TablexColumnHorizontalAlign.Center,
                verticalAlign: TablexColumnVerticalAlign.Bottom,
                class: 'col',
            },
            {
                id: 'updatedBy', // search person name only
                name: 'pages.enquiry.list.th.lastUpdated',
                enableFilter: true,
                type: TablexColumnType.Text,
                horizontalAlign: TablexColumnHorizontalAlign.Center,
                verticalAlign: TablexColumnVerticalAlign.Bottom,
                class: 'col',
            },
            {
                id: 'contactName', // search person name only
                name: 'pages.enquiry.list.th.created',
                enableFilter: true,
                type: TablexColumnType.Text,
                horizontalAlign: TablexColumnHorizontalAlign.Center,
                verticalAlign: TablexColumnVerticalAlign.Bottom,
                class: 'col',
            },
        ];
    }

    onRowUnselected: (index, rowData) => void;
    onRowSelected: (index, rowData) => void;
    requestTableData: () => void; // this is not a method for tablex component

    onPageSizeClicked = (pageSize: number) => {
        this.currentPage = 1;
        this.currentPageSize = pageSize;
        this.requestTableData();
    }

    onPageNumberClicked = (pageIndex: number) => {
        this.currentPage = pageIndex;
        this.requestTableData();
    }

    onFilterChanged = (event, index, header, filter) => {
        this.filter = this.cleanEmptyFilters(filter);
        this.currentPage = 1;
        this.isLoadingTable = false;
        this.requestTableData();
    }

    onFilterClear = () => {
        this.filter = {};
        this.requestTableData();
    }

    cleanEmptyFilters = (filters) => {
        for (const key in filters) {
            if (filters[key] === null || filters[key] === undefined || filters[key] === '') {
                delete filters[key];
            }
        }
        return filters;
    }
    onOptionFilterChanged = (event, i, header) => {
        if (header.filterDropdownOption.selectedValue) {
          this.filter[header.id] = header.filterDropdownOption.selectedValue;
        } else {
          delete this.filter[header.id];
        }
        this.requestTableData();
    }
}