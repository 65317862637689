import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-associate-sn-panel',
  templateUrl: './associate-sn-panel.component.html',
  styleUrls: ['./associate-sn-panel.component.scss']
})
export class AssociateSnPanelComponent implements OnInit {

  @Input() sn: JMOBJ.ServiceNotification;
  @Input() isInitComponent: false = false;
  @Output() onClose = new EventEmitter<any>();

  // ngModel
  selectedApprover     : string[]           = [];
  selectedAssociatingSn: string[] | string  = undefined;  // ng-select: if multiple, ngModel will be array type. if single, ngModel will be string type
  snPreviewInfoArray     : PreviewInterface[] = [];

  cacheSnMap      : Map<string, JMOBJ.ServiceNotification> = new Map();  // key: snumber
  cacheLocationMap: Map<string, JMOBJ.Location>            = new Map();  // key: locationCode

  // Options
  approverListOptions   : string[] = [];
  associateSnListOptions: string[] = [];

  // UI control
  hasApprovePermission : boolean = false;
  isMultipleAssociateSn: boolean = false;
  isLoadingApprover    : boolean = false;
  isLoadingAssociateSn : boolean = false;
  isLoadingSubmission  : boolean = false;
  submitDisabled       : boolean = false;

  constructor() { }

  ngOnInit() {
    this.hasApprovePermission = JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.SN_ASSOCIATE_APPROVE)
      || JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.SN_ASSOCIATE_APPROVE_ALL);
  }

  ngOnChanges() {
    if (this.isInitComponent) {
      if (this.sn) {
        if (!this.hasApprovePermission) {
          this.requestPostSummary();
        }
        this.requestAbleAssociateSnSummary();

        if (this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA) {
          this.isMultipleAssociateSn = false;
          this.selectedAssociatingSn = '';
        } else {
          // ng-select if multiple, ngModel will be array
          this.isMultipleAssociateSn = true;
          this.selectedAssociatingSn = [];
        }

        this.validateForm();
      }
    }
  }

  //===================================================
  // API request
  private requestPostSummary() {
    let request            = new JM.JMRequestPostsPostSummary();
    request.active         = JMENUM.RequestActive.ACTIVE;
    request.systemName     = Constants.SYSTEM_NAME;
    request.permissions    = [JMENUM.Permission.SN_ASSOCIATE_APPROVE];
    request.authorizations = { 
      workCenters: [this.sn.workCentre]
    }

    this.isLoadingApprover = true;
    JM.JMConnector.sendPostsPostSummary(request, (error: JM.JMNetworkError, response: JM.JMResponsePostsPostSummary) => {
      this.isLoadingApprover = false;
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (response.payload.records && response.payload.records.length > 0) {
        this.approverListOptions = response.payload.records.filter(post => post.name !== Session.userInfo.name).map(post => post.name);
        this.validateForm();
      }
    });
  }

  private requestAbleAssociateSnSummary() {
    let request      = new JM.JMRequestSnAbleAssociateSnSummary();
    request.snNumber = this.sn.snNumber;

    this.isLoadingAssociateSn = true;
    JM.JMConnector.sendSnAbleAssociateSnSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseSnAbleAssociateSnSummary) => {
      this.isLoadingAssociateSn = false;
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (response.payload.records && response.payload.records.length > 0) {
        this.associateSnListOptions = response.payload.records.map(sn => sn.snNumber);
        this.validateForm();
      }
    });
  }

  private async requestLocationSummary(locationCode: string) {
    let request                = new JM.JMRequestLocationsLocationSummary();
        request.location       = [locationCode];
        request.includeSummary = true;
        request.pageNumber     = 1;
        request.pageSize       = 1;

    let response = await JM.JMConnector.sendLocationsLocationSummaryPromise(request);
    if (response.error) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload.records && response.payload.records.length > 0) {
      const locationObj = response.payload.records[0];
      this.cacheLocationMap.set(locationObj.code, locationObj);
    } else {
      console.error("location not found " + request.location);
    }
  }

  private async requestSnSummary(snNumber: string) {
    let request                    = new JM.JMRequestSnGetSn();
    request.snNumber               = snNumber;
    request.excludeTeamInformation = false;

    let response = await JM.JMConnector.sendAsyncSnGetSn(request);

    if (response) {
      if (response.error) {
        AppDelegate.openErrorBar(response);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (response.payload) {
        const sn = response.payload;
        this.cacheSnMap.set(sn.snNumber, sn);
      }
    }
  }

  private async requestAssociateSn() {
    let request           = new JM.JMRequestSnAssociate;
    request.version       = this.sn.version;
    request.snNumber      = this.sn.snNumber;
    request.approver      = this.selectedApprover;
    request.associatingSn = Array.isArray(this.selectedAssociatingSn) ? this.selectedAssociatingSn : [this.selectedAssociatingSn];

    this.isLoadingSubmission = true;
    let response = await JM.JMConnector.sendSnAssociate(request);
    
    this.isLoadingSubmission = false;
    if (response) {
      if (response.error) {
        AppDelegate.openErrorBar(response.error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response)
        return;
      }

      const snackBarMsgKey = request.approver.length > 0 ? 'component.associate-sn-panel.waiting-approve' : 'component.associate-sn-panel.success-associate';
      AppDelegate.openSnackBar(JMLanguage.translate(snackBarMsgKey));

      this.onClose.emit();
    }
  }

  //===================================================
  // retrieve Function
  private async getSnObj(snNumber: string) {
    if (!this.cacheSnMap.has(snNumber)) {
      await this.requestSnSummary(snNumber);
    }

    if (this.cacheSnMap.has(snNumber)) {
      return this.cacheSnMap.get(snNumber);
    }
    return null;
  }

  private async getLocationObj(locationCode: string) {
    if (!this.cacheLocationMap.has(locationCode)) {
      await this.requestLocationSummary(locationCode);
    }

    if (this.cacheLocationMap.has(locationCode)) {
      return this.cacheLocationMap.get(locationCode);
    }
    return null;
  }
  
  
  //===================================================
  // UI Event
  async showSnPreviewInfo() {
    this.snPreviewInfoArray = [];
    let snList = Array.isArray(this.selectedAssociatingSn) ? this.selectedAssociatingSn : [this.selectedAssociatingSn];
    for (const snNumber of snList) {
      this.pushSnPreviewInfo(snNumber);
    }
  }

  async pushSnPreviewInfo(snNumber: string) {
    let sn: JMOBJ.ServiceNotification = await this.getSnObj(snNumber);
    let title: string                 = JMLanguage.translate('component.associate-sn-panel.preview', [snNumber]);
    let client: string                = '';
    let contactPerson: string         = '';
    let contactNumber: string[]       = [];
    let location: string              = '';
    let lcoationDetails: string       = '';
    let faultDetails: string          = '';

    if (sn) {
      sn = this.cacheSnMap.get(snNumber);
      if (sn.client) {
        const clientObj = JM.JMConnector.getClient(sn.client);
        client = clientObj.clientShortName + ' - ' + clientObj.name[Session.selectedLanguage];
      }
      contactPerson = sn.contactPerson ? sn.contactPerson : '';
      contactNumber = (sn.contactNumber || sn.contactNumber.length > 0) ? sn.contactNumber : [];
      if (sn.location) {
        const locationObj = await this.getLocationObj(sn.location);
        if (locationObj) {
          const description = locationObj.description[Session.selectedLanguage] ? locationObj.description[Session.selectedLanguage] : locationObj.description.en;
          location = `${description} (${this.sn.location})`;
        }
      }
      lcoationDetails = sn.locationDetail ? sn.locationDetail : '';
      faultDetails = sn.faultDetail ? sn.faultDetail : '';
    }

    this.snPreviewInfoArray.push({
      title          : title,
      client         : client,
      contactPerson  : contactPerson,
      contactNumber  : contactNumber,
      location       : location,
      lcoationDetails: lcoationDetails,
      faultDetails   : faultDetails,
    });
  }

  validateForm() {
    if (!this.hasApprovePermission) {
      if (this.approverListOptions.length === 0 || this.selectedApprover.length === 0){
        this.submitDisabled = true;
        return;
      }
    }

    if (
      (Array.isArray(this.selectedAssociatingSn) && this.selectedAssociatingSn.length === 0) // array case
      || (typeof this.selectedAssociatingSn === 'string' && !this.selectedAssociatingSn) // string case
    ) {
      this.submitDisabled = true;
      return;
    }

    this.submitDisabled = false;
  }
  
  onClickSubmit() {
    let title = 'component.associate-sn-panel.popup-title';

    AppDelegate.showPopUpAlert(
      JMLanguage.translate(title),
      "",
      [
        {
          name: JMLanguage.translate("global.yes"),
          handler: () => {
            this.requestAssociateSn();
          }
        }, {
          name: JMLanguage.translate("global.no")
        }
      ]
    );
  }
}

interface PreviewInterface {
  title                : string;
  client               : string;
  contactPerson        : string;
  contactNumber        : string[];
  location             : string;
  lcoationDetails      : string;
  faultDetails         : string;
}