import { APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthorizationService } from '@services/authorization.service';
import { ContactGroupService } from '@services/contact-group.service';
import { Ng5SliderModule } from 'ng5-slider';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { MaterialModule } from 'src/app/material-module';
import { ApprovalComponent } from 'src/app/pages/approval/approval.component';
import { ApprovalListPmComponent } from './setting-approval/approval-list-pm/approval-list-pm.component';
import { ApprovalRejectReasonPanelComponent } from './setting-approval/shared/approval-reject-reason-panel/approval-reject-reason-panel.component';
import { AuthorizationComponent } from 'src/app/pages/authorization/authorization.component';
import { CustomersComponent } from 'src/app/pages/customers/customers';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard';
import { EquipmentListComponent } from 'src/app/pages/equipment-list/equipment-list';
import { GeneralSettingsComponent } from 'src/app/pages/general-settings/general-settings.component';
import { LocationsComponent } from 'src/app/pages/locations/locations';
import { LoginComponent } from 'src/app/pages/login/login';
import { LogoutComponent } from './logout/logout.component';
import { PostingComponent } from 'src/app/pages/posting/posting.component';
import { SettingsComponent } from 'src/app/pages/settings/settings';
import { SnCreateComponent } from 'src/app/pages/sn-create/sn-create.component';
import { SnListComponent } from 'src/app/pages/sn-list/sn-list.component';
import { SnViewOperationLogComponent } from 'src/app/pages/sn-view-operation-log/sn-view-operation-log.component';
import { SnViewComponent } from 'src/app/ui/pages/sn-view/sn-view.component';
import { UserManagementRoleComponent } from 'src/app/pages/user-management-role/user-management-role.component';
import { UserManagementUsersComponent } from 'src/app/pages/user-management-users/user-management-users.component';
import { UserProfileComponent } from 'src/app/pages/user-profile/user-profile.component';
import { JobCardStatusPipe } from 'src/app/presenter/pipes/job-card-status.pipe';
import { SnStatusPipe } from 'src/app/presenter/pipes/sn-status.pipe';
import { SelectOptionTranslatePipe } from 'src/app/presenter/pipes/SelectOptionsTranslatePipe';
import { JMLanguagePipe } from 'src/lib/JMLanguage/JMLanguage.pip';

import { ActionSidebarComponent } from 'src/app/ui/components/action-sidebar/action-sidebar.component';
import { AttachmentPreviewerComponent } from 'src/app/ui/components/attachment-previewer/attachment-previewer.component';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { FooterComponent } from 'src/app/ui/components/footer/footer.component';
import { InformationDialogComponent } from 'src/app/ui/components/information-dialog/information-dialog.component';
import { MenuComponent } from 'src/app/ui/components/menu/menu.component';
import { NavComponent } from 'src/app/ui/components/nav/nav.component';
import { OfficeHoursTableComponent } from 'src/app/ui/components/office-hours-table/office-hours-table.component';
import { PaginationComponent } from 'src/app/ui/components/pagination/pagination.component';
import { PopUpDialogComponent } from 'src/app/ui/components/pop-up-dialog/pop-up-dialog.component';
import { ResponseTimeTableComponent } from 'src/app/ui/components/response-time-table/response-time-table.component';
import { ScheduleTableComponent } from 'src/app/ui/components/schedule-table/schedule-table.component';
import { SelectionBoxComponent } from 'src/app/ui/components/selection-box/selection-box.component';
import { SidebarComponent } from 'src/app/ui/components/sidebar/sidebar.component';
import { SliderPanelComponent } from 'src/app/ui/components/slider-panel/slider-panel.component';
import { SubmitButtonComponent } from 'src/app/ui/components/submit-button/submit-button.component';
import { TableComponent } from 'src/app/ui/components/table/table.component';
import { TablexComponent } from 'src/app/ui/components/tablex/tablex.component';
import { TeamSelectorComponent } from 'src/app/ui/components/team-selector/team-selector.component';
import { CancelSnFormComponent } from 'src/app/ui/components/cancel-sn-form/cancel-sn-form.component';
import { CcsSoSubmitFormComponent } from 'src/app/ui/components/ccs-so-submit-form/ccs-so-submit-form.component';
import { RoutingRuleCriteriaFormComponent } from 'src/app/ui/components/routing-rule-criteria-form/routing-rule-criteria-form.component';
import { CcsErrorLogComponent } from 'src/app/pages/ccs-error-log/ccs-error-log.component';
import { CompleteJobCardFormComponent } from 'src/app/ui/components/complete-job-card-form/complete-job-card-form.component';
import { CreateFollowUpJobCardFormComponent } from 'src/app/ui/components/create-follow-up-job-card-form/create-follow-up-job-card-form.component';
import { ContractorsComponent } from 'src/app/ui/pages/contractors/contractors.component';
import { JobCardAssignStaffFormComponent } from 'src/app/ui/components/job-card-assign-staff-form/job-card-assign-staff-form.component';
import { OfficerSettingsComponent } from 'src/app/ui/pages/officer-settings/officer-settings.component';
import { ManualInstructionPreActionFormComponent } from 'src/app/ui/components/manual-instruction-pre-action-form/manual-instruction-pre-action-form.component';
import { ManualInstructionHandlingFormComponent } from 'src/app/ui/components/manual-instruction-handling-form/manual-instruction-handling-form.component';
import { RejectJobCardFormComponent } from 'src/app/ui/components/reject-job-card-form/reject-job-card-form.component';
import { RejectJobCardCompletionComponent } from 'src/app/ui/components/reject-job-card-completion/reject-job-card-completion.component';
import { RejectSnFormComponent } from 'src/app/ui/components/reject-sn-form/reject-sn-form.component';
import { RemarkHistoryComponent } from 'src/app/ui/components/remark-history/remark-history.component';
import { ReportDownloadComponent } from 'src/app/ui/pages/report-download/report-download.component';
import { RoutingRulesListComponent } from 'src/app/ui/pages/routing-rules-list/routing-rules-list';
import { RoutingRulesComponent } from 'src/app/ui/pages/routing-rules/routing-rules';
import { TeamSettingsInstructionComponent } from 'src/app/ui/pages/team-settings-instruction/team-settings-instruction';
import { TeamSettingsMemberComponent } from "src/app/ui/pages/team-settings-member/team-settings-member";
import { TeamSettingsComponent } from "src/app/ui/pages/team-settings/team-settings";
import { TimesheetBatchComponent } from 'src/app/ui/pages/timesheet-batch/timesheet-batch.component';
import { TimesheetJobComponent } from 'src/app/ui/pages/timesheet-job/timesheet-job';
import { TimesheetOfficerComponent } from 'src/app/ui/pages/timesheet-officer/timesheet-officer';
import { TimesheetWorkcentreComponent } from 'src/app/ui/pages/timesheet-workcentre/timesheet-workcentre.component';
import { TimeRangeSliderComponent } from 'src/app/ui/components/time-range-slider/time-range-slider.component';
import { RequestWindowComponent } from './ui/components/request-window/request-window.component';
import { SafePipe } from './safe.pipe';
import { SnNonPmsmcViewComponent } from './pages/sn-non-pmsmc-view/sn-non-pmsmc-view';
// import { SnNonPmsmcRejectComponent } from './pages/sn-non-pmsmc-reject/sn-non-pmsmc-reject';
import { DummyContractComponent } from './ui/pages/dummy-contract/dummy-contract.component';
import { HashtagGroupComponent } from './ui/pages/hashtag-group/hashtag-group.component';
import { ContractCcsFormEditComponent } from './setting-contract/contract-ccs-form-edit/contract-ccs-form-edit.component';
import { AddDummyContractFormComponent } from './ui/components/add-dummy-contract-form/add-dummy-contract-form.component';
import { AddTransitionalContractFormComponent } from './ui/components/add-transitional-contract-form/add-transitional-contract-form.component';
import { AddContractFormComponent } from './ui/components/add-contract-form/add-contract-form.component';
import { AddSlaFormComponent } from './ui/components/add-sla-form/add-sla-form.component';
import { CreateTeamFormComponent } from './ui/components/create-team-form/create-team-form.component';
import { UpdateTeamFormComponent } from './ui/components/update-team-form/update-team-form.component';
import { AddTeamMemberForm } from './ui/components/add-team-member-form/add-team-member-form';
import { AddContractorTeamMemberForm } from './ui/components/add-contractor-team-member-form/add-contractor-team-member-form';

import { ModalManuelFaxComponent } from "./ui/components/modal-manuel-fax/modal-manuel-fax.component";
import { SnHistoryComponent } from './ui/components/sn-history/sn-history.component';
import { ModalOfficeHoursComponent } from './ui/components/modal-office-hours/modal-office-hours.component';
import { ManualInstructionPreActionListComponent } from './ui/components/manual-instruction-pre-action-list/manual-instruction-pre-action-list.component';

import { AppDelegate } from './AppDelegate';

import { TecoComponent } from './ui/pages/teco/teco.component';
import { UnTecoComponent } from './ui/pages/un-teco/un-teco.component';
import { TecoAdvancedSearchComponent } from './ui/components/teco-advanced-search/teco-advanced-search.component';
import { UnTecoReasonComponent } from './ui/components/un-teco-reason/un-teco-reason.component';

import { SettingsDropdownComponent } from './pages/settings-dropdown/settings-dropdown.component';
import { SettingsWorkCentresComponent } from './pages/settings-work-centres/settings-work-centres.component';
import { SettingsHaDefaultsComponent } from './pages/settings-ha-defaults/settings-ha-defaults.component';
import { HaEquipmentListComponent } from './pages/ha-equipment-list/ha-equipment-list.component';
import { HAEquipmentTagListComponent } from './pages/ha-equipment-tag-list/ha-equipment-tag-list.component';
import { CCSEquipmentTagListComponent } from './pages/ccs-equipment-tag-list/ccs-equipment-tag-list.component';
import { FunctionalItemListComponent } from '../lib/ui-component/functional-item-list/functional-item-list.component';
import { CustomFieldsFormComponent } from 'src/lib/ui-component/custom-fields-form/custom-fields-form.component';
import { CustomDropdownOptionsComponent } from 'src/lib/ui-component/custom-dropdown-options/custom-dropdown-options.component';
import { FLMSComponent } from './ui/pages/flms/flms.component';
import { EditFunctionalLocationPanelComponent } from './ui/components/edit-functional-location-panel/edit-functional-location-panel.component';
import { FlmsPendingChineseComponent } from './ui/pages/flms-pending-chinese/flms-pending-chinese.component';
import { FlmsPendingMainComponent } from './ui/pages/flms-pending-main/flms-pending-main.component';
import { FlmsImportPanelComponent } from './ui/components/flms-import-panel/flms-import-panel.component';
import { SettingsHospitalCodeComponent } from './pages/settings-hospital-code/settings-hospital-code.component';

import { EnvWarningComponent } from './ui/components/env-warning/env-warning.component';
import { ManualInstructionDetailPanelComponent } from './ui/components/manual-instruction-detail-panel/manual-instruction-detail-panel.component';
import { PatchCcsSoNumberFormComponent } from './ui/components/patch-ccs-so-number-form/patch-ccs-so-number-form.component';
import { RoutingDetailComponent } from './ui/components/routing-detail/routing-detail.component';
import { NotificationInfomationDetailComponent } from './ui/components/notification-infomation-detail/notification-infomation-detail.component';
import { HaInfomationDetailComponent } from './ui/components/ha-infomation-detail/ha-infomation-detail.component';
import { FaultInfomationDetailComponent } from './ui/components/fault-infomation-detail/fault-infomation-detail.component';
import { NotificationProgressDetailComponent } from './ui/components/notification-progress-detail/notification-progress-detail.component';
import { RemarkDetailComponent } from './ui/components/remark-detail/remark-detail.component';
import { BreadcrumbsComponent } from './ui/components/breadcrumbs/breadcrumbs.component';
import { SnAttachmentComponent } from './pages/sn-attachment/sn-attachment.component';
import { DisplayTextFieldComponent } from './ui/components/display-text-field/display-text-field.component';
import { PendingManualHandlingComponent } from './ui/components/pending-manual-handling/pending-manual-handling.component';
import { AttachmentDetailComponent } from './ui/components/attachment-detail/attachment-detail.component';
import { UploadPreviewerComponent } from './ui/components/upload-previewer/upload-previewer.component';
import { ModalHaEquipmentDetailsComponent } from './ui/components/modal-ha-equipment-details/modal-ha-equipment-details.component';
import { SnEditComponent } from './ui/pages/sn-edit/sn-edit.component';
import { JobcardJobDescriptionComponent } from './ui/components/jobcard-job-description/jobcard-job-description.component';
import { TabButtonsComponent } from './ui/components/tab-buttons/tab-buttons.component';
import { JobcardTaskProgressComponent } from './ui/components/jobcard-task-progress/jobcard-task-progress.component';
import { JobcardClientInformationComponent } from './ui/components/jobcard-client-information/jobcard-client-information.component';
import { JobcardEquipmentInformationComponent } from './ui/components/jobcard-equipment-information/jobcard-equipment-information.component';
import { JobcardProgressDetailsComponent } from './ui/components/jobcard-progress-details/jobcard-progress-details.component';
import { JobcardCcsInformationComponent } from './ui/components/jobcard-ccs-information/jobcard-ccs-information.component';
import { JobcardHaInformationComponent } from './ui/components/jobcard-ha-information/jobcard-ha-information.component';
import { JobcardAdditionalInformationComponent } from './ui/components/jobcard-additional-information/jobcard-additional-information.component';
import { JobcardAttachmentComponent } from './ui/components/jobcard-attachment/jobcard-attachment.component';
import { JobcardJobDetailsComponent } from './ui/components/jobcard-job-details/jobcard-job-details.component';
import { JobcardViewComponent } from './ui/pages/jobcard-view/jobcard-view.component';
import { JobcardEditComponent } from './ui/pages/jobcard-edit/jobcard-edit.component';
import { JobcardCreateComponent } from './ui/pages/jobcard-create/jobcard-create.component';
import { HaEquipmentListPanelComponent } from './ui/components/ha-equipment-list-panel/ha-equipment-list-panel.component';
import { AssociateSnPanelComponent } from './ui/components/associate-sn-panel/associate-sn-panel.component';
import { TimeInputComponent } from 'src/lib/ui-component/time-input/time-input.component';
import { DateInputComponent } from 'src/lib/ui-component/date-input/date-input.component';
import { DateTimeWeekPipe } from 'src/lib/presenter/date-time-week.pipe';
import { DateWeekPipe } from 'src/lib/presenter/date-week.pipe';

// import { EnquiryJobViewComponent } from './ui/components/job-card/enquiry-job-view/enquiry-job-view.component';
// import { CmJobViewComponent } from './ui/components/job-card/cm-job-view/cm-job-view.component';
// import { SingleRoutingDetailFormComponent } from './ui/components/single-routing-detail-form/single-routing-detail-form.component';
import { AddHashTagGroupFormComponent } from './ui/components/add-hashtag-group-form/add-hashtag-group-form.component';
import { AddTagFormComponent } from './ui/components/add-tag-form/add-tag-form.component';
import { JobListComponent } from './ui/pages/job-list/job-list.component';
import { EnquiryFormComponent } from './pages/enquiry-form/enquiry-form.component';
import { EnquiryListComponent } from './pages/enquiry-list/enquiry-list.component';
import { EnquiryViewComponent } from './pages/enquiry-view/enquiry-view.component';

import { EditEquipmentFormComponent } from './ui/components/edit-equipment-form/edit-equipment-form.component';
import { BatchEditEquipmentFormComponent } from './pages/batch-edit-equipment-form/batch-edit-equipment-form';
import { BatchEditHAEquipmentFormComponent } from './pages/batch-edit-ha-equipment-form/batch-edit-ha-equipment-form';
import { ApprovalListComponent } from './ui/pages/approval-list/approval-list.component';
import { PmPlanListComponent } from './ui/pages/pm-plan-list/pm-plan-list.component';
import { CostingListComponent } from './pages/costing-list/costing-list.component';
import { PmPeriodListComponent } from './ui/pages/pm-period-list/pm-period-list.component';
import { PmJobListComponent } from './ui/pages/pm-job-list/pm-job-list.component';
import { PmJobEditComponent } from './ui/pages/pm-job-edit/pm-job-edit.component';
import { PmJobDescriptionComponent } from './ui/components/pm-job-description/pm-job-description.component';
import { PmJobClientInformationComponent } from './ui/components/pm-job-client-information/pm-job-client-information.component';
import { PmJobJobProgressComponent } from './ui/components/pm-job-job-progress/pm-job-job-progress.component';
import { PmJobEquipmentInformationComponent } from './ui/components/pm-job-equipment-information/pm-job-equipment-information.component';

import { PmPlanPeriodsComponent } from './ui/components/pm-plan-periods/pm-plan-periods.component';
import { PmPlanRemarksComponent } from './ui/components/pm-plan-remarks/pm-plan-remarks.component';
import { PmPlanEquipmentListComponent } from './ui/pages/pm-plan-equipment-list/pm-plan-equipment-list.component';
import { PmPeriodEquipmentListComponent } from './ui/pages/pm-period-equipment-list/pm-period-equipment-list.component';
import { PmOutstandingEquipmentListComponent } from './pm/pm-outstanding-equipment-list/pm-outstanding-equipment-list.component';
import { PmPlanEditComponent } from './ui/pages/pm-plan-edit/pm-plan-edit.component';
import { PmPlanSearchCriteriaComponent } from './ui/components/pm-plan-search-criteria/pm-plan-search-criteria.component';
import { ReportListComponent } from './pages/report-list/report-list.component';
import { PmPlanSummaryComponent } from './ui/components/pm-plan-summary/pm-plan-summary.component';
import { PmPlanParticularsComponent } from './ui/components/pm-plan-particulars/pm-plan-particulars.component';
import { PmPlanRemindersComponent } from './ui/components/pm-plan-reminders/pm-plan-reminders.component';
import { PmPlanViewComponent } from './ui/pages/pm-plan-view/pm-plan-view.component';
import { PmPeriodSearchCriteriaComponent } from './ui/components/pm-period-search-criteria/pm-period-search-criteria.component';
import { SettingsSLAComponent } from './ui/pages/sla/sla.component';
import { PopUpWithRadioComponent } from './ui/components/pop-up-with-radio/pop-up-with-radio.component';
import { SnBatchCompleteComponent } from './sn-batch-complete/sn-batch-complete/sn-batch-complete.component';
import { BatchCompleteStageHeaderComponent } from './sn-batch-complete/shared/batch-complete-stage-header/batch-complete-stage-header.component';
import { BatchCompleteValidationResultComponent } from './sn-batch-complete/shared/batch-complete-validation-result/batch-complete-validation-result.component';
import { FileSelectorComponent } from './shared/file-selector/file-selector.component';
import { SnBatchHistoryComponent } from './sn-batch-complete/sn-batch-history/sn-batch-history.component';
import { PmJobEquipmentListComponent } from './ui/pages/pm-job-equipment-list/pm-job-equipment-list.component';
import { PmJobViewComponent } from './ui/pages/pm-job-view/pm-job-view.component';
import { PmJobCcsInformationComponent } from './ui/components/pm-job-ccs-information/pm-job-ccs-information.component';
import { PmJobListAdvSearchComponent } from './ui/components/pm-job-list-adv-search/pm-job-list-adv-search.component';
import { CancelPmJobFormComponent } from './ui/components/cancel-pm-job-form/cancel-pm-job-form.component';
import { CcsSoObtainFormComponent } from 'src/app/pm-job/shared/ccs-so-obtain-form/ccs-so-obtain-form.component';
import { PmJobRemarksComponent } from './ui/components/pm-job-remarks/pm-job-remarks.component';
import { TransferSnFormComponent } from './sn/transfer-sn-form/transfer-sn-form.component';
import { PmJobAttachmentComponent } from './pm-job/pm-job-attachment/pm-job-attachment.component';
import { PmPlanCancelFormComponent } from './pm-plan-cancel-form/pm-plan-cancel-form.component';
import { PmJobTimesheetComponent } from './pm-job/pm-job-timesheet/pm-job-timesheet.component';
import { PmJobTimesheetHistoryComponent } from './pm-job/pm-job-timesheet-history/pm-job-timesheet-history.component';
import { PmJobTimesheetStepsHeaderComponent } from './pm-job/shared/pm-job-timesheet-steps-header/pm-job-timesheet-steps-header.component';
import { StockReserveDetailComponent } from './stock-reserve/stock-reserve-detail/stock-reserve-detail.component';
import { StockReserveListComponent } from './stock-reserve/stock-reserve-list/stock-reserve-list.component';
import { ReserveAiStorePanelComponent } from './stock-reserve/shared/reserve-ai-store-panel/reserve-ai-store-panel.component';
import { JobcardStockReserveComponent } from './ui/components/jobcard-stock-reserve/jobcard-stock-reserve.component';
import { JobCardStandaloneCreateComponent } from './job-card/job-card-standalone-create/job-card-standalone-create.component';
import { JobCardStandaloneViewComponent } from './job-card/job-card-standalone-view/job-card-standalone-view.component';
import { JobCardStandaloneEditComponent } from './job-card/job-card-standalone-edit/job-card-standalone-edit.component';
import { JobCardCancelFormComponent } from './slider-form/job-card-cancel-form/job-card-cancel-form.component';
import { SnBatchReportSubmissionComponent } from './sn-batch-report-submission/sn-batch-submission/sn-batch-report-submission.component';
import { SnBatchReportSubmissionHistoryComponent } from './sn-batch-report-submission-history/sn-batch-report-submission-history.component'
import { SnBatchSubmissionStageHeaderComponent } from './sn-batch-report-submission/shared/sn-batch-submission-stage-header/sn-batch-submission-stage-header.component';
import { CcepPortalSsoLoginSuccessComponent } from './ccep-portal-sso/ccep-portal-sso-login-success/ccep-portal-sso-login-success.component';
import { CcepPortalSsoLogoutSuccessComponent } from './ccep-portal-sso/ccep-portal-sso-logout-success/ccep-portal-sso-logout-success.component';
import { ReleaseNoteComponent } from './setting-release-note/release-note/release-note.component';
import { SnEFormComponent } from './pages/sn-eForm/sn-eForm.component';
import { ContractorCMJobComponent } from './ui/pages/report-download/contractor-cm-job/contractor-cm-job.component';
import { PmsmcStatisticsComponent } from './ui/pages/report-download/pmsmc-statistics/pmsmc-statistics.component';
import { SpecialRequestAlertListComponent } from './ui/pages/special-request-alert/special-request-alert-list/special-request-alert-list.component';
import { SpecialRequestAlertDetailComponent } from './ui/pages/special-request-alert/special-request-alert-detail/special-request-alert-detail.component';
import { JobcardJobListAdvSearchComponent } from './ui/components/jobcard-job-list-adv-search/jobcard-job-list-adv-search.component';
import { InspectionManagementComponent } from './ui/components/inspection-management/inspection-management.component';
import { UploadAttachmentTooltipComponent } from './shared/upload-attachment-tooltip/upload-attachment-tooltip.component';
import { PasswordErrorListComponent } from './ui/components/password-error-list/password-error-list.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LOCATION_INITIALIZED } from '@angular/common';
import { Session } from 'src/app/services/session';
import { UploadAttachmentTableviewComponent } from './shared/upload-attachment-tableview/upload-attachment-tableview.component';
import { ArrivalGpsComponent } from './shared/arrival-gps/arrival-gps.component';
import { SpecialRequestAlertIconComponent } from './shared/special-request-alert-icon/special-request-alert-icon.component';
import { ContractInspectionReportSubmissionComponent } from './pages/contract-inspection-report-submission/contract-inspection-report-submission.component';
import { ContractInspectionReportSubmissionHistoryComponent } from './pages/contract-inspection-report-submission-history/contract-inspection-report-submission-history.component';
import { PmJobInspectionManagementComponent } from './ui/components/pm-job-inspection-management/pm-job-inspection-management.component';
import { TimesheetInputFormComponent } from './ui/components/timesheet/timesheet-input-form/timesheet-input-form.component';
import { InspectionFormComponent } from './ui/components/inspection-form/inspection-form/inspection-form.component';
import { TimesheetJobV2Component } from './ui/pages/timesheet-job-v2/timesheet-job-v2.component';
import { GpsLocationSubmittedTableComponent } from './ui/components/gps-location-submitted-table/gps-location-submitted-table.component';
import { TimetableComponent } from './ui/components/timetable/timetable.component';
import { WeatherEformComponent } from './redirects/weather-eform/weather-eform.component';
import { CCePJMAccountReviewSendEmailComponent } from './ui/pages/ccep-jm-account-review/send-email-confirmation/ccep-jm-account-review-send-email.component';
import { CCePJMAccountReviewUploadTemplateComponent } from './ui/pages/ccep-jm-account-review/upload-template/ccep-jm-account-review-upload-template.component';
import { CCePJMAccountReviewEmailHistoryComponent } from './ui/pages/ccep-jm-account-review/review-email-history/ccep-jm-account-review-email-history.component';
import { CCePJMAccountReviewSubmissionHistoryComponent } from './ui/pages/ccep-jm-account-review/review-submission-history/ccep-jm-account-review-submission-history.component';
import { NoPermissionComponent } from './ui/pages/no-permission/no-permission.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [

    AppComponent,
    NavComponent,
    FooterComponent,
    MenuComponent,
    SliderPanelComponent,
    CustomSliderPanelComponent,
    RoutingRuleCriteriaFormComponent,
    TeamSelectorComponent,
    SettingsComponent,
    EquipmentListComponent,
    RoutingRulesComponent,
    RoutingRulesListComponent,
    TeamSettingsComponent,
    TeamSettingsMemberComponent,
    TeamSettingsInstructionComponent,
    TimesheetJobComponent,
    TimesheetOfficerComponent,
    TimesheetWorkcentreComponent,
    TimesheetBatchComponent,
    DashboardComponent,
    LoginComponent,
    LogoutComponent,
    SidebarComponent,
    SnCreateComponent,
    PaginationComponent,
    PopUpDialogComponent,
    InformationDialogComponent,
    TableComponent,
    TablexComponent,
    SnStatusPipe,
    SelectOptionTranslatePipe,
    ActionSidebarComponent,
    AttachmentPreviewerComponent,
    SnViewComponent,
    SnNonPmsmcViewComponent,
    UserProfileComponent,
    AuthorizationComponent,
    PostingComponent,
    SnViewOperationLogComponent,
    GeneralSettingsComponent,
    UserManagementRoleComponent,
    UserManagementUsersComponent,
    SnListComponent,
    JobCardStatusPipe,
    ReportDownloadComponent,
    CustomersComponent,
    LocationsComponent,
    OfficerSettingsComponent,
    ContractorsComponent,
    DummyContractComponent,
    HashtagGroupComponent,
    AddTransitionalContractFormComponent,
    ContractCcsFormEditComponent,
    AddDummyContractFormComponent,
    AddContractFormComponent,
    AddSlaFormComponent,
    SelectionBoxComponent,
    OfficeHoursTableComponent,
    ResponseTimeTableComponent,
    ApprovalComponent,
    RemarkHistoryComponent,
    ManualInstructionPreActionFormComponent,
    ManualInstructionHandlingFormComponent,
    RejectSnFormComponent,
    CancelSnFormComponent,
    RejectJobCardFormComponent,
    RejectJobCardCompletionComponent,
    CcsSoSubmitFormComponent,
    RoutingRuleCriteriaFormComponent,
    CcsErrorLogComponent,
    CompleteJobCardFormComponent,
    CreateFollowUpJobCardFormComponent,
    JobCardAssignStaffFormComponent,
    SubmitButtonComponent,
    ScheduleTableComponent,
    TimeRangeSliderComponent,
    RequestWindowComponent,
    ModalManuelFaxComponent,
    SafePipe,
    SnHistoryComponent,
    CreateTeamFormComponent,
    UpdateTeamFormComponent,
    AddTeamMemberForm,
    AddContractorTeamMemberForm,
    JMLanguagePipe,
    ModalOfficeHoursComponent,
    ManualInstructionPreActionListComponent,
    TecoComponent,
    UnTecoComponent,
    TecoAdvancedSearchComponent,
    UnTecoReasonComponent,
    SettingsDropdownComponent,
    SettingsWorkCentresComponent,
    SettingsHaDefaultsComponent,
    SettingsHospitalCodeComponent,
    HaEquipmentListComponent,
    HAEquipmentTagListComponent,
    CCSEquipmentTagListComponent,
    FunctionalItemListComponent,
    CustomFieldsFormComponent,
    CustomDropdownOptionsComponent,
    FLMSComponent,
    EditFunctionalLocationPanelComponent,
    FlmsPendingChineseComponent,
    FlmsPendingMainComponent,
    FlmsImportPanelComponent,
    EnvWarningComponent,
    ManualInstructionDetailPanelComponent,
    PatchCcsSoNumberFormComponent,
    RoutingDetailComponent,
    ManualInstructionDetailPanelComponent,
    NotificationInfomationDetailComponent,
    HaInfomationDetailComponent,
    FaultInfomationDetailComponent,
    NotificationProgressDetailComponent,
    RemarkDetailComponent,
    DisplayTextFieldComponent,
    BreadcrumbsComponent,
    SnAttachmentComponent,
    PendingManualHandlingComponent,
    AttachmentDetailComponent,
    UploadPreviewerComponent,
    ModalHaEquipmentDetailsComponent,
    SnEditComponent,
    JobcardJobDescriptionComponent,
    TabButtonsComponent,
    JobcardTaskProgressComponent,
    JobcardClientInformationComponent,
    JobcardEquipmentInformationComponent,
    JobcardProgressDetailsComponent,
    JobcardCcsInformationComponent,
    JobcardHaInformationComponent,
    JobcardAdditionalInformationComponent,
    JobcardAttachmentComponent,
    JobcardViewComponent,
    JobcardEditComponent,
    JobcardCreateComponent,
    JobcardJobDetailsComponent,
    HaEquipmentListPanelComponent,
    AssociateSnPanelComponent,
    TimeInputComponent,
    DateInputComponent,
    DateTimeWeekPipe,
    DateWeekPipe,
    AddTagFormComponent,
    AddHashTagGroupFormComponent,
    JobListComponent,
    EnquiryFormComponent,
    EnquiryListComponent,
    EnquiryViewComponent,
    EditEquipmentFormComponent,
    BatchEditEquipmentFormComponent,
    BatchEditHAEquipmentFormComponent,
    ApprovalListComponent,
    PmPlanListComponent,
    PmPlanSearchCriteriaComponent,
    PmPeriodSearchCriteriaComponent,
    ReportListComponent,
    CostingListComponent,
    PmPeriodListComponent,
    PmJobListComponent,
    PmJobEditComponent,
    PmJobDescriptionComponent,
    PmJobClientInformationComponent,
    PmJobJobProgressComponent,
    PmJobEquipmentInformationComponent,
    PmPlanSummaryComponent,
    PmPlanParticularsComponent,
    PmPlanRemindersComponent,
    PmPlanPeriodsComponent,
    PmPlanRemarksComponent,
    PmPlanEquipmentListComponent,
    PmPeriodEquipmentListComponent,
    PmOutstandingEquipmentListComponent,
    PmPlanViewComponent,
    PmPlanEditComponent,
    SettingsSLAComponent,
    PopUpWithRadioComponent,
    SnBatchCompleteComponent,
    BatchCompleteStageHeaderComponent,
    BatchCompleteValidationResultComponent,
    FileSelectorComponent,
    SnBatchHistoryComponent,
    PmJobEquipmentListComponent,
    PmJobViewComponent,
    PmJobCcsInformationComponent,
    PmJobListAdvSearchComponent,
    CancelPmJobFormComponent,
    CcsSoObtainFormComponent,
    PmJobRemarksComponent,
    TransferSnFormComponent,
    PmJobAttachmentComponent,
    PmPlanCancelFormComponent,
    ApprovalListPmComponent,
    ApprovalRejectReasonPanelComponent,
    PmJobTimesheetComponent,
    PmJobTimesheetHistoryComponent,
    PmJobTimesheetStepsHeaderComponent,
    CcsSoObtainFormComponent,
    StockReserveDetailComponent,
    StockReserveListComponent,
    ReserveAiStorePanelComponent,
    JobcardStockReserveComponent,
    JobCardStandaloneCreateComponent,
    JobCardStandaloneViewComponent,
    JobCardStandaloneEditComponent,
    JobCardCancelFormComponent,
    SnBatchReportSubmissionComponent,
    SnBatchReportSubmissionHistoryComponent,
    SnBatchSubmissionStageHeaderComponent,
    CcepPortalSsoLoginSuccessComponent,
    CcepPortalSsoLogoutSuccessComponent,
    ReleaseNoteComponent,
    SnEFormComponent,
    ContractorCMJobComponent,
    PmsmcStatisticsComponent,
    SpecialRequestAlertListComponent,
    SpecialRequestAlertDetailComponent,
    JobcardJobListAdvSearchComponent,
    InspectionManagementComponent,
    UploadAttachmentTooltipComponent,
    PasswordErrorListComponent,
    ResetPasswordComponent,
    UploadAttachmentTableviewComponent,
    ArrivalGpsComponent,
    SpecialRequestAlertIconComponent,
    ContractInspectionReportSubmissionComponent,
    ContractInspectionReportSubmissionHistoryComponent,
    PmJobInspectionManagementComponent,
    TimesheetInputFormComponent,
    InspectionFormComponent,
    ContractInspectionReportSubmissionHistoryComponent,
    TimesheetJobV2Component,
    GpsLocationSubmittedTableComponent,
    TimetableComponent,
    WeatherEformComponent,
    CCePJMAccountReviewSendEmailComponent,
    CCePJMAccountReviewUploadTemplateComponent,
    CCePJMAccountReviewEmailHistoryComponent,
    CCePJMAccountReviewSubmissionHistoryComponent,
    NoPermissionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    Ng5SliderModule,
    PdfViewerModule,
    ReactiveFormsModule,
    MaterialModule,
    NgSelectModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ContactGroupService,
    AuthorizationService,
    CookieService,
    FormBuilder,
    InspectionFormComponent,
    InspectionManagementComponent,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    PopUpDialogComponent,
    InformationDialogComponent,
    ModalManuelFaxComponent,
    ModalOfficeHoursComponent,
    ModalHaEquipmentDetailsComponent,
    PopUpWithRadioComponent,
    GpsLocationSubmittedTableComponent
  ],
})

export class AppModule {
  constructor(private injector: Injector) {
    AppDelegate.appInjector = this.injector;
  }
}
