<div class="wrapper">
  <app-nav [navbarTitle]="title"></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content">
    <app-batch-complete-stage-header [stage]="stage"></app-batch-complete-stage-header>
    
    <ng-container *ngIf="isDownloadTemplateStage">
      <div class="mt-2">
        {{'component.sn-batch-complete.main.download-instruction' | translate}}
      </div>
      <!-- download button-->
      <app-submit-button
        [id]="'sn_batch_complete-downlaod_template_button'"
        [text]="'component.sn-batch-complete.main.button.download-template'"
        [buttonClass]="'standard brand-blue my-1'"
        [iconClass]="'fa-download'"
        [isDisabled]="button.downloadTemplate.isLoading || isDisabledAllButton"
        [isLoading]="button.downloadTemplate.isLoading"
        (onClick)="onClickedDownloadTemplateButton()"
      ></app-submit-button>
      <!-- next button -->
      <app-submit-button
        [id]="'sn_batch_complete-next_button'"
        [text]="'component.sn-batch-complete.main.button.next'"
        [buttonClass]="'standard brand-blue m-1'"
        [isDisabled]="isDisabledAllButton"
        (onClick)="onClickedNextButton()"
      ></app-submit-button>
    </ng-container>
    
    <ng-container *ngIf="isVerificationSuccessfulStage || isVerificationFailedStage">
      <app-batch-complete-validation-result [successCount]="validationResult.successCount"
        [failedCount]="validationResult.failedCount"></app-batch-complete-validation-result>
      <!-- submit button-->
      <app-submit-button
        *ngIf="isVerificationSuccessfulStage"
        [id]="'sn_batch_complete-submit_button'"
        [text]="'component.sn-batch-complete.main.button.submit'"
        [buttonClass]="'standard brand-amber mt-1 mb-4'"
        [iconClass]="'fa-save'"
        [isDisabled]="button.submit.isLoading || isDisabledAllButton || !selectedFile"
        [isLoading]="button.submit.isLoading"
        (onClick)="onClickedSubmitButton()"
      ></app-submit-button>
      <!-- download error report button -->
      <app-submit-button
        *ngIf="isVerificationFailedStage"
        [id]="'sn_batch_complete-download_error_report_button'"
        [text]="'component.sn-batch-complete.main.button.error-report'"
        [buttonClass]="'standard brand-red mt-1 mb-4'"
        [iconClass]="'fa-download'"
        [isDisabled]="isDisabledAllButton"
        (onClick)="onClickedDownloadErrorReportButton()"
      ></app-submit-button>
    </ng-container>

    <ng-container *ngIf="isUploadStage || isVerificationFailedStage">
      <div>{{uploadTitle}}</div>
      <div class="my-2 w-40">
        <app-file-selector [fileAcceptType]="'.xlsx'" [clear]="fileSelector.clear" [isDisabled]="isDisabledAllButton"
          (updatedFile)="onUpdatedFile($event)"></app-file-selector>
      </div>
      <!-- upload button-->
      <app-submit-button
        [id]="'sn_batch_complete-upload_button'"
        [text]="'component.sn-batch-complete.main.button.upload'"
        [buttonClass]="'standard brand-amber my-1'"
        [iconClass]="'fa-upload'"
        [isDisabled]="button.upload.isLoading || isDisabledAllButton || !selectedFile"
        [isLoading]="button.upload.isLoading"
        (onClick)="onClickedUploadButton()"
      ></app-submit-button>
      <!-- back button -->
      <app-submit-button
        [id]="'sn_batch_complete-back_button'"
        [text]="'component.sn-batch-complete.main.button.back'"
        [buttonClass]="'standard brand-blue m-1'"
        [isDisabled]="isDisabledAllButton"
        (onClick)="onClickedBackButton()"
      ></app-submit-button>
    </ng-container>

    <ng-container *ngIf="isSubmittedStage">
      <div class="mt-3">
        {{'component.sn-batch-complete.main.submitted-instruction-1' | translate}}
        <a routerLink="/sn/batch-complete/upload-history"> {{'component.sn-batch-complete.main.submitted-instruction-2' | translate}}</a>
        {{'component.sn-batch-complete.main.submitted-instruction-3' | translate}}
      </div>
    </ng-container>

  </div>
</div>