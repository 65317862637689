<div class="hashtag-group-form-wrap">
    <div class="title-wrap mx-0 mb-4">
        <h3 class="d-inline" *ngIf="parameters.mode=='add'" >{{ "add-hashtag-group-form.title.add" | translate }}</h3>
        <h3 class="d-inline" *ngIf="parameters.mode=='edit'">{{ "add-hashtag-group-form.title.edit" | translate }}</h3>
        <div class="button-wrap text-right d-inline-block float-right">
            <app-submit-button 
                [id]="'hashtagGroup_save_button'"
                [text]="'global.save'"
                [buttonClass]="' standard brand-amber mr-0'"
                [iconClass]="'fa-save'"
                [isDisabled]="!uiEnableSaveButton"
                [isLoading]="uiLoadingSaveButton"
                (onClick)="onSaveButtonClicked($event)"  
            ></app-submit-button>
            <ng-container *ngIf="parameters.mode=='edit'">
              <app-submit-button 
                [id]="'hashtagGroup_delete_button'"
                [text]="'global.delete'"
                [buttonClass]="'d-block standard brand-red mr-0 my-1'"
                [iconClass]="'fa-times'"
                [isDisabled]="!uiEnableDeleteButton"
                [isLoading]="uiLoadingDeleteButton"
                (onClick)="onDeleteButtonClicked($event)" 
            ></app-submit-button>
            </ng-container>
        </div>

        <div class="alert-font font-size-xs d-inline-block w-70" *ngIf="uiErrorMessage.length > 0">
          <div *ngFor="let msg of uiErrorMessage;" class="other-error-alert mb-2">
            {{msg}}
          </div>
        </div>
    </div>

      <form id='add-hashtagGroup-form'>

        <div class="bold mt-3">
            <span class="mandatory">*</span>{{ 'add-hashtag-group-form.row.title.name' | translate }}
          </div>
          <span class="alert-font font-size-xs" *ngIf="uiShowError && uiHashtagGroupName.length==0">
            {{ 'add-hashtag-group-form.error.null-name-zh' | translate }}
          </span>
          <input id="id_tag_group_name"
            name="uiHashtagGroupName"
            type="text"
            class="form-control"
            [(ngModel)]="uiHashtagGroupName"
            [class.input-error]="uiShowError && uiHashtagGroupName.length == 0"
          >
          
          <div class="bold mt-3">
            <span class="mandatory">*</span>{{'add-hashtag-group-form.row.title.status'| translate }}
           </div>
           <span class="alert-font font-size-xs" *ngif="">
             {{ '' | translate }}
           </span>
           <ng-select id="id_tag_group_status"
             name="uiHashtagGroupStatus"
             class="align-middle"
             bindLabel="label"
             bindValue="value"
             [(ngModel)]="uiHashtagGroupStatus"
             [items]="uiHashtagGroupStatusOptions"
             [multiple]="false" 
             [closeOnSelect]="true"
             [searchable]="true" 
             [clearable]="false"
             [hideSelected]="true" 
           ></ng-select>

           <!-- <div class="bold mt-3">
             <span class="mandatory">*</span>{{'add-hashtag-group-form.row.title.type'| translate }}
           </div>
           <span class="alert-font font-size-xs" *ngif="">
             {{ '' | translate }}
           </span> -->
           <!-- <ng-select id="id_tag_group_type"
             name="uiHashtagGroupType"
             class="align-middle"
             bindLabel="label"
             bindValue="value"
             [(ngModel)]="uiHashtagGroupType"
             [items]="uiHashtagGroupTypeArray"
             [multiple]="false" 
             [closeOnSelect]="true"
             [searchable]="true" 
             [hideSelected]="true" 
           ></ng-select>  -->

          <div class="bold mt-3">
            <span class="mandatory">*</span>{{'add-hashtag-group-form.row.title.IsNameOnDisplay'| translate }}
          </div>
          <span class="alert-font font-size-xs" *ngif="">
            {{ '' | translate }}
          </span>
          <ng-select id="id_tag_group_IsNameOnDisplay"
            name="uiHashtagGroupIsNameOnDisplay"
            class="align-middle"
            bindLabel="label"
            bindValue="value"
            [(ngModel)]="uiShowGroupName"
            [items]="uiShowGroupNameOptions"
            [multiple]="false" 
            [closeOnSelect]="true"
            [clearable]="false"
            [searchable]="false" 
            [hideSelected]="true" 
      
          ></ng-select>


          <div class="bold mt-3">
            {{ 'add-hashtag-group-form.row.title.Description' | translate }}
          </div>
          <input id="id_tag_group_description"
            name="uiHashtagGroupDescription"
            type="text"
            class="form-control"
            [(ngModel)]="uiHashtagGroupDescription"
          >

          <div class="bold mt-3">
            <span class="mandatory">*</span>{{'add-hashtag-group-form.row.title.WorkCentres'| translate }}
          </div>
          <span class="alert-font font-size-xs" *ngif="">
            {{ '' | translate }}
          </span>          
          <ng-select id="id_tag_group_workCentres"
            name="WorkCentreDropdown"
            class="align-middle"
            bindLabel="label"
            bindValue="value"
            [(ngModel)]="uiSelectedWorkCentreArray"
            [items]="uiWorkCentreOptions"
            [multiple]="true" 
            [searchable]="true" 
            [hideSelected]="true" 
        ></ng-select>
      </form>

     <p class="remarks mt-5">
       <span class="mandatory">*</span>{{ 'pages.contract-dummy.mandatory' | translate}}
     </p> 
</div>
