<div class="wrapper">
  <app-nav (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar></app-sidebar>

  <!-- Page Content -->
  <div id="content">
    <form>
      <div class="row justify-content-between row-space">
        <div class="col-auto align-self-center">
          <h2 class="title">
            {{ 'pages.report-download.pmsmc-statistics' | translate }}
          </h2>
        </div>
      </div>
      <hr class="underline" />

      <div id="search-panel" class="collapse show">
        <div class="row row-space">
          <div class="d-flex col-12 flex-wrap flex-wrap">
            <!-- Time Range -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <label for="report-download_start-date_inputbox" class="field-title reset">
                  {{ 'pages.report-download.job-completion-time' | translate }}
                </label>
              </div>
              <div class="input-field-group">
                <input
                  id="report-download_start-date_inputbox"
                  class="form-control date-picker-input-box"
                  name="jobCompletionDate"
                  placeholder="{{ 'pages.report-download.from-date' | translate }}"
                  readonly
                  ngbDatepicker
                  #jobCompletionDate="ngbDatepicker"
                  [maxDate]="setTodayDate()"
                  [disabled]="isDisabledNormalTimeRange"
                  [(ngModel)]="filterCriteria.jobCompletionDate"
                  (click)="jobCompletionDate.toggle()"
                />
                <div class="remark-container">
                  <span class="field-remark font-size-s px red">
                    {{ 'pages.report-download.job-completion-time.remark' | translate }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Status -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <label for="report-download_job-status_dropdown" class="field-title reset">{{ 'pages.report-download.job-status' | translate }}</label>
              </div>
              <div class="input-field-group">
                <div class="dropdwon-group">
                  <ng-select
                    id="report-download_job-status_dropdown"
                    [items]="statusOption"
                    bindLabel="label"
                    bindValue="id"
                    [(ngModel)]="filterCriteria.status"
                    placeholder="{{ 'pages.report-download.status-all' | translate }}"
                    [ngModelOptions]="{ standalone: true }"
                    [multiple]="true"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center row-space">
          <button
            id="export_excel_btn"
            type="button"
            class="standard brand-blue"
            (click)="onClickExportExcel()"
            [disabled]="isExportExcelButtonLoading"
          >
            <div *ngIf="isExportExcelButtonLoading" class="d-inline-block loading-spinner spinner-small">
              {{ 'global.loading' | translate }}
            </div>
            <span *ngIf="!isExportExcelButtonLoading">
              <i class="fas fa-download space"></i>
              {{ 'global.export-excel' | translate }}
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
