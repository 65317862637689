import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JM, JMENUM, JMUTILITY, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver';
import { AuthorizationService } from '@services/authorization.service';
import * as utility from 'src/app/services/utility';
import { Session } from '@services/session';

@Component({
  selector: 'app-pm-job-timesheet',
  templateUrl: './pm-job-timesheet.component.html',
  styleUrls: ['./pm-job-timesheet.component.scss'],
})
export class PmJobTimesheetComponent implements OnInit {
  stage: PmJobTimesheetComponentStages = PmJobTimesheetComponentStages.DOWNLOAD;

  // file
  selectedFile?: File;
  failedReport?: Blob;

  button = {
    downloadTemplate: {
      isLoading: false,
    },
    submit: {
      isLoading: false,
    },
    upload: {
      isLoading: false,
    },
  };

  fileSelector = {
    clear: ''
  }

  validationResult = {
    successCount: 0,
    failedCount: 0,
  };

  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  ngOnInit() {
    if(!utility.isEnabledFeature(Session,JMCONSTANT.JMFeature.JOB_CARD_TIMESHEET)) {
      this.router.navigate(['']);
      return;
    }
    if (!this.authorizationService.hasPermissions([JMENUM.Permission.TIMESHEET_UPDATE, JMENUM.Permission.TIMESHEET_VIEW] , true)) {
      this.router.navigate(['/']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
  }

  private async requestPmJobTimesheetTemplate() {
    let request = new JM.JMRequestTimesheetExportTimesheetExcelTemplate();

    this.button.downloadTemplate.isLoading = true;
    const response: JM.JMResponseTimesheetExportTimesheetExcelTemplate = await AppDelegate.sendJMRequest(request);
    this.button.downloadTemplate.isLoading = false;

    if (!response) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.saveExcel(response, 'timesheet-template');
    this.switchStage(PmJobTimesheetComponentStages.UPLOAD);
  }

  onClickedDownloadTemplateButton() {
    this.requestPmJobTimesheetTemplate();
  }

  onClickedNextButton() {
    this.switchStage(PmJobTimesheetComponentStages.UPLOAD);
  }

  onClickedBackButton() {
    this.switchStage(PmJobTimesheetComponentStages.DOWNLOAD);
  }

  onUpdatedFile(file?: File) {
    this.selectedFile = file;
  }

  onClickedUploadButton() {
    if (this.validateFile()) {
      this.requestValidateBatchCompleteTimesheet();
      this.setValidationResult(0, 0);
    }
  }

  onClickedSubmitButton() {
    if (this.validateFile()) {
      this.requestSubmitBatchCompleteTimesheet();
    }
  }

  validateFile() {
    if (!this.selectedFile) {
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-complete.message.file-not-found'));
      return false;
    }

    // check file type
    if (this.selectedFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-complete.message.upload-xlsx-file'));
      return false;
    }

    // check file size
    const fileSizeMB = this.selectedFile.size / 1024 / 1024; // B to MB
    if (fileSizeMB >= 10) { // cannot larger than 10 MB
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-complete.message.file-size-too-large'));
      return false;
    }

    return true;
  }

  async requestValidateBatchCompleteTimesheet() {
    let request = new JM.JMRequestTimesheetValidateTimesheetExcelInput();

    request.stream = new FormData;
    request.stream.set('files', this.selectedFile, this.selectedFile.name);

    this.button.upload.isLoading = true;
    const response: JM.JMResponseTimesheetValidateTimesheetExcelInput = await AppDelegate.sendJMRequestFile(request, request.stream);
    this.button.upload.isLoading = false;

    if (!response || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    const payload = response.payload;
    this.setValidationResult(payload.successCount, payload.failedCount);

    if (payload.failedCount === 0) {
      this.switchStage(PmJobTimesheetComponentStages.VERIFICATION_SUCCESSFUL);
    } else {
      this.switchStage(PmJobTimesheetComponentStages.VERIFICATION_FAILED);
      this.selectedFile = undefined;
      this.clearFileSelector();

      const base64 = payload.reportFile;
      this.failedReport = utility.base64ToBlob(base64, payload.fileType);
    }
  }

  async requestSubmitBatchCompleteTimesheet() {
    let request = new JM.JMRequestTimesheetSubmitTimesheetExcelInput();

    request.stream = new FormData;
    request.stream.set('files', this.selectedFile, this.selectedFile.name);

    this.button.submit.isLoading = true;
    const response: JM.JMResponseTimesheetSubmitTimesheetExcelInput = await AppDelegate.sendJMRequestFile(request, request.stream);
    this.button.submit.isLoading = false;

    if (!response || response.code !== 200) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.switchStage(PmJobTimesheetComponentStages.SUBMITTED);
  }

  onClickedDownloadErrorReportButton() {
    if (this.failedReport) {
      this.saveExcel(this.failedReport, 'Upload Timesheet Report');
    }
  }

  clearFileSelector() {
    this.fileSelector.clear = Math.random().toString();
  }

  setValidationResult(successCount: number, failedCount: number) {
    this.validationResult.successCount = successCount;
    this.validationResult.failedCount = failedCount;
  }

  switchStage(targetStage: PmJobTimesheetComponentStages) {
    this.stage = targetStage;
  }

  saveExcel(file: any, fileNamePrefix: string) {
    const dateTime = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    const fileName = `${fileNamePrefix} ${dateTime}.xlsx`;
    saveAs(file, fileName);
  }

  //==============================
  // get property
  get isStageDownload(): boolean {
    return this.stage === PmJobTimesheetComponentStages.DOWNLOAD;
  }
  get isStageUpload(): boolean {
    return this.stage === PmJobTimesheetComponentStages.UPLOAD;
  }
  get isStageSubmitted(): boolean {
    return this.stage === PmJobTimesheetComponentStages.SUBMITTED;
  }
  get isStageVerificationSuccessful(): boolean {
    return this.stage === PmJobTimesheetComponentStages.VERIFICATION_SUCCESSFUL;
  }
  get isStageVerificationFailed(): boolean {
    return this.stage === PmJobTimesheetComponentStages.VERIFICATION_FAILED;
  }

  get title(): string {
    return JMLanguage.translate('component.pm-job-timesheet.title');
  }
  get uploadTitle(): string {
    if (this.isStageUpload) {
      return JMLanguage.translate('component.sn-batch-complete.main.download-instruction');
    }
    if (this.isStageVerificationFailed) {
      return JMLanguage.translate('component.sn-batch-complete.main.upload-again-instruction');
    }
    return '';
  }


  get isDisabledAllButton(): boolean {
    const isDisabled =
      this.button.downloadTemplate.isLoading || this.button.submit.isLoading || this.button.upload.isLoading;
    return isDisabled;
  }
}

export enum PmJobTimesheetComponentStages {
  DOWNLOAD,
  UPLOAD,
  SUBMITTED, 
  VERIFICATION_SUCCESSFUL,
  VERIFICATION_FAILED,
}
