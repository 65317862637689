<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'team'"></app-sidebar>

  <div id="content">
    <!-- <div class="container"> -->
    <div class="row">
      <div class="col-md-3">
        <team-selector #inhouseTeamSelector 
        [mode]="'inhouse'"
        [workCentre]="selectedWorkCentre"
        (onTeamChanged)="onTeamChanged($event)"
      ></team-selector>


      <team-selector #contractorTeamSelector 
        [mode]="'contractor'"
        [workCentre]="selectedWorkCentre"
        (onTeamChanged)="onTeamChanged($event)"
      ></team-selector>
      </div>

      <div class="col-md">
       
        <ng-container *ngIf="isLoadingTeams; else contentDetail">
          <div class="row mt-5">
            <div class="col d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">{{"global.loading" | translate}}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #contentDetail>
          <div class="row">
            <div class="col m-3">
              <ng-container *ngIf="!selectedTeam; else teamDetail">
                <div class="row mt-5">
                  <div class="col d-flex justify-content-center h5">
                    {{ 'pages.team-setting.team-not-found' | translate}}
                  </div>
                </div>
              </ng-container>
              <ng-template #teamDetail>

                <!-- copy manual instruction and help container -->
                <div class="row align-items-center">
                  <!-- copy manual instruction -->
                  <ng-container *ngIf="hasEditionPermission && instructionConfigList && instructionConfigList.length > 0">
                    <div class="col-lg-10 my-2">
                      <div class="row align-items-center ">
                        <div class="col ml-2 align-self-center">
                          {{ 'pages.team-settings.copy-instruction-to' | translate }}
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-md-10">
                          <ng-select
                            id="settings-team-instruction_copy-team_dropdown"
                            class="align-self-center my-1"
                            bindLabel="name"
                            bindValue="_id"
                            [items]="copyTeamList"
                            [(ngModel)]="selectedCopyToTeam"
                            [clearable]="false"
                            [searchable]="true">
                          </ng-select>
                        </div>
                        <div class="col-md-2">
                          <app-submit-button 
                            [id]="'settings-team-instruction_copy-team_button'"
                            [text]="'global.copy'"
                            [buttonClass]="'standard brand-amber'"
                            [iconClass]="'fa-file'"
                            [isDisabled]="requestButtonDisabled"
                            [isLoading]="requestCopyTeamLoading"
                            (onClick)="onCopyTeamBtnClicked()" 
                          ></app-submit-button>
                        </div>
                      </div>
                      <div class="row" *ngIf="copyTeamErrorMessage">
                        <div class="col">
                          <i class="fas fa-exclamation-circle copy-team-error-icon"></i>
                          {{ copyTeamErrorMessage }}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- TODO: help -->
                  <div class="col-auto ml-auto">
                    <!-- <div class="row">
                      <div class="col my-2">
                        <a href="http://emsdhome/" target="_blank">
                          {{ 'global.help' | translate }}
                          <i class="fas fa-question-circle help-icon"></i>
                        </a>
                      </div>
                    </div> -->
                  </div>
                </div>

                <fieldset class="row custom-fieldset mt-3">
                  <!-- enable mauanl instruction checkbox -->
                  <legend>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input"
                        id="settings-team-instruction_enable-manual-instruction_checkbox"
                        [disabled]="!hasEditionPermission"
                        [(ngModel)]="enableManualInstruction">
                      <label class="custom-control-label"
                        for="settings-team-instruction_enable-manual-instruction_checkbox">
                        {{'pages.team-setting.enable-manual-instruction' | translate}}
                      </label>
                    </div>
                  </legend>

                  <div class="container">
                    <!-- view office hours -->
                    <div class="row">
                      <div class="col d-flex justify-content-end mx-3 mb-3">
                        <app-submit-button 
                          [id]="'settings-team-instruction_view-working-hours_button'"
                          [text]="'pages.team-settings.view-office-hours'"
                          [buttonClass]="'standard float-right'"
                          [isDisabled]="isLoadingOfficeHours"
                          [isLoading]="isLoadingOfficeHours"
                          (onClick)="onOfficeHoursBtnClicked()" 
                        ></app-submit-button>
                      </div>
                    </div>
                    
                    <!-- instruction option and button container -->
                    <ng-container *ngIf="hasEditionPermission && instructionConfigList && instructionConfigList.length > 0">
                      <div class="row">
                        <!-- manual instruction options dropdown -->
                        <div 
                          id="configOptionListId"
                          class="col-xl-9"
                          cdkDropList
                          [cdkDropListData]="instructionConfigOptionList"
                          [cdkDropListConnectedTo]="dropConnectedList"
                        >
                          <button class="btn input-border" cdkDrag>
                            <div class="row drag-panel">
                              <div class="col" style="width: 500px;">
                                <ng-select
                                  id="dropdown"
                                  [items]="instructionConfigList"
                                  bindLabel="description"
                                  bindValue="manualInstructionId"
                                  [(ngModel)]="instructionConfigOptionList[0].manualInstructionId"
                                  [clearable]="false"
                                  [searchable]="false"
                                  (change)="onInstructionConfigChanged($event)"
                                  >
                                </ng-select>
                              </div>
                              <ng-container *ngIf="instructionConfigOptionList[0].inputType != 0">
                                <div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                                <div class="col">
                                  <input id="instruction_inputbox" class="custom-input-text-box" type="text" maxlength="100"
                                    placeholder="{{ 'pages.team-setting.instruction-contact-information-placeholder' | translate }}"
                                    [(ngModel)]="instructionConfigOptionList[0].remarks"  />
                                </div>
                              </ng-container>
                            </div>
                          </button>
                        </div>
  
                        <ng-container *ngIf="hasEditionPermission && !isLoadingTeams">
                          <div class="col-xl-3 d-flex justify-content-end">
                            <!-- clear button -->
                            <app-submit-button 
                              [id]="'settings-team-instruction_clear_button'"
                              [text]="'global.clear'"
                              [buttonClass]="'standard brand-red mr-3 mt-2'"
                              [iconClass]="'fa-trash'"
                              (onClick)="onClearBtnClicked()" 
                            ></app-submit-button>
                            <!-- submit button -->
                            <app-submit-button 
                              [id]="'settings-team-instruction_save_button'"
                              [text]="'global.save'"
                              [buttonClass]="'standard brand-amber mr-3 mt-2'"
                              [iconClass]="'fa-save'"
                              [isDisabled]="requestButtonDisabled"
                              [isLoading]="requestUpdateTeamLoading"
                              (onClick)="onSaveBtnClicked()" 
                            ></app-submit-button>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
  
                    <!-- priority title container -->
                    <div class="row mx-2 mt-3">
                      <ng-container *ngFor="let item of priorityTitleViewData; let i = index; let last = last">
                        <div class="col d-flex my-1 mx-1 align-items-center justify-content-center {{item.titleCss}}">
                          <span>{{item.text | translate}}</span>
                        </div>
                        <button *ngIf="!last" class="btn copy-btn mx-1" (click)="onCopyPriorityInstructionGroupClicked(item.copyFormPriorityKey, item.copyToPriorityKey)" >
                          <i class="fa fa-arrow-right"></i>
                        </button>
                      </ng-container>
                    </div>
                    
                    <!-- instruction group container -->
                    <div class="row">
                      <ng-container *ngFor="let priorityItem of instructionViewData">
                        <div class="col mx-3 mb-3">
                          <ng-container *ngFor="let stateItem of priorityItem.stateItems">
                            <div class="row row-space">{{stateItem.text | translate}}</div>
  
                            <!-- loop the group instructions -->
                            <ng-container *ngFor="let instructionList of stateItem.instructionGroupList; let instructionGroupIndex = index">
                              <div class="row mb-2">
                                <div 
                                  class="col-md cdkdrop-list"
                                  id="{{ this.generateGroupId(priorityItem.id, stateItem.id, instructionGroupIndex) }}"
                                  cdkDropList
                                  [cdkDropListData]="instructionList"
                                  [cdkDropListConnectedTo]="dropConnectedList"
                                  [cdkDropListDisabled]="!hasEditionPermission"
                                  (cdkDropListDropped)="onDroppedToInstructionGroup($event, stateItem.instructionGroupList)"
                                >
                                  <div class="cdkdrop-box" cdkDrag *ngFor="let instruction of instructionList; let instructionIndex = index">
                                    <div class="btn custom-drop-btn {{priorityItem.buttonCss}}" [ngClass]="{'light': instructionList.length > 1}">
                                      {{ instructionList.length > 1 ? (instructionIndex + 1) + '. ' : ''}}{{ instruction.description }}{{ instruction.remarks ? ' - ' + instruction.remarks : ''}}
                                      <ng-container *ngIf="hasEditionPermission">
                                        <button
                                          class="btn remove-btn"
                                          [ngClass]="{'light': instructionList.length > 1}"
                                          (click)="onRemoveInstructionClicked(stateItem.instructionGroupList, instructionGroupIndex, instructionList, instructionIndex)"
                                        >
                                          <i class="fa fa-times "></i>
                                        </button>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            <!-- add new group -->
                            <div *ngIf="stateItem.instructionGroupList.length < 5" class="row mb-1">
                              <div 
                                class="col-md cdkdrop-list"
                                id="{{ this.generateGroupId(priorityItem.id, stateItem.id) }}"
                                cdkDropList
                                [cdkDropListData]="stateItem.createInstructionGroupList"
                                (cdkDropListDropped)="onDroppedToNewInstructionGroup($event, stateItem.instructionGroupList)"
                                >
                                <div *ngIf="hasEditionPermission" class="btn custom-drop-btn new-group text-center">
                                  {{'pages.team-setting.add-new-group' | translate}}
                                </div>
                              </div>
                            </div>
                            
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                </fieldset>

              </ng-template>
            </div>
          </div>
        </ng-template>
            
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>