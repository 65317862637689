import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';

const inHouseTableHeader = [
  {
    id: 'post',
    name: "pages.team-member.login-id",
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'name',
    name: "pages.team-member.staff-name",
    enableFilter: true,
    type: TablexColumnType.MultiLine,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'employeeCode',
    name: "pages.team-member.employee-code",
    enableFilter: false,
    type: TablexColumnType.MultiLine,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'phone',
    name: "pages.team-member.phone",
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'mobile',
    name: "pages.team-member.mobile",
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'email',
    name: "pages.team-member.email",
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  }
];

const nonPmsmcTableHeader = [
  {
    id: 'name',
    name: "pages.team-member.member-name",
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'phone',
    name: "pages.team-member.phone-number",
    enableFilter: true,
    type: TablexColumnType.Input,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'mobile',
    name: "pages.team-member.sms",
    enableFilter: true,
    type: TablexColumnType.Input,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'fax',
    name: "pages.team-member.fax",
    enableFilter: true,
    type: TablexColumnType.Input,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  },
  {
    id: 'email',
    name: "pages.team-member.email",
    enableFilter: true,
    type: TablexColumnType.Input,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle
  }
];

const pmsmcTableHeader = [
  {
    id: 'name',
    name: "pages.team-member.member-name",
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-2",
  },
  {
    id: 'teamName',
    name: "pages.team-member.team-name",
    enableFilter: false,
    type: TablexColumnType.MultiLine,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-2",
  },
  {
    id: 'role',
    name: "pages.team-member.role",
    enableFilter: false,
    type: TablexColumnType.MultiLine,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-2",
  },
  {
    id: 'phone',
    name: "pages.team-member.phone-number",
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-2",
  },
  {
    id: 'fax',
    name: "pages.team-member.fax",
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-2",
  },
  {
    id: 'email',
    name: "pages.team-member.email",
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-2",
  }
];

export {
  inHouseTableHeader,
  nonPmsmcTableHeader,
  pmsmcTableHeader,
}