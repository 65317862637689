<div class="wrapper">
    <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>
    <app-sidebar></app-sidebar> 
    <div id="content">

        <div class="row justify-content-between no-gutters">
            <h4 class="title">
              {{"pages.hashtag-group.page-header" | translate}}
            </h4>
            <div class="button-container float-right">
                <div class="add-btn-wrap">
                    {{'add-hashtag-group-form.title.add' | translate }}
                    <button type="button" class="rectangle brand-blue add-hashtagGroup-btn ml-1" id="add-hashtag-Group-btn" (click)="onAddHashtagGroupBtnClicked()">
                        <i class="fas fa-plus"></i>
                      </button>
                </div>
            </div>
        </div>
        <br>
        <hr class="underline" />
        

        <app-tablex [tablexParam]="tablexParam"></app-tablex>

        <custom-slider-panel  #add_hashtag_group_panel [customClass]="'add_hashtagGroup_panel'">
            <add-hashtag-group-form #addHashtagGroupForm [parameters]="addHashtagGroupParameters"></add-hashtag-group-form>
        </custom-slider-panel>

    </div>
  </div>