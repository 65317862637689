import { Component, Injector, Input, OnInit } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { Permission } from '@enum/permission';
import { Session }  from 'src/app/services/session';

@Component({
  selector: 'remark-history',
  templateUrl: './remark-history.component.html',
  styleUrls: ['./remark-history.component.scss']
})


export class RemarkHistoryComponent implements OnInit {
  protected authorizationService: AuthorizationService;
  @Input() remarks: any[];
  @Input() editMode: boolean;

  constructor(private injector: Injector) {
    this.authorizationService = injector.get(AuthorizationService);
  }
  
  lang: string;
  showVisibilityType: any = {};

  // sn remarks related permission
  permissions = {
    view: {
      public: Permission.snRemarksPublicView, 
      private: Permission.snRemarksPrivateView, 
      invisible: Permission.snRemarksInvisibleView
    },
    update: {
      public: Permission.snRemarksPublicUpdate, 
      private: Permission.snRemarksPrivateUpdate, 
      invisible: Permission.snRemarksInvisibleUpdate
    }
  }

  ngOnInit() {
    this.lang = Session.selectedLanguage; //TODO: remove lang

    let visibilityTypes = ["public", "private", "invisible"];
    visibilityTypes.forEach(type => {
      this.showVisibilityType[type] = this.authorizationService.hasPermission(this.permissions.update[type]);
    });
  }

  ngOnDestroy() {
  }

  ngOnChanges() {
    if (!this.remarks) return;
    let remarks = [];

    // remove remarks which has no permission to view
    this.remarks.forEach(remark => {
      if (this.authorizationService.hasPermission(this.permissions.view[remark.visibilityType]))
        remarks.push(remark);
    });

    // lastest remarks will show first
    remarks.sort((row1, row2) => {
      return row2.createdAt.localeCompare(row1.createdAt);
    });

    this.remarks = remarks;
  }

  onVisibilityChanged(index, visibility) {
    if (!this.editMode) return;
    this.remarks[index].visibilityType = visibility;
  }

}
