import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { formatDate, ngbDateToString, toNgbDate } from 'src/lib/presenter/Formatter';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { PmJobListAdvSearchComponent } from '../../components/pm-job-list-adv-search/pm-job-list-adv-search.component';
import { Session } from '@services/session';
@Component({
  selector: 'app-pm-plan-periods',
  templateUrl: './pm-plan-periods.component.html',
  styleUrls: ['./pm-plan-periods.component.scss']
})
export class PmPlanPeriodsComponent implements OnInit {
  @Input() plan;
  @Input() pageMode: JMENUM.JMPageMode;
  @Output() onRefreshPeriod: EventEmitter<any> = new EventEmitter();

  FormatDate = formatDate;
  periodPagination = {
    pageNumber: 1,
    totalPageCount: 1,
    pageSize: 1000,
  }

  searchSelections: PmJobListAdvSearchComponent['searchSelections'] = {
    contractNumber: [],
    pmJobNumber: null,
    pmPeriod: null,
    pmPlanNumber: null,
    equipmentNumber: null,
    planStatus: {},
    startRange: null,
    ngbStartDate: null,
    endRange: null,
    ngbEndDate: null,
    jobDescription: null,
    createTimeFrom: null,
    createTimeTo: null,
    handlingTeam: [],
  };

  //For HTML
  PMPlanFrequency = JMENUM.PMPlanFrequency;
  PlanCoverage = JMENUM.PMPlanCoverage;
  ScheduleType = JMENUM.PMScheduleType;
  PMStatus = JMENUM.PMStatus;
  JMPageMode = JMENUM.JMPageMode;

  isDisabledReset: boolean = true;

  // Validation 
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};

  // Input Fields
  overhaulDateNgb: any = [];

  // Period Status dropdown
  statusOptions: any = [];
  selectedStatus: string;

  // Cache
  periodEquipmentCountMap = new Map<string, number>(); // key: periodId, value: periodEquipmentCount

  constructor() { }

  ngOnInit() {
    this.statusOptions = [
      { label: JMLanguage.translate("pm.status.completed"), value: JMENUM.PMStatus.COMPLETED },
      { label: JMLanguage.translate("pm.status.in_progress"), value: JMENUM.PMStatus.IN_PROGRESS },
      { label: JMLanguage.translate("pm.status.outstanding"), value: JMENUM.PMStatus.OUTSTANDING },
    ];
  }

  ngOnChanges() {
    this.requestPmPeriodList(this.periodPagination.pageNumber).then(async (records) => {
      this.plan.periods = records;

      while (this.periodPagination.pageNumber < this.periodPagination.totalPageCount) {
        const additionalPeriods = await this.requestPmPeriodList(++this.periodPagination.pageNumber);
        this.plan.periods = [
          ...this.plan.periods,
          ...additionalPeriods,
        ]
      }
      this.initOverhaulDateInput();
    });
    
    // this.requestPmPeriodList().then(_ => {
    //   this.requestPmPeriodEquipmentCount();
    // });
  }

  // ----------- API ----------- //
  private async requestPmPeriodList(pageNumber: number) {
    if (!this.plan['pmPlanNumber']) return;
    const request: JM.JMRequestGetPmPeriodList = new JM.JMRequestGetPmPeriodList();
    request.pmPlanNumber = [this.plan.pmPlanNumber];
    request.pageSize = this.periodPagination.pageSize;
    pageNumber && (request.pageNumber = pageNumber);
    request.includeEquipmentCount = true;

    const response: JM.JMResponseGetPmPeriodList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    pageNumber == 1 && (this.periodPagination.totalPageCount = Math.ceil(response.payload.totalCount / this.periodPagination.pageSize));
    return response.payload.records;
  }

  onNumberOfJobsClick(data) {
    this.clearPmJobListSettingsFiltersSession();
    this.setPmJobListSettingsFilters(data);
    this.saveFilterOptionsToLocal();
    this.toPmJobList();
  }

  private clearPmJobListSettingsFiltersSession() {
    Session.setPmJobListSettingsFilters(null);
  }

  private setPmJobListSettingsFilters(data) {
    this.searchSelections = {
      ...this.searchSelections,
      pmPeriod: data._id ? data._id : null,
      pmPlanNumber: data.pmPlanNumber ? data.pmPlanNumber : null,
    }
  }

  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      Session.setPmJobListSettingsFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  toPmJobList() {
    AppDelegate.navigate(['/pm/job-list']);
  }

  private async requestPmPeriodEquipmentCount() {
    if (!this.plan['pmPlanNumber']) return;
    let request: JM.JMRequestGetPmPeriodEquipmentCount = new JM.JMRequestGetPmPeriodEquipmentCount();
    request.pmPlanNumber = this.plan.pmPlanNumber;

    const response: JM.JMResponseGetPmPeriodEquipmentCount = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    for (const record of response.payload.records) {
      this.periodEquipmentCountMap.set(record.periodId, record.equipmentCount);
    }

    this.plan.periods.forEach(period => {
      const periodId = period.periodId;
      period.equipmentCount = this.periodEquipmentCountMap.has(periodId) ? this.periodEquipmentCountMap.get(periodId) : 0;
    });
  }

  public async requestPmPeriodCalculate() {
    if (this.plan.status && this.plan.status != this.PMStatus.DRAFT) return;
    const request: JM.JMRequestPmPlanPmPeriodCalculate = new JM.JMRequestPmPlanPmPeriodCalculate();
    request.frequency = this.plan.frequency;
    request.scheduleType = this.plan.scheduleType;
    request.startDate = this.plan.startDate;
    request.endDate = this.plan.endDate;
    if (this.plan.pmPlanNumber) {
      request.pmPlanNumber = this.plan.pmPlanNumber;
    }
    if ((this.plan.scheduleType == this.ScheduleType.OVERHAUL || this.plan.scheduleType == this.ScheduleType.NORMAL) && this.plan.secondStartDate) {
      request.secondStartDate = this.plan.secondStartDate;
    }

    const response: JM.JMResponsePmPlanPmPeriodCalculate = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.plan.periods = response.payload;
    this.initOverhaulDateInput();
  }

  // ----------- UI function ----------- //
  public initOverhaulDateInput() {
    if (this.plan.scheduleType == this.ScheduleType.OVERHAUL) {
      this.overhaulDateNgb = [];
      this.plan.periods.forEach((record, r) => {
        if (record.index == 1) {
          if (record.status == this.PMStatus.IN_PROGRESS) {
            this.isDisabledReset = true;
          } else {
            this.isDisabledReset = false;
          }
        }
        this.overhaulDateNgb.push({
          index: record.index,
          startDate: toNgbDate(record.overhaulStartDate, "YYYYMMDD"),
          endDate: toNgbDate(record.overhaulEndDate, "YYYYMMDD"),
          isErrorStartDate: false,
          isErrorEndDate: false
        });
      });
    }
  }

  public onBlurStartDateInput(event) {
    this.plan.periods.find(period => {
      if (period.index == event.field) {
        period['overhaulStartDate'] = ngbDateToString(event.data);
        if (this.validateOverhaulStarEnd(period, true) || this.validateOverhaulStartToPeriod(period)) {
          this.overhaulDateNgb.find(field => field.index)['isErrorStartDate'] = true;
        } else {
          this.overhaulDateNgb.find(field => field.index)['isErrorStartDate'] = false;
        }
      }
    });
  }

  public onBlurEndDateInput(event) {
    this.plan.periods.find(period => {
      if (period.index == event.field) {
        period['overhaulEndDate'] = ngbDateToString(event.data);
        if (this.validateOverhaulStarEnd(period) || this.validateOverhaulEndToPeriod(period)) {
          this.overhaulDateNgb.find(field => field.index)['isErrorEndDate'] = true;
        } else {
          this.overhaulDateNgb.find(field => field.index)['isErrorEndDate'] = false;
        }
      }
    });
  }

  public onClickRefresh() {
    if (
      ((this.plan.frequency && this.plan.scheduleType) || this.plan.scheduleType == this.ScheduleType.ONCE)
      && this.plan.startDate && this.plan.endDate
    ) {
      this.onRefreshPeriod.emit();
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.please-fill-in-mandatory-fields"));
    }
  }

  public clearAll() {
    this.plan.periods = undefined;
  }

  public validateOverhaulStarEnd(period, isStartDate?) {
    // check if overhaulStartDate < overhaulEndDate
    if (period['overhaulStartDate'] && period['overhaulEndDate']) {
      let differ = moment(period['overhaulEndDate'], "YYYYMMDD").diff(moment(period['overhaulStartDate'], "YYYYMMDD"), 'days');
      if (differ <= 0) {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-invalid-overhaul"));
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public validateOverhaulStartToPeriod(period) {
    // check if periodStartDate < overhaulStartDate
    if (period['overhaulStartDate'] && period['periodStartDate']) {
      let differ = moment(period['overhaulStartDate'], "YYYYMMDD").diff(moment(period['periodStartDate']), 'days');
      if (differ < 0) {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-invalid-overhaul"));
        return true;
      } else {
        return false;
      }
    }
    return false;
  }


  public validateOverhaulEndToPeriod(period) {
    // check if periodEndDate < overhaulEndDate
    if (period['overhaulEndDate'] && period['periodEndDate']) {
      let differ = moment(period['periodEndDate']).diff(moment(period['overhaulEndDate'], "YYYYMMDD"), 'days');
      if (differ < 0) {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-invalid-overhaul"));
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public checkIsOutstandingNormalOnce(period): boolean {
    if (this.plan.scheduleType == JMENUM.PMScheduleType.NORMAL || this.plan.scheduleType == JMENUM.PMScheduleType.ONCE) {
      if (period.status === "outstanding" && moment(period.periodEndDate).endOf('day').isBefore(moment())) {
        return true;
      }
    }
  }
  public checkIsOutstandingOverhaul(period): boolean {
    if (this.plan.scheduleType == JMENUM.PMScheduleType.OVERHAUL) {
      if (period.status === "outstanding" && moment(period.overhaulEndDate).endOf('day').isBefore(moment())) {
        return true;
      }
    }
  }
}
