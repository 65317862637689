import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-special-request-alert-icon',
  templateUrl: './special-request-alert-icon.component.html',
  styleUrls: ['./special-request-alert-icon.component.scss']
})
export class SpecialRequestAlertIconComponent implements OnInit {
  vvip_indicator_iconpath = "/assets/img/vvip_indicator.png"
  constructor() { }

  ngOnInit() {
  }

}
