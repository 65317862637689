<div class="header d-flex justify-content-between">
  <h3>{{ 'component.reserve-stock.ai-store-panel.title' | translate }}</h3>
  <div class="submit-wrap d-inline">
    <app-submit-button 
      [id]="'transfer-sn-panel_submit_button'"
      [text]="'global.submit'"
      [buttonClass]="'standard brand-amber'"
      [iconClass]="'fa-check'"
      [isDisabled]="!submitEnable || isLoading"
      [isLoading]="isLoading"
      (onClick)="onClickSubmit()" 
    ></app-submit-button>
  </div>
</div>

<div class="col-12 mt-3 font-size-m">
  <span class="mandatory-mark">*</span>{{ 'component.reserve-stock.ai-store-panel.cost-centre' | translate }}
  <ng-select
    id="ai_store_cost_centre_dropdown"
    bindValue="value"
    placeholder=""
    [items]="costCentres"
    [searchable]="true"
    [ngModelOptions]="{standalone: true}"
    (change)="validateForm()"
    [(ngModel)]="selectedCostCentre"
  ></ng-select>
</div>

<div class="col-12 mt-3 font-size-m">
  <span class="mandatory-mark">*</span>{{ 'component.reserve-stock.ai-store-panel.estimate-pickup-date' | translate }}
  <app-date-input 
    class="col-12 m-0 mb-2 p-0"
    [isError]=""
    [input]="defaultNgbDate"
    [inputId]=""
    [field]="" 
    [dateAlertMsg]="dateAlertMsg"
    [enableDateString]="availableDates"
    (onBlur)="onBlurDateInput($event)"
  ></app-date-input>
</div>

<div class="col-12 mt-3 font-size-m">
  <span class="mandatory-mark">*</span>{{ 'component.reserve-stock.ai-store-panel.consignee-post' | translate }}
  <ng-select
    id="ai_store_post_dropdown"
    bindLabel="name"
    bindValue="name"
    placeholder=""
    [items]="posts"
    [searchable]="true"
    [ngModelOptions]="{standalone: true}"
    (search)="onSearchPostName($event)"
    (clear)="onClearPostName()"
    (change)="onPostChange()"
    (scrollToEnd)="onPostScrollToEnd()"
    [(ngModel)]="selectedPost"
  ></ng-select>
</div>

<div class="col-12 mt-3 font-size-m">
  <span class="mandatory-mark">*</span>{{ 'component.reserve-stock.ai-store-panel.consignee-contact-no' | translate }}

  <input
    class="form-control"
    type="text"
    maxlength="8"
    placeholder=""
    [disabled]="false"
    [class.input-error]="inputError?.contactNumber"
    [(ngModel)]="contactNumber"
  />
</div>

<div class="col-12 mt-3">
  <span class="mandatory-mark">*</span>{{ 'global.mandatory-fields' | translate }}
</div>
