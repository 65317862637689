<div id="custom-dropdown-options-wrap" class="custom-dropdown-options-wrap">

  <div id="custom-options-header-wrap" class="custom-options-header-wrap border-bottom row mx-0 justify-content-between align-items-center">
    <ng-container *ngIf="!parameters.header?.title">
      <div class="header-title col-auto" *ngIf="!parameters.isEditMode">
        {{ optionList[parameters.header.id] }}
      </div>
      <div class="header-title col px-0" [class.d-none]="!parameters.isEditMode" >
        <input [type]="InputFieldType.text" class="form-control" id="optionsTitle-input" 
          [(ngModel)]="optionList[parameters.header.id]" [disabled]="parameters.header?.disabled" (change)="parameters.header.onChange(); onChangeFields();"
          [class.input-error]="parameters.header.mandatory && !optionList[parameters.header.id] && parameters.showFieldsError"
        />
      </div>
    </ng-container>
    <ng-container *ngIf="parameters.header?.title">
      <div class="header-title col-auto" [ngClass]="parameters.header.customClass">
        {{ parameters.header.title }}
      </div>
    </ng-container>

    <div class="header-btn-wrap col-auto" *ngIf="parameters.headerBtn" [class.d-none]="parameters.header?.hideBtn">
      <button *ngFor="let button of parameters.headerBtn; let b = index" class="btn"
        [ngClass]="button.customClass" [type]="button?.type" [id]=" 'header-btn-' + button.id" 
        [disabled]="button?.disabled" (click)="$event.stopPropagation();button.onClick()" 
      >
        <i *ngIf="button.icon" [class]="button.icon" [id]=" 'header-btn-' + button.id+ '-icon' "></i>
        <span [id]=" 'header-btn-' + button.id + '-text'" class="header-btn-text">{{ button.text }}</span>
      </button>
    </div>
  </div>

  <div id="custom-options-body-wrap" class="custom-options-body-wrap" *ngIf="parameters.fieldList">

    <div class="custom-option-label-row row mx-0 mt-3 justify-content-start align-items-center">
      <ng-container *ngFor="let field of parameters.fieldList; let f = index">
        <ng-container *ngIf="field.supportLang">
          <div class="field-title col" [ngClass]="field?.titleCustomClass" *ngFor="let description of field.label | keyvalue">
            {{ description.value }}
          </div>
        </ng-container>
        <ng-container *ngIf="!field.supportLang">
          <div class="field-title col" [ngClass]="field?.titleCustomClass" >
            {{ field.title }}
          </div>
        </ng-container>
      </ng-container>
      <button class="btn col-2"
        [ngClass]="parameters.addRowBtn?.customClass" [type]="parameters.addRowBtn?.type" 
        [disabled]="parameters.addRowBtn?.disabled" (click)="openInputForm();parameters.addRowBtn.onClick()" 
      >
        <i *ngIf="parameters.addRowBtn.icon" [class]="parameters.addRowBtn?.icon" ></i>
        <span  class="header-btn-text">{{ parameters.addRowBtn.text }}</span>
      </button>
    </div>
    
    <div class="custom-option-input-row row mx-0 mt-3 justify-content-start align-items-center" [class.d-none]="!showInputForm">
      <ng-container *ngFor="let field of parameters.fieldList; let f = index">
        <ng-container *ngIf="field.supportLang">
          <input class="field-title col form-control mr-3" *ngFor="let subField of field.label | keyvalue"
            [type]="field.type" [(ngModel)]="userInput[field.key][subField.key]" [disabled]="field?.disabled" 
            [placeholder]="field?.placeholder" 
            [class.input-error]="field.mandatory && !userInput[field.key][subField.key] && parameters.showFieldsError"
            (change)="field.onChange()" 
          />
         </ng-container>  
        <ng-container *ngIf="!field.supportLang">
          <input class="field-title col form-control mr-3" [type]="field.type" 
            [(ngModel)]="userInput[field.key]" [disabled]="field?.disabled" [placeholder]="field?.placeholder" 
            [class.input-error]="field.mandatory && !userInput[field.key] && parameters.showFieldsError"
            (change)="field.onChange()" 
          />
        </ng-container> 
      </ng-container>
      <button *ngIf="parameters.optionBtn?.submitBtn" class=" col-2 btn brand-blue"
        [ngClass]="parameters.optionBtn?.submitBtnClass" id="option-form-submit-btn"
        [disabled]="parameters.optionBtn?.isDisabledSubmitBtn" (click)="parameters.optionBtn.onClickSubmit(userInput, formSubmitType)" 
      >
        <span class="">{{ submitBtnText }}</span>
      </button>
    </div>    

    <div class="" *ngIf="parameters.fieldList">
      <ng-container *ngIf="attrParam.mappingType == parameters.mappingType">
        <div class="" *ngFor="let input of optionList; let i = index">
        <div class="custom-option-view-row row mx-0 mt-2 justify-content-start align-items-center" *ngIf="input.active">
          <ng-container *ngFor="let field of parameters.fieldList">
              <div class="field-input col" *ngIf="!field.supportLang">
                {{input[field.key]}}
              </div>
              <ng-container *ngIf="field.supportLang">
                <div class="field-input col" *ngFor="let subField of field.label | keyvalue">
                  {{input[field.key][subField.key]}}
                </div>
              </ng-container>
            </ng-container>

          <div class="col-2 option-button-wrap">
            <button *ngIf="parameters.optionBtn?.editBtn" class="btn px-2 font-size-m" (click)="parameters.optionBtn.onClickEdit(i)" 
              [ngClass]="parameters.optionBtn?.editBtnClass" [disabled]="parameters.optionBtn?.isDisabledEdit"
            >
              <i class="fas fa-pen" ></i>
            </button>
            <button *ngIf="parameters.optionBtn?.deleteBtn" class="btn px-2 font-size-m" (click)="parameters.optionBtn.onClickDelete(i)" 
              [ngClass]="parameters.optionBtn?.deleteBtnClass" [disabled]="parameters.optionBtn?.isDisabledDelete"
            >
              <i class="fas fa-trash" ></i>
            </button>
            <button *ngIf="parameters.optionBtn?.copyBtn" class="btn px-2 font-size-m" (click)="editOption(i); parameters.optionBtn.onClickCopy(i)" 
              [ngClass]="parameters.optionBtn?.copyBtnClass" [disabled]="parameters.optionBtn?.isDisabledCopy"
            >
              <i class="fas fa-copy" ></i>
            </button>
          </div>
        </div>
      </div>
      </ng-container>
    </div>

  </div>

</div>