import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { JM, JMCONSTANT, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { TablexColumnFilterOption, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from "@enum/tablexColumnType";
import { AppDelegate } from "src/app/AppDelegate";
import { BasePage } from "src/app/ui/model/base/base";
import { Constants } from "src/constants";
import { JMLanguage } from "src/lib/JMLanguage/JMLanguage";
import * as utility from 'src/app/services/utility';
import { Session } from "@services/session";
import * as moment from "moment";
import { saveAs } from "file-saver";

@Component({
    selector: "app-ccep-jm-account-review-email-history",
    templateUrl: "./ccep-jm-account-review-email-history.component.html",
    styleUrls: ["./ccep-jm-account-review-email-history.component.scss"],
})
export class CCePJMAccountReviewEmailHistoryComponent extends BasePage implements OnInit {
    
    tablexParam: any;
    currentPageSize = 10;
    currentPage = 1;
    pageCount = 0;
    workCentreOption: TablexColumnFilterOption = new TablexColumnFilterOption();

    constructor(injector: Injector) {
        super(injector, true);
    }

    ngOnInit() {
        this.tablexParam = {
            enableClearFilter: false,
            enablePagination: true,
            enableSetPageSize: true,
            enableSort: false,
            isLoadingTable: false,
            minifyButton: true,
            pageSizeOptions: [10, 25, 50],
            currentPageSize: this.currentPageSize,
            currentPage: this.currentPage,
            pageCount: this.pageCount,
            sortOrder: -1,
            totalRowCount: 0,
            onPageNumberClicked: this.onPageNumberClicked.bind(this),
            onPageSizeClicked: this.onPageSizeClicked.bind(this),
            filterDebounceTime: Constants.DEBOUNCE_TIME,
            headers: [
                {
                    id: 'emailSentDateTime',
                    name: JMLanguage.translate('pages.ccep-jm-account-review.email-history.table.email-sent-date-and-time'),
                    type: TablexColumnType.DateTime,
                    horizontalAlign: TablexColumnHorizontalAlign.Center,
                    verticalAlign: TablexColumnVerticalAlign.Middle,
                    class: "col-3"
                },
                {
                    id: 'submitter',
                    name: JMLanguage.translate('pages.ccep-jm-account-review.email-history.table.submitter'),
                    type: TablexColumnType.Text,
                    horizontalAlign: TablexColumnHorizontalAlign.Center,
                    verticalAlign: TablexColumnVerticalAlign.Middle,
                    class: "col-3"
                },
                {
                    id: 'completionDate',
                    name: JMLanguage.translate('pages.ccep-jm-account-review.email-history.table.completion-date'),
                    type: TablexColumnType.DateTime,
                    horizontalAlign: TablexColumnHorizontalAlign.Center,
                    verticalAlign: TablexColumnVerticalAlign.Middle,
                    class: "col-3"
                },
                {
                    id: 'downloadReport',
                    name: JMLanguage.translate('pages.ccep-jm-account-review.email-history.table.download-report'),
                    type: TablexColumnType.Buttons,
                    horizontalAlign: TablexColumnHorizontalAlign.Center,
                    verticalAlign: TablexColumnVerticalAlign.Middle,
                    class: "col-3"
                }
            ],
            content: [],
            highlightedRows: []
        };
        this.checkfeatureEnabled() && this.checkPermission() && this.requestJMAccountReviewEmailHistory();
    }

    checkfeatureEnabled(): boolean {
        if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JM_HALF_YEAR_REVIEW_USER_ACCOUNT)) {
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

    checkPermission(): boolean {
        if (!this.authorizationService.hasPermission(JMENUM.Permission.ACCOUNT_REVIEW_SEND_EMAIL)) {
            AppDelegate.openSnackBar(JMLanguage.translate('api.error.401'));
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

    async requestJMAccountReviewEmailHistory() {
        this.tablexParam.isLoadingTable = true;
        const request = new JM.JMRequestAuthHalfYearReviewGetReviewStatusHistory();
        request.pageNumber = this.currentPage;
        request.pageSize = this.currentPageSize;
        const response: JM.JMResponseAuthHalfYearReviewGetReviewStatusHistory = await AppDelegate.sendJMRequest(request);
        this.tablexParam.isLoadingTable = false;
        if (!response || !response.code || response.code != 200 || !response.payload) {
            AppDelegate.openErrorBar(response);
            return;
        }
        if (Array.isArray(response.payload.records)) {
            this.tablexParam.content = this.patchRecords(response.payload.records);
            this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / this.tablexParam.currentPageSize);
        }
    }

    patchRecords(records: JMOBJ.HalfYearReview[]): any[] {
        const result = records.map(record => {
            const filename = `${moment().format('YYYYMMDD_HHmmss')}_${record.createdBy}_useraccount.xlsx`;
            return [
                record.startDate,
                record.createdBy,
                record.endDate,
                [
                    {
                        "class": "download-button btn" + (!record.fullUserExcelId ? " disabled" : ""), "icon": "fas fa-download ",
                        "onClicked": this.onDownloadButtonClicked.bind(this), "resultFileId": record.fullUserExcelId, "filename": filename
                    }
                ]
            ]
        });
        return result;
    }

    onDownloadButtonClicked(btn: any) {
        this.requestGetFile(btn.resultFileId, btn.filename, btn);
    }

    async requestGetFile(attachmentId: string, filename: string, button: any) {
        button.isLoading = true;
        let request: JM.JMRequestFilesGetFile = new JM.JMRequestFilesGetFile(attachmentId);
        const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request)
        button.isLoading = false;

        if (!response || response.code !== 200 || !response.payload) {
            AppDelegate.openErrorBar(response.error);
            button.isLoading = false;
            return;
        }
        saveAs(response.payload, filename || attachmentId);
    }

    onPageNumberClicked(pageIndex: number) {
        this.currentPage = pageIndex;
        this.requestJMAccountReviewEmailHistory();
    }

    onPageSizeClicked(pageSize: number) {
        this.currentPage = 1;
        this.currentPageSize = pageSize;
        this.requestJMAccountReviewEmailHistory();
    }

}