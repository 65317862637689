import { Component, OnInit } from '@angular/core';

import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-ccep-portal-sso-logout-success',
  templateUrl: './ccep-portal-sso-logout-success.component.html',
  styleUrls: ['./ccep-portal-sso-logout-success.component.scss']
})
export class CcepPortalSsoLogoutSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    Session.clear();
    AppDelegate.navigate(['/login'], { replaceUrl: true });
  }

}
