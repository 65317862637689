<div class="alert-bar-wrap px-3 py-2 font-size-l px">
    {{"component.pending-manual-handling.main-content" | translate}}
    <a class="link-wrap" (click)="onClickManualHandling()">
        {{"component.pending-manual-handling.click" | translate}}
    </a>
</div>

<custom-slider-panel #manual_instruction_handling_panel [widthPx]=600 [fixedPanel]="fixedPanel">
    <manual-instruction-handling-form 
        [pendingManualInstructionTime]="sn.pendingManualInstructionTime"
        [groupInstructions]="manualInstructionHandlingFormData" 
        [team]="manualInstructionHandlingFormTeam"
        [teamNextOperationTime]="manualInstructionHandlingFormTeamNextOperationTime"
        [handlingTeamNextOperationTime]="manualInstructionHandlingFormHandlingTeamNextOperationTime"
        [handlingStatus]="manualInstructionHandlingStatus" 
        [remark]="manualInstructionHandlingRemark"
        [isLoading]="isManualInstructionHandlingPanel" 
        (onSave)="submitManualInstruction($event, 'draft')"
        (onSubmit)="submitManualInstruction($event, 'handled')">
    </manual-instruction-handling-form>
</custom-slider-panel>