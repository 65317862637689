<!-- drag and drop area -->
<div class="" *ngIf="!files">
  <label for="file_input" class="w-100">
    <div class="dropzone d-flex align-items-center justify-content-center font-size-xl"
      (drop)="onFilesDropped($event)" (dragover)="onDraggedOver($event)">
      {{description}}
    </div>
  </label>
  <input type="file" id="file_input" [attr.accept]="fileAcceptType"  [disabled]="isDisabled" (change)="onFilesChanged($event)" hidden />
</div>

<!-- file preview -->
<div class="d-flex align-items-center" *ngIf="files">
  <ng-container *ngIf="allowDownloadFiles; else default">
    <span>
        <a href="javascript:;" (click)="downloadFile(files)">{{files?.name}}</a>
    </span>
  </ng-container>
  <ng-template #default>
    <span class="font-italic">{{ files?.name }}</span>
  </ng-template>
  <button id="delete-selected-file-btn" class="btn brand-red mx-2" [disabled]="isDisabled" (click)="onFileDelete()">
    <label class="mb-0"><i class="fas fa-times"></i></label>
  </button>
</div>