<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [breadcrumbs]="breadcrumbs"
  ></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding job-card-edit">

    <ng-container *ngIf="jobCard">
      <ng-container [ngSwitch]="jobCard?.jobNature">
        <ng-container *ngSwitchDefault>
          <div *ngIf="jobCard.ccsLastFailedAction" class="alert-bar-wrap px-3 py-2 font-size-l px mb-3">
            {{ jobCard.ccsErrorMessage }}
          </div>
          <div class="row">
            <div class="col-xl-6 col-12">
              <app-jobcard-job-description #cmJobDescElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode">
                <div instruction class="instruction">
                  <div class="instruction-row"><p>*</p>Mandatory</div>
                  <div class="instruction-row"><p>#</p>Mandatory for completion</div>
                </div>
              </app-jobcard-job-description>
              <app-jobcard-client-information #cmClientInfoElem [jobCard]="jobCard" [sn]="sn" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-client-information>
              <app-jobcard-equipment-information #cmEquipInfoElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4"></app-jobcard-equipment-information>
              <app-jobcard-ha-information #cmHaInfoElem *ngIf="isHaEquipmentOwner" [jobCard]="jobCard" [sn]="sn" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-ha-information>
              <app-jobcard-stock-reserve
                *ngIf="jobCard.stockReservations && jobCard.stockReservations.length > 0"
                class="d-block mb-4"
                [jobCard]="jobCard"
              ></app-jobcard-stock-reserve>
            </div>
            
            <div class="custom-tab-content col-xl-6 col-12" >
              <app-jobcard-task-progress #cmTaskProgressElem
                class="d-block"
                [jobCard]="jobCard"
                [componentParameters]="componentParameters"
                [pageMode]="pageMode"
              ></app-jobcard-task-progress>
              <app-jobcard-progress-details #cmProgressDetailsElem [jobCard]="jobCard" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-progress-details>
              <app-jobcard-ccs-information #cmCcsInfoElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-ccs-information>
              <!-- <app-jobcard-additional-information #cmAdditionalInfoElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4"></app-jobcard-additional-information> -->
              <app-attachment-detail #jobcardAttachmentDetailElem [jobCard]="jobCard" [page]="JMPage.JOBCARD" [currentPageMode]="pageMode" *ngIf="jobCard.attachments && jobCard.attachments.length > 0">
              </app-attachment-detail>
            </div>
          </div>
        </ng-container>
  
        <!-- Enquiry Job -->
        <ng-container *ngSwitchCase="jobNatureEnum.ENQUIRY">
          <div class="row">
            <div class="col-xl-6 col-12">
              <app-jobcard-job-description #enquiryJobDescElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode">
                <div instruction class="instruction">
                  <div class="instruction-row"><p>*</p>Mandatory</div>
                  <div class="instruction-row"><p>#</p>Mandatory for completion</div>
                </div>
              </app-jobcard-job-description>
              <app-jobcard-client-information #enquiryClientInfoElem [jobCard]="jobCard" [sn]="sn" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-client-information>
            </div>
            
            <div class="custom-tab-content col-xl-6 col-12" >
              <app-jobcard-task-progress #enquiryTaskProgressElem
                class="d-block"
                [jobCard]="jobCard"
                [componentParameters]="componentParameters"
                [pageMode]="pageMode"
              ></app-jobcard-task-progress>
              <app-jobcard-progress-details #enquiryProgressDetailsElem [jobCard]="jobCard" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-progress-details>
            </div>
          </div>
        </ng-container>

        <!-- Standalone Job -->
        <ng-container *ngSwitchCase="jobNatureEnum.STANDALONE">
          <app-job-card-standalone-edit
            [jobCard]="jobCard"
            [componentParameters]="componentParameters"
          ></app-job-card-standalone-edit>
        </ng-container>
      </ng-container>
    </ng-container>
    

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionButtons && actionButtons.length > 0">
      <app-action-sidebar [buttonData]="actionButtons" [disabled]="disabledActionSideBar"
        (clickEvent)="onActionButtonClicked($event)">
      </app-action-sidebar>
    </ng-container>

    <!--Slider panel-->
    <custom-slider-panel #assign_staff_panel [widthPx]=600>
      <job-card-assign-staff-form [parameters]="assignStaffFormParam"></job-card-assign-staff-form>
    </custom-slider-panel>

    <custom-slider-panel #patch_ccs_so_number_panel>
      <app-patch-ccs-so-number-form [jobCard]="jobCard"
        [componentParameters]="componentParameters"
        (onJobCardUpdated)="onPatchCcsSoSuccessSubmit($event)"
      ></app-patch-ccs-so-number-form>
    </custom-slider-panel>

    <custom-slider-panel #haEquipmentListPanel [widthPx]=900>
      <app-ha-equipment-list-panel #haEquipmentListForm [selectedHaEquipment]="selectedHaEquipment" (onSubmit)="onHaEquipmentListPanelSubmit()"></app-ha-equipment-list-panel>
    </custom-slider-panel>
  </div>
</div>