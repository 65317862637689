<div class="d-flex justify-content-end">
    <button id="'timesheet-input_close_button'"
    [ngClass]="'small-circle-button brand-red m-2 w-2'" [disabled]="isClosing"
    (click)="onDirectCloseBtnClicked()">
        <i class="fa-times fas"></i>
    </button>
</div>
<div class="timesheet-input d-flex align-items-center mb-2" >
    <div class="col-7 font-size-xl">
        {{ "component.timesheet.title" | translate }} 
        <span *ngIf="officer.postName && officer.postName.length > 0" class="post-name">{{officer.postName}}</span>
        <span *ngIf="officer.employeeName && officer.employeeName.length > 0" class="employee-name">{{officer.employeeName}}</span>
        <span *ngIf="officer.personnelNumber && officer.personnelNumber.length > 0" class="personnel-number">({{officer.personnelNumber}})</span>
    </div>
    <div class="col-5 font-size-m d-flex align-items-center">
        <div class="col-5">
            {{ "component.timesheet.task-number" | translate }}
        </div>
        <div class="col-7">
            <div class="input-field-group mb-2">
                <input type="text" class="form-control " id="timesheet_from-taskNumber_inputbox"
                value="{{officer.taskNumber}}" (keyup)="inputNumberDigit($event);updateTaskNumber($event)" (blur)="inputNumberDigit($event);updateTaskNumber($event)"
                placeholder="{{'pages.timesheet.task-number'  | translate}}"
                [disabled]='mode == TimeSheetActionMode.VIEW'/>
            </div>
        </div>
    </div>  
</div>
<div class="timesheet-input d-flex dateRow col-12 ">
    {{ "component.timesheet.note" | translate }}
</div>
<div class="timesheet-input">

    <div class="d-flex dateRow">
        <div class="col-2">
            {{ "component.timesheet.date" | translate }}
        </div>
        <div class="col-10">
            <div class="d-flex">
                <div class="col-3 mb-2 d-inline-block">
                    {{ "component.timesheet.start-time" | translate }}
                </div>
                <div class="col-3 mb-2 d-inline-block">
                    {{ "component.timesheet.end-time" | translate }}
                </div>
                <div class="col-2">
                    <button type="button" class="btn bin" (click)="onResetButtonClicked()" [disabled]='mode == TimeSheetActionMode.VIEW'>
                        <img class="action-img" src="/assets/svg/btn_action_clear.svg">
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex dateRow" *ngFor="let timeSheetInput of timeSheetInputs; let i = index; trackBy:timeSheetInputTrackBy">
        <div class="col-2">
            {{timeSheetInput.weekday}}
            <br>
            {{timeSheetInput.date}}
        </div>
        <div class="col-10">
            <div class="d-flex" *ngFor="let period of timeSheetInput.periods; let j = index; trackBy:periodTrackBy">
                <div class="col-3 mb-2">
                    <input 
                    [disabled]="mode == TimeSheetActionMode.VIEW"
                    id="startTime_{{j}}" 
                    class="form-control"
                    type="text"
                    (blur)="inputNumberWithIndex($event,timeSheetInput,j,'startTime');onTimeChanged($event,timeSheetInput,j,'startTime',startTimeHandler)"
                    (keyup.enter)="$event.target.blur();"
                    (keyup)="inputNumberWithIndex($event,timeSheetInput,j,'startTime')"
                    [(ngModel)]="period.startTime"
                    [class.input-error]="period.isStartTimeError"
                    placeholder="HH:MM"
                    maxlength="5"
                    />      
                </div>
                <div class="col-3 mb-2">
                    <input 
                    [disabled]="mode == TimeSheetActionMode.VIEW"
                    id="endTime_{{j}}" 
                    class="form-control"
                    type="text"
                    (blur)="inputNumberWithIndex($event,timeSheetInput,j,'endTime');onTimeChanged($event,timeSheetInput,j,'endTime',endTimeHandler)"
                    (keyup.enter)="$event.target.blur();"
                    (keyup)="inputNumberWithIndex($event,timeSheetInput,j,'endTime')"
                    [(ngModel)]="period.endTime"
                    [class.input-error]="period.isEndTimeError"
                    placeholder="HH:MM"
                    maxlength="5"
                    />
                </div>
                <div class="col-2">
                    <button *ngIf="j == timeSheetInput.periods.length - 1" type="button" class="btn"
                        (click)="onAddDateRowClicked(timeSheetInput)" [disabled] = 'mode == TimeSheetActionMode.VIEW'>
                        <i class="fa fa-plus blue fa-lg"></i>
                    </button>
                </div>
                <div class="col-4">
                    <div class="d-flex red" *ngFor="let errorMessage of period.errorMessages; let k = index;">
                        {{errorMessage}}
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<app-timetable [data]="timeSheetInputs" (selectPeriod)="selectPeriod($event)"></app-timetable>
<div class="timesheet-input">
    <div *ngIf="mode == TimeSheetActionMode.EDIT" class="row justify-content-center row-space">
        <app-submit-button [id]="'timesheet-input_save_button'" [text]="'component.timesheet.confirm'"
            [buttonClass]="'standard brand-blue m-2 w-2'" [isDisabled]="isSaving" [isLoading]="isSaving"
            (onClick)="onSaveBtnClicked()"></app-submit-button>
    </div>
</div>