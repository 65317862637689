<div *ngIf="parameters.onSubmitClicked" class="header">
    <h3>{{ 	"pages.sn.slider-panel.create-follow-up-job" | translate }}</h3>
    <button class="standard brand-amber" (click)="onClickSubmit()" [disabled]="parameters.isLoading || !isValidToSubmit()">
        <span *ngIf="parameters.isLoading">
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
        </span>
        <span *ngIf="!parameters.isLoading"><i class="fas fa-check space"></i>{{'global.submit' | translate}}</span>
    </button>
</div>

<!-- Follow up -->
<div>
    <ng-select class="mt-3" bindLabel="description" bindValue="value" [items]="followUpReasonOptions" (change)="onFollowUpInfoChanged()"
        [(ngModel)]="followUpReason" placeholder="* {{ 'pages.sn.follow-up-reason' | translate }}" [closeOnSelect]="true" [disabled]="parameters.isLoading">
    </ng-select>

    <textarea 
        maxlength="{{ getMaxRemarksLength }}" 
        type="text" 
        rows="5" 
        placeholder="{{ 'pages.sn.follow-up-remarks' | translate }}" 
        class="form-control" 
        [(ngModel)]="remark" 
        [disabled]="parameters.isLoading" 
        (change)="onFollowUpInfoChanged()"
    ></textarea>
    <span 
        *ngIf="remark?.length >= getMaxRemarksLength" 
        class="red small"
    >
        {{ "textarea.warning.max-length" | translate:[getMaxRemarksLength]}}
    </span>

    <h5 class="mt-3 mb-3"><span class="red">* </span>{{ "pages.sn.follow-up-job-assign-to" | translate}}</h5>

    <!-- TODO: contractor option disabled -->
    <div *ngIf="false" class="radio-wrap obtain-ccs-so-wrap custom-control custom-radio">
        <input class="radio custom-control-input" type="radio" id="contractor-radio" [(ngModel)]="followUpAssignOption" name="follow-up-assign-option" 
                value="contractor" (change)="onFollowUpAssignOptionChanged()">
        <label class="custom-control-label m-0 w-100" for="contractor-radio">
            <ng-select class="follow-up-contractor-dropdown" placeholder="{{ 'pages.sn.slider-panel.contractor' | translate }}" [closeOnSelect]="true" 
                bindLabel="description" bindValue="value" [items]="contractorOptions" (change)="onFollowUpInfoChanged()"
                [(ngModel)]="selectedContractor" [disabled]="followUpAssignOption != 'contractor'" >
            </ng-select>
        </label>
        <hr class="underline my-3" />
    </div>

    <div class="radio-wrap custom-control custom-radio">
        <input class="radio custom-control-input" type="radio" id="work-centre-radio" [(ngModel)]="followUpAssignOption" name="follow-up-assign-option" 
                value="work-centre" (change)="onFollowUpAssignOptionChanged()">
        <label for="work-centre-radio" class="m-0 custom-control-label">{{ "pages.sn.follow-up-this-work-centre" | translate}}</label>
    </div>

    <div *ngIf="followUpAssignOption == 'work-centre'" class="assign-staff">
        <job-card-assign-staff-form [parameters]="assignStaffFormParam"></job-card-assign-staff-form>
    </div>

    <div *ngIf="parameters.onSubmitClicked" class="mt-3">
        <span class="red">*</span> {{'pages.sn.mandatory-fields' | translate}}
    </div>

</div>

