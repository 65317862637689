<div class="special-request-alert-detail-container">
  <div class="header">
    <ng-container *ngIf="!SRADetail">
      <h3>{{ 'pages.special-request-alert.add.title' | translate }}</h3>
    </ng-container>
    <ng-container *ngIf="SRADetail">
      <h3>{{ 'pages.special-request-alert.edit.title' | translate }}</h3>
    </ng-container>
    <button class="standard brand-amber"
      [disabled]="!editAcknowledge"
      (click)="onClickSubmit()">
      <span *ngIf="isSavingSRA">
        <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
        {{'global.loading' | translate}}
      </span>
      <span *ngIf="!isSavingSRA"><i class="fas fa-check space"></i>{{'global.submit' | translate}}</span>
    </button>
  </div>
  <div class="content">
    <div *ngIf="SRADetail"
      class="control-row custom-control custom-checkbox">
      <input
        id="editAcknowledge"
        class="custom-control-input"
        type="checkbox"
        [(ngModel)]="editAcknowledge" />
      <label for="editAcknowledge"
        class="custom-control-label"
      ><span>{{ 'pages.special-request-alert.edit.acknowledge' | translate }}</span></label>
    </div>
    
    <form [formGroup]="SRAForm" class="d-flex flex-wrap">
      <!-- client -->
      <div class="control-row">
        <div class="col-4">
          {{ 'pages.special-request-alert.client' | translate}}
        </div>
        <div class="col-8">
          <ng-select id="client-dropdown" formControlName="client" bindLabel="label" bindValue="value"
            [items]="clientList" [clearable]="true" [multiple]="false"></ng-select>
        </div>
      </div>

      <!-- district -->
      <div class="control-row">
        <div class="col-4">
          {{ 'pages.special-request-alert.district' | translate}}
        </div>
        <div class="col-8">
          <ng-select id="district-dropdown" formControlName="district" bindLabel="label" bindValue="value"
            [items]="districtList" [clearable]="true" [multiple]="false"></ng-select>
        </div>
      </div>

      <!-- location -->
      <div class="control-row">
        <div class="col-4">
          {{ 'pages.special-request-alert.location' | translate}}
        </div>
        <div class="col-8">
          <ng-select id="location-dropdown" formControlName="location" bindLabel="label" bindValue="value"
            [items]="locationList" [loading]="isLoadingLocation" [multiple]="false" (clear)="clearLocationFilter()"
            (scrollToEnd)="onLocationScrollToEnd()" (search)="onSearchLocation($event)" (open)="clearLocationFilter()">
          </ng-select>
        </div>
      </div>

      <!-- equipment category -->
      <div class="control-row">
        <div class="col-4">
          {{ 'pages.special-request-alert.equipmentCategory' | translate}}
        </div>
        <div class="col-8">
          <ng-select id="equipment-category-dropdown" formControlName="equipmentCategory" bindLabel="label"
            [items]="equipmentCategoryList" [clearable]="true" [multiple]="false"></ng-select>
        </div>
      </div>

      <!-- equipment type -->
      <div class="control-row">
        <div class="col-4">
          {{ 'pages.special-request-alert.equipmentType' | translate}}
        </div>
        <div class="col-8">
          <ng-select id="equipment-type-dropdown" formControlName="equipmentType" bindLabel="label" bindValue="value"
            [items]="equipmentTypeList" [clearable]="true" [multiple]="false"></ng-select>
        </div>
      </div>

      <!-- recipient's email address -->
      <div class="control-row">
        <div class="mandatory-indicator">*</div>
        <div class="col-4">
          {{ 'pages.special-request-alert.recipientEmail' | translate}}
        </div>
        <div class="col-8">
          <ng-select #recipientEmailAddress id="recipient-email-address" formControlName="recipientEmailAddress" bindLabel="label"
            [items]="recipientList" [clearable]="true" [multiple]="true" [addTag]="true"
            (search)="onSearchRecipient($event)" [loading]="isLoadingRecipient && recipientEmailAddress.focused" (clear)="clearRecipientFilter()"
            (scrollToEnd)="onRecipientScrollToEnd()" (open)="clearRecipientFilter()"></ng-select>
        </div>
      </div>

      <!-- cc to -->
      <div class="control-row">
        <div class="col-4">
          {{ 'pages.special-request-alert.ccTo' | translate}}
        </div>
        <div class="col-8">
          <ng-select #ccTo id="cc-to" formControlName="ccTo" bindLabel="label"
            [items]="recipientList" [clearable]="true" [multiple]="true" [addTag]="true"
            (search)="onSearchRecipient($event)" [loading]="isLoadingRecipient && ccTo.focused" (clear)="clearRecipientFilter()"
            (scrollToEnd)="onRecipientScrollToEnd()" (open)="clearRecipientFilter()"></ng-select>
        </div>
      </div>

      <!-- remarks -->
      <div class="control-row">
        <div class="col-4">
          {{ 'pages.special-request-alert.remarks' | translate}}
        </div>
        <div class="col-8">
          <textarea
            class="form-control"
            type="text"
            rows="5"
            formControlName="remarks"
          ></textarea>
          <span
            *ngIf="SRAForm.value?.remarks?.length >= getMaxRemarksLength" 
            class="red small"
          >{{ "textarea.warning.max-length" | translate:[getMaxRemarksLength]}}</span>
        </div>
      </div>

      <div class="sub-title">
        {{ 'pages.special-request-alert.notificationEmail' | translate}}
      </div>

      <!-- sn action label -->
      <div class="control-row">
        <div class="col-4">
          {{ 'pages.special-request-alert.snActionLabel' | translate}}
        </div>
        <div class="col-8 custom-checkbox-group">
          <div class="check-box custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="sn-action-new" formControlName="snActionNew">
            <label class="custom-control-label" for="sn-action-new"
              [ngStyle]="{'background-color': SRAForm.value.snActionNew ? 'rgb(242 112 110)' : null }">
              {{ 'special-request-alert.snAction.create' | translate}}
            </label>
          </div>
          <div class="check-box custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="sn-action-reject" formControlName="snActionReject">
            <label class="custom-control-label" for="sn-action-reject"
              [ngStyle]="{'background-color': SRAForm.value.snActionReject ? 'rgb(242 112 110)' : null }">
              {{ 'special-request-alert.snAction.reject' | translate}}
            </label>
          </div>
          <div class="check-box custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="sn-action-cancel" formControlName="snActionCancel">
            <label class="custom-control-label" for="sn-action-cancel"
              [ngStyle]="{'background-color': SRAForm.value.snActionCancel ? 'rgb(242 112 110)' : null }">
              {{ 'special-request-alert.snAction.cancel' | translate}}
            </label>
          </div>
        </div>
      </div>

      <!-- enable reminder email -->
      <div class="control-row" style="margin-left: 30px">
        <div class="enable-reminder-email-container custom-checkbox">
          <input #isEnable type="checkbox" class="custom-control-input" id="is-enable-reminder-email" 
          formControlName="isEnableReminder">
          <label class="custom-control-label" for="is-enable-reminder-email">
            {{ 'pages.special-request-alert.isEnableReminderEmail' | translate}}
          </label>
        </div>
      </div>


      <!-- 1st reminder email -->
      <div class="control-row">
        <div class="mandatory-indicator">*</div>
        <div class="col-4">
          {{ 'pages.special-request-alert.firstReminderEmail' | translate}}
        </div>
        <div class="col-8">
          <div class="w-100">
            {{ 'pages.special-request-alert.after' | translate}}
            <div class="d-inline-block custom-text-control">
              <input type="number" class="text-input" formControlName="firstReminderEmail" (keydown)="onNumberFieldKeyDown($event)">
            </div>
            {{ 'pages.special-request-alert.snActionDays' | translate}}
          </div>
          <div class="error-msg" *ngIf="SRAForm.controls['firstReminderEmail'].errors && SRAForm.controls['firstReminderEmail'].errors.min">
            {{ 'error.positive-number-required' | translate}}
          </div>
        </div>
      </div>

      <!-- reminder email frequency -->
      <div class="control-row">
        <div class="mandatory-indicator">*</div>
        <div class="col-4">
          {{ 'pages.special-request-alert.reminderEmailFrequency' | translate}}
        </div>
        <div class="col-8">
          <div class="w-100">
            {{ 'pages.special-request-alert.sendEvery' | translate}}
            <div class="d-inline-block custom-text-control">
              <input type="number" class="text-input" formControlName="reminderEmailFrequency" (keydown)="onNumberFieldKeyDown($event)">
            </div>
            {{ 'pages.special-request-alert.daysAfterPrevNotiEmail' | translate}}
          </div>
          <div class="error-msg" *ngIf="SRAForm.controls['reminderEmailFrequency'].errors && SRAForm.controls['reminderEmailFrequency'].errors.min">
            {{ 'error.positive-number-required' | translate}}
          </div>
        </div>
      </div>


    </form>

    <div class="mt-3">
      <span class="red" style="margin-right: 3px">*</span> {{'pages.sn.mandatory-fields' | translate}}
    </div>
  </div>
</div>