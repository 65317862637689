import { Component, Injector, OnInit } from '@angular/core';
import { Permission } from '@enum/permission';
import { BasePage } from 'src/app/ui/model/base/base';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/entity/enum/tablexColumnType';
import { SnService } from '@services/sn.service';
import { JobCardService } from '@services/job-card.service';
import { PostJobCardCcsErrorSummaryRequest } from '@api/model/job-card/post-job-card-ccs-error-summary-request';
import { PostSnCcsErrorSummaryRequest } from '@api/model/sn/post-sn-ccs-error-summary-request';
import * as moment from 'moment';
import { PostSnCcsResyncRequest } from '@api/model/sn/post-sn-ccs-resync-request';
import { PostJobCardCcsResyncRequest } from '@api/model/job-card/post-job-card-ccs-resync-request';
import { PostSnRemoveCcsErrorRequest } from '@api/model/sn/post-sn-remove-ccs-error-request';
import { PostJobCardRemoveCcsErrorRequest } from '@api/model/job-card/post-job-card-remove-ccs-error-request';
import { TeamHandlingParty } from "src/app/entity/data-model/team";
import { JMENUM } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'app-ccs-error-log',
  templateUrl: './ccs-error-log.component.html',
  styleUrls: ['./ccs-error-log.component.scss']
})
export class CcsErrorLogComponent extends BasePage implements OnInit {

  hasViewPermission: boolean = false;
  hasUpdatePermission: boolean = false;
  uiFromDate: any;
  uiToDate: any;
  createTimeFrom: string;
  createTimeTo: string;
  snNumber: string;
  snCcsErrorSummary: any;
  jobCardCcsErrorSummary: any;
  snTotal: number = 0;
  jobTotal: number = 0;

  // ui
  isSearching: boolean = false;
  isLoading: boolean = false;
  isDisabledSnResync: boolean = false;
  isDisabledSnRemove: boolean = false;
  isDisabledJobcardResync: boolean = false;
  isDisabledJobcardRemove: boolean = false;
  isNoRecords: boolean = false;

  // tablex
  tablexParam: {} = {};
  pageSizeOptions     = [10, 50, 100];
  currentPageSize     = 10;
  currentPage         = 1;
  pageCount           = 1;
  totalLogCount       = 0;

  constructor(
    injector: Injector,
    private snService: SnService,
    private jobCardService: JobCardService
  ) {
    super(injector);
  }

  //===========================================================================
  // view life cycle functions
  ngOnInit() {
    this.checkViewPermission(JMENUM.Permission.CCS_ERROR_VIEW);
    this.hasUpdatePermission = this.authorizationService.hasPermission(JMENUM.Permission.CCS_ERROR_VIEW);
    this.isDisabledJobcardResync = !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CCS_RESYNC);
    this.isDisabledSnResync = !this.authorizationService.hasPermission(JMENUM.Permission.SN_CCS_RESYNC);
    this.isDisabledJobcardRemove = !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CCS_ERROR_REMOVE);
    this.isDisabledSnRemove = !this.authorizationService.hasPermission(JMENUM.Permission.SN_CCS_ERROR_REMOVE);

    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      tableRow              : "row",
      tableClass            : "error-log-table",
      tableWrapperClass     : "table-min-width",
      pageSizeOptions       : [10, 50, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      headers: [ 
        {
          id: 'create_date',
          name: "pages.ccs-error-log.table-column.create-date",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col p-1 p-xl-2",
        },
        {
          id: 'sn_number',
          name: "pages.ccs-error-log.table-column.sn-number",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col p-1 p-xl-2",
        },
        {
          id: 'job_number',
          name: "pages.ccs-error-log.table-column.job-number",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col p-1 p-xl-2",
        },
        {
          id: 'work_centre',
          name: "pages.ccs-error-log.table-column.work-centre",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col p-1 p-xl-2",
        },
        {
          id: 'handling_party',
          name: "pages.ccs-error-log.table-column.handling-party",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col p-1 p-xl-2",
        },
        {
          id: 'user_action',
          name: "pages.ccs-error-log.table-column.user-action",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col p-1 p-xl-2",
        },
        {
          id: 'parameters',
          name: "pages.ccs-error-log.table-column.parameters",
          type: TablexColumnType.Html, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col p-1 p-xl-2",
        },
        {
          id: 'error_log',
          name: "pages.ccs-error-log.table-column.error-log",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 p-1 p-xl-2",
        },
        {
          id: 'ccs_last_sync_date',
          name: "pages.ccs-error-log.table-column.ccs-last-sync-date",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col p-1 p-xl-2",
        },
        {
          id: 'action',
          name: "pages.ccs-error-log.table-column.action",
          type: TablexColumnType.Buttons, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 p-1",
        }
      ],
      content: []
    }

    this.getSnCcsErrorSummary();
    this.getJobCardCcsErrorSummary();

  }

  //===========================================================================
  // api function
  getSnCcsErrorSummary(){
    let request = {} as PostSnCcsErrorSummaryRequest;
    this.snNumber? request.snNumberList = [this.snNumber] : '';
    this.createTimeTo? request.createTimeTo = new Date(this.createTimeTo) : '';
    this.createTimeFrom? request.createTimeFrom = new Date(this.createTimeFrom) : '';
    request.sortBy = 'createdAt';
    request.pageNumber = this.currentPage;
    request.pageSize = this.tablexParam['currentPageSize']/2;

    this.tablexParam['isLoadingTable'] = true;

    this.apiHandler(this.snService.getSnCcsErrorSummary(request), result => 
    {
      this.tablexParam['isLoadingTable'] = false;
      this.isSearching = false;
      if (!result || !result.code || result.code != 200) {
        this.openErrorBar(result);
        return;
      }
      this.snCcsErrorSummary = result.payload.records;
      this.snTotal = result.payload.totalCount;
      this.renderTable();
    },
    error => {
        this.tablexParam['isLoadingTable'] = false;
        this.isSearching = false;
        console.error(error);
        this.openErrorBar(error);
    });
  }

  getJobCardCcsErrorSummary(){
    let request = {} as PostJobCardCcsErrorSummaryRequest;
    this.snNumber? request.snNumberList = [this.snNumber] : '';
    this.createTimeTo? request.createTimeTo = new Date(this.createTimeTo) : '';
    this.createTimeFrom? request.createTimeFrom = new Date(this.createTimeFrom) : '';
    request.sortBy = 'createdAt';
    request.pageNumber = this.currentPage;
    request.pageSize = this.tablexParam['currentPageSize']/2;

    this.tablexParam['isLoadingTable'] = true;

    this.apiHandler(this.jobCardService.getJobCardCcsErrorSummary(request), result => 
    {
      this.tablexParam['isLoadingTable'] = false;
      this.isSearching = false;
      if (!result || !result.code || result.code != 200) {
        this.openErrorBar(result);
        return;
      }
      this.jobCardCcsErrorSummary = result.payload.records;
      this.jobTotal = result.payload.totalCount;
      this.renderTable();
    },
    error => {
      this.tablexParam['isLoadingTable'] = false;
      this.isSearching = false;
      console.error(error);
      this.openErrorBar(error);
    });
  }

  requestCcsResync(snNumber, jobCardNumber){
  
    let snRequest = {} as PostSnCcsResyncRequest;
    let jobCardRequest = {} as PostJobCardCcsResyncRequest;
    if(snNumber){
      this.isDisabledSnResync = true;
      snRequest.snNumber = snNumber;
      this.apiHandler(this.snService.postSnCcsResyncRequest(snRequest), result => 
      {
        this.isDisabledSnResync = false;
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }
        this.getSnCcsErrorSummary();
        this.getJobCardCcsErrorSummary();
      },
      error => {
        this.isDisabledSnResync = false;
        console.error(error);
        this.openErrorBar(error);
      });
    }

    if(jobCardNumber){
      this.isDisabledJobcardResync = true;
      jobCardRequest.jobCardNumber = jobCardNumber;
      this.apiHandler(this.jobCardService.postJobCardCcsResyncRequest(jobCardRequest), result => 
        {
          this.isDisabledJobcardResync = false;
          if (!result || !result.code || result.code != 200) {
            this.openErrorBar(result);
            return;
          }
          this.getSnCcsErrorSummary();
          this.getJobCardCcsErrorSummary();
        },
        error => {
          this.isDisabledJobcardResync = false;
          console.error(error);
          this.openErrorBar(error);
        });
    }
  }

  requestRemoveCcsError(snNumber, jobCardNumber){
    let snRequest = {} as PostSnRemoveCcsErrorRequest;
    let jobCardRequest = {} as PostJobCardRemoveCcsErrorRequest;
    
    if(snNumber){
      this.isDisabledSnRemove = true;
      snRequest.snNumber = snNumber;
      this.apiHandler(this.snService.postSnRemoveCcsErrorRequest(snRequest), result => 
      {
        this.isDisabledSnRemove = false;
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }
        this.getSnCcsErrorSummary();
        this.getJobCardCcsErrorSummary();
      },
      error => {
        this.isDisabledSnRemove = false;
        console.error(error);
        this.openErrorBar(error);
      });
    }

    if(jobCardNumber){
      this.isDisabledJobcardRemove
      jobCardRequest.jobCardNumber = jobCardNumber;
      this.apiHandler(this.jobCardService.postJobCardRemoveCcsErrorRequest(jobCardRequest), result => 
      {
        this.isDisabledJobcardRemove = false;
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }
        this.getSnCcsErrorSummary();
        this.getJobCardCcsErrorSummary();
      },
      error => {
        this.isDisabledJobcardRemove = false;
        console.error(error);
        this.openErrorBar(error);
      });
    }
  }

  //===========================================================================
  // ui function
  onChangeDate(){
    this.createTimeFrom = this.NgbdatestructToISOString(this.uiFromDate, {hour: 0, minute:0});
    this.createTimeTo = this.NgbdatestructToISOString(this.uiToDate, {hour: 23, minute:59});

    if(this.createTimeTo < this.createTimeFrom){
      this.openSnackBar(this.translate("pages.ccs-error-log.invalid-time-range"));
    }
  }

  onSearchBtnClicked(){
    this.isSearching = true;
    this.getSnCcsErrorSummary();
    this.getJobCardCcsErrorSummary();
  }

  onResyncButtonClicked = (button) => {
    this.requestCcsResync(button.snNumber, button.jobCardNumber);
  }

  onDeleteButtonClicked = (button) => {
    let buttons = [
      {
        name: (this.translateService.instant("global.yes")),
        handler: () => {
          this.requestRemoveCcsError(button.snNumber, button.jobCardNumber);
        }
      },
      {name: (this.translateService.instant("global.no"))}
    ];
    this.showPopUpAlert(this.translateService.instant("pages.ccs-error-log.remove-confirm-alert"), "", buttons);
  }

  //===========================================================================
  // Tablex function
  onPageSizeClicked = (pageSize: number) => {
    this.currentPage  = 1;
    this.currentPageSize = pageSize;
    this.getSnCcsErrorSummary();
    this.getJobCardCcsErrorSummary();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage  = pageIndex;
    this.getSnCcsErrorSummary();
    this.getJobCardCcsErrorSummary();
  }

  private renderTable() {
    this.totalLogCount = 0;
    if(this.snCcsErrorSummary.length <= 0 && this.jobCardCcsErrorSummary.length <= 0){
      this.isNoRecords = true;
      this.tablexParam['content'] = [];
    }else{
      this.isNoRecords = false;
      this.tablexParam['content'] = [];
      this.tablexParam['content'] = this.snCcsErrorSummary.map(sn => {
        return [
          moment(sn.createdAt).format('DD/MM/YYYY HH:mm'),
          sn.snNumber,
          "", //jobCard Number
          sn.workCentre,
          TeamHandlingParty[sn.handlingParty],
          sn.ccsLastFailedAction,
          "<b>Client: </b>"+sn.client+"<br><b>Location: </b>"+sn.location,
          sn.ccsErrorMessage,
          moment(sn.ccsLastSyncTime).format('DD/MM/YYYY HH:mm'),
          [
            { "id": "resync_button_"+sn.snNumber, "name": "", "disable": this.isDisabledSnResync, "class": "glyph brand-amber mt-1", "icon": "fas fa-sync", "onClicked": this.onResyncButtonClicked, "snNumber":sn.snNumber, "jobCardNumber": null},
            { "id": "delete_button_"+sn.snNumber, "name": "", "disable": this.isDisabledSnRemove, "class": "glyph brand-red mt-1", "icon": "fas fa-times", "onClicked": this.onDeleteButtonClicked, "snNumber":sn.snNumber, "jobCardNumber": null}
          ]
        ];
      }).concat(
        this.jobCardCcsErrorSummary.map(job => {
          return[
            moment(job.createdAt).format('DD/MM/YYYY HH:mm'),
            job.snNumber,
            job.jobCardNumber,
            job.workCentre,
            TeamHandlingParty[job.handlingParty],
            job.ccsLastFailedAction,
            "<b>Job Type: </b>"+job.orderType+"<br><b>MAT: </b>"+job.matType+"<br><b>SLA No.: </b>"+job.serviceLevelAgreementNumber+"<br><b>SLA Item: </b>"+job.serviceLevelAgreementItem,
            job.ccsErrorMessage,
            moment(job.ccsLastSyncTime).format('DD/MM/YYYY HH:mm'),
            [
              { "id": "resync-button_"+job.jobCardNumber, "disable": this.isDisabledJobcardResync, "name": "", "class": "glyph brand-amber mt-1", "icon": "fas fa-sync", "onClicked": this.onResyncButtonClicked, "snNumber":null, "jobCardNumber": job.jobCardNumber},
              { "id": "delete-button_"+job.jobCardNumber, "disable": this.isDisabledJobcardRemove, "name": "", "class": "glyph brand-red mt-1", "icon": "fas fa-times", "onClicked": this.onDeleteButtonClicked, "snNumber":null, "jobCardNumber": job.jobCardNumber}
            ]
          ];
        })
      );  
      
      this.tablexParam['content'].sort( (a, b) => moment(a[0], "DD/MM/YYYY HH:mm").isAfter(moment(b[0], "DD/MM/YYYY HH:mm")) ? -1 : 1 );
      this.totalLogCount = this.snTotal+this.jobTotal;
      this.tablexParam['pageCount'] = Math.ceil(this.totalLogCount/this.currentPageSize);
    }
  }

  // TODO: reload language
  onLanguageChanged() {
    
  }

}
