import { Component, Input, Output, OnInit, EventEmitter, Injector } from '@angular/core';
import * as FORAMTTER from 'src/app/presenter/Formatter'
import { JMOBJ } from '@ccep/CCEPConnector-ts';
import { UIContractRow } from './UIContractRow';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'add-contract-form',
  templateUrl: './add-contract-form.component.html',
  styleUrls: ['./add-contract-form.component.scss']
})

// const BATCH = 100;
// string: JMOBJ.MaintenanceTermContract
export class AddContractFormComponent implements OnInit {
  @Input() parameters:{
    workCentreContractArray: string[],
    contractDict: { [key:string]: JMOBJ.MaintenanceTermContract },
    onSubmitButtonClicked: Function,

  } = {
    workCentreContractArray: undefined,
    contractDict: {},
    onSubmitButtonClicked: undefined,
  };
  
  private searchTerms = new Subject<any[]>();

  uiEnableSubmitButton: boolean = true;
  uiNewContractNumber: string = undefined;
  uiContractArray: UIContractRow[] = undefined; // array of label and checked

  nextContractArrayIndex = 0;

  constructor(){
  }

  ngOnInit() {
    this.searchTerms.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe((header) => {
      this.uiContractArray = [];
      this.nextContractArrayIndex = 0;
      if (this.uiNewContractNumber && this.uiNewContractNumber.length > 0) {
        this.updateView(Object.values(this.parameters.contractDict).length);        
      } else {
        this.updateView();
      }
    });
    this.clearView();
  }

  clearView() {
    this.enabletSubmitbutton(true);
    this.parameters.workCentreContractArray = [];
    this.parameters.contractDict = {};
    this.uiNewContractNumber = '';
    this.uiContractArray = [];
    this.nextContractArrayIndex = 0;
  }

  enabletSubmitbutton(enable:boolean) {
    this.uiEnableSubmitButton = enable;
  }

  // render all contract checkbox will be slow -> render 100 each
  // count is number of contract newly add to the array
  // ie show 'count' more rows 
  updateView(count?:number) {
    if (count == undefined || count == null) {
      count = 100;
    }
    // filter out the contracts which are already added to the work centre
    let initLength = this.nextContractArrayIndex;
    let contract:JMOBJ.MaintenanceTermContract;
    let contractValueArray = Object.values(this.parameters.contractDict);
    for (let i=initLength; i<Math.min(contractValueArray.length, initLength + count); i++) {
      contract = contractValueArray[i];
      let show = (this.parameters.workCentreContractArray.includes(contract.contractNumber) == false) &&
      ((this.uiNewContractNumber && contract.contractNumber.toLowerCase().includes(this.uiNewContractNumber.toLowerCase())) 
      || (!this.uiNewContractNumber || this.uiNewContractNumber.length === 0));
      
      if (show) {
        let pmsmc = '';
        let type:string = '';
        
        pmsmc = (contract.isPmsmcContract)?'P':'N';
        // if (contract.type === JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT) {
        //   type = '*';
        // } else if (contract.type === JMENUM.MaintenanceContractType.DUMMY_CONTRACT) {
        //   type = '^';
        // }
        this.uiContractArray.push({
          key: contract.contractNumber,
          label: pmsmc + ' ' + FORAMTTER.formatContractNumber(contract),
          checked: false,
        });

        this.nextContractArrayIndex = i + 1;
      }
    }
    
  }

  checkEffective(contractNumber:string):boolean {
    let contract:JMOBJ.MaintenanceTermContract = this.parameters.contractDict[contractNumber];
    let now = moment().format("YYYYMMDD");

    return ( contract.vendorStartDate <= now && now <= contract.vendorEndDate);
  }

  //-----------------------------------------------------------------------
  // button callback function
  onSubmitButtonClicked(event):void {
    this.enabletSubmitbutton(false);
    let newContractNumberArray = []
    let row:UIContractRow;
    for (row of this.uiContractArray) {
      if (row.checked) {
        newContractNumberArray.push(row.key);
      }
    }
    if (this.parameters.onSubmitButtonClicked) {
      this.parameters.onSubmitButtonClicked(newContractNumberArray);      
    }

  }

  onCheckBoxClicked(event, i, contractNumber:string) {
    // let contractRow:UIContractRow = this.uiContractArray[i];
    // contractRow.checked = !contractRow.checked;
    // console.log("onCheckBoxClicked " + i + ' ' + contractNumber + ' ' + contractRow.checked);
  }

  onNewContractNumberKeyup(event) {
    this.searchTerms.next(event);
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    let buffer = 100;
    if (event.target.offsetHeight + event.target.scrollTop + buffer >= event.target.scrollHeight) {
      this.updateView(100); // get contract number by batch
    }
  } 
}