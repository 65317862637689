import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { AuthorizationService } from '@services/authorization.service';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { ActionButtonContent, ActionButtonPmJob, ActionButtonType } from '@enum/action-button';
import { PmJobService } from '@services/pm-job.service';
import { ActionButtonService } from '@services/action-button.service';
import { CustomSliderPanelComponent } from '../../components/custom-slider-panel/custom-slider-panel.component';
import { CcsSoObtainFormComponent } from '../../../pm-job/shared/ccs-so-obtain-form/ccs-so-obtain-form.component';
import { ApprovalService } from '@services/approval.service';
import * as utility from 'src/app/services/utility';
import { Session } from '@services/session';

@Component({
  selector: 'app-pm-job-view',
  templateUrl: './pm-job-view.component.html',
  styleUrls: ['./pm-job-view.component.scss'],
})
export class PmJobViewComponent implements OnInit {
  @ViewChild('cancel_pm_job_panel', { static: true }) cancelPmJobPanel: CustomSliderPanelComponent;
  @ViewChild('obtain_ccs_so_panel', { static: true }) obtainCcsSoPanel: CustomSliderPanelComponent;
  @ViewChild('obtain_ccs_so_form', { static: true }) obtainCcsSoForm: CcsSoObtainFormComponent;
  @ViewChild("reject_approval_panel", { static: true }) rejectApprovalPanel: CustomSliderPanelComponent;


  router: Router;
  route: ActivatedRoute;
  authorizationService: AuthorizationService;
  pmJobService: PmJobService;
  actionBtnService: ActionButtonService;

  actionButtonData: ActionButtonContent[] = [];
  JMPage = JMENUM.JMPage;
  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;
  breadcrumbs: any = [];
  jobNumber: string;
  job: JMOBJ.PmJob;
  approvalList: JMOBJ.Approval[] = [];

  isCancelPmJobPanelLoading: boolean;

  constructor(
    router: Router,
    route: ActivatedRoute,
    authorizationService: AuthorizationService,
    pmJobService: PmJobService,
    actionBtnService: ActionButtonService,
    private approvalService: ApprovalService,
  ) {
    this.router = router;
    this.route = route;
    this.authorizationService = authorizationService;
    this.pmJobService = pmJobService;
    this.actionBtnService = actionBtnService;
  }

  ngOnInit() {
    this.initPage();
  }

  initPage = async () => {
    this.pagePermissionChecking();
    this.jobNumber = this.route.snapshot.paramMap.get('jobNumber');
    this.initBreadCrumbs();
    this.fetchJobData();
  };

  pagePermissionChecking() {
    if (!this.authorizationService.hasPermissions([JMENUM.Permission.PMJOB_VIEW], false)) {
      this.router.navigate(['/']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
  }

  initBreadCrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-pm-job',
        name: JMLanguage.translate('pages.pm-job.page-title.view'),
        route: '/pm/job-list',
      },
      {
        id: 'breadcrumbs-pm-job-number',
        name: this.jobNumber,
        route: null,
        currentPage: true,
      },
    ];
  }

  async fetchJobData() {
    let response: JM.JMResponseGetPmJob = await this.pmJobService.getPmJob(this.jobNumber);
    this.job = response && response.payload;
    this.initActionButtons();
  }

  async cancelPmJob({ cancelCode, cancelRemarks }: { cancelCode: number; cancelRemarks: string }) {
    this.isCancelPmJobPanelLoading = true;
    let response: JM.JMResponsePmJobCancel = await this.pmJobService.cancelPmJob(
      this.job,
      cancelCode,
      cancelRemarks
    );
    this.isCancelPmJobPanelLoading = false;
    if (response && response.payload) {
      this.cancelPmJobPanel.close();
      this.fetchJobData();
    }
  }

  async requestApproveRequest(approvals: JMOBJ.Approval[]) {
    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = approvals.map(approval => {
      return {
        approvalAction: JMENUM.ApprovalAction.APPROVE,
        approvalId: approval._id,
      }
    });

    this.actionButtonIsEnable(false);
    const records = await this.approvalService.requestBatchProcessRequest(request);
    this.actionButtonIsEnable(true);

    if (!records.length) { return; }

    const successCount = records.filter(r => r.success).length;
    const failCount = records.filter(r => !r.success).length;

    AppDelegate.openSnackBar(JMLanguage.translate('global.batch-process-request.result', [successCount, failCount]));
    await this.fetchJobData();
  }

  onCompleteCcsSo() {
    this.obtainCcsSoPanel.close();
    this.fetchJobData();
  }

  initActionButtons(): void {
    this.actionButtonData = [];
    let jobStatusDraft = this.job && this.job.status === JMENUM.PMJobStatus.DRAFT;
    let jobStatusSubmitted = this.job && this.job.status === JMENUM.PMJobStatus.SUBMITTED;
    let jobStatusComplete = this.job && this.job.status === JMENUM.PMJobStatus.COMPLETED;
    let jobStatusReworking = this.job && this.job.status === JMENUM.PMJobStatus.REWORKING;

    let periodStatusCancelled = this.job && this.job.period && this.job.period['status'] === JMENUM.PMPeriodStatus.CANCELLED;
 
    const isInHouseHandlingParty = (pmPlan) => {
      let parsedPmPlan = Array.isArray(pmPlan)? pmPlan : [pmPlan]

      const inHouseHandlingPartyIndex: number = parsedPmPlan.findIndex((pmPlan) => {
        return pmPlan['contract'] == null && !pmPlan['contract'];
      })
      return inHouseHandlingPartyIndex >= 0

    }

    const isInspectionEnabled =  utility.isEnabledFeature(Session,JMCONSTANT.JMFeature.JOB_CARD_INSPECTION)

    if (jobStatusComplete) {
      this.addActionBtn(ActionButtonPmJob.reopen, this.reopenBtnAction);
    }
    if ((jobStatusDraft || jobStatusReworking) && !periodStatusCancelled) {
      this.addActionBtn(ActionButtonPmJob.submit, this.submitBtnAction);
    }
    if (jobStatusDraft || jobStatusComplete || jobStatusReworking) {
      this.addActionBtn(ActionButtonPmJob.cancel, this.cancelBtnAction);
    }
    if (jobStatusDraft || jobStatusReworking) {
      this.addActionBtn(ActionButtonPmJob.edit, this.editBtnAction);
    }
    if (jobStatusSubmitted || jobStatusComplete || jobStatusReworking) {
      const equipIndexWithoutCcsSo = this.job.equipmentList.findIndex((equip) => {
        return equip['ccsStatus'] != "pending" && !equip['ccsServiceOrderNumber'];
      });
      equipIndexWithoutCcsSo >= 0 && this.addActionBtn(ActionButtonPmJob.generateCcsSo, this.ccsSoBtnAction);
    }
    if (jobStatusDraft || jobStatusComplete || jobStatusReworking){
      this.addActionBtn(ActionButtonPmJob.attachment, this.attachmentBtnAction);
    }

    if(isInspectionEnabled){
      if (jobStatusSubmitted || jobStatusComplete || jobStatusReworking){
        const equipIndexWithoutCcsSo = this.job.equipmentList.findIndex((equip) => {
          return equip['ccsStatus'] != "pending" && !equip['ccsServiceOrderNumber'];
        });
        equipIndexWithoutCcsSo == -1 && (this.job.pmPlan && !isInHouseHandlingParty(this.job.pmPlan)) && this.addActionBtn(ActionButtonPmJob.inspect, this.inspectBtnAction);
      }
    }

    if (jobStatusSubmitted) {
      this.approvalList = this.job.approvalObject ? [this.job.approvalObject] : [];
      const hasApprover = this.approvalService.hasRoleInApprovalList(this.approvalList, 'approver');
      const hasEditPermission = this.approvalService.hasPermissionInApprovalList(this.approvalList, 'edit');
      
      if (hasApprover || hasEditPermission) {
        this.addActionBtn(ActionButtonPmJob.approveSubmittion, this.approveSubmittedBtnAction);
        this.addActionBtn(ActionButtonPmJob.rejectSubmittion, this.rejectSubmittedBtnAction);
      }
    }
  }

  addActionBtn(buttonStatus: ActionButtonPmJob, button1CallBack?: Function, button2CallBack?: Function): void {
    let actionButton = this.actionBtnService.addActionBtn(
      ActionButtonType.pmJob,
      buttonStatus,
      button1CallBack,
      button2CallBack
    );
    actionButton && this.actionButtonData.push(actionButton);
  }

  actionButtonIsEnable(status: boolean) {
    this.actionBtnService.isEnable(this.actionButtonData, status);
  }

  cancelBtnAction = async () => {
    this.cancelPmJobPanel.show();
  };

  submitBtnAction = async () => {
    if (Array.isArray(this.job.equipmentList) && this.job.equipmentList.length < 1) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.pm-job.popup.message.mandatory-equipment'));
    } else {
      this.actionButtonIsEnable(false);
      await this.pmJobService.submitPmJob(this.job);
      this.actionButtonIsEnable(true);
      this.fetchJobData();
    }
  };

  ccsSoBtnAction = () => {
    this.obtainCcsSoForm.resetUiState();
    this.obtainCcsSoPanel.show();
  };

  attachmentBtnAction = () => {
    this.router.navigate(['/pm/job/view/' + this.jobNumber + '/attachment'])
  };

  inspectBtnAction = () => {
    this.router.navigate(['/pm/job/inspect/' + this.jobNumber])
  };

  editBtnAction = () => {
    this.router.navigate(['/pm/job/edit/' + this.job.pmJobNumber]);
  };

  approveSubmittedBtnAction = () => {
    this.requestApproveRequest(this.approvalList);
  };

  rejectSubmittedBtnAction = () => {
    this.rejectApprovalPanel.toggle();
  };

  reopenBtnAction = async () => {
    this.actionButtonIsEnable(false);
    let response: JM.JMResponsePmJobReopen = await this.pmJobService.reopenPmJob(this.job);
    this.actionButtonIsEnable(true);
    if (response && response.payload) {
      await this.fetchJobData();
      this.initActionButtons();
    }
  };


  public async onApprovalRejected(count: number) {
    this.rejectApprovalPanel.toggle();
    await this.fetchJobData();
  }
}
