import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { JM, JMCONSTANT, JMENUM } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { Subject } from 'rxjs';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { SRASummary } from '../special-request-alert-list/special-request-alert-list.component';
import { isArray } from 'underscore';
import { AuthorizationService } from '@services/authorization.service';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-special-request-alert-detail',
  templateUrl: './special-request-alert-detail.component.html',
  styleUrls: ['./special-request-alert-detail.component.scss']
})
export class SpecialRequestAlertDetailComponent implements OnInit {
  @Output() closePanel = new EventEmitter<boolean>();
  public SRADetail: SRASummary = null;
  public SRAForm: FormGroup;
  public editAcknowledge: boolean;

  public clientList: {
    label: string;
    value: string;
  }[] = [];
  public locationList: {
    label: string;
    value: string;
  }[] = [];
  public districtList: {
    label: string;
    value: string;
  }[] = [];
  public equipmentCategoryList: {
    label: string;
    value: string;
    typeList: string[];
  }[] = [];
  public equipmentTypeList: {
    label: string;
    value: string;
  }[] = [];
  public recipientList: {
    label: string;
    value: string;
  }[] = [];


  public originalEquipmentTypeList: {
    label: string;
    value: string;
  }[] = [];
  public originalEquipmentCategoryList: {
    label: string;
    value: string;
    typeList: string[];
  }[] = [];
  // Location Dropdown
  private searchLocationObserver = new Subject<any[]>();
  searchLocationKeyword: string = null;
  locationPageSize: number = 100;
  locationPageNumber: number = 1;
  locationTotalPage: number;
  isLoadingLocation: boolean = false;
  // Recipient Email Address Dropdown
  private searchRecipientObserver = new Subject<any[]>();
  searchRecipientKeyword: string = null;
  recipientPageSize: number = 100;
  recipientPageNumber: number = 1;
  recipientTotalPage: number;
  isLoadingRecipient: boolean = false;

  isSavingSRA = false;

  constructor(private fb: FormBuilder, private authService: AuthorizationService) { }

  ngOnInit() {
    this.initForm(this.SRADetail);
    this.searchLocationObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchLocations.bind(this));
    this.searchRecipientObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchRecipients.bind(this));
  }


  initForm(SRADetail: SRASummary) {
    this.SRADetail = SRADetail;
    this.initFormValue(SRADetail);
    this.initClientList();
    this.initDistrictList();
    this.initEquipmentCategoryList();
    this.initEquipmentTypeList();
    this.initEquipmentCategoryObserver();
    this.initEquipmentTypeObserver();
    this.initIsEnableReminderObserver();
    if (SRADetail) {
      this.patchEquipmentCategory();
    }
  }

  patchEquipmentCategory() {
    const fromEquipmentCatControl = this.SRAForm.get('equipmentCategory');
    if (fromEquipmentCatControl.value) {
      const equipmentCat = fromEquipmentCatControl.value && this.equipmentCategoryList && this.equipmentCategoryList.length ?
        this.equipmentCategoryList.filter(cat => cat.value === fromEquipmentCatControl.value)[0] : null;
      fromEquipmentCatControl.setValue(equipmentCat);
    }
  }

  initIsEnableReminderObserver() {
    this.SRAForm.controls['isEnableReminder'].valueChanges.subscribe(change => {
      if (change === false) {
        this.SRAForm.controls['firstReminderEmail'].disable();
        this.SRAForm.controls['reminderEmailFrequency'].disable();
      } else {
        this.SRAForm.controls['firstReminderEmail'].enable();
        this.SRAForm.controls['reminderEmailFrequency'].enable();
      }
    });
  }

  initFormValue(postObj: any) {
    if (!postObj) {
      this.editAcknowledge = true;
      this.SRAForm = this.fb.group({
        client: null,
        location: null,
        district: null,
        equipmentCategory: null,
        equipmentType: null,
        recipientEmailAddress: new FormControl(null, Validators.required),
        ccTo: null,
        remarks: ["", Validators.maxLength(Constants.TEXTAREA_MAX_LENGTH)],
        snActionNew: false,
        snActionReject: false,
        snActionCancel: false,
        isEnableReminder: true,
        firstReminderEmail: new FormControl(3, [Validators.required, Validators.min(1)]),
        reminderEmailFrequency: new FormControl(3, [Validators.required, Validators.min(1)])
      });
    } else {
      this.editAcknowledge = false;
      const recipientNameOrEmailList = [];
      const ccToList = [];
      if(isArray(postObj.recipientEmailAddress) && postObj.recipientEmailAddress.length) {
        postObj.recipientEmailAddress.forEach(recipient => {
          if(recipient.type) {
            if(recipient.type === "post") {
              recipientNameOrEmailList.push(recipient.postName);
            }  else if (recipient.type === "other") {
              recipientNameOrEmailList.push(recipient.email);
            }
          }
        })
      }
      if(isArray(postObj.ccTo) && postObj.ccTo.length) {
        postObj.ccTo.forEach(recipient => {
          if(recipient.type) {
            if(recipient.type === "post") {
              ccToList.push(recipient.postName);
            }  else if (recipient.type === "other") {
              ccToList.push(recipient.email);
            }
          }
        })
      }
      if(!this.SRAForm) {
        this.SRAForm = this.fb.group({
          client: postObj.client,
          location: postObj.location,
          district: postObj.district,
          equipmentCategory: postObj.equipmentCategory,
          equipmentType: postObj.equipmentType,
          recipientEmailAddress: new FormControl(recipientNameOrEmailList, Validators.required),
          ccTo: ccToList,
          remarks: [(postObj.remarks ? postObj.remarks :""), Validators.maxLength(Constants.TEXTAREA_MAX_LENGTH)],
          snActionNew: postObj.snActionList ? postObj.snActionList.includes(JMENUM.SnTriggerSraActionList.CREATE) : false,
          snActionReject: postObj.snActionList ? postObj.snActionList.includes(JMENUM.SnTriggerSraActionList.REJECT) : false,
          snActionCancel: postObj.snActionList ? postObj.snActionList.includes(JMENUM.SnTriggerSraActionList.CANCEL) : false,
          isEnableReminder: postObj.isReminderEmailEnabled,
          firstReminderEmail: new FormControl({value: postObj.firstReminderEmail || 3, disabled: !postObj.isReminderEmailEnabled} 
            , [Validators.required, Validators.min(1)]),
          reminderEmailFrequency: new FormControl({value: postObj.reminderEmailFrequency || 3, disabled: !postObj.isReminderEmailEnabled}
            , [Validators.required, Validators.min(1)])
        });
      } else {
        this.SRAForm.reset();
        this.SRAForm.patchValue({
          client: postObj.client,
          district: postObj.district,
          equipmentCategory: postObj.equipmentCategory,
          equipmentType: postObj.equipmentType,
          recipientEmailAddress: recipientNameOrEmailList,
          ccTo: ccToList,
          remarks: postObj.remarks,
          snActionNew: postObj.snActionList ? postObj.snActionList.includes(JMENUM.SnTriggerSraActionList.CREATE) : false,
          snActionReject: postObj.snActionList ? postObj.snActionList.includes(JMENUM.SnTriggerSraActionList.REJECT) : false,
          snActionCancel: postObj.snActionList ? postObj.snActionList.includes(JMENUM.SnTriggerSraActionList.CANCEL) : false,
          isEnableReminder: postObj.isReminderEmailEnabled,
          firstReminderEmail: postObj.firstReminderEmail || 3,
          reminderEmailFrequency: postObj.reminderEmailFrequency || 3
        }, { emitEvent: false });
        if(!postObj.isReminderEmailEnabled) {
          this.SRAForm.get('firstReminderEmail').disable();
          this.SRAForm.get('reminderEmailFrequency').disable();
        }
        if(postObj.location) {
          this.setLocationValue(postObj.location);
        }
      }
    }
    this.SRAForm.markAsUntouched();
  }

  async setLocationValue(location: any) {
    await this.onSearchLocation({term: location});
    this.SRAForm.get('location').setValue(location);
  }

  onNumberFieldKeyDown(event: KeyboardEvent): boolean {
    return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true 
              : !isNaN(Number(event.key)) && event.code!=='Space';
  }

  onClickSubmit() {
    const formValue: any = this.SRAForm.value;
    if (!this.checkInputClientDistrictOrLocation(formValue)) {
      return AppDelegate.openSnackBar(JMLanguage.translate('pages.special-request-alert.either-one-error'));
    } else if (!this.checkEquipmentValid(formValue)) {
      return AppDelegate.openSnackBar(JMLanguage.translate('pages.special-request-alert.equipment-invalid'));
    } else if (this.SRAForm.get("remarks").invalid) {
      return AppDelegate.openSnackBar(JMLanguage.translate('textarea.warning.max-length', [this.getMaxRemarksLength]));
    } else if (this.SRAForm.invalid) {
      return AppDelegate.openSnackBar(JMLanguage.translate('pages.special-request-alert.please-input-all-mandatory-fields'));
    }
    const snActionList: string[] = [];
    if (formValue.snActionNew) {
      snActionList.push('create');
    }
    if (formValue.snActionReject) {
      snActionList.push('reject');
    }
    if (formValue.snActionCancel) {
      snActionList.push('cancel');
    }
    const recipientList: string[] = [];
    const ccToList: string[] = [];
    formValue.recipientEmailAddress.forEach(recipient => {
      recipientList.push(recipient.value || recipient.label || recipient);
    });
    if(formValue.ccTo && formValue.ccTo.length) {
      formValue.ccTo.forEach(recipient => {
        ccToList.push(recipient.value || recipient.label || recipient);
      });
    }
    const requestBody = {
      clientShortName: formValue.client,
      district: formValue.district,
      locationCode: formValue.location,
      equipmentCategory: formValue.equipmentCategory ? formValue.equipmentCategory.value : null,
      equipmentType: formValue.equipmentType,
      snActionList: snActionList,
      recipientList: recipientList,
      ccList: ccToList,
      remarks: formValue.remarks,
      isReminderEmailEnabled: formValue.isEnableReminder,
    }
    if(requestBody.isReminderEmailEnabled) {
      requestBody['reminderEmailFrequency'] = formValue.reminderEmailFrequency || undefined;
      requestBody['reminderEmailFrequencyUnit'] = JMENUM.GeneralTimeUnit.DAYS;
      requestBody['firstReminderEmailPeriod'] = formValue.firstReminderEmail || undefined;
      requestBody['firstReminderEmailPeriodUnit'] = JMENUM.GeneralTimeUnit.DAYS;
    }
    Object.keys(requestBody).forEach(key => {
      requestBody[key] === undefined && delete requestBody[key];
    });

    // create
    if (!this.SRADetail || !this.SRADetail.id) {
      this.createSRA(requestBody);
    } else {
      // edit
      this.editSRA(requestBody);
    }
  }

  private async createSRA(data: any) {
    const request = new JM.JMRequestSnCreateSpecialRequestRule();
    Object.keys(data).forEach(key => {
      request[key] = data[key];
    });
    this.isSavingSRA = true;

    const response: JM.JMResponseSnCreateSpecialRequestRule = await AppDelegate.sendJMRequest(request);
    this.isSavingSRA = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
    this.closePanel.emit(true);
  }

  private async editSRA(data: any) {
    let request = new JM.JMRequestSnUpdateSpecialRequestRule();
    request.ruleId = this.SRADetail.id;
    request.version = this.SRADetail.version;
    Object.keys(data).forEach(key => {
      request[key] = data[key];
    });

    this.isSavingSRA = true;
    const response: JM.JMResponseSnUpdateSpecialRequestRule = await AppDelegate.sendJMRequest(request);
    this.isSavingSRA = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
    this.closePanel.emit(true);
  }

  checkInputClientDistrictOrLocation(formValue: any): boolean {
    return formValue.client || formValue.district || formValue.location;
  }

  checkEquipmentValid(formValue: any): boolean {
    return formValue.equipmentCategory || (!formValue.equipmentCategory && !formValue.equipmentType);
  }

  initClientList() {
    let options = [];
    const clientDict = Session.clientDict;

    for (const key in clientDict) {
      if (Object.prototype.hasOwnProperty.call(clientDict, key)) {
        const client = clientDict[key];
        options.push({
          label: client.clientShortName + " - " + client.name[JMLanguage.getCurrentLanguage()],
          value: client.clientShortName,
        });
      }
    }
    this.clientList = options;
  }

  initDistrictList() {
    let options = [];
    JM.JMConnector.getAllDistrictCode().map((code) => {
      options.push({
        label: JMLanguage.translate(`district.${code}`),
        value: code,
      });
    });
    this.districtList = options;
  }

  initEquipmentCategoryList() {
    this.equipmentCategoryList = this.getEquipmentCategoriesOptions(JM.JMConnector.getAllEquipmentCategoryCode());
    this.originalEquipmentCategoryList = this.equipmentCategoryList.concat();
  }

  initEquipmentTypeList() {
    this.equipmentTypeList = this.getEquipmentTypesOptions(JM.JMConnector.getAllEquipmentTypeCode());
    this.originalEquipmentTypeList = this.equipmentTypeList.concat();
  }

  getEquipmentCategoriesOptions(categoryCodeArray: any[]): { label: string, value: string, typeList: string[] }[] {

    const list = [];
    for (let key of categoryCodeArray) {
      let cat = JM.JMConnector.getEquipmentCategory(key);
      if(cat) {
        let item = {
          value: key,
          label: cat.code + ' - ' + cat.description[JMLanguage.getCurrentLanguage()],
          typeList: cat.equipmentType || []
        };
        list.push(item);
      }
    }
    list.sort((a, b) => {
      return (b.value > a.value) ? -1 : 1;
    });
    return list;
  }

  getEquipmentTypesOptions(typeCodeArray: any[]): { label: string, value: string }[] {
    const list = [];
    for (let code of typeCodeArray) {
      let typeObj = JM.JMConnector.getEquipmentType(code);
      if(typeObj) {
        list.push({
          value: code,
          label: typeObj.code + ' - ' + typeObj.description[JMLanguage.getCurrentLanguage()],
        });
      }
    }
    list.sort(function (a, b) {
      return (b.value > a.value) ? -1 : 1;
    });
    return list;
  }

  filterEquipmentList(smallList: string[], bigList: any[]): any[] {
    const list: any[] = [];
    bigList.forEach(val => {
      if (smallList.includes(val.value)) {
        list.push(val);
      }
    });
    return list;
  }

  initEquipmentCategoryObserver() {
    this.SRAForm.get('equipmentCategory').valueChanges.subscribe((value) => {
      if (value === null) {
        this.equipmentCategoryList = this.originalEquipmentCategoryList;
        this.equipmentTypeList = this.originalEquipmentTypeList;
        this.SRAForm.get('equipmentType').reset();
      } else {
        this.equipmentTypeList = this.filterEquipmentList(value.typeList, this.originalEquipmentTypeList);
        if (this.SRAForm.get('equipmentType').value) {
          if (!value.typeList.includes(this.SRAForm.get('equipmentType').value)) {
            this.SRAForm.get('equipmentType').reset();
          }
        }
      }
    });
  }

  initEquipmentTypeObserver() {
    this.SRAForm.get('equipmentType').valueChanges.subscribe((value) => {
      if (value) {
        this.equipmentCategoryList = this.originalEquipmentCategoryList.filter(cat => {
          return cat.typeList.includes(value);
        });
        if (this.equipmentCategoryList.length === 1) {
          this.SRAForm.get('equipmentCategory').setValue(this.equipmentCategoryList[0]);
        }
      } else {
        this.equipmentCategoryList = this.originalEquipmentCategoryList;
      }
    });
  }

  // location ng select handler
  onSearchLocation(event) {
    this.searchLocationKeyword = event.term;
    this.searchLocationObserver.next();
  }

  clearLocationFilter() {
    this.searchLocationKeyword = null;
    this.searchLocations();
  }

  async searchLocations() {
    this.locationList = [];
    this.locationPageNumber = 1;
    this.locationTotalPage = null;
    this.locationList = await this.requestLocationList(this.locationPageNumber) as any;
  }

  async onLocationScrollToEnd() {
    if (this.locationPageNumber < this.locationTotalPage) {
      this.locationPageNumber++;

      let locations = await this.requestLocationList(this.locationPageNumber) as any;

      // just append will not trigger ng-select change detection, need to update array reference
      this.locationList = this.locationList.concat(locations);
    }
  }

  private async requestLocationList(pageNumber?: number) {
    const request = new JM.JMRequestLocationsLocationSummary();

    request.locationOnly = true;
    request.parameters = ['code', 'description'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.locationPageSize;

    if (this.searchLocationKeyword) {
      request.locationDescription = this.searchLocationKeyword;
    }

    this.isLoadingLocation = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingLocation = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      return AppDelegate.openErrorBar(response);
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.locationTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((location) => {
      const lang = Session.selectedLanguage;
      const description = location.description[lang] ? location.description[lang] : location.description.en;
      return {
        value: location.code,
        label: `${description} (${location.code})`,
      };
    });
  }

  // recipient email address ng select handler
  onSearchRecipient(event) {
    this.searchRecipientKeyword = event.term;
    this.searchRecipientObserver.next();
  }

  clearRecipientFilter() {
    this.searchRecipientKeyword = null;
    this.searchRecipients();
  }

  async searchRecipients() {
    this.recipientList = [];
    this.recipientPageNumber = 1;
    this.recipientTotalPage = null;
    this.recipientList = await this.requestRecipientList(this.recipientPageNumber) as any;
  }

  async onRecipientScrollToEnd() {
    if (this.recipientPageNumber < this.recipientTotalPage) {
      this.recipientPageNumber++;

      let recipients = await this.requestRecipientList(this.recipientPageNumber) as any;

      // just append will not trigger ng-select change detection, need to update array reference
      this.recipientList = this.recipientList.concat(recipients);
    }
  }

  private async requestRecipientList(pageNumber?: number) {
    const request = new JM.JMRequestPostsPostSummary();

    request.parameters = ['name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.recipientPageSize;
    request.systemName = JMENUM.SourceSystem.CCEPJM;

    if (this.searchRecipientKeyword) {
      request.filter = {
        name: this.searchRecipientKeyword
      };
    }

    this.isLoadingRecipient = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingRecipient = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      return AppDelegate.openErrorBar(response);
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.recipientTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((recipient) => {
      const description = recipient.name;
      return {
        value: description,
        label: description,
      };
    });
  }

  get getMaxRemarksLength():number{
    return Constants.TEXTAREA_MAX_LENGTH;
  }
}
