<button 
  type="button" 
  class="{{buttonClass}}" 
  (click)="onClicked()" 
  [disabled]="isDisabled"
  id="{{id}}">
  <ng-container *ngIf="isLoading">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{'global.loading' | translate}}
  </ng-container>
  <ng-container *ngIf="!isLoading">
      <i class="fas {{iconClass}}" [ngClass]="{'space': iconClass}"></i>{{text | translate}}
  </ng-container>
</button>