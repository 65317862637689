<div class="wrapper non-pmsmc-page">
  <!-- <app-nav [navbarTitle]="'服務通知 Service Notification'"></app-nav>

  <app-sidebar [type]="'default'"></app-sidebar> -->

  <div id="content">
    <div class="" *ngIf="!sn && errorWhenGetSn">
      服務通知已被取消或重新派送
      SN is already cancelled or re-routed.
    </div>
    <div class="sn-wrap" *ngIf="sn">
      <div class="row col-12 mb-4">
        <img class="logo mx-auto" src="/assets/img/emsd_logo.jpg">
      </div>
      <div class="row col-12 mb-0">
        <h4 class="">
          <i class="fa fa-fw fa-bell"></i>服務通知 Service Notification
        </h4>
      </div>
      <div class="row mb-4">
        <div class="col-12 ">CCeP-JM Service Notification Details (Non-PMSMC Contractors) 服務通知（非PMSMC外判商）</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">CCeP-JM服務通知編號 Service Notification No.:</div>
        <div class="col col-8">{{ sn.snNumber }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">優先次序 Priority:</div>
        <div class="col col-8">{{ priorityTranslate[sn.priority].zh }} {{ priorityTranslate[sn.priority].en }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">服務通知狀態 Service Notification Status:</div>
        <div class="col col-8">{{ snStatusTranslte[sn.status].zh }} {{ snStatusTranslte[sn.status].en }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">聯絡人 Contact Person:</div>
        <div class="col-8">{{ sn.contactPerson }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">聯絡電話 Contact Number:</div>
        <div class="col col-8">{{ sn.contactNumber.join(", ") }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">客戶部門 Client:</div>
        <div class="col col-8">
          {{ clientDetail.zh }}
          {{ clientDetail.en }}
        </div>
      </div>
      <div class="row">
        <div class="col col-4 bold">區域 District: </div>
        <div class="col col-8">{{ districtNameTranslate[sn.district].zh }} {{ districtNameTranslate[sn.district].en }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">地點 Location:</div>
        <div class="col col-8">
          {{ locationDetail.zh }}
          {{ locationDetail.en }}
        </div>
      </div>
      <div class="row">
        <div class="col col-4 bold">地點細節 Location Details:</div>
        <div class="col col-8">{{ sn.locationDetail }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">設備總類 Equipment Category: </div>
        <div class="col col-8">{{ sn.equipmentCategory }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">設備類別 Equipment Type: </div>
        <div class="col col-8">{{ sn.equipmentType }}</div>
      </div>
      <div class="row">
        <div class="col col-4 bold">故障詳情 Fault Details by Client:</div>
        <div class="col col-8">{{ sn.faultDetail }}</div>
      </div>
            
      <div *ngIf="checkActionEnable('createJobCard')">
        <div class="row mt-4">
          <div class="col-12">
            請按 "建立工作卡" 進行下一步:<br>
            Please press "Create JobCard" button to proceed:
          </div>
        </div>
        <div class="row mb-2 mt-2" >
          <div class="col-auto">
            <button class="standard brand-amber d-inline-block mr-5" (click)="onClickCreateJobCard()" [disabled]="isCreateJobCardButtonLoading">
              <span *ngIf="isCreateJobCardButtonLoading">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                載入中 Loading...
              </span>
              <span *ngIf="!isCreateJobCardButtonLoading">建立工作卡 Create JobCard</span>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="checkActionEnable('acknowledge')">
        <div class="row mt-4">
          <div class="col-12">
            請按 "確認" 或 "拒絕" 回應服務通知:<br>
            Please press "Acknowledge" or "Reject" button to indicate your response:
          </div>
        </div>
        <div class="row mb-2 mt-2" >
          <div class="col-auto">
            <button class="standard brand-amber d-inline-block mr-5" (click)="onClickAcknowledge()" [disabled]="isAcknowledgeButtonLoading">
              <span *ngIf="isAcknowledgeButtonLoading">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                載入中 Loading...
              </span>
              <span *ngIf="!isAcknowledgeButtonLoading">確認 Acknowledge</span>
            </button>
          </div>
        </div>
      </div>

      <div class="mt-4" *ngIf="checkActionEnable('reject')">
        <div class="col-12 bold p-0">
          <span class="red">* </span>
          請提供拒絕服務通知原因 Please provide reject reason:
        </div>

        <div class="mt-1">
          <ng-container *ngFor="let option of rejectOptions; let i = index">
            <div class="radio-wrap custom-control custom-radio">
              <input class="radio custom-control-input" type="radio" id="rejectReason{{i}}" [(ngModel)]="rejectCode" name="reject-reason" value="{{option.value}}">
              <label class="custom-control-label m-0" for="rejectReason{{i}}">{{option.description}}</label>
            </div>
          </ng-container>
        </div>

        <div class="col-8 mt-3 p-0">
          <div>備註 Remarks:</div>
          <div class="mt-1">
            <textarea type="text" class="form-control" [(ngModel)]="rejectRemarks" placeholder="備註 Remarks" [ngModelOptions]="{standalone: true}" rows="6"></textarea>
          </div>
        </div>

        <div class="mt-3">
          <button class="standard brand-red d-inline-block mr-5" (click)="onClickReject()" [disabled]="isRejectButtonLoading || !rejectCode">
            <span *ngIf="isRejectButtonLoading">
              <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
              載入中 Loading...
            </span>
            <span *ngIf="!isRejectButtonLoading">拒絕 Reject</span>
          </button>
        </div>
      </div>


      <div *ngIf="checkActionEnable('completeJobCard')" class="mt-4">
        <div class="row mt-4">
          <div class="col-12">
            <h4>詳細工作資料 Job Details Information</h4>
          </div>
        </div>

        <div>

          <div class="row">
            <div class="col col-4">
              <div>預約到場時間</div> 
              <div>Appointment Time</div>
            </div>
            <div class="col col-8 row">
              <div class="pl-2">
                <input class="form-control date-picker-input-box" placeholder="YYYY/MM/DD" name="appointmenttime"
                  [(ngModel)]="jobCardInput.appointmentDate" ngbDatepicker #appointmenttime="ngbDatepicker" [disabled]="isCompleteJobCardButtonLoading"
                  (click)="appointmenttime.toggle()" readonly>
              </div>
              <div class="pl-2">
                <ngb-timepicker [(ngModel)]="jobCardInput.appointmentTime" [disabled]="isCompleteJobCardButtonLoading"></ngb-timepicker>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-4">
              <div>工作開始時間</div> 
              <div>Start Time</div>
            </div>
            <div class="col col-8 row">
              <div class="pl-2">
                <input class="form-control date-picker-input-box" placeholder="YYYY/MM/DD" name="starttime"
                  [(ngModel)]="jobCardInput.startDate" ngbDatepicker #starttime="ngbDatepicker" [disabled]="isCompleteJobCardButtonLoading"
                  (click)="starttime.toggle()" readonly>
              </div>
              <div class="pl-2">
                <ngb-timepicker [(ngModel)]="jobCardInput.startTime" [disabled]="isCompleteJobCardButtonLoading"></ngb-timepicker>
              </div >
            </div>
          </div>

          <div class="row">
            <div class="col col-4">
              <div>工作完成時間</div> 
              <div>Completion Time</div>
            </div>
            <div class="col col-8 row">
              <div class="pl-2">
                <input class="form-control date-picker-input-box" placeholder="YYYY/MM/DD" name="completiontime"
                  [(ngModel)]="jobCardInput.completionDate" ngbDatepicker #completiontime="ngbDatepicker" [disabled]="isCompleteJobCardButtonLoading"
                  (click)="completiontime.toggle()" readonly>
              </div>
              <div class="pl-2">
                <ngb-timepicker [(ngModel)]="jobCardInput.completionTime" [disabled]="isCompleteJobCardButtonLoading"></ngb-timepicker>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-4">
              <div>功能位置編號</div> 
              <div>Functional Location</div>
            </div>
            <div class="col col-8 row">
              <div class="pl-2">
                {{jobCard.functionalLocation}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-4">
              <div>設備總類</div> 
              <div>Equipment Category</div>
            </div>
            <div class="col col-8 row">
              <div class="pl-2">
                {{sn.equipmentCategory}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-4">
              <div>設備類別</div> 
              <div>Equipment Type</div>
            </div>
            <div class="col col-8 row">
              <div class="pl-2">
                {{sn.equipmentType}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-4">
              <div>設備</div> 
              <div>Equipment</div>
            </div>
            <div class="col col-8 row">
              <div class="col-8 pl-2">
                <ng-select [items]="equipmentOptions" bindLabel="description" bindValue="value" placeholder="設備 Equipment"
                  [(ngModel)]="jobCardInput.equipment" [ngModelOptions]="{standalone: true}" [disabled]="isCompleteJobCardButtonLoading || jobCard.equipment">
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-4">
              <div>工作描述 (外判商)</div> 
              <div>Job Description (Contractor)</div>
            </div>
            <div class="col col-8 row">
              <div class="col-8 pl-2">
                <textarea type="text" class="form-control" [(ngModel)]="jobCardInput.jobDescription" placeholder="工作描述 Job Description" [ngModelOptions]="{standalone: true}" rows="6"></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-4">
              <div>工作完成方式</div> 
              <div>Completion Reason</div>
            </div>
            <div class="col col-8 row">
              <div class="col-8 pl-2">
                <div class="mt-1">
                  <ng-container *ngFor="let option of completeOptions; let i = index">
                    <div class="radio-wrap custom-control custom-radio">
                      <input class="radio custom-control-input" type="radio" id="completeReason{{i}}" [(ngModel)]="jobCardInput.completeCode" name="complete-reason" value="{{option.value}}">
                      <label class="custom-control-label m-0" for="completeReason{{i}}">{{option.description}}</label>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>


        </div>
        
        <div class="row mb-2 mt-2" >
          <div class="col-auto">
            <button class="standard brand-amber d-inline-block mr-5" (click)="onClickCompleteJobCard()" [disabled]="isCompleteJobCardButtonLoading">
              <span *ngIf="isCompleteJobCardButtonLoading">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                載入中 Loading...
              </span>
              <span *ngIf="!isCompleteJobCardButtonLoading">完成工作卡 Complete JobCard</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          請按以下連結參閱服務通知詳情附件:<br>
          Please press below button for attachment to view Service Notification details:
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <app-submit-button 
            [id]="'id-button-export_pdf'"
            [text]="'輸出 Export PDF'"
            [buttonClass]="'standard brand-blue'"
            [iconClass]="'space fa-file-download space'"
            [isDisabled]="isExportPdfButtonLoading"
            [isLoading]="isExportPdfButtonLoading"
            (onClick)="onClickExportPdf()" 
          ></app-submit-button>

        </div>
      </div>
    </div>

  </div>
</div>