<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="row mx-0">
    <div class="col-12 col-lg-6 top">
      <div *ngIf="this.haServiceReportUploadPreviewParam.isHaSn && !this.isHSDServiceIntegrationEnabled">
        <div class="mb-2">
          <span class="title">{{ "component.attachment.haServiceReport" | translate }}</span>
          <app-upload-previewer #ha_service_report_upload_previewer
            id="ha-service-upload-preview"
            [parameters]="haServiceReportUploadPreviewParam"
          ></app-upload-previewer>
        </div>
        <hr class="underline" />
        <app-tablex id="sn-attachment-table" #haServiceTablex [tablexParam]='haTablexParam' class='mt-3'></app-tablex>
      </div>

      <div class="mb-2 bottom">
        <app-upload-attachment-tableview #upload_previewer id="sn-upload-preview"
        [parameters]="uploadPreviewParam"
        (onSubmitAttachment)="onClickSubmitAttachment($event)"></app-upload-attachment-tableview>
        <div class="title mb-2">{{ "component.attachment.attachment-title" | translate }}</div>
        <hr class="underline" />
        <app-tablex id="sn-attachment-table" #attachmentTablex [tablexParam]='tablexParam' class='mt-3'></app-tablex>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="title mb-2">{{ "component.attachment.preview-title" | translate }}</div>
      <hr class="underline" />
      <attachment-previewer #attachment_preview
        id="attachment-preview"
        class="d-block mt-3"
        [file]="file"
        [fileDetails]="fileDetails"
        [isLoading]="isPreviewerLoading"></attachment-previewer>
    </div>

  </div>
</div>
