import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { JM, JMENUM, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { saveAs } from 'file-saver';
import { AppDelegate } from 'src/app/AppDelegate';
import * as utility from 'src/app/services/utility';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AllowSwitchStage, SnBatchCompleteStage } from '../shared/sn-batch-complete.model';

@Component({
  selector: 'app-sn-batch-complete',
  templateUrl: './sn-batch-complete.component.html',
  styleUrls: ['./sn-batch-complete.component.scss']
})
export class SnBatchCompleteComponent implements OnInit {

  stage: SnBatchCompleteStage = SnBatchCompleteStage.DOWNLOAD_TEMPLATE;

  // file
  selectedFile?: File;
  failedReport?: Blob;

  // data
  validationResult = {
    successCount: 0,
    failedCount: 0,
  };
  fileSelector = {
    clear: ''
  }
  button = {
    downloadTemplate: {
      isLoading: false
    },
    submit: {
      isLoading: false
    },
    upload: {
      isLoading: false
    }

  };

  constructor() { }

  ngOnInit() {
    const allowPermissions = [JMENUM.Permission.JOBCARD_COMPLETE, JMENUM.Permission.JOBCARD_COMPLETE_ALL];
    const hasPermission = JMUTILITY.hasPermissions(Session.userInfo, allowPermissions, false);
    if (!hasPermission) {
      AppDelegate.navigate(['/']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
  }

  //==============================
  // api
  private async requestExportSnForBatchComplete() {
    let request = new JM.JMRequestSnExportSnForBatchComplete();

    this.button.downloadTemplate.isLoading = true;
    const response: JM.JMResponseSnExportSnForBatchComplete = await AppDelegate.sendJMRequest(request);
    this.button.downloadTemplate.isLoading = false;

    if (!response) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.saveExcel(response, 'Batch Complete SN List');
    this.switchStage(SnBatchCompleteStage.UPLOAD);
  }

  private async requestValidateBatchCompleteSn() {
    let request = new JM.JMRequestSnValidateBatchCompleteSn();
    request.stream = new FormData;
    request.stream.set('files', this.selectedFile, this.selectedFile.name);

    this.button.upload.isLoading = true;
    const response: JM.JMResponseSnValidateBatchCompleteSn = await AppDelegate.sendJMRequestFile(request, request.stream);
    this.button.upload.isLoading = false;

    if (!response && response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    const payload = response.payload;
    this.setValidationResult(payload.successCount, payload.failedCount);

    if (payload.failedCount === 0) {
      this.switchStage(SnBatchCompleteStage.VERIFICATION_SUCCESSFUL);
    } else {
      this.switchStage(SnBatchCompleteStage.VERIFICATION_FAILED);
      this.selectedFile = undefined;
      this.clearFileSelector();

      const base64 = payload.reportFile;
      this.failedReport = utility.base64ToBlob(base64, payload.fileType);
    }
  }

  private async requestSubmitBatchCompleteSn() {
    let request = new JM.JMRequestSnSubmitBatchCompleteSn();
    request.stream = new FormData;
    request.stream.set('files', this.selectedFile, this.selectedFile.name);

    this.button.submit.isLoading = true;
    const response: JM.JMResponseSnSubmitBatchCompleteSn = await AppDelegate.sendJMRequestFile(request, request.stream);
    this.button.submit.isLoading = false;

    if (!response && response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.switchStage(SnBatchCompleteStage.SUBMITTED);
  }

  private setValidationResult(successCount: number, failedCount: number) {
    this.validationResult.successCount = successCount;
    this.validationResult.failedCount = failedCount;
  }

  //==============================
  // ui event
  onClickedBackButton() {
    this.selectedFile = undefined;
    this.clearFileSelector();
    this.switchStage(SnBatchCompleteStage.DOWNLOAD_TEMPLATE);
  }

  onClickedDownloadTemplateButton() {
    this.requestExportSnForBatchComplete();
  }

  onClickedDownloadErrorReportButton() {
    if (this.failedReport) {
      this.saveExcel(this.failedReport, 'Batch Complete Import Report');
    }
  }

  onClickedNextButton() {
    this.switchStage(SnBatchCompleteStage.UPLOAD);
  }

  onClickedSubmitButton() {
    if (this.validateFile()) {
      this.requestSubmitBatchCompleteSn();
    }
  }

  onClickedUploadButton() {
    if (this.validateFile()) {
      this.requestValidateBatchCompleteSn();
      this.setValidationResult(0, 0);
    }
  }

  onUpdatedFile(file?: File) {
    this.selectedFile = file;
  }

  private clearFileSelector() {
    this.fileSelector.clear = Math.random().toString();
  }

  private switchStage(targetStage: SnBatchCompleteStage) {
    const allowSwitchStageList = AllowSwitchStage[this.stage];
    if (allowSwitchStageList.includes(targetStage)) {
      this.stage = targetStage;
    }
  }

  private saveExcel(file: any, fileNamePrefix: string) {
    const dateTime = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    const fileName = `${fileNamePrefix} ${dateTime}.xlsx`;
    saveAs(file, fileName);
  }

  private validateFile() {
    if (!this.selectedFile) {
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-complete.message.file-not-found'));
      return false;
    }

    // check file type
    if (this.selectedFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-complete.message.upload-xlsx-file'));
      return false;
    }

    // check file size
    const fileSizeMB = this.selectedFile.size / 1024 / 1024; // B to MB
    if (fileSizeMB >= 10) { // cannot larger than 10 MB
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-complete.message.file-size-too-large'));
      return false;
    }

    return true;
  }

  //==============================
  // get property
  get isDownloadTemplateStage(): boolean { return this.stage === SnBatchCompleteStage.DOWNLOAD_TEMPLATE; }
  get isUploadStage(): boolean { return this.stage === SnBatchCompleteStage.UPLOAD; }
  get isVerificationSuccessfulStage(): boolean { return this.stage === SnBatchCompleteStage.VERIFICATION_SUCCESSFUL; }
  get isVerificationFailedStage(): boolean { return this.stage === SnBatchCompleteStage.VERIFICATION_FAILED; }
  get isSubmittedStage(): boolean { return this.stage === SnBatchCompleteStage.SUBMITTED; }

  get title(): string { return JMLanguage.translate('component.sn-batch-complete.main.title'); }
  get uploadTitle(): string {
    if (this.isUploadStage) {
      return JMLanguage.translate('component.sn-batch-complete.main.download-instruction');
    }
    if (this.isVerificationFailedStage) {
      return JMLanguage.translate('component.sn-batch-complete.main.upload-again-instruction');
    }
    return '';
  }

  get isDisabledAllButton(): boolean {
    const isDisabled = this.button.downloadTemplate.isLoading
      || this.button.submit.isLoading
      || this.button.upload.isLoading;
    return isDisabled;
  }
}
