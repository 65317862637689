export enum SnStatus {
    draft = 1,
    pendingAcknowledge = 2,
    noResponse = 3,
    rejected = 4,
    acknowledged = 5,
    inProgress = 6,
    cancelled = 7,
    completed = 8,
    signedOff = 9,
}

export const SnStatusTranslate = [
    {en: "", zh: ""}, //no 0
    {en: "Draft", zh: ""},
    {en: "Pending Acknowledge", zh: "等待確認"},
    {en: "No Response", zh: ""},
    {en: "Rejected", zh: ""},
    {en: "Acknowledged", zh: ""},
    {en: "In Progress", zh: ""},
    {en: "Cancelled", zh: ""},
    {en: "Completed", zh: ""},
    {en: "Sign Off", zh: ""},
]

