<div *ngIf="isPaginationVisable || enablePageResize"
  style="display: flex;justify-content: space-between;margin-top:8px">
  <ul *ngIf="!isPaginationLeftSide &&  enablePageResize && !isPaginationAtBottom" class="pagination"
    style="margin-left: 8px">
    <li class="page-item" *ngFor="let option of pageSizeOptions" (click)="onPageSizeClicked(option)">
      <p class="page-link" [ngClass]="{'active': pageSize == option}">{{option}}</p>
    </li>
  </ul>

  <app-pagination *ngIf=" isPaginationVisable && !isPaginationAtBottom" [maximumPageCount]="maximumPageCount"
    [pageIndex]="currentPageIndex" [isLeft]="isPaginationLeftSide" (onPageChanged)="onPageNumberClicked($event)">
  </app-pagination>

  <ul *ngIf="isPaginationLeftSide && enablePageResize && !isPaginationAtBottom" class="pagination">
    <li class="page-item" *ngFor="let option of pageSizeOptions" (click)="onPageSizeClicked(option)">
      <p class="page-link" [ngClass]="{'active': pageSize == option}">{{option}}</p>
    </li>
  </ul>
</div>


<button *ngIf="excelDataObservable" class="standard brand-blue float-right" (click)="exportExcel()"><i
    class="fas fa-download" style="margin-right:8px "></i> {{'global.export-excel' | translate}}</button>

<table id="{{dataSource.id}}" mat-table [dataSource]="tableContent" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

  <ng-container *ngFor="let column of filterColumns" matColumnDef="{{column.key}}">
    <th mat-header-cell *matHeaderCellDef class="input-header">
      <input type="text" [(ngModel)]="column.value" placeholder="" (ngModelChange)="handlingFilterInput()" id={{dataSource.id}}_{{column.key}}_inputbox>
    </th>
  </ng-container>

  <ng-container *ngFor="let column of columns;let i = index" matColumnDef="{{column.key}}">

    <th mat-header-cell *matHeaderCellDef class="title-header">
      <button *ngIf="column.button" class="{{column.button.class}}" (click)="onButtonClicked(column.button)">
        <i class="{{column.button.icon}}"></i> {{column.button.description | translate}}
      </button>
      <div id="{{dataSource.id}}_header_{{column.id}}">
        {{column.description | translate}}
        <span *ngIf="enableSorting && column.type != 'button'">
          <i class="fas" style="cursor: pointer"
            [ngClass]="{'fa-sort': column.order == 0,'fa-sort-up': column.order == 1,'fa-sort-down': column.order == -1}"
            (click)="onSortingButtonClicked(column)"></i>
        </span>
      </div>

      <input *ngIf="filterPosition == 'inHeader' && column.type == 'text'" type="text"
        [(ngModel)]="filterColumns[i].value" placeholder="" (ngModelChange)="handlingFilterInput()">
    </th>

    <ng-container *ngIf="column.type == 'input'">
      <td class="input-type-cell" mat-cell [ngClass]="{'clickable':enableClickRow}"
        (click)="$event.stopPropagation();onTableRowClicked(element,i)" *matCellDef="let element;let i = index">
        <input type="text" id="{{dataSource.id}}_r{{i}}_{{column.id}}" [readOnly]="!element[column.key].isEditable"
          (blur)="checkValidation(element[column.key])" [ngClass]="{'active': element[column.key].isEditable}"
          [(ngModel)]="element[column.key].value" placeholder="">
      </td>
    </ng-container>
    <ng-container *ngIf="column.type == 'text'">
      <td id="{{dataSource.id}}_r{{i}}_{{column.id}}" mat-cell
        (click)="$event.stopPropagation();onTableRowClicked(element,i)"
        [ngClass]="{'clickable':enableClickRow}"
        *matCellDef="let element;let i = index">
        <p [ngClass]="{'left': column.textAlign == 'left','right':column.textAlign == 'right'}">
          {{element[column.key] | translate}}
        </p>
      </td>
    </ng-container>
    <ng-container *ngIf="column.type == 'dateTime'">
      <td class="input-type-cell" mat-cell [ngClass]="{'clickable':enableClickRow}"
        (click)="$event.stopPropagation();onTableRowClicked(element,i)" *matCellDef="let element;let i = index">
        <p [ngClass]="{'left': column.textAlign == 'left','right':column.textAlign == 'right'}">
          {{element[column.key] | date:column.format}}
        </p>
      </td>
    </ng-container>
    <ng-container *ngIf="column.type == 'hyperlink'">
      <td id="{{dataSource.id}}_r{{i}}_{{column.id}}" mat-cell
        (click)="$event.stopPropagation();onTableRowClicked(element,i)"
        [ngClass]="{'clickable':enableClickRow}"
        *matCellDef="let element;let i = index">
        <a [ngClass]="{'left': column.textAlign == 'left','right':column.textAlign == 'right'}"
          routerLink="{{element[column.key].url}}">
          {{element[column.key].description | translate}}
        </a>
      </td>
    </ng-container>
    <ng-container *ngIf="column.type == 'button'">
      <td mat-cell (click)="$event.stopPropagation();onTableRowClicked(element,i)"
        *matCellDef="let element;let i = index"
        [ngClass]="{'clickable':enableClickRow}">
        <div class="action row justify-content-around">
          <button id="{{dataSource.id}}_r{{i}}_{{button.id}}" *ngFor="let button of element[column.key]"
            class="{{button.class}}" (click)="$event.stopPropagation();onButtonClicked(button)" [hidden]="button.isHidden" [disabled]="button.disable">
            <i class="{{button.icon}}"></i>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container *ngIf="column.type == 'row'">
      <td mat-cell (click)="$event.stopPropagation();onTableRowClicked(element,i)"
        *matCellDef="let element;let i = index"
        [ngClass]="{'clickable':enableClickRow}">
        <div class="{{column.class}}">
          <div *ngFor="let row of element[column.key]">
            <p class="{{row.class}}">{{row.description | translate}}<span
                *ngIf="row.description && row.description != ''">:</span> <span *ngFor="let value of row.values"
                class="{{value.class}}">
                <span *ngIf="value.type == 'rowValue'">{{value.description}}<br></span>
                <button *ngIf="value.type == 'button'" id="{{dataSource.id}}_r{{i}}_{{value.id}}" 
                class="{{value.class}}" (click)="$event.stopPropagation();onButtonClicked(value)" [hidden]="value.isHidden" [disabled]="value.disable"
                [ngClass]="{'selected-tag-button': dataSource.selectedTagId && dataSource.selectedTagId == value.tagId && dataSource.selectedRowIndex == i}">
                <i class="{{value.icon}}"></i>
                {{value.description}}
                </button>
              </span></p>
          </div>
        </div>

      </td>
    </ng-container>

    <ng-container *ngIf="column.type == 'object'">
      <td mat-cell (click)="$event.stopPropagation();onTableRowClicked(element,i)"
        *matCellDef="let element;let i = index"
        [ngClass]="{'clickable':enableClickRow}">
        <div style="display: flex">
          <div *ngFor="let object of element.objects" class="{{object.class}}">

            <ng-container *ngIf="object.type != 'button'">
              <span *ngFor="let subObject of object.subObjects"
                class="{{subObject.class}}">{{subObject.description}}</span>
            </ng-container>

            <ng-container *ngIf="object.type == 'button'">
              <button *ngFor="let subObject of object.subObjects" class="{{subObject.class}}"
                (click)="$event.stopPropagation();onButtonClicked(subObject)">
                <i class="{{subObject.icon}}"></i>{{subObject.description | translate}}
              </button>
            </ng-container>


          </div>
        </div>

      </td>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="filterPosition == 'aboveHeader'">
    <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [ngClass]="{'selected': i == dataSource.selectedRowIndex,'highlight': this.tableContent[i].highlight}"></tr>
</table>

<div *ngIf="isPaginationVisable || enablePageResize"
  style="display: flex;justify-content: space-between;margin-top:8px ">
  <ul *ngIf="!isPaginationLeftSide &&  enablePageResize && isPaginationAtBottom" class="pagination"
    style="margin-left: 8px">
    <li class="page-item" *ngFor="let option of pageSizeOptions" (click)="onPageSizeClicked(option)">
      <p class="page-link" [ngClass]="{'active': pageSize == option}">{{option}}</p>
    </li>
  </ul>

  <app-pagination *ngIf="isPaginationVisable && isPaginationAtBottom" [maximumPageCount]="maximumPageCount"
    [pageIndex]="currentPageIndex" [isLeft]="isPaginationLeftSide" (onPageChanged)="onPageNumberClicked($event)">
  </app-pagination>

  <ul *ngIf="isPaginationLeftSide && enablePageResize && isPaginationAtBottom" class="pagination">
    <li class="page-item" *ngFor="let option of pageSizeOptions" (click)="onPageSizeClicked(option)">
      <p class="page-link" [ngClass]="{'active': pageSize == option}">{{option}}</p>
    </li>
  </ul>
</div>