<div class="font-size-xl m-0 mb-2">
    <div class="overflow-hidden">
      <div class="d-inline-block">
        {{ "component.pm-job-job-progress.title" | translate}} 
      </div>
    </div>
</div>

<hr class="underline" />


<ng-container *ngIf="pageMode == JMPageMode.VIEW">
  <div class="row mx-0 justify-content-start align-items-stretch">
    <div class="col-12 col-md-5 mb-3 task-item-container-col px-2">
      <div class="task-item-container row mx-0 py-4">
        <div class="col-12 align-self-end m-2">
          <div class="d-block d-flex">
            <span class="mandatory-mark">*</span>
            {{ "component.task-progress.arrival-start-time" | translate }}
            <ng-container *ngIf="pageMode === JMPageMode.VIEW" >
              <app-arrival-gps [arrivalGpsParam]="arrivalGpsParam"></app-arrival-gps>
            </ng-container>
          </div>
        </div>
  
        <div class="col-12 row mx-0 align-items-center justify-content-end">
            <app-date-input class="col-8 m-0 mb-2 p-0" [isError]="errorFields.startTime"
              [input]="dateInputSet['startTime']" [inputId]="'startDateInput'" [field]="'startTime'" 
              [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)" [disabled]="true">
            </app-date-input>
            <app-time-input class="col-4 m-0 mb-2 p-0" [isError]="errorFields.startTime" [customClass]="'form-control'"
              [input]="timeInputSet['startTime']" [inputId]="'startTimeInput'" [field]="'startTime'" 
              [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)" [disabled]="true">
            </app-time-input>
        </div>
      </div>
    </div>
  
    <div class="col-12 col-md-5 mb-3 task-item-container-col px-2">
      <div class="task-item-container row mx-0 py-4">
        <div class="col-12 align-self-end m-2">
          <div class="d-block">
            <span class="mandatory-mark">*</span>
            {{ "component.task-progress.completion-time" | translate }}
          </div>
        </div>
  
        <div class="col-12 row mx-0 align-items-center justify-content-end">
          <app-date-input class="col-8 m-0 mb-2 p-0" [isError]="errorFields.completionTime"
            [input]="dateInputSet['completionTime']" [inputId]="'completionTimeInput'" [field]="'completionTime'" 
            [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)" [disabled]="true">
          </app-date-input>
          <app-time-input class="col-4 m-0 mb-2 p-0" [isError]="errorFields.completionTime" [customClass]="'form-control'"
            [input]="timeInputSet['completionTime']" [inputId]="'completionTimeInput'" [field]="'completionTime'" 
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)" [disabled]="true">
          </app-time-input>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="pageMode == JMPageMode.CREATE || pageMode == JMPageMode.EDIT">
<div class="row mx-0 justify-content-start align-items-stretch">
  <div class="col-12 col-md-5 mb-3 task-item-container-col px-2">
    <div class="task-item-container row mx-0 py-4">
      <!-- <div class="col-12 mb-3">
        <div class="d-inline-block bold">{{ "component.task-progress.response" | translate }}</div>
      </div> -->
      <div class="col-12 align-self-end m-2">
        <div class="d-block">
          <span class="mandatory-mark">*</span>
          {{ "component.task-progress.arrival-start-time" | translate }}
        </div>
      </div>

      <div  class="col-12 row mx-0 align-items-center justify-content-end">
        <app-date-input class="col-8 m-0 mb-2 p-0" [isError]="inputFieldSettings?.startTime?.failed"
          [input]="dateInputSet['startTime']" [inputId]="'startDateInput'" [field]="'startTime'" 
          [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
        </app-date-input>
        <app-time-input class="col-4 m-0 mb-2 p-0" [isError]="inputFieldSettings?.startTime?.failed" [customClass]="'form-control'"
          [input]="timeInputSet['startTime']" [inputId]="'startTimeInput'" [field]="'startTime'" 
          [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
        </app-time-input>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-5 mb-3 task-item-container-col px-2">
    <div class="task-item-container row mx-0 py-4">
      <div class="col-12 align-self-end m-2">
        <div class="d-block">
          <span class="mandatory-mark">*</span>
          {{ "component.task-progress.completion-time" | translate }}
        </div>
      </div>

      <div class="col-12 row mx-0 align-items-center justify-content-end">
        <app-date-input class="col-8 m-0 mb-2 p-0" [isError]="inputFieldSettings?.completionTime?.failed"
          [input]="dateInputSet['completionTime']" [inputId]="'completionTimeInput'" [field]="'completionTime'" 
          [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
        </app-date-input>
        <app-time-input class="col-4 m-0 mb-2 p-0" [isError]="inputFieldSettings?.completionTime?.failed" [customClass]="'form-control'"
          [input]="timeInputSet['completionTime']" [inputId]="'completionTimeInput'" [field]="'completionTime'" 
          [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
        </app-time-input>
      </div>
    </div>
  </div>
</div>
</ng-container>