<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [navbarTitle]="navbarTitle"
    [breadcrumbs]="breadcrumbs"
  ></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding">

    <div class="row mx-0">
      <div class="col-xl-6 col-12">
        <app-pm-plan-summary #pmPlanSummaryElem [plan]="plan" [pageMode]="pageMode" class="d-block mb-5" (refreshActionButton)="buttonHandling()" (onChangeWorkCentreSelection)="onChangeWorkCentreSelection()"
        (onChangeSchTypeAndFreq)="onChangeSchTypeAndFreq($event)">
        </app-pm-plan-summary>
        <app-pm-plan-particulars #pmPlanParticularsElem [plan]="plan" [pageMode]="pageMode" 
        >
      </app-pm-plan-particulars>
      <ng-container *ngIf="hasPMReminderFeatureToggle">
        <app-pm-plan-reminders #pmPlanRemindersElem [plan]="plan" [pageMode]="pageMode" 
        >
        </app-pm-plan-reminders>
      </ng-container>
      </div>
      <div class="col-xl-6 col-12">
        <app-pm-plan-periods #pmPlanPeriodsElem class="d-block mb-5" 
          [plan]="plan" [pageMode]="pageMode" (onRefreshPeriod)="onRefreshPeriod()"
        >
        </app-pm-plan-periods>
        <app-pm-plan-remarks #pmPlanRemarksElem [plan]="plan" [pageMode]="pageMode">
        </app-pm-plan-remarks>
      </div>
    </div>
    
    <!-- Action Sidebar -->
    <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
      <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disabledActionSideBar"
        (clickEvent)="onActionButtonClicked($event)">
      </app-action-sidebar>
    </ng-container>

  </div>
</div>