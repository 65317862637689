import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Session } from '@services/session';

import * as moment from 'moment';
import { Constants } from 'src/constants';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { BasePage } from '../../model/base/base';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-ha-equipment-list-panel',
  templateUrl: './ha-equipment-list-panel.component.html',
  styleUrls: ['./ha-equipment-list-panel.component.scss']
})
export class HaEquipmentListPanelComponent extends BasePage implements OnInit {

  @Input() selectedHaEquipment: any;
  @Output() onSubmit = new EventEmitter<any>();

  // Table
  tablexFilter: any = {};
  tablexParam: {} = {};

  // Table column filter
  selectedRows = [];
  uiSelectedCount: number = 0;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];

  // HSDEquipment
  selectedEquipment: any = {};
  hsdEquipmentRecords: any = [];
  hsdEquipmentPageNumber: number = 1;
  hsdEquipmentPageSize: number = 10;
  hsdEquipmentPageCount = 1;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initAllTableHeader();
    this.initTablex();
  }

  //#region tablex setting
  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: true,
      enableColFilter: false,
      filter: {},
      tableRow: "row",
      tableClass: "ha-equipment-list-table",
      tableWrapperClass: "",
      currentPageSize: this.hsdEquipmentPageSize,
      currentPage: this.hsdEquipmentPageNumber,
      pageCount: this.hsdEquipmentPageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onPageNumberClicked,
      onRowClicked: this.onRowClicked,
      onFilterChanged: this.onFilterChanged,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      highlightedRows: [],
      headers: this.allColHeaders,
    }
  }

  public initAllTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: "ha-equipment-list-panel.component.table-column.id",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "d-none ",
      },
      {
        id: 'assetNumber',
        name: "ha-equipment-list-panel.component.table-column.ha-asset-number",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'ccsNumber',
        name: "ha-equipment-list-panel.component.table-column.ccs-number",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      //
      // id: 'regpc',
      // name: "ha-equipment-list-panel.component.table-column.regpc",
      // enableFilter: true,
      // type: TablexColumnType.Text,
      // horizontalAlign: TablexColumnHorizontalAlign.Left,
      // verticalAlign: TablexColumnVerticalAlign.Middle,
      // class: "col",
      //,
      {
        id: 'assetDescription',
        name: "ha-equipment-list-panel.component.table-column.description",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'HACluster',
        name: "ha-equipment-list-panel.component.table-column.cluster",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'hospitalCode',
        name: "ha-equipment-list-panel.component.table-column.ha-hospital-code",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'hospitalLocation',
        name: "ha-equipment-list-panel.component.table-column.hsd-hospital-code",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'clientNumber',
        name: "",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "d-none ",
      },
      {
        id: 'functionalLocation',
        name: "",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "d-none ",
      },
    ]
  }
  //#endregion

  //#region tablex action control
  onFilterChanged = (event, index, header, filter) => {
    this.resetSelectRow();
    this.tablexFilter = filter;
    for (let key in this.tablexFilter) {
      if (!this.tablexFilter[key]) {
        delete this.tablexFilter[key];
      }
    }
    this.hsdEquipmentPageNumber = 1;
    this.requestHSDEquipmentSummary();
  }

  onRowClicked = (index, row) => {
    if (this.tablexParam['highlightedRows'][index]) {
      this.resetSelectRow();
    } else {
      this.tablexParam['highlightedRows'] = []; //only selected row is highlighted
      this.tablexParam['highlightedRows'][index] = true;
      this.selectedRows = row;
      this.selectedEquipment = this.hsdEquipmentRecords.find(record => record.objId == row[0]);
    }
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.hsdEquipmentPageNumber = pageIndex;
    this.resetSelectRow();
    this.requestHSDEquipmentSummary();
  }

  resetSelectRow() {
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.selectedRows = [];
  }
  //#endregion

  //#region API call function
  async requestHSDEquipmentSummary() {
    const request: JM.JMRequestEquipmentsHSDEquipmentSummary = new JM.JMRequestEquipmentsHSDEquipmentSummary();
    request.hasHAEquipment = true;
    request.pageSize = this.hsdEquipmentPageSize;
    request.pageNumber = this.hsdEquipmentPageNumber;
    request.filter = this.renderRequestFilter(request);
    request.sortBy = 'createdAt';

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseEquipmentsHSDEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.totalCount > 0) {
      this.renderHSDRecord(response.payload.records);
    } else {
      this.tablexParam['content'] = [];
      this.hsdEquipmentRecords = [];
    }
    this.renderTable();
    this.hsdEquipmentPageCount = Math.ceil(response.payload.totalCount / this.hsdEquipmentPageSize);
    this.tablexParam['pageCount'] = this.hsdEquipmentPageCount;
  }
  //#endregion

  //#region render data function
  renderRequestFilter(request) {
    request.filter = {};

    for (let key in this.tablexFilter) {
      if (this.tablexFilter[key]) {
        switch (key) {
          case "ccsNumber":
            request.filter['equipment.equipmentNumber'] = this.tablexFilter[key];
            break;
          default:
            request.filter[key] = this.tablexFilter[key];
            break;
        }
      }
    }
    return request.filter;
  }

  renderHSDRecord(records) {
    this.hsdEquipmentRecords = records.map(data => {
      let haEquipment = data['HAEquipment'] ? data['HAEquipment'] : {};
      let obj = {
        objId: data['_id'],
        assetNumber: haEquipment['assetNumber'] ? haEquipment['assetNumber'] : data['assetNumber'],
        assetDescription: haEquipment['assetDescription'] ? haEquipment['assetDescription'] : data['assetDescription'],
        HACluster: haEquipment['HACluster'] ? haEquipment['HACluster'] : data['HACluster'],
        //regpc: data['regpc'],
        ccsNumber: data['equipment'] ? data['equipment']['equipmentNumber'] : null,
        clientNumber: data['clientNumber'],
        functionalLocation: data['functionalLocation'],
        hospitalLocation: data['hospitalLocation'],
      };
      
      if (haEquipment && haEquipment.HAEnrollment==JMENUM.RequestActive.ACTIVE) {
        obj['hospitalCode'] = haEquipment['hospitalCode'];
      }
      return obj;
    });
  }

  renderTable() {
    this.tablexParam['content'] = this.hsdEquipmentRecords.map((data, index) => {
      return [
        data.objId,
        data.assetNumber,
        data.ccsNumber,
        //data.regpc,
        data.assetDescription,
        data.HACluster,
        data.hospitalCode,
        data.hospitalLocation
      ];
    });
    this.tablexParam['isLoadingTable'] = false;
    if (this.selectedHaEquipment["assetNumber"]) {
      this.autoSelectRow(this.selectedHaEquipment["assetNumber"]);
    }
  }

  public autoSelectRow(assetNumber) {
    let index = this.hsdEquipmentRecords.findIndex(record => { return record["assetNumber"] == assetNumber });
    if (index >= 0) {
      this.tablexParam['selectedRowIndex'] = index;
      this.tablexParam['highlightedRows'][index] = true;
    }
  }
  //#endregion

  //#region Function For external call or emit Output
  public refreshTable() {
    this.resetSelectRow();
    if (this.selectedHaEquipment) {
      this.tablexParam["filter"] = { assetNumber: this.selectedHaEquipment["assetNumber"] };
      this.tablexFilter["assetNumber"] = this.selectedHaEquipment["assetNumber"];
    } else {
      this.tablexParam["filter"] = {};
      this.tablexFilter["assetNumber"] = undefined;
    }
    this.hsdEquipmentPageNumber = 1;
    this.requestHSDEquipmentSummary();
  }

  public onSubmitBtnClicked() {
    this.selectedHaEquipment = Object.assign(this.selectedHaEquipment, this.selectedEquipment);
    this.tablexFilter = {};
    this.tablexParam["filter"] = {};
    this.onSubmit.emit();
  }
  //#endregion


}
