import { Component, Input, OnInit, Injector } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session }  from 'src/app/services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { Constants } from 'src/constants';

export interface AddHATagFormParamInterface {
  onSave?:Function,
}

@Component({
  selector: 'add-tag-form',
  templateUrl: './add-tag-form.component.html',
  styleUrls: ['./add-tag-form.component.scss']
})

export class AddTagFormComponent implements OnInit {
  @Input() tagType:'ccs'|'ha';

  param:AddHATagFormParamInterface = {
    onSave: null
  };
  
  cacheWorkCentre?: string;

  mode:'edit'|'new' = 'new';
  uiEnableSaveButton:boolean = true;
  uiLoadingSaveButton:boolean = false;
  uiEnableDeleteButton:boolean = true;
  uiLoadingDeleteButton:boolean = false;

  // data
  tagGroupList: JMOBJ.HashTagGroup[] = [];

  uiTagGroup:string = null;
  uiTagGroupArray:{
    value:string,
    label:string,
  }[] = [];

  uiTagNameZh:string = '';
  uiTagNameEn:string = '';
  
  uiTagEquipmentCategory:string = null;
  uiTagEquipmentCategoryArray: any[] = null;

  uiTagEquipmentType:string = null;
  uiTagEquipmentTypeArray: any[] = null;

  uiDefaultPriority:JMENUM.JMHashTagPriority = null;
  uiAllDefaultPriorityArray: any[] = [
    { value: JMENUM.JMHashTagPriority.UserChoose, label: JMLanguage.translate("hashtag.priority.user-choose") },
    { value: JMENUM.JMHashTagPriority.Emergency, label: JMLanguage.translate("hashtag.priority.1") },
    { value: JMENUM.JMHashTagPriority.Urgent, label: JMLanguage.translate("hashtag.priority.2") },
    { value: JMENUM.JMHashTagPriority.NonUrgent, label: JMLanguage.translate("hashtag.priority.3") },
  ];
  uiDefaultType:string = null;
  uiAllDefaultTypeArray: any[] = [
    { value: "", label: JMLanguage.translate("hashtag.default-type.user-choose") },
    { value: "ZS01", label: "ZS01" },
    { value: "ZS02", label: "ZS02" },
    { value: "ZS04", label: "ZS04" },
  ];
  uiDefaultMAT:string = null;
  uiAllDefaultMATArray: {
    value: string,
    label: string,
  }[] = [
    { value: "" , label: JMLanguage.translate("hashtag.default-mat.user-choose")},
  ];

  uiAdditionalDropdown:string = '';
  uiAllAdditionalDropdownArray:[] = [];

  uiShowError:boolean = false;  // dont show error before first submission

  dropdownPageNumber:number = 1;
  dropdownPageSize:number = 20;
  uiSelectedDropDownArray:string[] = [];
  uiDropdownArray: {
    value: string,
    label: string,
  }[] = [];

  
  tag:JMOBJ.HashTag = undefined;
  constructor() {
    if (!this.tagType) { // set default value
      this.tagType = 'ccs';
    }
  }
  
  ngOnInit() {
    this.resetForm();
    this.requestDropdownSummary();
  }

  showTag(workCentre: string, tag?: JMOBJ.HashTag) {
    this.mode = tag?'edit':'new';
    this.tag = tag;

    if (tag) {
      this.uiTagGroup = this.tag.tagGroup._id;
      this.uiTagNameZh = this.tag.description.zh;
      this.uiTagNameEn = this.tag.description.en;
      this.uiTagEquipmentCategory = this.tag.equipmentCategory;
      this.uiTagEquipmentType = this.tag.equipmentType;

      this.uiDefaultPriority = this.tag.defaultPriority? this.tag.defaultPriority: JMENUM.JMHashTagPriority.UserChoose;
      this.uiDefaultType = this.tag.defaultOrderType? this.tag.defaultOrderType: '';
      this.uiDefaultMAT = this.tag.defaultMatType? this.tag.defaultMatType: '';
      this.uiSelectedDropDownArray = this.tag.additionalDropdown;
    }

    this.updateTagGroupList(workCentre);
    this.updateDefaultMATOption();
    this.updateEquipmentCategoryOption();
    this.updateEquipmentTypeOption();
  }
  
  private updateTagGroupList(workCentre: string) {
    if (this.cacheWorkCentre && this.cacheWorkCentre === workCentre) {
      this.autoSelectTagGroup();
    } else {
      // filter out TagGroups by selected WorkCentre
      this.cacheWorkCentre = workCentre;
      this.tagGroupList = [];

      this.requestAllTagGroups(workCentre);
    }
  }

  resetForm() {
    this.mode = 'new';
    this.tag = null;
    this.uiTagGroup = null;
    this.uiTagNameZh = '';
    this.uiTagNameEn = '';
    this.uiTagEquipmentCategory = null;
    this.uiTagEquipmentType = null;
    this.uiDefaultPriority = this.uiAllDefaultPriorityArray[0].value;
    this.uiDefaultType = this.uiAllDefaultTypeArray[0].value;
    this.uiDefaultMAT = this.uiAllDefaultMATArray[0].value;
    this.uiSelectedDropDownArray = [];

    this.uiShowError = false;
    this.uiEnableSaveButton = true;
    this.uiLoadingSaveButton = false;
    this.uiEnableDeleteButton = true;
    this.uiLoadingDeleteButton = false;

    this.updateDefaultMATOption();
    this.updateEquipmentCategoryOption();
    this.updateEquipmentTypeOption();
    
  }

  validateInput() {
    this.uiTagNameZh = this.uiTagNameZh.trim();
    this.uiTagNameEn = this.uiTagNameEn.trim();

    if (!this.uiTagGroup) {
      return false;
    }
    if (this.uiTagNameZh == null || this.uiTagNameZh.length == 0) {
      return false;
    }
    if (this.uiTagNameEn == null || this.uiTagNameEn.length == 0) {
      return false;
    }

    return true;
  }

  updateDefaultMATOption() {
    if (this.uiDefaultType == null) {
      this.uiAllDefaultMATArray = [
        { value: "" , label: JMLanguage.translate("hashtag.default-mat.user-choose")},
      ];
    } else if (this.uiDefaultType == "ZS01") {
      this.uiAllDefaultMATArray = [
        { value: "" , label: JMLanguage.translate("hashtag.default-mat.user-choose")},
        { value: "C01", label: "C01" },
        { value: "C02", label: "C02" },
        { value: "C03", label: "C03" },
        { value: "C99", label: "C99" },
        { value: "M10", label: "M10" }
      ]
    } else if (this.uiDefaultType == "ZS02") {
      this.uiAllDefaultMATArray = [
        { value: "" , label: JMLanguage.translate("hashtag.default-mat.user-choose")},
        { value: "N04", label: "N04" },
        { value: "N05", label: "N05" },
      ]
    } else if (this.uiDefaultType == "ZS04") {
      this.uiAllDefaultMATArray = [
        { value: "" , label: JMLanguage.translate("hashtag.default-mat.user-choose")},
        { value: "R01", label: "R01" },
        { value: "R02", label: "R02" },
        { value: "R03", label: "R03" },
        { value: "R04", label: "R04" },
        { value: "R05", label: "R05" }
      ]
    }
  }

  // update drop down option of equipment category based on selected equipment type
  updateEquipmentCategoryOption() {
    this.uiTagEquipmentCategoryArray = [];
    if (this.uiTagEquipmentType == null || this.uiTagEquipmentType.length == 0) {
      for (let catCode in Session.equipmentCategoryDict) {
        this.uiTagEquipmentCategoryArray.push({
          value: catCode,
          label: catCode + " - " + Session.equipmentCategoryDict[catCode].description[JMLanguage.getCurrentLanguage()],
        });
      }      
    } else {
      for (let catCode in Session.equipmentCategoryDict) {
        let cat = Session.equipmentCategoryDict[catCode];
        if (cat.equipmentType.includes(this.uiTagEquipmentType)) {
          this.uiTagEquipmentCategoryArray.push({
            value: catCode,
            label: catCode + " - " + Session.equipmentCategoryDict[catCode].description[JMLanguage.getCurrentLanguage()],
          });
        }
      }
    }
  }

  // update drop down option of equipment type based on selected equipment category
  updateEquipmentTypeOption() {
    this.uiTagEquipmentTypeArray = [];
    if (this.uiTagEquipmentCategory == null || this.uiTagEquipmentCategory.length == 0) {
      for (let typeCode in Session.equipmentTypeDict) { // loop the key of dict
        this.uiTagEquipmentTypeArray.push({
          value: typeCode,
          label: typeCode + " - " + Session.equipmentTypeDict[typeCode].description[JMLanguage.getCurrentLanguage()],
        });
      }      
    } else {
      let equipCat = Session.equipmentCategoryDict[this.uiTagEquipmentCategory];
      for (let typeCode of equipCat.equipmentType) {  // loop obj in array
        this.uiTagEquipmentTypeArray.push({
          value: typeCode,
          label: typeCode + " - " + Session.equipmentTypeDict[typeCode].description[JMLanguage.getCurrentLanguage()],
        });
      }      
    }
  }

  private autoSelectTagGroup() {
    if (this.mode === 'new') {
      const workCentreTagGroup = this.tagGroupList.find(tagGroup => tagGroup.type === JMENUM.HashtagGroupType.WORK_CENTRE);
      if (workCentreTagGroup) {
        this.uiTagGroup = workCentreTagGroup._id;
      }
    }
  }

  //---------------------------------
  // button callback
  onSaveButtonClicked = () => {
    this.uiShowError = true;

    if (!this.validateInput()) {
      return;
    }
    
    let request = new JM.JMRequestHashtagsUpdateHashtag();
    if (this.tag && this.mode == 'edit') { // update mode
      request.hashtagId = this.tag._id;
    } else {
      request.source = JMENUM.HashtagSource.MANUEL;
      // list out all workCentres linked with WorkCentres
    }

    request.tagGroupId          = this.uiTagGroup;
    request.equipmentSource     = (this.tagType == 'ccs')?JMENUM.EquipmentSource.CCS: JMENUM.EquipmentSource.HA;
    request.descriptionZh       = this.uiTagNameZh.trim();
    request.descriptionEn       = this.uiTagNameEn.trim();
    request.equipmentCategory   = this.uiTagEquipmentCategory;
    request.equipmentType       = this.uiTagEquipmentType;
    request.defaultPriority     = (this.uiDefaultPriority>JMENUM.JMHashTagPriority.UserChoose)?this.uiDefaultPriority: null; // save null as UserChoose
    request.defaultOrderType    = this.uiDefaultType?this.uiDefaultType: null;
    request.defaultMatType      = this.uiDefaultMAT?this.uiDefaultMAT: null;
    request.additionalDropdown  = this.uiSelectedDropDownArray;
    // change Stream? get lastest TagGroup and overwrite

    this.uiEnableSaveButton = false;
    this.uiLoadingSaveButton = true;
    this.uiEnableDeleteButton = false;
    this.uiLoadingDeleteButton = false;
    
    JM.JMConnector.sendHashtagsUpdateHashtags(request, (error: JM.JMNetworkError, response: JM.JMResponseWorkCentresHashtags) => {
      this.uiEnableSaveButton = true;
      this.uiLoadingSaveButton = false;
      this.uiEnableDeleteButton = true;
      this.uiLoadingDeleteButton = false;
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
      
      AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
      if (this.param.onSave) {
        this.param.onSave()
      }
    });
  }

  onDeleteButtonClicked = () => {
    const title = JMLanguage.translate('add-tag-form.delete-pop-up.title');
    const buttons = [
      {
        name: JMLanguage.translate('add-tag-form.delete-pop-up.yes'),
        handler: () => {
          this.requestDeleteHashtag();
        }
      }, {
        name: JMLanguage.translate('global.no')
      }
    ];

    AppDelegate.showPopUpAlert(title, '', buttons);
  }

  onEquipmentCategoryChanged = (event) => {
    this.updateEquipmentTypeOption();
    if (this.uiTagEquipmentType && !JM.JMConnector.getEquipmentTypeCodeFromCategory(this.uiTagEquipmentCategory).includes(this.uiTagEquipmentType)) {
      this.uiTagEquipmentType = null; 
    }
  }

  onEquipmentTypeChanged = (event) => {
    if (!this.uiTagEquipmentCategory && this.uiTagEquipmentType) {
      // currently selected cat does not match with current type
      this.uiTagEquipmentCategory = JM.JMConnector.getEquipmentCategoryCodeFromType(this.uiTagEquipmentType)[0];
      this.updateEquipmentTypeOption();
    }
  }

  onDefaultTypeChanged = (event) => {
    this.updateDefaultMATOption();
    this.uiDefaultMAT = this.uiAllDefaultMATArray[0].value;
  }

  onDropdownScrollToEnd = () => {
    if (this.dropdownPageSize > 0) {
      this.dropdownPageNumber = this.dropdownPageNumber + 1;
      this.requestDropdownSummary();
    }
  }

  onOpenDropdown = () => {
    if (this.uiDropdownArray.length <= 0) {
      this.requestDropdownSummary();
    }
  }

  //---------------------------------------
  private async requestDropdownSummary() {
    const request = new JM.JMRequestDropdownSummary();
    request.system = [Constants.SYSTEM_NAME];
    request.usageType = [JMENUM.dropdownSourceType.EQUIPMENT_TAGS];
    request.pageNumber = this.dropdownPageNumber;
    request.pageSize = this.dropdownPageSize;
    request.sortBy = "description";
    request.parameters = ["_id", "description"];

    const response: JM.JMResponseDropdownSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.totalCount > 0) {
      let dropdownOption = response.payload.records.map(dropdown => {
        return {
          value: dropdown._id,
          label: dropdown.description[Session.selectedLanguage]
        };
      });

      if (this.dropdownPageNumber == 1) {
        this.uiDropdownArray = dropdownOption;
      } else {
        this.uiDropdownArray = this.uiDropdownArray.concat(dropdownOption);
      }
    } else {
      this.dropdownPageSize = 0; // got all dropdown
    }
  }

  private async requestAllTagGroups(workCentre: string) {
    let request = new JM.JMRequestWorkCentresGetAllTagGroupsByWorkCentre();
    request.workCentre = workCentre;
    request.statusList = [JMENUM.HashtagGroupStatus.ACTIVE];

    const response: JM.JMResponseWorkCentresGetAllTagGroupsByWorkCentre = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    if (!response.payload.records) {
      return;
    }

    this.tagGroupList = response.payload.records;
    this.uiTagGroupArray = this.tagGroupList.map(tagGroup => {
      return {
        label: `${tagGroup.name}`,
        value: tagGroup._id
      }
    });

    this.autoSelectTagGroup();
  }

  private requestDeleteHashtag() {
    let request = new JM.JMRequestHashtagsDeleteHashtag();
    request.hashtagId = this.tag._id;

    this.uiEnableSaveButton = false;
    this.uiLoadingSaveButton = false;
    this.uiEnableDeleteButton = false;
    this.uiLoadingDeleteButton = true;

    JM.JMConnector.sendHashtagsDeleteHashtags(request, (error: JM.JMNetworkError, response: JM.JMResponseHashtagsDeleteHashtag) => {
      this.uiEnableSaveButton = true;
      this.uiLoadingSaveButton = false;
      this.uiEnableDeleteButton = true;
      this.uiLoadingDeleteButton = false;
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      AppDelegate.openSnackBar(JMLanguage.translate('global.deleted'));
      if (this.param.onSave) {
        this.param.onSave();
      }
    });
  }
}