import { Component, Injector, OnInit } from '@angular/core';
import { NavigationEnd, RouterEvent } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { ContactGroupService } from '@services/contact-group.service';
import { ExcelService } from '@services/excel/excel.service';
import { ParameterService } from '@services/parameter.service';
import * as moment from 'moment';
import { Options } from "ng5-slider";
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { saveAs } from "file-saver";
import { formatDate } from '@angular/common';
import { GetTeamsAllRequest } from 'src/app/api/contact-group/get-teams-all-request';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { PostExportSnSummaryRequest } from '@api/model/sn/post-export-sn-summary-request';
import { SnService } from '@services/sn.service';
import { JobCardService } from '@services/job-card.service';
import { PostExportJobCardSummaryRequest } from '@api/model/job-card/post-export-job-card-summary-request';
import { Session } from 'src/app/services/session';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-report-download',
  templateUrl: './report-download.component.html',
  styleUrls: ['./report-download.component.scss']
})
export class ReportDownloadComponent extends BasePage implements OnInit {

  public destroyed = new Subject<any>();

  constructor(
    injector: Injector,
    private parameterService: ParameterService,
    private contactGroupService: ContactGroupService,
    private snService: SnService,
    private jobCardService: JobCardService
  ) {
    super(injector);
    this.excelService = injector.get(ExcelService);
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.fetchData();
    });
  }

  excelService: ExcelService;

  selectedReportType: string;
  selectedWorkCentre: string;

  reportType: any = {
    sn: 'sn',
    job: 'job',
    insepction: 'insepction',
  }

  hasAuthorizationAll: boolean = false;
  maxSelectedWorkCentres: number = 30;

  timeRangeOptions: Options = {};
  normalTimeRangeOptions: Options = {};
  normalTimeRangeValue: number;
  isDisabledNormalTimeRange: boolean = false;
  jobCardTimeRangeOptions: Options = {};
  jobCardTimeRangeValue: number;
  isDisabledJobTimeRange: boolean = false;
  isDisabledTeam: boolean = true;
  isDisabledContract: boolean = true;
  isDisabledJobMat: boolean = true;
  filterCriteria: any = {};
  jobParameters: any = {};
  snParameters: any = {};

  snPriority: any[];
  equipmentTypes: any[];
  equipmentCategories: any[];
  status: any[];
  jobMat: any[];
  jobTypes: any[];
  workCentreOptions: any[];
  teams: JMOBJ.Team[];
  contractorOptions: any[];
  contractorSearch = {
    search$: new Subject<any[]>(),
    keywords: '',
    pageNumber: 1,
    totalPageNumber: 0,
    pageSize: 100
  };
  contractOptions: any[];

  clientOption: any[];
  jobStatusOption: any[];
  complianceStatusOption: any[];
  jobPriorityOption: any[];
  statusOption: any[];
  snTecoStatusOption: any[];
  jobTecoStatusOption: any[];

  isExportExcelButtonLoading: boolean = false;

  ngOnInit() {
    this.hasAuthorizationAll = this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL);
    this.initAllOption();
    switch (this.selectedReportType) {
      case this.reportType.sn:
        this.initClientOption();
        // this.initStatusOption();
        // this.initSNPriorityOption();
        this.getWorkCenters();
        // this.initSnTecoStatusOption();
        // this.initJobcardTecoStatusOption();
        break;
      case this.reportType.job:
        this.initClientOption();
        // this.initStatusOption();
        // this.initSNPriorityOption();
        this.getJobType();
        this.initJobPriorities();
        this.initJobCardStatus();
        this.initComplianceStatus();
        this.getWorkCenters();
        this.contractorSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
          this.clearContractorOption();
          this.requestContractor(false);
        });
        this.contractorSearch.search$.next();
        // this.initSnTecoStatusOption();
        // this.initJobcardTecoStatusOption();
        break;
      case this.reportType.insepction:
        this.getEquipmentCategories();
        this.getEquipmentTypes();
        break;
    }
  }



  initAllOption() {
    this.initTimeRangeOptions();
    this.jobCardTimeRangeValue = this.jobCardTimeRangeOptions.floor;
    this.normalTimeRangeValue = this.normalTimeRangeOptions.floor;
    switch (this.selectedReportType) {
      case this.reportType.sn:
        this.initStatusOption();
        this.initSNPriorityOption();
        this.initSnTecoStatusOption();
        this.initJobcardTecoStatusOption();
        break;
      case this.reportType.job:
        this.initStatusOption();
        this.initSNPriorityOption();
        this.initJobPriorities();
        this.initJobCardStatus();
        this.initComplianceStatus();
        this.initSnTecoStatusOption();
        this.initJobcardTecoStatusOption();
        break;
    }
  }

  fetchData() {

    let oldSelectedReportType = this.selectedReportType;
    this.lang = Session.selectedLanguage;  //TODO: remove lang
    this.selectedReportType = this.route.snapshot.paramMap.get('type');
    this.selectedWorkCentre = Session.selectedWorkCentre;

    if (oldSelectedReportType && oldSelectedReportType != this.selectedReportType) {
      switch (this.selectedReportType) {
        case this.reportType.job:
          this.getJobType();
          break;
        case this.reportType.insepction:
          this.getEquipmentCategories();
          this.getEquipmentTypes();
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  initClientOption(): void {
    this.clientOption = [];
    for (let client of JM.JMConnector.getAllClient()) {
      let item = {
        value: client.clientShortName,
        label: client.clientShortName + ' - ' + client.name[Session.selectedLanguage],
      }
      this.clientOption.push(item);
    };
  }

  initSNPriorityOption(): void {
    this.snPriority = [];
    for (let id of this.parameterService.allSNPriority()) {
      this.snPriority.push({
        id: id,
        label: JMLanguage.translate("sn.priority." + id)
      })
    }
  }

  initStatusOption(): void {
    this.statusOption = [];
    for (let id of JM.JMConnector.getAllSNStatus()) {
      this.statusOption.push({
        id: id,
        label: JMLanguage.translate("sn.status." + id)
      });
    }
  }

  private initSnTecoStatusOption(): void {
    this.snTecoStatusOption = [];
    for (let id of JM.JMConnector.getAllSnTecoStatus()) {
      this.snTecoStatusOption.push({
        value: id,
        label: JMLanguage.translate("teco.sn.status." + id),
      });
    }
  }

  private initJobcardTecoStatusOption(): void {
    this.jobTecoStatusOption = [];
    for (let id of JM.JMConnector.getAllJobcardTecoStatus()) {
      this.jobTecoStatusOption.push({
        value: id,
        label: JMLanguage.translate("teco.status." + id),
      });
    }
  }

  getJobMat(): void {
    if (this.filterCriteria.jobType) {
      this.isDisabledJobMat = false;

      const jobNatureList = [JMENUM.JobNature.CM, JMENUM.JobNature.PM];
      const matList = Object.values(JMCONSTANT.MatDictionary);

      this.jobMat = matList.filter(mat => {
        return mat.jobNatures.filter(jobNature => jobNatureList.includes(jobNature)).length
          && mat.orderTypes.includes(this.filterCriteria.jobType);
      }).map(mat => mat.code).sort();
    } else {
      this.isDisabledJobMat = true;
    }
  }

  getJobType(): void {
    this.jobTypes = Object.keys(JMCONSTANT.JobTypeMatMap);
  }

  initJobPriorities(): void {
    this.jobPriorityOption = [];
    for (let id of this.parameterService.allJobCardPriority()) {
      this.jobPriorityOption.push({
        id: id,
        label: JMLanguage.translate("jobcard.priority." + id)
      });
    }
  }

  initJobCardStatus(): void {
    this.jobStatusOption = [];
    for (let id of JM.JMConnector.getAllJobCardStatus()) {
      this.jobStatusOption.push({
        id: id,
        label: JMLanguage.translate("jobcard.status." + id)
      });
    }
  }

  initComplianceStatus(): void {
    this.complianceStatusOption = [];
    for (let id of Object.values(JMENUM.JobcardComplianceStatus)) {
      this.complianceStatusOption.push({
        value: id,
        label: JMLanguage.translate("jobcard.complianceStatus." + id)
      });
    }
  }

  getEquipmentCategories(): void {
    this.equipmentCategories = [];
    for (let code of JM.JMConnector.getAllEquipmentCategoryCode()) {
      let cat = JM.JMConnector.getEquipmentCategory(code);
      let item = {
        code: code,
        displayName: code + ' - ' + cat[Session.selectedLanguage],
      }
      this.equipmentCategories.push(item);
    }
  }

  getEquipmentTypes(): void {
    this.equipmentTypes = [];
    for (let code of JM.JMConnector.getAllEquipmentTypeCode()) {
      let etype = JM.JMConnector.getEquipmentType(code);
      let item = {
        code: code,
        displayName: code + ' - ' + etype[Session.selectedLanguage],
      }
      this.equipmentTypes.push(item);
    }
  }

  getWorkCenters(): void {
    this.workCentreOptions = this.hasAuthorizationAll ? JM.JMConnector.getAllWorkCentreCode() : this.authorizationService.getWorkCenters();
  }

  getTeams(): void {
    if (this.filterCriteria.workCentres && this.filterCriteria.workCentres.length === 1) {
      this.isDisabledTeam = false;
      let request = new JM.JMRequestTeamsTeamSummary();
      request.workCentreList = this.filterCriteria.workCentres;
      request.parameters = ["_id", "name"];
      request.includeSummary = true;
      request.pageSize = 1000;
      request.pageNumber = 1;
      JM.JMConnector.sendTeamSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseTeamsTeamSummary) => {
        
        if (error) {
          this.handleJMError(error);
          return;
        }
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }
        this.teams = response.payload.records;
      });
    } else {
      this.filterCriteria.team = null;
      this.isDisabledTeam = true;
    }
  }

  clearContractorOption() {
    this.contractorOptions = [];
    this.contractorSearch.pageNumber = 1;
    this.contractorSearch.totalPageNumber = 0;
  }

  onClearFilterContractor() {
    this.contractorSearch.keywords = null;
    this.contractorSearch.search$.next();
    this.filterCriteria.contractList = [];
  }

  onContractorScrollToEnd() {
    if (this.contractorSearch.pageNumber < this.contractorSearch.totalPageNumber) {
      this.contractorSearch.pageNumber++;
      this.requestContractor(true);
    }
  }

  onSearchContractor(event) {
    this.contractorSearch.keywords = event.term;
    this.contractorSearch.search$.next();
  }

  onChangeFilterContractor() {
    if (this.filterCriteria.contractor) {
      this.isDisabledContract = false;
      this.requestContracts();
    } else {
      this.isDisabledContract = true;
    }
  }

  async requestContractor(isAppend: boolean = false) {
    const request = new JM.JMRequestContractsGetJmVendorList();
    request.activeStatusList = ['active', 'inactive'];
    request.pageSize = this.contractorSearch.pageSize;
    request.pageNumber = this.contractorSearch.pageNumber;

    if (!!this.contractorSearch.keywords) {
      request.filter = {
        vendorName: this.contractorSearch.keywords,
      };
    }

    const response: JM.JMResponseContractsGetJmVendorList = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      return AppDelegate.openErrorBar(response);
    }
    if (Array.isArray(response.payload.records)) {
      const respondRecords = response.payload.records.map(record => {
        return {
          label: record.vendorName,
          value: record.vendorNumber,
        }
      });

      this.contractorSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
      if (isAppend) {
        this.contractorOptions = this.contractorOptions.concat(respondRecords);
      } else {
        this.contractorOptions = respondRecords;
      }
    }
  }

  async requestContracts() {
    const request = new JM.JMRequestContractsGetJmContractNumbersByVendor();
    request.vendorNumber = this.filterCriteria.contractor;
    const response: JM.JMResponseContractsGetJmContractNumbersByVendor = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      return AppDelegate.openErrorBar(response);
    }
    if (Array.isArray(response.payload.contractList)) {
      this.contractOptions = response.payload.contractList.map(contract => {
        return {
          label: contract,
          value: contract,
        }
      });
    } 
  }

  timeRangeChanged(type: 'normal' | 'job'): void {
    var value = type === 'normal' ? this.normalTimeRangeValue : this.jobCardTimeRangeValue;
    var today = this.momentDateToBgbDate(moment());
    switch (value) {
      case 0:
        if (type === 'normal') {
          this.isDisabledNormalTimeRange = false;
        } else {
          this.isDisabledJobTimeRange = false;
        }
        break;
      case 1:
        if (type === 'normal') {
          this.isDisabledNormalTimeRange = true;
          this.filterCriteria.startDate = today;
          this.filterCriteria.endDate = today;
        } else {
          this.isDisabledJobTimeRange = true;
          this.filterCriteria.jobStartDate = today;
          this.filterCriteria.jobEndDate = today;
        }
        break;
      case 2:
        var startDate = this.momentDateToBgbDate(moment().subtract(7, 'days'));
        if (type === 'normal') {
          this.isDisabledNormalTimeRange = true;
          this.filterCriteria.startDate = startDate;
          this.filterCriteria.endDate = today;
        } else {
          this.isDisabledJobTimeRange = true;
          this.filterCriteria.jobStartDate = startDate;
          this.filterCriteria.jobEndDate = today;
        }
        break;
      case 3:
        var startDate = this.momentDateToBgbDate(moment().subtract(1, 'months'));
        if (type === 'normal') {
          this.isDisabledNormalTimeRange = true;
          this.filterCriteria.startDate = startDate;
          this.filterCriteria.endDate = today;
        } else {
          this.filterCriteria.jobStartDate = startDate;
          this.filterCriteria.jobEndDate = today;
        }
        break;
      default:
        break;
    }
  }

  setSnParameters() {
    this.snParameters = {};

    if (this.filterCriteria.startDate) {
      let startDate = moment(this.filterCriteria.startDate.year + '-' + this.filterCriteria.startDate.month + '-' + this.filterCriteria.startDate.day);
      this.snParameters.createTimeFrom = new Date(startDate.toString());
    }
    if (this.filterCriteria.endDate) {
      let endDate = moment(this.filterCriteria.endDate.year + '-' + this.filterCriteria.endDate.month + '-' + this.filterCriteria.endDate.day).add(1, 'd').subtract(1, 's');
      this.snParameters.createTimeTo = new Date(endDate.toString());
    }
    if (this.filterCriteria.client) {
      this.snParameters.clientList = [this.filterCriteria.client];
    }
    if (this.filterCriteria.locationCode) {
      this.snParameters.locationCodeList = [this.filterCriteria.locationCode];
    }
    if (this.filterCriteria.location) {
      this.snParameters.locationDescription = this.filterCriteria.location;
    }
    if (this.filterCriteria.priorities) {
      this.snParameters.priorityList = [this.filterCriteria.priorities];
    }
    if (this.filterCriteria.status) {
      this.snParameters.statusList = [this.filterCriteria.status];
    }
    if (this.filterCriteria.workCentres) {
      this.snParameters.workCentreList = this.filterCriteria.workCentres;
    }
    if (this.filterCriteria.team) {
      this.snParameters.teamIdList = [this.filterCriteria.team];
    }
    if (this.filterCriteria.snTecoStatus) {
      this.snParameters.tecoStatusList = this.filterCriteria.snTecoStatus;
    }
  }

  setJobParameters() {
    this.jobParameters = {};

    if (this.filterCriteria.startDate) {
      this.jobParameters.snCreateTimeFrom = new Date(this.filterCriteria.startDate.year + '-' + this.filterCriteria.startDate.month + '-' + this.filterCriteria.startDate.day);
    }
    if (this.filterCriteria.endDate) {
      let endDate = moment(this.filterCriteria.endDate.year + '-' + this.filterCriteria.endDate.month + '-' + this.filterCriteria.endDate.day).add(1, 'd').subtract(1, 's');
      this.jobParameters.snCreateTimeTo = new Date(endDate.toString());
    }
    if (this.filterCriteria.jobStartDate) {
      this.jobParameters.jobCardCreateTimeFrom = new Date(this.filterCriteria.jobStartDate.year + '-' + this.filterCriteria.jobStartDate.month + '-' + this.filterCriteria.jobStartDate.day);
    }
    if (this.filterCriteria.jobEndDate) {
      let endDate = moment(this.filterCriteria.jobEndDate.year + '-' + this.filterCriteria.jobEndDate.month + '-' + this.filterCriteria.jobEndDate.day).add(1, 'd').subtract(1, 's');
      this.jobParameters.jobCardCreateTimeTo = new Date(endDate.toString());
    }
    if (this.filterCriteria.responseToClientTimeComplianceStatus) {
      this.jobParameters.responseToClientTimeComplianceStatus = this.filterCriteria.responseToClientTimeComplianceStatus;
    }
    if (this.filterCriteria.responseTimeComplianceStatus) {
      this.jobParameters.responseTimeComplianceStatus = this.filterCriteria.responseTimeComplianceStatus;
    }
    if (this.filterCriteria.rectificationTimeComplianceStatus) {
      this.jobParameters.rectificationTimeComplianceStatus = this.filterCriteria.rectificationTimeComplianceStatus;
    }
    if (this.filterCriteria.client) {
      this.jobParameters.snClientList = [this.filterCriteria.client];
    }
    if (this.filterCriteria.locationCode) {
      this.jobParameters.snLocationCodeList = [this.filterCriteria.locationCode];
    }
    if (this.filterCriteria.location) {
      this.jobParameters.snLocationDescription = this.filterCriteria.location;
    }
    if (this.filterCriteria.priorities) {
      this.jobParameters.snPriorityList = [this.filterCriteria.priorities];
    }
    if (this.filterCriteria.status) {
      this.jobParameters.snStatusList = [this.filterCriteria.status];
    }
    if (this.filterCriteria.workCentres) {
      this.jobParameters.snWorkCentreList = this.filterCriteria.workCentres;
    }
    if (this.filterCriteria.team) {
      this.jobParameters.snTeamIdList = [this.filterCriteria.team];
    }
    if (this.filterCriteria.contractor) {
      this.jobParameters.snVendorNumberList = [this.filterCriteria.contractor];
    }
    if (this.filterCriteria.contractList) {
      this.jobParameters.snContractNumberList = this.filterCriteria.contractList;
    }
    if (this.filterCriteria.jobMat) {
      this.jobParameters.jobCardMatType = this.filterCriteria.jobMat;
    }
    if (this.filterCriteria.jobType) {
      this.jobParameters.jobCardOrderType = this.filterCriteria.jobType;
    }
    if (this.filterCriteria.jobPriorities) {
      this.jobParameters.jobCardPriorityList = [this.filterCriteria.jobPriorities];
    }
    if (this.filterCriteria.jobStatus) {
      this.jobParameters.jobCardStatusList = [this.filterCriteria.jobStatus];
    }
    if (this.filterCriteria.jobTecoStatus) {
      this.jobParameters.jobCardTecoStatusList = this.filterCriteria.jobTecoStatus;
    }
  }

  onClickExportExcel(): void {
    switch (this.selectedReportType) {
      case this.reportType.sn:
        this.setSnParameters();

        if (!(this.snParameters.createTimeFrom && this.snParameters.createTimeTo)) {
          this.openSnackBar(JMLanguage.translate("pages.report-download.please-fill-in-creation-time"));
          return;
        }

        this.getSnSummaryReport();
        break;
      case this.reportType.job:
        this.setJobParameters();

        if (!this.jobParameters.snCreateTimeFrom || !this.jobParameters.jobCardCreateTimeFrom) {
          this.openSnackBar(JMLanguage.translate("pages.report-download.please-fill-in-creation-time"));
          return;
        }
        if (this.isMandatoryWorkCentre() && !((this.filterCriteria.workCentres && this.filterCriteria.workCentres.length > 0) || (this.filterCriteria.contractor && this.filterCriteria.contractor.length > 0))) {
          this.openSnackBar(JMLanguage.translate("pages.report-download.either-work-centre-or-contractor"));
          return;
        }
        
        this.getJobCardSummaryReport();
        break;
      case this.reportType.insepction:
        break;
      default:
        break;
    }
  }

  getSnSummaryReport() {
    let request = {} as PostExportSnSummaryRequest;
    request = this.snParameters;

    this.isExportExcelButtonLoading = true;
    this.apiHandler(this.snService.postExportSnSummaryRequest(request), result => {
      this.isExportExcelButtonLoading = false;
      try {
        let fileName = "SN_" + formatDate(new Date(), "yyyy-MM-dd", 'en-US') + ".xlsx";
        saveAs(result, fileName);
      } catch (e) {
        this.openErrorBar();
      }
    });
  }

  getJobCardSummaryReport() {
    let request = {} as PostExportJobCardSummaryRequest;
    request = this.jobParameters;

    this.isExportExcelButtonLoading = true;
    this.apiHandler(this.jobCardService.postExportJobCardSummaryRequest(request), result => {
      this.isExportExcelButtonLoading = false;
      try {
        let fileName = "Job_Card_" + formatDate(new Date(), "yyyy-MM-dd", 'en-US') + ".xlsx";
        saveAs(result, fileName);
      } catch (e) {
        this.openErrorBar();
      }
    });
  }


  /*** Custom function for frontend ***/

  private momentDateToBgbDate(momentDate: moment.Moment): any {
    return { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() };
  }

  private initTimeRangeOptions() {
    let label = [
      JMLanguage.translate("pages.report-download.custom"),
      JMLanguage.translate("pages.report-download.today"),
      JMLanguage.translate("pages.report-download.one-week"),
      JMLanguage.translate("pages.report-download.one-month")
    ];
    var options = {
      floor: 0,
      ceil: 3,
      showTicksValues: true,
      showSelectionBar: true,
      stepsArray: [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
      translate: (value: number): string => {
        return label[value];
      }
    };
    this.normalTimeRangeOptions = options;
    this.jobCardTimeRangeOptions = options;
  }

  setWorkCentrePlaceHolder(): string {
    return JMLanguage.translate('pages.report-download.work-centre-all');
  }

  isMandatoryWorkCentre(): boolean {
    return this.hasAuthorizationAll && this.selectedReportType === this.reportType.job;
  }

  setTodayDate(): NgbDateStruct {
    return this.momentDateToBgbDate(moment());
  }

  setMaxEndDate(startDate: NgbDateStruct): NgbDateStruct {
    if (!startDate) return null;
    const date: NgbDateStruct = {
      year: startDate.year,
      month: startDate.month + 1,
      day: startDate.day
    }

    return date;
  }

  setMinEndDate(startDate: NgbDateStruct): NgbDateStruct {
    if (!startDate) return null;
    const date: NgbDateStruct = {
      year: startDate.year,
      month: startDate.month,
      day: startDate.day
    }

    return date;
  }

  // TODO: reload language
  onLanguageChanged() {
  }
}
