import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActionButtonDefinition, ActionButtonPmJob, ActionButtonType } from '@enum/action-button';
import { Location } from '@angular/common';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/entity/enum/tablexColumnType';
import { ActionButtonService } from '@services/action-button.service';
import { PmJobService } from '@services/pm-job.service';

@Component({
  selector: 'app-pm-job-equipment-list',
  templateUrl: './pm-job-equipment-list.component.html',
  styleUrls: ['./pm-job-equipment-list.component.scss']
})
export class PmJobEquipmentListComponent implements OnInit {

  router: Router;
  route: ActivatedRoute;
  location: Location;
  actionBtnService:ActionButtonService;
  pmJobService:PmJobService;

  pmJobId: string;
  pmJobVersion: number;
  pmPeriodId: string;
  equipmentListData: any[] = [];
  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;
  JMPageMode = JMENUM.JMPageMode;

  breadcrumbs: any = [];
  actionButtonData = [];
  tabOptions = {
    assigned: JMLanguage.translate('pages.pm-job-equipment-list.assigned'),
    unassigned: JMLanguage.translate('pages.pm-job-equipment-list.unassigned')
  };
  activeTab;
  submitBtnName: string;

  //table X
  tablexParam:any = {};
  pageNumber: number = 1;
  pageSize: number = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  numberOfRowsSelected: number = 0;

  constructor(router: Router, route: ActivatedRoute, location: Location, actionBtnService:ActionButtonService, pmJobService:PmJobService) {
    this.router = router;
    this.route = route;
    this.location = location;
    this.actionBtnService = actionBtnService;
    this.pmJobService = pmJobService;
  }

  async ngOnInit() {
    this.initRouteParams();
    this.initBreadCrumbs();
    this.initTableX();
    this.setActiveTab(this.tabOptions.assigned);
    this.initActionButtons();
    this.fetchAndRender(this.pageNumber, this.pageSize);
  }

  // Main function to trigger the whole flow
  async fetchAndRender(requestPage: number, requestPageSize: number) {
    this.tablexParam['isLoadingTable'] = true;

    await fetchData.call(this);

    this.selectRow(null);
    this.updateTableX();
    this.tablexParam['isLoadingTable'] = false;

    async function fetchData() {
      let request;

      switch (this.activeTab) {
        case this.tabOptions.assigned:
          request = new JM.JMRequestGetPmJobAssignedEquipmentList();
          break;
        case this.tabOptions.unassigned:
          request = new JM.JMRequestGetPmJobUnassignedEquipmentList();
          break;
      }

      request.pmJobNumber = this.pmJobId;
      request.periodId = this.pmPeriodId;
      request.pageSize = requestPageSize ? requestPageSize : this.tablexParam['currentPageSize'];
      request.pageNumber = requestPage ? requestPage : this.tablexParam['currentPage'];

      if (this.tablexParam['sortBy']) {
        request.sortBy = this.tablexParam['sortBy'];
        request.sortOrder = this.tablexParam['sortOrder'];
      }
      // request.parameters = ['createdAt','status','successCount','failedCount','reportFileId'];  TODOOOOOO

      const response: JM.JMResponseGetPmJobUnassignedEquipmentList | JM.JMResponseGetPmJobAssignedEquipmentList = await AppDelegate.sendJMRequest(request);
      if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
      }

      this.equipmentListData = [];
      if (response.payload && response.payload.records && response.payload.records.length > 0) {
        this.equipmentListData = response.payload.records;
        this.tablexParam['currentPageSize'] = request.pageSize;
        this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.tablexParam['currentPageSize']);
        this.tablexParam['currentPage'] = request.pageNumber;
        this.pmJobVersion = response.payload.jobVersion;
      } else {
        this.tablexParam['pageCount'] = 0;
        this.tablexParam['currentPage'] = 0;
      }
    }

  }

  initTableX() {
    this.tablexParam = {
      isLoadingTable: true,
      // enableSort: true, // TODOOOOOOOOOOOOOOOOOOOO
      sortBy: 'equipmentNumber',
      sortOrder: 1, // TODOOOOOOOOOOOOOOOOOOOO
      tableRow: 'd-flex align-items-stretch justify-content-center',
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      enablePagination: true,
      enableSetPageSize: true,
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.pageSize,
      currentPage: 1,
      highlightedRows: [],
      onRowClicked: this.pageMode === JMENUM.JMPageMode.EDIT ? this.onRowClicked : null,
      onSortOrderChanged: this.onSortOrderChanged,
      headers: [
        {
          id: 'equipmentNumber',
          name: JMLanguage.translate('pages.pm-job-equipment-list.table.equipmentId'),
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: 'col-1 d-flex align-items-center justify-content-center',
          enableSort: true,
        },
        {
          id: 'description',
          name: JMLanguage.translate('pages.pm-job-equipment-list.table.description'),
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: 'col-2 d-flex align-items-center justify-content-center',
          enableSort: true,
        },
        {
          id: 'location',
          name: JMLanguage.translate('pages.pm-job-equipment-list.table.locationCode'),
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: 'col-1 d-flex align-items-center justify-content-center',
          enableSort: true,
        },
        {
          id: 'locationDescription',
          name: JMLanguage.translate('pages.pm-job-equipment-list.table.locationDescription'),
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: 'col-2 d-flex align-items-center justify-content-center',
          enableSort: true,
        },
        {
          id: 'clientShortName',
          name: JMLanguage.translate('pages.pm-job-equipment-list.table.client'),
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: 'col-1 d-flex align-items-center justify-content-center',
          enableSort: true,
        },
        {
          id: 'equipmentType',
          name: JMLanguage.translate('pages.pm-job-equipment-list.table.equipmentType'),
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: 'col-2 d-flex align-items-center justify-content-center',
          enableSort: true,
        },
        {
          id: 'slaType',
          name: JMLanguage.translate('pages.pm-job-equipment-list.table.slaType'),
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: 'col-2 d-flex align-items-center justify-content-center',
        },
        {
          id: 'status',
          name: JMLanguage.translate('pages.pm-job-equipment-list.table.status'),
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: 'col-1 d-flex align-items-center justify-content-center',
          enableSort: true,
        },
      ]
    }
  }

  initBreadCrumbs() {
    this.breadcrumbs = [
      { id: 'breadcrumbs-pm-job', name: JMLanguage.translate('pages.pm-job-equipment-list.pm-job'), route: null, currentPage: false }, // TODO: translate
      { id: 'breadcrumbs-pm-job-plan-number', name: this.pmJobId, route: `/pm/job/view/${this.pmJobId}`, currentPage: false },
      { id: 'breadcrumbs-pm-job-plan-equipment', name: JMLanguage.translate('pages.pm-job-equipment-list.equipment'), route: null, currentPage: true }// TODO: translate
    ];
  }

  initRouteParams() {
    this.pmJobId = this.route.snapshot.paramMap.get('jobId');
    this.pmPeriodId = this.route.snapshot.paramMap.get('periodId');
    let mode = this.route.snapshot.paramMap.get('mode');
    let parsedMode = this.pmJobService.initPageMode(mode);
    this.pageMode = parsedMode ? parsedMode : this.pageMode;
  }

  onActionButtonClicked(actionButton: any) {
    actionButton.buttons[0].handler();
  }

  onClickSetActiveTab = (tab) => {
    this.setActiveTab(tab);
    this.fetchAndRender(1, null);
  }

  onSubmitClick = () => {
    let selectedEquipmentList = [];
    this.tablexParam['highlightedRows'].forEach((el, index) => {
      if (el) {
        selectedEquipmentList.push(this.tablexParam['content'][index][0])
      }
    })
    if (selectedEquipmentList.length > 0) {
      this.submitEquipment(selectedEquipmentList);
    }
  }

  async submitEquipment(equipmentlist: string[]) {
    let request: JM.JMRequestRemoveEquipmentFromPmJob | JM.JMRequestAddEquipmentToPmJob;

    switch (this.activeTab) {
      case this.tabOptions.assigned:
        request = new JM.JMRequestRemoveEquipmentFromPmJob();
        break;
      case this.tabOptions.unassigned:
        request = new JM.JMRequestAddEquipmentToPmJob();
        break;
    }

    request.pmJobNumber = this.pmJobId;
    request.equipmentNumbers = equipmentlist;
    request.version = this.pmJobVersion;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.fetchAndRender(1, null);
  }

  onPageNumberClicked = (pageNumber: number) => {
    this.fetchAndRender(pageNumber, null);
  };

  onPageSizeClicked = (pageSize: number) => {
    this.fetchAndRender(1, pageSize);
  };

  onRowClicked = (index, row) => {
    this.selectRow(index);
  }

  onSortOrderChanged = (header, sortOrder) => {
    this.tablexParam['sortBy'] = header ? header : 'equipmentNumber';
    this.tablexParam['sortOrder'] = sortOrder;
    this.fetchAndRender(1, null);
  }



  updateTableX() {
    this.tablexParam['content'] = this.equipmentListData.map(element => {
      let { equipmentNumber, description, location, locationDescription, clientShortName, equipmentType, slaItemList, status } = element;
      let slaTypes = slaItemList ? slaItemList.map(el => el.material) : [];
      return [
        equipmentNumber,
        description,
        location,
        locationDescription,
        clientShortName,
        equipmentType,
        slaTypes.join(', '),
        status ? JMLanguage.translate('pm-period.status.'+status) : null
      ]
    })
  }

  initActionButtons(): void {
    this.actionButtonData = [];
    this.addActionBtn(ActionButtonPmJob.close, this.closeBtnAction);
  }
  selectRow(index: number) {
    if (index == null) {
      this.tablexParam['highlightedRows'] = [];
    } else {
      let prevState = this.tablexParam['highlightedRows'][index];
      this.tablexParam['highlightedRows'][index] = !prevState;
    }
    this.numberOfRowsSelected = this.tablexParam['highlightedRows'].filter(e => e).length;
  }

  onSelectAll = () =>{
    let content = this.tablexParam.content;
    this.tablexParam.highlightedRows = content.map(e=>true);
    this.numberOfRowsSelected = content.length;
  }

  onUnselectAll = () =>{
    this.tablexParam.highlightedRows = [];
    this.numberOfRowsSelected = 0;
  }

  addActionBtn(buttonStatus: ActionButtonPmJob, button1CallBack?: Function, button2CallBack?: Function): void {
    let actionButton = this.actionBtnService.addActionBtn(
      ActionButtonType.pmJob,
      buttonStatus,
      button1CallBack,
      button2CallBack
    );
    actionButton && this.actionButtonData.push(actionButton);
  }

  closeBtnAction = () => {
    this.router.navigate([`/pm/job/view/`, this.pmJobId]);
  }


  setActiveTab(tab) {
    switch (tab) {
      case this.tabOptions.assigned:
        this.submitBtnName = JMLanguage.translate('pages.pm-job-equipment-list.unassign');
        break;
      case this.tabOptions.unassigned:
        this.submitBtnName = JMLanguage.translate('pages.pm-job-equipment-list.assign');
        break;
    }
    this.activeTab = tab;
  }



}

