<div class="wrapper">
  <app-nav [navbarTitle]="'pages.pm-plan-list.page-title' | translate">
  </app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="">

    <div class="row mx-0 flex-column-reverse flex-xl-row">
      <div class="col-12 col-xl-9 ">
        <!-- <button class="btn standard brand-blue ml-auto d-block" (click)="onClickCreatePMTask()" >
          {{ "pages.pm-plan-list.create-pm-job" | translate }}
        </button> -->
        <app-tablex id="pm-plan-list-table" #pmPlanListTable [tablexParam]="tablexParam" class=""></app-tablex>
      </div>
      <div class="col-12 col-xl-3">
        <app-pm-plan-search-criteria (onSearch)="onAdvancedSearch($event)"></app-pm-plan-search-criteria>
      </div>
      
    </div>
  </div>
</div>
