<div class="request-window p-2">
  <ng-select 
    class="my-1"
    placeholder="{{'pages.authorization.use-team' | translate}}" 
    bindValue="value" 
    bindLabel="description"
    [items]="requestWindowGroups" 
    [searchable]="false"
    [clearable]="false" 
    [(ngModel)]="requestType" 
    [ngModelOptions]="{standalone: true}"
    (change)="onRequestTypeChanged()"
  ></ng-select>
  <ng-select 
    class="my-1"
    placeholder="{{'pages.authorization.work-centre' | translate}}"
    [disabled]="isLoadingWorkCentre"
    [loading]="isLoadingWorkCentre"
    [items]="workCentreOptions" 
    [(ngModel)]="approvalWorkCentre" 
    [ngModelOptions]="{standalone: true}"
    (change)="onWorkCentreOptionChanged()"
  ></ng-select> 

  <!-- Team Section -->
  <ng-container *ngIf="requestType == 'team'">
      <ng-select 
        class="my-1"
        placeholder="{{'pages.authorization.team' | translate}}"
        bindValue="_id" 
        bindLabel="name" 
        [items]="teamOptions"
        [disabled]="teamDisabled || isLoadingTeam"
        [loading]="isLoadingTeam"
        [(ngModel)]="approvalTeam" 
        [ngModelOptions]="{standalone: true}"
      ></ng-select>
  </ng-container>

  <!-- Post Section -->
  <ng-container *ngIf="requestType == 'post'">
    <ng-select 
      class="my-1"
      placeholder="{{'pages.authorization.post' | translate}}"
      bindValue="_id" 
      bindLabel="name" 
      [items]="officerOptions" 
      [disabled]="officerDisabled"
      [(ngModel)]="approvalOfficer" 
      [ngModelOptions]="{standalone: true}"
    ></ng-select>
    <div class="row justify-content-start no-gutters period-panel mt-1">
      <div>
        <input 
          #start="ngbDatepicker"
          id="settings-posting_acting-start_datepicker" 
          class="form-control date-picker-input-box" 
          placeholder="{{'pages.authorization.start-date' | translate}}" 
          name="start"
          ngbDatepicker 
          readonly
          [(ngModel)]="startDate" 
          (click)="start.toggle()" 
        >
      </div>
      <div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div>
        <input 
          #end="ngbDatepicker" 
          id="settings-posting_acting-end_datepicker" 
          class="form-control date-picker-input-box" 
          placeholder="{{'pages.authorization.end-date' | translate}}" 
          name="end"
          ngbDatepicker 
          readonly
          [(ngModel)]="endDate" 
          (click)="end.toggle()" 
        >
      </div>
    </div>
  </ng-container>
  
  
  <div class="row mt-2">
    <div class="col-md d-flex justify-content-center">
      <app-submit-button 
        [id]="'authorization_submit-request_button'"
        [text]="'pages.authorization.submit'"
        [buttonClass]="'standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="loadingSubmit"
        [isLoading]="loadingSubmit"
        (onClick)="onSubmitBtnClicked()" 
      ></app-submit-button>
    </div>
  </div>
</div>