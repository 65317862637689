<div class="wrapper">
  <app-nav [navbarTitle]="'pages.timesheet-officer.page-title' | translate"  (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'default'"></app-sidebar>

  <div id="content">

    <div id="fullpageLoading" *ngIf="isLoading">
      <div>
        <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
        {{'global.loading' | translate}}
      </div>
    </div>
    <div class="row justify-content-between align-items-center">
      <div class="col-auto pl-0">
        <h2 class="title mb-0">{{ 'pages.timesheet.summary' | translate }}</h2>
      </div>
      <div class="col-auto">
        <div class="d-inline-flex">
          <p class="font-size-l mb-0 mr-2">{{ 'pages.setting.work-center'  | translate }}:</p> 
          <ng-select class="work-centre-dropdown" id="timesheet-personal_work-center_dropdown" [items]="workCenterOptions" 
            [(ngModel)]="selectedWorkCenter" (change)="onChangeWorkCentre()" [clearable]="false" [searchable]="false"
            [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
    </div>
    <hr class="underline" />

    <div class="row my-2 mx-0">
      <div class="col-md-5 input-border px-0 py-2">
        <div class="row row-space justify-content-between align-items-center">
          <div class="col">{{ 'pages.timesheet.staff' | translate}}</div>
          <div class="col">
            <ng-select
              id="select-staff-dropdown"
              [items]="staffOptions"
              [(ngModel)]="selectedStaff"
              (change)="onChangeFilter()"
              [clearable]="false"
            >
            </ng-select>
          </div>
        </div>
        <div class="row row-space justify-content-between align-items-center">
          <div class="col">{{ 'pages.timesheet.number-of-jobs' | translate }}</div>
          <div class="col" *ngIf="timesheetConvert" id="total-job-count">{{ totalJobCount }}</div>
        </div>
        <div class="row row-space justify-content-between align-items-center">
          <div class="col">{{ 'pages.timesheet.total-chargeable-hours' | translate }}</div>
          <div class="col">
            <span id="chargeable-hour">{{displayChargeableHour[0]}} </span>
            {{ 'pages.timesheet.hours' | translate }}
            <span id="chargeable-minutes">{{displayChargeableHour[1]}} </span>
            {{ 'pages.timesheet.minutes' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md px-0 px-md-3 pl-md-4 my-4 my-md-0">
        <div class="row row-space mt-md-3 justify-content-between align-items-center">
          <div class="col pl-0">{{ 'pages.timesheet.period' | translate }}</div>
          <div class="col">
            <ng-select
              id="select-period-dropdown"
              [(ngModel)]="selectedPeriod"
              [items]="timesheetPeriodOptions"
              bindLabel="name"
              bindValue="value"
              [clearable]="false"
              (change)="onChangeFilter()"
            >
            </ng-select>
          </div>
        </div>
        <div class="row row-space justify-content-between align-items-center">
          <div class="col-auto pl-0 py-4">
            <div class="order-type-label-wrap" *ngIf="selectedOrderType === 'all'">
              <ng-container *ngFor="let type of orderTypeOptions; let t = index">
                <div *ngIf="type.value != 'all'" [class.active]="showCell.includes(type.value)" class="order-type-label d-inline-block mr-3" (click)="onFilterOrderType(type.value)">
                  <span class="label-color d-inline-block mr-1" [ngClass]="type.value" ></span>
                  <span class="label-text">{{ type.name }}</span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-auto pl-0 export-excel-wrap">
            <button id='export_excel_btn' type="button" class="standard brand-blue mr-0"
              (click)="onClickExportExcel()" [disabled]="isExportExcelButtonLoading" > 
              <span *ngIf="isExportExcelButtonLoading">
                <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                {{'global.loading' | translate}}
              </span>
              <span *ngIf="!isExportExcelButtonLoading">
                <i class="fas fa-download space"></i>
                {{"global.export-excel"|translate}}
              </span>
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="timesheet-wrap">
      <div class="row timesheet-heading my-3">
        <div class="col-2 col-xl-1 pl-0 bold">
          {{ 'pages.timesheet.job-number' | translate }}
        </div>
        <div class="col-2 col-xl-1 pl-0 bold">
          {{ 'pages.timesheet.employee-code' | translate }}
        </div>
        <div class="col-2 col-xl-1 bold">
          {{ 'pages.timesheet.hours' | translate }}
        </div>
        <div class="col-3 col-xl-2 bold">
          {{ 'pages.timesheet.date' | translate }}
        </div>
        <div class="col-0 col-xl-7 hour-heading d-flex justify-content-between pr-0">
          <span class="hour-span d-none d-xl-block" *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]">
            {{hour}}
          </span>
        </div>
      </div>

      <ng-container *ngIf="timesheetConvert">
        <ng-container *ngFor="let timesheet of timesheetConvert">

          <ng-container *ngIf="timesheet.workHour.length > 0" >
            <div class="row mb-xl-0 mb-4 record-row" *ngFor="let workHour of timesheet.workHour; let i = index"
              [class.show]="showCell.includes(timesheet.jobType)" attr.id="{{timesheet.jobCardNumber}}_{{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }}_row" >
              <div class="col-2 col-xl-1 pl-0 text-break job-card-number" attr.id="{{timesheet.jobCardNumber}}_{{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }}_job-card-number">
                <span >{{ timesheet.jobCardNumber }}</span>
              </div>
              <div class="col-2 col-xl-1 px-2 text-break employee-code" attr.id="{{timesheet.jobCardNumber}}_{{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }}_employee-code">
                <span >{{ timesheet.personnelNumber }}</span>
              </div>
              <div class="col-2 col-xl-1 staff-work-hour" attr.id="{{timesheet.jobCardNumber}}_{{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }}_staff-work-hour">
                <span *ngIf="timesheet.dayHour[i] === 0" class="no-duration">
                  {{ timesheet.dayHour[i]}}
                </span>
                <span *ngIf="timesheet.dayHour[i] !== 0">
                  {{ timesheet.dayHour[i] + timesheet.bufferTime }} ({{ timesheet.dayHour[i] }}+{{ timesheet.bufferTime }})
                </span>
              </div>
              <div class="col-3 col-xl-2 timesheetdate" attr.id="{{timesheet.jobCardNumber}}_{{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }}_timesheet-date">
                {{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }}
              </div>
              <div class="col-12 col-xl-7 mb-3 mt-0 my-xl-0 pr-0 pl-0 pl-xl-3">
                <!-- <div class="hour-heading d-flex d-xl-none justify-content-between mb-1 mt-2 time-bg">
                  <span class="hour-span" *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]">
                    {{hour}}
                  </span>
                </div> -->
                <ng-template #jobToolTip>
                  <div class="timesheet-tooltip-wrap text-left" attr.id="{{timesheet.jobCardNumber}}_{{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }}_tooltip">
                    {{ timesheet.jobCardNumber }}<br>
                    {{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }} <br>
                    {{ timesheet.isoDate[i][0] | date: 'HH:mm' }} - 
                      <span *ngIf="workHour.endTime === 24"> 24:00 </span>
                      <span *ngIf="workHour.endTime !== 24"> {{ timesheet.isoDate[i][1] | date: 'HH:mm' }} </span>
                    <br>
                    {{ timesheet.jobType }}
                  </div>
                </ng-template>
                
                <div class="timesheet-chart-wrap d-flex" attr.id="{{timesheet.jobCardNumber}}_{{ timesheet.isoDate[i][0] | date: 'yyyy-MM-dd' }}_timesheet-chart">
                  <span class="empty-cell" *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]; let h=index" >
                    <span class="colored-cell overnight" [class.show]="showCell.includes(timesheet.jobType)" [ngClass]="timesheet.jobType "
                          placement="bottom" [ngbTooltip]="jobToolTip" 
                          *ngIf="(workHour.endTime - workHour.startTime) != 0 && workHour.startTime >= workHour.endTime 
                          && (workHour.startTime <= hour+0.75 || workHour.endTime > hour+0.14)">
                    </span>
                    <span class="colored-cell normal" [class.show]="showCell.includes(timesheet.jobType)" [ngClass]="timesheet.jobType " 
                          placement="bottom" [ngbTooltip]="jobToolTip" 
                          *ngIf="(workHour.endTime - workHour.startTime) != 0 && workHour.startTime <= workHour.endTime 
                          && workHour.endTime > hour+0.14 && workHour.startTime <= hour+0.75">
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <hr class="underline" [class.show]="showCell.includes(timesheet.jobType)" >
          </ng-container>
          
        </ng-container>

      </ng-container>
      
    </div>
  </div>
</div>
