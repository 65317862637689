import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { JM, JMCONSTANT, JMENUM } from "@ccep/CCEPConnector-ts";
import { Session } from "@services/session";
import { BasePage } from "src/app/ui/model/base/base";
import * as utility from 'src/app/services/utility';
import { AppDelegate } from "src/app/AppDelegate";
import { JMLanguage } from "src/lib/JMLanguage/JMLanguage";
import { ActionButtonDefinition, ActionButtonJMAccountReview, ActionButtonType } from "@enum/action-button";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { SidebarComponent } from "src/app/ui/components/sidebar/sidebar.component";

@Component({
    selector: "app-ccep-jm-account-review-upload-template",
    templateUrl: "./ccep-jm-account-review-upload-template.component.html",
    styleUrls: ["./ccep-jm-account-review-upload-template.component.scss"],
})
export class CCePJMAccountReviewUploadTemplateComponent extends BasePage implements OnInit {
    @ViewChild("downloadTemplatePanel", { static: true }) downloadTemplatePanel;
    @ViewChild('sideBar', { static: true }) sideBar: SidebarComponent;
    step = 1;
    arrowSVG = "/assets/svg/settings_users_arrow.svg";
    step1SVG = "/assets/svg/settings_users_step1_grey.svg";
    step2SVG = "/assets/svg/settings_users_step2_grey.svg";
    step1ActiveSVG = "/assets/svg/settings_users_step1_blue.svg";
    step2ActiveSVG = "/assets/svg/settings_users_step2_blue.svg";
    clearUploadedTemplate = "";
    selectedFile: File;
    actionButtonData = [];
    workCentres: any[] = [];
    workCentreList: any[] = [];
    isSelectedAllWorkCentre = false;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.checkfeatureEnabled() && this.checkPermission() && this.checkReviewStatus();
        this.initActionBar();
        this.setWorkCentreList();
    }

    checkfeatureEnabled(): boolean {
        if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JM_HALF_YEAR_REVIEW_USER_ACCOUNT)) {
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

    checkPermission(): boolean {
        if (!this.authorizationService.hasPermission(JMENUM.Permission.ACCOUNT_REVIEW_CREATE)) {
            AppDelegate.openSnackBar(JMLanguage.translate('api.error.401'));
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

    async checkReviewStatus() {
        const request = new JM.JMRequestAuthHalfYearReviewCheckReviewStatus();
        const response: JM.JMResponseAuthHalfYearReviewCheckReviewStatus = await AppDelegate.sendJMRequest(request);

        if (!response || !response.code || response.code !== 200 || !response.payload) {
            AppDelegate.openErrorBar(response);
            return;
        }
        const isEnableReview = response.payload.enabled;
        if (Session.isEnableJMAccountReview !== isEnableReview) {
            Session.setIsEnableJMAccountReview(isEnableReview);
            this.sideBar.reRenderSideBar();
        }
        if (!isEnableReview) {
            this.router.navigate(['']);
        }
    }

    initActionBar() {
        this.actionButtonData = [];
        const donwloadTemplateBtn = ActionButtonDefinition[ActionButtonType.jmAccountReview][ActionButtonJMAccountReview.downloadTemplate];
        this.actionButtonData.push(donwloadTemplateBtn);
    }

    async setWorkCentreList() {
        this.workCentreList = [];
        if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
            this.workCentreList = Object.keys(Session.workCentreDict).map(wc => {
                return {
                    value: wc,
                    label: wc,
                }
            });
        } else {
            await this.getROWorkCentres();
        }
    }

    async getROWorkCentres() {
        let request = new JM.JMRequestWorkCentresWorkCentreSummary();
        request.active = JMENUM.RequestActive.ACTIVE;
        request.sortBy = "workCentreCode";
        request.responsibleOfficer = [Session.userInfo.name];
        request.includeSummary = true;
        request.pageNumber = 1;
        request.pageSize = 1000;
        const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);

        if (!response || !response.code || response.code !== 200 || !response.payload) {
            AppDelegate.openErrorBar(response);
            return;
        }
        this.workCentreList = this.workCentreList.concat(response.payload.records.map((wc: JM.JMOBJ.WorkCentre) => {
            return {
                value: wc.workCentreCode,
                label: wc.workCentreCode,
            }
        }));
    }

    onActionButtonClicked(args: any) {
        if (args.action === ActionButtonJMAccountReview.downloadTemplate) {
            this.downloadTemplatePanel.toggle();
        }
    }

    async downloadTemplate(btn: any) {
        btn.isLoading = true;
        const request = new JM.JMRequestAuthHalfYearReviewDownloadTemplate();
        request.workCentreList = this.workCentres;
        request._options = {
            responseType: 'blob'
        };

        const response: JM.JMResponseAuthHalfYearReviewDownloadTemplate = await AppDelegate.sendJMRequest(request);
        btn.isLoading = false;
        if (!response) {
            AppDelegate.openErrorBar(response);
            return;
        }

        const fileName = `${moment().format('YYYYMMDD_HHmmss')}_${Session.userInfo.name}_useraccount.xlsx`;
        saveAs(response, fileName);
    }



    async uploadTemplate(btn: any) {
        if (!this.selectedFile) return;
        btn.isLoading = true;
        let request: any = new JM.JMRequestAuthHalfYearReviewUploadTemplate();
        request.stream = new FormData;
        request.stream.set('template', this.selectedFile, this.selectedFile.name);
        const response: JM.JMResponseAuthHalfYearReviewUploadTemplate = await AppDelegate.sendJMRequestFile(request, request.stream);
        btn.isLoading = false;
        if (!response && response.code !== 200) {
            AppDelegate.openErrorBar(response);
            return;
        }
        this.step = 2;
    }

    validateFile(file: File) {
        // check file type
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            AppDelegate.openSnackBar(JMLanguage.translate('global.error.upload-xlsx-file'));
            return false;
        }

        // check file size
        const fileSizeMB = file.size / 1024 / 1024; // B to MB
        if (fileSizeMB >= 25) { // cannot larger than 25 MB
            AppDelegate.openSnackBar(JMLanguage.translate('global.error.file-size-less-than-25-mb'));
            return false;
        }

        return true;
    }

    onUpdatedFile(file: File) {
        if (file && !this.validateFile(file)) {
            this.clearUploadedTemplate = this.clearUploadedTemplate ? "" : "1"; // app-file-selector detect clear binding change to clear files
            return;
        }
        this.selectedFile = file;
    }

    checkHistory() {
        this.router.navigate(['/ccep-jm-account-review/submission-history']);
    }

    selectAllWorkCentres() {
        if (this.isSelectedAllWorkCentre) {
            this.workCentres = [];
        } else {
            this.workCentres = this.workCentreList.map(workCentre => workCentre.value);
        }
        this.onWorkCentreChange();
    }

    onWorkCentreChange() {
        this.isSelectedAllWorkCentre = this.workCentres.length === this.workCentreList.length;
    }
}

