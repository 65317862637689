import { ColumnType } from '@enum/table-column-type';
import { Observable } from 'rxjs';
import { InlineStyleHelper } from 'src/app/ui/components/inline-style-helper';

export class TableHelper {

    static createTableColumn(key: string, description: string, type: ColumnType, id: string = "", columnWidth: number = 100,textAlign?:string,timeStringFormat?:string): any {
        let column = {
            id: id,
            key: key,
            description: description,
            type: type.valueOf(),
            order: 0,
            columnWidth: columnWidth
        };

        if(textAlign) column["textAlign"] = textAlign;
        if(timeStringFormat) column["format"] = timeStringFormat;
        return column;
    }

    static createTableSource(totalRecordCount: number, content: any, tableName: string = "tmp", id: string = "", excelDataObservable?: Observable<any>): any {
        return {
            id: id,
            totalRecordCount: totalRecordCount,
            content: content,
            tableName: tableName,
            excelDataObservable: excelDataObservable,
            selectedRowIndex: -1
        }
    }

    static createText(description: string, id: string = "", css?: string) {
        return {
            id: id,
            description: description,
            class: css,
        }
    }

    static createHyperlink(description: string, id: string = "", url: string, css?: string) {
        return {
            id: id,
            description: description,
            url: url,
            class: css,
        }
    }

    static createButton(handler: any, id: string = "", css?: string, icon?: string, description?: string, returnValue?: any, isHidden?: boolean, tagId?, disable = false): any {
        let button = { id: id, handler: handler };

        if (css) button["class"] = css;
        if (icon) button["icon"] = icon;
        if (description) button["description"] = description;
        if (returnValue) button["value"] = returnValue;
        button["isHidden"] = isHidden ? isHidden : false;
        button["type"] = "button";
        button["tagId"] = tagId;
        button["disable"] = disable;

        return button;
    }

    static createRow(description: string, values: any[], css?: string) {
        let row = {
            description: description,
            values: values
        }

        if (css) row["class"] = css;

        return row;
    }

    static createRowValue(description: string, css?: string) {
        let value = {
            description: description
        }

        if (css) value["class"] = css;
        value["type"] = "rowValue";

        return value;

    }

    static createInput(value:string,validators: any[] = [],invalidHandler?:any, isEditable: boolean = false) {
        let input = {
            value: value,
            validators:validators,
            isEditable: isEditable,
            type: 'input'
        }

        if(invalidHandler) input["invalidHandler"] = invalidHandler;

        return input;

    }

    static creatObject(subObjects: any[], type: ColumnType, css?: string): any {
        return {
            subObjects: subObjects,
            type: type.valueOf(),
            class: css,
        }
    }

    static createTableColumnWidthStyles(tableColumns: any[]): HTMLStyleElement[] {
        let totalColumnWidth = 0;
        for (let column of tableColumns) {
            totalColumnWidth += column.columnWidth;
        }
        let tableStyle = tableColumns
            .map(column => InlineStyleHelper.createinlineStyle("mat-column-" + column.key, "{width: " + ((column.columnWidth / totalColumnWidth) * 100) + "%; }"))

        return tableStyle;
    }

    static setTableEditable(tableSource, isEditable) {
        tableSource.content.forEach(rowData => {
            for (const [key, value] of Object.entries(rowData)) {
              let cell = value as any;
              if (cell.type && cell.type === 'input') {
                cell.isEditable = isEditable;
              }
            }
          });
    }

    static setTableRowEditable(tableSource, isEditable, rowIndex) {
        for (const [key, value] of Object.entries(tableSource.content[rowIndex])) {
            let cell = value as any;
            if (cell.type && cell.type === 'input') {
                cell.isEditable = isEditable;
            }
        }

    }

    static setCellButtonIsHidden(tableSource, isHidden, rowIndex, columnId, buttonId) {
        let targetButtons = tableSource.content[rowIndex][columnId].filter(button => button.id === buttonId);

        if (targetButtons.length > 0) {
            targetButtons[0].isHidden = isHidden;
        }

    }

}