import { AppDelegate } from 'src/app/AppDelegate';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { ReleaseNoteViewData } from './release-note.model';

export async function requestAppUpdateVersion(platform) {
  const request = new JM.JMRequestSystemConfigsLatestVersion();
  request.appname = JMENUM.SourceSystem.CCEPJM;
  request.platform = platform;
  const response: JM.JMResponseSystemConfigsLatestVersion = await AppDelegate.sendJMRequest(request);
  if (!response || !response.code || response.code != 200 || !response.payload) {
    AppDelegate.openErrorBar(response);
    return null;
  }

  return response.payload;
}

export async function requestReleaseNoteList(platform, pagination? : {
  pageNumber?: number;
  pageSize?: number;
}) {
  const request = new JM.JMRequestSystemConfigsLatestNewsList();
  request.appname = JMENUM.SourceSystem.CCEPJM;
  request.platform = platform;
  request.isVersionFilled = true;
  pagination && pagination.pageNumber && (request.pageNumber = pagination.pageNumber);
  pagination && pagination.pageSize && (request.pageSize = pagination.pageSize);

  const response: JM.JMResponseSystemConfigsLatestNewsList = await AppDelegate.sendJMRequest(request);
  if (!response || !response.code || response.code != 200 || !response.payload) {
    AppDelegate.openErrorBar(response);
    return null;
  }

  return response.payload;
}

export async function requestRelaseNote(platform) {
  const request = new JM.JMRequestSystemConfigsLatestNews();
  request.appname = JMENUM.SourceSystem.CCEPJM;
  request.platform = platform;

  const response: JM.JMResponseSystemConfigsLatestNews = await AppDelegate.sendJMRequest(request);
  if (!response || !response.code || response.code != 200 || !response.payload) {
    AppDelegate.openErrorBar(response);
    return null;
  }

  return response.payload;
}

export async function requestReleaseNoteCreate(data: ReleaseNoteViewData, platform) {
  const request = new JM.JMRequestSystemConfigsCreateLatestNews();
  request.appname = JMENUM.SourceSystem.CCEPJM;
  request.platform = platform;

  request.version = data.version;
  request.enMessage = data.enMessage;
  request.zhMessage = data.zhMessage;
  data.latestVersion && (request.latestVersion = data.latestVersion);
  data.minVersion && (request.minVersion = data.minVersion);

  const response: JM.JMResponseSystemConfigsCreateLatestNews = await AppDelegate.sendJMRequest(request);
  if (!response || !response.code || response.code != 200 || !response.payload) {
    AppDelegate.openErrorBar(response);
    return null;
  }

  return response.payload;
}

export async function requestReleaseNoteEdit(data: ReleaseNoteViewData, platform) {
  const request = new JM.JMRequestSystemConfigsUpdateLatestNews();
  request.appname = JMENUM.SourceSystem.CCEPJM;
  request.platform = platform;

  request.version = data.version;
  request.enMessage = data.enMessage;
  request.zhMessage = data.zhMessage;
  data.latestVersion && (request.latestVersion = data.latestVersion);
  data.minVersion && (request.minVersion = data.minVersion);

  const response: JM.JMResponseSystemConfigsUpdateLatestNews = await AppDelegate.sendJMRequest(request);
  if (!response || !response.code || response.code != 200 || !response.payload) {
    AppDelegate.openErrorBar(response);
    return null;
  }

  return response.payload;
}
