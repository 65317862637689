<div class="font-size-xl m-0 mb-2 px-2">
  {{ "component.jobcard-task-progress.title" | translate}}
</div>
<hr class="underline" />

<div class="row mx-2 gray-bg mt-3 mb-3 p-3 align-items-center justify-content-between task-item-container">
  <div class="d-inline-block col-10 p-0">
    <div class="d-inline-block bold mr-2">
      <span class="red" *ngIf="mandatory.responseToClientTime == EnumMandatory.alwayMandate">*</span>
      <span class="red" *ngIf="mandatory.responseToClientTime == EnumMandatory.onCompleteMandate">#</span>
      {{ "component.task-progress.response-to-client-time" | translate }}
    </div>

    <app-display-text-field *ngIf="!editable.responseToClientTime"
      id="task-progress-response-to-client-time"
      class="d-inline-block mx-3"
      [textField]="jobCard?.responseToClientTime | dateTimeWeek "
      [emptyHandleDisplay]="emptyHandleNa"
      [ngClass]="{'input-error':errorFields.responseToClientTime}"
    ></app-display-text-field>

    <div *ngIf="editable.responseToClientTime" class="d-inline-block row mx-0">
      <app-date-input class="d-inline-block mr-2"
        [isError]="errorFields.responseToClientTime"
        [input]="dateInputSet['responseToClientTime']"
        [inputId]="'responseToClientDateImput'"
        [field]="'responseToClientTime'"
        (onBlur)="onBlurDateInput($event)"
      >
      </app-date-input>
      <app-time-input class="d-inline-block p-0 col-5 col-sm-3"
        [customClass]="'px-1 form-control'"
        [isError]="errorFields.responseToClientTime"
        [input]="timeInputSet['responseToClientTime']"
        [inputId]="'responseToClientTimeImput'"
        [field]="'responseToClientTime'"
        (onBlur)="onBlurTimeInput($event)"
      >
      </app-time-input>
    </div>
  </div>

  <div id="task-progress-response-client-status"
    *ngIf="needComplianceStatus"
    [ngClass]="{
      'bold': true,
      'blue': validationResult.isPassResponseClient == complianceStatus.pass,
      'red': validationResult.isPassResponseClient == complianceStatus.fail
    }">{{ "component.task-progress.compliance."+validationResult.isPassResponseClient | translate }}
  </div>
  
  <!-- <ng-container *ngIf="!isPageModeView">
    <div *ngIf="validationResult.isPassResponseClient" id="task-progress-response-client-status" class="bold blue">{{ "component.cm-task-progress.pass" | translate }}</div>
    <div *ngIf="!validationResult.isPassResponseClient" id="task-progress-response-client-status" class="bold red">{{ "component.cm-task-progress.fail" | translate }}</div>
  </ng-container> -->
</div>

<div class="row mx-0 justify-content-between align-items-stretch">

  <div class="col-12 col-md-4 mb-3 task-item-container-col px-2">
    <div class="task-item-container row mx-0 py-2">
      <div class="col-12 mb-3">
        <div class="d-inline-block bold">{{ "component.task-progress.response" | translate }}</div>
        <div id="task-progress-response-status"
          *ngIf="needComplianceStatus"
          [ngClass]="{
            'float-right': true,
            'bold': true,
            'blue': validationResult.isPassResponse == complianceStatus.pass,
            'red': validationResult.isPassResponse == complianceStatus.fail
          }">{{ "component.task-progress.compliance."+validationResult.isPassResponse | translate }}
        </div>
        <!-- !!! Not Yet Ready !!! -->
        <!-- <div class="float-right bold blue" id="task-progress-response-status" *ngIf="componentParameters.isPassResponse">{{ "component.task-progress.pass" | translate }}</div>
        <div class="float-right bold red" id="task-progress-response-status" *ngIf="!componentParameters.isPassResponse">{{ "component.task-progress.fail" | translate }}</div> -->
      </div>
      <div class="col-12 align-self-end">
        <div class="d-block">
          <span class="red" *ngIf="mandatory.receivedTime == EnumMandatory.alwayMandate">*</span>
          <span class="red" *ngIf="mandatory.receivedTime == EnumMandatory.onCompleteMandate">#</span>
          {{ "component.task-progress.received-time" | translate }}
        </div>
        <app-display-text-field *ngIf="!editable.receivedTime"
          id="task-progress-received-time"
          class="d-block mt-1 mx-3 mb-3" 
          [textField]="jobCard?.receivedTime | dateTimeWeek "
          [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':errorFields.receivedTime}"
        ></app-display-text-field>
        <div *ngIf="editable.receivedTime" class="row mx-0 align-items-center justify-content-end">
          <app-date-input class="col-12 m-0 mb-2 p-0" [isError]="errorFields.receivedTime"
            [input]="dateInputSet['receivedTime']" [inputId]="'receivedDateInput'" [field]="'receivedTime'" 
            [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
          </app-date-input>
          <app-time-input class="col-5 p-0" [isError]="errorFields.receivedTime" [customClass]="'form-control'"
            [input]="timeInputSet['receivedTime']" [inputId]="'receivedTimeInput'" [field]="'receivedTime'" 
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
          </app-time-input>
        </div>

        <div class="d-block">
          <span class="red" *ngIf="mandatory.appointmentTime == EnumMandatory.alwayMandate">*</span>
          <span class="red" *ngIf="mandatory.appointmentTime == EnumMandatory.onCompleteMandate">#</span>
          {{ "component.task-progress.appointed-time" | translate }}
        </div>
        <app-display-text-field *ngIf="!editable?.appointmentTime" class="d-block mt-1 mx-3 mb-3" id="task-progress-appointment-time" 
          [textField]="jobCard?.appointmentTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':errorFields.appointmentTime}">
        </app-display-text-field>
        <div *ngIf="editable.appointmentTime" class="row mx-0 align-items-center justify-content-end">
          <app-date-input class="col-12 m-0 mb-2 p-0" [isError]="errorFields.appointmentTime"
            [input]="dateInputSet['appointmentTime']" [inputId]="'appointmentDateInput'" [field]="'appointmentTime'" 
            [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
          </app-date-input>
          <app-time-input class="col-5 p-0" [isError]="errorFields.appointmentTime" [customClass]="'form-control'"
            [input]="timeInputSet['appointmentTime']" [inputId]="'appointmentTimeInput'" [field]="'appointmentTime'" 
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
          </app-time-input>
        </div>

        <div class="d-block d-flex">
          <span class="red" *ngIf="mandatory.startTime == EnumMandatory.alwayMandate">*&thinsp;</span>
          <span class="red" *ngIf="mandatory.startTime == EnumMandatory.onCompleteMandate">#&thinsp;</span>
          {{ "component.task-progress.arrival-start-time" | translate }}
          <ng-container *ngIf="pageMode === JMPageMode.VIEW" >
            <app-arrival-gps [arrivalGpsParam]='arrivalGpsParam'></app-arrival-gps>
          </ng-container>
        </div>
        <app-display-text-field *ngIf="!editable.startTime" class="d-block mt-1 mx-3 mb-3" id="task-progress-start-time" 
          [textField]="jobCard?.startTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':errorFields.startTime}">
        </app-display-text-field>
        <div *ngIf="editable.startTime" class="row mx-0 align-items-center justify-content-end">
          <app-date-input class="col-12 m-0 mb-2 p-0" [isError]="errorFields.startTime"
            [input]="dateInputSet['startTime']" [inputId]="'startDateInput'" [field]="'startTime'" 
            [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
          </app-date-input>
          <app-time-input class="col-5 p-0" [isError]="errorFields.startTime" [customClass]="'form-control'"
            [input]="timeInputSet['startTime']" [inputId]="'startTimeInput'" [field]="'startTime'" 
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
          </app-time-input>
        </div>
      </div>

    </div>
  </div>
  
  <div class="col-12 col-md-4 mb-3 task-item-container-col px-2">
    <div class="task-item-container row mx-0 py-2">
      <div class="col-12 mb-3">
        <div class="d-inline-block bold">{{ "component.task-progress.rectification" | translate }}</div>
        <div id="task-progress-rectification-status"
          *ngIf="needComplianceStatus"
          [ngClass]="{
            'float-right': true,
            'bold': true,
            'blue': validationResult.isPassRectification == complianceStatus.pass,
            'red': validationResult.isPassRectification == complianceStatus.fail
          }">{{ "component.task-progress.compliance."+validationResult.isPassRectification | translate }}
        </div>
        <!-- !!! Not Yet Ready !!! -->
        <!-- <div class="float-right bold blue" id="task-progress-rectification-status" *ngIf="componentParameters.isPassRectification">{{ "component.task-progress.pass" | translate }}</div>
        <div class="float-right bold red" id="task-progress-rectification-status" *ngIf="!componentParameters.isPassRectification">{{ "component.task-progress.fail" | translate }}</div> -->
        <!-- </ng-container> -->
      </div>

      <div class="col-12 align-self-end" >
        <div class="d-block">
          <span class="red" *ngIf="mandatory.plannedCompletionTime == EnumMandatory.alwayMandate">*</span>
          <span class="red" *ngIf="mandatory.plannedCompletionTime == EnumMandatory.onCompleteMandate">#</span>
          {{ "component.task-progress.planned-completion-time" | translate }}
        </div>
        <app-display-text-field
          *ngIf="!editable.plannedCompletionTime" 
          id="task-progress-planned-completion-time"
          class="d-block mt-1 mx-3 mb-3"  
          [textField]="jobCard?.plannedCompletionTime | dateTimeWeek "
          [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':errorFields.plannedCompletionTime}"
        ></app-display-text-field>
        <div *ngIf="editable.plannedCompletionTime" class="row mx-0 align-items-center justify-content-end">
          <app-date-input class="col-12 m-0 mb-2 p-0" [isError]="errorFields.plannedCompletionTime" 
            [input]="dateInputSet['plannedCompletionTime']" [inputId]="'plannedCompletionDateInput'" [field]="'plannedCompletionTime'" 
            [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
          </app-date-input>
          <app-time-input class="col-5 p-0" [isError]="errorFields.plannedCompletionTime" [customClass]="'form-control'"
            [input]="timeInputSet['plannedCompletionTime']" [inputId]="'plannedCompletionTimeInput'" [field]="'plannedCompletionTime'" 
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
          </app-time-input>
        </div>

        <div class="d-block">
          <span class="red" *ngIf="mandatory.completionTime == EnumMandatory.alwayMandate">*</span>
          <span class="red" *ngIf="mandatory.completionTime == EnumMandatory.onCompleteMandate">#</span>
          {{ "component.task-progress.completion-time" | translate }}
        </div>
        <app-display-text-field *ngIf="!editable.completionTime" class="d-block mt-1 mx-3 mb-3" id="task-progress-completion-time" 
          [textField]="jobCard?.completionTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':errorFields.completionTime}">
        </app-display-text-field>
        <div *ngIf="editable.completionTime" class="row mx-0 align-items-center justify-content-end">
          <app-date-input class="col-12 m-0 mb-2 p-0" [isError]="errorFields.completionTime" 
            [input]="dateInputSet['completionTime']" [inputId]="'completionDateInput'" [field]="'completionTime'" 
            [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
          </app-date-input>
          <app-time-input class="col-5 p-0" [isError]="errorFields.completionTime" [customClass]="'form-control'"
            [input]="timeInputSet['completionTime']" [inputId]="'completionTimeInput'" [field]="'completionTime'" 
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
          </app-time-input>
        </div>
      </div>

    </div>
  </div>

  <div class="col-12 col-md-4 mb-3 task-item-container-col px-2">
    <div class="task-item-container row mx-0 py-2">
      <div class="col-12 mb-3">
        <div class="d-inline-block bold">{{ "component.task-progress.malfunction" | translate }}</div>
        <div class="float-right bold" >
          <div class="custom-checkbox position-relative custom-control d-inline-block mr-2">
            <input type="checkbox" id="malfunction-breakdown-checkbox"
              class="checkbox custom-control-input p-0 "
              name="malfunction-breakdown"
              [disabled]="componentParameters.disabledBreakdown"
              (click)="onChangeBreakdown($event)"
              [(ngModel)]="jobCard.breakdown"
            />
            <label for="malfunction-breakdown-checkbox" class="custom-control-label mb-0">
              {{ "component.task-progress.breakdown" | translate }}
            </label>
          </div>
        </div>
      </div>

      <div class="col-12 align-self-end" >
        <div class="d-block">
          <span class="red" *ngIf="mandatory.malfunctionStartTime == EnumMandatory.alwayMandate">*</span>
          <span class="red" *ngIf="mandatory.malfunctionStartTime == EnumMandatory.onCompleteMandate">#</span>
          {{ "component.task-progress.malfunction-start-time" | translate }}
        </div>
        <app-display-text-field *ngIf="!editable.malfunctionStartTime" class="d-block mt-1 mx-3 mb-3" id="task-progress-malfunction-start-time" 
          [textField]="jobCard?.malfunctionStartTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':errorFields.malfunctionStartTime}">
        </app-display-text-field>
        <div *ngIf="editable.malfunctionStartTime" class="row mx-0 align-items-center justify-content-end">
          <app-date-input class="col-12 m-0 mb-2 p-0" [isError]="errorFields.malfunctionStartTime"
            [input]="dateInputSet['malfunctionStartTime']" [inputId]="'malfunctionStartDateInput'" [field]="'malfunctionStartTime'" 
            [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
          </app-date-input>
          <app-time-input class="col-5 p-0" [isError]="errorFields.malfunctionStartTime" [customClass]="'form-control'"
            [input]="timeInputSet['malfunctionStartTime']" [inputId]="'malfunctionStartTimeInput'" [field]="'malfunctionStartTime'" 
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
          </app-time-input>
        </div>

        <div class="d-block">
          <span class="red" *ngIf="mandatory.malfunctionEndTime == EnumMandatory.alwayMandate">*</span>
          <span class="red" *ngIf="mandatory.malfunctionEndTime == EnumMandatory.onCompleteMandate">#</span>
          {{ "component.task-progress.malfunction-end-time" | translate }}
        </div>
        <app-display-text-field *ngIf="!editable.malfunctionEndTime" class="d-block mt-1 mx-3 mb-3" id="task-progress-malfunction-end-time" 
          [textField]="jobCard?.malfunctionEndTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':errorFields.malfunctionEndTime}">
        </app-display-text-field>
        <div *ngIf="editable.malfunctionEndTime" class="row mx-0 align-items-center justify-content-end">
          <app-date-input class="col-12 m-0 mb-2 p-0" [isError]="errorFields.malfunctionEndTime" 
            [input]="dateInputSet['malfunctionEndTime']" [inputId]="'malfunctionEndDateInput'" [field]="'malfunctionEndTime'" 
            [dateAlertMsg]="dateAlertMsg" (onBlur)="onBlurDateInput($event)">
          </app-date-input>
          <app-time-input class="col-5 p-0" [isError]="errorFields.malfunctionEndTime" [customClass]="'form-control'" 
            [input]="timeInputSet['malfunctionEndTime']" [inputId]="'malfunctionEndTimeInput'" [field]="'malfunctionEndTime'" 
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
          </app-time-input>
        </div>
      </div>

    </div>
  </div>

</div>
