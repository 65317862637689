<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [breadcrumbs]="breadcrumbs"
  ></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding">

    <div class="row mx-0">
      <div class="col-xl-6 col-12">
        <app-pm-job-description 
          #pmJobDescriptionElem 
          class="d-block mb-5"
          [job]="job" 
          [pageMode]="pageMode" 
          >
        </app-pm-job-description>
        <app-pm-job-client-information 
          #pmJobClientInformationElem 
          class="d-block mb-5" 
          [job]="job" 
          [pageMode]="pageMode" 
          >
        </app-pm-job-client-information>
      </div>
      <div class="col-xl-6 col-12">
        <app-pm-job-job-progress 
          #pmJobJobProgressElem [job]="job" 
          class="d-block mb-5" 
          [pageMode]="pageMode" 
          >
        </app-pm-job-job-progress>
        <app-pm-job-equipment-information 
          #pmJobEquipmentInformationElem 
          class="d-block mb-5" 
          [periodId]="job?.period?._id" 
          [pmJobNumber]="job?.pmJobNumber" 
          [selectedEquipmentsNumber] = "job?.equipmentList?.length" 
          [pageMode]="pageMode" 
          >
        </app-pm-job-equipment-information>
      </div>
    </div>
    
    <!-- Action Sidebar -->
    <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
      <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disabledActionSideBar"
        (clickEvent)="actionBtnService.onActionButtonClick($event)">
      </app-action-sidebar>
    </ng-container>

  </div>
</div>