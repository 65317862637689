import { Component, HostListener, Injector, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AttachmentFileType } from '@enum/attachment-file-type';


@Component({
  selector: 'attachment-previewer',
  templateUrl: './attachment-previewer.component.html',
  styleUrls: ['./attachment-previewer.component.scss']
})

export class AttachmentPreviewerComponent implements OnInit {
  @Input() file: any;
  @Input() fileDetails: any;
  @Input() isLoading: boolean;
  @Input() isHaSn: boolean;
  @Input() sortOrder: any;
  @Output() onSelectedPageChange = new EventEmitter<number>();
  selectedPage: number;
  totalPages: number;
  isLoaded: boolean = false;
  fileType: AttachmentFileType;
  previewContent: string;
  attachmentFileType = AttachmentFileType;  // for html use

  constructor(private injector: Injector) { }

  ngOnInit() { this.resetPage(); }

  ngOnDestroy() { }

  ngOnChanges() {
    if (this.file) this.updatePreview();
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  //============================================================================
  updatePreview(file?) {
    if (!this.file) return;

    var mimeType = this.file.type;
    this.fileType = this.getAttachmentFileType(mimeType);

    if (this.fileType == AttachmentFileType.Text) {
      let promise = this.file.text();
      promise.then(result => {
        this.previewContent = result;
      });
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = (_event) => {
      this.previewContent = reader.result.toString();
    }
  }

  getAttachmentFileType(mimeType) {
    if (mimeType.startsWith("image/")) {
      return AttachmentFileType.Image;
    }

    if (mimeType.startsWith("application/pdf")) {
      return AttachmentFileType.Pdf;
    }

    if (mimeType.startsWith("text/")) {
      return AttachmentFileType.Text;
    }

    return AttachmentFileType.Other;
  }

  public getFileSize(fileSize) {
    let size = fileSize / 1024;
    let unit = "KB";

    if (size > 1024) {
      size = size / 1024;
      unit = "MB";
    }

    return size.toFixed(2) + " " + unit;
  }



  nextPage() {
    if (this.selectedPage >= this.totalPages) { return }
    this.selectedPage++;
    this.refreshSelectedPage(this.selectedPage)
  }

  prevPage() {
    if (this.selectedPage <= 1) { return }
    this.selectedPage--;
    this.refreshSelectedPage(this.selectedPage)
  }

  resetPage() {
    this.selectedPage = 1
    this.refreshSelectedPage(this.selectedPage)
  }

  setSelectedPage(e) {
    this.selectedPage = e.target.value
    this.refreshSelectedPage(this.selectedPage)
  }

  refreshSelectedPage(value) {
    this.onSelectedPageChange.emit(value)
  }

}
