import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { saveAs } from "file-saver";

import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { AuthorizationService } from '@services/authorization.service';
import { PmJobService } from '@services/pm-job.service';
import { BasePage } from 'src/app/ui/model/base/base';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Attachment } from 'src/app/entity/data-model/attachment';
import { formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
@Component({
  selector: 'app-pm-job-attachment',
  templateUrl: './pm-job-attachment.component.html',
  styleUrls: ['./pm-job-attachment.component.scss']
})
export class PmJobAttachmentComponent extends BasePage implements OnInit {
  @ViewChild('attachment_preview', { static: true }) attachmentPreview;
  @ViewChild('upload_previewer', { static: true }) uploadPreviewer;
  protected authorizationService: AuthorizationService;
  protected pmJobService: PmJobService;
  protected route: ActivatedRoute;

  jobNumber: string;
  job: JMOBJ.PmJob;

  breadcrumbs: any = [];

  file: any;
  fileList: any = {};
  fileDetails: any = {};

  attachment: Attachment;
  selectedAttachment: any;
  attachmentList: any = [];

  tablexParam: any = {};
  uploadPreviewParam: any = {};

  navbarTitle: string;
  
  isDisabledAddAttachment: boolean = false;
  isPreviewerLoading: boolean = false;
  hasViewPermission: boolean = false;
  hasEditPermission: boolean = true;
  isShowUploadPreview: boolean = false;

  constructor(injector: Injector) {
      super(injector);
      this.pmJobService = injector.get(PmJobService);
  }

  ngOnInit() {
    this.checkViewPermission(JMENUM.Permission.PMJOB_UPDATE);
    
    this.jobNumber = this.route.snapshot.paramMap.get('jobNumber');

    this.initBreadCrumbs();
    this.initTable();
    this.fetchJobData();
  }

  initBreadCrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-pm-job',
        name: JMLanguage.translate('pages.pm-job.page-title.view'),
        route: '/pm/job-list',
      },
      {
        id: 'breadcrumbs-pm-job-number',
        name: this.jobNumber,
        route: `/pm/job/view/${this.jobNumber}`,
      },
      { id: 'breadcrumbs-attchment',
        name: JMLanguage.translate('component.pm-job-attachment.attachment-title'),
        route: null,
        currentPage: true
      }
    ];
  }

  initPermission() {
    // Can view by: DRAFT / COMPLETED / REWORKING
    const jobStatus = this.job.status;

    if (jobStatus && (
      jobStatus === JMENUM.PMJobStatus.DRAFT || jobStatus === JMENUM.PMJobStatus.COMPLETED || jobStatus === JMENUM.PMJobStatus.REWORKING
    )) {
      if (this.hasWorkCenterPermission()) {
        this.hasEditPermission = true;
      }
    } else { // no permission
      this.openSnackBar(this.translate("component.pm-job-attachment.status-not-match"));
      this.router.navigate(['']);
    } 
  }

  // ----------- API ----------- //
  private async fetchJobData() {
    this.tablexParam.isLoadingTable = true;
    let response: JM.JMResponseGetPmJob = await this.pmJobService.getPmJob(this.jobNumber);
    this.tablexParam.isLoadingTable = false;

    if (response && response.payload) {
      this.updateJob(response && response.payload);
      this.initPermission();
      this.initUploadPreviewer();
    }
  }

  private async requestRemoveAttachment(attachmentId, button?) {
    if (button) button.isLoading = true;
    let response = await this.pmJobService.removeAttachment(this.job, attachmentId);
    if (button) button.isLoading = false;
    this.updateJob(response.payload);
    this.setPreviewContent(undefined);
    this.openSnackBar(this.translate("global.removed"));
  }

  private async requestUploadAttachment(files:Array<File>) {
    if (!(Array.isArray(files) && files.length > 0)) return;
    this.uploadPreviewParam.isLoadingAddAttachment = true;
    //this.file = file;
    let updatedJobCard;
    for (let file of files) {
      try {
        let response = await this.pmJobService.uploadAttachment(this.job, file, file.name);
        updatedJobCard = response.payload;
        this.job.version = response.payload.version
      } catch (err) { 
        console.error(err); 
      }
    }
    this.uploadPreviewParam.isLoadingAddAttachment = false;
    this.openSnackBar(JMLanguage.translate("global.saved"));
    this.updateJob(updatedJobCard);
    this.uploadPreviewParam.userInput = {};
    this.uploadPreviewer.clearUploadTable();
    this.uploadPreviewer.closeUploadPreview();
  }

  private async requestGetFile(attachmentId, description?, download?, button?) {
    if (button) button.isLoading = true;
    this.isPreviewerLoading = download ? false : true;

    const request = new JM.JMRequestFilesGetFile(attachmentId);

    if (button) button.isLoading = true;
    this.isPreviewerLoading = download ? false : true;
    const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request);
    if (button) button.isLoading = false;
    this.isPreviewerLoading = false;

    if (!response || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (download) {
      let fileName = description != null && description != undefined ? description : attachmentId
      saveAs(response.payload, fileName);
    } else {
      this.setPreviewContent(response.payload);
    }
  }

  // ----------- UI function ----------- //
  //========= Validation =========//
  hasWorkCenterPermission(): boolean {
    return this.authorizationService.hasPermission(JMENUM.Permission.WORKCENTRE_ALL) ||
      this.authorizationService.hasAuthorizationForWorkCenter(this.job.pmPlan['workCentre']);
  }
  
  updateJob(job) {
    this.job = job;
    
    if (this.job.attachments && this.job.attachments.length > 0) {
      this.job.attachments = this.job.attachments.reverse();
      this.attachmentList = this.job.attachments;
      this.requestGetFile(this.attachmentList[0].attachmentId);
      this.selectedAttachment = this.attachmentList[0];
    }else{
      this.attachmentList = [];
    }
    this.renderTable();
  }

  setPreviewContent(file) {
    if (file) {
      this.file = file;
      this.fileDetails = {
        fileName: this.selectedAttachment.description,
        fileSize: '',
        uploadedBy: this.selectedAttachment.uploadedBy,
        uploadDate: formatDateTimeWithWeek(this.selectedAttachment.uploadedAt)
      };

      this.fileDetails.fileSize = this.attachmentPreview.getFileSize(this.file.size);
    } else {
      this.file = undefined;
      this.fileDetails = {};
    }
  }

  // ----------- Button function ----------- //
  onClickSubmitAttachment = (files:Array<File>) => {
    this.requestUploadAttachment(files);
  }

  onDownloadButtonClicked = (button) => {
    this.requestGetFile(button.attachment.attachmentId,button.attachment.description, true, button)
  }

  onDeleteButtonClicked = (button) => {
    if (!button || !button.attachment || !button.attachment.attachmentId) {
      AppDelegate.openErrorBar();
      return;
    }

    let buttons = [
      {
        name: (JMLanguage.translate("global.yes")),
        handler: () => {
          this.requestRemoveAttachment(button.attachment.attachmentId, button);
        }
      },
      { name: (JMLanguage.translate("global.no")) }
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("component.pm-job-attachment.confirm-delete-attached"), "", buttons);
  }

  // ----------- Init Component ----------- //
  initUploadPreviewer() {
    this.uploadPreviewParam = {
      isDisabledAddAttachment: !this.hasEditPermission,
      hasEditPermission: this.hasEditPermission,
      userInput: {},
      customClass:'fa fa-plus blue',
      onSubmitAttachment: this.onClickSubmitAttachment
    }
  }

  // ----------- Tablex UI function ----------- //
  onRowClicked = (index: number, row: any) => {
    this.selectedAttachment = this.attachmentList[index];
    this.requestGetFile(this.selectedAttachment.attachmentId);
  }

  renderTable() {
    this.tablexParam.content = this.attachmentList.map(attachment => {
      let buttons = [
        { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
      ];
      if (this.hasEditPermission) {
        buttons = [
          { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
          { "id": "delete-button_" + attachment._id, "name": "", "class": "delete-button btn p-1", "icon": "fas fa-times font-size-l", "onClicked": this.onDeleteButtonClicked, "attachment": attachment }
        ];
      }
      return [
        attachment._id,
        attachment.description,
        buttons
      ];
    });
  }

  // ------ Tablex ------ //
  initTable() {
    this.tablexParam = {
      isLoadingTable: true,
      tableRow: "row",
      tableClass: "pm-job-attachment-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: false,
      enablePagination: false,
      onRowClicked: this.onRowClicked,
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.attachment.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 justify-content-around"
        }
      ],
      content: []
    };
  }
  // ------ Tablex End ------ //
}
