<div class="contract-form-wrap">

  <div class="title-wrap row justify-content-between mx-0 mb-4">
    <h3 class="d-inline">{{ uiTitle }}</h3>
    <div class="button-wrap text-right">
      <app-submit-button 
        [id]="'dummy_contract_save_button'"
        [text]="'global.submit'"
        [buttonClass]="'d-block standard brand-amber mr-0'"
        [iconClass]="'fa-save'"
        [isDisabled]="!uiEnableSubmitButton"
        [isLoading]="!uiEnableSubmitButton"
        (onClick)="onSubmitButtonClicked($event)" 
      ></app-submit-button>
    </div>
  </div>
  
  <div>{{ 'add-contractor-team-member-form.title.member-name' | translate }} <span class="text-danger">{{ uiErrorName }}</span></div>
  <input id="id_member_name"
    name="uiMemberName"
    type="text"
    class="form-control"
    [(ngModel)]="uiMemberName"
  >
  <br/>

  <div>{{ 'add-contractor-team-member-form.title.phone' | translate }} <span class="text-danger">{{ uiErrorPhone }}</span></div>
  <input id="id_phone"
    name="uiPhone"
    type="text"
    class="form-control"
    [(ngModel)]="uiPhone"
  >
  <br/>

  <div>{{ 'add-contractor-team-member-form.title.sms' | translate }} <span class="text-danger">{{ uiErrorFax }}</span></div>
  <input id="id_sms"
    name="uiSMS"
    type="text"
    class="form-control"
    [(ngModel)]="uiSMS"
  >
  <br/>

  <div>{{ 'add-contractor-team-member-form.title.fax' | translate }} <span class="text-danger">{{ uiErrorFax }}</span></div>
  <input id="id_fax"
    name="uiFax"
    type="text"
    class="form-control"
    [(ngModel)]="uiFax"
  >
  <br/>

  <div>{{ 'add-contractor-team-member-form.title.email' | translate }} <span class="text-danger">{{ uiErrorEmail }}</span></div>
  <input id="id_email"
    name="uiEmail"
    type="text"
    class="form-control"
    [(ngModel)]="uiEmail"
  >
  <br/>


        
  
</div>

