import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import * as _ from 'underscore';
import { Session } from '@services/session';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationService } from '@services/authorization.service';
import { pad,ngbDateToString,ngbdatestructToDate } from 'src/lib/presenter/Formatter';

@Component({
  selector: 'app-jobcard-job-list-adv-search',
  templateUrl: './jobcard-job-list-adv-search.component.html',
  styleUrls: ['./jobcard-job-list-adv-search.component.scss']
})
export class JobcardJobListAdvSearchComponent implements OnInit {

  @ViewChild("startDateElem", { static: false }) startDateElem;
  @ViewChild("endDateElem", { static: false }) endDateElem;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  //Services
  authorizationService: AuthorizationService;

  isSearching: boolean = false;
  isCollapse: boolean = true;
  isCreationPeriodError: boolean = false;
  showCreationTimeErrorMsg:boolean = false;
  isDisabled: boolean = false; //For Not Ready Function
  ngbCreateFrom:string
  ngbCreateTo:string
  //ENUM for HTML
  HandlingParty = JMENUM.HandlingParty;
  Priority = JMENUM.JMPriority;
  TecoStatus = JMENUM.TecoStatus;
  JobCardStatus = JMENUM.JobCardStatus;
  JobCardSummaryRequest: JM.JMRequestJobCardsJobCardSummary;

  orderTypeOptions: any[];
  matTypeOptions: any[];
  tecoStatusOptions: any[];
  jobCardStatusOptions: any[];

  timeAlertMsg: string = undefined;
  dateAlertMsg: string = undefined;

  searchSelections: {
    jobCardNumber: string;
    handlingParty: { [key: string]: boolean };
    priority: { [key: number]: boolean };
    tecoStatus: { [key: number]: boolean };
    ccsServiceOrderNumber: string;
    orderType: any;
    matType: any;
    client: string;
    equipment: string;
    officerInCharge: string
    location: string;
    jobCardStatus: { [key: number]: boolean };
    createTimeFrom: NgbDateStruct;
    createTimeTo: NgbDateStruct;
  };

  workCentreOptions: {
    list?: string[]
    isLoading?: boolean
  } = {}

  // Location Dropdown
  private searchLocationObserver = new Subject<any[]>();
  locationOptions: any = [];
  searchLocationKeyword: string = null;
  locationPageSize: number = 100;
  locationPageNumber: number = 1;
  locationTotalPage: number;
  isLoadingLocation: boolean = false;


  ngOnInit(): void {
    this.timeAlertMsg = JMLanguage.translate("global.invalid-time");
    this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
    this.initDefaultFilters();
    this.loadFilterOptionsFromLocal();
    this.fetchData();

    this.searchLocationObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.searchLocations()
    }
    );

  }

  ngOnDestroy(): void {

  }

  // ----------- API ----------- //

  async searchLocations() {
    this.locationOptions = [];
    this.locationPageNumber = 1;
    this.locationTotalPage = null;
    this.locationOptions = await this.requestLocationList(this.locationPageNumber);

  }
  async onLocationScrollToEnd() {
    if (this.locationPageNumber < this.locationTotalPage) {
      this.locationPageNumber++;

      let locations = await this.requestLocationList(this.locationPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.locationOptions = [...this.locationOptions, ...locations];
    }
  }

  private async requestLocationList(pageNumber?: number) {
    const request = new JM.JMRequestLocationsLocationSummary();

    request.locationOnly = true;
    request.parameters = ['code', 'description'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.locationPageSize;

    if (this.searchLocationKeyword) {
      request.locationDescription = this.searchLocationKeyword;
    }

    this.isLoadingLocation = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingLocation = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.locationTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((location) => {
      const lang = Session.selectedLanguage;
      const description = location.description[lang] ? location.description[lang] : location.description.en;
      return {
        value: location.code,
        label: `${description} (${location.code})`,
      };
    });
  }
  // ----------- UI function ----------- //
  private initDefaultFilters() {
    this.searchSelections = {
      jobCardNumber: null,
      handlingParty: {},
      ccsServiceOrderNumber: null,
      priority: {},
      tecoStatus: {},
      jobCardStatus: {},
      matType: null,
      client: null,
      equipment: null,
      orderType: null,
      location: null,
      officerInCharge: null,
      createTimeFrom: null,
      createTimeTo: null,
    };
    //handlingParty
    let handlingPartyEnum = Object.values(this.HandlingParty);
    for (let i = handlingPartyEnum.length / 2; i < handlingPartyEnum.length; i++) {
      this.searchSelections.handlingParty[handlingPartyEnum[i]] = false;
    }

    //priority
    let priorityEnum = Object.values(this.Priority);
    for (let i = priorityEnum.length / 2; i < priorityEnum.length; i++) {
      this.searchSelections.priority[priorityEnum[i]] = false;
    }
    //tecoStatus
    let tecoStatusEnum = Object.values(this.TecoStatus);
    for (let i = tecoStatusEnum.length / 2; i < tecoStatusEnum.length; i++) {
      this.searchSelections.tecoStatus[tecoStatusEnum[i]] = false;
    }
    //job card status
    let jobCardStatusEnum = Object.values(this.JobCardStatus);
    for (let i = jobCardStatusEnum.length / 2; i < jobCardStatusEnum.length; i++) {
      this.searchSelections.jobCardStatus[jobCardStatusEnum[i]] = false;
    }
    //Job Type
    let orderTypeEnum = Object.values(JMENUM.JobType);
    this.orderTypeOptions = [];
    for (let i = 0; i < orderTypeEnum.length; i++) {
      this.orderTypeOptions.push({ value: orderTypeEnum[i], label: orderTypeEnum[i] });
    }
    //mat Type
    let matTypeEnum = Object.values(JMENUM.Mat);
    this.matTypeOptions = [];
    for (let i = 0; i < matTypeEnum.length; i++) {
      this.matTypeOptions.push({ value: matTypeEnum[i], label: matTypeEnum[i] });
    }
    //error
    this.isCreationPeriodError = false;
    this.showCreationTimeErrorMsg = false;
  }

  fetchData(pageNumber?: number, pageSize?: number) {
    
    this.JobCardSummaryRequest = new JM.JMRequestJobCardsJobCardSummary();
    this.JobCardSummaryRequest.filter = {};
    this.JobCardSummaryRequest.filter['jobCardNumber'] = this.searchSelections.jobCardNumber ? this.searchSelections.jobCardNumber : undefined;
    let handlingParty = Object.keys(this.searchSelections.handlingParty).filter(party => this.searchSelections.handlingParty[party]);
    this.JobCardSummaryRequest.handlingPartyList = handlingParty.length > 0 ? handlingParty : undefined
    this.JobCardSummaryRequest.filter['ccsServiceOrderNumber'] = this.searchSelections.ccsServiceOrderNumber ? this.searchSelections.ccsServiceOrderNumber : undefined;
    this.JobCardSummaryRequest.orderTypeList = (this.searchSelections.orderType && this.searchSelections.orderType.length > 0) ? [this.searchSelections.orderType] : undefined;
    this.JobCardSummaryRequest.matTypeList = (this.searchSelections.matType && this.searchSelections.matType.length > 0) ? [this.searchSelections.matType] : undefined;
    this.JobCardSummaryRequest.filter['client'] = this.searchSelections.client ? this.searchSelections.client : undefined;
    this.JobCardSummaryRequest.locationList = !!this.searchSelections.location ? [this.searchSelections.location] : undefined;
    this.JobCardSummaryRequest.filter['equipment'] = this.searchSelections.equipment ? this.searchSelections.equipment : undefined;
    let priority = Object.keys(this.searchSelections.priority).filter(priority => this.searchSelections.priority[priority]).map(key => parseInt(key));
    this.JobCardSummaryRequest.priorityList = priority.length > 0 ? priority : undefined
    let tecoStatus = Object.keys(this.searchSelections.tecoStatus).filter(tecoStatus => this.searchSelections.tecoStatus[tecoStatus]).map(key => parseInt(key));
    this.JobCardSummaryRequest.tecoStatusList = tecoStatus.length > 0 ? tecoStatus : undefined
    let jobCardStatus = Object.keys(this.searchSelections.jobCardStatus).filter(jobCardStatus => this.searchSelections.jobCardStatus[jobCardStatus]).map(key => parseInt(key));
    this.JobCardSummaryRequest.statusList = jobCardStatus.length > 0 ? jobCardStatus : undefined
    this.JobCardSummaryRequest.filter['officerInCharge'] = this.searchSelections.officerInCharge ? this.searchSelections.officerInCharge : undefined;
    
    const fromDate = this.searchSelections.createTimeFrom;
    const toDate = this.searchSelections.createTimeTo;
    if (ngbdatestructToDate(toDate) != null && ngbdatestructToDate(fromDate) != null) {
      if (ngbdatestructToDate(fromDate).getTime() > ngbdatestructToDate(toDate).getTime()) {
        this.isCreationPeriodError = true;
        this.showCreationTimeErrorMsg = true;
        return;
      }
    }
    const createTimeFrom = this.parseDateCreateTimeFromToRequestFormat(fromDate);
    const createTimeTo = this.parseDateCreateTimeToToRequestFormat(toDate);
    createTimeFrom && (this.JobCardSummaryRequest.createTimeFrom = createTimeFrom);
    createTimeTo && (this.JobCardSummaryRequest.createTimeTo = createTimeTo);

    this.saveFilterOptionsToLocal();
    
    this.onSearch.emit({
      advancedSearch: this.JobCardSummaryRequest,
      pageNumber,
      pageSize,
    });
  }
  onSearchLocation(event) {
    this.searchLocationKeyword = event.term;
    this.searchLocationObserver.next();
  }
  clearLocationFilter() {
    this.searchLocationKeyword = null;
    this.searchLocations();
  }

  onClickSearch() {
    this.fetchData(1, null);
  }

  onClickClear() {
    //reset
    this.initDefaultFilters();
    this.fetchData(1, null);
  }

  validateTimePeriod(fromNgbDate?: String, toNgbDate?: String) {
    if (fromNgbDate && toNgbDate) {
      return fromNgbDate > toNgbDate ? true : false;
    }
    return true;
  }

  onBlurDateInput(event){
    this.isCreationPeriodError = false;

    if (event.field == 'startDate') {
      this.searchSelections.createTimeFrom = event.data;
      this.ngbCreateFrom = ngbDateToString(event.data)
    } else if (event.field == 'endDate') {
      this.searchSelections.createTimeTo = event.data;
      this.ngbCreateTo = ngbDateToString(event.data)
    }
    const valid = this.validateTimePeriod(this.ngbCreateFrom, this.ngbCreateTo);
    this.showCreationTimeErrorMsg = !valid;
  }

  private parseDateCreateTimeFromToRequestFormat = (dateData: NgbDateStruct): Date => {
    try {
      let date = new Date(dateData.year + '-' + pad(dateData.month) + '-' + pad(dateData.day) + "T00:00:00.000Z");
      return date;
    } catch (e) {
      return null;
    }
  }

  private parseDateCreateTimeToToRequestFormat = (dateData: NgbDateStruct): Date => {
    try {
      let date = new Date(dateData.year + '-' + pad(dateData.month) + '-' + pad(dateData.day) + "T23:59:59.999Z");
      return date;
    } catch (e) {
      return null;
    }
  }

  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      delete cache.frequencyOptions;
      Session.setJobListSettingsFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  }
  private loadFilterOptionsFromLocal = (): void => {
    try {
      let cache = Session.jobListSettingsFilters;
      if (cache) {
        let cacheParsed = JSON.parse(JSON.stringify(cache));
        this.searchSelections = {
          ...this.searchSelections,
          ...cacheParsed,
        };
        if (cacheParsed.location) {
          this.searchLocationKeyword = cacheParsed.location;
          this.searchLocations().then(() => {
            this.searchSelections.location = this.searchLocationKeyword
          })
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

}
