<div class="header">
    <h3>{{ "pages.sn.slider-panel.reject-job-card" | translate }}</h3>
</div>
<div class="reason-drop-down">
    <ng-select bindLabel="description" bindValue="value" [items]="options"
        [(ngModel)]="reason" placeholder="{{ 'pages.sn.slider-panel.reject-job-card-reason' | translate}}" [closeOnSelect]="true" [disabled]="isLoading">
    </ng-select>
</div>
<div>
    <textarea maxlength="{{ getMaxRemarksLength }}" type="text" rows="5" placeholder="{{ 'pages.sn.remark' | translate }}" [(ngModel)]="remark" [disabled]="isLoading"></textarea>
    <span 
        *ngIf="remark?.length >= getMaxRemarksLength" 
        class="red small"
    >
        {{ "pages.sn.slider-panel.reject-job-card.max-length" | translate:[getMaxRemarksLength]}}
    </span>
</div>
<div class="submit">
    <button class="standard brand-amber" (click)="onClickSubmit()" [disabled]="!reason || isLoading">
        <span *ngIf="isLoading">
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
        </span>
        <span *ngIf="!isLoading"><i class="fas fa-check space"></i>{{'global.submit' | translate}}</span>
    </button>
</div>