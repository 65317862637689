import { Component, Injector, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-env-warning',
  templateUrl: './env-warning.component.html',
  styleUrls: ['./env-warning.component.scss']
})
export class EnvWarningComponent implements OnInit {

  public isProduction: boolean;

  constructor() {
    this.isProduction = environment.production;
  }

  ngOnInit() {
  }

}
