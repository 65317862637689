import { PDFDocument } from 'pdf-lib';
import * as moment from 'moment';
export const fileType = {
  arrayBuffer: 0,
  binaryString: 1,
  dataUrl: 2,
  text:3
}

export function base64ToBlob(base64, contentType){
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: contentType});
}

export const removeBase64Header = (fileStr:string):string =>{
  return fileStr.split('base64,').pop();
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(file);
  reader.onerror = reject;
});

export const blobToBinary = (blob, type) =>{
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onerror = () => {
      reject(new DOMException())
    }

    fr.onloadend = () => {
      resolve(fr.result)
    }
    
    switch(type) {
      case fileType.arrayBuffer:
        return fr.readAsArrayBuffer(blob)
      case fileType.binaryString:
        return fr.readAsBinaryString(blob)
      case fileType.dataUrl:
        return fr.readAsDataURL(blob)
      case fileType.text:
        return fr.readAsText(blob)
    }

  })
} 

// input pdf in arrayBuffer => output as 2 pdf
export const splitPdfByPage = async (pdfBytes, selectedPages) => {
  try {
    // source pdf
    let source = await PDFDocument.load(pdfBytes)
    //get maxPage
    let maxPage = source.getPageCount()
    // init [] by page => idx + 1  [(n) ... 1, ,2 , 3]  selected = 2
    let totalPage = Array.from(Array(maxPage).keys())
    // get the rest of the pages
    //let otherPages = totalPage.filter(x => !selectedPages.includes(x))
    // creating two blank pdf 
    const blank1 = await PDFDocument.create() 
    const blank2 = await PDFDocument.create() 
    // copy 
    const selectedPagesCopy = await blank1.copyPages(source, selectedPages)
    const otherPagesCopy = await blank2.copyPages(source, totalPage)
    // add to blanks
    for(let page of selectedPagesCopy){ blank1.addPage(page) }
    for(let page of otherPagesCopy){ blank2.addPage(page) }
    // save & return
     
    return  [await blank1.save(), await blank2.save()]

  } catch (e) {
    throw e
  } 
}

export const splitPdfWithSelectedPages = async (pdfBytes, pages : []) => {
  try{
     // source pdf
     let source = await PDFDocument.load(pdfBytes)
     let results = []

    for (let i = 0; i < pages.length; i++){
      const blank = await PDFDocument.create()
      const selectedPageCopy = await blank.copyPages(source, [pages[i] - 1])
      for(let page of selectedPageCopy){ blank.addPage(page)}
      let pdfpage = await blank.save()
      results.push(pdfpage)
    }

    return results

  } catch (e) {
    throw e
  }

}

export const splitAllPdfs = async (pdfBytes) => {
  try{
    // source pdf
    let source = await PDFDocument.load(pdfBytes)
    const { length } = source.getPages();
    let result = []

    for (let i = 0, n=length; i<n; i++){
      const writePdf = await PDFDocument.create();
      const [page] = await writePdf.copyPages(source, [i]);
      writePdf.addPage(page)
      result.push(await writePdf.save())
    }
    return result

  } catch(e){
    throw e
  }
  
}

export const getPdfTotalPage = async (pdfBytes) => {
  try {
      let source = await PDFDocument.load(pdfBytes)
      return source.getPageCount()
       
      
  } catch (e) {
    throw e
  } 
}

export const isEnabledFeature = (session,key): boolean => {
  if(session.featureList == undefined || session.featureList == null) return false
  const feature = session.featureList.find(feature => feature.key == key);
  return feature && feature.enabled;
}

export const capitalizeWord = (word): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const sortAttachmentsByDate = (toSortAttachment: any[], isDesc = true): any[] => {
  return toSortAttachment.sort((a: any, b: any): number => {
    const aTime = moment(a.uploadedAt);
    const bTime = moment(b.uploadedAt);
    if(aTime && bTime) {
      if (isDesc) {
        return aTime.isAfter(bTime) ? -1 : 1;
      } else {
        return aTime.isAfter(bTime) ? 1 : -1;
      }
    } else {
      return 0;
    }
  });
}