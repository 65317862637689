<div class="row">
    <div class="col-9">
        <span class="title">{{'ha-equipment-list-panel.component.title' | translate}}</span>
    </div>
    <div class="col-3">
        <app-submit-button class="float-right" [id]="'ha_equipment_search_button'" [text]="'global.submit'"
            [buttonClass]="'standard brand-amber'" [iconClass]="'fas fa-search space'" (onClick)="onSubmitBtnClicked()">
        </app-submit-button>
    </div>
    <div class="col-12">
        <app-tablex id='ha-equipment-list-table' #haEquipmentListTable [tablexParam]='tablexParam' class='mt-3'></app-tablex>
    </div>
</div>