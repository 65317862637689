import { Injectable } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  async requestContractList(option : { pageNumber: number, pageSize: number, parameters?: string[], filter? }) {
    const request = new JM.JMRequestContractsGetContractList();
    option && option.pageSize && (request.pageSize = option.pageSize);
    option && option.pageNumber && (request.pageNumber = option.pageNumber);
    if (option && option.filter) {
      request.filter = {
        ...option.filter
      };
    }
    if (option && option.parameters) {
      request.parameters = option.parameters
    }

    const response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response)
      return;
    }
    return response.payload;
  }
}
