import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationMode } from '@enum/authenticationMode';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GetPostRequest } from 'src/app/api/authorization/get-post-request';
import { GetRoleAllRequest } from 'src/app/api/authorization/get-role-all-request';
import { PostPostSummaryRequest } from 'src/app/api/authorization/post-post-summary-request';
import { Authorizations, PostPostsCreateRequest, PostPostsUpdateRequest } from 'src/app/api/authorization/post-posts-create-update-request';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import { PasswordErrorListComponent } from 'src/app/ui/components/password-error-list/password-error-list.component';

import * as _ from 'underscore';

@Component({
  selector: 'app-user-management-users',
  templateUrl: './user-management-users.component.html',
  styleUrls: ['./user-management-users.component.scss']
})
export class UserManagementUsersComponent extends BasePage implements OnInit {
  @ViewChild("id_user_table", {static: false}) userTable;
  @ViewChild('passwordErrorList', { static: false }) passwordErrorList: PasswordErrorListComponent;

  ///------------
  mandatoryFields: string[] = [];
  errorFields: string[] = [];
  tablexParam             : {} = {};  // isLoadingTable
  currentPageSize         = 10;
  currentPage             = 1;
  pageCount               = 1;
  totalUserCount          = 0;

  // filter
  private searchTerms     = new Subject<any[]>();
  filterCriteria          : {} = {};
  needUpdate              : boolean;
  
  userList                : any; // the result of requestUserList
  roleOptions             : any[] = [];
  workCentreOptions       : any[] = [];
  mode                    : 'create' | 'edit' = 'create';
  isWorkCentreDisabled    = false; // if role ID contain admin -> do not display work centre 
  isSaving                = false;
  isLoadingUserInfo       = false;
  errorMessage            = '';
  selectedUser            = null;
  roleDetailMap           : any = {};
  
  hasCreatePermission     : boolean = false;
  hasEditionPermission    : boolean = false;
  showPasswordError       : boolean = false;
  userForm = this.fb.group({
    phone                 : "",
    mobile                : "",
    fax                   : "",
    email                 : "",
    name                  : "",
    post                  : "",
    code                  : "",
    password              : "",
    confirmPassword       : "",
    authenticationMode    : AuthenticationMode.LDAP,
    roles                 : [],
    isActive              : true,
    workCenters           : []
  });

  authenticationModeOptions: any[] = [
    { displayName   : 'LN', value: AuthenticationMode.LDAP },
    { displayName   : 'Local', value: AuthenticationMode.LOCAL }
  ];
  
  constructor(
    injector: Injector,
    private fb: FormBuilder
  ) {
    super(injector);
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.checkViewPermission(JMENUM.Permission.USER_SETTING_VIEW);
    this.hasEditionPermission = this.authorizationService.hasPermission(JMENUM.Permission.USER_SETTING_UPDATE);
    this.hasCreatePermission = this.authorizationService.hasPermission(JMENUM.Permission.USER_SETTING_CREATE);
    this.workCentreOptions = JM.JMConnector.getAllWorkCentreCode();
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      enableStickyHeader    : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      onRowClicked          : this.onRowClicked,
      onFilterKeyUp         : this.onFilterKeyUp,
      headers: [
        {
          id: "name",
          name: this.translate("pages.user-management-user.table-header.post"),
          enableFilter: true,
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id: "code",
          name: this.translate("pages.user-management-user.table-header.post_code"),
          enableFilter: true,
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id: "role",
          name: this.translate("pages.user-management-user.table-header.role"),
          enableFilter: false,
          type: TablexColumnType.MultiLine,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id: "isActive",
          name: this.translate("pages.user-management-user.table-header.account_status"),
          enableFilter: false,
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
      ],
      content: []
    };

    this.searchTerms.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      // distinctUntilChanged(),
    ).subscribe((header) => {
      this.onFilterChanged(header);
    });

    this.initUserForm();

    this.requestUserList();
    this.requestRoleOptions();
    this.requestRoleList();
    this.setMandatoryFields();
    // this.requestWorkCentreOptions();
  }

  ngOnDestroy() {
  }

  onLangChange() {
    this.renderTable();
  }

  //---------------------------------------------------------------------------
  // view functions
  private initUserForm() {
    this.resetUserForm();
  }

  private resetUserForm() {
    this.mode = 'create';    
    this.userForm.reset({
      phone                 : "",
      mobile                : "",
      fax                   : "",
      email                 : "",
      name                  : "",
      post                  : "",
      code                  : "",
      password              : "",
      confirmPassword       : "",
      authenticationMode    : AuthenticationMode.LDAP,
      roles                 : [],
      isActive              : true,
      workCenters           : []
    });
    this.updateUserFormMode();
  }
  
  setMandatoryFields() {
    this.mandatoryFields = []
    let authenticationMode = this.userForm.get('authenticationMode').value;
    switch(this.mode){
      case 'create':
        this.mandatoryFields.push('authenticationMode')
        this.mandatoryFields.push('post')
        this.mandatoryFields.push('roles')
        if (authenticationMode == JMENUM.AuthenticationMode.LOCAL) {
          this.mandatoryFields.push('password')
          this.mandatoryFields.push('confirmPassword')
          if (this.userForm.get('code').value != null && this.userForm.get('code').value.length > 0) {
            this.mandatoryFields.push('code')
          }
          else if(this.userForm.get('mobile').value != null && this.userForm.get('mobile').value.length > 0 &&
          this.userForm.get('email').value != null && this.userForm.get('email').value.length > 0){
            this.mandatoryFields.push('mobile')
            this.mandatoryFields.push('email')
          }
          else{
            this.mandatoryFields.push('code')
            this.mandatoryFields.push('mobile')
            this.mandatoryFields.push('email')
          }
        }
        break;
      case 'edit':
        this.mandatoryFields.push('authenticationMode')
        this.mandatoryFields.push('post')
        this.mandatoryFields.push('roles')
        if (authenticationMode == JMENUM.AuthenticationMode.LOCAL) {
          if (this.userForm.get('password').value != null && this.userForm.get('password').value.length > 0) {
            this.mandatoryFields.push('confirmPassword')
          }
          if (this.userForm.get('code').value != null && this.userForm.get('code').value.length > 0) {
            this.mandatoryFields.push('code')
          }
          else if(this.userForm.get('mobile').value != null && this.userForm.get('mobile').value.length > 0 &&
          this.userForm.get('email').value != null && this.userForm.get('email').value.length > 0){
            this.mandatoryFields.push('mobile')
            this.mandatoryFields.push('email')
          }
          else{
            this.mandatoryFields.push('code')
            this.mandatoryFields.push('mobile')
            this.mandatoryFields.push('email')
          }
        }
        break;
      default:
        break  
    }
  }

  private updateUserFormMode() {
    if (this.userForm.get('authenticationMode').value == AuthenticationMode.LDAP) {
      if (this.mode == 'create') {
        this.userForm.controls['post'].enable();
        this.userForm.controls['name'].disable();
        this.userForm.controls['password'].disable();
        this.userForm.controls['confirmPassword'].disable();
        this.userForm.controls['code'].disable();
        this.userForm.controls['phone'].disable();
        this.userForm.controls['mobile'].disable();
        this.userForm.controls['fax'].disable();
        this.userForm.controls['email'].disable();
        this.userForm.controls['roles'].enable();
        this.userForm.controls['workCenters'].enable();
        this.userForm.controls['isActive'].enable();
      } else {
        if (this.hasEditionPermission) {
          this.userForm.controls['post'].disable();
          this.userForm.controls['name'].disable();
          this.userForm.controls['password'].disable();
          this.userForm.controls['confirmPassword'].disable();
          this.userForm.controls['code'].disable();
          this.userForm.controls['phone'].disable();
          this.userForm.controls['mobile'].disable();
          this.userForm.controls['fax'].disable();
          this.userForm.controls['email'].disable();
          this.userForm.controls['roles'].enable();
          this.userForm.controls['workCenters'].enable();
          this.userForm.controls['isActive'].enable();
        } else {
          this.userForm.controls['post'].disable();
          this.userForm.controls['name'].disable();
          this.userForm.controls['password'].disable();
          this.userForm.controls['confirmPassword'].disable();
          this.userForm.controls['code'].disable();
          this.userForm.controls['phone'].disable();
          this.userForm.controls['mobile'].disable();
          this.userForm.controls['fax'].disable();
          this.userForm.controls['email'].disable();
          this.userForm.controls['roles'].disable();
          this.userForm.controls['workCenters'].disable();
          this.userForm.controls['isActive'].disable();
          this.userForm.controls['authenticationMode'].disable();
        }

      }
    } else {
      if (this.mode == 'create') {
        this.userForm.controls['post'].enable();
        this.userForm.controls['name'].enable();
        this.userForm.controls['password'].enable();
        this.userForm.controls['confirmPassword'].enable();
        this.userForm.controls['code'].enable();
        this.userForm.controls['phone'].enable();
        this.userForm.controls['mobile'].enable();
        this.userForm.controls['fax'].enable();
        this.userForm.controls['email'].enable();
        this.userForm.controls['roles'].enable();
        this.userForm.controls['workCenters'].enable();
        this.userForm.controls['isActive'].enable();
      } else {
        if (this.hasEditionPermission) {
          this.userForm.controls['post'].disable();
          this.userForm.controls['name'].enable();
          this.userForm.controls['password'].enable();
          this.userForm.controls['confirmPassword'].enable();
          this.userForm.controls['code'].enable();
          this.userForm.controls['phone'].enable();
          this.userForm.controls['mobile'].enable();
          this.userForm.controls['fax'].enable();
          this.userForm.controls['email'].enable();
          this.userForm.controls['roles'].enable();
          this.userForm.controls['workCenters'].enable();
          this.userForm.controls['isActive'].enable();
        } else {
          this.userForm.controls['post'].disable();
          this.userForm.controls['name'].disable();
          this.userForm.controls['password'].disable();
          this.userForm.controls['confirmPassword'].disable();
          this.userForm.controls['code'].disable();
          this.userForm.controls['phone'].disable();
          this.userForm.controls['mobile'].disable();
          this.userForm.controls['fax'].disable();
          this.userForm.controls['email'].disable();
          this.userForm.controls['roles'].disable();
          this.userForm.controls['workCenters'].disable();
          this.userForm.controls['isActive'].disable();
          this.userForm.controls['authenticationMode'].disable();
        }

      }
    }

    if (this.userForm.get('code').value && this.userForm.get('code').value.length > 0) {
      this.userForm.controls['name'].disable();
      this.userForm.controls['phone'].disable();
      this.userForm.controls['mobile'].disable();
      this.userForm.controls['fax'].disable();
      this.userForm.controls['email'].disable();
    }

    if (this.userForm.controls['workCenters'].enabled) {
      // update work centre based on role
      // if role contains authorizationAll, he has all workcenter permission
      let selectedRoles = this.userForm.get('roles').value;
      this.isWorkCentreDisabled = false;
      if (selectedRoles) {
        for (let userRole of selectedRoles) {
          for (let role of this.roleOptions) {
            if (role.roleId == userRole && role.permissions.includes(JMENUM.Permission.AUTHORIZATION_ALL)) {
              this.isWorkCentreDisabled = true;
              break;
            }
          }
        }
      }

      if (this.isWorkCentreDisabled) {
        this.userForm.patchValue({ workCenters: [] });
        this.userForm.controls['workCenters'].disable();
      } else {
        if (this.selectedUser && this.selectedUser.authorizations && this.selectedUser.authorizations.workCenters) {
          let workCenters = this.selectedUser.authorizations.workCenters;
          this.userForm.patchValue({ workCenters: workCenters });
        }
        this.userForm.controls['workCenters'].enable();
      }
      // end of role update
    }

  }

  private renderTable() {
    this.tablexParam['pageCount'] = Math.ceil(this.totalUserCount/this.currentPageSize);
    this.tablexParam['content'] = this.userList && this.userList.map(user => {
      return [
        user.name,
        user.code,
        user.roles.map(role => {
          if (!role.roleId || !this.roleDetailMap[role.roleId]) { return ''; }
          return this.roleDetailMap[role.roleId].name;
          // return this.translate('pages.admin.role.'+role.roleId);
         }),
        (user.isActive ? JMLanguage.translate("global.active") : JMLanguage.translate("global.inactive")),
      ];
     });
  }
  private updateUserFormValue() {
    // must be edit
    this.mode = 'edit';
    this.updateUserFormMode();

    let roles         = [];
    let workCentres   = [];
    
    for( let role of this.selectedUser.roles) {
      roles.push(role['roleId']);
    }

    if (this.selectedUser.permissions.includes(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.isWorkCentreDisabled   = true;
      workCentres                 = [];
    } else {
      this.isWorkCentreDisabled   = false;
      workCentres                 = this.selectedUser.authorizations.workCenters;
    }

    this.userForm.reset({
      phone                 :  this.selectedUser.phone,
      mobile                :  this.selectedUser.mobile,
      fax                   :  this.selectedUser.fax,
      email                 :  this.selectedUser.email,
      post                  :  this.selectedUser.name,
      name                  :  this.selectedUser.employeeName,
      code                  :  this.selectedUser.code,
      password              :  "",
      confirmPassword       :  "",
      authenticationMode    :  this.selectedUser.authenticationMode,
      roles                 :  roles,
      isActive              :  this.selectedUser.isActive,
      workCenters           :  workCentres
    })
  }

  //---------------------------------------------------------------------------
  // api function
  requestUserList(): void {
    let request                           = {} as PostPostSummaryRequest;
    request.systemName                    = 'CCEPJM';
    request.active                        = 'both';
    request.filter                        = this.filterCriteria;
    _.extend(request, {
      pageSize                            : this.currentPageSize,
      pageNumber                          : this.currentPage
    });
    this.tablexParam['isLoadingTable']    = true;
    
    this.apiHandler(this.authorizationService.postPostSummary(request),
      result => {
        this.tablexParam['isLoadingTable'] = false;

        if (!result || !result.code || result.code != 200) {
          this.totalUserCount = 0;
          this.userList = [];
          this.renderTable();
          this.openErrorBar(result);
          return;
        }
        
        this.totalUserCount = result.payload.totalCount;
        this.userList = result.payload.records;
        this.renderTable();
      }), error => {
        this.tablexParam['isLoadingTable'] = false;
        console.error(error);
        this.openErrorBar(null);
      };
  }

  requestRoleList() {
    let request = new JM.JMRequestAuthRoleSummary();
    request.systemName = [Constants.SYSTEM_NAME];
    request.parameters = ['roleId', 'name'];

    JM.JMConnector.sendAuthRoleSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseAuthRoleSummary) => {
      if (error) {
        console.error(error);
        this.openErrorBar(null);
        return;
      }

      if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records || !response.payload.records.length) {
        this.openErrorBar(response);
        return;
      }

      // get all role detail
      for (let role of response.payload.records) {
        if (!this.roleDetailMap[role.roleId]) {
          this.roleDetailMap[role.roleId] = role;
        }
      }

      this.renderTable();
    });
  }

  requestRoleOptions() {
    let request           = {} as GetRoleAllRequest;
    request.systemName    = 'CCEPJM';
    this.apiHandler(this.authorizationService.getRoleAllBySystemName(request),
      result => {
        this.roleOptions = result;
      });
  }

  requstUser(name: String) {
    let request               = {} as GetPostRequest;
    request.postName          = name;
    request.systemName        = 'CCEPJM';
    
    this.isLoadingUserInfo    = true;
    this.apiHandler(this.authorizationService.getPost(request),
      result => {
        this.isLoadingUserInfo = false;

        if (!result || !result.code || result.code != 200 || !result.payload) {
          this.selectedUser = null
          this.resetUserForm();
          this.openErrorBar(result);
          return;
        }

        this.selectedUser = result.payload;
        this.updateUserFormValue();
        this.updateUserFormMode();
        this.setMandatoryFields();
      }, error => {
        this.isLoadingUserInfo    = false;
        this.selectedUser         = null
        this.resetUserForm();
        this.openErrorBar(null);
      }
    );
  }

  private requestSaveUser = async() => {
      let request = new JM.JMRequestPostsSavePostInMultipleSystems()
      request.targetSystemList           = 
      [
        {
          "systemName": JMENUM.SystemName.CCEPPORTAL,
          "roles": [
            802
          ],
          "isActive": this.userForm.get('isActive').value
        }
      ]
      request.systemName                 = JMENUM.SystemName.CCEPJM;
      request.authenticationMode         = this.userForm.get('authenticationMode').value;
      request.name                       = this.userForm.get('post').value;
      request.isActive                   = this.userForm.get('isActive').value;
      request.roles                      = this.userForm.get('roles').value;
      request.workCentres                = this.userForm.get('workCenters').value; // TODO: add work centre to authorization object

      if (this.userForm.get('authenticationMode').value === AuthenticationMode.LOCAL) {
        request.employeeName             = this.userForm.get('name').value;
        request.code                     = this.userForm.get('code').value;
        request.phone                    = this.userForm.get('phone').value;
        request.mobile                   = this.userForm.get('mobile').value;
        request.fax                      = this.userForm.get('fax').value;
        request.email                    = this.userForm.get('email').value;
        if (this.userForm.get('password').value) {
          request.password                 = this.userForm.get('password').value;
        }

      }

      this.isSaving = true;
      const response: JM.JMResponsePostsSavePostInMultipleSystems = await AppDelegate.sendJMRequest(request);
      this.isSaving = false;
      if (!response || !response.code || response.code != 200|| !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
      this.selectedUser = response.payload;
      this.requestUserList();
      this.updateUserFormValue();
      this.updateUserFormMode();
      this.openSnackBar(this.translate("global.saved"));
    
  }

  //-----------------------------------------------------------------------------
  switchMode(mode, username) {
    this.mode = mode;
    if ( mode === 'create') {
      this.selectedUser = null;
      this.resetUserForm();
    } else { // else edit
      this.requstUser(username);
    }
  }

  private validUserInput(): boolean {
    let name                  = this.userForm.get('post').value;
    let password              = this.userForm.get('password').value;
    let confirmPassword       = this.userForm.get('confirmPassword').value;
    let authenticationMode    = this.userForm.get('authenticationMode').value;
    let roles                 = this.userForm.get('roles').value;
    this.errorFields = [];
    // check mandatory fields
    for (let field of this.mandatoryFields) {
      if (this.userForm.get(field).value == null || this.userForm.get(field).value == '') {
        this.errorFields.push(field);
        continue;
      }
    }
    if (this.errorFields.length) {
      this.openSnackBar(
        this.translate('pages.admin.users.missing-mandatory-fields')
      );
      return false;
    }

    if ((this.mode === 'create' || this.mode === 'edit') && (name === '')) {
      this.errorFields.push('post');
      this.openSnackBar(this.translate('pages.admin.users.incorrect-post'));
      return false;
    }

    if (
      ((this.mode === 'create' && (password === '' || password !== confirmPassword))
      || (this.mode === 'edit' && password !== confirmPassword))
      && authenticationMode != AuthenticationMode.LDAP
    ) {
      this.errorFields.push('password');
      this.errorFields.push('confirmPassword');
      this.openSnackBar(this.translate('pages.admin.users.incorrect-password'));
      return false;
    }

    if ((this.mode === 'create' || this.mode === 'edit') && (authenticationMode === '')) {
      this.errorFields.push('authenticationMode');
      this.openSnackBar(this.translate('pages.admin.users.incorrect-authentication-mode'));
      return false;
    }

    if (!roles || (roles && roles.length == 0)) {
      this.errorFields.push('roles');
      this.openSnackBar(this.translate('pages.admin.users.incorrect-role'));
      return false;
    }

    if (this.userForm.get('password').value != null && this.userForm.get('password').value != "" && this.userForm.get('password').value.length > 0){

      if(!this.passwordErrorList.isPasswordVaildate(this.userForm.get('password').value, name)){
        this.showPasswordError = true;
        return
      }
    }

    return true;
  }

  //-----------------------------------------------------------------------------
  // button call back function
  onRoleChanged(selectedRoles) {
    // this.userForm.patchValue({'roles': selectedRoles});
    this.updateUserFormMode();  // disable workcentre
    // if role contains authorizationAll, he has all workcenter permission
    // this.isWorkCentreDisabled = false;
    // for (let userRole of selectedRoles) {
    //   for ( let role of this.roleOptions) {
    //     if (role.roleId == userRole && role.permissions.includes(Permission.authorizationAll)) {
    //       this.isWorkCentreDisabled = true;
    //       break;
    //     }
    //   }
    // }

    // if (this.isWorkCentreDisabled) {
    //   this.userForm.patchValue({workCenters: []});
    //   this.userForm.controls['workCenters'].disable();
    // } else {
    //   if (this.selectedUser && this.selectedUser.authorizations && this.selectedUser.authorizations.workCenters) {
    //     let workCenters =  this.selectedUser.authorizations.workCenters;
    //     this.userForm.patchValue({workCenters: workCenters});
    //   }
    //   this.userForm.controls['workCenters'].enable();
    // }
  }

  onAuthenticationModeChanged(authenticationMode) {
    this.userForm.patchValue({
      authenticationMode: authenticationMode,
    });
    this.setMandatoryFields();
    this.updateUserFormMode();
    this.resetPasswordClickError();
  }

  onMobileChanged(event: any){
    this.setMandatoryFields()
  }

  onEmailChanged(event: any){
    this.setMandatoryFields()
  }

  onPasswordChanged(event: any){
    this.setMandatoryFields()
  }
  
  onPostCodeChanged(event) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    let postCode = event.target.value;

    if (postCode != undefined && postCode.length > 0) {
      this.userForm.controls['name'].disable();
      this.userForm.controls['phone'].disable();
      this.userForm.controls['mobile'].disable();
      this.userForm.controls['fax'].disable();
      this.userForm.controls['email'].disable();

      // this.userForm.patchValue({
      //   phone   : '',
      //   mobile  : '',
      //   fax     : '',
      //   email   : '',
      // });
    } else {
      this.userForm.controls['name'].enable();
      this.userForm.controls['phone'].enable();
      this.userForm.controls['mobile'].enable();
      this.userForm.controls['fax'].enable();
      this.userForm.controls['email'].enable();
    }
    this.setMandatoryFields();
  }

  onDiscardChangeClicked = () => {
    this.resetPasswordClickError();
    this.switchMode('create', null)
  }

  onCreateUserClicked = () => {
    this.resetPasswordClickError();
    if (this.validUserInput()) {
      let message = this.translate("pages.user-management-user.confirm-create-user", [this.userForm.get('post').value]);
      let buttons = [
        { name: this.translate("global.yes"), handler: this.requestSaveUser },
        { name: this.translate("global.no") }
      ]
      this.showPopUpAlert(message, "", buttons);
    }
  }
  
  private resetPasswordClickError(){
    this.showPasswordError = false
    this.passwordErrorList.resetPasswordErrors();
  }

  onUpdateUserClicked = () =>{
    this.resetPasswordClickError();
    if (this.validUserInput()) {
      let message = this.translate("pages.user-management-user.confirm-update-user", [this.userForm.get('post').value]);
      let buttons = [
        { name: this.translate("global.yes"), handler: this.requestSaveUser },
        { name: this.translate("global.no") }
      ]
      this.showPopUpAlert(message, "", buttons);
    }
  }

  onRowClicked = (index:number, row: any) => {
    let user = this.userList[index];
    this.switchMode('edit', user.name);
    this.resetPasswordClickError();
  }

  // page function
  onPageNumberClicked = (pageNumber) => {
    this.currentPage = parseInt(pageNumber);
    this.requestUserList();
  }

  onPageSizeClicked = (pageSize) => {
    this.currentPage        = 1;
    this.currentPageSize    = parseInt(pageSize);
    this.requestUserList();
  }

  onFilterChanged(header) {
    if (header.value == null || header.value == '') {
      delete this.filterCriteria[header.id];
    } else {
      this.filterCriteria[header.id] = header.value;
    }
    this.currentPage = 1;
    this.requestUserList();    
  }

  onFilterKeyUp = (index, header, newValue) => {
    header['value'] = newValue;

    // fire an event, call onFilterChanged after 0.5 second
    this.searchTerms.next(header);
  }

  // TODO: reload language
  onLanguageChanged() {
    
  }
}
