/*
version 20210707:
- basic tablex config setting
- mock field name for column filter
- translate

not complete:
- real field name waiting for api development
- api migration
*/

import { Component, OnInit, ViewChild } from '@angular/core';
import { TablexColumnFilterOption, TablexColumnFilterType, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Session } from '@services/session';
import * as moment from 'moment';
import { JM, JMENUM, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { JmUserSettings } from '@enum/jm-user-settings.enum';
import { AppDelegate } from 'src/app/AppDelegate';
import { Router } from '@angular/router';
import { AuthorizationService } from '@services/authorization.service';
import { PmPlanSearchCriteriaComponent } from '../../components/pm-plan-search-criteria/pm-plan-search-criteria.component'

@Component({
  selector: 'app-pm-plan-list',
  templateUrl: './pm-plan-list.component.html',
  styleUrls: ['./pm-plan-list.component.scss']
})
export class PmPlanListComponent implements OnInit {
  @ViewChild(PmPlanSearchCriteriaComponent, { static: false }) pmPlanSearchCriteriaEl : PmPlanSearchCriteriaComponent;

  language: string;

  // Table
  tablexFilter: any = {};
  tablexParam: {} = {};
  pageSizeOptions: any = [10, 25, 100];
  pageCount = 1;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];
  statusOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  scheduleOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  coverageOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  frequencyOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  teamOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  workCentreOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  pmPlanList: any = [];
  
  currentPageSize: number = 10;
  currentPageNumber: number = 1;

  // Team Dropdown
  teamList: any = [];
  private searchTeamObserver = new Subject<any[]>();
  searchTeamKeywords: string = null;
  teamPageSize: number = 1000;
  teamPageNumber: number = 0;
  teamTotalCount: number;
  isLoadingTeam: boolean = false;
  
    
  constructor(private router: Router, private authorizationService: AuthorizationService) { }

  async ngOnInit() {
    if (!this.authorizationService.hasPermissions([JMENUM.Permission.PMPLAN_VIEW] , false)) {
      this.router.navigate(['/']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    this.language = Session.selectedLanguage;
    this.initAllTableHeader();
    this.initFilterOptions();
    this.initSelectedCol();
    this.initTablex();

    do {
      await this.requestTeamSummary();
    } while (this.teamPageSize * this.teamPageNumber < this.teamTotalCount);
    this.renderTable();
  }

  // ----------- API ----------- //
  async requestUpdateUserSettings() {
    let userSettings = {};
    const request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[JmUserSettings.PM_PLAN_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
  }

  private async requestPmPlanList(advancedSearch?) {
    this.tablexParam['isLoadingTable'] = true;
    let request: JM.JMRequestGetPmPlanList = new JM.JMRequestGetPmPlanList();
    if(this.tablexFilter){
      request = this.renderRequestFilter(request);
    }
    if(advancedSearch){
      request = advancedSearch;
    }
    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];
    request.pageNumber = this.currentPageNumber;
    request.pageSize = this.currentPageSize;
    
    const response: JM.JMResponseGetPmPlanList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    if (response.payload.records && response.payload.records.length > 0) {
      this.pmPlanList = response.payload.records;
      this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
      this.tablexParam['currentPage'] = request.pageNumber;
      this.renderTable();
    }else{
      this.pmPlanList = [];
      this.renderTable();
      this.tablexParam['pageCount'] = 0;
      this.tablexParam['currentPage'] = 0;
    }
  }

  private async requestTeamSummary(){
    if (this.teamTotalCount !== null && 
      this.teamTotalCount <= this.teamPageNumber * this.teamPageSize) {
      return;
    }
    this.isLoadingTeam = true;
    this.teamPageNumber++;

    const request = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['_id', 'name'];
    request.pageNumber = this.teamPageNumber;
    request.pageSize = this.teamPageSize;

    const response = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;
    if (response) {
      if (response.error) {
        AppDelegate.openErrorBar(response);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
      if(response.payload.records.length > 0){
        let options =  response.payload.records.map(team => {
          let obj = { value: team._id, label: team.name };
          return obj;
        });
        this.teamTotalCount = response.payload.totalCount;
        this.teamOptions.items = this.teamOptions.items.concat(options);
      }
    }
  }

  // ----------- UI function ----------- //
  onAdvancedSearch({advancedSearch, pageNumber, pageSize}){
    if(typeof pageNumber === 'number') {
      this.currentPageNumber = pageNumber;
    }
    if(typeof pageSize === 'number') {
      this.currentPageSize = pageSize;
    }
    this.requestPmPlanList(advancedSearch);
  }

  onClickCreatePMTask(){

  }
  
  initSelectedCol() {
    this.selectedColId = [
      "objId",
      "pmPlanNumber",
      "planDescription",
      "status",
      "scheduleType",
      // "planCoverage",
      "frequency",
      "startDate",
      "endDate",
    ];
    
    this.loadColumnSettingsFromLocal();

    this.onColFiltered(this.selectedColId);
  }

  renderRequestFilter(request){
    request.filter = {};
    
    for (let key in this.tablexFilter) {
      if(this.tablexFilter[key]){
        switch (key){
          // case "status": 
          //   request.statusList = this.tablexFilter[key];
          //   break;
          // case "createdAt": 
          //   request.filter['createdAt'] = moment(this.tablexFilter[key]).format('YYYYMMDD');
          //   break;
          // case "updatedAt": 
          //   request.filter['updatedAt'] = moment(this.tablexFilter[key]).format('YYYYMMDD');
          //   break;
          default : 
            request.filter[key] = this.tablexFilter[key];
            break;
        }
      }
    }
    return request;
  }
 
  // ----------- Tablex UI function ----------- //
  onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();
    this.saveColumnSettingsToLocal();
  }

  onPageSizeClicked = (pageSize: number) => {
    this.pmPlanSearchCriteriaEl.fetchData(1, pageSize);
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.pmPlanSearchCriteriaEl.fetchData(pageIndex, null);
  }

  onSortOrderChanged = (header, sortOrder) => {
    this.tablexParam['sortBy'] = header ? header : 'startDate';// TODO: default sorting 
    this.tablexParam['sortOrder'] = sortOrder;
    this.pmPlanSearchCriteriaEl.fetchData();
  }

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if(!filter[key]){
        delete this.tablexFilter[key];
      }else{
        this.tablexFilter[key] = filter[key];
      }
    }

    this.pmPlanSearchCriteriaEl.fetchData(1, null);
  }

  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue && header.filterDropdownOption.selectedValue.length > 0) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }

    this.pmPlanSearchCriteriaEl.fetchData(1, null);
  }

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.pmPlanSearchCriteriaEl.fetchData(1, null);
  }

  
  onSearchTeamOptions = (event) => {
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver.next();
  }
  
  onTeamScrollToEnd = () => {
    this.requestTeamSummary();
  }

  onFilterTeamOptions = (term: string, item: any) => {
    return item.value.toLowerCase().includes(term.toLowerCase()) || 
            item.descriptionEn.toLowerCase().includes(term.toLowerCase()) || 
            item.descriptionZh.toLowerCase().includes(term.toLowerCase());
  }

  initFilterOptions(){
    this.statusOptions.items = [];
    let statusEnum = Object.values(JMENUM.PMStatus);
    for (let i=0; i < statusEnum.length; i++) {
      let value = statusEnum[i];
      this.statusOptions.items.push({ label: JMLanguage.translate("pm.status." + value), value: value });
    }
    this.statusOptions.bindLabel = "label";
    this.statusOptions.bindValue = "value";
    this.statusOptions.multiple = true;
    this.statusOptions.change = this.onOptionFilterChanged;

    this.scheduleOptions.items = [];
    let scheduleEnum = Object.values(JMENUM.PMScheduleType);
    for (let i=0; i < scheduleEnum.length; i++) {
      let value = scheduleEnum[i];
      this.scheduleOptions.items.push({ label: JMLanguage.translate("pm.schedule-type." + value), value: value });
    }
    this.scheduleOptions.bindLabel = "label";
    this.scheduleOptions.bindValue = "value";
    this.scheduleOptions.multiple = true;
    this.scheduleOptions.change = this.onOptionFilterChanged;

    this.coverageOptions.items = [];
    let coverageEnum = Object.values(JMENUM.PMPlanCoverage);
    for (let i=0; i < coverageEnum.length; i++) {
      let value = coverageEnum[i];
      this.coverageOptions.items.push({ label: JMLanguage.translate("pm.plan-coverage." + value), value: value });
    }
    this.coverageOptions.bindLabel = "label";
    this.coverageOptions.bindValue = "value";
    this.coverageOptions.multiple = true;
    this.coverageOptions.change = this.onOptionFilterChanged;

    this.frequencyOptions.items = [];
    let frequencyEnum = Object.values(JMENUM.PMPlanFrequency);
    for (let i=0; i < frequencyEnum.length; i++) {
      let value = frequencyEnum[i];
      this.frequencyOptions.items.push({ label: JMLanguage.translate("pm.frequency." + value), value: value });
    }
    this.frequencyOptions.bindLabel = "label";
    this.frequencyOptions.bindValue = "value";
    this.frequencyOptions.multiple = true;
    this.frequencyOptions.change = this.onOptionFilterChanged;

    this.teamOptions.items = [];
    this.teamOptions.bindLabel = "label";
    this.teamOptions.bindValue = "value";
    this.teamOptions.change = this.onOptionFilterChanged;
    this.teamOptions.onScrollToEnd = this.onTeamScrollToEnd;
    this.teamOptions.onSearch = this.onSearchTeamOptions;
    this.teamOptions.searchFn = this.onFilterTeamOptions;

    this.workCentreOptions.items = [];
    this.workCentreOptions.bindLabel = "label";
    this.workCentreOptions.bindValue = "value";
    this.workCentreOptions.items = JM.JMConnector.getAllWorkCentreCode().map(workCentre => {
      return { value: workCentre, label: workCentre};
    });
  }

  initAllTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: 'pages.job-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none'
      },
      {
        id: 'pmPlanNumber',
        name: 'pages.pm-plan-list.table-column.pm-plan-no',
        // enableFilter: true,
        enableSort: true,
        type: TablexColumnType.Hyperlink, 
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'planDescription',
        name: 'pages.pm-plan-list.table-column.plan-desc',
        // enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'status',
        name: 'pages.pm-plan-list.table-column.status',
        // enableFilter: true,
        // enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.statusOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'scheduleType',
        name: 'pages.pm-plan-list.table-column.schedule-type',
        // enableFilter: true,
        // enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.scheduleOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      // {
      //   id: 'planCoverage',
      //   name: 'pages.pm-plan-list.table-column.plan-coverage',
      //   // enableFilter: true,
      //   // enableSort: true,
      //   filterType: TablexColumnFilterType.Dropdown,
      //   filterDropdownOption: this.coverageOptions,
      //   showOptionTitle: true,
      //   type: TablexColumnType.Text,
      //   horizontalAlign: TablexColumnHorizontalAlign.Center,
      //   verticalAlign: TablexColumnVerticalAlign.Bottom,
      //   class: 'col th-align-self-start appraisal-status',
      // },
      {
        id: 'frequency',
        name: 'pages.pm-plan-list.table-column.frequency',
        // enableFilter: true,
        // enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.frequencyOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'startDate',
        name: 'pages.pm-plan-list.table-column.start-date',
        // enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'endDate',
        name: 'pages.pm-plan-list.table-column.end-date',
        // enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'teamId',
        name: 'pages.pm-plan-list.table-column.handling-team',
        // enableFilter: true,
        // enableSort: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.teamOptions,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'workCentre',
        name: 'pages.pm-plan-list.table-column.work-centre',
        // enableFilter: true,
        enableSort: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.workCentreOptions,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
    ];
    this.selectedCol = this.allColHeaders;
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      // enableClearFilter: true,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: true,
      // sortOrder: -1,
      // sortBy: 'updatedAt',
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'pm-plan-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPageNumber,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
      customClass: 'expiry',
      sortBy : 'startDate',
      sortOrder : 1
    };
  }

  renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter(col => {
      return this.selectedColId.includes(col.id);
    });

    this.tablexParam['content'] = this.pmPlanList.map((data, index) => {
      let team = this.teamOptions.items.find(team => team['value'] == data.teamId);
      let originRow = [
        data._id,
        data.pmPlanNumber ? {url: '/pm/standard-plan/view/'+data.pmPlanNumber, value: data.pmPlanNumber} : '',
        data.planDescription? data.planDescription : '',
        data.status? JMLanguage.translate('pm.status.' + data.status) : '',
        data.scheduleType? JMLanguage.translate('pm.schedule-type.' + data.scheduleType) : '',
        // data.planCoverage? JMLanguage.translate('pm.plan-coverage.' + data.planCoverage) : '',
        data.frequency? JMLanguage.translate('pm.frequency.' + data.frequency) : '',
        data.startDate? moment(data['startDate']).format(Constants.DATE_FORMAT) : '',
        data.endDate? moment(data['endDate']).format(Constants.DATE_FORMAT) : '',
        team !== null && team !== undefined? team['label'] : JMLanguage.translate(`global.na`),
        data.workCentre? data.workCentre : ''
      ];
      let row = []
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
  }

  // ------------tablex end ------------

  private saveColumnSettingsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.selectedColId));
      Session.setPmPlanSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }
  private loadColumnSettingsFromLocal = () : void => {
    try {
      let cache = Session.pmPlanListSettingsColumns;
      if (cache) {
        this.selectedColId = JSON.parse(JSON.stringify(cache))
      }
    } catch (e) {
      console.warn(e);
    }
  }

}
