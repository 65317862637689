<div>
  <app-custom-fields-form #batch_edit_tags_form class="batch-edit-tags-form-elem" [parameters]="batchEditTagsFormParam" [userInput]="batchEditTagsFormInput" >
  </app-custom-fields-form>
  <div class="row mx-0 justify-content-end mt-5">
    <button *ngIf="isAddMode" class="standard brand-amber col-2" (click)="onClickAddTags()" [disabled]="isLoadingBatchAddBtn || isDisabledBatchAddBtn">
      <div *ngIf="isLoadingBatchAddBtn" class="d-inline-block loading-spinner spinner-small">
        {{ "global.loading" | translate }}
      </div>
      <span *ngIf="!isLoadingBatchAddBtn">
        <i class="fas fa-file-download space"></i>
        {{ 'global.add' | translate }}
      </span>
    </button>

    <button *ngIf="isRemoveMode" class="standard brand-red col-2" (click)="onClickRemoveTags()" [disabled]="isLoadingBatchRemoveBtn || isDisabledBatchRemoveBtn">
      <div *ngIf="isLoadingBatchRemoveBtn" class="d-inline-block loading-spinner spinner-small">
        {{ "global.loading" | translate }}
      </div>
      <span *ngIf="!isLoadingBatchRemoveBtn">
        <i class="fas fa-file-download space"></i>
        {{ 'global.remove' | translate }}
      </span>
    </button>
  </div>
</div>