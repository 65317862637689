import { Component, Input, OnInit } from '@angular/core';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-jobcard-ccs-information',
  templateUrl: './jobcard-ccs-information.component.html',
  styleUrls: ['./jobcard-ccs-information.component.scss']
})
export class JobcardCcsInformationComponent implements OnInit {
  // @Input() sn: JMOBJ.ServiceNotification; 
  @Input() jobCard: JMOBJ.JobCard;
  @Input() componentParameters;
  @Input() pageMode: JMENUM.JMPageMode;

  ccsServiceOrderNumber = undefined;

  emptyHandleNa = JMLanguage.translate('component.display-text-field.na');

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.ccsServiceOrderNumber = this.jobCard.ccsServiceOrderNumber;
    if(!this.jobCard.soObtainedByCcep && this.ccsServiceOrderNumber){
      this.ccsServiceOrderNumber += " ("+JMLanguage.translate("pages.sn.designated-so")+")";
    }
  }

}
