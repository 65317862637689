<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [breadcrumbs]="breadcrumbs"
  ></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding job-card-view">

    <ng-container *ngIf="jobCard">
      <ng-container [ngSwitch]="jobCard?.jobNature">
        <!-- CM / PM Job -->
        <ng-container *ngSwitchDefault>
          <div *ngIf="jobCard.ccsLastFailedAction" class="alert-bar-wrap px-3 py-2 font-size-l px mb-3">
            {{ jobCard.ccsErrorMessage }}
          </div>
          <div class="row">
            <div class="col-xl-6 col-12">
              <app-jobcard-job-description #cmJobDescElem
                class="d-block mb-4"
                [jobCard]="jobCard"
                [sn]="sn"
                [componentParameters]="componentParameters"
                [pageMode]="pageMode"
              >
                <div instruction class="instruction">
                  <div class="instruction-row"><p>*</p>Mandatory</div>
                  <div class="instruction-row"><p>#</p>Mandatory for completion</div>
                </div>
              </app-jobcard-job-description>
              <app-jobcard-client-information #cmClientInfoElem
                class="d-block mb-4"
                [jobCard]="jobCard"
                [sn]="sn"
                [componentParameters]="componentParameters"
                [pageMode]="pageMode"
              ></app-jobcard-client-information>
              <app-jobcard-equipment-information #cmEquipInfoElem
                class="d-block mb-4"
                [jobCard]="jobCard"
                [componentParameters]="componentParameters"
              ></app-jobcard-equipment-information>
              <app-jobcard-ha-information #cmHaInfoElem *ngIf="isHaEquipmentOwner"
                class="d-block mb-4"
                [jobCard]="jobCard"
                [sn]="sn"
                [componentParameters]="componentParameters" 
                [pageMode]="pageMode"
                (onJobCardDropdownSummaryReady)="onJobCardDropdownSummaryReady()"
              ></app-jobcard-ha-information>
              <app-jobcard-stock-reserve
                *ngIf="jobCard.stockReservations && jobCard.stockReservations.length > 0"
                class="d-block mb-4"
                [jobCard]="jobCard"
              ></app-jobcard-stock-reserve>
            </div>
            
            <div class="custom-tab-content col-xl-6 col-12">
              <app-jobcard-task-progress #cmTaskProgressElem
                class="d-block mb-4"
                [jobCard]="jobCard"
                [componentParameters]="componentParameters"
                [pageMode]="pageMode">
              </app-jobcard-task-progress>
              <app-jobcard-progress-details #cmProgressDetailsElem [jobCard]="jobCard" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-progress-details>
              <app-jobcard-ccs-information #cmCcsInfoElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-ccs-information>
              <!-- <app-jobcard-additional-information #cmAdditionalInfoElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4"></app-jobcard-additional-information> -->
              <app-attachment-detail #jobcardAttachmentDetailElem [jobCard]="jobCard" [page]="JMPage.JOBCARD" [currentPageMode]="pageMode" *ngIf="jobCard.attachments && jobCard.attachments.length > 0">
              </app-attachment-detail>
            </div>
          </div>
        </ng-container>
  
        <!-- Enquiry Job -->
        <ng-container *ngSwitchCase="jobNatureEnum.ENQUIRY">
          <div class="row">
            <div class="col-xl-6 col-12">
              <app-jobcard-job-description #enquiryJobDescElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode">
                <div instruction class="instruction">
                  <div class="instruction-row"><p>*</p>Mandatory</div>
                  <div class="instruction-row"><p>#</p>Mandatory for completion</div>
                </div>
              </app-jobcard-job-description>
              <app-jobcard-client-information #enquiryClientInfoElem [jobCard]="jobCard" [sn]="sn" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-client-information>
            </div>
            
            <div class="custom-tab-content col-xl-6 col-12" >
              <app-jobcard-task-progress #enquiryTaskProgressElem [jobCard]="jobCard" [componentParameters]="componentParameters" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-task-progress>
              <app-jobcard-progress-details #enquiryProgressDetailsElem [jobCard]="jobCard" class="d-block mb-4" [pageMode]="pageMode"></app-jobcard-progress-details>
            </div>
          </div>
        </ng-container>

        <!-- Standalone Job -->
        <ng-container *ngSwitchCase="jobNatureEnum.STANDALONE">
          <app-job-card-standalone-view
            [jobCard]="jobCard"
            [componentParameters]="componentParameters"
          ></app-job-card-standalone-view>
        </ng-container>

      </ng-container>
    </ng-container>
    

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionButtons && actionButtons.length > 0">
      <app-action-sidebar [buttonData]="actionButtons" [disabled]="disabledActionSideBar"
        (clickEvent)="onActionButtonClicked($event)">
      </app-action-sidebar>
    </ng-container>

    <!--Slider panel-->
    <custom-slider-panel #reject_job_card_panel>
      <reject-job-card-form [options]="rejectOptions" [isLoading]="isRejectPanelLoading"
        (onSubmit)="requestRejectJobCard($event)"></reject-job-card-form>
    </custom-slider-panel>

    <custom-slider-panel #cancel_job_card_panel [widthPx]=600>
      <app-job-card-cancel-form
        [jobCard]="jobCard"
        (onComplete)="onJobCardCancelComplete()"
      ></app-job-card-cancel-form>
    </custom-slider-panel>

    <custom-slider-panel #complete_job_card_panel [widthPx]=600>
      <complete-job-card-form #completeJobCardForm [parameters]="completeJobCardFormParam"></complete-job-card-form>
    </custom-slider-panel>

    <custom-slider-panel #complete_job_card_with_no_action_panel [widthPx]=600>
      <complete-job-card-form [parameters]="completeJobCardFormParam" [completeWithNoAction]="true" idExtension="-no-action"></complete-job-card-form>
    </custom-slider-panel>

    <custom-slider-panel #obtain_ccs_so_panel [customClass]="'obtain_ccs_so_panel'" [widthPx]=900>
      <ccs-so-submit-form [parameters]="generateCcsSoParam" (onSubmit)="onSubmitCcsSoForm($event)"></ccs-so-submit-form>
    </custom-slider-panel>

    <custom-slider-panel #assign_staff_panel [widthPx]=600>
      <div *ngIf="!assignStaffFormParam.viewOnly" class="custom-control custom-checkbox">
        <input type="checkbox" id="show-all-post-checkbox" class="custom-control-input" [(ngModel)]="showAllPostForReassign"
          (change)="initReassignStaffPanel(false)" [disabled]="assignStaffFormParam.isLoading">
        <label for="show-all-post-checkbox" class="custom-control-label">
          {{'pages.sn.slider-panel.create-job-show-all-staff-checkbox' | translate}}
        </label>
      </div>
      <job-card-assign-staff-form [parameters]="assignStaffFormParam"></job-card-assign-staff-form>
    </custom-slider-panel>

    <!-- silder panel of unteco-->
    <custom-slider-panel #unTecoReasonPanel [customClass]="'add_dummy_contract_panel'">
      <app-un-teco-reason #unTecoReasonForm [selectedCount]="selectedCount" [unTecoParam]="unTecoParam" (onSubmit)="onSubmitUnTeco($event)">
      </app-un-teco-reason>
    </custom-slider-panel>

    <custom-slider-panel #patch_ccs_so_number_panel>
      <app-patch-ccs-so-number-form [jobCard]="jobCard" [componentParameters]="componentParameters" (onJobCardUpdated)="onPatchCcsSoSuccessSubmit($event)" ></app-patch-ccs-so-number-form>
    </custom-slider-panel>

    <custom-slider-panel #reject_job_card_completion_panel>
      <app-reject-job-card-completion [isLoading]="isRejectCompletionPanelLoading" (onSubmit)="requestRejectCompletion($event)"></app-reject-job-card-completion>
    </custom-slider-panel>
  </div>
</div>