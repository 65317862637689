import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';

export const columnsApproval = [
  {
    id: 'id',
    name: 'pages.approval-list.table-column.id',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'description',
    name: 'pages.approval-list.table-column.description',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col',
  },
  {
    id: 'job-type',
    name: 'pages.approval-list.table-column.job-type',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'request-work-centre',
    name: 'pages.approval-list.table-column.request-work-centre',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'contract-number',
    name: 'pages.approval-list.table-column.contract-number',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'requester',
    name: 'pages.approval-list.table-column.requester',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'approver',
    name: 'pages.approval-list.table-column.approver',
    enableFilter: false,
    type: TablexColumnType.MultiLine,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col',
  },
  {
    id: 'reason',
    name: 'pages.approval-list.table-column.reason',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col',
  },
  {
    id: 'start-date',
    name: 'pages.approval-list.table-column.start-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col',
  },
  {
    id: 'end-date',
    name: 'pages.approval-list.table-column.end-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col',
  },
  {
    id: 'requested-date',
    name: 'pages.approval-list.table-column.requested-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col',
  },
  {
    id: 'financial-cutoff-date',
    name: 'pages.approval-list.table-column.financial-cutoff-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col',
  },
  {
    id: 'overall-kpi-status',
    name: 'pages.approval-list.table-column.overall-kpi-status',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
];

export const columnsHistory = [
  {
    id: 'id',
    name: 'pages.approval-list.table-column.id',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'description',
    name: 'pages.approval-list.table-column.description',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-2',
  },
  {
    id: 'job-type',
    name: 'pages.approval-list.table-column.job-type',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'contract-number',
    name: 'pages.approval-list.table-column.contract-number',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'requester',
    name: 'pages.approval-list.table-column.requester',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'requested-date',
    name: 'pages.approval-list.table-column.requested-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-2',
  },
  {
    id: 'action-type',
    name: 'pages.approval-list.table-column.action-type',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'approve-by',
    name: 'pages.approval-list.table-column.approve-by',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-1',
  },
  {
    id: 'approve-date',
    name: 'pages.approval-list.table-column.approve-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'col-2',
  },
];
