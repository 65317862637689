<div class="mat-dialog-container">
  <h2 mat-dialog-title> {{data.title}} </h2>
  <div mat-dialog-content>
    <ng-container *ngFor="let item of data.content">

      <ng-container *ngIf="item.type == 'text'" >
        <div text-item>
          <h5> {{item.value}} </h5>
        </div>
      </ng-container>

      <ng-container *ngIf="item.type == 'tablex'" >
        <div tablex-item>
          <app-tablex [tablexParam]='item.value' class='mt-3'></app-tablex>
        </div>
      </ng-container>

      <ng-container *ngIf="item.type == 'previewer'" >
        <div>
          <attachment-previewer [file]="item.value"></attachment-previewer>
        </div>
      </ng-container>

      <ng-container *ngIf="item.type == 'snHistory'" >
        <div class="sn-history-container">
          <app-sn-history [sn]="item.value"></app-sn-history>
        </div>
      </ng-container>

    </ng-container>
  </div>
  
  <div mat-dialog-actions>
      <button [ngClass]="{'brand-amber': true}" (click)="buttonClicked($event)"> Close </button>
  </div>
</div>