import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ, JMUTILITY, JMREASON } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-cancel-pm-job-form',
  templateUrl: './cancel-pm-job-form.component.html',
  styleUrls: ['./cancel-pm-job-form.component.scss'],
})
export class CancelPmJobFormComponent implements OnInit {
  @Input() isLoading: boolean;
  @Output() onSubmit = new EventEmitter<any>();

  constants = Constants;

  reasonItems: {
    value: string;
    description: string;
  }[] = [];

  submitDisabled: boolean = true;

  formFields: {
    reasonCode?: number;
    remarks?: string;
  } = {};

  constructor() {}

  ngOnInit() {
    this.initDropDownItmes();
  }

  initDropDownItmes() {
    JMREASON.PM_JOB_CANCEL_REASON_LIST.list.forEach((reason) => {
      let item: any = {};
      item.value = reason.code;
      item.description =
        Session.selectedLanguage === JMENUM.Language.ZH ? reason.description.zh : reason.description.en;
      this.reasonItems.push(item);
    });
  }

  validateForm() {
    if (!this.formFields.reasonCode) {
      this.submitDisabled = true;
      return;
    }

    if (!this.formFields.remarks) {
      this.submitDisabled = true;
      return;
    }
    if (this.formFields.remarks) {
      if (this.formFields.remarks.trim().length === 0) {
        this.submitDisabled = true;
        return;
      }
    }

    this.submitDisabled = false;
  }

  onClickSubmit() {
    AppDelegate.showPopUpAlert(JMLanguage.translate('component.cancel-pm-job-form.popup.title'), null, [
      {
        name: JMLanguage.translate('global.yes'),
        handler: () =>
          this.onSubmit.emit({
            cancelCode: this.formFields.reasonCode,
            cancelRemarks: this.formFields.remarks.trim(),
          }),
      },
      { name: JMLanguage.translate('global.no') },
    ]);
  }

  get getMaxRemarksLength():number{
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
