<div class="wrapper page-enquiry-list">
  <app-nav navbarTitle="{{ 'pages.enquiry.title' | translate }}"></app-nav>
  <app-sidebar type='enquiry'></app-sidebar>
  
  <!-- Page Content -->
  <div id="content">
    <div class="row header">
      <div class="col">
        <h2 class="title">{{ 'pages.enquiry.list.title' | translate }}</h2>
        <hr class="mb-0">
      </div>
    </div>

    <div class="row content">
      <div class="col">
        <app-tablex 
          id="enquiry-list-table" 
          [tablexParam]='tablexParam'
        ></app-tablex>
      </div>
    </div>

  </div>
</div>
