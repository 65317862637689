<!-- temp permission of currentPageMode==pageMode.EDIT -->
<!-- Please remove when sn-edit handle draft and reroute case -->
<ng-container *ngIf="currentPageMode==pageMode.VIEW || currentPageMode==pageMode.EDIT">
    <div class="font-size-xl m-0 mb-2 px-2">
        {{ "component.notification-progress.title" | translate}}
    </div>
    <hr class="underline" />
    <div class="m-0 mb-4">
        <app-tablex id="jobcard-table" [tablexParam]="tablexParam"></app-tablex>
    </div>
</ng-container>