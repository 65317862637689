import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/AppDelegate';
import { ngbDateToString } from 'src/lib/presenter/Formatter';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit {
  @ViewChild('customDatePicker', { static: false }) customDatePicker ;
  @Input() dateAlertMsg;
  @Input() field; //field name
  @Input() input; //ngModel
  @Input() inputId; // id for HTML use
  @Input() isError = undefined;
  @Input() disabled = undefined;
  @Input() enableDateString: Array<string>;
  @Input() validator?: (time: string) => boolean = null;
  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  errorFields: boolean;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.errorFields = this.isError;
  }

  public onClickDatePicker(datepicker){
    setTimeout(() => {
      document.getElementById(datepicker).focus();
    }, 100);
  }
  
  public clearInput(){
    this.input = undefined;
  }

  public searchDate(date){
    let dateString = date;
    if(!date){ 
      this.input = undefined;
      this.onBlur.emit({field: this.field, data: this.input});
      return; 
    }

    if(typeof(date) != 'string'){
      dateString = ngbDateToString(date);
    }

    dateString = dateString.replace(/-|\//g, "");

    if(this.validator && !this.validator(dateString)){
      this.errorFields = true;
      AppDelegate.openSnackBar(this.dateAlertMsg);
      this.onBlur.emit({field: this.field, data: this.input});
      return
    }

    if(dateString.length < 4){
      return;
    }
    if(dateString.length > 8){
      this.errorFields = true;
      AppDelegate.openSnackBar(this.dateAlertMsg);
      this.onBlur.emit({field: this.field, data: undefined});
      return;
    }
    
    let ngbDate = {year: parseInt(moment().format('YYYY')), month: 1, day: 1};
    if(dateString.length >= 4){
      let year = parseInt(dateString.substring(0,4));
      if(year >= 1970){
        this.errorFields = false;
        ngbDate.year = year;
      }else{
        this.errorFields = true;
        AppDelegate.openSnackBar(this.dateAlertMsg);
      }
    }

    if(dateString.length >= 6){
      let month = parseInt(dateString.substring(4,6));
      if( month >=1 && month <= 13){
        this.errorFields = false;
        ngbDate.month = month;
      }else{
        this.errorFields = true;
        AppDelegate.openSnackBar(this.dateAlertMsg);
        
      }
    }
    if(dateString.length == 8){
      let day = parseInt(dateString.substring(6,8));
      if(day >= 1 && day <= 31){
        this.errorFields = false;
        ngbDate.day = day;
      }else{
        this.errorFields = true;
        AppDelegate.openSnackBar(this.dateAlertMsg);
      }
    }
    
    this.customDatePicker.navigateTo(ngbDate);
    if(dateString.length == 8){
      this.input = ngbDate;
      this.onBlur.emit({field: this.field, data: this.input});
    }
  }

  inputNumberWithIndex(event) {
    event.target.value = event.target.value.replace(/[^\d-]/g, '')
  }

  onChangeInput(event) {
    this.searchDate(event.target.value)
  }

  isDisabled(date, current) {
    if (Array.isArray(this.enableDateString)) {
      const dateStr = ngbDateToString(date);
      return !this.enableDateString.includes(dateStr);
    } else {
      return false;
    }
  }
}
