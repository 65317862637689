import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import * as _ from 'underscore';
import { formatDate } from 'src/lib/presenter/Formatter';
import { Session } from '@services/session';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationService } from '@services/authorization.service';

@Component({
  selector: 'app-pm-plan-search-criteria',
  templateUrl: './pm-plan-search-criteria.component.html',
  styleUrls: ['./pm-plan-search-criteria.component.scss']
})
export class PmPlanSearchCriteriaComponent implements OnInit {
  @ViewChild("startDateElem", {static: false}) startDateElem;
  @ViewChild("endDateElem", {static: false}) endDateElem;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  //Services
  authorizationService: AuthorizationService;
  
  isSearching: boolean = false;
  isCollapse: boolean = false;
  
  isDisabled: boolean = false; //For Not Ready Function

  //ENUM for HTML
  // PMPlanType = JMENUM.PMPlanType;
  PMStatus = JMENUM.PMStatus;
  // PlanCoverage = JMENUM.PMPlanCoverage;
  ScheduleType = JMENUM.PMScheduleType;
  PMPlanFrequency = JMENUM.PMPlanFrequency;
  
  PMPlanRequest: JM.JMRequestGetPmPlanList;

  frequencyOptions: any[];

  dateAlertMsg: string = undefined;

  searchSelections : {
    planNumber: string;
    agreementNumber: string;
    planStatus: { [ key : string ] : boolean };
    // planCoverage: { [ key : string ] : boolean };
    scheduleType: { [ key : string ] : boolean };
    startRange: 'on-or-before' | 'after';
    ngbStartDate: NgbDateStruct;
    endRange: 'on-or-before' | 'after';
    ngbEndDate: NgbDateStruct;
    frequency: string[];
    description: string;
    handlingTeam: string[];
    workCentre: string[];
  };

  workCentreOptions: {
    list?: string[]
    isLoading?: boolean
  } = {}

  // Team Dropdown
  teamOptions: any = [];
  private searchTeamObserver$ = new Subject<any[]>();
  private notifier$ = new Subject();
  searchTeamKeywords: string = null;
  teamPageSize: number = 100;
  teamPageNumber: number = 0;
  teamTotalCount: number;
  isLoadingTeam: boolean = false;
  
  constructor(authorizationService: AuthorizationService){
    this.authorizationService = authorizationService
  }

  ngOnInit(): void {
    this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
    this.initDefaultFilters();
    this.loadFilterOptionsFromLocal();
    this.fetchData();
    this.requestTeamList();
    this.initWorkCentres();

    this.searchTeamObserver$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.notifier$),
    ).subscribe(() => {
      this.searchTeams();
    });
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  // ----------- API ----------- //
  private async requestTeamList(){
    if (this.teamTotalCount !== null && 
      this.teamTotalCount <= this.teamPageNumber * this.teamPageSize) {
      return;
    }
    this.isLoadingTeam = true;
    this.teamPageNumber++;

    const request = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['_id', 'name'];
    request.pageNumber = this.teamPageNumber;
    request.pageSize = this.teamPageSize;

    if (this.searchTeamKeywords) {
      request.filter = {
        name: this.searchTeamKeywords
      }
    }

    const response = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;
    if (response) {
      if (response.error) {
        AppDelegate.openErrorBar(response);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
      if(response.payload.records.length > 0){
        let options =  response.payload.records.map(team => {
            let obj = { value: team._id, label: team.name };
            return obj;
          });
        this.teamTotalCount = response.payload.totalCount;
        this.teamOptions = this.teamOptions.concat(options);
      }
    }
  }


  // ----------- UI function ----------- //
  private initDefaultFilters(){
    this.searchSelections = {
      planNumber: null,
      agreementNumber: null,
      planStatus: {},
      // planCoverage: {},
      scheduleType: {},
      startRange: null,
      ngbStartDate: null,
      endRange: null,
      ngbEndDate: null,
      frequency: [],
      description: null,
      handlingTeam: [],
      workCentre: [],
    };

    let statusEnum = Object.values(this.PMStatus);
    for (let i=0; i < statusEnum.length; i++) {
      this.searchSelections.planStatus[statusEnum[i]] = false;
    }

    // let coverageEnum = Object.values(this.PlanCoverage);
    // for (let i=0; i < coverageEnum.length; i++) {
    //   this.searchSelections.planCoverage[coverageEnum[i]] = false;
    // }

    let scheduleEnum = Object.values(this.ScheduleType);
    for (let i=0; i < scheduleEnum.length; i++) {
      this.searchSelections.scheduleType[scheduleEnum[i]] = false;
    }

    let frequencyEnum = Object.values(this.PMPlanFrequency);
    this.frequencyOptions = [];
    for (let i=0; i < frequencyEnum.length; i++) {
      this.frequencyOptions.push({ value: frequencyEnum[i], label: JMLanguage.translate("pm.frequency."+frequencyEnum[i]) });
    }

    this.searchSelections.planStatus.outstanding = true;
    this.searchSelections.planStatus.in_progress = true;
    this.searchSelections.startRange = 'after';
    this.searchSelections.endRange = 'after';
    this.searchSelections.ngbStartDate = {
      year : 2010,
      month : 1,
      day : 1
    };
    this.searchSelections.ngbEndDate = {
      year : 2010,
      month : 1,
      day : 1
    };

  }

  fetchData(pageNumber?: number, pageSize?: number){
    this.PMPlanRequest = new JM.JMRequestGetPmPlanList();
    this.PMPlanRequest.filter = {};

    let planStatus = Object.keys(this.searchSelections.planStatus).filter(status => this.searchSelections.planStatus[status]);
    // let planCoverage = Object.keys(this.searchSelections.planCoverage).filter(coverage => this.searchSelections.planCoverage[coverage]);
    let scheduleType = Object.keys(this.searchSelections.scheduleType).filter(type => this.searchSelections.scheduleType[type]);

    this.PMPlanRequest.filter['pmPlanNumber'] = this.searchSelections.planNumber ? this.searchSelections.planNumber : undefined;
    this.PMPlanRequest.filter['agreementNumber'] = this.searchSelections.agreementNumber ? this.searchSelections.agreementNumber : undefined;
    this.PMPlanRequest.status = planStatus.length > 0 ? planStatus : undefined;
    // this.PMPlanRequest.planCoverage = planCoverage.length > 0 ? planCoverage : undefined;
    this.PMPlanRequest.scheduleType = scheduleType.length > 0 ? scheduleType : undefined
    this.PMPlanRequest.frequency = (this.searchSelections.frequency && this.searchSelections.frequency.length > 0)? this.searchSelections.frequency : undefined;
    this.PMPlanRequest.filter['planDescription'] = this.searchSelections.description ? this.searchSelections.description : undefined;
    this.PMPlanRequest.teamId = (this.searchSelections.handlingTeam && this.searchSelections.handlingTeam.length > 0)? this.searchSelections.handlingTeam : undefined;
    this.PMPlanRequest.endDateTo = undefined;
    this.PMPlanRequest.endDateFrom = undefined;
    this.PMPlanRequest.startDateTo = undefined;
    this.PMPlanRequest.startDateFrom = undefined;
    this.PMPlanRequest.workCentre = this.searchSelections.workCentre && this.searchSelections.workCentre.length > 0 ? this.searchSelections.workCentre : undefined;

    if ( this.searchSelections.ngbEndDate ) {
      if(this.searchSelections.endRange == 'on-or-before' ){
        this.PMPlanRequest.endDateTo = this.parseDateToRequestFormat( this.searchSelections.ngbEndDate );
      }else if(this.searchSelections.endRange == 'after'){
        this.PMPlanRequest.endDateFrom = this.parseDateToRequestFormat( this.searchSelections.ngbEndDate );
      }
    }

    if ( this.searchSelections.ngbStartDate ) {
      if(this.searchSelections.startRange == 'on-or-before'){
        this.PMPlanRequest.startDateTo = this.parseDateToRequestFormat( this.searchSelections.ngbStartDate );
      }else if(this.searchSelections.startRange == 'after'){
        this.PMPlanRequest.startDateFrom = this.parseDateToRequestFormat( this.searchSelections.ngbStartDate );
      }
    }

    this.saveFilterOptionsToLocal();
    this.onSearch.emit({
      advancedSearch: this.PMPlanRequest,
      pageNumber,
      pageSize,
    });
  }

  onClickSearch (){
    this.fetchData(1, null);
  }
  
  onClickClear(){
    this.initDefaultFilters();
    this.fetchData(1, null);
  }

  public onTeamScrollToEnd(){
    this.requestTeamList();
  }

  onSearchTeamClear() {
    this.searchTeamKeywords = undefined;
    this.searchTeamObserver$.next();
  }

  public onSearchTeam(event){
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver$.next();
  }

  public searchTeams() {
    this.teamOptions = [];
    this.teamPageNumber = 0;
    this.teamTotalCount = null;
    this.requestTeamList();
  }

  public onBlurDateInput(event){
    if(event.field == 'startDate'){
      this.searchSelections.ngbStartDate = event.data;
    }
    else if(event.field == 'endDate'){
      this.searchSelections.ngbEndDate = event.data;
    }
  }

  private parseDateToRequestFormat = ( dateData : any ) : string => {
    try {
      let date = new Date(dateData.year, dateData.month - 1, dateData.day);
      return formatDate( date.toString() , 'YYYYMMDD');
    } catch ( e ) {
      return null;
    }
  }

  private saveFilterOptionsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      delete cache.frequencyOptions;
      Session.setPmPlanSettingsFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  }
  private loadFilterOptionsFromLocal = () : void => {
    try {
      let cache = Session.pmPlanListSettingsFilters;
      if (cache) {
        let cacheParsed = JSON.parse(JSON.stringify(cache));
        this.searchSelections = {
          ...this.searchSelections,
          ...cacheParsed,
        };
      }
    } catch (e) {
      console.warn(e);
    }
  }

  initWorkCentres = () => {
    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.workCentreOptions.list = JM.JMConnector.getAllWorkCentreCode();
    } else {
      this.workCentreOptions.list = this.authorizationService.getWorkCenters();
    }
  }

}
