<div class="upload-preview-wrap position-relative float-right col-auto pr-0">
  <button *ngIf="parameters.hasEditPermission" id="add-attachment-btn" class="btn outline-none font-size-xl" type="button" 
    [disabled]="parameters.isDisabledAddAttachment" (click)="onClickAddAttachment()"
  >
    <i class="fa fa-plus blue"></i>
  </button>

  <div class="preview-window-wrap position-absolute" *ngIf="isShowUploadPreview">
    <div class="file-input-wrap row mx-0 justify-content-end">
      <label for="upload-file-input" id="choose-file-btn" class="choose-file-btn py-1 px-2 mx-2 mb-0 col-auto text-center align-self-center">
        {{ "component.attachment.choose-file" | translate }}
      </label>
      <input #fileUpload type="file" name="file" id="upload-file-input" class="d-none"
      (change)="onChangeFileInput(fileUpload.files)" />
      <button id="submit-attachment-btn" class="btn brand-blue px-3" type="button" 
      [disabled]="parameters.isLoadingAddAttachment || isFileOverSize || (parameters.isDisabledAddAttachment && parameters.userInput.fileInput)" (click)="parameters.onSubmitAttachment()"
      >
        <div *ngIf="parameters.isLoadingAddAttachment" class="d-inline-block loading-spinner spinner-small">
          {{ "global.loading" | translate }}
        </div>
        <div *ngIf="!parameters.isLoadingAddAttachment" >{{ "component.attachment.attach" | translate }}</div>
      </button>
    </div>

    <label for="upload-file-input" class="w-100" *ngIf="parameters?.userInput?.fileInput == undefined">
      <div class="dropzone d-flex align-items-center justify-content-center font-size-xl"
        (drop)="onFilesDropped($event)" (dragover)="onDraggedOver($event)">
        {{ "component.file-selector.drag-and-drop" | translate }}
      </div>
    </label>

    <div class="input-detail-wrap row justify-content-between mt-4" *ngIf="parameters?.userInput?.fileInput" >
      <h5 class="col-auto">{{ "component.attachment.preview" | translate }}</h5>
      <div class="col-auto">
        <span>{{ "component.attachment.file-size" | translate }}</span>
        <span id="preview-file-size" class="ml-3">{{ previewSize }}</span>
      </div>
    </div>
    <attachment-previewer id="upload-attachment-previewer" #upload_attachment_preview class="d-block w-80 mx-auto" (onSelectedPageChange)=refreshSelectedPage($event) [isHaSn]="parameters.isHaSn"  [file]="parameters.userInput.fileInput"  [isLoading]="isPreviewerLoading"></attachment-previewer>
  </div>
</div>
