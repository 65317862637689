<div class="wrapper">
    <app-nav [showActionSidebarIcon]="true" [breadcrumbs]="breadcrumbs"></app-nav>

    <app-sidebar></app-sidebar>

    <div id="content" class="row mx-0 align-content-start ">
        <div class="col-12 col-lg-6">
            <div class="mb-2">
                <span class="title">{{ "component.attachment.inspectionResult" | translate }}</span>
                <button type="button" *ngIf="hasSubmitJobInspectionPermission"
                    class="btn float-right" (click)="onSubmitJobInspectionBtnClicked($event)">
                    <i class="fas fa-plus blue fa-lg"></i>
                </button>
            </div>

            <hr class="underline" />

            <app-tablex #inspectionTable id="jobcard-attachment-table" [tablexParam]='tablexParam'
                class='mt-3'></app-tablex>
            <br />

        </div>
        <div class="col-12 col-lg-6">
            <div class="title mb-2">{{ "component.attachment.preview-title" | translate }}</div>
            <hr class="underline" />
            <attachment-previewer #attachment_preview id="attachment-preview" class="d-block mt-3" [file]="file"
                [fileDetails]="fileDetails" [isLoading]="isPreviewerLoading"></attachment-previewer>
        </div>
        <custom-slider-panel #submitJobInspectionPanel [widthPx]=600>
            <app-inspection-form
                (submitButtonStatusCheck)="checkSubmitButtonStatus()"
                (dateInput)="onBlurDateInput($event)"
                (downloadLocalFile) = "downloadLocalFile($event)"
                (imageFile) = "onChangeImageFileInput($event)"
                (imageFileDelete) = "onImageFileDelete($event)"
                (pdfFile) = "onChangePdfFileInput($event)"
                (pdfFileDelete) = "onPdfFileDelete($event)"
                (onSubmit) = "onClickSubmit()"
                [jobInspectionInput]="jobInspectionInput"
                [dateInputSet] = "dateInputSet"
                [errorFields] = "errorFields"
                [imageFiles] = "imageFiles"
                [pdfFiles] = "pdfFiles"
                [pdfAcceptType] = "pdfAcceptType"
                [imageAcceptType] = "imageAcceptType"
                [submitDisabled] = "submitDisabled"
                [isLoading] = "isLoading"
                [isDateValid] = "isDateValid"
            ></app-inspection-form>
        </custom-slider-panel>
    </div>