import { Component, Input, OnInit } from '@angular/core';
import { PmJobTimesheetComponentStages } from '../../pm-job-timesheet/pm-job-timesheet.component';
import { Session } from 'src/app/services/session';
import { JMENUM, JMUTILITY } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'app-pm-job-timesheet-steps-header',
  templateUrl: './pm-job-timesheet-steps-header.component.html',
  styleUrls: ['./pm-job-timesheet-steps-header.component.scss'],
})
export class PmJobTimesheetStepsHeaderComponent implements OnInit {
  @Input() stage: PmJobTimesheetComponentStages = PmJobTimesheetComponentStages.DOWNLOAD;
  uploadHistoryAccess: {
    permission: boolean,
    routing: string
  } = {
      permission: false,
      routing: '/pm/job/timesheet/upload-history'
    }

  constructor() {}

  ngOnInit() {
    this.checkUploadHistoryAccessPermission();
  }

  get isStageDownload(): boolean {
    return this.stage === PmJobTimesheetComponentStages.DOWNLOAD;
  }

  get isStageUpload(): boolean {
    return this.stage === PmJobTimesheetComponentStages.UPLOAD || 
      this.stage === PmJobTimesheetComponentStages.VERIFICATION_SUCCESSFUL || 
      this.stage === PmJobTimesheetComponentStages.VERIFICATION_FAILED;
  }

  get isStageResult(): boolean {
    return this.stage === PmJobTimesheetComponentStages.SUBMITTED;
  }

  checkUploadHistoryAccessPermission() {
    if (!JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.JOBCARD_COMPLETE, JMENUM.Permission.JOBCARD_COMPLETE_ALL], false)) {
      return
    }
    this.uploadHistoryAccess = {
      ...this.uploadHistoryAccess,
      permission: true
    }
  }
}
