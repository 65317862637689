<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings.special-request-alert' | translate">
  </app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="">
    <!--Slider panel-->
    <custom-slider-panel #createSRAPanel widthPx="600">
      <app-special-request-alert-detail #SRADetail (closePanel)="onClosePanel($event)"></app-special-request-alert-detail>
    </custom-slider-panel>

    <div class="d-inline-block float-right">
      <button class="col-auto btn standard-btn brand-blue bold" style="min-width: 100px" (click)="createSRA()">
        {{ "global.add" | translate }}
      </button>
    </div>
    <app-tablex id="special-request-alert-table" #specialRequestAlertTable [tablexParam]="tablexParam" class=""></app-tablex>
  </div>
</div>