<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar></app-sidebar>

  <!-- Page Content -->
  <div id="content">

    <!--Table-->
    <app-tablex id='customer_table' [tablexParam]='tablexParam' class='mt-3'></app-tablex>


  </div>
</div>