<div class="wrapper">

  <div id="fullpageLoading" *ngIf="isLoading">
    <div>
      <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
      {{'global.loading' | translate}}
    </div>
  </div>

  <app-nav [navbarTitle]="'pages.timesheet-job.page-title' | translate" (languageChanged)="onLanguageChanged()" [showActionSidebarIcon]="true">
  </app-nav>

  <app-sidebar [type]="'default'"></app-sidebar>

<!-- Action Sidebar -->
  <ng-container *ngIf="actionButtonData.length > 0">
    <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disabledActionSideBar" (clickEvent)="onActionButtonClicked($event)">
    </app-action-sidebar>
  </ng-container>

  <div id="content" class="job-card-time-sheet">
    <div class="row justify-content-between align-items-center">
      <div class="col-auto">
        <h2 class="title">{{ 'pages.timesheet.summary' | translate}}</h2>
      </div>
    </div>
    <hr class="underline" />

    <div class="row job-info-wrap align-items-center mx-0">
      <div class="info-container col-xl-6 col-md-5 input-border py-2">
        <div class="row mx-0 my-3">
          <div class="col-7 p-0">{{ 'pages.timesheet.job-number' | translate}}</div>
          <div class="col-5 p-0">
            <a (click)="onJobNumberClicked()" class="job-card-link">
              {{selectedJobCardId}}
            </a>
          </div>
        </div>
        <div class="row mx-0 my-3">
          <div class="col-7 p-0">{{ 'pages.timesheet.job-duration' | translate}}</div>
          <div class="col-5 p-0">
            <span class="d-inline" id="job-duration">{{uiJobDuration}}</span> {{ 'pages.timesheet.hours' | translate}}
          </div>
        </div>
        <div class="row mx-0 my-3">
          <div class="col-7 p-0">{{ 'pages.timesheet.staff-chargeable-hours' | translate}}</div>
          <div class="col-5 p-0">
            <span class="d-inline" id="chargeable-hour">{{ displayHour }} </span>
            {{ 'pages.timesheet.hours' | translate}}
            <span class="d-inline" id="chargeable-minutes">{{ displayMinutes }}</span>
            {{ 'pages.timesheet.minutes' | translate }}</div>
        </div>
      </div>
      <div class="col-xl-6 col-md-7 col-12">
        <div class="input-time-wrap" *ngIf="jobCard">
          <div class="time-range text-center my-2" *ngIf="jobCard">
            <span class="d-inline" id="job-card-start-time">{{jobCard.startTime | date:'yyyy-MM-dd HH:mm'}}</span>
            <i class="fas fa-long-arrow-alt-right font-size-l align-bottom mx-2" aria-hidden="true"></i>
            <span class="d-inline" id="job-card-end-time">{{jobCard.completionTime | date:'yyyy-MM-dd HH:mm'}}</span>
          </div>
          <div class="row mx-0 justify-content-center align-items-center mt-3 mb-4">
            <div class="col-3">{{ 'pages.timesheet.buffer-time' | translate}}</div>
            <div class="col-6">
              <div class="timebuffer-slider">
                <ng5-slider id="time-buffer-slider" [(value)]="bufferTimeValue" type="range" [options]="bufferTimeOptions" (userChangeEnd)="onChangeBufferTime()"></ng5-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="timesheet-detail-col">
      <div class="row staff-info-heading-wrap mx-0 my-3 align-items-end">
        <div class="bold col-4 col-xl-2 px-0 pr-xl-2 pr-3">
          {{ 'pages.timesheet.staff-info' | translate}}
        </div>
        <div class="bold col-2 col-xl-1 px-0 pr-xl-2 pr-3">
          {{ 'pages.timesheet.task-number' | translate}}
        </div>
        <div class="bold col-6 col-xl-2 px-0 pr-2">
          {{ 'pages.timesheet.date' | translate}}
        </div>
        <div class="bold col-7 time-range-header d-none d-xl-flex px-0 justify-content-between">
          <span class="time-span " *ngFor="let t of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]">{{t}}</span>
        </div>
      </div>

      <div class="text-center no-timesheet-alert" *ngIf="!jobCard">
        {{ "pages.timesheet.no-timesheet" | translate }}
      </div>

      <ng-container *ngIf="jobCard && !isLoading">
        <ng-container *ngFor="let post of employeeSummaryDict | keyvalue;">
          <ng-container *ngFor="let employee of getDistinctEmployee(post.key);" >
            <ng-container *ngFor="let day of jobPeriodList; let d = index;">
              <ng-container *ngIf="employeeWorkDate[post.key + '_' + employee.employeeCode].includes(day)">
                <div class="staff-info-wrap row mt-3 m-xl-0 mt-xl mx-0" attr.id="{{ post.key }}_{{employee.employeeCode }}_{{ day }}_row">

                  <!-- Staff Info column -->
                  <div class="col-4 col-xl-2 px-0 pr-xl-2 pr-3">
                    <ng-container *ngIf="employeeWorkDate[post.key + '_' + employee.employeeCode][0] == day">
                      <span attr.id="{{ post.key }}_{{ employee.employeeCode }}_post-name">{{post.key}}</span> 
                      <br>
                      <span attr.id="{{ post.key }}_{{ employee.employeeCode }}_person-name">{{employee.employeeName}}</span>
                      <i *ngIf="jobCard.officerInCharge == post.key" class="fas fa-user-circle officer-in-charge"></i>
                      <span class="d-block" attr.id="{{post.key}}_{{employee.employeeCode}}_employee-code">
                        ({{employee.employeeCode}})
                      </span>
                    </ng-container>
                  </div>

                  <!-- Task Number column -->
                  <div class="col-2 col-xl-1 px-0 pr-xl-2 pr-3">
                    <input *ngIf="employeeWorkDate[post.key + '_' + employee.employeeCode][0] == day" class="form-control px-1 py-0 task-number-input" attr.id="{{ post.key }}_{{ employee.employeeCode }}_task-number-input" type="text" [(ngModel)]="taskNumber[post.key + '_' + employee.employeeCode]" [disabled]="viewOnly || isDisabledTaskNumber(employeeWorkDate[post.key + '_' + employee.employeeCode])">
                  </div>

                  <!-- Date column -->
                  <div class="col-xl-2 col-6 px-0 text-left pr-xl-2">
                    <span class="d-inline-block d-xl-block mr-3 mr-xl-0" attr.id="{{ post.key }}_{{ employee.employeeCode }}_{{ day }}_weekday">
                        {{ getWeekdayTranslate(day) }}
                    </span>
                    <span attr.id="{{ key }}_date">
                      {{ day | date: 'yyyy-MM-dd' }}
                    </span>

                    <div class="time-input-wrap my-2">
                      <div class="range-input range-min mb-2 d-inline-block mr-3 mr-lg-2">
                        {{ 'pages.timesheet.start-time' | translate }}:
                        <input class="form-control px-1 py-0" attr.id="{{ post.key }}_{{ employee.employeeCode }}_{{ day }}_start-time-input" type="text" 
                          [(ngModel)]="staffTimeSheetDisplay[post.key + '_' + employee.employeeCode][day].startTime" 
                          (change)="onKeyUpTime($event, post.key, employee.employeeCode, day, 'start')"
                          [disabled]="viewOnly || isClosedDate(day)">
                      </div>
                      <div class="range-input range-max d-inline-block">
                        {{ 'pages.timesheet.end-time' | translate }}:
                        <input class="end-input form-control px-1 py-0" attr.id="{{ post.key }}_{{ employee.employeeCode }}_{{ day }}_end-time-input" type="text" 
                        [(ngModel)]="staffTimeSheetDisplay[post.key + '_' + employee.employeeCode][day].endTime" 
                        (change)="onKeyUpTime($event, post.key, employee.employeeCode, day, 'end')"
                        [disabled]="viewOnly || isClosedDate(day)">
                      </div>
                    </div>
                    <br />
                  </div>

                  <!-- Time range Slider column -->
                  <div class="time-sheet-col col-xl-7 px-0 col-12 align-self-center">
                    <!-- error message for each timesheet -->
                    <div 
                      *ngIf="timesheetTooltip[post.key + '_' + employee.employeeCode][day].show" 
                      attr.id="{{ post.key }}_{{ employee.employeeCode }}_{{ day }}_error-message">
                        <i class="fas fa-exclamation-circle no-timesheet-icon"></i>
                        {{ timesheetTooltip[post.key + '_' + employee.employeeCode][day].message }}
                    </div>
                    <!-- time range header for small screen -->
                    <div class="time-range-header d-flex d-xl-none mb-3 px-3 justify-content-between">
                      <span class="time-span " *ngFor="let t of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]">{{t}}</span>
                    </div>
                    <time-range-slider #time_range_slider
                      attr.id="{{ post.key }}_{{ employee.employeeCode }}_{{ day }}_time-range-slider"
                      class="time-range-slider"
                      [staffTimeSheetDisplay]="staffTimeSheetDisplay[ post.key + '_' + employee.employeeCode ][day]"
                      [staffTimeSheet]="staffTimeSheet[ post.key + '_' + employee.employeeCode ][day]" 
                      [employeeCode]="employee.employeeCode"
                      [postName]="post.key"
                      [day]="day"
                      [delegate]="this"
                      [disabled]="viewOnly || isClosedDate(day)"
                    ></time-range-slider>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <hr class="underline">
        </ng-container>
      </ng-container>

    <div class="col-7">
      <custom-slider-panel #assign_staff_panel [widthPx]=600>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" id="show-all-post-checkbox" class="custom-control-input" [(ngModel)]="showAllPostForReassignStaff" (change)="initReassignStaffPanel(false)" [disabled]="assignStaffFormParam.isLoading">
          <label for="show-all-post-checkbox" class="custom-control-label">
            {{'pages.sn.slider-panel.create-job-show-all-staff-checkbox' | translate}}
          </label> 
        </div>
        <job-card-assign-staff-form [parameters]="assignStaffFormParam"></job-card-assign-staff-form>
      </custom-slider-panel>
    </div>

  </div>
</div>