import { Component, Injector, OnInit } from "@angular/core";
import { JM, JMENUM, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { environment } from 'src/environments/environment';
import {
    TablexColumnHorizontalAlign,
    TablexColumnType,
    TablexColumnVerticalAlign,
  } from 'src/app/entity/enum/tablexColumnType';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { BasePage } from 'src/app/ui/model/base/base';
import { AppDelegate } from 'src/app/AppDelegate';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { Session } from '@services/session';
import { isEnabledFeature } from 'src/app/services/utility';

@Component({
    selector: 'app-sn-batch-submission-history',
    templateUrl: './sn-batch-report-submission-history.component.html',
    styleUrls: ['./sn-batch-report-submission-history.component.scss']
})

export class SnBatchReportSubmissionHistoryComponent extends BasePage implements OnInit {
    tablexParam: {[x:string]:any};
    pageCount: number
    currentPage : number = 1;
    pageSize : number = 10;
    pageSizeOptions : number[] = [10 , 25 , 100];
    pageTitle = JMLanguage.translate('component.sn-batch-report-submission.main.title');

    constructor(injector: Injector) {
        super(injector);
      }

    batchSignedOffResultId: string 
    ngOnInit() {
        //Hsd feature checking
        this.checkVisitable();
        //Permission Checking
        if (!this.authorizationService.hasPermission(JMENUM.Permission.HA_BATCH_SERVICE_REPORT_UPLOAD)) {
            this.router.navigate(['/']);
            AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
            return;
        }

        // get BatchSignedOffResultId
        this.batchSignedOffResultId = this.route.snapshot.paramMap.get('batchSignedOffResultId')

        //Init Tablex params
        this.initTablex();
        this.tablexParam['headers'] = [
            {
              name: JMLanguage.translate('pages.batch-signed-off-upload-history.table.header.upload-date'),
              type: TablexColumnType.Text,
              horizontalAlign: TablexColumnHorizontalAlign.Center,
              verticalAlign: TablexColumnVerticalAlign.Middle,
              class: 'col-5 d-flex align-items-center justify-content-center',
            },
            {
              name: JMLanguage.translate('pages.batch-signed-off-upload-history.table.header.result'),
              type: TablexColumnType.Html,
              horizontalAlign: TablexColumnHorizontalAlign.Center,
              verticalAlign: TablexColumnVerticalAlign.Middle,
              class: 'col-5 d-flex align-items-center justify-content-center',
            },
            {
              name: JMLanguage.translate('pages.batch-signed-off-upload-history.table.header.result-detail'),
              type: TablexColumnType.Html,
              horizontalAlign: TablexColumnHorizontalAlign.Center,
              verticalAlign: TablexColumnVerticalAlign.Middle,
              class: 'col-2 d-flex align-items-center justify-content-center',
            }
          ];
        //Request Call get Report Submission history
        this.requestBatchSignedOffHistory(this.currentPage , this.pageSize)
    }
    
    checkVisitable(){

      const isVisitable = isEnabledFeature(Session, JMCONSTANT.JMFeature.JM_HSD_SERVICE_INTEGRATION)? true : false;

      if(isVisitable){
        this.router.navigate(['/dashboard']);
        return;
      }

    }

    initTablex() {
        this.tablexParam = {
            isLoadingTable: false,
            enableSetPageSize: true,
            enablePagination: true,
            enableColFilter: false,
            enableSelectedRowCount: false,
            enableSort: false,
            tableRow: 'd-flex align-items-stretch',
            pageSizeOptions: this.pageSizeOptions,
            currentPageSize: this.pageSize,
            currentPage: this.currentPage,
            pageCount: this.pageCount,
            onPageNumberClicked: this.onPageNumberClicked,
            onPageSizeClicked: this.onPageSizeClicked,
   
        }
    }
    onPageNumberClicked = (pageNumber: number) => {
      this.requestBatchSignedOffHistory( pageNumber , null );
    };

    onPageSizeClicked = (pageSize: number) => {
      this.requestBatchSignedOffHistory( this.currentPage , pageSize );
    };

    /// TODO: add JMRequestHaBatchSubmissionHistoryList & JMResponseHaBatchSubmissionHistoryList
    requestBatchSignedOffHistory = async ( requestPage: number, requestPageSize: number ) => {
        requestPage = requestPage ? requestPage : this.tablexParam['currentPage'];
        requestPageSize = requestPageSize ? requestPageSize : this.tablexParam['currentPageSize'];
        
        this.tablexParam.isLoadingTable = true;
        const request: JM.JMRequestBatchSignedOffResult = new JM.JMRequestBatchSignedOffResult()
        request.pageNumber = requestPage;
        request.pageSize = requestPageSize;
        if(this.batchSignedOffResultId){
            request.batchSignedOffResultId = this.batchSignedOffResultId
        }

        const response: JM.JMResponse = await AppDelegate.sendJMRequest(request);
        this.tablexParam.isLoadingTable = false;

        if ( !response || !response.code || response.code != 200 || !response.payload ) {
            AppDelegate.openErrorBar(response);
            return;
        }

        if (response.payload && response.payload.records.length > 0) {
            this.tablexParam.totalRowCount = response.payload.totalCount
            this.tablexParam.pageCount = Math.ceil(response.payload.totalCount/ requestPageSize)
            this.tablexParam.content = this.parseResponseToTableContent(response.payload.records)
        }else{
          this.tablexParam['content']  = [];
          this.tablexParam['pageCount'] = 0;
          this.tablexParam['currentPage'] = 0;
        }
    }

    parseResponseToTableContent = (records: any[]) => {
        return records.map(el => {
            let downloadLink = environment.CCEP_FILE_HOST + '/files/' + el.importFileId;
            let statusContent = this.parseStatusToContent(el.status as JMENUM.BatchSignedOffResultStatus,  el.successCount, el.failedCount );
            return [
                el.createdAt ? moment( el.createdAt ).format(Constants.DATETIME_FORMAT_2) : '-',
                statusContent,
                `
                <a class='td-download fa-stack fa-1x brand-blue ${el.importFileId == null ? 'disabled' : ''}' href='${downloadLink}'>
                  <i class='fa fa-square fa-stack-2x'></i>
                  <i class='fa fa-download fa-stack-1x fa-inverse' aria-hidden='true'></i>
                </a>
                `,
          
            ]
        })

    }

    parseStatusToContent = (status : JMENUM.BatchSignedOffResultStatus , successCount : number , failedCount : number) => {
        switch (status) {
            case JMENUM.BatchSignedOffResultStatus.PENDING:
              return JMLanguage.translate('pages.batch-signed-off-upload-history.table.body.status.pending');
            case JMENUM.BatchSignedOffResultStatus.FINISHED:
              return `
              <div class='td-status'>
                <p>
                  <span>${JMLanguage.translate('pages.batch-signed-off-upload-history.table.body.result.success')}</span>
                  <span>:</span>
                  <span>${ typeof successCount === 'number' ? successCount : '-' }</span>
                </p>
                <p>
                  <span>${JMLanguage.translate('pages.batch-signed-off-upload-history.table.body.result.fail')}</span>
                  <span>:</span>
                  <span>${ typeof failedCount === 'number' ? failedCount : '-' }</span>
                </p>
              </div>
              `;
              default:
                return '-';
        }
    }

    toSnBatchHistory() {
        AppDelegate.navigate(['/sn/batch-complete/upload-history']);
      }
    
    toPmJobTimesheetHistory() {
        AppDelegate.navigate(['/pm/job/timesheet/upload-history']);
    }
}