import { Component, Injector, OnInit } from '@angular/core';
import { PostApprovalRequestSummaryRequest } from '@api/model/approval/post-approval-request-summary-request';
import { ApprovalService } from '@services/approval.service';
import { GetRoleAllRequest } from 'src/app/api/authorization/get-role-all-request';
import { PostPostSummaryRequest } from 'src/app/api/authorization/post-post-summary-request';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
import { JM, JMCONSTANT, JMENUM } from '@ccep/CCEPConnector-ts';
import { Session }  from 'src/app/services/session';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'settings',
  templateUrl: './settings.html',
  styleUrls: ['./settings.scss']
})
export class SettingsComponent extends BasePage implements OnInit {

  constructor(
    injector: Injector,
    private approvalService: ApprovalService
  ) {
    super(injector);
  }

  settingGroups: any[] = [];
  cellMap: any = {};
  workCenterOptions: string[] = []; 
  selectedWorkCenter: string;
  paramMap = {};

  ngOnInit() {
    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.workCenterOptions = JM.JMConnector.getAllWorkCentreCode();
    } else {
      this.workCenterOptions = this.authorizationService.getWorkCenters();
    }
    if (Session.selectedWorkCentre) {
      this.selectedWorkCenter = Session.selectedWorkCentre;
    } else if (this.workCenterOptions.length > 0){
      this.selectedWorkCenter = this.workCenterOptions[0];
      Session.setSelectedWorkCentre(this.selectedWorkCenter);
    }
    let workCentre = JM.JMConnector.getWorkCentre(this.selectedWorkCenter);
    if(workCentre && workCentre.HACluster){
      Session.setSelectedHACluster(workCentre.HACluster);
    }

    this.intialSettingGroups();
  }

  //Initial Information Function
  intialSettingGroups() {
    let workCentreMaintenance: any = {
      key: "work-centre-maintenance",
      description: "pages.setting.work-centre-maintenance",
      cells: this.initialworkCentreMaintenance(),
      permissions: [JMENUM.Permission.WCM_VIEW]
    };
    let personalSettings: any = {
      key: "personal-settings",
      description: "pages.setting.personal-settings",
      cells: this.initalPersonalSettings(),
    };
    let staticDataMaintenance: any = {
      key: "static-data-maintenance",
      description: "pages.setting.static-data-maintenance",
      cells: this.initalstaticDataMaintenance()
    };
    let adminstration: any = {
      key: "administration",
      description: "pages.setting.administration",
      cells: this.initalAdministration(),
      permissions:  [
        JMENUM.Permission.WCM_VIEW,
        JMENUM.Permission.USER_SETTING_VIEW,
        JMENUM.Permission.ROLE_SETTING_VIEW,
        JMENUM.Permission.CCS_ERROR_VIEW,
        JMENUM.Permission.FLMS_VIEW_ALL,
        JMENUM.Permission.FLMS_VIEW,
        JMENUM.Permission.DROPDOWN_SETTING_VIEW,
        JMENUM.Permission.DROPDOWN_SETTING_UPDATE,
        JMENUM.Permission.SPECIAL_REQUEST_UPDATE,
      ]
    };

    this.settingGroups = [workCentreMaintenance, personalSettings, staticDataMaintenance, adminstration].filter(group => this.hasPermissions(group.permissions))
      .map(group => {
        group.cells = group.cells.filter(cell => this.hasPermissions(cell.permissions));
        return group;
      });
    this.settingGroups = this.settingGroups.filter(group => {return group.cells.length>0});
    this.updateData();
  }

  initialworkCentreMaintenance() {
    this.cellMap["teams"] = {
      key: "teams", description: "pages.setting.teams",
      routing: "/setting/team-settings",
      icon: "../assets/svg/settings_icon_set_w_01.svg", 
      subTitle: null,
      permissions: [JMENUM.Permission.WCM_VIEW],
    }

    this.cellMap["officers"] = {
      key: "officers", description: "pages.setting.officers",
      routing: "/setting/officer-settings",
      icon: "../assets/svg/settings_icon_set_w_02.svg", 
      subTitle: null,
      permissions: [JMENUM.Permission.WCM_VIEW],
    }

    this.cellMap["general"] = {
      key: "general", description: "pages.setting.general",
      routing: "/setting/general-settings",
      permissions: [JMENUM.Permission.WCM_VIEW],
      icon: "../assets/svg/settings_icon_set_w_03.svg", 
      subTitle: null,
    }

    this.cellMap["routing"] = {
      key: "routing", description: "pages.setting.routing",
      routing: "/setting/routing-rules-list",
      icon: "../assets/svg/settings_icon_set_w_04.svg", 
      subTitle: null, 
      class: "",
      permissions: [JMENUM.Permission.WCM_VIEW],
    }

    return [this.cellMap["teams"], this.cellMap["officers"], this.cellMap["general"], this.cellMap["routing"]]
  }

  initalPersonalSettings() {
    this.cellMap["profile"] = {
      key: "profile", description: "pages.setting.profile",
      routing: "/setting/profile",
      icon: "../assets/svg/settings_icon_set_p_01.svg", 
      subTitle: Session.userInfo.name,
    }

    this.cellMap["auth"] = {
      key: "auth", description: "pages.authorization.setting-title",
      routing: "/setting/authorization",
      icon: "../assets/svg/settings_icon_set_p_02.svg", 
      class: "",
      permissions: [JMENUM.Permission.WCM_VIEW]
    }

    this.cellMap["approval"] = {
      key: "approval", description: "pages.approval.setting-title",
      routing: "/setting/approvalList",
      icon: "../assets/svg/settings_icon_set_p_02.svg", 
      class: ""
    }

    this.cellMap["posting"] = {
      key: "posting", description: "pages.setting.acting-appointment",
      routing: "/setting/posting",
      icon: "../assets/svg/settings_icon_set_p_03.svg", 
      class: "",
      permissions: [JMENUM.Permission.WCM_VIEW]
    }

    return [this.cellMap["profile"], this.cellMap["auth"], this.cellMap["approval"], this.cellMap["posting"]]
  }

  initalstaticDataMaintenance() {
    this.cellMap["contractors"] = {
      key: "contractors", description: "pages.setting.contracts",
      icon: "../assets/svg/settings_icon_set_s_01.svg", 
      subTitle: null,
      routing: "/setting/contractor",
      permissions: [JMENUM.Permission.WCM_VIEW],
    }
    this.cellMap["equipment"] = {
      key: "equipment", description: "pages.setting.equipment",
      icon: "../assets/svg/settings_icon_set_s_02.svg", 
      subTitle: null,
      routing: "/setting/ccs-equipment-tag-list",
      permissions: [JMENUM.Permission.WCM_VIEW],
    };
    this.cellMap["customers"] = {
      key: "customers", description: "pages.setting.customers",
      icon: "../assets/svg/settings_icon_set_s_03.svg", 
      subTitle: null,
      routing: "/setting/customers",
      permissions: [JMENUM.Permission.WCM_VIEW],
    }
    this.cellMap["locations"] = {
      key: "locations", description: "pages.setting.locations",
      icon: "../assets/svg/settings_icon_set_s_04.svg", 
      subTitle: null,
      routing: "/setting/locations",
      permissions: [JMENUM.Permission.WCM_VIEW],
    }
    this.cellMap["sla"] = {
      key: "sla", description: "pages.setting.sla",
      icon: "../assets/svg/settings_icon_sla.svg", 
      subTitle: null,
      routing: "/setting/sla",
      permissions: [JMENUM.Permission.WCM_VIEW],
    }

    return [this.cellMap["contractors"], this.cellMap["equipment"], this.cellMap["customers"], this.cellMap["locations"], this.cellMap["sla"]]
  }

  initalAdministration() {
      this.cellMap["role"] = {
        key: "role", description: "pages.setting.role",
        icon: "../assets/svg/ic_role_management.svg",
        routing: "/setting/admin/role",
        permissions: [JMENUM.Permission.ROLE_SETTING_VIEW],
      }
      this.cellMap["user"] = {
        key: "user", description: "pages.setting.user",
        icon: "../assets/svg/ic_user_management.svg",
        routing: "/setting/admin/user",
        permissions: [JMENUM.Permission.USER_SETTING_VIEW],
      }
      this.cellMap["ccsErrorLog"] = {
        key: "ccs-error-log", description: "pages.setting.ccs-error-log",
        icon: "../assets/svg/ic_error_log.svg",
        routing: "/setting/admin/ccs-error-log",
        permissions: [JMENUM.Permission.CCS_ERROR_VIEW],
      },
      this.cellMap["dropdown"] = {
        key: "dropdown", description: "pages.setting.dropdown",
        icon: "../assets/svg/settings_icon_fa-list.svg",
        routing: "/setting/admin/dropdown",
        permissions: [JMENUM.Permission.DROPDOWN_SETTING_VIEW],
      },
      this.cellMap["workCentres"] = {
        key: "work-centres", description: "pages.setting.work-centres",
        icon: "../assets/svg/settings_icon_fa-university.svg",
        routing: "/setting/admin/work-centres",
        permissions: [JMENUM.Permission.WORKCENTRES_SETTING_VIEW],
      },
      this.cellMap["haDefaults"] = {
        key: "ha-defaults", description: "pages.setting.ha-defaults",
        iconClass: "fas fa-user-friends gray-40",
        routing: "/setting/admin/ha-defaults",
        permissions: [JMENUM.Permission.HA_DEFAULTS_SETTING_VIEW],
      },
      this.cellMap["functionalLocation"] = {
        key: "functional-location", description: "pages.setting.functional-location",
        icon: "../assets/svg/settings_icon_functional_location.svg",
        routing: "/setting/admin/functional-location",
        permissions: [JMENUM.Permission.FLMS_VIEW_ALL,JMENUM.Permission.FLMS_VIEW],
      },
      this.cellMap["tagGroups"] = {
        key: "tag-groups", description: "pages.settings.hashtag-Group.page-title",
        icon: "../assets/svg/settings_icon_tag_group.svg",
        routing: "/setting/admin/hashtag-group",
        subTitle: null,
        permissions: [JMENUM.Permission.WCM_UPDATE_ALL],
      },
      this.cellMap["specialRequestAlert"] = {
        key: "special-request-alert", description: "pages.settings.special-request-alert",
        icon: "../assets/svg/settings_admin_sra.svg",
        routing: "/setting/admin/special-request-alert",
        subTitle: null,
        permissions: [JMENUM.Permission.SPECIAL_REQUEST_UPDATE],
      }
    const returnList = [this.cellMap["role"], this.cellMap["user"], this.cellMap["ccsErrorLog"],this.cellMap["workCentres"], this.cellMap["dropdown"], 
    this.cellMap["haDefaults"], this.cellMap["functionalLocation"], this.cellMap["tagGroups"]];
    if(Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.VVIP_SRA)) {
      returnList.push(this.cellMap["specialRequestAlert"]);
    }
    return returnList;
  }

  //Layout Event Function
  selectCentre() {
    Session.setSelectedWorkCentre(this.selectedWorkCenter);
    let workCentre = JM.JMConnector.getWorkCentre(this.selectedWorkCenter);
    if(workCentre.HACluster){
      Session.setSelectedHACluster(workCentre.HACluster);
    }
    this.updateData();
  }

  async requestTeamCount() {
    if (!this.selectedWorkCenter) return;

    const request = new JM.JMRequestTeamsTeamSummary();
    request.workCentreList = [this.selectedWorkCenter];
    request.includeSummary = false;
    request.isRoTeam = false;
  
    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['teams'].subTitle = response.payload.totalCount.toString();
  }

  async requestRoutingRuleCount() {
    if (!this.selectedWorkCenter) return;

    const request = new JM.JMRequestRoutingRulesRoutingRuleSummary();
    request.workCentres = [this.selectedWorkCenter];
    request.includeSummary = false;
    
    const response: JM.JMResponseRoutingRulesRoutingRuleSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (typeof response.payload.totalCount != "undefined") {
      this.cellMap['routing'].subTitle = response.payload.totalCount.toString();
    }
  }

  async requestOfficerCount() {
    if (!this.selectedWorkCenter) return;

    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.active = JMENUM.RequestActive.ACTIVE;
    request.authorizations = {'workCenters': this.selectedWorkCenter};
    request.includeSummary = false;

    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['officers'].subTitle = response.payload.totalCount.toString();
  }

  async requestGeneralResponsibleOfficer() {
    if (!this.selectedWorkCenter) return;

    const request:JM.JMRequestWorkCentresWorkCentreSummary = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.selectedWorkCenter];
    request.includeSummary = true;

    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records.length > 0) {
      let workCentre = response.payload.records[0];
      this.cellMap['general'].subTitle = workCentre.responsibleOfficer?workCentre.responsibleOfficer:'';
    } else {
      this.cellMap['general'].subTitle = '';
    }
  }
  
  async requestEquipmentCount() {
    if (!this.selectedWorkCenter) return;

    let wc = Session.workCentreDict[this.selectedWorkCenter];
    const request: JM.JMRequestEquipmentsEquipmentSummary = new JM.JMRequestEquipmentsEquipmentSummary();
    request.workCentre = (wc.enableHAEquipment)?AppDelegate.getWorkCentreUnderCluster(wc.HACluster):[this.selectedWorkCenter];
    request.includeSummary = false;
    request.active = JMENUM.RequestActive.ACTIVE;

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['equipment'].subTitle = response.payload.totalCount.toString();
  }

  async requestCustomerCount() {
    if (!this.selectedWorkCenter) return;

    const request: JM.JMRequestEquipmentsParameters = new JM.JMRequestEquipmentsParameters();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.workCentre = this.selectedWorkCenter;
    request.parameter = ["clientShortName"];
    request.includeSummary = false;

    const response: JM.JMResponseEquipmentsParameters = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['customers'].subTitle = response.payload.totalCount.toString();
  }

  async requestLocationCount() {
    if (!this.selectedWorkCenter) return;

    const request:JM.JMRequestEquipmentsParameters = new JM.JMRequestEquipmentsParameters();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.workCentre = this.selectedWorkCenter;
    request.parameter = ["location"];
    request.includeSummary = false;

    const response: JM.JMResponseEquipmentsParameters = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['locations'].subTitle = response.payload.totalCount.toString();
  }

  async requestRoleCount() {
    const request = new JM.JMRequestRolesAll(Constants.SYSTEM_NAME);
    request.systemName = Constants.SYSTEM_NAME;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (Array.isArray(response)) {
      this.cellMap['role'].subTitle = response.length.toString();
    }
  }

  async requestUserCount() {
    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.active = JMENUM.RequestActive.BOTH;
    request.includeSummary = false;
    
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['user'].subTitle = response.payload.totalCount.toString();
  }

  async requestPendingDelegationRequestFromCurrentWorkCentreCount() {
    if (!this.selectedWorkCenter) return;

    const request = new JM.JMRequestRequestSummary();
    request.includeSummary = false;
    request.requestWorkCentres = [this.selectedWorkCenter];
    request.status = [1];
    
    const response: JM.JMResponseRequestSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['auth'].subTitle = response.payload.totalCount.toString();
  }

  async requestPendingApprovalCount() {
    if (!this.selectedWorkCenter) return;

    this.cellMap['approval'].subTitle = '';
    let count = 0;
    
    // get sn/job pending approval count
    const request = new JM.JMRequestApprovalSummary();
    request.status = [JMENUM.ApprovalStatus.PENDING];
    request.system = ['CCEPJM'];
    request.pageNumber = 1;
    
    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    count = response.payload.totalCount;

    // get team pending approval count
    if (this.hasPermissions([JMENUM.Permission.REQUEST_APPROVE, JMENUM.Permission.REQUEST_REJECT])) {
      const request = new JM.JMRequestRequestSummary();
      request.includeSummary = false;
      request.approvalWorkCentres = [this.selectedWorkCenter];
      request.status = [1];
      request.pageNumber = 1;
      
      const response: JM.JMResponseRequestSummary = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      count = count + response.payload.totalCount;
    }
    this.cellMap['approval'].subTitle = count.toString();
  }

  async requestWorkCentreCount() {
    const request:JM.JMRequestWorkCentresWorkCentreSummary = new JM.JMRequestWorkCentresWorkCentreSummary();

    const response:JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['workCentres'].subTitle = response.payload.totalCount;
  }
  
  async requestDropdownCount() {
    const request:JM.JMRequestDropdownSummary = new JM.JMRequestDropdownSummary();

    const response:JM.JMResponseDropdownSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['dropdown'].subTitle = response.payload.totalCount;
  }

  async requestHADefaultsCount() {
    const request:JM.JMRequestSystemConfigsHADefaultSummary = new JM.JMRequestSystemConfigsHADefaultSummary();
    request.includeSummary = false;

    const response:JM.JMResponseSystemConfigsHADefaultSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['haDefaults'].subTitle = response.payload.totalCount;
  }

  async requestMaintenanceTermContract(page:number = 1) {
    let request:JM.JMRequestContractsMaintenanceTermContractSummary = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.workCentres = [Session.selectedWorkCentre];
    request.expired = JMENUM.RequestExpired.BOTH;
    request.includeSummary = false;

    const response:JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['contractors'].subTitle = (response.payload.totalCount)?response.payload.totalCount.toString():'0';
  }
  
  async requestSLACount(page:number=1) { 
    const request:JM.JMRequestEquipmentSLASummary = new JM.JMRequestEquipmentSLASummary();
    request.workCentres = [Session.selectedWorkCentre];
    request.includeExpired = true;
    request.includeSummary = false;

    const response: JM.JMResponseEquipmentSLASummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.cellMap['sla'].subTitle = (response.payload.totalCount)?response.payload.totalCount.toString():'0';
  }

  async requestTagGroupCount(){
    const request: JM.JMRequestHashtagGroupSummary = new JM.JMRequestHashtagGroupSummary();
    request.status = JMENUM.ActiveStatus.ACTIVE;
    request.includeSummary = false;

    const response: JM.JMResponseHashtagGroupSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    this.cellMap['tagGroups'].subTitle  = (response.payload.totalCount)?response.payload.totalCount.toString():'0';
  }

  updateData() {
    if(this.hasPermissions([JMENUM.Permission.WCM_VIEW])){
      // work centre
      this.requestTeamCount();
      this.requestOfficerCount();
      this.requestGeneralResponsibleOfficer();
      this.requestRoutingRuleCount(); 
      this.requestMaintenanceTermContract();
      
      // personal settings
      this.requestEquipmentCount();
      this.requestCustomerCount();
      this.requestLocationCount();
    }

    if (this.hasPermissions([JMENUM.Permission.ROLE_SETTING_VIEW])) {
      this.requestRoleCount();
    }
    if (this.hasPermissions([JMENUM.Permission.USER_SETTING_VIEW])) {
      this.requestUserCount();
    }
    if(this.hasPermissions([JMENUM.Permission.WORKCENTRES_SETTING_VIEW])){
      this.requestWorkCentreCount();
    }
    if(this.hasPermissions([JMENUM.Permission.DROPDOWN_SETTING_VIEW])){
      this.requestDropdownCount();
    }
    if(this.hasPermissions([JMENUM.Permission.HA_DEFAULTS_SETTING_VIEW])){
      this.requestHADefaultsCount();
    }

    if(this.hasPermissions([JMENUM.Permission.WORKCENTRES_SETTING_VIEW])){
      this.requestWorkCentreCount();
    }

    if(this.hasPermissions([JMENUM.Permission.DROPDOWN_SETTING_VIEW])){
      this.requestDropdownCount();
    }
    if(this.hasPermissions([JMENUM.Permission.WCM_VIEW])){
      this.requestSLACount();
    }

    if(this.hasPermissions([JMENUM.Permission.WCM_UPDATE_ALL])){
      this.requestTagGroupCount();
    }

    // delegation request
    this.requestPendingDelegationRequestFromCurrentWorkCentreCount();
    this.requestPendingApprovalCount();
    
  }


  // routing(cell: any) {
  //   let path = cell.routing;
  //   let param = this.getParams(cell.param);
  //   if (path) {
  //     let routerLink = param ? [path, param] : [path];
  //     this.router.navigate(routerLink);
  //   }
  // }

  hasPermissions(permissions?:string[]):Boolean {
    let valid = false;
    if (permissions) {
      for(let permission of permissions) {
        valid = valid || this.authorizationService.hasPermission(permission);
        if(valid) {
          break;
        }
      }
    } else if (!permissions || permissions.length == 0) {
      valid = true;
    }

    return valid;
  }

  // TODO: reload language
  onLanguageChanged() {
  }
  
}
