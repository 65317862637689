import { Component, Input, OnInit } from '@angular/core';
import { SliderPanelRowType } from '@enum/slider-panel-row-type';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';

@Component({
  selector: 'slider-panel',
  templateUrl: './slider-panel.component.html',
  styleUrls: ['./slider-panel.component.scss']
})
export class SliderPanelComponent implements OnInit {
  @Input() title: string;
  @Input() rows: any[];
  @Input() panelId: string;
  @Input() show: boolean;
  @Input() bottomListTitle: string;
  @Input() bottomList: any[];

  displayGroups: any[] = [];
  private rowMap: any = {};
  private bottomListKeys: string[] = [];
  errorMessage: string;
  private inputSource = new Subject<any[]>();

  sliderPanelRowType = SliderPanelRowType;

  constants = Constants; // for html use
 
  ngOnInit() {
    if (!this.panelId) this.panelId = "";
    document.addEventListener('mousedown', this.dismissSliderPanel);
    this.inputSource.pipe(
      debounceTime(200),
    ).subscribe(displayGroups=>{
      let buttons = displayGroups.filter(group => group.type == SliderPanelRowType.Button);
      
      for(let button of buttons){
        button["relatedGroupsCompleted"] = this.isRelatedGroupsCompletedForButton(button);
      }
    })
  }

  ngOnDestroy() {
    this.inputSource.unsubscribe();
    document.removeEventListener('click', this.dismissSliderPanel);
  }

  ngOnChanges() {
    this.displayGroups = this.rows;
    if(this.displayGroups){
      for (let group of this.displayGroups) {
        this.rowMap[group.id] = group;
      }
    }
    
    if (this.bottomList && this.bottomList.length > 0) {
      this.bottomListKeys = Object.keys(this.bottomList[0]);
    }
  }


  buttonClicked(index: number) {
    this.errorMessage = this.checkingValid();
    if (this.errorMessage == "") {
      let result = this.displayGroups.filter(group => 
        group.type != SliderPanelRowType.Button && group.type != SliderPanelRowType.Text &&  group.type != SliderPanelRowType.InformationRow
      );
      this.displayGroups[index].callback(result);
    }
  }

  radioButtonClicked(radioButtonObject: any, ) {

  }

  toggleExpandingGroup(group: any) {
    group.isExpended = !group.isExpended;
  }

  onExpandingGroupSelectAllClicked(group: any) {
    group.checked = !group.checked;
    group.cells.forEach(cell => {
      cell.checked = group.checked;
    })
  }

  onExpandingGroupCellClicked(group, cell) {
    cell.checked = !cell.checked;
    if (!cell.checked) {
      group.checked = false;
      if(cell.button){
        cell.button.callback(true);

      }
    } else {
      let selectAll = true;
      for (let cell of group.cells) {
        selectAll = selectAll && cell.checked;

        if (!selectAll) break;
      }

      group.checked = selectAll;
    }
  }

  checkingValid(): string {
    let message: string = "";
    for (let i = 0; i < this.displayGroups.length; i++) {
      let group = this.displayGroups[i]

      if (group.validators && group.validators.length > 0) {
        message += group.validators.map(validator => validator(group.value)).reduce((a, b) => a + "\n" + b);
        message += message.length > 0 ? "\n" : "";
      }
    }

    return message;
  }

  isRelatedGroupsCompletedForButton(button:any):boolean{
    let completed = true;
    if(!button.requireCompletedGroupIds || button.requireCompletedGroupIds.length == 0) return completed;

    for(let id of button.requireCompletedGroupIds){
      completed = completed && this.rowMap[id].value;
    }
    return completed;
  }

  onInputInformationChanged() {
    this.inputSource.next(this.displayGroups);
  }

  onDropDownMultipleTypeToSearch(group, event) {
    let groupValues = group.value ? group.value : [];
    let termValues = event.term.split(" ");
    let options = group.options.map(option => { return option.value})

    for (let i = 0; i < termValues.length - 1; i++) {
      if (!groupValues.includes(termValues[i]) && options.includes(termValues[i])) {
        groupValues.push(termValues[i]);
      }
    }

    group.value = groupValues.map(value => {return value});
  }

  dropDownMultipleTypeToSearchFilter(term: string, item: any) {
    let termValues = term.split(" ");
    term = termValues[termValues.length - 1];
    return item.value.toLowerCase().includes(term.toLowerCase());
  }

  dismissSliderPanel = (e) => {
    let sliderPanel = document.getElementById("slider-panel");
    const popupAlertBox = document.querySelector("app-pop-up-dialog");

    // Slider panel width + padding + margin + broder = 612
    if (!popupAlertBox && e.pageX < window.innerWidth - 612 && sliderPanel != null && sliderPanel.classList.contains("active")) {
      sliderPanel.classList.remove("active");
    }
  }
  
}
