<div>
  <div class="modal-header">
    <h4 class="modal-title">{{"modal-manuel-fax.title" | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onClosed()">
      <span aria-hidden="true"><i class="fa fa-times text-black"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group" *ngIf="faxDetail.teamName !== undefined">
        <label for="id-modalEFax-teamName">{{"modal-manuel-fax.team-name-label" | translate}}</label>
        <input type="text" 
          class="form-control" 
          id="id-teamName" 
          name="teamName"
          placeholder="{{ 'modal-manuel-fax.team-name-placeholder' | translate }}"
          [(ngModel)]="faxDetail.teamName"
        >
        <span class="validation-fail-message" *ngIf="uiShowWarning && !validInput(faxDetail.teamName)">{{ 'modal-manuel-fax.missing.team-name' | translate }}</span>
      </div>
      <div class="form-group" *ngIf="faxDetail.attention !== undefined">
        <label for="id-modal-attention">{{"modal-manuel-fax.attention-label" | translate}}</label>
        <input type="text" 
          class="form-control" id="id-attention" 
          name="attention"
          placeholder="{{'modal-manuel-fax.attention-label' | translate }}"
          [(ngModel)]="faxDetail.attention"
        >
        <span class="validation-fail-message" *ngIf="uiShowWarning && !validInput(faxDetail.attention)">{{ 'modal-manuel-fax.missing.attention' | translate }}</span>
      </div>
      <div class="form-group">
        <label for="id-modal-faxNumber">{{"modal-manuel-fax.fax-number-label" | translate}}</label>
        <input type="text" 
          class="form-control" 
          id="id-faxNumber" 
          name="faxNumber"
          placeholder="{{'modal-manuel-fax.fax-number-label' | translate }}"
          [(ngModel)]="faxDetail.faxNumber"
          required
        >
        <span class="validation-fail-message" *ngIf="uiShowWarning && !validInput(faxDetail.faxNumber)">{{ 'modal-manuel-fax.missing.fax-number' | translate }}</span>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <app-submit-button
      [buttonClass]="'standard brand-red'" 
      [text]="'modal-manuel-fax.back-button'"
      [iconClass]="'fa-times'"
      [isDisabled]="uiIsLoading"
      [id]="'id-back_button'"
      (onClick)="onClosed()"
    >
    </app-submit-button>
    <app-submit-button 
      [buttonClass]="'standard brand-blue'" 
      [text]="'modal-manuel-fax.resend-button'"
      [iconClass]="'fa-fax'"
      [isDisabled]="uiIsLoading"
      [isLoading]="uiIsLoading"
      [id]="'id-resend_button'"
      (onClick)="onResend()"
    >
    </app-submit-button>
  </div>
</div>