<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'workCenter'"></app-sidebar>

  <!-- Page Content -->
  <div id="content">
    <ng-container *ngIf="isLoadingWorkCentre; else contentDetail">
      <div class="row mt-5">
        <div class="col d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #contentDetail>   
      <ng-container *ngIf="!hasWorkCentreInfo; else workCentreDetail">
        <div class="row mt-5">
          <div class="col d-flex justify-content-center h5">
            {{ 'global.no-record-found' | translate}}
          </div>
        </div>
      </ng-container>
      <ng-template #workCentreDetail>
        <form [formGroup]="workCentreForm">
          <div class="row justify-content-around">
            <div class="col-12 col-md-4 px-0">
              <div class="row justify-content-start">
                <h2 class="font-size-l"> {{"pages.general-settings.summary" | translate}} </h2>
              </div>
              <div class="row justify-content-start my-2 information-row">
                <div class="col-4"> {{"pages.general-settings.work-center" | translate}} </div>
                <div class="division"></div>
                <div class="col-auto" id="settings-work-centre_work-center_text">
                  {{selectedWorkCenter}}
                </div>
              </div>
              <div class="row justify-content-start my-2 information-row">
                <div class="col-4"> {{'pages.general-settings.responsible-officer' | translate}} </div>
                <div class="col-8">
                  <ng-select
                    id="settings-work-centre_responsible-officer_inputbox"
                    [items]="workCentrePostList"
                    [loading]="isLoadingPostList"
                    [clearable]="false"
                    formControlName="responsibleOfficer">
                  </ng-select>
                </div>
              </div>
              <div class="row justify-content-start my-2 information-row">
                <div class="col-4"> {{"pages.general-settings.ccs-operator" | translate}} </div>
                <div class="col-8">
                  <ng-select
                    id="settings-work-centre_ccs-operator_inputbox"
                    [items]="workCentrePostList"
                    [loading]="isLoadingPostList"
                    [clearable]="false"
                    formControlName="ccsAccount">
                  </ng-select>
                </div>
              </div>
              <div class="row justify-content-start">
                <h2 class="font-size-l">{{"pages.general-settings.contact" | translate}}</h2>
              </div>
              <div class="row justify-content-around my-2 contact-row">
                <div class="col-6 custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="enable-fax_checkbox"
                    formControlName="enableHotline"
                    (change)="onEnableHotlineChanged($event)">
                  <label class="custom-control-label" for="enable-fax_checkbox">
                    {{'pages.general-settings.hotline' | translate}}
                  </label>
                </div>
                <input type="text" class="col-6 form-control" maxlength="8"
                  formControlName="defaultHotline"
                  (blur)="inputNumber($event)" 
                  (keyup)="inputNumber($event)">
              </div>
              <div class="row justify-content-around my-2 contact-row">
                <div class="col-6 custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="enable-hotline_checkbox"
                    formControlName="enableFaxNumber"
                    (change)="onEnableFaxChanged($event)">
                  <label class="custom-control-label" for="enable-hotline_checkbox">
                    {{'pages.general-settings.fax-number' | translate}}
                  </label>
                </div>
                <input type="text" class="col-6 form-control" maxlength="8"
                  formControlName="defaultFaxNumber"
                  (blur)="inputNumber($event)" 
                  (keyup)="inputNumber($event)">
              </div>
            </div>
            <div class="col-12 col-md-7">
              <div class="row">
                <app-office-hours-table [officeHoursParam]='officeHoursParam' class='col-md no-gutters'></app-office-hours-table>
              </div>
              <div class="row mt-3">
                <app-response-time-table [responseTimeParam]='responseTimeParam' class='col-md no-gutters'></app-response-time-table>
              </div>
            </div>
          </div>
          <ng-container *ngIf="hasEditionPermission">
            <div class="row justify-content-center row-space">
              <app-submit-button 
                [id]="'settings-work-centre_save_button'"
                [text]="'global.save'"
                [buttonClass]="'standard brand-amber'"
                [iconClass]="'fa-save'"
                [isDisabled]="isSaving"
                [isLoading]="isSaving"
                (onClick)="onSaveBtnClicked()" 
              ></app-submit-button>
            </div>
          </ng-container>
        </form>

      </ng-template>
    </ng-template>

  </div>
</div>