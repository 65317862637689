import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { InputFieldType } from 'src/lib/ui-component/custom-fields-form/input-field-type.enum';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-settings-ha-defaults',
  templateUrl: './settings-ha-defaults.component.html',
  styleUrls: ['./settings-ha-defaults.component.scss']
})
export class SettingsHaDefaultsComponent extends BasePage implements OnInit {
  @ViewChild('no_rule_team_form', { static: true }) defaultTeamFormElem;
  @ViewChild('hospital_code_team_form', { static: true }) hospitalCodeTeamFormElem;

  // Function: Create handling team by hospital code 
  isEnableCreateTeamByCode: boolean = true;


  // component config & data
  defaultTeamFormInput: any = {};
  defaultTeamFormParam: any = {};
  
  hospitalCodeTeamFormParam: any = {};
  hospitalCodeTeamFormInput: any = {};

  //Work Centre
  workCenterOptions: string[] = []; 
  defaultTeamWorkCentrePageNumber: number = 1;
  defaultTeamWorkCentrePageSize: number = 100;
  hospitalTeamWorkCentrePageNumber: number = 1;
  hospitalTeamWorkCentrePageSize: number = 100;
  searchWorkCentreKeyword: string;

  //Team
  defaultTeamTeamPageNumber: number = 1;
  defaultTeamTeamPageSize: number = 100;
  hospitalTeamTeamPageNumber: number = 1;
  hospitalTeamTeamPageSize: number = 100;
  

  hasEditPermission: boolean;
  hasDefaultTeam: boolean;
  teamId: string;
  workCentreList: any;
  hospitalCodeTeamRecords: any = [];
  isDisabledEditHospitalTeam: any = [];
  isDisabledAddTeam:boolean;

  private searchDefaultTeamWorkCentreObserver = new Subject<any[]>();
  private searchHospitalTeamWorkCentreObserver = new Subject<any[]>();

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.hasDefaultTeam = false;
    this.initPermission();
    this.initDefaultTeamFormElem();
    this.initHospitalCodeTeamElem();

    this.requestHSDWorkCentreSummary(true);
    this.requestHSDWorkCentreSummary(false);
    this.requestHAClusterSummary();
    this.requestHADefaultSummary(true);
    this.requestHADefaultSummary(false);
    this.requestAllHospitalCode();
    this.defaultTeamFormElem.copyInputData(this.defaultTeamFormInput);
    this.hospitalCodeTeamFormElem.copyInputData(this.hospitalCodeTeamFormInput);
    this.initObserverPipe();
  }
 
  //----- API -----//
  async requestHSDWorkCentreSummary(defaultTeam?){
    const request = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.enableHAEquipment = JMENUM.RequestActive.ACTIVE;
    if (defaultTeam) {
      this.defaultTeamFormInput.handlingTeam = '';
      request.pageNumber = this.defaultTeamWorkCentrePageNumber;
      request.pageSize = this.defaultTeamWorkCentrePageSize;
    } else {
      this.hospitalCodeTeamFormInput.handlingTeam = '';
      request.pageNumber = this.hospitalTeamWorkCentrePageNumber;
      request.pageSize = this.hospitalTeamWorkCentrePageSize;
    }

    if (this.searchWorkCentreKeyword) {
      request.filter = {
        workCentreCode: this.searchWorkCentreKeyword
      };
    }
    
    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let options = response.payload.records.map(workCentre => {
      let option = {
        id: workCentre.workCentreCode,
        key: workCentre.workCentreCode,
        value: workCentre.workCentreCode,
        label: workCentre.workCentreCode
      };
      return option; 
    });

    if (defaultTeam) {
      if (this.defaultTeamWorkCentrePageNumber == 1) {
        this.defaultTeamFormParam.fieldOptions['workCentre'] = options;
      } else if (response.payload.totalCount > this.defaultTeamFormParam.fieldOptions['workCentre'].length) {
        this.defaultTeamFormParam.fieldOptions['workCentre'] = this.defaultTeamFormParam.fieldOptions['workCentre'].concat(options);
      }
    } else {
      if (this.hospitalTeamWorkCentrePageNumber == 1) {
        this.hospitalCodeTeamFormParam.fieldOptions['workCentre'] = options;
      } else if (response.payload.totalCount > this.hospitalCodeTeamFormParam.fieldOptions['workCentre'].length) {
        this.hospitalCodeTeamFormParam.fieldOptions['workCentre'] = this.hospitalCodeTeamFormParam.fieldOptions['workCentre'].concat(options);
      }
    }
  }

  async requestAllTeamName() {
    let idList = this.hospitalCodeTeamRecords.map(record => record.handlingTeam);
    if(this.defaultTeamFormInput.handlingTeam){
      idList.push(this.defaultTeamFormInput.handlingTeam);
    }
    const request = new JM.JMRequestTeamsTeamSummary();
    request.idList = idList;
    request.parameters = ["_id", "name"];
    request.pageSize = 1000;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.hospitalCodeTeamRecords.forEach(record => {
      let handlingTeam = response.payload.records.find(team => team._id == record.handlingTeam);
      record.name = (handlingTeam)?handlingTeam['name']:undefined;
    });
  }

  async requestTeamSummary(defaultTeam?) {
    let request = new JM.JMRequestTeamsTeamSummary();
    if(defaultTeam){
      request.workCentreList = [this.defaultTeamFormInput.workCentre];
      request.pageNumber = this.defaultTeamTeamPageNumber;
      request.pageSize = this.defaultTeamTeamPageSize;
    }else{
      request.workCentreList = [this.hospitalCodeTeamFormInput.workCentre];
      request.pageNumber = this.hospitalTeamTeamPageNumber;
      request.pageSize = this.hospitalTeamTeamPageSize;
    }
    request.isRoTeam = false;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let options = response.payload.records.map(data => {
      let option = {
        id: data._id,
        key: data._id,
        value: data._id,
        label: data.name,
      };
      return option;
    });
    if (defaultTeam) {
      if (response.payload.totalCount <= 0 ) {
        this.defaultTeamFormInput._id = null;
        this.defaultTeamFormInput.handlingTeam = '';
      }
      if (this.defaultTeamTeamPageNumber == 1) {
        this.defaultTeamFormParam.fieldOptions['handlingTeam'] = options;
      } else if (response.payload.totalCount > this.defaultTeamFormParam.fieldOptions['handlingTeam'].length){
        this.defaultTeamFormParam.fieldOptions['handlingTeam'] = this.defaultTeamFormParam.fieldOptions['handlingTeam'].concat(options);
      }
    } else {
      if (response.payload.totalCount <= 0 ) {
        this.hospitalCodeTeamFormInput.handlingTeam = '';
      }
      if (this.hospitalTeamTeamPageNumber == 1) {
        this.hospitalCodeTeamFormParam.fieldOptions['handlingTeam'] = options;
      } else if (response.payload.totalCount > this.hospitalCodeTeamFormParam.fieldOptions['handlingTeam'].length) {
        this.hospitalCodeTeamFormParam.fieldOptions['handlingTeam'] = this.hospitalCodeTeamFormParam.fieldOptions['handlingTeam'].concat(options);
      }
    }
  }

  async requestHADefaultSummary(defaultRule?){
    const request = new JM.JMRequestSystemConfigsHADefaultSummary();
    request.default = false;
    if(defaultRule){
      request.default = true;
    }
    
    const response: JM.JMResponseSystemConfigsHADefaultSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.totalCount > 0) {
      if (defaultRule) {
        this.hasDefaultTeam = true;
        this.defaultTeamFormInput._id = response.payload.records[0]._id;
        this.defaultTeamFormInput.workCentre = response.payload.records[0].workCentre;
        this.defaultTeamFormInput.handlingTeam = response.payload.records[0].handlingTeam;
        this.defaultTeamFormElem.copyInputData(this.defaultTeamFormInput);
        this.onChangeDefaultTeamWorkCentre();
      } else {
        this.hospitalCodeTeamRecords = response.payload.records;
        this.requestAllTeamName();
      }
    }
  }

  async requestHAClusterSummary(){
    const request = new JM.JMRequestSystemConfigsHAClusterSummary();

    const response: JM.JMResponseSystemConfigsHAClusterSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.hospitalCodeTeamFormParam.fieldOptions['HACluster'] = response.payload.records.map(data => {
      let option = {
        id: data.clusterName,
        key: data.clusterName,
        value: data.clusterName,
        label: data.clusterName,
      }
      return option;
    });
  }

  async requestAllHospitalCode() {
    const request = new JM.JMRequestEquipmentsAllHospitalCode();

    const response: JM.JMResponseEquipmentsAllHospitalCode = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.hospitalCodeTeamFormParam.fieldOptions['hospitalCode'] = response.payload.map(data => {
      let option = {
        id: data,
        key: data,
        value: data,
        label: data,
      }
      return option;
    });
  }

  async requestCreateHADefault(defaultTeam){
    const request = new JM.JMRequestSystemConfigsCreateHADefault();

    if (defaultTeam) {
      request.workCentre = this.defaultTeamFormInput.workCentre;
      request.handlingTeam = this.defaultTeamFormInput.handlingTeam;
    } else {
      request.hospitalCode = this.hospitalCodeTeamFormInput.hospitalCode;
      request.HACluster = this.hospitalCodeTeamFormInput.HACluster;
      request.workCentre = this.hospitalCodeTeamFormInput.workCentre;
      request.handlingTeam = this.hospitalCodeTeamFormInput.handlingTeam;
    }
    
    const response: JM.JMResponseSystemConfigsCreateHADefault = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    if (defaultTeam) {
      this.hasDefaultTeam = true;
      this.defaultTeamFormInput._id = response.payload._id;
      this.defaultTeamFormElem.copyInputData(this.defaultTeamFormInput);
      this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.created-default-team"));
    } else {
      this.requestHADefaultSummary(false);
      this.resetHospitalTeamForm();
      this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.created-handling-team-by-hospital-code"));
    }
  }

  async requestUpdateHADefault(defaultTeam){
    const request = new JM.JMRequestSystemConfigsUpdateHADefault();
    
    if (defaultTeam) {
      request._id = this.defaultTeamFormInput._id;
      request.workCentre = this.defaultTeamFormInput.workCentre;
      request.handlingTeam = this.defaultTeamFormInput.handlingTeam;
    } else {
      request._id = this.teamId;
      request.hospitalCode = this.hospitalCodeTeamFormInput.hospitalCode;
      request.HACluster = this.hospitalCodeTeamFormInput.HACluster;
      request.workCentre = this.hospitalCodeTeamFormInput.workCentre;
      request.handlingTeam = this.hospitalCodeTeamFormInput.handlingTeam;
    }
    
    const response: JM.JMResponseSystemConfigsUpdateHADefault = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (defaultTeam) {
      this.defaultTeamFormElem.copyInputData(this.defaultTeamFormInput);
      this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.updated-default-team"));
    } else {
      this.requestHADefaultSummary(false);
      this.resetHospitalTeamForm();
      this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.updated-handling-team-by-hospital-code"));
    }
  }

  //----- UI Function -----//
  onClickAddTeamBtn(){
    // this function is disabled, check the boolean isEnableCreateTeamByCode
    this.hospitalCodeTeamFormParam.isEditMode = true;
    this.hospitalCodeTeamFormParam.onChangeBtn[0].text = JMLanguage.translate("pages.settings.ha-defaults.create");
  }

  onDiscardDefaultTeam = () => {
    this.defaultTeamFormElem.discardForm();
  }

  onDiscardHospitalCodeTeam = () => {
    this.hospitalCodeTeamFormElem.discardForm();
  }

  enableEditMode = () => {
    this.defaultTeamFormParam.isEditMode = true;
  }

  onClickUpdateHADefault = () => {
    this.defaultTeamFormParam.showFieldsError = true;
    if(this.defaultTeamFormInput.workCentre && this.defaultTeamFormInput.handlingTeam){
      if(this.hasDefaultTeam){
        this.requestUpdateHADefault(true);
      }else{
        this.requestCreateHADefault(true);
      }
    }else{
      this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.please-input-all-mandatory-fields"));
    }
  }

  onUpdateHospitalCodeTeam = () => {
    this.hospitalCodeTeamFormParam.showFieldsError = true;
    if(this.hospitalCodeTeamFormInput.workCentre && this.hospitalCodeTeamFormInput.handlingTeam 
      && this.hospitalCodeTeamFormInput.hospitalCode && this.hospitalCodeTeamFormInput.HACluster
    ){
      let codeExist = this.hospitalCodeTeamRecords.find(team => team.hospitalCode == this.hospitalCodeTeamFormInput.hospitalCode );
      if(this.teamId){
        if(!codeExist || codeExist['_id'] == this.teamId){
          this.requestUpdateHADefault(false);
        }else{
          this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.hospital-code-already-exist"))
          return;
        }
      }else{ 
        if(codeExist){
          this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.hospital-code-already-exist"))
          return;
        }else{
          this.requestCreateHADefault(false);
        }
      }
    }else{
      this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.please-input-all-mandatory-fields"));
    }
  }

  onChangeDefaultTeamWorkCentre = () => {
    this.defaultTeamTeamPageNumber = 1;
    this.requestTeamSummary(true);
    this.validateWorkCentre();
  }

  onLoadDefaultTeamWorkCentre = () => {
    this.defaultTeamWorkCentrePageNumber += 1;
    this.requestHSDWorkCentreSummary(true);
  }

  onSearchDefaultTeamWorkCentre = (event) => {
    this.searchWorkCentreKeyword = event.term;
    this.searchDefaultTeamWorkCentreObserver.next();
  }

  onLoadDefaultTeamHandlingTeam = () => {
    this.defaultTeamTeamPageNumber += 1;
    this.requestTeamSummary(true);
  }

  onChangeHospitalCodeWorkCentre = () => {
    this.hospitalTeamTeamPageNumber = 1;
    this.requestTeamSummary(false);
  }

  onLoadHospitalCodeWorkCentre = () => {
    this.hospitalTeamWorkCentrePageNumber += 1;
    this.requestHSDWorkCentreSummary(false);
  }

  onSearchHospitalCodeWorkCentre = (event) => {
    this.searchWorkCentreKeyword = event.term;
    this.searchHospitalTeamWorkCentreObserver.next();
  }

  onLoadHospitalCodeHandlingTeam = () => {
    this.hospitalTeamTeamPageNumber += 1;
    this.requestTeamSummary(false);
  }

  validateWorkCentre(){
    if(this.defaultTeamFormInput.workCentre){
      this.defaultTeamFormParam.fieldList.find(field => field.id == 'handlingTeam').disabled = false;
    }else{
      this.defaultTeamFormParam.fieldList.find(field => field.id == 'handlingTeam').disabled = true;
    }
  }

  onClickEditHospitalTeam(data, index){
    this.hospitalCodeTeamFormParam.onChangeBtn[0].text = JMLanguage.translate("pages.settings.ha-defaults.update");
    this.hospitalCodeTeamFormParam.isEditMode = true;
    this.teamId = data._id;
    this.hospitalCodeTeamFormInput = {
      hospitalCode: data.hospitalCode,
      HACluster: data.HACluster,
      workCentre: data.workCentre,
      handlingTeam: data.handlingTeam
    };
    this.requestTeamSummary(false);
  }

  resetHospitalTeamForm(){
    this.hospitalCodeTeamFormParam.showFieldsError = false;
    this.teamId = '';
    this.hospitalCodeTeamFormParam.isEditMode = false;
    this.hospitalCodeTeamFormInput = {
      hospitalCode: '',
      HACluster: '',
      workCentre: '',
      handlingTeam: ''
    };
    this.hospitalCodeTeamFormElem.copyInputData(this.hospitalCodeTeamFormInput);
  }

  //------ Init Component ------//
  initPermission(){
    this.checkViewPermission(JMENUM.Permission.HA_DEFAULTS_SETTING_VIEW);
    this.hasEditPermission = this.authorizationService.hasPermission(JMENUM.Permission.HA_DEFAULTS_SETTING_UPDATE);
  }

  initObserverPipe(){
    this.searchDefaultTeamWorkCentreObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.defaultTeamFormParam.fieldOptions['workCentre'] = [];
      this.defaultTeamWorkCentrePageNumber = 1;
      this.requestHSDWorkCentreSummary(true);
    });

    this.searchHospitalTeamWorkCentreObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.hospitalCodeTeamFormParam.fieldOptions['workCentre'] = [];
      this.hospitalTeamWorkCentrePageNumber = 1;
      this.requestHSDWorkCentreSummary(false);
    });
  }

  initDefaultTeamFormElem(){
    this.defaultTeamFormParam.showFieldsError = false;
    this.defaultTeamFormParam.isEditMode = true;
    this.defaultTeamFormParam.instruction = JMLanguage.translate("pages.settings.ha-defaults.default-team-instruction");
    this.defaultTeamFormParam.fieldOptions = {};
    this.defaultTeamFormParam.onChangeBtn = [
      { id: 'default-team-update-btn', customClass: 'brand-blue',  onClick: this.onClickUpdateHADefault, text: JMLanguage.translate('pages.setting.work-centres.button.update')},
      { id: 'default-team-discard-btn', icon: 'fas fa-times', onClick: this.onDiscardDefaultTeam }
    ];

    this.defaultTeamFormParam.header = {
      title: JMLanguage.translate("pages.settings.ha-defaults.default-team-heading") ,
      customClass: 'bold font-size-m'
    }

    this.defaultTeamFormParam.fieldList = [
      { 
        id: 'workCentre', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: this.onChangeDefaultTeamWorkCentre, 
        onSearch: this.onSearchDefaultTeamWorkCentre,
        scrollToEnd: this.onLoadDefaultTeamWorkCentre,
        name: JMLanguage.translate("pages.settings.ha-defaults.work-centre")
      },
      { 
        id: 'handlingTeam', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        disabled: true,
        onChange: () => {},
        onSearch: () => {},
        scrollToEnd: this.onLoadDefaultTeamHandlingTeam,
        name: JMLanguage.translate("pages.settings.ha-defaults.handling-team")
      },
    ];
  }

  initHospitalCodeTeamElem(){
    this.hospitalCodeTeamFormParam.displayFieldAsCol = true;
    this.hospitalCodeTeamFormParam.showFieldsError = false;
    this.hospitalCodeTeamFormParam.isEditMode = false;
    this.hospitalCodeTeamFormParam.fieldOptions = {};
    this.hospitalCodeTeamFormParam.fieldRowClass = 'col-3';
    this.hospitalCodeTeamFormParam.onChangeBtn = [
      { id: 'hospital-team-update-btn', customClass: 'brand-blue',  onClick: this.onUpdateHospitalCodeTeam, text:''},
      { id: 'hospital-team-discard-btn', icon: 'fas fa-times', onClick: this.onDiscardHospitalCodeTeam }
    ];

    this.hospitalCodeTeamFormParam.header = {
      title: JMLanguage.translate("pages.settings.ha-defaults.hospital-code-team-heading") ,
      customClass: 'bold font-size-m'
    }
    this.hospitalCodeTeamFormParam.fieldList = [
      { 
        id: 'hospitalCode', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        disabled: false,
        onChange:() => {}, 
        onSearch: () => {},
        scrollToEnd: () => {},
        name: JMLanguage.translate("pages.settings.ha-defaults.hospital-code")
      },
      { 
        id: 'HACluster', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: () => {}, 
        onSearch: () => {},
        scrollToEnd: () => {},
        name: JMLanguage.translate("pages.settings.ha-defaults.ha-cluster")
      },
      { 
        id: 'workCentre', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: this.onChangeHospitalCodeWorkCentre, 
        onSearch: this.onSearchHospitalCodeWorkCentre,
        scrollToEnd: this.onLoadHospitalCodeWorkCentre,
        name: JMLanguage.translate("pages.settings.ha-defaults.work-centre") 
      },
      { 
        id: 'handlingTeam', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: () => {},
        onSearch: () => {},
        scrollToEnd: this.onLoadHospitalCodeHandlingTeam,
        name: JMLanguage.translate("pages.settings.ha-defaults.handling-team") 
      },
    ];
  }
}
