<div class="container">
    <div class="row justify-content-md-center no-gutters">
        <div class="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
            <img class="w-100" src="/assets/img/no_permission.png" alt="No Permission Image"/>
        </div>
        <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 right-box">
            <div class="h-100 d-flex flex-column align-items-center">
                <div class="h-50 d-flex justify-content-center flex-column align-items-center">
                    <div>{{'global.no-permission.1' | translate}}</div>
                    <div>{{'global.no-permission.2' | translate}}</div>
                </div>
                <div class="h-50 d-flex align-items-center">
                    <app-submit-button [id]="'no_permission_go_to_homepage_button'"
                        [text]="'pages.no-permission.go-to-homepage'" [buttonClass]="'standard brand-blue'"
                        (onClick)="navigateToDashBoard()"></app-submit-button>
                </div>
            </div>
        </div>
    </div>
</div>