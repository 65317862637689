<div class="font-size-xl m-0 mb-2 px-2">
  {{ "component.ccs-information.title" | translate}}
</div>

<hr class="underline" />

<div class="row align-items-center">
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{ "component.ccs-information.ccs-so-number" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 pr-0 ">
    <app-display-text-field id="ccs-information-ccs-so-number" [textField]="ccsServiceOrderNumber" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
    <button *ngIf="componentParameters.isShowPatchCcsSoNumberButton && componentParameters.isShowPatchCcsSoNumberButton()" class="btn btn-sm btn-patch-cssso-no" (click)="componentParameters.onClickPatchCcsSoNumber()"
    [disabled]="!this.jobCard.matType || !this.jobCard.orderType">
      <span>{{'pages.sn.patch-ccs-so-number' | translate}}</span>
    </button>
  </div>
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.ccs-information.sla-number" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ccs-information-sla-number" [textField]="jobCard?.serviceLevelAgreementNumber" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.ccs-information.sla-item-number" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ccs-information-sla-item-number" [textField]="jobCard?.serviceLevelAgreementItem" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>
</div>
