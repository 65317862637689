import { AfterViewInit, Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Permission } from '@enum/permission';
import { Priority } from '@enum/priority';
import { TablexColumnType } from '@enum/tablexColumnType';
import { isEmailAdress, isPhoneNumber, isTimeString } from 'src/app/presenter/validators/validator';
import { BasePage } from "src/app/ui/model/base/base";
import { Session }  from 'src/app/services/session';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
@Component({
  selector: "team-settings",
  templateUrl: "./team-settings.html",
  styleUrls: ["./team-settings.scss"]
})

export class TeamSettingsComponent extends BasePage implements OnInit, AfterViewInit {
  @ViewChild("inhouseTeamSelector", { static: true }) inhouseTeamSelectorView;
  @ViewChild("contractorTeamSelector", { static: true }) contractorTeamSelectorView;

  pageTitle: string;

  officeHoursParam                  : {} = {};
  timeIndex = {
    start                           : 1,
    end                             : 2
  }
  responseTimeParam                 : {} = {};
  priorityArray: string[]           = [JMENUM.JMPriority.NonUrgent.toString(), JMENUM.JMPriority.Urgent.toString(), JMENUM.JMPriority.Emergency.toString()];
  contactSequenceArray              = [0, 1, 2, 3, 4];

  saveButtonDisabled                = false;
  deleteButtonDisabled              = false;
    
  inHouseContactSequencesViewData   : any;
  
  hasHaCluster                      = false; 
  isLoadingTeams                    = true;
  isLoadingInHouseHandlingTeams     = false;
  selectedWorkCentre                : string;
  selectedWorkCentreObj             : JMOBJ.WorkCentre = null;
  hasCreationPermission             : boolean = false;
  hasEditionPermission              : boolean = false;
  
  inHouseHandlingTeamOption         : JMOBJ.Team[] = null;
  selectedTeam                      : JMOBJ.Team = null;
  defaultFaxNumber                  : string;
  selectedTeamContractNumber        : string;

  workCentreOfficerArray: string[] = undefined;
  uiLoadingWorkCentreOfficer: boolean = false;
  uiEmailRecipientOption: string[] = undefined;
  isLoadedAllWorkCentreOffcer: boolean = false;

  errorFields:any = {};

  constructor(injector: Injector) {
    super(injector);

    this.hasCreationPermission    = this.authorizationService.hasPermission(Permission.wcmUpdate);
    this.selectedWorkCentre       = Session.selectedWorkCentre;
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.team.page-title", [Session.selectedWorkCentre]);
    this.checkViewPermission(Permission.wcmView);
    this.checkWorkCenterPermission(this.selectedWorkCentre);
  }
  
  ngAfterViewInit() {
    this.requestWorkCentre();
    this.requestInhouseTeamList();
  }

  //---------------------------------------------------------------------------
  // view functions
  private updateOfficeHoursView(team: JMOBJ.Team) {
    let operationPeriod = team.operationPeriod;
    this.officeHoursParam = {
      componentId: 'settings-team_office-hour',
      isLoadingTable: false,
      isEditMode: false,
      hasEditionPermission: this.hasEditionPermission,
      onEditButtonClicked: () => {
        this.officeHoursParam['isEditMode'] = true;
      },
      // table content
      headers: [
        { name: 'global.day-of-week', type: TablexColumnType.Text, class: 'w-40', },
        { name: 'global.start', type: TablexColumnType.Input, class: 'w-30', },
        { name: 'global.end', type: TablexColumnType.Input, class: 'w-30', },
      ],
      content: [
        [
          'global.monday',
          { id: '', dayOfWeek: 1, name: 'global.monday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 1, name: 'global.monday', timeType: 'global.end', value: '' }
        ],
        [
          'global.tuesday',
          { id: '', dayOfWeek: 2, name: 'global.tuesday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 2, name: 'global.tuesday', timeType: 'global.end', value: '' }
        ],
        [
          'global.wednesday',
          { id: '', dayOfWeek: 3, name: 'global.wednesday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 3, name: 'global.wednesday', timeType: 'global.end', value: '' }
        ],
        [
          'global.thursday',
          { id: '', dayOfWeek: 4, name: 'global.thursday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 4, name: 'global.thursday', timeType: 'global.end', value: '' }
        ],
        [
          'global.friday',
          { id: '', dayOfWeek: 5, name: 'global.friday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 5, name: 'global.friday', timeType: 'global.end', value: '' }
        ],
        [
          'global.saturday',
          { id: '', dayOfWeek: 6, name: 'global.saturday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 6, name: 'global.saturday', timeType: 'global.end', value: '' }
        ],
        [
          'global.sunday',
          { id: '', dayOfWeek: 0, name: 'global.sunday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 0, name: 'global.sunday', timeType: 'global.end', value: '' }
        ],
        [
          'global.public-holidays',
          { id: '', dayOfWeek: 100, name: 'global.public-holidays', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 100, name: 'global.public-holidays', timeType: 'global.end', value: '' }
        ]
      ]
    };

    if (operationPeriod && operationPeriod.operationPeriod && operationPeriod.operationPeriod.length > 0) {
      operationPeriod.operationPeriod.forEach(element => {
        let contentIndex = 0;
        switch (element.startDayOfWeek) {
          case 0:
            contentIndex = 6;
            break;
          case 1:
            contentIndex = 0;
            break;
          case 2:
            contentIndex = 1;
            break;
          case 3:
            contentIndex = 2;
            break;
          case 4:
            contentIndex = 3;
            break;
          case 5:
            contentIndex = 4;
            break;
          case 6:
            contentIndex = 5;
            break;
        }
        this.officeHoursParam['content'][contentIndex][this.timeIndex.start]['value'] = element.startTime ? element.startTime : '';
        this.officeHoursParam['content'][contentIndex][this.timeIndex.end]['value'] = element.endTime ? element.endTime : '';
      });
    }

    if (operationPeriod && operationPeriod.publicHoliday && operationPeriod.publicHoliday.length > 0) {
      let element = operationPeriod.publicHoliday[0];
      // index 7 == public holiday
      this.officeHoursParam['content'][7][this.timeIndex.start]['value'] = element.startTime ? element.startTime : '';
      this.officeHoursParam['content'][7][this.timeIndex.end]['value'] = element.endTime ? element.endTime : '';
    }
  }

  // private initContactSequencesViewData() {
  private updateContactSequencesView() {
    this.inHouseContactSequencesViewData = [
      {
        id: 'non-urgent',
        name: 'team.priority.non-urgent',
        titleCss: 'non-urgent-label',
        priority: Priority.NonUrgent,
        contactSequencesIndex: [1, 2, 3, 4]
      }, {
        id: 'urgent',
        name: 'team.priority.urgent',
        titleCss: 'urgent-label',
        priority: Priority.Urgent,
        contactSequencesIndex: [1, 2, 3, 4]
      }, {
        id: 'emergency',
        name: 'team.priority.emergency',
        titleCss: 'emergency-label',
        priority: Priority.Emergency,
        contactSequencesIndex: [1, 2, 3, 4]
      }
    ]

    this.inHouseContactSequencesViewData.push({
      id: 'pm',
      name: 'team.priority.pm',
      titleCss: 'pm-label',
      priority: 'pm',
      contactSequencesIndex: [1, 2, 3, 4]
    });
  }

  // private initResponseTimeTable(responseTimeObject: any) {
  private updateResponseTimeView(team: JMOBJ.Team) {
    let responseTimeObject = team.responseTime;
    this.responseTimeParam = {
      componentId: 'settings-team_time-for-no-response',
      isLoadingTable: false,
      isEditMode: false,
      hasEditionPermission: this.hasEditionPermission,
      onEditButtonClicked: () => {
        this.responseTimeParam['isEditMode'] = true;
        this.responseTimeParam['isDisable'] = false;
      },
      // table content
      headers: [],
      content: []
    };

    // set header
    this.responseTimeParam['headers'] = [
      { name: '', type: TablexColumnType.Text, class: 'w-20', },
      { name: 'team.priority.non-urgent', type: TablexColumnType.Input, class: 'w-20', },
      { name: 'team.priority.urgent', type: TablexColumnType.Input, class: 'w-20', },
      { name: 'team.priority.emergency', type: TablexColumnType.Input, class: 'w-20', },
      { name: 'team.priority.pm', type: TablexColumnType.Input, class: 'w-20', },
    ]

    // set content
    if (this.selectedTeam.handlingParty == JMENUM.HandlingParty.INHOUSE) {
      this.responseTimeParam['content'] = [
        [
          'component.response-time-table.1st-contact',
          { id: '', order: 1, name: 'component.response-time-table.1st-contact', priority: JMENUM.JMPriority.NonUrgent, value: this.getResponseTimeStr(responseTimeObject, Priority.NonUrgent.toString(), 1) },
          { id: '', order: 1, name: 'component.response-time-table.1st-contact', priority: JMENUM.JMPriority.Urgent, value: this.getResponseTimeStr(responseTimeObject, Priority.Urgent.toString(), 1) },
          { id: '', order: 1, name: 'component.response-time-table.1st-contact', priority: JMENUM.JMPriority.Emergency, value: this.getResponseTimeStr(responseTimeObject, Priority.Emergency.toString(), 1) },
        ],
        [
          'component.response-time-table.2nd-contact',
          { id: '', order: 2, name: 'component.response-time-table.2nd-contact', priority: JMENUM.JMPriority.NonUrgent, value: this.getResponseTimeStr(responseTimeObject, Priority.NonUrgent.toString(), 2) },
          { id: '', order: 2, name: 'component.response-time-table.2nd-contact', priority: JMENUM.JMPriority.Urgent, value: this.getResponseTimeStr(responseTimeObject, Priority.Urgent.toString(), 2) },
          { id: '', order: 2, name: 'component.response-time-table.2nd-contact', priority: JMENUM.JMPriority.Emergency, value: this.getResponseTimeStr(responseTimeObject, Priority.Emergency.toString(), 2) },
        ],
        [
          'component.response-time-table.3rd-contact',
          { id: '', order: 3, name: 'component.response-time-table.3rd-contact', priority: JMENUM.JMPriority.NonUrgent, value: this.getResponseTimeStr(responseTimeObject, Priority.NonUrgent.toString(), 3) },
          { id: '', order: 3, name: 'component.response-time-table.3rd-contact', priority: JMENUM.JMPriority.Urgent, value: this.getResponseTimeStr(responseTimeObject, Priority.Urgent.toString(), 3) },
          { id: '', order: 3, name: 'component.response-time-table.3rd-contact', priority: JMENUM.JMPriority.Emergency, value: this.getResponseTimeStr(responseTimeObject, Priority.Emergency.toString(), 3) },
        ],
        [
          'component.response-time-table.responsible-officer',
          { id: '', order: 4, name: 'component.response-time-table.responsible-officer', priority: JMENUM.JMPriority.NonUrgent, value: this.getResponseTimeStr(responseTimeObject, Priority.NonUrgent.toString(), 4) },
          { id: '', order: 4, name: 'component.response-time-table.responsible-officer', priority: JMENUM.JMPriority.Urgent, value: this.getResponseTimeStr(responseTimeObject, Priority.Urgent.toString(), 4) },
          { id: '', order: 4, name: 'component.response-time-table.responsible-officer', priority: JMENUM.JMPriority.Emergency, value: this.getResponseTimeStr(responseTimeObject, Priority.Emergency.toString(), 4) },
        ]
      ];

      this.responseTimeParam['content'][0].push({ id: '', order: 1, name: 'component.response-time-table.1st-contact', priority: 'pm', value: this.getResponseTimeStr(responseTimeObject, 'pm', 1) });
      this.responseTimeParam['content'][1].push({ id: '', order: 2, name: 'component.response-time-table.2nd-contact', priority: 'pm', value: this.getResponseTimeStr(responseTimeObject, 'pm', 2) });
      this.responseTimeParam['content'][2].push({ id: '', order: 3, name: 'component.response-time-table.3rd-contact', priority: 'pm', value: this.getResponseTimeStr(responseTimeObject, 'pm', 3) });
      this.responseTimeParam['content'][3].push({ id: '', order: 4, name: 'component.response-time-table.responsible-officer', priority: 'pm', value: this.getResponseTimeStr(responseTimeObject, 'pm', 4) });

    } else {
      this.responseTimeParam['content'] = [
        [
          'component.response-time-table.contractor',
          { id: '', order: 0, name: 'component.response-time-table.contractor', priority: JMENUM.JMPriority.NonUrgent, value: this.getResponseTimeStr(responseTimeObject, Priority.NonUrgent.toString(), 0) },
          { id: '', order: 0, name: 'component.response-time-table.contractor', priority: JMENUM.JMPriority.Urgent, value: this.getResponseTimeStr(responseTimeObject, Priority.Urgent.toString(), 0) },
          { id: '', order: 0, name: 'component.response-time-table.contractor', priority: JMENUM.JMPriority.Emergency, value: this.getResponseTimeStr(responseTimeObject, Priority.Emergency.toString(), 0) },
        ]
      ];

      this.responseTimeParam['content'][0].push({ id: '', order: 0, name: 'component.response-time-table.contractor', priority: 'pm', value: this.getResponseTimeStr(responseTimeObject, 'pm', 0) });
    }
  }

  async onTeamChanged(team:JMOBJ.Team) {
    // get team detail
    const request = new JM.JMRequestTeamsTeamSummary();
    request.idList = [team._id];
    request.includeSummary = true;
    request.pageSize = 1;
    request.pageNumber = 1;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload || response.payload.records.length <= 0) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    this.selectedTeam = response.payload.records[0];
    if (this.selectedTeam.handlingParty == JMENUM.HandlingParty.INHOUSE) {
      this.contractorTeamSelectorView.deselectTeam(); 
    } else {
      this.inhouseTeamSelectorView.deselectTeam();

    }
    this.updateTeamView();
  }

  updateTeamView() {
    this.isLoadingTeams = false;
    Session.setTeamSettingsSelectedTeamId(this.selectedTeam._id.toString());
    this.updateEditionPermission();
    this.updateOfficeHoursView(this.selectedTeam);
    this.updateResponseTimeView(this.selectedTeam);
    this.updateContactSequencesView();
    this.selectedTeam.contractNumber ? this.requestTeamContract(this.selectedTeam.contractNumber.toString()) : this.selectedTeamContractNumber = "";
  }


  //---------------------------------------------------------------------------
  // office hours table function
  private validateOperationPeriod(): string {
    let isInputTime = (startValue, endValue) => {
      return (startValue && endValue || !startValue && !endValue);
    }
    for (let i = 0; i < this.officeHoursParam['content'].length; i++) {
      let startObject = this.officeHoursParam['content'][i][this.timeIndex.start];
      let endObject = this.officeHoursParam['content'][i][this.timeIndex.end];

      if (!isTimeString(startObject.value)) {
        return JMLanguage.translate("pages.team-settings.error.invalid-operation-start-time", [JMLanguage.translate(startObject.name)])
      }

      if (!isTimeString(endObject.value)) {
        return JMLanguage.translate("pages.team-settings.error.invalid-operation-end-time", [JMLanguage.translate(startObject.name)])
      }

      if (!isInputTime(startObject.value, endObject.value)) {
        return JMLanguage.translate("pages.team-settings.error.invalid-operation-incomplete-time", [JMLanguage.translate(startObject.name)])
      }
    }
    return null;  // no error
  }

  private getOperationPeriod():{
    operationPeriod: {
        startDayOfWeek: number,
        startTime: string,
        endTime: string,
        endDayOfWeek: number,
    }[],
    publicHoliday: {
        startTime: undefined,
        endTime: undefined,
    }[],
  } {
    let editedOperationPeriod:{
      operationPeriod: {
          startDayOfWeek: number,
          startTime: string,
          endTime: string,
          endDayOfWeek: number,
      }[],
      publicHoliday: {
          startTime: undefined,
          endTime: undefined,
      }[],
    } = {
      operationPeriod: [],
      publicHoliday: []
    };
    
    for (let i = 0; i < this.officeHoursParam['content'].length; i++) {
      let startObject = this.officeHoursParam['content'][i][this.timeIndex.start];
      let endObject = this.officeHoursParam['content'][i][this.timeIndex.end];


      // dayOfWeek 100 == public holiday
      if (startObject.dayOfWeek == 100) {
        editedOperationPeriod.publicHoliday.push({
          startTime: startObject.value,
          endTime: endObject.value
        });
      } else {
        editedOperationPeriod.operationPeriod.push({
          startDayOfWeek: startObject.dayOfWeek,
          endDayOfWeek: startObject.dayOfWeek,
          startTime: startObject.value,
          endTime: endObject.value
        });
      }
    }
    
    return editedOperationPeriod;
  }

  //---------------------------------------------------------------------------
  // no response time table function
  private validateResponseTime() {
    let error = null;
    for(let i=0; i<this.responseTimeParam['content'].length; i++) {
      let row = this.responseTimeParam['content'][i];
      for (let y=1; y<row.length; y++) {  // row 0 is header
        let col = row[y];
        let value = col.value;
        let pri = col.priority + '';

        if (pri === 'pm') { // PM response time is optional
          continue;
        }
        if (Number(value) <= 0 ) { 
          error = JMLanguage.translate("pages.team-settings.error.invalid-respond-time", [JMLanguage.translate(col.priority), JMLanguage.translate(col.name)])
        }
      }
    }
    return error;
  }

  private getResponseTime() {
    let responseTime:{
      '1': number[],
      '2': number[],
      '3': number[],
      'pm': number[]
    } = {
      '1': [null,null,null,null,null,],
      '2': [null,null,null,null,null,],
      '3': [null,null,null,null,null,],
      'pm': [null,null,null,null,null,],
    }
    for(let i=0; i<this.responseTimeParam['content'].length; i++) {
      let row = this.responseTimeParam['content'][i];
      for (let y=1; y<row.length; y++) {  // row 0 is header
        let col = row[y];
        let pri = col.priority + '';
        responseTime[pri][col.order] = parseInt(col.value);
      }
    }
    return responseTime;
  }

  private getResponseTimeStr(object: any, priority: string, order: number): string {
    let timeStr = null;
    if (object && object[priority] && object[priority][order]) {
      timeStr = object[priority][order]
    }
    return timeStr;
  }

  private async requestInhouseTeamList() {
    const request = new JM.JMRequestTeamsTeamSummary();
    request.workCentreList = [this.selectedWorkCentre];
    request.active = JMENUM.RequestActive.ACTIVE;
    request.handlingPartyList = [JMENUM.HandlingParty.INHOUSE];
    request.isRoTeam = false;
    request.includeApprovedWorkCentre = false;
    request.includeSummary = true;
    request.pageSize = 100;
    request.pageNumber = 1;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.inHouseHandlingTeamOption = response.payload.records;
  }

  private async requestSaveTeam() {
    // valid

    this.setBtnDisabled(true);

    const request = new JM.JMRequestTeamsUpdateTeam();
    request._id         = this.selectedTeam._id;
    request.fax         = this.selectedTeam.fax ? this.selectedTeam.fax : '';
    request.enableFax   = this.selectedTeam.enableFax;
    request.email       = this.selectedTeam.email;
    request.enableEmail = this.selectedTeam.enableEmail;
    request.operationPeriod = this.getOperationPeriod();
    request.responseTime = this.getResponseTime();
    
    if (this.selectedTeam.handlingParty == JMENUM.HandlingParty.INHOUSE) {
      // Check RO
      let newContactSequences = this.selectedTeam.contactSequences;
      for (let j = 0; j < this.priorityArray.length; j++) {
        let priority = this.priorityArray[j]
        let hasContactPeron = false;

        for (let i = 0; i < this.contactSequenceArray.length; i++) {
          let contactSequence = this.contactSequenceArray[i];

          // check 1/2/3 contact person(s)
          if (contactSequence == 1 || contactSequence == 2 || contactSequence == 3) {
            if (newContactSequences[priority][contactSequence].length > 0) {
              hasContactPeron = true;
            }
          }

          // If the contact sequence is selected 1/2/3 contact person(s), RO should be mandatory.
          if (contactSequence == 4 && hasContactPeron && newContactSequences[priority][contactSequence].length == 0) {
            let priorityStr = JMLanguage.translate("team.priority." + priority);
            this.openSnackBar(JMLanguage.translate("pages.team-settings.error.no-ro", [priorityStr]));
            this.setBtnDisabled(false);
            return;
          }
        }
      }

      request.contactSequences = newContactSequences;
    } else {
      let handlingTeam = this.inHouseHandlingTeamOption.find(t => t._id === this.selectedTeam.handlingTeam);
      let responseTime = this.getResponseTime();

      request.handlingTeam = this.selectedTeam.handlingTeam;
      request.contactSequences = { ...handlingTeam.contactSequences };
      request.responseTime = { ...handlingTeam.responseTime };

      request.contactSequences['1'][0] = this.selectedTeam.members;
      request.contactSequences['2'][0] = this.selectedTeam.members;
      request.contactSequences['3'][0] = this.selectedTeam.members;
      request.contactSequences['pm'][0] = this.selectedTeam.members;

      request.responseTime['1'][0] = responseTime['1'][0];
      request.responseTime['2'][0] = responseTime['2'][0];
      request.responseTime['3'][0] = responseTime['3'][0];
      request.responseTime['pm'][0] = responseTime['pm'][0];
    }

    const response: JM.JMResponseTeamsUpdateTeam = await AppDelegate.sendJMRequest(request);
    // update Team
    this.officeHoursParam['isEditMode'] = false;
    this.responseTimeParam['isEditMode'] = false;
    this.setBtnDisabled(false);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
  }

  private async requestDeleteTeam() {
    const request = new JM.JMRequestTeamsDeleteTeam();
    request.teamIds = [this.selectedTeam._id];
    
    const response: JM.JMResponseTeamsDeleteTeam = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeams = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (this.selectedTeam.handlingParty == JMENUM.HandlingParty.INHOUSE) {
      this.inhouseTeamSelectorView.deselectTeam();
      this.inhouseTeamSelectorView.requestTeamSummary();
    } else {
      this.contractorTeamSelectorView.deselectTeam();
      this.contractorTeamSelectorView.requestTeamSummary();
    }
    this.selectedTeam = null;
  }

  // get work centre default fax number since it can be updated freq, cannot use cache
  private async requestWorkCentre() {
    const request = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.selectedWorkCentre];
    request.active = JMENUM.RequestActive.ACTIVE;
    request.includeSummary = true;

    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.selectedWorkCentreObj = response.payload.records[0];
    this.defaultFaxNumber = response.payload.records[0].defaultFaxNumber;
    
    if (response.payload.records[0].HACluster) {
      this.hasHaCluster = true;
    }
    this.priorityArray.push('pm');
  }

  private async requestTeamContract(contractNumber: string) {
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.contractNumber = [contractNumber];
    request.parameters  = ["contractNumber", "type"];
    request.expired = JMENUM.RequestExpired.BOTH;

    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records.length > 0) {
      this.selectedTeamContractNumber = FORAMTTER.formatContractNumber(response.payload.records[0]);
    } else {
      this.selectedTeamContractNumber = "";
    }
  }

  private async requestWorkCentreOfficer() {
    this.uiLoadingWorkCentreOfficer = true;

    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.authorizations = {'workCenters': this.selectedWorkCentre};
    request.active = JMENUM.RequestActive.ACTIVE;
    request.includeSummary = true;
    request.pageSize = 1000;
    
    if (this.workCentreOfficerArray) {
      request.pageNumber = (this.workCentreOfficerArray.length / request.pageSize)+1;
    } else {
      request.pageNumber = 1;
    }
        
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
    }

    if (this.workCentreOfficerArray == undefined) {
      this.workCentreOfficerArray = [];
    }
    for (let post of response.payload.records) {
      this.workCentreOfficerArray.push(post.name);
    }
    this.isLoadedAllWorkCentreOffcer = (this.workCentreOfficerArray.length >= response.payload.totalCount);
    this.uiEmailRecipientOption = [...this.workCentreOfficerArray]; // trigger ng-select detect change
    this.uiLoadingWorkCentreOfficer = false;
  }

  //---------------------------------------------------------------------------
  // event functions
  onEnableFaxChanged(e) {
    if (!e.currentTarget.checked) {
      this.selectedTeam.fax = ''
    };
  }
  onEmailOptionOpen() {
    if(this.workCentreOfficerArray == undefined) {
      this.requestWorkCentreOfficer();
    }
  }
  onEmailOptionScrollToEnd() {
    if (this.isLoadedAllWorkCentreOffcer == false) {
      this.requestWorkCentreOfficer();
    }
  }
  onEnableEmailChanged() {
    if (this.selectedTeam.enableEmail == false) {
      this.selectedTeam.email = [];
    }
  }

  onSaveButtonClicked() {
    let opValidResult = this.validateOperationPeriod();
    if (opValidResult) {
      AppDelegate.openSnackBar(opValidResult);
      return;
    }

    let rtValidResult = this.validateResponseTime();
    if (rtValidResult) {
      AppDelegate.openSnackBar(rtValidResult);
      return;
    }
    
    let isFaxFormat = isPhoneNumber(this.selectedTeam.fax.toString()) || isEmailAdress(this.selectedTeam.fax.toString());
    if (this.selectedTeam.enableFax && (!this.selectedTeam.fax || !isFaxFormat)) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.team-setting.invalid-fax"));
      return;
    }

    if((!this.selectedTeam.email || this.selectedTeam.email.length <=0) && this.selectedTeam.enableEmail){
      this.errorFields = {
        email: (!this.selectedTeam.email || this.selectedTeam.email.length <=0)  && this.selectedTeam.enableEmail
      };
      AppDelegate.openSnackBar(JMLanguage.translate("pages.team-settings.message.error.invalidRecipients"));
      return ;
    }
    else{
      this.errorFields = {};
    }
      
    


    let message = JMLanguage.translate("pages.team-settings.confirm-save-team-title", [this.selectedTeam.name]);
    let handler = () => this.requestSaveTeam();
    this.popupConfirmAlert(message, handler);
  }

  onDeleteButtonClicked() {
    let message = JMLanguage.translate("pages.team-settings.confirm-delete-team-title", [this.selectedTeam.name]);
    this.popupConfirmAlert(message, () => this.requestDeleteTeam());
  }
  
  // TODO: reload language
  onLanguageChanged() {
    
  }

  //---------------------------------------------------------------------------
  // ui functions

  private setBtnDisabled(isDisabled: boolean) {
    this.saveButtonDisabled = isDisabled;
    this.deleteButtonDisabled = isDisabled;
  }

  private updateEditionPermission() {
    this.hasEditionPermission = this.authorizationService.hasPermission(Permission.wcmUpdate) && this.selectedTeam.workCentre == this.selectedWorkCentre;
  }
    
  private popupConfirmAlert(message: string, handler: any) {
    let buttons = [
      { name: JMLanguage.translate("global.yes"), handler },
      { name: JMLanguage.translate("global.no") }
    ]
    this.showPopUpAlert(message, "", buttons);
  }


  isInhouse(handlingParty: JMENUM.HandlingParty): boolean {
    return handlingParty == JMENUM.HandlingParty.INHOUSE;
  }

  isContractor(handlingParty: JMENUM.HandlingParty): boolean {
    return handlingParty == JMENUM.HandlingParty.PMSMC || handlingParty == JMENUM.HandlingParty.NON_PMSMC;
  }

  // isOwnerTeam(): boolean {
  //   return this.selectedTeam.workCentre == this.selectedWorkCentre;
  // }

  getContactSequencePlaceholder(index: number) {
    switch (index) {
      case 0:
        return "pages.team-setting.contractor";
      case 1:
        return "pages.team-setting.1st-contact";
      case 2:
        return "pages.team-setting.2nd-contact";
      case 3:
        return "pages.team-setting.3rd-contact";
      case 4:
        return "pages.team-setting.responsible-officer";
    }
    return '';
  }
}
 