import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { NgOption } from '@ng-select/ng-select';
import { ActivatedRoute, Router } from '@angular/router';
import { fileSize } from 'src/lib/presenter/Formatter';
import { cloneDeep } from 'lodash';
import { AuthorizationService } from '@services/authorization.service';
import { Session } from 'src/app/services/session';

interface IFileRequestData {
  name: string;
  data: string;
}
const initData: Partial<JMOBJ.Enquiry> = {
  enquiryNumber: '',
  systemName: '',
  description: '',
  status: null,
  contactName: '',
  contactNumber: null,
  email: '',
  category: null,
  subCategory: null,
  ccList: [],
  attachments: [],
  discussion: [],
  submittedAt: null,
  submittedBy: '',
  updatedAt: null,
  updatedBy: '',
};
const initCommentData: Partial<JMOBJ.Discussion> = {
  comment: '',
  attachments: [],
};
const initCommentErrorFeilds = {} as Record<keyof JMOBJ.Discussion, boolean>;

@Component({
  selector: 'app-enquiry-view',
  templateUrl: './enquiry-view.component.html',
  styleUrls: ['./enquiry-view.component.scss']
})
export class EnquiryViewComponent implements OnInit {
  id: string;
  data = cloneDeep(initData); // API data
  commentData = cloneDeep(initCommentData); // API data
  commentAttachmentsTemp = []; // for storing fileDate upon upload. Will convert to API-favor data later
  statusOption: NgOption[] = Object.keys(JMENUM.EnquiryStatus).map(key => (
    {value: key, label: JMLanguage.translate('enquiry.status.' + key)}
  ));
  isLoading = {
    getEnquiryList: false,
    addDiscussion: false,
    updateStatus: false,
  };
  commentErrorFields = {...initCommentErrorFeilds};
  canComment = false;
  canUpdateStatus = false;
  hasStatusChanged = false;

  // for files
  allowedFileExt = [
    'jpg', 'jpeg', 'gif',
    'doc', 'docx', 'docm',
    'ppt', 'pptx', 'pptm',
    'xls', 'xlm', 'xlsm', 'xlsx', 'csv',
    'zip', 'rar',
    'pdf',
    'txt',
  ];
  allowedFileExtPrint = this.allowedFileExt.map(item => '.' + item).join(', ');
  fileSize = fileSize;

  // for UI
  moment = moment;
  constants = Constants;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authorizationService: AuthorizationService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    if (!this.hasPagePermission()) {
      this.router.navigate(['/enquiry/list']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    this.requestEnquirySummary();

    this.canComment = this.authorizationService.hasPermission(JMENUM.Permission.ENQUIRY_DISCUSSION_ADD);
    this.canUpdateStatus = (
      this.authorizationService.hasPermission(JMENUM.Permission.ENQUIRY_UPDATE) ||
      this.authorizationService.hasPermission(JMENUM.Permission.ENQUIRY_UPDATE_ALL)
    );
  }

  hasPagePermission = (): boolean => {
    // check creater should be done by backend. should stop getting the response data
    return (
      this.authorizationService.hasPermission(JMENUM.Permission.ENQUIRY_VIEW) ||
      this.authorizationService.hasPermission(JMENUM.Permission.ENQUIRY_VIEW_ALL)
    );
  }

  renderPlainLongText = (desc: string): string => {
    // add line break;
    return desc.replace(/\r?\n/g, '<br />');
  }

  showCommentTime = (time: Date): string => {
    const lang = JMLanguage.getCurrentLanguage() === 'zh' ? 'zh-hk' : 'en';
    return moment().diff(time, 'days') !== 0
      ? moment(time).locale(lang).format(Constants.DATETIME_FORMAT)
      : moment(time).locale(lang).fromNow();
  }

  validateAddDiscussion = (): boolean => {
    this.commentErrorFields.comment = !this.commentData.comment;
    return Object.keys(this.commentErrorFields).every(field => !this.commentErrorFields[field]);
  }

  onCommentSubmit = (): void => {
    if (!this.validateAddDiscussion()) return;
    this.requestEnquiryAddDiscussion();
  }

  resetComment = (): void => {
    this.commentData = cloneDeep(initCommentData);
    this.commentErrorFields = {...initCommentErrorFeilds};
    this.commentAttachmentsTemp = [];
  }

  sortDiscussion = (commentArr: JMOBJ.Discussion[]): JMOBJ.Discussion[] => {
    return commentArr.sort((a, b) => + new Date(b.createdAt) - + new Date(a.createdAt));
  }

  onStatusChange = (): void => {
    this.hasStatusChanged = true;
  }

  updateStatus = (): void => {
    this.requestEnquiryUpdateStatus();
  }

  onFileInput = ({target}: {target: HTMLInputElement}): void => {
    const maxFileSize = 50 * 1024 * 1024; // 50Mb
    const maxTotalFileSize = 50 * 1024 * 1024; // 50Mb
    const maxNoOfFiles = 10;

    if (target.files.length) {
      Array.from(target.files).forEach(file => {
        if (this.commentAttachmentsTemp.length >= maxNoOfFiles) {
          return AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry-form.msg.error.number-of-file'));
        }
        if (file.size > maxFileSize) {
          return AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry-form.msg.error.total-file-size'));
        }
        if (this.commentAttachmentsTemp.reduce((acc, curr) => acc + curr.size, 0) + file.size >= maxTotalFileSize) {
          return AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry-form.msg.error.total-file-size'));
        }
        if (this.allowedFileExt.indexOf(file.name.split('.')[1]) === -1) {
          return AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry-form.msg.error.file-type'));
        }
        this.commentAttachmentsTemp.push(file);
      });
    }
  }

  convertToFilesRequestDataPromise = (files: any[]): Promise<IFileRequestData[]> => {
    const promises = files.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise<IFileRequestData>((resolve) => {
        reader.onload = () => {
          resolve({
            name: file.name,
            data: reader.result.toString().split(',')[1],
          });
        };
      });
    });
    return Promise.all(promises);
  }

  getFileIconClass = (fileName: string): string => {
    const fileExt = fileName.split('.')[1];
    if (['jpg', 'jpeg', 'gif'].indexOf(fileExt) > -1) return 'fa-image';
    if (['doc', 'docx', 'docm'].indexOf(fileExt) > -1) return 'fa-file-word';
    if (['ppt', 'pptx', 'pptm'].indexOf(fileExt) > -1) return 'file-powerpoint';
    if (['xlm', 'xlsm', 'xls', 'xlsx', 'csv'].indexOf(fileExt) > -1) return 'fa-file-excel';
    if (['pdf'].indexOf(fileExt) > -1) return 'fa-pdf';
    if (['zip', 'rar'].indexOf(fileExt) > -1) return 'fa-file-archive';
    return 'fa-file-alt';
  }

  removeAttachment = (idx): void => {
    this.commentAttachmentsTemp.splice(idx, 1);
  }

  downloadFile = (attachmentId) => {
    const fileHost = JM.JMConnector.getFileHost();
    window.location.assign(fileHost + '/files/' + attachmentId); // download file
  }

  requestEnquirySummary = async (): Promise<void> => {
    // Error handling
    if (this.isLoading.getEnquiryList) return console.warn('Debug...Early exit...');

    this.isLoading.getEnquiryList = true;

    // Pre request
    const request = new JM.JMRequestEnquirySummary();
    request.enquiryNumber = [this.id];

    // Post request
    const response = await JM.JMConnector.sendAsyncEnquirySummary(request)
    this.isLoading.getEnquiryList = false;

    // Error handling
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records.length) {
      this.router.navigate(['/enquiry/list']);
      AppDelegate.openErrorBar({response});
      return;
    }
    // Actions
    this.data = response.payload.records[0];
  }

  requestEnquiryAddDiscussion = async (): Promise<void> => {
    // Error handling
    if (this.isLoading.addDiscussion) return console.warn('Debug...Early exit...');
    if (!this.canComment) return AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));

    // Pre request
    this.isLoading.addDiscussion = true;

    const request = new JM.JMRequestEnquiryAddDiscussion();
    request.enquiryNumber = this.id;
    request.comment = this.commentData.comment;
    request.attachments = await this.convertToFilesRequestDataPromise(this.commentAttachmentsTemp);

    // Post request
    const response = await JM.JMConnector.sendAsyncEnquiryAddDiscussion(request)
    this.isLoading.addDiscussion = false;

    // Error handling
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      return AppDelegate.openErrorBar({response});
    }
    // Actions
    this.data = response.payload;
    this.resetComment();
    if (Session.userInfo.name === this.data.submittedBy) {
      this.data.status = JMENUM.EnquiryStatus.pending_helpdesk_action;
      this.requestEnquiryUpdateStatus();
    }
    AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry.toast.added-comment'));
  }

  requestEnquiryUpdateStatus = async (): Promise<void> => {
    // Error handling
    if (this.isLoading.updateStatus) return console.warn('Debug...Early exit...');
    if (!this.canUpdateStatus) return AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));

    // Pre request
    this.isLoading.updateStatus = true;

    const request = new JM.JMRequestEnquiryUpdateStatus();
    request.enquiryNumber = this.id;
    request.status = this.data.status;

    // Post request
    const response = await JM.JMConnector.sendAsyncEnquiryUpdateStatus(request)
    this.isLoading.updateStatus = false;
    this.hasStatusChanged = false;

    // Error handling
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      return AppDelegate.openErrorBar({response});
    }
    // Actions
    this.data = response.payload;
    AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry.toast.updated-status'));
  }

  checkData = () => {
    console.log(111, 'data', this.data);
    console.log(111, 'commentData', this.commentData);
  }
}
