import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { TablexComponentParams } from 'src/app/ui/components/tablex/tablex.component';
import {
  GetStockListParams,
  SelectedStockTableColumnIndex,
  stockTableHeader,
  selectedStockTableHeader,
} from './stock-reserve-list.model';
import { Session } from '@services/session';
import { ActionButtonService } from '@services/action-button.service';
import { ActionButtonContent, ActionButtonDefinition, ActionButtonStock, ActionButtonType } from '@enum/action-button';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-stock-reserve-list',
  templateUrl: './stock-reserve-list.component.html',
  styleUrls: ['./stock-reserve-list.component.scss']
})
export class StockReserveListComponent implements OnInit {
  @ViewChild("reserve_ai_store_slider", { static: true }) reserveAiStoreSlider;
  @ViewChild("reserve_ai_store_panel", { static: true }) reserveAiStorePanel;


  breadcrumbs: any = [];
  actionButtonData: ActionButtonContent[] = [];
  snNumber: string;
  jobCardNumber: string;
  jobCard: JM.JMOBJ.JobCard;
  jobStockReservation: JM.JMOBJ.InventoryStockReservation;
  reservationVersion: number;

  // model
  searchFilters: {[x:string]:any} = {};
  stockList: Array<any>;
  reservedItemList: Array<any> = new Array();
  selectedStockMap: Map<String, any> = new Map();
  reservedItemMap: Map<String, any> = new Map();

  // ai Store related
  formPayloadStockToBeReserved:any;
  aiStorePanelInited: boolean = false;

  stockTablexParam: TablexComponentParams;
  selectedStockTablexParam: TablexComponentParams;

  // action buttons
  actionButtons: any[];
  disabledActionSideBar: boolean;

  // guard check
  haveUserChanges: boolean;

  // error code
  FAIL_TO_CREATE_CCS_RESERVATION = 22002;
  VERSION_NOT_LATEST = 22007;
  CCS_RESERVATION_QTY_UPDATED = 22008;
  FAIL_TO_UPDATE_CCS_RESERVATION = 22009;
  FAIL_TO_COMPLETE_SOME_REQUEST = 22023;

  requestReservationErrorMsg: string;

  constructor(private route: ActivatedRoute, private actionBtnService: ActionButtonService) { }

  async ngOnInit() {
    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');
    this.haveUserChanges = false;

    this.initStockTable();
    this.initSelectedStockTable();
    this.initActionButtons();

    this.requestJobCard().then(() => {
      this.initBreadcrumbs();
    });
    
    this.requestStockReservation().then(() => {
      this.reservationVersion = this.jobStockReservation.version;
      this.initReserveStockMap();
      this.initSelectedStockMap();
      this.renderSelectedStockTable();
      this.updateActionBar();
    });

    await this.requestStockList();
    this.renderStockTable();
  }

  async requestJobCard() {
    const request = new JM.JMRequestJobCardsGetJobCard();
    request.jobCardNumber = this.jobCardNumber;

    const response: JM.JMResponseJobCardsGetJobCard = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }

    if (response.payload.jobCard) {
      this.jobCard = response.payload.jobCard;
    }
  }

  async requestStockReservation() {
    const request = new JM.JMRequestInventoryGetStockReservation();
    request.jobNumber = this.jobCardNumber;
    request.isGettingLastestStockLevel = true;
    request.aiStoreInfoStatus = [JMENUM.RequestActive.ACTIVE, JMENUM.RequestActive.INACTIVE];

    const response: JM.JMResponseInventoryGetStockReservation = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }
    
    if (response.payload) {
      this.jobStockReservation = response.payload;
    }
  }

  async requestStockList(pageNumber?: number, pageSize?: number) {
    const request = new JM.JMRequestInventoryStockList();
    request.pageNumber = typeof pageNumber === 'number' ? pageNumber : this.stockTablexParam.currentPage; 
    request.pageSize = typeof pageSize === 'number' ? pageSize : this.stockTablexParam.currentPageSize; 
    request.sortBy = GetStockListParams.materialNumber;
    request.sortOrder = JMENUM.SortOrder.ASC;

    if(Object.keys(this.searchFilters).length > 0){
      request.filter = this.searchFilters;
    }
    // request.parameters

    this.stockTablexParam.isLoadingTable = true;
    const response: JM.JMResponseInventoryStockList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.stockTablexParam.isLoadingTable = false;

    this.stockTablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
    this.stockTablexParam.currentPage = request.pageNumber;
    this.stockTablexParam.currentPageSize = request.pageSize;
    this.stockList = response.payload.records;
  }
  
  async requestStockReserve(stockToBeReserved, aiStoreForm?) {
    const request = new JM.JMRequestInventorySubmitStockReservation();
    request.jobNumber = this.jobCardNumber;
    request.stockToBeReserved = stockToBeReserved;
    aiStoreForm && (request.aiStore = aiStoreForm);
    this.reservationVersion && (request.version = this.reservationVersion);

    const response: JM.JMResponseInventorySubmitStockReservation = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.onStockReserveError(response);
      response.payload && this.updateResult(response.payload);
      return;
    }

    if (response.payload.material.some(material => material.isPreviousOperationSuccessful === false)) {
      this.updateResult(response.payload);
      return;
    }

    if (this.jobCard && 
      Array.isArray(this.jobCard.stockReservations) &&
      this.jobCard.stockReservations.length > 0 && this.jobCard.stockReservations[0].reservationNumber
    ) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.reserve-stock.submitted'));
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.reserve-stock.submitted.new'));
    }
    return response.payload;
  }

  onStockReserveError(response: JM.JMResponseInventorySubmitStockReservation) {
    switch(response.code) {
      case this.VERSION_NOT_LATEST:
      case this.CCS_RESERVATION_QTY_UPDATED:
        const buttons = [
          {
            name: JMLanguage.translate("global.ok"),
            handler: this.onReloadReservation.bind(this),
          },
        ];
        AppDelegate.showPopUpAlert(JMLanguage.translate("pages.reserve-stock.submitted.version-err"), null, buttons);
        break;
      
      case this.FAIL_TO_CREATE_CCS_RESERVATION:
        this.requestReservationErrorMsg = `${JMLanguage.translate(`api.error.inventory.${this.FAIL_TO_CREATE_CCS_RESERVATION}`)} : ${response.error || ''}`;
        break;
      case this.FAIL_TO_COMPLETE_SOME_REQUEST:
        if (response.payload) {
          this.requestReservationErrorMsg = response.payload.material
            .filter(mat => mat.isPreviousOperationSuccessful === false)
            .map(mat => `${mat.materialNumber}: ${mat.remarks}`)
            .join('<br>');
        }
        break;
      default:
        this.requestReservationErrorMsg = `${response.error || ''}`;
        break;
    }
  }

  onReloadReservation() {
    this.requestStockReservation().then(() => {
      this.reservationVersion = this.jobStockReservation.version;
      this.initReserveStockMap();
      this.initSelectedStockMap();
      this.renderSelectedStockTable();
      this.renderStockTable();
      this.updateActionBar();
    });
  }

  initBreadcrumbs() {
    const arr = [
      { id: 'breadcrumbs-job-card-number', name: this.jobCardNumber, route: `/job-card/view/${this.jobCardNumber}` },
      { id: 'breadcrumbs-page-title', name: JMLanguage.translate('pages.reserve-detail.page-title'), currentPage: true },
    ];
    if (this.jobCard && this.jobCard.snNumber) {
      arr.unshift({ id: 'breadcrumbs-sn-number', name: this.jobCard.snNumber, route: `/sn/view/${this.jobCard.snNumber}` })
    }
    this.breadcrumbs = arr;
  }

  private initReserveStockMap() {
    this.reservedItemList = new Array();
    this.reservedItemMap = new Map();

    this.jobStockReservation.material.forEach(material => {
      const stockUnitList = material.unitStockList;

      const _id = material._id;
      const reservationItem = material.reservationItem;
      const locationCode = material.storageLocation.code;
      const locationDescription = material.storageLocation.description;
      const materialNumber = material.materialNumber;
      const materialNumberObj = {
        text: materialNumber,
        tooltipMsg: material.lastBatch ? JMLanguage.translate('pages.reserve-stock.stock-table.column.error.no-reorder') : '',
      }
      const materialDescription = material.description;
      const batchNumber = material.batchNumber;
      const unitStock = stockUnitList && stockUnitList.find(unitStock => {
        return unitStock.unit === material.unit;
      });
      const qtyRemain = unitStock ? unitStock.stock : "";
      const qtyCommitted = material.qtyCommitted;
      const qtyIssued = material.qtyIssued;

      const aiStoreList = Session.storageLocationAiList;
      const isAiStore = aiStoreList && aiStoreList.findIndex(store => store.code == locationCode) > -1;
      const storeColumn = {
        text: locationCode,
        iconStyle: { height: '24px', width: '24px', marginLeft: '2px' },
        iconSrc: isAiStore ? '/assets/svg/ai_store.svg' : null,
      }

      const unitList = {
        "id": 'unitList',
        "class": "",
        "fixed": (typeof qtyCommitted != 'undefined' && qtyCommitted != null) ? true : false,
        "value": material.unit,
        "options": stockUnitList && stockUnitList.map(unitStock => {
          return {
            label: unitStock.unit,
            value: unitStock.unit,
            stock: unitStock.stock,
          }
        }),
        "onChange": this.onChangeStockUnit.bind(this),
      }

      const qtyRequire = {
        "id": 'qtyRequire',
        "class": '',
        "type": 'number',
        "placeholder": '',
        "value": material.qtyRequired,
        "enable": true,
        "maxlength": 8,
        "onChange": this.onInputChange.bind(this),
        "onKeydown": this.onInputKeyDown.bind(this),
      };

      // const buttons = [{
      //   "id": _id,
      //   "name": "",
      //   "class": "delete-button btn",
      //   "icon": "fas fa-times",
      //   "onClicked": this.onReserveDeleteButtonClicked.bind(this),
      // }];

      const rowData = [
        _id,
        reservationItem,
        storeColumn,
        locationDescription,
        materialNumberObj,
        materialDescription,
        batchNumber,
        unitList,
        qtyRemain,
        qtyRequire,
        qtyCommitted,
        qtyIssued,
        null,
        null, // qtyIssued ? null : buttons,
      ];
      this.reservedItemList.push(rowData);

      if (this.reservedItemMap.has(_id)) {
        const itemMapArray = this.reservedItemMap.get(_id);
        itemMapArray.push(rowData);
        this.reservedItemMap.set(_id, itemMapArray);
      } else {
        this.reservedItemMap.set(_id, [rowData]);
      }
    })
  }

  private initSelectedStockMap() {
    this.selectedStockMap.clear();
  }

  // action tablex functions
  private initSelectedStockTable() {
    this.selectedStockTablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: false,
      enablePagination: false,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      // tableWrapperClass: 'table-min-width',
      // onFilterChanged: this.onFilterChanged,
      // onFilterClear: this.onFilterClear,
      // onPageNumberClicked: this.onActionPageNumberClicked.bind(this),
      // onPageSizeClicked: this.onActionPageSizeClicked.bind(this),
      // onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      content: [],
      highlightedRows: [],
    };
    this.selectedStockTablexParam.headers = selectedStockTableHeader;
  }

  renderSelectedStockTable() {
    const reservedStock = Array.from(this.reservedItemList);
    const selectedStock = Array.from(this.selectedStockMap.values());
    const tableContent = reservedStock.concat(selectedStock);

    this.selectedStockTablexParam.content = tableContent;
  }

  // Stock tablex functions
  private initStockTable() {
    this.stockTablexParam = {
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      // tableWrapperClass: 'table-min-width',
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      // onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      content: [],
      highlightedRows: [],
    };
    this.stockTablexParam.headers = stockTableHeader;
  }

  renderStockTable() {
    const notApplicableText = JMLanguage.translate('global.na');

    this.stockTablexParam.content = this.stockList.map((stock, index) => {
      const _id = stock[GetStockListParams._id];
      const locationCode = stock.storageLocation.code;
      const locationDescription = stock.storageLocation.description;
      const materialNumber = stock[GetStockListParams.materialNumber];
      const materialNumberObj = {
        text: materialNumber,
        tooltipMsg: stock[GetStockListParams.lastBatch] ? JMLanguage.translate('pages.reserve-stock.stock-table.column.error.no-reorder') : '',
      }
      const materialDescription = stock[GetStockListParams.materialDescription];
      const batchNumber = stock[GetStockListParams.batchNumber];
      const stockUnitList = stock[GetStockListParams.stockUnitList];
      const hasStockUnit = Array.isArray(stockUnitList) && stockUnitList.length > 0;
      const defaultStockUnit = hasStockUnit ? stockUnitList[0].unit : notApplicableText;
      const defaultQtyRemain = hasStockUnit ? stockUnitList[0].stock : notApplicableText;

      const aiStoreList = Session.storageLocationAiList;
      const isAiStore = aiStoreList && aiStoreList.findIndex(store => store.code == locationCode) > -1;
      const storeColumn = {
        text: locationCode,
        iconStyle: { height: '24px', width: '24px', marginLeft: '2px' },
        iconSrc: isAiStore ? '/assets/svg/ai_store.svg' : null,
      }

      const buttons = [{
        "id": _id,
        "name": "",
        "class": "add-button btn",
        "icon": "fas fa-plus",
        "onClicked": this.onAddButtonClicked.bind(this),
        "materialNumber": materialNumber
      }];

      return [
        _id,
        storeColumn,
        locationDescription,
        materialNumberObj,
        materialDescription,
        batchNumber,
        defaultStockUnit,
        defaultQtyRemain,
        this.reservedItemMap.has(_id) || this.selectedStockMap.has(_id) || !hasStockUnit ? null : buttons,
      ];
    });
  }

  onFilterChanged = async (event, index, header, filter) => {
    this.searchFilters = filter;
    for (let key in this.searchFilters) {
      if(!this.searchFilters[key]){
        delete this.searchFilters[key];
      }
    }

    const pageNumber = 1;
    await this.requestStockList(pageNumber, this.stockTablexParam.currentPageSize);
    this.renderStockTable();
  }

  onFilterClear() {
  }

  onPageNumberClicked = async (pageNumber: number) => {
    await this.requestStockList(pageNumber);
    this.renderStockTable();
  };

  onPageSizeClicked = async (pageSize: number) => {
    await this.requestStockList(1, pageSize);
    this.renderStockTable();
  };

  onAddButtonClicked(button) {
    if(button.id) {
      this.haveUserChanges = true;

      const stockRecord = this.stockList.find((stock) => button.id == stock[GetStockListParams._id]);

      const _id = stockRecord[GetStockListParams._id];
      const locationCode = stockRecord.storageLocation.code;
      const locationDescription = stockRecord.storageLocation.description;
      const materialNumber = stockRecord[GetStockListParams.materialNumber];
      const materialNumberObj = {
        text: materialNumber,
        tooltipMsg: stockRecord[GetStockListParams.lastBatch] ? JMLanguage.translate('pages.reserve-stock.stock-table.column.error.no-reorder') : '',
      }
      const materialDescription = stockRecord[GetStockListParams.materialDescription];
      const batchNumber = stockRecord[GetStockListParams.batchNumber];
      const stockUnitList = stockRecord[GetStockListParams.stockUnitList];
      const defaultStockUnit = Array.isArray(stockUnitList) && stockUnitList.length > 0 ? stockUnitList[0].unit : "";
      const defaultQtyRemain = Array.isArray(stockUnitList) && stockUnitList.length > 0 ? stockUnitList[0].stock : "";

      const aiStoreList = Session.storageLocationAiList;
      const isAiStore = aiStoreList && aiStoreList.findIndex(store => store.code == locationCode) > -1;
      const storeColumn = {
        text: locationCode,
        iconStyle: { height: '24px', width: '24px', marginLeft: '2px' },
        iconSrc: isAiStore ? '/assets/svg/ai_store.svg' : null,
      }

      const unitList = {
        "id": 'unitList',
        "class": "",
        "value": defaultStockUnit,
        "options": stockUnitList.map((stock) => {
          return {
            label: stock.unit,
            value: stock.unit,
            stock: stock.stock,
          }
        }),
        "onChange": this.onChangeStockUnit.bind(this),
      }
      const qtyRequire = {
        "id": 'qtyRequire',
        "class": '',
        "type": 'number',
        "placeholder": '',
        "value": 0,
        "enable": true,
        "maxlength": 8,
        "onChange": this.onInputChange.bind(this),
        "onKeydown": this.onInputKeyDown.bind(this),
        "onKeyup": this.onInputKeyUp.bind(this),
      };

      const buttons = [{
        "id": _id,
        "name": "",
        "class": "delete-button btn",
        "icon": "fas fa-times",
        "onClicked": this.onDeleteButtonClicked.bind(this),
      }];

      this.selectedStockMap.set(button.id, [
        _id,
        null,
        storeColumn,
        locationDescription,
        materialNumberObj,
        materialDescription,
        batchNumber,
        unitList,
        defaultQtyRemain,
        qtyRequire,
        null,
        null,
        null,
        buttons
      ]);
      this.renderStockTable();
      this.renderSelectedStockTable();
      this.updateActionBar();
    }
  }

  onDeleteButtonClicked(button) {
    if(button.id) {
      this.selectedStockMap.delete(button.id);
      this.renderStockTable();
      this.renderSelectedStockTable();
      this.updateActionBar();
    }
  }

  // onReserveDeleteButtonClicked(button) {
  //   if(button.id) {
  //     this.reservedItemMap.delete(button.id);
  //     this.renderStockTable();
  //     this.renderSelectedStockTable();
  //     this.updateActionBar();
  //   }
  // }

  onChangeStockUnit(row) {
    const currentUnit = row[SelectedStockTableColumnIndex.stockUnit].value;
    const currentQtyRemain = row[SelectedStockTableColumnIndex.stockUnit].options.find((option=>option.value==currentUnit)).stock;
    row[SelectedStockTableColumnIndex.qtyRemain] = currentQtyRemain;
    row[SelectedStockTableColumnIndex.qtyRequired].value = 0;

    row[SelectedStockTableColumnIndex.qtyRequired].class = '';
    row[SelectedStockTableColumnIndex.qtyRequired].tooltipMsg = null;
  }

  onInputChange(event, row) {
    this.haveUserChanges = true;

    const qtyRequired = parseInt(row[SelectedStockTableColumnIndex.qtyRequired].value);
    const qtyRemain = row[SelectedStockTableColumnIndex.qtyRemain];
    const qtyIssued = row[SelectedStockTableColumnIndex.qtyIssued];

    const _id = row[SelectedStockTableColumnIndex._id];
    const reservedItem = this.reservedItemMap.get(_id);
    let totalQuantity;

    if (Array.isArray(reservedItem) && reservedItem.length > 1) {
      totalQuantity = this.selectedStockTablexParam.content.filter( row => {
        return row[SelectedStockTableColumnIndex._id] == _id;
      }).reduce((prev, cur) => {
        const curQuantity = parseInt(cur[SelectedStockTableColumnIndex.qtyRequired].value);
        return isNaN(curQuantity) ? prev : prev + curQuantity;
      }, 0);
    } else {
      totalQuantity = qtyRequired;
    }

    if (qtyIssued > 0 && qtyRequired < qtyIssued) {
      row[SelectedStockTableColumnIndex.qtyRequired].class = 'error';
      row[SelectedStockTableColumnIndex.qtyRequired].tooltipMsg = JMLanguage.translate('pages.reserve-stock.stock-table.column.error.qty-required-under-issue');
    } else if (qtyRemain < totalQuantity) {
      row[SelectedStockTableColumnIndex.qtyRequired].class = 'error';
      row[SelectedStockTableColumnIndex.qtyRequired].tooltipMsg = JMLanguage.translate('pages.reserve-stock.stock-table.column.error.qty-required-over');
    } else {
      row[SelectedStockTableColumnIndex.qtyRequired].class = '';
      row[SelectedStockTableColumnIndex.qtyRequired].tooltipMsg = null;
    }
  }

  onInputKeyDown(event) {
    // block input chars other than integer
    return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space';
  }

  onInputKeyUp(event) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
  }

  async submitBtnAction() {
    let hasError = false;
    const stockToBeReserved = this.selectedStockTablexParam.content.map((row) => {
      const materialId = row[SelectedStockTableColumnIndex._id];
      const reservationItem = row[SelectedStockTableColumnIndex.reservationItem] || null;
      const qtyRequired = parseInt(row[SelectedStockTableColumnIndex.qtyRequired].value);
      const qtyIssued = row[SelectedStockTableColumnIndex.qtyIssued];

      if (Number.isNaN(qtyRequired)) {
        row[SelectedStockTableColumnIndex.qtyRequired].class = 'error';
        row[SelectedStockTableColumnIndex.qtyRequired].tooltipMsg = JMLanguage.translate('pages.reserve-stock.stock-table.column.error.qty-required');
        hasError = true;
      }

      if (qtyIssued > 0 && qtyRequired < qtyIssued) {
        row[SelectedStockTableColumnIndex.qtyRequired].class = 'error';
        row[SelectedStockTableColumnIndex.qtyRequired].tooltipMsg = JMLanguage.translate('pages.reserve-stock.stock-table.column.error.qty-required-under-issue');
        hasError = true;
      }

      if (this.selectedStockMap.has(materialId) && qtyRequired < 1) {
        row[SelectedStockTableColumnIndex.qtyRequired].class = 'error';
        row[SelectedStockTableColumnIndex.qtyRequired].tooltipMsg = JMLanguage.translate('pages.reserve-stock.stock-table.column.error.qty-required-new-zero');
        hasError = true;
      }

      return {
        materialId,
        reservationItem,
        unit: row[SelectedStockTableColumnIndex.stockUnit].value,
        materialNumber: row[SelectedStockTableColumnIndex.materialNumber].text,
        batchNumber: row[SelectedStockTableColumnIndex.batchNumber],
        storageLocationCode: row[SelectedStockTableColumnIndex.locationCode].text,
        qtyRequired,
      }
    });

    if (!hasError) {
      const aiStoreCodeList = Session.storageLocationAiList.map(loc => loc.code);
      const aiStoreStockToBeReserved = stockToBeReserved.filter(ele => {
        return aiStoreCodeList.includes(ele.storageLocationCode) && ele.qtyRequired && ele.qtyRequired > 0;
      });
      const hasAiStore = aiStoreStockToBeReserved.length > 0;

      if (hasAiStore) {
        this.formPayloadStockToBeReserved = stockToBeReserved;
        this.openAiStorePanel();
      } else {
        this.disabledActionSideBar = true;
        const response = await this.requestStockReserve(stockToBeReserved);
        this.disabledActionSideBar = false;
        if (response) {
          this.haveUserChanges = false;
          AppDelegate.navigate([`/job-card/view/${this.jobCardNumber}/stock-detail`]);
        }
      }
    }
  }

  updateResult(payload) {
    const newVersion = payload.version;
    const materials: Array<JM.JMOBJ.ReservationMaterial> = payload.material;

    this.reservationVersion = newVersion;
    materials.forEach((material) => {
      const resultRow = this.selectedStockTablexParam.content.find(row => {
        const materialNumberCol = row[SelectedStockTableColumnIndex.materialNumber];
        return materialNumberCol ? materialNumberCol.text == material.materialNumber : false;
      });

      if (!resultRow) return;
      if (material.isPreviousOperationSuccessful) {
        resultRow[SelectedStockTableColumnIndex.result] = {
          icon: "fas fa-check msg-success",
          class: 'msg-success ml-1',
          textAfter: JMLanguage.translate("global.success"),
        };
        resultRow[SelectedStockTableColumnIndex.action] = null;
      } else {
        resultRow[SelectedStockTableColumnIndex.result] = {
          icon: "fas fa-times msg-failure",
          class: 'msg-failure ml-1',
          textAfter: `${JMLanguage.translate("global.failure")} ${material.remarks || ''}`,
        };
      }
    })
  }

  exitBtnAction() {
    this.haveUserChanges = false;
    AppDelegate.navigate([`/job-card/view/${this.jobCardNumber}`]);
  }

  private initActionButtons(): void {
    this.addActionBtn(ActionButtonStock.submit, this.submitBtnAction.bind(this));
    this.addActionBtn(ActionButtonStock.exit, this.exitBtnAction.bind(this));
  }

  addActionBtn(buttonStatus: ActionButtonStock, button1CallBack?: Function, button2CallBack?: Function): void {
    let actionButton = this.actionBtnService.addActionBtn(
      ActionButtonType.stock,
      buttonStatus,
      button1CallBack,
      button2CallBack
    );
    actionButton && this.actionButtonData.push(actionButton);
  }

  updateActionBar() {
    this.actionButtonData[0].isEnable = !this.isSelectedStockTableEmpty;
  }

  // START: AI store panel
  public openAiStorePanel(): void {
    !this.aiStorePanelInited && this.initAiStorePanel();
    this.reserveAiStoreSlider.toggle();
  }

  public initAiStorePanel() {
    this.reserveAiStorePanel.init();
    this.aiStorePanelInited = true;
  }
  
  public async onAiStoreSubmit($event) {
    this.reserveAiStorePanel.isLoading = true;
    const response = await this.requestStockReserve(this.formPayloadStockToBeReserved, $event);
    this.reserveAiStorePanel.isLoading = false;
    this.reserveAiStoreSlider.toggle();
    if (response) {
      this.haveUserChanges = false;
      AppDelegate.navigate([`/job-card/view/${this.jobCardNumber}/stock-detail`]);
    }
  }
  // END: AI store panel

  get isSelectedStockTableEmpty () {
    return this.selectedStockMap.size == 0 && this.reservedItemMap.size == 0;
  }
}
