export enum DashboardStatisticType {
    PENDING_ACK = 0,
    FURTHER_ACTION_REQUIRED = 1,
    OUTSTANDING_PM_EQUIPMENT_INHOUSE = 2,
    OUTSTANDING_PM_EQUIPMENT_CONTRACT = 3,
    TO_BE_PROCESS = 4,
    REASSIGNMENT_REQUIRED = 5,
    FAILED_FAX = 6,
    MY_MANUAL_INSTRUCTION = 7,
    MANUAL_INSTRUCTION_QUEUE = 8,
    OTHER_MANUAL_INSTRUCTION = 9,
    PENDING_FAULT = 10,
    APPROVAL_CM = 11,
    APPROVAL_PM_PLAN = 12,
    APPROVAL_PM_JOB = 13,
}
