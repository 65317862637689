<div class="font-size-xl m-0 mb-2">
  {{ "component.plan-particulars.title" | translate}}
</div>
<hr class="underline" />

<div class="row mx-0 align-items-center">
  <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
    {{ (plan.planCoverage === "location" ? "component.plan-particulars.number-of-locations" : "component.plan-particulars.number-of-equipment") | translate }}
  </div>
  <div *ngIf="!editable?.equipmentCount" class="col-8 col-sm-4  text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="plan-part-equipment-count" emptyHandleDisplay="0" [ngClass]="{'input-error':errorFields?.equipmentCount}"
      [textField]="plan?.minPeriodEquipmentQty == plan?.maxPeriodEquipmentQty ? plan?.minPeriodEquipmentQty: plan?.minPeriodEquipmentQty + ' - ' + plan?.maxPeriodEquipmentQty">
    </app-display-text-field>
    <ng-container *ngIf="showPmPlanEquipmentLink">
      (<a href="/pm/standard-plan/equipment-list/{{plan.pmPlanNumber}}">{{ "component.pm-periods.detail" | translate }}</a>)
    </ng-container>
  </div>

  <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="mandatory-mark">*</span>
    {{ "component.plan-particulars.handling-team" | translate }}
  </div>
  <div *ngIf="!editable?.teamId" class="col-8 col-sm-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="plan-part-handling-team" [emptyHandleDisplay]="emptyHandleDisplay" [textField]="teamName" [ngClass]="{'input-error':errorFields?.teamId}">
    </app-display-text-field>
  </div>
  <div *ngIf="editable?.teamId" class="col-8 col-sm-4 col-8 text-left m-0 mb-4">
    <ng-select id="edit-plan-handling-team"
      class="px-0 "
      [class.input-error]="errorFields?.teamId"
      [items]="teamOptions"
      bindLabel="label"
      bindValue="value" 
      [(ngModel)]="plan.teamId"
      [loading]="isLoadingTeam"
      [disabled]="isDisabledTeam"
      (scrollToEnd)="onTeamScrollToEnd()"
      (clear)="onClearTeam($event)"
      (search)="onSearchTeam($event)"
    ></ng-select>
  </div>
</div>