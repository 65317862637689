<div class="wrapper">
  <app-nav (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar></app-sidebar>

  <!-- Page Content -->
  <div id="content" class="dashboard-content">
    <!--Event Count Layout-->
    <div class="row justify-content-start col-12 m-0 p-0">
      <div class="col-8 col-md-9 col-lg-9 col-xl-10">
        <div class="row">
          <div class="col-md-12">
            <div class="search-box">
              <i class="fas fa-search" (click)="search()"></i>
              <input class="form-control" id="search" type="search" (keyup.enter)="search()"
                [(ngModel)]="searchKeywords" placeholder="{{'pages.dashboard.search' | translate}}">
            </div>
          </div>
        </div>
        <ng-container *ngFor="let group of statisticGroups">
          <ng-container *ngIf="group.mode == 'tab'">
            <div class="row m-0 mt-4">
              <ng-container *ngFor="let tab of group.tabs">
                <div *ngIf="authorizationService.hasPermissions(group.tabGroups[tab.key].permission, true)"
                  class="group-tab col border border-bottom-0 border-secondary rounded-top text-center py-1"
                  [ngClass]="{ active : tabActive[group.type] == tab.key }"
                  (click)="onGroupTabClick(tab.key, group.type)">
                  {{ tab.text | translate }}
                </div>
              </ng-container>
            </div>
            <ng-container
              *ngIf="authorizationService.hasPermissions(group.tabGroups[tabActive[group.type]].permission, true)">
              <ng-container *ngFor="let group of group.tabGroups[tabActive[group.type]].subGroups">
                <div class="col border border-secondary rounded-bottom mb-4 group-container">
                  <ng-container *ngFor="let row of group.rows">
                    <ng-container *ngTemplateOutlet="statGroup; context: {$implicit: row}">
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="group.mode != 'tab' && isShowCscOperationContainer">
            <div class="col border border-secondary rounded-bottom mb-4 group-container">
              <ng-container *ngFor="let row of group.rows">
                <ng-container *ngTemplateOutlet="statGroup; context: {$implicit: row}">
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="work-centre-wrap col-4 col-md-3 col-lg-3 col-xl-2 p-2">
        <div class="title dropdown-label text-left text-md-center">
          {{ 'pages.dashboard.work-center' | translate}}
        </div>
        <ul class="list-group work-centre-list">
          <li class="list-group-item" (change)="selectAllCentre($event)">
            <input class="form-check-input" type="checkbox" value="workCentre-all" id="workCentre-all"
              change="selectAllCentre($event)" [checked]="workCentreOptions.length == selectedWorkCentres.length">
            <label class="form-check-label select-all-label" for="workCentre-all">
              <span class="btn" *ngIf="workCentreOptions.length != selectedWorkCentres.length">{{
                'pages.dashboard.select-all' | translate}}</span>
              <span class="btn" *ngIf="workCentreOptions.length == selectedWorkCentres.length">{{
                'pages.dashboard.unselect-all' | translate}}</span>
            </label>
          </li>
          <li class="list-group-item" *ngFor="let workCentre of workCentreOptions; let i = index">
            <input class="form-check-input" type="checkbox" value="{{workCentre}}" id="workCentre{{i}}"
              (change)="selectCentre($event, workCentre)" [checked]="selectedWorkCentres.includes(workCentre) ">
            <label class="form-check-label" for="workCentre{{i}}">
              {{workCentre}}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #statGroup let-group>
  <div class="stat-group-row-container">
    <ng-container *ngIf="authorizationService.hasPermission(group.permission)">
      <h2 class="title">{{ group.description | translate }}</h2>
      <hr class="underline" />
    </ng-container>
  
    <div class="row sn-stat-row">
      <ng-container *ngFor="let statistic of group.statistics">
        <ng-container *ngIf="authorizationService.hasPermissions(statistic.permission)">
          <div class="{{'statistic-item-wrapper col-12 my-3' + (group.className ? ' ' + group.className : '')}}">
  
            <div class="statistic-item-container">
              <div class="item-container">
                <div class="left">
                  <div class="img-wrapper">
                    <img class="icon-img" src="{{ statistic.icon }}">
                  </div>
                </div>
                <div class="right" class="right">
                  <div class="right-top-container d-flex justify-content-center">
                    <ng-container *ngIf="statistic.recordsSplitted">
                      <ng-container *ngFor="let item of statistic.value; let i = index">
                        <div *ngIf="statistic.records[i] && item" class="mini-item" [id]="statistic.id+'_'+i"
                          (click)="clickStatistic(statistic.key, statistic.records[i], statistic.value)"
                          [ngClass]="{'sn-stat-wrap':statistic.records[i].length > 0, 'blue':i > 0 && statistic.records[i].length > 0}">
                          {{ item }}
                        </div>
                        <div *ngIf="!item" class="mini-item" [id]="statistic.id+'_'+i">
                          0
                        </div>
                        <div class="mini-item" *ngIf="i < statistic.value.length-1">/</div>
                      </ng-container>
                      <ng-container *ngIf="statistic.value.length <= 0">
                        <div class="mini-item">
                          {{ "0 / 0" }} </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!statistic.recordsSplitted">
                      <div class="mini-item" (click)="clickStatistic(statistic.key, statistic.records, statistic.value)"
                        [ngClass]="{'sn-stat-wrap':statistic.value > 0}">{{ statistic.value }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="right-bottom-container d-flex">
                    <div class="font-size-m text-center w-100">{{ statistic.description | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>