<div class="d-flex justify-content-between mb-2 align-items-center">
  <div class="font-size-xl align-self-end">
    {{ "pages.pm-plan-list.search-criteria" | translate }}
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn icon-btn brand-amber mr-2 img-btn" (click)="onClickClear()" [disabled]="isDisabled">
      <i class="fas fa-undo"></i>
    </button>
    <button class="btn icon-btn brand-blue img-btn" (click)="onClickSearch()" [disabled]="isSearching || isDisabled">
      <div *ngIf="isSearching" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
      <i class="fas fa-search white font-size-l"></i>
    </button>
    <button class="btn icon-btn ml-2 d-inline-block d-xl-none navbar-toggler" (click)="isCollapse = !isCollapse">
      <i class="fas fa-chevron-up" [class.d-none]="isCollapse"></i>
      <i class="fas fa-chevron-down" [class.d-none]="!isCollapse"></i>
    </button>
  </div>
</div>
<hr class="underline" class="underline" />

<div class="navbar navbar-expand-xl px-0">
  <div class="row justify-content-between mx-0 collapse navbar-collapse align-items-start" id="search-field-wrap"
    [ngbCollapse]="isCollapse">
    <div class="col-12 col-md-6 col-xl-12  pl-0 pr-0 pr-md-3 pr-xl-0">
      <input class="form-control mb-3" placeholder="{{'pages.job-list.table-column.job-number' | translate}}"
        [(ngModel)]="searchSelections.jobCardNumber" id="jobCardNumber">
      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">{{ "pages.job-list.table-column.handling-party" |
          translate }}</span>
        <div class="d-inline-block" *ngIf="searchSelections.handlingParty">
          <label [for]="'search-handling-party-'+party.key+'-checkbox'"
            *ngFor="let party of searchSelections.handlingParty | keyvalue" class="checkbox-tag brand-blue"
            [class.checked]="searchSelections.handlingParty[party.key]">
            {{ "team.handling-party."+party.key | translate }}
            <input type="checkbox" hidden="true" name="handling-party"
              [(ngModel)]="searchSelections.handlingParty[party.key]"
              [id]="'search-handling-party-'+party.key+'-checkbox'" />
          </label>
        </div>
      </div>

      <input class="form-control mb-3" placeholder="{{'pages.job-list.table-column.ccs-service-order-number' | translate}}"
        [(ngModel)]="searchSelections.ccsServiceOrderNumber" id="search-ccsSoNumber">

      <ng-select id="orderType" class="px-0 mb-2 mt-3"
        placeholder="{{'pages.job-list.table-column.job-type' | translate}}" [items]="orderTypeOptions"
        bindLabel="label" bindValue="value" [(ngModel)]="searchSelections.orderType" [multiple]="false">
      </ng-select>

      <ng-select id="matType" class="px-0 mb-2 mt-3"
        placeholder="{{'pages.job-list.table-column.mat-type' | translate}}" [items]="matTypeOptions" bindLabel="label"
        bindValue="value" [(ngModel)]="searchSelections.matType" [multiple]="false">
      </ng-select>

      <hr class="d-block d-md-none d-xl-block" />
    </div>

    <div class="col-12 col-md-6 col-xl-12  pr-0 pl-0 pl-md-3 pl-xl-0">
      <input class="form-control mb-3" placeholder="{{'pages.job-list.table-column.client' | translate}}"
        [(ngModel)]="searchSelections.client" id="client">
      <ng-select class="px-0 mb-2 mt-3" placeholder="{{'components.search-criteria.location' | translate }}"
        bindLabel="label" bindValue="value" [items]="locationOptions" (open)="clearLocationFilter()"
        (search)="onSearchLocation($event)" (clear)="clearLocationFilter()" [loading]="isLoadingLocation"
        (scrollToEnd)="onLocationScrollToEnd()" [(ngModel)]="searchSelections.location">
      </ng-select>

      <input class="form-control mb-3" placeholder="{{'pages.job-list.table-column.equipment-id' | translate}}"
        [(ngModel)]="searchSelections.equipment" id="equipment">

      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">{{ "pages.job-list.table-column.priority" | translate
          }}</span>
        <div class="d-inline-block" *ngIf="searchSelections.priority">
          <label [for]="'search-priority-'+status.key+'-checkbox'"
            *ngFor="let status of searchSelections.priority | keyvalue" class="checkbox-tag brand-blue"
            [class.checked]="searchSelections.priority[status.key]">
            {{ "jobcard.priority."+status.key | translate }}
            <input type="checkbox" hidden="true" name="priority" [(ngModel)]="searchSelections.priority[status.key]"
              [id]="'search-priority-'+status.key+'-checkbox'" />
          </label>
        </div>
      </div>

      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">{{ "pages.job-list.table-column.teco-status" |
          translate }}</span>
        <div class="d-inline-block" *ngIf="searchSelections.tecoStatus">
          <label [for]="'search-tecoStatus-'+status.key+'-checkbox'"
            *ngFor="let status of searchSelections.tecoStatus | keyvalue" class="checkbox-tag brand-blue"
            [class.checked]="searchSelections.tecoStatus[status.key]">
            {{ "teco.status."+status.key | translate }}
            <input type="checkbox" hidden="true" name="tecoStatus" [(ngModel)]="searchSelections.tecoStatus[status.key]"
              [id]="'search-tecoStatus-'+status.key+'-checkbox'" />
          </label>
        </div>
      </div>

      <hr class="d-block d-md-none d-xl-block" />

      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">{{ "pages.job-list.table-column.status" | translate
          }}</span>
        <div class="d-inline-block" *ngIf="searchSelections.jobCardStatus">
          <label [for]="'search-jobCardStatus-'+status.key+'-checkbox'"
            *ngFor="let status of searchSelections.jobCardStatus | keyvalue" class="checkbox-tag brand-blue"
            [class.checked]="searchSelections.jobCardStatus[status.key]">
            {{ "jobcard.status."+status.key | translate }}
            <input type="checkbox" hidden="true" name="jobCardStatus"
              [(ngModel)]="searchSelections.jobCardStatus[status.key]"
              [id]="'search-jobCardStatus-'+status.key+'-checkbox'" />
          </label>
        </div>
      </div>
      <hr class="d-block d-md-none d-xl-block" />
      <div class="mt-3">
        <div class="mt-3">
        <div class="d-inline d-md-block d-xl-inline gray-40 mr-2">
          {{"pages.job-list.table-column.job-create-time-from" | translate}}</div>
          <div class="d-inline-block">
            <app-date-input class="mt-1 d-inline-block" #startDateElem
              [input]="searchSelections.createTimeFrom" [inputId]="'job-create-time-from'" [field]="'startDate'" 
              (onBlur)="onBlurDateInput($event)" [dateAlertMsg]="dateAlertMsg" >
            </app-date-input>
          </div>
        </div>
        <div class="mt-3">
        <div class="d-inline d-md-block d-xl-inline gray-40 mr-2">
          {{"pages.job-list.table-column.job-create-time-to" | translate}}</div>
        <div class="d-inline-block">
          <app-date-input class="mt-1 d-inline-block" #endDateElem
            [input]="searchSelections.createTimeTo" [inputId]="'job-create-time-to'" [field]="'endDate'" 
            (onBlur)="onBlurDateInput($event)" [dateAlertMsg]="dateAlertMsg">
          </app-date-input>
        </div>
      </div>
        <div *ngIf="showCreationTimeErrorMsg" class="red font-italic">
          {{ 'pages.job-list.search-criteria.error-msg.wrong-creation-time-from' | translate }}
        </div>
      </div>
      <input id="search-plan-officerInCharge" class="form-control mb-2 mt-3"
        placeholder="{{'pages.job-list.table-column.officer-in-charge' | translate}}"
        [(ngModel)]="searchSelections.officerInCharge">

    </div>

  </div>

</div>