import { Component, Injector, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { BasePage } from '../../model/base/base';
@Component({
  selector: 'app-reject-job-card-completion',
  templateUrl: './reject-job-card-completion.component.html',
  styleUrls: ['./reject-job-card-completion.component.scss']
})

export class RejectJobCardCompletionComponent extends BasePage implements OnInit {
  @Input() isLoading: boolean;
  @Output() onSubmit = new EventEmitter<any>();
  remarks: string;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngOnChanges() {
  }

  onClickSubmit() {
    let buttons = [
      {
        name: this.translateService.instant("global.ok"), 
        handler: () => {
          if (this.remarks) this.remarks = this.remarks.trim();
          this.onSubmit.emit(this.remarks);
        }
      }, 
      {name: this.translateService.instant("global.no")}
    ];

    this.showPopUpAlert(this.translate("action.button.popup.reject-approval"), "", buttons);
  }

  get getMaxRemarksLength():number{
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }

}
