<div class="wrapper">
    <app-nav [breadcrumbs]="breadcrumbs" >
    </app-nav>
  
    <app-sidebar></app-sidebar>
    <div id="content">
        <div class="row justify-content-between no-gutters">
            <h4 class="title">
                {{"pages.pm-period-equipment-list.select-equipment-title" | translate}} 
                {{ " ( " + periodDateString + " ) " }}
            </h4>
            <h5>
                {{ "pages.pm-plan-equipment-list.selected-text" | translate:[numberOfRowsSelected,(tablexParam.content?.length ? tablexParam.content?.length : 0)]}}
            </h5>
        </div>
        <hr class="underline" />

        <div id="multi-tab-content-container" class="container-fluid">
            <ul class="nav nav-tabs">
                <li *ngFor="let tab of tabOptions | keyvalue" class="nav-item">
                  <a class="nav-link" [class.active]="activeTab==tab.value" (click)="onClickSetActiveTab(tab.value)">{{tab.value}}</a>
                </li>
              </ul>
            <div class="content row flex-column">
                <div *ngIf="enableAssignButton"
                    class="buttons-wrapper">

                    <app-submit-button 
                        [text]="submitBtnName"
                        [buttonClass]="'btn standard brand-blue'"
                        [isDisabled]="numberOfRowsSelected <= 0 || isSubmitting"
                        [isLoading]="isSubmitting" 
                        (onClick)="onSubmitClick()" 
                    ></app-submit-button>

                    <button 
                        class="btn standard brand-blue" 
                        [disabled]="numberOfRowsSelected >= this.tablexParam?.content?.length"
                        (click)="onSelectAll()" 
                        >
                        {{"pages.pm-period-equipment-list.button.select-all" | translate}}
                    </button>

                    <button 
                        class="btn standard brand-blue" 
                        [disabled]="numberOfRowsSelected <= 0"
                        (click)="onUnselectAll()"
                        >
                        {{"pages.pm-period-equipment-list.button.unselect-all" | translate}}
                    </button>
                </div>
                <app-tablex id="equipment-list-table" #equipmentListTable [tablexParam]="tablexParam"></app-tablex>
            </div>
        </div>

        
    </div>
</div>