import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionButtonDefinition, ActionButtonPmJob, ActionButtonType } from '@enum/action-button';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/AppDelegate';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Router, ActivatedRoute } from '@angular/router';
import { PmJobDescriptionComponent } from '../../components/pm-job-description/pm-job-description.component';
import { PmJobClientInformationComponent } from '../../components/pm-job-client-information/pm-job-client-information.component';
import { PmJobJobProgressComponent } from '../../components/pm-job-job-progress/pm-job-job-progress.component';
import { PmJobService } from '@services/pm-job.service';
import { ActionButtonService } from '@services/action-button.service';
import { PmJobEquipmentInformationComponent } from '../../components/pm-job-equipment-information/pm-job-equipment-information.component';

@Component({
  selector: 'app-pm-job-edit',
  templateUrl: './pm-job-edit.component.html',
  styleUrls: [],
})
export class PmJobEditComponent extends BasePage implements OnInit {
  @ViewChild(PmJobDescriptionComponent, { static: true }) pmJobDescriptionElem: PmJobDescriptionComponent;
  @ViewChild(PmJobClientInformationComponent, { static: false }) pmJobClientInformationElem: PmJobClientInformationComponent;
  @ViewChild(PmJobJobProgressComponent, { static: false }) pmJobJobProgressElem: PmJobJobProgressComponent;
  @ViewChild('pmJobEquipmentInformationElem', { static: false }) pmJobEquipmentInformationElem: PmJobEquipmentInformationComponent;

  actionButtonData = [];
  pmJobNumber = undefined;
  job: any = {};
  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.CREATE;
  breadcrumbs: any = [];
  routerState: { [key:string] : any};

  route: ActivatedRoute;
  router: Router;
  pmJobService: PmJobService;
  actionBtnService: ActionButtonService;

  constructor(
    injector: Injector,
    router: Router,
    route: ActivatedRoute,
    pmJobService: PmJobService,
    actionBtnService: ActionButtonService
  ) {
    super(injector);
    this.route = route;
    this.router = router;
    this.pmJobService = pmJobService;
    this.actionBtnService = actionBtnService;
  }

  async ngOnInit() {
    this.routerState = history.state;
    this.initPageMode();
    this.checkPagePermission();
    if (this.isPageModeCreate && this.routerState && this.routerState.pmPlanId) {
      this.job.pmPlan = {};
      this.job.pmPlan.pmPlanNumber = (this.routerState as PmJobEditComponentRouterStateI).pmPlanId;
      this.job.pmPlan.status = (this.routerState as PmJobEditComponentRouterStateI).pmPlanStatus;

      this.pmJobDescriptionElem.searchPmPlanKeyword = this.routerState.pmPlanId;
    }
    await this.initPageData();
    this.initBreadCrumbs();
    this.initActionButtons();
  }

  initPageMode() {
    let mode = this.route.snapshot.paramMap.get('mode');
    let parsedMode = this.pmJobService.initPageMode(mode);
    this.pageMode = parsedMode ? parsedMode : this.pageMode;
  }

  async initPageData() {
    this.pmJobNumber = this.route.snapshot.paramMap.get('jobNumber');
    if (this.pageMode === JMENUM.JMPageMode.EDIT && this.pmJobNumber) {
      await this.requestPmJob();
    }
  }

  checkPagePermission() {
    let pass = true;

    if (this.pageMode === JMENUM.JMPageMode.EDIT) {
      pass = pass && this.authorizationService.hasPermissions([JMENUM.Permission.PMJOB_UPDATE], false);
    }
    if (this.pageMode === JMENUM.JMPageMode.CREATE) {
      pass = pass && this.authorizationService.hasPermissions([JMENUM.Permission.PMJOB_CREATE], false);
    }

    if (!pass) {
      this.router.navigate(['/']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
  }

  initBreadCrumbs() {
    switch (this.pageMode) {
      case JMENUM.JMPageMode.EDIT:
        this.breadcrumbs = [
          {
            id: 'breadcrumbs-pm-job',
            name: JMLanguage.translate('pages.pm-job.page-title.edit'),
            route: '/pm/job-list',
          },
          {
            id: 'breadcrumbs-pm-job-number',
            name: this.pmJobNumber,
            route: null,
            currentPage: true,
          },
        ];
        break;
      case JMENUM.JMPageMode.CREATE:
        this.breadcrumbs = [
          {
            id: 'breadcrumbs-pm-job',
            name: JMLanguage.translate('pages.pm-job.page-title.create'),
            route: null,
          },
        ];
        break;
    }
  }

  validationFieldsOnAllComponents(): boolean {
    let pass = true;
    if (!this.pmJobDescriptionElem.validate()) pass = false;
    if (!this.pmJobClientInformationElem.validate()) pass = false;
    if (!this.pmJobJobProgressElem.validate()) pass = false;
    return pass;
  }

  async requestSavePmJob() {
    if (!this.validationFieldsOnAllComponents()) return false;
    this.actionButtonIsEnable(false);
    let response = await this.pmJobService.savePmJob(this.job);
    this.actionButtonIsEnable(true);
    return response;
  }

  async requestPmJob() {
    let response = await this.pmJobService.getPmJob(this.pmJobNumber);
    this.job = response && response.payload;
  }

  initActionButtons(): void {
    this.actionButtonData = [];
    let draft = this.job && this.job.status == JMENUM.PMJobStatus.DRAFT;

    this.addActionBtn(ActionButtonPmJob.save, this.saveBtnAction);
    this.addActionBtn(ActionButtonPmJob.clear, this.clearBtnAction);
    if (draft) {
      this.addActionBtn(ActionButtonPmJob.submit, this.submitBtnAction);
    }
    if (draft) {
      this.addActionBtn(ActionButtonPmJob.equipment, this.equipmentBtnAction);
    }
    this.addActionBtn(ActionButtonPmJob.close, this.closeBtnAction);
  }

  saveBtnAction = async () => {
    let response = await this.requestSavePmJob();
    if (response && response.payload && response.payload.pmJobNumber) {
      this.router.navigate(['/pm/job/view', response.payload.pmJobNumber]);
    }
  };

  clearBtnAction = () => {
    this.pmJobDescriptionElem.clearAll();
    this.pmJobClientInformationElem.clearAll();
    this.pmJobJobProgressElem.clearAll();
  };

  submitBtnAction = async () => {
    if (!this.validationFieldsOnAllComponents()) return false;
    this.actionButtonIsEnable(false);
    let response = await this.pmJobService.submitPmJob(this.job);
    this.actionButtonIsEnable(true);
    if (response && response.payload && response.payload.pmJobNumber) {
      this.router.navigate(['/pm/job/view', response.payload.pmJobNumber]);
    }
  };

  equipmentBtnAction = async () => {
    let response = await this.requestSavePmJob();
    if (response && response.payload && response.payload.pmJobNumber) {
      this.router.navigate(['/pm/job/equipment-list/edit', this.job.period._id, response.payload.pmJobNumber]);
    }
  };

  closeBtnAction = () => {
    if (this.pmJobNumber) this.router.navigate(['/pm/job/view/' + this.pmJobNumber]);
    else this.router.navigate(['/dashboard']);
  };

  addActionBtn(buttonStatus: ActionButtonPmJob, button1CallBack?: Function, button2CallBack?: Function): void {
    let actionButton = this.actionBtnService.addActionBtn(
      ActionButtonType.pmJob,
      buttonStatus,
      button1CallBack,
      button2CallBack
    );
    actionButton && this.actionButtonData.push(actionButton);
  }

  actionButtonIsEnable(status: boolean) {
    this.actionBtnService.isEnable(this.actionButtonData, status);
  }

  get isPageModeCreate(){
    return this.pageMode == JMENUM.JMPageMode.CREATE;
  }

  get isPageModeEdit(){
    return this.pageMode == JMENUM.JMPageMode.EDIT;
  }
}

export interface PmJobEditComponentRouterStateI {
  periodId: string;
  pmPlanId: string;
  pmPlanStatus: string;
}
