<div class="sla-form-wrap">
  <form>
    <div class="title-wrap row justify-content-between mx-0 mb-4">
      <h3 class="d-inline">{{ 'add-sla-form.title' | translate }}</h3>
      <div class="button-wrap text-right">
        <app-submit-button 
          [id]="'dummy_sla_save_button'"
          [text]="'global.submit'"
          [buttonClass]="'d-block standard brand-amber mr-0'"
          [iconClass]="'fa-save'"
          [isDisabled]="!uiEnableSubmitButton"
          [isLoading]=""
          (onClick)="onSubmitButtonClicked($event)" 
        ></app-submit-button>
      </div>
    </div>
    
    
    <h4 class="mt-4">{{ "add-sla-form.sla-number" | translate }}</h4>
    <input id="id_search_input"
      type="text" 
      class="form-control" 
      [(ngModel)]="uiNewSlaNumber" 
      name="newSlaNumber"
      (keyup)="onNewSlaNumberKeyup($event)"
    >

    <br>
    <div class="search-result-container" (scroll)="onScroll($event)">
      <ul class="my-2 pl-0">
        <ng-container *ngIf="uiSlaArray">
          <ng-container *ngFor="let sla of uiSlaArray;let i = index">
            <li class="py-1">          
              <div class="checkbox-wrap position-relative custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" 
                id="sla_checkbox_{{sla.key}}"
                class="checkbox custom-control-input"
                name="sla_checkbox_{{sla.key}}"
                [(ngModel)]="sla.checked"
                (change)="onCheckBoxClicked($event, i, sla.key)"
              >
              <label class="custom-control-label" for="sla_checkbox_{{sla.key}}" id="id_sla_label_{{sla.key}}">
                {{ sla.label }}  
              </label>
              </div>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </form>
</div>

<ng-template #slaNotEffectiveToolTip>
    <div class="warning-tooltip-wrap text-center">
      {{ "add-sla-form.sla-effective-tooltip" | translate }}
    </div>
</ng-template>