import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snStatus'
})

/* Deprecated */
export class SnStatusPipe implements PipeTransform {

  transform(value: number, lang?: string): string {
    let _lang = lang === 'en' ? lang : 'zh';
    let statusStr: string = '-';
    switch (value) {
      case 1:
        statusStr = _lang === 'en' ? 'Draft' : '草案';
        break
      case 2:
        statusStr = _lang === 'en' ? 'Pending Acknowledge' : '等待確認';
        break
      case 3:
        statusStr = _lang === 'en' ? 'No Response' : '沒有反應';
        break
      case 4:
        statusStr = _lang === 'en' ? 'Rejected' : '被拒絕';
        break
      case 5:
        statusStr = _lang === 'en' ? 'Acknowledged' : '確認';
        break
      case 6:
        statusStr = _lang === 'en' ? 'In Progress' : '進行中';
        break
      case 7:
        statusStr = _lang === 'en' ? 'Cancelled' : '取消';
        break
      case 8:
        statusStr = _lang === 'en' ? 'Completed' : '完成';
        break
      case 9:
        statusStr = _lang === 'en' ? 'Signed Off' : 'zh-Signed off';
        break
      default:
        break;
    }
    return statusStr;
  }
}