import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMUTILITY, JMREASON } from '@ccep/CCEPConnector-ts';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { TablexComponentParams } from 'src/app/ui/components/tablex/tablex.component';
import { Session } from '@services/session';

import { PmJobService } from '@services/pm-job.service';

@Component({
  selector: 'app-ccs-so-obtain-form',
  templateUrl: './ccs-so-obtain-form.component.html',
  styleUrls: ['./ccs-so-obtain-form.component.scss']
})
export class CcsSoObtainFormComponent implements OnInit, OnChanges {
  @Input() pmJob: JMOBJ.PmJob;

  @Output() onComplete = new EventEmitter<any>();

  equipmentListFull: Array<any>;
  equipmentListFiltered: Array<any>;
  tablexParam: TablexComponentParams = {};

  slaNumberSet = new Set<string>();
  slaItemMap = new Map<string, any>();
  slaNumberOptions = [];
  slaItemOptions = [];
  slaNumberSelected: string;
  slaItemSelected: string;

  isLoading: boolean = false;
  isLoadingSla: boolean = false;
  ccsAcknowledge: boolean = false;
  submitDisabled: boolean = true;

  constructor(private pmJobService:PmJobService) { }

  ngOnInit() {
    this.tablexParam = {
      enableSetPageSize: true,
      enablePagination: true,
      enableStickyHeader: false,
      enableClearFilter: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSelectedAll: false,
      enableSort: false,
      enableSelectCol: false,

      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,  
      currentPage: null,
      pageCount: 0,

      onPageNumberClicked: this.onPageNumberClicked.bind(this),
      onPageSizeClicked: this.onPageSizeClicked.bind(this),

      headers: [
        {
          id: 'equipmentNumber',
          name: "pages.sn.generate-ccs-so.table-column-equipment-number",
          enableFilter: false,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 col-lg-2 p-1",
        },
        {
          id: 'description',
          name: "pages.sn.generate-ccs-so.table-column-description",
          enableFilter: false,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-3 col-lg-3 p-1",
        },
        {
          id: 'location',
          name: "pages.sn.generate-ccs-so.table-column-location-code",
          enableFilter: false,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 col-lg-2 p-1",
        },
        {
          id: 'locationDescription',
          name: "pages.sn.generate-ccs-so.table-column-location-description",
          enableFilter: false,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-3 col-lg-3 p-1",
        },
        {
          id: 'clientShortName',
          name: "pages.sn.generate-ccs-so.table-column-client",
          enableFilter: false,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 col-lg-2 p-1",
        }
      ],
    }
  }
  
  async ngOnChanges() {
    this.ccsAcknowledge = false;
    if(this.pmJob) {
      const equipmentNumberList = this.pmJob.equipmentList.filter((equip) => {
        return equip['ccsStatus'] != "pending" && !equip['ccsServiceOrderNumber'];
      }).map((equip) => {
        return equip['equipmentNumber'];
      });

      if (equipmentNumberList.length < 1) return;

      await this.requestEquipmentSummary(equipmentNumberList);
      this.getSlaOptionsFromEquipmentList();

      this.tablexParam.currentPage = 1;
      this.renderTable(this.tablexParam.currentPage, this.tablexParam.currentPageSize);
    }
  }

  resetUiState() {
    this.ccsAcknowledge = false;
    this.slaNumberSelected = null;
    this.slaItemSelected = null;
  }

  async requestEquipmentSummary(equipmentNumberList: string[]) {
    const request = new JM.JMRequestEquipmentsEquipmentSummary();
    request.sortBy = "equipmentNumber";
    request.sortOrder = JMENUM.SortOrder.ASC;
    request.equipmentNumber = equipmentNumberList;
    request.parameters = ["equipmentNumber", "description", "clientShortName", "location", "locationDescription", "slaItemList"];

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.equipmentListFull = response.payload.records;
  }

  getSlaOptionsFromEquipmentList() {
    this.slaNumberSet.clear();
    this.slaItemMap.clear();
    Array.isArray(this.equipmentListFull) && this.equipmentListFull.length > 0 && this.equipmentListFull.forEach(equip => {
      Array.isArray(equip.slaItemList) && equip.slaItemList.length > 0 && equip.slaItemList.forEach(slaItem => {
        if(slaItem.material.includes("SLA_PM") || slaItem.material.includes("BILL_AMC")) {
          this.slaNumberSet.add(slaItem.agreementNumber);
          this.slaItemMap.set(`${slaItem.agreementNumber}.${slaItem.itemNumber}`, {
            agreementNumber: slaItem.agreementNumber,
            itemNumber: slaItem.itemNumber
          });
        }
      });
    });

    this.slaNumberOptions = Array.from(this.slaNumberSet);
    this.slaItemOptions = [];
  }

  onSlaNumberChange() {
    this.slaItemOptions = Array.from(this.slaItemMap.values()).filter(obj => {
      return obj.agreementNumber == this.slaNumberSelected;
    });

    this.tablexParam.currentPage = 1;
    this.renderTable(this.tablexParam.currentPage, this.tablexParam.currentPageSize);
  }

  onSlaItemChange() {
    this.tablexParam.currentPage = 1;
    this.renderTable(this.tablexParam.currentPage, this.tablexParam.currentPageSize);
  }

  renderTable(pageNumber, pageSize) {
    this.equipmentListFiltered = this.equipmentListFull.filter(equip => {
      let passFilter;

      // Array.isArray(equip.slaItemList) && equip.slaItemList.length > 0 && equip.slaItemList.forEach(slaItem => {
      //   if (passFilter) return;

      //   if (this.slaNumberSelected) {
      //     if(slaItem.agreementNumber != this.slaNumberSelected) return;
      //   } else {
      //     passFilter = true;
      //   }

      //   if (this.slaItemSelected) {
      //     slaItem.itemNumber == this.slaItemSelected && (passFilter = true);
      //   } else {
      //     passFilter = true;
      //   }
      // });

      Array.isArray(equip.slaItemList) && equip.slaItemList.length > 0 && (passFilter = equip.slaItemList.find(slaItem => {
        if (this.slaNumberSelected) {
          if(slaItem.agreementNumber != this.slaNumberSelected) return;
        } else {
          return true;
        }

        if (this.slaItemSelected) {
          if(slaItem.itemNumber == this.slaItemSelected) return true;
        } else {
          return true;
        }
      }));

      return passFilter;
    });

    this.tablexParam.pageCount = Math.ceil(this.equipmentListFiltered.length / this.tablexParam.currentPageSize);

    const equipmentPage = this.equipmentListFiltered.slice((pageNumber - 1) * pageSize, pageSize);
    this.tablexParam.content = equipmentPage.map(equip => {
      return [
        equip.equipmentNumber,
        equip.description,
        equip.location,
        equip.locationDescription ? equip.locationDescription[Session.selectedLanguage]:'',
        equip.clientShortName,
      ]
    });
  }

  onPageNumberClicked(pageNumber) {
    this.tablexParam.currentPage = pageNumber;
    this.renderTable(this.tablexParam.currentPage, this.tablexParam.currentPageSize);
  }

  onPageSizeClicked(pageSize) {
    this.tablexParam.currentPageSize = pageSize;
    this.tablexParam.currentPage = 1;
    this.renderTable(this.tablexParam.currentPage, this.tablexParam.currentPageSize);
  }

  validateForm() {
    let isValid = false;

    if (Array.isArray(this.equipmentListFiltered) && this.equipmentListFiltered.length > 0) { isValid = true; }

    // check disclaimer
    if (!this.ccsAcknowledge) { isValid = false; }

    this.submitDisabled = !isValid;
  }

  onClickSubmit() {
    AppDelegate.showPopUpAlert(JMLanguage.translate('pages.sn.popup.confirm-submit-ccsso-number-title'), null, [
      {
        name: JMLanguage.translate('global.yes'),
        handler: async () => {
          this.isLoading = true;
          const response: JM.JMResponseJobCardsObtainCcsSoNumber = await this.pmJobService.generateCcsSoPmJob(
            this.pmJob,
            this.equipmentListFiltered.map(equip=>equip.equipmentNumber),
            this.slaNumberSelected,
            this.slaItemSelected);
          this.isLoading = false;
          if (response && response.code == 200 && response.payload) {
            this.onComplete.emit();
          }

          // this.onSubmit.emit({
          //   cancelCode: this.formFields.reasonCode,
          //   cancelRemarks: this.formFields.remarks.trim(),
          // }),
        }
      },
      { name: JMLanguage.translate('global.no') },
    ]);
  }
}
