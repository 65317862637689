import { Component, OnInit, ViewChild } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { JM, JMCONSTANT, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AuthorizationService } from '@services/authorization.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as utility from 'src/app/services/utility';
import { Session } from '@services/session';
import { formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { toNgbDate } from 'src/lib/presenter/Formatter';
import { AttachmentMimeType } from '@enum/attachment-file-type';
import { PmJobService } from '@services/pm-job.service';
import { JobInspectionInputInterface } from 'src/app/ui/components/inspection-management/inspection-management.component'

@Component({
  selector: 'app-pm-job-inspection-management',
  templateUrl: './pm-job-inspection-management.component.html',
  styleUrls: ['./pm-job-inspection-management.component.scss']
})
export class PmJobInspectionManagementComponent implements OnInit {
  @ViewChild('attachment_preview', { static: true }) attachmentPreview;
  @ViewChild('submitJobInspectionPanel', { static: true }) submitJobInspectionPanel: CustomSliderPanelComponent;
  
  protected route: ActivatedRoute;
  protected authorizationService: AuthorizationService;
  protected pmJobService: PmJobService;
  protected router: Router;
  breadcrumbs: any = [];
  jobNumber: string;
  job: JMOBJ.PmJob;
  isPreviewerLoading: boolean = false;
  isLoading:boolean = false;
  selectedInspection: any;
  inspectionList: any = [];
  hasEditPermission: boolean = false;
  statusListWithoutEditPermission = [JMENUM.JobCardStatus.COMPLETED];
  tablexParam: any = {};
  fileDetails: any = {};
  file: any;
  submitDisabled:boolean = true;
  //form
  hasSubmitJobInspectionPermission = false
  isSubmitJobInspectionPanelShow: any = {
    jobStatus: [
      JMENUM.PMJobStatus.SUBMITTED,
      JMENUM.PMJobStatus.COMPLETED,
      JMENUM.PMJobStatus.REWORKING
    ]
  }
  dateInputSet = {
    inspectionDate: undefined,
    inspectionTime: undefined,
  };
  errorFields: any = {};
  jobInspectionInput: JobInspectionInputInterface = {
    inspectorPost: (Session && Session.userInfo.name) || undefined,
    inspectorName: (Session && Session.userInfo.employeeName) || undefined,
    inspectionResult: undefined,
    modeOfCheck: undefined,
    formattedDate: undefined,
    remark: undefined
  };
  pdfFiles?: Array<File> = [];
  imageFiles?: Array<File> = [];
  valid:boolean = false;
  imageAcceptType:any = [
    AttachmentMimeType.Png,
    AttachmentMimeType.Jpeg
  ];
  pdfAcceptType:any = [AttachmentMimeType.Pdf];

  constructor(router: Router,
    route: ActivatedRoute,
    authorizationService: AuthorizationService, ) {
    this.router = router;
    this.route = route;
    this.authorizationService = authorizationService;
    this.jobNumber = this.route.snapshot.paramMap.get('jobNumber');
  }

  async ngOnInit() {
    this.checkfeatureEnabled();
    this.checkPermission();
    this.initBreadCrumbs();
    await this.requestPmJob();
    this.hasSubmitJobInspectionPermission = this.checkSubmitJobInspectionPermission();
    this.initTable();
    this.requestInspectionReportHistory();
    this.initDateTimeSet();
    this.resetAllErrorFields();
    this.checkSubmitButtonStatus();
  }

  async onClickSubmit() {
    this.uploadFiles().then(() => {
      this.requestCompleteApplication();
    }).catch((error)=>{
      console.error(error)
    });
  }
  
  async uploadFiles() {
    const promises: any = [];
    
    this.imageFiles.forEach((imageFile: any) => {
      if (!imageFile.attachmentId) {
        promises.push(new Promise(async (resolve) => {
          let request: JM.JMRequestFilesUploadFile = new JM.JMRequestFilesUploadFile()
          let uploadData = new FormData();
          uploadData.append('files', imageFile, imageFile.name);

          const response: JM.JMResponseFilesUploadFile = await AppDelegate.sendJMFileRequestWithFileHost(request, uploadData)

          if (!response || response.code !== 200 || !response.payload) {
            throw new Error(`Failed to upload file: ${imageFile.name}`);
          } else {
            imageFile.attachmentId = response.payload[0].attachmentId;
          }
          resolve(response)
        }));
      }
    });

    this.pdfFiles.forEach((pdfFile: any) => {
      if (!pdfFile.attachmentId) {
        promises.push(new Promise(async (resolve) => {
          let request: JM.JMRequestFilesUploadFile = new JM.JMRequestFilesUploadFile()
          let uploadData = new FormData();
          uploadData.append('files', pdfFile, pdfFile.name);

          const response: JM.JMResponseFilesUploadFile = await AppDelegate.sendJMFileRequestWithFileHost(request, uploadData)

          if (!response || response.code !== 200 || !response.payload) {
            throw new Error(`Failed to upload file: ${pdfFile.name}`);
          } else {
            pdfFile.attachmentId = response.payload[0].attachmentId;
          }
          resolve(response)
        }));
      }
    });

    await Promise.all(promises);

  }

  async requestCompleteApplication(){
    const {inspectionResult, modeOfCheck, formattedDate, remark, inspectorName, inspectorPost} = this.jobInspectionInput
    let request: JM.JMRequestContractsGeneratePmJobInspectionReport = new JM.JMRequestContractsGeneratePmJobInspectionReport();
    request.pmJobNumber = this.jobNumber;
    request.inspectionResult = inspectionResult != undefined && inspectionResult;
    request.inspectionMode = modeOfCheck != undefined && modeOfCheck;
    request.inspectedAt = formattedDate != undefined && formattedDate;
    if(inspectorName != undefined && inspectorName.trim().length > 0){
      request.inspectorName = inspectorName
    }
    if(inspectorPost != undefined && inspectorPost.trim().length > 0){
      request.inspectorPost = inspectorPost
    }
    if(remark != undefined && remark.length > 0){
      request.remarks = remark
    }
    if (this.imageFiles.length > 0) {
      if (request.attachmentList == undefined) {
        request.attachmentList = []
      }
      request.attachmentList = request.attachmentList.concat(this.getParsedAttachmentList(this.imageFiles))
    }
    if (this.pdfFiles.length > 0) {
      if (request.attachmentList == undefined) {
        request.attachmentList = []
      }
      request.attachmentList = request.attachmentList.concat(this.getParsedAttachmentList(this.pdfFiles))
    }

    this.isLoading = true;
    const response: JM.JMResponseContractsGenerateJobCardInspectionReport = await AppDelegate.sendJMRequest(request)
    this.isLoading = false;

    if (!response || !response.code || response.code != 200) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate("global.submitted"));
    this.resetApplicationForm();
    this.submitJobInspectionPanel.toggle();
    this.requestInspectionReportHistory();
    this.renderTable();
    this.unselectTablexRow();
  }

  unselectTablexRow(){
    this.tablexParam.selectedRowIndex=-1
  }

  getParsedAttachmentList(Files): JM.JMRequestContractsGenerateJobCardInspectionReport['attachmentList'] {
    return Files.map(File => {
      return {
        attachmentId: File['attachmentId'],
        attachmentType: File['attachmentType'],
        description: File.name
      }
    })
  }

  checkSubmitButtonStatus() {
    const { inspectionResult, modeOfCheck, formattedDate, inspectorName, inspectorPost } = this.jobInspectionInput
    const { inspectionDate, inspectionTime} = this.dateInputSet 
    if (inspectionResult != undefined &&
      modeOfCheck != undefined &&
      inspectionDate != undefined &&
      inspectionTime != undefined &&
      formattedDate != undefined &&
      inspectorName.trim().length > 0 &&
      inspectorPost.trim().length > 0
    ) {
      this.submitDisabled = false;
    } else {
      this.submitDisabled = true;
    }
  }

  isDateValid = (dateString: string): boolean => {
    let charRegex = /[a-zA-Z]+/
    if(dateString == undefined){
      return false;
    }

    if (dateString.length > 0 && dateString.match(charRegex)) {
      return false;
    }
    return true;
  }

  resetApplicationForm(){
    this.jobInspectionInput.inspectorName = Session && Session.userInfo && Session.userInfo.employeeName ? Session.userInfo.employeeName : undefined;
    this.jobInspectionInput.inspectorPost = Session && Session.userInfo && Session.userInfo.name ? Session.userInfo.name : undefined;
    this.jobInspectionInput.inspectionResult = undefined;
    this.jobInspectionInput.modeOfCheck = undefined;
    this.initDateTimeSet();
    this.jobInspectionInput.remark = undefined;
    this.imageFiles = [];
    this.pdfFiles = [];
    this.checkSubmitButtonStatus();
  }

  onChangeImageFileInput(event: Event){
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const uploadFiles: FileList = target.files;
    if (uploadFiles.length > 0 && this.isImageFileVaild(uploadFiles)) {
      this.setImageFile(uploadFiles);
    }
  }

  onChangePdfFileInput(event: Event){
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const uploadFiles: FileList = target.files;
    if (uploadFiles.length > 0 && this.isPdfFileVaild(uploadFiles)) {
      this.setPdfFile(uploadFiles);
    }
  }

  private setPdfFile(files?: FileList) {
    for (let i = 0; i < files.length; i++) {
      (files[i] as any).attachmentType = JMENUM.InspectionAttachmentType.DOCUMENT
    }

    this.pdfFiles = this.pdfFiles.concat(Array.from(files));
  }
  
  private setImageFile(files?: FileList) {
    for (let i = 0; i < files.length; i++) {
      (files[i] as any).attachmentType = JMENUM.InspectionAttachmentType.PHOTO
    }

    this.imageFiles = this.imageFiles.concat(Array.from(files));
  }

  isPdfFileVaild(uploadFiles: FileList){
    let isVaild= true;
    for (let i = 0; i < uploadFiles.length; i++) {
     
      if(uploadFiles[i].size  > Constants.ATTACHMENT_SIZE){
        AppDelegate.openSnackBar(JMLanguage.translate("component.attachment.oversize"))
        isVaild = false
      }
      if(!this.pdfAcceptType.includes(uploadFiles[i].type)){
        AppDelegate.openSnackBar(JMLanguage.translate("component.inspection.job.inspection.attachment.document.type.error"))
        isVaild = false
      }
    }
    return isVaild;
  }
  
  isImageFileVaild(uploadFiles: FileList){
    let isVaild= true;

    for (let i = 0; i < uploadFiles.length; i++) {
      if(uploadFiles[i].size > Constants.ATTACHMENT_SIZE){
        AppDelegate.openSnackBar(JMLanguage.translate("component.attachment.oversize"))
        isVaild = false
      }
      if(!this.imageAcceptType.includes(uploadFiles[i].type)){
        AppDelegate.openSnackBar(JMLanguage.translate("component.inspection.job.inspection.attachment.photo.type.error"))
        isVaild = false
      }
    }
    
    return isVaild;
  }

  dateTimeValidation() {
    this.errorFields['inspectionDate'] = false;
    this.errorFields['inspectionTime'] = false;

    //Check if have date & time
    if (!this.isDateValid(this.dateInputSet.inspectionDate)) {
      this.errorFields['inspectionDate'] = true;
      return false;
    }

    if (!this.isDateValid(this.dateInputSet.inspectionTime)) {
      this.errorFields['inspectionTime'] = true;
      return false;
    }
    return true;
  }

  public onBlurDateInput(event){
    this.dateInputSet[event.field] = event.data;
    this.setDateTime(event);
  }

  public resetAllErrorFields() {
    this.valid = false;
    this.errorFields = {
      inspectionDate: false,
      inspectionTime: false,
    }
  }

  initDateTimeSet(){
    let currentDate = moment(Date.now()).format("YYYY-MM-DD")
    let currentTime = moment(Date.now()).format("HH:mm")
    this.dateInputSet = {
      inspectionDate: toNgbDate(new Date(currentDate + "T00:00:00.000Z").toISOString()),
      inspectionTime: currentTime,
    };
    this.setFormattedDate();
  }

  setDateTime(event) {
    this.resetErrorField(event.field);
    
    if (!this.dateInputSet['inspectionDate'] || !this.dateInputSet['inspectionTime']) {
      this.jobInspectionInput.formattedDate = undefined;
    }

    this.validation(event.field);

    if (this.dateInputSet[event.field] && this.valid) {
      this.setFormattedDate();
    }else if(!this.valid){
      this.jobInspectionInput.formattedDate = undefined;
    }
    
    this.checkSubmitButtonStatus();
  }

  setFormattedDate(){
    const date = this.dateInputSet['inspectionDate'];
    const timeArr = this.dateInputSet['inspectionTime'].split(':');
    let formattedDate = new Date(date.year, date.month - 1, date.day);
    if (timeArr && timeArr.length > 0) {
      formattedDate.setHours(timeArr[0]);
      formattedDate.setMinutes(timeArr[1]);
      this.jobInspectionInput.formattedDate = new Date(formattedDate);
    }
  }

  resetErrorField(key) {
    if (this.errorFields[key] != undefined) {
      this.errorFields[key] = false;
    }
  }

  validation(field) {
    let hasErrorField = false;

    let dateFormatErr = this.dateTimeValidation();
    if (this.errorFields[field] || !dateFormatErr) {
      hasErrorField = true;
    }

    for (let i in this.errorFields) {
      if (this.errorFields[i] === true) {
        hasErrorField = true;
        break;
      }
    }
    this.valid = !hasErrorField;
  }

  onSubmitButtonStatusCheck = () => {
    this.checkSubmitButtonStatus()
  }

  private checkSubmitJobInspectionPermission = () => {

    const equipIndexWithoutCcsSo = this.job.equipmentList.findIndex((equip) => {
      return equip['ccsStatus'] != "pending" && !equip['ccsServiceOrderNumber'];
    });

    const isInHouseHandlingParty = (pmPlan) => {
      let parsedPmPlan = Array.isArray(pmPlan)? pmPlan : [pmPlan]
      const inHouseHandlingPartyIndex: number = parsedPmPlan.findIndex((pmPlan) => {
        return pmPlan['contract'] == null && !pmPlan['contract'];
      })
      return inHouseHandlingPartyIndex >= 0
    }

    if(!this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_INSPECTION)){
      return false;
    }

    if( !this.job ){
      return false;
    }

    if (!this.job.status || !this.isSubmitJobInspectionPanelShow.jobStatus.includes(this.job.status)) {
      return false;
    }

    if( !this.job.pmPlan || isInHouseHandlingParty(this.job.pmPlan)){
      return false;
    }
    
    if (equipIndexWithoutCcsSo != -1) return false;

    return true
  }

  private async requestRemoveAttachment(attachment, button?){
    let request: JM.JMRequestContractsDeleteInspectionReport = new JM.JMRequestContractsDeleteInspectionReport();
    request.id = attachment._id
    
    if (button) button.isLoading = true;
    const response: JM.JMResponseContractsDeleteInspectionReport = await AppDelegate.sendJMRequest(request)
    if (button) button.isLoading = false;

    if (!response || !response.code || response.code != 200) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.requestInspectionReportHistory();
    this.renderTable();
    this.setPreviewContent(undefined);
    AppDelegate.openSnackBar(JMLanguage.translate("global.removed"));
  }

  downloadLocalFile(event) {
    const {file, index} = event
    saveAs(file);
  }

  onDeleteButtonClicked = (button) => {
    if (!button || !button.attachment || !button.attachment.attachmentId) {
      AppDelegate.openErrorBar();
      return;
    }

    let buttons = [
      {
        name: (JMLanguage.translate("pages.sn.attachments.delete-the-file")),
        handler: () => {
          this.requestRemoveAttachment(button.attachment, button);
        }
      },
      { name: (JMLanguage.translate("global.no")) }
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("pages.inspection.report.delete"), "", buttons);
  }

  onImageFileDelete(event){
    const {index,imageFileInputRef} = event
    this.imageFiles.splice(index, 1);
    imageFileInputRef.nativeElement.value = null;
  }
  
  onPdfFileDelete(event){
    const {index,pdfFileInputRef} = event
    this.pdfFiles.splice(index, 1);
    pdfFileInputRef.nativeElement.value = null;
  }

  renderTable() {
    this.tablexParam.content = this.inspectionList.map(inspection => {
      inspection.attachment._id = inspection._id
      let buttons = [
        { "id": "download-button_" + inspection._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": inspection.attachment  },
      ];
      if (this.hasEditPermission) {

        buttons.push({
          "id": "delete-button_" + inspection._id,
          "name": "",
          "class": "delete-button btn p-1",
          "icon": "fas fa-times font-size-l",
          "onClicked": this.onDeleteButtonClicked,
          "attachment": inspection.attachment 
        });
      }
      return [
        inspection._id,
        inspection.attachment.filename,
        inspection.inspectionDetail != undefined && inspection.inspectionDetail.checkMode != undefined? this.getModeOfCheckWord(inspection.inspectionDetail.checkMode) : '',
        inspection.inspectionDetail != undefined && inspection.inspectionDetail.inspectedAt != undefined ? formatDateTimeWithWeek(inspection.inspectionDetail.inspectedAt) : '',
        inspection.uploadedAt ? formatDateTimeWithWeek(inspection.uploadedAt) : '',
        inspection.uploadedBy,
        buttons
      ];
    });
  }

  onDownloadButtonClicked = (button) => {
    this.requestGetFile(button.attachment.attachmentId, button.attachment.filename, true, button)
  }

  getModeOfCheckWord = (checkMode) => {
    switch (checkMode) {
      case JMENUM.InspectionMode.ON_SITE: return JMLanguage.translate('component.inspection.job.inspection.option.on.site');
      case JMENUM.InspectionMode.REMOTE: return JMLanguage.translate('component.inspection.job.inspection.option.remote');
      case JMENUM.InspectionMode.DOCUMENT_CHECK: return JMLanguage.translate('component.inspection.job.inspection.option.document.check');
      default: '';
    }
  }

  private initPermission() {
    let jobcardInspectionRemove = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_INSPECTION_REMOVE);

    if (jobcardInspectionRemove) {
      this.hasEditPermission = true;
    }
  }

  private async requestInspectionReportHistory() {
    this.tablexParam.isLoadingTable = true;
    let request: JM.JMRequestContractsGetInspectionReportList = new JM.JMRequestContractsGetInspectionReportList();
    request.pmJobNumberList = [this.jobNumber];
    
    let response: JM.JMResponseContractsGetInspectionReportList = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;

    if (!response || !response.code || response.code != 200 || !response.payload || response.error) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload) {
      if(response.payload.records.length == 0 && this.hasSubmitJobInspectionPermission){
        this.submitJobInspectionPanel.toggle();
      }
      this.updateInspectionReportHistory(response.payload.records);
      this.initPermission();
      this.renderTable();
      if(this.tablexParam.content.length > 0){
        this.onRowClicked(0);
      }
    }
  }

  onSubmitJobInspectionBtnClicked(){
    this.submitJobInspectionPanel.toggle();
    return
  }

  updateInspectionReportHistory(inspectionReportHistorys) {
    this.inspectionList = inspectionReportHistorys
    this.selectedInspection = inspectionReportHistorys[0]
  }

  private async requestPmJob() {
    this.tablexParam.isLoadingTable = true;
    let request: JM.JMRequestGetPmJob = new JM.JMRequestGetPmJob();
    request.pmJobNumber = this.jobNumber

    const response: JM.JMResponseGetPmJob = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload) {
      this.job = response.payload;
    }
  }

  setPreviewContent(file) {
    if(file){
      this.file = file;
      this.fileDetails = {
        fileName: this.selectedInspection.attachment.filename,
        fileSize: '',
        submittedBy: this.selectedInspection.uploadedBy,
        submissionDate: formatDateTimeWithWeek(this.selectedInspection.uploadedAt)
      };

      this.fileDetails.fileSize = this.attachmentPreview.getFileSize(this.file.size);
    }else{
      this.file = undefined;
      this.fileDetails = {};
    }
  }

  private async requestGetFile(attachmentId, description?, download?, button?) {
    const request = new JM.JMRequestFilesGetFile(attachmentId);

    this.isPreviewerLoading = download ? false : true;
    if (button) button.isLoading = true;
    const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request);
    if (button) button.isLoading = false;
    this.isPreviewerLoading = false;

    if (!response || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response.error);
      if (button) button.isLoading = false;
      return;
    }

    if (download) {
      let fileName = description != null && description != undefined ? description : attachmentId
      saveAs(response.payload, fileName);
      if (button) button.isLoading = false;
    } else {
      this.setPreviewContent(response.payload);
    }
  }

  onRowClicked = (index: number) => {
    this.attachmentPreview.resetPage();
    this.selectedInspection = this.inspectionList[index];
    this.requestGetFile(this.selectedInspection.attachment.attachmentId);
  }

  initTable() {
    this.tablexParam = {
      isLoadingTable: false,
      tableRow: "row",
      tableClass: "sn-attachment-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: false,
      enablePagination: false,
      onRowClicked: this.onRowClicked,
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.attachment.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'modeOfCheck',
          name: 'component.attachment.modeOfCheck',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'inspectionTime',
          name: 'component.attachment.inspectionTime',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'submissionDate',
          name: 'component.attachment.submissionDate',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'submittedBy',
          name: 'component.attachment.submittedBy',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        }
      ],
      content: []
    };

  }

  private checkPermission = () => {
    let hasPermission: boolean = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_INSPECTION);
    if (!hasPermission) {
      this.router.navigate(['/dashboard']);
      AppDelegate.openSnackBar(JMLanguage.translate('pages.job-card.create.toast-msg.permission-denied'));
    }
  }

  private checkfeatureEnabled() {
    if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JOB_CARD_INSPECTION)) {
      this.router.navigate(['']);
      return;
    }
  }

  initBreadCrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-pm-job',
        name: JMLanguage.translate('pages.pm-job.page-title.view'),
        route: '/pm/job-list',
      },
      {
        id: 'breadcrumbs-pm-job-number',
        name: this.jobNumber,
        route: `/pm/job/view/${this.jobNumber}`,
      },
      { id: 'breadcrumbs-inspect', name: JMLanguage.translate('action.button.inspect'), route: null, currentPage: true }
    ];

  }
}
