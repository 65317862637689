<div class="row justify-content-between m-0">
  <h3 class="d-inline">{{ 'pages.flms.import-files.slider-panel.title-file' | translate }}</h3>
  <div class="submit-wrap d-inline">
    <button class="standard brand-blue" (click)="onClickClose()">
      <ng-container *ngIf="!parameters.isLoading">{{ 'action.button.close' | translate }}</ng-container>
      <div *ngIf="parameters.isLoading" class="d-inline-block loading-spinner spinner-small">
        {{ "global.loading" | translate }}
      </div>
    </button>
  </div>
</div>

<div class="panel-content mt-4">

  <div id="attachment-type-wrap" class="d-flex mb-2">
    <div class="custom-control custom-radio col-6" *ngIf="hasImportLocationChiPermission">
      <input type="radio" class="custom-control-input mr-1" id="attachment-type-chinese" name="attachment-type-radio"
        value="{{attachmentType.CHINESE_LOCATION}}" [(ngModel)]="parameters.attachmentType" checked>
      <label class="custom-control-label" for="attachment-type-chinese">{{
        "pages.flms.import-files.slider-panel.type-chinese" | translate }}</label>
    </div>
    <div class="custom-control custom-radio col-6" *ngIf="hasImportLocationPermission">
      <input type="radio" class="custom-control-input mr-1" id="attachment-type-main" name="attachment-type-radio"
        value="{{attachmentType.FUNCTIONAL_LOCATION}}" [(ngModel)]="parameters.attachmentType">
      <label class="custom-control-label" for="attachment-type-main">{{ "pages.flms.import-files.slider-panel.type-main"
        | translate }}</label>
    </div>
  </div>

  <div class="dropzone d-flex align-items-center justify-content-center w-100 mb-3 font-size-xl"
    (drop)="onFilesDrop($event)" (dragover)="allowDrop($event)">
    {{ "pages.flms.import-files.slider-panel.drop-xls-here" | translate }}
  </div>
  <input #attachmentFile type="file" accept=".xls,.xlsx" name="attachmentFiles[]" id="attachmentFiles" class="inputfile"
    (change)="onClickUploadAttachment($event)" hidden />

  <button class="btn standard brand-amber p-0">
    <div *ngIf="parameters.isLoading" class="d-inline-block loading-spinner spinner-small">
      {{ "global.loading" | translate }}
    </div>
    <label for="attachmentFiles" class="mb-0 p-2" *ngIf="!parameters.isLoading">
      {{ "pages.flms.import-files.slider-panel.select-xls-button" | translate}}
    </label>
  </button>

  <div class="row mt-3">
    <a class="col-12 hyper-link mb-2" id="download-chinese-location-template-link" (click)="saveChineseTemplate()">
      {{ "pages.flms.import-files.slider-panel.chinese-location-template" | translate}}
    </a>

    <a class="col-12 hyper-link" id="download-functional-location-template-link" (click)="saveTemplate()">
      {{ "pages.flms.import-files.slider-panel.functional-location-template" | translate}}
    </a>
  </div>

  <div *ngIf="parameters.uploadResult" id="upload-result-container" class="mt-4">
    <div id="result-container-title" class="bold font-size-xl mb-3 text-underline">{{
      "pages.flms.import-files.upload-result-title" | translate }}</div>
    <div id="selected-file-count-wrap" class="mb-2">
      <span class="bold" id="selected-file-count-title" class="mr-2 bold">
        {{"pages.flms.import-files.totalCount" | translate }}
      </span>
      <span id="selected-file-count">{{ parameters.uploadResult.totalCount }}</span>
    </div>
    <div id="success-result-wrap" class="mb-2">
      <span class="bold" id="success-result-title" class="mr-2 bold green">
        {{ "pages.flms.import-files.successCount" | translate }}
      </span>
      <span id="success-result-count"
        [class.green]="parameters.uploadResult.successCount > 0">{{parameters.uploadResult.successCount }}</span>
    </div>

    <div id="failed-result-wrap">
      <span class="bold" id="failed-result-title" class="mr-2 bold red">{{ "pages.flms.import-files.pendingCount" |
        translate }}</span>
      <span id="failed-result-count" class="red">{{ parameters.uploadResult.pendingCount }}</span>
      <ul class="mt-2">
        <li *ngFor="let file of parameters.uploadResult.failedFile; let f = index;" class="red" id="failed-file-{{f}}">
          {{ file }}</li>
      </ul>
    </div>
  </div>

</div>