<nav class="navbar navbar-expand navbar-dark fixed-top theme">
  
  <div class="collapse navbar-collapse">

    <div class="navbar-nav mr-auto">
        <button type="button" id="navigation-menu_main_menu-item" class="sidebar"  (click)="$event.stopPropagation();toggleSidebar()">
            <i class="fas fa-bars"></i>
        </button>
        <!--<p class="navbar-brand title">{{ systemVersion }}</p>-->
        <div id="id_navbar_title" class="nav-title" *ngIf="navbarTitle">{{navbarTitle}}</div>
        <app-breadcrumbs [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs.length > 0 && !navbarTitle"></app-breadcrumbs> 
    </div>
    
    <ul class="navbar-nav">
      
      <li id="top-menu_dashboard_menu-item" class="nav-item ml-2 ml-md-3">
        <a class="nav-link" routerLink="/dashboard"
          routerLinkActive="active"
          (click)="resetMenu()">{{ homeLinkTitle }} <span class="sr-only">(current)</span></a>
      </li>
      
      <li id="top-menu_settings_menu-item" class="nav-item ml-2 ml-md-3">
        <a class="nav-link" routerLink="/setting" routerLinkActive="active">{{"navigation.settings" | translate}}</a>
      </li>
      
      <!-- <li id="top-menu_enquiry_menu-item" class="nav-item ml-2 ml-md-3">
        <a class="nav-link" routerLink="/enquiry" routerLinkActive="active" >{{"navigation.enquiry" | translate}} <span class="sr-only">(current)</span></a>
      </li> -->

      <li id="top-menu_settings_menu-item" class="nav-item ml-2 ml-md-3">
        <div class="language-button">
          <!-- <button class="btn" (click)="onLanguageButtonClicked('zh')" [ngClass]="{'brand-red':language =='zh','theme-red':language !='zh'}">中</button>
          <button class="btn" (click)="onLanguageButtonClicked('en')" [ngClass]="{'brand-red':language =='en','theme-red':language !='en'}">En</button> -->
          <button *ngIf="language !='zh'" class="btn" (click)="onLanguageButtonClicked('zh')">中</button>
          <button *ngIf="language !='en'" class="btn" (click)="onLanguageButtonClicked('en')">En</button>
      </div>
      </li>

      <li id="top-menu_user-id_menu-item" class="nav-item dropdown">
        <a class="nav-link clickable" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-user-circle"></i>{{user.name}}</a>
      
        <div class="dropdown-menu">
          <div *ngIf="!isSsoLogin" id="top-menu_logout_menu-item" class="dropdown-item clickable" (click)="onClickLogout()">{{"global.logout" | translate}}</div>
          <div *ngIf="isSsoLogin" id="top-menu_logout_menu-item" class="dropdown-item clickable" (click)="onClickSsoLogout()">{{ "global.logout.sso" | translate }}</div>
        </div>
      </li>
      <ng-container *ngIf="showActionSidebarIcon">
        <li class="nav-item">
          <div class="navbar-nav mr-auto">
              <button type="button" id="" class="actionSidebar"  (click)="$event.stopPropagation();toggleActionSidebar()">
                  <i class="fas fa-bars"></i>
              </button>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>