import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { ActionButtonApproval, ActionButtonDefinition, ActionButtonType } from '@enum/action-button';
import { ApprovalService } from '@services/approval.service';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { Constants } from 'src/constants';
import { JMLanguage, Language } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'approval-reject-reason-panel',
  templateUrl: './approval-reject-reason-panel.component.html',
  styleUrls: ['./approval-reject-reason-panel.component.scss']
})
export class ApprovalRejectReasonPanelComponent implements OnInit {
  @Input() approvalList: JMOBJ.Approval[];
  @Input() showDefaultResultMsg = true;

  @Output() onSubmit = new EventEmitter<number>();

  lang: Language;
  isLoading: boolean = false;
  submitEnable: boolean = true;
  selectedPmPlan: string[] = [];
  selectedPmPlanHeader: string = '';

  rejectReasonOptions;
  rejectReasonCode: number;
  rejectReasonRemarks: string;

  ApprovalType = JMENUM.ApprovalType;
  ApprovalTypeShowingSelectedPlan = [JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT, JMENUM.ApprovalType.PMPLAN_SUBMIT];

  constructor(private approvalService: ApprovalService) { }

  ngOnInit() {
    this.lang = Session.selectedLanguage;
    this.rejectReasonOptions = JMREASON.PM_PLAN_REJECT_APPROVAL_REASON_LIST.list.map((reason) => {
      return {
        label: this.lang == Language.ZH ? reason.description.zh : reason.description.en,
        value: reason.code
      }
    });
  }

  ngOnChanges() {
    // for pm plan submission or pm plan equipment change request,
    // show the distinct pm plan(s) selected on reject panel
    if (this.approvalList.every(a => this.ApprovalTypeShowingSelectedPlan.includes(a.type))) {
      this.selectedPmPlan = Array.from(new Set(this.approvalList.map(e => {
        if (e.type == JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT)
          return e.customField.pmPeriodEquipmentEdit.targetPmPeriod.pmPlanNumber;
        else if (e.type == JMENUM.ApprovalType.PMPLAN_SUBMIT)
          return e.customField.pmPlanSubmit.pmPlanNumber;
      })));
      this.selectedPmPlanHeader = JMLanguage.translate("pages.approval.reject.selected-pm-plan", [this.selectedPmPlan.length]);
    }
    
    this.reset();
  }

  reset() {
    this.rejectReasonCode = undefined;
    this.rejectReasonRemarks = undefined;
    this.validateForm();
  }

  validateForm() {
    if (this.approvalList.length > 0 && this.approvalList[0].type == JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT) {
      this.submitEnable = this.rejectReasonRemarks ? true : false ;
    } else {
      this.submitEnable = (this.rejectReasonCode && this.rejectReasonRemarks) ? true : false ;
    }
  }

  onClickSubmit() {
    const rejectActionButton = ActionButtonDefinition[ActionButtonType.approval][ActionButtonApproval.rejectWithReason];
    const buttons = [
      {
        name: JMLanguage.translate('global.yes'),
        handler: () => {
          this.requestRejectRequest();
        },
      },
      {
        name: JMLanguage.translate('global.no'),
        handler: null,
      },
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate(rejectActionButton.popupTitle), "", buttons);
  }

  async requestRejectRequest() {
    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = this.approvalList.map(approval => {
      return {
        approvalAction: JMENUM.ApprovalAction.REJECT,
        approvalId: approval._id,
        customParameters: {
          // conditionally add rejectCode property only when rejecting pm plan or pm job creation
          ...((approval.type == JMENUM.ApprovalType.PMPLAN_SUBMIT || approval.type == JMENUM.ApprovalType.PMJOB_SUBMIT) && {rejectCode: this.rejectReasonCode}),
          rejectReason: this.rejectReasonRemarks
        },
      }
    });

    this.isLoading = true;
    const records = await this.approvalService.requestBatchProcessRequest(request);
    this.isLoading = false;

    if (!records.length) { return; }

    const successCount = records.filter(r => r.success).length;
    const failCount = records.filter(r => !r.success).length;

    if (this.showDefaultResultMsg) {
      AppDelegate.openSnackBar(JMLanguage.translate('global.batch-process-request.result', [successCount, failCount]));
    }
    this.onSubmit.emit(successCount);
    this.reset();
  }

  get getMaxRemarksLength(): number {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
