import { Component, OnChanges, OnInit, Input, SimpleChanges, ViewChild } from '@angular/core';
import { JMOBJ, JMENUM } from '@ccep/CCEPConnector-ts';

import { JobcardJobDetailsComponent } from 'src/app/ui/components/jobcard-job-details/jobcard-job-details.component';
import { JobcardTaskProgressComponent } from 'src/app/ui/components/jobcard-task-progress/jobcard-task-progress.component';

@Component({
  selector: 'app-job-card-standalone-edit',
  templateUrl: './job-card-standalone-edit.component.html',
  styleUrls: ['./job-card-standalone-edit.component.scss']
})
export class JobCardStandaloneEditComponent implements OnChanges, OnInit {
  @Input() jobCard: JMOBJ.JobCard;
  @Input() componentParameters;

  @ViewChild(JobcardJobDetailsComponent, { static: false }) jobDetailElem:JobcardJobDetailsComponent;
  @ViewChild(JobcardTaskProgressComponent, { static: false }) jobTaskProgressElem: JobcardTaskProgressComponent;

  JMPage = JMENUM.JMPage;
  pageMode = JMENUM.JMPageMode;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  validation(): boolean {
    return this.jobDetailElem.validation() && this.jobTaskProgressElem.validation();
  }

  updateJobCardData( data : { [key: string] : any } ) {
    for (let key in data) {
      this.jobCard[key] = data[key];
    }
  }
}
