import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-batch-complete-validation-result',
  templateUrl: './batch-complete-validation-result.component.html',
  styleUrls: ['./batch-complete-validation-result.component.scss']
})
export class BatchCompleteValidationResultComponent implements OnInit {

  @Input() successCount: number = 0;
  @Input() failedCount: number = 0;

  constructor() { }

  ngOnInit() {
  }

}
