<div>

    <div class="title-wrap mx-0 mb-4">
        <h3 class="d-inline" *ngIf="mode=='new'">{{ "add-tag-form.title.add" | translate }}</h3>
        <h3 class="d-inline" *ngIf="mode=='edit'">{{ "add-tag-form.title.edit" | translate }}</h3>
        <div class="button-wrap text-right d-inline-block float-right">
            <app-submit-button [id]="'save_button'" [text]="'global.save'" [buttonClass]="' standard brand-amber mr-0'" [iconClass]="'fa-save'" [isDisabled]="!uiEnableSaveButton" [isLoading]="uiLoadingSaveButton" (onClick)="onSaveButtonClicked()"></app-submit-button>
            <ng-container *ngIf="mode=='edit'">
                <app-submit-button [id]="'delete_button'" [text]="'global.delete'" [buttonClass]="'d-block standard brand-red mr-0 my-1'" [iconClass]="'fa-times'" [isDisabled]="!uiEnableDeleteButton" [isLoading]="uiLoadingDeleteButton" (onClick)="onDeleteButtonClicked()"></app-submit-button>
            </ng-container>
        </div>
    </div>


    <div class="mt-3">
        <span class="mandatory">*</span>{{ 'add-tag-form.row.title.tag-group' | translate }}
    </div>
    <span class="alert-font font-size-xs" *ngIf="uiShowError && !uiTagGroup">
        {{ 'add-tag-form.error.null-tag-group' | translate }}
    </span>
    <ng-select id="id_tag_Group" name="tagGroup" class="align-middle" bindLabel="label" bindValue="value" [(ngModel)]="uiTagGroup" [items]="uiTagGroupArray" [multiple]="false" [closeOnSelect]="true" [clearable]="false" [searchable]="true" [disabled]="false"
        [class.input-error]="uiShowError && !uiTagGroup">
    </ng-select>


    <div class="mt-3">
        <span class="mandatory">*</span>{{ 'add-tag-form.row.title.name-zh' | translate }}
    </div>
    <span class="alert-font font-size-xs" *ngIf="uiShowError && uiTagNameZh.length==0">
        {{ 'add-tag-form.error.null-name-zh' | translate }}
    </span>
    <input id="id_tag_name_zh" name="uiTagNameZh" type="text" class="form-control" [(ngModel)]="uiTagNameZh" [class.input-error]="uiShowError && uiTagNameZh.length == 0">

    <div class="mt-3">
        <span class="mandatory">*</span>{{ 'add-tag-form.row.title.name-en' | translate }}
    </div>
    <span class="alert-font font-size-xs" *ngIf="uiShowError && uiTagNameEn.length==0">
        {{ 'add-tag-form.error.null-name-en' | translate }}
    </span>
    <input id="id_tag_name_en" name="uiTagNameEn" type="text" class="form-control" [(ngModel)]="uiTagNameEn" [class.input-error]="uiShowError && uiTagNameEn.length == 0">

    <ng-container *ngIf="tagType=='ccs'">
        <div class="mt-3">
            {{ 'add-tag-form.row.title.equipment-category' | translate }}
        </div>
        <ng-select id="id_equipment_category" name="equipmentCategory" class="align-middle" bindLabel="label" bindValue="value" [(ngModel)]="uiTagEquipmentCategory" [items]="uiTagEquipmentCategoryArray" [multiple]="false" [closeOnSelect]="true" [searchable]="true"
            [disabled]="mode=='edit' && tag.equipmentCategory" (change)="onEquipmentCategoryChanged($event)">
        </ng-select>

        <div class="mt-3">
            {{ 'add-tag-form.row.title.equipment-type' | translate }}
        </div>
        <ng-select id="id_equipment_type" name="equipmentType" class="align-middle" bindLabel="label" bindValue="value" [(ngModel)]="uiTagEquipmentType" [items]="uiTagEquipmentTypeArray" [multiple]="false" [closeOnSelect]="true" [searchable]="true"
            [disabled]="mode=='edit' && tag.equipmentType" (change)="onEquipmentTypeChanged($event)">
        </ng-select>
    </ng-container>

    <div class="mt-3">
        {{ 'add-tag-form.row.title.default-priority' | translate }}
    </div>
    <ng-select id="id_default_priority" name="defaultPriority" class="align-middle" bindLabel="label" bindValue="value" [(ngModel)]="uiDefaultPriority" [items]="uiAllDefaultPriorityArray" [multiple]="false" [closeOnSelect]="true" [searchable]="true"></ng-select>

    <div class="mt-3">
        {{ 'add-tag-form.row.title.default-type' | translate }}
    </div>
    <ng-select id="id_default_type" name="defaultType" class="align-middle" bindLabel="label" bindValue="value" [(ngModel)]="uiDefaultType" [items]="uiAllDefaultTypeArray" [multiple]="false" [closeOnSelect]="true" [searchable]="true"
        (change)="onDefaultTypeChanged($event)"></ng-select>

    <div class="mt-3">
        {{ 'add-tag-form.row.title.default-mat' | translate }}
    </div>
    <ng-select id="id_default_mat" name="defaultMAT" class="align-middle" bindLabel="label" bindValue="value" [(ngModel)]="uiDefaultMAT" [items]="uiAllDefaultMATArray" [multiple]="false" [closeOnSelect]="true" [searchable]="true"></ng-select>

    <div class="mt-3">
        {{ 'add-tag-form.row.title.additional-dropdown' | translate }}
    </div>
    <ng-select id="id_additional_dropdown" name="additionalDropdown" class="align-middle" bindLabel="label" bindValue="value" [(ngModel)]="uiSelectedDropDownArray" [items]="uiDropdownArray" [multiple]="true" [searchable]="true" (scrollToEnd)="onDropdownScrollToEnd()"
        (open)="onOpenDropdown()"></ng-select>

    <p class="remarks mt-5">
        <span class="mandatory">*</span>{{ 'pages.contract-dummy.mandatory' | translate}}
    </p>
</div>