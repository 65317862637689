import { Component, Input, Output, OnInit, EventEmitter, Injector } from '@angular/core';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '@services/authorization.service';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'contract-ccs-form-edit',
  templateUrl: './contract-ccs-form-edit.component.html',
  styleUrls: ['./contract-ccs-form-edit.component.scss']
})
export class ContractCcsFormEditComponent implements OnInit {
  @Input() parameters: {
    allContractArray: JMOBJ.MaintenanceTermContract[],
    onSaveButtonClicked: Function,
  };
  // permissions
  hasContractUpdatePermission: boolean = false;
  private translateService: TranslateService;

  constructor(injector: Injector, private authorizationService: AuthorizationService) {
    this.translateService = injector.get(TranslateService);
  }

  uiEnableSaveButton: boolean = undefined;
  uiEnableDeleteButton: boolean = undefined;
  uiEnableContractorNumber: boolean = undefined;
  uiEnableContractorName: boolean = undefined;
  uiLoadingSaveButton: boolean = undefined;
  uiLoadingDeleteButton: boolean = undefined;
  uiLiftTrapped: boolean = undefined;

  uiContractNumber: string = undefined;
  uiContractorNumber: string = undefined;
  uiContractorName: string = undefined;
  uiContractType: string = undefined;
  uiFindEquipmentBy: string = undefined;

  uiAllContractorNumberArray: string[] = undefined;
  uiAllContractorNameArray: string[] = undefined;
  uiAllFindEquipmentByOptions: any[] = [
    {
      name: JMLanguage.translate("pages.contract-dummy.dummy-find-equipment-by.option-1"),
      id: JMENUM.EquipmentCriteria.WORK_CENTRE,
    },
    {
      name: JMLanguage.translate("pages.contract-dummy.dummy-find-equipment-by.option-2"),
      id: JMENUM.EquipmentCriteria.ROUTING_RULE,
    }
  ];
  uiAllContractTypeArray = [
    {
      name: 'PMSMC',
      id: 'PMSMC',
    },
    {
      name: 'Non-PMSMC',
      id: 'NONPMSMC',
    }
  ];
  uiErrorMessage: string[] = undefined;
  uiHaveTeamLink: boolean = undefined;
  uiHaveSwitchOver: boolean = undefined;


  // data
  // contractorDict: {[key:string]: string} = undefined; // key: vendorNumber, obj: vendorName


  ngOnInit() {
    this.hasContractUpdatePermission = this.authorizationService.hasPermission(JMENUM.Permission.CONTRACT_UPDATE);
    this.clearView();
  }

  //--------------------------------------------------
  enableSaveButton(enable: boolean): void {
    this.uiEnableSaveButton = enable;
  }

  // setContractorDict(dict:{}) {
  //   this.contractorDict = dict;
  //   this.uiAllContractorNumberArray = Object.keys(this.contractorDict);
  //   this.uiAllContractorNameArray = Object.values(this.contractorDict);
  // }

  clearView() {
    this.uiEnableSaveButton = true;
    this.uiEnableDeleteButton = false;
    this.uiEnableContractorNumber = false;
    this.uiEnableContractorName = false;
    this.uiLoadingSaveButton = false;
    this.uiLoadingDeleteButton = false;
    this.uiContractNumber = '';
    this.uiContractorNumber = '';
    this.uiContractorName = '';
    // this.uiAllContractorNumberArray = [];
    // this.uiAllContractorNameArray = [];
    this.uiErrorMessage = [];
    // this.uiHaveTeamLink = false;
    // this.uiHaveSwitchOver = false;
    // this.contractorDict = {};
  }

  // setDeletable(deletable:boolean) {
  //   this.uiEnableDeleteButton = deletable;
  //   this.uiEnableContractorNumber = deletable;
  //   this.uiEnableContractorName = deletable;
  // }

  // setHaveTeam(haveTeam:boolean) {
  //   this.uiHaveTeamLink = haveTeam;
  //   this.setDeletable(!(haveTeam || this.uiHaveSwitchOver));
  // }

  // setHaveSwitchOver(haveSwitchOver:boolean) {
  //   this.uiHaveSwitchOver = haveSwitchOver;
  //   this.setDeletable(!(haveSwitchOver || this.uiHaveTeamLink));
  // }

  setLoadingDelete(deleting) {
    this.uiEnableDeleteButton = !deleting;
    this.uiLoadingDeleteButton = deleting;
  }

  setLoadingSave(saving) {
    this.uiEnableSaveButton = !saving;
    this.uiLoadingSaveButton = saving;
  }

  validateNumber(event: KeyboardEvent): boolean {
    const isAvailableKey = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.code) ? true
      : !isNaN(Number(event.key)) && event.code !== 'Space';
    return isAvailableKey;
  }

  validateNumberWhenKepUp(event: any) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
  }

  //--------------------------------------------------
  // button callback functions
  onContractNumberKeyup(event) {
    if (event.target && event.target.value) {
      if (event.target.value.match(/\s/g)) {
        event.target.value = event.target.value.replace(/\s/g, '')
      }
    }
  }

  onContractTypeChanged(event) {
  }

  onSaveButtonClicked(event) {
    // validate
    this.uiErrorMessage = [];
    // if (this.uiContractNumber == undefined || this.uiContractNumber == null || this.uiContractNumber.length == 0) {
    //   this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.transitional-contract-number-cannot-empty"));
    // }
    // if (this.uiContractorNumber == undefined || this.uiContractorNumber == null || this.uiContractorNumber.length == 0) {
    //   this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.contractor-number-cannot-empty"));
    // }
    // if (this.uiContractorName == undefined || this.uiContractorName == null || this.uiContractorName.length == 0) {
    //   this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.contractor-name-cannot-empty"));
    // }
    if (this.uiErrorMessage.length > 0) {
      return;
    }


    // tell parent
    if (this.parameters.onSaveButtonClicked) {
      this.parameters.onSaveButtonClicked(this);
    }
  }
}
