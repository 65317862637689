import { formatDate } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '@services/authorization.service';
import { Column } from '@services/excel/column';
import { ExcelService } from '@services/excel/excel.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InlineStyleHelper } from 'src/app/ui/components/inline-style-helper';
import { TableHelper } from 'src/app/ui/components/table/table-helper';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})


export class TableComponent implements OnInit {
  @Input() dataSource: any; // total, contents, observable
  @Input() columns: any[];
  @Input() enableSorting: boolean; // enableSorting
  @Input() readOnly: boolean; //TODO: remove

  @Input() paginationPosition: string;// none,top-left,bottom-left,top-right,bottom-right
  @Input() pageSize: number = 10;
  @Input() enablePageResize: boolean;

  @Input() filterPosition: string; // null or none or undefined or empty string, inHeader, aboveHeader

  @Input() enableExportExcel: boolean;
  @Input() enableClickRow: boolean;

  @Output() onPageChanged = new EventEmitter<number>();
  @Output() onFilterInputChanged = new EventEmitter<any>();
  @Output() onSorted = new EventEmitter<any>();
  @Output() onPageResized = new EventEmitter<number>();
  @Output() onRowClicked = new EventEmitter<any>();

  tableContent: any[];
  displayedColumns: string[];
  displayedFilterColumns: string[];
  filterColumns: any[]; // key or value

  totalRecordCount: number; // totalRecordCount
  maximumPageCount: number; // maximumPageCount
  currentPageIndex: number = 1;
  isPaginationLeftSide: boolean = false;
  isPaginationAtBottom: boolean = false;
  isPaginationVisable: boolean;
  pageSizeOptions: number[] = [10, 25, 100];

  private authorizationService: AuthorizationService;
  private excelService: ExcelService;
  private translateService: TranslateService;
  private searchTerms = new Subject<any[]>();

  inlineStyles: HTMLStyleElement[] = []; // inlineStyles  

  excelDataObservable: Observable<any>;
  useCase1 = true;

  constructor(private injector: Injector) {
    this.excelService = injector.get(ExcelService);
    this.translateService = injector.get(TranslateService);
  }

  ngOnInit() {
    this.initTable();
    this.initPagination();
    this.initFilterColumns();

  }

  ngOnChanges(change:SimpleChanges) {
    this.initTable();
  }

  ngOnDestroy() {
    for (let inlineStyle of this.inlineStyles) {
      InlineStyleHelper.removeStyle(inlineStyle.id);
    }
  }

  initPagination() {
    this.isPaginationVisable = this.paginationPosition != "none";
    if (!this.paginationPosition || !this.isPaginationVisable) return;

    let positionArray = this.paginationPosition.split("-");

    if (positionArray.length < 2) {
      console.error("invalid paginationPosition value");
    } else {
      this.isPaginationAtBottom = positionArray[0] == "bottom";
      this.isPaginationLeftSide = positionArray[1] == "left";
    }
  }

  initTable() {
    if (!this.dataSource) return;
    if (!this.pageSize) this.pageSize = 10;

    this.displayedColumns = this.columns.map(column => column.key);

    this.tableContent = this.dataSource.content;
    this.totalRecordCount = this.dataSource.totalRecordCount;
    this.excelDataObservable = this.dataSource.excelDataObservable;

    if (this.tableContent.length > this.pageSize) {
      this.tableContent = this.tableContent.slice(0, this.pageSize);
    }

    this.inlineStyles = TableHelper.createTableColumnWidthStyles(this.columns);
    this.inlineStyles.forEach(c => InlineStyleHelper.addStyle(c));

    if (this.totalRecordCount <= this.pageSize) {
      this.maximumPageCount = 1;
    } else {
      let roundedNumber = Math.floor(this.totalRecordCount / this.pageSize)
      this.maximumPageCount = (this.totalRecordCount / this.pageSize) - roundedNumber == 0 ? roundedNumber : roundedNumber + 1;
    }

  }

  initFilterColumns() {

    if (this.filterPosition) {
      this.filterColumns = this.columns.filter(column => column.type != "button").map(column => {
        let filterObject = { key: (column.key + "-filter"), value: "" };
        return filterObject;

      });
      this.displayedFilterColumns = this.filterColumns.map(column => column.key);

      this.searchTerms.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
      ).subscribe((terms) => {
        this.currentPageIndex = 1;
        this.onFilterInputChanged.emit(terms);
      });
    }
  }



  handlingFilterInput() {
    let filterInputs = this.filterColumns.map(column => column.value);
    this.searchTerms.next(filterInputs);

  }

  onPageNumberClicked(pageIndex: number) {
    this.dataSource.selectedRowIndex = -1;
    this.onPageChanged.emit(pageIndex);
  }

  onButtonClicked(button: any) {
    let handler = button.handler;
    if (handler) {
      handler(button.value);
    }
  }

  onSortingButtonClicked(column: any) {
    this.dataSource.selectedRowIndex = -1;
    let currentColumn = column;
    switch (column.order) {
      case 0:
      case -1:
        column.order = 1;
        break;
      case 1:
        column.order = -1;
        break;
      default:
        break;
    }
    this.columns.filter(column => column.key != currentColumn.key).forEach(column => {
      column.order = 0;
    })
    this.onSorted.emit({ sortBy: column.key, sortOrder: column.order });
  }

  onPageSizeClicked(pageSize: number) {
    this.dataSource.selectedRowIndex = -1;
    this.pageSize = pageSize;
    this.currentPageIndex = 1;
    this.onPageResized.emit(pageSize);
  }

  onTableRowClicked(row: any, rowIndex: number) {
    if (this.enableClickRow) {
      this.dataSource.selectedRowIndex = rowIndex;
      this.onRowClicked.emit(row);
    }

  }

  checkValidation(inputObject: any) {
    let value = inputObject.value;
    let errorMessage = "";
    for (let validator of inputObject.validators) {
      errorMessage += validator(value);
    }

    if (errorMessage.length > 0 && inputObject.invalidHandler) {
      inputObject.invalidHandler(errorMessage);
    }
  }

  exportExcel(): void {
    let headers: Column[] = this.columns
      .filter(column => this.authorizationService.hasPermission(column.permission) && column.type == "text")
      .map(column => new Column(column.key, this.translateService.instant(column.description), column.columnWidth));

    if (!this.excelDataObservable) {
      //case1
      let data = this.cloneAndFormat(this.tableContent);
      this.excelService.exportAsExcelFile(data, this.dataSource.tableName, headers);
    } else {
      //case2
      this.excelDataObservable.subscribe(excelData => {
        this.excelService.saveAsExcelFile(excelData, this.dataSource.tableName);
      })
    }



  }

  cloneAndFormat(rows: any[]) {
    return rows.map(function (row) {
      let clone = {};
      for (let key in row) {
        switch (key) {
          case 'createdAt':
          case 'updatedAt':
            clone[key] = formatDate(row[key], 'MMM d, yyyy h:mm aa', 'en-US');
            break;
          default:
            clone[key] = row[key];
        }
      }
      return clone;
    });
  }



}
