import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Constants } from 'src/constants';

@Injectable()
export class DatepickerFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = moment(value);
            return {
                day: parseInt(date.day.toString()),
                month: parseInt(date.month.toString()),
                year: parseInt(date.year.toString())
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        if(date)
        {
            return moment(date.year + '-' + date.month + '-' + date.day).format(Constants.DATE_FORMAT_2);
        }
        return null;
    }
}