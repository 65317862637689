import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionButtonDefinition, ActionButtonPmPlan, ActionButtonType } from '@enum/action-button';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/AppDelegate';
import { BasePage } from '../../model/base/base';
import { JM, JMCONSTANT, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session } from '@services/session';

@Component({
  selector: 'app-pm-plan-edit',
  templateUrl: './pm-plan-edit.component.html',
  styleUrls: ['./pm-plan-edit.component.scss']
})
export class PmPlanEditComponent extends BasePage implements OnInit {
  @ViewChild("pmPlanSummaryElem", { static: false }) pmPlanSummaryElem;
  @ViewChild("pmPlanParticularsElem", { static: false }) pmPlanParticularsElem;
  @ViewChild("pmPlanRemindersElem", { static: false }) pmPlanRemindersElem;
  @ViewChild("pmPlanPeriodsElem", { static: false }) pmPlanPeriodsElem;
  @ViewChild("pmPlanRemarksElem", { static: false }) pmPlanRemarksElem;

  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.CREATE;

  pmPlanNumber = undefined;
  plan: JMOBJ.PmPlan = new JMOBJ.PmPlan;
  // periods: JMOBJ.PmPeriod = new JMOBJ.PmPeriod;
  navbarTitle: string;
  breadcrumbs: any = [];

  // action buttons
  actionButtonData = [];
  uiIsLoading: boolean = false;
  disableActionSideBar: boolean = false;
  lastClickedActionButton: ActionButtonPmPlan;

  hasPMReminderFeatureToggle = false;

  reminderDefaultValueDict = {
    normal: {
      weekly: {
        toBeDueDay: 6, interval: 3, overdueInterval: 2
      },
      bi_weekly: {
        toBeDueDay: 6, interval: 3, overdueInterval: 2
      },
      monthly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 7
      },
      bi_monthly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 14
      },
      quarterly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 21
      },
      tetra_monthly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 28
      },
      half_yearly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 90
      },
      yearly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 90
      },
      bi_yearly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 180
      },
    },
    once: {
      toBeDueDay: 6, interval: 3, overdueInterval: 2
    },
    overhaul: {
      weekly: {
        toBeDueDay: 6, interval: 3, overdueInterval: 2
      },
      bi_weekly: {
        toBeDueDay: 6, interval: 3, overdueInterval: 2
      },
      monthly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 7
      },
      bi_monthly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 14
      },
      quarterly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 21
      },
      tetra_monthly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 28
      },
      half_yearly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 90
      },
      yearly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 90
      },
      bi_yearly: {
        toBeDueDay: 14, interval: 7, overdueInterval: 180
      },
    },
  }

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    // JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.PMPLAN_CREATE], false);
    let paraMode = this.route.snapshot.paramMap.get('mode');

    switch (paraMode) {
      case "edit":
        this.pageMode = JMENUM.JMPageMode.EDIT;
        break;
      case "create":
        this.pageMode = JMENUM.JMPageMode.CREATE;
        break; 
    }
    this.pmPlanNumber = this.route.snapshot.paramMap.get('pmNumber');
    if (this.pageMode === JMENUM.JMPageMode.EDIT && this.pmPlanNumber) {
      this.requestPmPlan();
    }
    this.initActionButtons();
    this.buttonHandling();

    this.initBreadcrumbs();

    const pmReminderFeature = Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.JM_PM_PLAN_REMINDER);
    if(pmReminderFeature) {
      this.hasPMReminderFeatureToggle = pmReminderFeature.enabled;
    }
  }

  initBreadcrumbs() {
    if (this.pageMode === JMENUM.JMPageMode.CREATE) {
      this.navbarTitle = JMLanguage.translate('pages.pm-plan-create.page-title');
    } else {
      this.breadcrumbs = [
        {
          id: 'breadcrumbs-pm-plan',
          name: JMLanguage.translate('pages.pm-plan.page-title'),
          route: '/pm/plan-list',
        },
        {
          id: 'breadcrumbs-pm-plan-number',
          name: this.pmPlanNumber,
          route: `/pm/standard-plan/view/${this.pmPlanNumber}`,
        },
        {
          id: 'breadcrumbs-pm-plan-edit',
          name: JMLanguage.translate('action.button.edit'),
          route: null,
          currentPage: true,
        }
      ];
    }
  }

  // ----------- API ----------- //
  private async requestSavePmPlan() {
    let request: JM.JMRequestPmPlanDraft = new JM.JMRequestPmPlanDraft();
    request.pmPlanNumber = this.plan.pmPlanNumber;
    request.workCentre = this.plan.workCentre;
    request.agreementNumber = this.plan.agreementNumber;
    request.periods = this.plan['periods'] ? this.plan['periods'] : undefined;
    request.teamId = this.plan.teamId;
    request.frequency = this.plan.frequency;
    request.planCoverage = this.plan.planCoverage;
    request.scheduleType = this.plan.scheduleType;
    request.startDate = this.plan.startDate;
    request.secondStartDate = (this.plan.scheduleType == JMENUM.PMScheduleType.OVERHAUL || this.plan.scheduleType == JMENUM.PMScheduleType.NORMAL) && this.plan.secondStartDate ? this.plan.secondStartDate : undefined;
    request.endDate = this.plan.endDate;
    request.planDescription = this.plan.planDescription
    request.reminderRecipients = this.plan.reminderRecipients ? this.plan.reminderRecipients : undefined;
    request.remarks = this.plan.remarks ? this.plan.remarks : undefined;
    request.toBeOverdueReminder = this.plan.toBeOverdueReminder ? this.plan.toBeOverdueReminder : undefined;
    request.overduePeriodReminder = this.plan.overduePeriodReminder ? this.plan.overduePeriodReminder : undefined;
    request.version = this.plan.version;
    // request.attachments

    const response: JM.JMResponsePmPlanDraft = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.saved"));
    this.redirectAfterSave(response.payload.pmPlanNumber);
  }

  private async requestSubmitPmPlan() {
    let request: JM.JMRequestPmPlanSubmit = new JM.JMRequestPmPlanSubmit();
    request.pmPlanNumber = this.plan.pmPlanNumber;
    request.workCentre = this.plan.workCentre;
    request.agreementNumber = this.plan.agreementNumber;
    request.periods = this.plan['periods'] ? this.plan['periods'] : undefined;
    request.teamId = this.plan.teamId;
    request.frequency = this.plan.frequency;
    request.planCoverage = this.plan.planCoverage;
    request.scheduleType = this.plan.scheduleType;
    request.startDate = this.plan.startDate;
    request.secondStartDate = (this.plan.scheduleType == JMENUM.PMScheduleType.OVERHAUL || this.plan.scheduleType == JMENUM.PMScheduleType.NORMAL) && this.plan.secondStartDate ? this.plan.secondStartDate : undefined;
    request.endDate = this.plan.endDate;
    request.planDescription = this.plan.planDescription
    request.reminderRecipients = this.plan.reminderRecipients ? this.plan.reminderRecipients : undefined;
    request.remarks = this.plan.remarks ? this.plan.remarks : undefined;
    request.toBeOverdueReminder = this.plan.toBeOverdueReminder ? this.plan.toBeOverdueReminder : undefined;
    request.overduePeriodReminder = this.plan.overduePeriodReminder ? this.plan.overduePeriodReminder : undefined;
    request.version = this.plan.version;
    // request.attachments

    const response: JM.JMResponsePmPlanSubmit = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.submitted"));
    this.router.navigate(['/pm/standard-plan/view/' + response.payload.pmPlanNumber]);
  }

  private async requestUpdatePmPlan() {
    let request: JM.JMRequestPmPlanUpdate = new JM.JMRequestPmPlanUpdate();
    request.pmPlanNumber = this.plan.pmPlanNumber;
    request.planDescription = this.plan.planDescription;
    request.reminderRecipients = this.plan.reminderRecipients ? this.plan.reminderRecipients : undefined;
    request.remarks = this.plan.remarks ? this.plan.remarks : undefined;
    request.toBeOverdueReminder = this.plan.toBeOverdueReminder ? this.plan.toBeOverdueReminder : undefined;
    request.overduePeriodReminder = this.plan.overduePeriodReminder ? this.plan.overduePeriodReminder : undefined;
    request.version = this.plan.version;

    const response: JM.JMResponsePmPlanUpdate = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.saved"));
    this.redirectAfterSave(response.payload.pmPlanNumber);
  }

  private async requestPmPlan() {
    if (!this.pmPlanNumber) return;

    let request: JM.JMRequestGetPmPlan = new JM.JMRequestGetPmPlan();
    request.pmPlanNumber = this.pmPlanNumber;

    const response: JM.JMResponseGetPmPlan = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.plan = response.payload;
    this.initUpdatePermission();
  }

  redirectAfterSave(pmPlanNumber) {
    if (this.lastClickedActionButton == ActionButtonPmPlan.equipment) {
      this.router.navigate(['/pm/standard-plan/equipment-list/' + pmPlanNumber]);
    } else if (this.lastClickedActionButton == ActionButtonPmPlan.location) {
      this.router.navigate(['/pm/standard-plan/equipment-list/' + pmPlanNumber]);
    } else {
      this.router.navigate(['/pm/standard-plan/view/' + pmPlanNumber]);
    }
    this.lastClickedActionButton = undefined;
  }

  // ----------- UI function ----------- //
  initUpdatePermission() {
    this.initActionButtons();
    this.buttonHandling();
  }

  onRefreshPeriod() {
    let validateSecondStartDate = true;
    if (this.plan.secondStartDate) {
      validateSecondStartDate = this.pmPlanSummaryElem.validateSecondStartDate();
    }
    if (this.pmPlanSummaryElem.validateStartEndDate() && validateSecondStartDate) {
      this.pmPlanPeriodsElem.requestPmPeriodCalculate();
    }
  }

  triggerSaveButtonAction() {
    if (this.pmPlanSummaryElem.validate() && this.pmPlanParticularsElem.validate() && (this.hasPMReminderFeatureToggle ? this.pmPlanRemindersElem.validate() : true)) {
      if (this.plan.status == JMENUM.PMStatus.IN_PROGRESS || this.plan.status == JMENUM.PMStatus.OUTSTANDING) {
        this.requestUpdatePmPlan();
      } else {
        this.requestSavePmPlan();
      }
    }
  }

  initActionButtons(): void {
    this.actionButtonData = [];
    this.addActionBtn(ActionButtonPmPlan.save);
    this.addActionBtn(ActionButtonPmPlan.clear);
    this.addActionBtn(ActionButtonPmPlan.submit);
    this.addActionBtn(ActionButtonPmPlan.equipment);
    // this.addActionBtn(ActionButtonPmPlan.location);
    this.addActionBtn(ActionButtonPmPlan.close);
  }

  addActionBtn(buttonStatus: ActionButtonPmPlan): void {
    let actionButton = { ...ActionButtonDefinition[ActionButtonType.pmPlan][buttonStatus] };
    let buttonHandler = () => { };

    if (actionButton['permission'] && !JMUTILITY.hasPermission(Session.userInfo, actionButton['permission'])) return;

    switch (buttonStatus) {
      case ActionButtonPmPlan.save:
        buttonHandler = () => {
          this.lastClickedActionButton = ActionButtonPmPlan.save;
          this.triggerSaveButtonAction();
        }
        break;
      case ActionButtonPmPlan.clear:
        buttonHandler = () => {
          this.pmPlanSummaryElem.clearAll();
          this.pmPlanParticularsElem.clearAll();
          this.pmPlanPeriodsElem.clearAll();
          this.pmPlanRemarksElem.clearAll();
        };
        break;
      case ActionButtonPmPlan.submit:
        buttonHandler = () => {
          /* TODO:
            If Plan Coverage is “Equipment base”, at least one equipment is selected. 
            If Plan Coverage is “Location base” or “FS251”, at least one Location is selected.
          */
          if (this.pmPlanSummaryElem.validate() && this.pmPlanParticularsElem.validate() && (this.hasPMReminderFeatureToggle ? this.pmPlanRemindersElem.validate() : true)) {
            this.requestSubmitPmPlan();
          }
        };
        break;
      case ActionButtonPmPlan.equipment:
        buttonHandler = () => {
          this.lastClickedActionButton = ActionButtonPmPlan.equipment;
          this.triggerSaveButtonAction();
        };
        break;
      case ActionButtonPmPlan.location:
        buttonHandler = () => {
          this.lastClickedActionButton = ActionButtonPmPlan.location;
          this.triggerSaveButtonAction();
        };
        break;
      case ActionButtonPmPlan.close:
        buttonHandler = () => {
          if (this.pmPlanNumber)
            this.router.navigate(['/pm/standard-plan/view/' + this.pmPlanNumber]);
          else
            this.router.navigate(['/dashboard']);
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: JMLanguage.translate(
          (actionButton.buttons && actionButton.buttons.length >= 1) ?
            actionButton.buttons[0].name : "global.yes"
        ),
        handler: buttonHandler
      },
      {
        name: JMLanguage.translate(
          (actionButton.buttons && actionButton.buttons.length >= 2) ?
            actionButton.buttons[1].name : "global.no"
        ),
        handler: () => { this.uiIsLoading = false }
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      AppDelegate.showPopUpAlert(JMLanguage.translate(actionButton.popupTitle), '', actionButton.buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  hideActionButton(button) {
    let actionButton = this.actionButtonData.find(({ action }) => action == button);
    actionButton.isShow = false;
  }

  showActionButton(button) {
    let actionButton = this.actionButtonData.find(({ action }) => action == button);
    actionButton.isShow = true;
  }

  buttonHandling() {
    if (this.plan.status == JMENUM.PMStatus.DRAFT && !JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.PMPLAN_UPDATEDRAFT)) {
      this.hideActionButton(ActionButtonPmPlan.save);
    }
    if ((this.plan.status == JMENUM.PMStatus.OUTSTANDING || this.plan.status == JMENUM.PMStatus.IN_PROGRESS) && !JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.PMPLAN_UPDATE)) {
      this.hideActionButton(ActionButtonPmPlan.save);
    }
    if (this.plan.planCoverage == JMENUM.PMPlanCoverage.EQUIPMENT) {
      this.showActionButton(ActionButtonPmPlan.equipment);
    } else if (this.plan.planCoverage == JMENUM.PMPlanCoverage.LOCATION) {
      this.hideActionButton(ActionButtonPmPlan.equipment);
    }
    if (this.pageMode === JMENUM.JMPageMode.EDIT && this.plan.status !== JMENUM.PMStatus.DRAFT) {
      this.hideActionButton(ActionButtonPmPlan.submit);
    }
  }


  onChangeWorkCentreSelection() {
    this.pmPlanParticularsElem.clearTeam();
    this.pmPlanParticularsElem.toggleTeamInput();
    this.pmPlanParticularsElem.requestTeamList();
    if (this.hasPMReminderFeatureToggle) {
      this.pmPlanRemindersElem.clearRecipients();
      this.pmPlanRemindersElem.toggleRecipientsInput();
      this.pmPlanRemindersElem.requestPostSummary();
    }
  }

  onChangeSchTypeAndFreq(args: any) {
    if (this.hasPMReminderFeatureToggle && this.pageMode === JMENUM.JMPageMode.CREATE && this.reminderDefaultValueDict[args.scheduleType]) {
      this.plan['toBeOverdueReminder'] = {
        toBeDueDay: this.reminderDefaultValueDict[args.scheduleType][args.frequency] ? this.reminderDefaultValueDict[args.scheduleType][args.frequency]['toBeDueDay'] : this.reminderDefaultValueDict[args.scheduleType]['toBeDueDay'],
        interval: this.reminderDefaultValueDict[args.scheduleType][args.frequency] ? this.reminderDefaultValueDict[args.scheduleType][args.frequency]['interval'] : this.reminderDefaultValueDict[args.scheduleType]['interval'],
        activeStatus: JMENUM.ActiveStatus.ACTIVE
      };
      this.plan['overduePeriodReminder'] = {
        interval: this.reminderDefaultValueDict[args.scheduleType][args.frequency] ? this.reminderDefaultValueDict[args.scheduleType][args.frequency]['overdueInterval'] : this.reminderDefaultValueDict[args.scheduleType]['overdueInterval'],
        activeStatus: JMENUM.ActiveStatus.ACTIVE
      };
      this.pmPlanRemindersElem.updateReminderDefaultValues();
    }
  }
}
