import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { JMOBJ } from '@ccep/CCEPConnector-ts';
import { TablexComponentParams } from 'src/app/ui/components/tablex/tablex.component';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session } from '@services/session';

@Component({
  selector: 'app-jobcard-stock-reserve',
  templateUrl: './jobcard-stock-reserve.component.html',
  styleUrls: ['./jobcard-stock-reserve.component.scss']
})
export class JobcardStockReserveComponent implements OnInit, OnChanges {
  @Input() jobCard: JMOBJ.JobCard;

  tablexParam: TablexComponentParams = {};
  detailLink: string[];

  emptyHandleNa = JMLanguage.translate('component.display-text-field.na');
  
  constructor() { }

  ngOnInit() {
    this.tablexParam = {
      ...this.tablexParam,
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      tableWrapperClass: 'max-height-500 scroll-y-auto',
      // onFilterChanged: this.onFilterChanged,
      // onFilterClear: this.onFilterClear,
      // onPageNumberClicked: this.onActionPageNumberClicked.bind(this),
      // onPageSizeClicked: this.onActionPageSizeClicked.bind(this),
      // onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      highlightedRows: [],
    };
    this.tablexParam.headers = [
      // {
      //   id: 'reservationNumber',
      //   name: 'component.reserve-stock.column.reservation-no',
      //   enableSort: false,
      //   enableFilter: false,
      //   type: TablexColumnType.Text,
      //   horizontalAlign: TablexColumnHorizontalAlign.Center,
      //   verticalAlign: TablexColumnVerticalAlign.Middle,
      //   class: 'w-10',
      // },
      {
        id: 'storageLocation.code',
        name: 'component.reserve-stock.column.location-code',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.TextIcon,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },
      {
        id: 'materialNumber',
        name: 'component.reserve-stock.column.material-no',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-10',
      },
      {
        id: 'description',
        name: 'component.reserve-stock.column.material-description',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: 'stockUnit',
        name: 'component.reserve-stock.column.unit',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },
      {
        id: 'qtyRequired',
        name: 'component.reserve-stock.column.qty-required',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },
      {
        id: 'qtyCommitted',
        name: 'component.reserve-stock.column.qty-committed',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.TextIconTooltip,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },
      {
        id: 'qtyIssued',
        name: 'component.reserve-stock.column.qty-issued',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },
    ];
  }

  ngOnChanges() {
    if (this.jobCard && Array.isArray(this.jobCard.stockReservations) && this.jobCard.stockReservations.length > 0) {
      const content = [];
      const reservation = this.jobCard.stockReservations[0];
      // this.jobCard.stockReservations.forEach((reservation) => {
        const materials = reservation.material;
        const aiStoreList = Session.storageLocationAiList;
        materials.forEach((material) => {
          const lowStock = material.qtyCommitted > 0 && material.qtyRequired > material.qtyCommitted;
          const isAiStore = aiStoreList && aiStoreList.findIndex(store => store.code == material.storageLocation.code) > -1;
          const storeColumn = {
            text: material.storageLocation.code,
            iconStyle: { height: '24px', width: '24px', marginLeft: '2px' },
            iconSrc: isAiStore ? '/assets/svg/ai_store.svg' : null,
          }
          content.push([
            storeColumn,
            material.materialNumber,
            material.description,
            material.unit,
            material.qtyRequired,
            {
                text: material.qtyCommitted,
                tooltipMsg: lowStock ? JMLanguage.translate('component.reserve-stock.low-stock') : null,
            },
            material.qtyIssued,
          ]);
        });
      // });

      this.tablexParam.content = content;
      this.detailLink = [`/job-card/view/${this.jobCard.jobCardNumber}/stock-detail`];
    } else {
      this.tablexParam.content = [];
    }
  }
}
