/*
version 20210707:
- basic tablex config setting
- translate

not complete:
- real field name waiting for api development
- api migration
- mock field name for column filter

*/

import { Component, OnInit } from '@angular/core';
import { TablexColumnFilterOption, TablexColumnFilterType, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Session } from '@services/session';
import * as moment from 'moment';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { JmUserSettings } from '@enum/jm-user-settings.enum';
import { AppDelegate } from 'src/app/AppDelegate';
import { Router } from '@angular/router';
import { PmJobEditComponentRouterStateI } from '../pm-job-edit/pm-job-edit.component';
import { PmJobListAdvSearchComponent } from '../../components/pm-job-list-adv-search/pm-job-list-adv-search.component'

@Component({
  selector: 'app-pm-period-list',
  templateUrl: './pm-period-list.component.html',
  styleUrls: ['./pm-period-list.component.scss']
})
export class PmPeriodListComponent implements OnInit {

  language: string;

  // Table
  tablexFilter: any = {};
  tablexParam: {} = {};
  pageSizeOptions: any = [10, 25, 100];
  currentPageSize: number = 10;
  currentPageNumber: number = 1;
  pageCount: number = 1;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedTableRows: any = [];
  statusOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  booleanOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  searchSelections:PmJobListAdvSearchComponent['searchSelections'] = {
    contractNumber: [],
    pmJobNumber: null,
    pmPeriod: null,
    pmPlanNumber: null,
    equipmentNumber: null,
    planStatus: {},
    startRange: null,
    ngbStartDate: null,
    endRange: null,
    ngbEndDate: null,
    jobDescription: null,
    createTimeFrom: null,
    createTimeTo: null,
    handlingTeam: [],
  };
  pmPeriodSummary: any = [];

  advancedSearch: any = {};

  selectedInfo: PmJobEditComponentRouterStateI = null ;

  router : Router;

  constructor( router: Router ) { 
    this.router = router;
  }

  ngOnInit() {
    // JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.PMPLAN_VIEW], false);
    this.language = Session.selectedLanguage;
    this.initAllTableHeader();
    this.initFilterOptions();
    this.initSelectedCol();
    this.initTablex();
  }

  // ----------- API ----------- //
  async requestUpdateUserSettings() {
    let userSettings = {};
    let request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[JmUserSettings.PM_PERIOD_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
  }

  private async requestPmPeriodSummary() {
    let request: JM.JMRequestGetPmPeriodList = new JM.JMRequestGetPmPeriodList();

    if(this.tablexFilter){
      request = this.renderRequestFilter(request);
    }
    if(this.advancedSearch) {
      // use new request instead of saved request object as request._url removed in each sendJMRequest call
      this.translateSearchParams(request, this.advancedSearch);
    }
    request.includeEquipmentCount = true;
    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];
    request.pageNumber = this.currentPageNumber;
    request.pageSize = this.currentPageSize;

    this.tablexParam['isLoadingTable'] = true;
    let response: JM.JMResponseGetPmPeriodList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records && response.payload.records.length > 0) {
      this.pmPeriodSummary = response.payload.records;
      this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
      this.renderTable();
    }else{
      this.pmPeriodSummary = [];
      this.renderTable();
      this.tablexParam['pageCount'] = 0;
      this.tablexParam['currentPage'] = 0;
    }
  }

  // ----------- UI function ----------- //
  onAdvancedSearch(advancedSearch){
    this.currentPageNumber = 1;
    this.tablexParam['currentPage'] = 1;
    this.advancedSearch = advancedSearch;
    this.requestPmPeriodSummary();
  }

  initSelectedCol() {
    this.selectedColId = [
      "objId",
      "pmPlanNumber",
      "period",
      "status",
      "periodStartDate",
      "periodEndDate",
      "equipmentNumbers",
      // "equipmentCompleted",
      "periodSchedule",
      "numberOfJobs",
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[JmUserSettings.PM_PERIOD_LIST_COLUMNS]) {
      Object.assign(this.selectedColId, cachedUserSettings[JmUserSettings.PM_PERIOD_LIST_COLUMNS]);
    }

    this.onColFiltered(this.selectedColId);
  }

  renderRequestFilter(request) {
    request.filter = {};

    for (let key in this.tablexFilter) {
      if (this.tablexFilter[key]) {
        switch (key) {
          // case "status": 
          //   request.statusList = this.tablexFilter[key];
          //   break;
          // case "createdAt": 
          //   request.filter['createdAt'] = moment(this.tablexFilter[key]).format('YYYYMMDD');
          //   break;
          // case "updatedAt": 
          //   request.filter['updatedAt'] = moment(this.tablexFilter[key]).format('YYYYMMDD');
          //   break;
          default:
            request.filter[key] = this.tablexFilter[key];
            break;
        }
      }
    }
    return request;
  }

  translateSearchParams(request:JM.JMRequestGetPmPeriodList, advancedSearch:JM.JMRequestGetPmPeriodList) {
    // TODO: {{advancedSearch}} use custom obj instead of api request obj
    if(Array.isArray(advancedSearch.pmPlanNumber) && advancedSearch.pmPlanNumber.length > 0) {
      request.filter = {
        pmPlanNumber: advancedSearch.pmPlanNumber[0]
      };
    }
    request.status = advancedSearch.status;
    request.periodScheduleStatus = advancedSearch.periodScheduleStatus;
    request.periodStartDateTo = advancedSearch.periodStartDateTo;
    request.periodStartDateFrom = advancedSearch.periodStartDateFrom;
    request.periodEndDateTo = advancedSearch.periodEndDateTo;
    request.periodEndDateFrom = advancedSearch.periodEndDateFrom;
  }

  // ----------- Tablex UI function ----------- //
  onColFiltered = (selectedColId) => {
    this.selectedColId = selectedColId.map((col) => col);
    this.renderTable();

    if (!Session.userInfo['userSettings'] || (Session.userInfo['userSettings'] && Session.userInfo.userSettings[JmUserSettings.PM_PERIOD_LIST_COLUMNS] != this.selectedColId)) {
      this.requestUpdateUserSettings();
      let userSettings = {};
      userSettings[JmUserSettings.PM_PERIOD_LIST_COLUMNS] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  }

  onPageSizeClicked = (pageSize: number) => {
    this.currentPageNumber = 1;
    this.currentPageSize = pageSize;
    this.requestPmPeriodSummary();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPageNumber = pageIndex;
    this.requestPmPeriodSummary();
  }

  onSortOrderChanged = (header, sortOrder) => {
    this.tablexParam['sortBy'] = header;
    this.tablexParam['sortOrder'] = sortOrder;
    this.requestPmPeriodSummary();
  }

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }

    this.currentPageNumber = 1;
    this.requestPmPeriodSummary();
  }

  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue && header.filterDropdownOption.selectedValue.length > 0) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }

    this.currentPageNumber = 1;
    this.requestPmPeriodSummary();
  }

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.currentPageNumber = 1;
    this.requestPmPeriodSummary();
  }

  initFilterOptions() {
    this.statusOptions.items = [
      { label: JMLanguage.translate("pm.status.completed"), value: JMENUM.PMStatus.COMPLETED },
      { label: JMLanguage.translate("pm.status.in_progress"), value: JMENUM.PMStatus.IN_PROGRESS },
      { label: JMLanguage.translate("pm.status.outstanding"), value: JMENUM.PMStatus.OUTSTANDING },
    ];
    this.statusOptions.bindLabel = "label";
    this.statusOptions.bindValue = "value";
    this.statusOptions.multiple = true;
    this.statusOptions.change = this.onOptionFilterChanged;

    this.booleanOptions.items = [
      { label: JMLanguage.translate("global.yes"), value: 'yes' },
      { label: JMLanguage.translate("global.no"), value: 'no' },
    ];
    this.booleanOptions.bindLabel = "label";
    this.booleanOptions.bindValue = "value";
    this.booleanOptions.change = this.onOptionFilterChanged;
  }

  initAllTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: 'pages.job-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none'
      },
      {
        id: 'pmPlanNumber', 
        name: 'pages.pm-period-list.table-column.pm-plan-no',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'period',
        name: 'pages.pm-period-list.table-column.period',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'status', 
        name: 'pages.pm-period-list.table-column.status',
        enableFilter: false,
        enableSort: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.statusOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'periodStartDate', 
        name: 'pages.pm-period-list.table-column.period-start-date',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'periodEndDate',
        name: 'pages.pm-period-list.table-column.period-end-date',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start appraisal-status',
      },
      {
        id: 'overhaulStartDate',
        name: 'pages.pm-period-list.table-column.overhaul-start-date',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'overhaulEndDate',
        name: 'pages.pm-period-list.table-column.overhaul-end-date',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'equipmentNumbers', 
        name: 'pages.pm-period-list.table-column.number-of-equipment',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      // {
      //   id: 'equipmentCompleted', //TODO: fieldname not confirm
      //   name: 'pages.pm-period-list.table-column.equipment-completed',
      //   enableFilter: true,
      //   enableSort: true,
      //   type: TablexColumnType.Text,
      //   horizontalAlign: TablexColumnHorizontalAlign.Center,
      //   verticalAlign: TablexColumnVerticalAlign.Bottom,
      //   class: 'col th-align-self-start',
      // },
      {
        id: 'periodSchedule', 
        name: 'pages.pm-period-list.table-column.period-schedule',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'numberOfJobs', 
        name: 'pages.pm-period-list.table-column.number-of-jobs',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      }
    ];
    this.selectedCol = this.allColHeaders;
  }

  onRowClicked = (index, row) => {
    let prevStatus = this.tablexParam['highlightedRows'][index];
    if ( !prevStatus ) {
      const { _id , pmPlanNumber, status } = this.pmPeriodSummary[index];
      this.selectedInfo = {
        periodId : _id,
        pmPlanId : pmPlanNumber,
        pmPlanStatus : status,
      };
    } else {
      this.selectedInfo = null;
    }
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['highlightedRows'][index] = !prevStatus;
  }

  onClickCreatePMTask = () => {
    this.router.navigateByUrl( '/pm/job/create' , { state : this.selectedInfo })
  }

  resetRowSelected() {
    this.selectedInfo = null;
    this.tablexParam['highlightedRows'] = [];
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      // enableClearFilter: true,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: true,
      minifyButton: true,
      sortBy: 'periodStartDate',
      sortOrder: -1,
      tableRow: 'row',
      tableClass: 'pm-period-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPageNumber,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      onRowClicked: this.onRowClicked,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
      customClass: 'expiry'
    };
  }

  renderTable() {
    this.resetRowSelected();

    this.tablexParam['headers'] = this.allColHeaders.filter(col => {
      return this.selectedColId.includes(col.id);
    });

    this.tablexParam['content'] = this.pmPeriodSummary.map((data, index) => {

      let buttons:any = [
        { "id": "button_" + data._id, "name": 0, "class": "custom-noneOfJobButton"}
      ]

      if(data.pmJobList != undefined && data.pmJobList.length != 0){
        buttons = [
          { "id": "button_" + data._id, "name": data.pmJobList.length, "class": "custom-numberOfJobsButton", "onClicked": ()=>this.onNumberOfJobsClicked(data)},
        ];
      }

      let originRow = [
        data._id,
        data.pmPlanNumber ? {url: '/pm/standard-plan/view/'+data.pmPlanNumber, value: data.pmPlanNumber} : '',
        data.index? data.index : '',
        data.status? JMLanguage.translate('pm.status.' + data.status) : '',
        data.periodStartDate? moment(data['periodStartDate']).format(Constants.DATE_FORMAT) : '',
        data.periodEndDate? moment(data['periodEndDate']).format(Constants.DATE_FORMAT) : '',
        data.overhaulStartDate? moment(data['overhaulStartDate']).format(Constants.DATE_FORMAT) : '',
        data.overhaulEndDate? moment(data['overhaulEndDate']).format(Constants.DATE_FORMAT) : '',
        data.equipmentCount? data.equipmentCount : 0,
        // data.equipmentCompleted? data.equipmentCompleted : '',
        data.periodScheduleStatus ? this.getOverdueStatusText(data.periodScheduleStatus) : '',
        buttons
      ];
      let row = []
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
    this.tablexParam['isLoadingTable'] = false;
  }

  onNumberOfJobsClicked(data){
    this.clearPmJobListSettingsFiltersSession();
    this.setPmJobListSettingsFilters(data);
    this.saveFilterOptionsToLocal();
    this.toPmJobList();
  }
  
  getOverdueStatusText(code: string) {
    switch(code) {
      case JMENUM.PMPlanPeriod.ON_SCHEDULE:
        return JMLanguage.translate('pm.plan-period.on_schedule');
      case JMENUM.PMPlanPeriod.OVERDUE:
        return JMLanguage.translate('pm.plan-period.overdue');
      default:
        return '';
    }
  }
  
  private clearPmJobListSettingsFiltersSession(){
    Session.setPmJobListSettingsFilters(null);
  }

  private setPmJobListSettingsFilters(data){
    this.searchSelections = {
      ...this.searchSelections,
      pmPeriod: data._id? data._id:null,
      pmPlanNumber:data.pmPlanNumber? data.pmPlanNumber:null,
    }
  }

  private saveFilterOptionsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      Session.setPmJobListSettingsFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  toPmJobList() {
    AppDelegate.navigate(['/pm/job-list']);
  }
  // ------------tablex end ------------
}
