import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';
import { environment } from 'src/environments/environment';
import { Session }  from 'src/app/services/session';
import { JM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BasePage implements OnInit {
  
    constructor(injector: Injector) {
        super(injector, false);
    }
  
  ngOnInit() {
    this.translateService.setDefaultLang(Session.selectedLanguage);
    this.translateService.use(Session.selectedLanguage);
    JMLanguage.changeLanguage(Session.selectedLanguage);
        
    JM.JMConnector.setHost(environment.CCEP_API_HOST);
    JM.JMConnector.setFileHost(environment.CCEP_FILE_HOST);
    JM.JMConnector.setLanguage(Session.selectedLanguage);
    JM.JMConnector.setEquipmentCategory(Session.equipmentCategoryDict);
    JM.JMConnector.setEquipmentType(Session.equipmentTypeDict);
    JM.JMConnector.setClientDict(Session.clientDict);
    JM.JMConnector.setWorkCentreDict(Session.workCentreDict);
    JM.JMConnector.setManualInstructionArray(Session.manualInstructionArray);
    if (Session.userInfo) {
      JM.JMConnector.setPost(Session.userInfo);
      JM.JMConnector.setToken(Session.userToken);
    }
  }
  
}
