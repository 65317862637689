import { Component, ElementRef, Input, OnInit, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() timeIntervals: TimeInterval[] = [];
  @Input() periodWidth: number = 68;

  @Output() selectPeriod = new EventEmitter<any>();

  highlightRectangle: HTMLElement;
  @ViewChild('highlightRectangle', { static: false })
  set setHighlightRectangl(ref: ElementRef) {
    this.highlightRectangle = ref.nativeElement;
  }
  @ViewChild('tableContainer', { static: false }) tableContainer: ElementRef;
  @ViewChild('spinner', { static: false }) spinner: ElementRef;
  @ViewChild('tableHeader', { static: false }) tableHeader: ElementRef;
  @ViewChild('tableBody', { static: false }) tableBody: ElementRef;
  @ViewChild('virtualTableForDisplay', { static: false }) virtualTableForDisplay: ElementRef;
  @ViewChild('virtualTableForDraw', { static: false }) virtualTableForDraw: ElementRef;
  @ViewChild('timetableWrapper', { static: false }) timetableWrapper: ElementRef;

  intervalCount = 4;
  startDragPoint: point;
  isDragInsideVirtualTable = true;
  highlightedIntervalKeyObjs: highlightedIntervalsKeyObj[] = [];
  firstHighlightedIntervalKeyObj: highlightedIntervalsKeyObj;
  restrictWithinPeriod = false;
  dataRowHeight: number;
  highlightedRectangle: rectangle;
  isHighlighting = false;
  isShowSpinner = false;
  firstRowCellWidth = 0;
  allDatePeriods: datePeriods[] = [];

  constructor() { }

  ngOnInit() {
    if (!this.timeIntervals.length) {
      for (let i = 0; i < 24; i++) {
        this.timeIntervals.push(new TimeInterval({ label: i.toString(), value: i }));
      }
    }
    this.intervalCount = this.timeIntervals[0].intervals.length;
    this.renderPeriods(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.timeIntervals.length && changes.data && changes.data.currentValue) {
      this.setUpVirtualTablePosition();
      this.renderPeriods(changes.data.currentValue)
    }
  }


  ngAfterViewInit() {
    this.dataRowHeight = document.querySelector(".timetable-container .body-container .data-row").getBoundingClientRect().height;
    this.setUpVirtualTablePosition();
  }

  // ngAfterViewChecked() {
  //   if (this.tableContainer && this.spinner && this.tableHeader && this.tableBody) {
  //     const tableContainerRect = this.tableContainer.nativeElement.getBoundingClientRect();
  //     const tableHeaderRect = this.tableHeader.nativeElement.getBoundingClientRect();
  //     const tableBodyRect = this.tableBody.nativeElement.getBoundingClientRect();
  //     this.spinner.nativeElement.style.height =
  //       `${Math.min(tableContainerRect.height, (tableHeaderRect.height + tableBodyRect.height))}px`;
  //     this.spinner.nativeElement.style.top = this.tableContainer.nativeElement.scrollTop + 'px';
  //     this.spinner.nativeElement.style.width = tableHeaderRect.width - 1 + 'px';
  //   }
  // }

  setUpVirtualTablePosition() {
    if (this.tableContainer && this.tableHeader && this.tableBody) {
      this.firstRowCellWidth = this.tableBody.nativeElement.getElementsByClassName('first-row-cell').length ? this.tableBody.nativeElement.getElementsByClassName('first-row-cell')[0].getBoundingClientRect().width : 0;
      if (this.virtualTableForDisplay && this.virtualTableForDraw) {
        this.virtualTableForDisplay.nativeElement.style.width = `calc(100% - ${this.firstRowCellWidth}px)`;
        this.virtualTableForDisplay.nativeElement.style.visibility = 'visible';
        this.virtualTableForDraw.nativeElement.style.width = `calc(100% - ${this.firstRowCellWidth}px)`;
      }
    }
  }

  renderPeriods(data: any[]) {
    if (!this.timeIntervals.length) {
      return;
    }
    this.allDatePeriods = [];
    data.forEach((dateData) => {
      const datePeriods: datePeriods = {
        date: dateData.date,
        periods: []
      };
      if (dateData.date) {
        dateData.periods.forEach(period => {
          if (period.startTime && period.endTime) {
            const strTime = moment(period.startTime, "HH:mm");
            const strPeriod = strTime.hours();
            const strMin = strTime.minutes();
            const startIndex = strPeriod * this.intervalCount + this.timeIntervals[0].intervals.findIndex(interval => +interval.value === strMin);
            const endTime = moment(period.endTime, "HH:mm");
            const endPeriod = endTime.hours() === 0 && !endTime.isSame(strTime, 'date') ? 24 : endTime.hours(); // 00:00 next day will be treated as same day 24:00
            const endMin = endTime.minutes();
            const endIndex = endPeriod * this.intervalCount + this.timeIntervals[0].intervals.findIndex(interval => +interval.value === endMin);
            const duration = moment.duration(endTime.diff(strTime));
            const hours = Math.floor(duration.asHours());
            const minutes = duration.asMinutes() % 60;
            const displayText = `${hours ? hours + ' ' + (hours > 1 ? JMLanguage.translate('pages.timesheet.short-form.hours') : JMLanguage.translate('pages.timesheet.short-form.hour')) :
              ''}${minutes ? ' ' + minutes + ' ' + JMLanguage.translate('pages.timesheet.short-form.mins') : ''}`;
            if (hours === 0 && minutes <= 30) {
              datePeriods.periods.push({ startIndex, endIndex, label: displayText, style: { color: 'black' } });
            } else {
              datePeriods.periods.push({ startIndex, endIndex, label: displayText });
            }

          }
        });
      }
      this.allDatePeriods.push(datePeriods);
    });
  }

  onStartDrawing(args: MouseEvent) {
    this.startDragPoint = {
      x: args.offsetX,
      y: args.offsetY
    };
    const rowIndex = Math.floor(this.startDragPoint.y / this.dataRowHeight);
    const periodIndex = Math.floor(args.offsetX / this.periodWidth);
    const intervalIndex = Math.floor(args.offsetX % this.periodWidth / (this.periodWidth / this.intervalCount));
    this.restrictWithinPeriod = this.checkIfIndexIsWithinPeriod(rowIndex, periodIndex * this.intervalCount + intervalIndex);
    const firstHighLightElement = document.getElementById(`cell-${rowIndex}-${periodIndex}-${intervalIndex}`);
    this.firstHighlightedIntervalKeyObj = {
      rowIndex, periodIndex, intervalIndex, left: firstHighLightElement.offsetLeft - this.firstRowCellWidth,
      right: firstHighLightElement.offsetLeft - this.firstRowCellWidth + firstHighLightElement.offsetWidth, top: firstHighLightElement.offsetTop
    };
    document.addEventListener('mousemove', this.onDrawing);
    document.addEventListener('mouseup', this.onStopDrawing);
  }

  onStopDrawing = (args: any) => {
    this.finishDrawTimePeriod();
    this.clearHighlight();
    this.firstHighlightedIntervalKeyObj = null;
    document.removeEventListener('mousemove', this.onDrawing);
    document.removeEventListener('mouseup', this.onStopDrawing);
  }

  onDrawing = (args: any) => {
    if (this.isDragInsideVirtualTable) {
      this.clearHighlight();
      this.highlightIntervals(args.offsetX);
      this.drawHighlightRect();
    } else {
      const ele = this.timetableWrapper && this.timetableWrapper.nativeElement;
      if (ele) {
        const eleRect = ele.getBoundingClientRect();
        if (args.x > eleRect.right) {
          ele.scrollLeft += 10;
        } else if (args.x < eleRect.left) {
          ele.scrollLeft -= 10;
        }
      }
    }
  }

  finishDrawTimePeriod() {
    if (this.highlightedIntervalKeyObjs.length) {
      const firstSelectedDate = moment(this.data[this.highlightedIntervalKeyObjs[0].rowIndex].date);
      const firstSelectedDateTime = firstSelectedDate.hour(this.highlightedIntervalKeyObjs[0].periodIndex).minute(this.highlightedIntervalKeyObjs[0].intervalIndex * 15);
      const lastSelectedDate = moment(this.data[this.highlightedIntervalKeyObjs[this.highlightedIntervalKeyObjs.length - 1].rowIndex].date);
      const lastSelectedDateTime = lastSelectedDate.hour(this.highlightedIntervalKeyObjs[this.highlightedIntervalKeyObjs.length - 1].periodIndex).minute(
        (this.highlightedIntervalKeyObjs[this.highlightedIntervalKeyObjs.length - 1].intervalIndex) * 15);
      const startTime = firstSelectedDateTime.isSameOrBefore(lastSelectedDateTime) ? firstSelectedDateTime : lastSelectedDateTime;
      const endTime = lastSelectedDateTime.isSameOrAfter(firstSelectedDateTime) ? lastSelectedDateTime : firstSelectedDateTime;
      endTime.minute(endTime.minute() + 15);
      this.selectPeriod.emit({
        date: this.data[this.highlightedIntervalKeyObjs[0].rowIndex].date,
        weekday: this.data[this.highlightedIntervalKeyObjs[0].rowIndex].weekday,
        periods: [
          {
            startTime,
            endTime
          }
        ]
      })
    }
  }

  checkIfIndexIsWithinPeriod(rowIndex: number, intervalIndex: number) {
    return this.allDatePeriods[rowIndex].periods.findIndex((period: periodTimeline) => (period.startIndex <= intervalIndex) && (intervalIndex < period.endIndex)) > -1;
  }

  drawHighlightRect() {
    this.highlightRectangle.style.left = this.highlightedRectangle.x + 'px';
    this.highlightRectangle.style.top = this.highlightedRectangle.y + 'px';
    this.highlightRectangle.style.width = this.highlightedRectangle.width + 'px';
    this.highlightRectangle.style.height = this.highlightedRectangle.height + 'px';
    this.isHighlighting = true;
  }

  onMouseEnterVirtualTable() {
    this.isDragInsideVirtualTable = true;
  }

  onMouseLeaveVirtualTable() {
    this.isDragInsideVirtualTable = false;
  }

  highlightIntervals(newX: number) {
    let { rowIndex, periodIndex, intervalIndex, left, right, top } = this.firstHighlightedIntervalKeyObj;
    const highLightIntervalCount = 1 +
      (newX > this.startDragPoint.x ?
        Math.ceil((newX - right) / (this.periodWidth / this.intervalCount)) : // turn right
        Math.ceil((left - Math.max(newX, 0)) / (this.periodWidth / this.intervalCount))); // turn left
    this.highlightedIntervalKeyObjs.push({ rowIndex, periodIndex, intervalIndex });
    this.highlightedRectangle = {
      x: left - 1,
      y: top - 1,
      width: (this.periodWidth / this.intervalCount) + 1,
      height: this.dataRowHeight + 1
    }; // +1 or -1 for cover border 
    const xDiff = newX - this.startDragPoint.x;
    if (xDiff >= 0) { // turn right
      for (let i = 1; i < highLightIntervalCount; i++) {
        if (periodIndex === 23 && intervalIndex === this.intervalCount) {
          return;
        }
        intervalIndex += 1;
        if (intervalIndex >= this.intervalCount) {
          periodIndex++;
          intervalIndex = 0;
        }
        if (this.restrictWithinPeriod !== this.checkIfIndexIsWithinPeriod(rowIndex, periodIndex * this.intervalCount + intervalIndex)) {
          return;
        }
        this.highlightedIntervalKeyObjs.push({ rowIndex, periodIndex, intervalIndex });
        this.highlightedRectangle.width += (this.periodWidth / this.intervalCount);
      }
    } else { // turn left
      for (let i = 1; i < highLightIntervalCount; i++) {
        if (periodIndex === 0 && intervalIndex === 0) {
          return;
        }
        intervalIndex -= 1;
        if (intervalIndex < 0) {
          periodIndex--;
          intervalIndex = this.intervalCount - 1;
        }
        if (this.restrictWithinPeriod !== this.checkIfIndexIsWithinPeriod(rowIndex, periodIndex * this.intervalCount + intervalIndex)) {
          return;
        }
        this.highlightedIntervalKeyObjs.push({ rowIndex, periodIndex, intervalIndex });
        this.highlightedRectangle.x -= (this.periodWidth / this.intervalCount);
        this.highlightedRectangle.width += (this.periodWidth / this.intervalCount);
      }
    }
  }

  clearHighlight() {
    this.highlightedIntervalKeyObjs = [];
    this.isHighlighting = false;
  }

}

class TimeInterval {
  period: cellConfig = null;
  intervals: cellConfig[] = [];
  constructor(period: cellConfig, intervals?: cellConfig[]) {
    this.period = period;
    this.intervals = intervals ? intervals : [{ label: '00', value: 0 }, { label: '15', value: 15 }, { label: '30', value: 30 }, { label: '45', value: 45 }];
  }
}

interface cellConfig {
  label: string;
  value: string | number;
  style?: any;
  highlightStyle?: any;
  periodConfig?: periodConfig;
}

interface periodConfig {
  displayText: string;
  style: any;
}

interface point {
  x: number;
  y: number;
}

interface highlightedIntervalsKeyObj {
  rowIndex: number;
  periodIndex: number;
  intervalIndex: number;
  left?: number;
  right?: number;
  top?: number;
}

interface rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface datePeriods {
  date: string | Date;
  periods: periodTimeline[];
}

interface periodTimeline {
  startIndex: number;
  endIndex: number;
  label?: string;
  style?: any;
}

