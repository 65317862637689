import { Component, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { fileSize } from 'src/lib/presenter/Formatter';
import { Constants } from 'src/constants';
import { isEmpty } from 'src/app/presenter/validators/validator';
import { NgOption } from '@ng-select/ng-select';
import { debounce, cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { AuthorizationService } from '@services/authorization.service';

interface IFileRequestData {
  name: string;
  data: string;
}

interface IInitOptions {
  items: NgOption[];
  nextPage: number;
  totalPage: number;
  search: string;
  filter: string | {[propName: string]: any} | null;
  reset: () => IInitOptions;
  setFilter: (val: string | {[propName: string]: any} | null) => IInitOptions;
  setSearch: (val: string) => IInitOptions;
}

const initOptions: IInitOptions = { // for lazy load
  items: [],
  nextPage: 1,
  totalPage: 1,
  filter: null,
  search: '',
  reset() {
    this.items = [];
    this.nextPage = 1;
    this.totalPage = 1;
    this.filter = null;
    return this;
  },
  setFilter(value) {
    if (typeof value === 'object' && value !== null) {
      this.filter = {...this.filter, ...value};
    } else {
      this.filter = value;
    }
    return this;
  },
  setSearch(value) {
    this.search = value;
    return this;
  }
};

const initData: Partial<JM.JMRequestEnquiryCreateEnquiry> = {
  contactName: '',
  contactNumber: null,
  systemName: Constants.SYSTEM_NAME,
  email: '',
  category: null,
  subCategory: null,
  description: '',
  ccList: [],
  attachments: []
};

const initErrorFeilds = {} as Record<keyof JMOBJ.Enquiry, boolean>;

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss']
})

export class EnquiryFormComponent implements OnInit {

  data = cloneDeep(initData);
  attachmentsTemp = []; // for storing fileDate upon upload. Will convert to API-favor data later
  errorFields = {...initErrorFeilds};

  categoryMap = {} as Record<keyof typeof JMENUM.EnquiryCategory, (keyof typeof JMENUM.EnquirySubCategory)[]>;
  hintTextMap = {} as Record<keyof typeof JMENUM.EnquirySubCategory, string>;
  categoryOption: NgOption[];
  subCategoryOption: NgOption[];
  cclistOption = {...initOptions};
  optionsPageSize = 10;
  onCCListSelectSearchDebounce: (e) => void;
  onCCListSelectScrollToEndDebounce: () => void;

  // file related
  fileSize = fileSize;
  allowedFileExt = [
    'jpg', 'jpeg', 'gif',
    'doc', 'docx', 'docm',
    'ppt', 'pptx', 'pptm',
    'xls', 'xlm', 'xlsm', 'xlsx', 'csv',
    'zip', 'rar',
    'pdf',
    'txt',
  ];
  allowedFileExtPrint = this.allowedFileExt.map(item => '.' + item).join(', ');

  descriptionPlaceholder = JMLanguage.translate('pages.enquiry-form.hint.default');

  // loading flags
  isLoading = {
    requestPostSummary: false,
    submitEnquiry: false,
    getEnquiryCategory: false,
  };

  // for ui
  JMENUM = JMENUM;

  constructor(private router: Router, private authorizationService: AuthorizationService) {
    this.onCCListSelectSearchDebounce = debounce(this.onCCListSelectSearch, 250);
    this.onCCListSelectScrollToEndDebounce = debounce(this.onCCListSelectScrollToEnd, 250);
  }

  ngOnInit() {
    if (!this.hasPagePermission()) {
      this.router.navigate(['/enquiry/list']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    this.onResetForm();
    this.requestPostSummary();
    this.requestEnquiryGetEnquiryCategory();
  }

  hasPagePermission = (): boolean => {
    return this.authorizationService.hasPermission(JMENUM.Permission.ENQUIRY_CREATE);
  }

  onCCListSelectScrollToEnd = (): void => {
    this.requestPostSummary();
  }

  onCCListSelectSearch = (e): void => {
    this.cclistOption.reset().setFilter({ employeeName: e.term }).setSearch(e.term);
    this.requestPostSummary();
  }

  onCCListSelectClear = (): void => {
    this.cclistOption.reset();
    this.requestPostSummary();
  }

  onCCListSelectClick = (): void => {
    if (!this.data.ccList.length && !!this.cclistOption.search) this.onCCListSelectClear();
  }

  onFileInput = ({target}: {target: HTMLInputElement}): void => {
    const maxFileSize = 50 * 1024 * 1024; // 50Mb
    const maxTotalFileSize = 50 * 1024 * 1024; // 50Mb
    const maxNoOfFiles = 10;

    if (target.files.length) {
      Array.from(target.files).forEach(file => {
        if (this.attachmentsTemp.length >= maxNoOfFiles) {
          return AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry-form.msg.error.number-of-file'));
        }
        if (file.size > maxFileSize) {
          return AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry-form.msg.error.max-size'));
        }
        if (this.attachmentsTemp.reduce((acc, curr) => acc + curr.size, 0) + file.size >= maxTotalFileSize) {
          return AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry-form.msg.error.total-file-size'));
        }
        if (this.allowedFileExt.indexOf(file.name.split('.')[1]) === -1) {
          return AppDelegate.openSnackBar(JMLanguage.translate('pages.enquiry-form.msg.error.file-type'));
        }
        this.attachmentsTemp.push(file);
      });
    }
  }

  convertToFilesRequestDataPromise = (files: any[]): Promise<IFileRequestData[]> => {
    const promises = files.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise<IFileRequestData>((resolve) => {
        reader.onload = () => {
          resolve({
            name: file.name,
            data: reader.result.toString().split(',')[1],
          });
        };
      });
    });
    return Promise.all(promises);
  }

  getFileIconClass = (fileName: string): string => {
    const fileExt = fileName.split('.')[1];
    if (['jpg', 'jpeg', 'gif'].indexOf(fileExt) > -1) return 'fa-image';
    if (['doc', 'docx', 'docm'].indexOf(fileExt) > -1) return 'fa-file-word';
    if (['ppt', 'pptx', 'pptm'].indexOf(fileExt) > -1) return 'file-powerpoint';
    if (['xlm', 'xlsm', 'xls', 'xlsx', 'csv'].indexOf(fileExt) > -1) return 'fa-file-excel';
    if (['pdf'].indexOf(fileExt) > -1) return 'fa-pdf';
    if (['zip', 'rar'].indexOf(fileExt) > -1) return 'fa-file-archive';
    return 'fa-file-alt';
  }

  removeAttachment = (idx): void => {
    this.attachmentsTemp.splice(idx, 1);
  }

  onCategoryChange = (value?: keyof typeof JMENUM.EnquiryCategory): void => {
    if (!value) this.data.subCategory = null;
    // if (!value || value === JMENUM.EnquiryCategory.OTHERS) this.data.subCategory = null;
    this.updateSubCategoryList(value);
  }

  onSubCategoryChange = (): void => {
    this.updateDescriptionPlaceholder();
  }

  updateSubCategoryList = (category?: keyof typeof JMENUM.EnquiryCategory): void => {
    this.subCategoryOption = this.subCategoryOption.map(subCat => {
      return typeof subCat.value === 'string'
        ? {
          ...subCat,
          hidden: category ? this.categoryMap[category].indexOf(JMENUM.EnquirySubCategory[subCat.value]) === -1 : false
        }
        : subCat;
    });
  }

  updateDescriptionPlaceholder = (): void => {
    this.descriptionPlaceholder = JMLanguage.translate('pages.enquiry-form.hint.' + (this.data.subCategory || 'default') );
  }

  isSubCategoryDisabled = (): boolean => {
    return !this.data.category;
    // return !this.data.category || this.data.category === JMENUM.EnquiryCategory.OTHERS;
  }

  validation = (): boolean => {
    let isValid = true;

    // Check Mandatory
    const mandatoryFields = ['contactName', 'contactNumber', 'email', 'category', 'description'];
    mandatoryFields.forEach(field => {
      this.errorFields[field] = isEmpty(this.data[field]);
    });

    // Check Addtional checkings
    // this.errorFields.subCategory =  !this.data.subCategory && this.data.category !== JMENUM.EnquiryCategory.OTHERS;
    this.errorFields.subCategory =  !this.data.subCategory;
    return isValid = Object.keys(this.errorFields).every(field => !this.errorFields[field]);
  }

  onSubmit = (): void => {
    if (!this.validation()) return;
    AppDelegate.showPopUpAlert(
      JMLanguage.translate('pages.enquiry-form.popup.submit-confirm'), '',
      [{
        name: JMLanguage.translate('global.ok'),
        handler: () => this.requestEnquiryCreateEnquiry()
      }, {
        name: JMLanguage.translate('global.no'),
      }]
    );
  }

  onResetForm = (): void => {
    this.data = {
      ...cloneDeep(initData),
      contactName: (Session && Session.userInfo.name) || '',
    };
    this.errorFields = {...initErrorFeilds};
  }

  requestPostSummary = async (): Promise<void> => {
    // Error handling
    if (
      this.isLoading.requestPostSummary ||
      this.cclistOption.nextPage > this.cclistOption.totalPage
    ) return console.warn('Debug...Early exit...');

    // Pre request
    this.isLoading.requestPostSummary = true;
    const isEnquiryAdmin = Session.userInfo.roles.some(({roleId}) => (
      // #17378 - admin: Administrator, Helpdesk Support
      // #17419 - For CSC/system administrator, user can view all user list
      roleId === 108 || // Helpdesk Support
      roleId === 102 || // CSC Supervisor
      roleId === 103 || // CSC User
      roleId === 601 // Admin
    ));
    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.parameters = ['name', 'employeeName'];
    request.pageNumber = this.cclistOption.nextPage;
    request.pageSize = this.optionsPageSize;
    if (!isEnquiryAdmin) request.authorizations.workCenters = Session.userInfo.authorizations.workCenters;
    // if (typeof this.cclistOption.filter === 'string') { request.name = [this.cclistOption.filter]; }
    if (typeof this.cclistOption.filter === 'object') { request.filter = this.cclistOption.filter; }

    // Post request
    const response = await JM.JMConnector.sendAsyncPostsPostSummary(request)
    this.isLoading.requestPostSummary = false;

    // Error handling
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      return AppDelegate.openErrorBar({response});
    }
    // Actions
    const optionItems = response.payload.records;
    // update cclistOption property
    this.cclistOption = {
      ...this.cclistOption,
      items: this.cclistOption.items.concat(optionItems),
      nextPage: this.cclistOption.nextPage + 1,
      totalPage: Math.ceil(response.payload.totalCount / this.optionsPageSize),
    };
  }

  requestEnquiryCreateEnquiry = async (): Promise<void> => {
    // Error handling
    if (this.isLoading.submitEnquiry) return console.warn('Debug...Early exit...');

    // Pre request
    this.isLoading.submitEnquiry = true;
    let request = new JM.JMRequestEnquiryCreateEnquiry();
    request = {...request, ...this.data};
    request.contactNumber = request.contactNumber.toString();
    request.attachments = await this.convertToFilesRequestDataPromise(this.attachmentsTemp);

    // Post request
    const response = await JM.JMConnector.sendAsyncEnquiryCreateEnquiry(request)
    this.isLoading.submitEnquiry = false;

    // Error handling
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      return AppDelegate.openErrorBar({response});
    }
    // Actions
    this.router.navigate(['/enquiry/list']);
  }

  requestEnquiryGetEnquiryCategory = async (): Promise<void> => {
    // Error handling
    if (this.isLoading.getEnquiryCategory) return console.warn('Debug...Early exit...');

    // Pre request
    this.isLoading.getEnquiryCategory = true;
    const request = new JM.JMRequestEnquiryGetEnquiryCategory();

    // Post request
    const response = await JM.JMConnector.sendAsyncEnquiryGetEnquiryCategory(request)
    this.isLoading.getEnquiryCategory = false;

    // Error handling
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records.length) {
      return AppDelegate.openErrorBar({response});
    }
    // Actions
    const records = response.payload.records.sort(sorting);
    this.categoryOption = records.map(({code}): NgOption => ({
      value: code,
      label: JMLanguage.translate('enquiry.category.' + code),
      // label: category.description[JMLanguage.getCurrentLanguage()] || category.description.en || '',
    }));
    this.subCategoryOption = records.reduce((acc, currCategory) => {
      return acc.concat(currCategory.SubCategory
        .sort(sorting)
        .map(({code}): NgOption => ({
          value: code,
          label: JMLanguage.translate('enquiry.subCategory.' + code),
          // label: category.description[JMLanguage.getCurrentLanguage()] || category.description.en || '',
        })
      ))
    }, []);
    response.payload.records.forEach(cetegory => {
      this.categoryMap[cetegory.code] = cetegory.SubCategory.map(subCategory => subCategory.code);
    });

    function sorting(a, b) {
      return a.code > b.code ? 1 : -1;
    }
  }

  checkData = () => {
    console.log(111, 'data', this.data);
  }
}
