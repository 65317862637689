<div class="wrapper page-enquiry-form">
  <app-nav navbarTitle="{{ 'pages.enquiry.title' | translate }}"></app-nav>
  <app-sidebar type='enquiry'></app-sidebar>
  
  <!-- Page Content -->
  <div id="content">
    <div class="row header">
      <div class="col">
        <h2 class="title">{{ 'pages.enquiry-form.title' | translate }}</h2>
        <hr>
      </div>
    </div>
    
    <form action="">
      <div class="row">
        <!-- Left Hand Side -->
        <div class="col-md-6">
  
          <div class="field row mb-3">
            <label for="form-item--contactName" class="col-4 m-0 d-flex justify-content-end align-items-center text-right">
              <span class="required">{{ 'pages.enquiry.label.contactName' | translate }}</span>
            </label>
            <div class="col-8">
              <input 
                id="form-item--contactName" 
                name="form-item--contactName" 
                class="form-control"
                type="text"
                [class.input-error]="errorFields.contactName"
                [(ngModel)]="data.contactName"
              />
            </div>
          </div>

          <div class="field row mb-3">
            <label for="form-item--contactNumber" class="col-4 m-0 d-flex justify-content-end align-items-center text-right">
              <span class="required">{{ 'pages.enquiry.label.contactNumber' | translate }}</span>
            </label>
            <div class="col-8">
              <input 
                id="form-item--contactNumber" 
                name="form-item--contactNumber" 
                class="form-control"
                type="number"
                [class.input-error]="errorFields.contactNumber"
                [(ngModel)]="data.contactNumber"
              />
            </div>
          </div>

          <div class="field row mb-3">
            <label for="form-item--email" class="col-4 m-0 d-flex justify-content-end align-items-center text-right">
              <span class="required">{{ 'pages.enquiry.label.email' | translate }}</span>
            </label>
            <div class="col-8">
              <input 
                id="form-item--email" 
                name="form-item--email" 
                class="form-control"
                type="email"
                [class.input-error]="errorFields.email"
                [(ngModel)]="data.email"
              />
            </div>
          </div>

          <div class="field row mb-3">
            <label for="form-item--category" class="col-4 m-0 d-flex justify-content-end align-items-center text-right">
              <span class="required">{{ 'pages.enquiry.label.category' | translate }}</span>
            </label>
            <div class="col-8">
              <ng-select 
                id="form-item--category" 
                name="form-item--category" 
                (change)="onCategoryChange($event)"
                (clear)="onCategoryChange()"
                [class.input-error]="errorFields.category"
                [loading]="isLoading.getEnquiryCategory"
                [(ngModel)]="data.category"
              >
                <ng-option *ngFor="let option of categoryOption" [value]="option.value">{{ option.label }}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="field row mb-3">
            <label for="form-item--subCategory" class="col-4 m-0 d-flex justify-content-end align-items-center text-right">
              <span [class.required]="data.category && data.category !== JMENUM.EnquiryCategory.others">{{ 'pages.enquiry.label.subCategory' | translate }}</span>
            </label>
            <div class="col-8">
              <ng-select 
                id="form-item--subCategory" 
                name="form-item--subCategory" 
                (change)="onSubCategoryChange()"
                [disabled]="isSubCategoryDisabled() "
                [class.input-error]="errorFields.subCategory"
                [loading]="isLoading.getEnquiryCategory"
                [(ngModel)]="data.subCategory"
              >
                <ng-container *ngFor="let option of subCategoryOption" >
                  <ng-option *ngIf="!option.hidden" [value]="option.value">{{ option.label }}</ng-option>
                </ng-container>
              </ng-select>
            </div>
          </div>

          <div class="field row mb-3">
            <label for="form-item--ccList" class="col-4 m-0 d-flex justify-content-end align-items-center text-right">
              <span>{{ 'pages.enquiry.label.ccList' | translate }}</span>
            </label>
            <div class="col-8">
              <ng-select
                id="form-item--ccList" 
                name="form-item--ccList" 
                multiple="true"
                [(ngModel)]="data.ccList"
                [loading]="isLoading.requestPostSummary"
                [class.input-error]="errorFields.ccList"
                (click)="onCCListSelectClick()"
                (clear)="onCCListSelectClear()"
                (search)="onCCListSelectSearchDebounce($event)"
                (scrollToEnd)="onCCListSelectScrollToEnd()"
              >
                <ng-option *ngFor="let option of cclistOption.items" [value]="option.name">{{ option.employeeName + ' (' + option.name + ')' }}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col offset-4">
              <button
                id="enquiry-form-submit"
                name="enquiry-form-submit"
                class="btn brand-blue"
                (click)="onSubmit()"
              >
                <i class="fas fa-paper-plane mr-1"></i>
                {{ 'pages.enquiry.label.submit' | translate }}
                <span *ngIf="isLoading.submitEnquiry" class="spinner-border spinner-border-sm ml-3" role="status" aria-hidden="true"></span>
              </button>
              <button class="btn blue ml-2" (click)="onResetForm()">{{ 'pages.enquiry.label.reset' | translate }}</button>
            </div>
          </div>
        </div>
  
        <!-- Right Hand Side -->
        <div class="col-md-6">

          <div class="field row mb-3">
            <label for="form-item--description" class="col-4 m-0 d-flex justify-content-end align-items-center text-right">
              <span class="required">{{ 'pages.enquiry.label.description' | translate }}</span>
            </label>
            <div class="col-8">
              <textarea 
                id="form-item--description" 
                name="form-item--description" 
                name="form-item--description"
                class="form-control"
                rows="8"
                [placeholder]="descriptionPlaceholder"
                [class.input-error]="errorFields.description"
                [(ngModel)]="data.description"
              ></textarea>
            </div>
          </div>
  
          <div class="field row mb-3">
            <label for="form-item--attachments" class="col-4 m-0 d-flex justify-content-end align-items-center text-right">
              <span>{{ 'pages.enquiry.label.attachments' | translate }}</span>
            </label>
            <div class="col-8">
              <ng-container *ngIf="attachmentsTemp.length">
                <ul class="attachments list-unstyled">
                  <li *ngFor="let file of attachmentsTemp; let i = index;" class="pl-2 mb-1 d-flex align-items-center">
                    <i [class]="'fas fa-fw fa-lg ' + getFileIconClass(file.name) + ' blue mr-2'"></i>
                    {{file.name}} - {{fileSize(file.size)}}
                    <span class="btn ml-auto" (click)="removeAttachment(i)">×</span>
                  </li>
                </ul>
              </ng-container>
              <button class="btn brand-blue" (click)="fileInput.click()">
                <i class="fas fa-paperclip mr-1"></i> {{ 'pages.enquiry.label.add-attachments' | translate }}
              </button>
              <input #fileInput type="file" (change)="onFileInput($event)" class="hidden" ngDefaultControl multiple />
              <div class="desc mt-2">
                {{ 'pages.enquiry-form.help-text.allowed-files' | translate }}: {{ allowedFileExtPrint }}.
              </div>
              
            </div>
          </div>
          
        </div>
      </div>
    </form>
  </div>
  
</div>
