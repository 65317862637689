

<nav aria-label="Page navigation example col-auto" [ngClass]="{'left': isLeft, 'right': !isLeft}">
  <ul class="pagination">
    <li (click)="onFirstPageClick()">
      <p [ngClass]="(currentPageIndex <= 1)?'page-link inactive':'page-link'">|&lt;</p>
    </li>
    <li (click)="onPreviousPageClick()">
      <p [ngClass]="(currentPageIndex <= 1)?'page-link inactive':'page-link'">&lt;</p>
    </li>
    
    <li (click)="onCurPageClick()">
      <ng-container *ngIf="lastPageIndex==1"><p class="page-link active">{{currentPageIndex}}</p></ng-container>
      <ng-container *ngIf="lastPageIndex>1"><input type='text' class="page-link active" [attr.maxlength]="lastPageIndex.toString().length" size={{lastPageIndex.toString().length}} value="{{currentPageIndex}}" (keyup)="onCurPageKeyup($event)"/></ng-container>
    </li>
    <li >
      <p class="page-link noclick">/</p>
    </li>
    <li >
      <p class="page-link noclick">{{lastPageIndex}}</p>
    </li>

    <li (click)="onNextPageClick()">
      <p [ngClass]="(currentPageIndex >= lastPageIndex)?'page-link inactive':'page-link'">&gt;</p>
    </li>
    <li (click)="onLastPageClick()">
      <p [ngClass]="(currentPageIndex >= lastPageIndex)?'page-link inactive':'page-link'">&gt;|</p>
    </li>
  </ul>
</nav>
