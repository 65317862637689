<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [breadcrumbs]="breadcrumbs"
    (languageChanged)="onLanguageChanged()"
  ></app-nav>

  <app-sidebar></app-sidebar>

  <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
    <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
      (clickEvent)="receiveActionClick($event)">
    </app-action-sidebar>
  </ng-container>

  <!-- Page Content -->
  <div id="content" class="action-btn-padding">
    <div class="row mb-3">
      <div class="col-xl-6 col-12" *ngIf="hasManualHandlingNotification">
        <app-pending-manual-handling #pending_manual_handling [sn]="sn" (refreshsn)="requestSn()">
        </app-pending-manual-handling>
      </div>
    </div>
    <div *ngIf="sn?.ccsErrorMessage" class="alert-bar-wrap px-3 py-2 font-size-l px mb-3">
      {{ sn.ccsErrorMessage }}
    </div>
    <div class="row" *ngIf="sn">

      <div class="col-xl-6 col-12">

        <!-- Notification Information Component -->
        <app-notification-information-detail #notification_infomation_detail [sn]="sn" [currentPageMode]="currentPageMode" class="d-block mb-4">
        </app-notification-information-detail>

        <!-- Fault Information Component -->
        <app-fault-infomation-detail #fault_infomation_detail [sn]="sn" [currentPageMode]="currentPageMode" class="d-block mb-4"></app-fault-infomation-detail>

        <!-- HA Information Component -->
        <app-ha-infomation-detail #ha_infomation_detail [sn]="sn" [currentPageMode]="currentPageMode" *ngIf="isHaEquipmentOwner" class="d-block mb-4">
        </app-ha-infomation-detail>

      </div>

      <div class="col-xl-6 col-12">

        <!-- Notification Progress Component -->
        <app-notification-progress-detail #notification_progress_detail [sn]="sn" [currentPageMode]="currentPageMode"
          *ngIf="sn.jobCards && sn.jobCards.length > 0" class="d-block mb-4"></app-notification-progress-detail>

        <!-- Routing Detail Component -->
        <app-routing-detail #routing_detail [sn]="sn" [currentPageMode]="currentPageMode" *ngIf="sn.team" class="d-block mb-4">
        </app-routing-detail>

        <!-- Remark Component -->
        <app-remark-detail #remark_detail [sn]="sn" [currentPageMode]="currentPageMode" *ngIf="sn.remarks&&sn.remarks.length>0" class="d-block mb-4">
        </app-remark-detail>

        <!-- Attachment Component -->
        <app-attachment-detail #attachment_detail [sn]="sn" [page]="JMPage.SN" [currentPageMode]="currentPageMode" *ngIf="sn.attachmentList&&sn.attachmentList.length>0" class="d-block mb-4">
        </app-attachment-detail>

      </div>
    </div>
  </div>

  <custom-slider-panel #create_follow_up_job_card_panel [widthPx]=600 [fixedPanel]="createFollowUpJobCardPanelFixedPanel">
    <create-follow-up-job-card-form [parameters]="createFollowUpJobCardFormParam"></create-follow-up-job-card-form>
  </custom-slider-panel>

  <custom-slider-panel #cancel_sn_panel [widthPx]=600 [fixedPanel]="canelSnPanelFixedPanel">
    <cancel-sn-form
      [sn]="sn"
      [options]="cancelReasonOptions"
      [isLoading]="isCancelSnPanelLoading"
      (onSubmit)="requestCancelSn($event)"
    ></cancel-sn-form>
  </custom-slider-panel>

  <custom-slider-panel #reject_sn_panel [widthPx]=600 [fixedPanel]="rejectSnPanelFixedPanel">
    <reject-sn-form [options]="rejectReasonOptions" (onSubmit)="requestRejectSn($event)"
      [isLoading]="isRejectSnPanelLoading"></reject-sn-form>
  </custom-slider-panel>

  <custom-slider-panel #transfer_sn_panel [widthPx]=600 [fixedPanel]="transferSnPanelFixedPanel">
    <transfer-sn-form
      [options]="transferWorkCenterOptions"
      [isLoading]="isTransferSnPanelLoading"
      (onSubmit)="handleTransferSn($event)"
    ></transfer-sn-form>
  </custom-slider-panel>

  <custom-slider-panel #create_job_card_panel [widthPx]=600 [fixedPanel]="createJobcardPanelFixedPanel">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" id="show-all-post-checkbox" class="custom-control-input"
        [(ngModel)]="showAllPostForCreateJob" (change)="initJobCardCreationPanel(false, this.createJobCardFormParam.jobNature)"
        [disabled]="createJobCardFormParam.isLoading">
      <label for="show-all-post-checkbox" class="custom-control-label">
        {{'pages.sn.slider-panel.create-job-show-all-staff-checkbox' | translate}}
      </label>
    </div>
    <job-card-assign-staff-form [parameters]="createJobCardFormParam"></job-card-assign-staff-form>
  </custom-slider-panel>

  <custom-slider-panel #manual_instruction_pre_action_panel [widthPx]=600 [fixedPanel]="isManualInstructionPreActionPanelLoading">
    <manual-instruction-pre-action-form
      [groupInstructions]="manualInstructionPreActionFormData"
      [team]="manualInstructionPreActionFormTeam"
      [teamNextOperationTime]="manualInstructionPreActionFormTeamNextOperationTime"
      [handlingTeamNextOperationTime]="manualInstructionPreActionFormHandlingTeamNextOperationTime"
      [isLoading]="isManualInstructionPreActionPanelLoading"
      (onSubmit)="submitSnWithManualInstruction($event)"
    >
    </manual-instruction-pre-action-form>
  </custom-slider-panel>

  <custom-slider-panel #associate_sn_panel [widthPx]=600 [fixedPanel]="false">
    <app-associate-sn-panel
      [sn]="sn"
      [isInitComponent]="isAssociateSnPanelInit"
      (onClose)="closeSnAssociatePanel($event)"
    >
    </app-associate-sn-panel>
  </custom-slider-panel>

</div>