<div class="contract-form-wrap">
  <form>
    <div class="title-wrap row justify-content-between mx-0 mb-4">
      <h3 class="d-inline">{{ uiTitle }}</h3>
      <div class="button-wrap text-right">
        <app-submit-button 
          [id]="'dummy_contract_save_button'"
          [text]="'global.submit'"
          [buttonClass]="'d-block standard brand-amber mr-0'"
          [iconClass]="'fa-save'"
          [isDisabled]="!uiEnableSubmitButton"
          [isLoading]="!uiEnableSubmitButton"
          (onClick)="onSubmitButtonClicked($event)" 
        ></app-submit-button>
      </div>
    </div>

    <ng-container *ngIf="uiOfficerArray && uiOfficerArray.length > 0; else noTeamMember">
      <div class="search-result-container" (scroll)="onScroll($event)">
        <ul class="my-2 pl-0">
          <li class="ml-1 my-1 nobull">
            <div class="checkbox-wrap position-relative custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" 
                id="id_new_member_checkbox_select_all"
                class="checkbox custom-control-input"
                name="new_member_checkbox_select_all"
                [(ngModel)]="uiSelectedAll"
                (change)="onSelectAllClicked()"
              >
              <label class="custom-control-label" for="id_new_member_checkbox_select_all" id="id_new_member_label_select_all">
                <ng-container *ngIf="!uiSelectedAll">
                  {{ "add-team-member-form.select-all" | translate }}  
                </ng-container>
                <ng-container *ngIf="uiSelectedAll">
                  {{ "add-team-member-form.unselect-all" | translate }}  
                </ng-container>
              </label>
            </div>
          </li>
          
          <ng-container *ngFor="let row of uiOfficerArray;let i = index">
            <li class="ml-2 my-1 nobull ">      

              <div class="checkbox-wrap position-relative custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" 
                  id="id_new_member_checkbox_{{row.key}}"
                  class="checkbox custom-control-input"
                  name="new_member_checkbox_{{row.key}}"
                  [(ngModel)]="row.checked"
                  (change)="onCheckBoxClicked($event, i, row.key)"
                >
                <label class="custom-control-label" for="id_new_member_checkbox_{{row.key}}" id="id_new_member_label_{{row.key}}">
                  {{ row.label }}  
                </label>
              </div>

            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
    <ng-template #noTeamMember>
      <div>{{ "add-team-member-form.no-member-all" | translate }}</div>
    </ng-template>
  </form>
</div>

