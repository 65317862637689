import { Component, Injector, Input, OnInit } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { AppDelegate } from 'src/app/AppDelegate';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'job-card-assign-staff-form',
  templateUrl: './job-card-assign-staff-form.component.html',
  styleUrls: ['./job-card-assign-staff-form.component.scss']
})

export class JobCardAssignStaffFormComponent extends BasePage implements OnInit {
  @Input() parameters = {
    teamMembers: [],
    assignedPersons: [],
    officerInCharge: null,
    jobCardNumber: null,    // show time sheet use only
    viewOnly: false,
    isLoading: false,
    timeSheetInputedStaff: [],
    errorMsg: undefined,
    onSubmitClicked: (button)=>{},
    onAddMemberClicked: (button)=>{},
    onRemoveMemberClicked: (button)=>{},
    onOfficerInChargeChanged: (button)=>{},
  };

  // tablex parameters
  assignedStaffTable             : any = {};
  unassignedStaffTable           : any = {};

  // filter key
  assignedStaffTableFilterKey             : any = {};
  unassignedStaffTableFilterKey           : any = {};

  // ---
  assignedPersons: string[] = [];
  targetPerson: string = '';
  jobAssignedInfo: any = {};


  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnDestroy() {}

  ngOnChanges() {
    if (!this.parameters) return;
    if (!this.parameters.teamMembers) this.parameters.teamMembers = [];
    if (!this.parameters.assignedPersons) this.parameters.assignedPersons = [];
    if (!this.parameters.timeSheetInputedStaff) this.parameters.timeSheetInputedStaff = [];

    this.assignedPersons = [];
    this.parameters.assignedPersons.forEach(member => {
      this.assignedPersons.push(member);
    });
    this.initTables();
    
    // request
    this.requestPostAssignments();
    this.renderTables();
  }

  // init tablex parameters
  initTables() {
    this.initAssignedStaffTable();
    if (!this.parameters.viewOnly) this.initUnassignedStaffTable();
  }

  initAssignedStaffTable() {
    let tableWrapperClass = this.parameters.viewOnly ? "assigned-staff-long-table-wrapper" : "assigned-staff-table-wrapper";
    this.assignedStaffTable = {
      isLoadingTable        : false,
      tableWrapperClass     : tableWrapperClass,
      enableStickyHeader    : true,
      enableSetPageSize     : false,
      enablePagination      : false,
      onFilterKeyUp         : this.onAssignStaffTableFilterKeyUp,
      headers: [
        {id: "inCharge",    name: "job-card-assign-staff-form.in-charge",    type: TablexColumnType.Buttons, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
        {id: "staffPost",   name: "job-card-assign-staff-form.staff-post",   type: TablexColumnType.Text,    horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle, enableFilter: true},
        {id: "jobAssigned", name: "job-card-assign-staff-form.job-assigned", type: TablexColumnType.Text,    horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle, enableFilter: true},
      ],
      content: [],
    };

    // show time sheet inputed information column
    let timeSheetInputedColumn = {id: "timeSheetInputed",     
                                  name: this.translate("job-card-assign-staff-form.timesheet-inputted"),
                                  type: TablexColumnType.Text, 
                                  horizontalAlign: TablexColumnHorizontalAlign.Center, 
                                  verticalAlign: TablexColumnVerticalAlign.Middle};
    if (this.parameters.jobCardNumber) this.assignedStaffTable.headers.push(timeSheetInputedColumn);

    // show remove staff column
    let removeStaffColumn = {id: "remove",     
                            name: this.translate("global.remove"), 
                            type: TablexColumnType.Buttons, 
                            horizontalAlign: TablexColumnHorizontalAlign.Center, 
                            verticalAlign: TablexColumnVerticalAlign.Middle};
    if (!this.parameters.viewOnly) this.assignedStaffTable.headers.push(removeStaffColumn);
  }

  initUnassignedStaffTable() {
    this.unassignedStaffTable = {
      isLoadingTable        : false,
      tableWrapperClass     : "assigned-staff-table-wrapper",
      enableStickyHeader    : true,
      enableSetPageSize     : false,
      enablePagination      : false,
      onFilterKeyUp         : this.onUnassignStaffTableFilterKeyUp,
      headers: [
        {id: "staffPost",   name: "job-card-assign-staff-form.staff-post",              type: TablexColumnType.Text,    horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle, enableFilter: true},
        {id: "jobAssigned", name: "job-card-assign-staff-form.job-assigned",            type: TablexColumnType.Text,    horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle, enableFilter: true},
        {id: "add",         name: "job-card-assign-staff-form.job-assigned.add-button", type: TablexColumnType.Buttons, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
      ],
      content: [],
    };
  }

  // tablex render function
  renderTables() {
    this.renderAssignStaffTable();
    this.renderUnassignStaffTable();
  }

  renderAssignStaffTable() {
    let tableContent = this.assignedPersons.map((member) => {
      let css = "btn user-icon";
      css += (member == this.parameters.officerInCharge) ? "" : " unselected-user";

      let row =  [
        [{ "name": "", "class": css, "icon": "fas fa-user-circle", "onClicked": this.onClickOfficerInCharge, "member": member, "disable": this.parameters.viewOnly}],
        member,
        this.jobAssignedInfo[member],
      ];

      // show time sheet inputed information column
      if (this.parameters.jobCardNumber) 
        row.push(this.parameters.timeSheetInputedStaff.includes(member) ? "Y" : "N");

      // show remove staff column
      if (!this.parameters.viewOnly) 
        row.push([{ "name": "", "class": "glyph brand-red", "icon": "fas fa-times", "onClicked": this.onClickRemovePerson, "member": member}]);

      return row;
    });


    // filter key
    tableContent = tableContent.filter(value => {
      let staffPostKey = this.assignedStaffTableFilterKey["staffPost"];
      let jobAssignedKey = this.assignedStaffTableFilterKey["jobAssigned"];

      let includeStaffPostKey = staffPostKey ? value[1].toLowerCase().includes(staffPostKey.toLowerCase()) : true;
      let includerJobAssignedKey = jobAssignedKey ? value[2] == jobAssignedKey : true;
      return includeStaffPostKey && includerJobAssignedKey;
    });

    this.assignedStaffTable.content = tableContent;
  }

  renderUnassignStaffTable() {
    let members = this.parameters.teamMembers.filter(value => !this.assignedPersons.includes(value))
    let tableContent = members.map((member) => {
      return [
        member,
        this.jobAssignedInfo[member],
        [{ "name": "", "class": "glyph brand-blue", "icon": "fas fa-plus", "onClicked": this.onClickAddPerson, "member": member}],
      ]
    });

    // filter key
    tableContent = tableContent.filter(value => {
      let staffPostKey = this.unassignedStaffTableFilterKey["staffPost"];
      let jobAssignedKey = this.unassignedStaffTableFilterKey["jobAssigned"];

      let includeStaffPostKey = staffPostKey ? value[0].toLowerCase().includes(staffPostKey.toLowerCase()) : true;
      let includerJobAssignedKey = jobAssignedKey ? value[1] == jobAssignedKey : true;
      return includeStaffPostKey && includerJobAssignedKey;
    });

    this.unassignedStaffTable.content = tableContent;
  }

  removePerson(member) {
    if (member == this.parameters.officerInCharge) this.parameters.officerInCharge = null;
    this.assignedPersons =  this.assignedPersons.filter(value => value != member);
    
    this.renderTables();
    if (this.parameters.onRemoveMemberClicked) this.parameters.onRemoveMemberClicked(this.getCallbackData());
  }

  //===================================================================================================================
  // API request functions
  public async requestPostAssignments() {
    if (!this.parameters.teamMembers || this.parameters.teamMembers.length === 0) return;
    
    const request: JM.JMRequestJobCardsGetAssignment = new JM.JMRequestJobCardsGetAssignment();
    request.postNameList = this.parameters.teamMembers;

    const response: JM.JMResponseJobCardsGetAssignment = await AppDelegate.sendJMRequest(request);
    this.jobAssignedInfo = {};
    if (response.error) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    response.payload.forEach(item => {
      this.jobAssignedInfo[item.postName] = item.jobCount;
    });
    this.renderTables();
  }

  requestRemoveTimeSheet(request) {
    this.removePerson(request.member);
  }
  //===================================================================================================================

  getCallbackData() {
    return {targetPerson: this.targetPerson, assignedPersons: this.assignedPersons, officerInCharge: this.parameters.officerInCharge};
  }

  //===================================================================================================================
  // Event callback
  onClickOfficerInCharge = (button) => {
    this.targetPerson = button.member;

    if (this.targetPerson == this.parameters.officerInCharge) 
      this.parameters.officerInCharge = null;
    else
      this.parameters.officerInCharge = this.targetPerson;

    this.renderTables();
    if (this.parameters.onOfficerInChargeChanged) this.parameters.onOfficerInChargeChanged(this.getCallbackData());
  }

  onClickAddPerson = (button) => {
    this.targetPerson = button.member;

    if (!this.assignedPersons.includes(this.targetPerson)) 
      this.assignedPersons.push(this.targetPerson);

    this.renderTables();
    if (this.parameters.onAddMemberClicked) this.parameters.onAddMemberClicked(this.getCallbackData());
  }

  onClickRemovePerson = (button) => {
    this.targetPerson = button.member;

    if (this.targetPerson == this.parameters.officerInCharge) {
      this.openSnackBar(this.translate("job-card-assign-staff-form.cannot-remove-officer"));
      return;
    }

    if (!this.parameters.timeSheetInputedStaff.includes(this.targetPerson)) {
      this.removePerson(this.targetPerson);
      return;
    }

    let buttons = [
      {
        name: this.translate("action.button.popup.remove-the-staff"),
        handler: () => {
          this.requestRemoveTimeSheet({member: this.targetPerson});
        }
      },
      {name: this.translateService.instant("global.no")}
    ];

    this.showPopUpAlert(this.translate("job-card-assign-staff-form.confirm-remove-has-timesheet-staff"), "", buttons);
  }

  onAssignStaffTableFilterKeyUp = (index, header, newValue) => {
    if (newValue == "") newValue = null;
    this.assignedStaffTableFilterKey[header.id] = newValue;
    this.renderAssignStaffTable();
  }

  onUnassignStaffTableFilterKeyUp = (index, header, newValue) => {
    if (newValue == "") newValue = null;
    this.unassignedStaffTableFilterKey[header.id] = newValue;
    this.renderUnassignStaffTable();
  }

  onClickSubmit() {
    if (this.parameters.onSubmitClicked) this.parameters.onSubmitClicked(this.getCallbackData());
  }
}
