<div class="row">
    <div class="col-7">
        <h3>{{'conponent.manual-instruction.title' | translate}}</h3>
    </div>
    <div class="col-5 ml-auto">
        <div class="col d-flex justify-content-end mx-3 mb-3">
            <app-submit-button 
            [id]="'manual-instruction_view-working-hours_button'"
            [text]="'pages.team-settings.view-office-hours'"
            [buttonClass]="'standard float-right'"
            [isDisabled]="isLoadingTeamSummary"
            [isLoading]="isLoadingTeamSummary"
            (onClick)="onOfficeHoursBtnClicked()" 
            ></app-submit-button>
        </div>
    </div>
</div>

<div class="manual-instruction-sub-title">
    {{'conponent.manual-instruction.working-hours' | translate}}
</div>
<ng-container *ngFor="let workingHourGroup of workingHourData.workingHourGroups;let index = index">
    <div 
        class="group-instructions-container row" 
        [style.background]="workingHourGroup.isGroup ? workingHourGroup.groupBackgroundColor : '#FFF'">
        <div class="col-1 font-weight-bold">
            {{ workingHourGroup.groupText }}
        </div>
        <div class="col-11">
            <ng-container *ngFor="let text of workingHourGroup.displayTextList">
                <div> {{ text }} </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<div class="manual-instruction-sub-title">
    {{'conponent.manual-instruction.non-working-hours' | translate}}
</div>
<ng-container *ngFor="let workingHourGroup of workingHourData.nonWorkingHourGroups;let index = index">
    <div 
        class="group-instructions-container row" 
        [style.background]="workingHourGroup.isGroup ? workingHourGroup.groupBackgroundColor : '#FFF'">
        <div class="col-1 font-weight-bold">
            {{ workingHourGroup.groupText }}
        </div>
        <div class="col-11">
            <ng-container *ngFor="let text of workingHourGroup.displayTextList">
                <div> {{ text }} </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<textarea 
    maxlength="{{ getMaxRemarksLength }}" 
    class="manual-instruction-remark" 
    type="text" 
    rows="5" 
    placeholder="*{{'pages.sn.remark' | translate}}" 
    [(ngModel)]="remark" 
    [disabled]="isLoading"
>
</textarea>
<span 
    *ngIf="remark?.length >= getMaxRemarksLength" 
    class="red small"
>
    {{ "textarea.warning.max-length" | translate:[getMaxRemarksLength]}}
</span>
<div>*{{'pages.sn.mandatory' | translate }}</div>
<div class="manual-instruction-submit">
    <button class="standard brand-amber" (click)="onClickSubmit()" [disabled]="isLoading">
        <span *ngIf="isLoading">
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
        </span>
        <span *ngIf="!isLoading"><i class="fas fa-check space"></i>{{'global.submit' | translate}}</span>
    </button>
</div>