/*
  version 20201123:
   - popupDialog appear after slider panel submit
*/

import { Input } from '@angular/core';
import { Injector } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ParameterService } from '@services/parameter.service';
import { BasePage } from '../../model/base/base';

@Component({
  selector: 'app-un-teco-reason',
  templateUrl: './un-teco-reason.component.html',
  styleUrls: ['./un-teco-reason.component.scss']
})
export class UnTecoReasonComponent extends BasePage implements OnInit {

  @Input() selectedCount: number = 0;

  @Input()  unTecoParam = {
    unTecoReasonCode:undefined,
    unTecoRemarks:undefined,
    selectedCount:0,
    isPanelSubmitLoading:false,
    isAcknowledgeAction:false,
    popUpSubMessage: ""
  };

  @Output() onSubmit = new EventEmitter<any>();

  unTecoReasonOption = [];
  vaildationCheck: boolean = true;
  errorFields = {
    unTecoReasonCode: false
  };

  constructor(injector: Injector, private parameterService: ParameterService) { super(injector); }

  ngOnInit() {
    this.initUnTecoReason();
  }

  private initUnTecoReason() {
    this.unTecoReasonOption = [];
    for (let code of this.parameterService.allUnTecoReasonCode()) {
      this.unTecoReasonOption.push({
        value: code,
        label: this.translate("pages.un-teco.slider-panel.un-teco-reason." + code),
      });
    }
  }

  onLangChange() {
    this.initUnTecoReason();
  }

  public onSubmitButtonClicked(event) {
    // validate
    this.vaildation();
    if (this.vaildationCheck) {
      let onSubmitClicked =  () => {
        const message = this.translate("action.button.popup.un-teco");
        const buttons = [
          { name: this.translate("global.yes"), handler: () => { this.onSubmit.emit({ unTecoReasonCode: this.unTecoParam.unTecoReasonCode, unTecoRemarks: this.unTecoParam.unTecoRemarks }); } },
          { name: this.translate('global.no'), handler: () => {  } }
        ];
        this.showPopUpAlert(message, this.unTecoParam.popUpSubMessage, buttons);
      }
      onSubmitClicked();
    }
  }

  vaildation() {
    if (this.unTecoParam.unTecoReasonCode) {
      this.vaildationCheck = true;
      this.errorFields = { unTecoReasonCode: false };
    }
    else {
      this.errorFields = {
        unTecoReasonCode: !this.unTecoParam.unTecoReasonCode,
      }
      this.vaildationCheck = false;
    }
  }

  get getMaxRemarksLength():number{
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }

}
