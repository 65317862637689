<div class="wrapper">
    <app-nav
      [showActionSidebarIcon]="true"
      [navbarTitle]="pageTitle"
    ></app-nav>
    <app-sidebar></app-sidebar>
    <div id="content">
            <h2 class="title">{{ 'component.upload-inspection-forms.title' | translate }}
                <a routerLink="{{uploadHistoryRouting}}"><i class="fa fa-history blue"></i></a>
            </h2>
            <div class="row col-9">
                <!-- Left Hand Side -->
                <div class="mt-3 col-md-6">
                    <div class="d-flex form-group custom-group">
                        <label for="form-item--contractNumber" class="justify-content-end d-flex align-items-center mb-0 col-md-6">
                            <span class="required"><span class="red pr-1">*</span>{{ 'component.upload-inspection-forms.contract' | translate }}</span>
                        </label>
                        <div class="col-md-6 dropdown-group">
                        <ng-select name="form-item--contractNumber" name="contractNumber" class="px-0" placeholder="{{'components.search-criteria.contract-number' | translate }}"
                            [items]="contractNumberOptions" (open)="clearContractNumberFilter()"
                            (change)="checkIsDisabled();"
                            (search)="onContractNumberSearch($event)" (clear)="clearContractNumberFilter()" [loading]="isLoadingContractNumber"
                            (scrollToEnd)="onContractNumberScrollToEnd()" [(ngModel)]="contractInspectionForm.contractNumber">
                        </ng-select>
                        </div>
                    </div>
                    <div class="d-flex form-group custom-group">
                        <label for="form-item--inspectorName" class="justify-content-end d-flex align-items-center mb-0 col-md-6">
                            <span class="required"><span class="red pr-1">*</span>{{ 'component.upload-inspection-forms.inspector-name' | translate }}</span>
                        </label>
                        <div class="col-md-6 dropdown-group">
                        <input 
                            id="form-item--inspectorName"
                            class="form-control" 
                            type="text"
                            maxlength="35"
                            placeholder="{{'component.upload-inspection-forms.inspector-name' | translate }}"
                            [(ngModel)]="contractInspectionForm.inspectorName"
                            (ngModelChange)="checkIsDisabled();"
                            required
                          />
                        </div>
                    </div>
                    <div class="d-flex form-group custom-group">
                        <label for="form-item--inspectionResult" class="justify-content-end d-flex align-items-center mb-0 col-md-6">
                            <span class="required"><span class="red pr-1">*</span>{{ 'component.upload-inspection-forms.inspection-result' | translate }}</span>
                        </label>
                        <div class="col-md-6 dropdown-group">
                        <ng-select id="form-item--inspectionResult" name="inspectionResult" class="px-0" placeholder="{{'component.upload-inspection-forms.inspection-result' | translate }}"
                            [items]="inspectionResultOptions" 
                            bindLabel="description" 
                            bindValue="value" 
                            (change)="checkIsDisabled();"
                            [searchable]="false"
                            [(ngModel)]="contractInspectionForm.inspectionResult">
                        </ng-select>
                        </div>
                    </div>
                    <div class="d-flex form-group custom-group">
                        <span class="col-6 font-size-s d-flex align-items-center justify-content-end"><span class="red pr-1">*</span>{{
                            "component.inspection.job.inspection.inspectionTime.title" | translate }}</span>
                        <app-date-input class="pl-0 col-4 d-inline-block " [input]="contractInspectionForm.inspectionDate"
                            [inputId]="dateInputSet['inspectionDate']" [field]="'inspectionDate'" (onBlur)="onBlurDateInput($event);checkIsDisabled();"
                            [dateAlertMsg]="dateAlertMsg" [isError]="errorFields?.inspectionDate">
                        </app-date-input>
                        <app-time-input class="col-2 p-0" [isError]="errorFields?.inspectionTime" [customClass]="'form-control'"
                            [input]="timeInputSet['inspectionTime']" [inputId]="'inspectionTimeInput'" [field]="'inspectionTime'"
                            [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event);checkIsDisabled();">
                        </app-time-input>
                    </div>
                </div>
                <!-- Right Hand Side -->
                <div class="mt-3 col-md-6">
                    <div class="d-flex form-group custom-group">
                        <label for="form-item--maintenanceType" class="justify-content-end d-flex align-items-center mb-0 col-md-6">
                            <span class="required"><span class="red pr-1">*</span>{{ 'component.upload-inspection-forms.maintenance-type' | translate }}</span>
                        </label>
                        <div class="col-md-6 dropdown-group">
                        <ng-select id="form-item--maintenanceType" name="maintenanceType" class="px-0" placeholder="{{'component.upload-inspection-forms.maintenance-type' | translate }}"
                            [items]="maintenanceTypeOptions" 
                            bindLabel="description" 
                            bindValue="value" 
                            (change)="checkIsDisabled();"
                            [searchable]="false"
                            [(ngModel)]="contractInspectionForm.maintenanceType">
                        </ng-select>
                        </div>
                    </div>
                    <div class="d-flex form-group custom-group">
                        <label for="form-item--inspectorPost" class="justify-content-end d-flex align-items-center mb-0 col-md-6">
                            <span class="required"><span class="red pr-1">*</span>{{ 'component.upload-inspection-forms.inspector-post' | translate }}</span>
                        </label>
                        <div class="col-md-6 dropdown-group">
                        <input 
                            id="form-item--inspectorPost"
                            class="form-control" 
                            type="text"
                            maxlength="35"
                            placeholder="{{'component.upload-inspection-forms.inspector-post' | translate }}"
                            [(ngModel)]="contractInspectionForm.inspectorPost"
                            (ngModelChange)="checkIsDisabled();"
                            required
                          />
                        </div>
                    </div>
                    <div class="d-flex form-group custom-group">
                        <label for="form-item--modeOfCheck" class="justify-content-end d-flex align-items-center mb-0 col-md-6">
                            <span class="required"><span class="red pr-1">*</span>{{ 'component.upload-inspection-forms.mode-of-check' | translate }}</span>
                        </label>
                        <div class="col-md-6 dropdown-group">
                        <ng-select id="form-item--modeOfCheck" name="modeOfCheck" class="px-0" placeholder="{{'component.upload-inspection-forms.mode-of-check' | translate }}"
                            [items]="modeOfCheckOptions" 
                            bindLabel="description" 
                            bindValue="value" 
                            (change)="checkIsDisabled();"
                            [searchable]="false"
                            [(ngModel)]="contractInspectionForm.modeOfCheck">
                        </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2">{{ 'component.upload-inspection-forms.upload.instruction' | translate }}</div>
            <div class="my-2 w-40">
                <app-file-selector #fileSelector [validator]="isFileValid" [fileAcceptType]="'.pdf'" (updatedFile)="onUpdatedFile($event);checkIsDisabled();" [isDisabled]="isDisableUploadBtn"></app-file-selector>
            </div>
            <app-submit-button 
                [id]="'inspection-report_upload_button'"
                [text]="'global.upload'" [buttonClass]="'standard brand-amber'"
                [iconClass]="'fas fa-upload space'" [isDisabled]="uploadIsLoading || isDisabled"
                [isLoading]="uploadIsLoading" (onClick)="onClickedUploadButton()" 
            >
            </app-submit-button>
    </div>
</div>