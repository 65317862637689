<div class="wrapper" >
    <app-nav [showActionSidebarIcon]="true"></app-nav>

    <app-sidebar></app-sidebar>
    <ng-container *ngIf="actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar" (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <!-- Page Content -->
    <div id="content">
        <form>
            <div *ngIf="snView.snNumber">
                <div class="row justify-content-between">
                    <div class="col-auto align-self-center title-group">
                        <span class="title">{{'pages.sn.basic-information' | translate}}</span>
                        <span id="view-sn_maintenance-type_text" class="maintenance-type my-2 mx-1 p-1" *ngIf="snView.maintenanceType">{{snView.maintenanceType}} </span>
                        <span id="view-sn_party-type_text" class="party-type mx-1 p-1" *ngIf="snView.handlingParty">{{'team.handling-party.' + snView.handlingParty | translate}}</span>
                    </div>
                </div>
                <hr class="underline" />
                <div class="row data-row">
                    <div class="col-lg-2 label-name">{{'pages.sn.sn-no' | translate}}</div>
                    <div class="col-lg-4 text-content" id="view-sn_sn-no_text">{{snView.snNumber}}</div>
                    <div class="col-lg-2 label-name">{{'pages.sn.status' | translate}}</div>
                    <div class="col-lg-4 text-content" id="view-sn_sn-status_text">{{snView.status | snStatus:lang}}</div>
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-auto align-self-center">
                    <h2 class="title">{{'pages.sn.client-and-location' | translate}}</h2>
                </div>
                <div class="col-auto align-self-center fields-notation">
                    <span class="red">*</span> {{'pages.sn.mandatory-fields' | translate}}<br>
                    <span class="red">^</span> {{'pages.sn.for-routing-purpose' | translate}}<br>
                    <span *ngIf="isVVIP_SRAEnable" class="d-inline-flex align-items-center"><app-special-request-alert-icon></app-special-request-alert-icon>&nbsp;{{ 'special-request-alert' | translate}}</span>
                </div>
            </div>
            <hr class="underline" />
            <div class="form-row save-client-info ml-1">
                <div class="form-group custom-group col-md-12">
                    <div class="checkbox-wrap position-relative custom-control custom-checkbox mt-3 d-inline-block">
                        <input type="checkbox"
                            id="create-sn_save-client-info_checkbox"
                            class="checkbox custom-control-input"
                            (change)="onInputUpdated(false)"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="snView.saveClientInfo"
                            [disabled]="disableSaveClientDetail">
                        <label class="custom-control-label" for="create-sn_save-client-info_checkbox">{{'pages.sn.save-client-detail' | translate}}</label>
                    </div>

                    <div class="checkbox-wrap position-relative custom-control custom-checkbox mt-3 d-inline-block ml-2">
                        <input type="checkbox"
                            id="unlock-client-location-equipment_checkbox"
                            class="checkbox custom-control-input"
                            (change)="onUnlockClientLocationEquipmentChanged($event)"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="snView.unlockClientLocationEquipment"
                            [disabled]="disableEditTeam">
                        <label class="custom-control-label" for="unlock-client-location-equipment_checkbox">{{'pages.sn.unlock-client-location-equipment' | translate}}</label>
                    </div>
                    <div *ngIf="this.crmExternalRefId" class="position-relative mt-3 d-inline-block ml-2 float-right">
                        <b>{{ "pages.sn.rexternal-ref-number" | translate}}</b> {{ crmExternalRefId }}
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6">
                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_client_dropdown"><span class="red">^</span>
                            {{'pages.sn.client' | translate}}</label>
                        <div class="col-md-8 dropdwon-group">
                            <ng-select id="create-sn_client_dropdown"
                                [items]="clientsOptions"
                                bindLabel="label"
                                bindValue="value"
                                (open)="onOpenedClient()"
                                (change)="onRuleFilterUpdated()"
                                [loading]="isClientDropdownLoading"
                                [(ngModel)]="snView.client"
                                [disabled]="disableEditTeam || hasHaEquipmentNumber"
                                [ngModelOptions]="{standalone: true}"
                                (clear)="onClearFilterField()"
                                [ngClass]="{'input-error':errorFields.Client}"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_contact-person_inputbox"><span class="red">*</span>
                            {{'pages.sn.contact-person' | translate}}</label>
                        <input type="text" class="form-control col-md-8"
                            id="create-sn_contact-person_inputbox"
                            maxlength="35"
                            required=""
                            (change)="onInputUpdated(false)"
                            [(ngModel)]="snView.contactPerson"
                            [disabled]="disableEditContactDetails"
                            [ngModelOptions]="{standalone: true}"
                            [ngClass]="{'input-error':errorFields.ContactPerson}"/>
                    </div>
                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_contact-number-1_inputbox"><span class="red">*</span>
                            {{'pages.sn.contact-number' | translate}}</label>
                        <div class="input-group col-md-8 contact-number-group" [ngClass]="{'input-error':errorFields.ContactNumber}">
                            <mat-form-field class="form-control col-md-12 contact-number-input-text" [class.disabled]="disableEditContactDetails">
                                <input matInput
                                    id="create-sn_contact-number-1_inputbox"
                                    maxlength="8"
                                    required=""
                                    type="text"
                                    (change)="onInputUpdated(false)"
                                    (blur)="inputNumberWithIndex($event,0);"
                                    (keyup)="inputNumberWithIndex($event,0);"
                                    [matAutocomplete]="auto"
                                    [matAutocompleteDisabled]="disableEditTeam"
                                    [formControl]="formControl"
                                    [readonly]="disableEditContactDetails"
                                    [(ngModel)]="snView.contactNumber[0]"
                                />
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onContactNumberChanged($event.option.value)" >
                                    <mat-option *ngFor="let option of contactNumberFilteredOptions | async" [value]="option">{{option}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <!-- <div class="flex-grow-1">
                                <ng-select id="create-sn_contact-number-1_inputbox"
                                    (change)="onContactNumberChanged($event)"
                                    [addTag]="true"
                                    [items]="contactNumberOptions"
                                    [(ngModel)]="snView.contactNumber[0]"
                                    [ngModelOptions]="{standalone: true}"
                                    [ngClass]="{'input-error':errorFields.ContactNumber}"
                                >
                                </ng-select>
                            </div> -->
                            <div>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-primary btn-contact-number"
                                        id="create-sn_add-contact-number_button"
                                        [disabled]="disableEditContactDetails"
                                        type="button" (click)="onAddContactNumberButtonClicked()">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group custom-group" *ngIf="this.snView.contactNumber.length > 1">
                        <div class="input-group col-md-8 offset-md-4 contact-number-group" [ngClass]="{'input-error':errorFields.ContactNumber}">
                            <input type="text"
                                class="form-control col-md-12 contact-number-input-text"
                                id="create-sn_contact-number-2_inputbox" maxlength="8"
                                required=""
                                (blur)="inputNumberWithIndex($event,1)"
                                (keyup)="inputNumberWithIndex($event,1)"
                                (change)="onInputUpdated(false)"
                                [disabled]="disableEditContactDetails"
                                [(ngModel)]="snView.contactNumber[1]"
                                [ngModelOptions]="{standalone: true}"/>
                            <div class="input-group-append">
                                <button class="btn btn-outline-primary btn-contact-number"
                                    [disabled]="disableEditContactDetails" id="create-sn_remove-contact-number-2_button"
                                    type="button" (click)="onHideContactNumberButtonClicked(1)">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group custom-group" *ngIf="this.snView.contactNumber.length > 2">
                        <div class="input-group col-md-8 offset-md-4 contact-number-group" [ngClass]="{'input-error':errorFields.ContactNumber}">
                            <input type="text" class="form-control col-md-12 contact-number-input-text"
                                id="create-sn_contact-number-3_inputbox"
                                maxlength="8"
                                required=""
                                (blur)="inputNumberWithIndex($event,2)"
                                (keyup)="inputNumberWithIndex($event,2)"
                                (change)="onInputUpdated(false)"
                                [disabled]="disableEditContactDetails"
                                [(ngModel)]="snView.contactNumber[2]"
                                [ngModelOptions]="{standalone: true}"/>
                            <div class="input-group-append">
                                <button class="btn btn-outline-primary btn-contact-number"
                                    [disabled]="disableEditContactDetails"
                                    id="create-sn_remove-contact-number-3_button"
                                    type="button" (click)="onHideContactNumberButtonClicked(2)">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_email_inputbox">
                            {{'pages.sn.email-address' | translate}}</label>
                        <input type="text"
                            class="form-control col-md-8"
                            id="create-sn_email_inputbox"
                            (change)="onInputUpdated(false)"
                            [ngClass]="{'input-error':errorFields.Email}"
                            [(ngModel)]="snView.email"
                            [disabled]="disableEditContactDetails"
                            [ngModelOptions]="{standalone: true}"
                            maxlength="50" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_district_dropdown"><span class="red">^</span>
                            {{'pages.sn.district' | translate}}</label>
                        <div class="col-md-8 dropdwon-group">
                            <ng-select id="create-sn_district_dropdown"
                                [items]="districtsOptions"
                                bindLabel="label"
                                bindValue="value"
                                (open)="onOpenedDistrict()"
                                (change)="onRuleFilterUpdated()"
                                (clear)="onClearDistrict()"
                                [loading]="isDistrictDropdownLoading"
                                [(ngModel)]="snView.district"
                                [disabled]="disableEditTeam || hasHaEquipmentNumber"
                                [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'input-error':errorFields.District}"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_location_dropdown"><span class="red">^</span>
                            {{'pages.sn.location' | translate}}</label>
                        <div class="col-md-8 dropdwon-group">
                            <ng-select id="create-sn_location_dropdown" class="location-dropdown"
                                [items]="locationsOptions"
                                bindLabel="label"
                                bindValue="value"
                                (open)="onOpenedLocation()"
                                (close)="onClosedLocation()"
                                (change)="onLocaitonChanged()"
                                (ngModelChange)="onLocaitonChange()"
                                (clear)="onClearLocation()"
                                (scrollToEnd)="onLocationScrollToEnd()"
                                [loading]="isLocationDropdownLoading"
                                [(ngModel)]="snView.location"
                                [disabled]="disableEditTeam || disableLocationField || hasHaEquipmentNumber"
                                [ngClass]="{'input-error':errorFields.Location}"
                                [ngModelOptions]="{standalone: true}"
                                (search)="onSearchLocationOptions($event)"
                                [searchFn]="onFilterLocationOptions"
                                
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <div style="white-space: normal" title="{{item.label}}">{{item.label}} <app-special-request-alert-icon *ngIf="item.isSpecialRequestAlert"></app-special-request-alert-icon></div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="location-dropdown-item" title="{{item.label}}">{{item.label}} <app-special-request-alert-icon *ngIf="item.isSpecialRequestAlert"></app-special-request-alert-icon></div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div *ngIf="snView.location && locationFehdVenueMapping[snView.location] && locationFehdVenueMapping[snView.location].length" class="form-group custom-group">
                        <div class="col-md-4 radio-text"> {{'pages.sn.fehd-venue' | translate}}
                        </div>
                        <div class="col-md-8 radio-group">
                            <div class="btn-group btn-group-toggle priority-toggle-button" data-toggle="buttons"
                                [ngClass]="{'disabled': disableEditTeam || disableLocationField || hasHaEquipmentNumber}">
                                <button *ngIf="snView.location && locationFehdVenueMapping[snView.location].includes(FehdVenue.TOILET)"
                                    id="create-sn_fehd-venue-toilet_button" class="btn btn-priority urgent"
                                    (click)="onFehdVenueChanged(FehdVenue.TOILET)"
                                    [ngClass]="{'disabled': disableEditTeam || disableLocationField || hasHaEquipmentNumber,'active':this.snView.fehdVenue == FehdVenue.TOILET}"
                                    [disabled]="disableEditTeam || disableLocationField || hasHaEquipmentNumber">
                                    <input type="radio" name="fehd-venue-options" value="FehdVenue.TOILET" [(ngModel)]="snView.fehdVenue">
                                    {{'fehd-venue.toilet' | translate}}
                                </button>
                                <button *ngIf="snView.location && locationFehdVenueMapping[snView.location].includes(FehdVenue.MARKET)"
                                    id="create-sn_fehd-venue-toilet_button" class="btn btn-priority non-urgent"
                                    (click)="onFehdVenueChanged(FehdVenue.MARKET)"
                                    [ngClass]="{'disabled': disableEditTeam || disableLocationField || hasHaEquipmentNumber,'active':this.snView.fehdVenue == FehdVenue.MARKET}"
                                    [disabled]="disableEditTeam || disableLocationField || hasHaEquipmentNumber">
                                    <input type="radio" name="fehd-venue-options" value="FehdVenue.MARKET" [(ngModel)]="snView.fehdVenue">
                                    {{'fehd-venue.market' | translate}}
                                </button>
                                <button id="create-sn_fehd-venue-unclassified_button" class="btn btn-priority emergency"
                                    (click)="onFehdVenueChanged(null)"
                                    [ngClass]="{'disabled': disableEditTeam || disableLocationField || hasHaEquipmentNumber,'active':!this.snView.fehdVenue}"
                                    [disabled]="disableEditTeam || disableLocationField || hasHaEquipmentNumber">
                                    <input type="radio" name="fehd-venue-options" value="null" [(ngModel)]="snView.fehdVenue">
                                    {{'pages.sn.unclassified' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_location-details_inputbox">
                            {{'pages.sn.location-details' | translate}}</label>
                        <textarea maxlength="{{ constants.TEXTAREA_MAX_LENGTH }}"
                            type="text"
                            class="form-control col-md-8"
                            id="create-sn_location-details_inputbox"
                            (change)="onInputUpdated(false)"
                            [disabled]="disableEditLocationDetails"
                            [(ngModel)]="snView.locationDetail"
                            [ngModelOptions]="{standalone: true}" rows="3" ></textarea>
                    </div>
                </div>
            </div>

            <div class="row justify-content-between">
                <div class="col-auto align-self-center">
                    <h1 class="title">{{'pages.sn.fault-details' | translate}}</h1>
                </div>
            </div>
            <hr class="underline" />
            <div class="form-row">
                <div class="col-md-6">
                    <div class="form-group custom-group">
                        <div class="col-md-4 radio-text"><span class="red">^</span> {{'pages.sn.priority' | translate}}
                        </div>
                        <div class="col-md-8 radio-group">
                            <div class="btn-group btn-group-toggle priority-toggle-button" data-toggle="buttons"
                                [ngClass]="{'disabled': disableEditTeam || disablePriority || hasHaEquipmentNumber}">
                                <button id="create-sn_priority-non-urgent_button"
                                    class="btn btn-priority non-urgent"
                                    (click)="onPriorityChanged(3)"
                                    [ngClass]="{'disabled': disableEditTeam || disablePriority || hasHaEquipmentNumber,'active':this.snView.priority == 3}"
                                    [disabled]="disableEditTeam || disablePriority || hasHaEquipmentNumber">
                                    <input type="radio" name="priority-radio-options" value="3"
                                        [(ngModel)]="this.snView.priority" checked>
                                    {{'pages.sn.non-urgent' | translate}}
                                </button>
                                <button id="create-sn_priority-urgent_button" class="btn btn-priority urgent"
                                    (click)="onPriorityChanged(2)"
                                    [ngClass]="{'disabled': disableEditTeam || disablePriority || hasHaEquipmentNumber,'active':this.snView.priority == 2}"
                                    [disabled]="disableEditTeam || disablePriority || hasHaEquipmentNumber">
                                    <input type="radio" name="priority-radio-options" value="2"
                                        [(ngModel)]="this.snView.priority">
                                    {{'pages.sn.urgent' | translate}}
                                </button>
                                <button id="create-sn_priority-emergency_button" class="btn btn-priority emergency"
                                    (click)="onPriorityChanged(1)"
                                    [ngClass]="{'disabled': disableEditTeam || disablePriority || hasHaEquipmentNumber,'active':this.snView.priority == 1}"
                                    [disabled]="disableEditTeam || disablePriority || hasHaEquipmentNumber">
                                    <input type="radio" name="priority-radio-options" value="1"
                                        [(ngModel)]="this.snView.priority">
                                    {{'pages.sn.emergency' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- HA equipment number -->
                    <div class="form-group custom-group position-relative calendar-input-wrap">
                        <label class="col-md-4" for="create-sn_ha-equipment-number_dropdown"><span class="red">^</span>
                            {{'pages.sn.ha-equipment-number' | translate}}</label>
                        <!-- <span>
                            <input type="text"
                                class="form-control col-md-8"
                                id="create-sn_ha-equipment-number_inputbox"
                                [(ngModel)]="snView.haEquipmentNumber"
                                [ngModelOptions]="{standalone: true}"
                                (click)="onClickHaEquipmentNo()"
                                [ngClass]="{'input-error':errorFields.HaEquipmentNumber}"
                                [disabled]="disableEditTeam || disableHaEquipmentNumber"
                                maxlength="0" />
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </span> -->

                        <!-- <input type="text" id="create-time-from-inputbox" class="form-control date-picker-input-box"
                        placeholder="From" name="createTimeFromPicker" #c2="ngModel"
                        [(ngModel)]="filterCriteria.createTimeFrom" ngbDatepicker #createTimeFromPicker="ngbDatepicker"
                        autocomplete="off" [readonly]="true" (click)="createTimeFromPicker.toggle()"
                        [maxDate]="filterCriteria.createTimeTo"> -->
                        <input type="text"
                                class="form-control col-md-8 date-picker-input-box"
                                id="create-sn_ha-equipment-number_inputbox"
                                [(ngModel)]="snView.haEquipmentNumber"
                                [ngModelOptions]="{standalone: true}"
                                (click)="onClickHaEquipmentNo()"
                                [ngClass]="{'input-error':errorFields.HaEquipmentNumber}"
                                [disabled]="disableEditTeam || disableHaEquipmentNumber"
                                maxlength="0" READONLY />
                        <button class="btn calendar" (click)="onClearHaEquipmentNumber()" type="button" [ngClass]="{'d-none':!snView.haEquipmentNumber||(disableEditTeam || disableHaEquipmentNumber)}">
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                    </div>



                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_equipment-category_dropdown"><span class="red">^</span>
                            {{'pages.sn.equipment-category' | translate}}</label>
                        <div class="col-md-8 dropdwon-group">
                            <ng-select id="create-sn_equipment-category_dropdown"
                                [items]="equipmentCategoriesOptions"
                                bindLabel="label"
                                bindValue="value"
                                (open)="onOpenedEquipmentCategory()"
                                (change)="onRuleFilterUpdated()"
                                (clear)="onClearFilterField()"
                                [loading]="isEquipmentCategoryDropdownLoading"
                                [(ngModel)]="snView.equipmentCategory"
                                [disabled]="disableEditTeam || hasHaEquipmentNumber"
                                [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'input-error':errorFields.EquipmentCategory}"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_equipment-type_dropdown"><span class="red">^</span>
                            {{'pages.sn.equipment-type' | translate}}</label>
                        <div class="col-md-8 dropdwon-group">
                            <ng-select id="create-sn_equipment-type_dropdown"
                                [items]="equipmentTypesOptions"
                                bindLabel="label"
                                bindValue="value"
                                (open)="onOpenedEquipmentType()"
                                (change)="onRuleFilterUpdated()"
                                (clear)="onClearFilterField()"
                                [loading]="isEquipmentTypeDropdownLoading"
                                [(ngModel)]="snView.equipmentType"
                                [disabled]="disableEditTeam || hasHaEquipmentNumber"
                                [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'input-error':errorFields.EquipmentType}">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group custom-group">
                        <label class="col-md-4" for="create-sn_fault-details_inputbox"><span class="red">*</span>
                            {{'pages.sn.fault-details-by-client' | translate}}</label>
                        <textarea maxlength="{{ constants.TEXTAREA_MAX_LENGTH }}" type="text"
                            class="form-control col-md-8" id="create-sn_fault-details_inputbox"
                            (change)="onInputUpdated(false)" [(ngModel)]="snView.faultDetail"
                            [disabled]="disableEditFaultDetails" [ngModelOptions]="{standalone: true}" rows="8"
                            [ngClass]="{'input-error':errorFields.FaultDetails}">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6">
                    <div class="form-group custom-group internal-remarks-group">
                        <label class="col-md-4" for="create-sn_internal-remarks_inputbox">
                            {{'pages.sn.internal-remarks' | translate}}</label>
                        <textarea maxlength="{{ constants.TEXTAREA_MAX_LENGTH }}" type="text"
                            class="form-control col-md-8" id="create-sn_internal-remarks_inputbox"
                            (change)="onInputUpdated(false)" [(ngModel)]="snView.internalRemarks"
                            [disabled]="disableEditInternalRemarks" [ngModelOptions]="{standalone: true}" rows="3">
                        </textarea>
                    </div>
                </div>
                <div class="col-md-6">              
                    <div class="form-group custom-group remark-group">
                        <label class="col-md-4 remark-label" for="create-sn_remarks_inputbox">{{'pages.sn.remarks' |
                            translate}}</label>
                        <div class="col-md-8 d-inline-block p-0">
                            <div class="visibility-type" [ngClass]="{'no-show': !disableRemarks}">
                                <div [ngClass]="{'selected-visibility': snView.remarkVisibility  == 'invisible'}">
                                    <label *ngIf="showRemarkVisibilityType.invisible"
                                        [ngClass]="{'clickable': !disableRemarks}"
                                        (click)="onRemarkVisibilityChanged('invisible')">{{'pages.sn.remark-visibility-invisible'
                                        | translate}}</label>
                                </div>
                                <div [ngClass]="{'selected-visibility': snView.remarkVisibility  == 'private'}">
                                    <label *ngIf="showRemarkVisibilityType.private"
                                        [ngClass]="{'clickable': !disableRemarks}"
                                        (click)="onRemarkVisibilityChanged('private')">{{'pages.sn.remark-visibility-private'
                                        | translate}}</label>
                                </div>
                                <div [ngClass]="{'selected-visibility': snView.remarkVisibility  == 'public'}">
                                    <label *ngIf="showRemarkVisibilityType.public"
                                        [ngClass]="{'clickable': !disableRemarks}"
                                        (click)="onRemarkVisibilityChanged('public')">{{'pages.sn.remark-visibility-public'
                                        | translate}}</label>
                                </div>
                            </div>
                            <textarea maxlength="{{ constants.TEXTAREA_MAX_LENGTH }}" id="create-sn_remarks_inputbox"
                                type="text" class="form-control" (change)="onInputUpdated(false)"
                                [disabled]="disableRemarks" [(ngModel)]="snView.remark"
                                [ngModelOptions]="{standalone: true}" rows="3">
                            </textarea>
                        </div>   
                    </div>
                    <div *ngIf="showRemarkHistory && this.selectedSN != null && this.selectedSN.remarks != null"
                        class="form-group custom-group remark-group">
                        <label class="col-md-4"></label>
                        <remark-history class="col-md-8" [remarks]="this.selectedSN.remarks" [editMode]=!disableRemarks>
                        </remark-history>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="mode != 'reroute' ">
                <div class="row justify-content-between">
                    <div class="col-auto align-self-center">
                        <h1 class="title">{{"pages.sn.attachment.title" | translate}}</h1>
                    </div>
                </div>
                <hr class="underline" />
                <div class="row mb-2" *ngIf="mode != 'edit' ">
                    <div class="row col-12 mx-0 mt-3 align-items-start p-0">
                        <label for="attachment-button" class="col-xl-2 col-12 ml-xl-2 mt-2 text-xl-right">{{"pages.sn.attachment.title" | translate}}</label>
                        <div class="col-xl-9 col-12 mt-2 m-xl-0 p-xl-0">
                            <button id="attachment-button" class="btn standard brand-blue p-0 mb-2" type="button" *ngIf="mode!='edit'">
                                <input
                                    #fileInput
                                    type="file"
                                    name="attachmentInput[]"
                                    id="attachmentInput"
                                    class="inputfile inputfile-2"
                                    data-multiple-caption="{count} files selected"
                                    (change)="onClickedAddAttachment($event)"
                                    (click)="fileInput.value = null"
                                    multiple
                                />
                                <label for="attachmentInput" class="m-0 p-2">
                                    <i class="fas fa-paperclip mr-1"></i><span>{{"action.button.upload" | translate}}</span>
                                </label>
                            </button>
                            <ng-container *ngIf="this.snView">
                                <div class="row col-12">
                                    <ng-container *ngFor="let attachment of this.snView.attachmentList; let i = index">
                                        <div class="col-12 col-xl-5 m-0 p-1 mr-xl-2 mb-1">
                                            <span class="mr-2 text-break">
                                            <a href="javascript:;" (click)="requestGetFile(i)">
                                                {{attachment.name}}</a></span>
                                            <button class="btn standard brand-red py-1 px-2 m-0 mx-1"
                                                (click)="onClickedDelAttachment(i)" *ngIf="mode!='edit'">
                                                <label class="mb-0"><i class="fas fa-times"></i></label>
                                            </button>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row mb-2" *ngIf="mode=='edit'">
                <div class="row col-12 mx-0 mt-3 align-items-start p-0">
                    <label for="attachment-button" class="col-xl-2 col-12 ml-xl-2 mt-2 text-xl-right">{{"pages.sn.attachment.title" | translate}}</label>
                    <div class="col-xl-9 col-12 mt-2 ml-4 m-xl-0 p-xl-0">
                        <ng-container *ngIf="this.snView">
                            <div class="row col-12 mt-1">
                                <ng-container *ngFor="let attachment of this.snView.attachmentList; let i = index">
                                    <div class="col-12 col-xl-5 m-0 p-1 mr-xl-2 mb-1">
                                        <span class="mr-2 text-break">
                                        <a href="javascript:;" (click)="requestGetFileFromServer(attachment.attachmentId,attachment.description)">
                                            {{attachment.description}}</a></span>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

        </form>

        <div #anchor class="row justify-content-between">
            <div class="col-auto align-self-center">
                <h1 class="title">{{'pages.sn.routing-details' | translate}}</h1>
            </div>
        </div>
        <hr class="underline" />
        <div class="row">
            <div class="form-row col-md-12 ml-1">
                <div class="checkbox-wrap position-relative custom-control custom-checkbox mt-3">
                    <input type="checkbox"
                        class="checkbox custom-control-input"
                        id="create-sn_show-out-of-office-hour_checkbox"
                        (change)="onInputUpdated(true)"
                        [(ngModel)]="snView.includeNonOfficeHour"
                        [disabled]="disableEditTeam" >
                    <label class="custom-control-label d-block" for="create-sn_show-out-of-office-hour_checkbox">
                        {{'pages.sn.show-matching-out-of-office-hours' | translate}}
                    </label>
                </div>
            </div>
        </div>
        <div>
            <app-tablex id="routing-choices-table" #routing_choices_table [tablexParam]='tablexParam' class='mt-3'></app-tablex>
        </div>
        <div *ngIf="showLoadingWheel" class="table-loading-wheel">
            <div class="spinner-border mt-5" role="status">
            </div>
        </div>
        <custom-slider-panel #manual_instruction_form_panel [widthPx]=600>
            <manual-instruction-pre-action-form
                [groupInstructions]="manualInstructionPreActionFormData"
                [team]="manualInstructionTeam"
                [teamNextOperationTime]="manualInstructionTeamNextOperationTime"
                [handlingTeamNextOperationTime]="manualInstructionHandlingTeamNextOperationTime"
                [isLoading]="isManualInstructionPanelLoading"
                (onSubmit)="onManualInstructionSubmitClicked($event)"
            >
            </manual-instruction-pre-action-form>
        </custom-slider-panel>
        <custom-slider-panel #manual_instruction_list_panel [widthPx]=400>
            <manual-instruction-pre-action-list [groupInstructions]="manualInstructionPreActionListData">
            </manual-instruction-pre-action-list>
        </custom-slider-panel>
        <custom-slider-panel #ha_equipment_list_panel [widthPx]=900>
            <app-ha-equipment-list-panel #ha_equipment_list_form [selectedHaEquipment]="SelectedHaEquipment" (onSubmit)="onHaEquipmentListPanelSubmit()"></app-ha-equipment-list-panel>
        </custom-slider-panel>
    </div>
</div>
