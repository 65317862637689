<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings.work-centres.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'default'"></app-sidebar>

  <div id="content" class="row mx-0">
    <app-functional-item-list #work_centre_list
      class="col-md-3 col-12 work-centre-list-elem mb-md-0 mb-4" 
      [parameters]="workCentreListParam"
      [userInput]="workCentreListInput"
      [listItem]="workCentreList"
      (submitClickListItem)="onClickWorkCentre($event)"
    >
    </app-functional-item-list>

    <app-custom-fields-form #work_centre_form
      class="col-md-9 col-12 work-centre-form-elem"
      [parameters]="workCentreFormParam"
      [userInput]="workCentreFormInput"
    ></app-custom-fields-form>
  </div>
</div>
