import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AuthorizationService } from '@services/authorization.service';
import { ParameterService } from '@services/parameter.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/AppDelegate';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign
} from 'src/app/entity/enum/tablexColumnType';
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { Session } from 'src/app/services/session';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

// jquery reference
declare var $: any;

@Component({
  selector: 'app-sn-list',
  templateUrl: './sn-list.component.html',
  styleUrls: ['./sn-list.component.scss']
})
export class SnListComponent implements OnInit {
  isExpanded = false;
  isCollapsed: boolean = false;
  isGeneralSearch: boolean = false;
  generalSearchWords: string;
  snParameterList = [
    '_id',
    'snNumber',
    'faultDetail',
    'maintenanceType',
    'workCentre',
    'handlingParty',
    'team.name',
    'status',
    'pendingApproval',
    'equipmentOwner',
    'eamData.assetNumber',
    'eamData.HAWorkOrderNumber',
    'eamData.hospitalCode',
    'eamData.pmScheduleDate',
    'eamData.cmBreakdownDate',
    'priority',
    'client',
    'location',
    'locationDetail',
    'ccsSnNumber',
    'createdAt',
    'createdBy',
    'version'
  ];
  filterCriteria: any = {};

  // Filter Options
  clientOptions: OptionsI[];
  priorityOptions: NumberOptionsI[];
  equipmentTypeOptions: OptionsI[];
  equipmentCategoryOptions: OptionsI[];
  initialPartyOptions: OptionsI[];
  handlingPartyOptions: NumberOptionsI[];
  snStatusOptions: OptionsI[];
  jobStatusOptions: OptionsI[];
  teamWorkCentreOptions: string[]; // for selected team workCentre
  snWorkCentreOptions: string[]; // for selected sn workCentre 
  teamOptions: OptionsI[] = [];
  maintenanceTypeOptions: string[] = [JMENUM.MaintenanceType.CM, JMENUM.MaintenanceType.PM];

  // tablex
  snList: JMOBJ.ServiceNotification[] = [];
  tablexParam: { [x:string]:any } = {};
  tableXHeader;
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  locationDict: {} = {}; // key: locationCode, value: location of array
  haAssetDict: { [x:string]:any } = {};

  // loading flag
  isSearchingSN = false;
  isLoadingTeam = false;

  // for batch ack
  isAcknowledging: boolean = false;
  isDisableBatchAck: boolean = true;
  selectedSnList: JMOBJ.ServiceNotification[] = [];

  // for searching team use
  private searchTeamObserver = new Subject<any[]>();
  searchTeamKeywords: string;
  teamOptionsTotalPage = 1;
  teamOptionsPage = 1;

  constructor(
    private parameterService: ParameterService,
    private authorizationService: AuthorizationService,
  ) { }

  ngOnInit() {
    // string from dashboard
    this.generalSearchWords = Session.snListGeneralSearchKeyword;

    if (Session.snListAdvancedSearchCriteria) {
      this.filterCriteria = Session.snListAdvancedSearchCriteria;
      this.isExpanded = true;
    }

    this.initTablex();
    this.loadColumnSettingsFromLocal();

    if (this.generalSearchWords && this.generalSearchWords.length > 0) {
      this.isGeneralSearch = true;
      this.generalSearch();
    } else {
      this.advancedSearch();
    }
    // init client options
    this.requestClientList();
    this.initAllOption();
    this.initSearchTeamObserver();
  }

  ngOnDestroy() {
    Session.setSnListGeneralSearchKeyword(null);
    Session.setSnListAdvancedSearchCriteria(null);
  }

  //------------------------------------------------
  // init options function
  private initAllOption() {
    this.initPriorityOption();
    this.initInitialPartyOption();
    this.initHandlingPartyOption();
    this.initSnStatus();
    this.initEquipmentCategoryOption();

    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.teamWorkCentreOptions = JM.JMConnector.getAllWorkCentreCode();
    } else {
      this.teamWorkCentreOptions = this.authorizationService.getWorkCenters();
    }

    this.snWorkCentreOptions = JM.JMConnector.getAllWorkCentreCode();
  }

  private initPriorityOption() {
    this.priorityOptions = [];
    for (let id of this.parameterService.allSNPriority()) {
      this.priorityOptions.push({
        value: id,
        label: 'sn.priority.' + id,
      });
    }
  }

  private initInitialPartyOption() {
    this.initialPartyOptions = [];
    for (let id of this.parameterService.allInitialParty()) {
      id !== "All" && this.initialPartyOptions.push({
        value: id,
        label: 'team.initial-party.' + id,
      });
    }
  }

  private initHandlingPartyOption() {
    this.handlingPartyOptions = [];
    for (let id of this.parameterService.allHandlingParty()) {
      this.handlingPartyOptions.push({
        value: id,
        label: 'team.handling-party.' + id,
      });
    }
  }

  private initSnStatus() {
    this.snStatusOptions = [];
    this.jobStatusOptions = [];
    const snStatusList = JM.JMConnector.getAllSNStatus();
    const jobCardSusList = JM.JMConnector.getAllJobCardStatus();

    for (const status of snStatusList) {
      this.snStatusOptions.push({
        value: status,
        label: JMLanguage.translate('sn.status.' + status),
      });
    }

    for (const status of jobCardSusList) {
      this.jobStatusOptions.push({
        value: status,
        label: JMLanguage.translate('jobcard.status.' + status),
      });
    }
  }

  private initEquipmentCategoryOption() {
    this.equipmentCategoryOptions = [];
    for (let code of JM.JMConnector.getAllEquipmentCategoryCode()) {
      let cat = JM.JMConnector.getEquipmentCategory(code);
      let item = {
        value: code,
        label: code + ' - ' + cat.description[Session.selectedLanguage],
      };
      this.equipmentCategoryOptions.push(item);
    }

    // update equipment type options
    this.updateEquipmentTypeOptions(null);
  }

  private updateEquipmentTypeOptions(newCat?: string) {
    this.equipmentTypeOptions = [];

    const lang = Session.selectedLanguage;
    let equipmentTypeList = newCat ? JM.JMConnector.getEquipmentTypeCodeFromCategory(newCat) : JM.JMConnector.getAllEquipmentTypeCode();

    for (const typeCode of equipmentTypeList) {
      const item = {
        value: typeCode,
        label: typeCode + ' - ' + JM.JMConnector.getEquipmentType(typeCode).description[lang],
      }
      this.equipmentTypeOptions.push(item);
    }
  }

  private initSearchTeamObserver() {
    // for search team use
    this.searchTeamObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.teamOptions = [];
      this.teamOptionsPage = 1;
      this.requestTeamSummary();
    }
    );
  }

  //------------------------------------------------
  // tablex function
  onPageSizeClicked = (pageSize) => {
    this.tablexParam['currentPage'] = 1;
    this.currentPageSize = pageSize;
    this.resetSelectedRows();
    if (this.isGeneralSearch) {
      this.generalSearch();
    } else {
      this.advancedSearch();
    }
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.tablexParam['currentPage'] = pageIndex;
    this.resetSelectedRows();
    if (this.isGeneralSearch) {
      this.generalSearch();
    } else {
      this.advancedSearch();
    }
  }

  onColFiltered = (selectedColId) => {
    this.tablexParam.selectedColId = selectedColId;
    this.renderTable();
    this.saveColumnSettingsToLocal();
  };

  private saveColumnSettingsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.tablexParam.selectedColId));
      Session.setSnListSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }
  
  private loadColumnSettingsFromLocal = () : void => {
    try {
      let cache = Session.snListSettingsColumns;
      if (cache) {
        this.tablexParam.selectedColId = JSON.parse(JSON.stringify(cache))
      }
    } catch (e) {
      console.warn(e);
    }
  }

  onRowClicked = (index, row) => {
    // use hidden column 'sn object id' as key
    const snId = row[0];
    const matchedSn = this.snList.find(sn => sn._id === snId);
    if (matchedSn) {
      const isInSelectedList = this.selectedSnList.includes(matchedSn);
      if (isInSelectedList) {    // click selected row
        this.tablexParam['highlightedRows'][index] = false;
        this.selectedSnList = this.selectedSnList.filter(sn => sn._id !== matchedSn._id);
        if (this.selectedSnList.length == 0) {
          this.isDisableBatchAck = true;
        }
      } else { // click new row
        this.tablexParam['highlightedRows'][index] = true;
        this.selectedSnList.push(matchedSn);
        this.isDisableBatchAck = false;
      }
    }

    this.tablexParam['selectedRowCount'] = this.selectedSnList.length;
  }

  private initTablex() {
    this.tableXHeader = [
      {
        id: 'objId',
        name: 'pages.sn-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none',
      },
      {
        id: 'sn_number',
        name: 'pages.sn-list.table-column.sn-number',
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'fault',
        name: 'pages.sn-list.table-column.fault',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-3 col-lg-3 p-1 p-xl-2',
      },
      {
        id: 'maintenance_type',
        name: 'pages.sn-list.table-column.maintenance-type',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-3 col-lg-3 p-1 p-xl-2',
      },
      {
        id: 'workCentre',
        name: 'pages.sn-list.table-column.work-centre',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'handling_party',
        name: 'pages.sn-list.table-column.handling-party',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'teamName',
        name: 'pages.sn-list.table-column.team-name',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-2 col-lg-2 p-1 p-xl-2',
      },
      {
        id: 'status',
        name: 'pages.sn-list.table-column.status',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'haWorkOrderNumber',
        name: 'pages.sn-list.table-column.ha-work-order-number',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'haAssetNumber',
        name: 'pages.sn-list.table-column.ha-asset-number',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'haAssetDescription',
        name: 'pages.sn-list.table-column.ha-asset-description',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'pmScheduleDate',
        name: 'pages.sn-list.table-column.pm-schedule-date',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'cmBreakdownDate',
        name: 'pages.sn-list.table-column.cm-breakdown-date',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'haEquipmentBrand',
        name: 'pages.sn-list.table-column.ha-equipment-brand',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'haEquipmentModel',
        name: 'pages.sn-list.table-column.ha-equipment-model',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'priority',
        name: 'pages.sn-list.table-column.priority',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'client',
        name: 'pages.sn-list.table-column.client',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'hospitalCode',
        name: 'pages.sn-list.table-column.hospital-code',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'hospitalLocation',
        name: 'pages.sn-list.table-column.hospital-location',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-1 col-lg-1 p-1 p-xl-2',
      },
      {
        id: 'location',
        name: 'pages.sn-list.table-column.location',
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-2 col-lg-2 p-1 p-xl-2',
      },
      {
        id: 'locationDetail',
        name: 'pages.sn-list.table-column.location-detail',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-2 col-lg-2 p-1 p-xl-2',
      },
      {
        id: 'ccsSnNumber',
        name: 'pages.sn-list.table-column.ccs-sn-number',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-2 col-lg-2 p-1 p-xl-2',
      },
      {
        id: 'created_at',
        name: 'pages.sn-list.table-column.created-at',
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col col-2 col-lg-2 p-1 p-xl-2',
      }
    ];

    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      enableSelectedRowCount: true,
      enableSelectedAll: true,
      tableRow: 'row',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedColId: [
        'sn_number',
        'fault',
        'maintenance_type',
        'handling_party',
        'teamName',
        'status',
        'priority',
        'client',
        'location',
        'created_at',
      ],
      fullColNameList: this.tableXHeader.map((col) => {
        return { id: col.id, name: col.name };
      }),
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onColFiltered: this.onColFiltered,
      onRowClicked: this.onRowClicked,
      enableStickyHeader: false,
      content: [],
      highlightedRows: [],
      customClassRows: []
    }
  }

  /**
   * 3 possbile additional API in preRender
   * Base on sn location code to get location data. Location data source from cache or API.
   * Get hospital Code Mapping from sn location code if HA equipment
   * Get HaAssetSummary from sn eamData asset assetNumber if HA equipment
   */
  private preRenderTable() {
    if (this.snList.length > 0) {
      let promiseArray = [];
      
      const locationCodeSet: Set<string> = new Set();
      this.snList
        .filter(sn => sn.location && !this.locationDict.hasOwnProperty(sn.location))
        .forEach(sn => locationCodeSet.add(sn.location));
      if (locationCodeSet.size > 0) {
        promiseArray.push(this.requestLocationSummary(Array.from(locationCodeSet)));
      }

      const haAssetCodeSet: Set<string> = new Set();
      this.snList
        .filter(sn => sn.eamData && sn.eamData.assetNumber && !this.haAssetDict.hasOwnProperty(sn.eamData.assetNumber))
        .forEach(sn => haAssetCodeSet.add(sn.eamData.assetNumber));
      if (haAssetCodeSet.size > 0) {
        promiseArray.push(this.requestHaAssetSummary(Array.from(haAssetCodeSet)));
      }

      Promise.all(promiseArray).finally(() => {
        this.renderTable();
      });
    } else {
      this.renderTable();
    }
  }

  private renderTable() {
    const lang = Session.selectedLanguage;
    this.tablexParam.headers = this.tableXHeader.filter((col) => {
      return this.tablexParam.selectedColId.includes(col.id);
    });
    this.tablexParam.content = this.snList.map(sn => {
      // fault detail
      let faultDetail = sn.faultDetail;
      if (sn.faultDetail && sn.faultDetail.length >= 1000) {
        faultDetail = sn.faultDetail.substring(0, 1000) + '...';
      }

      // location
      let locationArray = [];
      if (sn.location) {
        const description = this.locationDict[sn.location];
        if (description && description[lang]) {
          locationArray.push(description[lang]);
        }
        locationArray.push(`(${sn.location})`);
      }

      let assetNumber = '', HAWorkOrderNumber = '', description = '', brand = '', model = '', addressAll = '', hospitalCode = '', pmScheduleDate = '', cmBreakdownDate = '';
      
      if (sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA) {
        if (sn.eamData) {
          if (sn.eamData.assetNumber) {
            assetNumber = sn.eamData.assetNumber;
            const dictEntry = this.haAssetDict[assetNumber];
            if (dictEntry) {
              description = dictEntry.description || '';
              brand = dictEntry.brand || '';
              model = dictEntry.model || '';
              addressAll = `${dictEntry.address1 || ''} ${dictEntry.address2 || ''} ${dictEntry.address3 || ''}`;
            }
          }
          HAWorkOrderNumber = sn.eamData.HAWorkOrderNumber || '';
          hospitalCode = sn.eamData.hospitalCode || '';
          pmScheduleDate = sn.eamData.pmScheduleDate ? formatDate(sn.eamData.pmScheduleDate, 'dd/MM/yyyy HH:mm', 'en-US') : '';
          cmBreakdownDate = sn.eamData.cmBreakdownDate ? formatDate(sn.eamData.cmBreakdownDate, 'dd/MM/yyyy HH:mm', 'en-US') : '';
        }
      }

      let fullRow = [
        sn._id,
        {
          url: '/sn/view/' + sn.snNumber,
          value: sn.snNumber,
        },
        faultDetail,
        sn.maintenanceType ? sn.maintenanceType : '',
        sn.workCentre ? sn.workCentre : '',
        sn.handlingParty ? JMLanguage.translate('team.handling-party.' + sn.handlingParty) : '',
        sn.team ? sn.team.name : '',
        sn.pendingApproval? JMLanguage.translate(`sn.pending-approval.${sn.pendingApproval}`) : JMLanguage.translate(`sn.status.${sn.status}`),
        HAWorkOrderNumber,
        assetNumber,
        description,
        pmScheduleDate,
        cmBreakdownDate,
        brand,
        model,
        JMLanguage.translate('sn.priority.' + sn.priority),
        sn.client,
        hospitalCode,
        addressAll,
        locationArray,
        sn.locationDetail ? sn.locationDetail : '',
        sn.ccsSnNumber ? sn.ccsSnNumber : '',
        [sn.createdBy, formatDate(sn.createdAt, 'dd/MM/yyyy HH:mm', 'en-US')]
      ];

      let rowFiltered = fullRow.filter((value, i) => {
        return this.tablexParam.selectedColId.includes(this.tableXHeader[i].id);
      });
      return rowFiltered;
    });
    this.tablexParam['isLoadingTable'] = false;
  }

  private resetSelectedRows() {
    this.selectedSnList = [];
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = this.selectedSnList.length;
    this.isDisableBatchAck = true;
  }

  //------------------------------------------------
  // API funciton
  private requestClientList() {
    this.clientOptions = [];
    for (let client of JM.JMConnector.getAllClient()) {
      let option = {
        label: client.clientShortName + ' - ' + client.name[Session.selectedLanguage],
        value: client.clientShortName,
      };
      this.clientOptions.push(option);
    }
  }

  private async generalSearch() {
    this.isGeneralSearch = true;
    this.snList = [];

    let request = new JM.JMRequestSnGeneralSearch();
    request.expression = this.generalSearchWords;
    request.pageNumber = this.tablexParam['currentPage'];
    request.pageSize = this.currentPageSize;
    request.parameters = this.snParameterList;

    this.tablexParam['isLoadingTable'] = true;
    this.isSearchingSN = true;
    const response: JM.JMResponseSnGeneralSearch = await AppDelegate.sendJMRequest(request);
    this.isSearchingSN = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.tablexParam['pageCount'] = 1;
      this.renderTable();
      return;
    }

    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    this.snList = response.payload.records;
    this.preRenderTable();
  }

  private async advancedSearch() {
    this.isGeneralSearch = false;
    this.snList = [];
    const filter = this.filterCriteria;
    let request = new JM.JMRequestSnSummary();

    request.pageNumber = this.tablexParam['currentPage'];
    request.pageSize = this.currentPageSize;
    request.parameters = this.snParameterList;
    request.snNumberList = filter.snNumber ? [filter.snNumber] : undefined;
    request.ccsSnNumberList = filter.ccsSnNumber ? [filter.ccsSnNumber] : undefined;
    request.clientList = filter.client ? [filter.client] : undefined;
    request.contact = filter.contact ? filter.contact : undefined;
    request.location = filter.location ? filter.location : undefined;
    request.priorityList = filter.priority ? filter.priority : undefined;
    request.equipmentTypeList = filter.equipmentType ? [filter.equipmentType] : undefined;
    request.equipmentCategory = filter.equipmentCategory ? filter.equipmentCategory : undefined;
    request.maintenanceType = filter.maintenanceType ? filter.maintenanceType : undefined;
    request.initialPartyList = filter.initialParty ? filter.initialParty : undefined;
    request.handlingPartyList = filter.handlingParty ? filter.handlingParty : undefined;
    request.createdList = filter.created ? [filter.created] : undefined;
    request.workCentreList = filter.snWorkCentre ? [filter.snWorkCentre] : undefined;
    request.teamWorkCentreList = filter.teamWorkCentre ? [filter.teamWorkCentre] : undefined;
    request.remarks = filter.remarks ? filter.remarks : undefined;
    request.filter = {};
    request.filter['faultDetail'] = filter.faultDetail ? filter.faultDetail : undefined;
    request.filter['eamData.assetNumber'] = filter.assetNumber ? filter.assetNumber : undefined;
    request.filter['eamData.HAWorkOrderNumber'] = filter.haWorkOrderNo ? filter.haWorkOrderNo : undefined;
    request.statusList = filter.snStatus ? filter.snStatus : undefined;
    request.jobCardStatusList = filter.jobStatus ? filter.jobStatus : undefined;

    if (filter.team) {
      request.teamIdList = [filter.team];
      delete request.teamWorkCentreList;
    }

    if (filter.haveManualInstruction != undefined) {
      request.haveManualInstruction = filter.haveManualInstruction;
    }

    this.tablexParam['isLoadingTable'] = true;
    this.isSearchingSN = true;
    const response: JM.JMResponseSnSummary = await AppDelegate.sendJMRequest(request);
    this.isSearchingSN = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.tablexParam['pageCount'] = 1;
      this.renderTable();
      return;
    }

    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    this.snList = response.payload.records;
    this.preRenderTable();
  }

  private async requestLocationSummary(locationCodeList: string[]) {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.parameters = ['code', 'description', 'districtCode'];
    request.location = locationCodeList;
    request.locationOnly = true;
    request.pageSize = this.currentPageSize;
    request.pageNumber = 1;

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    for (const location of response.payload.records) {
      this.locationDict[location.code] = location.description;
    }
  }

  private async requestHaAssetSummary(haAssetCodeList: string[]) {
    let request = new JM.JMRequestEquipmentsHSDEquipmentSummary();
    request.assetNumber = haAssetCodeList;
    request.pageSize = this.currentPageSize;
    request.pageNumber = 1;

    const response: JM.JMResponseEquipmentsHSDEquipmentSummary = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    for (const record of response.payload.records) {
      this.haAssetDict[record.assetNumber] = {
        description: record.HAEquipment.assetDescription,
        model: record.HAEquipment.model,
        brand: record.HAEquipment.brand,
        address1: record.HAEquipment.address1,
        address2: record.HAEquipment.address2,
        address3: record.HAEquipment.address3,
      }
    }
  }

  private async requestTeamSummary() {
    this.teamOptionsPage = 1;

    let request = new JM.JMRequestTeamsTeamSummary();
    request.active = JMENUM.RequestActive.BOTH;
    request.parameters = ['_id', 'name', 'workCentre', 'handlingParty', 'contractNumber'];
    request.workCentreList = [this.filterCriteria.teamWorkCentre];
    request.includeApprovedWorkCentre = true;
    request.includeSummary = true;
    request.pageSize = 10;
    request.pageNumber = this.teamOptionsPage;

    if (this.searchTeamKeywords && this.searchTeamKeywords != '') {
      request.filter = { name: this.searchTeamKeywords };
    }

    this.isLoadingTeam = true;
    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let totalTeamCount = response.payload.totalCount;
    this.teamOptionsTotalPage = Math.ceil(totalTeamCount / request.pageSize);
    this.setTeamOptions(response.payload.records);
  }

  onTeamScrollToEnd = async () => {
    if (this.teamOptionsPage < this.teamOptionsTotalPage) {
      this.teamOptionsPage += 1;

      let request = new JM.JMRequestTeamsTeamSummary();
      request.active = JMENUM.RequestActive.BOTH;
      request.parameters = ['_id', 'name', 'workCentre', 'handlingParty', 'contractNumber'];
      request.workCentreList = [this.filterCriteria.teamWorkCentre];
      request.includeApprovedWorkCentre = true;
      request.includeSummary = true;
      request.pageSize = 10;
      request.pageNumber = this.teamOptionsPage;

      if (this.searchTeamKeywords && this.searchTeamKeywords != '') {
        request.filter = { name: this.searchTeamKeywords };
      }

      this.isLoadingTeam = true;
      const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
      this.isLoadingTeam = false;

      if (!response || !response.code || response.code !== 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      this.setTeamOptions(response.payload.records, true);
    }
  }

  private setTeamOptions(teamDetailArray: JMOBJ.Team[], append = false): void {
    // just append will not trigger ng-selct change detection
    this.teamOptions = append ? JSON.parse(JSON.stringify(this.teamOptions)) : [];

    for (let team of teamDetailArray) {
      let formattedTeamName = FORAMTTER.formatTeamNameContractNumber(team);
      let teamWorkCentre = this.filterCriteria.teamWorkCentre && team.workCentre != this.filterCriteria.teamWorkCentre ? `<${team.workCentre}>` : '';
      let option = {
        label: `${formattedTeamName} ${teamWorkCentre} `,
        value: team._id,
      };
      this.teamOptions.push(option);
    }
  }

  private async requestBatchAcknowledge() {
    let request = new JM.JMRequestSnBatchAcknowledgeSn();
    request.snList = this.selectedSnList.map(sn => {
      return {
        snNumber: sn.snNumber,
        version: sn.version
      }
    });

    this.isAcknowledging = true;
    this.isDisableBatchAck = true;
    const response: JM.JMResponseSnBatchAcknowledgeSn = await AppDelegate.sendJMRequest(request)
    this.isDisableBatchAck = false;
    this.isAcknowledging = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    const successCount = response.payload.successCount;
    const failedCount = this.selectedSnList.length - response.payload.successCount;
    AppDelegate.openSnackBar(JMLanguage.translate('pages.sn-list.batch-ack-result', [successCount, failedCount]));

    this.resetSelectedRows();
    // refresh page
    this.advancedSearch()
  }

  //------------------------------------------------
  // button callback function
  checkSearchEmpty() {
    if (this.generalSearchWords.length <= 0) {
      if (this.isGeneralSearch) {
        this.generalSearchWords = null;
        Session.setSnListGeneralSearchKeyword(null);
        this.advancedSearch();
      } else {
        this.advancedSearch();
      }
    }
  }

  onBatchAcknowledgeClicked() {
    const message = JMLanguage.translate('pages.sn-list.confirm-batch-ack-sn', [this.selectedSnList.length]);
    const buttons = [
      {
        name: JMLanguage.translate('global.yes'),
        handler: () => {
          this.requestBatchAcknowledge();
        }
      },
      {
        name: JMLanguage.translate('global.no')
      }
    ]
    AppDelegate.showPopUpAlert(message, '', buttons);
  }

  onKeyup = (event, searchType) => {
    if (event.key === 'Enter') {
      if (searchType == 'generalSearch') {
        this.onGeneralSearchClicked();
      } else if (searchType == 'advancedSearch') {
        this.onSearchClicked();
      }
    }
  }

  onOpenFilterClicked = () => {
    this.isExpanded = !this.isExpanded;
  }

  onSearchClicked() {
    this.tablexParam['currentPage'] = 1;
    this.advancedSearch();
  }

  onGeneralSearchClicked() {
    this.tablexParam['currentPage'] = 1;
    if (this.generalSearchWords ? (this.generalSearchWords.trim().length <= 0) : true) {
      this.generalSearchWords = null;
      Session.setSnListGeneralSearchKeyword(null);
      this.advancedSearch();
    } else {
      this.generalSearch();
    }
  }

  onResetFilterClicked() {
    this.filterCriteria = {};
    this.tablexParam['currentPage'] = 1;
    if (this.isGeneralSearch) {
      this.generalSearch();
    } else {
      this.advancedSearch();
    }
  }

  //------------------------
  // dropdown/input box function
  onTeamWorkCentreChanged = () => {
    this.filterCriteria.team = null;

    if (this.filterCriteria.teamWorkCentre && this.filterCriteria.teamWorkCentre != '') {
      this.requestTeamSummary();
    } else {
      this.teamOptions = [];
    }
  }

  onClearTeam() {
    this.searchTeamKeywords = null;
    this.searchTeamObserver.next();
  }

  onSearchTeamOptions(event) {
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver.next();
  }

  onFilterTeamOptions(term: string, item: any) {
    return item.label.toLowerCase().includes(term.toLowerCase());
  }

  onEquipmentCategoryChanged = (option: OptionsI) => {
    const selectedCat = option && option.value ? option.value : undefined;
    this.updateEquipmentTypeOptions(selectedCat);
  }

  onEquipmentTypeChanged = (option: OptionsI) => {
    // update category
    let equipmentCategoryList: string[] = [];
    this.equipmentCategoryOptions = [];
    if (option != undefined && option.value != null && option.value.length > 0) {
      equipmentCategoryList = JM.JMConnector.getEquipmentCategoryCodeFromType(option.value);
    } else {
      equipmentCategoryList = JM.JMConnector.getAllEquipmentCategoryCode();
    }

    for (const code of equipmentCategoryList) {
      const cat = JM.JMConnector.getEquipmentCategory(code);
      const item = {
        value: code,
        label: code + ' - ' + cat.description[Session.selectedLanguage],
      };
      this.equipmentCategoryOptions.push(item);
    }
  }

  onMiButtonClick(haveManualInstruction?: boolean) {
    if (this.filterCriteria.haveManualInstruction == haveManualInstruction) {
      this.filterCriteria.haveManualInstruction = undefined;
    } else {
      this.filterCriteria.haveManualInstruction = haveManualInstruction;
    }
  }

  checkBtnInList(args: any, key: string, value: any) {
    if (args && args.target) {
      if((args.target as any).checked) {
        this.filterCriteria[key] ? this.filterCriteria[key].includes(value) ? null : this.filterCriteria[key].push(value) : this.filterCriteria[key] = [value];
      } else {
        this.filterCriteria[key] ? this.filterCriteria[key].includes(value) ? this.filterCriteria[key].splice(this.filterCriteria[key].indexOf(value), 1) : null : null;
      }
    }
  }
}

interface OptionsI {
  value: string,
  label: string
}

interface NumberOptionsI {
  value: number,
  label: string
}