<div #timetableWrapper class="timetable-container-wrapper">
    <div #tableContainer class="timetable-container">
        <div #spinner class="table-spinner" [hidden]="!isShowSpinner">

        </div>
        <div #tableHeader class="header-container">
            <div class="header-cell first-header-cell">
                {{ 'pages.timesheet.date' | translate}}
            </div>
            <ng-container *ngFor="let timeInterval of timeIntervals">
                <div class="header-cell">
                    <div class="period-wrapper" [ngStyle]="{width: periodWidth + 'px'}">{{timeInterval.period.label}}
                    </div>
                    <div class="interval-wrapper">
                        <div *ngFor="let interval of timeInterval.intervals" class="interval-indicator">
                            {{interval.label}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div #tableBody class="body-container">
            <div *ngFor="let row of data; let i = index" class="data-row">
                <div class="row-cell first-row-cell">
                    <div class="first-label">{{row.weekday}}</div>
                    <div class="second-label">{{row.date}}</div>
                </div>
                <div *ngFor="let timeInterval of timeIntervals" class="row-cell">
                    <div class="interval-wrapper" [ngStyle]="{width: periodWidth + 'px'}">
                        <div *ngFor="let interval of timeInterval.intervals; let intervalIndex = index"
                            id="{{'cell-' + i + '-' + timeInterval.period.value + '-' + intervalIndex}}"
                            class="interval-indicator">
                        </div>
                    </div>
                </div>
            </div>

            <div #virtualTableForDisplay class="virtual-table virtual-table-display">
                <div #highlightRectangle class="highlight-rectangle" [hidden]="!isHighlighting">
                </div>
                <div class="period-timeline-layer-container">
                    <div class="period-timeline" *ngFor="let datePeriods of allDatePeriods">
                        <div class="period" *ngFor="let period of datePeriods.periods" [style.left.px]="period.startIndex * (periodWidth / intervalCount)"  [style.width.px]="(period.endIndex - period.startIndex) * (periodWidth / intervalCount)">
                            <div class="period-label" [ngStyle]="period.style || null">
                                {{period.label}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #virtualTableForDraw class="virtual-table z-index-101" (mousedown)="onStartDrawing($event)"
                (mouseenter)="onMouseEnterVirtualTable()" (mouseleave)="onMouseLeaveVirtualTable()">
            </div>
        </div>
    </div>
</div>