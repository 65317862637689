import { Component, Injector, Input, OnInit } from '@angular/core';
import { PostPostSummaryRequest } from '@api/model/authorization/post-post-summary-request';
import { SnReason } from '@enum/sn-reason';
import { ContactGroupService } from '@services/contact-group.service';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session }  from 'src/app/services/session';
import { forEach } from 'underscore';

@Component({
  selector: 'create-follow-up-job-card-form',
  templateUrl: './create-follow-up-job-card-form.component.html',
  styleUrls: ['./create-follow-up-job-card-form.component.scss']
})

export class CreateFollowUpJobCardFormComponent extends BasePage implements OnInit {
  @Input() parameters = {
    workCentre: null, 
    jobCardList: [],
    isLoading: false,
    onSubmitClicked: (button) => {},
    onFollowUpInfoChanged: (button) => {},
  };

  // callback return value
  remark: string;

  followUpReason: any;
  followUpAssignOption: any;

  selectedContractor: any;

  assignedPersons: string[];
  officerInCharge: string;


  // ---

  // for assign staff use
  postList: string[];
  assignStaffFormParam = {} as any;

  // drop down options
  followUpReasonOptions: any[];
  contractorOptions: any[];

  constructor(
    private injector: Injector, 
    private contactGroupService: ContactGroupService) {
    super(injector);
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngOnChanges() {
    if (!this.parameters) return;

    this.initDropDownOptions();
    this.requestPostList();

    // TODO: contractor option disabled
    this.followUpAssignOption = 'work-centre';
    this.onFollowUpAssignOptionChanged();
  }

  //===================================================================================================================
  // UI functions
  initDropDownOptions() {
    this.followUpReasonOptions = [];
    const lang = Session.selectedLanguage;
    SnReason.forEach(reason => {
      if (reason.area === 'jobCard' && reason.orderType === 'createFollowUp') {
        this.followUpReasonOptions.push({
          value: reason.id,
          description: (lang === 'zh' ? reason.descriptionZh : reason.descriptionEn)
        });
      }
    });

    this.requestContractorTeam();
  }

  findFirstAttendedJobCard(jobCardList) {
    let firstAttendedJobCard;
    jobCardList.forEach(jobCard => {
      let tmpArr = jobCard.split("-");
      if(tmpArr.length > 1 && tmpArr[1] == '01'){
        firstAttendedJobCard = jobCard;
        return;
      }
    });
    return firstAttendedJobCard;
  }

  //===================================================================================================================
  // API request functions
  requestContractorTeam() {
    if (!this.parameters.workCentre) return;

    let request                         = new JM.JMRequestTeamsTeamSummary();
    request.workCentreList              = [this.parameters.workCentre];
    request.handlingPartyList           = [JMENUM.HandlingParty.PMSMC, JMENUM.HandlingParty.NON_PMSMC]; // contractor team
    request.includeApprovedWorkCentre   = true;
    request.pageSize                    = 1000;

    JM.JMConnector.sendTeamSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseTeamsTeamSummary) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      if (response.payload.records && response.payload.records.length) {
        this.contractorOptions = response.payload.records.map(item => {
          return {
            description: item.name,
            value: item._id
          };
        });
      }
    });
  }

  requestPostList() {
    let request = {} as PostPostSummaryRequest;
    request.systemName = 'CCEPJM';
    request.active = 'active';
    request.authorizations = {'workCenters': this.parameters.workCentre};

    this.apiHandler(
      this.authorizationService.postPostSummary(request), result => {
        if (result == null || result.code != 200 ||result.payload == null) {
          this.openErrorBar(result);
          return;
        }
        if (!result.payload.records || !result.payload.records.length) return;

        this.postList = result.payload.records.map(post => { return post.name});
        this.initAssignStaffParameters();
        if(this.parameters.jobCardList){
          this.requestJobCardSummary();
        }
      }, error => { this.openErrorBar(error);});
  }

  requestJobCardSummary(){
    let request: JM.JMRequestJobCardsJobCardSummary = new JM.JMRequestJobCardsJobCardSummary();
    let firstAttendedJobCard = this.findFirstAttendedJobCard(this.parameters.jobCardList);
    request.jobCardNumberList = [firstAttendedJobCard];
    request.parameters = ['assignedPersons', 'officerInCharge'];

    JM.JMConnector.sendJobCardsJobCardSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseJobCardsJobCardSummary) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.assignedPersons = []; // always clear for copy first attend assigned persons
      response.payload.records[0].assignedPersons.forEach(person => {
        if(this.postList.find(contractor => contractor == person)) {
          this.assignedPersons.push(person);
        }
      });

      this.officerInCharge = response.payload.records[0].officerInCharge
      this.initAssignStaffParameters();
    });
  }
  //===================================================================================================================
  
  initAssignStaffParameters(): void {
    // this.assignedPersons = null;
    // this.officerInCharge = null;

    this.assignStaffFormParam = {
      teamMembers: this.postList,
      assignedPersons: this.assignedPersons, 
      officerInCharge: this.officerInCharge,
      onAddMemberClicked: (button)=>{ this.onAssignedStaffChanged(button) },
      onRemoveMemberClicked: (button)=>{ this.onAssignedStaffChanged(button) },
      onOfficerInChargeChanged: (button)=>{ this.onAssignedStaffChanged(button) },
    };
  }

  isValidToSubmit() {
    if (!this.followUpAssignOption) return false;

    let valid = this.followUpReason != null;

    switch (this.followUpAssignOption) {
      case 'contractor':
        return valid && this.selectedContractor;
      case 'work-centre':
        return valid && this.assignedPersons && this.assignedPersons.length > 0 && this.officerInCharge;
      default:
        return false;
    }
  }

  getCallbackData() {
    if (this.remark) this.remark = this.remark.trim();
    let result = {followUpReasonCode: this.followUpReason, followUpRemarks: this.remark} as any;

    switch (this.followUpAssignOption) {
      case 'contractor':
        result.selectedContractor = this.selectedContractor;
        break;
      case 'work-centre':
        result.assignedPersons = this.assignedPersons;
        result.officerInCharge = this.officerInCharge;
        break;
      default:
        break;
    }

    return result;
  }

  //===================================================================================================================
  // Event callback
  onClickSubmit() {
    if (this.parameters.onSubmitClicked) this.parameters.onSubmitClicked(this.getCallbackData());
  }

  onFollowUpInfoChanged = () => {
    let result = this.getCallbackData();
    result.completed = this.isValidToSubmit();
    if (this.parameters.onFollowUpInfoChanged) this.parameters.onFollowUpInfoChanged(result);
  }

  onFollowUpAssignOptionChanged = () => {
    this.selectedContractor = null;
    this.initAssignStaffParameters();

    this.onFollowUpInfoChanged();
  }

  onAssignedStaffChanged = (button) => {
    this.assignedPersons = button.assignedPersons;
    this.officerInCharge = button.officerInCharge;

    this.onFollowUpInfoChanged();
  }

  get getMaxRemarksLength():number{
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }

}
