import { Component, Input, OnInit } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import  { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'update-team-form',
  templateUrl: './update-team-form.component.html',
  styleUrls: ['./update-team-form.component.scss']
})

export class UpdateTeamFormComponent implements OnInit {
  
  uiEnableSaveButton:boolean = true;
  uiTitle:string = '';
  uiTeamName:string = ''
  uiTeamDescription:string = '';
  
  team:JMOBJ.Team = undefined;

  @Input() parameters: {
    workCentre: string,
    onSaveButtonClicked: Function,
  };

  

  ngOnInit() {
    
  }

  //----
  clear() {
    this.uiTeamName = "";
    this.uiTeamDescription = "";
    this.team = undefined;
  }

  setTeam(team: JMOBJ.Team) {
    this.team = team;
    this.uiTitle = JMLanguage.translate("update-team-form.title",  [this.team.name]);
    this.uiTeamName = this.team.name;
    this.uiTeamDescription = this.team.description;
  }

  //----
  // button  callback
  async onSaveButtonClicked(e) {
    const request = new JM.JMRequestTeamsUpdateTeam();
    request._id = this.team._id.toString();
    request.name = this.uiTeamName;
    request.description = this.uiTeamDescription;
    
    this.uiEnableSaveButton = false;
    const response: JM.JMResponseTeamsUpdateTeam = await AppDelegate.sendJMRequest(request);
    this.uiEnableSaveButton = true;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.parameters.onSaveButtonClicked(null, response);
  }
}
