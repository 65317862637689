import { Component, EventEmitter, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { JMENUM } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Output } from '@angular/core';

@Component({
  selector: 'app-inspection-form',
  templateUrl: './inspection-form.component.html',
  styleUrls: ['./inspection-form.component.scss']
})
export class InspectionFormComponent implements OnInit {
  @Input() jobInspectionInput;
  @Input() dateInputSet;
  @Input() errorFields;
  @Input() imageFiles;
  @Input() pdfFiles;
  @Input() submitDisabled;
  @Input() imageAcceptType;
  @Input() pdfAcceptType;
  @Input() isLoading;
  @Input() isDateValid;
  @ViewChild('imageFileUpload', { static: false }) imageFileInputRef: ElementRef<HTMLInputElement>;
  @ViewChild('pdfFileUpload', { static: false }) pdfFileInputRef: ElementRef<HTMLInputElement>;

  @Output() dateInput = new EventEmitter<any>();
  @Output() downloadLocalFile = new EventEmitter<any>();
  @Output() imageFile = new EventEmitter<any>();
  @Output() imageFileDelete = new EventEmitter<any>();
  @Output() pdfFile = new EventEmitter<any>();
  @Output() pdfFileDelete = new EventEmitter<any>();
  @Output() onSubmit = new EventEmitter<any>();
  @Output() submitButtonStatusCheck = new EventEmitter<any>();
  timeAlertMsg: string = JMLanguage.translate("global.invalid-time");
  dateAlertMsg: string = JMLanguage.translate("global.invalid-date");

  public inspectionResultOptions: any[] = [
    {
      value: JMENUM.InspectionResult.SATISFACTORY,
      description: JMLanguage.translate('component.contract-inspection.inspectionResult.options.satisfactory')
    },
    {
      value: JMENUM.InspectionResult.UNSATISFACTORY,
      description: JMLanguage.translate('component.contract-inspection.inspectionResult.options.unsatisfactory')
    },
    {
      value: JMENUM.InspectionResult.NO_OBSERVATION,
      description: JMLanguage.translate('component.contract-inspection.inspectionResult.options.noObservation')
    },
  ];

  public modeOfCheckOptions: any[] = [
    {
      value: JMENUM.InspectionMode.ON_SITE,
      description: JMLanguage.translate('component.inspection.job.inspection.option.on.site')
    },
    {
      value: JMENUM.InspectionMode.REMOTE,
      description: JMLanguage.translate('component.inspection.job.inspection.option.remote')
    },
    {
      value: JMENUM.InspectionMode.DOCUMENT_CHECK,
      description: JMLanguage.translate('component.inspection.job.inspection.option.document.check')
    },
  ];

  constructor() { }

  ngOnInit() {
  }

  onChangeSubmitButtonStatusCheck() {
    this.submitButtonStatusCheck.emit();
  }

  onBlurDateInput(event) {
    this.dateInput.emit(event);
  }

  onChangeImageFileInput(event) {
    this.imageFile.emit(event);
  }

  onChangePdfFileInput(event) {
    this.pdfFile.emit(event);
  }

  onClickDownloadLocalFile(file, i) {
    this.downloadLocalFile.emit({
      file: file,
      index: i
    });
  }

  onClickImageFileDelete(i) {
    this.imageFileDelete.emit({
      index: i,
      imageFileInputRef: this.imageFileInputRef
    })
  }

  onClickPdfFileDelete(i) {
    this.pdfFileDelete.emit({
      index: i,
      pdfFileInputRef: this.pdfFileInputRef
    })
  }

  onClickSubmit() {
    this.onSubmit.emit();
  }

  get getMaxRemarksLength(): number {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
