<div class="functional-item-list-wrap" id="functional-item-list-wrap" *ngIf="parameters">

  <div class="list-header-wrap border-bottom">
    <div class="row mx-0 header-input-wrap align-items-center">
      <input id="header-input" class="form-control col w-auto " [(ngModel)]="userInput.headerInput" 
        type="text" [placeholder]="parameters.headerInput?.placeholder"
        (change)="parameters.headerInput?.onChange()" (keyup)="parameters.headerInput?.onKeyUp()"
      />
      <button id="list-header-btn" class="btn col-auto font-size-xl outline-none " [ngClass]="parameters.listHeaderBtn?.customClass" type="button" 
        [disabled]="parameters.listHeaderBtn?.disabled" (click)="parameters.listHeaderBtn?.onClick(); onClickHeaderBtn()"
      >
        <i [ngClass]="parameters.listHeaderBtn?.icon" *ngIf="parameters.listHeaderBtn?.icon"></i>
      </button>
    </div>

    <div id="hidden-container" class="row mx-0 p-2 mt-2" [class.hide]="parameters.isHideHiddenContainer">
      <input id="hidden-container-input" class="form-control {{ parameters.hiddenContainerInput?.customClass }}"
        type="text" [placeholder]="parameters.hiddenContainerInput?.placeholder"
        [(ngModel)]="userInput.hiddenContainerInput" (change)="parameters.hiddenContainerInput?.onChange()" (keyup.enter)="parameters.hiddenContainerBtn?.onClick();"
      />
      <button id="hidden-container-btn" class="btn col-xl-auto col-12 outline-none {{ parameters.hiddenContainerBtn?.customClass }}"
        type="button" [disabled]="parameters.hiddenContainerBtn?.disabled" (click)="parameters.hiddenContainerBtn?.onClick();"
      >
      <i [ngClass]="parameters.hiddenContainerBtn?.icon" *ngIf="parameters.hiddenContainerBtn?.icon"></i>
        {{ parameters.hiddenContainerBtn?.text }}
      </button>
    </div>

  </div>

  <div class="list-wrap mt-2" (scroll)="parameters.onScroll($event);">
    <div [id]=" 'item_'+i " class="list-item p-2" [class.selected]="i == selectedItem"
      *ngFor="let item of listItem; let i = index" (click)="onClickListItem(i)" 
    >
      {{ item }}
    </div>
  </div>
</div>