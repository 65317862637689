import { Component, OnInit, Input } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';

@Component({
  selector: 'manual-instruction-pre-action-list',
  templateUrl: './manual-instruction-pre-action-list.component.html',
  styleUrls: ['./manual-instruction-pre-action-list.component.scss']
})
export class ManualInstructionPreActionListComponent implements OnInit {

  @Input() groupInstructions: [{
    manualInstructionId: number,
    remarks: string,
  }[]];

  displayInstructionList = [];

  constructor() { }
  
  ngOnInit() {
    
  }

  ngOnChanges() {
    if (!this.groupInstructions) {
      return;
    }

    this.updateInstructionList();
  }

  updateInstructionList() {
    this.displayInstructionList = [];

    if (this.groupInstructions && Array.isArray(this.groupInstructions)) {
      for (const instructionList of this.groupInstructions) {
        if (Array.isArray(instructionList)) {
          for (const instruction of instructionList) {
            const mi: JMOBJ.ManualInstruction = JM.JMConnector.getManualInstruction(instruction.manualInstructionId);

            this.displayInstructionList.push({
              description: mi.description[Session.selectedLanguage],
              remark     : instruction.remarks,
            });
          }
        }
      }
    }
  }
}
