/*
  version 20201119:
   - add CCS SO Number

  version 20201103:
   - create this page
*/

import { Input } from '@angular/core';
import { Injector } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { BasePage } from '../../model/base/base';
import { ParameterService } from '@services/parameter.service';
import { OrderType } from '@enum/order-type';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-teco-advanced-search',
  templateUrl: './teco-advanced-search.component.html',
  styleUrls: ['./teco-advanced-search.component.scss']
})
export class TecoAdvancedSearchComponent extends BasePage implements OnInit {

  @Input() parameters = {
    fullTextSearch: false,
    defaultPanelOpen: false,
    title: "",
    isTecoPage: true
  };
  @Output() onSubmit = new EventEmitter<any>();

  protected authorizationService: AuthorizationService;

  isExpanded: boolean;
  workCentreOptions: any[];
  filterCriteria: any = {};
  isLoadingTeam = false;
  isLoadingAssignedStaff = false;

  handlingPartyOptions: any[];
  tecoStatusOptions: any[];
  orderTypeOptions: any[] = [
    { name: OrderType.SLA_JOB, value: OrderType.SLA_JOB },
    { name: OrderType.AD_HOC_JOB, value: OrderType.AD_HOC_JOB },
    { name: OrderType.TRANSFERRED_JOB, value: OrderType.TRANSFERRED_JOB }
  ];

  teamOptionsTotalPage = 1;
  teamOptionsPage = 1;
  teamOptions: JMOBJ.Team[] = [];
  teamOptionPageSize = 10;


  assignedStaffOptionsTotalPage = 1;
  assignedStaffOptionsPage = 1;
  assignedStaffOptions: any = [];
  assignedStaffPageSize = 10;

  // for searching team use
  private searchTeamObserver = new Subject<any[]>();
  searchTeamKeywords: string;
  // for seaching assigned staff use
  private searchAssignedStaffObserver = new Subject<any[]>();
  searchAssignedStaffKeywords: string;
  timesheetSubmittedOptions: any[];
  quotationSubmittedOptions: any[];


  constructor(injector: Injector, private parameterService: ParameterService) { super(injector); }

  ngOnInit() {
    this.isExpanded = this.parameters.defaultPanelOpen;
    //may be not default later? 
    // this.filterCriteria.timesheetSubmitted = true;
    // this.filterCriteria.quotationSubmitted = true;
    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.workCentreOptions = JM.JMConnector.getAllWorkCentreCode();
    } else {
      this.workCentreOptions = this.authorizationService.getWorkCenters();
    }
    this.onLangChange();
    this.requestAssignedStaffSummary();
    this.searchTeamObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.teamOptions = [];
      this.teamOptionsPage = 1;
      this.requestTeamsTeamSummary();
    }
    );
    this.searchAssignedStaffObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.assignedStaffOptions = [];
      this.assignedStaffOptionsPage = 1;
      this.requestAssignedStaffSummary();
    }
    );
  }

  private initTecoStatusOption() {
    this.tecoStatusOptions = [];
    let para = [];
    if (this.parameters.isTecoPage) {
      para = this.parameterService.allTecoPageStatus();
    } else {
      para = this.parameterService.allUnTecoPageStatus();
    }
    for (let id of para) {
      this.tecoStatusOptions.push({
        value: id,
        label: this.translate("teco.status." + id),
      });
    }
  }

  private initHandlingPartyOption() {
    this.handlingPartyOptions = [];
    for (let id of this.parameterService.allHandlingParty()) {
      this.handlingPartyOptions.push({
        value: id,
        label: this.translate("team.handling-party." + id),
      });
    }
  }

  private initTimesheetSubmittedOption()
  {
    this.timesheetSubmittedOptions = [];
    for (let id of this.parameterService.allTimesheetSubmittedStatus()) {
      this.timesheetSubmittedOptions.push({
        value: id,
        label: this.translate("teco.advanced-search.timesheet-submitted." + id),
      });
    }
  }

  private initQuotationSubmittedOption()
  {
    this.quotationSubmittedOptions = [];
    for (let id of this.parameterService.allTimesheetSubmittedStatus()) {
      this.quotationSubmittedOptions.push({
        value: id,
        label: this.translate("teco.advanced-search.quotation-submitted." + id),
      });
    }
  }

  onTimesheetSubmittedClick(timesheetSubmitted) {
    this.filterCriteria.timesheetSubmitted = timesheetSubmitted;
  }

  onQuotationSubmittedClick(quotationSubmitted) {
    this.filterCriteria.quotationSubmitted = quotationSubmitted;
  }

  onLangChange() {
    this.initHandlingPartyOption();
    this.initTecoStatusOption();
    this.initQuotationSubmittedOption()
    this.initTimesheetSubmittedOption();
  }

  public onOpenFilterClicked() {
    this.isExpanded = !this.isExpanded;
  }

  public onSubmitSearch() {
    this.onSubmit.emit(this.filterCriteria);
  }

  public onClearSearch() {
    this.filterCriteria = {};
    this.teamOptionsPage = 1;
    this.assignedStaffOptionsPage = 1;
    this.searchAssignedStaffKeywords = undefined;
    this.searchAssignedStaffObserver.next();
  }

  //Assigned Staff------------------------------------------------------------------------------
  onSearchAssignedStaffOptions(event) {
    this.assignedStaffOptionsPage = 1;
    this.searchAssignedStaffKeywords = event.term;
    this.searchAssignedStaffObserver.next();
  }

  onClearAssignedStaff() {
    this.assignedStaffOptions = 1;
    this.searchAssignedStaffKeywords = null;
    this.searchAssignedStaffObserver.next();
  }

  private requestAssignedStaffSummary() {
    let request = new JM.JMRequestPostsPostSummary();
    request.active = JMENUM.RequestActive.BOTH;
    request.systemName = Constants.SYSTEM_NAME
    request.includeSummary = true;
    request.pageSize = this.assignedStaffPageSize;
    request.pageNumber = this.assignedStaffOptionsPage;
    request.active = JMENUM.RequestActive.ACTIVE;

    if (this.searchAssignedStaffKeywords && this.searchAssignedStaffKeywords != '') {
      request.filter = { name: this.searchAssignedStaffKeywords };
    }

    this.isLoadingAssignedStaff = true;

    JM.JMConnector.sendPostsPostSummary(request, (error: JM.JMNetworkError, response: JM.JMResponsePostsPostSummary) => {
      this.isLoadingAssignedStaff = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.assignedStaffOptionsTotalPage = Math.ceil(response.payload.totalCount / request.pageSize);


      if (this.assignedStaffOptionsPage == 1) {
        this.assignedStaffOptions = response.payload.records;
      } else if (response.payload.totalCount > this.assignedStaffOptions.length) {
        this.assignedStaffOptions = this.assignedStaffOptions.concat(response.payload.records);
      }

      if ((response.payload.totalCount / this.assignedStaffPageSize) > this.assignedStaffOptionsPage) {
        this.assignedStaffOptionsPage += 1;
      }
    });
  }

  //--------------------------------------------------------------------------------------------

  //Work Centre and Team------------------------------------------------------------------------
  onWorkCentreChanged = () => {
    if (this.filterCriteria.workCentre && this.filterCriteria.workCentre != '') {
      this.requestTeamsTeamSummary();
    } else {
      this.teamOptions = [];
    }
  }

  onClearWorkCentre() {
    this.filterCriteria.team = undefined;
    this.filterCriteria.workCentre = undefined;
  }

  private requestTeamsTeamSummary() {
    let request = new JM.JMRequestTeamsTeamSummary();
    request.active = JMENUM.RequestActive.BOTH;
    request.parameters = ["_id", "name"];
    request.workCentreList = [this.filterCriteria.workCentre];
    request.includeApprovedWorkCentre = true;
    request.includeSummary = true;
    request.pageSize = this.teamOptionPageSize;
    request.pageNumber = this.teamOptionsPage;

    if (this.searchTeamKeywords && this.searchTeamKeywords != '') {
      request.filter = { name: this.searchTeamKeywords };
    }

    this.isLoadingTeam = true;

    JM.JMConnector.sendTeamSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseTeamsTeamSummary) => {
      this.isLoadingTeam = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.teamOptionsTotalPage = Math.ceil(response.payload.totalCount / request.pageSize);


      if (this.teamOptionsPage == 1) {
        this.teamOptions = response.payload.records;
      } else if (response.payload.totalCount > this.teamOptions.length) {
        this.teamOptions = this.teamOptions.concat(response.payload.records);
      }

      if ((response.payload.totalCount / this.teamOptionPageSize) > this.teamOptionsPage) {
        this.teamOptionsPage += 1;
      }
    });
  }

  onClearTeam() {
    this.teamOptionsPage = 1;
    this.searchTeamKeywords = null;
    this.searchTeamObserver.next();
  }

  onSearchTeamOptions(event) {
    this.teamOptionsPage = 1;
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver.next();
  }
  //--------------------------------------------------------------------------------------------

}
