// export enum HashTagPriority {
//     UserChoose = 0,
//     Emergency = 1,
//     Urgent = 2,
//     NonUrgent = 3,
// }

export enum Priority {
    Emergency = 1,
    Urgent = 2,
    NonUrgent = 3
}

export const SnPriorityTranslate = [
    {en: "", zh: ""}, //no 0
    {"en": "Emergency", "zh": "危急"},
    {"en": "Urgent", "zh": "緊急"},
    {"en": "Non Urgent", "zh": "非緊急"}
]