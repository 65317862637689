import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Session } from '@services/session';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { BasePage } from '../../model/base/base';

@Component({
  selector: 'app-edit-functional-location-panel',
  templateUrl: './edit-functional-location-panel.component.html',
  styleUrls: ['./edit-functional-location-panel.component.scss']
})
export class EditFunctionalLocationPanelComponent extends BasePage implements OnInit {

  @Input() editMode = "";
  @Input() locationParam: JMOBJ.Location = new JMOBJ.Location();

  @Output() onSubmit = new EventEmitter<any>();

  levelOption: any = [];
  categoryCodeOption: any = [];
  classificationCodeOption: any = [];

  locationEditMode = JMENUM.LocationEditMode;
  FehdVenue = JMENUM.FehdVenue;

  fehdVenuesCheckedList: any = {};

  constructor(injector: Injector) { super(injector); }

  ngOnInit() {
    this.levelOption = [
      {
        value: JMENUM.LocationLevel.ONE,
        label: JMENUM.LocationLevel.ONE,
      }, {
        value: JMENUM.LocationLevel.TWO,
        label: JMENUM.LocationLevel.TWO,
      }, {
        value: JMENUM.LocationLevel.THREE,
        label: JMENUM.LocationLevel.THREE,
      }
    ];

    this.categoryCodeOption = Object.values(Session.categoryCodeDict);

    this.classificationCodeOption = Object.values(Session.classificationCodeDict);

    //-----------------------------------------------------
  }

  ngOnChanges() {
    this.initFehdVenuesCheckedList();
  }

  initFehdVenuesCheckedList() {
    for (let fehdVenue of Object.values(JMENUM.FehdVenue)) {
      this.fehdVenuesCheckedList[fehdVenue] = false;
    }

    if (!this.locationParam || !this.locationParam.fehdVenues || !this.locationParam.fehdVenues.length) {
      return;
    }

    for (let fehdVenue of this.locationParam.fehdVenues) {
      this.fehdVenuesCheckedList[fehdVenue] = true;
    }
  }

  public onSaveButtonClicked(event) {
    // validate
    //this.vaildation();
    //if (this.vaildationCheck) {
      let onSubmitClicked =  () => {
        const message = this.translate("action.button.popup.edit-functional-location");
        const buttons = [
          { name: this.translate("global.yes"), handler: () => { this.onSubmit.emit(); } },
          { name: this.translate('global.no'), handler: () => {  } }
        ];
        this.showPopUpAlert(message, '', buttons);
      }
      onSubmitClicked();
      
    //}
  }

  onFehdVenuesChanged = () => {
    this.locationParam.fehdVenues = [];
    for (let fehdVenue of Object.values(JMENUM.FehdVenue)) {
      if (this.fehdVenuesCheckedList[fehdVenue]) {
        this.locationParam.fehdVenues.push(fehdVenue);
      }
    }
  }

  //--------------------------
  canUpdateDetail() {
    let showField = this.editMode == JMENUM.LocationEditMode.LIST || this.editMode == JMENUM.LocationEditMode.FUNCTIONAL_LOCATION;
    let hasPermission = this.authorizationService.hasPermission(JMENUM.Permission.FLMS_UPDATE);

    return showField && hasPermission; 
  }

  canUpdateFehdVenues() {
    let showField = this.editMode == JMENUM.LocationEditMode.LIST;
    let hasPermission = this.authorizationService.hasPermission(JMENUM.Permission.FLMS_UPDATE_FEHD);

    return showField && hasPermission;
  }

  canUpdateChineseDescription() {
    let showField = this.editMode == JMENUM.LocationEditMode.LIST || this.editMode == JMENUM.LocationEditMode.CHINESE_LOCATION;
    let hasPermission = this.authorizationService.hasPermissions([JMENUM.Permission.FLMS_UPDATE_CHI, JMENUM.Permission.FLMS_UPDATE], false);

    return showField && hasPermission;
  }

}
