<!--Table-->
<div class="wrapper">
    <app-nav [breadcrumbs]="breadcrumbs"></app-nav>

    <app-sidebar></app-sidebar>

    <ng-container *ngIf="actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
            (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <div id="content" class="action-btn-padding">
        <app-tablex [tablexParam]='tablexParam' class='mt-3'></app-tablex>
        <slider-panel [title]="sliderPaneltitle" [rows]="sliderPanelGroups">
        </slider-panel>
    </div>
</div>