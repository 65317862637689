<div class="wrapper ha-equipment-list-page">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [customItems]='sideBarItem'></app-sidebar>

  <div id="content">
    <div class="row">
      <div class="col-9">
        <input id="search_input" type="text" class="form-control" [(ngModel)]="searchInputText"
          (keydown.enter)="onSearchKeyup()" placeholder="{{'pages.ha-equipment-list.search-text-field-title' | translate}}">
      </div>
      <div class="col-3 px-0 pr-3">
        <div class="text-right">
          <button id="search_btn" class="standard brand-blue mx-2" (click)="onSearchClicked()">
            <i class="fas fa-search space"></i>{{"pages.ha-equipment-list.search-button" | translate }}
          </button>
          <button id="clear_btn" class="standard brand-red mx-2" (click)="onClearSearchClicked()">
            <i class="fas fa-trash space"></i>{{"pages.ha-equipment-list.clear-button" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="row mx-0 mt-3">

      <div class="col-9 checkbox-wrap position-relative custom-control custom-checkbox mt-3 d-inline-block">

        <div class="mr-xl-2">
            <input type="checkbox" 
                id="no-haequipment-tag-only__checkbox" 
                class="checkbox custom-control-input"
                [ngModelOptions]="{standalone: true}"
                (change)="onNoTagSearchClicked()" 
                class="checkbox custom-control-input" 
                [(ngModel)]="isNoTagSearch">
            <label class="custom-control-label" for="no-haequipment-tag-only__checkbox">{{"pages.ha-equipment-list.no-hashtag-filter-checkbox" | translate }}</label>
      
        </div>
        <div class="mr-xl-2">
            <input type="checkbox" 
                id="no-ccsEquipment__checkbox" 
                class="checkbox custom-control-input"
                [ngModelOptions]="{standalone: true}"
                (change)="onNoCCSEquipmentSearchClicked()" 
                class="checkbox custom-control-input" 
                [(ngModel)]="isNoCCSEquipmentSearch">
            <label class="custom-control-label" for="no-ccsEquipment__checkbox">{{"pages.ha-equipment-list.no-ccs-equipment-filter-checkbox" | translate }}</label>
        </div>
        
       
      </div>


      <div class="col-3">
        <div class="text-right">
          <button id='export-excel-btn' type="button" class="standard brand-blue ml-2 mr-0"
            (click)="onClickExportExcel()" [disabled]="isExportExcelBtnLoading || isDisabledExportExcelBtn">
            <div *ngIf="isExportExcelBtnLoading" class="d-inline-block loading-spinner spinner-small">
              {{ "global.loading" | translate }}
            </div>
            <span *ngIf="!isExportExcelBtnLoading">
              <i class="fas fa-file-download space"></i>
              {{ "global.export-excel" | translate }}
            </span>
          </button>
          <button id="batch-add-btn" type="button" class="standard brand-blue ml-2 mr-0"
            (click)="onClickBatchAdd()" [disabled]="isDisabledBatchEditBtn">
            <span>
              {{ "pages.ha-equipment-list.batch-add" | translate }}
            </span>
          </button>
          <button id="batch-remove-btn" type="button" class="standard brand-blue ml-2 mr-0"
            (click)="onClickBatchRemove()" [disabled]="isDisabledBatchEditBtn">
            <span>
              {{ "pages.ha-equipment-list.batch-remove" | translate }}
            </span>
          </button>
        </div>
      </div>


    </div>

    <app-tablex id='ha-equipment-list-table' #haEquipmentListTable [tablexParam]='tablexParam' class='mt-3'>
    </app-tablex>
  </div>

  <!-- <custom-slider-panel #edit_ha_equipment_panel (onClosePanel)="onCloseEditEquipment()" [widthPx]=600>
    <button id="update-equipment-btn" class="btn brand-blue ml-auto d-block" [disabled]="isLoadingUpdateEquipment" (click)="validateEquipmentForm()">
      <span>{{ "global.submit" | translate }}</span>
    </button>
    <app-custom-fields-form #edit_equipment_form class="edit-equipment-form-elem" [parameters]="editEquipmentFormParam" [userInput]="editEquipmentFormInput" >
    </app-custom-fields-form>
  </custom-slider-panel> -->

  <custom-slider-panel #edit_ha_equipment_panel [widthPx]=900>
    <edit-equipment-form #edit_equipment_form></edit-equipment-form>
  </custom-slider-panel>

  <!-- <custom-slider-panel #batch_edit_tags_panel [widthPx]=900>
    <app-custom-fields-form #batch_edit_tags_form class="batch-edit-tags-form-elem" [parameters]="batchEditTagsFormParam" [userInput]="batchEditTagsFormInput" >
    </app-custom-fields-form>
    <div class="row mx-0 justify-content-between mt-5">
      <button class="standard brand-blue col-2" (click)="onClickAddTags()" [disabled]="isDisabledBatchAddBtn">
        {{ 'global.add' | translate }}
      </button>
      <button class="standard brand-red col-2" (click)="onClickRemoveTags()" [disabled]="isDisabledBatchRemoveBtn">
        {{ 'global.remove' | translate }}
      </button>
    </div>
  </custom-slider-panel> -->
  <custom-slider-panel #batch_edit_tags_panel [widthPx]=900>
    <batch-edit-ha-equipment-form #batch_edit_tags_form></batch-edit-ha-equipment-form>
  </custom-slider-panel>
</div>