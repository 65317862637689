import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { EquipmentTagService } from '@services/equipment-tag.service';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { AddTagFormComponent } from 'src/app/ui/components/add-tag-form/add-tag-form.component';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { SidebarItemInterface } from 'src/app/ui/components/sidebar/sidebar-items';
import { BasePage } from 'src/app/ui/model/base/base';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
@Component({
  selector: 'ha-equipment-tag-list',
  templateUrl: './ha-equipment-tag-list.component.html',
  styleUrls: ['./ha-equipment-tag-list.component.scss']
})


export class HAEquipmentTagListComponent extends BasePage implements OnInit, AfterViewInit {
  @ViewChild(CustomSliderPanelComponent, { static: true }) addTagPanel: CustomSliderPanelComponent;
  @ViewChild(AddTagFormComponent, { static: true }) addTagForm: AddTagFormComponent;
  
  pageTitle:string = undefined;
  haCluster:string = undefined;
  hasEditPermission:boolean = false;

  sideBarItem: SidebarItemInterface[] = [];
  hashTagsList:JMOBJ.HashTag[] = [];
  tablexParam = undefined;
  userTagGroup:JMOBJ.HashTagGroup[] = [];
  userTagGroupWorkCentreCodes:string[] = undefined;

  constructor(
    injector: Injector,
    private equipmentTagService: EquipmentTagService,
  ) { 
    super(injector);
  }
  
  protected componentName(): string {
    return "ha-equipment-tag-list";
  }

  //===========================================================================
  // view life cycle functions
  ngOnInit() {
    const workCentre = Session.workCentreDict[Session.selectedWorkCentre];
    this.haCluster = workCentre.HACluster;

    const text = this.haCluster + ' - ' + [Session.selectedWorkCentre]; 
    this.pageTitle = JMLanguage.translate("pages.ha-equipment-tag-list.title", [text ? text : '']);
    
    this.hasEditPermission = JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.HSD_EQUIPMENT_UPDATE);

    this.initSideBar();
    this.initTablex();
  }

  ngAfterViewInit() {
    
    this.addTagForm.param = {
      onSave: this.onTagFormSaved
    };
    // TODO: TBC - remove
    // if (this.haCluster) {
    //   this.requestTagGroup();
     this.requestHAEquipmentTag();
    // }
  }

  ngOnDestroy() {
   
  }

  //========================
  private initSideBar() {
    this.sideBarItem = [
      {
        title: "side-bar.equipment",
        subItems:[
          {
            title: "side-bar.equipment-tag",
            path: '/setting/ccs-equipment-tag-list',
          }, {
            title: "side-bar.equipment-list",
            path: '/setting/equipment-list',
          }
        ]
      }
    ];
    let enableHAEquip = Session.workCentreDict[Session.selectedWorkCentre].enableHAEquipment;
    if (enableHAEquip && JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.HSD_EQUIPMENT_VIEW)) {
      this.sideBarItem[0].subItems = this.sideBarItem[0].subItems.concat([
        {
          id: 'equipment-list',
          title: "side-bar.ha-equipment-tag-list",
          path: '/setting/ha-equipment-tag-list'
        }, {
          title: "side-bar.ha-equipment-list",
          path: '/setting/ha-equipment-list',
        }, 
      ]);
    }

  } 

  private initTablex() {
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : false,
      enablePagination      : false,
      tableRow              : "row",
      tableWrapperClass     : "table-min-width",
      headers: [  
        
        {
          id:'tagGroup', 
          name: "pages.ha-equipment-tag-list.col-header.tagGroup",
          class: "col-1",  
          type: TablexColumnType.Text, 
          enableFilter: false, 
          onFilterKeyUp: null, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id:'workCentre', 
          name: "pages.ha-equipment-tag-list.col-header.workcentre",
          class: "col-2",  
          type: TablexColumnType.Text, 
          enableFilter: false, 
          onFilterKeyUp: null, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id:'tag',
          name: "pages.ha-equipment-tag-list.col-header.tag",
          class: "col-9",
          type: TablexColumnType.BorderLabel,
          enableFilter:false,
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          buttons: this.hasEditPermission?[
            {
              id:'new_tag',
              name: '',
              icon: 'fas fa-plus',
              class: 'glyph brand-blue',
              hidden:false,
              disable: false,
              onClicked: this.onAddNewTagClicked,
            }
          ]:[]
        }
      ],
      content: []
    }
  }

  private renderTable() {
    // groupby tagGroup
    let tagGroupDict: { [key: string]: JMOBJ.HashTag[] } = {};
    for (const tag of this.hashTagsList) {
      if (tag.tagGroup._id in tagGroupDict) {
        tagGroupDict[tag.tagGroup._id].push(tag);
      } else {
        tagGroupDict[tag.tagGroup._id] = [tag];
      }
    }

    this.tablexParam['content'] = [];

    for (const tagList of Object.values(tagGroupDict)) {
      // assume tag.tagGroup.workCentres are same under same tagGroup
      const tagGroup = tagList[0].tagGroup;
      const tagGroupName = tagGroup.name;
      const wcCodeList = tagGroup.workCentres
        .filter(wc => wc && wc.workCentreCode)
        .map(wc => wc.workCentreCode);
      const labelObjectList = tagList.map(tag => {
        return {
          name: this.equipmentTagService.getTagDescription(tag, false),
          data: tag,
          class: 'tag-blue',
          onClicked: this.onTagClicked,
        };
      })

      this.tablexParam['content'].push([
        tagGroupName,
        wcCodeList.join(', '),
        labelObjectList
      ]);
    }
  }

  //----------------------------------------
  // button callback
  onAddNewTagClicked = () => {
    this.addTagForm.resetForm();
    // this.addTagForm.showTag(this.userTagGroupWorkCentreCodes, null);
    this.addTagForm.showTag(Session.selectedWorkCentre, null);
    this.addTagPanel.toggle();
  }

  onTagClicked = (rowIndex, button) => {
    let tag = button.data;
    this.addTagForm.resetForm();
    // this.addTagForm.showTag(this.userTagGroupWorkCentreCodes, tag);
    this.addTagForm.showTag(Session.selectedWorkCentre, tag);
    this.addTagPanel.toggle();
  }

  onTagFormSaved = () => {
    this.requestHAEquipmentTag();
    // this.requestTagGroup();
    this.addTagPanel.toggle();
  }

  //----------------------------------------
  // API function
  private async requestHAEquipmentTag() {
    const request = new JM.JMRequestWorkCentresHashtags();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.tagGroupStatusList = [JMENUM.HashtagGroupStatus.ACTIVE];
    request.equipmentSource = JMENUM.EquipmentSource.HA;
    request.workCentre = Session.selectedWorkCentre;

    const response: JM.JMResponseWorkCentresHashtags = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if(response.payload.length > 0){
      this.hashTagsList = response.payload;
      this.renderTable();
    }
  }

  // TODO: TBC - remove
  private async requestTagGroup(){
    const request = new JM.JMRequestHashtagGroupSummary();
    let clusterAndSelected = []
    clusterAndSelected = AppDelegate.getWorkCentreUnderCluster([this.haCluster])
    if(clusterAndSelected.includes([Session.selectedWorkCentre])){
      clusterAndSelected.push([Session.selectedWorkCentre])
    }
    request.workCentreList = clusterAndSelected 
    request.status = JMENUM.RequestActive.ACTIVE

    const response: JM.JMResponseHashtagGroupSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if(response.payload.totalCount > 0){
      this.userTagGroupWorkCentreCodes = clusterAndSelected
      this.userTagGroup = response.payload.records;
      this.requestHAEquipmentTag();
    }
  }
}

// TODO: TBC - remove?
const getWorkCentresFromHashtagGroup = (tagGroup) => {
  if(!tagGroup || tagGroup.length === 0){
    return
  }
  const workCentreCode = [];
  tagGroup.map(({workCentres}) => {
    workCentres.map((code) => code.workCentreCode).forEach(
      (e) => {
        workCentreCode.push(e)
      }
    )
  })
  return workCentreCode
}