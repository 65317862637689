import { Component, Injector, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOfficeHoursComponent } from '../modal-office-hours/modal-office-hours.component';
@Component({
  selector: 'manual-instruction-pre-action-form',
  templateUrl: './manual-instruction-pre-action-form.component.html',
  styleUrls: ['./manual-instruction-pre-action-form.component.scss']
})


export class ManualInstructionPreActionFormComponent extends BasePage implements OnInit {
  @Input() groupInstructions: [{
    manualInstructionId: number,
    remarks: string,
  }[]];
  @Input() team: any;
  @Input() teamNextOperationTime: string;
  @Input() handlingTeamNextOperationTime: string;

  @Input() isLoading: boolean;
  @Output() onSubmit = new EventEmitter<any>();

  isLoadingTeamSummary: boolean = false;
  remark: string;

  workingHourData: {
    workingHourGroups: workingHourViewModel[],
    nonWorkingHourGroups: workingHourViewModel[],
  } = {
    workingHourGroups: [],
    nonWorkingHourGroups: []
  };

  // max 5 group instruction
  groupInstructionViewData = [
    { groupText: 'A', groupBackgroundColor: '#007bff40' },
    { groupText: 'B', groupBackgroundColor: '#28a74540' },
    { groupText: 'C', groupBackgroundColor: '#ff996040' },
    { groupText: 'D', groupBackgroundColor: '#346f4240' },
    { groupText: 'E', groupBackgroundColor: '#a9003a40' },
  ]

  constructor(
    private injector: Injector,
    private modalService: NgbModal,
  ) {
    super(injector);
    
  }

  ngOnInit() { }

  ngOnDestroy() { }

  ngOnChanges() {
    if (!this.groupInstructions || !this.team) {
      return;
    }

    this.updateWorkingHourData();
  }

  updateWorkingHourData() {
    this.workingHourData = {
      workingHourGroups: [],
      nonWorkingHourGroups: []
    };

    if (this.groupInstructions && Array.isArray(this.groupInstructions)) {

      for (let groupInstructionIndex = 0; groupInstructionIndex < this.groupInstructions.length; groupInstructionIndex++) {
        const instructionList = this.groupInstructions[groupInstructionIndex];

        if (Array.isArray(instructionList)) {
          let workingHourGroup: workingHourViewModel = {
            isGroup: false,
            groupInstructionIndex: groupInstructionIndex,
            groupText: this.groupInstructionViewData[groupInstructionIndex].groupText,
            groupBackgroundColor: this.groupInstructionViewData[groupInstructionIndex].groupBackgroundColor,
            displayTextList: []
          };
          let nonWorkingHourGroup: workingHourViewModel = {
            isGroup: false,
            groupInstructionIndex: groupInstructionIndex,
            groupText: this.groupInstructionViewData[groupInstructionIndex].groupText,
            groupBackgroundColor: this.groupInstructionViewData[groupInstructionIndex].groupBackgroundColor,
            displayTextList: []
          };
          let isGroup = false;

          // loop instructionList
          for (let instructionIndex = 0; instructionIndex < instructionList.length; instructionIndex++) {
            const instruction = instructionList[instructionIndex];
            const mi: JMOBJ.ManualInstruction = JM.JMConnector.getManualInstruction(instruction.manualInstructionId);

            if (mi) {
              let orderText = instructionList.length > 1 ? `${instructionIndex + 1}. ` : ``;
              let remark = instruction.remarks ? ` - ${instruction.remarks}` : ``;
              let text = `${orderText}${mi.description[Session.selectedLanguage]}${remark}`;

              if (this.isWorkingHour(mi)) {
                workingHourGroup.displayTextList.push(text);
              } else {
                nonWorkingHourGroup.displayTextList.push(text);
              }

              if (instructionIndex === 0) {
                isGroup = mi.groupType ? true : false;
              }
            }
          }

          if (workingHourGroup.displayTextList.length > 0) {
            workingHourGroup.isGroup = isGroup;
            this.workingHourData.workingHourGroups.push(workingHourGroup);
          }
          if (nonWorkingHourGroup.displayTextList.length > 0) {
            nonWorkingHourGroup.isGroup = isGroup;
            this.workingHourData.nonWorkingHourGroups.push(nonWorkingHourGroup);
          }
        }
      }
    }
  }

  isWorkingHour(mi: JMOBJ.ManualInstruction) {
    const isInhouseInstruction = mi.handlingParty.includes(JMENUM.HandlingParty.INHOUSE);
    const isInhouseTeam = this.team.handlingParty === JMENUM.HandlingParty.INHOUSE;
    const isTeamWorking = this.teamNextOperationTime === null;
    const hasHandlingTeam = this.team.handlingTeam;
    const isHandlingTeamWorking = this.handlingTeamNextOperationTime === null;

    let isWorkingHour = false;

    if (!mi.groupType) {
      isWorkingHour = true;
    } else if (mi.groupType === 1) { // "call inhouse" or "call contructor" instructions
      if (mi.workHourType === 1) { // 1: always
        isWorkingHour = true;
      } else if (mi.workHourType === 2) { // 2: office hour
        if (isInhouseTeam) { // inhouse team. assume "call inhouse" instructions only 
            if (isTeamWorking) {
              isWorkingHour = true;
            }
        } else { // contructor team (maybe inclue handling team)
          if (isInhouseInstruction) { // "call inhouse" instruction (handle by handling team)
            if (hasHandlingTeam && isHandlingTeamWorking) {
              isWorkingHour = true;
            }
          } else {
            if (isTeamWorking) {  // "call contructor" instruction
              isWorkingHour = true;
            }
          }
        }
      } else if (mi.workHourType === 3) { // 3: out of office hour
        if (isInhouseTeam) { // inhouse team. assume "call inhouse" instructions only 
            if (!isTeamWorking) {
              isWorkingHour = true;
            }
        } else { // contructor team (maybe inclue handling team)
          if (isInhouseInstruction) { // "call inhouse" instruction (handle by handling team)
            if (hasHandlingTeam && !isHandlingTeamWorking) {
              isWorkingHour = true;
            }
          } else {
            if (!isTeamWorking) {  // "call contructor" instruction
              isWorkingHour = true;
            }
          }
        }
      }
    }
    return isWorkingHour;
  }

  requestHandlingTeamSummary() {
    let request        = new JM.JMRequestTeamsTeamSummary();
        request.idList = [this.team.handlingTeam];

    this.isLoadingTeamSummary = true;
    JM.JMConnector.sendTeamSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseTeamsTeamSummary) => {
      this.isLoadingTeamSummary = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
        this.openErrorBar(response);
        return;
      }

      const handlingTeam = response.payload.records[0];
      this.openOfficeHoursModal(handlingTeam, this.team);
    });
  }

  onClickSubmit() {
    if (!this.remark || this.remark.length == 0) {
      this.openSnackBar(this.translateService.instant("pages.sn.fill-in-all-the-mandatory-fields"));
    } else {
      if (this.remark) {
        this.remark = this.remark.trim();
      }
      this.onSubmit.emit(this.remark);
    }
  }

  onOfficeHoursBtnClicked() {
    const isInhouseTeam = this.team.handlingParty === JMENUM.HandlingParty.INHOUSE;
    const hasHandlingTeam = this.team.handlingTeam;

    if (isInhouseTeam) {
        this.openOfficeHoursModal(this.team, null);
    } else {
      if (hasHandlingTeam) {
        this.requestHandlingTeamSummary();
      } else {
        this.openOfficeHoursModal(null, this.team);
      }
    }
  }

  private openOfficeHoursModal(inhouseTeam?, contractorTeam?) {
    let modalRef = this.modalService.open(
      ModalOfficeHoursComponent,
      { 
        backdrop: 'static',
        centered: true,
        size: 'lg'
      }
    );

    if (inhouseTeam) {
      modalRef.componentInstance.inhouseTeam = inhouseTeam;
    }

    if (contractorTeam) {
      modalRef.componentInstance.contractorTeam = contractorTeam;
    }
  }

  get getMaxRemarksLength():number{
    return this.constants.TEXTAREA_MAX_LENGTH;
  }

}

class workingHourViewModel {

  isGroup: boolean = undefined;
  groupInstructionIndex: number = undefined;
  groupText: string = undefined;
  groupBackgroundColor: string = undefined;
  displayTextList: string[] = undefined;

  constructor() {
    this.isGroup = undefined;
    this.groupInstructionIndex = undefined;
    this.groupText = undefined;
    this.groupBackgroundColor = undefined;
    this.displayTextList = undefined;
  }
}