<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'team'"></app-sidebar>

  <div id="content">
    <div class="row pb-5">
      <div class="col-md-3">
        <team-selector #inhouseTeamSelector 
          [mode]="'inhouse'"
          [workCentre]="selectedWorkCentre"
          (onTeamChanged)="onTeamChanged($event)"
        ></team-selector>
      
        <team-selector #contractorTeamSelector 
          [mode]="'contractor'"
          [workCentre]="selectedWorkCentre"
          (onTeamChanged)="onTeamChanged($event)"
        ></team-selector>
      </div>

      <div class="col-md-9">
          <ng-container *ngIf="!selectedTeam; else teamDetail">
            <div class="row mt-5">
              <div class="col d-flex justify-content-center h5">
                {{ 'pages.team-setting.team-not-found' | translate}}
              </div>
            </div>
          </ng-container>
          <ng-template #teamDetail>
            <div class="row">
              <app-office-hours-table [officeHoursParam]='officeHoursParam' class='col-md no-gutters'></app-office-hours-table>
              <div class="col-md">
                <div class="row">
                  <app-response-time-table [responseTimeParam]='responseTimeParam' class='col-md no-gutters'></app-response-time-table>
                </div>
                
                <div class="row no-gutters mt-3">
                  <div class="col-md pb-2 px-3 input-border">
                    <div class="row row-space">
                      <div class="col-md">
                        <i class="fas fa-phone space ccc"></i>{{ "pages.team-settings.contact-information" | translate}}
                      </div>
                    </div>
                    <div class="row row-space">
                      <div class="col-md align-items-center">
                        <label for="settings-team_fax_inputbox"
                          class="mr-2">{{ "pages.team-settings.fax" | translate}}</label>
                        <input id="settings-team_fax_inputbox" class="mr-2 custom-input-text-box" maxlength="35"
                          [(ngModel)]="selectedTeam.fax" [disabled]="!hasEditionPermission || !selectedTeam.enableFax" />
                        <div class="custom-control custom-checkbox d-inline-block">
                          <input type="checkbox" class="custom-control-input"
                            id="id_enable_team_email"
                            [disabled]="!hasEditionPermission"
                            [(ngModel)]="selectedTeam.enableFax"
                            (change)="onEnableFaxChanged($event)" >
                          <label class="custom-control-label" for="id_enable_team_email"></label>
                        </div>
                      </div>
                    </div>
                    
                      <div class="row">
                        <div class="col-12">{{ "team-settings.label.email" | translate}}</div>
                      </div>
                      <div class="row">
                        <div class="col-11 align-items-center">
                          <ng-select id="id_input_email"
                            id="id-uiSelectedEmail" 
                            (open)="onEmailOptionOpen()"
                            (scrollToEnd)="onEmailOptionScrollToEnd()"
                            [items]="uiEmailRecipientOption" 
                            [searchable]="true"
                            [multiple]="true"
                            [loading]="uiLoadingWorkCentreOfficer"
                            [(ngModel)]="selectedTeam.email"
                            [disabled]="!selectedTeam.enableEmail"
                            [class.input-error]="errorFields.email"
                          ></ng-select>
                        </div>
                        <div class="col-1 align-items-center">
                          <div class="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" class="custom-control-input"
                              id="settings-team_fax_checkbox"
                              [disabled]="!hasEditionPermission"
                              [(ngModel)]="selectedTeam.enableEmail"
                              (change)="onEnableEmailChanged()" >
                            <label class="custom-control-label" for="settings-team_fax_checkbox"></label>
                          </div>
                        </div>
                      </div>
                    
                  </div>
                </div>
                <div class="row no-gutters mt-3" *ngIf="isContractor(selectedTeam.handlingParty)">
                  <div class="col-md px-3 input-border">
                    <div class="row row-space">
                      <div class="col-md">
                        <i class="fas fa-file-alt space ccc"></i>
                        {{ "pages.team-settings.contract-information" | translate}}
                      </div>
                    </div>
                    <span class="list-remarks d-inline-flex align-middle pr-4 font-size-xs">
                      ^ = {{ 'pages.contractors.dummy-contrat-prefix' | translate }}<br>
                      * = {{ 'pages.contractors.transitional-contrat-prefix' | translate }}
                    </span>
                    <div class="row row-space">
                      <div class="col-md align-items-center">
                        {{ "pages.team-settings.contract-number" | translate}}:
                        {{selectedTeamContractNumber}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters mt-3" *ngIf="isContractor(selectedTeam.handlingParty)">
              {{ "pages.team-settings.in-house-handling-team" | translate}}
    
              <ng-select
                id="team_in-house-handling-team_dropdown"
                class="handling-team-dropdown"
                [disabled]="!hasEditionPermission"
                [items]="inHouseHandlingTeamOption" 
                [(ngModel)]="selectedTeam.handlingTeam" 
                bindLabel="name"
                bindValue="_id">
              </ng-select>
            </div>
    
            <div class="row mt-3" *ngIf="isInhouse(selectedTeam.handlingParty)">
              <ng-container *ngFor="let data of inHouseContactSequencesViewData">
                <div class="col-md information-box">
                  <div class="row">
                    <div class="col-md d-flex align-items-center justify-content-center {{data.titleCss}}">
                      <span>{{data.name | translate}}</span>
                    </div>
                  </div>
                  <ng-container *ngFor="let i of data.contactSequencesIndex">
                    <div class="row teamlist">
                      <i class="fas fa-arrow-down"></i>
                    </div>
                    <div class="row teamlist">
                      <ng-select id="team_{{data.id}}-{{i}}-contact-persons_dropdown" 
                        [multiple]="true" [clearable]="false"
                        placeholder="{{getContactSequencePlaceholder(i) | translate}}"
                        [disabled]="!hasEditionPermission"
                        [items]="selectedTeam.members"
                        [(ngModel)]="selectedTeam.contactSequences[data.priority][i]">
                      </ng-select>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
    
            <ng-container *ngIf="hasEditionPermission && !isLoadingTeams">
              <div class="row mt-3">
                <div class="col-md d-flex justify-content-center">
                  <app-submit-button 
                    [id]="'settings-team_save_button'"
                    [text]="'global.save'"
                    [buttonClass]="'standard brand-amber'"
                    [iconClass]="'fa-save'"
                    [isDisabled]="saveButtonDisabled"
                    [isLoading]=""
                    (onClick)="onSaveButtonClicked()" 
                  ></app-submit-button>
                  <app-submit-button 
                    [id]="'settings-team_delete_button'"
                    [text]="'pages.team-setting.delete-team'"
                    [buttonClass]="'ml-2 standard brand-red'"
                    [iconClass]="'fa-times'"
                    [isDisabled]="deleteButtonDisabled"
                    [isLoading]=""
                    (onClick)="onDeleteButtonClicked()" 
                  ></app-submit-button>
                </div>
              </div>
            </ng-container>
          </ng-template>    
      
      </div>
    </div>
  </div>
</div>