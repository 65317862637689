<div class="wrapper">
    <app-nav [navbarTitle]="'side-bar.ccep-jm-account-review' | translate" [showActionSidebarIcon]="true"></app-nav>

    <app-sidebar #sideBar></app-sidebar>

    <ng-container *ngIf="actionButtonData && actionButtonData.length > 0 && step === 1">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
            (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <!-- Page Content -->
    <div id="content">
        <div class="upload-template-container">
            <div class="header">
                <div class="title">
                    {{ 'pages.ccep-jm-account-review.upload-template.title' | translate }}
                    <ng-container *ngIf="step === 1">
                        <button class="btn custom-btn" (click)="checkHistory()">
                            <i class="fa fa-history"></i>
                        </button>
                    </ng-container>
                </div>
                <div class="step-container">
                    <div class="step">
                        <div class="icon"><img [src]="step === 1 ? step1ActiveSVG : step1SVG" alt=""></div>
                        <div class="name" [ngStyle]="{'color': step === 1 ? '#3b8cc1' : '#666666'}">{{
                            'pages.ccep-jm-account-review.upload-template.step1.name' |
                            translate }}</div>
                    </div>
                    <div class="arrow"><img [src]="arrowSVG" alt=""></div>
                    <div class="step">
                        <div class="icon"><img [src]="step === 2 ? step2ActiveSVG : step2SVG" alt=""></div>
                        <div class="name" [ngStyle]="{'color': step === 2 ? '#3b8cc1' : '#666666'}">{{
                            'pages.ccep-jm-account-review.upload-template.step2.name' |
                            translate }}</div>
                    </div>
                </div>
            </div>
            <div class="content">
                <ng-template [ngIf]="step === 1">
                    <div class="step-1">
                        <div class="instruction">
                            {{ 'pages.ccep-jm-account-review.upload-template.step1.instruction' | translate }}
                        </div>
                        <app-file-selector [fileAcceptType]="'.xlsx'" [clear]="clearUploadedTemplate"
                            [allowDownloadFiles]="true" (updatedFile)="onUpdatedFile($event)"></app-file-selector>
                        <div class="button-row">
                            <app-submit-button #uploadBtn text="global.upload" buttonClass="standard brand-amber my-1"
                                iconClass="fa-upload" [isDisabled]="!selectedFile" [isLoading]="false"
                                (onClick)="uploadTemplate(uploadBtn)"></app-submit-button>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="step === 2">
                    <div class="step-2 w-100">
                        <div class="line">
                            {{ 'pages.ccep-jm-account-review.upload-template.step2.instruction-1' | translate }}
                        </div>
                        <div class="line">
                            {{ 'pages.ccep-jm-account-review.upload-template.step2.instruction-2' | translate }}
                            <button class="btn custom-btn" (click)="checkHistory()">
                                <i class="fa fa-history"></i>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<custom-slider-panel #downloadTemplatePanel [widthPx]=600 [fixedPanel]="false">
    <div class="download-template-container">
        <div class="header">
            <h3>{{ 'pages.ccep-jm-account-review.upload-template.download-template.title' | translate }}</h3>
            <app-submit-button #downloadBtn text="global.download" buttonClass="standard brand-blue"
                iconClass="fa-download" [isDisabled]="!workCentres.length" [isLoading]="false"
                (onClick)="downloadTemplate(downloadBtn)"></app-submit-button>
        </div>
        <div class="content">
            <div class="control-row">
                <div class="label">
                    <span class="mandatory-indicator">* </span> {{
                    'pages.ccep-jm-account-review.upload-template.download-template.label.work-centre' | translate}}
                </div>
                <div class="value">
                    <ng-select [(ngModel)]="workCentres" bindLabel="label" bindValue="value" [items]="workCentreList"
                        [closeOnSelect]="false" [clearable]="true" [multiple]="true" dropdownPosition="bottom"
                        (change)="onWorkCentreChange()">
                        <ng-container *ngIf="workCentreList.length">
                            <ng-template ng-header-tmp>
                                <button (click)="selectAllWorkCentres()" class="btn btn-sm btn-secondary">
                                    {{ (isSelectedAllWorkCentre ?'global.unselect-all' :'global.select-all') | translate }}
                                </button>
                            </ng-template>
                        </ng-container>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
</custom-slider-panel>