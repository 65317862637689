import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '@services/authorization.service';
import { ActionButtonService } from '@services/action-button.service';
import { ActionButtonContent, ActionButtonDefinition, ActionButtonTypeJobCard, ActionButtonType } from '@enum/action-button';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';

import { JobcardJobDetailsComponent } from 'src/app/ui/components/jobcard-job-details/jobcard-job-details.component';
import { JobcardClientInformationComponent } from 'src/app/ui/components/jobcard-client-information/jobcard-client-information.component';
import { JobcardTaskProgressComponent } from 'src/app/ui/components/jobcard-task-progress/jobcard-task-progress.component';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { JobCardAssignStaffFormComponent } from 'src/app/ui/components/job-card-assign-staff-form/job-card-assign-staff-form.component';
import { constant } from 'lodash';

@Component({
  selector: 'app-job-card-standalone-create',
  templateUrl: './job-card-standalone-create.component.html',
  styleUrls: ['./job-card-standalone-create.component.scss']
})
export class JobCardStandaloneCreateComponent implements OnInit {
  @ViewChild('sectionJobDetails', { static: false }) sectionJobDetails: JobcardJobDetailsComponent;
  @ViewChild('sectionClientInfo', { static: false }) sectionClientInfo: JobcardClientInformationComponent;
  @ViewChild('sectionTaskProgress', { static: false }) sectionTaskProgress: JobcardTaskProgressComponent;
  @ViewChild('assignStaffPanel', { static: true }) assignStaffPanel: CustomSliderPanelComponent;
  @ViewChild('assignStaffForm', { static: false }) assignStaffForm: JobCardAssignStaffFormComponent;

  pageMode = JMENUM.JMPageMode.CREATE;
  pageTitle = JMLanguage.translate('pages.jobcard.create.standalone');
  // action buttons
  actionButtonData = [];

  // page Data
  snData: JMOBJ.ServiceNotification;
  jobCardData: JMOBJ.JobCard;

  // component data
  componentParameters = null;
  assignStaffFormParam = null;

  // ui state
  isProcessingJobCardCreate = false;
  isLoadingWorkCentreStaffs = false;


  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private actionBtnService: ActionButtonService) {


    this.componentParameters = {
      disabledBreakdown: true,
      userWorkCenters: this.getUserWorkCenterList(),
      onClickHaEquipmentNo: this.onClickHaEquipmentNo.bind(this),
      onClearHaEquipmentNumber: this.onClearHaEquipmentNumber.bind(this),
    };

    this.assignStaffFormParam = {
      teamMembers: [],
      teamMembersInitial: [], // for cache
      workCentreStaffs: [], // for cache
      isLoading: false,
      viewOnly: false,
      workCentre: '',
      handlingParty: null,
      showWorkCentreStaffs: false,
      onAddMemberClicked: this.updateParamWithoutFormReinit.bind(this),
      onRemoveMemberClicked: this.updateParamWithoutFormReinit.bind(this),
      onOfficerInChargeChanged: this.updateParamWithoutFormReinit.bind(this),
      onSubmitClicked: this.onCreateJobCard.bind(this),
    }
  }

  ngOnInit() {
    this.checkPermission();
    this.initData();
    this.initActionButtons();
  }

  checkPermission() {
    const hasJobCardCreatePermission = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CREATE);
    if (!hasJobCardCreatePermission) {
      this.router.navigate(['/dashboard']);
      AppDelegate.openSnackBar(JMLanguage.translate('pages.job-card.create.toast-msg.permission-denied'));
    }
  }

  async initData() {
    this.snData = new JMOBJ.ServiceNotification();
    this.jobCardData = new JMOBJ.JobCard();
    
    this.snData.contactNumber = [''];
    this.snData.eamData = new JMOBJ.EamData();

    this.jobCardData.jobNature = JMENUM.JobNature.STANDALONE;
    this.jobCardData.orderType = JMENUM.OrderType.SLA_JOB;
    // this.jobCardData.matType = JMENUM.Mat.M20;

    await this.requestStandaloneDefaultStartEndTime();
    this.sectionTaskProgress.setValueFromJobCard();
  }

  // API
  async requestStandaloneDefaultStartEndTime() {
    const request = new JM.JMRequestJobCardsGetSupervisionJobDefaultTime;
    const response: JM.JMResponseJobCardsGetSupervisionJobDefaultTime = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    };
    this.jobCardData.completionTime = response.payload.completionTime;
    this.jobCardData.startTime = response.payload.startTime;
  }

  async requestCreateStandaloneJob() {
    const request = new JM.JMRequestJobCardsCreateStandaloneJob;
    request.matType = this.jobCardData.matType;
    request.jobDescription = this.jobCardData.jobDescription;
    request.teamId = this.jobCardData.snTeamId;
    request.assignedPersons = this.jobCardData.assignedPersons;
    request.officerInCharge = this.jobCardData.officerInCharge;
    // TODO: 
    request.client = this.snData.client;

    request.breakdown = this.jobCardData.breakdown;
    request.appointmentTime = this.jobCardData.appointmentTime;
    request.startTime = this.jobCardData.startTime;
    request.completionTime = this.jobCardData.completionTime;
    request.malfunctionStartTime = this.jobCardData.malfunctionStartTime;
    request.malfunctionEndTime = this.jobCardData.malfunctionEndTime;
    request.responseToClientTime = this.jobCardData.responseToClientTime;
    request.receivedTime= this.jobCardData.receivedTime;
    request.plannedCompletionTime= this.jobCardData.plannedCompletionTime;

    this.isProcessingJobCardCreate = true;
    const response: JM.JMResponseJobCardsCreateStandaloneJob = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    };
    this.isProcessingJobCardCreate = false;

    this.router.navigate(['/job-card/view/' + response.payload.jobCardNumber]);
  }

  private async requestWorkCentrePostList() {
    if (this.isLoadingWorkCentreStaffs) { return console.warn('Is loading workCentreStaffs...'); }
    
    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.authorizations = { workCenters: this.assignStaffFormParam.workCentre };
    request.active = JMENUM.RequestActive.ACTIVE;

    this.isLoadingWorkCentreStaffs = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingWorkCentreStaffs = false;

    if (!response || response.error || response == null || response.code !== 200 || !response.payload) {
      return AppDelegate.openErrorBar(response);
    }
    if (!response.payload.records || !response.payload.records.length) { return console.warn('No valid payload data...'); }

    const staffIdArr = response.payload.records.map(({name}) => name);
    this.assignStaffFormParam.workCentreStaffs = staffIdArr; // for cache
    this.assignStaffFormParam.teamMembers = this.assignStaffFormParam.teamMembersInitial.concat(staffIdArr);
    this.assignStaffForm.requestPostAssignments();
    this.assignStaffForm.renderTables();
  }


  getUserWorkCenterList() {
    const { clusters, workCenters } = Session.userInfo.authorizations;
    const workCentreDict = (Session && Session.workCentreDict) || {};
    const workCentersFromClusters = Object.values(workCentreDict)
      .filter(({HACluster}) => HACluster && clusters.indexOf(HACluster) !== -1)
      .map(({workCentreCode}) => workCentreCode);

    return workCenters.concat(workCentersFromClusters)
      .filter((value, index, self) => self.indexOf(value) === index); // make distinct values
  }

  onClickHaEquipmentNo() {
    // this.haEquipmentListPanel.toggle();
    // this.haEquipmentListForm.refreshTable();
  }
  onClearHaEquipmentNumber() {
    // this.selectedHaEquipment = cloneDeep(initSelectedHaEquipment);
    // this.snData.eamData.assetNumber = null;
  }

  updateParamWithoutFormReinit ({ assignedPersons, officerInCharge }) {
    // dont reassign the whole 'jobCardData' obj
    // just reassign the properties or there will be ngChange-->formReinit
    this.jobCardData.assignedPersons = assignedPersons;
    this.jobCardData.officerInCharge = officerInCharge;
  }

  updateSnData = ( data : { [key: string] : any } ) => {
    this.snData = {
      ...this.snData,
      ...data
    }
  }

  updateJobCardData = ( data : { [key: string] : any } ) => {
    this.jobCardData = {
      ...this.jobCardData,
      ...data
    };
  }

  onTeamChange = ({members, workCentre, handlingParty}) => {
    // clear data

    // isLoading: false,
    // viewOnly: false,
    this.assignStaffFormParam = {
      ...this.assignStaffFormParam,
      // ...cloneDeep(initAssignStaffFormParam),
      showWorkCentreStaffs: false,
      workCentreStaffs: [],
      teamMembers: members,
      teamMembersInitial: members,
      workCentre,
      handlingParty,
    };
    this.jobCardData = {
      ...this.jobCardData,
      assignedPersons: [],
      officerInCharge: null,
      handlingParty: handlingParty
    };
  }

  onCreateJobCard() {
    AppDelegate.showPopUpAlert(JMLanguage.translate('action.button.popup.create-job-card'), null, [
      {
        name: JMLanguage.translate('global.yes'),
        handler: () => this.requestCreateStandaloneJob(),
      },
      { name: JMLanguage.translate('global.no') },
    ]);
  }

  validation() {
    let result = [
      this.sectionJobDetails.validation(),
      this.sectionClientInfo.validation(),
      // this.sectionHAInfo.validation(),
      this.sectionTaskProgress.validation(),
    ]
    return result.every(isValid => isValid);
  }

  resetForm() {
    this.initData();

    // this.selectedHaEquipment = {...this.selectedHaEquipment, ...cloneDeep(initSelectedHaEquipment)};
    this.sectionJobDetails.errorFields = {};
    this.sectionClientInfo.errorFields = {};
    // Below components have their own local states. need to reset them as well
    // this.sectionHAInfo.resetForm();
    // this.sectionHAInfo.resetAllErrorFields();
    this.sectionTaskProgress.resetForm();
    this.sectionTaskProgress.resetAllErrorFields();
  }

  // Action Button Bar
  private initActionButtons(): void {
    this.addActionBtn(ActionButtonTypeJobCard.create, this.createBtnAction.bind(this));
    this.addActionBtn(ActionButtonTypeJobCard.close, this.closeBtnAction.bind(this));
    this.addActionBtn(ActionButtonTypeJobCard.clear, this.clearBtnAction.bind(this));
  }

  addActionBtn(buttonStatus: ActionButtonTypeJobCard, button1CallBack?: Function, button2CallBack?: Function): void {
    let actionButton = this.actionBtnService.addActionBtn(
      ActionButtonType.jobCard,
      buttonStatus,
      button1CallBack,
      button2CallBack
    );
    actionButton && this.actionButtonData.push(actionButton);
  }

  createBtnAction() {
    console.log(this.jobCardData);
    if (this.validation()) {
    //   // if the handling team is non-p team, it is not needed to slide a panel for user to assign staff
    //   if (this.assignStaffFormParam.handlingParty !== JMENUM.HandlingParty.NON_PMSMC) {
        this.assignStaffPanel.toggle();
        this.assignStaffForm.renderTables();
        this.assignStaffForm.requestPostAssignments();
    //   } else {
    // this.onCreateJobCard();
    //   }
    }
  }

  closeBtnAction() {
    AppDelegate.navigate(['/dashboard']);
  }

  clearBtnAction() {
    this.resetForm();
  }

  // slide panel handler
  onShowAllWorkCenterStaffChecked = (e) => {
    if (e.target.checked) {
      // if on, add workCenter staffs with team members tgt
      if (this.assignStaffFormParam.workCentreStaffs.length) {
        // if loaded workCenter staffs, use the cached loaded values to prevent multiple api calls
        this.assignStaffFormParam.teamMembers = this.assignStaffFormParam.teamMembersInitial.concat(
          this.assignStaffFormParam.workCentreStaffs
          );
      } else {
        this.requestWorkCentrePostList();
      }
    } else {
      // if off, show team members only
      this.assignStaffFormParam.teamMembers = this.assignStaffFormParam.teamMembersInitial;
    }
    this.assignStaffForm.renderTables();
  }
}
