<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [breadcrumbs]="breadcrumbs"
  ></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding">
    <div class="row mx-0">
      <div class="col-xl-6 col-12">
        <app-pm-job-description
          #pmJobDescriptionElem
          class="d-block mb-5"
          [job]="job"
          [pageMode]="pageMode"
        >
        </app-pm-job-description>
        <app-pm-job-client-information
          #pmJobClientInformationElem
          class="d-block mb-5"
          [job]="job"
          [pageMode]="pageMode"
        >
        </app-pm-job-client-information>
      </div>
      <div class="col-xl-6 col-12">
        <app-pm-job-job-progress
          #pmJobJobProgressElem
          class="d-block mb-5"
          [job]="job"
          [pageMode]="pageMode"
        >
        </app-pm-job-job-progress>
        <app-pm-job-equipment-information
          #pmJobEquipmentInformationElem
          class="d-block mb-5"
          [periodId]="job?.period['_id']"
          [pmJobNumber]="job?.pmJobNumber"
          [selectedEquipmentsNumber] = "job?.equipmentList?.length"
          [pageMode]="pageMode"
        >
        
        </app-pm-job-equipment-information>

        <app-pm-job-remarks
          [job]="job"
        >
          
        </app-pm-job-remarks>

        <app-pm-job-ccs-information
          #pmJobCcsInformationElem
          class="d-block mb-5"
          [pageMode]="pageMode"
          [job]='job'
          (onEquipmentRemove)="initPage()"
        >
        </app-pm-job-ccs-information>

        <app-attachment-detail
          *ngIf="job?.attachments && job.attachments.length > 0"
          #jobcardAttachmentDetailElem
          [pmJob]="job"
          [page]="JMPage.PMJOB"
          [currentPageMode]="pageMode"
        >
        </app-attachment-detail>
      </div>
    </div>
  </div>

  <custom-slider-panel #obtain_ccs_so_panel [widthPx]=800>
    <app-ccs-so-obtain-form #obtain_ccs_so_form
     [pmJob]="job"
     (onComplete)="onCompleteCcsSo()"
    ></app-ccs-so-obtain-form>
  </custom-slider-panel>

  <custom-slider-panel #cancel_pm_job_panel [widthPx]=600>
    <app-cancel-pm-job-form
      [isLoading]="isCancelPmJobPanelLoading"
      (onSubmit)="cancelPmJob($event)"
    ></app-cancel-pm-job-form>
  </custom-slider-panel>

  <custom-slider-panel #reject_approval_panel [widthPx]=600>
    <approval-reject-reason-panel
      [approvalList]="approvalList"
      [showDefaultResultMsg]="true"
      (onSubmit)="onApprovalRejected($event)"
    ></approval-reject-reason-panel>
  </custom-slider-panel>

  <!-- Action Sidebar -->
  <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
    <app-action-sidebar
      [buttonData]="actionButtonData"
      [disabled]="disabledActionSideBar"
      (clickEvent)="actionBtnService.onActionButtonClick($event)"
    >
    </app-action-sidebar>
  </ng-container>
</div>
