/*
  version 20201123:
   - handle button disable when calling API
   - popupDialog appear after slider panel submit

  version 20201119:
   - change the checking for timesheet "N" case
   - add CCS SO Number to Tablex
   - new field for jobsummary call to handle NA case

  version 20201117:
   - update jobcard summary api
   - add action button dim control
   - change translate to use JMLanguage

  version 20201109
    - call un-teco api
    - update summary after un-teco 

  version 20201105
    - default searching criteria 
    - advanced search handling
    - call job card summary
    - get team name by jobCard?/workCentre?

  version 20201030:
   - create this page

*/

/*
  not yet complete
   - get quotationSubmitted
*/

import { formatDate } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionButtonUnTeco, ActionButtonDefinition, ActionButtonType } from '@enum/action-button';
import { TablexColumnType, TablexColumnHorizontalAlign, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { BasePage } from 'src/app/ui/model/base/base';
import { ParameterService } from '@services/parameter.service';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import { pad } from 'src/lib/presenter/Formatter';
import { Session }  from 'src/app/services/session';
import { Constants } from 'src/constants';
import { Options } from 'src/app/ui/pages/teco/teco.component';
@Component({
  selector: 'app-un-teco',
  templateUrl: './un-teco.component.html',
  styleUrls: ['./un-teco.component.scss']
})
export class UnTecoComponent extends BasePage implements OnInit {
  @ViewChild('un-teco-table', { static: true }) unTecoTable;
  @ViewChild('teco-advanced-search-panel', { static: true }) tecoAdvancedSearchPanel;
  @ViewChild("unTecoReasonPanel", { static: true }) openUnTecoReasonPanel;
  @ViewChild("unTecoReasonForm", { static: true }) unTecoReasonForm;

  actionButtonData = [];
  uiIsLoading: boolean = true;
  lang = JMLanguage.getCurrentLanguage() === 'zh' ? 'zh' : 'en';

  // Table
  selectedTableRows: any = [];
  selectedRows: any = [];
  tablexParam: any = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  commonHeader = {
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col",
  };

  // Dropdown List
  dropdownNameList: string[] = [];
  dropdownAttr: any[] = [];
  dropDownMap: Map<String, Options[]> = new Map();
  usageTypeFilter = ['haEquipment','equipmentTags']
  isExpanded = true;

  tableXHeader: any[] = [];

  jobCardSummary: any = [];

  advancedSearchPara = {};

  searchingcriteria = {};

  //constractor Handling Party.....
  constractorHandlingParty: string[] = ["2", "3"];

  selectedCount = 0;

  unTecoParam = {
    unTecoReasonCode: undefined,
    unTecoRemarks: undefined,
    isPanelSubmitLoading: false,
    isAcknowledgeAction: false,
    popUpSubMessage: ""
  };

  constructor(injector: Injector, private parameterService: ParameterService) { super(injector); }

  ngOnInit() {
    if (!this.authorizationService.hasPermissions([JMENUM.Permission.JOBCARD_UNTECO_ALL, JMENUM.Permission.JOBCARD_ZS01_UNTECO, JMENUM.Permission.JOBCARD_ZS02_UNTECO, JMENUM.Permission.JOBCARD_ZS04_UNTECO], false)) {
      this.router.navigate(["/dashboard"]);
    }
    this.advancedSearchPara = {
      fullTextSearch: false,
      defaultPanelOpen: true,
      title: "pages.un-teco.page-header",
      isTecoPage: false,
    };
    this.initActionButtons();
  }

  async ngAfterViewInit(){
    await this.requestDropdownSummary();
    this.initTablex();
    this.loadColumnSettingsFromLocal();
    this.requestJobCardSummary();
  }

  //Action Button Function-----------------------------------------------------------------------
  initActionButtons(): void {
    this.actionButtonData = [];
    this.addActionBtn(ActionButtonUnTeco.unTeco);
  }

  addActionBtn(buttonStatus: ActionButtonUnTeco): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.unTeco][buttonStatus];
    let buttonHandler = () => { };

    switch (buttonStatus) {
      case ActionButtonUnTeco.unTeco:
        buttonHandler = () => {
          if (this.selectedTableRows.length > 0) {
            this.selectedCount = this.tablexParam["selectedRowCount"];
            this.openUnTecoReasonPanel.toggle();
          }
          else {
            this.openSnackBar(JMLanguage.translate("popupError.no-job-card"));
          }
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : "global.yes",
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : "global.no",
        handler: () => { this.uiIsLoading = false }
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  public onActionButtonClicked(actionButton: any) {
    if (this.selectedTableRows.length > 0) {
      if (actionButton.showPopup) {
        let buttons = actionButton.buttons;
        buttons.forEach(button => {
          button.name = JMLanguage.translate(button.name);
        });
        this.popUpHandle(actionButton.popupTitle, buttons);
      }
      else {
        if (this.selectedTableRows.length > 1) {
          this.unTecoParam.popUpSubMessage = JMLanguage.translate("popup.selected-job-cards.title") + this.tablexParam['selectedRowCount'];
        }
        else {
          this.unTecoParam.popUpSubMessage = JMLanguage.translate("popup.job-card-number.title") + this.selectedTableRows[0][1].value;
        }
        actionButton.buttons[0].handler();
      }
    }
    else {
      this.openSnackBar(JMLanguage.translate("popupError.no-job-card"));
    }
  }

  onAdvancedSearch(event) {
    this.searchingcriteria = event;
    this.currentPage = 1;
    this.requestJobCardSummary();
  }

  popUpHandle(popupTitle, buttons) {
    let subMsg;
    if (this.selectedTableRows.length > 1) {
      subMsg = JMLanguage.translate("popup.selected-job-cards.title") + this.tablexParam['selectedRowCount'];
    }
    else {
      subMsg = JMLanguage.translate("popup.job-card-number.title") + this.selectedTableRows[0][1].value;
    }
    this.showPopUpAlert(JMLanguage.translate(popupTitle), subMsg, buttons);
  }

  //--------------------------------------------------------------------------------------------

  //Tablex Function-----------------------------------------------------------------------------
  public renderTable() {
    this.tablexParam.headers = this.tableXHeader.filter((col) => {
      return this.tablexParam.selectedColId.includes(col.id);
    });
    this.tablexParam['content'] = this.jobCardSummary.map(data => {
      let { 
        eamDropdown,
        equipmentTagDropdown 
      } = data
      let additionalDataList = new Map();
      let additionalValues = [];
      let timesheetSubmitted = JMLanguage.translate('teco.timesheet-submitted.na');
      let timesheetSubmittedClass = "";
      if (data.timesheetSubmitted == true) {
        timesheetSubmitted = JMLanguage.translate('teco.timesheet-submitted.yes');
      }
      else if (this.checkTimesheetRequired(data.timesheetSubmitted, data.handlingParty)) {
        timesheetSubmitted = JMLanguage.translate('teco.timesheet-submitted.no');
        timesheetSubmittedClass = "class='red'";
      }
      
      this.dropdownNameList.forEach(dropdownName=>{additionalDataList.set(dropdownName,null)}) //init additionalRow with order by dropdownNameList

      //eamDropdown
      if(eamDropdown!=null){
        this.setAdditionalDataList(eamDropdown,additionalDataList)
      }

      //equipmentTagDropdown
      if(equipmentTagDropdown!=null){
        this.setAdditionalDataList(equipmentTagDropdown,additionalDataList)
      }

      additionalDataList.forEach((value, key) => {
        additionalValues.push(value)
      })
      
      let fullRow = [
        data.version,                                                                                //version
        { url: "/job-card/view/" + data.jobCardNumber, value: data.jobCardNumber },  //jobCardNumber
        data.ccsServiceOrderNumber,                                                                  //ccs so
        data.handlingParty ? JMLanguage.translate('team.handling-party.' + data.handlingParty) : "", //handlingParty
        data.workCentre,                                                                             //workCentre
        data.snTeamName,                                                                             //team name 
        data.orderType,                                                                              //orderType
        (data.completionTime) ? formatDate(data.completionTime, "dd/MM/yyyy HH:mm", 'en-US') : "",   //completionTime
        ["<span " + timesheetSubmittedClass + ">" + timesheetSubmitted + "</span>"],                 //timesheetSubmitted
        "NA",                                                                                        //TODO: Teco2: not yet get quotationSubmitted
        (data.tecoStatus) ? JMLanguage.translate('teco.status.' + data.tecoStatus) : "",             //tecoStatus   
        data.tecoErrorMessage                                                                        //tecoErrorMessage
        ]
        
        fullRow = fullRow.concat(additionalValues)

        let rowFiltered = []
        fullRow.forEach((value, i) => {
          if (this.tablexParam.selectedColId.includes(this.tableXHeader[i].id)) {
            rowFiltered.push(fullRow[i]);
          }
        });
        
        return rowFiltered;
    });
  }

  private setAdditionalDataList(source, dataList){
    for (const [key, value] of Object.entries(source)) {
        if(this.dropDownMap.get(key) != undefined){
          this.dropDownMap.get(key).map(options=>{
            if(options.key === value && options.description[`${this.lang}`]!=undefined){
              dataList.set(key,options.description[`${this.lang}`])
            }
          })
        }
    }
  }

  appendAdditionalDropDownHeaders(tableXHeader){
  
    this.dropdownAttr.forEach((dropdownAttr, index)=>{
      if(dropdownAttr.key != undefined || dropdownAttr.key != null){
        tableXHeader.push({
          id: dropdownAttr.key,
          name: dropdownAttr.description[`${this.lang}`] !=undefined ? dropdownAttr.description[`${this.lang}`] : '',
          ...this.commonHeader
        })
      }
    })

    return tableXHeader

  }

  public initTablex() {
    this.tableXHeader = [
      {
        id: 'objId',
        name: "",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col d-none",
      },
      {
        id: 'jobCardNumber',
        name: "pages.un-teco.table-column.job-card-number",
        enableFilter: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsSoNumber',
        name: "pages.un-teco.table-column.ccs-so-number",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'handlingParty',
        name: "pages.un-teco.table-column.handling-party",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'workCentre',
        name: "pages.un-teco.table-column.work-centre",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'team',
        name: "pages.un-teco.table-column.team",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'orderType',
        name: "pages.un-teco.table-column.order-type",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'completionTime',
        name: "pages.un-teco.table-column.completion-time",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: "col",
      },
      {
        id: 'timesheetSubmitted',
        name: "pages.un-teco.table-column.timesheet-submitted",
        enableFilter: false,
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: "col",
      },
      {
        id: 'quotationSubmitted',
        name: "pages.un-teco.table-column.quotation-submitted",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "d-none",//TODO: unTeco2: not yet get quotationSubmitted
      },
      {
        id: 'tecoStatus',
        name: "pages.un-teco.table-column.teco-status",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'unTecoErrorMessage',
        name: "pages.un-teco.table-column.teco-error-message",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col-2",
      }
    ];

    this.tableXHeader = this.appendAdditionalDropDownHeaders(this.tableXHeader);

    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      minifyButton: true,
      enableSelectedRowCount: true,
      enableSelectedAll: true,
      tableRow: "row",
      tableClass: "teco-table",
      tableWrapperClass: "table-min-width",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      onColFiltered: this.onColFiltered,
      enableStickyHeader: false,
      content: [],
      highlightedRows: [],
      customClassRows: [],
      selectedColId: [

        'jobCardNumber',
        'ccsSoNumber',
        'handlingParty',
        'workCentre',
        'team',
        'orderType',
        'completionTime',
        'timesheetSubmitted',
        'quotationSubmitted',
        'tecoStatus',
        'tecoErrorMessage',
        // 'createdBy',
      ],
      fullColNameList:this.tableXHeader.map((col) => {
        return { id: col.id, name: col.name };
      }),
    };
  }

  onColFiltered = (selectedColId) => {
    this.tablexParam.selectedColId = selectedColId;
    this.renderTable();
    this.saveColumnSettingsToLocal();
  };

  public onRowClicked = (index, row) => {
    let findRow = this.selectedTableRows.find(item => { return item.every((val, index) => val === row[index]); })
    if (this.selectedTableRows.indexOf(findRow) > -1) {
      this.tablexParam['highlightedRows'][index] = false;
      this.selectedTableRows.splice(this.selectedTableRows.indexOf(findRow), 1);
    } else {
      this.tablexParam['highlightedRows'][index] = true;
      this.selectedTableRows.push(row);
    }
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
    //checking to dim the button
    //this.checkDimButton();
  }

  public onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
    this.requestJobCardSummary();
  }

  public onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
    this.requestJobCardSummary();
  }
  //--------------------------------------------------------------------------------------------

  //API CALL------------------------------------------------------------------------------------
  async requestDropdownSummary(key?, deletedRecord?) {
    const request = new JM.JMRequestDropdownSummary();
    request.system = [Constants.SYSTEM_NAME];
    request.usageType = this.usageTypeFilter;
    request.active = true;
    const response: JM.JMResponseDropdownSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload.totalCount <= 0) {
      if (deletedRecord) {
        this.requestDropdownSummary();
      } else {
        this.openSnackBar(JMLanguage.translate("global.no-record-found"));
      }
      return;
    }  else {

      this.dropdownAttr = response.payload.records.map(dropdown => {
        let data = {
          _id: dropdown._id,
          key: dropdown.key,
          descriptionEn: dropdown.description.en,
          descriptionZh: dropdown.description.zh,
          description: dropdown.description,
          usageType: dropdown.usageType,
          mappingType: dropdown.mappingType,
          options: dropdown.options,
          mandatoryJobNature: dropdown.mandatoryJobNatureList,
          active: dropdown.active
        };

        this.dropDownMap.set(dropdown.key,dropdown.options as Options[])

        return data;
      });
      this.dropdownNameList = this.dropdownAttr.map(dropdown => dropdown.key);
    }
  }

  public async requestJobCardSummary() {
    const request: JM.JMRequestJobCardsJobCardSummary = new JM.JMRequestJobCardsJobCardSummary();
    request.parameters = [
      "version",
      "snNumber",
      "jobCardNumber",
      "ccsServiceOrderNumber",
      "handlingParty",
      "workCentre",
      "snTeamName",
      "orderType",
      "completionTime",
      "timesheetSubmitted",
      /*"Quotation submitted?",*/ //TODO: unTeco2: not yet get quotationSubmitted
      "tecoStatus",
      "tecoErrorMessage",
      "eamDropdown",
      "equipmentTagDropdown"
    ];
    //default showing status
    request.tecoStatusList = this.parameterService.allUnTecoPageStatus();
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    request.sortBy = "jobCardNumber";
    request.sortOrder = -1;
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    request.financialCutOffFrom = tomorrow;
    request.statusList = [4];

    if (this.searchingcriteria) {
      request.filter = {};
      if (this.searchingcriteria["createTimeFrom"]) {
        request.createTimeFrom = new Date(this.searchingcriteria["createTimeFrom"].year + '-' + pad(this.searchingcriteria["createTimeFrom"].month) + '-' + pad(this.searchingcriteria["createTimeFrom"].day) + "T00:00:00.000Z");
      }
      if (this.searchingcriteria["createTimeTo"]) {
        request.createTimeTo = new Date(this.searchingcriteria["createTimeTo"].year + '-' + pad(this.searchingcriteria["createTimeTo"].month) + '-' + pad(this.searchingcriteria["createTimeTo"].day) + "T23:59:59.999Z");
      }
      if (this.searchingcriteria["completionTimeFrom"]) {
        request.completionTimeFrom = new Date(this.searchingcriteria["completionTimeFrom"].year + '-' + pad(this.searchingcriteria["completionTimeFrom"].month) + '-' + pad(this.searchingcriteria["completionTimeFrom"].day) + "T00:00:00.000Z");
      }
      if (this.searchingcriteria["completionTimeTo"]) {
        request.completionTimeTo = new Date(this.searchingcriteria["completionTimeTo"].year + '-' + pad(this.searchingcriteria["completionTimeTo"].month) + '-' + pad(this.searchingcriteria["completionTimeTo"].day) + "T23:59:59.999Z");
      }
      if (this.searchingcriteria["tecoStatus"] && this.searchingcriteria["tecoStatus"].length > 0) {
        request.tecoStatusList = this.searchingcriteria["tecoStatus"];
      }
      if (this.searchingcriteria["workCentre"]) {
        request.workCentre = this.searchingcriteria["workCentre"];
      }
      if (this.searchingcriteria["team"]) {
        request.snTeamIdList = [this.searchingcriteria["team"]];
      }
      if (this.searchingcriteria["assignedPersonList"] && this.searchingcriteria["assignedPersonList"].length > 0) {
        request.assignedPersonList = this.searchingcriteria["assignedPersonList"];
      }
      if (this.searchingcriteria["jobCardNumber"]) {
        request.filter["jobCardNumber"] = this.searchingcriteria["jobCardNumber"];
      }
      if (this.searchingcriteria["ccsServiceOrderNumber"]) {
        request.filter["ccsServiceOrderNumber"] = this.searchingcriteria["ccsServiceOrderNumber"];
      }
      if (this.searchingcriteria["orderType"] && this.searchingcriteria["orderType"].length > 0) {
        request.orderTypeList = this.searchingcriteria["orderType"];
      }
      if (this.searchingcriteria["handlingParty"]) {
        request.handlingPartyList = [this.searchingcriteria["handlingParty"]];
      }
      //handcode to handle timesheetSubmitted
      if (this.searchingcriteria["timesheetSubmitted"]) {
        switch (this.searchingcriteria["timesheetSubmitted"]) {
          case "yes":
            request.timesheetSubmitted = true;
            break;
          case "no":
            request.timesheetSubmitted = false;
            break;
          case "na":
            request.timesheetRequired = false;
            break;
        }
      }
      // request.quotationSubmitted
    }

    this.tablexParam["isLoadingTable"] = true;
    const response: JM.JMResponseJobCardsJobCardSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam["isLoadingTable"] = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.jobCardSummary = response.payload.records;
    this.renderTable();
    if (this.searchingcriteria) {
      this.selectedTableRows = [];
      this.tablexParam['highlightedRows'] = [];
      this.tablexParam['selectedRowCount'] = 0;
    }
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);

  }

  public async requestUnTeco() {
    const request: JM.JMRequestJobCardsUnTeco = new JM.JMRequestJobCardsUnTeco();
    request.unTecoList = this.getSelectedJobCardNumberList();

    this.handleDisableActionButton(ActionButtonUnTeco.unTeco, false);
    this.unTecoParam.isPanelSubmitLoading = true;
    const response: JM.JMResponseJobCardsUnTeco = await AppDelegate.sendJMRequest(request);
    this.handleDisableActionButton(ActionButtonUnTeco.unTeco, true);
    this.unTecoParam.isPanelSubmitLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    //update existing tablex
    this.updateExistingJobCardsSummary(response.payload.records);
    this.unTecoParam = {
      unTecoReasonCode: undefined,
      unTecoRemarks: undefined,
      isPanelSubmitLoading: false,
      isAcknowledgeAction: false,
      popUpSubMessage: ""
    };
    this.openUnTecoReasonPanel.close();
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.openSnackBar(JMLanguage.translate("pages.un-teco.message.un-teco", this.getJobCardsSummaryCount(response.payload.records)));
  }
  //--------------------------------------------------------------------------------------------

  //Layout reaction-----------------------------------------------------------------------------
  public onOpenFilterClicked() {
    this.isExpanded = !this.isExpanded;
  }

  public updateExistingJobCardsSummary(records) {
    records.forEach(record => {
      let jobCard = this.jobCardSummary.find(data => { return data.jobCardNumber == record.jobCard.jobCardNumber });
      jobCard.version = record.jobCard.version;
      jobCard.jobCardNumber = record.jobCard.jobCardNumber;
      jobCard.handlingParty = record.jobCard.handlingParty;
      jobCard.workCentre = record.jobCard.workCentre;
      jobCard.orderType = record.jobCard.orderType;
      jobCard.completionTime = record.jobCard.completionTime;
      jobCard.tecoStatus = record.jobCard.tecoStatus;
      jobCard.tecoErrorMessage = record.jobCard.tecoErrorMessage;
      jobCard.timesheetSubmitted = record.jobCard.timesheetSubmitted;
      if (record.errorMessage) {
        (jobCard.tecoErrorMessage) ? jobCard.tecoErrorMessage + record.errorMessage : jobCard.tecoErrorMessage = record.errorMessage;
      }
    });
    this.renderTable();
  }

  public getJobCardsSummaryCount(records) {
    let fail = 0;
    let success = 0;
    records.forEach(record => {
      if (record.errorMessage) {
        fail += 1;
      }
      else {
        success += 1;
      }
    });
    return [success, fail];
  }
  //--------------------------------------------------------------------------------------------

  //Other---------------------------------------------------------------------------------------
  private getSelectedJobCardNumberList() {
    let list = [];
    //TODO: missing unteco approver
    this.selectedTableRows.forEach(row => {
      list.push({
        version: row[0],                                //version
        jobCardNumber: row[1].value,                    //jobCardNumber
        unTecoCode: this.unTecoParam.unTecoReasonCode,  //Un-Teco ReasonCode
        unTecoRemarks: this.unTecoParam.unTecoRemarks   //Un-Teco Remarks
      });
    });
    return list;
  }

  //emit the slider panel submit
  public onSubmit(event) {
    this.requestUnTeco();
  }

  onLanguageChanged() {
  }

  handleDisableActionButton(button, isEnable) {
    let actionButton = this.actionButtonData.find(({ action }) => action == button);
    actionButton.isEnable = isEnable;
  }

  //may be change in future
  checkTimesheetRequired(timesheetSubmitted, handlingParty) {
    if ((timesheetSubmitted == false || !timesheetSubmitted) && handlingParty == 1) {
      return true;
    }
    return false;
  }
  //--------------------------------------------------------------------------------------------
  
  private saveColumnSettingsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.tablexParam.selectedColId));
      Session.setUntecoJobListSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  private loadColumnSettingsFromLocal = () : void => {
    try {
      let cache = Session.untecoJobListSettingsColumns;
      if (cache) {
        this.tablexParam.selectedColId = JSON.parse(JSON.stringify(cache))
      }
    } catch (e) {
      console.warn(e);
    }
  }

}
