import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from '@enum/tablexColumnType';

enum GetStockListParams {
  _id = '_id',
  reservationItem = 'reservationItem',
  locationCode = 'storageLocation.code',
  locationDescription = 'storageLocation.description',
  lastBatch = 'lastBatch',
  materialNumber = 'materialNumber',
  materialDescription = 'description',
  batchNumber = 'batchNumber',
  stockUnitList = 'stockUnitList',
}

enum StockTableColumnIndex {
  _id,
  locationCode,
  locationDescription,
  materialNumber,
  materialDescription,
  batchNumber,
  defaultstockUnit,
  defaultQtyRemain,
  action,
}

enum SelectedStockTableColumnIndex {
  _id,
  reservationItem,
  locationCode,
  locationDescription,
  materialNumber,
  materialDescription,
  batchNumber,
  stockUnit,
  qtyRemain,
  qtyRequired,
  qtyCommitted,
  qtyIssued,
  result,
  action,
}

const stockTableHeader = [
  {
    id: GetStockListParams._id,
    name: '_id',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'd-none',
  },
  {
    id: GetStockListParams.locationCode,
    name: 'pages.reserve-stock.stock-table.column.location-code',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.TextIcon,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: GetStockListParams.locationDescription,
    name: 'pages.reserve-stock.stock-table.column.location-description',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-20',
  },
  {
    id: GetStockListParams.materialNumber,
    name: 'pages.reserve-stock.stock-table.column.material-no',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.TextIconTooltip,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: GetStockListParams.materialDescription,
    name: 'pages.reserve-stock.stock-table.column.material-description',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetStockListParams.batchNumber,
    name: 'pages.reserve-stock.stock-table.column.batch-no',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: 'defaultStockUnit',
    name: 'pages.reserve-stock.stock-table.column.unit',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: 'defaultQtyRemain',
    name: 'pages.reserve-stock.stock-table.column.qty-remain',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: 'action',
    name: 'pages.reserve-stock.stock-table.column.action',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Buttons,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
];

const selectedStockTableHeader = [
  {
    id: GetStockListParams._id,
    name: '_id',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'd-none',
  },
  {
    id: GetStockListParams.reservationItem,
    name: 'pages.reserve-stock.stock-table.column.reservation-item',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'd-none',
  },
  {
    id: GetStockListParams.locationCode,
    name: 'pages.reserve-stock.stock-table.column.location-code',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.TextIcon,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-10',
  },
  {
    id: GetStockListParams.locationDescription,
    name: 'pages.reserve-stock.stock-table.column.location-description',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: GetStockListParams.materialNumber,
    name: 'pages.reserve-stock.stock-table.column.material-no',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.TextIconTooltip,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: GetStockListParams.materialDescription,
    name: 'pages.reserve-stock.stock-table.column.material-description',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-20',
  },
  {
    id: GetStockListParams.batchNumber,
    name: 'pages.reserve-stock.stock-table.column.batch-no',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: GetStockListParams.stockUnitList,
    name: 'pages.reserve-stock.stock-table.column.unit',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Dropdown,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-10',
  },
  {
    id: 'qtyRemain',
    name: 'pages.reserve-stock.stock-table.column.qty-remain',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-10',
  },
  {
    id: 'qtyRequired',
    name: 'pages.reserve-stock.stock-table.column.qty-required',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Input,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-10',
  },
  {
    id: 'qtyCommitted',
    name: 'pages.reserve-stock.stock-table.column.qty-committed',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-10',
  },
  {
    id: 'qtyIssued',
    name: 'pages.reserve-stock.stock-table.column.qty-issued',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-10',
  },
  {
    id: 'result',
    name: 'pages.reserve-stock.stock-table.column.result',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.TextIcon,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10',
  },
  {
    id: 'action',
    name: 'pages.reserve-stock.stock-table.column.action',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Buttons,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-10',
  },
];

export {
  GetStockListParams,
  StockTableColumnIndex,
  SelectedStockTableColumnIndex,
  stockTableHeader,
  selectedStockTableHeader,
}
