import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { ActionButtonApproval, ActionButtonDefinition, ActionButtonType } from '@enum/action-button';
import { ApprovalService } from '@services/approval.service';
import { AuthorizationService } from '@services/authorization.service';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { InformationDialogHelper } from 'src/app/ui/components/information-dialog/information-dialog-helper';
import { SidebarItemInterface, SIDEBAR_ITEMS } from 'src/app/ui/components/sidebar/sidebar-items';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
import { columnsApproval, columnsHistory } from './approval-list-pm.model';
import { TablexColumnType, TablexColumnHorizontalAlign, TablexColumnVerticalAlign } from '../../entity/enum/tablexColumnType';
import { MatDialog } from '@angular/material';
import { InformationDialogComponent } from 'src/app/ui/components/information-dialog/information-dialog.component';

enum PmType {
  PLAN = 'plan',
  JOB = 'job',
}
enum TabOption {
  PENDING,
  HISTORY,
}
const RequestTypeOptions = [JMENUM.ApprovalType.PMPLAN_SUBMIT, JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT]

@Component({
  selector: 'app-approval-list-pm',
  templateUrl: './approval-list-pm.component.html',
  styleUrls: ['./approval-list-pm.component.scss']
})
export class ApprovalListPmComponent implements OnInit {
  @ViewChild("reject_approval_panel", { static: true }) rejectApprovalPanel: CustomSliderPanelComponent;

  // model
  selectedApprovalPendingList: JMOBJ.Approval[] = [];
  approvalPendingList: JMOBJ.Approval[] = [];
  generalSearchWords: string;

  // action button related
  actionButtonData = [];
  disableActionSideBar = false;

  // sidebar
  sideBarItem: SidebarItemInterface[] = [];
  snJobApprovalCount: number = 0;
  currentTypePmPlanApprovalCount: number = 0;
  totalPmPlanApprovalCount: number = 0;
  pmJobApprovalCount: number = 0;
  teamApprovalCount: number = 0;

  // tablex
  tablexParamPending: { [x: string]: any } = {};
  tablexParamHistory: { [x: string]: any } = {};
  pageSizeOptions = [10, 20, 50];
  currentPageSize = 10;
  currentPageSizeHistory = 10;
  currentPage = 1;
  currentPageHistory = 1;
  pageCount = 1;
  pageCountHistory = 1;

  // Dropdown Filter
  requestTypeOptions: {
    label: string,
    value: JMENUM.ApprovalType,
  }[] = [];
  selectedRequestType: {
    label: string,
    value: JMENUM.ApprovalType,
  };

  // selectedColId: any = [];
  pmType: PmType;

  // Tab
  activeTab: TabOption;
  tabOptions = [{
    value: TabOption.PENDING,
    label: JMLanguage.translate('pages.approval-pm-list.tab.pending-approval'),
  }, {
    value: TabOption.HISTORY,
    label: JMLanguage.translate('pages.approval-pm-list.tab.approval-history')
  }];

  constructor(
    private authorizationService: AuthorizationService,
    private approvalService: ApprovalService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.route.params.subscribe(params => {
      this.pmType = params.pmType;
      this.initPage();
    })
  }

  ngOnInit() {
  }

  initPage() {
    // get total count for display
    // TODO: fix isLoadingApi
    this.requestSnJobApprovalCount();
    this.requestPmJobApprovalCount();
    this.requestPmPlanApprovalCount();
    const hasShowTeamApprovalPermission = JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.REQUEST_APPROVE, JMENUM.Permission.REQUEST_REJECT], false);
    if (hasShowTeamApprovalPermission) {
      this.requestTeamApprovalCount();
    }

    this.initActionBar();
    this.initFilterOptions();
    this.initPendingTablex();
    this.initHistoryTablex();
    this.initSearchBar();

    this.setActiveTab(this.tabOptions[0].value);
  }

  async requestPmPlanApprovalPendingTable(pageNumber: number, pageSize: number) {
    this.isLoadingApi(true);
    let response = await this.approvalService.requestApprovalPmPlanPending(pageNumber, pageSize, true, this.selectedRequestType.value, this.generalSearchWords);
    this.isLoadingApi(false);

    if (response) {
      this.currentTypePmPlanApprovalCount = response.payload.totalCount;
      this.approvalPendingList = response.payload.records;
      this.pageCount = Math.ceil(this.currentTypePmPlanApprovalCount / pageSize);
    } else {
      this.currentTypePmPlanApprovalCount = 0;
      this.approvalPendingList = [];
      this.pageCount = 1;
    }
    this.renderPendingTable(this.approvalPendingList, pageNumber, this.pageCount, pageSize, this.currentTypePmPlanApprovalCount);
    this.updateSideBar();
  }

  async requestPmJobApprovalPendingTable(pageNumber: number, pageSize: number) {
    this.isLoadingApi(true);
    let response = await this.approvalService.requestApprovalPmJobPending(pageNumber, pageSize, true, this.generalSearchWords);
    this.isLoadingApi(false);

    if (response) {
      this.pmJobApprovalCount = response.payload.totalCount;
      this.approvalPendingList = response.payload.records;
      this.pageCount = Math.ceil(this.pmJobApprovalCount / pageSize);
    } else {
      this.pmJobApprovalCount = 0;
      this.approvalPendingList = [];
      this.pageCount = 1;
    }
    this.renderPendingTable(this.approvalPendingList, pageNumber, this.pageCount, pageSize, this.pmJobApprovalCount);
    this.updateSideBar();
  }

  private async requestSnJobApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalCmPending(minPageNumber, minPageSize, needApprovalDetail);

    this.snJobApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  private async requestPmPlanApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalPmPlanPending(minPageNumber, minPageSize, needApprovalDetail);

    this.totalPmPlanApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  private async requestPmJobApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalPmJobPending(minPageNumber, minPageSize, needApprovalDetail);

    this.pmJobApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  private async requestTeamApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestRequestSummary(minPageNumber, minPageSize, needApprovalDetail);

    this.teamApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  async requestApprovalHistoryTable(pageNumber: number, pageSize: number) {
    const needApprovalDetail = true;
    let response: JM.JMResponseApprovalSummary;

    this.tablexParamHistory.isLoadingTable = true;
    if (this.isPmPlanType) {
      const approvalType = this.selectedRequestType.value;
      response = await this.approvalService.requestApprovalPmPlanHistory(pageNumber, pageSize, needApprovalDetail, approvalType, this.generalSearchWords);
    } else {
      response = await this.approvalService.requestApprovalPmJobHistory(pageNumber, pageSize, needApprovalDetail, this.generalSearchWords);
    }
    this.tablexParamHistory.isLoadingTable = false;

    let historyList = [];
    if (response) {
      const totalCount = response.payload.totalCount;
      this.pageCountHistory = Math.ceil(totalCount / pageSize);
      historyList = response.payload.records;
    } else {
      this.pageCountHistory = 1;
    }

    this.renderHistoryTable(historyList, pageNumber, this.pageCountHistory, pageSize);
  }

  async requestApprovalPendingTable() {
    if (this.isPmPlanType) {
      this.requestPmPlanApprovalPendingTable(this.currentPage, this.currentPageSize)
    } else {
      this.requestPmJobApprovalPendingTable(this.currentPage, this.currentPageSize)
    }
  }

  viewApprovalDetail(approval: JMOBJ.Approval) {
    switch (approval.type) {
      case JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT: {
        const customField = approval.customField.pmPeriodEquipmentEdit;
        const pmPeriod = customField && customField.targetPmPeriod;
        if (pmPeriod && pmPeriod.pmPlanNumber) {
          window.open(`/pm/standard-plan/view/${pmPeriod.pmPlanNumber}`, '_blank');
        }
      }
        break;
      case JMENUM.ApprovalType.PMPLAN_SUBMIT: {
        const pmPlan = approval.customField.pmPlanSubmit;
        if (pmPlan && pmPlan.pmPlanNumber) {
          window.open(`/pm/standard-plan/view/${pmPlan.pmPlanNumber}`, '_blank');
        }
        break;
      }
      case JMENUM.ApprovalType.PMJOB_SUBMIT: {
        const pmJob = approval.customField.pmJobSubmit;
        if (pmJob && pmJob.pmJobNumber) {
          window.open(`/pm/job/view/${pmJob.pmJobNumber}`, '_blank');
        }
        break;
      }
      default:
        break;
    }
  }

  async requestApproveRequest(approvals: JMOBJ.Approval[]) {
    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = approvals.map(approval => {
      return {
        approvalAction: JMENUM.ApprovalAction.APPROVE,
        approvalId: approval._id,
      }
    });

    await this.handleBatchProcessRequest(request);
  }

  async requestWithdrawRequest(approvals: JMOBJ.Approval[]) {
    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = approvals.map(approval => {
      return {
        approvalAction: JMENUM.ApprovalAction.WITHDRAW,
        approvalId: approval._id,
      }
    });

    await this.handleBatchProcessRequest(request);
  }

  private async handleBatchProcessRequest(request: JM.JMRequestApprovalsBatchProcessRequest) {
    this.isLoadingApi(true);
    const records = await this.approvalService.requestBatchProcessRequest(request);
    this.isLoadingApi(false);

    if (!records.length) { return; }

    const successCount = records.filter(r => r.success).length;
    const failCount = records.filter(r => !r.success).length;

    AppDelegate.openSnackBar(JMLanguage.translate('global.batch-process-request.result', [successCount, failCount]));
    this.currentPage = this.getLatestPageNumber(this.currentPageSize, this.currentPage, this.pageCount, successCount);
    this.resetSelectedRowParam();
    this.updateActionBar();
    this.requestApprovalPendingTable();
  }

  private isLoadingApi(isLoading: boolean) {
    this.tablexParamPending.isLoadingTable = isLoading;
    this.disableActionSideBar = isLoading;
  }

  private resetSelectedRowParam() {
    this.selectedApprovalPendingList = [];
    this.tablexParamPending.highlightedRows = [];
    this.tablexParamPending.selectedRowCount = this.selectedApprovalPendingList.length;
    this.updateActionBar();
  }

  getLatestPageNumber(currentPageSize: number, currentPageNumber: number, totalPageCount: number, deletedCount: number) {
    const isReducePageNumber = currentPageSize == deletedCount && currentPageNumber == totalPageCount && currentPageNumber != 1;
    return isReducePageNumber ? currentPageNumber - 1 : currentPageNumber;
  }

  private updateSideBar() {
    let sideBarConfig = JSON.parse(JSON.stringify(SIDEBAR_ITEMS.approval));

    this.sideBarItem = sideBarConfig.filter(item => {
      return this.authorizationService.hasPermissions(item.permission, false);
    }).map(item => {
      let processedSubItems = item.subItems.filter(cell => {
        return this.authorizationService.hasPermissions(cell.permission, false);
      });
      processedSubItems.forEach((subItem) => {
        if (subItem.id === 'approval-sn-job') {
          subItem.title = JMLanguage.translate(subItem.title, [this.snJobApprovalCount]);
        }
        if (subItem.id === 'approval-pm-plan') {
          subItem.title = JMLanguage.translate(subItem.title, [this.totalPmPlanApprovalCount]);
        }
        if (subItem.id === 'approval-pm-job') {
          subItem.title = JMLanguage.translate(subItem.title, [this.pmJobApprovalCount]);
        }
        if (subItem.id === 'approval-team') {
          subItem.title = JMLanguage.translate(subItem.title, [this.teamApprovalCount]);
        }
      });

      return {
        ...item,
        subItems: processedSubItems,
      };
    });
  }

  // action button
  initActionBar(): void {
    this.actionButtonData = [];

    this.addActionBtn(ActionButtonApproval.view);
    this.addActionBtn(ActionButtonApproval.approve);
    this.addActionBtn(ActionButtonApproval.rejectWithReason);
    // this.addActionBtn(ActionButtonApproval.withdraw); // Suppose withdraw for contractor

    this.updateActionBar();
  }

  private addActionBtn(buttonStatus: ActionButtonApproval): void {
    const actionButton = ActionButtonDefinition[ActionButtonType.approval][buttonStatus];
    let buttonHandler;

    switch (buttonStatus) {
      case ActionButtonApproval.view:
        buttonHandler = () => {
          if (this.selectedApprovalPendingList.length) {
            this.viewApprovalDetail(this.selectedApprovalPendingList[0]);
          }
        };
        break;
      case ActionButtonApproval.approve:
        buttonHandler = () => {
          if (this.selectedApprovalPendingList.length) {
            this.requestApproveRequest(this.selectedApprovalPendingList);
          }
        };
        break;
      case ActionButtonApproval.rejectWithReason:
        buttonHandler = () => {
          if (this.selectedApprovalPendingList.length) {
            this.onRejectActionButtonClicked(this.selectedApprovalPendingList);
          }
        };
        break;
      case ActionButtonApproval.withdraw:
        buttonHandler = () => {
          if (this.selectedApprovalPendingList.length) {
            this.requestWithdrawRequest(this.selectedApprovalPendingList);
          }
        };
        break;
      default:
        buttonHandler = () => { };
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : "global.yes",
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : "global.no",
        handler: () => { }
      }
    ];

    this.actionButtonData.push(actionButton);
  }

  updateActionBar() {
    const hasRecord = this.selectedApprovalPendingList.length > 0;
    const isSelectedOneRecord = this.selectedApprovalPendingList.length === 1;

    const hasApprover = this.approvalService.hasRoleInApprovalList(this.selectedApprovalPendingList, 'approver');
    const hasRequester = this.approvalService.hasRoleInApprovalList(this.selectedApprovalPendingList, 'requester');
    const hasEditPermission = this.approvalService.hasPermissionInApprovalList(this.selectedApprovalPendingList, 'edit');

    const isEnableApproveButton = hasRecord && (hasApprover || hasEditPermission);
    const isEnableRejectButton = hasRecord && (hasApprover || hasEditPermission);
    const isEnableWithdrawButton = hasRecord && hasRequester;

    this.enableActionButton(ActionButtonApproval.view, isSelectedOneRecord);
    this.enableActionButton(ActionButtonApproval.approve, isEnableApproveButton);
    this.enableActionButton(ActionButtonApproval.rejectWithReason, isEnableRejectButton);
    this.enableActionButton(ActionButtonApproval.withdraw, isEnableWithdrawButton);
  }

  private enableActionButton(button: ActionButtonApproval, isEnable: boolean = true) {
    ActionButtonDefinition[ActionButtonType.approval][button]['isEnable'] = isEnable;
  }

  public onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      const buttons = actionButton.buttons;

      for (const button of buttons) {
        button.name = JMLanguage.translate(button.name);
      }
      AppDelegate.showPopUpAlert(JMLanguage.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  public onRejectActionButtonClicked(approvalList: JMOBJ.Approval[]) {
    this.rejectApprovalPanel.toggle();
  }

  public onApprovalRejected(count: number) {
    this.currentPage = this.getLatestPageNumber(this.currentPageSize, this.currentPage, this.pageCount, count);
    this.resetSelectedRowParam();
    this.updateActionBar();
    this.requestApprovalPendingTable();
    this.rejectApprovalPanel.toggle();
  }

  //==================================================================
  // tablex function
  initPendingTablex() {
    this.tablexParamPending = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: false,
      enableSelectedRowCount: true,
      enableSort: false,
      tableRow: "row",
      tableClass: "approval-table",
      tableWrapperClass: "table-min-width",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: this.selectedApprovalPendingList.length,
      totalRowCount: this.currentTypePmPlanApprovalCount,
      // selectedColId: this.selectedColId,
      fullColNameList: columnsApproval.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onPendingTablePageNumberClicked.bind(this),
      onPageSizeClicked: this.onPendingTablePageSizeClicked.bind(this),
      // onFilterChanged: this.onFilterChanged,
      onRowClicked: this.onApprovalRowClicked.bind(this),
      // onColFiltered: this.onColFiltered,
      // onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: columnsApproval,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  initHistoryTablex() {
    this.tablexParamHistory = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSort: false,
      tableRow: "row",
      tableClass: "approval-table",
      tableWrapperClass: "table-min-width",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSizeHistory,
      currentPage: this.currentPageHistory,
      pageCount: this.pageCountHistory,
      // selectedRowCount: 0,
      // totalRowCount: 0,
      // selectedColId: this.selectedColId,
      fullColNameList: columnsHistory.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onHistoryTablePageNumberClicked.bind(this),
      onPageSizeClicked: this.onHistoryTablePageSizeClicked.bind(this),
      // onFilterChanged: this.onFilterChanged,
      // onRowClicked: this.onApprovalRowClicked.bind(this),
      // onColFiltered: this.onColFiltered,
      // onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: columnsHistory,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  initFilterOptions() {
    // Request Type
    this.requestTypeOptions = [];
    for (const requestTypeOption of RequestTypeOptions) {
      this.requestTypeOptions.push({
        label: JMLanguage.translate('pages.approval-list.request-type.' + requestTypeOption),
        value: requestTypeOption
      });
    }

    this.selectedRequestType = {
      label: JMLanguage.translate('pages.approval-list.request-type.' + JMENUM.ApprovalType.PMPLAN_SUBMIT),
      value: JMENUM.ApprovalType.PMPLAN_SUBMIT
    }
  }

  public renderPendingTable(approvalList: JM.JMOBJ.Approval[],
    currentPage: number, pageCount: number, pageSize: number, totalRowCount: number) {
    // this.tablexParamPending.headers = columnsApproval;
    this.tablexParamPending.content = approvalList.map((data: JMOBJ.Approval) => {
      const showMoreButton = {
        type: "buttons",
        buttons: [
          {
            'name': JMLanguage.translate("pages.approval-list.button.show-more"),
            'class': 'popup-link',
            'onClicked': this.onShowMoreApproversClicked,
            'object': data.approver.map(approver => { return { type: "text", value: approver } }),
            'component': this
          }
        ]
      };
      const defaultString = '-';
      const description = this.approvalService.getApprovalDescription(data, defaultString);
      let approver;
      if (data.approver.length) {
        if (data.approver.length > 2) {
          approver = [
            data.approver.slice(0, 2).join(', '),
            showMoreButton
          ];
        } else {
          approver = [data.approver.join(', ')];
        }
      } else {
        approver = [defaultString];
      }
      const pmPlan = data.customField.pmPlanSubmit;
      const scheduleType = pmPlan ? JMLanguage.translate(`pm.schedule-type.${pmPlan.scheduleType}`) : defaultString;
      const frequency = (pmPlan && pmPlan.frequency) ? JMLanguage.translate(`pm.frequency.${pmPlan.frequency}`) : defaultString;
      const startDate = pmPlan ? formatDateTimeWithWeek(pmPlan.startDate) : defaultString;
      const endDate = pmPlan ? formatDateTimeWithWeek(pmPlan.endDate) : defaultString;
      const requestedDate = formatDateTimeWithWeek(data.createdAt);
      const buttonArray = this.getMoreInfoButtonArray(data);

      return [
        data.id,
        description,
        data.requester,
        approver,
        scheduleType,
        frequency,
        startDate,
        endDate,
        requestedDate,
        buttonArray,
      ];
    });
    this.tablexParamPending.currentPageSize = pageSize;
    this.tablexParamPending.currentPage = currentPage;
    this.tablexParamPending.pageCount = pageCount;
    this.tablexParamPending.totalRowCount = totalRowCount;
  }

  public renderHistoryTable(approvalList: JM.JMOBJ.Approval[], currentPage: number, pageCount: number, pageSize: number) {
    // this.tablexParamHistory.headers = columnsHistory;
    this.tablexParamHistory.content = approvalList.map((data: JMOBJ.Approval) => {
      const defaultString = '-';
      const description = this.approvalService.getApprovalDescription(data, defaultString);
      const pmPlan = data.customField.pmPlanSubmit;
      const scheduleType = pmPlan ? JMLanguage.translate(`pm.schedule-type.${pmPlan.scheduleType}`) : defaultString;
      const frequency = (pmPlan && pmPlan.frequency) ? JMLanguage.translate(`pm.frequency.${pmPlan.frequency}`) : defaultString;
      const startDate = pmPlan ? formatDateTimeWithWeek(pmPlan.startDate) : defaultString;
      const endDate = pmPlan ? formatDateTimeWithWeek(pmPlan.endDate) : defaultString;
      const requestedDate = formatDateTimeWithWeek(data.createdAt);
      const actionType = data.status && typeof data.status === 'string' ?
        data.status[0].toUpperCase() + data.status.slice(1) : defaultString;
      const approveBy = data.updatedBy ? data.updatedBy : defaultString;
      const updatedDate = formatDateTimeWithWeek(data.updatedAt);
      const buttonArray = this.getMoreInfoButtonArray(data);

      return [
        data.id,
        description,
        data.requester,
        scheduleType,
        frequency,
        startDate,
        endDate,
        requestedDate,
        actionType,
        approveBy,
        updatedDate,
        buttonArray,
      ];
    });

    this.tablexParamHistory.currentPageSize = pageSize;
    this.tablexParamHistory.currentPage = currentPage;
    this.tablexParamHistory.pageCount = pageCount;
  }

  private getMoreInfoButtonArray(approval: JMOBJ.Approval) {
    if (approval.type != JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT) {
      return [];
    }
    return [
      {
        'id': 'information-button',
        'name': '',
        'class': 'glyph brand-blue',
        'icon': 'fas fa-info',
        'onClicked': this.onPmPeriodEquipmentDetailButtonClicked,
        'object': approval,
        'component': this
      }
    ]
  }

  // UI handler
  public onPendingTablePageNumberClicked(pageIndex: number) {
    this.currentPage = pageIndex;
    this.resetSelectedRowParam();
    this.requestApprovalPendingTable();
  }

  public onHistoryTablePageNumberClicked(pageIndex: number) {
    this.currentPageHistory = pageIndex;
    this.requestApprovalHistoryTable(this.currentPageHistory, this.currentPageSizeHistory);
  }

  public onPendingTablePageSizeClicked(pageSize: number) {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetSelectedRowParam();
    this.requestApprovalPendingTable();
  }

  public onHistoryTablePageSizeClicked(pageSize: number) {
    this.currentPageHistory = 1;
    this.currentPageSizeHistory = pageSize;
    this.requestApprovalHistoryTable(this.currentPageHistory, this.currentPageSizeHistory);
  }

  public onApprovalRowClicked(index, row) {
    const approvalId = row[0];
    const foundApproval = this.approvalPendingList.find(approval => approval.id === approvalId);

    if (foundApproval) {
      const isIncludedSelectedList = this.selectedApprovalPendingList.includes(foundApproval);
      if (isIncludedSelectedList) {
        this.tablexParamPending.highlightedRows[index] = false;
        this.selectedApprovalPendingList = this.selectedApprovalPendingList.filter(approval => approval.id !== approvalId);
      } else {
        this.tablexParamPending.highlightedRows[index] = true;
        this.selectedApprovalPendingList = [...this.selectedApprovalPendingList, foundApproval];
      }
      this.tablexParamPending.selectedRowCount = this.selectedApprovalPendingList.length;
      this.updateActionBar();
    }
  }

  public onShowMoreApproversClicked(button) {
    const component: ApprovalListPmComponent = button.component;
    const approvers: Object[] = button.object;
    const dialogTitle = JMLanguage.translate('pages.approval-pm-list.approver-full-list-dialog.title');
    component.dialog.open(InformationDialogComponent, InformationDialogHelper.createDialogData(dialogTitle, approvers));
  }

  public onPmPeriodEquipmentDetailButtonClicked(button) {
    const component: ApprovalListPmComponent = button.component;
    const approval: JMOBJ.Approval = button.object;
    const customField = approval.customField.pmPeriodEquipmentEdit;
    const equipmentChangeList = customField && customField.equipmentChangeList;
    const equipmentNumberList = equipmentChangeList && equipmentChangeList.map(obj => obj.equipmentNumber);

    // functions
    const fetchEquipmentData = async () => {
      if (equipmentNumberList.length <= 0) {
        return;
      }
      const request = new JM.JMRequestEquipmentsEquipmentSummary();
      request.equipmentNumber = equipmentNumberList;
      request.parameters = ['equipmentNumber', 'description', 'equipmentType', 'location', 'locationDescription', 'clientShortName'];
      request.sortBy = 'equipmentNumber';
      request.sortOrder = JMENUM.SortOrder.ASC;
      request.pageNumber = tablexParam.currentPage;
      request.pageSize = tablexParam.currentPageSize;
      request.active = JMENUM.RequestActive.BOTH;

      const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);

      if (!response || response.error || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
        AppDelegate.openErrorBar(response);
        return;
      }

      tablexParam.totalRowCount = response.payload.totalCount;
      tablexParam.pageCount = Math.ceil(response.payload.totalCount / tablexParam.currentPageSize);

      renderEquipmentTable(response.payload.records);
    }

    const renderEquipmentTable = (equipmentList: JMOBJ.Equipment[]) => {
      tablexParam.content = equipmentList.map(equipment => {
        const changeEquipment = equipmentChangeList.find(obj => obj.equipmentNumber == equipment.equipmentNumber);
        const locationDescription = equipment.locationDescription ? equipment.locationDescription[JMLanguage.getCurrentLanguage()] : '';
        const locationText = equipment.location ? `${locationDescription} (${equipment.location})` : '';
        const actionText = JMLanguage.translate(`pages.approval-pm-list.equipment-change-dialog.${changeEquipment.action}`);

        return [
          equipment.equipmentNumber,
          equipment.description,
          locationText,
          equipment.clientShortName,
          equipment.equipmentType,
          actionText,
        ];
      });
    }

    // Tablex
    let pageSizeOptions = [10, 25, 100];
    let tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      pageSizeOptions: pageSizeOptions,
      currentPageSize: 10,
      totalRowCount: 1,
      pageCount: 1,
      currentPage: 1,
      headers: [
        {
          id: 'equipment-id',
          name: 'pages.approval-pm-list.equipment-change-dialog.table-column.equipment-id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
        },
        {
          id: 'description',
          name: 'pages.approval-pm-list.equipment-change-dialog.table-column.description',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
        },
        {
          id: 'location',
          name: 'pages.approval-pm-list.equipment-change-dialog.table-column.location',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
        },
        {
          id: 'client',
          name: 'pages.approval-pm-list.equipment-change-dialog.table-column.client',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
        },
        {
          id: 'equipment-type',
          name: 'pages.approval-pm-list.equipment-change-dialog.table-column.equipment-type',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
        },
        {
          id: 'change',
          name: 'pages.approval-pm-list.equipment-change-dialog.table-column.change',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
        },
      ],
      content: [],
      onPageNumberClicked: function (pageIndex: number) {
        tablexParam.currentPage = pageIndex;
        fetchEquipmentData();
      },
      onPageSizeClicked: function (pageSize: number) {
        tablexParam.currentPage = 1;
        tablexParam.currentPageSize = pageSize;
        fetchEquipmentData();
      },
    }

    // open dialog
    const dialogTitle = JMLanguage.translate('pages.approval-pm-list.equipment-change-dialog.title');
    const content = [InformationDialogHelper.createTablex(tablexParam)];
    component.dialog.open(InformationDialogComponent, InformationDialogHelper.createDialogData(dialogTitle, content));

    fetchEquipmentData();
  }

  public selectRequestType = (event) => {
    this.selectedRequestType = event;
    this.resetPendingHistoryTable();
  }

  //=======================================================================================================================
  // Search bar event
  onGeneralSearchClicked() {
    this.resetPendingHistoryTable();
  }

  checkGeneralSearchEmpty() {
    if (this.generalSearchWords.length <= 0) {
      this.resetPendingHistoryTable();
    }
  }

  onGeneralSearchKeyup = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.resetPendingHistoryTable();
    }
  }

  private initSearchBar() {
    this.generalSearchWords = null;
  }
  //=======================================================================================================================
  // Tab event
  onClickedActiveTab = (tab: TabOption) => {
    this.setActiveTab(tab);
  }

  setActiveTab(tab: TabOption) {
    this.activeTab = tab;
    this.resetPendingHistoryTable();
  }

  private resetPendingHistoryTable() {
    this.resetSelectedRowParam();

    switch (this.activeTab) {
      case TabOption.PENDING:
        this.currentPage = 1;
        this.currentPageSize = this.pageSizeOptions[0];
        this.requestApprovalPendingTable();
        break;
      case TabOption.HISTORY:
        this.currentPageHistory = 1;
        this.currentPageSizeHistory = this.pageSizeOptions[0];
        this.requestApprovalHistoryTable(this.currentPageHistory, this.currentPageSizeHistory);
        break;
    }
  }

  //=======================================================================================================================
  // getter
  get isPmPlanType(): boolean { return this.pmType == PmType.PLAN; }
  get isPmJobType(): boolean { return this.pmType == PmType.JOB; }
  get isPendingTab(): boolean { return this.activeTab == TabOption.PENDING; }
  get isHistoryTab(): boolean { return this.activeTab == TabOption.HISTORY; }

  get generalSerachPlaceholder(): string {
    if (this.isPmPlanType) {
      return JMLanguage.translate('pages.approval-pm-list.pm-plan-placeholder');
    }
    if (this.isPmJobType) {
      return JMLanguage.translate('pages.approval-pm-list.pm-job-placeholder');
    }
    return '';
  }
}
