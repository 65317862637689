import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { JMENUM } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'app-modal-ha-equipment-details',
  templateUrl: './modal-ha-equipment-details.component.html',
  styleUrls: ['./modal-ha-equipment-details.component.scss']
})
export class ModalHaEquipmentDetailsComponent implements OnInit {

  @Input() haEquipment;
  @Input() ccsInfo;
  @Input() isLoading = false;

  moment = moment;
  isHaEnrollment: boolean;
  haAddress: string = '';
  acceptanceDate: string = '';
  warrantyExpiryDate: string = '';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.formatHAEquipmentAddress();
    this.checkHAEnrollment();
  }

  formatHAEquipmentAddress(){
    if(this.haEquipment){
      let haAddressList = [this.haEquipment['address1'], this.haEquipment['address2'], this.haEquipment['address3']];
      for(let address of haAddressList){
        if(address){
          this.haAddress += address;
          if(haAddressList.indexOf(address) != haAddressList.length - 1){
            this.haAddress+= ', ';
          }
        }
      }
    }
  }

  checkHAEnrollment(){
    if(this.haEquipment && this.haEquipment['HAEnrollment'] && this.haEquipment['HAEnrollment'] == JMENUM.RequestActive.ACTIVE){
      this.isHaEnrollment = true;
    }else{
      this.isHaEnrollment = false;
    }
  }

  onClickClose() {
    this.activeModal.dismiss();
  }
}
