<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [breadcrumbs]="breadcrumbs"
  ></app-nav>

  <app-sidebar></app-sidebar>

  <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
          (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
      </ng-container>

  <!-- Page Content -->
  <div id="content" class="action-btn-padding" >
    <!-- (click)="requestUpdateSN()" -->
    <div class="row" *ngIf="sn">
      <div class="col-xl-6 col-12">

        <!-- Notification Information Component -->
        <app-notification-information-detail #notification_infomation_detail [sn]="sn"
          [isPendingApproval]="isPendingApproval"
          [currentPageMode]="currentPageMode" (onValidation)="onNotiInfoDetailValidation($event)" class="d-block mb-4">
        </app-notification-information-detail>

        <!-- Fault Information Component -->
        <app-fault-infomation-detail #fault_infomation_detail [sn]="sn" [isPendingApproval]="isPendingApproval"
          [currentPageMode]="currentPageMode" (onValidation)="onFaultDetailValidation($event)" class="d-block mb-4"></app-fault-infomation-detail>

        <!-- HA Information Component -->
        <app-ha-infomation-detail #ha_infomation_detail [sn]="sn" [isPendingApproval]="isPendingApproval"
          [currentPageMode]="currentPageMode" (onValidation)="onHAValidation($event)" class="d-block mb-4"
          *ngIf="isHaEquipmentOwner">
        </app-ha-infomation-detail>

      </div>

      <div class="col-xl-6 col-12">

        <!-- Notification Progress Component -->
        <app-notification-progress-detail #notification_progress_detail [sn]="sn" [currentPageMode]="currentPageMode" class="d-block mb-4"
          *ngIf="sn.jobCards && sn.jobCards.length > 0"></app-notification-progress-detail>

        <!-- Routing Detail Component -->
        <app-routing-detail #routing_detail [sn]="sn" [currentPageMode]="currentPageMode" *ngIf="sn.team" class="d-block mb-4">
        </app-routing-detail>

        <!-- Remark Component -->
        <app-remark-detail #remark_detail [sn]="sn" [currentPageMode]="currentPageMode" class="d-block mb-4"
          [additionalRemark]="additionalRemark" >
          <!-- *ngIf="sn.remarks&&sn.remarks.length>0" -->
        </app-remark-detail>

        <!-- Attachment Component -->
        <app-attachment-detail #attachment_detail [sn]="sn" [page]="JMPage.SN" [currentPageMode]="currentPageMode" class="d-block mb-4"
          *ngIf="sn.attachmentList&&sn.attachmentList.length>0">
        </app-attachment-detail>

      </div>
    </div>
  </div>
</div>