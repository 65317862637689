import { InformationDialogItemType } from '@enum/information-dialog-item-type';

export class InformationDialogHelper {

    static createDialogData(title: string, content: any[]): any {
        return { data:{title: title,  content: content} };
    }

    static createText(text: string): any {
        return {type: InformationDialogItemType.Text, value: text};
    }

    static createTablex(tablexParam: any): any {
        return {type: InformationDialogItemType.Tablex, value: tablexParam};
    }

    static createPreviewer(file: any): any {
        return {type: InformationDialogItemType.Previewer, value: file};
    }

    static createSnHistory(sn: any): any {
        return {type: InformationDialogItemType.SnHistory, value: sn};
    }
}