
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '@services/authorization.service';
import { JM, JMENUM, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import * as utility from 'src/app/services/utility';
import { Session } from '@services/session';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
import { saveAs } from "file-saver";
import { InspectionManagementComponent } from 'src/app/ui/components/inspection-management/inspection-management.component'

@Component({
  selector: 'app-contract-inspection-report-submission-history',
  templateUrl: './contract-inspection-report-submission-history.component.html',
  styleUrls: ['./contract-inspection-report-submission-history.component.scss']
})
export class ContractInspectionReportSubmissionHistoryComponent implements OnInit {
  pageTitle = JMLanguage.translate('pages.contract.inspection.report.submission');

  // tablex
  pageCount: number = 0;
  currentPageSize: number = 10;
  currentPage: number = 1;
  tablexParam: any = {};
  pageSizeOptions = [10, 25, 100];

  inspectionReportHistorys: JM.JMOBJ.InspectionReport[] = [];
  hasEditPermission: boolean = false;

  constructor(private router: Router,
    private authorizationService: AuthorizationService,
    private InspectionManagementComponent: InspectionManagementComponent
  ) { }

  ngOnInit() {
    this.checkPermission();
    this.checkfeatureEnabled();
    this.initTable();
    this.requestInspectionReportHistory();
  }

  initTable() {
    this.tablexParam = {
      isLoadingTable: true,
      tableRow: "row",
      tableClass: "inspection-report-history-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: true,
      enablePagination: true,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      onPageSizeClicked: this.onPageSizeClicked,
      onPageNumberClicked: this.onPageNumberClicked,
      pageSizeOptions: this.pageSizeOptions,
      //onRowClicked: this.onRowClicked,
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'contract',
          name: 'component.inspection.report.history.contract',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'fileName',
          name: 'component.inspection.report.history.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'maintenanceType',
          name: 'component.upload-inspection-forms.maintenance-type',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'modeOfCheck',
          name: 'component.upload-inspection-forms.mode-of-check',
          type: TablexColumnType.Text,
          horizontalAlign: `${TablexColumnHorizontalAlign.Center} textOverFlow-ellipsis`,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col justify-content-around min-w-px-100"
        },
        {
          id: 'inspectionResult',
          name: 'component.upload-inspection-forms.inspection-result',
          type: TablexColumnType.Text,
          horizontalAlign: `${TablexColumnHorizontalAlign.Center} textOverFlow-ellipsis`,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col justify-content-around min-w-px-100"
        },
        {
          id: 'inspectorName',
          name: 'component.upload-inspection-forms.inspector-name',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'inspectorPost',
          name: 'component.upload-inspection-forms.inspector-post',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'inspectionTime',
          name: 'component.inspection.job.inspection.inspectionTime.title',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'uploadDate',
          name: 'component.inspection.report.history.uploadDate',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'uploadedBy',
          name: 'component.inspection.report.history.uploadedBy',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'action',
          name: 'component.inspection.report.history.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 justify-content-around"
        }
      ],
      content: []
    };
  }

  onPageSizeClicked = (pageSize) => {
    this.tablexParam['currentPage'] = 1;
    this.currentPageSize = pageSize;
    this.requestInspectionReportHistory();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.tablexParam['currentPage'] = pageIndex;
    this.requestInspectionReportHistory();
  }

  checkPermission() {
    let hasPermission: boolean = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_INSPECTION);
    if (!hasPermission) {
      this.router.navigate(['/dashboard']);
      AppDelegate.openSnackBar(JMLanguage.translate('pages.job-card.create.toast-msg.permission-denied'));
    }
  }

  checkfeatureEnabled() {
    if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JOB_CARD_INSPECTION)) {
      this.router.navigate(['']);
      return;
    }
  }

  private async requestInspectionReportHistory() {
    this.tablexParam.isLoadingTable = true;
    let request: JM.JMRequestContractsGetInspectionReportList = new JM.JMRequestContractsGetInspectionReportList();
    request.pageNumber = this.tablexParam['currentPage'];
    request.pageSize = this.currentPageSize;

    const response: JM.JMResponseContractsGetInspectionReportList = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload || response.error) {
      AppDelegate.openErrorBar(response);
      this.tablexParam['pageCount'] = 1;
      return;
    }

    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);

    if (response.payload) {
      this.updateInspectionReportHistory(response.payload.records);
      this.initPermission();
      this.renderTable();
    }
  }

  updateInspectionReportHistory(inspectionReportHistorys: JM.JMOBJ.InspectionReport[]) {
    this.inspectionReportHistorys = inspectionReportHistorys
  }

  initPermission() {
    let jobcardInspectionRemove = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_INSPECTION_REMOVE);

    if (jobcardInspectionRemove) {
      this.hasEditPermission = true;
    }
  }

  renderTable() {

    this.tablexParam.content = this.inspectionReportHistorys.map(inspectionReportHistory => {
      inspectionReportHistory.attachment['_id'] = inspectionReportHistory._id
      let buttons = [
        { "id": "download-button_" + inspectionReportHistory._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": inspectionReportHistory.attachment },
      ];
      if (this.hasEditPermission) {
        buttons = [
          { "id": "download-button_" + inspectionReportHistory._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": inspectionReportHistory.attachment },
          { "id": "delete-button_" + inspectionReportHistory._id, "name": "", "class": "delete-button btn p-1", "icon": "fas fa-times font-size-l", "onClicked": this.onDeleteButtonClicked, "attachment": inspectionReportHistory.attachment },
        ];
      }
      return [
        inspectionReportHistory._id,
        inspectionReportHistory.contractNumber,
        inspectionReportHistory.attachment.filename,
        inspectionReportHistory.maintenanceType != undefined ? this.getMaintenanceTypeWord(inspectionReportHistory.maintenanceType) : '',
        inspectionReportHistory.inspectionDetail.checkMode != undefined ? this.InspectionManagementComponent.getModeOfCheckWord(inspectionReportHistory.inspectionDetail.checkMode) : '',
        inspectionReportHistory.inspectionDetail.result != undefined ? this.getResultWord(inspectionReportHistory.inspectionDetail.result) : '',
        inspectionReportHistory.inspectionDetail.inspectorName,
        inspectionReportHistory.inspectionDetail.inspectorPost,
        inspectionReportHistory.inspectionDetail.inspectedAt ? formatDateTimeWithWeek(inspectionReportHistory.inspectionDetail.inspectedAt) : '',
        inspectionReportHistory.uploadedAt ? formatDateTimeWithWeek(inspectionReportHistory.uploadedAt) : '',
        inspectionReportHistory.uploadedBy, // uploadedBy
        buttons
      ];
    });
  }

  getResultWord = (result) => {
    switch (result) {
      case JMENUM.InspectionResult.SATISFACTORY: return JMLanguage.translate('component.contract-inspection.inspectionResult.options.satisfactory');
      case JMENUM.InspectionResult.UNSATISFACTORY: return JMLanguage.translate('component.contract-inspection.inspectionResult.options.unsatisfactory');
      case JMENUM.InspectionResult.NO_OBSERVATION: return JMLanguage.translate('component.contract-inspection.inspectionResult.options.noObservation');
      case JMENUM.InspectionResult.FAILED: return JMLanguage.translate('component.inspection.job.inspection.option.failed');
      case JMENUM.InspectionResult.PASSED: return JMLanguage.translate('component.inspection.job.inspection.option.passed');
      default: return '';
    }
  }

  getMaintenanceTypeWord = (maintenanceType) => {
    switch (maintenanceType) {
      case JMENUM.MaintenanceType.CM: return JMLanguage.translate('component.contract-inspection.maintenanceType.options.cm');
      case JMENUM.MaintenanceType.PM: return JMLanguage.translate('component.contract-inspection.maintenanceType.options.pm');
      default: return '';
    }
  }

  onDownloadButtonClicked = (button) => {
    this.requestGetFile(button.attachment.attachmentId, button.attachment.filename, true, button)
  }

  onAddInspectionReportClicked = (button) => {
    this.router.navigate(['/inspection-report']);
  }

  onDeleteButtonClicked = (button) => {
    if (!button || !button.attachment || !button.attachment.attachmentId) {
      AppDelegate.openErrorBar();
      return;
    }

    let buttons = [
      {
        name: (JMLanguage.translate("pages.sn.attachments.delete-the-file")),
        handler: () => {
          this.requestRemoveAttachment(button.attachment, button);
        }
      },
      { name: (JMLanguage.translate("global.no")) }
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("component.inspection.report.confirm.delete"), "", buttons);
  }

  //---------- Get files---------- 
  private async requestGetFile(attachmentId, description?, download?, button?) {
    if (button) button.isLoading = true;
    let request: JM.JMRequestFilesGetFile = new JM.JMRequestFilesGetFile(attachmentId);
    const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request)

    if (!response || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response.error);
      button.isLoading = false;
      return;
    }
    if (download) {
      let fileName = description != null && description != undefined ? description : attachmentId
      saveAs(response.payload, fileName);
      button.isLoading = false;
    }
  }
  //---------- Remove file---------- 
  private async requestRemoveAttachment(attachment, button?) {
    if (button) button.isLoading = true;

    let request: JM.JMRequestContractsDeleteInspectionReport = new JM.JMRequestContractsDeleteInspectionReport();
    request.id = attachment._id

    const response: JM.JMResponseContractsDeleteInspectionReport = await AppDelegate.sendJMRequest(request)

    if (button) button.isLoading = false;
    if (!response || !response.code || response.code !== 200) {
      AppDelegate.openErrorBar(response.error);
      return;
    }

    if (response.code == 200) {
      this.requestInspectionReportHistory()
      AppDelegate.openSnackBar(JMLanguage.translate("global.removed"));
    }
  }

}
