import { OperationPeriod } from './operation-period';

export enum TeamHandlingParty {
  InHouse = 1,
  PMSMC = 2,
  NonPMSMC = 3
}

export interface ManualInstruction {
  manualInstructionId   : String
  remarks               : String
}

export interface Team {
  membersResult: any;
    _id                         : String
    name                        : String
    description                 : String
    isActive                    : Boolean
    workCentre                  : String
    handlingParty               : TeamHandlingParty
    members                     : string[]
    operationPeriod             : OperationPeriod
    contactSequences            : {}
    fax                         : String
    enableFax                   : Boolean
    responseTime                : any
    isRoTeam                    : Boolean
    manualInstructions          : {}
    handlingTeam                : string
    contractNumber              : String
    enableManualInstructions    : Boolean
    status                      : number
    isWorkAroundClock           : Boolean
}

