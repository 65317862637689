export enum SliderPanelRowType {
    Input = "input",
    DropDown = "dropDown",
    Button = "button",
    Text = "text",
    RadioButton = "radioButton",
    Checkbox = "checkbox",
    FieldsetCheckbox = "fieldsetCheckbox",
    TextArea = "text-area",
    InformationRow = "information-row",
    Expanding = "expanding",
    ImportDataButton = "import-data",
    Underline = "underline"
}