<div class="wrapper timesheet-v2-container">

    <div id="fullpageLoading" *ngIf="isLoading">
        <div>
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
        </div>
    </div>

    <app-nav [navbarTitle]="'pages.timesheet-job.page-title' | translate" (languageChanged)="onLanguageChanged()" [showActionSidebarIcon]="true">
    </app-nav>

    <app-sidebar [type]="'default'"></app-sidebar>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disabledActionSideBar"
            (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <div id="content" class="job-card-time-sheet">
        <div class="row justify-content-between align-items-center">
            <div class="col-auto">
                <h2 class="title">{{ 'pages.timesheet.summary' | translate}}</h2>
            </div>
        </div>
        <hr class="underline" />

        <div class="row job-info-wrap align-items-center mx-0">
            <div class="info-container col-xl-6 col-md-5 input-border py-2">
                <div class="row mx-0 my-3">
                    <div class="col-6 p-0">{{ 'pages.timesheet.job-number' | translate}}</div>
                    <div class="col-6 p-0">
                        <a (click)="onJobNumberClicked()" class="job-card-link">
                            {{selectedJobCardId}}
                        </a>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-6 p-0">{{ 'pages.timesheet.staff-chargeable-hours' | translate}}</div>
                    <div class="col-6 p-0">
                        {{timesheetForUI?.totalChargableHours || 0}}
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-7 col-12">
                <div class="input-time-wrap" *ngIf="jobCard">
                    <div class="time-range text-center my-2" *ngIf="jobCard">
                        <span class="d-inline" id="job-card-start-time">{{jobCard.startTime | date:'yyyy-MM-dd
                            HH:mm'}}</span>
                        <i class="fas fa-long-arrow-alt-right font-size-l align-bottom mx-2" aria-hidden="true"></i>
                        <span class="d-inline" id="job-card-end-time">{{jobCard.completionTime | date:'yyyy-MM-dd
                            HH:mm'}}</span>
                    </div>
                    <div class="row mx-0 justify-content-center align-items-center mt-3 mb-4">
                        <div class="col-3">{{ 'pages.timesheet.buffer-time' | translate}}</div>
                        <div class="col-6">
                            <div class="timebuffer-slider">
                                <ng5-slider id="time-buffer-slider" [(value)]="bufferTimeValue" type="range"
                                    [options]="bufferTimeOptions" (userChangeEnd)="onChangeBufferTime()"></ng5-slider>
                            </div>
                        </div>
                        <div *ngIf="timesheetForUI && timesheetForUI.isUpdatedBufferTime" class="col-12 text-center"
                            style="margin: 15px 0 -25px;">
                            <i class="fas fa-exclamation-triangle amber"></i>
                            {{ 'pages.timesheet.save-reminder' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="staff-info-heading-wrap">
            <div #tableHeaderWrapper class="table-header-wrapper">
                <div class="bold staff-info-col">
                    {{ 'pages.timesheet.staff-info' | translate}}
                </div>
                <div class="bold task-number-col">
                    {{ 'pages.timesheet.task-number' | translate}}
                </div>
                <!-- Simulate rows structure -->
                <div class="p-0 d-flex align-items-end w-100">
                    <div class="bold date-col">
                        {{ 'pages.timesheet.date' | translate}}
                    </div>
                    <div class="start-end-col">
                        <div class="bold col-6">
                            {{ 'pages.timesheet.start-time' | translate}}
                        </div>
                        <div class="bold col-6">
                            {{ 'pages.timesheet.end-time' | translate}}
                        </div>
                    </div>
                    <div class="bold total-hours-col">
                        {{ 'pages.timesheet.total-hours' | translate}}
                    </div>
                    <div class="bold action-col">
                        {{ 'pages.timesheet.action' | translate}}
                    </div>
                    <div class="message-col">
                        <div class="table-action-bar">
                            <div class="search-box-container">
                                <div class="search-box">
                                    <i class="fas fa-search" (click)="searchTimesheet()"></i>
                                    <input class="form-control" id="search" type="search" [(ngModel)]="searchWord"
                                        (keyup.enter)="searchTimesheet()" (ngModelChange)="checkSearchEmpty()"
                                        placeholder="{{'pages.timesheet.search' | translate}}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="timesheet-detail-col" (scroll)="onTableScroll($event, tableHeaderWrapper)">

            <div class="no-timesheet-alert" *ngIf="!jobCard">
                {{ "pages.timesheet.no-timesheet" | translate }}
            </div>

            <div class="staff-content-wrap" *ngIf="jobCard && !isLoading">
                <ng-container *ngFor="let officer of timesheetForUI.officers; let i = index">
                    <div class="staff-info-row-wrap row mx-0" *ngIf="!officer.isHide">
                        <!-- Staff Info column -->
                        <div class="staff-info-col">
                            <ng-container>
                                <span>{{officer.postName}}</span>
                                <br>
                                <span style="margin-right: 6px;">{{officer.employeeName}}</span>
                                <i *ngIf="jobCard.officerInCharge == officer.postName"
                                    class="fas fa-user-circle officer-in-charge"></i>
                                <span class="d-block">
                                    ({{officer.personnelNumber}})
                                </span>
                            </ng-container>
                        </div>
                        <!-- Task Number column -->
                        <div class="task-number-col">
                            {{officer.taskNumber}}
                            <span *ngIf="officer.isUpdatedTaskNumber" class="mt-2">
                                <i class="fas fa-exclamation-triangle amber" [ngbTooltip]="updatedToolTip"
                                    placement="bottom"></i>
                            </span>
                        </div>
                        <div class="p-0 d-flex flex-column">
                            <ng-container *ngFor="let workingDay of officer.workingDays; let d = index;">
                                <div class="job-period-date-row d-flex">
                                    <!-- Date column -->
                                    <div class="date-col">
                                        <span>
                                            {{ getWeekdayTranslate(workingDay.postDate) }}
                                        </span>
                                        <span>
                                            {{ workingDay.postDate }}
                                        </span>
                                    </div>

                                    <div class="start-end-col flex-column">
                                        <ng-container *ngIf="workingDay.periods.length; else emptyPeriod">
                                            <ng-container *ngFor="let period of workingDay.periods">
                                                <div class="d-flex">
                                                    <!-- Start column -->
                                                    <div class="col-6">
                                                        {{ period.startTime || '-' }}
                                                    </div>

                                                    <!-- End column -->
                                                    <div class="col-6">
                                                        {{ period.endTime || '-' }}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #emptyPeriod>
                                            <div class="d-flex">
                                                <!-- Start column -->
                                                <div class="col-6">
                                                    -
                                                </div>

                                                <!-- End column -->
                                                <div class="col-6">
                                                    -
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <!-- Total Hours column -->
                                    <div class="total-hours-col">
                                        {{workingDay.totalHours || 0}}
                                    </div>

                                    <!-- Action column -->
                                    <div class="action-col row-button-wrap">
                                        <ng-container *ngIf="!viewOnly; else viewBtn">
                                            <button class="btn btn-sm font-size-m edit-btn"
                                                (click)="onClickTimeSheetBtn(officer,viewOnly)">
                                                <img class="action-img" src="/assets/svg/btn_action_edit.svg">
                                            </button>
                                            <button class="btn btn-sm font-size-m"
                                                (click)="openResetPopup(officer, workingDay)">
                                                <img class="action-img" src="/assets/svg/btn_action_clear.svg">
                                            </button>
                                        </ng-container>
                                        <ng-template #viewBtn>
                                            <button class="btn btn-sm font-size-m"
                                                (click)="onClickTimeSheetBtn(officer,viewOnly)">
                                                <i class="fas fa-eye" [ngStyle]="{color: '#e4a939'}"></i>
                                            </button>
                                        </ng-template>
                                    </div>

                                    <!-- Message column -->
                                    <div class="message-col">
                                        <div *ngIf="workingDay?.isPeriodsUpdated; else ccsErrorMessage">
                                            <i class="fas fa-exclamation-triangle amber"></i>
                                            {{ 'pages.timesheet.save-reminder' | translate}}
                                        </div>
                                        <ng-template #ccsErrorMessage>
                                            <div class="css-error-msg" *ngFor="let period of workingDay.periods">
                                                <div *ngIf="period.ccsStatus && period.ccsStatus.isSuccess === false">
                                                    <i class="fas fa-exclamation-circle no-timesheet-icon"></i>
                                                    {{period.ccsStatus.errMessage }}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isNoRecord">
                    <div class="no-record-msg">
                        {{ "pages.ccs-error-log.no-records-found" | translate }}
                    </div>
                </ng-container>
            </div>

            <div class="col-7">
                <custom-slider-panel #assign_staff_panel [widthPx]=600>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="show-all-post-checkbox" class="custom-control-input"
                            [(ngModel)]="showAllPostForReassignStaff" (change)="initReassignStaffPanel(false)"
                            [disabled]="assignStaffFormParam.isLoading">
                        <label for="show-all-post-checkbox" class="custom-control-label">
                            {{'pages.sn.slider-panel.create-job-show-all-staff-checkbox' | translate}}
                        </label>
                    </div>
                    <job-card-assign-staff-form [parameters]="assignStaffFormParam"></job-card-assign-staff-form>
                </custom-slider-panel>
            </div>

        </div>
    </div>

    <ng-template #updatedToolTip>
        <div class="warning-tooltip-wrap tooltip-inner amber text-left">
            {{ 'pages.timesheet.save-reminder' | translate}}
        </div>
    </ng-template>