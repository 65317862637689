import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { ActionButtonContent, ActionButtonDefinition, ActionButtonPmJob, ActionButtonType } from '@enum/action-button';
import { Session }  from 'src/app/services/session';

@Injectable({
  providedIn: 'root'
})
export class ActionButtonService {
  //Services
  router: Router;
  route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  addActionBtn(actionBtnType: ActionButtonType, buttonStatus: string, button1CallBack?:Function , button2CallBack?:Function): ActionButtonContent {
    let actionButton: ActionButtonContent = ActionButtonDefinition[actionBtnType][buttonStatus];

    if(actionButton['permission'] && !JMUTILITY.hasPermission(Session.userInfo, actionButton['permission'])) return;

    actionButton.buttons = [
      {
        name: JMLanguage.translate(
          (actionButton.buttons && actionButton.buttons.length >= 1) ? 
            actionButton.buttons[0].name : "global.yes"
        ),
        handler: button1CallBack
      },
      {
        name: JMLanguage.translate(
          (actionButton.buttons && actionButton.buttons.length >= 2) ?
            actionButton.buttons[1].name : "global.no"
          ),
        handler: button2CallBack
      }
    ]
    return actionButton;
  }

  onActionButtonClick(actionButton: any) {
    if (actionButton.showPopup) {
      AppDelegate.showPopUpAlert(JMLanguage.translate(actionButton.popupTitle), '', actionButton.buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  isEnable(actionButtonArray: any[], status: boolean){
    actionButtonArray.forEach(e =>{
      e.isEnable = status;
    })
  }
}
