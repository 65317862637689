<div class="row justify-content-between m-0">
  <h3 class="d-inline">{{ 'pages.sn.ccs-interface' | translate }}</h3>
  <div class="submit-wrap d-inline">
    <app-submit-button 
      [id]="'ccs-so-submit-form_submit_button'"
      [text]="'global.submit'"
      [buttonClass]="'standard brand-amber'"
      [iconClass]="'fa-check'"
      [isDisabled]="submitDisabled || parameters.isLoading"
      [isLoading]="parameters.isLoading"
      (onClick)="onClickSubmit()" 
    ></app-submit-button>
  </div>
</div>

<div class="row">
  <div class="col-lg-7 px-0">
    <div class="row data-row">
      <div class="col-lg-2 label-name">{{'pages.sn.job-type' | translate}}</div>
      <div class="col-lg-2 text-content">{{jobCard.orderType}}</div>
      <div class="col-lg-2 label-name">{{'pages.sn.client' | translate}}</div>
      <div class="col-lg-6 text-content">{{ clientName }}</div>
    </div>
    <div class="row data-row">
      <div class="col-lg-2 label-name">{{'pages.sn.mat-type' | translate}}</div>
      <div class="col-lg-2 text-content">{{ jobCard.matType }}</div>
      <div class="col-lg-2 label-name">{{'pages.sn.location' | translate}}</div>
      <div class="col-lg-6 text-content">{{ displayLocationDescription }}</div>
    </div>
  </div>
  <div class="col-lg-5 px-0">
    <div class="row validation-fail-message text-right">
      <ng-container *ngFor="let msg of displayErrorMessage; let isLast=last">
        {{ msg }}
        <ng-container *ngIf="!isLast">
          <br />
          <br />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="parameters.displayCcsErrorMessage">
        <div class="bold">{{ 'pages.sn.generate-ccs-so.error-msg.ccs-return-error' | translate }}</div>
        {{ parameters.displayCcsErrorMessage }}
      </ng-container>
    </div>
  </div>
</div>

<div class="form-wrap">
  <div class="checkbox-wrap position-relative mb-3 custom-control custom-checkbox">
    <input
      type="checkbox"
      id="ccsAcknowledge"
      class="checkbox custom-control-input"
      [(ngModel)]="ccsAcknowledge"
      (change)="validateForm()"
      >
    <label for="ccsAcknowledge" class="d-block custom-control-label ">{{'pages.sn.ccs-acknowledge' | translate}}</label>
  </div>
  <!-- Start of assign CCS SO -->
  <div class="ccs-options my-1">
    <div class="radio-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="assignCcsSo"
        name="ccs-so-permission-rights"
        value="assign" 
        [(ngModel)]="ccsSoOption"
        (change)="validateForm()"
        >
      <label for="assignCcsSo" class=" m-0 custom-control-label">{{'pages.sn.assign-ccs-so' | translate}}</label>
    </div>
  </div>
  <div class="option-wrap pl-4" *ngIf="ccsSoOption == 'assign'">
    <div class="row">
      <div class="col-4 pl-0">   
        <ng-select
          class="my-2"
          placeholder="{{'pages.sn.ccs-so-no' | translate}}"
          [(ngModel)]="selectedCcsSoNumber"
          [items]="ccsSoNumberOptions"
          [loading]="isLoadingCcsSo"
          [disabled]="isLoadingCcsSo || !ccsSoNumberOptions || ccsSoNumberOptions.length === 0"
          [clearable]="false"
          (change)="validateForm()"
          >
        </ng-select>
      </div>
    </div>
  </div>
  <!-- End of assign CCS SO -->

  <!-- Start of obtain by location -->
  <div class="ccs-options my-1" *ngIf="enableObtainSoByLocation">
    <div class="radio-wrap obtain-ccs-so-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="obtainCcsSoByLocation"
        name="ccs-so-permission-rights" 
        value="obtainByLocation"
        [(ngModel)]="ccsSoOption"
        (change)="validateForm()"
        >
      <label class="custom-control-label m-0" for="obtainCcsSoByLocation">
        {{'pages.sn.obtain-ccs-so-by-location' | translate}}
      </label>
    </div>
  </div>
  <div class="option-wrap pl-4" *ngIf="ccsSoOption == 'obtainByLocation'">
    <div class="radio-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="show-location-in-tag"
        name="location-option-type" 
        value="equipmentTag"
        [(ngModel)]="locationOptionType"
        [disabled]="!sn.hashtagId || locationTablexParam.isLoadingTable || sn.equipmentOwner === SnEquipmentOwnerEnum.HA"
        (change)="onLocationOptionTypeChange()"
        >
      <label class="custom-control-label m-0" for="show-location-in-tag">
        {{'pages.sn.generate-ccs-so.show-location-in-tag' | translate}}
      </label>
    </div>
    <div class="radio-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="show-location-in-work-centre"
        name="location-option-type" 
        value="workCentre"
        [(ngModel)]="locationOptionType"
        [disabled]="locationTablexParam.isLoadingTable || !sn.snNumber || sn.equipmentOwner === SnEquipmentOwnerEnum.HA"
        (change)="onLocationOptionTypeChange()"
        >
      <label class="custom-control-label m-0" for="show-location-in-work-centre">
        {{'pages.sn.generate-ccs-so.show-location-in-work-centre' | translate}}
      </label>
    </div>
    <div class="radio-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="show-all-location"
        name="location-option-type" 
        value="all"
        [(ngModel)]="locationOptionType"
        [disabled]="locationTablexParam.isLoadingTable"
        (change)="onLocationOptionTypeChange()"
        >
      <label class="custom-control-label m-0" for="show-all-location">
        {{'pages.sn.generate-ccs-so.show-all-location' | translate}}
      </label>
    </div>
    <ng-container>
      <app-tablex id='location_table' #equipment_table [tablexParam]="locationTablexParam"></app-tablex>
    </ng-container>

    <div class="row">
      <div class="col-8 pl-0">
        <ng-select
          *ngIf="jobCard.orderType === OrderTypeEnum.SLA_JOB"
          class="my-2"
          placeholder="{{'pages.sn.sla-no' | translate}}"
          [(ngModel)]="selectedSlaNumberLoc" 
          [items]="slaNumberOptionsLoc"
          bindLabel="label" 
          bindValue="value"
          [loading]="isLoadingSlaLoc"
          [disabled]="!selectedLocationCode || isLoadingSlaLoc || !slaNumberOptionsLoc || slaNumberOptionsLoc.length === 0"
          [clearable]="false"
          (change)="onLocationSlaNumberChange($event); validateForm()"
          >
        </ng-select>
      </div>
      <div 
        *ngIf="jobCard.orderType === OrderTypeEnum.SLA_JOB && selectedLocationCode && !isLoadingSlaLoc && (!slaNumberOptionsLoc || slaNumberOptionsLoc.length === 0)"
        class="col-8 align-self-center alert-font">
        {{ 'pages.sn.generate-ccs-so.error-msg.no-effective-sla' | translate}}
      </div>
    </div>
    <div class="row">
      <div class="col-8 pl-0">   
        <ng-select
          *ngIf="jobCard.orderType === OrderTypeEnum.SLA_JOB"
          class="my-2"
          placeholder="{{'pages.sn.sla-item' | translate}}"
          [(ngModel)]="selectedSlaItemLoc" 
          [items]="slaItemOptionsLoc"
          bindLabel="label" 
          bindValue="value"
          [loading]="isLoadingSlaLoc"
          [disabled]="!selectedLocationCode || !selectedSlaNumberLoc || isLoadingSlaLoc || !slaItemOptionsLoc || slaItemOptionsLoc.length === 0"
          [clearable]="false"
          (change)="validateForm()"
          >
        </ng-select>
      </div>
    </div>

  </div>
  <!-- End of obtain by location -->

  <!-- Start of obtain by Equipment -->
  <div class="ccs-options my-1" *ngIf="enableObtainSoByEquipment">
    <div class="radio-wrap obtain-ccs-so-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="obtainCcsSoByEquipment"
        name="ccs-so-permission-rights" 
        value="obtainByEquipment"
        [(ngModel)]="ccsSoOption"
        (change)="validateForm()"
        >
      <label class="custom-control-label m-0" for="obtainCcsSoByEquipment">
        {{'pages.sn.obtain-ccs-so-by-equipment' | translate}}
      </label>
    </div>
  </div>
  <div class="option-wrap pl-4" *ngIf="ccsSoOption == 'obtainByEquipment'">
    <div class="radio-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="show-equipment-in-tag"
        name="equipment-option-type" 
        value="equipmentTag"
        [(ngModel)]="equipmentOptionType"
        [disabled]="!sn.hashtagId || equipmentTablexParam.isLoadingTable || sn.equipmentOwner === SnEquipmentOwnerEnum.HA"
        (change)="onEquipmentOptionTypeChange()"
        >
      <label class="custom-control-label m-0" for="show-equipment-in-tag">
        {{'pages.sn.generate-ccs-so.show-equipment-in-tag' | translate}}
      </label>
    </div>
    <div class="radio-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="show-equipment-in-work-centre"
        name="equipment-option-type" 
        value="workCentre"
        [(ngModel)]="equipmentOptionType"
        [disabled]="equipmentTablexParam.isLoadingTable || !sn.snNumber ||sn.equipmentOwner === SnEquipmentOwnerEnum.HA"
        (change)="onEquipmentOptionTypeChange()"
        >
      <label class="custom-control-label m-0" for="show-equipment-in-work-centre">
        {{'pages.sn.generate-ccs-so.show-equipment-in-work-centre' | translate}}
      </label>
    </div>
    <div class="radio-wrap custom-control custom-radio">
      <input
        class="radio custom-control-input"
        type="radio"
        id="show-all-equipment"
        name="equipment-option-type" 
        value="all"
        [(ngModel)]="equipmentOptionType"
        [disabled]="equipmentTablexParam.isLoadingTable"
        (change)="onEquipmentOptionTypeChange()"
        >
      <label class="custom-control-label m-0" for="show-all-equipment">
        {{'pages.sn.generate-ccs-so.show-all-equipment' | translate}}
      </label>
    </div>
    <ng-container>
      <app-tablex id='equipment_table' #equipment_table [tablexParam]="equipmentTablexParam" ></app-tablex>
    </ng-container>
    <div class="row">
      <div class="col-8 pl-0">
        <ng-select
          *ngIf="jobCard.orderType === OrderTypeEnum.SLA_JOB"
          class="my-2"
          placeholder="{{'pages.sn.sla-no' | translate}}"
          [(ngModel)]="selectedSlaNumber" 
          [items]="slaNumberOptions"
          bindLabel="label" 
          bindValue="value"
          [loading]="isLoadingSla"
          [disabled]="!selectedEquipment || isLoadingSla || !slaNumberOptions || slaNumberOptions.length === 0"
          [clearable]="false"
          (change)="onEquipmentSlaNumberChange(); validateForm()"
          >
        </ng-select>
      </div>
      <div 
        *ngIf="jobCard.orderType === OrderTypeEnum.SLA_JOB && selectedEquipment && !isLoadingSla && (!slaNumberOptions || slaNumberOptions.length === 0)"
        class="col-8 align-self-center alert-font">
        {{ 'pages.sn.generate-ccs-so.error-msg.no-effective-sla' | translate}}
      </div>
    </div>
    <div class="row">
      <div class="col-8 pl-0">   
        <ng-select
          *ngIf="jobCard.orderType === OrderTypeEnum.SLA_JOB"
          class="my-2"
          placeholder="{{'pages.sn.sla-item' | translate}}"
          [(ngModel)]="selectedSlaItem" 
          [items]="slaItemOptions"
          bindLabel="label" 
          bindValue="value"
          [loading]="isLoadingSla"
          [disabled]="!selectedEquipment || !selectedSlaNumber || isLoadingSla || !slaItemOptions || slaItemOptions.length === 0"
          [clearable]="false"
          (change)="validateForm()"
          >
        </ng-select>
      </div>
    </div>
  </div>
  <!-- End of obtain by Equipment -->
</div>