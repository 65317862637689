import { Component, Injector, Input, OnInit } from '@angular/core';
import { Sn } from 'src/app/entity/data-model/sn';
import { Session } from 'src/app/services/session';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'app-sn-history',
  templateUrl: './sn-history.component.html',
  styleUrls: ['./sn-history.component.scss']
})
export class SnHistoryComponent extends BasePage implements OnInit {

  @Input() sn: Sn;
  
  // for html use
  JM = JM;

  displaySn          : Sn;
  displayLocation    : string = '';
  displayEquipmentTag: string = '';

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.lang = Session.selectedLanguage;
    this.displaySn = this.sn;
    this.requestLocationSummary();
    this.requestEquipmentTag();
  }

  //---------------------------------------------------------------------------
  // api function
  private requestLocationSummary() {
    if (!this.sn.location) return;
    let request = new JM.JMRequestLocationsLocationSummary();
    request.location = [this.sn.location];

    JM.JMConnector.sendLocationsLocationSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseLocationsLocationSummary) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      if (!response.payload.records || !response.payload.records.length) return;

      let location = response.payload.records[0];
      let description = location.description[this.lang] ? location.description[this.lang] : location.description["en"];
      this.displayLocation = `${description} (${this.sn.location})`;
    });
  }

  private requestEquipmentTag() {
    if (!this.sn.hashtagId) return;

    let request = new JM.JMRequestHashtagsGetHashtags();
    request.hashtagIdList = [this.sn.hashtagId];

    JM.JMConnector.sendHashtagsGetHashtags(request, (error: JM.JMNetworkError, response: JM.JMResponseHashtagsGetHashtags) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      if (!response.payload.records || !response.payload.records.length) return;

      let tag = response.payload.records[0];
      this.displayEquipmentTag = tag.description[this.lang] ? tag.description[this.lang] : tag.description["en"];
    });
  }

}
