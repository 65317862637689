export const environment = {
  production: false,
  useHash: false,
  CCEP_JM_WEB_HOST: "https://jm-uat.ccep.emsd.gov.hk",
  CCEP_API_HOST: 'https://api-uat.ccep.emsd.gov.hk',
  CCEP_FILE_HOST: 'https://file-uat.ccep.emsd.gov.hk',
  CCEP_PORTAL_HOST: "",
  CCEP_PORTAL_SSO_KEY : "",
  CCEP_HSD_WEB_HOST: "https://hsdsr-uat.ccep.emsd.gov.hk",
  CCEP_EFORM_WEB_HOST: "https://eform-weather-uat.ccep.emsd.gov.hk",
  DOSS_HOST : "",
  DOSS_CLIENT_ID : "",
  IOS_DOWNLOAD_LINK: "itms-services://?action=download-manifest&url=https://jm-uat.ccep.emsd.gov.hk/mobile/jm/ios/manifest-latest.plist",
  ANDROID_DOWNLOAD_LINK: "/mobile/jm/android/app-latest.apk",
};
