<div class="wrapper">
  <app-nav [navbarTitle]="'pages.user-management-user.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>
  <app-sidebar [type]="'default'"></app-sidebar>
  <div id="content" >
    
    <div class="row">
      <div class="col-md-8">
        <app-tablex #id_user_table [tablexParam]='tablexParam' class='mt-3'></app-tablex>
      </div>
      <div class="col-md-4">
        <div class="row">
          <h2 class="col-md-12 title" *ngIf="mode == 'create' && hasCreatePermission">{{'pages.admin.users.create-user' | translate}}</h2>
          <h2 class="col-md-12 title" *ngIf="mode == 'edit' && hasEditionPermission">{{'pages.admin.users.edit-user' | translate}}</h2>
          <h2 class="col-md-12 title" *ngIf="mode == 'edit' && !hasEditionPermission">{{'pages.admin.users.view-user' | translate}}</h2>
        </div>
        <form [formGroup]="userForm" *ngIf="!(mode == 'create' && !hasCreatePermission)">
          <ng-container *ngIf="isLoadingUserInfo; else contentDetail">
            <div class="row mt-5">
              <div class="col d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #contentDetail>

            <div class="row mx-0 align-items-center my-2 custom-group group-container">
              <label class="col-md-5 field-label px-0" for="user_authentication-mode_dropdown"><span *ngIf="mandatoryFields.includes('authenticationMode')" class=" red">*</span> {{'pages.admin.users.authentication-mode' | translate}}</label>
              <div class="col-md-7 dropdwon-group">
                <ng-select 
                  [ngClass]="{'input-error': errorFields.includes('authenticationMode')}"
                  id="user_authentication-mode_dropdown" 
                  formControlName="authenticationMode"
                  bindLabel="displayName" 
                  bindValue="value" 
                  [items]="authenticationModeOptions" 
                  [searchable]="false"
                  [clearable]="false"
                  (change)="onAuthenticationModeChanged(userForm.value.authenticationMode)"
                >
                </ng-select>
              </div>
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user_post_inputbox"><span *ngIf="mandatoryFields.includes('post')" class="red">*</span> {{'pages.admin.users.post' | translate}}</label>
              <input [ngClass]="{'input-error': errorFields.includes('post')}" type="text" class="form-control col-md-7" id="user_post_inputbox" formControlName="post"  maxlength="35" />
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user_name_inputbox"><span *ngIf="mandatoryFields.includes('name')" class=" red">*</span> {{'pages.admin.users.name' | translate}}</label>
              <input [ngClass]="{'input-error': errorFields.includes('name')}" type="text" class="form-control col-md-7" id="user_name_inputbox" formControlName="name"  maxlength="35" />
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user_password_inputbox"><span *ngIf="mandatoryFields.includes('password')" class=" red">*</span> {{'pages.admin.users.password' | translate}}</label>
              <input [ngClass]="{'input-error': errorFields.includes('password')}" type="password" class="form-control col-md-7" id="user_password_inputbox" formControlName="password"  maxlength="100" 
              (keyup)="onPasswordChanged($event)" />
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user_confirm_password_inputbox"><span *ngIf="mandatoryFields.includes('confirmPassword')" class="red">*</span> {{'pages.admin.users.confirm-password' | translate}}</label>
              <input [ngClass]="{'input-error': errorFields.includes('confirmPassword')}" type="password" class="form-control col-md-7" id="user_confirm_password_inputbox" formControlName="confirmPassword"  maxlength="100" />
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user-code_inputbox"><span *ngIf="mandatoryFields.includes('code')" class=" red">*</span> {{'pages.admin.users.code' | translate}}</label>
              <input [ngClass]="{'input-error': errorFields.includes('code')}" type="text" class="form-control col-md-7" id="user-code_inputbox" formControlName="code" maxlength="35"
                (keyup)="onPostCodeChanged($event)" />
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user-phone_inputbox"><span *ngIf="mandatoryFields.includes('phone')" class="red">*</span> {{'pages.admin.users.phone' | translate}}</label>
              <input  [ngClass]="{'input-error': errorFields.includes('phone')}" type="text" class="form-control col-md-7" id="user-phone_inputbox" formControlName="phone" maxlength="8"
                (keyup)="inputNumber($event)" />
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user-mobile_inputbox"><span *ngIf="mandatoryFields.includes('mobile')" class="red">*</span> {{'pages.admin.users.mobile' | translate}}</label>
              <input [ngClass]="{'input-error': errorFields.includes('mobile')}" type="text" class="form-control col-md-7" id="user-mobile_inputbox" formControlName="mobile" maxlength="8"
                (keyup)="inputNumber($event); onMobileChanged($event);" />
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user-fax_inputbox"><span *ngIf="mandatoryFields.includes('fax')" class="red">*</span> {{'pages.admin.users.fax' | translate}}</label>
              <input [ngClass]="{'input-error': errorFields.includes('fax')}" type="text" class="form-control col-md-7" id="user-fax_inputbox" formControlName="fax" maxlength="8"
                (keyup)="inputNumber($event)" />
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group">
              <label class="col-md-5 field-label px-0" for="user-email_inputbox"><span *ngIf="mandatoryFields.includes('email')" class="red">*</span> {{'pages.admin.users.email' | translate}}</label>
              <input [ngClass]="{'input-error': errorFields.includes('email')}" type="text" class="form-control col-md-7" id="user-email_inputbox" formControlName="email" maxlength="100" (keyup)="onEmailChanged($event)"/>
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group group-container">
              <label class="col-md-5 field-label px-0" for="user_role_dropdown"><span *ngIf="mandatoryFields.includes('roles')" class="red">*</span> {{'pages.admin.users.role' | translate}}</label>
              <div class="col-md-7 dropdwon-group">
                <ng-select 
                  [ngClass]="{'input-error': errorFields.includes('roles')}"
                  id="user_role_dropdown" 
                  formControlName="roles"  
                  bindLabel="name" 
                  bindValue="roleId"
                  [items]="roleOptions" 
                  [multiple]="true" 
                  [closeOnSelect]="false"
                  [searchable]="false" 
                  [hideSelected]="true" 
                  (change)="onRoleChanged(userForm.value.roles)"
                >
                </ng-select>
              </div>
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group group-container">
              <label class="col-md-5 field-label px-0" for="user_work-centre_dropdown"><span *ngIf="mandatoryFields.includes('workCenters')" class="red">*</span> {{'pages.admin.users.work-centre' | translate}}</label>
              <div class="col-md-7 dropdwon-group">
                <ng-select 
                  [ngClass]="{'input-error': errorFields.includes('workCenters')}"
                  id="user_work-centre_dropdown"
                  formControlName="workCenters"
                  [items]="workCentreOptions" 
                  [multiple]="true" 
                  [closeOnSelect]="false"
                  [searchable]="true" 
                  [hideSelected]="true" 
                >
                </ng-select>
              </div>
            </div>
            <div class="row mx-0 align-items-center my-2 custom-group group-container">
              <span *ngIf="mandatoryFields.includes('isActive')" class="red">*</span>
              <label class="col-md-5 field-label px-0"> {{'pages.admin.users.active' | translate}} </label>
              <div class="col-md-7 custom-group custom-checkbox">
                <input [ngClass]="{'input-error': errorFields.includes('isActive')}" type="checkbox" class="custom-control-input" id="user_active_checkbox" formControlName="isActive">
                <label class="custom-control-label" for="user_active_checkbox"></label>
              </div>
            </div>
            <div [ngClass]="{hidden: !showPasswordError}" class="mt-2">
              <app-password-error-list #passwordErrorList>
              </app-password-error-list>
            </div>
            <div *ngIf="errorMessage" class="row mt-3 mb-1">
              <div class="col-md">
                <p class="msg text-danger">{{errorMessage | translate}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md d-flex justify-content-center">
                <ng-container *ngIf="mode === 'edit' && hasEditionPermission">
                  <app-submit-button  
                    [id]="'user_save_button'"
                    [text]="'global.save'"
                    [buttonClass]="'m-2 w-2 standard brand-amber'"
                    [iconClass]="'fa-pen'"
                    [isDisabled]="isSaving"
                    [isLoading]="isSaving"
                    (onClick)="onUpdateUserClicked()" 
                  ></app-submit-button>
                  <app-submit-button 
                    [id]="'user_discard_change_button'"
                    [text]="'pages.user-management-user.discard-edit'"
                    [buttonClass]="'m-2 w-2 standard brand-red'"
                    [iconClass]="'fa-trash'"
                    [isDisabled]="isSaving"
                    [isLoading]=""
                    (onClick)="onDiscardChangeClicked()" 
                  ></app-submit-button>
                </ng-container>
                <ng-container *ngIf="mode === 'edit' && hasCreatePermission">
                  <app-submit-button 
                    [id]="'user_discard_change_button'"
                    [text]="'pages.user-management-user.back'"
                    [buttonClass]="'m-2 w-2 standard brand-red'"
                    [iconClass]="'fa-times'"
                    [isDisabled]="isSaving"
                    [isLoading]=""
                    (onClick)="onDiscardChangeClicked()" 
                  ></app-submit-button>
                </ng-container>
                <ng-container *ngIf="mode === 'create'">
                  <app-submit-button 
                    [id]="'user_create_button'"
                    [text]="'global.create'"
                    [buttonClass]="'m-2 w-2 standard brand-amber'"
                    [iconClass]="'fa-pen'"
                    [isDisabled]="isSaving"
                    [isLoading]="isSaving"
                    (onClick)="onCreateUserClicked()" 
                  ></app-submit-button>
                </ng-container>
              </div>
            </div>

          </ng-template>
        </form>
      </div>
    </div>
  
  </div>
</div>