import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/entity/enum/tablexColumnType';
import { PostPostSummaryRequest } from 'src/app/api/authorization/post-post-summary-request';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session }  from 'src/app/services/session';
import { Permission } from '@enum/permission';
import { Constants } from 'src/constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';


@Component({
  selector: 'app-sla',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.scss']
})
export class SettingsSLAComponent extends BasePage implements OnInit {
  @ViewChild("add_sla_panel", { static: true }) addSlaPanel;
  @ViewChild("addSlaForm", { static: true }) addSlaForm;

  pageTitle: string;

  addSlaFormParameters:{
    workCentreSlaArray: string[],
    slaDict: { [key:string]: JMOBJ.ServiceLevelAgreement },
    onSubmitButtonClicked: Function,
  };

  allSla : string[] = null; // array of all SLA
  selectedWorkCentreCode        : string;
  selectedWorkCentre            = null;
  workCentrePostArray       = [];
  clientDict : { [key:string]: JMOBJ.Client };
  
  selectedSla:JMOBJ.ServiceLevelAgreement = null; // SLA object
  slaArray             = [];   // detail of SLAs that on option list
  
  uiShowDetial               : boolean = false;
  uiEnableRemoveButton       : boolean = true;
  uiEnableRefreshReminder    : boolean = false;
  uiContractStartDate        : Date = null;
  uiContractEndDate          : Date = null;
  uiContractSignedDate       : Date = null;
  uiSlaSort                  : string = '';
  uiSortActive               : string = '';

  // new contract
  uiNewSla             : string = '';
  uiAvailableNewSla : string[] = [];
  uiEnableNewSla: boolean = true;

  // hideContent               : boolean = true;
  isLoadingSlaOptions         : boolean = false;
  isLoadingSlaDetails         : boolean = false;
  isRemoving                  : boolean = false;
  hasEditionPermission        : boolean = false;

  slaFieldSearchTerm   = new Subject<string>();

  // Table
  tablexFilter        = null;
  tablexParam         : {} = {};
  pageSizeOptions     = [10, 25, 100];
  currentPageSize     = 10;
  currentPage         = 1;
  pageCount           = 1;
  uiTotalEquipmentCount = 0;
  
  constructor(
    injector                  : Injector,
  ) {
    super(injector);
    this.selectedWorkCentreCode = Session.selectedWorkCentre;
  }

  protected componentName(): string {
    return "sla";
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.sla.page-title", [this.selectedWorkCentreCode]);
    this.checkViewPermission(Permission.wcmView);
    this.checkWorkCenterPermission(this.selectedWorkCentreCode);
    this.hasEditionPermission = this.authorizationService.hasPermission(Permission.wcmUpdate);

    this.addSlaFormParameters = {
      workCentreSlaArray: [],
      slaDict: {},
      onSubmitButtonClicked: this.onAddSlaSubmitButtonClicked,
    };

    this.requestAllSla(); // Prepare sla dictionary
    this.requestSlaByWorkCentre(); // get sla of that work centre
  }

  ngOnDestroy() {
  }

  //---------------------------------------------------------------------------
  // view functions


  // expiryDate: string: 2014-02-28 00:00:00.000Z
  dayToExpiry(expiryDate) {
    let exDate = moment(expiryDate, "YYYYMMDD").toDate();
    let today = new Date();
    let dayDiff = (exDate.getTime() - today.getTime())/(60*60*24*1000);
    return Math.floor(dayDiff);
  }

  // updateSlaView(sla?) {
 
  // }


  checkExpired(inputDate){
    return this.dayToExpiry(inputDate) <= 0;
  }

  checkContractPeriod(newStartDate, oldEndDate){
    return moment(newStartDate).diff(moment(oldEndDate), 'days') > 0 ? true : false;
  }

  

  //---------------------------------------------------------------------------
  // api function
  
  // get the sla of the work centre
  async requsetSlaOption(): Promise<void> {
    this.selectedSla = null;
    this.slaArray = [];

    const request: JM.JMRequestWorkCentresWorkCentreSummary = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.selectedWorkCentreCode];
    request.includeSummary = true;

    this.isLoadingSlaOptions = true;
    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingSlaOptions = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records.length > 0) {
      this.selectedWorkCentre = response.payload.records[0];
      this.requestSlaByWorkCentre();
      this.requestAvailableSla(); // get all contract for slider panel
    } else {
      console.error(this.selectedWorkCentreCode + " work centre not found");
    }
  }

  // get details of sla of that work centre which are shown on left option
  async requestSlaByWorkCentre(): Promise<void> {
    this.selectedSla = null;
    
    const request:JM.JMRequestEquipmentSLASummary = new JM.JMRequestEquipmentSLASummary();
    request.workCentres = [this.selectedWorkCentreCode];
    request.includeExpired = true;

    this.isLoadingSlaDetails = true;
    const response: JM.JMResponseEquipmentSLASummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingSlaDetails = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.slaArray = response.payload.records;
    this.addSlaFormParameters.workCentreSlaArray = this.slaArray.map(sla => {return sla.agreementNumber});
    this.selectedSla = this.slaArray.length ?  this.slaArray[0] : null;
    this.uiContractStartDate = moment(this.selectedSla.contractStartDate, "YYYYMMDD").toDate();
    this.uiContractEndDate = moment(this.selectedSla.contractEndDate, "YYYYMMDD").toDate();
    this.uiContractSignedDate = moment(this.selectedSla.contractSignedDate, "YYYYMMDD").toDate();
  }


  // get available sla for user to add
  async requestAvailableSla(): Promise<void> {
    this.uiAvailableNewSla = [];
    
    const request: JM.JMRequestEquipmentSLASummary = new JM.JMRequestEquipmentSLASummary();
    request.parameters = ['agreementNumber'];
    request.includeExpired = false;

    const response: JM.JMResponseEquipmentSLASummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingSlaDetails = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.uiAvailableNewSla = response.payload.records.map(c => c.agreementNumber);
    let newlist = [];
    for (let sla of response.payload.records) {
      if (sla.agreementNumber != this.selectedSla.agreementNumber) {
        newlist.push(sla.agreementNumber);
      }
    }
    this.uiAvailableNewSla = newlist;
  }

  // Prepare sla dictionary
  async requestAllSla(page:number=1) {
    // request all SLA
    const request = new JM.JMRequestEquipmentSLASummary();
    request.includeExpired = true;
    request.pageNumber = page;
    request.pageSize = 1000;

    const response: JM.JMResponseEquipmentSLASummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let sla: JMOBJ.ServiceLevelAgreement;

    for (sla of response.payload.records) {
      this.addSlaFormParameters.slaDict[sla.agreementNumber] = sla;
    }
    // request the remaining contract, 1 got already
    if (page < Math.ceil(response.payload.totalCount/request.pageSize)) {
      this.requestAllSla(page + 1 );
    }
  }

  //Prepare client dictionary to-do
  // async requestClientSummary() {
  //   const request = new JM.JMRequestClientsClientSummary();
  //   request.active = JMENUM.RequestActive.BOTH;
  //   request.sortBy = "clientShortName";
  //   request.parameters = ['clientNumber', "clientShortName", "name"]
  //   request.includeSummary = true;

  //   const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
  //   if (!response || !response.code || response.code != 200 || !response.payload) {
  //     AppDelegate.openErrorBar(response);
  //     return;
  //   }

  //   let client:JMOBJ.Client;
  //   console.log('sfefesfes' + response.payload.records)
  //   for (client of response.payload.records) {
  //     console.log('client: ' + client.clientShortName)
  //     this.clientDict[client.clientNumber] = client;
  //   }
  //   //console.log('clientDict' + this.clientDict['100007'])
  // }
  
  //-----------------------------------------------------------------------------
  // button call back function
  onSlaChanged(index: number): void {
    if (this.slaArray.length > 0) {
      if (this.selectedSla.agreementNumber !== this.slaArray[index].agreementNumber) {
        this.selectedSla = this.slaArray[index];
        this.uiContractStartDate = moment(this.selectedSla.contractStartDate, "YYYYMMDD").toDate();
        this.uiContractEndDate = moment(this.selectedSla.contractEndDate, "YYYYMMDD").toDate();
        this.uiContractSignedDate = moment(this.selectedSla.contractSignedDate, "YYYYMMDD").toDate();
      }

    } else {
      this.selectedSla = null;
    }
    
  }
  
  onRemoveBtnClicked(): void {
    let message = this.translate("action.button.popup.remove") + ' SLA: ' + this.selectedSla.agreementNumber;
    let handler = async () => {
      const request = new JM.JMRequestWorkCentreRemoveSla();
      request.workCentre = this.selectedWorkCentreCode;
      request.slaNumber = this.selectedSla.agreementNumber;
      
      this.isRemoving = true;
      const response: JM.JMResponseWorkCentreRemoveSla = await AppDelegate.sendJMRequest(request);
      this.isRemoving = false;
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      this.requsetSlaOption();
      this.openSnackBar(this.translate("global.removed"));
    };
    this.popupConfirmAlert(message, handler);
  }

  onShowDetailBtnClicked(event: any) {
    this.uiShowDetial = !this.uiShowDetial;
  }

  onAddSlaBtnClicked(event: any) {
    this.addSlaPanel.toggle();
    this.addSlaForm.uiSlaArray =[];
    this.addSlaForm.nextSlaArrayIndex = 0;
    this.addSlaForm.updateView();
  }


  private popupConfirmAlert(message: string, handler: any) {
    let buttons = [
      { name: this.translate("global.yes"), handler },
      { name: this.translate("global.no") }
    ]
    this.showPopUpAlert(message, "", buttons);
  }
  

  // ------------ add SLA form ------------
  onAddSlaSubmitButtonClicked = async (newSlaNumberArray) => {
    const request = new JM.JMRequestWorkCentreAddSla();
    request.newSlaList = newSlaNumberArray;
    request.workCentre = this.selectedWorkCentreCode;

    const response: JM.JMResponseWorkCentreAddSla = await AppDelegate.sendJMRequest(request);
    this.addSlaForm.enabletSubmitbutton(true);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
      
    this.requsetSlaOption();
    this.openSnackBar(this.translate("global.added"));
    this.addSlaPanel.toggle();
    this.addSlaFormParameters.workCentreSlaArray = this.addSlaFormParameters.workCentreSlaArray.concat(newSlaNumberArray);
  }


  //----------------------------
  


  // TODO: reload language
  onLanguageChanged() {
    
  }

}
