<div class="row mx-0">
    <h3 class="col-12 col-xl-9 p-0">{{parameters.title | translate}}</h3>
    <button class="standard brand-amber col-4 col-xl-3" (click)="onOpenFilterClicked()" id="advanced-search-button">
        <i class="fas space" [ngClass]="{'fa-chevron-down': !isExpanded,'fa-chevron-up': isExpanded}"></i>
        {{"global.advanced-search" | translate}}
    </button>
</div>
<hr class="mb-3">

<ng-container *ngIf="isExpanded">
    <div class="row mx-0">
        <div class="col-12 col-xl-4 mx-0 p-0 mb-4 pr-4">
            <div class="col-12 mx-0 p-0 label-name _left pl-3 mb-2">
                {{"teco.advanced-search.subtitle.job-card-create-time" | translate}}</div>
            <div class="col-12 position-relative px-0 calendar-input-wrap mb-2">
                <input type="text" id="create-time-from-inputbox" class="form-control date-picker-input-box"
                    placeholder="From" name="createTimeFromPicker" #c2="ngModel"
                    [(ngModel)]="filterCriteria.createTimeFrom" ngbDatepicker #createTimeFromPicker="ngbDatepicker"
                    autocomplete="off" [readonly]="true" (click)="createTimeFromPicker.toggle()"
                    [maxDate]="filterCriteria.createTimeTo">
                <button class="btn calendar" (click)="createTimeFromPicker.toggle()" type="button">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
            </div>
            <div class="col-12 position-relative px-0 calendar-input-wrap mb-2">
                <input type="text" id="create-time-to-inputbox" class="form-control date-picker-input-box"
                    placeholder="To" name="createTimeToPicker" #c2="ngModel" [(ngModel)]="filterCriteria.createTimeTo"
                    ngbDatepicker #createTimeToPicker="ngbDatepicker" autocomplete="off"
                    [disabled]="!filterCriteria.createTimeFrom" [minDate]="filterCriteria.createTimeFrom"
                    [readonly]="true" (click)="createTimeToPicker.toggle()">
                <button class="btn calendar" (click)="createTimeToPicker.toggle()" type="button"
                    [disabled]="!filterCriteria.createTimeFrom">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="col-12 col-xl-4 mx-0 p-0 mb-4 pr-4">
            <div class="col-12 mx-0 p-0 label-name _left pl-3 mb-2">
                {{"teco.advanced-search.subtitle.completion-time" | translate}}</div>
            <div class="col-12 position-relative px-0 calendar-input-wrap mb-2">
                <input type="text" id="completion-time-from-inputbox" class="form-control date-picker-input-box"
                    placeholder="From" name="completionTimeFromPicker" #c2="ngModel"
                    [(ngModel)]="filterCriteria.completionTimeFrom" ngbDatepicker
                    #completionTimeFromPicker="ngbDatepicker" autocomplete="off" [readonly]="true"
                    (click)="completionTimeFromPicker.toggle()" [maxDate]="filterCriteria.completionTimeTo">
                <button class="btn calendar" (click)="completionTimeFromPicker.toggle()" type="button">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
            </div>
            <div class="col-12 position-relative px-0 calendar-input-wrap mb-2">
                <input type="text" id="completion-time-to-inputbox" class="form-control date-picker-input-box"
                    placeholder="To" name="completionTimeToPicker" #c2="ngModel"
                    [(ngModel)]="filterCriteria.completionTimeTo" ngbDatepicker #completionTimeToPicker="ngbDatepicker"
                    autocomplete="off" [disabled]="!filterCriteria.completionTimeFrom"
                    [minDate]="filterCriteria.completionTimeFrom" [readonly]="true"
                    (click)="completionTimeToPicker.toggle()">
                <button class="btn calendar" (click)="completionTimeToPicker.toggle()" type="button"
                    [disabled]="!filterCriteria.completionTimeFrom">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="col-12 col-xl-4 mx-0 p-0 mb-4 pr-4">
            <div class="col-12 mx-0 p-0 label-name _left pl-3 mb-2">
                {{"teco.advanced-search.subtitle.teco-status" | translate}}</div>
            <div class="col-12 mx-0 p-0">
                <ng-select id="teco-status-dropdown" bindLabel="label" bindValue="value"
                    placeholder="{{'teco.advanced-search.placeholder.teco-status' | translate}}"
                    [items]="tecoStatusOptions" [(ngModel)]="filterCriteria.tecoStatus"
                    [ngModelOptions]="{standalone: true}" [multiple]="true">
                </ng-select>
            </div>
        </div>
        <div class="col-12 col-xl-4 mx-0 p-0 mb-4 pr-4">
            <div class="col-12 mx-0 p-0 label-name _left pl-3 mb-2">
                {{"teco.advanced-search.subtitle.work-centre-team" | translate}}</div>
            <div class="col-12 mx-0 p-0 mb-2">
                <ng-select id="teco-work-centre-dropdown"
                    placeholder="{{'teco.advanced-search.placeholder.work-centre' | translate}}"
                    [items]="workCentreOptions" (change)="onWorkCentreChanged()" (clear)="onClearWorkCentre()"
                    [(ngModel)]="filterCriteria.workCentre" [ngModelOptions]="{standalone: true}">
                </ng-select>
            </div>
            <div class="col-12 mx-0 p-0 mb-2">
                <ng-select id="teco-team-dropdown" [items]="teamOptions" bindLabel="name" bindValue="_id"
                    (scrollToEnd)="requestTeamsTeamSummary()" (clear)="onClearTeam()"
                    (search)="onSearchTeamOptions($event)" [disabled]="!filterCriteria.workCentre"
                    [loading]="isLoadingTeam" placeholder="{{'teco.advanced-search.placeholder.team' | translate}}"
                    [(ngModel)]="filterCriteria.team" [ngModelOptions]="{standalone: true}">
                </ng-select>
            </div>
            <div class="col-12 mx-0 p-0">
                <ng-select id="teco-assigned-staff-dropdown" [items]="assignedStaffOptions" bindLabel="name"
                    bindValue="name" (scrollToEnd)="requestAssignedStaffSummary()" (clear)="onClearAssignedStaff()"
                    (search)="onSearchAssignedStaffOptions($event)" [loading]="isLoadingAssignedStaff"
                    placeholder="{{'teco.advanced-search.placeholder.assigned-staff' | translate}}"
                    [(ngModel)]="filterCriteria.assignedPersonList" [ngModelOptions]="{standalone: true}"
                    [multiple]="true">
                </ng-select>
            </div>
        </div>
        <div class="col-12 col-xl-4 mx-0 p-0 mb-4 pr-4">
            <div class="col-12 mx-0 p-0 label-name _left pl-3 mb-2">
                {{"teco.advanced-search.subtitle.job-card-number-and-type" | translate}}</div>
            <div class="col-12 mx-0 p-0 mb-2">
                <input type="text" class="form-control" id="teco-job-card-number-inputbox"
                    [(ngModel)]="filterCriteria.jobCardNumber"
                    placeholder="{{'teco.advanced-search.placeholder.job-card-number' | translate}}"
                    [ngModelOptions]="{standalone: true}" maxlength="50" />
            </div>
            <div class="col-12 mx-0 p-0 mb-2">
                <ng-select id="job-type-dropdown" bindLabel="name" bindValue="value"
                    placeholder="{{'teco.advanced-search.placeholder.job-type' | translate}}" [items]="orderTypeOptions"
                    [(ngModel)]="filterCriteria.orderType" [ngModelOptions]="{standalone: true}" [multiple]="true">
                </ng-select>
            </div>
            <div class="col-12 mx-0 p-0">
                <input type="text" class="form-control" id="teco-ccs-so-number-inputbox"
                    [(ngModel)]="filterCriteria.ccsServiceOrderNumber"
                    placeholder="{{'teco.advanced-search.placeholder.ccs-so-number' | translate}}"
                    [ngModelOptions]="{standalone: true}" maxlength="50" />
            </div>
        </div>
        <div class="col-12 col-xl-4 mx-0 p-0 mb-4 pr-4">
            <div class="col-12 mx-0 p-0 label-name _left pl-3 mb-2">
                {{"teco.advanced-search.subtitle.handling-party" | translate}}</div>
            <div class="col-12 mx-0 p-0">
                <ng-select id="teco-handling-party-dropdown" bindLabel="label" bindValue="value"
                    placeholder="{{'teco.advanced-search.placeholder.handling-party' | translate}}"
                    [items]="handlingPartyOptions" [(ngModel)]="filterCriteria.handlingParty"
                    [ngModelOptions]="{standalone: true}">
                </ng-select>
            </div>
        </div>
        <div class="col-12 col-xl-4 mx-0 p-0 mb-4 pr-4">
            <div class="col-12 mx-0 p-0 label-name _left pl-3 mb-2">
                {{"teco.advanced-search.subtitle.time-sheet-submitted" | translate}}</div>
            <div class="col-12 mx-0 p-0">
                <ng-select id="teco-time-sheet-submitted-dropdown" bindLabel="label" bindValue="value"
                    placeholder="{{'teco.advanced-search.placeholder.timesheet-submitted' | translate}}"
                    [items]="timesheetSubmittedOptions" [(ngModel)]="filterCriteria.timesheetSubmitted"
                    [ngModelOptions]="{standalone: true}">
                </ng-select>
                <!-- [multiple]="true" -->
            </div>
        </div>
        <!-- remove d-none when quotation-submitted can use -->
        <div class="col-12 col-xl-4 mx-0 p-0 mb-4 pr-4 d-none">
            <div class="col-12 mx-0 p-0 label-name _left pl-3 mb-2">
                {{"teco.advanced-search.subtitle.quotation-submitted" | translate}}</div>
            <div class="col-12 mx-0 p-0">
                <ng-select id="teco-quotation-submitted-dropdown" bindLabel="label" bindValue="value"
                    placeholder="{{'teco.advanced-search.placeholder.quotation-submitted' | translate}}"
                    [items]="quotationSubmittedOptions" [(ngModel)]="filterCriteria.quotationSubmitted"
                    [ngModelOptions]="{standalone: true}" [multiple]="true">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row m-0">
        <div class="ml-auto">
            <button class="btn standard brand-blue mb-3 mx-2" (click)="onSubmitSearch()">
                <label class="mb-0">
                    <i class="fas fa-search"></i>
                    {{ "global.search" | translate }}
                </label>
            </button>
            <button class="btn standard brand-red mb-3 mx-2" (click)="onClearSearch()">
                <label class="mb-0">
                    <i class="fas fa-trash"></i>
                    {{ "global.clear-filter" | translate }}
                </label>
            </button>
        </div>
    </div>

</ng-container>