import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { TablexColumnFilterOption, TablexColumnFilterType, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/entity/enum/tablexColumnType';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
    selector: 'app-pm-plan-equipment-list',
    templateUrl: './pm-plan-equipment-list.component.html',
    styleUrls: ['./pm-plan-equipment-list.component.scss']
})
export class PmPlanEquipmentListComponent extends BasePage implements OnInit {
    // page
    selectedLanguage: string;
    pmPlanNumber = this.route.snapshot.paramMap.get('pmNumber');
    isJmPmPlan: boolean = true;
    breadcrumbs: any = [];
    plan: JMOBJ.PmPlan = new JMOBJ.PmPlan;
    planVersion: number;
    equipmentCount:number = 0;
    // searchInputText: string;
    isSubmitting: boolean = false;

    // Table
    tablexFilter: any = {};
    tablexParam;
    pageSizeOptions: any = [10, 25, 100];
    selectedColId: any = [];
    allColHeaders: any = [];
    // selectedCol: any = [];
    equipmentPageNumber: number = 1;
    equipmentPageSize: number = 10;
    equipmentPageCount = 1;

    equipmentList:any[] = [];

    tabOptions = {
      assigned: JMLanguage.translate('pages.pm-job-equipment-list.assigned'),
      unassigned: JMLanguage.translate('pages.pm-job-equipment-list.unassigned')
    };
    activeTab;
    numberOfRowsSelected: number = 0;
    submitBtnName: string;

    constructor(injector: Injector) {
        super(injector);
      }

    ngOnInit() {
        this.breadcrumbs = [
            { id: 'breadcrumbs-pm-plan', name: JMLanguage.translate('pages.pm-plan-equipment-list.pm-plan'), route: '/pm/plan-list', currentPage: false },
            { id: 'breadcrumbs-pm-plan-number', name: this.pmPlanNumber, route: '/pm/standard-plan/view/' + this.pmPlanNumber, currentPage: false },
            { id: 'breadcrumbs-pm-plan-equipment', name: JMLanguage.translate('pages.pm-plan-equipment-list.equipment'), route: null, currentPage: true }
          ];
        this.setActiveTab(this.tabOptions.assigned);
        this.initTableHeader();
        this.initSelectedCol();
        this.initTablexParam();
        this.requestPmPlan();
    }

    // ----------- API ----------- //
    private async requestPmPlan() {
        if(!this.pmPlanNumber) return;

        const request: JM.JMRequestGetPmPlan = new JM.JMRequestGetPmPlan();
        request.pmPlanNumber = this.pmPlanNumber;
    
        const response: JM.JMResponseGetPmPlan = await AppDelegate.sendJMRequest(request);
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        
          this.plan = response.payload;
          this.isJmPmPlan = !!(this.plan.workCentre && this.plan.agreementNumber);
          this.requestEquipmentSummary();
    }

    async requestEquipmentSummary(){
      this.selectRow(null);
      let request: JM.JMRequestGetPmPlanAssignedEquipmentList | JM.JMRequestGetPmPlanUnassignedEquipmentList;

      switch (this.activeTab) {
        case this.tabOptions.assigned:
          request = new JM.JMRequestGetPmPlanAssignedEquipmentList();
          break;
        case this.tabOptions.unassigned:
          request = new JM.JMRequestGetPmPlanUnassignedEquipmentList();
          break;
      }
      
        this.tablexParam['isLoadingTable'] = true;
        // this.prepareRequestFilter(request);
        request.sortBy = "equipmentNumber";
        request.sortOrder = JMENUM.SortOrder.ASC;
        request.pageNumber  = this.equipmentPageNumber;
        request.pageSize = this.equipmentPageSize;
        request.parameters = ["_id", "equipmentNumber", "description", "location", "locationDescription", "clientShortName", "locationDescription", "equipmentType", "slaItemList"];
        request.pmPlanNumber = this.pmPlanNumber;

        // if (this.searchInputText && this.searchInputText != ''){
        //     request.equipmentNumber = this.splitSearchInput();
        // }
        
        this.equipmentList = [];

        const response : JM.JMResponseGetPmPlanAssignedEquipmentList | JM.JMResponseGetPmPlanUnassignedEquipmentList = await AppDelegate.sendJMRequest(request);
        if (!response || !response.code || response.code != 200 || !response.payload) {
            AppDelegate.openErrorBar(response);
            return;
        }
        this.equipmentList = response.payload.records;
        this.equipmentCount = response.payload.totalCount;
        this.equipmentPageCount = Math.ceil(response.payload.totalCount / this.equipmentPageSize);
        this.tablexParam.pageCount = this.equipmentPageCount;
        this.tablexParam.currentPage = request.pageNumber;
        this.planVersion = response.payload.planVersion;
        

        this.renderTable();
    }

    private async addEquipmentToPmPlan(equipmentlist: string[]) {
        if(!this.pmPlanNumber) return;
    
        const request = new JM.JMRequestAddEquipmentToPmPlan();
        request.pmPlanNumber = this.pmPlanNumber;
        request.equipmentNumbers = equipmentlist;
        request.version = this.planVersion;

        const response: JM.JMResponseAddEquipmentToPmPlan = await AppDelegate.sendJMRequest(request);
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        return;
    }

    private async removeEquipmentFromPmPlan(equipmentlist: string[]) {
        if(!this.pmPlanNumber) return;
    
        const request = new JM.JMRequestRemoveEquipmentFromPmPlan();
        request.pmPlanNumber = this.pmPlanNumber;
        request.equipmentNumbers = equipmentlist;
        request.version = this.planVersion;

        const response: JM.JMResponseRemoveEquipmentFromPmPlan = await AppDelegate.sendJMRequest(request);
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        return;
    }

    renderTable(){
        this.tablexParam['headers'] = this.allColHeaders.filter(col => {
          return this.selectedColId.includes(col.id);
        });
        
        this.tablexParam['content'] = this.equipmentList.map((data, index) => {          
          let originRow = {
            equipmentId          : data.equipmentNumber,
            description          : data.description,
            location             : data.location,
            locationDescription  : data.locationDescription ? data.locationDescription[JMLanguage.getCurrentLanguage()] : "",
            clientShortName      : data.clientShortName,
            equipmentType        : data.equipmentType,
            slaType              : Array.isArray(data.slaType) ? data.slaType.join() : '',
            // createdDate          : data['createdAt'] ? moment(data['createdAt']).format(Constants.DATE_FORMAT) : null,
            // lastUpdatedDate      : data['updatedAt'] ? moment(data['updatedAt']).format(Constants.DATE_FORMAT) : null,
          };
          
          let row = [];
          for(let header of this.allColHeaders) {
            if (this.selectedColId.includes(header.id)) {
              row.push(originRow[header.id]);  
            }
          }
          
          return row;
        });
        
        this.tablexParam['isLoadingTable'] = false;
    }

    initSelectedCol(){
        this.selectedColId = [
          "equipmentId",
          "description",
          "location",
          "locationDescription",
          "clientShortName",
          "equipmentType",
          "slaType"
        ];
        // let cachedUserSettings = Session.userInfo.userSettings;
        // if (cachedUserSettings && cachedUserSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS]) {
        //   Object.assign(this.selectedColId, cachedUserSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS]);
        // }
    }

    onPageNumberClicked = (pageIndex: number) => {
        this.equipmentPageNumber  = pageIndex;
        this.requestEquipmentSummary();
    }

    onPageSizeClicked = (pageSize: number) => {
        this.equipmentPageNumber  = 1;
        this.equipmentPageSize = pageSize;
        this.requestEquipmentSummary();
    }
    
    onRowClicked = (index, row) => {
        this.selectRow(index);
    }

    initTablexParam(){
        this.tablexParam = {
          isLoadingTable        : false,
          enableSetPageSize     : true,
          enablePagination      : true,
          enableColFilter       : false,
          filter                : {},
          tableRow              : "row",
          tableClass            : "ccs-equipment-list-table",
          tableWrapperClass     : "",
          pageSizeOptions       : this.pageSizeOptions,
          currentPageSize       : this.equipmentPageSize,
          currentPage           : this.equipmentPageNumber,
          pageCount             : this.equipmentPageCount,
          selectedColId         : this.selectedColId,
          fullColNameList       : this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
          onPageNumberClicked   : this.onPageNumberClicked,
          onPageSizeClicked     : this.onPageSizeClicked,
          onFilterChanged       : this.onFilterChanged,
        //   onColFiltered         : this.onColFiltered,
          onRowClicked          : this.onRowClicked,
        //   filterDebounceTime    : Constants.DEBOUNCE_TIME,
          highlightedRows       : [],
          headers               : this.allColHeaders.filter(col => {
                                  return this.selectedColId.includes(col.id);
                                }),
        }
    }

    initTableHeader() {
        this.allColHeaders = [
          {
            id: 'equipmentId',
            name: "pages.pm-plan-equipment-list.table-column.equipment-id",
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'description',
            name: "pages.pm-plan-equipment-list.table-column.description",
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'location',
            name: "pages.pm-plan-equipment-list.table-column.location-code", 
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'locationDescription',
            name: "pages.pm-plan-equipment-list.table-column.location-description", 
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'clientShortName',
            name: "pages.pm-plan-equipment-list.table-column.client", 
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col"
          },
          {
            id: 'equipmentType',
            name: "pages.pm-plan-equipment-list.table-column.equipment-type",    
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'slaType',
            name: "pages.pm-plan-equipment-list.table-column.sla-type",
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col ",
          }
        ]
    }

    getSelectedText(selected, total) {
      return JMLanguage.translate('pages.pm-plan-equipment-list.selected-text', [selected, total]);
    }

    setActiveTab(tab) {
      switch (tab) {
        case this.tabOptions.assigned:
          this.submitBtnName = JMLanguage.translate('pages.pm-job-equipment-list.unassign');
          break;
        case this.tabOptions.unassigned:
          this.submitBtnName = JMLanguage.translate('pages.pm-job-equipment-list.assign');
          break;
      }
      this.activeTab = tab;
    }

    onClickSetActiveTab = (tab) => {
      this.setActiveTab(tab);
      this.equipmentPageNumber = 1;
      this.requestEquipmentSummary();
    }
    
    onSelectAll = () =>{
      let content = this.tablexParam.content;
      this.tablexParam.highlightedRows = content.map(e=>true);
      this.numberOfRowsSelected = content.length;
    }

    onUnselectAll = () =>{
      this.tablexParam.highlightedRows = [];
      this.numberOfRowsSelected = 0;
    }

    selectRow(index: number) {
      if (index == null) {
        this.tablexParam.highlightedRows = [];
      } else {
        let prevState = this.tablexParam.highlightedRows[index];
        this.tablexParam.highlightedRows[index] = !prevState;
      }
      this.numberOfRowsSelected = this.tablexParam.highlightedRows.filter(e => e).length;
    }

    onSubmitClick = () => {
      let selectedEquipmentList = [];
      this.tablexParam.highlightedRows.forEach((el, index) => {
        if (el) {
          selectedEquipmentList.push(this.tablexParam.content[index][0])
        }
      })
      if (selectedEquipmentList.length > 0) {
        this.submitEquipment(selectedEquipmentList);
      }
    }
  
    async submitEquipment(equipmentlist: string[]) {
      this.isSubmitting = true;
      switch (this.activeTab) {
        case this.tabOptions.assigned:
          await this.removeEquipmentFromPmPlan(equipmentlist);
          break;
        case this.tabOptions.unassigned:
          await this.addEquipmentToPmPlan(equipmentlist);
          break;
      }
      this.isSubmitting = false;
      
      this.equipmentPageNumber = 1;
      await this.requestEquipmentSummary();
    }

    onFilterChanged = (event, index, header, filter) => {
      //Missing feature from original page
    }
}