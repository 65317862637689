<div class="header">
    <h3>{{ "pages.sn.slider-panel.reject-sn" | translate}}</h3>
    <button class="standard brand-amber" (click)="onClickSubmit()" [disabled]="!confirmed || !reason || isLoading">
        <span *ngIf="isLoading">
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
        </span>
        <span *ngIf="!isLoading"><i class="fas fa-check space"></i>{{'global.submit' | translate}}</span>
    </button>
</div>
<div class="reason-drop-down">
    <ng-select bindLabel="description" bindValue="value" [items]="options"
        [(ngModel)]="reason" placeholder="{{ 'pages.sn.slider-panel.reject-sn-reason' | translate }}" [closeOnSelect]="true" [disabled]="isLoading">
    </ng-select>
</div>
<div>
    <textarea 
        maxlength="{{ getMaxRemarksLength }}" 
        type="text" 
        rows="5" 
        placeholder="{{ 'pages.sn.remarks' | translate}}" 
        [(ngModel)]="remark" 
        [disabled]="isLoading"
    >
    </textarea>
    <span 
        *ngIf="remark?.length >= getMaxRemarksLength" 
        class="red small"
    >
        {{ "textarea.warning.max-length" | translate:[getMaxRemarksLength]}}
    </span>
</div>
<div class="confirmation-message custom-control custom-checkbox">
    <input type="checkbox" id="confirm-reject-checkbox" class="confirmation-checkbox custom-control-input" [(ngModel)]="confirmed" [disabled]="isLoading">
    <label for="confirm-reject-checkbox" class="custom-control-label">{{ "pages.sn.slider-panel.confirm-reject-msg" | translate }}</label> 
</div>
