<div>

  <div class="title-wrap mx-0 mb-4">
    <h3 class="d-inline">{{ uiTitle }}</h3>
    <div class="button-wrap text-right d-inline-block float-right">
      <app-submit-button 
        [id]="'save_button'"
        [text]="'global.save'"
        [buttonClass]="' standard brand-amber mr-0'"
        [iconClass]="'fa-save'"
        [isDisabled]="!uiEnableSaveButton"
        [isLoading]="!uiEnableSaveButton"
        (onClick)="onSaveButtonClicked($event)" 
      ></app-submit-button>
    </div> 
  </div>
  
    
  <span class="mandatory"><span class="red">* </span>{{ 'create-team-form.team-name' | translate }}</span> <span class="text-danger">{{ uiErrorTeamName }}</span>
  <input id="id_team_name"
    name="uiTeamName"
    type="text"
    class="form-control"
    [(ngModel)]="uiTeamName"
  >
  <br/>
  {{ 'create-team-form.team-description' | translate }}
  <input id="id_team_description"
    name="uiTeamDescription"
    type="text"
    class="form-control"
    [(ngModel)]="uiTeamDescription"
  >

  <br/>
  <span class="text-danger">{{ uiErrorFax }}</span>

  <ng-container *ngIf="uiMode=='inhouse'">
    <fieldset class="custom-fieldset checkbox-container">
      <legend>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" 
            id="id_checkbox_enable_fax"
            class="custom-control-input" 
            [(ngModel)]="uiEnableFax"
            [ngModelOptions]="{standalone: true}"
            (change)="onEnableFaxChanged($event)"
          >
          <label class="custom-control-label" for="id_checkbox_enable_fax">
            {{ 'create-team-form.enable-fax' | translate}}
          </label>
        </div>
      </legend>
      <input id="id_input_fax"
        class="form-control my-0 mx-1" 
        name="uiFax"
        type="text"
        [disabled]="!uiEnableFax"
        [(ngModel)]="uiFax"
      >
      <div class="p-2">{{ 'create-team-form.fax-notes' | translate}}</div>
    </fieldset>
  </ng-container>
  <ng-container *ngIf="uiMode=='contractor'">
    <fieldset class="custom-fieldset checkbox-container">
      <legend>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" 
            id="id_checkbox_enable_fax_contractor"
            class="custom-control-input" 
            [(ngModel)]= "uiEnableFax"
            [ngModelOptions]="{standalone: true}"
            (change)="onEnableFaxChanged($event)"
          >
          <label class="custom-control-label" for="id_checkbox_enable_fax_contractor">
            {{ 'create-team-form.enable-fax' | translate}}
          </label>
        </div>
      </legend>
      <input id="id_input_fax"
        class="form-control my-0 mx-1" 
        name="uiFax"
        type="text"
        [disabled]="!uiEnableFax"
        [(ngModel)]="uiFax"
      >
      <div class="p-2">{{ 'create-team-form.fax-notes' | translate}}</div>
    </fieldset>
  </ng-container>

  <br/>
  <ng-container *ngIf="uiMode=='inhouse'">
    <span class="text-danger">{{ uiErrorEmail }}</span>
    <fieldset class="custom-fieldset checkbox-container">
      <legend>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" 
            id="id_checkbox_enable_email"
            class="custom-control-input" 
            [(ngModel)]="uiEnableEmail"
            [ngModelOptions]="{standalone: true}"
            (change)="onEnableEmailChanged($event)"
          >
          <label class="custom-control-label" for="id_checkbox_enable_email">
            {{ 'create-team-form.enable-email' | translate}}
          </label>
        </div>
      </legend>
      <ng-select id="id_input_email"
        id="id-uiSelectedEmail" 
        [items]="workCentreOfficerArray" 
        [searchable]="true"
        [multiple]="true"
        [loading]="uiLoadingWorkCentreOfficer"
        [(ngModel)]="uiSelectedEmail"
        [disabled]="!uiEnableEmail"
      ></ng-select>
      <div class="p-2">{{ 'create-team-form.email-notes' | translate}}</div>
    </fieldset>
  </ng-container>
  <ng-container *ngIf="uiMode=='contractor'">
    <div><span class="red">* </span>{{ 'create-team-form.title.contract' | translate }} <span class="text-danger">{{ uiErrorContract }}</span></div>
    <div>{{ 'create-team-form.title.contract-notes-1' | translate }}</div>
    <div>{{ 'create-team-form.title.contract-notes-2' | translate }}</div>
    <ng-select 
      id="id-uiContractOption" 
      bindLabel="displayName" 
      bindValue="value" 
      [items]="uiContractOption" 
      [searchable]="true"
      [loading]="uiLoadingContract"
      [(ngModel)]="uiContractorNumber"
    >
    </ng-select>

    <br>
    <div><span class="red">* </span>{{ 'create-team-form.title.inhouse-handling-team' | translate }} <span class="text-danger">{{ uiErrorInhouseHandlingTeam }}</span></div>
    <ng-select 
      id="id-uiInhouseHandlingTeamArray" 
      bindLabel="name" 
      bindValue="_id" 
      [items]="uiInhouseHandlingTeamArray" 
      [searchable]="true"
      [loading]="uiLoadingInhouseHandlingTeam"
      [(ngModel)]="uiInhouseHandlingTeam"
    >
    </ng-select>
  </ng-container>

  
  <p class="remarks mt-5">
    <span class="mandatory"><span class="red">* </span></span>{{ "create-team-form.mandatory" | translate}}
  </p>
</div>
