import { Component, Input, OnInit } from '@angular/core';
import { SnBatchReportSubmissionComponentStages } from '../../sn-batch-submission/sn-batch-report-submission.component'
import { Session } from 'src/app/services/session';
import { JMENUM, JMUTILITY } from '@ccep/CCEPConnector-ts';
@Component({
    selector: 'app-sn-batch-submission-stage-header',
    templateUrl: './sn-batch-submission-stage-header.component.html',
    styleUrls: ['./sn-batch-submission-stage-header.component.scss']
  })

export class SnBatchSubmissionStageHeaderComponent implements OnInit{
  @Input() stage: SnBatchReportSubmissionComponentStages = SnBatchReportSubmissionComponentStages.UPLOAD;
  uploadHistoryAccess: {
    permission: boolean,
    routing: string
  } = {
      permission: false,
      routing: '/sn/batch-report-submission/upload-history'
    }

    constructor() { }

    ngOnInit() {
      this.checkUploadHistoryAccessPermission();
    }

    get isStageUpload(): boolean {
      return this.stage === SnBatchReportSubmissionComponentStages.UPLOAD
    }


    get isStageVerification(): boolean {
      return this.stage === SnBatchReportSubmissionComponentStages.VERIFICATION
    }

    get isStageResult(): boolean {
      return this.stage === SnBatchReportSubmissionComponentStages.RESULT
    }

    checkUploadHistoryAccessPermission() {
      if (!JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.JOBCARD_COMPLETE, JMENUM.Permission.JOBCARD_COMPLETE_ALL], false)) {
        return
      }
      this.uploadHistoryAccess = {
        ...this.uploadHistoryAccess,
        permission: true
      }
    }
}