import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ParameterService } from '@services/parameter.service';
import * as qs from "qs";
import { BasePage } from 'src/app/ui/model/base/base';
import { Session } from 'src/app/services/session';
import { Constants } from 'src/constants';
import { environment } from 'src/environments/environment';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AuthorizationService } from '@services/authorization.service';
import { AppDelegate } from 'src/app/AppDelegate';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent extends BasePage implements OnInit {
  @Input() breadcrumbs: [] = [];
  @Input() navbarTitle: String;
  @Input() showActionSidebarIcon = false;
  @Output() sidebarClicked = new EventEmitter<boolean>();
  @Output() actionSidebarClicked = new EventEmitter<boolean>();
  @Output() languageChanged = new EventEmitter<boolean>();

  systemVersion: string = Constants.SYSTEM_VERSION;
  event: any;
  eventActionSidebar: any;
  user: JMOBJ.Post = undefined;
  language: JMENUM.Language;

  homeLinkTitle: string;

  private parameterService: ParameterService;


  constructor(injector: Injector, private authService: AuthorizationService) {
    super(injector);

    this.parameterService = injector.get(ParameterService);
  }

  ngOnInit() {
    this.user = Session.userInfo;
    this.language = Session.selectedLanguage;

    if (this.authService.hasOnlyWeatherFormPermission()) {
      this.homeLinkTitle = JMLanguage.translate("navigation.home");
    } else {
      this.homeLinkTitle = JMLanguage.translate("navigation.dashboard");
    }

    if (window.innerWidth <= 1000) {
      document.addEventListener('click', this.dismissSideBar);
      document.addEventListener('click', this.dismissActionSideBar);
      this.event = this.dismissSideBar;
      this.eventActionSidebar = this.dismissActionSideBar;
    }

    window.addEventListener('resize', this.changeSizeHandling);
  }

  changeSizeHandling = () => {
    if (window.innerWidth <= 1000) {
      if (!this.event) {
        document.addEventListener('click', this.dismissSideBar);
        this.event = this.dismissSideBar;
      } else if (!this.eventActionSidebar) {
        document.addEventListener('click', this.dismissActionSideBar);
        this.eventActionSidebar = this.dismissActionSideBar;
      }
    } else {
      if (this.event) {
        document.removeEventListener('click', this.dismissSideBar);
        this.event = null;
      } else if (this.eventActionSidebar) {
        document.removeEventListener('click', this.dismissActionSideBar);
        this.eventActionSidebar = null;
      }
    }

    let ele = document.getElementById("id_navbar_title");
    if (ele) {
      ele.classList.add('leftmost');
    }
  }

  dismissSideBar = (e) => {
    let sideBar = document.getElementById("sidebar");

    if (sideBar != null && window.innerWidth < 1000 && e.pageX > 190 && sideBar.classList.contains("active")) {
      sideBar.classList.remove("active");
    }
  }

  dismissActionSideBar = (e) => {
    let actionSideBar = document.getElementById("action-sidebar");

    if (actionSideBar != null
      && window.innerWidth < 1000 && e.pageX < (window.innerWidth - 96) && actionSideBar.classList.contains("active")) {
      actionSideBar.classList.remove("active");
    }
  }

  //Layout Event Function
  toggleSidebar() {
    let sideBar = document.getElementById("sidebar");
    sideBar.classList.add("active");
    // this.sidebarClicked.emit(true);
  }

  //Layout Event Function
  toggleActionSidebar() {
    let sideBar = document.getElementById("action-sidebar");
    if (sideBar.classList.contains("active")) {
      sideBar.classList.remove("active");
    } else {
      sideBar.classList.add("active");
    }
    // this.sidebarClicked.emit(true);
  }

  onLanguageButtonClicked(language: JMENUM.Language) {
    if (this.language != language) {
      const request = new JM.JMRequestContactPersonsUpdateContactPerson();
      request.post = Session.userInfo.name;
      request.languagePreference = language;
      this.language = language
      JMLanguage.changeLanguage(language);
      Session.setSelectedLanguage(this.language);
      this.translateService.use(Session.selectedLanguage);
      AppDelegate.sendJMRequest(request).finally(() => window.location.href = window.location.href)
      // in case the api have slow or no response
      setTimeout(() => {
        window.location.href = window.location.href
      }, 500);
    }
  }

  resetMenu() {
    //this.menuService.eventListener.emit([]);
  }

  onClickLogout() {
    AppDelegate.logout();
  }

  onClickSsoLogout() {
    const param = {
      key: environment.CCEP_PORTAL_SSO_KEY,
      successUrl: `${environment.CCEP_JM_WEB_HOST}/ccep-portal/logoutSuccess`,
      failureUrl: `${environment.CCEP_JM_WEB_HOST}/ccep-portal/logoutFailure`,
    }
    const queryStr = qs.stringify(param);
    window.location.assign(`${environment.CCEP_PORTAL_HOST}/sso/logout?${queryStr}`);
  }

  get isSsoLogin() {
    return Session.authSource == JMENUM.SourceSystem.CCEPPORTAL;
  }
}
