import { Component, Input, OnInit, Injector } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from "moment";
import { TranslateService } from '@ngx-translate/core';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AuthorizationService } from '@services/authorization.service';

@Component({
  selector: 'add-dummy-contract-form',
  templateUrl: './add-dummy-contract-form.component.html',
  styleUrls: ['./add-dummy-contract-form.component.scss']
})

export class AddDummyContractFormComponent implements OnInit {
  private translateService: TranslateService;

  constructor(injector: Injector, private authorizationService: AuthorizationService) {
    this.translateService = injector.get(TranslateService);
  }

  @Input() parameters: {
    allContractArray: JMOBJ.MaintenanceTermContract[],
    onSaveButtonClicked: Function,
    onDeleteButtonClicked: Function,
    mode: string, // add / edit
  }
  uiEnableSaveButton: boolean = undefined;
  uiEnableDeleteButton: boolean = undefined;
  uiEnableStartDate: boolean = undefined;
  uiEnableContractorNumber: boolean = undefined;
  uiLoadingSaveButton: boolean = undefined;
  uiLoadingDeleteButton: boolean = undefined;
  uiLiftTrapped: boolean = undefined;
  uiContractNumber: string = undefined;
  uiContractorNumber: string = undefined;
  uiContractorName: string = undefined;
  uiFindEquipmentBy: string = undefined;
  uiContractStartDate: {
    year: number,
    month: number,
    day: number,
  } = undefined;
  uiContractEndDate: {
    year: number,
    month: number,
    day: number,
  } = undefined;
  uiContractMinEndDate: {
    year: number,
    month: number,
    day: number,
  } = undefined;
  uiContractTitle: string = undefined;

  uiAllContractorNumberArray: string[] = undefined;
  uiAllContractorNameArray: string[] = undefined;
  uiAllFindEquipmentByOptions: any[] = [
    {
      name: JMLanguage.translate("pages.contract-dummy.dummy-find-equipment-by.option-1"),
      id: JMENUM.EquipmentCriteria.WORK_CENTRE,
    },
    {
      name: JMLanguage.translate("pages.contract-dummy.dummy-find-equipment-by.option-2"),
      id: JMENUM.EquipmentCriteria.ROUTING_RULE,
    }
  ];
  uiErrorMessage: string[] = undefined;
  uiHaveTeamLink: boolean = undefined;
  uiHaveSwitchOver: boolean = undefined;

  // data
  allContractDict: { [key: string]: string } = undefined;  // key: contractorNumber; val: contractName
  contractorDict: { [key: string]: string } = undefined; // key: vendorNumber, obj: vendorName

  // permissions
  hasDummyTranUpdatePermission: boolean = false;
  hasContractUpdatePermission: boolean = false;

  ngOnInit() {
    this.hasDummyTranUpdatePermission = this.authorizationService.hasPermission(JMENUM.Permission.CONTRACT_TRANSDUMMY_UPDATE);
    this.hasContractUpdatePermission = this.authorizationService.hasPermission(JMENUM.Permission.CONTRACT_UPDATE);
    this.clearView();
    this.setMode('add');
  }

  //--------------------------------------------------
  enableSaveButton(enable: boolean): void {
    this.uiEnableSaveButton = enable;
  }
  setContractorDict(dict: {}) {
    this.contractorDict = dict;
    this.uiAllContractorNumberArray = Object.keys(this.contractorDict);
    this.uiAllContractorNameArray = Object.values(this.contractorDict);
  }

  clearView() {
    let today = moment();
    this.uiEnableSaveButton = true;
    this.uiEnableDeleteButton = true;
    this.uiEnableContractorNumber = true;
    this.uiEnableStartDate = true;
    this.uiLoadingSaveButton = false;
    this.uiLoadingDeleteButton = false;
    this.uiContractNumber = '';//JMConstant.DUMMY_CONTRACT_PREFIX;
    this.uiContractorNumber = '';
    this.uiContractorName = '';
    this.uiContractStartDate = this.getTodayDate();
    this.uiContractEndDate = this.getTomorrowDate();
    this.uiContractMinEndDate = this.getTomorrowDate();
    this.uiContractTitle = undefined;
    this.uiAllContractorNumberArray = [];
    this.uiAllContractorNameArray = [];
    this.uiErrorMessage = [];
    this.uiHaveTeamLink = false;
    this.uiHaveSwitchOver = false;
    this.contractorDict = {};
  }
  private getTodayDate(): any {
    let today = moment();
    return {
      year: today.year(),
      month: today.month() + 1,
      day: today.date(),
    };
  }
  private getTomorrowDate(): any {
    let today = moment();
    return {
      year: today.year(),
      month: today.month() + 1,
      day: today.date() + 1,
    };
  }

  setMode(mode: string) {
    this.parameters.mode = mode;
  }

  setDeletable(deletable: boolean) {
    this.uiEnableDeleteButton = deletable;
    this.uiEnableContractorNumber = deletable;
    this.uiEnableStartDate = deletable;
  }

  setHaveTeam(haveTeam: boolean) {
    this.uiHaveTeamLink = haveTeam;
    this.setDeletable(!(haveTeam || this.uiHaveSwitchOver));
  }

  setHaveSwitchOver(haveSwitchOver: boolean) {
    this.uiHaveSwitchOver = haveSwitchOver;
    this.setDeletable(!(haveSwitchOver || this.uiHaveTeamLink));
  }
  setContractStartDate(startDate: string) {
    this.uiContractStartDate = {
      year: moment(startDate, "YYYYMMDD").year(),
      month: moment(startDate, "YYYYMMDD").month() + 1,
      day: moment(startDate, "YYYYMMDD").date(),
    };
  }
  setContractEndDate(endDate: string) {
    let m = moment(endDate);
    this.uiContractEndDate = {
      year: moment(endDate, "YYYYMMDD").year(),
      month: moment(endDate, "YYYYMMDD").month() + 1,
      day: moment(endDate, "YYYYMMDD").date(),
    };
  }

  setLoadingDelete(deleting) {
    this.uiEnableDeleteButton = !deleting;
    this.uiEnableSaveButton = !deleting;
    this.uiLoadingDeleteButton = deleting;
  }

  setLoadingSave(saving) {
    this.uiEnableDeleteButton = !saving;
    this.uiEnableSaveButton = !saving;
    this.uiLoadingSaveButton = saving;
  }

  //--------------------------------------------------
  // button callback functions
  onContractorNumberChanged(event) {
    if (this.uiContractorNumber && this.uiContractorNumber in this.contractorDict) {
      this.uiContractorName = this.contractorDict[this.uiContractorNumber];
    }
  }

  onContractorNameChanged(event) {
    if (Object.keys(this.contractorDict) && Object.keys(this.contractorDict).length && Object.keys(this.contractorDict).includes(this.uiContractorName)) {
      for (let cnum of Object.keys(this.contractorDict)) {
        let name = this.contractorDict[cnum];
        if (name === this.uiContractorName) {
          this.uiContractorNumber = cnum;
          return;
        }
      }
    }
  }
  onContractNumberKeyup(event) {
    if (event.target && event.target.value) {
      if (event.target.value.match(/\s/g)) {
        event.target.value = event.target.value.replace(/\s/g, '')
      }
    }
  }

  onSaveButtonClicked(event) {
    // validate
    this.uiErrorMessage = [];
    if (this.uiContractNumber == undefined || this.uiContractNumber == null || this.uiContractNumber.length == 0) {
      this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.dummy-contract-number-cannot-empty"));
    }
    if (this.uiContractorNumber == undefined || this.uiContractorNumber == null || this.uiContractorNumber.length == 0) {
      this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.contractor-number-cannot-empty"));
    }
    if (this.uiContractorName == undefined || this.uiContractorName == null || this.uiContractorName.length == 0) {
      this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.contractor-name-cannot-empty"));
    }
    if (this.uiContractEndDate == undefined || this.uiContractEndDate == null) {
      this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.dummy-contract-end-date-cannot-empty"));
    }
    if (this.uiContractStartDate == undefined || this.uiContractStartDate == null) {
      this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.dummy-contract-start-date-cannot-empty"));
    }
    if (this.isInvalidContractStartDate()) {
      this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.invalid-contract-start-date"));
    }
    if (this.isInvalidContractEndDate()) {
      this.uiErrorMessage.push(this.translateService.instant("pages.contract-dummy.invalid-contract-end-date"));
    }

    if (this.uiErrorMessage.length > 0) {
      return;
    }

    // tell parent
    if (this.parameters.onSaveButtonClicked) {
      this.parameters.onSaveButtonClicked(this);
    }
  }
  private isInvalidContractStartDate(): boolean {
    const startDate = moment(new Date(this.uiContractStartDate.year, this.uiContractStartDate.month - 1, this.uiContractStartDate.day));
    const endDate = moment(new Date(this.uiContractEndDate.year, this.uiContractEndDate.month - 1, this.uiContractEndDate.day));
    return startDate.isAfter(endDate);
  }
  private isInvalidContractEndDate(): boolean {
    const today = moment();
    const endDate = moment(new Date(this.uiContractEndDate.year, this.uiContractEndDate.month - 1, this.uiContractEndDate.day));
    return today.isSameOrAfter(endDate);
  }

  onDeleteButtonClicked(event) {
    if (this.parameters.onSaveButtonClicked) {
      this.parameters.onDeleteButtonClicked(JM.JMConstant.DUMMY_CONTRACT_PREFIX + this.uiContractNumber, JMENUM.MaintenanceContractType.DUMMY_CONTRACT);
    }
  }

  validateNumber(event: KeyboardEvent): boolean {
    const isAvailableKey = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.code) ? true
      : !isNaN(Number(event.key)) && event.code !== 'Space';
    return isAvailableKey;
  }

  validateNumberWhenKepUp(event: any) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
  }
}