<div class="wrapper">
  <app-nav [navbarTitle]="'pages.pm-outstanding-equip-list.page-title' | translate">
  </app-nav>
  
  <app-sidebar></app-sidebar>

  <div id="content">

    <div class="row mx-0 flex-column-reverse flex-xl-row">
      <div class="col-12">
        <button class="btn standard brand-blue ml-auto d-block" (click)="onClickCreatePMTask()" [disabled]="!selectedInfo">
          {{ "pages.pm-outstanding-equip-list.create-pm-job" | translate }}
        </button>

        <app-tablex #pmOutstandingEquipListTable
          id="pm-outstanding-equip-table"
          [tablexParam]="tablexParam"
        ></app-tablex>
      </div>
    </div>
  </div>
</div>

