<div id="pmJobTimesheetComponent" class="wrapper">
  <app-nav [navbarTitle]="title"></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content">
    <app-pm-job-timesheet-steps-header [stage]="stage"></app-pm-job-timesheet-steps-header>

    <ng-container *ngIf="isStageSubmitted">
      <div class="mt-2">
        {{ 'component.pm-job-timesheet.submitted.start' | translate }}
        <a href="/pm/job/timesheet/upload-history">{{ 'component.pm-job-timesheet.submitted.link' | translate }}</a>
        {{ 'component.pm-job-timesheet.submitted.end' | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="isStageDownload">
      <div class="mt-2">
        {{ 'component.pm-job-timesheet.memo' | translate }}
      </div>
      <!-- download button-->
      <app-submit-button
        [id]="'pmJobTimesheet_button_download'"
        [text]="'component.pm-job-timesheet.button.download'"
        [buttonClass]="'standard brand-blue my-1'"
        [iconClass]="'fa-download'"
        [isDisabled]="button.downloadTemplate.isLoading || isDisabledAllButton"
        [isLoading]="button.downloadTemplate.isLoading"
        (onClick)="onClickedDownloadTemplateButton()"
      ></app-submit-button>
      <!-- next button -->
      <app-submit-button
        [id]="'pmJobTimesheet_button_next'"
        [text]="'component.pm-job-timesheet.button.next'"
        [buttonClass]="'standard brand-blue m-1'"
        [isDisabled]="isDisabledAllButton"
        (onClick)="onClickedNextButton()"
      ></app-submit-button>
    </ng-container>

    <ng-container *ngIf="isStageUpload || isStageVerificationSuccessful || isStageVerificationFailed">
      <div *ngIf="isStageVerificationSuccessful || isStageVerificationFailed" class="my-2">
        <div class="font-size-l">{{ 'component.sn-batch-complete.validation-result.title' | translate }}</div>
        <div class="mt-3">
          {{ 'component.sn-batch-complete.validation-result.pass' | translate }} {{ validationResult.successCount }}
        </div>
        <div class="">
          {{ 'component.sn-batch-complete.validation-result.fail' | translate }}
          <span [class.red]="validationResult.failedCount">{{ validationResult.failedCount }}</span>
        </div>
      </div>
      <app-submit-button
        *ngIf="isStageVerificationSuccessful"
        [id]="'sn_batch_complete-submit_button'"
        [text]="'component.sn-batch-complete.main.button.submit'"
        [buttonClass]="'standard brand-amber mt-1 mb-4'"
        [iconClass]="'fa-save'"
        [isDisabled]="button.submit.isLoading || isDisabledAllButton || !selectedFile"
        [isLoading]="button.submit.isLoading"
        (onClick)="onClickedSubmitButton()"
      ></app-submit-button>
      <!-- download error report button -->
      <app-submit-button
        *ngIf="isStageVerificationFailed"
        [id]="'sn_batch_complete-download_error_report_button'"
        [text]="'component.sn-batch-complete.main.button.error-report'"
        [buttonClass]="'standard brand-red mt-1 mb-4'"
        [iconClass]="'fa-download'"
        [isDisabled]="isDisabledAllButton"
        (onClick)="onClickedDownloadErrorReportButton()"
      ></app-submit-button>

      <ng-container *ngIf="isStageUpload || isStageVerificationFailed">
        <div>{{ uploadTitle }}</div>
        <div class="my-2 w-40">
          <app-file-selector
            [fileAcceptType]="'.xlsx'"
            [clear]="fileSelector.clear"
            [isDisabled]="isDisabledAllButton"
            (updatedFile)="onUpdatedFile($event)"
          ></app-file-selector>
        </div>
        <!-- upload button-->
        <app-submit-button
          [id]="'sn_batch_complete-upload_button'"
          [text]="'component.sn-batch-complete.main.button.upload'"
          [buttonClass]="'standard brand-amber my-1'"
          [iconClass]="'fa-upload'"
          [isDisabled]="button.upload.isLoading || isDisabledAllButton || !selectedFile"
          [isLoading]="button.upload.isLoading"
          (onClick)="onClickedUploadButton()"
        ></app-submit-button>
        <app-submit-button
          [id]="'pmJobTimesheet_button_back'"
          [text]="'component.sn-batch-complete.main.button.back'"
          [buttonClass]="'standard brand-blue m-1'"
          (onClick)="onClickedBackButton()"
        ></app-submit-button>
      </ng-container>
    </ng-container>
  </div>
</div>
