import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Injector } from '@angular/core';
import { JMENUM } from '@ccep/CCEPConnector-ts';
import { BasePage } from '../../model/base/base';
import { saveAs } from "file-saver";


@Component({
  selector: 'app-flms-import-panel',
  templateUrl: './flms-import-panel.component.html',
  styleUrls: ['./flms-import-panel.component.scss']
})
export class FlmsImportPanelComponent extends BasePage implements OnInit {
  @ViewChild('attachmentFile', { static: false }) attachmentFile: ElementRef;
  @Input() parameters = {
    isLoading: false,
    files: [],
    uploadResult: {},
    attachmentType: JMENUM.LocationImportType.CHINESE_LOCATION
  };

  @Output() onUpload = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();

  files: any = [];
  attachmentType = JMENUM.LocationImportType;
  hasImportLocationChiPermission = false;
  hasImportLocationPermission = false;

  constructor(injector: Injector) { super(injector); }

  ngOnInit(): void {
    this.parameters.attachmentType = JMENUM.LocationImportType.CHINESE_LOCATION;
    if (this.authorizationService.hasPermission(JMENUM.Permission.FLMS_UPLOAD_CHI)) {
      this.hasImportLocationChiPermission = true;
    }
    if (this.authorizationService.hasPermission(JMENUM.Permission.FLMS_UPLOAD)) {
      this.hasImportLocationChiPermission = true;
      this.hasImportLocationPermission = true;
    }
  }

  onClickClose() {
    this.onClose.emit();
    this.attachmentFile.nativeElement.value = [];
  }

  onFilesDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    let files = event.dataTransfer.files;
    if (files.length > 0) {
      this.parameters.files = files[0];
      this.onUpload.emit();
    }
  }

  allowDrop(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onClickUploadAttachment(event) {
    let files = event.target.files
    if (files.length > 0) {
      this.parameters.files = files[0];
      this.onUpload.emit();
    }
  }

  allowReUpload() {
    this.parameters.isLoading = false;
    this.attachmentFile.nativeElement.value = [];
  }

  saveChineseTemplate() {
    saveAs("../../../assets/Functional Location (Chinese) Template.xlsx", "Functional Location (Chinese) Template.xlsx");
  }

  saveTemplate() {
    saveAs("../../../assets/Functional Location Template.xlsx", "Functional Location Template.xlsx");
  }


}

