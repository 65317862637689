<div class="col-12 font-size-xl m-0 mb-2">
  <div class="overflow-hidden">
    <div class="d-inline-block">
      {{ 'component.job-details.title' | translate }}
    </div>

    <div *ngIf="pageMode != JMENUM.JMPageMode.CREATE" class="d-inline-block">
      <span id="job-desc-job-nature" class="maintenance-type mx-1 font-size-s" *ngIf="jobCard.jobNature">
        {{ descriptionLabel }}
      </span>
      <span id="job-desc-handling-party" class="handling-party-type mx-1 font-size-s" *ngIf="jobCard.handlingParty">
        {{ "team.handling-party." + jobCard.handlingParty | translate }}
      </span>
    </div>
    <div *ngIf="pageMode != JMENUM.JMPageMode.CREATE" class="overflow-hidden d-inline-block float-right">
      <app-display-text-field id="job-desc-status" [textField]="jobStatus" [emptyHandleDisplay]="emptyHandle"
          [attr.data-content]="jobCard.status">
      </app-display-text-field>
    </div>
  </div>
</div>

<hr class="underline" />
<ng-content select="[instruction]"></ng-content>

<div class="row align-items-center">
  <div *ngIf="isJobNatureCM || isJobNaturePM" class="d-flex pl-0 pr-0 col-12">
    <!-- Maintenance Type -->
    <div class="col-2 m-0 text-right mb-4 gray-title pl-0 pr-1">
      <label>
        <span class="red">*</span>
        {{ 'component.job-card.job-details.label.job-nature' | translate }}
      </label>
    </div>
    <div class="col-10 m-0 text-left mb-4 font-size-s">
      <div class="btn-group">
        <button
          id="form-item-job-details-maintenance-type-cm"
          class="btn"
          [class.active]="isJobNatureCM"
          (click)="onMaintenanceTypeButtonClick(JMENUM.JobNature.CM)"
        >
          CM
        </button>
        <button
          id="form-item-job-details-maintenance-type-pm"
          class="btn"
          [class.active]="isJobNaturePM"
          (click)="onMaintenanceTypeButtonClick(JMENUM.JobNature.PM)"
        >
          PM
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isJobNatureStandalone" class="d-flex pl-0 pr-0 col-12">
    <!-- MAT Type -->
    <div class="col-2 m-0 text-right mb-4 gray-title pl-0 pr-1">
      <label for="">
        <span *ngIf="fields.matType?.mandatory" class="red">*</span>
        {{ 'pages.sn.MAT' | translate }}
      </label>
    </div>
    <div class="col-4 m-0 text-left mb-4 font-size-s">
      <ng-select *ngIf="fields.matType?.editable"
        id="mat-dropdown"
        bindLabel="description"
        bindValue="value"
        [items]="matOptions"
        [class.input-error]="errorFields.matType"
        [ngModel]="jobCard.matType"
        (ngModelChange)="ngModelChangeForJobCard('matType', $event)"
      ></ng-select>
      <span *ngIf="!fields.matType?.editable">{{ jobCard.matType }}</span>
    </div>
  </div>

  <div class="d-flex flex-wrap pl-0 pr-0 col-12">
    <!-- job Type -->
    <div class="col-2 m-0 text-right mb-4 gray-title pl-0 pr-1">
      <label for="">
        <span class="red">*</span>
        {{ 'pages.sn.job-type' | translate }}
      </label>
    </div>
    <div class="col-4 m-0 text-left mb-4 font-size-s">
      <ng-select *ngIf="!isJobNatureStandalone"
        #inputJobType="ngModel"
        id="form-item-job-details-job-type"
        bindLabel="label"
        bindValue="value"
        [items]="jobTypeOptions"
        [disabled]="!isJobNatureCM"
        [class.input-error]="errorFields?.orderType"
        [ngModel]="jobCard.orderType"
        (ngModelChange)="ngModelChangeForJobCard('orderType', $event)"
        required
      ></ng-select>
      <span *ngIf="isJobNatureStandalone">{{ jobCard.orderType }}</span>
    </div>
    <div *ngIf="isJobNatureStandalone" class="col-6"></div>

    <div class="col-2 m-0 text-right mb-4 gray-title pl-0 pr-1">
      <label for="">
        <span class="red">*</span>
        {{ 'component.job-card.job-details.label.handling-team' | translate }}
      </label>
    </div>
    <div class="col-4 m-0 text-left mb-4 font-size-s">
      <ng-container *ngIf="fields.teamId?.editable">
        <ng-select *ngIf="isJobNatureStandalone"
          id="form-item-job-details-handling-team"
          bindValue="value"
          bindLabel="label"
          [items]="teamOptions.items"
          [class.input-error]="errorFields?.teamId"
          [ngModel]="jobCard.snTeamId"
          (ngModelChange)="ngModelChangeForJobCard('snTeamId', $event)"
          (click)="onTeamSelectClick()"
          (clear)="onTeamSelectClear()"
          (search)="onTeamSelectSearchDebounce($event)"
          (scrollToEnd)="onTeamSelectScrollToEndDebounce()"
          (change)="onHandlingTeamSelectChange($event)"
          required
        >
        </ng-select>
        <ng-select *ngIf="!isJobNatureStandalone"
          id="form-item-job-details-handling-team"
          bindValue="value"
          bindLabel="label"
          [items]="teamOptions.items"
          [class.input-error]="errorFields?.teamId"
          [ngModel]="sn.teamId"
          (ngModelChange)="ngModelChangeForSn('teamId', $event)"
          (click)="onTeamSelectClick()"
          (clear)="onTeamSelectClear()"
          (search)="onTeamSelectSearchDebounce($event)"
          (scrollToEnd)="onTeamSelectScrollToEndDebounce()"
          (change)="onHandlingTeamSelectChange($event)"
          required
        >
        </ng-select>
      </ng-container>
      <app-display-text-field *ngIf="!fields.teamId?.editable"
        [textField]="teamName"
      ></app-display-text-field>
    </div>
  </div>

  <div class="d-flex pl-0 pr-0 col-12">
    <!-- Job Description -->
    <div class="col-2 m-0 text-right mb-4 gray-title pl-0 pr-1">
      <label for="">
        <span *ngIf="fields.jobDescription?.mandatory" class="red">*</span>
        {{ 'component.job-card.job-details.label.job-description' | translate }}
      </label>
    </div>
    <div class="col-10 m-0 text-left mb-4 font-size-s overflow-hidden">
      <textarea *ngIf="fields.jobDescription?.editable"
        id="form-item-job-details-job-description"
        class="form-control"
        rows="3"
        [maxlength]="textareaMaxLength"
        [class.input-error]="errorFields?.jobDescription"
        [ngModel]="jobCard.jobDescription"
        (ngModelChange)="ngModelChangeForJobCard('jobDescription', $event)"
        required
      ></textarea>
      <app-display-text-field *ngIf="!fields.jobDescription?.editable"
        [textField]="jobCard.jobDescription"
      ></app-display-text-field>
    </div>
  </div>
</div>
