<div class="mb-2">
    <div class="clearfix">
        <span class="title float-left">{{ "component.inspection.job.inspection" | translate }}</span>
        <app-submit-button [id]="'job_inspection_submit_button'" [text]="'global.submit'"
            [buttonClass]="'float-right standard brand-blue'" [isDisabled]="submitDisabled || isLoading"
            [isLoading]="isLoading" (onClick)="onClickSubmit()"></app-submit-button>
    </div>
    <div class="py-2 clearfix">
        <span class="font-size-s float-left">{{ "component.inspection.job.inspection.please-input" | translate }}</span>
        <span class="font-size-s float-right"><span class="red pr-1">*</span>{{ "global.mandatory-fields" |
            translate }}</span>
    </div>

    <div class="py-2 d-flex">
        <label for="form-item--inspectorName" class="d-flex align-items-center mb-0 col-md-6 px-0">
            <span class="required"><span class="red pr-1">*</span>{{ 'component.inspection.job.inspection.name.title' | translate }}</span>
        </label>
        <div class="col-md-6 dropdown-group px-1">
            <input 
                id="form-item--inspectorName"
                class="form-control" 
                type="text"
                maxlength="35"
                [(ngModel)]="jobInspectionInput.inspectorName"
                (ngModelChange)="onChangeSubmitButtonStatusCheck();"
                required
              />
        </div>
    </div>

    <div class="py-2 d-flex">
        <label for="form-item--inspectorPost" class="d-flex align-items-center mb-0 col-md-6 px-0">
            <span class="required"><span class="red pr-1">*</span>{{ 'component.inspection.job.inspection.post.title' | translate }}</span>
        </label>
        <div class="col-md-6 dropdown-group px-1">
            <input 
                id="form-item--inspectorPost"
                class="form-control" 
                type="text"
                maxlength="35"
                [(ngModel)]="jobInspectionInput.inspectorPost"
                (ngModelChange)="onChangeSubmitButtonStatusCheck();"
                required
              />
        </div>
    </div>

    <div class="py-2 d-flex">
        <span class="font-size-s col-md-6 pl-0"><span class="red pr-1">*</span>{{ "component.inspection.job.inspection.result.title" |
            translate }}</span>
        <div class="col-md-6 px-1">
            <ng-container *ngFor="let option of inspectionResultOptions; let i = index">
                <div class="pt-1 radio-wrap custom-control custom-radio">
                    <input class="radio custom-control-input" type="radio" id="inspectionResultReason{{i}}"
                        name="inspectionResult-reason" [(ngModel)]="jobInspectionInput.inspectionResult"
                        value="{{option.value}}" (ngModelChange)="onChangeSubmitButtonStatusCheck()">
                    <label class="custom-control-label m-0" for="inspectionResultReason{{i}}">{{option.description}}</label>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="py-2 d-flex">
        <span class="font-size-s col-md-6 pl-0"><span class="red pr-1">*</span>{{
            "component.inspection.job.inspection.modeOfCheck.title" | translate }}</span>
        <div class="col-md-6 px-1">
            <ng-container *ngFor="let option of modeOfCheckOptions; let i = index">
                <div class="pt-1 radio-wrap custom-control custom-radio">
                    <input class="radio custom-control-input" type="radio" id="modeOfCheckReason{{i}}"
                        name="modeOfCheck-reason" [(ngModel)]="jobInspectionInput.modeOfCheck" value="{{option.value}}"
                        (ngModelChange)="onChangeSubmitButtonStatusCheck()">
                    <label class="custom-control-label m-0" for="modeOfCheckReason{{i}}">{{option.description}}</label>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="py-2 row mr-1">
        <span class="col-6 font-size-s d-flex align-items-center"><span class="red pr-1">*</span>{{
            "component.inspection.job.inspection.inspectionTime.title" | translate }}</span>
        <app-date-input class="col-4 d-inline-block" [input]="dateInputSet['inspectionDate']"
            [inputId]="'inspectionDateInput'" [field]="'inspectionDate'" (onBlur)="onBlurDateInput($event)"
            [dateAlertMsg]="dateAlertMsg" [isError]="errorFields?.inspectionDate" [validator]="isDateValid">
        </app-date-input>
        <app-time-input class="col-2 p-0" [isError]="errorFields?.inspectionTime" [customClass]="'form-control'"
            [input]="dateInputSet['inspectionTime']" [inputId]="'inspectionTimeInput'" [field]="'inspectionTime'"
            [alertMsg]="timeAlertMsg" (onBlur)="onBlurDateInput($event)">
        </app-time-input>
    </div>
    <div class="py-2">
        <textarea id="remark" name="form-item-remark" class="form-control" rows="6"
            maxlength="{{ getMaxRemarksLength }}"
            placeholder="{{ 'component.inspection.job.inspection.placeholder.remark' | translate }}"
            [(ngModel)]="jobInspectionInput.remark">
        </textarea>
    </div>
    <div class="py-2">
        <label for="image_file_input" id="choose-file-btn"
            class="choose-file-btn py-0 px-0 mx-0 mb-0 col-auto text-center align-self-center">
            {{ "component.inspection.job.inspection.attachment.photo" | translate }}
            <i class="pl-1 fas fa-plus blue fa-lg cursor-pointer"></i>
        </label>
        <input #imageFileUpload class="input-file" id="image_file_input" type="file"
            (change)="onChangeImageFileInput($event)" multiple="multiple" accept="{{imageAcceptType}}" hidden />
    </div>
    <div class="align-items-center" *ngIf="imageFiles">
        <div *ngFor="let file of imageFiles; let i = index">
            <div class="pt-1 col-12">
                <a class="name" (click)="onClickDownloadLocalFile(file, i)">{{ file?.name || file?.description }}</a>
                <button id="delete-selected-image-file-btn" class="btn mx-2" [disabled]="isDisabled"
                    (click)="onClickImageFileDelete(i)">
                    <label class="mb-0"><i class="fas red fa-times cursor-pointer"></i></label>
                </button>
            </div>
        </div>
    </div>
    <div class="py-2">
        <label for="pdf_file_input" id="choose-file-btn"
            class="choose-file-btn py-0 px-0 mx-0 mb-0 col-auto text-center align-self-center">
            {{ "component.inspection.job.inspection.attachment.document" | translate }}
            <i class="pl-1 fas fa-plus blue fa-lg cursor-pointer"></i>
        </label>
        <input #pdfFileUpload class="input-file" id="pdf_file_input" type="file" (change)="onChangePdfFileInput($event)"
            multiple="multiple" accept="{{pdfAcceptType}}" hidden />
    </div>
    <div class="align-items-center" *ngIf="pdfFiles">
        <div *ngFor="let file of pdfFiles; let i = index">
            <div class="pt-1 col-12">
                <a class="name" (click)="onClickDownloadLocalFile(file, i)">{{ file?.name || file?.description }}</a>
                <button id="delete-selected-pdf-file-btn" class="btn mx-2" [disabled]="isDisabled"
                    (click)="onClickPdfFileDelete(i)">
                    <label class="mb-0"><i class="fas red fa-times cursor-pointer"></i></label>
                </button>
            </div>
        </div>
    </div>
</div>