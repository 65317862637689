<div class="wrapper">
  <app-nav [navbarTitle]="'pages.ccs-error-log.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'default'"></app-sidebar>

  <div id="content">
    <div id="search-panel" class="row mb-4">
      <div class="col-6 col-xl-4">
        <div class="title-container d-flex my-2">
          <div class="division mr-2"></div>
          <span>{{'pages.ccs-error-log.time-range' | translate}}</span>
        </div>
        <div class="input-field-group mb-2">
          <input class="form-control date-picker-input-box" id="ccs-error-log_from-date_inputbox"
            placeholder="{{'pages.ccs-error-log.from-date' | translate}}" name="createTimeFrom"
            [(ngModel)]="uiFromDate" (click)="createTimeFrom.toggle()" (ngModelChange)="onChangeDate()" 
            ngbDatepicker #createTimeFrom="ngbDatepicker" readonly 
          />
        </div>
        <div class="input-field-group mb-2">
          <input class="form-control date-picker-input-box" id="ccs-error-log_to-date_inputbox"
            placeholder="{{'pages.ccs-error-log.to-date' | translate}}" name="createTimeTo"
            [(ngModel)]="uiToDate" (click)="createTimeTo.toggle()" (ngModelChange)="onChangeDate()" 
            ngbDatepicker #createTimeTo="ngbDatepicker" readonly 
          />
        </div>
      </div>
      <div class="col-6 col-xl-4">
        <div class="title-container d-flex my-2">
          <div class="division mr-2"></div>
          <span>{{'pages.ccs-error-log.sn' | translate}}</span>
        </div>
        <div class="input-field-group mb-2 ">
          <input type="text" 
          class="form-control " 
            id="ccs-error-log_sn-number_inputbox" name="snNumber"
            placeholder="{{'pages.ccs-error-log.sn-number' | translate}}"
            [(ngModel)]="snNumber"
            maxlength="50" />
        </div>
        <app-submit-button 
          class="float-right" [id]="'ccs-error_search_button'"
          [text]="'global.search'" [buttonClass]="'standard brand-blue'"
          [iconClass]="'fas fa-search space'" [isDisabled]="isSearching"
          [isLoading]="isSearching" (onClick)="onSearchBtnClicked()" 
        >
        </app-submit-button>
      </div>
      
    </div>
    
    <div *ngIf="isNoRecords" id="no-error-log">{{ "pages.ccs-error-log.no-records-found" | translate }}</div>
    <app-tablex id='error_log_table' [tablexParam]='tablexParam' *ngIf="tablexParam['content'].length > 0"></app-tablex>

  </div>
</div>