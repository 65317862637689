import { Component, Injector, OnInit } from '@angular/core';
import { Permission } from '@enum/permission';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { ContactGroupService } from '@services/contact-group.service';
import { PostPostSummaryRequest } from 'src/app/api/authorization/post-post-summary-request';
import { BasePage } from 'src/app/ui/model/base/base';
import { Session }  from 'src/app/services/session';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-officer-settings',
  templateUrl: './officer-settings.component.html',
  styleUrls: ['./officer-settings.component.scss']
})
export class OfficerSettingsComponent extends BasePage implements OnInit {

  constructor(injector: Injector,
    private contactGroupService: ContactGroupService) {
    super(injector);
  }

  pageTitle: string;

  ///------------
  selectedWorkCenter    : string; // current selected workcentre
  tablexParam: {} = {};  // isLoadingTable
  currentPageSize       = 10;
  currentPage           = 1;
  pageCount             = 1;
  totalPostCount        = 0;
  teamDict              : {} = {}; // key: post; value: array of team name
  postList              : any; // the result of requestClientList

  //
  protected componentName(): string {
    return "officers";
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.selectedWorkCenter = Session.selectedWorkCentre;
    this.pageTitle = JMLanguage.translate("pages.officer.page-title", [this.selectedWorkCenter]);
    this.checkViewPermission(Permission.wcmView);
    this.checkWorkCenterPermission(this.selectedWorkCenter);
    
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      headers: [
        {id:'post', name   : "pages.officers.table-column-post", type: TablexColumnType.Text     , horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
        {id:'name', name   : "pages.officers.table-column-name", type: TablexColumnType.Text     , horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
        {id:'code', name   : "pages.officers.table-column-code", type: TablexColumnType.Text     , horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
        {id:'role', name   : "pages.officers.table-column-role", type: TablexColumnType.MultiLine, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle} ,
        {id:'team', name   : "pages.officers.table-column-team", type: TablexColumnType.MultiLine, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle} ,
      ],
      content: []
    };

    this.requestPostList();
  }

  ngOnDestroy() {
  }

  //---------------------------------------------------------------------------
  // view functions
  private renderTable() {
    this.tablexParam['pageCount'] = Math.ceil(this.totalPostCount/this.currentPageSize);
    this.tablexParam['content'] = this.postList.map(post => {
      return [
        post.name,
        post.employeeName,
        post.code,
        post.roles.map(role => {
          return role.name;
        }),
        (post.name in this.teamDict)?this.teamDict[post.name].map(name=>{return '- ' + name}):[]
      ];
     });
  }

  //---------------------------------------------------------------------------
  // api function
  requestPostList() {
    this.tablexParam['isLoadingTable'] = true;
    let request = {} as PostPostSummaryRequest;
    request.systemName = 'CCEPJM';
    request.active = 'active';
    request.authorizations = {'workCenters': this.selectedWorkCenter};
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    
    this.apiHandler(this.authorizationService.postPostSummary(request),
      result => {
        if (result && result.payload ) {
          this.totalPostCount = result.payload.totalCount;
          this.postList = result.payload.records;

          // retrieve team 
          let teamRequest                         = new JM.JMRequestTeamsTeamSummary();
          teamRequest.workCentreList              = [this.selectedWorkCenter];
          teamRequest.parameters                  = ["_id", "name", "members"];
          teamRequest.memberList                  = this.postList.map(post => { return post.name});
          teamRequest.active                      = JMENUM.RequestActive.ACTIVE;
          teamRequest.pageSize                    = 100;
          teamRequest.includeSummary              = true;
          teamRequest.includeApprovedWorkCentre   = false;

          JM.JMConnector.sendTeamSummary(teamRequest, (error: JM.JMNetworkError, response: JM.JMResponseTeamsTeamSummary) => {
            this.tablexParam['isLoadingTable'] = false;
            this.teamDict = {};

            if (error) {
              this.handleJMError(error);
              this.renderTable();
              return;
            }
            if (!response || !response.code || response.code != 200 || !response.payload) {
              this.openErrorBar(response);
              this.renderTable();
              return;
            }

            // prepare the team dict
            for (let team of response.payload.records) {
              for(let member of team.members){
                if(member in this.teamDict){
                  this.teamDict[member].push(team.name);
                } else {
                  this.teamDict[member] = [team.name];
                }
              }
            }
            this.renderTable();
          });
        } 
        this.renderTable();
      }), error => {
        this.tablexParam['isLoadingTable'] = false;
        this.openErrorBar(null);
        console.error(error);
      };

  }

  //---------------------------------------------------------------------------
  // page function
  onPageNumberClicked = (pageNumber) => {
    this.currentPage = parseInt(pageNumber);
    this.requestPostList();
  }

  onPageSizeClicked = (pageSize) => {
    this.currentPage = 1;
    this.currentPageSize = parseInt(pageSize);
    this.requestPostList();
  }

  // TODO: reload language
  onLanguageChanged() {
    
  }

}