<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle"></app-nav>

  <app-sidebar [customItems]='sideBarItem'></app-sidebar>

  <!-- Page Content -->
  <div id="content">
    <div class="row justify-content-between no-gutters">
      <h4 class="title">
        {{"pages.approval.pending-approval-title" | translate}}
      </h4>
      <ng-select 
        bindLabel="label" 
        bindValue="value" 
        [items]="requestTypeOptions" 
        [searchable]="false"
        [clearable]="false"
        [(ngModel)]="requestType"
        [ngModelOptions]="{standalone: true}" 
        (change)="requestApprovedRequest()" 
        >
      </ng-select>
    </div>
    <app-tablex [tablexParam]='pendingRequestTablexParam'></app-tablex>
    <div class="row justify-content-start no-gutters">
      <h4 class="title mt-1">{{"pages.approval.approved-title" | translate}}</h4>
    </div>
    <app-tablex [tablexParam]='approvedRequestTablexParam'></app-tablex>
  </div>
</div>