import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import {  TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/entity/enum/tablexColumnType';
import { formatDate, ngbDateToString, toNgbDate } from 'src/lib/presenter/Formatter';
import { AppDelegate } from 'src/app/AppDelegate';
import { ModalBootstrapService } from '@services/modal-bootstrap.service';
import { PopUpWithRadioComponent, PopUpWithRadioComponentRadiosI } from '../../components/pop-up-with-radio/pop-up-with-radio.component';

@Component({
    selector: 'app-pm-period-equipment-list',
    templateUrl: './pm-period-equipment-list.component.html',
    styleUrls: ['./pm-period-equipment-list.component.scss']
})
export class PmPeriodEquipmentListComponent extends BasePage implements OnInit {
    // page
    selectedLanguage: string;
    pmPlanNumber = this.route.snapshot.paramMap.get('pmNumber');
    pmPeriodId = this.route.snapshot.paramMap.get('periodId');
    isJmPmPlan: boolean = true;
    breadcrumbs: any = [];
    plan: JMOBJ.PmPlan = new JMOBJ.PmPlan;
    period: any;
    periodVersion: number;
    equipmentCount:number = 0;
    // searchInputText: string;
    periodDateString : string;
    isSubmitting: boolean = false;

    // Table
    tablexFilter: any = {};
    tablexParam;
    pageSizeOptions: any = [10, 25, 100];
    selectedColId: any = [];
    allColHeaders: any = [];
    selectedEquipment = [];
    // selectedCol: any = [];
    equipmentPageNumber: number = 1;
    equipmentPageSize: number = 10;
    equipmentPageCount = 1;

    equipmentList:any[] = [];

    tabOptions = {
      assigned: JMLanguage.translate('pages.pm-job-equipment-list.assigned'),
      unassigned: JMLanguage.translate('pages.pm-job-equipment-list.unassigned')
    };
    activeTab;
    numberOfRowsSelected: number = 0;
    submitBtnName: string;

    modalRadioService : ModalBootstrapService;

    constructor(injector: Injector, modalService : ModalBootstrapService) {
        super(injector);
        this.modalRadioService = modalService
      }

    async ngOnInit() {
        this.breadcrumbs = [
            { id: 'breadcrumbs-pm-plan', name: JMLanguage.translate('pages.pm-period-equipment-list.pm-plan'), route: '/pm/plan-list', currentPage: false },
            { id: 'breadcrumbs-pm-plan-number', name: this.pmPlanNumber, route: '/pm/standard-plan/view/' + this.pmPlanNumber, currentPage: false },
            { id: 'breadcrumbs-pm-plan-equipment', name: JMLanguage.translate('pages.pm-period-equipment-list.equipment'), route: null, currentPage: true }
          ];
        this.setActiveTab(this.tabOptions.assigned);
        this.initTableHeader();
        this.initSelectedCol();
        this.initTablexParam();
        await this.requestPmPlan();
        this.requestPmPeriodSummary();
    }

    // modal handler
    modalRadioOpen(callback : Function) {

      let modalRadioOptions: PopUpWithRadioComponentRadiosI[] = [
        {
          description: JMLanguage.translate('pages.pm-plan-equipment-list.modal.radio.description.1',[this.periodDateString]),
          value: false,
        },
        {
          description: JMLanguage.translate('pages.pm-plan-equipment-list.modal.radio.description.2'),
          value: true,
        },
      ];

      this.modalRadioService.openRadioPopup(
        JMLanguage.translate('pages.pm-plan-equipment-list.modal.title'),
        JMLanguage.translate('pages.pm-plan-equipment-list.modal.description'),
        JMLanguage.translate('pages.pm-plan-equipment-list.modal.close'),
        JMLanguage.translate('pages.pm-plan-equipment-list.modal.dismiss'),
        modalRadioOptions,
        (el: PopUpWithRadioComponentRadiosI) => {
          callback(el.value);
        },
        null
      ); 
    }

    generatePeriodDateString() {
      if (
        this.period &&
        this.period.periodStartDate &&
        this.period.periodEndDate
      ) {
        this.periodDateString = `${formatDate(this.period.periodStartDate, 'YYYY/MM/DD')} - ${formatDate(this.period.periodEndDate, 'YYYY/MM/DD')}`;
      }
    }


    // ----------- API ----------- //
    private async requestPmPlan() {
        if(!this.pmPlanNumber) return;

        const request: JM.JMRequestGetPmPlan = new JM.JMRequestGetPmPlan();
        request.pmPlanNumber = this.pmPlanNumber;

        const response: JM.JMResponseGetPmPlan = await AppDelegate.sendJMRequest(request);
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        
          this.plan = response.payload;
          this.isJmPmPlan = !!(this.plan.workCentre && this.plan.agreementNumber);
    }

    private async requestPmPeriodSummary(){
      if (!this.pmPeriodId) return;
      let request: JM.JMRequestGetPmPeriod = new JM.JMRequestGetPmPeriod();
      request.periodId = this.pmPeriodId;

      const response: JM.JMResponseGetPmPeriod = await AppDelegate.sendJMRequest(request);
      if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
      }

      this.period = response.payload;

      this.generatePeriodDateString();
      this.requestEquipmentSummary();
    }

    async requestEquipmentSummary(){
        this.selectRow(null);
        this.tablexParam['isLoadingTable'] = true;

        let request;
        switch (this.activeTab) {
          case this.tabOptions.assigned:
            request = new JM.JMRequestGetPmPeriodAssignedEquipmentList();
            break;
          case this.tabOptions.unassigned:
            request = new JM.JMRequestGetPmPeriodUnassignedEquipmentList();
            break;
        }

        // this.prepareRequestFilter(request);
        request.sortBy = "equipmentNumber";
        request.sortOrder = JMENUM.SortOrder.ASC;
        request.pageNumber  = this.equipmentPageNumber;
        request.pageSize = this.equipmentPageSize;
        request.workCentre = this.plan.workCentre;
        request.slaNumber = this.plan.agreementNumber;
        request.parameters = [
          "_id", 
          "equipmentNumber", 
          "description", 
          "location", 
          "locationDescription", 
          "clientShortName", 
          "locationDescription", 
          "equipmentType", 
          "slaItemList"
        ];
        request.periodId = this.pmPeriodId;

        // if (this.searchInputText && this.searchInputText != ''){
        //     request.equipmentNumber = this.splitSearchInput();
        // }
        
        this.equipmentList = [];

        const response : JM.JMResponseGetPmPeriodAssignedEquipmentList | JM.JMResponseGetPmPeriodUnassignedEquipmentList= await AppDelegate.sendJMRequest(request);
        if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
        }
        this.tablexParam['isLoadingTable'] = false;
    
        this.equipmentList = response.payload.records; // BEWARE: equipment.status <- peirod status
        this.equipmentCount = response.payload.totalCount;
        this.equipmentPageCount = Math.ceil(response.payload.totalCount / this.equipmentPageSize);
        this.tablexParam['pageCount'] = this.equipmentPageCount;
        
        this.periodVersion = response.payload.periodVersion;
        

        // this.checkEnablePageButton(response.payload.totalCount);
        this.renderTable();
        
    }

    private addEquipmentToPmPeriod = async (isApplyToConsequentPeriods : boolean) => {
        if(!this.pmPeriodId) return;
        this.isSubmitting = true;
    
        let request = new JM.JMRequestAddEquipmentToPmPeriod();
        request.pmPeriodId = this.pmPeriodId;
        request.equipmentNumbers = this.getSelectedEquipmentList();
        request.isApplyToConsequentPeriods = isApplyToConsequentPeriods;
        request.version = this.periodVersion;

        const response = await AppDelegate.sendJMRequest(request);
        if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
        }

        this.isSubmitting = false;

        this.requestPmPeriodSummary();
        return;
    }

    private removeEquipmentFromPmPeriod = async (isApplyToConsequentPeriods : boolean) => {
        if(!this.pmPeriodId) return;
        this.isSubmitting = true;
    
        let request = new JM.JMRequestRemoveEquipmentFromPmPeriod();
        request.pmPeriodId = this.pmPeriodId;
        request.equipmentNumbers = this.getSelectedEquipmentList();
        request.isApplyToConsequentPeriods = isApplyToConsequentPeriods;
        request.version = this.periodVersion;

        const response = await AppDelegate.sendJMRequest(request);
        if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
        }
        this.isSubmitting = false;

        this.requestPmPeriodSummary();
        return;
    }

    renderTable(){
        this.tablexParam['headers'] = this.allColHeaders.filter(col => {
          if(this.activeTab === this.tabOptions.unassigned && col.id === 'status'){
            return false;
          }
          return this.selectedColId.includes(col.id);
        });
        
        this.tablexParam['content'] = this.equipmentList.map((data, index) => {          
          let originRow = {
            equipmentId          : data.equipmentNumber,
            description          : data.description,
            location             : data.location,
            locationDescription  : data.locationDescription ? data.locationDescription[JMLanguage.getCurrentLanguage()] : "",
            clientShortName      : data.clientShortName,
            equipmentType        : data.equipmentType,
            slaType              : Array.isArray(data.slaType) ? data.slaType.join() : '',
            status               : data.status ? JMLanguage.translate('pm-period.status.'+data.status) : null,
            workCentre           : this.plan.workCentre,
            // assigned             : this.period.equipmentList && this.period.equipmentList.some( equipment => equipment.equipmentNumber == data.equipmentNumber) ? JMLanguage.translate('global.yes') : JMLanguage.translate('global.no')
            // createdDate          : data['createdAt'] ? moment(data['createdAt']).format(Constants.DATE_FORMAT) : null,
            // lastUpdatedDate      : data['updatedAt'] ? moment(data['updatedAt']).format(Constants.DATE_FORMAT) : null,
          };
          
          let row = [];
          for(let header of this.allColHeaders) {
            if(this.activeTab === this.tabOptions.unassigned && header.id === 'status') {
              continue;
            }
            if (this.selectedColId.includes(header.id)) {
              row.push(originRow[header.id]);  
            }
          }
          
          return row;
        });
        
        this.tablexParam['isLoadingTable'] = false;
    }

    initSelectedCol(){
        this.selectedColId = [
          "equipmentId",
          "description",
          "location",
          "locationDescription",
          "clientShortName",
          "equipmentType",
          "slaType",
          "status",
          "workCentre",
          "assigned"
        ];
        // let cachedUserSettings = Session.userInfo.userSettings;
        // if (cachedUserSettings && cachedUserSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS]) {
        //   Object.assign(this.selectedColId, cachedUserSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS]);
        // }
    }

    onPageNumberClicked = (pageIndex: number) => {
        this.equipmentPageNumber  = pageIndex;
        this.requestEquipmentSummary();
    }

    onPageSizeClicked = (pageSize: number) => {
        this.equipmentPageNumber  = 1;
        this.equipmentPageSize = pageSize;
        this.requestEquipmentSummary();
    }
    
    onRowClicked = (index, row) => {
      this.selectRow(index);
    }

    initTablexParam(){
        this.tablexParam = {
          isLoadingTable        : false,
          enableSetPageSize     : true,
          enablePagination      : true,
          enableColFilter       : false,
          filter                : {},
          tableRow              : "row",
          tableClass            : "ccs-equipment-list-table",
          tableWrapperClass     : "",
          pageSizeOptions       : this.pageSizeOptions,
          currentPageSize       : this.equipmentPageSize,
          currentPage           : this.equipmentPageNumber,
          pageCount             : this.equipmentPageCount,
          selectedColId         : this.selectedColId,
          fullColNameList       : this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
          onPageNumberClicked   : this.onPageNumberClicked,
          onPageSizeClicked     : this.onPageSizeClicked,
        //   onFilterChanged       : this.onFilterChanged,
        //   onColFiltered         : this.onColFiltered,
          onRowClicked          : this.onRowClicked,
        //   filterDebounceTime    : Constants.DEBOUNCE_TIME,
          highlightedRows       : [],
          headers               : this.allColHeaders.filter(col => {
                                  return this.selectedColId.includes(col.id);
                                }),
        }
    }

    initTableHeader() {
        this.allColHeaders = [
          {
            id: 'equipmentId',
            name: "pages.pm-plan-equipment-list.table-column.equipment-id",
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'description',
            name: "pages.pm-plan-equipment-list.table-column.description",
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'location',
            name: "pages.pm-plan-equipment-list.table-column.location-code", 
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'locationDescription',
            name: "pages.pm-plan-equipment-list.table-column.location-description", 
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'clientShortName',
            name: "pages.pm-plan-equipment-list.table-column.client", 
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col"
          },
          {
            id: 'equipmentType',
            name: "pages.pm-plan-equipment-list.table-column.equipment-type",    
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col",
          },
          {
            id: 'slaType',
            name: "pages.pm-plan-equipment-list.table-column.sla-type",
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col ",
          },
          {
            id: 'status',
            name: "pages.pm-plan-equipment-list.table-column.status",
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col ",
          },
          {
            id: 'workCentre',
            name: "pages.pm-plan-equipment-list.table-column.workCentre",
            // enableFilter: true,
            type: TablexColumnType.Text, 
            horizontalAlign: TablexColumnHorizontalAlign.Center, 
            verticalAlign: TablexColumnVerticalAlign.Middle,
            class: "col ",
          },
        ]
    }

    setActiveTab(tab) {
      switch (tab) {
        case this.tabOptions.assigned:
          this.submitBtnName = JMLanguage.translate('pages.pm-job-equipment-list.unassign');
          break;
        case this.tabOptions.unassigned:
          this.submitBtnName = JMLanguage.translate('pages.pm-job-equipment-list.assign');
          break;
      }
      this.activeTab = tab;
    }

    onClickSetActiveTab = (tab) => {
      this.setActiveTab(tab);
      this.equipmentPageNumber = 1;
      this.requestEquipmentSummary();
    }
    
    onSelectAll = () =>{
      let content = this.tablexParam.content;
      this.tablexParam.highlightedRows = content.map(e=>true);
      this.numberOfRowsSelected = content.length;
    }

    onUnselectAll = () =>{
      this.tablexParam.highlightedRows = [];
      this.numberOfRowsSelected = 0;
    }

    selectRow(index: number) {
      if (index == null) {
        this.tablexParam.highlightedRows = [];
      } else {
        let prevState = this.tablexParam.highlightedRows[index];
        this.tablexParam.highlightedRows[index] = !prevState;
      }
      this.numberOfRowsSelected = this.tablexParam.highlightedRows.filter(e => e).length;
    }

    onSubmitClick = () => {
      switch (this.activeTab) {
        case this.tabOptions.assigned:
          this.modalRadioOpen(this.removeEquipmentFromPmPeriod);
          break;
          case this.tabOptions.unassigned:
          this.modalRadioOpen(this.addEquipmentToPmPeriod);
          break;
      }
    }

    getSelectedEquipmentList(){
      let selectedEquipmentList = [];
      this.tablexParam.highlightedRows.forEach((el, index) => {
        if (el) {
          selectedEquipmentList.push(this.tablexParam.content[index][0])
        }
      })
      return selectedEquipmentList;
    }

    get isPeriodStatusCancelled(){
      return this.period ? this.period.status === JMENUM.PMPeriodStatus.CANCELLED : false;
    }

    get isPeriodStatusCompleted(){
      return this.period ? this.period.status === JMENUM.PMPeriodStatus.COMPLETED : false;
    }

    get enableAssignButton() {
      if (!this.isJmPmPlan || this.isPeriodStatusCancelled) return false;

      if (this.isPeriodStatusCompleted) return this.activeTab == this.tabOptions.unassigned;

      return this.activeTab;
    }
}
