import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { ngbDateToString, stringToNgbDate } from 'src/lib/presenter/Formatter';
import { InputFieldType } from './input-field-type.enum';
import { Subject } from 'rxjs';
import { debounceTime,  } from 'rxjs/operators';

@Component({
  selector: 'app-custom-fields-form',
  templateUrl: './custom-fields-form.component.html',
  styleUrls: ['./custom-fields-form.component.scss'],
  providers: [
  ]
})
export class CustomFieldsFormComponent implements OnInit, OnChanges {
  @Input() parameters = {
    showFieldsError: false,
    isEditMode: false,
    displayFieldAsRow: false,
    displayFieldAsCol: true,
    displayAllAsRow: false,
    instruction: '',
    header: {
      id: '',
      title: '',
      customClass: '',
      customInputClass: '',
      mandatory: false,
    },
    headerBtn: {},
    fieldList: [],
    fieldOptions: {},
    fieldRowClass: '',
    onChangeBtn:[],
  };
  @Input() userInput = {};

  InputFieldType = InputFieldType;
  isShowOnChangeBtn: boolean = false;
  copyFormValue: any = {};
  copyHeaderValue: string;
  dateInputList: any = {};

  // Row / Col style for form
  styleForm: string;
  styleCustomFieldRow: string;
  styleFieldTitle: string;
  styleFieldInputWrap: string;
  styleOnChangeBtn: string;

  private searchDropdown$ = new Subject<any[]>();

  constructor(injector: Injector, private dateAdapter: NgbDateAdapter<string>) {

  }

  ngOnInit() {
    this.checkParameters();
    this.initStyleClass();
    this.initSearchDropdownObserver();
  }

  ngOnChanges() {
    this.formatInputDate();
  }

  checkParameters(){
    if(!this.parameters.displayFieldAsCol 
      && !this.parameters.displayFieldAsRow 
      && !this.parameters.displayAllAsRow
    ){
      this.parameters.displayFieldAsRow = true;
    }
    if(!this.parameters.fieldRowClass){
      this.parameters.fieldRowClass = '';
    }
  }

  initStyleClass(){
    if(this.parameters.displayFieldAsCol){
      this.styleForm = 'row mx-0 col-10 px-0';
      this.styleCustomFieldRow = 'col-auto';
      this.styleFieldTitle = '';
      this.styleFieldInputWrap = '';
      this.styleOnChangeBtn = 'col-2';
    }else if(this.parameters.displayFieldAsRow){
      this.styleForm = 'col-12 col-lg-9';
      this.styleCustomFieldRow = 'row mx-0 mt-3';
      this.styleFieldTitle = 'col-4';
      this.styleFieldInputWrap = 'col-8';
      this.styleOnChangeBtn = 'col-lg-auto col-12';
    }else if(this.parameters.displayAllAsRow){
      this.styleForm = 'col-12 px-0';
      this.styleCustomFieldRow = 'row mx-0 mt-3';
      this.styleFieldTitle = 'col-12';
      this.styleFieldInputWrap = 'col-12';
      this.styleOnChangeBtn = 'col-lg-auto col-12';
    }
  }

  public copyInputData(userInput) {
    this.copyHeaderValue = userInput[this.parameters.header['id']];
    this.parameters.fieldList.forEach(field => {
      if(field.type == InputFieldType.checkbox){
        this.copyFormValue[field.id] = Object.assign({}, userInput[field.id]);
      }else{
        this.copyFormValue[field.id] = userInput[field.id];
      }
    });
  }

  public discardForm() {
    /**
     * discardForm() need to work with copyInputData(), 
     * use copyInputData() on first init & after data updated/created
     **/
    this.userInput[this.parameters.header['id']] = this.copyHeaderValue;
    this.parameters.fieldList.forEach(field => {
      if(field.type == InputFieldType.checkbox){
        this.userInput[field.id] = Object.assign({}, this.copyFormValue[field.id]);
      }else{
        this.userInput[field.id] = this.copyFormValue[field.id];
      }
    });
  }

  public clearField(fieldId){
    let field = this.parameters.fieldList.find(field => field.id == fieldId);
    switch (field.type){
      case InputFieldType.date: 
        this.dateInputList[fieldId] = undefined;
        this.onChangeDate(fieldId);
        break;
      default :
        this.userInput[fieldId] = null;
        break;
    }
  }

  onChangeFields(){
    this.isShowOnChangeBtn = true;
    this.parameters['showFieldsError'] = false;
  }

  onChangeCheckBox(fieldIndex, value, optionIndex){
    if(this.userInput[fieldIndex].length <= 0){
      this.userInput[fieldIndex] = [];
      this.userInput[fieldIndex][optionIndex] = value;
    }else{
      this.userInput[fieldIndex][optionIndex] = value;
    }
  }

  onChangeDate(fieldId) {
    let dateString = ngbDateToString(this.dateInputList[fieldId]);
    if(dateString){
      this.userInput[fieldId] = moment(dateString).format(Constants.DATE_FORMAT);
    }else{
      this.userInput[fieldId] = undefined;
    }
  }

  public formatInputDate(){
    this.parameters.fieldList.forEach(field => {
      if(field.type == InputFieldType.date){
        if(this.userInput[field.id]){
          // this.dateInputList[field.id] = stringToNgbDate(moment(moment(this.userInput[field.id]).format(Constants.API_DATE_FORMAT)).format(Constants.DATE_FORMAT));
          this.dateInputList[field.id] = stringToNgbDate(moment(this.userInput[field.id]).format(Constants.DATE_FORMAT));
        }else{
          this.dateInputList[field.id] = null;
        }
      }
    });
  }
  
  public setMinDateByField(targetFieldId, minDateId){
    this.parameters.fieldList.find(field => field.id == targetFieldId)['minDate'] = this.dateInputList[minDateId];
  }

  public setMaxDateByField(targetFieldId, maxDateId){
    this.parameters.fieldList.find(field => field.id == targetFieldId)['maxDate'] = this.dateInputList[maxDateId];
  }


  //===========================
  // dropdown search
  onDropdownSearch(searchObj: any, fieldListIndex: number) {
    if (this.parameters.fieldList && this.parameters.fieldList[fieldListIndex]) {
      const data = [searchObj, fieldListIndex];
      this.searchDropdown$.next(data);
    }
  }

  private initSearchDropdownObserver() {
    this.searchDropdown$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME)
    ).subscribe((data) => {
      this.searchDropdownDebounce(data);
    });
  }

  private searchDropdownDebounce(args: any[]) {
    const searchObj = args[0];
    const index = args[1];
    
    if (this.parameters.fieldList[index].onSearch) {
      this.parameters.fieldList[index].onSearch(searchObj);
    }
  }

}
