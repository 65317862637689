import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JmUserSettings } from '@enum/jm-user-settings.enum';
import { TablexColumnFilterOption, TablexColumnFilterType, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { EquipmentTagService } from '@services/equipment-tag.service';
import { Session } from '@services/session';
import { base64ToBlob } from '@services/utility';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { AppDelegate } from 'src/app/AppDelegate';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { EditEquipmentFormComponent } from 'src/app/ui/components/edit-equipment-form/edit-equipment-form.component';
import { SidebarItemInterface } from 'src/app/ui/components/sidebar/sidebar-items';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { InputFieldType } from 'src/lib/ui-component/custom-fields-form/input-field-type.enum';
import { BatchEditHAEquipmentFormComponent, BatchEditHaEquipmentFormComponentModes } from '../batch-edit-ha-equipment-form/batch-edit-ha-equipment-form';

@Component({
  selector: 'app-ha-equipment-list',
  templateUrl: './ha-equipment-list.component.html',
  styleUrls: ['./ha-equipment-list.component.scss']
})
export class HaEquipmentListComponent extends BasePage implements OnInit {
  @ViewChild('edit_ha_equipment_panel', { static: true }) editHaEquipmentPanel: CustomSliderPanelComponent;
  @ViewChild('edit_equipment_form', { static: true }) editEquipmentForm: EditEquipmentFormComponent;
  @ViewChild('batch_edit_tags_panel', { static: true }) batchEditTagsPanel: CustomSliderPanelComponent;
  @ViewChild('batch_edit_tags_form', { static: true }) batchEditTagsForm: BatchEditHAEquipmentFormComponent;

  pageTitle: string;
  selectedLanguage: string;
  searchInputText: string;

  sideBarItem: SidebarItemInterface[] = [];
  
  // Permission
  hasEditPermission: boolean;

  // Button / Loading Status
  isExportExcelBtnLoading: boolean = false;
  isDisabledExportExcelBtn: boolean = false;
  isLoadingUpdateEquipment: boolean = false;
  isDisabledBatchEditBtn: boolean = false;
  isDisabledBatchAddBtn: boolean = false;
  isDisabledBatchRemoveBtn: boolean = false;

  isNoTagSearch:boolean = false;
  isNoCCSEquipmentSearch:boolean = false;
  // Table
  tablexFilter: any = {};
  tablexParam: {} = {};
  pageSizeOptions: any = [100, 200, 500];

  // Table filter dropdown options
  ztFilterOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  statusFilterOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  activeFilterOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  // Table column filter
  selectedRows = [];
  uiSelectedCount: number = 0;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];

  // HSDEquipment
  // selectedEquipment: any = {};
  hsdEquipmentRecords: JMOBJ.HSDEquipment[] = [];
  hsdEquipmentPageNumber: number = 1;
  hsdEquipmentPageSize: number = 100;
  hsdEquipmentPageCount = 1;
  selectedHAEnrollmentOption: any;
  showCCSEquipment: boolean = true;
  locationDict = {}; // key: location code , code: location description

  // Config & data of batch_edit_tags_form
  batchEditTagsFormParam: any = {};
  batchEditTagsFormInput: any = {};

  selectedWorkCentre: string;
  selectedHACluster: string;
  selectedWorkCentreInfo: any;
  selectedWorkCentreCode: string;

  // Equipment Tags
  allTagObjectList: any = [];
  currentWorkCentreTagOptionList: any = [];

  constructor(
    injector: Injector,
    private equipmentTagService: EquipmentTagService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.selectedWorkCentre = Session.selectedWorkCentre;
    this.selectedLanguage = Session.selectedLanguage;
    this.selectedWorkCentreInfo = Session.workCentreDict[Session.selectedWorkCentre];
    this.selectedHACluster = Session.workCentreDict[Session.selectedWorkCentre].HACluster;
    this.pageTitle = JMLanguage.translate("pages.settings.ha-equipment-list.page-title",[this.selectedHACluster +  ' - ' + [Session.selectedWorkCentre]?this.selectedHACluster + ' - ' + [Session.selectedWorkCentre]:'']);


    // check if enable HA equipment on this work centre
    if (this.selectedWorkCentreInfo.enableHAEquipment) {
      this.initPermission();
      
      this.initBatchEditTagsFormElem();
      // this.initBatchUploadFormElem(); // TODO
      this.initAllTableHeader();
      this.initSelectedCol();
      this.initTablex();
      this.initSideBar();
      this.onColFiltered(this.selectedColId);
      
      this.requestHSDEquipmentSummary();
    } else {
      AppDelegate.openSnackBar(this.translate("pages.settings.ha-equipment-list.work-centre-no-ha-equipment"));
      AppDelegate.navigate(['']);
    }
  }
  
  private initSideBar() {
    this.sideBarItem = [
      {
        title: "side-bar.equipment",
        subItems:[
          {
            title: "side-bar.equipment-tag",
            path: '/setting/ccs-equipment-tag-list',
          }, {
            title: "side-bar.equipment-list",
            path: '/setting/equipment-list',
          }
        ]
      }
    ];
    let enableHAEquip = Session.workCentreDict[Session.selectedWorkCentre].enableHAEquipment;
    if (enableHAEquip && JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.HSD_EQUIPMENT_VIEW)) {
      this.sideBarItem[0].subItems = this.sideBarItem[0].subItems.concat([
        {
          id: 'equipment-list',
          title: "side-bar.ha-equipment-tag-list",
          path: '/setting/ha-equipment-tag-list'
        }, {
          title: "side-bar.ha-equipment-list",
          path: '/setting/ha-equipment-list',
        }, 
      ]);
    }

  } 

  ngAfterViewInit() {
    this.editEquipmentForm.onSave = this.onSaveEquipment;
  }

  onLanguageChanged() {
  }

  onLangChange() {
  }

  // ----------- API ----------- //
  async requestWorkCentreHasHAEquipment(){
    const request: JM.JMRequestWorkCentresWorkCentreSummary = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.selectedWorkCentre];

    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.totalCount <= 0) {
      this.isDisabledExportExcelBtn = true;
      this.isDisabledBatchEditBtn = true;
      return;
    } else {
      this.isDisabledExportExcelBtn = false;
      this.isDisabledBatchEditBtn = false;
      if (!response.payload.records[0].enableHAEquipment) {
        this.openSnackBar(this.translate("pages.settings.ha-equipment-list.work-centre-no-ha-equipment"));
        this.router.navigate(['']);
      } else {
        this.selectedWorkCentreInfo = response.payload.records[0];
      }
    }
  }

  async requestHSDEquipmentSummary() {
    const request: JM.JMRequestEquipmentsHSDEquipmentSummary = new JM.JMRequestEquipmentsHSDEquipmentSummary();
    request.hasHAEquipment = true;
    request.HACluster = [this.selectedWorkCentreInfo.HACluster];
    request.HAEnrollment = this.selectedHAEnrollmentOption? [this.selectedHAEnrollmentOption]: undefined;

    request.pageSize = this.hsdEquipmentPageSize;
    request.pageNumber = this.hsdEquipmentPageNumber;
    request.sortBy = 'assetNumber';

    request.filter = this.renderRequestFilter();

    if(this.searchInputText && this.searchInputText != ''){
      request.assetNumber = this.splitSeachInput()
    }

    if(this.isNoTagSearch === true){
        request.hasHashtag = this.setHasHashTagParam();
    }

    if(this.isNoCCSEquipmentSearch === true){
        request.hasCCSEquipment = this.setHasCCSLinkedParam()
    }

    this.tablexParam['isLoadingTable'] = true;
    const response:JM.JMResponseEquipmentsHSDEquipmentSummary = await AppDelegate.sendJMRequest(request);
    
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.tablexParam['isLoadingTable'] = false;
      return;
    }

    this.hsdEquipmentRecords = response.payload.records;
    this.hsdEquipmentPageCount =  Math.ceil(response.payload.totalCount / this.hsdEquipmentPageSize);
    this.tablexParam['pageCount'] = this.hsdEquipmentPageCount;
    this.checkEnablePageButton(response.payload.totalCount);

    //get the location list of hsd equipment
    const locationList=[];
    for (let asset of response.payload.records) {
      if (asset.equipment && asset.equipment.location)
        locationList.push(asset.equipment.location) 
    }

    // call location summary to get the location description
    const locationRequest: JM.JMRequestLocationsLocationSummary = new JM.JMRequestLocationsLocationSummary();
    locationRequest.location = locationList;
    locationRequest.parameters = ['code','description'];

    const locationResponse: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(locationRequest);
    this.tablexParam['isLoadingTable'] = false;
    if (!locationResponse || !locationResponse.code || locationResponse.code != 200 || !locationResponse.payload) {
      AppDelegate.openErrorBar(locationResponse);
      return;
    }

    // prepare location dictionary
    for (let location of locationResponse.payload.records) {
        this.locationDict[location.code] = location.description;
    }
    this.renderTable();
  }

  async requestUpdateUserSettings() {
    let userSettings = {};
    const request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[JmUserSettings.HA_EQUIPMENT_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
  }

  async requestExportHAEquipmentSummary(){
    const request: JM.JMRequestEquipmentsExportHAEquipmentSummary = new JM.JMRequestEquipmentsExportHAEquipmentSummary();
    request.HACluster = [this.selectedWorkCentreInfo.HACluster];
    request.filter = this.renderRequestFilter();

    if(this.searchInputText && this.searchInputText != ''){
      request.assetNumber = this.splitSeachInput()
    }

    if(this.isNoTagSearch === true){
        request.hasHashtag = this.setHasHashTagParam();
    }

    if(this.isNoCCSEquipmentSearch === true){
        request.hasCCSEquipment = this.setHasCCSLinkedParam()
    }

    this.isExportExcelBtnLoading = true;
    const response: JM.JMResponseEquipmentsExportHAEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.isExportExcelBtnLoading = false;
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let blob = base64ToBlob(response.payload.base64, response.payload.type);
    saveAs(blob, `HA_Equipment_${moment().format("YYYY-MM-DD")}.xlsx`);
  }

  splitSeachInput() {
    if(this.searchInputText){
      return this.searchInputText.split(" ").filter(a => {return a != ""})
    } else {
      return []
    }
  }

  resetPageNumber() {this.hsdEquipmentPageNumber = 1}
  
  setHasHashTagParam(){
    return this.isNoTagSearch ? false : undefined
  }
 
  setHasCCSLinkedParam(){
    return this.isNoCCSEquipmentSearch ? false : undefined
  }

  // ----------- Button function ----------- //
  checkEnablePageButton(recordCount?) {
    if (recordCount <= 0) {
      this.hsdEquipmentRecords = [];
      this.isDisabledExportExcelBtn = true;
      this.isDisabledBatchEditBtn = true;
    } else {
      this.isDisabledExportExcelBtn = false;
      this.isDisabledBatchEditBtn = false;
    }
  }

  onClickBatchAdd() {
    this.batchEditTagsForm.mode = BatchEditHaEquipmentFormComponentModes.ADD;
    this.batchAddRemove();
  }

  onClickBatchRemove() {
    this.batchEditTagsForm.mode = BatchEditHaEquipmentFormComponentModes.REMOVE;
    this.batchAddRemove();
  }

  batchAddRemove() {
    this.batchEditTagsForm.equipmentList = this.hsdEquipmentRecords;
    this.batchEditTagsForm.equipmentFilter = this.renderRequestFilter();
    this.batchEditTagsForm.hasHashtagParam = this.setHasHashTagParam();
    this.batchEditTagsForm.hasCCSEquipmentParam = this.setHasCCSLinkedParam();
    this.batchEditTagsForm.assetNumberSearchList = this.splitSeachInput();
    this.batchEditTagsForm.onSave = () => {
      this.requestHSDEquipmentSummary();
      this.batchEditTagsPanel.toggle();
    }
    this.batchEditTagsForm.reset();
    this.batchEditTagsForm.initBatchEditTagsFormElem();
    this.batchEditTagsPanel.toggle();
  }

  onSearchKeyup() {
    this.resetPageNumber();
    this.requestHSDEquipmentSummary();
  }

  onSearchClicked() {
    this.resetPageNumber();
    this.requestHSDEquipmentSummary();
  }

  onClearSearchClicked() {
    this.searchInputText = ""
    this.resetPageNumber();
    this.requestHSDEquipmentSummary();
  }

  onNoTagSearchClicked(){
    this.resetPageNumber();
    this.requestHSDEquipmentSummary();
  }

  onNoCCSEquipmentSearchClicked(){
    this.resetPageNumber();
    this.requestHSDEquipmentSummary();
  }

  // ----------- UI function ----------- //
  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }
    this.resetPageNumber();
    this.requestHSDEquipmentSummary();
  }

  onHAEnrollmentOptionFilterChanged = (event, i, header) => {
    
    if (header.filterDropdownOption.selectedValue) {
      this.selectedHAEnrollmentOption = header.filterDropdownOption.selectedValue;
    } else {
      this.selectedHAEnrollmentOption = null
    }
    this.requestHSDEquipmentSummary();
  }


  onCloseEditEquipment(){
    this.resetSelectRow();
  }

  onClickExportExcel(){
    this.requestExportHAEquipmentSummary();
  }

  onChangeBatchEquipmentTags = () => {
    if(this.batchEditTagsFormInput.equipmentTag && this.batchEditTagsFormInput.equipmentTag.length <= 0){
      delete this.batchEditTagsFormInput.equipmentTag
    }
  }

  onChangeBatchEditRange = () => {
    
  }

  sortEquipmentTag(equipmentTag){
    let sortedTag = [];
    sortedTag = equipmentTag.sort((a, b) => {
      if(a.equipmentType && b.equipmentType){
        return a.equipmentType.localeCompare(b.equipmentType) || a.equipmentName.localeCompare(b.equipmentName);
      }else{
        return a.equipmentName.localeCompare(b.equipmentName);
      }
    });
    return sortedTag;
  }

  validateBatchEditForm() {
    if(this.batchEditTagsFormInput.equipmentTag && this.batchEditTagsFormInput.range){
      this.batchEditTagsFormParam.showFieldsError = false;
      return true;
    }else{
      this.batchEditTagsFormParam.showFieldsError = true;
      return false;
    }
  }


  resetBatchEditEquipmentForm(){
    this.batchEditTagsFormParam.showFieldsError = false;
    this.batchEditTagsFormInput = {
    };
  }

  renderRequestFilter() {
    let filter = {};

    for (let key in this.tablexFilter) {
      if (this.tablexFilter[key]) {
        switch (key) {
          case 'assetNumber':
            filter['assetNumber'] = this.tablexFilter[key];
            break;
          case 'assetDescription':
            filter['HAEquipment.assetDescription'] = this.tablexFilter[key];
            break;
          case 'ccsEquipmentDescription':
            filter['equipment.description'] = this.tablexFilter[key];
            break;
          case 'ccsObjectType':
            filter['equipment.objectType'] = this.tablexFilter[key];
            break;
          case 'ccsClient':
            filter['equipment.clientShortName'] = this.tablexFilter[key];
            break;
          case 'ccsEquipmentType':
            filter['equipment.equipmentType'] = this.tablexFilter[key];
            break;
          case 'ccsLocationDesc':
            filter['equipment.location'] = this.tablexFilter[key];
            break;
          case 'technicalId':
            filter['equipment.technicalId'] = this.tablexFilter[key];
            break;
          case 'ccsPlannerGroup':
            filter['equipment.plannerGroup'] = this.tablexFilter[key];
            break;
          case 'ccsWorkCentre':
            filter['equipment.workCentre'] = this.tablexFilter[key];
            break;
          case 'ccsNumber':
            filter['equipment.equipmentNumber'] = this.tablexFilter[key];
            break;
          case 'ccsFunctionalLocation':
            filter['equipment.functionalLocation'] = this.tablexFilter[key];
            break;
          case 'hospitalCode':
            filter['HAEquipment.hospitalCode'] = this.tablexFilter[key];
            break;
          case 'haBrand':
            filter['HAEquipment.manufacturer'] = this.tablexFilter[key];
            break;
          case 'haModel':
            filter['HAEquipment.model'] = this.tablexFilter[key];
            break;
          case 'haLocationCode':
            filter['HAEquipment.locationCode'] = this.tablexFilter[key];
            break;
          case 'haContractNumber':
            filter['HAEquipment.HAContractNumber'] = this.tablexFilter[key];
            break;
          case 'haSerialNumber':
            filter['HAEquipment.serialNumber'] = this.tablexFilter[key];
            break;
          case 'lastUpdatedBy':
            filter['updatedBy'] = this.tablexFilter[key];
            break;
          case 'active':
            filter['HAEquipment.HAEnrollment'] = this.tablexFilter[key];
            break;
          case 'equipmentTag':
            filter['hashtags.description.' + Session.selectedLanguage] = this.tablexFilter[key];
            break;

          // default : 
          //   filter[key] = this.tablexFilter[key];
          //   break;
        }
      }
    }
    return filter;
  }

  // ----------- Tablex UI function ----------- //
  onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();
    if (!Session.userInfo['userSettings'] || (Session.userInfo['userSettings'] && Session.userInfo.userSettings[JmUserSettings.HA_EQUIPMENT_LIST_COLUMNS] != this.selectedColId)) {
      this.requestUpdateUserSettings();
      let userSettings = {};
      userSettings[JmUserSettings.HA_EQUIPMENT_LIST_COLUMNS] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  }
  
  onFilterChanged = (event, index, header, filter) => {
    this.resetSelectRow();
    this.tablexFilter = filter;
    for (let key in this.tablexFilter) {
      if(!this.tablexFilter[key]){
        delete this.tablexFilter[key];
      }
    }
    
    this.resetPageNumber();
    this.requestHSDEquipmentSummary();
    // this.requestEquipmentSummaryForOptions();
  }

  onRowClicked = (index, row) => {
    // if(this.tablexParam['highlightedRows'][index]){
    //   this.resetSelectRow();
    // }else{
      // this.tablexParam['highlightedRows'] = []; //only selected row is highlighted
      // this.tablexParam['highlightedRows'][index] = true;
      this.selectedRows = row;
      // let hsdEquip = this.hsdEquipmentRecords.find(record => record.objId == row[0]);
      let hsdEquip = this.hsdEquipmentRecords[index];
      
      // this.renderEditEquipmentForm(record);
      this.editEquipmentForm.setHsdEquipment(hsdEquip);
      this.editHaEquipmentPanel.toggle();
    // }
  }

  resetSelectRow() {
    this.tablexParam['selectedRowIndex'] = undefined;
    // this.tablexParam['highlightedRows'] = [];
    this.selectedRows = [];
  }
  
  onPageSizeClicked = (pageSize: number) => {
    this.resetPageNumber();
    this.hsdEquipmentPageSize = pageSize;
    this.requestHSDEquipmentSummary();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.hsdEquipmentPageNumber  = pageIndex;
    this.requestHSDEquipmentSummary();
  }

  onSaveEquipment = (equipment:JMOBJ.Equipment) => {
    this.resetSelectRow();
    this.requestHSDEquipmentSummary();
    this.editHaEquipmentPanel.toggle();
  }

  // ----------- Init Component ----------- //
  initPermission(){
    this.checkViewPermission(JMENUM.Permission.HSD_EQUIPMENT_VIEW);
    this.hasEditPermission = this.authorizationService.hasPermission(JMENUM.Permission.HSD_EQUIPMENT_UPDATE);
    // this.requestWorkCentreHasHAEquipment();
  }

  initSelectedCol(){
    this.selectedColId = [
      "objId",
      "assetNumber",
      "assetDescription",
      "ccsEquipmentDescription",
      "ccsEquipmentType",
      "ccsClient",
      "ccsNumber",
      "ccsFunctionalLocation",
      "equipmentTag"
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[JmUserSettings.HA_EQUIPMENT_LIST_COLUMNS]) {
      Object.assign(this.selectedColId, cachedUserSettings[JmUserSettings.HA_EQUIPMENT_LIST_COLUMNS]);
    }
  }

  initBatchEditTagsFormElem(){
    this.batchEditTagsFormParam.isEditMode = true;
    this.batchEditTagsFormParam.showFieldsError = false;
    this.batchEditTagsFormParam.fieldOptions = {};
    this.batchEditTagsFormParam.displayAllAsRow = true;

    this.batchEditTagsFormParam.fieldOptions = {
      range: [
        { id: 'all-in-this-page', key: 'all-in-this-page', value: 'all-in-this-page', label: JMLanguage.translate('pages.ha-equipment.all-in-this-page') },
        { id: 'filtered', key: 'filtered', value: 'filtered', label: JMLanguage.translate('pages.ha-equipment.filtered') },
        { id: 'all-equipment', key: 'all-equipment', value: 'all-equipment', label: JMLanguage.translate('pages.ha-equipment.all-equipment') }
      ]
    };

    this.batchEditTagsFormParam.fieldList = [
      {
        id: 'equipmentTag',
        name: JMLanguage.translate("pages.ha-equipment-list.table-column.equipment-tag"),
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        template: true,
        onChange: this.onChangeBatchEquipmentTags, 
        onSearch: () => {},
        scrollToEnd: () => {},
      },
      {
        id: 'range',
        name: '',
        type: InputFieldType.radio, 
        placeholder: '',
        mandatory: true,
        customClass: 'd-block mr-0 mb-2',
        onChange: this.onChangeBatchEditRange
      }
    ];
  }


  // ------ Tablex ------ //
  renderTable(){
    this.tablexParam['headers'] = this.allColHeaders.filter(col => {
      return this.selectedColId.includes(col.id);
    });
    
    // for usable tag checking
    let usableTagGroupMap = new Map<string, boolean>(); // key: tag group id, value: identify usable tag
    for (const hsdEquipment of this.hsdEquipmentRecords) {
      if (!hsdEquipment.hashtags) {
        continue;
      }
      for (const tag of hsdEquipment.hashtags) {
        if (tag.tagGroup && !usableTagGroupMap.has(tag.tagGroup._id)) {
          const isUsableTag = this.equipmentTagService.isUsableTag(tag, this.selectedWorkCentre);
          usableTagGroupMap.set(tag.tagGroup._id, isUsableTag);
        }
      }
    }

    this.tablexParam['content'] = this.hsdEquipmentRecords.map(data => {
      let haEquipment = data.HAEquipment ? data.HAEquipment : {};

      // prepare tag
      let tagLabelList = [];
      if (data.hashtags) {
        for (const tag of data.hashtags) {
          const isUsableTag = tag.tagGroup ? usableTagGroupMap.get(tag.tagGroup._id) : false;
          const tagDescription = this.equipmentTagService.getTagDescription(tag, false);
          const tagLabel = {
            id: tag._id,
            workCentre: this.selectedWorkCentre,
            name: tagDescription,
            class: isUsableTag ? 'tag-blue' : 'tag-red'
          };
          tagLabelList.push(tagLabel);
        }
      }

      let obj = {
        objId: data['_id'],
        // *******HA attributes*********
        assetNumber: data['assetNumber'],
        assetDescription: haEquipment['assetDescription'],
        haBrand: haEquipment['manufacturer'],
        haModel: haEquipment['model'],
        haLocationCode: haEquipment['locationCode'],
        maintenanceDate: data['warrantyExpiryDate'] ? moment(data['warrantyExpiryDate']).format(Constants.DATE_FORMAT) : null,
        acceptanceDate: data['acceptanceDate'] ? moment(data['acceptanceDate']).format(Constants.DATE_FORMAT) : null,
        maintStatus: JMENUM.HaEquipmentStatus[data['maintStatus']],
        haSerialNumber: haEquipment['serialNumber'],
        active: JMLanguage.translate('pages.ha-equipment-list.active.' + haEquipment['HAEnrollment']),
        hospitalLocation: data["hospitalLocation"],
        haContractNumber: haEquipment['HAContractNumber'],
        //HAContractNumber: haEquipment['HAContractNumber'],
        // *******CCS attributes*********
        ccsNumber: data['equipment'] && data['equipment']['equipmentNumber'] ? data['equipment']['equipmentNumber'] : null,
        ccsEquipmentDescription: data['equipment'] && data['equipment']['description'] ? data['equipment']['description'] : null,
        ccsObjectType: data['equipment'] &&  data['equipment']['objectType']  ? data['equipment']['objectType'] : null,
        ccsClient: data['equipment'] && data['equipment']['clientShortName'] ? data['equipment']['clientShortName'] : null, 
        ccsFunctionalLocation: data['equipment'] && data['equipment']['functionalLocation'] ? data['equipment']['functionalLocation'] : null,
        ccsWorkCentre: data['equipment'] && data['equipment']['workCentre'] ? data['equipment']['workCentre'] : null,
        ccsEquipmentType: data['equipment']&& data['equipment']['equipmentType'] ? data['equipment']['equipmentType'] : null,
        ccsLocationDesc: data['equipment'] && data['equipment']['location'] && this.locationDict[data['equipment']['location']] ? this.locationDict[data['equipment']['location']][Session.selectedLanguage] : null,
        technicalId: data['equipment'] && data['equipment']['technicalId'] ? data['equipment']['technicalId'] : null,
        vendorWarrantyStart:  data['equipment'] && data['equipment']['vendorWarrantyStart'] ? moment(data['createdAt']).format(Constants.DATE_FORMAT) : null,
        ccsPlannerGroup: data['equipment'] && data['equipment']['plannerGroup'] ? data['equipment']['plannerGroup'] : null,
        // *******JM attributes*********
        createdDate: data['createdAt'] ? moment(data['createdAt']).format(Constants.DATE_FORMAT) : null,
        lastUpdatedDate: data['updatedAt'] ? moment(data['updatedAt']).format(Constants.DATE_FORMAT) : null,
        lastUpdatedBy: data['updatedBy'],
        equipmentTag: {
          label: tagLabelList,
          btnClass: 'd-none',
        }
      };
      if (haEquipment) {
        obj['hospitalCode'] = haEquipment['hospitalCode'];
        //obj['HACluster'] = haEquipment['HACluster'] ? haEquipment['HACluster'] : data['HACluster'];
        //obj['haContractNumber'] = haEquipment['HAContractNumber'];
        if (!obj['maintenanceDate'] && haEquipment['warrantyExpiryDate']) {
          obj['maintenanceDate'] = moment(haEquipment['warrantyExpiryDate']).format(Constants.DATE_FORMAT);
        }
        obj['contractStartDate'] = haEquipment['contractStartDate'] ? moment(haEquipment['contractStartDate']).format(Constants.DATE_FORMAT) : null;
        obj['contractEndDate'] = haEquipment['contractStartDate'] ? moment(haEquipment['contractStartDate']).format(Constants.DATE_FORMAT) : null;
      }

      let row = [];
      for(let header of this.allColHeaders) {
        if (this.selectedColId.includes(header.id)) {
          row.push(obj[header.id]);
        }
      }

      return row;
    });
    this.tablexParam['isLoadingTable'] = false;
  }

  initTablex(){
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      enableColFilter       : true,
      filter                : {},
      tableRow              : "row",
      tableClass            : "ha-equipment-list-table",
      tableWrapperClass     : "",
      pageSizeOptions       : this.pageSizeOptions,
      currentPageSize       : this.hsdEquipmentPageSize,
      currentPage           : this.hsdEquipmentPageNumber,
      pageCount             : this.hsdEquipmentPageCount,
      selectedRowCount      : 0,
      totalRowCount         : 0,
      selectedColId         : this.selectedColId,
      fullColNameList       : this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      onRowClicked          : this.onRowClicked,
      onFilterChanged       : this.onFilterChanged,
      onColFiltered         : this.onColFiltered,
      filterDebounceTime    : Constants.DEBOUNCE_TIME,
      highlightedRows       : [],
      headers               : this.allColHeaders,
    }
  }

  initAllTableHeader() {
    // this.ztFilterOptions.items = this.editEquipmentFormParam.fieldOptions.zt;
    this.ztFilterOptions.items = [ 
      { id: 'zt-yes', key: 'zt-yes', value: 'Y', label: JMLanguage.translate('global.yes') },
      { id: 'zt-no', key: 'zt-no', value: 'N', label: JMLanguage.translate('global.no') },
    ];
    this.ztFilterOptions.bindLabel = "label";
    this.ztFilterOptions.bindValue = "value";
    this.ztFilterOptions.change = this.onOptionFilterChanged;

    // this.statusFilterOptions.items = this.editEquipmentFormParam.fieldOptions.maintStatus;
    this.statusFilterOptions.items = [
      { id: JMENUM.HaEquipmentStatus.PENDING, key: JMENUM.HaEquipmentStatus.PENDING, value: JMENUM.HaEquipmentStatus.PENDING, label: JMLanguage.translate('pages.ha-equipment-list.pending') },
      { id: JMENUM.HaEquipmentStatus.IN_CONTRACT, key: JMENUM.HaEquipmentStatus.IN_CONTRACT, value: JMENUM.HaEquipmentStatus.IN_CONTRACT, label: JMLanguage.translate('pages.ha-equipment-list.in-contract') },
      { id: JMENUM.HaEquipmentStatus.ARCHIVED, key: JMENUM.HaEquipmentStatus.ARCHIVED, value: JMENUM.HaEquipmentStatus.ARCHIVED, label: JMLanguage.translate('pages.ha-equipment-list.archived') },
      { id: JMENUM.HaEquipmentStatus.DELETE, key: JMENUM.HaEquipmentStatus.DELETE, value: JMENUM.HaEquipmentStatus.DELETE, label: JMLanguage.translate('pages.ha-equipment-list.delete') },
      { id: JMENUM.HaEquipmentStatus.CONDEMN, key: JMENUM.HaEquipmentStatus.CONDEMN, value: JMENUM.HaEquipmentStatus.CONDEMN, label: JMLanguage.translate('pages.ha-equipment-list.condemn') },
      { id: JMENUM.HaEquipmentStatus.IN_WARRANTY, key: JMENUM.HaEquipmentStatus.IN_WARRANTY, value: JMENUM.HaEquipmentStatus.IN_WARRANTY, label: JMLanguage.translate('pages.ha-equipment-list.in-warranty') },
      { id: JMENUM.HaEquipmentStatus.IN_HOUSE, key: JMENUM.HaEquipmentStatus.IN_HOUSE, value: JMENUM.HaEquipmentStatus.IN_HOUSE, label: JMLanguage.translate('pages.ha-equipment-list.in-house') }
    ];
    this.statusFilterOptions.bindLabel = "label";
    this.statusFilterOptions.bindValue = "value";
    this.statusFilterOptions.change = this.onOptionFilterChanged;

    this.activeFilterOptions.items = [
      { value: JMENUM.RequestActive.ACTIVE, label: JMLanguage.translate("global.active") },
      { value: JMENUM.RequestActive.INACTIVE, label: JMLanguage.translate("global.inactive") },
    ]
    this.activeFilterOptions.bindLabel = "label";
    this.activeFilterOptions.bindValue = "value";
    this.activeFilterOptions.change = this.onHAEnrollmentOptionFilterChanged;

    this.allColHeaders = [
      {
        id: 'objId',
        name: "pages.ha-equipment-list.table-column.id",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "d-none",
      },
      {
        id: 'assetNumber',
        name: "pages.ha-equipment-list.table-column.ha-asset-number",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'assetDescription',
        name: "pages.ha-equipment-list.table-column.ha-description",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsEquipmentDescription',
        name: "pages.ha-equipment-list.table-column.ccs-description",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsObjectType',
        name: "pages.ha-equipment-list.table-column.object-type",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsClient',
        name: "pages.ha-equipment-list.table-column.client",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsEquipmentType',
        name: "pages.ha-equipment-list.table-column.equipmentType",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsLocationDesc',
        name: "pages.ha-equipment-list.table-column.locationDesc",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'technicalId',
        name: "pages.ha-equipment-list.table-column.technicalId",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'vendorWarrantyStart',
        name: "pages.ha-equipment-list.table-column.vendorWarrantyStart",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsPlannerGroup',
        name: "pages.ha-equipment-list.table-column.plannerGroup",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsWorkCentre',
        name: "pages.ha-equipment-list.table-column.work-centre",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsNumber',
        name: "pages.ha-equipment-list.table-column.ccs-number",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsFunctionalLocation',
        name: "pages.ha-equipment-list.table-column.functional-location",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'hospitalCode',
        name: "pages.ha-equipment-list.table-column.hospital-code.ha",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'haBrand',
        name: "pages.ha-equipment-list.table-column.haBrand",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'haModel',
        name: "pages.ha-equipment-list.table-column.haModel",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'haLocationCode',
        name: "pages.ha-equipment-list.table-column.haLocationCode",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'maintenanceDate',
        name: "pages.ha-equipment-list.table-column.maintenance-date",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'acceptanceDate',
        name: "pages.ha-equipment-list.table-column.acceptance-date",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'haContractNumber',
        name: "pages.ha-equipment-list.table-column.ha-contract-number",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'contractStartDate',
        name: "pages.ha-equipment-list.table-column.contract-start-date",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'contractEndDate',
        name: "pages.ha-equipment-list.table-column.contract-end-date",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'haSerialNumber',
        name: "pages.ha-equipment-list.table-column.ha-serial-number",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'createdDate',
        name: "pages.ha-equipment-list.table-column.created-date",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'lastUpdatedDate',
        name: "pages.ha-equipment-list.table-column.last-updated-date",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'lastUpdatedBy',
        name: "pages.ha-equipment-list.table-column.last-updated-by",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'active',
        name: "pages.ha-equipment-list.table-column.active",
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.activeFilterOptions,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'equipmentTag',
        name: "pages.ha-equipment-list.table-column.equipment-tag",
        enableFilter: true,
        type: TablexColumnType.BorderLabelAddButton, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
        disableButton: true,
      },
    ]
  }
  // ------ Tablex End ------ //
}