<div>
  <div id="schedule-label">
    <div *ngFor="let team of teamList; let i=index" (click)="showTeamSchedule(team._id)" class="teamname-wrap" [ngClass]="labelShow === true || teamLabel == team._id ? 'show': ''"> 
      <span>
      <svg width="23" height="23" >
        <rect width="23" height="23" [attr.fill]="colorTable[team._id]" /></svg>
      </span>
      <div class="teamname">{{team.name}}</div>
    </div>
  </div>

  <svg width="80%" height="200" viewBox="0 0 900 200" id="schedule-chart-wrap">
    <g id="schedule-chart">
      <rect x="120" y="10" width="750" height="20" fill="#fff"/>
      <g fill="#333" text-anchor="end">
        <text x="120" y="45">{{"global.monday" | translate}}</text>
        <text x="120" y="65">{{"global.tuesday" | translate}}</text>
        <text x="120" y="85">{{"global.wednesday" | translate}}</text>
        <text x="120" y="105">{{"global.thursday" | translate}}</text>
        <text x="120" y="125">{{"global.friday" | translate}}</text>
        <text x="120" y="145">{{"global.saturday" | translate}}</text>
        <text x="120" y="165">{{"global.sunday" | translate}}</text>
        <text x="120" y="185">{{"global.public-holidays" | translate}}</text>
      </g>
      <g fill="#333" text-anchor="middle">
        <text [attr.x]="130" y="25">0</text>
        <text [attr.x]="130 + 30*1" y="25">1</text>
        <text [attr.x]="130 + 30*2" y="25">2</text>
        <text [attr.x]="130 + 30*3" y="25">3</text>
        <text [attr.x]="130 + 30*4" y="25">4</text>
        <text [attr.x]="130 + 30*5" y="25">5</text>
        <text [attr.x]="130 + 30*6" y="25">6</text>
        <text [attr.x]="130 + 30*7" y="25">7</text>
        <text [attr.x]="130 + 30*8" y="25">8</text>
        <text [attr.x]="130 + 30*9" y="25">9</text>
        <text [attr.x]="130 + 30*10" y="25">10</text>
        <text [attr.x]="130 + 30*11" y="25">11</text>
        <text [attr.x]="130 + 30*12" y="25">12</text>
        <text [attr.x]="130 + 30*13" y="25">13</text>
        <text [attr.x]="130 + 30*14" y="25">14</text>
        <text [attr.x]="130 + 30*15" y="25">15</text>
        <text [attr.x]="130 + 30*16" y="25">16</text>
        <text [attr.x]="130 + 30*17" y="25">17</text>
        <text [attr.x]="130 + 30*18" y="25">18</text>
        <text [attr.x]="130 + 30*19" y="25">19</text>
        <text [attr.x]="130 + 30*20" y="25">20</text>
        <text [attr.x]="130 + 30*21" y="25">21</text>
        <text [attr.x]="130 + 30*22" y="25">22</text>
        <text [attr.x]="130 + 30*23" y="25">23</text>
        <text [attr.x]="130 + 30*24" y="25">24</text>
      </g>
      
      <rect x="0" y="30" width="0%" height="160" fill="#fff"/>
      <g class="empty-cell">
          <ng-container *ngFor="let day of [0,1,2,3,4,5,6,7]; let i=index">
            <ng-container *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,21,23]; let j=index">
                <rect [attr.x]="130 + j*30" [attr.y]="30 + i*20" width="3.3%" height="20" ></rect>
            </ng-container>
          </ng-container>
      </g>
      <g class="colored-cell">
        <ng-container *ngFor="let teamOfficeHour of officeHourList; let i=index">
          <ng-container *ngFor="let weekDay of teamOfficeHour; let j=index">
            <ng-container *ngFor="let hour of weekDay; let k=index">
              <ng-container *ngIf="hour">
                <rect class="colored-rect" [attr.x]="130 + k*30" [attr.y]="30 + j*20" width="3.3%" height="20" [attr.fill]="colorTable[teamList[teamList.length-1-i]._id]" [ngClass]="labelShow === true || teamLabel == teamList[teamList.length-1-i]._id ? 'show': '' "></rect>
              </ng-container>   
            </ng-container>  
          </ng-container>
        </ng-container>
      </g>
    </g>
  </svg>
</div>