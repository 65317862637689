import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Permission } from '@enum/permission';
import { Priority } from '@enum/priority';
import { TablexColumnType } from '@enum/tablexColumnType';
import { isPhoneNumber, isTimeString } from 'src/app/presenter/validators/validator';
import { BasePage } from 'src/app/ui/model/base/base';
import { Session }  from 'src/app/services/session';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent extends BasePage implements OnInit {

  pageTitle: string;

  officeHoursParam: {} = {};
  timeIndex = {
    start: 1,
    end: 2
  }

  responseTimeParam: {} = {};
  priorityIndex = {
    nonUrgent: 1,
    urgent: 2,
    emergency: 3
  }

  workCentreForm = this.fb.group({
    responsibleOfficer    : "",
    ccsAccount            : "",
    enableHotline         : false,
    defaultHotline        : "",
    enableFaxNumber       : false,
    defaultFaxNumber      : "",
  });

  selectedWorkCenter                        : string;
  workCentrePostList                        : string[] = [];

  hasEditionPermission                      : boolean = false;
  hasWorkCentreInfo                         : boolean = false;
  isLoadingWorkCentre                       : boolean = true;
  isLoadingPostList                         : boolean = true;
  isSaving                                  : boolean = false;

  constructor(
    injector    : Injector,
    private fb  : FormBuilder
  ) {
    super(injector)
    this.selectedWorkCenter = Session.selectedWorkCentre;
  }

  //===========================================================================
  // view life cycle functions
  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.general-settings.page-title", [Session.selectedWorkCentre]);
    this.checkViewPermission(Permission.wcmView);
    this.checkWorkCenterPermission(this.selectedWorkCenter);
    this.hasEditionPermission = this.authorizationService.hasPermission(Permission.wcmUpdate);

    this.requestWorkCenterInformation();
    this.requestWorkCentrePostList();
  }

  //===========================================================================
  // view functions
  private setWorkCentreForm(workCentre) {

    this.workCentreForm.patchValue({
      responsibleOfficer    : workCentre.responsibleOfficer,
      ccsAccount            : workCentre.ccsAccount,
      enableHotline         : workCentre.enableHotline,
      defaultHotline        : workCentre.defaultHotline,
      enableFaxNumber       : workCentre.enableFaxNumber,
      defaultFaxNumber      : workCentre.defaultFaxNumber,
    })
    if (this.hasEditionPermission) {
      this.workCentreForm.controls['responsibleOfficer'].enable();
      this.workCentreForm.controls['ccsAccount'].enable();
    } else {
      this.workCentreForm.controls['responsibleOfficer'].disable();
      this.workCentreForm.controls['ccsAccount'].disable();
    }
    
    if (this.hasEditionPermission) {
      this.workCentreForm.controls['enableHotline'].enable();
      this.workCentreForm.controls['enableFaxNumber'].enable();

      if (workCentre.enableHotline) {
        this.workCentreForm.controls['defaultHotline'].enable();
      } else {
        this.workCentreForm.controls['defaultHotline'].disable();
      }
      if (workCentre.enableFaxNumber) {
        this.workCentreForm.controls['defaultFaxNumber'].enable();
      } else {
        this.workCentreForm.controls['defaultFaxNumber'].disable();
      }
    } else {
      this.workCentreForm.controls['enableHotline'].disable();
      this.workCentreForm.controls['defaultHotline'].disable();
      this.workCentreForm.controls['enableFaxNumber'].disable();
      this.workCentreForm.controls['defaultFaxNumber'].disable();
    }
  }
  
  initOfficeHoursTable(operationPeriod: any) {
    this.officeHoursParam = {
      componentId: 'settings-work-centre_office-hours',
      isLoadingTable: false,
      isEditMode: false,
      hasEditionPermission: this.hasEditionPermission,
      onEditButtonClicked: () => {
        this.officeHoursParam['isEditMode'] = true;
      },
      // table content
      headers: [
        { name: 'global.day-of-week', type: TablexColumnType.Text, class: 'w-40', },
        { name: 'global.start', type: TablexColumnType.Input, class: 'w-30', },
        { name: 'global.end', type: TablexColumnType.Input, class: 'w-30', },
      ],
      content: [
        [
          'global.monday',
          { id: '', dayOfWeek: 1, name: 'global.monday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 1, name: 'global.monday', timeType: 'global.end', value: '' }
        ],
        [
          'global.tuesday',
          { id: '', dayOfWeek: 2, name: 'global.tuesday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 2, name: 'global.tuesday', timeType: 'global.end', value: '' }
        ],
        [
          'global.wednesday',
          { id: '', dayOfWeek: 3, name: 'global.wednesday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 3, name: 'global.wednesday', timeType: 'global.end', value: '' }
        ],
        [
          'global.thursday',
          { id: '', dayOfWeek: 4, name: 'global.thursday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 4, name: 'global.thursday', timeType: 'global.end', value: '' }
        ],
        [
          'global.friday',
          { id: '', dayOfWeek: 5, name: 'global.friday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 5, name: 'global.friday', timeType: 'global.end', value: '' }
        ],
        [
          'global.saturday',
          { id: '', dayOfWeek: 6, name: 'global.saturday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 6, name: 'global.saturday', timeType: 'global.end', value: '' }
        ],
        [
          'global.sunday',
          { id: '', dayOfWeek: 0, name: 'global.sunday', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 0, name: 'global.sunday', timeType: 'global.end', value: '' }
        ],
        [
          'global.public-holidays',
          { id: '', dayOfWeek: 100, name: 'global.public-holidays', timeType: 'global.start', value: '' },
          { id: '', dayOfWeek: 100, name: 'global.public-holidays', timeType: 'global.end', value: '' }
        ]
      ]
    };

    if (operationPeriod && operationPeriod.operationPeriod && operationPeriod.operationPeriod.length > 0) {
      operationPeriod.operationPeriod.forEach(element => {
        let contentIndex = 0;
        switch (element.startDayOfWeek) {
          case 0:
            contentIndex = 6;
            break;
          case 1:
            contentIndex = 0;
            break;
          case 2:
            contentIndex = 1;
            break;
          case 3:
            contentIndex = 2;
            break;
          case 4:
            contentIndex = 3;
            break;
          case 5:
            contentIndex = 4;
            break;
          case 6:
            contentIndex = 5;
            break;
        }
        this.officeHoursParam['content'][contentIndex][this.timeIndex.start]['value'] = element.startTime ? element.startTime : '';
        this.officeHoursParam['content'][contentIndex][this.timeIndex.end]['value'] = element.endTime ? element.endTime : '';
      });
    }

    if (operationPeriod && operationPeriod.publicHoliday && operationPeriod.publicHoliday.length > 0) {
      let element = operationPeriod.publicHoliday[0];
      // index 7 == public holiday
      this.officeHoursParam['content'][7][this.timeIndex.start]['value'] = element.startTime ? element.startTime : '';
      this.officeHoursParam['content'][7][this.timeIndex.end]['value'] = element.endTime ? element.endTime : '';
    }
  }

  initResponseTimeTable(defaultNoResponseTime: any) {
    this.responseTimeParam = {
      componentId: 'settings-work-centre_time-for-no-response',
      isLoadingTable: false,
      isEditMode: false,
      hasEditionPermission: this.hasEditionPermission,
      onEditButtonClicked: () => {
        this.responseTimeParam['isEditMode'] = true;
        this.responseTimeParam['isDisable'] = false;
      },
      // table content
      headers: [
        { name: '', type: TablexColumnType.Text, class: 'w-30', },
        { name: 'priority.non-urgent', type: TablexColumnType.Input, class: 'w-20', },
        { name: 'priority.urgent', type: TablexColumnType.Input, class: 'w-20', },
        { name: 'priority.emergency', type: TablexColumnType.Input, class: 'w-20', },
      ],
      content: [
        [
          'component.response-time-table.contractor',
          { id: '', order: 0, name: 'component.response-time-table.contractor', priority: 'priority.non-urgent', value: null },
          { id: '', order: 0, name: 'component.response-time-table.contractor', priority: 'priority.urgent', value: null },
          { id: '', order: 0, name: 'component.response-time-table.contractor', priority: 'priority.emergency', value: null }
        ],
        [
          'component.response-time-table.1st-contact',
          { id: '', order: 1, name: 'component.response-time-table.1st-contact', priority: 'priority.non-urgent', value: null },
          { id: '', order: 1, name: 'component.response-time-table.1st-contact', priority: 'priority.urgent', value: null },
          { id: '', order: 1, name: 'component.response-time-table.1st-contact', priority: 'priority.emergency', value: null }
        ],
        [
          'component.response-time-table.2nd-contact',
          { id: '', order: 2, name: 'component.response-time-table.2nd-contact', priority: 'priority.non-urgent', value: null },
          { id: '', order: 2, name: 'component.response-time-table.2nd-contact', priority: 'priority.urgent', value: null },
          { id: '', order: 2, name: 'component.response-time-table.2nd-contact', priority: 'priority.emergency', value: null }
        ],
        [
          'component.response-time-table.3rd-contact',
          { id: '', order: 3, name: 'component.response-time-table.3rd-contact', priority: 'priority.non-urgent', value: null },
          { id: '', order: 3, name: 'component.response-time-table.3rd-contact', priority: 'priority.urgent', value: null },
          { id: '', order: 3, name: 'component.response-time-table.3rd-contact', priority: 'priority.emergency', value: null }
        ],
        [
          'component.response-time-table.responsible-officer',
          { id: '', order: 4, name: 'component.response-time-table.responsible-officer', priority: 'priority.non-urgent', value: null },
          { id: '', order: 4, name: 'component.response-time-table.responsible-officer', priority: 'priority.urgent', value: null },
          { id: '', order: 4, name: 'component.response-time-table.responsible-officer', priority: 'priority.emergency', value: null }
        ]
      ]
    }

    for (let i = 0; i < defaultNoResponseTime[Priority.Emergency].length; i++) {
      this.responseTimeParam['content'][i][this.priorityIndex.nonUrgent]['value'] = this.getResponseTimeStr(defaultNoResponseTime, Priority.NonUrgent, i);
      this.responseTimeParam['content'][i][this.priorityIndex.urgent]['value'] = this.getResponseTimeStr(defaultNoResponseTime, Priority.Urgent, i);
      this.responseTimeParam['content'][i][this.priorityIndex.emergency]['value'] = this.getResponseTimeStr(defaultNoResponseTime, Priority.Emergency, i);
    }
  }

  private getResponseTimeStr(object: any, priority: number, order: number): string {
    let timeStr = null;
    if (object && object[priority] && object[priority][order]) {
      timeStr = object[priority][order]
    }
    return timeStr;
  }

  //===========================================================================
  // api function
  requestWorkCenterInformation() {
    this.isLoadingWorkCentre    = true;
    this.hasWorkCentreInfo      = false;

    let request:JM.JMRequestWorkCentresWorkCentreSummary = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.selectedWorkCenter];
    request.includeSummary = true;

    JM.JMConnector.sendWorkCentresWorkCentreSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseWorkCentresWorkCentreSummary) => {
      this.isLoadingWorkCentre    = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      
      if (response.payload.records.length > 0) {
        this.hasWorkCentreInfo = true;
        let workCentre = response.payload.records[0];
        this.setWorkCentreForm(workCentre);
        this.initOfficeHoursTable(workCentre.defaultOperationPeriod);
        this.initResponseTimeTable(workCentre.defaultNoResponseTime);
      } else {
        this.hasWorkCentreInfo = false;
      }
    });
  }

  requestWorkCentrePostList() {
    this.isLoadingPostList = true

    let request               = new JM.JMRequestPostsPostSummary();
    request.systemName        = 'CCEPJM';
    request.authorizations    = {'workCenters': this.selectedWorkCenter};
    request.active            = JMENUM.RequestActive.ACTIVE;

    JM.JMConnector.sendPostsPostSummary(request, (error:JM.JMNetworkError, response:JM.JMResponsePostsPostSummary) => {
      this.isLoadingPostList = false
      
      if (error) {
        this.handleJMError(error);
        return;
      }

      if (response == null || response.code != 200 ||response.payload == null) {
        this.openErrorBar(response);
        return;
      }

      if (!response.payload.records || !response.payload.records.length) {
        return;
      }

      this.workCentrePostList = response.payload.records.map(post => { return post.name});

    });
  }

  private saveHandler(request: JM.JMRequestWorkCentresUpdateWorkCentre) {
    this.isSaving = true;
    
    JM.JMConnector.sendWorkCentresUpdateWorkCentre(request, (error:JM.JMNetworkError, response:JM.JMResponseWorkCentresUpdateWorkCentre) => {
      this.isSaving = false;
      this.officeHoursParam['isEditMode'] = false;
      this.responseTimeParam['isEditMode'] = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      this.openSnackBar(this.translate("global.saved"));
    });
  }

  //===========================================================================
  // ui functions

  onSaveBtnClicked() {
    let operationPeriod                   = this.validateOperationPeriod();
    let responseTime                      = this.validateResponseTime();
    let enableHotline                     = this.workCentreForm.get('enableHotline').value;
    let defaultHotline                    = this.workCentreForm.get('defaultHotline').value;
    let enableFaxNumber                   = this.workCentreForm.get('enableFaxNumber').value;
    let defaultFaxNumber                  = this.workCentreForm.get('defaultFaxNumber').value;
    let responsibleOfficer                = this.workCentreForm.get('responsibleOfficer').value;
    let ccsAccount                        = this.workCentreForm.get('ccsAccount').value;
    
    if (operationPeriod == null) {
      return;
    }

    if (responseTime == null) {
      return;
    }

    if (!responsibleOfficer || this.trimString(responsibleOfficer).length === 0) {
      this.openSnackBar(this.translate("pages.general-settings.empty-RO"));
      return;
    }

    if (!ccsAccount || this.trimString(ccsAccount).length === 0) {
      this.openSnackBar(this.translate("pages.general-settings.empty-ccs-operator"));
      return;
    }

    if (ccsAccount && this.trimString(ccsAccount).length > 20) {
      this.openSnackBar(this.translate("pages.general-settings.long-ccs-operator"));
      return;
    }

    if (enableHotline && 
      (!defaultHotline || (defaultHotline && !isPhoneNumber(defaultHotline.toString())))) {
      this.openSnackBar(this.translate("pages.general-settings.invalid-hotline"));
      return;
    }

    if (enableFaxNumber && 
      (!defaultFaxNumber || (defaultFaxNumber && !isPhoneNumber(defaultFaxNumber.toString())))) {
      this.openSnackBar(this.translate("pages.general-settings.invalid-fax"));
      return;
    }
    let request: JM.JMRequestWorkCentresUpdateWorkCentre = new JM.JMRequestWorkCentresUpdateWorkCentre();
    request.code                = this.selectedWorkCenter;
    request.enableHotline       = enableHotline;
    request.defaultHotline      = (defaultHotline && defaultHotline.length>0)?defaultHotline.trim(): null;
    request.enableFaxNumber     = enableFaxNumber;
    request.defaultFaxNumber    = (defaultFaxNumber && defaultFaxNumber.length>0)? defaultFaxNumber.trim(): null;
    request.responsibleOfficer  = (responsibleOfficer && responsibleOfficer.length>0)? responsibleOfficer.trim(): null;
    request.ccsAccount          = (ccsAccount && ccsAccount.length>0)? ccsAccount.trim(): null;
    request.defaultOperationPeriod = operationPeriod;
    request.defaultNoResponseTime = responseTime;

    let message = this.translate("action.button.popup.save");
    let handler = () => this.saveHandler(request);
    this.popupConfirmAlert(message, handler);
  }

  private validateOperationPeriod(): object {
    let editedOperationPeriod: any = {};
    editedOperationPeriod.operationPeriod = [];
    editedOperationPeriod.publicHoliday = [];

    let isInputTime = (startValue, endValue) => {
      return (startValue && endValue || !startValue && !endValue);
    }
    let error = null;
    for (let i = 0; i < this.officeHoursParam['content'].length; i++) {
      let startObject = this.officeHoursParam['content'][i][this.timeIndex.start];
      let endObject = this.officeHoursParam['content'][i][this.timeIndex.end];

      if (!isTimeString(startObject.value)) {
        error = `${this.translate(startObject.name)} - ${this.translate(startObject.timeType)} ${this.translate("pages.general-settings.invalid-time")}`;
      }

      if (!isTimeString(endObject.value)) {
        error = `${this.translate(endObject.name)} - ${this.translate(endObject.timeType)} ${this.translate("pages.general-settings.invalid-time")}`;
      }

      if (!isInputTime(startObject.value, endObject.value)) {
        error = `${this.translate(startObject.name)} ${this.translate("pages.general-settings.missing-input-time")}`;
      }
      if (error) {
        this.openSnackBar(error);
        return null;
      }
      // dayOfWeek 100 == public holiday
      if (startObject.dayOfWeek == 100) {
        editedOperationPeriod.publicHoliday.push({
          startTime: startObject.value,
          endTime: endObject.value
        });
      } else {
        editedOperationPeriod.operationPeriod.push({
          startDayOfWeek: startObject.dayOfWeek,
          endDayOfWeek: startObject.dayOfWeek,
          startTime: startObject.value,
          endTime: endObject.value
        });
      }
    }
    
    return editedOperationPeriod;
  }

  private validateResponseTime(): object {
    let editedNoResponseTime: any = {};
    let errorMessage = '';
    let setResponseTime = (data: any, order: number, priority: Priority, priorityIndex: number) => {
      if (!data[priorityIndex] && !data[priorityIndex].value) {
        errorMessage = `${this.translate(data[priorityIndex].name)} - ${this.translate(data[priorityIndex].priority)} ${this.translate("pages.general-settings.invalid-time")}`;
      }
      if (Number(data[priorityIndex].value) > 0 ) {
        editedNoResponseTime[priority][order] = Number(data[priorityIndex].value);
      } else {
        errorMessage = `${this.translate(data[priorityIndex].name)} - ${this.translate(data[priorityIndex].priority)} ${this.translate("pages.general-settings.invalid-time")}`;
      }
    }

    editedNoResponseTime[Priority.NonUrgent]    = [null, null, null, null, null];
    editedNoResponseTime[Priority.Urgent]       = [null, null, null, null, null];
    editedNoResponseTime[Priority.Emergency]    = [null, null, null, null, null];

    for (let i = this.responseTimeParam['content'].length - 1; i >= 0; i--) {
      const element = this.responseTimeParam['content'][i];
      setResponseTime(element, element[1].order, Priority.NonUrgent, this.priorityIndex.nonUrgent);
      setResponseTime(element, element[1].order, Priority.Urgent, this.priorityIndex.urgent);
      setResponseTime(element, element[1].order, Priority.Emergency, this.priorityIndex.emergency);
      if (errorMessage) {
        this.openSnackBar(errorMessage);
        return null;
      }
    }

    return editedNoResponseTime;
  }

  private popupConfirmAlert(message: string, handler: any) {
    let buttons = [
      { name: this.translate("global.yes"), handler },
      { name: this.translate("global.no") }
    ]
    this.showPopUpAlert(message, "", buttons);
  }


  onEnableHotlineChanged($event: any) {
    if ($event.currentTarget.checked) {
      this.workCentreForm.controls['defaultHotline'].enable();
    } else {
      this.workCentreForm.controls['defaultHotline'].disable();
      this.workCentreForm.patchValue({'defaultHotline': ''});
    }
  }

  onEnableFaxChanged($event: any) {
    if ($event.currentTarget.checked) {
      this.workCentreForm.controls['defaultFaxNumber'].enable();
    } else {
      this.workCentreForm.controls['defaultFaxNumber'].disable();
      this.workCentreForm.patchValue({'defaultFaxNumber': ''});
    }
  }

  // TODO: reload language
  onLanguageChanged() {
    
  }

}
