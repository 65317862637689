import { Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JM, JMENUM, JMOBJ, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { debounce } from 'underscore';
import { Observable } from 'rxjs';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { isEnabledFeature } from 'src/app/services/utility';
import { LocationService } from 'src/app/shared/location.service';

interface ISelectOptions {
  value: string;
  label: string;
}

interface IClientAPIRespnse {
  clientShortName: string;
  name: {
    en: string;
    zh: string;
  };
}

interface ILocationAPIRespnse {
  code: string;
  description: {
    en: string;
    zh: string;
  };
}

interface IInitOptions {
  items: ISelectOptions[];
  nextPage: number;
  totalPage: number;
  search: '';
  filter: string | {[propName: string]: any} | null;
  reset: () => IInitOptions;
  setFilter: (val: string) => IInitOptions;
  setSearch: (val: string) => IInitOptions;
}

const initOptions: IInitOptions = {
  items: [],
  nextPage: 1,
  totalPage: 1,
  search: '',
  filter: null,
  reset() {
    this.items = [];
    this.nextPage = 1;
    this.totalPage = 1;
    this.search = '';
    this.filter = null;
    return this;
  },
  setFilter(val) {
    this.filter = val;
    return this;
  },
  setSearch(val) {
    this.search = val;
    return this;
  }
};

@Component({
  selector: 'app-jobcard-client-information',
  templateUrl: './jobcard-client-information.component.html',
  styleUrls: ['./jobcard-client-information.component.scss']
})
export class JobcardClientInformationComponent implements OnInit {
  lang = Session.selectedLanguage;
  errorFields: any = {};
  maxContactCount = 3;
  textareaMaxLength = Constants.TEXTAREA_MAX_LENGTH;

  // client info
  clientInformation: {} = null;
  contactNumberOptions = null;
  contactNumberFilteredOptions: Observable<string[]>;
  isVVIP_SRAEnable: boolean = false;
  // formControl = new FormControl();

  // Options config
  clientOptions = {...initOptions};
  locationOptions = {...initOptions};
  optionsPageSize = 100;
  onClientSelectScrollToEndDebounce: () => void;
  onClientSelectSearchDebounce: (e) => void;
  onLocationSelectScrollToEndDebounce: () => void;
  onLocationSelectSearchDebounce: (e) => void;
  SRALocationSet : Set<JMOBJ.SpecialRequestRule['location']['code']> = new Set();
  emptyHandleNa = JMLanguage.translate('component.display-text-field.na');
  
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() jobCard: JMOBJ.JobCard;
  @Input() componentParameters;
  @Input() pageMode:JMENUM.JMPageMode;
  @Output() updateSnData = new EventEmitter<{ [key: string] : any }>();
  @Output() updateJobCardData = new EventEmitter<{ [key: string] : any }>();


  constructor(private locationService: LocationService) {
    this.onClientSelectScrollToEndDebounce = debounce(this.onClientSelectScrollToEnd, 250);
    this.onClientSelectSearchDebounce = debounce(this.onClientSelectSearch, 250);
    this.onLocationSelectScrollToEndDebounce = debounce(this.onLocationSelectScrollToEnd, 250);
    this.onLocationSelectSearchDebounce = debounce(this.onLocationSelectSearch, 250);
    this.isVVIP_SRAEnable = isEnabledFeature(Session,JMCONSTANT.JMFeature.VVIP_SRA);
    /////////////////////////////////////////////////////////
    // comment for disabling search saved contact feature
    //
    // this.clientInformation = this.loadClientInformation();
    // this.contactNumberOptions = this.clientInformation ? Object.keys(this.clientInformation).sort() : [];
    // this.contactNumberFilteredOptions = this.formControl.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this.filterContactNumber(value))
    // );
    //
    /////////////////////////////////////////////////////////
  }

  async ngOnInit() {
    if (this.isPageModeCreate) {
      if (this.isVVIP_SRAEnable && !this.isJobNatureStandalone) {
        await this.setSRALocationSet();
      }
      this.requestClientsClientSummary();
      this.requestLocationsLocationSummary();
    }
  }

  ngOnChanges(changes: SimpleChanges){
    let { jobCard } = changes;
    if (jobCard) {
      if(this.isPageModeCreate){
        let prevJobNature = jobCard.previousValue && jobCard.previousValue.jobNature;
        let nextJobNature = jobCard.currentValue && jobCard.currentValue.jobNature;
        if (prevJobNature !== nextJobNature) {
          this.clearValidations();
          if(prevJobNature === JMENUM.JobNature.STANDALONE || nextJobNature === JMENUM.JobNature.STANDALONE){ 
            this.clearNonStandaloneFields();
          }
        }
      }
    }
  }

  private async setSRALocationSet() {
    return this.locationService.requestAllSRALocation().then((locationCodeSet: Set<JMOBJ.SpecialRequestRule['location']['code']>) => {
      this.SRALocationSet = locationCodeSet
    }).catch((error) => {
      console.error(error)
    })
  }

  inputNumberWithIndex(event: any, index) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    this.sn.contactNumber[index] = event.target.value;
  }

  onAddContactNumberButtonClicked() {
    if (this.sn.contactNumber.length < this.maxContactCount) {
      this.sn.contactNumber.push('');
    }
  }

  onHideContactNumberButtonClicked(index: number) {
    this.sn.contactNumber.splice(index, 1);
  }

  /////////////////////////////////////////////////////////
  // comment for disabling search saved contact feature
  //
  // filterContactNumber(value: string): string[] {
  //     const filterValue = value;
  //     return this.contactNumberOptions.filter(option => option.indexOf(filterValue) === 0);
  // }

  // loadClientInformation() {
  //     return Session.snCreateClientDetail ? Session.snCreateClientDetail : {};
  // }

  onContactNumberChanged = (value: string) => {
    //   if (value in this.clientInformation) {
    //     const clientInfo = this.clientInformation[value];
    //     this.sn.client = clientInfo.client;
    //     this.sn.contactPerson = clientInfo.contactPerson;
    //     this.sn.email = clientInfo.email;
    //     this.sn.district = clientInfo.district;
    //     this.sn.location = clientInfo.location;
    //     this.sn.locationDetail = clientInfo.locationDetail;
    //   }
  }
  //
  /////////////////////////////////////////////////////////

  // Select Methods

  onClientSelectScrollToEnd = () => {
    this.requestClientsClientSummary();
  }

  onClientSelectSearch = (e) => {
    this.clientOptions.reset().setFilter(e.term).setSearch(e.term);
    this.requestClientsClientSummary();
  }

  onClientSelectClear = () => {
    this.clientOptions.reset();
    this.requestClientsClientSummary();
  }

  onClientSelectClick = () => {
    if (!this.sn.client && !!this.clientOptions.search) this.onClientSelectClear();
  }

  onLocationSelectScrollToEnd = () => {
    this.requestLocationsLocationSummary();
  }

  onLocationSelectSearch = (e) => {
    this.locationOptions.reset().setFilter(e.term).setSearch(e.term);
    this.requestLocationsLocationSummary();
  }

  onLocationSelectClear = () => {
    this.locationOptions.reset();
    this.requestLocationsLocationSummary();
  }

  onLocationSelectBlur = () => {
    if (!this.sn.location && !!this.locationOptions.search) this.onLocationSelectClear();
  }

  requestClientsClientSummary = async (exactClientCode?: string[]) => {
    if (!exactClientCode && (this.clientOptions.nextPage > this.clientOptions.totalPage)) {
      return console.warn('Client options nextpage is larger than its totalpage...');
    }
    const request = new JM.JMRequestClientsClientSummary();

    request.clientShortName = exactClientCode;
    request.parameters = ['clientShortName', 'name'];
    request.pageNumber = this.clientOptions.nextPage;
    request.pageSize = this.optionsPageSize;
    if (typeof this.clientOptions.filter === 'string' && this.clientOptions.filter !== '') { request.clientDescription = this.clientOptions.filter; }
    if (typeof this.clientOptions.filter === 'object') { request.filter = this.clientOptions.filter; }

    const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    const optionItems = this.getNewClientOptions(response.payload.records);
    // update clientOptions property
    this.clientOptions = {
      ...this.clientOptions,
      items: exactClientCode ? optionItems : this.clientOptions.items.concat(optionItems),
      nextPage: this.clientOptions.nextPage + 1,
      totalPage: Math.ceil(response.payload.totalCount / this.optionsPageSize),
    };
  }

  getNewClientOptions = (clientResArr: IClientAPIRespnse[]) => {
    return clientResArr.map( ({clientShortName, name}) => {
      const name2 = name[this.lang] ? name[this.lang] : name.en;
      return {
        value: clientShortName,
        label: `${clientShortName} - ${name2}`
      };
    }).sort( (a, b) => (b.label > a.label) ? -1 : 1 );
  }

  requestLocationsLocationSummary = async (exactLocationCode?: string[]) => {
    if (this.locationOptions.nextPage > this.locationOptions.totalPage) {
      return console.warn('Location options nextpage is larger than its totalpage...');
    }
    const request = new JM.JMRequestLocationsLocationSummary();

    request.location = exactLocationCode;
    request.parameters = ['code', 'description'];
    request.locationOnly = true;
    request.pageNumber = this.locationOptions.nextPage;
    request.pageSize = this.optionsPageSize;
    if (typeof this.locationOptions.filter === 'string' && this.locationOptions.filter !== '') { request.locationDescription = this.locationOptions.filter; }
    if (typeof this.locationOptions.filter === 'object') { request.filter = this.locationOptions.filter; }

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    const optionItems = this.getNewLocationOptions(response.payload.records);
    // update locationOptions property
    this.locationOptions = {
      ...this.locationOptions,
      items: exactLocationCode ? optionItems : this.locationOptions.items.concat(optionItems),
      nextPage: this.locationOptions.nextPage + 1,
      totalPage: Math.ceil(response.payload.totalCount / this.optionsPageSize),
    };
  }

  getNewLocationOptions = (locationResArr: ILocationAPIRespnse[]) => {
    return locationResArr.map( ({code, description}) => {
      const desc = description[this.lang] ? description[this.lang] : description.en;
      let locationOption :any = {
        value: code,
        label: `${desc} (${code})`
      };
      if(this.isVVIP_SRAEnable){
        locationOption = {
          ...locationOption,
          isSpecialRequestAlert : this.isSpecialRequestAlert(code)
        };
      } 
      return locationOption;
    }).sort( (a, b) => (b.label > a.label) ? -1 : 1 );
  }

  isSpecialRequestAlert = (code:string) : boolean => {
    return this.SRALocationSet.has(code)
  }

  clearValidations = () =>{
    this.errorFields = {};
  }

  validation = () => {
    let isValid = true;
    this.clearValidations();
    this.errorFields.client = !this.sn.client;
    if (this.jobCard.jobNature !== JMENUM.JobNature.STANDALONE) {
      this.errorFields.contactNumber = !(this.sn.contactNumber.length && !!this.sn.contactNumber[0]);
      this.errorFields.contactPerson = !this.sn.contactPerson;
      this.errorFields.location = !this.sn.location;
    }

    isValid = !Object.keys(this.errorFields).find(field => this.errorFields[field]);
    if (!isValid) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.sn-edit.error.msg.mandatory-field'));
    }
    return isValid;
  }

  clearNonStandaloneFields = () => {
    this.updateSnData.emit({ 
      contactNumber : [],
      contactPerson : undefined,
      email : undefined,
      location : undefined,
      locationDetail : undefined
    });
  }

  get isJobNatureStandalone(){
    return this.jobCard.jobNature === JMENUM.JobNature.STANDALONE;
  }

  get isPageModeCreate(){
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }

  get isPageModeEdit(){
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }

  get isPageModeView(){
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }
  
}
