import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostApprovalAddRequestRequest } from '@api/model/approval/post-approval-add-request-request';
import { PostApprovalAddRequestResponse } from '@api/model/approval/post-approval-add-request-response';
import { PostApprovalApproveRequestRequest } from '@api/model/approval/post-approval-approve-request-request';
import { PostApprovalApproveRequestResponse } from '@api/model/approval/post-approval-approve-request-response';
import { PostApprovalRejectRequestRequest } from '@api/model/approval/post-approval-reject-request-request';
import { PostApprovalRejectRequestResponse } from '@api/model/approval/post-approval-reject-request-response';
import { PostApprovalRequestSummaryRequest } from '@api/model/approval/post-approval-request-summary-request';
import { PostApprovalRequestSummaryResponse } from '@api/model/approval/post-approval-request-summary-response';
import { AuthorizationService } from '@services/authorization.service';
import { Session } from 'src/app/services/session';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';

@Injectable({
  providedIn: 'root'
})

export class ApprovalService {


  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService
  ) { }

  //=======================================================================================================================
  // HttpClient request
  getRequestSummary(request: PostApprovalRequestSummaryRequest): Observable<PostApprovalRequestSummaryResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/approval/requestSummary";

    return this.http.post<PostApprovalRequestSummaryResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    );
  }

  submitRequest(request: PostApprovalAddRequestRequest): Observable<PostApprovalAddRequestResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/approval/addRequest";

    return this.http.post<PostApprovalAddRequestResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    );
  }

  approveRequest(request: PostApprovalApproveRequestRequest): Observable<PostApprovalApproveRequestResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/approval/approveRequest";

    return this.http.post<PostApprovalApproveRequestResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    );
  }

  rejectRequest(request: PostApprovalRejectRequestRequest): Observable<PostApprovalRejectRequestResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/approval/rejectRequest";

    return this.http.post<PostApprovalRejectRequestResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  //=======================================================================================================================
  // cm approval
  async requestApprovalCmPending(pageNumber: number, pageSize: number, includeSummary?: boolean, generalSearchKeyword?: string) {
    let request = this.approvalCmRequestAdapter(pageNumber, pageSize, includeSummary, generalSearchKeyword);
    request.status = [JMENUM.ApprovalStatus.PENDING];

    return this.requestApprovalSummary(request);
  }

  async requestApprovalCmHistory(pageNumber: number, pageSize: number, includeSummary?: boolean, generalSearchKeyword?: string) {
    let request = this.approvalCmRequestAdapter(pageNumber, pageSize, includeSummary, generalSearchKeyword);
    request.status = [JMENUM.ApprovalStatus.APPROVED, JMENUM.ApprovalStatus.REJECTED, JMENUM.ApprovalStatus.WITHDRAWN];

    return this.requestApprovalSummary(request);
  }

  private approvalCmRequestAdapter(pageNumber: number, pageSize: number, includeSummary?: boolean, generalSearchKeyword?: string) {
    let request = new JM.JMRequestApprovalSummary;
    request.system = ['CCEPJM'];
    request.type = [
      JMENUM.ApprovalType.JOBCARD_COMPLETE,
      JMENUM.ApprovalType.SN_ASSOCIATE,
      JMENUM.ApprovalType.SN_CANCEL
    ];
    request.pageNumber = pageNumber;
    request.pageSize = pageSize;
    request.sortBy = 'createdAt';
    request.sortOrder = JMENUM.SortOrder.DESC;
    typeof includeSummary == 'boolean' && (request.includeSummary = includeSummary);

    if (!!generalSearchKeyword && generalSearchKeyword.trim().length) {
      request.searchKeyword = generalSearchKeyword;
      request.searchFieldList = [
        'requester',
        'customField.snCancel.snNumber',
        'customField.snAssociate.parentSnNumber',
        'customField.snAssociate.childSnNumberList',
        'customField.jobCardComplete.snNumber',
        'customField.jobCardComplete.jobCardNumber',
      ];
    }

    return request;
  }

  //=======================================================================================================================
  // pm approval
  async requestApprovalPmPlanPending(pageNumber: number, pageSize: number, includeSummary?: boolean, approvalType?: JMENUM.ApprovalType, generalSearchKeyword?: string) {
    let request = this.approvalPmPlanRequestAdapter(pageNumber, pageSize, includeSummary, approvalType, generalSearchKeyword);
    request.status = [JMENUM.ApprovalStatus.PENDING];

    return this.requestApprovalSummary(request);
  }

  async requestApprovalPmPlanHistory(pageNumber: number, pageSize: number, includeSummary?: boolean, approvalType?: JMENUM.ApprovalType, generalSearchKeyword?: string) {
    let request = this.approvalPmPlanRequestAdapter(pageNumber, pageSize, includeSummary, approvalType, generalSearchKeyword);
    request.status = [JMENUM.ApprovalStatus.APPROVED, JMENUM.ApprovalStatus.REJECTED, JMENUM.ApprovalStatus.WITHDRAWN];

    return this.requestApprovalSummary(request);
  }

  async requestApprovalPmJobPending(pageNumber: number, pageSize: number, includeSummary?: boolean, generalSearchKeyword?: string) {
    let request = this.approvalPmJobRequestAdapter(pageNumber, pageSize, includeSummary, generalSearchKeyword);
    request.status = [JMENUM.ApprovalStatus.PENDING];

    return this.requestApprovalSummary(request);
  }

  async requestApprovalPmJobHistory(pageNumber: number, pageSize: number, includeSummary?: boolean, generalSearchKeyword?: string) {
    let request = this.approvalPmJobRequestAdapter(pageNumber, pageSize, includeSummary, generalSearchKeyword);
    request.status = [JMENUM.ApprovalStatus.APPROVED, JMENUM.ApprovalStatus.REJECTED, JMENUM.ApprovalStatus.WITHDRAWN];

    return this.requestApprovalSummary(request);
  }

  private approvalPmPlanRequestAdapter(pageNumber: number, pageSize: number, includeSummary?: boolean, approvalType?: JMENUM.ApprovalType, generalSearchKeyword?: string) {
    let request = new JM.JMRequestApprovalSummary;
    request.system = ['CCEPJM'];
    request.type = [
      JMENUM.ApprovalType.PMPLAN_SUBMIT,
      JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT,
    ];
    if (approvalType) {
      request.type = request.type.filter(type => type == approvalType);
    }
    request.pageNumber = pageNumber;
    request.pageSize = pageSize;
    request.sortBy = 'createdAt';
    request.sortOrder = JMENUM.SortOrder.DESC;
    typeof includeSummary == 'boolean' && (request.includeSummary = includeSummary);

    if (!!generalSearchKeyword && generalSearchKeyword.trim().length) {
      request.filter = {};
      const keyword = generalSearchKeyword.trim();
      if (request.type.includes(JMENUM.ApprovalType.PMPLAN_SUBMIT)) {
        request.filter['customField.pmPlanSubmit.pmPlanNumber'] = keyword;
      }
      if (request.type.includes(JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT)) {
        request.filter['customField.pmPeriodEquipmentEdit.targetPmPeriod.pmPlanNumber'] = keyword;
      }
    }

    return request;
  }

  private approvalPmJobRequestAdapter(pageNumber: number, pageSize: number, includeSummary?: boolean, generalSearchKeyword?: string) {
    let request = new JM.JMRequestApprovalSummary;
    request.system = ['CCEPJM'];
    request.type = [JMENUM.ApprovalType.PMJOB_SUBMIT];
    request.pageNumber = pageNumber;
    request.pageSize = pageSize;
    request.sortBy = 'createdAt';
    request.sortOrder = JMENUM.SortOrder.DESC;
    typeof includeSummary == "boolean" && (request.includeSummary = includeSummary);

    if (!!generalSearchKeyword && generalSearchKeyword.trim().length) {
      request.filter = {};
      const keyword = generalSearchKeyword.trim();
      request.filter['customField.pmJobSubmit.pmJobNumber'] = keyword;
    }

    return request;
  }

  //=======================================================================================================================
  // Team approval
  async requestRequestSummary(pageNumber: number, pageSize: number, includeSummary?: boolean) {
    const request = new JM.JMRequestRequestSummary;
    let selectedWorkCenter;

    if (Session.selectedWorkCentre) {
      selectedWorkCenter = Session.selectedWorkCentre;
    } else {
      let workCenterOptions = null;
      if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
        workCenterOptions = JM.JMConnector.getAllWorkCentreCode();
      } else {
        workCenterOptions = this.authorizationService.getWorkCenters();
      }
      if (Array.isArray(workCenterOptions) && workCenterOptions.length > 0) {
        selectedWorkCenter = workCenterOptions[0]
      }
    }

    request.approvalWorkCentres = [selectedWorkCenter];
    request.status = [1];
    
    request.pageNumber = pageNumber;
    request.pageSize = pageSize;
    typeof includeSummary == "boolean" && (request.includeSummary = includeSummary);
    
    try {
      const response = await JM.JMConnector.sendAsyncRequestSummary(request);
      if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
      return response;
    } catch (error) {
      if (error.code === 498 || error.code === 401) {
        AppDelegate.logout();
        return null;
      }
      return;
    }
  }

  //=======================================================================================================================
  // General API
  async requestBatchProcessRequest(request: JM.JMRequestApprovalsBatchProcessRequest) {
    const response: JM.JMResponseApprovalsBatchProcessRequest = await AppDelegate.sendJMRequest(request);

    if (!response || response.error || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.openErrorBar(response);
      return [];
    }
    return response.payload.records;
  }

  private async requestApprovalSummary(request: JM.JMRequestApprovalSummary) {
    const response: JM.JMResponseApprovalSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    return response;
  }

  //=======================================================================================================================
  // get text
  getApprovalDescription(approval: JMOBJ.Approval, defaultString: string): string {
    let description = defaultString;

    switch (approval.type) {
      case JMENUM.ApprovalType.SN_CANCEL: {
        const customField = approval.customField.snCancel;
        if (customField && customField.snNumber) {
          description = JMLanguage.translate('pages.approval-list.description.sn-cancel', [customField.snNumber]);
        }
        break;
      }
      case JMENUM.ApprovalType.SN_ASSOCIATE: {
        const customField = approval.customField.snAssociate;
        if (customField
          && customField.parentSnNumber
          && customField.childSnNumberList
          && customField.childSnNumberList.length
        ) {
          const childSnNumberList = customField.childSnNumberList.join(', ');
          description = JMLanguage.translate('pages.approval-list.description.sn-associate', [customField.parentSnNumber, childSnNumberList]);
        }
        break;
      }
      case JMENUM.ApprovalType.JOBCARD_COMPLETE: {
        // TODO: customField use 1 to 1 mapping type
        const customField = approval.customField.jobCardComplete;
        if (customField && customField.jobCardNumber) {
          description = JMLanguage.translate('pages.approval-list.description.jobcard-complete', [customField.jobCardNumber]);
        }
        break;
      }
      case JMENUM.ApprovalType.PMPLAN_SUBMIT: {
        const pmPlan = approval.customField.pmPlanSubmit;
        if (pmPlan && pmPlan.pmPlanNumber) {
          description = JMLanguage.translate('pages.approval-list.description.pmplan-submit', [pmPlan.pmPlanNumber]);
        }
        break;
      }
      case JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT: {
        const customField = approval.customField.pmPeriodEquipmentEdit;
        const pmPeriod = customField && customField.targetPmPeriod;
        if (pmPeriod && pmPeriod.pmPlanNumber) {
          description = JMLanguage.translate('pages.approval-list.description.pmperiod-equipment-edit', [pmPeriod.pmPlanNumber]);
        }
        break;
      }
      case JMENUM.ApprovalType.PMJOB_SUBMIT: {
        const pmJob = approval.customField.pmJobSubmit;
        if (pmJob && pmJob.pmJobNumber) {
          description = JMLanguage.translate('pages.approval-list.description.pmjob-submit', [pmJob.pmJobNumber]);
        }
        break;
      }
    }
    return description;
  }

  getOverallKpiStatus(jobCard: JMOBJ.JobCard, defaultString: string): string {
    let description = defaultString;

    if (jobCard && jobCard.complianceStatus) {
      const isRectificationTimePassed = jobCard.complianceStatus.isRectificationTimePassed;
      const isResponseTimePassed = jobCard.complianceStatus.isResponseTimePassed;
      const isResponseToClientTimePassed = jobCard.complianceStatus.isResponseToClientTimePassed;

      const notAvailableStatus = isRectificationTimePassed == null
        && isResponseTimePassed == null
        && isResponseToClientTimePassed == null;
      const passStatus = [null, true].includes(isRectificationTimePassed)
        && [null, true].includes(isResponseTimePassed)
        && [null, true].includes(isResponseToClientTimePassed);
      if (notAvailableStatus == true) {
        description = 'N/A';
      } else if (passStatus == true) {
        description = JMLanguage.translate('jobcard.complianceStatus.pass');
      } else {
        description = JMLanguage.translate('jobcard.complianceStatus.fail');
      }
    }
    return description;
  }

  //=======================================================================================================================
  // Permission
  hasRoleInApprovalList(approvalList: JMOBJ.Approval[], type: 'approver' | 'requester') {
    const postName = Session.userInfo && Session.userInfo.name;
    const filteredApprovalList = approvalList.filter(approval => 
      approval[type] && approval[type].includes(postName)
    );
    return (approvalList.length === filteredApprovalList.length);
  }

  hasPermissionInApprovalList(approvalList: JMOBJ.Approval[], type: 'edit') {
    const filteredApprovalList = approvalList.filter(approval => {
      const hasActionPermission = approval.actionPermission && approval.actionPermission[type] && this.authorizationService.hasPermission(approval.actionPermission[type]);
      const hasCoveragePermission = approval.coveragePermission && approval.coveragePermission[type] && this.authorizationService.hasPermissions(approval.coveragePermission[type], false);
      return hasActionPermission && hasCoveragePermission;
    });
    return (approvalList.length === filteredApprovalList.length);
  }
}