import { AfterViewInit, Component, Injector, OnInit, ViewChild, ViewChildren, ChangeDetectorRef, QueryList } from "@angular/core";
import { TimeRangeSliderComponent } from '../../components/time-range-slider/time-range-slider.component';
import { ActionButtonDefinition, ActionButtonType, ActionButtonTimeSheet } from '@enum/action-button';
import { PostGetTeamsRequest } from '@api/model/contact-group/post-get-teams-request';
import { PostSnSummaryRequest } from '@api/model/sn/post-sn-summary-request';
import { ContactGroupService } from '@services/contact-group.service';
import { BasePage } from "src/app/ui/model/base/base";
import { JM, JMENUM, JMOBJ, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { SnService } from '@services/sn.service';
import { Options, LabelType } from "ng5-slider";
import { Permission } from '@enum/permission';
import * as moment from 'moment';
import { OrderType } from '../../../entity/enum/order-type';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import * as utility from 'src/app/services/utility';
import { Session } from '@services/session';

@Component({
  selector: "timesheet-job",
  templateUrl: "./timesheet-job.html",
  styleUrls: ["./timesheet-job.scss"]
})
export class TimesheetJobComponent extends BasePage implements OnInit, AfterViewInit {
  
  @ViewChild("assign_staff_panel", { static: true }) assignStaffPanel;
  @ViewChildren("time_range_slider") timeRangeSliderArray:QueryList<TimeRangeSliderComponent>;

  actionButtonData = [];
  disabledActionSideBar: boolean;
  isLoading: boolean = true;
  // timeSheetNotSaved: boolean = false;

  workCenterOptions: string[];
  selectedJobCardId: string;
  selectedJobNumber: string;

  assignStaffFormParam = {} as any;
  showAllPostForReassignStaff = false;
  workCentrePostList: any[];
  selectedAssignPersons: {
    assignedPersons: string[],
    officerInCharge: string
  } = {
    assignedPersons: [],
    officerInCharge: null
  };

  jobCard: JMOBJ.JobCard = undefined;
  SnTeamData: any;
  snTeamId: any;
  employeeSummaryDict: {
    [key:string]: { // post name
        postCode: string,
        postName: string,
        employees: JMOBJ.Employee[],
    }
  } = {}; // key: post; obj:JMOBJ.Employee
  

  // storing the work date of the staff
  employeeWorkDate: {
    [key:string]: string[]
  } = {}; // key: <postName>_<employeeCode, val: YYYY-MM-DD[], the acting date within job period

  bufferTimeOptions: Options = {};
  bufferTimeValue: number = 0;
  

  taskNumber: { [key:string]: string } = {};  // key is postName
  uiJobDuration: string = '';
  displayHour: number = 0;
  displayMinutes: number = 0;
  jobPeriod: number = 1;
  jobPeriodList: any = [];
  officeHours: any = [];
  staffTimeSheet: {} = {}; //for slider, storing  {startTime: 9, endTime: 21.75}
  staffTimeSheetForm: {} = {}; //for submit, storing  {startTime: Moment, endTime: Moment}
  staffTimeSheetDisplay: {} = {}; //for display, storing  {startTime: "9:00", endTime: "21:45"}
  timesheetTooltip: {} = {}; // key: [postName_employeeCode][day], obj: { show: true, message: ''}
  timeSheetRecords: JMOBJ.Timesheet;

  uiTimesheetSaved: boolean = false;  // if the record has been saved in backend

  viewOnly: boolean = false;

  constructor(
    injector: Injector,
    private contactGroupService: ContactGroupService,
    private snService: SnService,
    private changeDetector: ChangeDetectorRef,
  ) {
    super(injector);
  }

  //===========================================================================
  // view life cycle functions
  ngOnInit() {
    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.workCenterOptions = JM.JMConnector.getAllWorkCentreCode();
    } else {
      this.workCenterOptions = this.authorizationService.getWorkCenters();
    }
    this.checkfeatureEnabled();
    this.selectedJobCardId = this.route.snapshot.paramMap.get('jobCardId');
    this.selectedJobNumber = '';
    
    this.initBufferTimeSlider();
    this.requestTimeSheetId();
  }

  ngAfterContentChecked(): void{
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.checkViewPermission(Permission.timesheetUpdate);
  }

  //===========================================================================
  // view functions

  initBufferTimeSlider() {
    this.bufferTimeOptions = {
      floor: 0,
      ceil: 2,
      showTicksValues: true,
      showSelectionBar: true,
      animate: false,
      disabled: this.viewOnly,
      translate: (value: number): string => {
        return value + "h";
      },
      stepsArray: [{ value: 0 }, { value: 1 }, { value: 2 }]
    }
    this.bufferTimeValue = (this.timeSheetRecords && this.timeSheetRecords.bufferTime) ? (this.timeSheetRecords.bufferTime / 60) : 0;
  }

  checkfeatureEnabled(){
    if(!utility.isEnabledFeature(Session,JMCONSTANT.JMFeature.JOB_CARD_TIMESHEET)) {
      this.router.navigate(['']);
      return;
    }
  }

  resetWorkPeriod() {
    let oldStaffTimeSheet = Object.assign({}, this.staffTimeSheet);
    let oldStaffTimeSheetForm = Object.assign({}, this.staffTimeSheetForm);
    let oldStaffTimeSheetDisplay = Object.assign({}, this.staffTimeSheetDisplay);
    let oldTimesheetTooltip = Object.assign({}, this.timesheetTooltip);

    // this.taskNumber = {};
    this.staffTimeSheet = {};
    this.staffTimeSheetForm = {};
    this.staffTimeSheetDisplay = {};
    this.timesheetTooltip = {};

    for(let postName in this.employeeSummaryDict) {
      for (let employee of this.getDistinctEmployee(postName)) {
        let key = postName + "_" + employee.employeeCode;
        this.staffTimeSheet[key] = {};
        this.staffTimeSheetForm[key] = {};
        this.staffTimeSheetDisplay[key] = {};
        this.timesheetTooltip[key] = {};

        let dayOffset = 0;
        for( let jobDate of this.jobPeriodList) {
          // if the job date is in employee acting date
          if (this.employeeWorkDate[key].includes(jobDate)) {
            let initMoment = moment(this.jobCard.startTime).add(dayOffset, 'day').set({
              minute: Math.floor(moment(this.jobCard.startTime).minute() / 15.0) * 15,
              second: 0,
            });
            this.timesheetTooltip[key][jobDate] = {
              show: true,
              message: this.translate('pages.timesheet.no-timesheet-tooltip')
            };
            this.staffTimeSheet[key][jobDate] = {
              startTime: initMoment.hour() + initMoment.minute()/60.0, // convert to base 10
              endTime: initMoment.hour() + initMoment.minute()/60.0,
            };
            this.staffTimeSheetForm[key][jobDate] = {
              startTime: initMoment,
              endTime: initMoment,
            };
            this.staffTimeSheetDisplay[key][jobDate] = {
              startTime: initMoment.format("HH:mm"),
              endTime: initMoment.format("HH:mm"),
            };
            dayOffset = dayOffset + 1;
          }

          // alway return false, unreachable code
          if (this.isClosedDate(jobDate) && oldStaffTimeSheet[key] && oldStaffTimeSheetForm[key] && oldStaffTimeSheetDisplay[key]) {
            this.staffTimeSheet[key][jobDate] = oldStaffTimeSheet[key][jobDate];
            this.staffTimeSheetForm[key][jobDate] = oldStaffTimeSheetForm[key][jobDate];
            this.staffTimeSheetDisplay[key][jobDate] = oldStaffTimeSheetDisplay[key][jobDate];
            this.timesheetTooltip[key][jobDate] = oldTimesheetTooltip[key][jobDate];
          }
        }
      }
    }
    this.countChargeableHours();
  }

  // initTimeSheetValues() {
  updateWorkPeriod() {
    if (!this.timeSheetRecords) {
      return this.resetWorkPeriod();
    }

      this.taskNumber = {};
      this.staffTimeSheet = {};
      this.staffTimeSheetForm = {};
      this.staffTimeSheetDisplay = {};
      this.timesheetTooltip = {};

      let jobStartMoment = moment(this.jobCard.startTime).set({
        minute: Math.floor(moment(this.jobCard.startTime).minute() / 15.0) * 15,
        second: 0,
      });
      

      let officerDict = {}; //key post; obj: officer
      for(let officer of this.timeSheetRecords.officerArray){
        let key = officer.post +"_" + officer.personnelNumber;
        officerDict[key] = officer;
      }

      // for (let p=0; p<this.assignedPersons.length; p++) {
      for(let postName in this.employeeSummaryDict) {
        for (let employee of this.getDistinctEmployee(postName)) {
          let key = postName + "_" + employee.employeeCode;
          this.taskNumber[key] = "";
          this.staffTimeSheet[key] = {};
          this.staffTimeSheetForm[key] = {};
          this.staffTimeSheetDisplay[key] = {};
          this.timesheetTooltip[key] = {};

          if (key in officerDict) {
            let officer = officerDict[key];
            this.taskNumber[key] = officer.taskNumber;
            
            let workPeriodDict = {}
            for (let period of officer.workPeriodArray) {
              let workPeriodDate = moment(period.startTime).format("YYYY-MM-DD");
              workPeriodDict[workPeriodDate] = period;
            }
            let jobMoment = null;
            for( let jobDate of this.jobPeriodList) {
              jobMoment = moment(jobDate, "YYYY-MM-DD");

              if (this.employeeWorkDate[key].includes(jobDate)) {
                if (jobDate in workPeriodDict) {
                  // have this date record
                  let record = workPeriodDict[jobDate];
                  this.timesheetTooltip[key][jobDate] = {
                    show: (!record.isSuccess && record.messageText),
                    message: record.messageText
                  };
                  let period = workPeriodDict[jobDate];
                  let startMoment = moment(period.startTime).set({
                    minute: Math.floor(moment(period.startTime).minute() / 15.0) * 15,
                    second: 0,
                  });
                  let endMoment = moment(period.endTime).set({
                    minute: Math.ceil(moment(period.endTime).minute() / 15.0) * 15,
                    second: 0,
                  });
      
                  this.staffTimeSheet[key][jobDate] = {
                    startTime: startMoment.hour() + startMoment.minute()/60.0, // convert to base 10
                    endTime: endMoment.hour() + endMoment.minute()/60.0,
                  };
                  this.staffTimeSheetForm[key][jobDate] = {
                    startTime: startMoment, 
                    endTime: endMoment,
                  };
                  this.staffTimeSheetDisplay[key][jobDate] = {
                    startTime: startMoment.format("HH:mm"),
                    endTime: endMoment.format("HH:mm"),
                  };

                  if (endMoment.format("YYMMDD") > startMoment.format("YYMMDD")) {
                    this.staffTimeSheet[key][jobDate].endTime = 24;
                    this.staffTimeSheetDisplay[key][jobDate].endTime = '24:00';
                  }
                
                } else {
                  // no this date record
                  this.timesheetTooltip[key][jobDate] = {
                    show: true,
                    message: this.translate('pages.timesheet.no-timesheet-tooltip')
                  };
                  this.staffTimeSheet[key][jobDate] = {
                    startTime: jobStartMoment.hour() + jobStartMoment.minute()/60.0, // convert to base 10
                    endTime: jobStartMoment.hour() + jobStartMoment.minute()/60.0,
                  };
                  this.staffTimeSheetForm[key][jobDate] = {
                    startTime: jobMoment.set({hour:jobStartMoment.hour(), minute:jobStartMoment.minute()}),
                    endTime: jobMoment.set({hour:jobStartMoment.hour(), minute:jobStartMoment.minute()}),
                  };
                  this.staffTimeSheetDisplay[key][jobDate] = {
                    startTime: jobStartMoment.format("HH:mm"),
                    endTime: jobStartMoment.format("HH:mm"),
                  };
                }
              }
            }
          } else {
            // no timesheet
            this.taskNumber[key] = '';
            
            for( let jobDate of this.jobPeriodList) {
              let jobDateMoment = moment(jobDate, "YYYY-MM-DD");

              this.timesheetTooltip[key][jobDate] = {
                show: true,
                message: this.translate('pages.timesheet.no-timesheet-tooltip')
              };
              this.staffTimeSheet[key][jobDate] = {
                startTime: jobStartMoment.hour() + jobStartMoment.minute()/60.0, // convert to base 10
                endTime: jobStartMoment.hour() + jobStartMoment.minute()/60.0,
              };
              this.staffTimeSheetForm[key][jobDate] = {
                startTime: jobDateMoment.set({hour:jobStartMoment.hour(), minute:jobStartMoment.minute()}),
                endTime: jobDateMoment.set({hour:jobStartMoment.hour(), minute:jobStartMoment.minute()}),
              };
              this.staffTimeSheetDisplay[key][jobDate] = {
                startTime: jobStartMoment.format("HH:mm"),
                endTime: jobStartMoment.format("HH:mm"),
              };
            }
          }
        };
      }
      this.countChargeableHours();
  }

  initJobInfo() {
    if(this.jobCard.completionTime){
      let jobDuration = Math.floor(moment(this.jobCard.completionTime.toString()).diff(moment(this.jobCard.startTime.toString()), 'minutes') / 60);
      this.uiJobDuration = jobDuration.toString();
    }else{
      this.uiJobDuration = '';
    }
  }

  initReassignStaffPanel(isClear = true) {
    if (this.showAllPostForReassignStaff && this.workCentrePostList == null) {
      this.requestWorkCentrePostList();
      return;
    }

    let assignedPersons = [];
    let officerInCharge = null;
    let teamMembers = this.showAllPostForReassignStaff ? this.workCentrePostList : this.jobCard.teamMembers;

    if (isClear) {
      assignedPersons = this.jobCard.assignedPersons;
      officerInCharge = this.jobCard.officerInCharge;
      this.selectedAssignPersons.assignedPersons = assignedPersons;
      this.selectedAssignPersons.officerInCharge = officerInCharge;
    } else {
      assignedPersons = this.selectedAssignPersons.assignedPersons;
      officerInCharge = this.selectedAssignPersons.officerInCharge;
    }
    teamMembers = Array.from(new Set(teamMembers.concat(this.selectedAssignPersons.assignedPersons)));

    this.assignStaffFormParam = {
      teamMembers: teamMembers,
      assignedPersons: assignedPersons,
      officerInCharge: officerInCharge,
      onSubmitClicked: this.onAssignStaffSubmitClicked,
      onAddMemberClicked: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      },
      onRemoveMemberClicked: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      },
      onOfficerInChargeChanged: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      }
    };
  }

  // calculate the job period, have to call after the job period is updated
  officeHoursMap() {
    let list = this.SnTeamData.operationPeriod.operationPeriod;
    this.jobPeriodList = [];
    let jcStartMoment = moment(this.jobCard.startTime).startOf('day');
    let jcEndMoment = this.jobCard.completionTime? moment(this.jobCard.completionTime).startOf('day'): moment().startOf('day');
    this.jobPeriod = jcEndMoment.diff(jcStartMoment, 'day') + 1;
    
    for(let d=0; d < this.jobPeriod; d++){
      let date = moment(this.jobCard.startTime).add(d, 'day').format('YYYY-MM-DD');
      this.jobPeriodList.push(date);
      this.officeHours[date] = list.find( list => list.startDayOfWeek == moment(date).weekday());
    }
  }

  fillOfficeHours(){
    let oldStaffTimeSheet = Object.assign({}, this.staffTimeSheet);
    let oldStaffTimeSheetForm = Object.assign({}, this.staffTimeSheetForm);
    let oldStaffTimeSheetDisplay = Object.assign({}, this.staffTimeSheetDisplay);
    let oldTimesheetTooltip = Object.assign({}, this.timesheetTooltip);

    this.staffTimeSheet = {};
    this.staffTimeSheetForm = {};
    this.staffTimeSheetDisplay = {};
    this.timesheetTooltip = {};

    for (let postName in this.employeeSummaryDict) {
      for (let employee of this.getDistinctEmployee(postName)) {
        let key = postName + "_" + employee.employeeCode;
        this.staffTimeSheet[key] = {};
        this.staffTimeSheetForm[key] = {};
        this.staffTimeSheetDisplay[key] = {};
        this.timesheetTooltip[key] = {};

        
        for (let jobDate of this.jobPeriodList) {
          if (this.employeeWorkDate[key].includes(jobDate)) {
            let officerHour = this.officeHours[jobDate];
            this.timesheetTooltip[key][jobDate] = {
              show: true,
              message: this.translate('pages.timesheet.no-timesheet-tooltip')
            };
            let startTime;
            let endTime;
            let endTimeStep = 0; // Handle 24:00
            let endTimeDisplay = "00:00"; // Handle 24:00

            let jobStartTime = moment(this.jobCard.startTime).set({
              minute: Math.floor(moment(this.jobCard.startTime).minute() / 15.0) * 15,
              second: 0,
            });
            let jobEndTime = moment(this.jobCard.completionTime).set({
              minute: Math.ceil(moment(this.jobCard.completionTime).minute() / 15.0) * 15,
              second: 0,
            });
            
            if (jobStartTime.isSame(jobEndTime, 'day')) { // one day only
              startTime = jobStartTime;
              endTime = jobEndTime;
            } else { // more than 1 day
              let firstDay = moment(this.jobCard.startTime).format("YYYY-MM-DD");
              let lastDay = moment(this.jobCard.completionTime).format("YYYY-MM-DD");
              let teamStartTime = moment(jobDate).set({
                hour: parseInt(officerHour.startTime.split(":")[0]),
                minute: Math.floor(parseInt(officerHour.startTime.split(":")[1]) / 15.0) * 15,
              });
              let teamEndTime = moment(jobDate).set({
                hour: parseInt(officerHour.endTime.split(":")[0]),
                minute: Math.ceil(parseInt(officerHour.endTime.split(":")[1]) / 15.0) * 15,
              });

              if (jobDate == firstDay) {  // 1st day
                startTime = jobStartTime;
                if (jobStartTime.isSameOrBefore(teamEndTime)) {
                  endTime = teamEndTime;
                } else {
                  endTime = jobStartTime;
                }
              } else if (jobDate == lastDay) { // last day
                endTime = jobEndTime;
                if (jobEndTime.isSameOrBefore(teamStartTime)) {
                  startTime = jobEndTime;
                } else {
                  startTime = teamStartTime;
                }
              } else { // next day but not the last day
                startTime = teamStartTime;
                endTime = teamEndTime;
              }
            }

            // Start time and end time should be same day. Range: 00:00-24:00
            // Handle team end time 2400 convert to next day 0000 case (caused by momentjs)
            if (startTime.isSame(endTime, 'day')) {
              endTimeStep = endTime.hour() + endTime.minute() / 60.0;
              endTimeDisplay = endTime.format("HH:mm");
            } else {
              endTimeStep = 24;
              endTimeDisplay = "24:00";
            }

            this.staffTimeSheet[key][jobDate] = {
              startTime: startTime.hour() + startTime.minute() / 60.0,
              endTime: endTimeStep,
            };
            this.staffTimeSheetForm[key][jobDate] = {
              startTime: startTime,
              endTime: endTime,
            };
            this.staffTimeSheetDisplay[key][jobDate] = {
              startTime: startTime.format("HH:mm"),
              endTime: endTimeDisplay,
            };
          }

          // alway return false, unreachable code
          if (this.isClosedDate(jobDate) && oldStaffTimeSheet[key] && oldStaffTimeSheetForm[key] && oldStaffTimeSheetDisplay[key]) {
            this.staffTimeSheet[key][jobDate] = oldStaffTimeSheet[key][jobDate];
            this.staffTimeSheetForm[key][jobDate] = oldStaffTimeSheetForm[key][jobDate];
            this.staffTimeSheetDisplay[key][jobDate] = oldStaffTimeSheetDisplay[key][jobDate];
            this.timesheetTooltip[key][jobDate] = oldTimesheetTooltip[key][jobDate];
          }
        }
      }
    }
    this.countChargeableHours();
  }

  countChargeableHours(){
    let chargeableMinute = 0; // in minute
    // for (let p=0; p<this.assignedPersons.length; p++) {
    for (let postName in this.employeeSummaryDict) {
      for (let employee of this.getDistinctEmployee(postName)) {
        let key = postName + "_" + employee.employeeCode;
        for( let jobDate of this.jobPeriodList) {

          if ( jobDate in this.staffTimeSheetForm[key]) {
            let workTime = this.staffTimeSheetForm[key][jobDate];
            // total in minute
            let total = (workTime.endTime).diff(workTime.startTime, 'minute');
            if (total > 0) { 
              chargeableMinute += total + this.bufferTimeValue * 60;
            }
          }
        }
      }
    };
    this.displayHour = Math.floor(chargeableMinute/60.0);
    this.displayMinutes = chargeableMinute%60;
    this.isLoading = false;
  }

  //===========================================================================
  // api function
  async requestJobCard() {
    const request = new JM.JMRequestJobCardsJobCardSummary();
    request.jobCardNumberList = [this.selectedJobCardId];
    request.parameters = [
      "version",
      "jobCardNumber",
      "snNumber",
      "status",
      "startTime",
      "completionTime",
      "teamMembers",
      "assignedPersons",
      "officerInCharge",
      "orderType",
      "workCentre",
      "snWorkCentre",
      "snTeamId",
      "ccsServiceOrderNumber",
      "soObtainedByCcep",
      "freezedActionList",
    ];
    request.includeSummary = true;

    const response: JM.JMResponseJobCardsJobCardSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.jobCard = response.payload.records[0];

    // view only if action is freezed
    if (this.jobCard.freezedActionList && this.jobCard.freezedActionList.includes("updateTimesheetSubmitStatus")) {
      this.viewOnly = true;
    }

    // view only if designated CCS SO
    if (!this.jobCard.soObtainedByCcep) {
      this.viewOnly = true;
      AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.timesheet.alert-msg.designated-so"), "", [{
        name: JMLanguage.translate("global.ok")
      }]);
    } 

    // view only if job date is closed
    if (this.jobCard && this.jobCard.startTime && this.jobCard.completionTime) {
      let jobStartDate = moment(this.jobCard.startTime).utc().add(8, 'hour').format("YYYY-MM-DD");
      let jobEndDate = moment(this.jobCard.completionTime).utc().add(8, 'hour').format("YYYY-MM-DD");

      if (this.isClosedDate(jobStartDate) && this.isClosedDate(jobEndDate)) {
        this.viewOnly = true;
      }
    } else {
      this.viewOnly = true;
    }

    // Only "ZS01 job" can access this page
    if (this.jobCard.orderType !== OrderType.SLA_JOB) {
      this.router.navigate(['']);
    }

    this.selectedJobNumber = this.jobCard.snNumber;
    this.assignStaffFormParam.teamMembers = this.jobCard.teamMembers;

    // check work centre enable timesheet first
    this.requestworkCentreAttribute();
  }

  async requestworkCentreAttribute() {
    if (!this.jobCard.workCentre) return;

    const request = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.jobCard.workCentre];
    request.parameters = ['enableTimesheet'];
    request.includeSummary = true;

    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let workCentreAttribute = {} as any;
    if (response.payload.records.length > 0) {
      workCentreAttribute = response.payload.records[0];
    }

    if (!workCentreAttribute.enableTimesheet) {
      this.openSnackBar(this.translate("error.no-work-centre"));
      this.router.navigate(['']);
      return;
    }

    // init timesheet page
    if (this.jobCard.snNumber) {
      this.requestSn();
    } else {
      this.snTeamId = this.jobCard.snTeamId;
      const request = new JM.JMRequestTeamsTeamSummary();
      request.idList = [this.snTeamId];
      
      AppDelegate.sendJMRequest(request).then((response) => {
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }
        if (Array.isArray(response.payload.records) && response.payload.records.length > 0) {
          this.SnTeamData = response.payload.records[0];
          this.officeHoursMap();
          this.requestPersonsId();
        }
      });
    }
    
    this.initBufferTimeSlider();
    this.initActionButtons();
    this.initJobInfo();
  }

  async requestSn() {
    const request = new JM.JMRequestSnSummary();
    request.snNumberList = [this.jobCard.snNumber];
    request.parameters = ["team"];

    const response: JM.JMResponseSnSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.totalCount > 0 && response.payload.records) {
      this.snTeamId = response.payload.records[0].team._id;
          
      this.SnTeamData = response.payload.records[0].team;
      this.officeHoursMap();
      this.requestPersonsId();
    }
  }

  async requestWorkCentrePostList() {
    this.assignStaffFormParam.isLoading = true

    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = 'CCEPJM';
    request.authorizations = {'workCenters': this.jobCard.workCentre};
    request.active = JMENUM.RequestActive.ACTIVE;

    const response = await AppDelegate.sendJMRequest(request);
    this.assignStaffFormParam.isLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.showAllPostForReassignStaff = false;
      return;
    }

    if (!response.payload.records || !response.payload.records.length) {
      this.showAllPostForReassignStaff = false;
      return;
    }

    this.workCentrePostList = response.payload.records.map(post => post.name);
    this.initReassignStaffPanel(false);
  }

  async requestPersonsId() {
    const request = new JM.JMRequestEmployeesEmployeeSummary();
    request.start = this.jobCard.startTime;
    request.end = this.jobCard.completionTime;
    request.postNameList = this.jobCard.assignedPersons;

    this.employeeSummaryDict = {};
    this.employeeWorkDate = {};

    const response:JM.JMResponseEmployeesEmployeeSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let employeeSummary:{
        postCode: string,
        employees: JMOBJ.Employee[],
        postName: string,
    } = null;

    for (employeeSummary of response.payload.records) {
      this.employeeSummaryDict[employeeSummary.postName] = employeeSummary;
      
      for (let employee of employeeSummary.employees) {
        let validFromMoment = moment(employee.validDayFrom, "YYYYMMDD");
        let validToMoment = employee.validDayTo? moment(employee.validDayTo, "YYYYMMDD"): null;
        let key = employeeSummary.postName + "_" + employee.employeeCode;

        if ((key in this.employeeWorkDate) == false) {
          this.employeeWorkDate[employeeSummary.postName + "_" + employee.employeeCode] = []
        }
        
        for (let jobDate of this.jobPeriodList) {  
          let jobDateMoment = moment(jobDate, "YYYY-MM-DD");
          if ( validFromMoment <= jobDateMoment && (validToMoment == null || jobDateMoment <= validToMoment)) {
            this.employeeWorkDate[employeeSummary.postName + "_" + employee.employeeCode].push(jobDate);
          }
        }
      }
    }
    this.updateWorkPeriod();
  }

  // check if there is a timesheet already
  async requestTimeSheetId(){
    this.isLoading = true; // will turn after got the last request
    const request = new JM.JMRequestTimesheetGetTimesheeByJobCardNumber();
    request.jobCardNumber = this.selectedJobCardId;

    const response: JM.JMResponseTimesheetGetTimesheeByJobCardNumber = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload) { // have timesheet
      this.timeSheetRecords = response.payload;
      this.bufferTimeValue = this.timeSheetRecords.bufferTime / 60;
    } else {
      this.timeSheetRecords = null;
      this.bufferTimeValue = 0;
    }
    this.requestJobCard();
  }

  async requestSaveTimeSheet() {
    if (this.timeSheetRecords) { // update
      let officerArray:JMOBJ.TimesheetOfficer[]  = [];

      for (let postName in this.employeeSummaryDict) {
        for (let employee of this.getDistinctEmployee(postName)) {
          let key = postName + "_" + employee.employeeCode;
          let officer: JMOBJ.TimesheetOfficer = new JMOBJ.TimesheetOfficer();
          officer.post = postName; 
          officer.personnelNumber = employee.employeeCode;
          officer.activityType = employee.activityType;
          officer.taskNumber = this.taskNumber[key];
          officer.costCentre = employee.costCentre;

          const oldOfficer = this.timeSheetRecords.officerArray.find(officer => officer.post == postName && officer.personnelNumber == employee.employeeCode);
          if (oldOfficer) {
            let newWorkPeriod:{
              confirmationCounter   : string,
              isSuccess             : boolean,
              messageText           : string,
              startTime             : Date,
              endTime               : Date,
            }[]  = Object.values(this.staffTimeSheetForm[key])
            let period: {
                confirmationCounter : string,
                isSuccess           : boolean,
                messageText         : string,
                startTime           : Date,
                endTime             : Date,
            };
            for (period of newWorkPeriod) { // copy back the "other" data from timesheet to new
              // find out old period
              for (let oldPeriod of oldOfficer.workPeriodArray) {
                let oldStartDate = moment(oldPeriod.startTime).format("YYYY-MM-DD");
                let newStartDate = moment(period.startTime).format("YYYY-MM-DD");
                if ( oldStartDate == newStartDate ) {
                  // update
                  period.confirmationCounter = oldPeriod.confirmationCounter;;
                  period.isSuccess = oldPeriod.isSuccess;
                  period.messageText = oldPeriod.messageText;
                  period.startTime = this.staffTimeSheetForm[key][oldStartDate].startTime;
                  period.endTime = this.staffTimeSheetForm[key][oldStartDate].endTime;
                  break;
                }
              }
            }
            officer.workPeriodArray = newWorkPeriod;
          } else { // No timesheet record
            officer.workPeriodArray = Object.values(this.staffTimeSheetForm[key]);
          }
          officerArray.push(officer);
        }
      };
      
      const request:JM.JMRequestTimesheetUpdateTimesheet = new JM.JMRequestTimesheetUpdateTimesheet();
      request.timesheetId = this.timeSheetRecords._id;
      request.bufferTime = this.bufferTimeValue * 60;
      request.officerArray = officerArray; 

      const response: JM.JMResponseTimesheetUpdateTimesheet = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code) {
        AppDelegate.openErrorBar(response);
        return;
      }

      switch (response.code) {
        case 200:
          // this.noTimeSheet.fill(false);
          this.timeSheetRecords = response.payload;
          this.updateWorkPeriod();
          this.openSnackBar(this.translateService.instant("pages.timesheet.saved"));
          break;
        case 19009: // Cannot update timesheet in CCS
        case 19010: // Partially saved 
          if (response.payload && response.payload._id) {
            this.timeSheetRecords = response.payload;
            this.updateWorkPeriod();
            const failCount = this.getFailTimesheetCount(response.payload.officerArray);
            this.openSnackBar(this.translate("pages.timesheet.error.fail-upload-timesheet", [failCount]));
          } else {
            this.openErrorBar(response);
          }
          break;
        default:
          this.openErrorBar(response);
      }

    } else { // create
      let officerArray:JMOBJ.TimesheetOfficer[]  = [];
      for (let postName in this.employeeSummaryDict) {
        for (let employee of this.getDistinctEmployee(postName)) {
          let key = postName + "_" + employee.employeeCode;
          let officer: JMOBJ.TimesheetOfficer = new JMOBJ.TimesheetOfficer();
          officer.post = postName; 
          officer.personnelNumber = employee.employeeCode;
          officer.activityType = employee.activityType;
          officer.taskNumber = this.taskNumber[key];
          officer.costCentre = employee.costCentre;
          officer.workPeriodArray = Object.values(this.staffTimeSheetForm[key]);
          officerArray.push(officer);
        }
      };
      
      const request = new JM.JMRequestTimesheetCreateTimesheet();
      request.jobCardNumber = this.jobCard.jobCardNumber;
      request.jobType = this.jobCard.orderType;
      request.workCentre = this.jobCard.workCentre;
      request.bufferTime = this.bufferTimeValue * 60;
      request.ccsServiceOrderNumber = this.jobCard.ccsServiceOrderNumber;
      request.officerArray = officerArray;

      const response: JM.JMResponseTimesheetUpdateTimesheet = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code) {
        AppDelegate.openErrorBar(response);
        return;
      }

      switch (response.code) {
        case 200:
          // this.noTimeSheet.fill(false);
          this.timeSheetRecords = response.payload;
          this.updateWorkPeriod();
          this.openSnackBar(this.translateService.instant("pages.timesheet.saved"));
          break;
        case 19008: // Cannot create timesheet in CCS
        case 19010: // Partially saved 
          if (response.payload && response.payload._id) {
            this.timeSheetRecords = response.payload;
            this.updateWorkPeriod();
            const failCount = this.getFailTimesheetCount(response.payload.officerArray);
            this.openSnackBar(this.translate("pages.timesheet.error.fail-upload-timesheet", [failCount]));
          } else {
            this.openErrorBar(response);
          }
          break;
        default:
          this.openErrorBar(response);
      }
    }
  }

  private getFailTimesheetCount(officerArray: any): number {
    let count = 0;
    for (const officer of officerArray) {
      for (const workPeriod of officer.workPeriodArray) {
        if (!workPeriod.isSuccess || workPeriod.isSuccess === false) count++;
      }
    }
    return count;
  }

  //==================================================================
  // UI function

  initActionButtons(): void {
    this.actionButtonData = [];

    if (this.viewOnly) {
      this.addActionBtn(ActionButtonTimeSheet.exit);
      return;
    }

    this.addActionBtn(ActionButtonTimeSheet.save);
    this.addActionBtn(ActionButtonTimeSheet.reset);
    this.addActionBtn(ActionButtonTimeSheet.autoFill);
    if(this.jobCard.status == JMENUM.JobCardStatus.IN_PROGRESS){
      this.addActionBtn(ActionButtonTimeSheet.addStaff);
    }
    this.addActionBtn(ActionButtonTimeSheet.exit);
  }

  addActionBtn(buttonStatus: ActionButtonTimeSheet): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.timeSheet][buttonStatus];
    let buttonHandler = () => {};

    switch (buttonStatus) {
      case ActionButtonTimeSheet.save:
        buttonHandler = () => {
          this.requestSaveTimeSheet();
        };
        break;
      case ActionButtonTimeSheet.reset:
        buttonHandler = () => { 
          this.resetWorkPeriod()
        };
        break;
      case ActionButtonTimeSheet.autoFill:
        buttonHandler = () => { 
          this.fillOfficeHours();
        };
        break;
      case ActionButtonTimeSheet.exit:
        buttonHandler = () => { 
          this.router.navigate(["/job-card/view/", this.selectedJobCardId]);
        };
        break;
      case ActionButtonTimeSheet.addStaff:
        buttonHandler = () => {
          this.initReassignStaffPanel(true);
          this.assignStaffPanel.toggle();
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ? 
              actionButton.buttons[0].name : this.translateService.instant("global.yes"),
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ? 
              actionButton.buttons[1].name : this.translateService.instant("global.no"),
        handler: () => {this.isLoading = false}
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = this.translateService.instant(button.name);
      })
      this.showPopUpAlert(this.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  onChangeBufferTime(){
    this.countChargeableHours();
  }

  onJobNumberClicked(){
    this.router.navigate(["/job-card/view/", this.selectedJobCardId]);
  }

  // call this function if input changes
  // then call onTimesheetSliderChanged
  // point: start|end
  // dayType: startDay|otherDay|endDay
  onChangeTimeSheet = (point:string, postName:string, employeeCode:string, day:string, dayType:string) => {
    let key = postName + "_" + employeeCode;
    let startHour = parseInt(this.staffTimeSheetDisplay[key][day].startTime.split(":")[0]);
    let startMinute = parseInt(this.staffTimeSheetDisplay[key][day].startTime.split(":")[1]);
    let endHour = parseInt(this.staffTimeSheetDisplay[key][day].endTime.split(":")[0]);
    let endMinute = parseInt(this.staffTimeSheetDisplay[key][day].endTime.split(":")[1]);
    this.staffTimeSheet[key][day] = {
      startTime: startHour + startMinute/60.0,
      endTime: endHour + endMinute/60.0,
    };
    this.timeRangeSliderArray.toArray()[key].onChangeTimeSheet(point, postName, employeeCode, day, dayType);
  }

  updatStaffTimeSheetForm(postName:string, employeeCode:string, day:string, point:string) {
    let key = postName + "_" + employeeCode;
    if (point == 'start') {
      this.staffTimeSheetForm[key][day].startTime = moment(this.staffTimeSheetForm[key][day].startTime).set({
        year: moment(day).year(),
        month: moment(day).month(),
        date: moment(day).date(),
        hour: parseInt(this.staffTimeSheetDisplay[key][day].startTime.split(":")[0]),
        minute: parseInt(this.staffTimeSheetDisplay[key][day].startTime.split(":")[1]),
      });
    } else {
      this.staffTimeSheetForm[key][day].endTime = moment(this.staffTimeSheetForm[key][day].endTime).set({
        year: moment(day).year(),
        month: moment(day).month(),
        date: moment(day).date(),
        hour: parseInt(this.staffTimeSheetDisplay[key][day].endTime.split(":")[0]),
        minute: parseInt(this.staffTimeSheetDisplay[key][day].endTime.split(":")[1]),
      });
    }
      
    this.countChargeableHours();
  }
  
  // call this function if slider changes
  onTimesheetSliderChanged = (point:string, postName:string, employeeCode:string, day:string) => {
    let key = postName + "_" + employeeCode;
    this.timesheetTooltip[key][day] = {
      show: true,
      message: this.translate('pages.timesheet.no-timesheet-tooltip')
    };
    if (point == 'start') {
      let startTime:number  = this.staffTimeSheet[key][day].startTime;
      let hh_str = this.valueToTimeFormat(startTime).split(":")[0];
      let mm_str = this.valueToTimeFormat(startTime).split(":")[1];
      
      let startMoment = moment(day + " " + hh_str + ":" + mm_str, "YYYY-MM-DD HH:mm");
      let endMoment = moment(day + " " + this.staffTimeSheetDisplay[key][day].endTime, "YYYY-MM-DD HH:mm");
      let earliestMoment = moment(this.jobCard.startTime).subtract(4, 'hour');
      earliestMoment.set({
        minute: Math.floor(earliestMoment.minute()/15.0)*15
      });

      if (startMoment < earliestMoment) {
        let timeValue = earliestMoment.hour() +  earliestMoment.minute()/60.0;
        this.staffTimeSheetDisplay[key][day].startTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].startTime = timeValue;
        this.openSnackBar(this.translateService.instant("pages.timesheet.start-time-max"));

      } else if (startMoment > endMoment) {
        this.staffTimeSheetDisplay[key][day].startTime = this.staffTimeSheetDisplay[key][day].endTime;
        this.staffTimeSheet[key][day].startTime = this.staffTimeSheet[key][day].endTime;

      } else {
        this.staffTimeSheetDisplay[key][day].startTime = this.valueToTimeFormat(startTime);
      }

    } else if (point == 'end') {
      let endTime:number = this.staffTimeSheet[key][day].endTime;
      let hh_str = this.valueToTimeFormat(endTime).split(":")[0];
      let mm_str = this.valueToTimeFormat(endTime).split(":")[1];
      
      let startMoment = moment(day + " " + this.staffTimeSheetDisplay[key][day].startTime, "YYYY-MM-DD HH:mm");
      let endMoment = moment(day + " " + hh_str + ":" + mm_str, "YYYY-MM-DD HH:mm");
      let latestMoment = this.jobCard.completionTime? moment(this.jobCard.completionTime).add(4, 'hour'): null;
      if (latestMoment) {
        latestMoment.set({
          minute: Math.ceil(latestMoment.minute()/15.0)*15
        });
      }

      if (latestMoment && endMoment > latestMoment) {
        let timeValue = latestMoment.hour() +  latestMoment.minute()/60.0;
        this.staffTimeSheetDisplay[key][day].endTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].endTime = timeValue;
        this.openSnackBar(this.translateService.instant("pages.timesheet.end-time-max"));

      } else if (endMoment < startMoment) {
        this.staffTimeSheetDisplay[key][day].endTime = this.staffTimeSheetDisplay[key][day].startTime;
        this.staffTimeSheet[key][day].endTime = this.staffTimeSheet[key][day].startTime;

      } else {
        this.staffTimeSheetDisplay[key][day].endTime = this.valueToTimeFormat(endTime);
      }
    }
    this.updatStaffTimeSheetForm(postName, employeeCode, day, point);
  };
  

  onKeyUpTime(event, postName:string, employeeCode:string, day:string, point:string) {
    // if input valid -> update slider display and form value
    let key = postName + "_" + employeeCode;

    // set not syn
    this.timesheetTooltip[key][day] = {
      show: true,
      message: this.translate('pages.timesheet.no-timesheet-tooltip')
    };
    
    if (point == 'start') {
      let startTime:string  = this.staffTimeSheetDisplay[key][day].startTime; // in 12:45 format
      let hh_str = startTime.split(":")[0];
      let hh = parseInt(hh_str);
      let mm = Math.floor(parseInt(startTime.split(":")[1])/15.0)*15;
      let mm_str = mm.toString().padStart(2, '0');
      
      if (Number.isNaN(hh) || hh < 0 || hh > 24 ) {
        hh = 0;
        hh_str = "00";
      }
      if (Number.isNaN(mm) || mm < 0 || mm > 60 ) {
        mm = 0;
        mm_str = "00";
      }

      let startMoment = moment(day + " " + hh_str + ":" + mm_str, "YYYY-MM-DD HH:mm");
      let endMoment = moment(day + " " + this.staffTimeSheetDisplay[key][day].endTime, "YYYY-MM-DD HH:mm");
      let earliestMoment = moment(this.jobCard.startTime).subtract(4, 'hour');
      earliestMoment.set({
        "minute": Math.floor(earliestMoment.minute()/15.0)*15
      });

      if (startMoment < earliestMoment) {
        let timeValue = earliestMoment.hour() +  earliestMoment.minute()/60.0;
        this.staffTimeSheetDisplay[key][day].startTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].startTime = timeValue;
        this.openSnackBar(this.translateService.instant("pages.timesheet.start-time-max"));

      } else if (startMoment > endMoment) {
        this.staffTimeSheetDisplay[key][day].startTime = this.staffTimeSheetDisplay[key][day].endTime;
        this.staffTimeSheet[key][day].startTime = this.staffTimeSheet[key][day].endTime;

      } else {
        let timeValue = hh + mm/60.0;
        this.staffTimeSheetDisplay[key][day].startTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].startTime = timeValue;
      }

    } else if (point == 'end') {
      let endTime  = this.staffTimeSheetDisplay[key][day].endTime;
      let hh_str = endTime.split(":")[0];
      let hh = parseInt(hh_str);
      let mm = Math.ceil(parseInt(endTime.split(":")[1])/15.0)*15;
      let mm_str = mm.toString().padStart(2, '0');

      if (Number.isNaN(hh) || hh < 0 || hh > 24 ) {
        hh = 0;
        hh_str = "00";
      }
      if (Number.isNaN(mm) || mm < 0 || mm > 60 ) {
        mm = 0;
        mm_str = "00";
      }

      let startMoment = moment(day + " " + this.staffTimeSheetDisplay[key][day].startTime, "YYYY-MM-DD HH:mm");
      let endMoment = moment(day + " " + hh_str + ":" + mm_str, "YYYY-MM-DD HH:mm");
      let latestMoment = this.jobCard.completionTime? moment(this.jobCard.completionTime).add(4, 'hour'): null;
      if (latestMoment) {
        latestMoment.set({
          minute: Math.ceil(latestMoment.minute()/15.0)*15
        });
      }

      if (latestMoment && endMoment > latestMoment) {   // the job may be not finish yet 
        let timeValue = latestMoment.hour() +  latestMoment.minute()/60.0;
        this.staffTimeSheetDisplay[key][day].endTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].endTime = timeValue;
        this.openSnackBar(this.translateService.instant("pages.timesheet.end-time-max"));
      
      } else if (endMoment < startMoment) {
        this.staffTimeSheetDisplay[key][day].endTime = this.staffTimeSheetDisplay[key][day].startTime;
        this.staffTimeSheet[key][day].endTime = this.staffTimeSheet[key][day].startTime;

      } else {
        let timeValue = hh + mm/60.0;
        this.staffTimeSheetDisplay[key][day].endTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].endTime = timeValue;
      }
    }
    
    this.updatStaffTimeSheetForm(postName, employeeCode, day, point);
  }

  // data: 
  // assignedPersons: string[]
  // officerInCharge: string, post
  onAssignStaffSubmitClicked = (data) => {
    let request:JM.JMRequestJobCardsReassign = new JM.JMRequestJobCardsReassign();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.assignedPersons = data.assignedPersons;
    request.officerInCharge = data.officerInCharge;
    
    this.assignStaffPanel.isLoading = true;
    JM.JMConnector.sendJobCardsReassign(request, (error:JM.JMNetworkError, response:JM.JMResponseJobCardsReassign) => {
      this.assignStaffPanel.isLoading = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      // get the timesheet and job card again
      this.requestTimeSheetId();
      this.assignStaffPanel.toggle();  
    });
  }

  // TODO: reload language
  onLanguageChanged() {

  }


  //===========================================================================
  // custom function
  private getDistinctEmployee(postName:string):JMOBJ.Employee[] {
    let employeeArray = [];
    let codeArray = [];
    for (let employee of this.employeeSummaryDict[postName].employees) {
      if (codeArray.includes(employee.employeeCode) == false) {
        codeArray.push(employee.employeeCode);
        employeeArray.push(employee);
      }
    }
    return employeeArray;
  }

  // convert time in decmial to string time format
  // eg: 13.75 => 13:45
  private valueToTimeFormat(value: number): string {
    return Math.floor(value).toString().padStart(2, '0') + ":" + ((value%1)*60).toString().padStart(2, '0');
  }

  private getWeekdayTranslate(date): string {
    let weekday = '';
    switch (moment(date).weekday()) {
      case 0:
        weekday = 'global.sunday'
        break;
      case 1:
        weekday = 'global.monday'
        break;
      case 2:
        weekday = 'global.tuesday'
        break;
      case 3:
        weekday = 'global.wednesday'
        break;
      case 4:
        weekday = 'global.thursday'
        break;
      case 5:
        weekday = 'global.friday'
        break;
      case 6:
        weekday = 'global.saturday'
        break;
      default:
        break;
    }
    return this.translate(weekday);
  }

  isClosedDate(date) {
    return false;
    // let month =  moment(date, "YYYY-MM-DD").format("YYYYMM");
    // let thisMonth = moment(new Date()).utc().add(8, 'hour').format("YYYYMM");

    // return month < thisMonth;
  }

  isDisabledTaskNumber(workingDays) {
    if (!workingDays || !workingDays.length) {
      return true;
    }

    for (let date of workingDays) {
      if (!this.isClosedDate(date)) {
        return false;
      }
    }

    return true;
  }
}


  

