<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'sla'"></app-sidebar>

  <div id="content">
    <div class="row mx-0 " >
      <div class="col-xl-2 col-12 col-md-4 mb-4 mb-md-0 p-0">
        <div class="sla-list-wrap">
          <div class="heading-wrap d-flex justify-content-between mb-3">
            <h5>{{ 'pages.sla.sla-list-title' | translate }}</h5>
            <button type="button" *ngIf="hasEditionPermission" class="rectangle brand-blue add-sla-btn float-right" (click)="onAddSlaBtnClicked($event)">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class="sla-list pr-2">
              <ul class="list-group" >
                <li class="heading-li list-group-item d-flex justify-content-around align-items-center px-1">
                  <div class="col-3 font-size-xs">
                    {{ "pages.sla.th-sla-number" | translate }}
                  </div>
                  <div class="col-5 font-size-xs sla-days-col">
                    {{ "pages.sla.th-days-to-expire" | translate }}
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-around align-items-center px-1 clickable"
                  [class.active]=" sla.agreementNumber == this.selectedSla.agreementNumber "
                  attr.id="sla-list-{{sla.agreementNumber}}"
                  (click)="onSlaChanged(i)" *ngFor="let sla of slaArray; let i = index">
                  <div class="col-7 text-break">
                    <span class="list-sla-number">{{ sla.agreementNumber }}</span>
                     <i class="expired-icon icon-absolute fas fa-exclamation-circle" *ngIf="dayToExpiry(sla.contractEndDate) < 0"
                    [ngbTooltip]="expiredToolTip" placement="bottom" ></i> 
                  </div>
                  <div class="col-5 text-left">
                    <ng-container *ngIf="dayToExpiry(sla.contractEndDate) < 0">
                      {{ "pages.sla.sla-expired" | translate }}
                    </ng-container>
                    <ng-container *ngIf="dayToExpiry(sla.contractEndDate) >= 0">
                      {{ dayToExpiry(sla.contractEndDate) }}
                    </ng-container> 
                  </div>
                </li> 
              </ul> 
          </div>
        </div>
      </div>
      <div class="sla-detail-wrap col-xl-5 col-7 col-md-6 mt-10 pt-10 ">
        <ng-container *ngIf="isLoadingSlaDetails;">
          <div class="d-flex justify-content-center pt-5">
            <div class="spinner-border" role="status">
              <span class="sr-only">{{ "global.loading" | translate }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isLoadingSlaDetails">
          <ng-container *ngIf="!selectedSla">
            <div class="d-block">
              {{ 'global.no-record-found' | translate}}
            </div>
          </ng-container>
          <ng-container *ngIf="selectedSla">
            <div class="row d-flex justify-content-between ml-3 mb-md-0 p-0 mt-0">
              <div class="col-1 bold d-flex justify-content-right">
                <h5>{{ 'pages.sla.sla-number-placeholder' | translate }}</h5>
              </div>
              <div class="col-8">
                <ng-container *ngIf="selectedSla">
                  <h5>{{ selectedSla.agreementNumber }} </h5>
                </ng-container>
              </div>
              <div class="col-3"  
                [disableTooltip]="uiEnableRemoveButton"
                [ngbTooltip]="contractLinkedToTeamToolTip"
              >
                <button type="button" *ngIf="hasEditionPermission"
                  class="btn standard  float-right btn-lg px-0 py-0 "
                  (click)="onRemoveBtnClicked()"
                  [disabled]="!uiEnableRemoveButton"
                >
                <i class="fa fa-trash-o fa-lg" ></i>
                </button>
              </div>
            </div>
            <hr class="underline my-2 ml-4">
            
            <div class="row ml-3 mb-md-0 p-0 mt-1">
              <div class="col-7 bold">
                {{ 'pages.sla.responsible-party' | translate }}
              </div>
            </div>
            
            <div class="row ml-3 mb-md-0 p-0 mt-1">
                <div class="col-4">
                  {{ 'pages.sla.division' | translate }}
                </div>
                <div class="col-8">
                  <ng-container *ngIf="selectedSla">
                     {{ selectedSla.division }}  
                  </ng-container>
                </div>
            </div>
            <div class="row my-7 ml-3 mb-md-0 p-0 mt-1">
                <div class="col-4">
                  {{ 'pages.sla.contractStartDate' | translate }}
                </div>
                <div class="col-8">
                  <ng-container *ngIf="selectedSla">
                    {{  uiContractStartDate | date: 'yyyy-MM-dd' }}  
                  </ng-container>
                </div>
            </div>
            <div class="row my-7 ml-3 mb-md-0 p-0 mt-1">
              <div class="col-4">
                {{ 'pages.sla.contractSignedDate' | translate }}
              </div>
              <div class="col-8">
                <ng-container *ngIf="selectedSla">
                  {{  uiContractSignedDate | date: 'yyyy-MM-dd' }}  
                </ng-container>
              </div>
            </div>
            <div class="row my-7 ml-3 mb-md-0 p-0 mt-1">
              <div class="col-4">
                {{ 'pages.sla.contractEndDate' | translate }}
              </div>
              <div class="col-8">
                <ng-container *ngIf="selectedSla">
                  {{  uiContractEndDate | date: 'yyyy-MM-dd' }}  
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container> 
      </div> 
    
      

    </div>
    <!--Slider panel-->
    <ng-template #expiredToolTip>
      <div class="warning-tooltip-wrap text-center">
        {{ "pages.sla.expired-message" | translate }}
      </div>
    </ng-template>

    <custom-slider-panel #add_sla_panel [customClass]="'add_sla_panel'">
      <add-sla-form #addSlaForm [parameters]="addSlaFormParameters"></add-sla-form>
    </custom-slider-panel>

  </div>
</div>