import { Component, Input, OnChanges, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-upload-previewer',
  templateUrl: './upload-previewer.component.html',
  styleUrls: ['./upload-previewer.component.scss']
})

export class UploadPreviewerComponent implements OnInit, OnChanges {
  @ViewChild('upload_attachment_preview', { static: false }) uploadAttachmentPreview;

  @Input() parameters = {
    isDisabledAddAttachment: false,
    isDisabledDescription: false,
    isLoadingAddAttachment: false,
    hasEditPermission: false,
    isHaSn: false,
    userInput: {},
    onSubmitAttachment: () => {}
  }
  isShowUploadPreview: boolean = false;
  isPreviewerLoading: boolean = false;
  isFileOverSize: boolean = false;
  previewSize: string;
  

  constructor() { }

  ngOnInit() {}

  ngOnChanges(){
  }
  
  refreshSelectedPage(selectedPage: number){
    this.parameters['selectedPage'] = selectedPage
  }

  onClickAddAttachment(){
    this.isShowUploadPreview = !this.isShowUploadPreview;
  }

  // drag and drop handler
  onDraggedOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  onFilesDropped(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const uploadFiles: FileList = event.dataTransfer.files;
    this.onChangeFileInput(uploadFiles);
  }

  onChangeFileInput(files: FileList){
    if (files && files.length === 0) return;
    this.parameters.userInput['fileInput'] = files[0];
    this.previewSize = this.uploadAttachmentPreview.getFileSize(files[0].size);
    this.isFileOverSize = files[0].size > Constants.ATTACHMENT_SIZE;
    this.isFileOverSize && AppDelegate.openSnackBar(JMLanguage.translate("component.attachment.oversize"));
  }

  public closeUploadPreview(){
    this.isShowUploadPreview = false;
    this.parameters.userInput = {};
  }

}
