import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent implements OnInit, OnChanges {

  // select single file only
  @Input() clear?: string; // any change will trigger to remove file
  @Input() description: string = JMLanguage.translate('component.file-selector.drag-and-drop');
  @Input() fileAcceptType?: string; // .xlsx
  @Input() isDisabled: boolean = false;
  @Input() validator?: (file: any) => boolean = null;
  @Input() allowDownloadFiles: boolean = false;
  @Output() updatedFile: EventEmitter<File> = new EventEmitter();

  files?: File;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clear && !changes.clear.isFirstChange()) {
      this.files = undefined;
    }
  }

  //------------------------
  // button callback
  onDraggedOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  onFilesChanged(event: any) {
    const uploadFiles: FileList = event.target.files;
    if (uploadFiles.length > 0) {
      if(this.validator && !this.validator(uploadFiles[0])){
        return
      } 
      this.setFile(uploadFiles[0]);
    }
  }

  onFilesDropped(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const uploadFiles: FileList = event.dataTransfer.files;
    if (uploadFiles.length > 0) {
      if(this.validator && !this.validator(uploadFiles[0])){
        return
      } 
      this.setFile(uploadFiles[0]);
    }
  }

  onFileDelete() {
    this.resetFile();
  }

  downloadFile(file: File) {
    saveAs(file);
  }

  private resetFile() {
    this.setFile(undefined);
  }

  private setFile(file?: File) {
    this.files = file;
    this.updatedFile.emit(file);
  }

}
