import { Component, Input, OnInit } from '@angular/core';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import { JM, JMCONSTANT, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-jobcard-job-description',
  templateUrl: './jobcard-job-description.component.html',
  styleUrls: ['./jobcard-job-description.component.scss']
})
export class JobcardJobDescriptionComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() jobCard: JMOBJ.JobCard;
  @Input() componentParameters;
  @Input() pageMode: JMENUM.JMPageMode;

  // const for HTML use
  JobCardStatus = JMENUM.JobCardStatus;
  JMPriority = JMENUM.JMPriority;
  jobNatureEnum = JMENUM.JobNature;
  JMHandlingParty = JMENUM.HandlingParty;

  // Dropdown Options
  priorityOptions = [];
  orderTypeOptions = [];
  matOptions = [];

  // Validation 
  valid: boolean;
  errorFields: any = {};

  editable: any = {};
  mandatory: any = {};

  // Input Fields
  priority;
  orderType;
  matType;

  // Need formatting
  jobStatus: string;
  tecoStatus: string;
  teamName: string;
  assignedStaff: string;

  emptyHandleNa = JMLanguage.translate('component.display-text-field.na');
  emptyHandle = JMLanguage.translate('component.display-text-field.not-selected');

  constructor(
    private parameterService: ParameterService,
  ) { }

  ngOnInit() {
    if (this.jobCard.snTeamId) {
      this.requestTeam();
    }
    this.setValueFromJobCard();
    this.initPriorityOptions();
    this.initOrderTypeOptions();
    this.initMatOption();
    this.fieldControl();

    this.resetAllErrorFields();
    this.valid = false;
    this.assignedStaff = this.jobCard.assignedPersons ? this.jobCard.assignedPersons.length + JMLanguage.translate('component.job-description.persons') : undefined;
    this.tecoStatus = this.jobCard.tecoStatus ? JMLanguage.translate('teco.status.' + this.jobCard.tecoStatus) : undefined;
  }

  ngOnChanges() {
    const hasSnPendingApproval = this.sn && this.sn.pendingApproval;
    const translationKey = hasSnPendingApproval ? `sn.pending-approval.${this.sn.pendingApproval}` : `jobcard.status.${this.jobCard.status}`;

    this.jobStatus = JMLanguage.translate(translationKey);
    this.assignedStaff = this.jobCard.assignedPersons ? this.jobCard.assignedPersons.length + JMLanguage.translate('component.job-description.persons') : undefined;
    this.tecoStatus = this.jobCard.tecoStatus ? JMLanguage.translate('teco.status.' + this.jobCard.tecoStatus) : undefined;
  }

  private async requestTeam() {
    const request: JM.JMRequestTeamsTeamSummary = new JM.JMRequestTeamsTeamSummary();
    request.idList = [this.jobCard.snTeamId];

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    let team = response.payload.records[0];
    this.teamName = team ? team.name + " (" + team.workCentre + ")" : undefined;
  }

  //====== UI Functions ======//
  private setValueFromJobCard() {
    this.priority = this.jobCard.priority;
    this.orderType = this.jobCard.orderType;
    this.matType = this.jobCard.matType;
  }

  public onChangeOrderType(key, newValue) {
    this.matType = this.jobCard.matType = null;
    this.setMatOption(newValue);
    this.assignInputToValue(key, newValue);
  }

  public assignInputToValue(key, newValue) {
    this.resetErrorField(key);
    if (newValue) {
      this.jobCard[key] = newValue;
    }
    else {
      this.jobCard[key] = undefined;
      if (this.errorFields[key] != undefined) {
        this.errorFields[key] = true;
      }
    }
  }

  //====== Dropdown Options =======//
  private initPriorityOptions() {
    for (let id of this.parameterService.allSNPriority()) {
      this.priorityOptions.push({
        id: id,
        displayName: JMLanguage.translate("sn.priority." + id),
      });
    }
  }

  private initOrderTypeOptions() {
    const orderTypeSet = new Set();

    for (const mat of Object.values(JMCONSTANT.MatDictionary)) {
      if (mat.jobNatures.includes(this.jobCard.jobNature as JMENUM.JobNature)) {
        for (const orderType of mat.orderTypes) {
          orderTypeSet.add(orderType);
        }
      }
    }

    this.orderTypeOptions = Array.from(orderTypeSet);
  }

  private initMatOption() {
    if (!this.jobCard.orderType) return;
    this.setMatOption(this.jobCard.orderType);
  }

  private setMatOption(orderType) {
    const matList = Object.values(JMCONSTANT.MatDictionary);
    const jobFollowType = this.componentParameters.isFirstAttendedJobCard ? JMENUM.JobFollowType.FIRST_ATTEND : JMENUM.JobFollowType.FOLLOW_UP;

    this.matOptions = matList.filter(mat => {
      const hasJobCardMatType = mat.code == this.jobCard.matType;
      const matchJobCardMatType = mat.jobNatures.includes(this.jobCard.jobNature as JMENUM.JobNature) 
        && mat.orderTypes.includes(orderType)
        && mat.handlingParties.includes(this.jobCard.handlingParty)
        && mat.jobFollowTypes.includes(jobFollowType);

      return hasJobCardMatType || matchJobCardMatType;
    }).map(mat => mat.code).sort();
  }


  private fieldControl() {
    let fields = {};

    if (this.jobCard.jobNature != this.jobNatureEnum.ENQUIRY) {
      fields = {
        priority: true,
        orderType: true,
        matType: true
      };
      // disable OrderTypoe & matType if ccsServiceOrderNumber is obtained
      if (this.jobCard.ccsServiceOrderNumber) {
        fields = {
          priority: true,
          orderType: false,
          matType: false
        };
      }
      // disable orderType if this is follow up job card
      if (!this.componentParameters.isFirstAttendedJobCard) {
        fields['orderType'] = false;
      }
    } else if (this.jobCard.jobNature == this.jobNatureEnum.ENQUIRY) {
      fields = {
        priority: true,
        orderType: false,
        matType: false
      };
    }

    if (!this.isPageModeView) {
      // Edit Mode
      this.editable = { ...fields };
      this.mandatory = { ...fields };
    } else {
      // View Mode
      this.mandatory = { ...fields };
    }
  }

  //======= Validation =======//
  public validation() {
    let hasErrorField = false;
    let fields = {};
    if (!this.isPageModeView) {
      fields = { ... this.editable };
    } else {
      // View Mode
      fields = { ... this.mandatory };
    }

    // Check fields have data
    for (let field in fields) {
      if (fields[field] && this.mandatory[field]) {
        this.errorFields[field] = this.jobCard[field] ? false : true;
      }
    }
    for (let field in fields) {
      if (fields[field] && this.errorFields[field]) {
        hasErrorField = true;
      }
    }

    if (hasErrorField) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.error.msg.mandatory-field"));
    }

    this.valid = !hasErrorField;
    return this.valid;
  }

  public resetAllErrorFields() {
    this.valid = true;
    this.errorFields = {
      priority: false,
      orderType: false,
      matType: false,
    };
  }

  public resetErrorField(key) {
    if (this.errorFields[key] != undefined) {
      this.errorFields[key] = false;
    }
  }

  getDisplayJobType = () => {
    return this.jobCard.jobNature === JMENUM.JobNature.ENQUIRY
      ? JMLanguage.translate('jobcard.job-nature.' + JMENUM.JobNature.ENQUIRY)
      : this.jobCard.orderType;
  }

  get isPageModeCreate(){
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }

  get isPageModeEdit(){
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }

  get isPageModeView(){
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }

  get descriptionLabel(){
    return JMLanguage.translate(`jobcard.job-nature.${this.jobCard.jobNature}`);
  }
}
