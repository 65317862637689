import { Component, Input, Injector, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'schedule-table',
  templateUrl: './schedule-table.component.html',
  styleUrls: ['./schedule-table.component.scss']
})


export class ScheduleTableComponent implements OnInit, OnChanges  {
  @Input() isLoadingTable = false;
  @Input() teamList;
  @Input() colorTable;

  // UI Data 
  @Input() officeHourList;

  // Sevice
  private translateService: TranslateService;  

  labelShow :boolean = true;
  teamLabel : string;

  constructor(private injector: Injector) {
    this.translateService = injector.get(TranslateService);
  }

  ngOnInit() {

  }

  ngOnChanges(change:SimpleChanges) {
    this.updateSchedule();
  }

  showTeamSchedule(teamId){
    if(this.teamLabel == teamId){
      this.labelShow = true;
    } else{
      this.labelShow = false;
      this.teamLabel = teamId;
    }

    if(teamId == 'UpdateSchedule'){
      this.labelShow = true;
    }
  }

  public updateSchedule(){
    this.officeHourList = [];
    for(let i = 0; i < this.teamList.length; i++) {
      let team = this.teamList[i];
      // update office hour
      let teamOfficeHour = [
        [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
      ];

      for(let period of team.operationPeriod.operationPeriod) {
        let startDay = period.startDayOfWeek;
        let endDay = period.endDayOfWeek;
        let startTimeArr = period.startTime.split(":");
        let endTimeArr = period.endTime.split(":");
        if (period.startTime !== period.endTime && startTimeArr.length > 1 && endTimeArr.length > 1) {
          if (startDay == endDay) {
            let startHour = Math.min(parseInt(startTimeArr[0]),24);
            let startMin =  parseInt(startTimeArr[1]);
            let endHour = Math.min(parseInt(endTimeArr[0]),24);
            let endMin =  parseInt(endTimeArr[1]);
            if(endHour * 60 + endMin > startHour * 60 + startMin) {
              for(let j=startHour; j<endHour; j++) {
                teamOfficeHour[(startDay-1+7)%7][j] = true;
              }
              // handle having minutes
              if (parseInt(endTimeArr[1]) != 0) {
                teamOfficeHour[(startDay-1+7)%7][Math.min(endHour, 24)] = true;
              }
            } else {
              for(let j=0; j<endHour; j++) {
                teamOfficeHour[(startDay-1+7)%7][j] = true;
              }
              for(let j=startHour; j<24; j++) {
                teamOfficeHour[(startDay-1+7)%7][j] = true;
              }
              // handle having minutes
              if (parseInt(startTimeArr[1]) != 0) {
                teamOfficeHour[(startDay-1+7)%7][Math.min(endHour, 24)] = true;
              }
            }
          }
        }
      }

      // add public holiday
      for( let holiday of team.operationPeriod.publicHoliday) {
        let startTimeArr = holiday.startTime.split(":");
        let endTimeArr = holiday.endTime.split(":");
        if (holiday.startTime !== holiday.endTime && startTimeArr.length > 1 && endTimeArr.length > 1) {
          // have input
          let startHour = Math.min(parseInt(startTimeArr[0]),24);
          let startMin =  parseInt(startTimeArr[1]);
          let endHour = Math.min(parseInt(endTimeArr[0]),24);
          let endMin =  parseInt(endTimeArr[1]);
          if(endHour * 60 + endMin > startHour * 60 + startMin) {
            for(let j=startHour; j<endHour; j++) {
              teamOfficeHour[7][j] = true;
            }
            // handle having minutes
            if (parseInt(endTimeArr[1]) != 0) {
              teamOfficeHour[7][Math.min(endHour, 24)] = true;
            }
          } else {
            for(let j=0; j<endHour; j++) {
              teamOfficeHour[7][j] = true;
            }
            for(let j=startHour; j<24; j++) {
              teamOfficeHour[7][j] = true;
            }
            // handle having minutes
            if (parseInt(startTimeArr[1]) != 0) {
              teamOfficeHour[7][Math.min(endHour, 24)] = true;
            }
          }
        }
      }
      this.officeHourList.unshift(teamOfficeHour);  // add to begining
      this.showTeamSchedule('UpdateSchedule');
    }
  }

}

