import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetSnRequest } from 'src/app/api/sn/get-sn-request';
import { GetSnResponse } from 'src/app/api/sn/get-sn-response';
import { PostSnAcknowledgeRequest } from 'src/app/api/sn/post-sn-acknowledge-request';
import { PostSnAcknowledgeResponse } from 'src/app/api/sn/post-sn-acknowledge-response';
import { PostSnCancelRequest } from 'src/app/api/sn/post-sn-cancel-request';
import { PostSnCancelResponse } from 'src/app/api/sn/post-sn-cancel-response';
import { PostSnDraftRequest } from 'src/app/api/sn/post-sn-draft-request';
import { PostSnDraftResponse } from 'src/app/api/sn/post-sn-draft-response';
import { PostSnRejectRequest } from 'src/app/api/sn/post-sn-reject-request';
import { PostSnRejectResponse } from 'src/app/api/sn/post-sn-reject-response';
import { PostSnRerouteRequest } from 'src/app/api/sn/post-sn-reroute-request';
import { PostSnRerouteResponse } from 'src/app/api/sn/post-sn-reroute-response';
import { PostSnSubmitRequest } from 'src/app/api/sn/post-sn-submit-request';
import { PostSnSubmitResponse } from 'src/app/api/sn/post-sn-submit-response';
import { PostSnSummaryRequest } from 'src/app/api/sn/post-sn-summary-request';
import { PostSnSummaryResponse } from 'src/app/api/sn/post-sn-summary-response';
import { PostSnManualInstructionRequest } from 'src/app/api/sn/post-sn-manual-instruction-request';
import { PostSnManualInstructionResponse } from 'src/app/api/sn/post-sn-manual-instruction-response';
import { PutSnRequest } from 'src/app/api/sn/put-sn-request';
import { PutSnResponse } from 'src/app/api/sn/put-sn-response';
import { environment } from 'src/environments/environment';
import { PostSnGeneralSearchResponse } from '@api/model/sn/post-sn-general-search-response';
import { PostSnGeneralSearchRequest } from '@api/model/sn/post-sn-general-search-request';
import { PostSnGetSnByTokenRequest } from '@api/model/sn/post-sn-get-sn-by-token-request';
import { PostSnGetSnByTokenResponse } from '@api/model/sn/post-sn-get-sn-by-token-response';
import { PostAcknowledgeByTokenRequest } from '@api/model/sn/post-sn-acknowledge-by-token-request';
import { PostAcknowledgeByTokenResponse } from '@api/model/sn/post-sn-acknowledge-by-token-response';
import { PostExportSnSummaryRequest } from '@api/model/sn/post-export-sn-summary-request';
import { PostSnCcsErrorSummaryResponse } from '@api/model/sn/post-sn-ccs-error-summary-response';
import { PostSnCcsErrorSummaryRequest } from '@api/model/sn/post-sn-ccs-error-summary-request';
import { PostSnCcsResyncRequest } from '@api/model/sn/post-sn-ccs-resync-request';
import { PostSnRemoveCcsErrorRequest } from '@api/model/sn/post-sn-remove-ccs-error-request';

@Injectable({
  providedIn: 'root'
})
export class SnService {

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService
  ) { }

  // getSnByNumber(request: GetSnRequest): Observable<GetSnResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/getSn"

  //   return this.http.post<any>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // postSnManualInstruction(request: PostSnManualInstructionRequest): Observable<PostSnManualInstructionResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/manualInstruction";

  //   return this.http.post<PostSnManualInstructionResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // @Deprecated
  // TODO: remove
  postSnSummaries(request: PostSnSummaryRequest): Observable<PostSnSummaryResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/sn/snSummary";

    return this.http.post<PostSnSummaryResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  // postSnGeneralSearch(request: PostSnGeneralSearchRequest): Observable<PostSnGeneralSearchResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/snGeneralSearch";

  //   return this.http.post<PostSnGeneralSearchResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // draftSn(request: PostSnDraftRequest): Observable<PostSnDraftResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/draft";

  //   return this.http.post<PostSnDraftResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // submitSn(request: PostSnSubmitRequest): Observable<PostSnSubmitResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/submit";

  //   return this.http.post<PostSnSubmitResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // acknowledgeSn(request: PostSnAcknowledgeRequest): Observable<PostSnAcknowledgeResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/acknowledge";

  //   return this.http.post<PostSnAcknowledgeResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // rejectSn(request: PostSnRejectRequest): Observable<PostSnRejectResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/reject";

  //   return this.http.post<PostSnRejectResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // cancelSn(request: PostSnCancelRequest): Observable<PostSnCancelResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/cancel";

  //   return this.http.post<PostSnCancelResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // rerouteSn(request: PostSnRerouteRequest): Observable<PostSnRerouteResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/reroute";

  //   return this.http.post<PostSnRerouteResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // putSn(request: PutSnRequest): Observable<PutSnResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/sn/";

  //   return this.http.put<PutSnResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  getSnByToken(request: PostSnGetSnByTokenRequest): Observable<PostSnGetSnByTokenResponse> {
    let url = environment.CCEP_API_HOST + "/sn/getSnByToken";

    return this.http.post<PostSnGetSnByTokenResponse>(
      url,
      request
    ).pipe(
      catchError(this.handleError)
    )
  }

  acknowledgeByToken(request: PostAcknowledgeByTokenRequest): Observable<PostAcknowledgeByTokenResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/sn/acknowledgeByToken";

    return this.http.post<PostAcknowledgeByTokenResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  rejectByToken(request: any): Observable<any> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/sn/rejectByToken";

    return this.http.post<any>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  postExportSnSummaryRequest(request: PostExportSnSummaryRequest):Observable<Blob> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/sn/exportSnSummary";

    return this.http.post(
      url,
      request,
      { 
        headers: httpHeaders,
        responseType: "blob"
       }
    ).pipe(
      catchError(this.handleError)
    )
  }
  
  getSnCcsErrorSummary(request: PostSnCcsErrorSummaryRequest): Observable<PostSnCcsErrorSummaryResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/sn/ccsErrorSummary";

    return this.http.post<PostSnCcsErrorSummaryResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  postSnCcsResyncRequest(request: PostSnCcsResyncRequest){
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/sn/ccsResync";

    return this.http.post<any>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  postSnRemoveCcsErrorRequest(request: PostSnRemoveCcsErrorRequest){
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/sn/removeCcsError";

    return this.http.post<any>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
