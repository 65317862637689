<ng-container *ngIf="textField">
    <span>{{textField}}</span>
</ng-container>
<ng-container *ngIf="textFieldArray.length > 0">
    <ng-container *ngFor="let item of textFieldArray">
        <span class="seperate-item">
            {{ item }}
        </span>
    </ng-container>
</ng-container> 
<ng-container *ngIf="translateKey">
    <span>{{ translateKey | translate }}</span>
</ng-container>
<ng-container *ngIf="!textField && !translateKey && textFieldArray.length < 1">
    <span class="empty-display font-size-xs">
        <ng-container *ngIf="emptyHandleDisplay">
            {{emptyHandleDisplay | translate}}
        </ng-container>
        <ng-container *ngIf="!emptyHandleDisplay">
            {{'component.display-text-field.not-defined' | translate}}
        </ng-container>
    </span>
</ng-container>