import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { Session } from '@services/session';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { BasePage } from '../../model/base/base';
import { isEmailVaild } from 'src/app/presenter/check-format-tool';
import { AppDelegate } from 'src/app/AppDelegate';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

@Component({
  selector: 'app-notification-information-detail',
  templateUrl: './notification-infomation-detail.component.html',
  styleUrls: ['./notification-infomation-detail.component.scss']
})
export class NotificationInfomationDetailComponent extends BasePage implements OnInit {

  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Input() isPendingApproval: boolean = false;
  @Input() currentPageMode: JMENUM.JMPageMode = undefined;
  @Output() onValidation: EventEmitter<any> = new EventEmitter();

  valid = false;

  pageMode = JMENUM.JMPageMode;

  snStatus = undefined;
  client = undefined;
  initialParty = undefined;
  tecoStatus = undefined;
  associatedSn = undefined;
  email = undefined;
  workCentre = undefined;
  ccsSnNumber = undefined;
  createdAt = undefined;
  createdBy = undefined;
  updatedAt = undefined;
  externalRefId = undefined;
  contactPerson = undefined;
  snNumber = undefined;
  contactNumber = undefined;
  completedAt = undefined;

  ccsNoRefreshHandle = undefined;

  editable = {
    client: false,
    initialParty: false,
    associatedSn: false,
    email: false,
    workCentre: false,
    ccsSnNumber: false,
    externalRefId: false,
    contactPerson: false,
    contactNumber: false,
  }

  errorFields = {
    client: false,
    contactPerson: false,
    contactNumber: false,
    email: false,
  };

  otherEmptyHandle = "component.notification-information-detail.other-empty-handle";
  emptyHandleNa = JMLanguage.translate('component.display-text-field.na');
  emptyHandle = JMLanguage.translate('component.display-text-field.not-selected');

  dateTimeFormat = Constants.DATETIME_FORMAT;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.resetAllErrorFields();
    this.snNumber = this.sn.snNumber;
    if (this.sn.pendingApproval) {
      this.snStatus = JMLanguage.translate(`sn.pending-approval.${this.sn.pendingApproval}`);
    } else {
      this.snStatus = JMLanguage.translate(`sn.status.${this.sn.status}`);
    }
    if (this.sn.client) {
      let clientObj = JM.JMConnector.getClient(this.sn.client);
      this.client = (clientObj)?clientObj.clientShortName + ' - ' + clientObj.name[Session.selectedLanguage]:undefined;
    }
    this.tecoStatus = JMLanguage.translate((this.sn.tecoStatus) ? "teco.sn.status." + this.sn.tecoStatus : "teco.status.na");
    this.contactPerson = (this.sn.contactPerson) ? this.sn.contactPerson : undefined;
    this.contactNumber = (this.sn.contactNumber || this.sn.contactNumber.length > 0) ? this.sn.contactNumber : undefined;

    this.email = (this.sn.email) ? this.sn.email : undefined;
    this.workCentre = (this.sn.workCentre) ? this.sn.workCentre : undefined;
    this.ccsSnNumber = (this.sn.ccsSnNumber) ? this.sn.ccsSnNumber : undefined;
    this.createdBy = (this.sn.createdBy) ? this.sn.createdBy : undefined;
    
    this.associatedSn = undefined;
    if (this.sn.associatedSn) {
      this.associatedSn = [this.sn.associatedSn];     
    } else if (this.sn.associatingSn.length > 0) {
      this.associatedSn = this.sn.associatingSn;
    }

    if (this.sn.crmData) {
      this.externalRefId = (this.sn.crmData.externalRefId) ? this.sn.crmData.externalRefId : undefined;
    }
    if (this.sn.createdAt) {
      let time = moment(this.sn.createdAt);
      this.createdAt = time.format(Constants.DATETIME_FORMAT);
    }
    if (this.sn.updatedAt) {
      let time = moment(this.sn.updatedAt);
      this.updatedAt = time.format(Constants.DATETIME_FORMAT);
    }
    if (this.sn.completedAt) {
      let time = moment(this.sn.completedAt);
      this.completedAt = time.format(Constants.DATETIME_FORMAT);
    }
    if (this.sn.status != JMENUM.SnStatus.DRAFT && !this.sn.ccsSnNumber) {
      this.ccsNoRefreshHandle = "pages.sn.ccs-refresh-ccs-number";
    } else {
      this.ccsNoRefreshHandle = undefined;
    }

    this.fieldControl();
    this.validation();
  }

  private fieldControl() {
    if (this.currentPageMode == JMENUM.JMPageMode.EDIT) {
      const statusReadOnlyList = [
        JMENUM.SnStatus.COMPLETED,
        JMENUM.SnStatus.CANCELLED,
        JMENUM.SnStatus.DRAFT,
        JMENUM.SnStatus.SIGNED_OFF,
      ];
      if (!statusReadOnlyList.includes(this.sn.status) && !this.isPendingApproval) {
        this.editable.contactPerson = true;
        this.editable.email = true;
        this.editable.contactNumber = true;
      }
    }
  }

  public assignEmailToValue(key, newValue) {
    this.resetErrorField(key);
    if (!this.isEmailVaild(newValue) && newValue) {
      newValue = undefined;
      AppDelegate.openSnackBar(JMLanguage.translate("component.notification-information-detail.error.msg.email"));
      if (this.errorFields[key] != undefined) {
        this.errorFields[key] = true;
      }
    }
    else {
      this.sn[key] = newValue;
    }
    this.validation();
  }

  public assignInputToValue(key, newValue) {
    this.resetErrorField(key);
    if (newValue) {
      this.sn[key] = newValue;
    }
    else {
      if (this.errorFields[key] != undefined) {
        this.errorFields[key] = true;
      }
    }
    this.validation();
  }

  public assignInputToValueArray(key, newValue, index) {
    this.resetErrorField(key);
    newValue = newValue.replace(/[^\d]/g, '');
    this.sn[key][index] = newValue;
    if (!(this.sn[key].filter(item => item).length > 0)) {
      if (this.errorFields[key] != undefined) {
        this.errorFields[key] = true;
      }
    }
    this.validation();
  }

  onClickDetails() {
    let path = "/sn/view/" + this.snNumber + "/operation-log";
    if (this.currentPageMode == JMENUM.JMPageMode.VIEW) {
      this.router.navigate([path]);
    } else if (this.currentPageMode == JMENUM.JMPageMode.EDIT) {
      window.open(path);
    }
  }

  onClickAssociatedSn(snNumber) {
    let path = "/sn/view/" + snNumber;
    window.open(path);
  }

  inputNumberWithIndex(event: any) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
  }

  addContactNumber() {
    this.contactNumber[this.contactNumber.length] = "";
  }

  removeContactNumber(index) {
    this.contactNumber.splice(index, 1);
    if (!(this.sn.contactNumber.filter(item => item).length > 0)) {
      if (this.errorFields.contactNumber != undefined) {
        this.errorFields.contactNumber = true;
      }
    }
  }

  public validation() {
    let haveData = (this.sn.client && this.sn.contactPerson && this.sn.contactNumber.length > 0) ? true : false;
    this.errorFields.client = (this.sn.client && this.client) ? false : true;
    this.errorFields.contactPerson = (this.sn.contactPerson && this.contactPerson) ? false : true;
    this.errorFields.contactNumber = (this.sn.contactNumber.filter(item => item).length > 0 && this.contactNumber.filter(item => item).length > 0) ? false : true;
    let hasErrorField = false;
    for (let i in this.errorFields) {
      if (this.errorFields[i] === true) {
        hasErrorField = true;
        break;
      }
    }
    this.valid = !hasErrorField && haveData;
    this.onValidation.emit(this.valid);
  }

  public resetAllErrorFields() {
    this.valid = true;
    this.errorFields = {
      client: false,
      contactPerson: false,
      contactNumber: false,
      email: false,
    };
  }

  //N0 Need To Reset Target Error Fields 
  // public resetErrorFields(keys: string[]) {
  //   keys.forEach(key => this.resetErrorField(key));
  // }

  public resetErrorField(key) {
    if (this.errorFields[key] != undefined) {
      this.errorFields[key] = false;
    }
  }

  //TODO:
  public isEmailVaild(value: string): Boolean {
    try {
      value = value.toLowerCase();
      var isVaild = false;
      if (value != undefined && value.includes("@")) {
        isVaild = true;
      }
      return isVaild;
    } catch (err) {
      return false;
    }

  }
}