import { Component, OnInit, Input, Injector, EventEmitter, Output } from '@angular/core';
import { Session } from '@services/session';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { BasePage } from '../../model/base/base';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-fault-infomation-detail',
  templateUrl: './fault-infomation-detail.component.html',
  styleUrls: ['./fault-infomation-detail.component.scss']
})
export class FaultInfomationDetailComponent implements OnInit {

  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Input() isPendingApproval: boolean = false;
  @Input() currentPageMode: JMENUM.JMPageMode = undefined;

  @Output() onValidation: EventEmitter<any> = new EventEmitter();

  valid = false;

  pageMode = JMENUM.JMPageMode;
  JMFehdVenue = JMENUM.FehdVenue;

  location = undefined;
  equipmentCategory = undefined;
  equipmentType = undefined;
  faultDetail = undefined;
  internalRemarks = undefined;
  locationDetail = undefined;
  priority = undefined;
  district = undefined;
  fehdVenue = undefined;

  editable = {
    location: false,
    equipmentCategory: false,
    equipmentType: false,
    faultDetail: false,
    internalRemarks: false,
    locationDetail: false,
    priority: false,
    district: false,
    fehdVenue: false,
  };

  errorFields = {
    faultDetail: false,
  };

  fehdVenueOptionList = [];

  emptyHandle = JMLanguage.translate("component.display-text-field.not-selected");
  emptyHandleUnclassified = JMLanguage.translate("global.unclassified");

  constructor() { }

  ngOnInit() {
    this.validation();
  }

  ngOnChanges() {
    this.resetAllErrorFields();
    if (this.sn.location) {
      this.requestLocationSummary();
    }
    if (this.sn.equipmentCategory) {
      let equipmentCategoryObj = JM.JMConnector.getEquipmentCategory(this.sn.equipmentCategory);
      if (equipmentCategoryObj.description[Session.selectedLanguage]) {
        this.equipmentCategory = this.sn.equipmentCategory + " - " + equipmentCategoryObj.description[Session.selectedLanguage];
      }
      else {
        this.equipmentCategory = this.sn.equipmentCategory;
      }
    }
    if (this.sn.equipmentType) {
      let equipmentTypeObj = JM.JMConnector.getEquipmentType(this.sn.equipmentType);
      if (equipmentTypeObj.description[Session.selectedLanguage]) {
        this.equipmentType = this.sn.equipmentType + " - " + equipmentTypeObj.description[Session.selectedLanguage];
      }
      else {
        this.equipmentType = this.sn.equipmentType;
      }
    }
    this.faultDetail = (this.sn.faultDetail) ? this.sn.faultDetail : undefined;
    this.internalRemarks = (this.sn.internalRemarks) ? this.sn.internalRemarks : undefined;
    this.locationDetail = (this.sn.locationDetail) ? this.sn.locationDetail : undefined;
    this.priority = (this.sn.priority) ? JMLanguage.translate('sn.priority.' + this.sn.priority) : undefined;
    this.district = (this.sn.district) ? JMLanguage.translate('district.' + this.sn.district) : undefined;
    this.fehdVenue = (this.sn.fehdVenue) ? JMLanguage.translate('fehd-venue.' + this.sn.fehdVenue) : undefined;
    this.fieldControl();
    this.validation();
  }

  private fieldControl() {
    if (this.currentPageMode == JMENUM.JMPageMode.EDIT) {
      const statusReadOnlyList = [
        JMENUM.SnStatus.COMPLETED,
        JMENUM.SnStatus.CANCELLED,
        JMENUM.SnStatus.DRAFT,
        JMENUM.SnStatus.SIGNED_OFF,
      ];
      if (!statusReadOnlyList.includes(this.sn.status) && !this.isPendingApproval) {
        this.editable.faultDetail = this.sn.createdParty === JMENUM.SNCreatedParty.SBU ? true : JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.SN_FAULT_DETAILS_PRIVATE_UPDATE);
        this.editable.internalRemarks = true;
        this.editable.locationDetail = true;
        this.editable.fehdVenue = true;
      }
    }
  }

  private requestLocationSummary() {
    let request: JM.JMRequestLocationsLocationSummary = new JM.JMRequestLocationsLocationSummary();
    request.location = [];
    request.location.push(this.sn.location);

    JM.JMConnector.sendLocationsLocationSummaryPromise(request, (error: JM.JMNetworkError, response: JM.JMResponseLocationsLocationSummary) => {
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }

      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
      if (!response.payload.records || !response.payload.records.length) return;

      if (response.payload.records && response.payload.records.length > 0) {
        let locationObj = response.payload.records[0];
        let description = locationObj.description[Session.selectedLanguage] ? locationObj.description[Session.selectedLanguage] : locationObj.description["en"];

        this.location = description + " (" + this.sn.location + ")";

        if (locationObj && locationObj.fehdVenues && locationObj.fehdVenues.length) {
          this.fehdVenueOptionList = locationObj.fehdVenues;
        }
      } else {
        console.error("location not found " + request.location);
      }
    });
  }

  public assignInputToValue(key, newValue) {
    this.resetErrorField(key);
    if (newValue !== undefined) {
      this.sn[key] = newValue;
    }
    else {
      if (this.errorFields[key] != undefined) {
        this.errorFields[key] = true;
      }
    }
    this.validation();
  }

  public validation() {
    let haveData = (this.sn.faultDetail) ? true : false;
    this.errorFields.faultDetail = (this.sn.faultDetail && this.faultDetail) ? false : true;
    let hasErrorField = false;
    for (let i in this.errorFields) {
      if (this.errorFields[i] === true) {
        hasErrorField = true;
        break;
      }
    }
    this.valid = !hasErrorField && haveData;
    this.onValidation.emit(this.valid);
  }

  public resetAllErrorFields() {
    this.valid = true;
    this.errorFields = {
      faultDetail: false,
    };
  }

  //N0 Need To Reset Target Error Fields 
  // public resetErrorFields(keys: string[]) {
  //   keys.forEach(key => this.resetErrorField(key));
  // }

  public resetErrorField(key) {
    if (this.errorFields[key] != undefined) {
      this.errorFields[key] = false;
    }
  }

}
