import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from '@services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: "team-selector",
  templateUrl: "./team-selector.component.html",
  styleUrls: ["./team-selector.component.scss"]
})
export class TeamSelectorComponent implements OnInit {
  @ViewChild("createTeamSliderPanel", { static: true }) createTeamSliderPanel;
  @ViewChild("createTeamForm", { static: true }) createTeamForm;
  @ViewChild("updateTeamSliderPanel", { static: true }) updateTeamSliderPanel;
  @ViewChild("updateTeamForm", { static: true }) updateTeamForm;

  @Output() onTeamChanged: EventEmitter<any> = new EventEmitter();
  
  @Input() mode: "inhouse"|"contractor" = "inhouse";
  @Input() workCentre: string = null;
  
  uiTitle: string = '';
  showCreateButton: boolean = false;
  teamList: JMOBJ.Team[] = [];
  selectedTeam: JMOBJ.Team = undefined;
  isLoading: boolean = false;
  
  createTeamFormParameters: {
    workCentre: string,
    onSaveButtonClicked: Function,
  } = undefined;

  updateTeamFormParameters: {
    workCentre: string,
    onSaveButtonClicked: Function,
  } = undefined;

  handlingParty = JMENUM.HandlingParty;

  ngOnInit() {
    if (this.mode == "inhouse") {
      this.uiTitle = JMLanguage.translate("team-selector.title.inhouse-team", [0]);
    } else {
      this.uiTitle = JMLanguage.translate("team-selector.title.contractor-team", [0]);
    }
    this.showCreateButton = JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.WCM_UPDATE);
    this.createTeamFormParameters = {
      workCentre: this.workCentre,
      onSaveButtonClicked: this.onCreatedTeam,
    };
    this.updateTeamFormParameters = {
      workCentre: this.workCentre,
      onSaveButtonClicked: this.onUpdateTeamBtnClicked,
    }
    this.requestTeamSummary();
  }

  //---------------------------------------------------
  deselectTeam() {
    this.selectedTeam = undefined;
  }

  reloadTeam() {
    this.requestTeamSummary();
  }

  //---------------------------------------------------
  // button callback
  onTeamClicked = (team:JMOBJ.Team) => {
    if (this.selectedTeam && team._id == this.selectedTeam._id) {
      return;
    }
    this.selectedTeam = team;
    Session.setTeamSettingsSelectedTeamId(team._id);
    this.onTeamChanged.emit(team);
  }

  onCreateTeamBtnClicked = () => {
    this.createTeamForm.clear();
    this.createTeamForm.setMode(this.mode);
    this.createTeamSliderPanel.toggle();
  }

  onCreatedTeam = () => {
    this.requestTeamSummary();
    this.createTeamSliderPanel.toggle();
  }

  onUpdateTeamBtnClicked = () => {
    this.requestTeamSummary();
    this.updateTeamSliderPanel.toggle();
  }

  onEditTeamClicked = (team:JMOBJ.Team) => {
    this.updateTeamForm.setTeam(team);
    this.updateTeamSliderPanel.toggle();
  }

  //---------------------------------------------------
  private requestTeamSummary = async () => {
    let request = new JM.JMRequestTeamsTeamSummary();
    request.workCentreList = [this.workCentre];
    request.active = JMENUM.RequestActive.BOTH;
    request.includeSummary = true;
    request.isRoTeam = false;
    request.pageSize = 1000;
    request.pageNumber = 1;
    if (this.mode == "inhouse") {
      request.handlingPartyList = [JMENUM.HandlingParty.INHOUSE];
    } else {
      request.handlingPartyList = [JMENUM.HandlingParty.PMSMC, JMENUM.HandlingParty.NON_PMSMC];
    }

    this.isLoading = true;
    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    this.isLoading = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let teams = response.payload.records;
    let selectedTeamId = Session.teamSettingsSelecteTeamId;
    this.teamList = teams.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const pmsmcContractNumberList = this.teamList.filter((team) => {
      return team.handlingParty == JMENUM.HandlingParty.PMSMC && team.contractNumber;
    }).map((team) => team.contractNumber);

    if (Array.isArray(pmsmcContractNumberList) && pmsmcContractNumberList.length > 0) {
      const request = new JM.JMRequestPostsGetPostCountByContractList();
      request.contractNumber = pmsmcContractNumberList;

      const response: JM.JMResponsePostsGetPostCountByContractList = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      const contractTeamPostCountDict = {};
      response.payload.forEach((record) => {
        contractTeamPostCountDict[record.contractNumber] = record.count;
      });

      this.teamList.forEach((team) => {
        if (team.handlingParty == JMENUM.HandlingParty.PMSMC && team.contractNumber) {
          const postCount = contractTeamPostCountDict[team.contractNumber];
          team['memberCount'] = postCount;
        }
      });
    }
    
    for (let i = 0 ; i < teams.length ; i++) {
      let t = teams[i];

      if (!selectedTeamId && i == teams.length - 1 || selectedTeamId && t._id === selectedTeamId) {
        Session.setTeamSettingsSelectedTeamId(t._id);
        this.selectedTeam = t;
        this.onTeamChanged.emit(t);
      }
    }

    if (this.mode == "inhouse") {
      this.uiTitle = JMLanguage.translate("team-selector.title.inhouse-team", [this.teamList.length]);
    } else {
      this.uiTitle = JMLanguage.translate("team-selector.title.contractor-team", [this.teamList.length]);
    }
  }

  checkTeamExpired(status:JMENUM.TeamStatus) {
    return (status === JMENUM.TeamStatus.CONTRACT_EXPIRED);
  }

  checkTeamIneffective(status:JMENUM.TeamStatus) {
    return (status === JMENUM.TeamStatus.CONTRACT_INEFFECTIVE);
  }
}
