<div class="bold font-size-l mb-1">{{ 'pages.mi-detail-panel.title' | translate}}</div>
<div class="bold font-size-s priority-wrap py-1 mb-4"
    [ngClass]="{'emergency':priority==1,'urgent':priority==2,'non-urgent':priority==3}">
    {{ 'sn.priority.'+priority | translate }}
</div>
<ng-container *ngIf="preActionArray.length > 0">
<div class="bold font-size-m mb-1">{{ 'pages.mi-detail-panel.subtitle.action-before-creating-sn' | translate}}</div>
<div class="mb-2 mx-0">
    <ng-container *ngFor="let preAction of preActionArray; let j = index">
        <div class="mb-1" [id]="'mi-detail-preaction-'+j">{{preAction}}</div>
    </ng-container>
    <hr class="custom-hr" *ngIf="contractorNoResponseArray.length > 0 || roNoResponseArray.length > 0"/>
</div>
</ng-container>
<ng-container *ngIf="contractorNoResponseArray.length > 0">
    <div class="mb-1">
        <div class="bold font-size-m mx-0 d-inline mr-2">{{ 'pages.mi-detail-panel.subtitle.no-response-of-contractor' | translate }}</div>
        <ng-container *ngIf="contractorResponseTime>0">
            <i class="fas fa-stopwatch amber px-1 font-size-m"></i>
            <span id="mi-detail-contractor-response-time">{{contractorResponseTime}}</span>
            <span class="pl-1">mins</span>
        </ng-container>
    </div>
    <div class="mb-2 mx-0">
        <ng-container *ngFor="let contractorNoResponse of contractorNoResponseArray; let j = index">
            <div class="mb-1" [id]="'mi-detail-contractor-no-response-'+j">{{contractorNoResponse}}</div>
        </ng-container>
        <hr class="custom-hr" *ngIf="roNoResponseArray.length > 0"/>
    </div>
</ng-container>
<ng-container *ngIf="roNoResponseArray.length > 0">
    <div class="mb-1">
        <div class="bold font-size-m mx-0 d-inline mr-2">{{ 'pages.mi-detail-panel.subtitle.no-response-of-ro' | translate}}
        </div>
        <ng-container *ngIf="roResponseTime>0">
            <i class="fas fa-stopwatch amber px-1 font-size-m"></i>
            <span id="mi-detail-ro-response-time">{{roResponseTime}}</span>
            <span class="pl-1">mins</span>
        </ng-container>
    </div>
    <div class="mb-2 mx-0">
        <ng-container *ngFor="let roNoResponse of roNoResponseArray; let j = index">
            <div class="mb-1" [id]="'mi-detail-ro-response-'+j">{{roNoResponse}}</div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="contractorResponseTime>0 || roResponseTime>0">
    <div>
        <i class="fas fa-stopwatch amber pr-2 font-size-m mx-0 mt-5"></i>{{'pages.mi-detail-panel.remarks.time-for-no-response' | translate}}
    </div>
</ng-container>