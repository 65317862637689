<div class="contract-form-wrap">
  <form>
    <div class="title-wrap row justify-content-between mx-0 mb-4">
      <h3 class="d-inline">{{ 'add-contract-form.title' | translate }}</h3>
      <div class="button-wrap text-right">
        <app-submit-button 
          [id]="'dummy_contract_save_button'"
          [text]="'global.submit'"
          [buttonClass]="'d-block standard brand-amber mr-0'"
          [iconClass]="'fa-save'"
          [isDisabled]="!uiEnableSubmitButton"
          [isLoading]=""
          (onClick)="onSubmitButtonClicked($event)" 
        ></app-submit-button>
      </div>
    </div>
    <div>
      <div>{{ 'add-contract-form.legend.pmsmc' | translate }}</div>
      <div>{{ 'add-contract-form.legend.non-pmsmc' | translate }}</div>
      <div>{{ 'add-contract-form.legend.transitional-contract' | translate }}</div>
      <div>{{ 'add-contract-form.legend.dummy-contract' | translate }}</div>
    </div>
    
    <h4 class="mt-4">{{ "add-contract-form.contract-number" | translate }}</h4>
    <input id="id_search_input"
      type="text" 
      class="form-control" 
      [(ngModel)]="uiNewContractNumber" 
      name="newContractNumber"
      (keyup)="onNewContractNumberKeyup($event)"
    >

    <h4 class="mt-4">{{ "add-contract-form.search-result" | translate }}</h4>
    <div class="search-result-container" (scroll)="onScroll($event)">
      <ul class="my-2 pl-0">
        <ng-container *ngIf="uiContractArray">
          <ng-container *ngFor="let contract of uiContractArray;let i = index">
            <li class="py-1">          
              <div class="checkbox-wrap position-relative custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" 
                id="contract_checkbox_{{contract.key}}"
                class="checkbox custom-control-input"
                name="contract_checkbox_{{contract.key}}"
                [(ngModel)]="contract.checked"
                (change)="onCheckBoxClicked($event, i, contract.key)"
              >
              <label class="custom-control-label" for="contract_checkbox_{{contract.key}}" id="id_contract_label_{{contract.key}}">
                {{ contract.label }}  
                <i class="expired-icon fas fa-exclamation-circle mx-2" 
                  *ngIf="checkEffective(contract.key) == false" 
                  [ngbTooltip]="contractNotEffectiveToolTip"
                  placement="bottom" ></i>
              </label>
              </div>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </form>
</div>

<ng-template #contractNotEffectiveToolTip>
    <div class="warning-tooltip-wrap text-center">
      {{ "add-contract-form.contract-effective-tooltip" | translate }}
    </div>
</ng-template>