<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle"></app-nav>
  
  <app-sidebar></app-sidebar>
  
  <div id="content">
    <div class="border">
      <app-tablex id='batch-history-table' [tablexParam]='tablexParam'></app-tablex>
    </div>
  </div>
</div>
