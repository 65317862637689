<div class="wrapper">
    <app-nav [navbarTitle]="'side-bar.costing' | translate"></app-nav>
    <app-sidebar></app-sidebar>

    <div id="content" class="settings-content ">
        <div class="d-block sections-wrap mb-4" *ngFor="let section of sectionItems; let s = index">
            <div class="title">{{ section.sectionName }}</div>
            <hr class="" />

            <div class="row sections-item-container m-0">
                <ng-container *ngFor="let item of section.items; let i = index;">
                    <a *ngIf="item.permission"
                        class="section-item d-flex align-items-center px-4 py-1 justify-content-start mr-3 mb-3"
                        [ngClass]="section.theme" routerLink="{{item.routing}}">
                        <img *ngIf="item.iconPath" src="{{item.iconPath}}" class="item-icon img mr-3">
                        <i *ngIf="item.icon" class="item-icon mr-3" [ngClass]="item.icon"></i>
                        <span class="font-size-m item-name">
                            {{ item.name }}
                        </span>
                    </a>
                </ng-container>
            </div>
        </div>

    </div>
</div>