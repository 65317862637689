import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { SnReason } from '@enum/sn-reason';
import { Session } from '@services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-jobcard-progress-details',
  templateUrl: './jobcard-progress-details.component.html',
  styleUrls: ['./jobcard-progress-details.component.scss']
})
export class JobcardProgressDetailsComponent implements OnInit {
  // @Input() sn: JMOBJ.ServiceNotification; 
  @Input() jobCard: JMOBJ.JobCard;
  @Input() pageMode: JMENUM.JMPageMode;
  @Output() onValidation: EventEmitter<any> = new EventEmitter();

  jobCardStatus = JMENUM.JobCardStatus;
  jobNatureEnum = JMENUM.JobNature; 
  constants = Constants;
  isInHouse = false;

  // Input Fields
  jobDescription: string;

  // Need Formatting
  reasonRemarks: any = {};
  followUpRemarks: string;

  constructor() { }

  ngOnInit() {
    this.initRemarks();
    this.setValueFromJobCard();
    this.isInHouse = this.jobCard.handlingParty === JMENUM.HandlingParty.INHOUSE;
  }

  ngOnChanges(): void {
    this.initRemarks();
  }

  get showTitle(): boolean {
    return this.showJobDescription ||
    this.showfollowUpRemarks ||
    this.showRemarks ||
    this.showReportedByContractor;
  }

  get showJobDescription (): boolean {
    const allowJobNatureList = [
      JMENUM.JobNature.CM,
      JMENUM.JobNature.PM,
      JMENUM.JobNature.ENQUIRY,
    ];

    return allowJobNatureList.includes(this.jobCard.jobNature as JMENUM.JobNature) || 
    (this.jobCard.jobNature == this.jobNatureEnum.STANDALONE && this.pageMode == JMENUM.JMPageMode.VIEW);
  }

  get showfollowUpRemarks(): boolean {
    return this.jobCard.jobNature == this.jobNatureEnum.CM && !!this.followUpRemarks;
  }

  get showRemarks(): boolean {
    return this.reasonRemarks && this.reasonRemarks.remarks;
  }

  get showReportedByContractor(): boolean {
    return !this.isInHouse;
  }

  private setValueFromJobCard(){
    this.jobDescription = this.jobCard.jobDescription;
  }

  private initRemarks(){
    const followUpdescription = JMREASON.JOB_FOLLOW_UP_REASON_LIST.getDescription(this.jobCard.followUpReasonCode);
    this.followUpRemarks = followUpdescription && followUpdescription[Session.selectedLanguage] ? followUpdescription[Session.selectedLanguage] : '';

    let remark;
    switch (this.jobCard.status){
      case this.jobCardStatus.REWORKING: 
      case this.jobCardStatus.REJECTED: 
        remark = this.jobCard.rejectCode? '[' + JMLanguage.translate('jobcard-reason.'+this.jobCard.rejectCode) + '] ' : '' 
        remark += this.jobCard.rejectRemarks ? this.jobCard.rejectRemarks : '';
        this.reasonRemarks['label'] = JMLanguage.translate('pages.sn.reject-remarks');
        this.reasonRemarks['remarks'] =  remark? remark : undefined;
        break;
      case this.jobCardStatus.SUBMITTED_FOR_COMPLETION: 
      case this.jobCardStatus.COMPLETED: 
        remark = this.jobCard.completeCode? '[' + JMLanguage.translate('jobcard-reason.'+this.jobCard.completeCode) + '] ' : '';
        remark += this.jobCard.completeRemarks ? this.jobCard.completeRemarks : '';
        this.reasonRemarks['label'] = JMLanguage.translate('pages.sn.complete-remarks');
        this.reasonRemarks['remarks'] = remark? remark : undefined;
        break;
      case this.jobCardStatus.CANCELLED:
        remark = this.jobCard.cancelCode ? `[${JMREASON.JOB_CANCEL_REASON_LIST.getDescription(this.jobCard.cancelCode)[JMLanguage.getCurrentLanguage()]}] ` : '';
        remark += this.jobCard.cancelRemarks ? this.jobCard.cancelRemarks : '';
        this.reasonRemarks['label'] = JMLanguage.translate('component.progress-details.remarks.cancel');
        this.reasonRemarks['remarks'] = remark ? remark : undefined;
        break;
      default:
        this.reasonRemarks['label'] = JMLanguage.translate('component.progress-details.remarks');
        this.reasonRemarks['remarks'] = undefined;
        break;
    }
  }

  //======= Validation =======//
  public assignInputToValue(key, newValue) {
    if(key == 'jobDescription'){
      newValue = this.jobDescription.trim();
      this.jobCard[key] = newValue;
    }
  }

  get isPageModeCreate(){
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }

  get isPageModeEdit(){
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }

  get isPageModeView(){
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }

}
