import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/AppDelegate';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { inputNumber } from 'src/lib/presenter/Formatter';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session } from 'src/app/services/session';
@Component({
  selector: 'app-pm-plan-reminders',
  templateUrl: './pm-plan-reminders.component.html',
  styleUrls: ['./pm-plan-reminders.component.scss']
})
export class PmPlanRemindersComponent implements OnInit {
  JMPageMode = JMENUM.JMPageMode;

  @Input() plan: JMOBJ.PmPlan;
  @Input() pageMode: JMENUM.JMPageMode;

  // Validation 
  inputNumber = inputNumber;
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};

  // Input Fields
  toBeOverhaulActiveStatus: boolean;
  toBeOverhaulDays: number;
  toBeOverhaulInterval: number;
  overdueActiveStatus: boolean;
  overdueInterval: number;
  reminderRecipients: any;

  // recipients Dropdown
  recipientsOptions: any = [];
  private searchPostObserver = new Subject<any[]>();
  searchPostKeywords: string = null;
  postPageSize: number = 50;
  postPageNumber: number = 0;
  postTotalCount: number;
  isLoadingPost: boolean = false;
  isDisabledRecipients: boolean = true;

  //enum
  activeStatus = JMENUM.ActiveStatus;

  componentPlanRemindersIn:string = Session.selectedLanguage === JMENUM.Language.ZH ? JMLanguage.translate('component.plan-reminders.in') :'';
  
  constructor() { }

  ngOnInit() {
    if (this.pageMode !== JMENUM.JMPageMode.VIEW) {
      this.fieldsControl();
      this.prefillReminders();
      this.searchPostObserver.pipe(
        debounceTime(Constants.DEBOUNCE_TIME),
      ).subscribe(() => {
        this.searchPost()
      }
      );
    }
    this.initPlan();
  }

  ngOnChanges() {
    if (this.pageMode !== JMENUM.JMPageMode.VIEW) {
      this.toggleRecipientsInput();
      this.requestPostSummary();
    }
    this.initPlan();
  }

  prefillReminders() {
    this.toBeOverhaulActiveStatus = true;
    this.overdueActiveStatus = true;
    this.updatePlanReminders();
  }

  initPlan() {
    if (!this.plan) return;
    this.toBeOverhaulActiveStatus = this.plan['toBeOverdueReminder'] ? this.plan['toBeOverdueReminder']['activeStatus'] === JMENUM.ActiveStatus.ACTIVE : false;
    this.toBeOverhaulDays = this.plan['toBeOverdueReminder'] ? this.plan['toBeOverdueReminder']['toBeDueDay'] : undefined;
    this.toBeOverhaulInterval = this.plan['toBeOverdueReminder'] ? this.plan['toBeOverdueReminder']['interval'] : undefined;
    this.overdueActiveStatus = this.plan['toBeOverdueReminder'] ? this.plan['overduePeriodReminder']['activeStatus'] === JMENUM.ActiveStatus.ACTIVE : false;
    this.overdueInterval = this.plan['overduePeriodReminder'] ? this.plan['overduePeriodReminder']['interval'] : undefined;
    this.reminderRecipients = this.plan['reminderRecipients'] ? this.plan['reminderRecipients'] : undefined;
  }

  updateReminderDefaultValues() {
    if (!this.plan) return;
    this.toBeOverhaulActiveStatus = this.plan['toBeOverdueReminder'] ? this.plan['toBeOverdueReminder']['activeStatus'] === JMENUM.ActiveStatus.ACTIVE : false;
    this.toBeOverhaulDays = this.plan['toBeOverdueReminder'] ? this.plan['toBeOverdueReminder']['toBeDueDay'] : undefined;
    this.toBeOverhaulInterval = this.plan['toBeOverdueReminder'] ? this.plan['toBeOverdueReminder']['interval'] : undefined;
    this.overdueActiveStatus = this.plan['toBeOverdueReminder'] ? this.plan['overduePeriodReminder']['activeStatus'] === JMENUM.ActiveStatus.ACTIVE : false;
    this.overdueInterval = this.plan['overduePeriodReminder'] ? this.plan['overduePeriodReminder']['interval'] : undefined;
  }

  // ----------- API ----------- //
  private async requestPostSummary() {
    if (this.postTotalCount !== null &&
      this.postTotalCount <= this.postPageNumber * this.postPageSize || !this.plan.workCentre) {
      return;
    }
    this.postPageNumber++;

    const request: JM.JMRequestPostsPostSummary = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.pageNumber = this.postPageNumber;
    request.pageSize = this.postPageSize;
    request.workCentre = [this.plan.workCentre];
    if (this.searchPostKeywords) {
      request.filter = { name: this.searchPostKeywords };
    }

    this.isLoadingPost = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingPost = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let options = response.payload.records.map(record => {
      return { label: record.name, value: record.name };
    });
    this.postTotalCount = response.payload.totalCount;
    this.recipientsOptions = this.recipientsOptions.concat(options);
  }

  // ----------- UI function ----------- //
  private fieldsControl() {
    if (this.pageMode === JMENUM.JMPageMode.CREATE) {
      this.editable = {
        toBeOverhaulDays: false,
        toBeOverhaulInterval: false,
        overdueInterval: false,
        reminderRecipients: true
      }
    } else if (this.pageMode === JMENUM.JMPageMode.EDIT) {
      if (this.plan.status === JMENUM.PMJobStatus.DRAFT) {
        this.editable = {
          toBeOverhaulDays: false,
          toBeOverhaulInterval: false,
          overdueInterval: false,
          reminderRecipients: true
        }
      } else {
        this.editable = {
          toBeOverhaulDays: false,
          toBeOverhaulInterval: false,
          overdueInterval: false,
          reminderRecipients: true
        }
      }
    }
    this.mandatory = { ...this.editable };
    this.mandatory.reminderRecipients = false;
  }

  public clearRecipients() {
    this.recipientsOptions = [];
    this.postPageNumber = 0;
    this.postTotalCount = null;
    this.plan.reminderRecipients = undefined;
    this.searchPostKeywords = undefined;
  }

  public toggleRecipientsInput() {
    if (this.plan.workCentre) {
      this.isDisabledRecipients = false;
    } else {
      this.isDisabledRecipients = true;
    }
  }

  public onChangeInterval() {
    this.updatePlanReminders();
  }

  public onChangeIntervalAndCheckMin(keyName: string, ele: any, min: number) {
    let str = ele.value;
    let newValue = str.replace(/[^\d]/g, '');
    if (+newValue < min) {
      newValue = null;
      this[keyName] = null;
    }
    if (ele) {
      ele.value = newValue;
    }
    this.updatePlanReminders();
  }

  updatePlanReminders() {
    this.plan.toBeOverdueReminder = { toBeDueDay: this.toBeOverhaulDays, interval: this.toBeOverhaulInterval, activeStatus: this.toBeOverhaulActiveStatus ? JMENUM.ActiveStatus.ACTIVE : JMENUM.ActiveStatus.INACTIVE };
    this.plan.overduePeriodReminder = { interval: this.overdueInterval, activeStatus: this.overdueActiveStatus ? JMENUM.ActiveStatus.ACTIVE : JMENUM.ActiveStatus.INACTIVE };
  }

  public onPostScrollToEnd() {
    this.requestPostSummary();
  }

  public onSearchPost(event) {
    this.searchPostKeywords = event.term;
    this.searchPostObserver.next();
  }
  public searchPost() {
    this.recipientsOptions = [];
    this.postPageNumber = 0;
    this.postTotalCount = null;
    this.requestPostSummary();
  }

  public toggleActiveStatus() {
    this.updatePlanReminders();
    this.removeErrorFieldIfDisabled();
  }

  public removeErrorFieldIfDisabled() {
    if (!this.toBeOverhaulActiveStatus) {
      this.errorFields['toBeOverhaulDays'] = false;
      this.errorFields['toBeOverhaulInterval'] = false;
    }
    if (!this.overdueActiveStatus) {
      this.errorFields['overdueInterval'] = false;
    }
  }

  public validate() {
    this.errorFields = {};
    if (this.toBeOverhaulActiveStatus) {
      if (!this.plan.toBeOverdueReminder || (!this.plan.toBeOverdueReminder.toBeDueDay && this.plan.toBeOverdueReminder.toBeDueDay !== 0)) {
        this.errorFields['toBeOverhaulDays'] = true;
      }
      if (!this.plan.toBeOverdueReminder || (!this.plan.toBeOverdueReminder.interval && this.plan.toBeOverdueReminder.interval !== 0)) {
        this.errorFields['toBeOverhaulInterval'] = true;
      }
    }
    if (this.overdueActiveStatus) {
      if (!this.plan.overduePeriodReminder || (!this.plan.overduePeriodReminder.interval && this.plan.overduePeriodReminder.interval !== 0)) {
        this.errorFields['overdueInterval'] = true;
      }
    }
    if (!this.isDisabledRecipients && (this.overdueActiveStatus || this.toBeOverhaulActiveStatus)) {
      if (!this.plan.reminderRecipients || this.plan.reminderRecipients.length === 0) {
        this.errorFields['reminderRecipients'] = true;
      }
    }
    const isValid = Object.keys(this.errorFields).length === 0;
    if (!isValid) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.please-fill-in-mandatory-fields"));
    }
    return isValid;
  }

}
