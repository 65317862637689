<div class="mt-3 tablex">
  <div *ngIf="tablexParam.enableSetPageSize || tablexParam.enablePagination"
    class="align-items-end d-flex justify-content-between">
    <div class="table-tools left align-items-baseline row mx-0 flex-row-reverse justify-content-end">
      <div class="tablex-button-wrap d-flex align-items-center mb-3 pl-0 col-12 col-md-auto " [class.minify-button]="tablexParam.minifyButton"
        *ngIf="tablexParam.enableColFilter || tablexParam.enableClearFilter || tablexParam.enableSelectedRowCount">
        <div *ngIf="tablexParam.enableColFilter" ngbDropdown class="d-inline-block mr-2" [autoClose]="'outside'">
          <button class="btn standard brand-blue tablex-button" id="filter-col-button" title="{{ 'global.columns' | translate }}" ngbDropdownToggle >
            <i *ngIf="tablexParam.minifyButton" class="fas fa-columns mx-1 font-size-l"></i>
            <ng-container *ngIf="!tablexParam.minifyButton">
              <i class="fas fa-filter mx-1"></i>
              {{ "global.columns" | translate }}
            </ng-container>
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem  class="mb-2 select-all-col"
              (click)="onSelectFilterCol('all')">
              <ng-container *ngIf="selectedColId.length != tablexParam['fullColNameList'].length">
                {{ "global.select-all" | translate }}
              </ng-container>
              <ng-container *ngIf="selectedColId.length == tablexParam['fullColNameList'].length">
                {{ "global.unselect-all" | translate }}
              </ng-container>
              
            </button>
            <ng-container *ngFor="let colName of tablexParam.fullColNameList; let i=index">
              <button *ngIf="colName.id != 'objId' " ngbDropdownItem [class.active]="selectedColId.includes(colName.id)"
                (click)="onSelectFilterCol(colName.id)">
                {{ colName.name | translate }}
              </button>
            </ng-container>
          </div>
        </div>
    
        <button id="tablex-clear-filter-button" class="btn standard brand-red text-nowrap tablex-button" title="{{ 'global.clear-filter' | translate }}"
          *ngIf="tablexParam.enableClearFilter" (click)="onFilterClear()">
          <img *ngIf="tablexParam.minifyButton" src="../assets/svg/filter-remove.svg" />
          <ng-container *ngIf="!tablexParam.minifyButton">
            <i class="fas fa-trash-alt mx-1"></i>
            {{ "global.clear-filter" | translate }}
          </ng-container>
        </button>
        <ng-container *ngIf="tablexParam.enableSelectedRowCount">
          <button *ngIf="tablexParam.selectedRowCount == 0" class="tablex-button btn standard brand-amber ml-2" id="select-all-button"
            (click)="onClickSelectAll()" [title]="'global.select-all' | translate">
            <ng-container *ngIf="tablexParam.minifyButton">
              <img src="../assets/svg/select-all.svg" />
            </ng-container>
            <ng-container *ngIf="!tablexParam.minifyButton">
              {{ "global.select-all" | translate }}
            </ng-container>
          </button>

          <button *ngIf="tablexParam.selectedRowCount > 0" class="tablex-button btn standard brand-amber ml-2" id="select-all-button"
            (click)="onClickSelectAll()" [title]="'global.unselect-all' | translate">
            <ng-container *ngIf="tablexParam.minifyButton">
              <img  src="../assets/svg/unselect-all.svg" />
            </ng-container>
            <ng-container *ngIf="!tablexParam.minifyButton">
              {{ "global.unselect-all" | translate }}
            </ng-container>
          </button>
        </ng-container>
        
      </div>

      <ul class="pagination px-0 col-auto mr-2" *ngIf="tablexParam.enableSetPageSize">
        <li id='id_tablex_page_li_{{option}}' class="page-item" *ngFor="let option of tablexParam.pageSizeOptions"
          (click)="onPageSizeClicked(option)">
          <p id='id_tablex_page_p_{{option}}' class="page-link"
            [ngClass]="{'active': tablexParam.currentPageSize == option}">{{option}}</p>
        </li>
      </ul>
    </div>

    <div class="table-tools right text-nowrap text-right">
      <div id="selected-cert-label" class="d-inline-block" *ngIf="tablexParam.enableSelectedRowCount">
        {{ "global.selected-row" | translate }}
        <span id="selected-count">{{ tablexParam.selectedRowCount }} / {{ tablexParam.totalRowCount }} </span>
      </div>
      <app-pagination *ngIf="tablexParam.enablePagination" class="ml-4" [maximumPageCount]="tablexParam.pageCount"
        [pageIndex]="tablexParam.currentPage" (onPageChanged)="onPageNumberClicked($event)">
      </app-pagination>
    </div>
  </div>

  <div class="table-wrap {{tablexParam.tableWrapperClass}}">
    <table id="table" class="table table-condensed mt-1 {{tablexParam.tableClass}} "
      [ngClass]="{'sticky-header-table': tablexParam.enableStickyHeader}">
      <thead>
        <tr class="table-header mx-0 align-items-end {{tablexParam.tableRow}}">
          <th id="{{tablexParam.headers[i].id}}" *ngFor="let header of tablexParam.headers; let i=index"
            class="pb-0 {{tablexParam.headers[i].verticalAlign}} {{tablexParam.headers[i].class}}">
            <div class="{{header.horizontalAlign}}" [ngClass]="{'text-break': tablexParam.enableStickyHeader}"
              (click)="onHeaderClicked(i, header)">
              <div *ngIf="header.buttons" class="d-flex justify-content-between align-items-center">
                <div></div>
                <div [ngClass]="{'text-break': tablexParam.enableStickyHeader}">{{header.name | translate}}</div>
                <div>
                  <button *ngFor="let button of header.buttons" id="{{button.id}}"
                    class="{{button.class}} {{(button.hidden?'hidden':'')}} d-inline-block ml-1"
                    [disabled]="button.disable" (click)="$event.stopPropagation();button.onClicked(button)">
                    <i class="{{button.icon}}"></i>{{button.name | translate}}
                  </button>
                </div>
              </div>
              <div *ngIf="!header.buttons" [class.d-block]="tablexParam.enableSort"
                [ngClass]="{'text-break': tablexParam.enableStickyHeader}">
                {{header.name | translate}}
              </div>
            </div>
          </th>
        </tr>
        <tr class="table-header mx-0 align-items-end {{tablexParam.tableRow}}">
          <th id="{{tablexParam.headers[i].id}}" *ngFor="let header of tablexParam.headers; let i=index"
            class="py-0 {{tablexParam.headers[i].verticalAlign}} {{tablexParam.headers[i].class}}">
            <div class="d-flex justify-content-around align-items-center">
              <div *ngIf="header.type=='checkbox' && header.enableSelectCol" class="custom-control custom-checkbox" [ngClass]="header.horizontalAlign">
                <input id="{{header.id}}-select-all-checkbox" type="checkbox" [(ngModel)]="header.selectAll"
                  class="inline-edit active custom-control-input"
                  (change)="onClickSelectCol(header.id, i); header.onSelectAll(header.id)" />
                <label class="custom-control-label" for="{{header.id}}-select-all-checkbox"></label>
              </div>

              <button *ngIf="tablexParam.enableSort && header.enableSort" class="fas fa-caret-up sort-btn d-inline-block"
                [id]="header.id+'-sort-btn'" (click)="onSortOrderChanged(header)"
                [ngClass]="{active: tablexParam.sortBy == header.id, up: tablexParam.sortBy == header.id && tablexParam.sortOrder == 1, down: tablexParam.sortBy == header.id && tablexParam.sortOrder == -1}">
              </button>
            </div>
          </th>
        </tr>
        <tr class="table-header mx-0 align-items-end {{tablexParam.tableRow}}">
          <th id="{{tablexParam.headers[i].id}}" *ngFor="let header of tablexParam.headers; let i=index"
            class="pt-0 {{tablexParam.headers[i].verticalAlign}} {{tablexParam.headers[i].class}}">
            <div *ngIf="header.enableFilter" class="mt-1">
              <input *ngIf="tablexParam.filter && (header.filterType==undefined || header.filterType == 'text')" id='{{header.id}}_filter' placeholder="{{header.placeholder | translate}}" 
                type="text" [(ngModel)]="tablexParam.filter[header.id]" (keyup)="onFilterKeyUp($event, i, header)" 
              />
              <ng-select *ngIf="header.filterType == 'dropdown'" 
                placeholder="{{header.filterDropdownOption.placeholder}}"
                [bindLabel]="header.filterDropdownOption.bindLabel"
                [bindValue]="header.filterDropdownOption.bindValue"
                [multiple]="header.filterDropdownOption.multiple"
                [items]="header.filterDropdownOption.items"
                [(ngModel)]="header.filterDropdownOption.selectedValue"
                [loading]="header.filterDropdownOption.isLoading"
                (change)="header.filterDropdownOption.change($event, i, header)"
                (scrollToEnd)="header.filterDropdownOption.onScrollToEnd()" 
                (search)="header.filterDropdownOption.onSearch($event)"
                [ngModelOptions]="{standalone: true}"
              >
                <ng-template *ngIf="header.showOptionTitle" ng-option-tmp let-item="item">
                  <span title="{{item[header.filterDropdownOption.bindLabel]}}">{{item[header.filterDropdownOption.bindLabel]}}</span>
                </ng-template>
            </ng-select>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let content of tablexParam.content; let i=index">
          <tr *ngIf="!tablexParam.isLoadingTable" (click)="onRowClicked(i, content)"
            class="{{tablexParam.tableRow}} mx-0"
            [attr.data-content]="tablexParam.tableRowDataContents && tablexParam.tableRowDataContents.length > i ? tablexParam.tableRowDataContents[i] : null"
            [ngClass]="getNgClassObj(i)">
            <!-- just keep history -> [ngClass]="{'selected': i == tablexParam.selectedRowIndex,'highlight': tablexParam.highlightedRows && tablexParam.highlightedRows[i]}" -->
            <td *ngFor="let header of tablexParam.headers; let j=index"
              class="text-break {{tablexParam.headers[j].verticalAlign}} {{tablexParam.headers[j].class}} ">

              <!-- text -->
              <div *ngIf="header.type=='text'" class=" {{header.horizontalAlign}}">
                {{content[j]}}
              </div>

              <!-- text with icon -->
              <div *ngIf="header.type=='textIcon'" class=" {{header.horizontalAlign}}">
                <span *ngIf="content[j]?.text"
                  [ngClass]="content[j].class">{{content[j].text}}</span>
                <ng-container *ngIf="content[j]?.icon">
                  <i [ngClass]="content[j].icon" ></i>
                </ng-container>
                <ng-container *ngIf="content[j]?.iconSrc">
                  <img [src]="content[j].iconSrc" [ngClass]="content[j].iconClass" [ngStyle]="content[j].iconStyle" />
                </ng-container>
                <span *ngIf="content[j]?.textAfter"
                  [ngClass]="content[j].class">{{content[j].textAfter}}</span>
              </div>

              <!-- text with error tooltip -->
              <div *ngIf="header.type=='textIconTooltip'" class=" {{header.horizontalAlign}}">
                <span>{{content[j].text}}</span>
                <ng-container *ngIf="content[j].tooltipMsg">
                  <i class="content-icon font-size-xl m-2" [id]="content[j].id" class="expired-icon fas fa-exclamation-circle font-size-s ml-1"
                    [ngbTooltip]="attentionToolTip" placement="top">
                  </i>
                  <ng-template #attentionToolTip>
                    <div class="warning-tooltip-wrap tooltip-inner text-center">
                      {{ content[j].tooltipMsg }}
                    </div>
                  </ng-template>
                </ng-container>
              </div>

              <!-- link -->
              <div *ngIf="header.type=='link'" class="text-break {{header.horizontalAlign}}">
                <a routerLink="{{content[j].href}}">
                  {{content[j].name}}
                </a>
              </div>

              <!-- html -->
              <div *ngIf="header.type=='html'" [innerHTML]="content[j]" class="text-break {{header.horizontalAlign}}">
              </div>

              <!-- mutiple lines -->
              <div *ngIf="header.type=='multiLine'" class="text-break {{header.horizontalAlign}}">
                <div *ngFor="let contentValue of content[j]">
                  <ng-container *ngIf="contentValue && !contentValue.type">
                    {{contentValue}}<br>
                  </ng-container>
                  <ng-container *ngIf="contentValue && contentValue.type=='html'">
                    <div [innerHTML]="contentValue.value" class="text-break {{header.horizontalAlign}}"></div>
                  </ng-container>
                  <ng-container *ngIf="contentValue && contentValue.type=='buttons'">
                    <button id='{{button.id}}' *ngFor="let button of contentValue.buttons"
                      class="{{button.class}} {{(button.hidden?'hidden':'')}}" [disabled]="button.disable"
                      title="{{button.title}}" (click)="$event.stopPropagation();onButtonClicked(button)">
                      <i class="{{button.icon}}"></i>{{button.name}}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="contentValue && contentValue.type=='icons'">
                    {{contentValue.value}}
                    <ng-container *ngFor="let icon of contentValue.icons">
                      <i class="{{icon.class}}" title="{{icon.tooltip}}">
                      </i>
                    </ng-container>
                  </ng-container>
                </div>
              </div>

              <!-- buttons -->
              <div *ngIf="header.type=='buttons'" class="{{header.horizontalAlign}}">
                <span class="row m-0 justify-content-around">
                  <ng-container *ngFor="let button of content[j]">
                    <button id="{{button.id}}" *ngIf="button.show != undenfined ? button.show : true"
                      class="{{button.class}}" title="{{button.title}}" [disabled]="button.disable || button.isLoading"
                      (click)="$event.stopPropagation();onButtonClicked(button, i)">
                      <span *ngIf="button.isLoading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span *ngIf="button.name && button.name != ''">{{'global.loading' | translate}}</span>
                      </span>
                      <span *ngIf="!button.isLoading"><i class="{{button.icon}}"></i>{{button.name | translate}}</span>
                    </button>
                  </ng-container>
                </span>
              </div>

              <!-- BorderLabel -->
              <div *ngIf="header.type=='borderLabel'" class="text-break {{header.horizontalAlign}}">
                <div id="draggable-events">
                  <div *ngFor="let tag of content[j]" mwlDraggable (dragStart)="onDragStart(tag)"
                    (dragEnd)="onDragEnd(tag)" (click)="$event.stopPropagation();onBorderLabelClicked(i, tag)"
                    class="{{tag.class}}">
                    {{tag.name}}
                  </div>
                </div>
              </div>

              <!-- BorderLabelAddButton - horizontal and vertical align must be center-->
              <div *ngIf="header.type=='borderLabelAddButton'" class="text-break text-center">
                <div class="d-flex justify-content-between align-items-center">
                  <div></div>
                  <div>
                    <div id="draggable-events">
                      <div *ngFor="let tag of content[j].label" class="{{tag.class}}">
                        {{tag.name}}
                      </div>
                    </div>
                  </div>
                  <div class="ml-2" *ngIf="!header.disableButton">
                    <button id="{{content[j].id}}" class="glyph brand-blue"
                      (click)="$event.stopPropagation();onBorderLabelAddClicked(i,j, content)"
                      [disabled]="content[j].disable">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>

              <!-- input -->
              <div *ngIf="header.type=='input'" class="text-break {{header.horizontalAlign}}">
                <div *ngIf="content[j].enable" class="justify-content-around">
                  <input id="r{{j}}_{{content[j].id}}"
                    class="inline-edit active {{content[j].class}}"
                    placeholder="{{content[j].placeholder}}"
                    maxlength="{{content[j].maxlength}}"
                    [type]="content[j].type?content[j].type:text"
                    [(ngModel)]="content[j].value"
                    (change)="content[j].onChange && content[j].onChange($event, content)"
                    (keydown)="content[j].onKeydown && content[j].onKeydown($event)"
                    (keyup)="content[j].onKeyup && content[j].onKeyup($event)"
                  >
                  <ng-container *ngIf="content[j].tooltipMsg">
                    <i class="content-icon font-size-xl m-2" class="expired-icon fas fa-exclamation-circle font-size-s ml-1"
                      [ngbTooltip]="attentionToolTip" placement="top">
                    </i>
                    <ng-template #attentionToolTip>
                      <div class="warning-tooltip-wrap tooltip-inner text-center">
                        {{ content[j].tooltipMsg }}
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
                <ng-container *ngIf="!content[j].enable">
                  {{content[j].value}}
                </ng-container>
              </div>

              <!-- checkbox -->
              <div *ngIf="header.type=='checkbox'" class=" {{header.horizontalAlign}}">
                <div class="justify-content-around custom-control custom-checkbox">
                  <input id="{{content[j].id}}" type="checkbox" [(ngModel)]="content[j].value"
                    [ngModelOptions]="{standalone: true}" class="inline-edit active custom-control-input"
                    (change)="content[j].onChange($event)" />
                  <label class="custom-control-label" for="{{content[j].id}}"></label>
                </div>
              </div>

              <!-- dropdown -->
              <div *ngIf="header.type=='dropdown'" class=" {{header.horizontalAlign}}">
                <div class="justify-content-around">
                  <select *ngIf="!content[j].fixed"
                    id="{{content[j].id}}"
                    [(ngModel)]="content[j].value"
                    class="{{content[j].class}}"
                    (change)="content[j].onChange(content)">
                    <option *ngFor="let option of content[j].options" [value]="option.value">
                      {{option.label}}
                    </option>
                  </select>
                  <span *ngIf="content[j].fixed"
                    id="{{content[j].id}}">
                      {{content[j].value}}
                  </span>
                </div>
              </div>

              <!-- icon -->
              <div *ngIf="header.type=='icon'" class="{{header.horizontalAlign}}">
                <span class="row m-0">
                  <ng-container *ngFor="let icon of content[j]">
                    <i class="content-icon font-size-xl m-2" [id]="icon.id" [ngClass]="icon.iconClass"
                      [title]="icon.title" [ngbTooltip]="attentionToolTip" placement="top">
                      <i *ngIf="icon.attention" class="expired-icon fas fa-exclamation-circle font-size-m mx-2"
                        [title]="icon.title"></i>
                    </i>
                    <ng-template #attentionToolTip>
                      <div class="warning-tooltip-wrap tooltip-inner text-center">
                        {{ icon.title }}
                      </div>
                    </ng-template>
                  </ng-container>
                </span>
              </div>

              <div *ngIf="header.type=='hyperlink'" class="text-break {{header.horizontalAlign}}">
                <a routerLink="{{content[j].url}}">
                  {{content[j].value}}
                </a>
              </div>

              <!-- datetime -->
              <div *ngIf="header.type=='dateTime'" class="{{header.horizontalAlign}}">
                {{content[j] | date:'yyyy-MM-dd HH:mm'}}
              </div>

            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <div *ngIf="tablexParam.isLoadingTable"
      class="text-center m-5">
      <div class="loading-spinner mt-5">
        <span class="sr-only">{{ "global.loading" | translate }}</span>
      </div>
    </div>
    <div *ngIf="!tablexParam.isLoadingTable && (tablexParam.content == null || tablexParam.content.length == 0)"
      class="no-record-label">
      {{"global.no-record-found" | translate}}
    </div>
  </div>
</div>
