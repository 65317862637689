import { Component, OnInit, Input, ViewChild, Injector } from '@angular/core';
import { InformationDialogItemType } from '@enum/information-dialog-item-type';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { BasePage } from '../../model/base/base';
import { InformationDialogHelper } from '../information-dialog/information-dialog-helper';

@Component({
  selector: 'app-routing-detail',
  templateUrl: './routing-detail.component.html',
  styleUrls: ['./routing-detail.component.scss']
})
export class RoutingDetailComponent extends BasePage implements OnInit {
  @ViewChild("manual_instruction_detail", { static: true }) manualInstructionDetail;

  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Input() currentPageMode: JMENUM.JMPageMode = undefined;

  pageMode = JMENUM.JMPageMode;

  manualInstructionDetailData: any = {
    team: undefined,
    priority: undefined,
  };

  teamStatus = JMENUM.TeamStatus;

  venderName = undefined;
  hashTag: JMOBJ.HashTag = undefined;
  hashTagName = undefined;
  equipmentType = undefined;
  location = undefined;
  team = undefined;
  client = undefined;
  district = undefined;
  workCenter = undefined;
  workCenterDesc = undefined;
  contactSequences = undefined;
  contractor = undefined;
  manualInstructions = undefined;

  //tablex config in info box------------------------------------------------------------------------
  inhouseMemberTablexParam = {
    isLoadingTable: false,
    enableSetPageSize: false,
    enablePagination: false,
    currentPage: 1,
    pageCount: 1,
    headers: [
      { id: "contact", name: 'popup.contact-list.column.contact', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: "post", name: 'popup.contact-list.column.post', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: "name", name: 'popup.contact-list.column.name', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: "phone", name: 'popup.contact-list.column.phone', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: "mobile", name: 'popup.contact-list.column.mobile', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: "fax", name: 'popup.contact-list.column.efax', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: "email", name: 'popup.contact-list.column.email', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: "remark", name: 'popup.contact-list.column.remark', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
    ],
    content: []
  }
  nonPmsmcMemberTablexParam = {
    isLoadingTable: false,
    enableSetPageSize: false,
    enablePagination: false,
    currentPage: 1,
    pageCount: 1,
    headers: [
      { id: 'member-name', name: 'popup.contact-list.column.member-name', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'team-name', name: 'popup.contact-list.column.team-name', type: TablexColumnType.MultiLine, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'role', name: 'popup.contact-list.column.role', type: TablexColumnType.MultiLine, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'phone', name: 'popup.contact-list.column.phone', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'fax', name: 'popup.contact-list.column.fax', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'email', name: 'popup.contact-list.column.email', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
    ],
    content: []
  }
  pmsmcMemberTablexParam = {
    isLoadingTable: false,
    enableSetPageSize: false,
    enablePagination: false,
    currentPage: 1,
    pageCount: 1,
    headers: [
      { id: 'member-name', name: 'popup.contact-list.column.member-name', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'rmarks', name: 'popup.contact-list.column.remark', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'role', name: 'popup.contact-list.column.role', type: TablexColumnType.MultiLine, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'phone', name: 'popup.contact-list.column.phone', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'fax', name: 'popup.contact-list.column.fax', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      { id: 'email', name: 'popup.contact-list.column.email', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
    ],
    content: []
  }
  //-------------------------------------------------------------------------------------------------

  emptyHandle = JMLanguage.translate('component.display-text-field.not-selected');

  constructor(injector: Injector) { super(injector); }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.sn.team) {
      this.team = this.sn.team.name + " (" + this.sn.team.workCentre + ")";
      if (this.sn.maintenanceType == JMENUM.MaintenanceType.CM) {
        this.contactSequences = (this.sn.team.contactSequences["" + this.sn.priority]) ? this.sn.team.contactSequences["" + this.sn.priority] : undefined;
      }
      else if (this.sn.maintenanceType == JMENUM.MaintenanceType.PM) {
        this.contactSequences = (this.sn.team.contactSequences[JMENUM.HandlingTeam.PM]) ? this.sn.team.contactSequences[JMENUM.HandlingTeam.PM] : undefined;
      }
      if (this.sn.team.enableManualInstructions) {
        this.manualInstructions = (this.sn.team.manualInstructions) ? this.sn.team.manualInstructions : undefined;
        this.manualInstructionDetailData.team = this.sn.team;
        this.manualInstructionDetailData.priority = this.sn.priority;
      }
      this.requestVenderName();
      let workCenter = JM.JMConnector.getWorkCentre(this.sn.team.workCentre);
      this.workCenter = (workCenter) ? workCenter.workCentreCode : undefined;
      this.workCenterDesc = (this.workCenter.description) ? this.workCenter.description : undefined;
    }
    this.client = (this.sn.client) ? this.sn.client : undefined;
    this.district = (this.sn.district) ? JMLanguage.translate('district.' + this.sn.district) : undefined;
    if (this.sn.equipmentType) {
      this.equipmentType = this.sn.equipmentType + " (" + JM.JMConnector.getEquipmentType(this.sn.equipmentType).description[Session.selectedLanguage] + ")";
    }
    this.requestEquipmentTag();
    this.requestLocationSummary();
  }

  private requestVenderName() {
    if (this.sn.team.contractNumber) {
      let request = new JM.JMRequestContractsMaintenanceTermContractSummary();
      request.contractNumber = [this.sn.team.contractNumber];
      request.expired = JMENUM.RequestExpired.BOTH;
      request.parameters = ["contractNumber", "vendorName"];

      JM.JMConnector.sendContractsMaintenanceTermContractSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseContractsMaintenanceTermContractSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
          AppDelegate.openErrorBar(response);
          return;
        }

        this.venderName = (response.payload.records[0]) ? response.payload.records[0].vendorName : undefined;
        if (this.sn.team.contractNumber) {
          this.contractor = (this.venderName) ? this.sn.team.contractNumber + " - " + this.venderName : this.sn.team.contractNumber;
        }
      });
    }
  }

  private requestEquipmentTag() {
    if (this.sn.hashtagId) {
      let request = new JM.JMRequestHashtagsGetHashtags();
      request.hashtagIdList = [this.sn.hashtagId];
      JM.JMConnector.sendHashtagsGetHashtags(request, (error: JM.JMNetworkError, response: JM.JMResponseHashtagsGetHashtags) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        if (response.payload.records.length > 0) {
          this.hashTag = response.payload.records[0];
          this.hashTagName = this.hashTag.description[Session.selectedLanguage];
        }
        if (!this.sn.equipmentType) {
          this.equipmentType = (this.hashTag && this.hashTag.equipmentType) ? this.hashTag.equipmentType + " (" + JM.JMConnector.getEquipmentType(this.hashTag.equipmentType).description[Session.selectedLanguage] + ")" : undefined;
        }

      });
    }
  }

  private requestLocationSummary() {
    if (this.sn.location) {
      let request: JM.JMRequestLocationsLocationSummary = new JM.JMRequestLocationsLocationSummary();
      request.location = [this.sn.location];

      JM.JMConnector.sendLocationsLocationSummaryPromise(request, (error: JM.JMNetworkError, response: JM.JMResponseLocationsLocationSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        if (!response.payload.records || !response.payload.records.length) return;

        if (response.payload.records && response.payload.records.length > 0) {
          this.location = (response.payload.records[0]) ? response.payload.records[0].description[Session.selectedLanguage] + " (" + response.payload.records[0].code + ")" : undefined;
        } else {
          console.error("location not found " + request.location);
        }
      });
    }
  }

  public onInformationButtonClicked() {
    if (!this.contactSequences) {
      AppDelegate.openErrorBar();
      return;
    }
    const team: JMOBJ.Team = this.sn.team;
    const contactSequences = this.contactSequences;
    const dialogTitle = '';

    const inhouseHandlingParty = team.handlingParty == JMENUM.HandlingParty.INHOUSE;
    const pmsmcHandlingParty = team.handlingParty == JMENUM.HandlingParty.PMSMC;
    const postNameSet = new Set<string>();
  
    const requestContactPersonList = async (postNameSet: Set<string>) => {
      const request = new JM.JMRequestGetContactPersonsByPost();
      request.posts = Array.from(postNameSet);
  
      const response: JM.JMResponseGetContactPersonsByPost = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
  
      return response.payload;
    };
  
    for (let i = 0; i < 5; i++) {
      if (!contactSequences[i]) { continue; }
      contactSequences[i].forEach((post: string) => {
        postNameSet.add(post);
      });
    }
  
    if (inhouseHandlingParty && postNameSet.size == 0) {
      let content = [InformationDialogHelper.createTablex(this.inhouseMemberTablexParam)];
      this.showInformationDialog(dialogTitle, content);
      return;
    }
  
    requestContactPersonList(postNameSet).then(contactPersonList => {
      const contactPersonMap = new Map<string, JMOBJ.ContactPerson>();
      const contactSequenceName = [
        JMLanguage.translate('pages.sn.routing-contact-list-table.contractor'),
        JMLanguage.translate('pages.sn.routing-contact-list-table.1st'),
        JMLanguage.translate('pages.sn.routing-contact-list-table.2nd'),
        JMLanguage.translate('pages.sn.routing-contact-list-table.3rd'),
        JMLanguage.translate('pages.sn.routing-contact-list-table.ro')
      ];
  
      let dialogContent = [];
      let inhouseMembertableContent = [];
      let nonPmsmcMemberTableContent = [];
  
      const getPmsmcMemberContent = (sn: JMOBJ.ServiceNotification) => {
        if (!Array.isArray(sn.vendorTeamMemberList)) { return []; }
        return sn.vendorTeamMemberList.map((m) => {
          return [
            m.name,
            m.remarks,
            m.roleNameList ? m.roleNameList : [],
            m.phone,
            m.fax,
            m.email,
          ];
        });
      };
  
      contactPersonList.forEach(cp => {
        contactPersonMap.set(cp.post, cp);
      });
  
      for (let i = 0; i < 5; i++) {
        for (let j = 0; j < contactSequences[i].length; j++) {
          const contact = contactPersonMap.get(contactSequences[i][j]);
          if (!contact) { continue; }
          if (i == 0) { // contractor 
            const data = [
              contact.name,
              [],
              [],
              contact.phone,
              contact.fax,
              contact.email
            ];
            nonPmsmcMemberTableContent.push(data);
          } else {
            const data = [
              contactSequenceName[i],
              contactSequences[i][j],
              contact.name,
              contact.phone,
              contact.mobile,
              contact.fax,
              contact.email,
              contact.remarks
            ];
            inhouseMembertableContent.push(data);
          }
        }
      }
  
      this.inhouseMemberTablexParam.content = inhouseMembertableContent;
      this.nonPmsmcMemberTablexParam.content = nonPmsmcMemberTableContent;
      this.pmsmcMemberTablexParam.content = getPmsmcMemberContent(this.sn);
  
      if (inhouseHandlingParty) {
        dialogContent = [InformationDialogHelper.createTablex(this.inhouseMemberTablexParam)];
      } else {
        const contractMemberTablexParam = pmsmcHandlingParty ? this.pmsmcMemberTablexParam : this.nonPmsmcMemberTablexParam;
        dialogContent = [
          InformationDialogHelper.createText(this.translate('pages.sn.routing-contact-list-table.contractor-contact-list')),
          InformationDialogHelper.createTablex(contractMemberTablexParam),
          InformationDialogHelper.createText(this.translate('pages.sn.routing-contact-list-table.inhouse-contact-list')),
          InformationDialogHelper.createTablex(this.inhouseMemberTablexParam)
        ];
      }
  
      this.showInformationDialog(dialogTitle, dialogContent);
    });
  }

  public onManualInstructionButtonClicked() {
    this.manualInstructionDetail.toggle();
  }

}
