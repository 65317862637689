<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [customItems]='sideBarItem'></app-sidebar>

  <div *ngIf="false" class="mr-2 btn-group btn-group-toggle"  id="equipment-tagList-options" ngbRadioGroup name="usable-options" (change)="onChangeUsableOption()" [(ngModel)]="selectUsableOption">
    <label ngbButtonLabel class="radio-tag brand-blue">
      <input ngbButton type="radio" [value]="usableOption.usable" id="usable-equipment-tagList"> Usable Tags
    </label>
    <label ngbButtonLabel class="radio-tag brand-blue">
      <input ngbButton type="radio" [value]="usableOption.unusable" id="unusable-equipment-tagList"> Unusable Tags
    </label>
</div>

  <div id="content">
    <div *ngIf="haCluster==null">
      <div>This work Centre does not belong to any cluster</div>
    </div>

    <div *ngIf="haCluster">
      <app-tablex #id_tag_table [tablexParam]='tablexParam' class='mt-3'></app-tablex>
    </div>

    <custom-slider-panel #add_tag_panel [widthPx]=600 [customClass]="'add_tag_panel'">
      <add-tag-form #addTagForm [tagType]="'ha'"></add-tag-form>
    </custom-slider-panel>

  </div>
</div>