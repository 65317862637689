<div>
  <button id="update-equipment-btn" class="btn brand-amber ml-auto d-block" [disabled]="uiIsSaving" (click)="onSaveButtonClicked()">
    <span>{{ "global.submit" | translate }}</span>
  </button>
  <div *ngIf="mode === 'ccs' && isShowMsgNoEquipment" class="mt-2 mx-3 red">
    {{ "pages.equipment-list.no-equipment-matched" | translate }}
  </div>

  <app-custom-fields-form #edit_equipment_form class="edit-equipment-form-elem" 
    [parameters]="editEquipmentFormParam" 
    [userInput]="editEquipmentFormInput" >
  </app-custom-fields-form>

</div>