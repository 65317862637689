import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import * as moment from 'moment';
import { pad, toNgbDate } from 'src/lib/presenter/Formatter';
import { NgbModal, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ModalHaEquipmentDetailsComponent } from '../modal-ha-equipment-details/modal-ha-equipment-details.component';
import { DatepickerFormatter } from '../../../presenter/datepickerFormatter';
import { formatHAContractEndDateToDateString, formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
import { AuthorizationService } from '@services/authorization.service';


@Component({
  selector: 'app-ha-infomation-detail',
  templateUrl: './ha-infomation-detail.component.html',
  styleUrls: ['./ha-infomation-detail.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DatepickerFormatter }
  ]
})
export class HaInfomationDetailComponent implements OnInit {

  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Input() isPendingApproval: boolean = false;
  @Input() currentPageMode: JMENUM.JMPageMode = undefined;

  @Output() onValidation: EventEmitter<any> = new EventEmitter();

  valid = false;

  pageMode = JMENUM.JMPageMode;
  maintenanceType = JMENUM.MaintenanceType;


  eamData = undefined;
  hospitalCode = undefined;
  assetDescription = undefined;
  manufacturer = undefined;
  brandName = undefined;
  haModel = undefined;
  haContractNumber = undefined;
  locationCode = undefined;
  serialNumber = undefined;
  cmBreakdownDate = undefined;
  pmScheduleDate = undefined;
  haWorkOrderNumber = undefined;
  assetNumber = undefined;
  replyToHA = undefined;

  haEquipment: any = {}; 
  hsdEquipment: any;
  haEquipmentContractExpired = false;
  ccsInfo: any;

  //for edit
  dateInputSet = { pmScheduleDate: undefined, cmBreakdownDate: undefined };
  timeInputSet = { pmScheduleDate: undefined, cmBreakdownDate: undefined };
  pmScheduleDateModel = undefined;
  pmScheduleHourModel = undefined;
  pmScheduleMinModel = undefined;
  cmBreakdownDateModel = undefined;
  cmBreakdownHourModel = undefined;
  cmBreakdownMinModel = undefined;

  editable = {
    hospitalCode: false,
    assetDescription: false,
    manufacturer: false,
    brandName: false,
    haModel: false,
    haContractNumber: false,
    locationCode: false,
    serialNumber: false,
    haWorkOrderNumber: false,
    assetNumber: false,
    replyToHA: false,
    pmScheduleDate: false,
    cmBreakdownDate: false
  }

  errorFields = {
    pmScheduleDateModel: false,
    pmScheduleHourModel: false,
    pmScheduleMinModel: false,
    cmBreakdownDateModel: false,
    cmBreakdownHourModel: false,
    cmBreakdownMinModel: false,
  };

  emptyHandleNa = JMLanguage.translate('component.display-text-field.na');
  emptyHandle = JMLanguage.translate('component.display-text-field.not-selected');

  textYes = JMLanguage.translate('global.yes');
  textNo = JMLanguage.translate('global.no');

  hasHAOrderReplyPermission = false;
  isResending = false;
  isRegenerating = false;

  constructor(private modalService: NgbModal, private parserFormatter: NgbDateParserFormatter, private dateAdapter: NgbDateAdapter<string>, private _authService: AuthorizationService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.resetAllErrorFields();
    if (this.sn.eamData && this.sn.maintenanceType) {
      this.eamData = this.sn.eamData;
      this.assetNumber = (this.sn.eamData.assetNumber) ? this.sn.eamData.assetNumber : null;
      this.haWorkOrderNumber = (this.sn.eamData.HAWorkOrderNumber) ? this.sn.eamData.HAWorkOrderNumber : null;
      this.replyToHA = (this.sn.eamData.replyToHA !== undefined) ? this.sn.eamData.replyToHA : null;
      // handle cm & pm
      switch (this.sn.maintenanceType) {
        case JMENUM.MaintenanceType.CM:
          this.cmBreakdownDate = undefined;
          let cmBreakdownDate: any = this.sn.eamData.cmBreakdownDate;

          if (cmBreakdownDate) {
            this.cmBreakdownDate = formatDateTimeWithWeek(cmBreakdownDate);
            let time = moment(cmBreakdownDate);
            this.dateInputSet['cmBreakdownDate'] = toNgbDate(cmBreakdownDate);
            this.timeInputSet['cmBreakdownDate'] = pad(time.hour()) + ':' + pad(time.minute());
          }
          break;
        case JMENUM.MaintenanceType.PM:
          this.pmScheduleDate = undefined;
          let pmScheduleDate: any = this.sn.eamData.pmScheduleDate;

          if (pmScheduleDate) {
            this.pmScheduleDate = formatDateTimeWithWeek(pmScheduleDate);
            let time = moment(pmScheduleDate);
            this.dateInputSet['pmScheduleDate'] = toNgbDate(pmScheduleDate);
            this.timeInputSet['pmScheduleDate'] = pad(time.hour()) + ':' + pad(time.minute());
          }

          break;
      }
      this.requestHSDEquipmentSummary();
    } 
    this.fieldControl();
    this.validation();
    this.hasHAOrderReplyPermission = this._authService.hasPermission(JMENUM.Permission.SN_ORDERREPLY_RESEND);
  }

  public onBlurDateInput(event){
    this.dateInputSet[event.field] = event.data;
    this.setDateTime(event.field);
  }

  public onBlurTimeInput(event){
    this.timeInputSet[event.field] = event.data;
    this.setDateTime(event.field);
  }

  private fieldControl() {
    if (this.currentPageMode != JMENUM.JMPageMode.EDIT || !this.sn || this.isPendingApproval) { 
      return; 
    }

    let isHaSn = this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA;
    let isPMSn = this.sn.maintenanceType == JMENUM.MaintenanceType.PM;
    let isCMSn = this.sn.maintenanceType == JMENUM.MaintenanceType.CM;
    
    this.editable.pmScheduleDate = isHaSn && isPMSn && this.sn.sourceSystem == "CCEPJM";
    this.editable.cmBreakdownDate = isHaSn && isCMSn && (!this.sn.eamData || !this.sn.eamData.HAWorkOrderNumber);
  }

  public assignInputToValue(key, newValue) {
    this.resetErrorField(key);
    if (newValue) {
      this.sn.eamData[key] = newValue;
    }
    else {
      this.errorFields[key] = true;
    }
    this.validation();
  }

  public resetAllErrorFields() {
    this.valid = true;
    this.errorFields = {
      pmScheduleDateModel: false,
      pmScheduleHourModel: false,
      pmScheduleMinModel: false,
      cmBreakdownDateModel: false,
      cmBreakdownHourModel: false,
      cmBreakdownMinModel: false,
    };
  }

  public resetErrorFields(keys: string[]) {
    keys.forEach(key => this.resetErrorField(key));
  }

  public resetErrorField(key) {
    this.errorFields[key] = false;
  }

  
  public setDateTime(field) {
    this.resetErrorField(field);
    if(this.dateInputSet[field] && this.timeInputSet[field]){
      let date = this.dateInputSet[field];
      let timeArr =  this.timeInputSet[field].split(':');
      let formattedDate = new Date(date.year, date.month - 1, date.day);
      if(timeArr && timeArr.length > 0){
        formattedDate.setHours(timeArr[0]);
        formattedDate.setMinutes(timeArr[1]);
        this.sn.eamData[field] = new Date(formattedDate);
      }
    }

    this.validation();
  }

  public dateTimeValidation(field){
    this.errorFields[field] = false;

    //Check if have date & time
    if( (this.dateInputSet[field] && !this.timeInputSet[field]) 
      || (this.timeInputSet[field] && !this.dateInputSet[field])
    ){
        this.errorFields[field] = true;
        return false;
    }
    return true;
  }

  public validation() {
    let hasErrorField =  false;
    let fields = {... this.editable};

    for(let field in fields){
      let dateFormatErr = this.dateTimeValidation(field);
      if((fields[field] && this.errorFields[field]) || !dateFormatErr){
        hasErrorField = true;
      }
    }

    for (let i in this.errorFields) {
      if (this.errorFields[i] === true) {
        hasErrorField = true;
        break;
      }
    }
    this.valid = !hasErrorField;
    this.onValidation.emit(this.valid);
  }

  private requestHSDEquipmentSummary() {
    if (this.sn.eamData.assetNumber) {
      let request: JM.JMRequestEquipmentsHSDEquipmentSummary = new JM.JMRequestEquipmentsHSDEquipmentSummary();
      request.hasHAEquipment = true;
      request.assetNumber = [this.sn.eamData.assetNumber];
      JM.JMConnector.sendEquipmentsHSDEquipmentSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseEquipmentsHSDEquipmentSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }
        
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        
        // handles hsd backorder: Asset info with no HAWorkOrderNumber
        if (response.payload.totalCount > 0) {
          this.haEquipment = response.payload.records[0]['HAEquipment'];
          this.hsdEquipment = response.payload.records[0];
          if (this.haEquipment) {
            // Get HAEquipment Info
            if (this.haEquipment["contractEndDate"]) {
              let contractEndDate = formatHAContractEndDateToDateString(this.haEquipment["contractEndDate"]);
              let currentDate = new Date();
              let diffTime = currentDate.getTime() - contractEndDate.getTime();
              let diffDays = Math.round(diffTime / (1000 * 3600 * 24));
              this.haEquipmentContractExpired = diffDays > 0;
            }
          }
          if (response.payload.records[0]['equipment'] && response.payload.records[0]['equipment']['equipmentNumber']) {
            this.requestEquipmentSummaryByNumber(response.payload.records[0]['equipment']['equipmentNumber']);
          }          
        }
        
        // Get XML for HA Information
        if(this.sn.eamData.haWorkOrderXML || this.haEquipment){
          this.hospitalCode = (this.sn.eamData.hospitalCode) ? this.sn.eamData.hospitalCode : (this.haEquipment && this.haEquipment.hospitalCode) ? this.haEquipment.hospitalCode : this.hsdEquipment.hospitalLocation;
          this.assetDescription = (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.assetDescription) ? this.sn.eamData.haWorkOrderXML.assetDescription : (this.haEquipment) ? this.haEquipment.assetDescription : null;
          this.manufacturer = (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.manufacturer) ? this.sn.eamData.haWorkOrderXML.manufacturer : (this.haEquipment) ? this.haEquipment.manufacturer : null;
          this.brandName = (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.brandName) ? this.sn.eamData.haWorkOrderXML.brandName : (this.haEquipment) ? this.haEquipment.brand : null;
          this.haModel = (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.model) ? this.sn.eamData.haWorkOrderXML.model : (this.haEquipment) ? this.haEquipment.model : null;
          this.haContractNumber = (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.HAContractNumber) ? this.sn.eamData.haWorkOrderXML.HAContractNumber : (this.haEquipment) ? this.haEquipment.HAContractNumber : null;
          this.serialNumber = (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.serialNumber) ? this.sn.eamData.haWorkOrderXML.serialNumber : (this.haEquipment) ? this.haEquipment.serialNumber : null;
          this.locationCode = (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.locationCode) ? this.sn.eamData.haWorkOrderXML.locationCode : (this.haEquipment) ? this.haEquipment.locationCode : null;
          // modal information 
          //manufacturer
          this.haEquipment.manufacturer =  (this.haEquipment && this.haEquipment.manufacturer) ? this.haEquipment.manufacturer : (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.manufacturer) ? this.sn.eamData.haWorkOrderXML.manufacturer : null;
          //brand
          this.haEquipment.brand =  (this.haEquipment && this.haEquipment.brand) ? this.haEquipment.brand : (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.brandName) ? this.sn.eamData.haWorkOrderXML.brandName : null;
          //model
          this.haEquipment.model =  (this.haEquipment && this.haEquipment.model) ? this.haEquipment.model : (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.model) ? this.sn.eamData.haWorkOrderXML.model : null;
          //serialNumber
          this.haEquipment.serialNumber =  (this.haEquipment && this.haEquipment.serialNumber) ? this.haEquipment.serialNumber : (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.serialNumber) ? this.sn.eamData.haWorkOrderXML.serialNumber : null;
          //HAContractNumber
          this.haEquipment.HAContractNumber =  (this.haEquipment && this.haEquipment.HAContractNumber) ? this.haEquipment.HAContractNumber : (this.sn.eamData.haWorkOrderXML && this.sn.eamData.haWorkOrderXML.HAContractNumber) ? this.sn.eamData.haWorkOrderXML.HAContractNumber : null;
          }
      });
    }
  }

  public requestEquipmentSummaryByNumber(equipmentNumber) {
    let request: JM.JMRequestEquipmentsEquipmentSummary = new JM.JMRequestEquipmentsEquipmentSummary();
    request.equipmentNumber = [equipmentNumber];
    request.parameters = ["equipmentNumber", "description", "equipmentType", "location", "clientShortName", "isActive"];
    request.active = JMENUM.RequestActive.BOTH;

    JM.JMConnector.sendEquipmentsEquipmentSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseEquipmentsHSDEquipmentSummary) => {
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }

      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (response.payload.totalCount > 0) {
        this.ccsInfo = response.payload.records[0];
      }
    });
  }
    
  public onClickHADetails(): void {
    let modalRef = this.modalService.open(ModalHaEquipmentDetailsComponent, { backdrop: 'static', centered: true, size: 'xl' });
    modalRef.componentInstance.haEquipment = this.haEquipment;
    modalRef.componentInstance.ccsInfo = this.ccsInfo;
  }

  async resendHAOrderReply() {
    this.isResending = true;
    const request: JM.JMRequestResendOrderReply = new JM.JMRequestResendOrderReply();
    request.snNumber = this.sn.snNumber;
    let response = await AppDelegate.sendJMRequest(request);
    this.isResending = false;
    if (!response || response.error || !response.code || response.code != 200) {
      const errCode = response.code || 99999;
      let msg = JMLanguage.translate('api.error.'+ errCode);
      if (msg === 'api.error.'+ errCode) {
        msg = response.error;
      }
      AppDelegate.openSnackBar(msg);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate('global.success'));
  }

  async regenerateHAOrderReply() {
    this.isRegenerating = true;
    const request: JM.JMRequestRegenerateOrderReply = new JM.JMRequestRegenerateOrderReply();
    request.snNumber = this.sn.snNumber;
    let response = await AppDelegate.sendJMRequest(request);
    this.isRegenerating = false;
    if (!response || response.error || !response.code || response.code != 200) {
      const errCode = response.code || 99999;
      let msg = JMLanguage.translate('api.error.'+ errCode);
      if (msg === 'api.error.'+ errCode) {
        msg = response.error;
      }
      AppDelegate.openSnackBar(msg);
      return;
    }
    AppDelegate.openSnackBar(JMLanguage.translate('global.success'));
  }

}

