import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Attachment } from 'src/app/entity/data-model/attachment';
import { saveAs } from "file-saver";
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AuthorizationService } from '@services/authorization.service';
import { ActivatedRoute } from '@angular/router';
import { formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
@Component({
  selector: 'app-jobcard-attachment',
  templateUrl: './jobcard-attachment.component.html',
  styleUrls: ['./jobcard-attachment.component.scss']
})
export class JobcardAttachmentComponent extends BasePage implements OnInit {
  @ViewChild('attachment_preview', { static: true }) attachmentPreview;
  @ViewChild('upload_previewer', { static: true }) uploadPreviewer;
  protected authorizationService: AuthorizationService;
  protected route: ActivatedRoute;
  
  snNumber: string;
  jobCardNumber: string;
  jobCard: JMOBJ.JobCard;

  file: any;
  fileList: any = {};
  fileDetails: any = {};

  attachment: Attachment;
  selectedAttachment: any;
  attachmentList: any = [];

  uploadPreviewParam: any = {};

  navbarTitle: string;
  breadcrumbs: any = [];
  tablexParam: any = {};

  isDisabledAddAttachment: boolean = false;
  enableRemoveAttachment: boolean = true;
  isPreviewerLoading: boolean = false;
  hasViewPermission: boolean = false;
  hasEditPermission: boolean = true;
  isShowUploadPreview: boolean = false;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    this.checkViewPermission(JMENUM.Permission.JOBCARD_VIEW);
    
    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');

    this.initBreadcrumbs();
    this.initTable();
    await this.requestJobCardSummary();
  }

  private initBreadcrumbs = () => {
    let arr = [];
    this.snNumber && arr.push({ id: 'breadcrumbs-sn-number', name: this.snNumber, route: '/sn/view/' + this.snNumber });
    arr.push({ id: 'breadcrumbs-job-card-number', name: this.jobCardNumber, route: '/job-card/view/'+ this.jobCardNumber });
    arr.push({ id: 'breadcrumbs-attchment', name: JMLanguage.translate('action.button.attachment'), route: null , currentPage: true });
    this.breadcrumbs = arr;
  }

  initPermission() {
    // Can view by: In-house + In-progress / cancelling ; Non-P + In-progress
    // Standalone : In-progress / cancelling / reworking / completed (no remove attachment)
    let handlingPartyList = [
      JMENUM.HandlingParty.INHOUSE,
      JMENUM.HandlingParty.NON_PMSMC
    ];

    let statusList = [
      JMENUM.JobCardStatus.IN_PROGRESS,
      JMENUM.JobCardStatus.CANCELLING,
      JMENUM.JobCardStatus.REWORKING
    ];

    if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE) {
      statusList.push(JMENUM.JobCardStatus.COMPLETED);

      if (this.jobCard.status == JMENUM.JobCardStatus.COMPLETED) this.enableRemoveAttachment = false;
    }
    
    if(!handlingPartyList.includes(this.jobCard.handlingParty) || !statusList.includes(this.jobCard.status)){
      this.openSnackBar(this.translate("component.jobcard-attachment.status-not-match"));
      this.router.navigate(['']);
      return;
    }

    if (this.isTeamMember() && this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_UPDATE_ALL)) {
      this.hasEditPermission = true;
    }
  }

  // ----------- API ----------- //
  private async requestJobCardSummary(){
    const request = new JM.JMRequestJobCardsJobCardSummary();
    request.jobCardNumberList = [this.jobCardNumber];

    this.tablexParam.isLoadingTable = true;
    const response: JM.JMResponseJobCardsJobCardSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records) {
      this.updateJobCard(response.payload.records[0]);
      this.snNumber = this.jobCard && this.jobCard.snNumber;
      this.initPermission();
      this.renderTable();
      this.initUploadPreviewer();
    }
  }

  private async requestRemoveAttachment(attachmentId, button?) {
    const request = new JM.JMRequestJobCardsRemoveAttachments();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.attachmentIds = [attachmentId];

    if (button) button.isLoading = true;
    const response: JM.JMResponseJobCardsRemoveAttachments = await AppDelegate.sendJMRequest(request);
    if (button) button.isLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.updateJobCard(response.payload);
    this.renderTable();
    this.setPreviewContent(undefined);
    this.openSnackBar(this.translate("global.removed"));
  }

  private async requestUploadFile(files:Array<File>){
    if (!(Array.isArray(files) && files.length > 0)) return;
    this.uploadPreviewParam.isLoadingAddAttachment = true;

    for (let file of files) {
      try {
      const request = new JM.JMRequestFilesUploadFile();
      let uploadData = new FormData();
      uploadData.append('files', file, file.name);
      request.uploadData = uploadData;
      
      const response: JM.JMResponseFilesUploadFile = await AppDelegate.sendJMFileRequestWithFileHost(request, request.uploadData);
      if (response.payload.length > 0) {
        let uploadedFiles = response.payload;
        let attachments = uploadedFiles.map(file => {
          let attachment = new JMOBJ.Attachment();
          attachment.attachmentId = file['attachmentId'];
          attachment.description = file.originalname != undefined && file.originalname != null?  file.originalname: JMLanguage.translate(`global.na`)
          return attachment;
        });
        await this.requestAddAttachments(attachments);
      }
      } catch (err) { 
        console.error(err); 
      }
    }
  }

  private async requestAddAttachments(attachments) {
    const request = new JM.JMRequestJobCardsAddAttachments();
    
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version.valueOf();
    request.newAttachments = attachments;

    this.uploadPreviewParam.isLoadingAddAttachment = true;
    const response = await AppDelegate.sendJMRequest(request);
    this.uploadPreviewParam.isLoadingAddAttachment = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.openSnackBar(JMLanguage.translate("pages.sn.saved"));
    this.updateJobCard(response.payload);
    this.renderTable();
    this.uploadPreviewParam.userInput = {};
    this.uploadPreviewer.clearUploadTable();
    this.uploadPreviewer.closeUploadPreview();
  }

  private async requestGetFile(attachmentId, description?, download?, button?) {
    const request = new JM.JMRequestFilesGetFile(attachmentId);
    
    this.isPreviewerLoading = download ? false : true;
    if (button) button.isLoading = true;
    const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request);
    if (button) button.isLoading = false;
    this.isPreviewerLoading = false;

    if (!response || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (download) {
      let fileName = description != null && description != undefined ? description : attachmentId
      saveAs(response.payload, fileName);
    } else {
      this.setPreviewContent(response.payload);
    }
  }

  // ----------- UI function ----------- //
  isTeamMember(member?) {
    if (!member) {
      member = Session.userInfo.name;
    }
    if (!this.jobCard || !this.jobCard.teamMembers || !this.jobCard.teamMembers.length) {
      return false;
    }
    
    return this.jobCard.teamMembers.includes(member);
  }

  updateJobCard(jobCard) {
    this.jobCard = jobCard;
    
    if (this.jobCard.attachments && this.jobCard.attachments.length > 0) {
      this.jobCard.attachments = this.jobCard.attachments.reverse();
      this.attachmentList = this.jobCard.attachments;
      this.requestGetFile(this.attachmentList[0].attachmentId);
      this.selectedAttachment = this.attachmentList[0];
    } else {
      this.attachmentList = [];
    }
  }

  setPreviewContent(file) {
    if(file){
      this.file = file;
      this.fileDetails = {
        fileName: this.selectedAttachment.description,
        fileSize: '',
        uploadedBy: this.selectedAttachment.uploadedBy,
        uploadDate: formatDateTimeWithWeek(this.selectedAttachment.uploadedTime)
        
      };

      this.fileDetails.fileSize = this.attachmentPreview.getFileSize(this.file.size);
    }else{
      this.file = undefined;
      this.fileDetails = {};
    }
    
  }

  // ----------- Button function ----------- //
  onClickSubmitAttachment = (files:Array<File>) => {
    this.requestUploadFile(files);
  }

  onDownloadButtonClicked = (button) => {
    this.requestGetFile(button.attachment.attachmentId, button.attachment.description, true, button)
  }

  onDeleteButtonClicked = (button) => {
    if (!button || !button.attachment || !button.attachment.attachmentId) {
      AppDelegate.openErrorBar();
      return;
    }

    let buttons = [
      {
        name: (JMLanguage.translate("pages.sn.attachments.delete-the-file")),
        handler: () => {
          this.requestRemoveAttachment(button.attachment.attachmentId, button);
        }
      },
      { name: (JMLanguage.translate("global.no")) }
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.attachments.confirm-delete-attached"), "", buttons);
  }

  // ----------- Init Component ----------- //
  initUploadPreviewer() {
    this.uploadPreviewParam = {
      isDisabledAddAttachment: !this.hasEditPermission,
      hasEditPermission: this.hasEditPermission,
      userInput: {},
      customClass:'fa fa-plus blue',
      onSubmitAttachment: this.onClickSubmitAttachment
    }
  }

  // ----------- Tablex UI function ----------- //
  onRowClicked = (index: number, row: any) => {
    this.selectedAttachment = this.attachmentList[index];
    this.requestGetFile(this.selectedAttachment.attachmentId);
  }

  renderTable() {
    this.tablexParam.content = this.attachmentList.map(attachment => {
      let buttons = [
        { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
      ];
      if (this.hasEditPermission) {
        buttons = [
          { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
        ];
        this.enableRemoveAttachment && buttons.push({
          "id": "delete-button_" + attachment._id,
          "name": "",
          "class": "delete-button btn p-1",
          "icon": "fas fa-times font-size-l",
          "onClicked": this.onDeleteButtonClicked,
          "attachment": attachment
        });
      }
      return [
        attachment._id,
        attachment.description,
        buttons
      ];
    });
  }

  // ------ Tablex ------ //
  initTable() {
    this.tablexParam = {
      isLoadingTable: false,
      tableRow: "row",
      tableClass: "sn-attachment-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: false,
      enablePagination: false,
      onRowClicked: this.onRowClicked,
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.attachment.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 justify-content-around"
        }
      ],
      content: []
    };
  }
  // ------ Tablex End ------ //
}
