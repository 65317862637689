import { Component, OnInit } from '@angular/core';
import * as jwt_decode from "jwt-decode";
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ccep-portal-sso-login-success',
  templateUrl: './ccep-portal-sso-login-success.component.html',
  styleUrls: ['./ccep-portal-sso-login-success.component.scss']
})
export class CcepPortalSsoLoginSuccessComponent implements OnInit {
  redirectUrl: string = "/dashboard";

  constructor(private route: ActivatedRoute) { }

  async ngOnInit() {
    const { token, refreshToken, redirectUrl } = this.route.snapshot.queryParams;
    const resultArray = await this.verifySsoTokens(token, refreshToken);

    redirectUrl && (this.redirectUrl = redirectUrl);
    if (this.redirectUrl) {
      const absoluteUrlReg = new RegExp('^(?:[a-z+]+:)?//', 'i');
      if (absoluteUrlReg.test(this.redirectUrl)) {
        window.location.replace(this.redirectUrl);
      } else {
        AppDelegate.navigate([this.redirectUrl], { replaceUrl: true });
      }
    } else {
      AppDelegate.navigate([], { replaceUrl: true });
    }
  }

  async verifySsoTokens(token, refreshToken) {
    const user = jwt_decode(token);

    // Session.setUserInfo(response.post);
    token && Session.setUserToken(token);
    refreshToken && Session.setRefreshToken(refreshToken);
    Session.setAuthSource(JMENUM.SourceSystem.CCEPPORTAL);
    // Session.setRememberMe(this.rememberMe);
    // Session.setRememberUsername(this.rememberMe ? response.post.name : null);
    Session.setPmPeriodSavedSearchCriteria(null);

    // save cache
    JM.JMConnector.cacheEquipmentCategory().then((ecat) => {
      Session.setEquipmentCategoryDict(ecat);
    });
    JM.JMConnector.cacheEquipmentType().then((etype) => {
      Session.setEquipmentTypeDict(etype);
    });
    JM.JMConnector.cacheAllClient().then((allClient) => {
      Session.setClientDict(allClient);
    });
    JM.JMConnector.cacheAllWorkCentre().then((allWorkCentre) => {
      Session.setWorkCentreDict(allWorkCentre);
    });
    JM.JMConnector.cacheManualInstruction().then((allMI) => {
      Session.setManualInstructionArray(allMI);
    });
    //
    JM.JMConnector.cachCategoryObj().then((allCategoryObj) => {
      Session.setCategoryObj(allCategoryObj);
    });
    JM.JMConnector.cacheClassificationObj().then((allClassificationObj) => {
      Session.setClassificationObj(allClassificationObj);
    });
    const requestStorageLocationList = new JM.JMRequestInventoryGetStorageLocationList();
    requestStorageLocationList.isAiStore = true;
    AppDelegate.sendJMRequest(requestStorageLocationList).then((res) => {
      Session.setStorageLocationAiList(res.payload.storageLocationList);
    });
    const requestCostCenters = new JM.JMRequestWorkCentresGetCostCentres();
    AppDelegate.sendJMRequest(requestCostCenters).then((res) => {
      Session.setCostCentreList(res.payload.costCentres);
    });
    const apiToWaitList: Promise<any>[] = [];
    // get feature list
    const requestSystemFeatureList = new JM.JMRequestSystemConfigsGetSystemFeatureList();
    requestSystemFeatureList.systemName = JMENUM.SourceSystem.CCEPJM;

    apiToWaitList.push(AppDelegate.sendJMRequest(requestSystemFeatureList))
    // check jm account review 
    const checkReviewRequest = new JM.JMRequestAuthHalfYearReviewCheckReviewStatus();
    apiToWaitList.push(AppDelegate.sendJMRequest(checkReviewRequest));

    apiToWaitList.push(JM.JMConnector.cacheAll());

    //
    return Promise.all(apiToWaitList).then(([res1, res2, res3]) => {
      res1.payload && Session.setFeatureList(res1.payload.records);
      res2.payload && Session.setIsEnableJMAccountReview(res2.payload.enabled);
    });
  }
}
