import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import * as _ from 'underscore';
import { formatDate, toNgbDate } from 'src/lib/presenter/Formatter';
import { Session } from 'src/app/services/session';

@Component({
  selector: 'app-pm-period-search-criteria',
  templateUrl: './pm-period-search-criteria.component.html',
  styleUrls: ['./pm-period-search-criteria.component.scss']
})
export class PmPeriodSearchCriteriaComponent implements OnInit {
  @ViewChild("startDateElem", {static: false}) startDateElem;
  @ViewChild("endDateElem", {static: false}) endDateElem;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  
  isSearching: boolean = false;
  isCollapse: boolean = false;

  isDisabled: boolean = false; //For Not Ready Function

  //ENUM for HTML
  PMStatus = JMENUM.PMStatus;
  PlanPeriod = JMENUM.PMPlanPeriod;
  
  PMPeriodRequest: JM.JMRequestGetPmPeriodList = new JM.JMRequestGetPmPeriodList();

  planNumber: string;
  periodStatus: any;
  startRange: any;
  startDate:any;
  ngbStartDate: any;
  endRange: any; 
  endDate: any;
  ngbEndDate: any;
  planPeriod: any;
  defaultSearchCriteria = {
    periodStatus: {
      outstanding : true,
      completed : false,
      inProgress : true
    },
    planPeriod: {
      onTheSchedule: false,
      overdue: true
    },
    startRange: 'after',
    endRange: 'after',
    startDate: '20100101',
    endDate: '20100101'
  };

  
  constructor() { }

  ngOnInit(): void {
    this.initCheckboxValue();
  }

  // ----------- API ----------- //


  // ----------- UI function ----------- //
  private initCheckboxValue(){
    this.periodStatus = {};
    this.planPeriod = {};

    this.periodStatus[this.PMStatus.OUTSTANDING] = false;
    this.periodStatus[this.PMStatus.COMPLETED] = false;
    this.periodStatus[this.PMStatus.IN_PROGRESS] = false;


    let periodEnum = Object.values(this.PlanPeriod);
    for (let i=0; i < periodEnum.length; i++) {
      this.planPeriod[periodEnum[i]] = false;
    }

    if (Session.pmPeriodListSavedSearchCriteria) {
      this.setSearchCriteria(Session.pmPeriodListSavedSearchCriteria);
    } else {
      this.setSearchCriteria(this.defaultSearchCriteria);
    };
    this.updatePMPeriodRequestObject();
    this.onSearch.emit(this.PMPeriodRequest);
  }

  private setSearchCriteria(searchCriteria) {
    this.planNumber = searchCriteria.pmPlanNumber? searchCriteria.pmPlanNumber[0]: undefined;
    this.periodStatus[this.PMStatus.OUTSTANDING] = searchCriteria.periodStatus.outstanding;
    this.periodStatus[this.PMStatus.COMPLETED] = searchCriteria.periodStatus.completed;
    this.periodStatus[this.PMStatus.IN_PROGRESS] =searchCriteria.periodStatus.inProgress;
    this.planPeriod[this.PlanPeriod.ON_SCHEDULE] = searchCriteria.planPeriod.onTheSchedule;
    this.planPeriod[this.PlanPeriod.OVERDUE] = searchCriteria.planPeriod.overdue;
    this.startRange = searchCriteria.startRange;
    this.ngbStartDate = toNgbDate(searchCriteria.startDate);
    this.startDate = searchCriteria.startDate;
    this.endRange = searchCriteria.endRange;
    this.ngbEndDate = toNgbDate(searchCriteria.endDate);
    this.endDate = searchCriteria.endDate;
  }

  private updatePMPeriodRequestObject() {
    let periodStatus = Object.keys(this.periodStatus).filter(status => this.periodStatus[status]);
    this.PMPeriodRequest.pmPlanNumber = this.planNumber ? [this.planNumber] : undefined;
    this.PMPeriodRequest.status = periodStatus.length > 0 ? periodStatus : undefined;
    this.PMPeriodRequest.periodScheduleStatus = undefined;
    if (this.planPeriod.overdue || this.planPeriod.on_schedule ) {
      this.PMPeriodRequest.periodScheduleStatus = [];
      this.planPeriod.overdue && this.PMPeriodRequest.periodScheduleStatus.push(this.PlanPeriod.OVERDUE);
      this.planPeriod.on_schedule && this.PMPeriodRequest.periodScheduleStatus.push(this.PlanPeriod.ON_SCHEDULE);
    }
    this.PMPeriodRequest.periodStartDateTo = undefined;
    this.PMPeriodRequest.periodStartDateFrom = undefined;
    this.PMPeriodRequest.periodEndDateTo = undefined;
    this.PMPeriodRequest.periodEndDateFrom = undefined;

    if(this.startRange == 'on-or-before'){
      this.PMPeriodRequest.periodStartDateTo = formatDate(this.startDate, 'YYYYMMDD');
    }
    if(this.startRange == 'after'){
      this.PMPeriodRequest.periodStartDateFrom = formatDate(this.startDate, 'YYYYMMDD');
    }
    if(this.endRange == 'on-or-before'){
      this.PMPeriodRequest.periodEndDateTo = formatDate(this.endDate, 'YYYYMMDD');
    }
    if(this.endRange == 'after'){
      this.PMPeriodRequest.periodEndDateFrom = formatDate(this.endDate, 'YYYYMMDD');
    }
  }

  private saveSearchCriteria() {
    Session.setPmPeriodSavedSearchCriteria({
      pmPlanNumber: this.planNumber ? [this.planNumber] : undefined,
      periodStatus: {
        outstanding : this.periodStatus[this.PMStatus.OUTSTANDING],
        completed : this.periodStatus[this.PMStatus.COMPLETED],
        inProgress : this.periodStatus[this.PMStatus.IN_PROGRESS]
      },
      planPeriod: {
        onTheSchedule: this.planPeriod.on_schedule,
        overdue: this.planPeriod.overdue
      },
      startRange: this.startRange,
      endRange: this.endRange,
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  public onClickSearch(){
    //TODO: pm plan period
    this.updatePMPeriodRequestObject();
    this.saveSearchCriteria();
    this.onSearch.emit(this.PMPeriodRequest);
  }
  
  public onClickReset(){
    this.setSearchCriteria(this.defaultSearchCriteria);
  }

  public onBlurDateInput(event){
    let formattedDate = undefined;
    if(event.data){
      formattedDate = new Date(event.data.year, event.data.month - 1, event.data.day);
    }
    if(event.field == 'startDate'){
      this.startDate = formattedDate ? new Date(formattedDate) : undefined;
    }
    if(event.field == 'endDate'){
      this.endDate =  formattedDate ? new Date(formattedDate) : undefined;
    }
  }

  ngModelChangePeriodStatus(){
    let combinedStatus = false;
    for(let key in this.periodStatus){
      combinedStatus = combinedStatus || this.periodStatus[key];
    }
    if(!combinedStatus){
      for(let key in this.periodStatus){
        this.periodStatus[key] = true;
      }
    }
  }
}
