import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { InputFieldType } from 'src/lib/ui-component/custom-fields-form/input-field-type.enum';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { BasePage } from 'src/app/ui/model/base/base';
import { Session } from '@services/session';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { Subject } from 'rxjs';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-settings-hospital-code',
  templateUrl: './settings-hospital-code.component.html',
  styleUrls: ['./settings-hospital-code.component.scss']
})

export class SettingsHospitalCodeComponent extends BasePage implements OnInit {
  @ViewChild('hospital_code_form', { static: true }) hospitalCodeFormElem;

  // component config & data
  hospitalCodeFormParam: any = {};
  hospitalCodeFormInput: any = {};

  hasEditPermission: boolean;
  codeMapId: string;

  hospitalCodeRecords: any = [];
  isDisabledEditHospitalCode: any = [];
  isDisabledAddMapping:boolean;

  //Location List
  locationList: any = [];
  locationPageNumber: number = 1;
  locationPageSize: number = 100;
  searchLocationKeyword: string;
  searchLocationDescriptionKeyword: string;

  //Client List
  clientList: any = [];
  clientPageNumber: number = 1;
  clientPageSize: number = 1000;
  clientCount: number = 0;
  searchClientKeyword: string;
  
  private searchClientObserver = new Subject<any[]>();
  private searchLocationObserver = new Subject<any[]>();

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initPermission();
    this.requestDropDownData();

    this.searchLocationObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.clientPageNumber = 1;
      this.requestLocationSummary();
    });

    this.searchLocationObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.locationPageNumber = 1;
      this.requestLocationSummary();
    });
  }

  onLanguageChanged() {
    
  }

  requestDropDownData(){
    this.initHospitalCodeFormElem();
    this.requestAllHospitalCode();
    this.requestFullClientSummary();
    this.requestLocationSummary();
    this.requestHospitalCodeLocationMapping();
    this.hospitalCodeFormElem.copyInputData(this.hospitalCodeFormInput);
  }

  //----- API -----//
  async requestLocationSummary(){
    const request = new JM.JMRequestLocationsLocationSummary();
    request.pageNumber = this.locationPageNumber;
    request.pageSize = this.locationPageSize;

    if(this.searchLocationKeyword && this.searchLocationKeyword != ''){
      request.filter = {code:this.searchLocationKeyword};
    }

    if(this.searchLocationDescriptionKeyword && this.searchLocationDescriptionKeyword !=''){
      request.locationDescription = this.searchLocationDescriptionKeyword;
    }

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (this.locationPageNumber == 1) {
      this.locationList = response.payload.records;
      this.hospitalCodeFormParam.fieldOptions['location'] = response.payload.records.map(data => {
        let obj = {
          id: data.code, 
          key: data.code, 
          value: data.code, 
          label: data.code
        }
        return obj;
      });
      this.hospitalCodeFormParam.fieldOptions['locationDescription'] = response.payload.records.map(data => {
        let obj = {
          id: data.code, 
          key: data.code, 
          value: data.description[Session.selectedLanguage], 
          label: data.description[Session.selectedLanguage]
        }
        return obj;
      });
    } else {
      this.locationList = this.locationList.concat(response.payload.records);
      let loc = response.payload.records.map(data => {
        let obj = {
          id: data.code, 
          key: data.code, 
          value: data.code, 
          label: data.code
        }
        return obj;
      });
      let locDesc = response.payload.records.map(data => {
        let obj = {
          id: data.code, 
          key: data.code, 
          value: data.description[Session.selectedLanguage], 
          label: data.description[Session.selectedLanguage]
        }
        return obj;
      });
      this.hospitalCodeFormParam.fieldOptions['location'] = this.hospitalCodeFormParam.fieldOptions['location'].concat(loc);
      this.hospitalCodeFormParam.fieldOptions['locationDescription'] = this.hospitalCodeFormParam.fieldOptions['locationDescription'].concat(locDesc);
    }
  }

  async requestAllHospitalCode(){
    const request = new JM.JMRequestEquipmentsAllHospitalCode();

    const response: JM.JMResponseEquipmentsAllHospitalCode = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.hospitalCodeFormParam.fieldOptions['hospitalCode'] = response.payload;
  }

  async requestFullClientSummary() {
    do {
      await this.requestClientSummary();
      this.clientPageNumber++;
    } while (this.clientPageSize * (this.clientPageNumber - 1) < this.clientCount);
  }

  async requestClientSummary() {
    const request = new JM.JMRequestClientsClientSummary();
    request.active = JMENUM.RequestActive.BOTH;
    request.sortBy = "clientShortName";
    request.pageSize = this.clientPageSize;
    request.pageNumber = this.clientPageNumber;
    request.parameters = ['clientNumber', "clientShortName", "name"]
    request.includeSummary = true;

    if (this.searchClientKeyword && this.searchClientKeyword != ''){
      request.filter = {
        clientShortName: this.searchClientKeyword
      };
    }

    const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    } 

    this.clientCount = response.payload.totalCount;
    if (this.clientPageNumber == 1) {
      this.clientList = response.payload.records;
      this.hospitalCodeFormParam.fieldOptions['client'] = response.payload.records.map(data => {
        return {
          id: data.clientShortName,
          key: data.clientShortName,
          value: data.clientShortName,
          label: data.clientShortName
        };
      });
    } else {
      this.clientList = this.clientList.concat(response.payload.records);
      let clientRecords = response.payload.records.map(data => {
        return {
          id: data.clientShortName,
          key: data.clientShortName,
          value: data.clientShortName,
          label: data.clientShortName
        };
      });
      this.hospitalCodeFormParam.fieldOptions['client'] = this.hospitalCodeFormParam.fieldOptions['client'].concat(clientRecords);
    }
  }

  async requestHospitalCodeLocationMapping(){
    const request = new JM.JMRequestEquipmentsHospitalCodeLocationMapping();

    const response: JM.JMResponseEquipmentsHospitalCodeLocationMapping = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.hospitalCodeRecords = response.payload.map(data => {
      let obj = {
        _id: data._id,
        hospitalCode: data.hospitalCode,
        client: data.client,
        location: data.location,
        locationDescription: data.locationDescription[Session.selectedLanguage]
      };
      return obj;
    });
  }

  async requestCreateHospitalCodeLocationMapping(){
    const request = new JM.JMRequestEquipmentsCreateHospitalCodeLocationMapping();
    request.hospitalCode = this.hospitalCodeFormInput.hospitalCode;
    request.client = this.hospitalCodeFormInput.client;
    request.location = this.hospitalCodeFormInput.location;

    this.hospitalCodeFormParam.onChangeBtn[0].disabled = true;
    const response: JM.JMResponseEquipmentsCreateHospitalCodeLocationMapping = await AppDelegate.sendJMRequest(request);
    this.hospitalCodeFormParam.onChangeBtn[0].disabled = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.requestHospitalCodeLocationMapping();
    this.resetHospitalCodeForm();
    this.openSnackBar(JMLanguage.translate("pages.settings.hospital-code.create-mapping-success"));
  }

  async requestUpdateHospitalCodeLocationMapping(){
    const request = new JM.JMRequestEquipmentsUpdateHospitalCodeLocationMapping();
    request._id = this.codeMapId;
    request.client = this.hospitalCodeFormInput.client;
    request.location = this.hospitalCodeFormInput.location;

    this.hospitalCodeFormParam.onChangeBtn[0].disabled = true;
    const response: JM.JMResponseEquipmentsUpdateHospitalCodeLocationMapping = await AppDelegate.sendJMRequest(request);
    this.hospitalCodeFormParam.onChangeBtn[0].disabled = false;

    this.requestHospitalCodeLocationMapping();
    this.resetHospitalCodeForm();
    this.openSnackBar(JMLanguage.translate("pages.settings.hospital-code.update-mapping-success"));
  }

  //----- UI Function -----//
  onChangeLocation = () => {
    this.hospitalCodeFormInput.locationDescription = this.locationList.find(loc => 
      loc.code == this.hospitalCodeFormInput.location
    )['description'][Session.selectedLanguage];
  }

  onChangeLocationDescription = () => {
    this.hospitalCodeFormInput.location = this.hospitalCodeFormParam.fieldOptions.locationDescription.find(loc => 
      loc.value == this.hospitalCodeFormInput.locationDescription
    )['id'];
  }

  onLoadClient = () => {
    this.clientPageNumber += 1;
    this.requestClientSummary();
  }

  onLoadLocation = () => {
    this.locationPageNumber += 1;
    this.requestLocationSummary();
  }

  onSearchClient = (event) => {
    this.searchClientKeyword = event.term;
    this.searchClientObserver.next();
  }

  onSearchLocation = (event) => {
    this.searchLocationDescriptionKeyword = null;
    this.searchLocationKeyword = event.term;
    this.searchLocationObserver.next();
  }

  onSearchLocationDescription = (event) => {
    this.searchLocationKeyword = null;
    this.searchLocationDescriptionKeyword = event.term;
    this.searchLocationObserver.next();
  }

  onClickAddMappingBtn(){
    this.resetHospitalCodeForm();
    this.hospitalCodeFormParam.isEditMode = true;
    this.hospitalCodeFormParam.onChangeBtn[0].text = JMLanguage.translate("pages.settings.ha-defaults.create");
    this.hospitalCodeFormParam.fieldList.find(field => field.id == 'hospitalCode').disabled = false;
    this.hospitalCodeFormElem.copyInputData(this.hospitalCodeFormInput);
  }

  onDiscardHospitalCodeTeam = () => {
    this.hospitalCodeFormElem.discardForm();
  }

  onClickUpdateHospitalCode = () => {
    this.hospitalCodeFormParam.showFieldsError = true;
    if(this.hospitalCodeFormInput.hospitalCode && this.hospitalCodeFormInput.client && this.hospitalCodeFormInput.location){
      if(this.codeMapId){
        this.requestUpdateHospitalCodeLocationMapping();
      }else{ 
        this.requestCreateHospitalCodeLocationMapping();
      }
    }else{
      this.openSnackBar(JMLanguage.translate("pages.settings.ha-defaults.please-input-all-mandatory-fields"));
    }
  }

  onClickEditHospitalCode(data, index){
    this.hospitalCodeFormParam.onChangeBtn[0].text = JMLanguage.translate("pages.settings.ha-defaults.update");
    this.hospitalCodeFormParam.isEditMode = true;
    this.hospitalCodeFormParam.fieldList.find(field => field.id == 'hospitalCode').disabled = true;
    this.codeMapId = data._id;
    this.hospitalCodeFormInput = {
      _id: data._id,
      client: data.client,
      hospitalCode: data.hospitalCode,
      location: data.location,
      locationDescription: data.locationDescription,
    };
    this.searchLocationKeyword = data.location;
    this.requestLocationSummary();
    this.hospitalCodeFormElem.copyInputData(this.hospitalCodeFormInput);
  }

  resetHospitalCodeForm(){
    this.hospitalCodeFormParam.showFieldsError = false;
    this.codeMapId = '';
    this.hospitalCodeFormParam.isEditMode = false;
    this.hospitalCodeFormInput = {
      _id: '',
      client: '',
      hospitalCode: '',
      location: '',
      locationDescription: ''
    };
    this.hospitalCodeFormElem.copyInputData(this.hospitalCodeFormInput);
  }

  //------ Init Component ------//
  initPermission(){
    this.checkViewPermission(JMENUM.Permission.HA_DEFAULTS_SETTING_VIEW);
    this.hasEditPermission = this.authorizationService.hasPermission(JMENUM.Permission.HA_DEFAULTS_SETTING_UPDATE);
  }

  initHospitalCodeFormElem(){
    this.hospitalCodeFormParam.displayFieldAsCol = true;
    this.hospitalCodeFormParam.showFieldsError = false;
    this.hospitalCodeFormParam.isEditMode = false;
    this.hospitalCodeFormParam.fieldOptions = {};
    this.hospitalCodeFormParam.fieldRowClass = 'col-3';
    this.hospitalCodeFormParam.onChangeBtn = [
      { id: 'update-btn', customClass: 'brand-blue',  onClick: this.onClickUpdateHospitalCode, text: JMLanguage.translate('pages.setting.work-centres.button.update')},
      { id: 'discard-btn', icon: 'fas fa-times', onClick: this.onDiscardHospitalCodeTeam }
    ];

    this.hospitalCodeFormParam.header = {
      title: JMLanguage.translate("pages.settings.ha-defaults.default-team-heading") ,
      customClass: 'bold font-size-m'
    }

    this.hospitalCodeFormParam.fieldList = [
      { 
        id: 'hospitalCode', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        disabled: true,
        onChange: () => {}, 
        onSearch: () => {},
        scrollToEnd: () => {},
        name: JMLanguage.translate("pages.settings.hospital-code.hospital-code")
      },
      { 
        id: 'client', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        disabled: false,
        onChange: () => {}, 
        onSearch: this.onSearchClient,
        // scrollToEnd: this.onLoadClient,
        name: JMLanguage.translate("pages.settings.hospital-code.client")
      },
      { 
        id: 'location', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        disabled: false,
        onChange: this.onChangeLocation,
        onSearch: this.onSearchLocation,
        scrollToEnd: this.onLoadLocation,
        name: JMLanguage.translate("pages.settings.hospital-code.functional-location")
      },
      { 
        id: 'locationDescription', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        disabled: false,
        onChange: this.onChangeLocationDescription,
        onSearch: this.onSearchLocationDescription,
        scrollToEnd: () => {},
        name: JMLanguage.translate("pages.settings.hospital-code.description")
      },
    ];
  }

}
