import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as qs from 'qs';
import { environment } from 'src/environments/environment';
import { Session } from 'src/app/services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { RoutePaths } from 'src/constants';

@Component({
  selector: 'app-weather-eform',
  templateUrl: './weather-eform.component.html',
  styleUrls: ['./weather-eform.component.scss']
})
export class WeatherEformComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    // check token existed
    let token = Session.userToken;
    if (!token) {
      AppDelegate.navigate([RoutePaths.LOGIN], {
        replaceUrl: true,
        queryParams: {
          successUrl: window.location.pathname,
        },
      });
    } else {
      const path = this.activeRoute.snapshot.url.map((segment) => segment.path).join("/");
      const param = {
        t: Session.userToken,
        lang: Session.selectedLanguage,
      } as any;
      const queryStr = qs.stringify(param);
      window.location.replace(`${environment.CCEP_EFORM_WEB_HOST}/${path}?${queryStr}`);
    }
  }

}
