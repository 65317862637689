import { Component, Injector, OnInit } from '@angular/core';
import { NavigationEnd, RouterEvent } from '@angular/router';
import { ContactGroupService } from '@services/contact-group.service';
import { ExcelService } from '@services/excel/excel.service';
import { ParameterService } from '@services/parameter.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { formatDate } from '@angular/common';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { SnService } from '@services/sn.service';
import { JobCardService } from '@services/job-card.service';
import { Session } from 'src/app/services/session';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import * as utility from 'src/app/services/utility';

@Component({
  selector: 'app-pmsmc-statistics',
  templateUrl: './pmsmc-statistics.component.html',
  styleUrls: ['./pmsmc-statistics.component.scss'],
})
export class PmsmcStatisticsComponent extends BasePage implements OnInit {
  public destroyed = new Subject<any>();

  constructor(
    injector: Injector,
    private parameterService: ParameterService,
    private contactGroupService: ContactGroupService,
    private snService: SnService,
    private jobCardService: JobCardService
  ) {
    super(injector);
    this.excelService = injector.get(ExcelService);
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroyed)
      )
      .subscribe(() => {
        this.fetchData();
      });
  }
  isDisabledNormalTimeRange: boolean = false;
  excelService: ExcelService;
  hasAuthorizationAll: boolean = false;
  isDisabledContract: boolean = true;
  filterCriteria: any = {};
  status: any[];
  allowStatus: any = new Set([JMENUM.JobCardStatus.COMPLETED, JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION]);
  statusOption: any[];
  allowStatusOption: [];
  isExportExcelButtonLoading: boolean = false;

  ngOnInit() {
    this.hasAuthorizationAll = this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL);
    this.initAllOption();
  }

  initAllOption() {
    this.initJobCompletionDateOption();
    this.initStatusOption();
  }

  fetchData() {
    this.lang = Session.selectedLanguage; //TODO: remove lang
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  initJobCompletionDateOption(): void {
    this.filterCriteria.jobCompletionDate = this.setTodayDate();
  }

  initStatusOption(): void {
    this.statusOption = [];
    for (let id of JM.JMConnector.getAllJobCardStatus()) {
      if (this.allowStatus.has(id)) {
        this.statusOption.push({
          id: id,
          label: JMLanguage.translate('jobcard.status.' + id),
        });
      }
    }
  }

  onClickExportExcel(): void {
    if (!this.filterCriteria.jobCompletionDate || !this.filterCriteria.jobCompletionDate) {
      this.openSnackBar(JMLanguage.translate('pages.report-download.pmsmc-statistics.please-fill-in-job-complete-time'));
      return;
    }
    this.getReport();
  }

  async getReport() {
    const jobCompletionDateformat = 'YYYYMMDD';
    const request = new JM.JMRequestJobCardsExportPmsmcStatisticsReport();

    request.jobCompletionDate = moment(
      new Date(
        this.filterCriteria.jobCompletionDate.year +
          '-' +
          this.filterCriteria.jobCompletionDate.month +
          '-' +
          this.filterCriteria.jobCompletionDate.day
      )
    ).format(jobCompletionDateformat);
    if (this.filterCriteria.status && this.filterCriteria.status.length>0) {
      request.statusList = this.filterCriteria.status;
    }

    this.isExportExcelButtonLoading = true;
    const response: JM.JMResponseJobCardsExportPmsmcStatisticsReport = await AppDelegate.sendJMRequest(request);
    this.isExportExcelButtonLoading = false;

    if (!response) {
      AppDelegate.openErrorBar(response);
      return;
    }
    let fileName = `PMSMC_Statistics_${formatDate(new Date(), 'yyyy-MM-dd', 'en-US')}.xlsx`;
    saveAs(response, fileName);
  }

  /*** Custom function for frontend ***/

  private momentDateToBgbDate(momentDate: moment.Moment): any {
    return { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() };
  }

  setTodayDate(): NgbDateStruct {
    return this.momentDateToBgbDate(moment());
  }

  // TODO: reload language
  onLanguageChanged() {}
}
