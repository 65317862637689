<div *ngIf="parameters.onSubmitClicked" class="header">
    <button class="standard brand-amber" (click)="onClickSubmit()" [disabled]="!assignedPersons.length || !parameters.officerInCharge || parameters.isLoading">
        <span *ngIf="parameters.isLoading">
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
        </span>
        <span *ngIf="!parameters.isLoading"><i class="fas fa-check space"></i>{{'global.submit' | translate}}</span>
    </button>
</div>
<div class="validation-fail-message">
    <span *ngIf="!assignedPersons.length">{{'job-card-assign-staff-form.warning.no-assign-staff' | translate }}</span>
    <span *ngIf="assignedPersons.length && !parameters.officerInCharge">{{ 'job-card-assign-staff-form.warning.no-officer-in-charge' | translate }}</span>
    <span *ngIf="parameters.errorMsg" class="text-break text-wrap">{{parameters.errorMsg}}</span>
</div>
<div [ngClass]="{ 'assigned-staff-container': !parameters.viewOnly }">
    <h3>{{ 'job-card-assign-staff-form.assigned-staff' | translate }}</h3>
    <app-tablex [tablexParam]='assignedStaffTable' id="assigned-staff-table" class='mt-3'></app-tablex>
</div>
<div *ngIf="!parameters.viewOnly" class="unassigned-staff-container">
    <h3>{{ "job-card-assign-staff-form.unassigned-staff" | translate }} </h3>
    <app-tablex [tablexParam]='unassignedStaffTable' id="unassigned-staff-table" class='mt-3'></app-tablex>
</div>
