import { Component, Injector, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';

@Component({
  selector: 'time-range-slider',
  templateUrl: './time-range-slider.component.html',
  styleUrls: ['./time-range-slider.component.scss']
})

export class TimeRangeSliderComponent extends BasePage implements OnChanges {
  @Input() staffTimeSheet: any;
  @Input() staffTimeSheetDisplay: any;
  // @Input() startTimeMin: any;
  // @Input() endTimeMax: any;
  
  @Input() postName: string;
  @Input() employeeCode: string;
  @Input() day: string; // YYYY-MM-DD
  @Input() delegate: any;
  @Input() disabled: any = false;

  constructor(
    injector: Injector
  ){
    super(injector)
  }

  ngOnChanges() {
    
  }
  
  onChangeTimeSheet(point) {
    if (this.delegate) {
      this.delegate.onTimesheetSliderChanged(point, this.postName, this.employeeCode, this.day)
    }
  }

  // convert time in decmial to string time format
  // eg: 13.75 => 13:45
  valueToTimeFormat(value: number): string {
    return Math.floor(value).toString().padStart(2, '0') + ":" + ((value%1)*60).toString().padStart(2, '0');
  }

}
