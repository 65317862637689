import { Component, Injector, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material';
import * as qs from 'qs';
import { JM, JMCONSTANT, JMENUM } from '@ccep/CCEPConnector-ts';

import { BasePage } from 'src/app/ui/model/base/base';
import { DashboardStatisticType } from 'src/app/entity/enum/dashboard-statistic-type';
import { ApprovalService } from '@services/approval.service';
import { Session } from 'src/app/services/session';
import { environment } from 'src/environments/environment';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

enum STAT_KEY {
  EQUIP_INHOUSE = 'outstanding-equip-inhouse',
  EQUIP_CONTRACT = 'outstanding-equip-contract',
  APPROVAL_CM = 'approval-cm-count',
  APPROVAL_PM_PLAN = 'approval-pm-plan-count',
  APPROVAL_PM_JOB = 'approval-pm-job-count',
}

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss']
})
export class DashboardComponent extends BasePage implements OnInit {

  statistics = [
    {
      id: "dashboard_sn-pending-ack-count_text", description: 'pages.dashboard.pending-acknowledge', key: 'pending-acknowledge', permission: [
        JMENUM.Permission.DASHBOARD_CSC, JMENUM.Permission.DASHBOARD_SBU
      ], icon: '../assets/img/dashboard_sn_pending_acknowledge.png', value: [], records: [[], []], recordsSplitted: true
    },
    {
      id: "dashboard_sn-further-action-required-count_text", description: 'pages.dashboard.further-action-required', key: 'further-action-required', permission: [
        JMENUM.Permission.DASHBOARD_CSC, JMENUM.Permission.DASHBOARD_SBU
      ], icon: '../assets/img/dashboard_sn_further_action_required.png', value: [], records: [[], []], recordsSplitted: true
    },
    {
      id: 'dashboard_pm-outstanding-equip-inhouse-count_text',
      description: 'pages.dashboard.outstanding-equip-inhouse',
      key: STAT_KEY.EQUIP_INHOUSE,
      permission: [JMENUM.Permission.DASHBOARD_CSC, JMENUM.Permission.DASHBOARD_SBU],
      icon: '../assets/img/dashboard_pm_outstanding_PM_equipment.png',     
      value: 0,
      records: [],
      recordsSplitted: false
    },
    {
      id: 'dashboard_pm-outstanding-equip-contract-count_text',
      description: 'pages.dashboard.outstanding-equip-contract',
      key: STAT_KEY.EQUIP_CONTRACT,
      permission: [JMENUM.Permission.DASHBOARD_CSC, JMENUM.Permission.DASHBOARD_SBU],
      icon: '../assets/img/dashboard_job_card_reassignment_required.png',
      value: 0,
      records: [],
      recordsSplitted: false
    },
    {
      id: "dashboard_job-card-pending-accept-count_text", description: 'pages.dashboard.pending-action', key: 'to-be-process', permission: [JMENUM.Permission.DASHBOARD_SBU], icon: '../assets/img/dashboard_job_card_to_be_process.png', value: [], records: [[], []], recordsSplitted: true
    },
    {
      id: "dashboard_job-card-reassignment-required-count_text", description: 'pages.dashboard.reassignment-required', key: 'reassignment-required', permission: [JMENUM.Permission.DASHBOARD_SBU], icon: '../assets/img/dashboard_job_card_reassignment_required.png', value: [], records: [[], []], recordsSplitted: true
    },
    {
      id: "dashboard_csc-failed-fax-count_text", description: 'pages.dashboard.failed-fax', key: 'failed-fax', permission: [JMENUM.Permission.DASHBOARD_CSC], icon: '../assets/img/dashboard_waiting_csc_failed_fax.png', value: 0, records: [], recordsSplitted: false
    },
    {
      id: "dashboard_csc-my-manual-instruction-count_text", description: 'pages.dashboard.my-manual-instruction', key: 'my-manual-instruction', permission: [JMENUM.Permission.DASHBOARD_CSC], icon: '../assets/img/dashboard_waiting_csc_my_manual_instruction.png',
      value: 0, records: [], recordsSplitted: false
    },
    {
      id: "dashboard_csc-manual-instruction-queue-count_text", description: 'pages.dashboard.manual-instruction-queue', key: 'manual-instruction-queue', permission: [JMENUM.Permission.DASHBOARD_CSC], icon: '../assets/img/dashboard_waiting_csc_manual_instruction_queue.png',
      value: 0, records: [], recordsSplitted: false
    },
    {
      id: "dashboard_csc-other-manual-instruction-count_text", description: 'pages.dashboard.other-manual-instruction', key: 'other-manual-instruction', permission: [JMENUM.Permission.DASHBOARD_CSC], icon: '../assets/img/dashboard_waiting_csc_other_manual_instruction.png',
      value: 0, records: [], recordsSplitted: false
    },
    {
      id: "client-pending-fault-count_text", description: 'pages.dashboard.pending-fault', key: 'pending-fault', permission: [JMENUM.Permission.DASHBOARD_CSC], icon: '../assets/img/dashboard_client_app_pending_fault.png', value: 0, records: [], recordsSplitted: false
    },
    {
      id: "dashboard-approval-cm-count",
      description: 'pages.dashboard.approval-cm-count',
      key: STAT_KEY.APPROVAL_CM,
      permission: [JMENUM.Permission.SN_ASSOCIATE_APPROVE, JMENUM.Permission.SN_ASSOCIATE_APPROVE_ALL,
      JMENUM.Permission.SN_CANCEL_APPROVE, JMENUM.Permission.SN_CANCEL_APPROVE_ALL,
      JMENUM.Permission.JOBCARD_COMPLETE_APPROVE, JMENUM.Permission.JOBCARD_COMPLETE_APPROVE_ALL,
      ],
      icon: '../assets/img/dashboard_pending_approval_cm_job.png',
      value: 0, records: [], recordsSplitted: false
    },
    {
      id: "dashboard-approval-pm-plan-count",
      description: 'pages.dashboard.approval-pm-plan-count',
      key: STAT_KEY.APPROVAL_PM_PLAN,
      permission: [JMENUM.Permission.PMPLAN_APPROVE],
      icon: '../assets/img/dashboard_pending_approval_pm_plan.png',
      value: 0, records: [], recordsSplitted: false
    },
    {
      id: "dashboard-approval-pm-job-count",
      description: 'pages.dashboard.approval-pm-job-count',
      key: STAT_KEY.APPROVAL_PM_JOB,
      permission: [JMENUM.Permission.PMJOB_APPROVE],
      icon: '../assets/img/dashboard_pending_approval_pm_job.png',
      value: 0, records: [], recordsSplitted: false
    },
  ];  

  tabActive = {
    snPm: 'sn',
  };
  statisticGroups = [
    {
      mode: 'tab',
      type: 'snPm',
      tabs: [
        { key: 'sn', text: 'pages.dashboard.tab.sn-job' },
        { key: 'pm', text: 'pages.dashboard.tab.pm' },
      ],
      tabGroups: {
        'sn': {
          permission: [JMENUM.Permission.SN_VIEW, JMENUM.Permission.DASHBOARD_SBU],
          subGroups: [
            {
              rows: [
                {
                  type: 'sn',
                  description: 'pages.dashboard.service-notification',
                  permission: JMENUM.Permission.SN_VIEW,
                  className: 'col-xl-6',
                  statistics: [
                    this.statistics[DashboardStatisticType.PENDING_ACK],
                    this.statistics[DashboardStatisticType.FURTHER_ACTION_REQUIRED]
                  ]
                },
                {
                  type: 'jobCard',
                  description: 'pages.dashboard.job-card',
                  permission: JMENUM.Permission.DASHBOARD_SBU,
                  className: 'col-xl-6',
                  statistics: [
                    this.statistics[DashboardStatisticType.TO_BE_PROCESS],
                    this.statistics[DashboardStatisticType.REASSIGNMENT_REQUIRED]
                  ]
                },
                {
                  type: 'approval',
                  description: 'pages.dashboard.pending-approval',
                  permission: null,
                  className: 'col-xl-6',
                  statistics: [
                    this.statistics[DashboardStatisticType.APPROVAL_CM],
                  ]
                }
              ]
            }
          ]
        },
        'pm': {
          permission: [JMENUM.Permission.PMPLAN_VIEW],
          subGroups: [
            {
              rows: [
                {
                  type: 'pm',
                  description: 'pages.dashboard.preventive-maintenance',
                  permission: JMENUM.Permission.PMPLAN_VIEW,
                  className: 'col-xl-6',
                  statistics: [
                    this.statistics[DashboardStatisticType.OUTSTANDING_PM_EQUIPMENT_INHOUSE],
                    // this.statistics[DashboardStatisticType.OUTSTANDING_PM_EQUIPMENT_CONTRACT]
                  ]
                },
                {
                  type: 'approval',
                  description: 'pages.dashboard.pending-approval',
                  permission: null,
                  className: 'col-xl-6',
                  statistics: [
                    this.statistics[DashboardStatisticType.APPROVAL_PM_PLAN],
                    this.statistics[DashboardStatisticType.APPROVAL_PM_JOB],
                  ]
                }
              ]
            }],
        }
      },
    },
    {
      rows: [
        {
          type: 'cscOperations',
          description: 'pages.dashboard.csc-operations',
          permission: JMENUM.Permission.DASHBOARD_CSC,
          className: "col-xl-6 custom-col-xxl-4",
          statistics: [
            this.statistics[DashboardStatisticType.FAILED_FAX],
            this.statistics[DashboardStatisticType.MY_MANUAL_INSTRUCTION],
            this.statistics[DashboardStatisticType.MANUAL_INSTRUCTION_QUEUE],
            this.statistics[DashboardStatisticType.OTHER_MANUAL_INSTRUCTION]
          ]
        },
        {
          type: 'clientApplication',
          description: 'pages.dashboard.client-application',
          permission: JMENUM.Permission.DASHBOARD_CSC,
          className: "col-xl-6 custom-col-xxl-4",
          statistics: [
            this.statistics[DashboardStatisticType.PENDING_FAULT]
          ]
        }
      ]
    },
  ];

  searchKeywords: string;
  workCentreOptions: string[] = [];
  cscPermission: boolean = false;
  sbuPermission: boolean = false;
  selectedWorkCentres: any[] = [];

  isShowCscOperationContainer: boolean = false;

  constructor(injector: Injector, private approvalService: ApprovalService) {
    super(injector);

    // window.opener = window.opener || {};
    // window.opener.angularSocket = window.opener.angularSocket || {};
    // window.opener.angularSocket.handleSocketMessage = this.handleSocketMessage.bind(this);
  }

  ngOnInit() {
    this.cscPermission = this.authorizationService.hasPermission(JMENUM.Permission.DASHBOARD_CSC);
    this.sbuPermission = this.authorizationService.hasPermission(JMENUM.Permission.DASHBOARD_SBU);

    if (Session.featureList) {
      const weatherEformFeature = Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.JM_ADVERSE_WEATHER_FORM);
      weatherEformFeature && weatherEformFeature.enabled && this.checkRedirectToWeatherEformSystem();
    }

    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.workCentreOptions = JM.JMConnector.getAllWorkCentreCode();
    } else {
      this.workCentreOptions = this.authorizationService.getWorkCenters();
    }

    let matchedWorkCentreList = (Session.dashboardSelectedworkCentre) ? Session.dashboardSelectedworkCentre.filter(workCentre => { return this.workCentreOptions.includes(workCentre) }) : [];
    if (matchedWorkCentreList && matchedWorkCentreList.length > 0) {
      this.selectedWorkCentres = matchedWorkCentreList;
    } else {
      this.selectedWorkCentres = JSON.parse(JSON.stringify(this.workCentreOptions));
    }
    Session.setDashboardSelectedWorkCentre(this.selectedWorkCentres);

    this.getDashboardInformation();

    this.checkAndShowChangePWReminder();

    this.initActiveTabCmPm();
    this.isShowCscOperationContainer = this.isShowStatisticGroupsContainer(this.statisticGroups[1]);
  }

  checkRedirectToWeatherEformSystem() {
    if (this.authorizationService.hasOnlyWeatherFormPermission()) {
      const param = {
        t: Session.userToken,
        lang: Session.selectedLanguage,
      } as any;
      const queryStr = qs.stringify(param);
      window.location.replace(`${environment.CCEP_EFORM_WEB_HOST}/form-type?${queryStr}`);
    }
  }

  protected componentName(): string {
    return "dashboard";
  }

  //Initial Information Function
  initActiveTabCmPm() {
    const CmPmTableGroup = this.statisticGroups[0].tabGroups;
    const showCmTab = this.authorizationService.hasPermissions(CmPmTableGroup.sn.permission, true);
    const showPmTab = this.authorizationService.hasPermissions(CmPmTableGroup.pm.permission, true);

    this.tabActive.snPm = showCmTab ? 'sn' : showPmTab ? 'pm' : 'sn';
  }

  isShowStatisticGroupsContainer(statisticGroups) {
    if (Array.isArray(statisticGroups.rows)) {
      const rowHasPermission = statisticGroups.rows.filter(row => {
        return row.permission && this.authorizationService.hasPermission(row.permission);
      });

      return rowHasPermission.length > 0;
    } else return false;
  }

  getDashboardInformation() {
    if (this.cscPermission) {
      this.getPendingFaultStatistic();
      this.getFaxFailureStatistic();
      this.getMyManualInstructionsStatistic();
      this.getManualInstructionsQueueStatistic();
      this.getOtherManualInstructionsStatistic();
    }

    if (this.sbuPermission) {
      this.getSnToBeProcessStatistic();
      this.getReassignmentRequiredStatistic();
    }

    if (this.cscPermission || this.sbuPermission) {
      this.getPendingAckStatistic();
      this.getFurtherActionStatistic();

      this.getOutStandingPmEquipStatInhouse();
      // this.getOutStandingPmEquipStatContract();
    }

    this.requestSnJobApprovalStatistic();
    this.requestPmPlanApprovalStatistic();
    this.requestPmJobApprovalStatistic();
  }

  private async requestSnJobApprovalStatistic() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalCmPending(minPageNumber, minPageSize, needApprovalDetail);

    this.statistics[DashboardStatisticType.APPROVAL_CM].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.APPROVAL_CM].records = [];
  }

  private async requestPmPlanApprovalStatistic() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalPmPlanPending(minPageNumber, minPageSize, needApprovalDetail);

    this.statistics[DashboardStatisticType.APPROVAL_PM_PLAN].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.APPROVAL_PM_PLAN].records = [];
  }

  private async requestPmJobApprovalStatistic() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalPmJobPending(minPageNumber, minPageSize, needApprovalDetail);

    this.statistics[DashboardStatisticType.APPROVAL_PM_JOB].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.APPROVAL_PM_JOB].records = [];
  }

  async getPendingFaultStatistic() {
    const request = new JM.JMRequestSnDashboardPendingFault();

    const response: JM.JMResponseSnDashboardPendingFault = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.statistics[DashboardStatisticType.PENDING_FAULT].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.PENDING_FAULT].records = response.payload.records;
  }

  getPendingAckStatistic() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.PENDING_ACK].value = [];
      this.statistics[DashboardStatisticType.PENDING_ACK].records = [[], []];
      return;
    }

    let maintenanceTypes = Object.values(JMENUM.MaintenanceType);
    maintenanceTypes.forEach(async (maintenanceType, index) => {
      const request = new JM.JMRequestSnDashboardPendingAck();
      request.workCentreList = this.selectedWorkCentres;
      request.maintenanceType = maintenanceType;

      const response: JM.JMResponseSnDashboardPendingAck = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.statistics[DashboardStatisticType.PENDING_ACK].value[index] = response.payload.totalCount;
      this.statistics[DashboardStatisticType.PENDING_ACK].records[index] = response.payload.records;
    })
  }

  getFurtherActionStatistic() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.FURTHER_ACTION_REQUIRED].value = [];
      this.statistics[DashboardStatisticType.FURTHER_ACTION_REQUIRED].records = [[], []];
      return;
    }

    let maintenanceTypes = Object.values(JMENUM.MaintenanceType);
    maintenanceTypes.forEach(async (maintenanceType, index) => {
      const request = new JM.JMRequestSnDashboardFurtherActions();
      request.workCentreList = this.selectedWorkCentres;
      request.maintenanceType = maintenanceType;

      const response: JM.JMResponseSnDashboardFurtherActions = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.statistics[DashboardStatisticType.FURTHER_ACTION_REQUIRED].value[index] = response.payload.totalCount;
      this.statistics[DashboardStatisticType.FURTHER_ACTION_REQUIRED].records[index] = response.payload.records;
    })
  }

  async getOutStandingPmEquipStatInhouse() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.OUTSTANDING_PM_EQUIPMENT_INHOUSE].value = 0;
      this.statistics[DashboardStatisticType.OUTSTANDING_PM_EQUIPMENT_INHOUSE].records = [];
      return;
    }

    const request = new JM.JMRequestPmPlanDashboardGetOutstandingPeriodEquipmentList();
    request.workCentreList = this.selectedWorkCentres;
    request.pageSize = 1;
    request.parameters = ['equipment.equipmentNumber'];

    const response: JM.JMResponsePmPlanDashboardGetOutstandingPeriodEquipmentList = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.statistics[DashboardStatisticType.OUTSTANDING_PM_EQUIPMENT_INHOUSE].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.OUTSTANDING_PM_EQUIPMENT_INHOUSE].records = [];
  }

  getOutStandingPmEquipStatContract() {
    this.statistics[DashboardStatisticType.OUTSTANDING_PM_EQUIPMENT_CONTRACT].value = 0;
    this.statistics[DashboardStatisticType.OUTSTANDING_PM_EQUIPMENT_CONTRACT].records = [];
  }

  getSnToBeProcessStatistic() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.TO_BE_PROCESS].value = [];
      this.statistics[DashboardStatisticType.TO_BE_PROCESS].records = [[], []];
      return;
    }

    let maintenanceTypes = Object.values(JMENUM.MaintenanceType);
    maintenanceTypes.forEach(async (maintenanceType, index) => {
      const request = new JM.JMRequestSnDashboardToBeProcess();
      request.workCentreList = this.selectedWorkCentres;
      // request.parameters = ["snNumber"];
      request.maintenanceType = maintenanceType;

      const response: JM.JMResponseSnDashboardToBeProcess = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.statistics[DashboardStatisticType.TO_BE_PROCESS].value[index] = response.payload.totalCount;
      this.statistics[DashboardStatisticType.TO_BE_PROCESS].records[index] = response.payload.records;
      this.getJobCardToBeProcessStatistic(index, maintenanceType);
    });
  }

  async getJobCardToBeProcessStatistic(index, maintenanceType) {
    const request = new JM.JMRequestJobCardsDashboardToBeProcess();
    request.workCentreList = this.selectedWorkCentres;
    // request.parameters = ["snNumber"];
    request.maintenanceType = maintenanceType;

    const response: JM.JMResponseJobCardsDashboardToBeProcess = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.statistics[DashboardStatisticType.TO_BE_PROCESS].value[index] += (response.payload.snTotalCount) ? response.payload.snTotalCount : 0;
    if (response.payload.records.length > 0) {
      response.payload.records.forEach(record => {
        this.statistics[DashboardStatisticType.TO_BE_PROCESS].records[index].push(record.snNumber);
      });
    }
  }

  async getFaxFailureStatistic() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.FAILED_FAX].value = 0;
      this.statistics[DashboardStatisticType.FAILED_FAX].records = [];
      return;
    }

    const request = new JM.JMRequestSnDashboardFaxFailure();
    request.workCentreList = this.selectedWorkCentres;

    const response: JM.JMResponseSnDashboardFaxFailure = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.statistics[DashboardStatisticType.FAILED_FAX].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.FAILED_FAX].records = response.payload.records;
  }

  getReassignmentRequiredStatistic() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.REASSIGNMENT_REQUIRED].value = [];
      this.statistics[DashboardStatisticType.REASSIGNMENT_REQUIRED].records = [[], []];
      return;
    }

    let maintenanceTypes = Object.values(JMENUM.MaintenanceType);
    maintenanceTypes.forEach(async (maintenanceType, index) => {
      const request = new JM.JMRequestJobCardsDashboardReassignmentRequired();
      request.workCentreList = this.selectedWorkCentres;
      request.maintenanceType = maintenanceType;

      const response: JM.JMResponseJobCardsDashboardReassignmentRequired = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.statistics[DashboardStatisticType.REASSIGNMENT_REQUIRED].value[index] = (response.payload.snTotalCount) ? response.payload.snTotalCount : 0;
      if (response.payload.records.length > 0) {
        response.payload.records.forEach(record => {
          this.statistics[DashboardStatisticType.REASSIGNMENT_REQUIRED].records[index].push(record.snNumber);
        });
      } else {
        this.statistics[DashboardStatisticType.REASSIGNMENT_REQUIRED].records[index] = [];
      }
    });
  }

  async getMyManualInstructionsStatistic() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.MY_MANUAL_INSTRUCTION].value = 0;
      this.statistics[DashboardStatisticType.MY_MANUAL_INSTRUCTION].records = [];
      return;
    }

    const request = new JM.JMRequestSnDashboardPendingManualInstructions();
    request.workCentreList = this.selectedWorkCentres;
    request.manualInstructionHandleBy = "me";
    request.snCreatedParties = [JMENUM.SNCreatedParty.CSC];

    const response: JM.JMResponseSnDashboardPendingManualInstructions = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.statistics[DashboardStatisticType.MY_MANUAL_INSTRUCTION].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.MY_MANUAL_INSTRUCTION].records = response.payload.records;
  }

  async getManualInstructionsQueueStatistic() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.MANUAL_INSTRUCTION_QUEUE].value = 0;
      this.statistics[DashboardStatisticType.MANUAL_INSTRUCTION_QUEUE].records = [];
      return;
    }

    const request = new JM.JMRequestSnDashboardPendingManualInstructionsQueue();
    request.workCentreList = this.selectedWorkCentres;
    request.snCreatedParties = [JMENUM.SNCreatedParty.CSC];

    const response: JM.JMResponseSnDashboardPendingManualInstructionsQueue = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.statistics[DashboardStatisticType.MANUAL_INSTRUCTION_QUEUE].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.MANUAL_INSTRUCTION_QUEUE].records = response.payload.records;
  }

  async getOtherManualInstructionsStatistic() {
    if (!this.selectedWorkCentres || this.selectedWorkCentres.length === 0) {
      this.statistics[DashboardStatisticType.OTHER_MANUAL_INSTRUCTION].value = 0;
      this.statistics[DashboardStatisticType.OTHER_MANUAL_INSTRUCTION].records = [];
      return;
    }
    const request = new JM.JMRequestSnDashboardPendingManualInstructions();
    request.workCentreList = this.selectedWorkCentres;
    request.manualInstructionHandleBy = "other";
    request.snCreatedParties = [JMENUM.SNCreatedParty.CSC];

    const response: JM.JMResponseSnDashboardPendingManualInstructions = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }
    this.statistics[DashboardStatisticType.OTHER_MANUAL_INSTRUCTION].value = response.payload.totalCount;
    this.statistics[DashboardStatisticType.OTHER_MANUAL_INSTRUCTION].records = response.payload.records;
  }

  //Layout Event Function
  refreshDashboard() {
    this.getDashboardInformation();
  }

  search() {
    Session.setSnListGeneralSearchKeyword(this.searchKeywords);
    this.router.navigate(["/sn/list"]);
  }

  searchByAdvanced() {
    //TODO: set advanced search criatia
    Session.setSnListAdvancedSearchCriteria({ status: "sn-" + JMENUM.SnStatus.PENDING_ACKNOWLEDGE, manualInstructionHandleBy: "all" });
    this.router.navigate(["/sn/list"]);
  }

  clickStatistic(key, records, value) {
    switch (key) {
      case STAT_KEY.EQUIP_INHOUSE:
        value > 0 && this.router.navigate(['/pm/equipments/outstanding/inhouse']);
        return;
      case STAT_KEY.EQUIP_CONTRACT:
        // this.router.navigate(['/pm/equipments/outstanding/contract']);
        return;
      case STAT_KEY.APPROVAL_CM:
        value > 0 && this.router.navigate(['/setting/approvalList']);
        return;
      case STAT_KEY.APPROVAL_PM_PLAN:
        value > 0 && this.router.navigate(['/setting/approvalList/pm/plan']);
        return;
      case STAT_KEY.APPROVAL_PM_JOB:
        value > 0 && this.router.navigate(['/setting/approvalList/pm/job']);
        return;
    }

    if (records != null && records != '') {
      this.searchKeywords = "snNumber:" + records.toString().replace(/,/g, ' ');
      this.search();
    }
  }

  selectAllCentre(event) {
    this.selectedWorkCentres = event.target.checked ? JSON.parse(JSON.stringify(this.workCentreOptions)) : [];  // assign to copy
    Session.setDashboardSelectedWorkCentre(this.selectedWorkCentres)
    this.refreshDashboard();
  }

  selectCentre(event, workCentre) {
    if (event.target.checked) {
      this.selectedWorkCentres.push(workCentre);
    }
    else {
      this.selectedWorkCentres.splice(this.selectedWorkCentres.indexOf(workCentre), 1);
    }

    Session.setDashboardSelectedWorkCentre(this.selectedWorkCentres)
    this.refreshDashboard();
  }

  // TODO: reload language
  onLanguageChanged() {

  }

  // handle stat group tab click
  onGroupTabClick(key, type) {
    if (this.tabActive[type] != key) {
      this.tabActive[type] = key;
    }
  }

  checkAndShowChangePWReminder() {
    const userName = Session.userInfo.name;
    const reminderDays = [14,7,4,3,2,1];
    const passwordRemainingValidDays = Session.userInfo.passwordRemainingValidDays;
    const passwordExpiryDaysConfig = Session.userInfo.passwordExpiryDaysConfig;
    const shdRemindChangePW = reminderDays.includes(passwordRemainingValidDays) && !this.authorizationService.isUserRemindedPwChange(userName);
    if (!shdRemindChangePW) {
      return;
    }
    const config: MatDialogConfig = {};
    config.disableClose = true;
    config.data = {
      title: JMLanguage.translate('reminder.title'),
      buttons: [
        {
          name: JMLanguage.translate('reminder.change-password.action.reset-now'),
          handler: () => this.changePWNow(userName),
          backgroundColor: '#3b8cc1'
        },
        {
          name: JMLanguage.translate('reminder.change-password.action.maybe-later'),
          handler: () => this.remindChangePWLaterAcknowledged(userName),
          backgroundColor: '#f0ad4e'
        }
      ],
      messageArray: [
        JMLanguage.translate('reminder.change-password.content.1', [passwordExpiryDaysConfig]),
        JMLanguage.translate('reminder.change-password.content.2', [passwordRemainingValidDays])
      ],
      titleIcon: 'fas fa-bell'
    };
    config.backdropClass = 'dark-blur-background';
    config.panelClass = 'change-pw-panel';
    this.showPopUpReminder(config);
  }

  changePWNow(userName: string) {
    this.remindChangePWLaterAcknowledged(userName);
    this.router.navigate(["/setting/profile"]);
  }

  remindChangePWLaterAcknowledged(userName: string) {
    this.authorizationService.addUserRemindedPwChange(userName);
  }
}
