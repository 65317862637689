import { Injectable } from '@angular/core';
import { JMOBJ, JMENUM } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';


@Injectable({
  providedIn: 'root'
})
export class EquipmentTagService {

  constructor() { }

  getTagDescription(tag: JMOBJ.HashTag, showEquipmentType: boolean = true): string {
    const equipmentType = (showEquipmentType && tag.equipmentType) ? `[${tag.equipmentType}] ` : '';
    const description = Session.selectedLanguage === JMENUM.Language.ZH ? tag.description.zh : tag.description.en;
    const tagGroupName = (tag.tagGroup && tag.tagGroup.isNameOnDisplay) ? ` <${tag.tagGroup.name}>` : '';

    return `${equipmentType}${description}${tagGroupName}`;
  };

  isUsableTag(tag: JMOBJ.HashTag, workCentre: string): boolean {
    if (!tag.tagGroup || !tag.tagGroup || !tag.tagGroup.workCentres) {
      return false;
    }

    const isActiveTagGroup = tag.tagGroup.status === JMENUM.HashtagGroupStatus.ACTIVE;
    const isCoverWorkCentre = tag.tagGroup.workCentres
      .filter(wc => wc.workCentreCode)
      .map(wc => wc.workCentreCode)
      .includes(workCentre);

    return isActiveTagGroup && isCoverWorkCentre;
  }
}
