import { Component, OnInit } from '@angular/core';
import { JMENUM, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session } from 'src/app/services/session';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  sectionItems: any[] = [];

  constructor() { }

  ngOnInit() {
    this.initSectionItems();
  }

  initSectionItems() {
    this.sectionItems = [
      {
        sectionName: JMLanguage.translate("side-bar.download-report"),
        theme: "bg-blue",
        items: [
          {
            id: "snReport",
            name: JMLanguage.translate("side-bar.download-sn-report"),
            icon: "fa fa-list-alt",
            routing: "/report-download/sn",
            permission: JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.SN_VIEW)
          },
          {
            id: "jobCardReport",
            name: JMLanguage.translate("side-bar.download-job-report"),
            icon: "fa fa-list-alt",
            routing: "/report-download/job",
            permission: JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.SN_VIEW)
          },
          {
            id: 'pmsmcStatisticsReport',
            name: JMLanguage.translate('side-bar.download-pmsmc-statistics-report'),
            icon: 'fa fa-list-alt',
            routing: '/report-download/pmsmc-statistics',
            permission: JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.JOBCARD_VIEW_ALL),
          },
          {
            id: "contractorCMJobReport",
            name: JMLanguage.translate("side-bar.download-contractor-cm-job-report"),
            icon: "fa fa-list-alt",
            routing: "/report-download/contractor-cm-job",
            permission: JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.JOBCARD_VIEW)
          }
        ]
      }
    ];
  }

}
