import { Component, Injector, OnInit, Input } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import * as moment from 'moment';
import { pad, toNgbDate } from 'src/lib/presenter/Formatter';

@Component({
    selector: 'app-pm-job-job-progress',
    templateUrl: './pm-job-job-progress.component.html',
    styleUrls: ['./pm-job-job-progress.component.scss']
})
export class PmJobJobProgressComponent extends BasePage implements OnInit {

    @Input() set job( job ) {
        if (job) {
          this.setValueFromPmJob(job);
          this.arrivalGpsParam = job.arrival != undefined && {
            startTime : job.startTime,
            completionTime : job.completionTime,
            gpsLocations: job.arrival
            }
        }
        this._job = job;

    };
    @Input() pageMode: JMENUM.JMPageMode;

    JMPageMode = JMENUM.JMPageMode;

    errorFields: any = {};
    timeAlertMsg: string = undefined;
    dateAlertMsg: string = undefined;
    mandatory: any = {};
    editable: any = {};
    _job : any = {};

    dateInputSet : any = {};
    timeInputSet : any = {};
    arrivalGpsParam: any = undefined;

    inputFieldSettings : {
        [key: string] : {
            editable?: boolean
            mandatory?: boolean
            failed?: boolean
        }
    };

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.timeAlertMsg = JMLanguage.translate("global.invalid-time");
        this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
        this.initDateTimeSet();
        this.fieldsControl();
    }

    initDateTimeSet() {
        this.dateInputSet = {
            appointmentTime: undefined,
            startTime: undefined,
            completionTime: undefined,
            malfunctionStartTime: undefined,
            malfunctionEndTime: undefined,
        };
        this.timeInputSet = {
            appointmentTime: undefined,
            startTime: undefined,
            completionTime: undefined,
            malfunctionStartTime: undefined,
            malfunctionEndTime: undefined,
        };
    }

    private fieldsControl() {
        this.inputFieldSettings = {
            startTime : {
                editable: true,
                mandatory: true,
            },
            completionTime: {
                editable: true,
                mandatory: true,
            },
        }
        // this.setValueFromPmJob();

    }

    public clearAll(){
        for (let field in this.inputFieldSettings) {
            this._job[field] = undefined;
            this.inputFieldSettings[field].failed = false;
            this.dateInputSet[field] = undefined;
            this.timeInputSet[field] = undefined;
        }
    }

    private setValueFromPmJob(job) {
        let startTime: any = job.startTime ? job.startTime : undefined;
        if (startTime) {
            let time = moment(startTime);
            this.dateInputSet['startTime'] = toNgbDate(startTime);
            this.timeInputSet['startTime'] = pad(time.hour()) + ':' + pad(time.minute());
        }

        let completionTime: any = job.completionTime ? job.completionTime : undefined;
        if (completionTime) {
            let time = moment(completionTime);
            this.dateInputSet['completionTime'] = toNgbDate(completionTime);
            this.timeInputSet['completionTime'] = pad(time.hour()) + ':' + pad(time.minute());
        }
    }

    public onBlurDateInput(event) {
        this.dateInputSet[event.field] = event.data;
        this.setDateTime(event.field);
    }

    public onBlurTimeInput(event) {
        this.timeInputSet[event.field] = event.data;
        this.setDateTime(event.field);
    }

    public setDateTime(field) {
        this.resetErrorField(field);
        if (this.dateInputSet[field] && this.timeInputSet[field]) {
            let date = this.dateInputSet[field];
            let timeArr = this.timeInputSet[field].split(':');
            let formattedDate = new Date(date.year, date.month - 1, date.day);
            if (timeArr && timeArr.length > 0) {
                formattedDate.setHours(timeArr[0]);
                formattedDate.setMinutes(timeArr[1]);
                this._job[field] = new Date(formattedDate);
            }
        }

        if (!this.timeInputSet[field] || !this.dateInputSet[field]) {
            this._job[field] = undefined;
        }

        if (this._job['startTime'] && this._job['completionTime']) {
          if(!this.validateJobDuration()) return;
        }

        if (this._job['startTime'] && this._job.period) {
          if(!this.validateStartTime()) return;
        }

        if (this._job['completionTime'] && this._job.period) {
          if(!this.validateCompletionTime()) return;
        }
    }

    public validateJobDuration() {
        // reset
        this.inputFieldSettings.completionTime.failed = false;
        this.inputFieldSettings.startTime.failed = false;

        if (this._job['startTime'] && this._job['completionTime']) {
            let completionTime = new Date(this._job.completionTime);
            let startTime = new Date(this._job.startTime);
            let differTime = completionTime.valueOf() - startTime.valueOf();

            // check if complete time > start time
            if (differTime <= 0) {
                this.inputFieldSettings.completionTime.failed = true;
                this.inputFieldSettings.startTime.failed = true;
                AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-job.job-progress.arrival-time-must-be-earlier-than-completion-time"));
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    public validateStartTime() {
        // show error if startTime < periodStartDate
        if (this._job.startTime && this._job.period.periodStartDate) {
            let differTime = moment(this._job.startTime).diff(moment(this._job.period.periodStartDate), 'days', true);
            if (differTime < 0) {
                this.inputFieldSettings.startTime.failed = true;
                AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-job.job-progress.pm-job-cannot-exceed-pm-period"));
                return false;
            } else {
                return true;
            }
        } else {
          return false;
        }
    }

    public validateCompletionTime() {
        // show error if completionTime > periodEndDate
        if (this._job.completionTime && this._job.period.periodEndDate) {
            let differTime = moment(this._job.completionTime).diff(moment(this._job.period.periodEndDate), 'days', false);
            if (differTime > 0) {
                this.inputFieldSettings.completionTime.failed = true;
                AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-job.job-progress.error.pm-job-after-pm-period"));
                return false;
            } else {
                return true;
            }
        } else {
          return false;
        }
    }

    public validate = () => {
      let validationStatus = true;

      for (let field in this.inputFieldSettings) {
        let target = this.inputFieldSettings[field];
        if (target.editable && target.mandatory) {
          let isFieldEmpty = this._job[field] ? false : true;
          target.failed = isFieldEmpty;
          if (isFieldEmpty) {
            validationStatus = false;
          }
        }
      }

      if (!validationStatus) {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.please-fill-in-mandatory-fields"));
        return validationStatus;
      }

      if (!this.validateJobDuration()) {
        return validationStatus = false;
      }

      if (this._job['startTime'] && this._job.period && !this.validateStartTime()) {
        return validationStatus = false;
      }

      if (this._job['completionTime'] && this._job.period && !this.validateCompletionTime()) {
        return validationStatus = false;
      }

      return validationStatus;
    }

    public resetErrorField(key) {
        if (this.inputFieldSettings[key] != undefined) {
            this.inputFieldSettings[key].failed = false;
        }
    }
}