import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { JM, JMCONSTANT, JMENUM } from "@ccep/CCEPConnector-ts";
import { Session } from "@services/session";
import { BasePage } from "src/app/ui/model/base/base";
import * as utility from 'src/app/services/utility';
import { ActionButtonDefinition, ActionButtonJMAccountReview, ActionButtonType } from "@enum/action-button";
import { AppDelegate } from "src/app/AppDelegate";
import { JMLanguage } from "src/lib/JMLanguage/JMLanguage";
import { SidebarComponent } from "src/app/ui/components/sidebar/sidebar.component";


@Component({
    selector: "app-ccep-jm-account-review-send-email",
    templateUrl: "./ccep-jm-account-review-send-email.component.html",
    styleUrls: ["./ccep-jm-account-review-send-email.component.scss"],
})
export class CCePJMAccountReviewSendEmailComponent extends BasePage implements OnInit {
    @ViewChild('sideBar', { static: true }) sideBar: SidebarComponent;
    isEnableReview: boolean = undefined;
    emailSentDate: Date;
    completionDate: Date;
    actionButtonData = [];

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.checkfeatureEnabled() && this.checkPermission() && this.checkReviewStatus();
        this.initActionBar();
    }

    checkfeatureEnabled(): boolean {
        if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JM_HALF_YEAR_REVIEW_USER_ACCOUNT)) {
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

    checkPermission(): boolean {
        if (!this.authorizationService.hasPermission(JMENUM.Permission.ACCOUNT_REVIEW_SEND_EMAIL)) {
            AppDelegate.openSnackBar(JMLanguage.translate('api.error.401'));
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

    initActionBar() {
        this.actionButtonData = [];
        const historyBtn = ActionButtonDefinition[ActionButtonType.jmAccountReview][ActionButtonJMAccountReview.history];
        this.actionButtonData.push(historyBtn);
    }

    onActionButtonClicked(args: any) {
        if (args.action === ActionButtonJMAccountReview.history) {
            this.router.navigate(['/ccep-jm-account-review/email-history']);
        }
    }

    async checkReviewStatus() {
        const request = new JM.JMRequestAuthHalfYearReviewCheckReviewStatus();
        const response: JM.JMResponseAuthHalfYearReviewCheckReviewStatus = await AppDelegate.sendJMRequest(request);

        if (!response || !response.code || response.code !== 200 || !response.payload) {
            AppDelegate.openErrorBar(response);
            return;
        }
        this.isEnableReview = response.payload.enabled;
        if (this.isEnableReview) {
            this.emailSentDate = response.payload.startDate;
            this.completionDate = response.payload.endDate;
        }
        if (Session.isEnableJMAccountReview !== this.isEnableReview) {
            Session.setIsEnableJMAccountReview(this.isEnableReview);
            this.sideBar.reRenderSideBar();
        }
    }

    async sendReviewEmail() {
        this.isEnableReview = undefined;
        const request = new JM.JMRequestAuthHalfYearReviewStartReview();
        const response: JM.JMResponseAuthHalfYearReviewStartReview = await AppDelegate.sendJMRequest(request);

        if (!response || !response.code || response.code !== 200 || !response.payload) {
            this.isEnableReview = false;
            AppDelegate.openErrorBar(response);
            return;
        }
        this.isEnableReview = response.payload.enabled;
        if (this.isEnableReview) {
            this.emailSentDate = response.payload.startDate;
            this.completionDate = response.payload.endDate;
        }
        if (Session.isEnableJMAccountReview !== this.isEnableReview) {
            Session.setIsEnableJMAccountReview(this.isEnableReview);
            this.sideBar.reRenderSideBar();
        }
    }

    onConfirmClicked() {
        let message = this.translate("pages.ccep-jm-account-review.send-email-confirmation.ask-confirm");
        let buttons = [
            { name: this.translate("global.yes"), handler: () => { this.sendReviewEmail() } },
            { name: this.translate("global.no") }
        ]
        this.showPopUpAlert(message, "", buttons);
    }
}