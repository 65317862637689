<div class="pm-plan-remarks-component">
  <div class="font-size-xl m-0 mb-2">
    {{ 'component.plan-remarks.title' | translate }}
  </div>

  <hr class="underline" />

  <ng-container *ngIf="pageMode === JMPageMode.VIEW">
    <div class="messages">
      <div *ngFor="let msg of messages; index as i" class="message">
        <p *ngIf="msg.title" class="left">{{ msg.title }}</p>
        <p class="right">{{ msg.details }}</p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="pageMode !== JMPageMode.VIEW">
    <textarea
      *ngIf="editable?.remarks"
      id="edit-pm-plan-remarks"
      class="form-control"
      rows="3"
      [maxlength]="remarksMaxLength"
      [(ngModel)]="plan.remarks"
      (change)="onChangeRemarks()"
      [class.input-error]="errorFields.remarks"
    ></textarea>
  </ng-container>
</div>
