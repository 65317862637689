import { Component, Input, OnInit, Output , EventEmitter} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pop-up-with-radio',
  templateUrl: './pop-up-with-radio.component.html',
  styleUrls: ['./pop-up-with-radio.component.scss'],
})
export class PopUpWithRadioComponent implements OnInit {
  @Input() radios: PopUpWithRadioComponentRadiosI[];
  // @Output() result : EventEmitter<any> = new EventEmitter();
  @Input() successActionCallBack: Function;
  @Input() dismissActionCallBack?: Function;
  @Input() modalTitle: string;
  @Input() modalDescription: string;
  @Input() closeBtnName: string;
  @Input() dismissBtnName: string;

  checkedIndex = 0;
  ngbActiveModal: NgbActiveModal;

  constructor(ngbActiveModal: NgbActiveModal) {
    this.ngbActiveModal = ngbActiveModal;
  }

  ngOnInit() {}

  onChangeRadio(i: number) {
    this.checkedIndex = i;
    // this.result.emit(radio);
  }

  submit() {
    this.ngbActiveModal.close(this.radios[this.checkedIndex]);
  }

  dismiss(reason: string) {
    this.ngbActiveModal.dismiss(reason);
  }
}
export interface PopUpWithRadioComponentRadiosI {
  description : string
  value : any
}