import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';

/*
  This class store all the sessino variables of current logged in user
  including the
  
    1. JM related session: current user name, user object, user token,
    2. user preference: selected language, locale etc
    3. JM related cache
    4. input/selected value of each page
*/

class SessionClass {
  // settings
  public rememberMe: boolean = false;
  public rememberUsername: string = null;
  public userInfo: JMOBJ.Post = null;
  public userToken: string = null;
  public refreshToken: string = null;
  public authSource: string = null;
  public pwReminderDisableList: {[x:string]: Array<string>} = null;
  
  // User preference
  public selectedLanguage: JMENUM.Language = null;
  public selectedWorkCentre: string = null;
  public selectedHACluster: string = null;
  public dashboardSelectedworkCentre: string[] = null;
  
  // JM Caching
  // public equipmentCategory: {[key: string]: JMOBJ.EquipmentCategory; } = null;
  public equipmentCategoryDict: {[id:string]:JMOBJ.EquipmentCategory} = null;
  public equipmentTypeDict: {[id:string]:JMOBJ.EquipmentType} = null;
  public clientDict: {[id:string]:JMOBJ.Client} = null;
  public workCentreDict: {
    [id:string]:JMOBJ.WorkCentre,
  } = null;
  public manualInstructionArray: JMOBJ.ManualInstruction[] = null;
  public storageLocationAiList: any[] = null;
  public costCentreList: string[] = null;
  public categoryCodeDict: {} = null;
  public classificationCodeDict: {} = null;
  public featureList: {[x:string]:any}[] = null;

  // page variables, naming convention: <page name><variable name>
  public snListGeneralSearchKeyword: string = null;
  public snListAdvancedSearchCriteria: {} = null;
  public snListSettingsColumns: any = null;
  public teamSettingsSelecteTeamId: string = null;
  public snCreateSnInputForm: any = null;
  public snCreateClientDetail: any = null;
  public pmPeriodListSavedSearchCriteria: any = null;
  public pmPlanListSettingsFilters: any = null;
  public pmPlanListSettingsColumns: any = null;
  public pmJobListSettingsFilters: any = null;
  public pmJobListSettingsColumns: any = null;
  public tecoJobListSettingsColumns: any = null;
  public untecoJobListSettingsColumns: any = null;
  public approvalCmListSettingsColumns: any = null;
  public jobListSettingsFilters: any = null;
  public pmTaskListGeneralSearchCriteria: Map <string,any>=new Map();
  // routing rule
  public routingRuleId: string = null;

  // jm account review
  public isEnableJMAccountReview: boolean = false;

  // constant for local storage
  private readonly REMEMBER_ME: string = "REMEMBER_ME";
  private readonly REMEMBER_USERNAME: string = "REMEMBER_USERNAME";
  private readonly USER_INFO: string = "ccep-login";
  private readonly USER_TOKEN: string = "ccep-token";
  private readonly REFRESH_TOKEN: string = "ccep-refresh-token";
  private readonly AUTH_SOURCE: string = "auth-source";
  private readonly PW_REMINDER: string = "pw-reminder";

  private readonly SELECTED_LANGUAGE: string = "ccep-current-language";
  private readonly SELECTED_WORK_CENTRE: string = "selected-work-centre";
  private readonly SELECTED_HA_CLUSTER: string = "selected-ha-cluster";
  private readonly DASHBOARD_SELECTED_WORK_CENTRE: string = "dashboard-selected-work-centre";

  private readonly EQUIPMENT_CATEGORIES: string = "equipment-categories";
  private readonly EQUIPMENT_TYPES: string = "equipment-types";
  private readonly CLIENT_DICT: string = "client-dict";
  private readonly CACHE_KEY_WORK_CENTRE_DICT:string = "CACHE_KEY_WORK_CENTRE_DICT";
  private readonly CACHE_KEY_MANUAL_INSTRUCTION:string = "CACHE_KEY_MANUAL_INSTRUCTION";
  private readonly CACHE_STORAGE_LOCATION_AI_LIST:string = "CACHE_STORAGE_LOCATION_AI_LIST";
  private readonly CACHE_COST_CENTRE_LIST:string = "CACHE_COST_CENTRE_LIST";
  private readonly CACHE_KEY_CATEGORY_CODE: string = "category-code";
  private readonly CACHE_KEY_CLASSIFICATION_CODE: string = "classification-code";
  private readonly FEATURE_LIST:string = "feature-list";

  private readonly SN_LIST_GENERAL_SEARCH_KEYWORD:string = "general-search-keywords";
  private readonly SN_LIST_ADVANCED_SEARCH_CRITERIA:string = "advanced-search-Criteria";
  private readonly SN_LIST_SETTINGS_COLUMNS: string = "settings-sn-list-columns";
  private readonly TEAM_SETTINGS_SELECTED_TEAM_ID:string = "selected-team-id";
  private readonly SN_CREATE_SN_INPUT_FORM:string = "sn-input-form";
  private readonly SN_CREATE_CLIENT_DETAIL:string = "sn-client-info";
  private readonly PM_PERIOD_LIST_SAVED_SEARCH_CRITERIA:string = "pm-period-list-saved-search-Criteria";
  private readonly PM_PLAN_LIST_SETTINGS_FILTERS:string = "settings-pm-plan-list-filters";
  private readonly PM_PLAN_LIST_SETTINGS_COLUMNS:string = "settings-pm-plan-list-columns";
  private readonly PM_JOB_LIST_SETTINGS_FILTERS:string = "settings-pm-job-list-filters";
  private readonly PM_JOB_LIST_SETTINGS_COLUMNS:string = "settings-pm-job-list-columns";
  private readonly APPROVAL_CM_LIST_SETTINGS_COLUMNS:string = "settings-approval-cm-list-columns";
  private readonly TECO_JOB_LIST_SETTINGS_COLUMNS: string = "settings-teco-job-list-columns";
  private readonly UNTECO_JOB_LIST_SETTINGS_COLUMNS: string = "settings-unteco-job-list-columns";
  private readonly JOB_LIST_SETTINGS_FILTERS:string = "settings-job-list-columns";
  private readonly ROUTING_RULE_ID: string = "routing-rule-id";
  private readonly IS_ENABLE_JM_ACCOUNT_REVIEW: string = "is-enable-jm-account-review";


  constructor() {
    this.rememberMe = this.get(this.REMEMBER_ME);
    this.rememberUsername = this.get(this.REMEMBER_USERNAME);
    this.userInfo = this.get(this.USER_INFO);
    this.userToken = this.get(this.USER_TOKEN);
    this.refreshToken = this.get(this.REFRESH_TOKEN);
    this.authSource = this.get(this.AUTH_SOURCE);
    this.pwReminderDisableList = this.get(this.PW_REMINDER);

    this.selectedLanguage = (this.get(this.SELECTED_LANGUAGE))?this.get(this.SELECTED_LANGUAGE):JMENUM.Language.EN;
    this.selectedWorkCentre = this.get(this.SELECTED_WORK_CENTRE);
    this.selectedHACluster = this.get(this.SELECTED_HA_CLUSTER);
    this.dashboardSelectedworkCentre = this.get(this.DASHBOARD_SELECTED_WORK_CENTRE);

    this.equipmentCategoryDict = this.get(this.EQUIPMENT_CATEGORIES);
    this.equipmentTypeDict = this.get(this.EQUIPMENT_TYPES);
    this.clientDict = this.get(this.CLIENT_DICT);
    this.workCentreDict = this.get(this.CACHE_KEY_WORK_CENTRE_DICT);
    this.manualInstructionArray = this.get(this.CACHE_KEY_MANUAL_INSTRUCTION);
    this.storageLocationAiList = this.get(this.CACHE_STORAGE_LOCATION_AI_LIST);
    this.costCentreList = this.get(this.CACHE_COST_CENTRE_LIST);
    this.categoryCodeDict =this.get(this.CACHE_KEY_CATEGORY_CODE);
    this.classificationCodeDict =this.get(this.CACHE_KEY_CLASSIFICATION_CODE);
    this.featureList = this.get(this.FEATURE_LIST);

    this.snListGeneralSearchKeyword = this.get(this.SN_LIST_GENERAL_SEARCH_KEYWORD);
    this.snListSettingsColumns = this.get(this.SN_LIST_SETTINGS_COLUMNS);
    this.teamSettingsSelecteTeamId = this.get(this.TEAM_SETTINGS_SELECTED_TEAM_ID);
    this.snCreateSnInputForm = this.get(this.SN_CREATE_SN_INPUT_FORM);
    this.snCreateClientDetail = this.get(this.SN_CREATE_CLIENT_DETAIL);
    this.pmPeriodListSavedSearchCriteria = this.get(this.PM_PERIOD_LIST_SAVED_SEARCH_CRITERIA);
    this.pmPlanListSettingsFilters = this.get(this.PM_PLAN_LIST_SETTINGS_FILTERS);
    this.pmPlanListSettingsColumns = this.get(this.PM_PLAN_LIST_SETTINGS_COLUMNS);
    this.pmJobListSettingsFilters = this.get(this.PM_JOB_LIST_SETTINGS_FILTERS);
    this.pmJobListSettingsColumns = this.get(this.PM_JOB_LIST_SETTINGS_COLUMNS);
    this.tecoJobListSettingsColumns = this.get(this.TECO_JOB_LIST_SETTINGS_COLUMNS);
    this.untecoJobListSettingsColumns = this.get(this.UNTECO_JOB_LIST_SETTINGS_COLUMNS);
    this.approvalCmListSettingsColumns = this.get(this.APPROVAL_CM_LIST_SETTINGS_COLUMNS);
    this.jobListSettingsFilters = this.get(this.JOB_LIST_SETTINGS_FILTERS);

    this.routingRuleId = this.get(this.ROUTING_RULE_ID);

    this.isEnableJMAccountReview = this.get(this.IS_ENABLE_JM_ACCOUNT_REVIEW);
  }

  //--------------------------------------------------------------------
  private set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private get(key: string): any {
    let value = localStorage.getItem(key);

    try { return JSON.parse(value); }
    catch (e) { return value; }
  }

  public clear(): void {
    localStorage.removeItem(this.USER_INFO);
    this.userInfo = null;
    localStorage.removeItem(this.USER_TOKEN);
    this.userToken = null;
    localStorage.removeItem(this.REFRESH_TOKEN);
    this.refreshToken = null;
    localStorage.removeItem(this.AUTH_SOURCE);
    this.authSource = null;

    localStorage.removeItem(this.SELECTED_WORK_CENTRE);
    this.selectedWorkCentre = null;
    localStorage.removeItem(this.DASHBOARD_SELECTED_WORK_CENTRE);
    this.dashboardSelectedworkCentre = null;

    localStorage.removeItem(this.EQUIPMENT_CATEGORIES);
    this.equipmentCategoryDict = null;
    localStorage.removeItem(this.EQUIPMENT_TYPES);
    this.equipmentTypeDict = null;
    localStorage.removeItem(this.CLIENT_DICT);
    this.clientDict = null;
    localStorage.removeItem(this.CACHE_KEY_WORK_CENTRE_DICT);
    this.workCentreDict = null;
    localStorage.removeItem(this.CACHE_KEY_MANUAL_INSTRUCTION);
    this.manualInstructionArray = null;
    localStorage.removeItem(this.CACHE_STORAGE_LOCATION_AI_LIST);
    this.storageLocationAiList = null;
    localStorage.removeItem(this.CACHE_COST_CENTRE_LIST);
    this.costCentreList = null;
    localStorage.removeItem(this.CACHE_KEY_CATEGORY_CODE);
    this.categoryCodeDict = null;
    localStorage.removeItem(this.CACHE_KEY_CLASSIFICATION_CODE);
    this.classificationCodeDict = null;
    localStorage.removeItem(this.FEATURE_LIST);
    this.featureList = null;

    localStorage.removeItem(this.SN_LIST_GENERAL_SEARCH_KEYWORD);
    this.snListGeneralSearchKeyword = null;
    localStorage.removeItem(this.TEAM_SETTINGS_SELECTED_TEAM_ID);
    this.teamSettingsSelecteTeamId = null;
    localStorage.removeItem(this.SN_CREATE_SN_INPUT_FORM);
    this.snCreateSnInputForm = null;
    localStorage.removeItem(this.SN_CREATE_CLIENT_DETAIL);
    this.snCreateClientDetail = null;
    localStorage.removeItem(this.PM_PERIOD_LIST_SAVED_SEARCH_CRITERIA);
    this.pmPeriodListSavedSearchCriteria = null;
    localStorage.removeItem(this.PM_PLAN_LIST_SETTINGS_FILTERS);
    this.pmPlanListSettingsFilters = null;
    localStorage.removeItem(this.PM_PLAN_LIST_SETTINGS_COLUMNS);
    this.pmPlanListSettingsColumns = null;
    localStorage.removeItem(this.PM_JOB_LIST_SETTINGS_FILTERS);
    this.pmJobListSettingsFilters = null;
    localStorage.removeItem(this.PM_JOB_LIST_SETTINGS_COLUMNS);
    this.pmJobListSettingsColumns = null;
    localStorage.removeItem(this.TECO_JOB_LIST_SETTINGS_COLUMNS);
    this.tecoJobListSettingsColumns = null;
    localStorage.removeItem(this.UNTECO_JOB_LIST_SETTINGS_COLUMNS);
    this.untecoJobListSettingsColumns = null;
    localStorage.removeItem(this.APPROVAL_CM_LIST_SETTINGS_COLUMNS);
    this.approvalCmListSettingsColumns = null;
    localStorage.removeItem(this.JOB_LIST_SETTINGS_FILTERS);
    this.jobListSettingsFilters = null;

    localStorage.removeItem(this.ROUTING_RULE_ID);
    this.routingRuleId = null;
    this.isEnableJMAccountReview = false;

    sessionStorage.clear();
  }

  //--------------------------------------------------------------------
  public setRememberMe(value:boolean): void {
    this.set(this.REMEMBER_ME, value);
    this.rememberMe = value;
  }
  public setRememberUsername(value:string): void {
    this.set(this.REMEMBER_USERNAME, value);
    this.rememberUsername = value;
  }
  public setUserInfo(value:JMOBJ.Post): void {
    this.set(this.USER_INFO, value);
    this.userInfo = value;
  }
  public setUserToken(value:string): void {
    this.set(this.USER_TOKEN, value);
    this.userToken = value;
  }
  public setRefreshToken(value:string): void {
    this.set(this.REFRESH_TOKEN, value);
    this.refreshToken = value;
  }
  public setAuthSource(value:string): void {
    this.set(this.AUTH_SOURCE, value);
    this.authSource = value;
  }

  public setPwReminder(value): void {
    this.set(this.PW_REMINDER, value);
    this.pwReminderDisableList = value;
  }

  public setSelectedLanguage(value:JMENUM.Language) {
    this.set(this.SELECTED_LANGUAGE, value);
    this.selectedLanguage = value;
  }
  public setSelectedWorkCentre(value:string) {
    this.set(this.SELECTED_WORK_CENTRE, value);
    this.selectedWorkCentre = value;
  }

  public setSelectedHACluster(value:string) {
    this.set(this.SELECTED_HA_CLUSTER, value);
    this.selectedHACluster = value;
  }

  public setDashboardSelectedWorkCentre(value:string[]) {
    this.set(this.DASHBOARD_SELECTED_WORK_CENTRE, value);
    this.dashboardSelectedworkCentre = value;
  }

  public setEquipmentCategoryDict(value:{}) {
    this.set(this.EQUIPMENT_CATEGORIES, value);
    this.equipmentCategoryDict = value;
  }
  public setEquipmentTypeDict(value:{}) {
    this.set(this.EQUIPMENT_TYPES, value);
    this.equipmentTypeDict = value;
  }
  public setClientDict(value:{}) {
    this.set(this.CLIENT_DICT, value);
    this.clientDict = value;
  }
  public setWorkCentreDict(value:{}) {
    this.set(this.CACHE_KEY_WORK_CENTRE_DICT, value);
    this.workCentreDict = value;
  }
  public setManualInstructionArray(value:JMOBJ.ManualInstruction[]) {
    this.set(this.CACHE_KEY_MANUAL_INSTRUCTION, value);
    this.manualInstructionArray = value;
  }

  public setStorageLocationAiList(value: any[]) {
    this.set(this.CACHE_STORAGE_LOCATION_AI_LIST, value);
    this.storageLocationAiList = value;
  }

  public setCostCentreList(value: string[]) {
    this.set(this.CACHE_COST_CENTRE_LIST, value);
    this.costCentreList = value;
  }
  
  public setCategoryObj(value:{}) {
    this.set(this.CACHE_KEY_CATEGORY_CODE, value);
    this.categoryCodeDict = value;
  }

  public setClassificationObj(value:{}) {
    this.set(this.CACHE_KEY_CLASSIFICATION_CODE, value);
    this.classificationCodeDict = value;
  }

  public setFeatureList(list: {[x:string]:any}[]) {
    const featureList = list.filter(ele => ele.enabled).map(ele => {
      return {
        key: ele.key,
        enabled: ele.enabled,
      }
    });
    this.set(this.FEATURE_LIST, featureList);
    this.featureList = featureList;
  }

  //-------------------
  // page variable
  public setJobListSettingsFilters(value:any) {
    this.set(this.JOB_LIST_SETTINGS_FILTERS, value);
    this.jobListSettingsFilters = value;
  }
  public setSnListGeneralSearchKeyword(value:string) {
    this.set(this.SN_LIST_GENERAL_SEARCH_KEYWORD, value);
    this.snListGeneralSearchKeyword = value;
  }
  public setSnListAdvancedSearchCriteria(value:any) {
    this.set(this.SN_LIST_ADVANCED_SEARCH_CRITERIA, value);
    this.snListAdvancedSearchCriteria = value;
  }
  public setSnListSettingsColumns(value:any) {
    this.set(this.SN_LIST_SETTINGS_COLUMNS, value);
    this.snListSettingsColumns = value;
  }
  public setTeamSettingsSelectedTeamId(value:string) {
    this.set(this.TEAM_SETTINGS_SELECTED_TEAM_ID, value);
    this.teamSettingsSelecteTeamId = value;
  }
  public setSnCreateSnInputForm(value:any) {
    this.set(this.SN_CREATE_SN_INPUT_FORM, value);
    this.snCreateSnInputForm = value;
  }
  public setSnCreateClientDetail(value:any) {
    this.set(this.SN_CREATE_CLIENT_DETAIL, value);
    this.snCreateClientDetail = value;
  }
  public setPmPeriodSavedSearchCriteria(value:any) {
    this.set(this.PM_PERIOD_LIST_SAVED_SEARCH_CRITERIA, value);
    this.pmPeriodListSavedSearchCriteria = value;
  }
  public setPmPlanSettingsFilters(value:any) {
    this.set(this.PM_PLAN_LIST_SETTINGS_FILTERS, value);
    this.pmPlanListSettingsFilters = value;
  }
  public setPmPlanSettingsColumns(value:any) {
    this.set(this.PM_PLAN_LIST_SETTINGS_COLUMNS, value);
    this.pmPlanListSettingsColumns = value;
  }
  public setPmJobListSettingsFilters(value:any) {
    this.set(this.PM_JOB_LIST_SETTINGS_FILTERS, value);
    this.pmJobListSettingsFilters = value;
  }
  public setPmJobListSettingsColumns(value:any) {
    this.set(this.PM_JOB_LIST_SETTINGS_COLUMNS, value);
    this.pmJobListSettingsColumns = value;
  }
  public setTecoJobListSettingsColumns(value:any) {
    this.set(this.TECO_JOB_LIST_SETTINGS_COLUMNS, value);
    this.tecoJobListSettingsColumns = value;
  }
  public setUntecoJobListSettingsColumns(value:any) {
    this.set(this.UNTECO_JOB_LIST_SETTINGS_COLUMNS, value);
    this.untecoJobListSettingsColumns = value;
  }

  // Approval List
  public setApprovalCmListSettingsColumns(value:any) {
    this.set(this.APPROVAL_CM_LIST_SETTINGS_COLUMNS, value);
    this.approvalCmListSettingsColumns = value;
  }

  public setRoutingRuleId(value:string) {
    this.set(this.ROUTING_RULE_ID, value);
    this.routingRuleId = value;
  }

  // JM account review
  public setIsEnableJMAccountReview(value: boolean) {
    this.set(this.IS_ENABLE_JM_ACCOUNT_REVIEW, value);
    this.isEnableJMAccountReview = value;
  }
}


//----------------------------------------------------------------------
let Session = new SessionClass();

export {
  Session
}