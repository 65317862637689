import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts'
import { Session } from '@services/session';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import { ngbDateToString, stringToNgbDate } from 'src/lib/presenter/Formatter';

@Component({
  selector: 'app-reserve-ai-store-panel',
  templateUrl: './reserve-ai-store-panel.component.html',
  styleUrls: ['./reserve-ai-store-panel.component.scss']
})
export class ReserveAiStorePanelComponent implements OnChanges, OnInit {
  @Input() aiStoreObj;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  isLoading: boolean;
  defaultNgbDate: any;

  costCentres: Array<String>
  availableDates: Array<string>;
  posts: Array<JMOBJ.Post>;

  searchPostNameObserver = new Subject<any[]>();
  searchPostName: string = null;
  searchPostPageNumber = 1;
  searchPostPageSize = 100;
  searchPostMaxPageSize;

  selectedCostCentre: string;
  selectedDate: string;
  selectedPost: string;
  contactNumber: string;

  dateAlertMsg: string = undefined;
  inputError = {
    contactNumber: false
  }

  constructor() { }

  async ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges){
    let { aiStoreObj } = changes;
    if (aiStoreObj.currentValue) {
      this.selectedCostCentre = aiStoreObj.currentValue.costCentre;
      this.selectedDate = aiStoreObj.currentValue.estimatedPickupDate;
      this.defaultNgbDate = stringToNgbDate(this.selectedDate);
      this.selectedPost = aiStoreObj.currentValue.consigneePost;
      this.contactNumber = aiStoreObj.currentValue.consigneeContactNumber;
    }
  }

  async init() {
    this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
    this.costCentres = Session.costCentreList;
    this.requestAvailableDates();
    this.searchPostPageNumber = 1;
    this.posts = await this.requestPosts(this.searchPostPageNumber);

    this.searchPostNameObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(async (values) => {
      this.searchPostName = Array.isArray(values) && values.length > 0 ? values[0] : null;
      this.searchPostPageNumber = 1;
      this.posts = await this.requestPosts(this.searchPostPageNumber);
    });
  }
  // async requestCostCenters() {
  //   const request = new JM.JMRequestWorkCentresGetCostCentres();
  //   const response: JM.JMResponseWorkCentresGetCostCentres = await AppDelegate.sendJMRequest(request);
  //   if (!response || !response.code || response.code != 200 || !response.payload) {
  //     return;
  //   }
  //   this.costCentres = response.payload.costCentres;
  // }

  async requestAvailableDates() {
    const request = new JM.JMRequestInventoryGetAvailablePickupDate();
    const response: JM.JMResponseInventoryGetAvailablePickupDate = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }
    this.availableDates = response.payload.availableDates;
    if(this.selectedDate && !this.availableDates.includes(this.selectedDate)) {
      this.defaultNgbDate = null;
      this.selectedDate = null;
    }
  }

  async requestPosts(pageNumber):Promise<any[]> {
    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = JMENUM.SourceSystem.CCEPJM;
    request.active = JMENUM.RequestActive.ACTIVE;
    request.pageSize = this.searchPostPageSize;
    request.pageNumber = pageNumber || 1;
    this.searchPostName && (request.filter = {
      name: this.searchPostName
    });
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }
    this.searchPostMaxPageSize = Math.ceil(response.payload.totalCount / this.searchPostPageSize);
    return response.payload.records;
  }

  async requestPostContact() {
    const request = new JM.JMRequestPostsGetPost();
    request.systemName = JMENUM.SourceSystem.CCEPJM;
    request.postName = this.selectedPost;
    const response: JM.JMResponsePostsGetPost = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }
    this.contactNumber = response.payload.mobile;
  }

  validateForm() {
    let passed = true;
    if (!this.contactNumber) {
      this.inputError.contactNumber = true;
      passed = false;
    } else {
      passed = /^\d{8}$/.test(this.contactNumber);
      if (!passed) {
        AppDelegate.openSnackBar(JMLanguage.translate('component.client-information.error.msg.contact-number'));
      }
    }

    this.inputError.contactNumber = !passed;
    return passed;
  }

  onBlurDateInput(event) {
    this.selectedDate = ngbDateToString(event.data);
  }

  onSearchPostName(event) {
    this.searchPostNameObserver.next([event.term]);
  }

  onClearPostName() {
    this.searchPostNameObserver.next();
  }

  async onPostChange() {
    this.selectedPost && this.requestPostContact();
  }

  async onPostScrollToEnd() {
    if (this.searchPostPageNumber < this.searchPostMaxPageSize) {
      this.searchPostPageNumber++;
      const newList = await this.requestPosts(this.searchPostPageNumber);
      if (Array.isArray(newList)) {
        this.posts = this.posts.concat(newList);
      }
    }
  }

  onClickSubmit() {
    if(this.validateForm()) {
      const aiStorePayload = {
        costCentre: this.selectedCostCentre,
        estimatedPickupDate: this.selectedDate,
        consigneePost: this.selectedPost,
        consigneeContactNumber: this.contactNumber,
      };
      this.onSubmit.emit(aiStorePayload);
    }
  }

  get submitEnable(): boolean {
    return !!(this.selectedCostCentre && this.selectedDate && this.selectedPost && this.contactNumber);
  }
}
