<nav id="sidebar">
  <div class="sidebar-header">
    <div class="system-name">{{ systemName }} {{ systemVersion }}</div>
  </div>

  <ul class="list-unstyled components">
    <li *ngFor="let item of items;">
      <ng-container *ngIf="item.href">
        <a href="{{ item.href }}" target="_blank">{{ item.title  | translate }}</a>
      </ng-container>
      <ng-container *ngIf="!item.href">
      <a *ngIf="!item.path" href="#{{ item.id }}" role="button" data-toggle="collapse" aria-expanded="false"
        [ngClass]="{ 'dropdown-toggle': item.subItems && item.subItems.length > 0 }" >{{ item.title  | translate }}</a>
        <!-- for routable title and disable data-toggle  -->
      <a *ngIf="item.path" routerLink="{{item.path}}" (click)="item.onClick && item.onClick($event)" >{{ item.title  | translate }}</a>
      <ul *ngIf="item.subItems && item.subItems.length > 0" class="collapse list-unstyled sub-item show" id="{{ item.id }}">
        <li id="navigation-menu_{{subItem.id}}" *ngFor="let subItem of item.subItems">
          <ng-container *ngIf="subItem.href">
            <a href="{{subItem.href}}" >{{ subItem.title  | translate }}</a>
          </ng-container>
          <ng-container *ngIf="subItem.path">
            <a routerLink="{{subItem.path}}" (click)="subItem.onClick && subItem.onClick($event)">{{ subItem.title  | translate }}</a>
          </ng-container>
        </li>
      </ul>
    </ng-container>
    </li>
  </ul>

  <nav class="footer">
    <div (click)="onClickedRealseNoteLink()">
      <i class="fas fa-headphones"></i><span>CCeP-JM Support</span>
    </div>
    <div><i class="fas fa-envelope"></i><span>{{ supportContactInformation.ldapId }}</span></div>
    <div><i class="fas fa-envelope"></i><span>{{ supportContactInformation.ldapId2 }}</span></div>
    <div><i class="fas fa-envelope"></i><span>{{ supportContactInformation.email }}</span></div>
    <div><i class="fas fa-phone"></i><span>{{ supportContactInformation.phone }}</span></div>
  </nav>
</nav>