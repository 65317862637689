<div class="wrapper">
  <app-nav [navbarTitle]=""></app-nav>

  <app-sidebar [customItems]='sideBarItem'></app-sidebar>

  <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
    <app-action-sidebar
      [buttonData]="actionButtonData"
      [disabled]="disableActionSideBar"
      (clickEvent)="onActionButtonClicked($event)">
    </app-action-sidebar>
  </ng-container>

  <!-- Page Content -->
  <div id="content" class="approval-content">
    <!-- pending approval -->
    <div class="row justify-content-between row-space ">
      <div class="col-xl-3 col-12 align-self-center mt-1">
        <h2 class="title">{{ "pages.approval.pending-approval-title" | translate }}</h2>
      </div>
      <div class="row col-xl-9 col-12 align-self-center justify-content-md-end justify-content-start mt-1">
        <div class="col-xl-8 col-12 search-box-wrap align-self-center px-2 px-md-3">
          <div class="search-box">
              <i class="fas fa-search" (click)="onGeneralSearchClicked()" ></i>
            <input class="form-control" id="sn_search_inputbox" type="search" (keyup)="onGeneralSearchKeyup($event)" [(ngModel)]="generalSearchWords"
              placeholder="{{generalSerachPlaceholder}}" (ngModelChange)="checkGeneralSearchEmpty()">
          </div>
        </div>
      </div>
    </div>

    <hr class="underline" />

    <!-- tab -->
    <div id="multi-tab-content-container" class="container-fluid">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabOptions" class="nav-item">
          <a class="nav-link" [class.active]="activeTab==tab.value"
            (click)="onClickedActiveTab(tab.value)">{{tab.label}}</a>
        </li>
      </ul>
      <div class="flex-column">
        <app-tablex *ngIf="isPendingTab" id="approval-pending-table" [tablexParam]='tablexParamPending'></app-tablex>
        <app-tablex *ngIf="isHistoryTab" id="approval-history-table" [tablexParam]='tablexParamHistory'></app-tablex>
      </div>
    </div>

    <!-- history -->
    <!-- <div class="row justify-content-start no-gutters">
      <h4 class="title mt-1">
        {{"pages.approval.history-title" | translate}}
      </h4>
    </div>
    <hr class="underline" /> -->
    <!-- <app-tablex [tablexParam]='approvedRequestTablexParam'></app-tablex> -->
  </div>
</div>