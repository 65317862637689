import { Component, Input, Output, OnInit, EventEmitter, Injector } from '@angular/core';
import * as FORAMTTER from 'src/app/presenter/Formatter'
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';

import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/AppDelegate';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

// const BATCH = 100;
// string: JMOBJ.MaintenanceTermContract
class UIContractRow {
  key: string;
  label: string;
  checked: boolean;

  constructor() { 
    this.key = undefined;
    this.label = undefined;
    this.checked = false;
  }
}


@Component({
  selector: 'add-team-member-form',
  templateUrl: './add-team-member-form.html',
  styleUrls: ['./add-team-member-form.scss']
})

export class AddTeamMemberForm implements OnInit {
  @Input() parameters:{
    onSubmitButtonClicked: Function,

  } = {
    onSubmitButtonClicked: undefined,
  };
  
  uiTitle:string = '';
  uiSelectedAll:boolean = false;
  uiEnableSubmitButton: boolean = true;
  uiOfficerArray: UIContractRow[] = []; // array of label and checked

  team: JMOBJ.Team;
  loadedAllOfficer:boolean = false;
  currentPageNumber = 1;

  constructor(){
  }

  ngOnInit() {
  }

  initView(team: JMOBJ.Team,) {
    this.team = team;
    this.uiTitle = JMLanguage.translate('add-team-member-form.title', [this.team.name]);
    this.uiOfficerArray = [];
    this.currentPageNumber = 1;
    this.loadedAllOfficer = false;
    this.enabletSubmitbutton(true);
    this.requestWorkCentreOfficer(this.currentPageNumber);

  }

  enabletSubmitbutton(enable:boolean) {
    this.uiEnableSubmitButton = enable;
  }

  //-----------------------------------------------------------------------
  requestWorkCentreOfficer(pageNumber:number) {
    let request:JM.JMRequestPostsPostSummary = new JM.JMRequestPostsPostSummary();
    request.authorizations = {'workCenters': this.team.workCentre};
    request.active = JMENUM.RequestActive.ACTIVE;
    request.systemName = Constants.SYSTEM_NAME;
    request.includeSummary = true;
    request.pageSize = 100;
    request.pageNumber = pageNumber;
    
    JM.JMConnector.sendPostsPostSummary(request, (error:JM.JMNetworkError, result:JM.JMResponsePostsPostSummary) => {
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }
      if (!result || !result.code || result.code != 200 || !result.payload) {
        AppDelegate.openErrorBar(result);
        return;
      }
      for(let post of result.payload.records) {
        if (this.team.members.includes(post.name) == false) {
          let row:UIContractRow = new UIContractRow();
          row.key = post.name;
          row.label = post.name;
          row.checked = false;
          this.uiOfficerArray.push(row);
        }
      }
      this.loadedAllOfficer = (result.payload.records.length == 0);
    });
  }

  //-----------------------------------------------------------------------
  // button callback function
  onSubmitButtonClicked = (event) => {
    this.uiEnableSubmitButton = false;

    let newMemberArray:string[] = [];
    for (let row of this.uiOfficerArray) {
      if (row.checked) {
        newMemberArray.push(row.key);
      }
    }

    let request:JM.JMRequestTeamsUpdateTeam = new JM.JMRequestTeamsUpdateTeam()
    request._id = this.team._id.toString();
    request.members = this.team.members.concat(newMemberArray);
    
    JM.JMConnector.sendTeamsUpdateTeam(request, (error:JM.JMNetworkError, result:JM.JMResponseTeamsUpdateTeam) => {
      this.uiEnableSubmitButton = true;

      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }
      if (!result || !result.code || result.code != 200 || !result.payload) {
        AppDelegate.openErrorBar(result);
        return;
      }
      AppDelegate.openSnackBar(JMLanguage.translate("global.added"));
      if (this.parameters.onSubmitButtonClicked) {
        this.parameters.onSubmitButtonClicked(); 
      }
    });
  }
  onSelectAllClicked() {
    for (let row of this.uiOfficerArray) {
      row.checked = this.uiSelectedAll;
    }
  }

  onCheckBoxClicked(event, i, rowKey:string) {
    let selectedAll = true;
    for (let row of this.uiOfficerArray) {
      selectedAll = (selectedAll && row.checked);
    }
    this.uiSelectedAll = selectedAll;
  }


  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    let buffer = 100;
    if (event.target.offsetHeight + event.target.scrollTop + buffer >= event.target.scrollHeight) {
      if (this.loadedAllOfficer == false) {
        this.currentPageNumber = this.currentPageNumber + 1;
        this.requestWorkCentreOfficer(this.currentPageNumber);
      }
    }
  } 
}