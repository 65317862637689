<!-- <ng-container *ngIf="currentPageMode==pageMode.VIEW"> -->
<div class="font-size-xl m-0 mb-2 px-2">
    {{ "component.fault-information-detail.title" | translate}}
</div>
<hr class="underline" />

<div class="row align-items-center">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.fault-information-detail.district" | translate}}
    </div>

    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="fault-info-district" *ngIf="!editable.district" [textField]="district"
            [emptyHandleDisplay]="emptyHandle">
        </app-display-text-field>
    </div>
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.fault-information-detail.priority" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="fault-info-priority" *ngIf="!editable.priority" [textField]="priority"
            [emptyHandleDisplay]="emptyHandle">
        </app-display-text-field>
    </div>
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.fault-information-detail.location" | translate}}
    </div>

    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="fault-info-location" *ngIf="!editable.location" [textField]="location"
            [emptyHandleDisplay]="emptyHandle" [attr.data-content]="sn.location">
        </app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.fault-information-detail.location-details" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">

        <app-display-text-field id="fault-info-location-details" *ngIf="!editable.locationDetail"
            [textField]="locationDetail"></app-display-text-field>
        <textarea class="form-control" id="fault-info-location-details-input" *ngIf="editable.locationDetail"
            [(ngModel)]="locationDetail" (change)="assignInputToValue('locationDetail',locationDetail)"
            rows=3></textarea>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">

        {{"component.fault-information-detail.equipment-category" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="fault-info-equipment-category" *ngIf="!editable.equipmentCategory"
            [textField]="equipmentCategory" [emptyHandleDisplay]="emptyHandle" [attr.data-content]="sn.equipmentCategory">
        </app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.fault-information-detail.equipment-type" | translate}}
    </div>

    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="fault-info-equipment-type" *ngIf="!editable.equipmentType"
            [textField]="equipmentType" [emptyHandleDisplay]="emptyHandle" [attr.data-content]="sn.equipmentType">
        </app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1" *ngIf="fehdVenueOptionList && fehdVenueOptionList.length">
        {{"component.fault-information-detail.fehd-venue" | translate}}
    </div>
    <div class="col-10 text-left font-size-s m-0 mb-4 overflow-auto" *ngIf="fehdVenueOptionList && fehdVenueOptionList.length">
        <app-display-text-field id="fault-info-fehd-venue" *ngIf="!editable.fehdVenue" 
            [textField]="fehdVenue"  [emptyHandleDisplay]="emptyHandleUnclassified">
        </app-display-text-field>
        <div class="radio-group" *ngIf="editable.fehdVenue" >
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <button *ngIf="fehdVenueOptionList.includes(JMFehdVenue.TOILET)"
                    id="fault-info-fehd-venue-toilet_button" class="btn btn-priority urgent"
                    (click)="assignInputToValue('fehdVenue', JMFehdVenue.TOILET)"
                    [ngClass]="{'active': sn.fehdVenue == JMFehdVenue.TOILET}">
                    <input type="radio" name="fehd-venue-options" value="JMFehdVenue.TOILET" [(ngModel)]="sn.fehdVenue">
                    {{'fehd-venue.toilet' | translate}}
                </button>
                <button *ngIf="fehdVenueOptionList.includes(JMFehdVenue.MARKET)"
                    id="fault-info-fehd-venue-toilet_button" class="btn btn-priority non-urgent"
                    (click)="assignInputToValue('fehdVenue', JMFehdVenue.MARKET)"
                    [ngClass]="{'active': sn.fehdVenue == JMFehdVenue.MARKET}">
                    <input type="radio" name="fehd-venue-options" value="JMFehdVenue.MARKET" [(ngModel)]="sn.fehdVenue">
                    {{'fehd-venue.market' | translate}}
                </button>
                <button id="fault-info-fehd-venue-unclassified_button" class="btn btn-priority emergency"
                    (click)="assignInputToValue('fehdVenue', null)"
                    [ngClass]="{'active': !sn.fehdVenue}">
                    <input type="radio" name="fehd-venue-options" value="null" [(ngModel)]="sn.fehdVenue">
                    {{'pages.sn.unclassified' | translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.fault-information-detail.fault-details-by-client" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="fault-info-by-client" *ngIf="!editable.faultDetail"
            [textField]="faultDetail"></app-display-text-field>
        <textarea class="form-control" id="fault-info-by-client" *ngIf="editable.faultDetail"
            [(ngModel)]="faultDetail" (change)="assignInputToValue('faultDetail',faultDetail)" rows=3
            [ngClass]="{'input-error':errorFields.faultDetail}"></textarea>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.fault-information-detail.internal-remarks" | translate}}
    </div>

    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="internal-remarks" *ngIf="!editable.internalRemarks"
            [textField]="internalRemarks"></app-display-text-field>
        <textarea class="form-control" id="internal-remarks" *ngIf="editable.internalRemarks"
            [(ngModel)]="internalRemarks" (change)="assignInputToValue('internalRemarks',internalRemarks)" rows=3></textarea>
    </div>
</div>
<!-- </ng-container> -->