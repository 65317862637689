import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JmUserSettings } from '@enum/jm-user-settings.enum';
import { EquipmentTagService } from '@services/equipment-tag.service';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/AppDelegate';
import { TablexColumnFilterOption, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/entity/enum/tablexColumnType';
import { Session } from 'src/app/services/session';
import { base64ToBlob } from 'src/app/services/utility';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { EditEquipmentFormComponent } from 'src/app/ui/components/edit-equipment-form/edit-equipment-form.component';
import { SidebarItemInterface } from 'src/app/ui/components/sidebar/sidebar-items';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { BatchEditEquipmentFormComponentModes } from '../../pages/batch-edit-equipment-form/batch-edit-equipment-form';

@Component({
  selector: 'equipment-list',
  templateUrl: './equipment-list.html',
  styleUrls: ['./equipment-list.scss']
})
export class EquipmentListComponent extends BasePage implements OnInit {
  @ViewChild('edit_equipment_panel', { static: true }) editEquipmentPanel: CustomSliderPanelComponent;
  @ViewChild('batch_edit_tags_panel', { static: true }) batchEditTagsPanel: CustomSliderPanelComponent;
  @ViewChild('batch_edit_tags_form', { static: true }) batchEditTagsForm;
  @ViewChild('edit_equipment_form', {static: true}) editEquipmentForm: EditEquipmentFormComponent;

  pageTitle: string;
  selectedLanguage: string;
  searchInputText: string;

  // Permission
  hasEditPermission: boolean;
  hasEditHSDPermission: boolean;

  // Button / Loading Status
  isExportExcelBtnLoading: boolean = false;
  isDisabledExportExcelBtn: boolean = false;
  isDisabledBatchEditBtn: boolean = false;
  isShowMsgNoEquipment: boolean = false;
  
  // Table
  tablexFilter: any = {};
  tablexParam: {} = {};
  pageSizeOptions: any = [100, 200, 500];

  // Table filter dropdown options
  ztFilterOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  statusFilterOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  // Table column filter
  selectedRows = [];
  uiSelectedCount: number = 0;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];

  //Work Centre
  selectedWorkCentre: string;
  isHSDWorkCentre: boolean;

  // CCS Equipment
  selectedEquipment: any = {};
  ccsEquipmentRecords: any = [];
  equipmentPageNumber: number = 1;
  equipmentPageSize: number = 100;
  equipmentPageCount = 1;
  
  
  private searchTerms     = new Subject<any[]>();

  sideBarItem: SidebarItemInterface[] = [];

  equipmentList:JMOBJ.Equipment[] = [];

  constructor(
    injector: Injector,
    private equipmentTagService: EquipmentTagService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.selectedLanguage = Session.selectedLanguage;
    this.selectedWorkCentre = Session.selectedWorkCentre;

    let wc = Session.workCentreDict[this.selectedWorkCentre];
    this.isHSDWorkCentre = Session.workCentreDict[Session.selectedWorkCentre].enableHAEquipment;
    this.pageTitle = JMLanguage.translate("pages.equipment-list.page-title", [this.isHSDWorkCentre?wc.HACluster:this.selectedWorkCentre]);

    this.initPermission();
    this.initSelectedCol();
    this.onColFiltered(this.selectedColId);
    this.initObserverPipe();
    this.initSideBar();
    this.initComponent();

    this.requestEquipmentSummary();
  }

  ngOnDestroy() { 
  }

  ngAfterViewInit() {
    this.editEquipmentForm.onSave = this.onSaveEquipment;
  }

  onLanguageChanged() {
  }

  initComponent(){
    if(this.isHSDWorkCentre){
      this.initHSDTableHeader();
      this.initHSDTablexParam();
    } else{
      // Non HSD-WorkCentre
      this.initNonHSDTableHeader();
      this.initNonHSDTablexParam();
    }
  }

  private initSideBar() {
    this.sideBarItem = [
      {
        title: "side-bar.equipment",
        subItems:[
          {
            title: "side-bar.equipment-tag",
            path: '/setting/ccs-equipment-tag-list',
          }, {
            title: "side-bar.equipment-list",
            path: '/setting/equipment-list',
          }
        ]
      }
    ];
    let enableHAEquip = Session.workCentreDict[Session.selectedWorkCentre].enableHAEquipment;
    if (enableHAEquip && JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.HSD_EQUIPMENT_VIEW)) {
      this.sideBarItem[0].subItems = this.sideBarItem[0].subItems.concat([
        {
          id: 'equipment-list',
          title: "side-bar.ha-equipment-tag-list",
          path: '/setting/ha-equipment-tag-list'
        }, {
          title: "side-bar.ha-equipment-list",
          path: '/setting/ha-equipment-list',
        }, 
      ]);
    }

  } 

  // ----------- API ----------- //
  async requestEquipmentSummary(){
    const request = new JM.JMRequestEquipmentsEquipmentSummary();
    this.prepareRequestFilter(request);
    request.sortBy = "equipmentNumber";
    request.sortOrder = JMENUM.SortOrder.ASC;
    request.pageNumber  = this.equipmentPageNumber;
    request.pageSize = this.equipmentPageSize;

    if(this.isHSDWorkCentre) {
      let wc = Session.workCentreDict[this.selectedWorkCentre];
      let wcArray = AppDelegate.getWorkCentreUnderCluster(wc.HACluster);
      request.workCentre = wcArray;
      request.includeHSDEquipment = true;
      request.parameters = ["_id", "workCentre", "equipmentNumber", "equipmentType", "description", "clientShortName", "location", "locationDescription", 
        "objectType", "technicalId", "verndorWarrantyStart","plannerGroup", "createdAt", "updatedAt", "hashtags", "HSDEquipment"
      ];
    }else{
      request.workCentre = [this.selectedWorkCentre];
      request.parameters = ["_id", "equipmentNumber", "equipmentType", "description", "clientShortName", "location", "locationDescription", "hashtags"];
    }
    if (this.searchInputText && this.searchInputText != ''){
      request.equipmentNumber = this.splitSearchInput();
    }
    this.equipmentList = [];

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
    }

    this.equipmentList = response.payload.records;
    this.equipmentPageCount = Math.ceil(response.payload.totalCount / this.equipmentPageSize);
    this.tablexParam['pageCount'] = this.equipmentPageCount;

    this.checkEnablePageButton(response.payload.totalCount);
    this.renderTable();
  }

  async requestExportHSDEquipmentSummary() {
    const request: JM.JMRequestEquipmentsExportHSDEquipmentSummary = new JM.JMRequestEquipmentsExportHSDEquipmentSummary();
    this.prepareRequestFilter(request);

    if (this.isHSDWorkCentre) {
      let wc = Session.workCentreDict[this.selectedWorkCentre];
      let wcArray = AppDelegate.getWorkCentreUnderCluster(wc.HACluster);
      request.workCentre = wcArray;
    } else{
      request.workCentre = [this.selectedWorkCentre];
    }

    if (this.searchInputText && this.searchInputText != ''){
      request.equipmentNumber = this.splitSearchInput();
    }

    this.isExportExcelBtnLoading = true;
    const response: JM.JMResponseEquipmentsExportHSDEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.isExportExcelBtnLoading = false;
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let blob = base64ToBlob(response.payload.base64, response.payload.type);
    saveAs(blob, "CCS_Equipment_" + moment().format("YYYY-MM-DD") + ".xlsx");
  }

  async requestNonHSDEquipmentSummary() {
    const request = new JM.JMRequestEquipmentsExportEquipmentSummary();
    this.prepareRequestFilter(request);
    request.workCentre = [this.selectedWorkCentre];
    if (this.searchInputText && this.searchInputText != '') {
      request.equipmentNumber = this.splitSearchInput();
    }

    this.isExportExcelBtnLoading = true;
    const response: JM.JMResponseEquipmentsExportEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.isExportExcelBtnLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let blob = base64ToBlob(response.payload.base64, response.payload.type);
    saveAs(blob, "CCS_Equipment_" + moment().format("YYYY-MM-DD") + ".xlsx");
  }

  async requestUpdateUserSettings() {
    let userSettings = {};
    const request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
  }

  // ----------- Render Record ----------- //
  
  // ----------- Button function ----------- //
  onClickExportExcel(){
    if(this.isHSDWorkCentre){
      this.requestExportHSDEquipmentSummary();
    }else{
      this.requestNonHSDEquipmentSummary();
    }
  }

  onClickBatchAdd(){
    this.batchEditTagsForm.mode = BatchEditEquipmentFormComponentModes.ADD;
    this.batchAddRemove();
  }

  onClickBatchRemove(){
    this.batchEditTagsForm.mode = BatchEditEquipmentFormComponentModes.REMOVE;
    this.batchAddRemove();
  }

  batchAddRemove() {
    // this.checkEnableBatchEditBtn();
    this.batchEditTagsForm.equipmentList = this.equipmentList;
    this.batchEditTagsForm.equipmentFilter = this.tablexFilter;
    this.batchEditTagsForm.equipmentNumberFilter = this.splitSearchInput();
    this.batchEditTagsForm.onSave = () => {
      this.requestEquipmentSummary();
      this.batchEditTagsPanel.toggle();
    }
    this.batchEditTagsForm.reset();
    this.batchEditTagsForm.initBatchEditTagsFormElem();
    this.batchEditTagsPanel.toggle();
  }


  checkEnablePageButton(recordCount?) {
    if(recordCount <= 0){
      this.equipmentList = [];
      this.isDisabledExportExcelBtn = true;
      this.isDisabledBatchEditBtn = true;  
    }else{
      this.isDisabledExportExcelBtn = false;
      this.isDisabledBatchEditBtn = false;
    }
  }

  // ----------- UI function ----------- //
  onSaveEquipment = (equipment:JMOBJ.Equipment) => {
    this.editEquipmentPanel.toggle();
    this.requestEquipmentSummary();
  }

  sortEquipmentTag(equipmentTag){
    let sortedTag = [];
    sortedTag = equipmentTag.sort((a, b) => {
      if(a.equipmentType && b.equipmentType){
        return a.equipmentType.localeCompare(b.equipmentType) || a.equipmentName.localeCompare(b.equipmentName);
      }else{
        return a.equipmentName.localeCompare(b.equipmentName);
      }
    });
    return sortedTag;
  }

  splitSearchInput() {
    if (this.searchInputText) {
      return this.searchInputText.split(" ").filter(ele => {return ele.length > 0;});
    } else {
      return [];
    }
  }

  onClearSearchClicked = () => {
    this.searchInputText    = '';
    this.requestEquipmentSummary();
  }

  onSearchClicked() {
    this.requestEquipmentSummary();
  }

  onSearchKeyup() {
    this.requestEquipmentSummary();
  }

  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }
    this.equipmentPageNumber = 1;
    this.requestEquipmentSummary();
  }

  onCloseEditEquipment(){
    this.resetSelectRow();
  }


  prepareRequestFilter(request) {
    request.filter = {};
    for (let key in this.tablexFilter) {
      if(this.tablexFilter[key]){
        switch (key){
          case "functionalLocation": 
            request.filter['locationCode'] = this.tablexFilter[key];
            break;
          case "plannerGroup": 
            request.filter['plannerGroup'] = this.tablexFilter[key];
            break;
          case "technicalId": 
            request.filter['technicalId'] = this.tablexFilter[key];
            break;  
          case "objectType": 
            request.filter['objectType'] = this.tablexFilter[key];
            break;
          case "vendorWarrantyStart": 
            request.filter['vendorWarrantyStart'] = this.tablexFilter[key];
            break;
          case "createdAt": 
            request.filter['createdAt'] = moment(this.tablexFilter[key]).format('YYYYMMDD');
             break;
          case "updatedAt": 
             request.filter['updatedAt'] = moment(this.tablexFilter[key]).format('YYYYMMDD');
             break;
          case "equipmentTag": 
            request.filter['tagDescription'] = this.tablexFilter[key]
            break;
          case 'locationDescription':
            request.locationDescription = this.tablexFilter[key]
            break;
          default : 
            request.filter[key] = this.tablexFilter[key];
            break;
        }
      }
    }
    return request;
  }

  // ----------- Tablex UI function ----------- //
  onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();
    if (!Session.userInfo['userSettings'] || (Session.userInfo['userSettings'] && Session.userInfo.userSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS] != this.selectedColId)) {
      this.requestUpdateUserSettings();
      let userSettings = {};
      userSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  }
  
  onFilterChanged = (event, index, header, filter) => {
    this.searchTerms.next(filter);
  }
  onFilterChangedDebounce = (filter) => {
    this.resetSelectRow();
    this.tablexFilter = filter;
    for (let key in this.tablexFilter) {
      if(!this.tablexFilter[key]){
        delete this.tablexFilter[key];
      }
    }
    
    this.equipmentPageNumber = 1;
    this.requestEquipmentSummary();
    // this.requestEquipmentSummaryForOptions();
  }

  onRowClicked = (index, row) => {
    if(this.tablexParam['highlightedRows'][index]){
      this.resetSelectRow();
    }else{
      this.tablexParam['highlightedRows'] = []; //only selected row is highlighted
      this.tablexParam['highlightedRows'][index] = true;
      this.selectedRows = row;
      
      let equipment = this.equipmentList[index];
      this.editEquipmentForm.setEquipment(equipment);
      this.editEquipmentPanel.toggle();
    }
  }

  resetSelectRow() {
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.selectedRows = [];
  }
  
  onPageSizeClicked = (pageSize: number) => {
    this.equipmentPageNumber  = 1;
    this.equipmentPageSize = pageSize;
    this.requestEquipmentSummary();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.equipmentPageNumber  = pageIndex;
    this.requestEquipmentSummary();
  }

  // ----------- Init Component ----------- //
  initPermission(){
    this.checkViewPermission(JMENUM.Permission.WCM_VIEW);
    this.hasEditPermission = this.authorizationService.hasPermission(JMENUM.Permission.WCM_UPDATE);
    this.hasEditHSDPermission = this.authorizationService.hasPermission(JMENUM.Permission.HSD_EQUIPMENT_UPDATE);
  }

  initObserverPipe() {
    this.searchTerms.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe((data) => {
      this.onFilterChangedDebounce(data);
    });
  }

  initSelectedCol(){
    this.selectedColId = [
      "equipmentNumber",
      "equipmentType",
      "description",
      "clientShortName",
      "location",
      "locationDescription",
      "equipmentTag"
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS]) {
      Object.assign(this.selectedColId, cachedUserSettings[JmUserSettings.CCS_EQUIPMENT_LIST_COLUMNS]);
    }
  }


  // ------ Tablex ------ //
  renderTable(){
    this.tablexParam['headers'] = this.allColHeaders.filter(col => {
      return this.selectedColId.includes(col.id);
    });

    // for usable tag checking
    let usableTagGroupMap = new Map<string, boolean>(); // key: tag group id, value: identify usable tag
    for (const equipment of this.equipmentList) {
      if (!equipment.hashtags) {
        continue;
      }
      for (const tag of equipment.hashtags) {
        if (tag.tagGroup && !usableTagGroupMap.has(tag.tagGroup._id)) {
          const isUsableTag = this.equipmentTagService.isUsableTag(tag, this.selectedWorkCentre);
          usableTagGroupMap.set(tag.tagGroup._id, isUsableTag);
        }
      }
    }
    
    this.tablexParam['content'] = this.equipmentList.map((data, index) => {
      // prepare tag
      let tagLabelList = [];
      if (data.hashtags) {
        for (const tag of data.hashtags) {
          const isUsableTag = tag.tagGroup ? usableTagGroupMap.get(tag.tagGroup._id) : false;
          const tagDescription = this.equipmentTagService.getTagDescription(tag, true);
          const tagLabel = {
            id: tag._id,
            workCentre: this.selectedWorkCentre,
            name: tagDescription,
            class: isUsableTag ? 'tag-blue' : 'tag-red'
          };
          tagLabelList.push(tagLabel);
        }
      }

      let originRow = {
        workCentre           : data.workCentre,
        equipmentNumber      : data.equipmentNumber,
        equipmentType        : data.equipmentType,
        description          : data.description,
        clientShortName      : data.clientShortName,
        location             : data.location,
        locationDescription  : data.locationDescription ? data.locationDescription[JMLanguage.getCurrentLanguage()] : '',
        objectType           : data.objectType,
        technicalId          : data.technicalId,
        vendorWarrantyStart  : data.vendorWarrantyStart,
        plannerGroup         : data.plannerGroup,
        createdDate          : data.createdAt ? moment(data.createdAt).format(Constants.DATE_FORMAT) : null,
        lastUpdatedDate      : data.updatedAt ? moment(data.updatedAt).format(Constants.DATE_FORMAT) : null,
        equipmentTag         : {
          label: tagLabelList,
          btnClass: 'd-none'
        },
      };
      
      let row = [];
      for (let header of this.allColHeaders) {
        if (this.selectedColId.includes(header.id)) {
          row.push(originRow[header.id]);
        }
      }

      return row;
    });
    
    this.tablexParam['isLoadingTable'] = false;
  }
  
  initHSDTablexParam() {
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      enableColFilter       : true,
      filter                : {},
      tableRow              : "row",
      tableClass            : "ccs-equipment-list-table",
      tableWrapperClass     : "",
      pageSizeOptions       : this.pageSizeOptions,
      currentPageSize       : this.equipmentPageSize,
      currentPage           : this.equipmentPageNumber,
      pageCount             : this.equipmentPageCount,
      selectedRowCount      : 0,
      totalRowCount         : 0,
      selectedColId         : this.selectedColId,
      fullColNameList       : this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      onRowClicked          : this.onRowClicked,
      onFilterChanged       : this.onFilterChanged,
      onColFiltered         : this.onColFiltered,
      filterDebounceTime    : Constants.DEBOUNCE_TIME,
      highlightedRows       : [],
      headers               : this.allColHeaders.filter(col => {
                              return this.selectedColId.includes(col.id);
                            }),
    }
  }

  initNonHSDTablexParam(){
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      enableColFilter       : true,
      filter                : {},
      tableRow              : "row",
      tableClass            : "ccs-equipment-list-table",
      tableWrapperClass     : "",
      pageSizeOptions       : this.pageSizeOptions,
      currentPageSize       : this.equipmentPageSize,
      currentPage           : this.equipmentPageNumber,
      pageCount             : this.equipmentPageCount,
      selectedRowCount      : 0,
      totalRowCount         : 0,
      selectedColId         : this.selectedColId,
      fullColNameList       : this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      onFilterChanged       : this.onFilterChanged,
      onColFiltered         : this.onColFiltered,
      onRowClicked          : this.onRowClicked,
      filterDebounceTime    : Constants.DEBOUNCE_TIME,
      highlightedRows       : [],
      headers               : this.allColHeaders.filter(col => {
                              return this.selectedColId.includes(col.id);
                            }),
    }
  }

  initHSDTableHeader() {
    this.ztFilterOptions.items = [ 
        { id: 'zt-yes', key: 'zt-yes', value: 'Y', label: JMLanguage.translate('global.yes') },
        { id: 'zt-no', key: 'zt-no', value: 'N', label: JMLanguage.translate('global.no') },
      ];
    this.ztFilterOptions.bindLabel = "label";
    this.ztFilterOptions.bindValue = "value";
    this.ztFilterOptions.change = this.onOptionFilterChanged;

    this.statusFilterOptions.items = [
        { id: JMENUM.HaEquipmentStatus.PENDING, key: JMENUM.HaEquipmentStatus.PENDING, value: JMENUM.HaEquipmentStatus.PENDING, label: JMLanguage.translate('pages.ha-equipment-list.pending') },
        { id: JMENUM.HaEquipmentStatus.IN_CONTRACT, key: JMENUM.HaEquipmentStatus.IN_CONTRACT, value: JMENUM.HaEquipmentStatus.IN_CONTRACT, label: JMLanguage.translate('pages.ha-equipment-list.in-contract') },
        { id: JMENUM.HaEquipmentStatus.ARCHIVED, key: JMENUM.HaEquipmentStatus.ARCHIVED, value: JMENUM.HaEquipmentStatus.ARCHIVED, label: JMLanguage.translate('pages.ha-equipment-list.archived') },
        { id: JMENUM.HaEquipmentStatus.DELETE, key: JMENUM.HaEquipmentStatus.DELETE, value: JMENUM.HaEquipmentStatus.DELETE, label: JMLanguage.translate('pages.ha-equipment-list.delete') },
        { id: JMENUM.HaEquipmentStatus.CONDEMN, key: JMENUM.HaEquipmentStatus.CONDEMN, value: JMENUM.HaEquipmentStatus.CONDEMN, label: JMLanguage.translate('pages.ha-equipment-list.condemn') },
        { id: JMENUM.HaEquipmentStatus.IN_WARRANTY, key: JMENUM.HaEquipmentStatus.IN_WARRANTY, value: JMENUM.HaEquipmentStatus.IN_WARRANTY, label: JMLanguage.translate('pages.ha-equipment-list.in-warranty') },
        { id: JMENUM.HaEquipmentStatus.IN_HOUSE, key: JMENUM.HaEquipmentStatus.IN_HOUSE, value: JMENUM.HaEquipmentStatus.IN_HOUSE, label: JMLanguage.translate('pages.ha-equipment-list.in-house') }
      ];
    this.statusFilterOptions.bindLabel = "label";
    this.statusFilterOptions.bindValue = "value";
    this.statusFilterOptions.change = this.onOptionFilterChanged;
    this.allColHeaders = [
      {
        id: 'workCentre',
        name: "pages.equipment-list.workCentre",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'equipmentNumber',
        name: "pages.equipment-list.number",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'equipmentType',
        name: "pages.equipment-list.type",    
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'description',
        name: "pages.equipment-list.description",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'clientShortName',
        name: "pages.equipment-list.customer", 
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col"
      },
      {
        id: 'location',
        name: "pages.equipment-list.location-code", 
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'locationDescription',
        name: "pages.equipment-list.location-description",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'objectType',
        name: "pages.equipment-list.object-type",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col th-align-self-center",
      },
      {
        id: 'technicalId',
        name: "pages.equipment-list.technicalId",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col th-align-self-center",
      },
      {
        id: 'vendorWarrantyStart',
        name: "pages.equipment-list.vendorWarrantyStart",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col th-align-self-center",
      },
      {
        id: 'plannerGroup',
        name: "pages.equipment-list.plannerGroup",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col th-align-self-center",
      },
      {
        id: 'createdDate',
        name: "pages.equipment-list.created-date",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'lastUpdatedDate',
        name: "pages.equipment-list.last-updated-date",
        enableFilter: false,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'equipmentTag',
        name: "pages.equipment-list.equipment-tag",
        enableFilter: true,
        type: TablexColumnType.BorderLabelAddButton, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
        disableButton: true,
      }
    ];
  }

  initNonHSDTableHeader() {
    this.allColHeaders = [
      {
        id: 'equipmentNumber',
        name: "pages.equipment-list.number",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'equipmentType',
        name: "pages.equipment-list.type",    
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'description',
        name: "pages.equipment-list.description",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'clientShortName',
        name: "pages.equipment-list.customer", 
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col"
      },
      {
        id: 'location',
        name: "pages.equipment-list.location-code", 
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'locationDescription',
        name: "pages.equipment-list.location-description",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'equipmentTag',
        name: "pages.ha-equipment-list.table-column.equipment-tag",
        enableFilter: true,
        type: TablexColumnType.BorderLabelAddButton, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
        disableButton: true,
      },
    ]
  }
  // ------ Tablex End ------ //
}