import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '@services/authorization.service';
import { JM, JMENUM, JMOBJ, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import * as utility from 'src/app/services/utility';
import { Session } from '@services/session';
import { AttachmentMimeType } from '@enum/attachment-file-type';
import { Constants } from 'src/constants';
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { FileSelectorComponent } from 'src/app/shared/file-selector/file-selector.component';
import { JMRequestContractsUploadInspectionReport } from '@ccep/CCEPConnector-ts/lib/JMConnector/JMConnector';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { InspectionFormComponent } from 'src/app/ui/components/inspection-form/inspection-form/inspection-form.component'
import { InspectionMode } from '@ccep/CCEPConnector-ts/lib/JMConnector/JMEnum';
@Component({
  selector: 'app-contract-inspection-report-submission',
  templateUrl: './contract-inspection-report-submission.component.html',
  styleUrls: ['./contract-inspection-report-submission.component.scss']
})
export class ContractInspectionReportSubmissionComponent implements OnInit {
  @Input() uploadIsLoading: boolean;
  @ViewChild('fileSelector', { static: false }) fileSelector: FileSelectorComponent;
  pageTitle = JMLanguage.translate('pages.contract.inspection.report.submission');
  isDateTimeValid: boolean = false;

  uploadHistoryRouting: string = '/inspection-report/upload-history'
  uploadFile?: File;
  isDisableUploadBtn: boolean;

  isLoadingContractReminder: boolean = false;
  isDisabled: boolean = true;
  contractInspectionForm: any = {
    contractNumber: undefined,
    maintenanceType: undefined,
    inspectionResult: undefined,
    inspectorPost: (Session && Session.userInfo.name) || undefined,
    inspectorName: (Session && Session.userInfo.employeeName) || undefined,
    modeOfCheck: undefined,
    formattedInspectionDateTime: undefined
  };
  dateInputSet: any = {
    inspectionDate: undefined,
  };
  timeInputSet: any = {
    inspectionTime: undefined,
  };
  errorFields: any = {};

  editable = {
    inspectionDate: false,
    inspectionTime: false,
  }

  // contract number Dropdown
  private searchContractNumberObserver = new Subject<any[]>();
  contractNumberOptions: any = [];
  searchContractNumberKeyword: string = null;
  contractNumberPageSize: number = 100;
  contractNumberPageNumber: number = 1;
  contractNumberTotalPage: number;
  isLoadingContractNumber: boolean = false;

  // maintenanceType Options
  maintenanceTypeOptions: any[] = [
    {
      value: JMENUM.MaintenanceType.CM,
      description: JMLanguage.translate('component.contract-inspection.maintenanceType.options.cm')
    },
    {
      value: JMENUM.MaintenanceType.PM,
      description: JMLanguage.translate('component.contract-inspection.maintenanceType.options.pm')
    },
  ];

  // modeOfCheck Options
  modeOfCheckOptions: any[] = this.inspectionFormComponent.modeOfCheckOptions;

  // inspection Result Options
  inspectionResultOptions: any[] = this.inspectionFormComponent.inspectionResultOptions;

  timeAlertMsg = JMLanguage.translate("global.invalid-time");
  dateAlertMsg = JMLanguage.translate("global.invalid-date");

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private inspectionFormComponent: InspectionFormComponent) {

  }

  ngOnInit() {
    this.checkPermission();
    this.checkfeatureEnabled();
    this.initPipe();
  }

  initPipe() {
    this.searchContractNumberObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.searchContractNumbers()
    });
  }

  checkPermission() {
    let hasPermission: boolean = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_INSPECTION);
    if (!hasPermission) {
      this.router.navigate(['/dashboard']);
      AppDelegate.openSnackBar(JMLanguage.translate('pages.job-card.create.toast-msg.permission-denied'));
    }
  }

  checkfeatureEnabled() {
    if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JOB_CARD_INSPECTION)) {
      this.router.navigate(['']);
      return;
    }
  }

  isFormReady() {
    for (const field of Object.keys(this.contractInspectionForm)) {
      if (!this.contractInspectionForm[field]) {
        return false
      }
    }
    return true
  }

  checkIsDisabled() {
    if (this.isFormReady() && this.isDateTimeValid && this.uploadFile != undefined) {
      this.isDisabled = false
    } else {
      this.isDisabled = true
    }
  }

  onUpdatedFile(file?: File) {
    this.uploadFile = file;
  }

  onClickedUploadButton() {
    if (this.validateFile()) {
      this.uploadIsLoading = true
      this.requestUploadFile()
      //disable cancel button next to file name
      this.isDisableUploadBtn = true
    }
  }

  public onBlurDateInput(event) {
    this.dateInputSet[event.field] = event.data;
    this.setDateTime(event.field);
  }

  public onBlurTimeInput(event) {
    this.timeInputSet[event.field] = event.data;
    this.setDateTime(event.field);
  }

  public resetErrorField(key) {
    this.errorFields[key] = false;
  }

  public setDateTime(field) {
    this.resetErrorField(field);
    if (this.dateInputSet['inspectionDate'] && this.timeInputSet['inspectionTime']) {
      let date = this.dateInputSet['inspectionDate'];
      let timeArr = this.timeInputSet['inspectionTime'].split(':');
      let formattedDate = new Date(date.year, date.month - 1, date.day);
      if (timeArr && timeArr.length > 0) {
        formattedDate.setHours(timeArr[0]);
        formattedDate.setMinutes(timeArr[1]);
        this.contractInspectionForm.formattedInspectionDateTime = new Date(formattedDate);
      }
    }

    if (!this.timeInputSet['inspectionTime'] || !this.dateInputSet['inspectionDate']) {
      this.contractInspectionForm.formattedInspectionDateTime = undefined;
    }

    this.validation();
  }

  isDateValid = (dateString: string): boolean => {
    let charRegex = /[a-zA-Z]+/
    if (dateString == undefined) {
      return false;
    }

    if (dateString.length > 0 && dateString.match(charRegex)) {
      return false;
    }
    return true;
  }

  public dateTimeValidation(field) {
    this.errorFields['inspectionDate'] = false;
    this.errorFields['inspectionTime'] = false;

    //Check if have date & time
    if (!this.isDateValid(this.dateInputSet.inspectionDate)) {
      this.errorFields['inspectionDate'] = true;
      return false;
    }

    if (!this.isDateValid(this.timeInputSet.inspectionTime)) {
      this.errorFields['inspectionTime'] = true;
      return false;
    }

    return true;
  }

  public validation() {
    let hasErrorField = false;
    let fields = { ... this.editable };

    for (let field in fields) {
      let dateFormatErr = this.dateTimeValidation(field);
      if ((fields[field] && this.errorFields[field]) || !dateFormatErr) {
        hasErrorField = true;
      }
    }

    for (let i in this.errorFields) {
      if (this.errorFields[i] === true) {
        hasErrorField = true;
        break;
      }
    }

    this.isDateTimeValid = !hasErrorField && this.contractInspectionForm.formattedInspectionDateTime;
  }


  isFileValid = (file: any): boolean => {
    if (!file) return true;
    if (file.size > Constants.ATTACHMENT_SIZE) {
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-report-submission.message.file-size-too-large'));
      return false;
    }
    return true;
  }

  private validateFile() {
    if (!this.uploadFile) {
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-complete.message.file-not-found'));
      return false;
    }

    // check file TablexColumnType.Texte
    if (this.uploadFile.type !== AttachmentMimeType.Pdf) {
      AppDelegate.openSnackBar(JMLanguage.translate('component.contract.inspection.pdf.type.error'));
      return false;
    }

    // check file size
    if (this.uploadFile.size > Constants.ATTACHMENT_SIZE) {
      AppDelegate.openSnackBar(JMLanguage.translate('component.sn-batch-report-submission.message.file-size-too-large'));
      return false;
    }

    return true;
  }

  private async requestUploadFile() {
    if (!this.uploadFile) return;
    try {
      const { contractNumber, maintenanceType, inspectionResult, inspectorPost, inspectorName, modeOfCheck, formattedInspectionDateTime, } = this.contractInspectionForm
      let fileBase64 = await utility.toBase64(this.uploadFile) as string
      let request: JM.JMRequestContractsUploadInspectionReport = new JM.JMRequestContractsUploadInspectionReport();
      request.file = utility.removeBase64Header(fileBase64);
      request.contractNumber = contractNumber;
      request.inspectionResult = inspectionResult;
      request.inspectionMode = modeOfCheck;
      request.maintenanceType = maintenanceType;
      request.inspectedAt = formattedInspectionDateTime;
      request.inspectorPost = inspectorPost;
      request.inspectorName = inspectorName;
      request.filename = this.uploadFile.name ? this.uploadFile.name : '';
      const response: JM.JMResponse = await AppDelegate.sendJMRequest(request);

      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        this.uploadIsLoading = false;
        return;
      }

      this.uploadIsLoading = false
      AppDelegate.openSnackBar(JMLanguage.translate('global.submitted'));
      this.resetForm();
      this.toInspectionReportUploadHistory();
    } catch (err) { console.error(err); }

  }

  private toInspectionReportUploadHistory() {
    this.router.navigate(['/inspection-report/upload-history']);
  }

  private resetForm() {
    for (let field of Object.keys(this.contractInspectionForm)) {
      this.contractInspectionForm[field] = undefined
    }
    this.fileSelector.onFileDelete();
  }

  onContractNumberSearch(event) {
    this.searchContractNumberKeyword = event.term;
    this.searchContractNumberObserver.next();
  }

  clearContractNumberFilter() {
    this.searchContractNumberKeyword = null;
    this.searchContractNumbers();
  }

  async searchContractNumbers() {
    this.contractNumberOptions = [];
    this.contractNumberPageNumber = 1;
    this.contractNumberTotalPage = null;
    this.contractNumberOptions = await this.requestContractNumberList(this.contractNumberPageNumber);

  }

  async onContractNumberScrollToEnd() {
    if (this.contractNumberPageNumber < this.contractNumberTotalPage) {
      this.contractNumberPageNumber++;

      let contractNumbers = await this.requestContractNumberList(this.contractNumberPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.contractNumberOptions = [...this.contractNumberOptions, ...contractNumbers];
    }
  }

  private async requestContractNumberList(pageNumber?: number) {
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();

    request.expired = JMENUM.RequestExpired.BOTH;
    request.sortBy = 'contractNumber';
    request.sortOrder = 1;
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.contractNumberPageSize;

    if (this.searchContractNumberKeyword) {
      request.filter = { contractNumber: this.searchContractNumberKeyword };
    }

    this.isLoadingContractNumber = true;
    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingContractNumber = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.contractNumberTotalPage = Math.ceil(totalCount / request.pageSize);
    }

    return response.payload.records.map((contract) => {
      return contract.contractNumber;
    });
  }
}
