import { Component, Input, OnInit, Injector } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { TranslateService } from '@ngx-translate/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { ErrorCode } from 'src/JMErrorCode';
@Component({
    selector: 'add-hashtag-group-form',
    templateUrl: './add-hashtag-group-form.component.html',
    styleUrls: ['./add-hashtag-group-form.component.scss']
})

export class AddHashTagGroupFormComponent implements OnInit{
  private translateService: TranslateService;
    tagGroup: JMOBJ.HashTagGroup = undefined;
    uiShowError:boolean = false;  // dont show error before first submission
    dropdownPageNumber:number = 1;
    dropdownPageSize:number = 20;
    
   // uiWorkCentreArray: { value: string, label: string, }[] = [];
    hashtagGroupStatusList: { value: string, label: string, }[] = [];
    constructor(injector: Injector) {
      this.translateService = injector.get(TranslateService);
    }

    @Input() parameters:{
      onSaveButtonClicked: Function,
      onDeleteButtonClicked: Function,
      mode: string,
    }

    uiEnableSaveButton:boolean = undefined;
    uiEnableDeleteButton:boolean = undefined;
    uiEnableStartDate:boolean = undefined;

    uiLoadingSaveButton: boolean = undefined;
    uiLoadingDeleteButton: boolean = undefined;
    uiHashtagGroupName:string = undefined;
    uiHashtagGroupDescription:string = undefined;
    // uiHashtagGroupWorkCentres: any[] = undefined;
    uiSelectedWorkCentreArray:string[] = [];  // selected work centre code
    uiWorkCentreOptions: {
      label: string,
      value: string,
    }[] = [];

    uiHashtagGroupStatus:JMENUM.HashtagGroupStatus = undefined;
    uiHashtagGroupStatusOptions: {
      label: string,
      value: JMENUM.HashtagGroupStatus,
    }[] = undefined;

    // show group name
    uiShowGroupName:boolean = undefined;
    uiShowGroupNameOptions: {
      label: string,
      value: boolean,
    }[] = undefined;
    // uiHashtagGroupType:string = undefined;
    // uiHashtagGroupTypeArray: any[] = undefined;

    uiErrorMessage: string[] = undefined;

    ngOnInit(){
      this.uiShowGroupNameOptions = [
        {
          label: JMLanguage.translate("global.yes"),
          value: true,
        }, {
          label: JMLanguage.translate("global.no"),
          value: false,
        }
      ];
      this.uiHashtagGroupStatusOptions = [
        {
          label: JMLanguage.translate("hashtagGroup.status.active"),
          value: JMENUM.HashtagGroupStatus.ACTIVE,
        }, {
          label: JMLanguage.translate("hashtagGroup.status.inactive"),
          value: JMENUM.HashtagGroupStatus.INACTIVE,
        }
      ];
      this.uiWorkCentreOptions = Object.keys(Session.workCentreDict).map(wc => { return {
        value: wc,
        label: wc,
      }});
      this.clearView();
      this.setMode('add');
    }

    enableSaveButton(enable:boolean):void {
      this.uiEnableSaveButton = enable;
    }

    clearView() {
      this.tagGroup = undefined;
      this.uiEnableSaveButton = true;
      this.uiEnableDeleteButton = true;
      this.uiLoadingSaveButton = false;
      this.uiLoadingDeleteButton = false;
      this.uiSelectedWorkCentreArray = [];
      this.uiHashtagGroupName = '';
      this.uiHashtagGroupDescription = '';
      this.uiHashtagGroupStatus = JMENUM.HashtagGroupStatus.ACTIVE;
      this.uiShowGroupName = true;
      this.uiErrorMessage = [];
    }
    setMode(mode:'add'|'edit'){
      this.parameters.mode = mode;
    }
    setTagGroup(tg:JMOBJ.HashTagGroup){
      this.tagGroup = tg;
      this.uiHashtagGroupName = this.tagGroup.name;
      this.uiHashtagGroupDescription = this.tagGroup.description;
      this.uiHashtagGroupStatus = this.tagGroup.status;
      this.uiShowGroupName = this.tagGroup.isNameOnDisplay;
      this.uiSelectedWorkCentreArray = this.tagGroup.workCentres.map(wc => { return wc.workCentreCode});
    }
    setEnableButtons(enable) {
      this.uiEnableSaveButton = enable;
      this.uiEnableDeleteButton = enable;
      this.uiLoadingSaveButton = false;
      this.uiLoadingDeleteButton = false;
    }
    setLoadingDelete(deleting){
      this.uiEnableDeleteButton = !deleting;
      this.uiEnableSaveButton = !deleting;
      this.uiLoadingDeleteButton = deleting;
    }
    setLoadingSave(saving){
      this.uiEnableDeleteButton = !saving;
      this.uiEnableSaveButton = !saving;
      this.uiLoadingSaveButton = saving;
    }


    validateInput(){
      this.uiHashtagGroupName = this.uiHashtagGroupName.trim();
      if(this.uiHashtagGroupName == null || this.uiHashtagGroupName.length == 0){
        AppDelegate.openSnackBar(JMLanguage.translate("add-hashtag-group-form.error.null-name"));
        return false;
      }
      if(this.uiHashtagGroupStatus === undefined){
        AppDelegate.openSnackBar(JMLanguage.translate("add-hashtag-group-form.error.null-status"));
        return false;
      }
      if(this.uiShowGroupName === undefined){
        AppDelegate.openSnackBar(JMLanguage.translate("add-hashtag-group-form.error.null-isNameOnDisplay"));
        return false;
      }
      // workCentre Type validation 
      if(this.uiSelectedWorkCentreArray.length == 0){
        AppDelegate.openSnackBar(JMLanguage.translate("add-hashtag-group-form.error.null-workCentres"));
        return false;
      }
      return true;
    }

    onSaveButtonClicked(event) {
      if(this.validateInput()) {
        // send request
        this.setLoadingSave(true);

        let request = new JM.JMRequestHashtagGroupUpdateHashtagGroup();
        if (this.parameters.mode == 'edit') {
          request._id = this.tagGroup._id;
        }
        request.name = this.uiHashtagGroupName.trim();
        request.description = this.uiHashtagGroupDescription;
        request.status = this.uiHashtagGroupStatus;
        request.isNameOnDisplay = this.uiShowGroupName;
        request.workCentres = this.uiSelectedWorkCentreArray;

        JM.JMConnector.sendHashtagGroupUpdateHashtagGroup(request,(error: JM.JMNetworkError, response: JM.JMResponseHashtagGroupUpdateHashtagGroup) => {
          this.setLoadingSave(false);
          if(error){
            AppDelegate.handleJMError(error);
            return;
          }
          if (!response || !response.code || response.code != 200 || !response.payload) {
            AppDelegate.openErrorBar(response);
            return;
          }
          
          AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
          if (this.parameters.onSaveButtonClicked) {
            this.parameters.onSaveButtonClicked();
          }

        });
      }
    }

    onDeleteButtonClicked(event) {
      let message = JMLanguage.translate("add-hashtag-group-form.confirm-delete-tag-group", []);
      let buttons = [
        { 
          name: JMLanguage.translate("add-hashtag-group-form.confirm-delete-tag-group.yes"), 
          handler: () => { 
            this.setLoadingDelete(true);
            let request = new JM.JMRequestHashtagGroupDeleteHashtagGroup();
            request._id = this.tagGroup._id;
            JM.JMConnector.sendHashtagGroupDeleteHashtagGroup(request,(error: JM.JMNetworkError, response: JM.JMResponseHashtagsDeleteHashtag) => {
              this.setLoadingDelete(false);
    
              if(error){
                AppDelegate.handleJMError(error);
                return;
              }
              if (response) {
                if (response.code == 200) {
                  AppDelegate.openSnackBar(JMLanguage.translate("global.removed"))
              
                  if(this.parameters.onDeleteButtonClicked){
                    this.parameters.onDeleteButtonClicked();
                  }

                } else if (response.code == ErrorCode.CANNOT_DELETE_TAG_GROUP_WITH_TAG) {
                  AppDelegate.openSnackBar(JMLanguage.translate("api.error.13020"))
              
                  
                } else {
                  AppDelegate.openErrorBar(response);
                }
              } else {
                AppDelegate.openErrorBar(response);
              }
            }
          );
        } 
      }, { 
        name: JMLanguage.translate("global.no"), 
        handler: ()=> {
          this.setEnableButtons(true);
        }
      }];
      AppDelegate.showPopUpAlert(message, "", buttons)
    }
}