import { Component, Input, OnInit, Injector } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { Session } from '@services/session';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { Constants } from 'src/constants';
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'app-remark-detail',
  templateUrl: './remark-detail.component.html',
  styleUrls: ['./remark-detail.component.scss']
})
export class RemarkDetailComponent extends BasePage implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Input() currentPageMode: JMENUM.JMPageMode = undefined;
  @Input() additionalRemark: JMOBJ.Remark = new JMOBJ.Remark();

  pageMode = JMENUM.JMPageMode;

  SnStatus = JMENUM.SnStatus;

  RemarkVisibility = JMENUM.RemarkVisibility;

  publicVisibilityPermission = false;
  privateVisibilityPermission = false;
  invisibleVisibilityPermission = false;

  // additionalRemark: JMOBJ.Remark = new JMOBJ.Remark;
  defaultRemarkVisibility = JMENUM.RemarkVisibility.PUBLIC;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.additionalRemark.visibilityType = this.defaultRemarkVisibility;
  }

  ngOnChanges() {
    this.additionalRemark.snStatus = (this.sn.status) ? this.sn.status : undefined;
    this.additionalRemark.visibilityType = (this.additionalRemark.visibilityType)?this.additionalRemark.visibilityType:this.defaultRemarkVisibility;
    if (this.currentPageMode == this.pageMode.EDIT) {
      this.publicVisibilityPermission = this.authorizationService.hasPermission(JMENUM.Permission.SN_REMARKS_PUBLIC_UPDATE);
      this.privateVisibilityPermission = this.authorizationService.hasPermission(JMENUM.Permission.SN_REMARKS_PRIVATE_UPDATE);
      this.invisibleVisibilityPermission = this.authorizationService.hasPermission(JMENUM.Permission.SN_REMARKS_INVISIBLE_UPDATE);
    }
    // else if(this.currentPageMode == this.pageMode.VIEW){
    //   this.publicVisibilityPermission = false;
    //   this.privateVisibilityPermission = false;
    //   this.invisibleVisibilityPermission = false;
    // }
  }

  public onRemarkVisibilityChanged(visibility: JMENUM.RemarkVisibility) {
    this.additionalRemark.visibilityType = visibility;
  }

}
