import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { JMLanguage } from '../JMLanguage/JMLanguage';

  export function ngbDateToString(date: NgbDateStruct | undefined): string | undefined {
    if(date){
      return date.year.toString() + date.month.toString().padStart(2, '0') + date.day.toString().padStart(2, '0');
    }
    return undefined;
  }

  export function toNgbDate(value: string | undefined, format?): NgbDateStruct | undefined {
    if (value) {
      let date;
      if(format){
        date = moment(value, format);
      }else{
        date = moment(value);
      }
      
      return {
        day: parseInt(date.date().toString()),
        month: parseInt((date.month()+1).toString()),
        year: parseInt(date.year().toString())
      };
    }
    return undefined;
  }

  export function stringToNgbDate (value: string | undefined, format?:string): NgbDateStruct | undefined {
    let dateFormat = format?format:Constants.DATE_FORMAT;
    if (value) {
      let date = moment(moment(value).format(dateFormat));
      let ngbDateObj: NgbDateStruct;
      ngbDateObj = {day: +date.format("D"), month: +date.format("M"), year: +date.format("YYYY")};
      return ngbDateObj
    }
    return undefined;
  }
  
  export function stringToMoment(value: string | undefined, format?: string): moment.Moment | undefined {
    if (value) {
      const momentValue = format ? moment(value) : moment(value, format);
      if (momentValue.isValid()) {
        return momentValue;
      }
      return undefined;
    }
    return undefined;
  }
  
  export function formatDate (value: string | undefined,style): string | undefined {
    if (value) {
      if(moment(value).isValid())
      {
        return moment(value).format(style).toString();
      }
      return undefined;
    }
    return undefined;
  }

  export function formatDateToOtherFormat (value: string | undefined,currentStyle: string,style: string): string | undefined {
    if (value) {
      if(moment(value,currentStyle).isValid())
      {
        return moment(value,currentStyle).format(style).toString();
      }
      return undefined;
    }
    return undefined;
  }

  export function base64ToBlob(base64, contentType){
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: contentType});
  }

  export function pad(num, size=2) {
    var s = "0" + num;
    return s.substr(s.length - size);
  }

/*
    Contract End Date format: YYYYMMDD
    Temp function to handle the dateString
*/
export function formatHAContractEndDateToDateString(contractEndDate: string): Date {
  if (contractEndDate.length == 8) {
      return new Date(contractEndDate.substr(0, 4) + "-" + contractEndDate.substr(4, 2) + "-" + contractEndDate.substr(6, 2));
  } else {
      return undefined;
  }
}

export function fileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


export function formatDateTimeWithWeek(date){
  // YYYY-MM-DD (ddd) HH:mm
  let result = undefined;
  if(moment(date).isValid()){
    result = moment(date).format(Constants.DATE_FORMAT) + " (" +  JMLanguage.translate("global.weekday.abbr."+moment(date).format("d")) + ") " + moment(date).format("HH:mm");
  }
  return result;
}

export function formatDateWithWeek(date){
  // YYYY-MM-DD (ddd)
  let result = undefined;
  if(moment(date).isValid()){
    result = moment(date).format(Constants.DATE_FORMAT) + " (" +  JMLanguage.translate("global.weekday.abbr."+moment(date).format("d")) + ") ";
  }
  return result;
}

export function inputNumber(event: any) { //copy from base.ts
  event.target.value = event.target.value.replace(/[^\d]/g, '');
}
export function inputNumberDigit(event: any) { //copy from base.ts
  event.target.value = event.target.value.replace(/[^0-9a-zA-Z]/g, '');
}
export function ngbdatestructToDate(ngbDate: NgbDateStruct):Date {
  if (ngbDate == null || ngbDate.year == null || ngbDate.month == null || ngbDate.day == null) return null;
  return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
}
