import { PostAcknowledgeByTokenRequest } from '@api/model/sn/post-sn-acknowledge-by-token-request';
import { PostSnGetSnByTokenRequest } from '@api/model/sn/post-sn-get-sn-by-token-request';
import { Component, Injector, OnInit, ɵConsole } from '@angular/core';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { SnStatusTranslate, SnStatus } from '@enum/sn-status';
import { JobCard } from 'src/app/entity/data-model/job-card';
import { JobCardService } from '@services/job-card.service';
import { DistrictNameTranslate } from '@enum/district';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { SnPriorityTranslate } from '@enum/priority';
import * as utility from 'src/app/services/utility';
import { Sn } from 'src/app/entity/data-model/sn';
import { SnService } from '@services/sn.service';
import { SnReason } from '@enum/sn-reason';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-sn-non-pmsmc-view',
  templateUrl: './sn-non-pmsmc-view.html',
  styleUrls: ['./sn-non-pmsmc-view.scss'],
  providers: [NgbTimepickerConfig]
})

export class SnNonPmsmcViewComponent extends BasePage implements OnInit {

  constructor(
    injector: Injector,
    config: NgbTimepickerConfig,
    private snService: SnService,
    private jobCardService: JobCardService,
  ) {
    super(injector, false);
    config.spinners = false;
  }

  sn: Sn;
  snToken: string;

  jobCard: JobCard;
  jobCardInput: any = {};
  
  snStatus = SnStatus;
  priorityTranslate = SnPriorityTranslate;
  snStatusTranslte = SnStatusTranslate;
  districtNameTranslate = DistrictNameTranslate;
  locationDetail = {zh:'', en: ''};
  clientDetail = {zh:'', en: ''};

  isAcknowledgeButtonLoading = false;
  isRejectButtonLoading = false;
  isCreateJobCardButtonLoading = false;
  isCompleteJobCardButtonLoading = false;
  isExportPdfButtonLoading = false;

  rejectOptions: any[];
  rejectCode: String;
  rejectRemarks: String;

  completeOptions: any[];
  equipmentOptions: any[] = [];

  errorWhenGetSn: boolean = false;

  // job card input fields
  jobCardInputFields = [
    "equipment", 
    "jobDescription",
    "completeCode",
  ];

  // job card date time fields
  jobCardDateTimeFields = [
    {sourceDateTime: "appointmentTime",      viewDate: "appointmentDate",      viewTime: "appointmentTime"},
    {sourceDateTime: "completionTime",       viewDate: "completionDate",       viewTime: "completionTime"},
    {sourceDateTime: "startTime",            viewDate: "startDate",            viewTime: "startTime"},
  ];

  ngOnInit() {
    this.snToken = this.route.snapshot.paramMap.get('snToken');
    this.initRejectOption();
    this.initCompleteOption();
    this.requestGetSnByToken();
  }

  //===================================================================================================================
  // API request functions
  requestGetSnByToken(): void {
    let request = {} as PostSnGetSnByTokenRequest;
    request.token = this.snToken;
    this.apiHandler(this.snService.getSnByToken(request),
    result => {
      if (!result || result.code != 200) {
        this.openErrorBar(result);
        this.errorWhenGetSn = true;
        return;
      }

      if (!result.payload) {
        this.openErrorBar();
        this.errorWhenGetSn = true;
        return;
      }

      this.errorWhenGetSn = false;

      let payload = result.payload;
      this.sn = payload.sn;
      this.jobCard = payload.jobCard;

      if (this.jobCard && this.jobCard.equipment) {
        this.jobCardInput['equipment'] = this.jobCard.equipment;
      }
      
      JM.JMConnector.setToken(payload.jwt);
      this.authorizationService.setUserToken(payload.jwt);

      this.requestLocationDetail();
      this.requestClientDetail();
      this.requestEquipment();
    }, error => {
      console.log(error);
    });
  }

  requestLocationDetail() {
    if (!this.sn || !this.sn.location) return;
    let request:JM.JMRequestLocationsLocationSummary = new JM.JMRequestLocationsLocationSummary();
    request.location = [this.sn.location];

    JM.JMConnector.sendLocationsLocationSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseLocationsLocationSummary) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      if (response.payload.records.length > 0) {
        this.locationDetail = response.payload.records[0].description;
      } else {
        console.error("location not found: " + request.location);
      }
    });

  }

  requestClientDetail() {
    if (!this.sn || !this.sn.client) return;

    let request = new JM.JMRequestClientsClientSummary();
    request.clientShortName = [this.sn.client];
    JM.JMConnector.sendClientsClientSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseClientsClientSummary) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.clientDetail = response.payload.records[0].name;
    });
  }

  requestEquipment() {
    if (!this.sn || !this.sn.hashtagId) return;
    let request: JM.JMRequestEquipmentsEquipmentSummary = new JM.JMRequestEquipmentsEquipmentSummary();
    request.parameters = ["equipmentNumber", "description"];
    request.tagId = [this.sn.hashtagId];
    
    JM.JMConnector.sendEquipmentsEquipmentSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseEquipmentsEquipmentSummary) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      this.equipmentOptions = response.payload.records.map(e => { 
        return { 
          value: e.equipmentNumber, 
          description: e.equipmentNumber + " - " + e.description 
        } 
      });
    });
  }

  requestAcknowledgeByToken() {
    this.isAcknowledgeButtonLoading = true;

    let request = {} as PostAcknowledgeByTokenRequest;
    request.token = this.snToken;
    request.version = this.sn.version;
    this.apiHandler(this.snService.acknowledgeByToken(request),
      result => {
      this.isAcknowledgeButtonLoading = false;

      if (!result || result.code != 200) {
        this.openErrorBar(result);
        return;
      }

      if (!result.payload) {
        this.openErrorBar();
        return;
      }

      this.sn = result.payload;
    }, error => {
      this.isAcknowledgeButtonLoading = false;
      this.openErrorBar();
    });
  }

  requestRejectSnByToken() {
    this.isRejectButtonLoading = true;

    let request = {} as any;
    request.token = this.snToken;
    request.version = this.sn.version;
    request.rejectCode = this.rejectCode;
    if(this.rejectRemarks) request.rejectRemarks = this.rejectRemarks;

    this.apiHandler(this.snService.rejectByToken(request),
      result => {
      this.isRejectButtonLoading = false;

      if (!result || result.code != 200) {
        this.openErrorBar(result);
        return;
      }

      if (!result.payload) {
        this.openErrorBar();
        return;
      }

      this.sn = result.payload;
    }, error => {
      this.isRejectButtonLoading = false;
      this.openErrorBar();
    });
  }

  
  requestCreateJobCardByToken() {
    this.isCreateJobCardButtonLoading = true;

    let request = {} as any;
    request.token = this.snToken;

    this.apiHandler(this.jobCardService.createByToken(request),
      result => {
      this.isCreateJobCardButtonLoading = false;

      if (!result || result.code != 200) {
        this.openErrorBar(result);
        return;
      }

      if (!result.payload) {
        this.openErrorBar();
        return;
      }

      this.jobCard = result.payload;
      this.sn.status = SnStatus.inProgress; // asycn problem
    }, error => {
      this.isCreateJobCardButtonLoading = false;
      this.openErrorBar();
    });
  }

  requestCompleteJobCardByToken() {
    if (!this.jobCard || !this.checkJobCardInputValid()) return;

    let request = {} as any;
    request.token = this.snToken;
    request.version = this.jobCard.version;

    // get input value
    this.jobCardInputFields.forEach(field => {
      request[field] = null;
      if (this.jobCardInput[field]) request[field] = this.jobCardInput[field];
    });

    // get input date time value
    this.jobCardDateTimeFields.forEach(field => {
      request[field.sourceDateTime] = null;
      let dateTime = this.NgbdatestructToISOString(this.jobCardInput[field.viewDate], this.jobCardInput[field.viewTime]);
      if (dateTime) request[field.sourceDateTime] = dateTime;
    });

    this.isCompleteJobCardButtonLoading = true;

    this.apiHandler(this.jobCardService.completeByToken(request),
    result => {
      this.isCompleteJobCardButtonLoading = false;

      if (!result || result.code != 200) {
        this.openErrorBar(result);
        return;
      }

      if (!result.payload) {
        this.openErrorBar();
        return;
      }

      this.jobCard = result.payload;
      this.sn.status = SnStatus.inProgress; // SnStatus.completed; // asycn problem
    }, error => {
      this.isCompleteJobCardButtonLoading = false;
      this.openErrorBar();
    });
  }

  //===================================================================================================================
  initRejectOption() {
    this.rejectOptions = [];
    SnReason.forEach(reason => {
      if (reason.area === 'sn' && reason.orderType === 'reject') {
        this.rejectOptions.push({
          value: reason.id,
          description: reason.descriptionZh + " " + reason.descriptionEn
        });
      }
    });
  }

  initCompleteOption() {
    this.completeOptions = [];
    let nonPmsmcReasonCode = ["3200", "3210"];

    SnReason.forEach(reason => {
      if (reason.area === 'jobCard' && reason.orderType === 'complete' && nonPmsmcReasonCode.includes(reason.id)) {
        this.completeOptions.push({
          value: reason.id,
          description: reason.descriptionZh + " " + reason.descriptionEn
        });
      }
    });
  }

  checkActionEnable(action) {
    let enableStatus = [];

    switch (action) {
      case "acknowledge":
        enableStatus = [SnStatus.pendingAcknowledge, SnStatus.noResponse];
        return enableStatus.includes(this.sn.status as SnStatus);
      case "reject":
        enableStatus = [SnStatus.pendingAcknowledge, SnStatus.noResponse, SnStatus.acknowledged];
        return enableStatus.includes(this.sn.status as SnStatus);
      case "createJobCard":
        return !this.jobCard && this.sn.status === SnStatus.acknowledged;
      case "completeJobCard":
        return this.jobCard && this.jobCard.status === JMENUM.JobCardStatus.IN_PROGRESS && !this.jobCard.pendingApproval;
      default:
        return false;
    }
  }

  checkJobCardInputValid() {
    if (!this.jobCardInput) return false;

    let mandatoryFields = [
      "equipment", 
      "completeCode",
      "appointmentDate",
      "appointmentTime",
      "completionDate",
      "completionTime",
      "startDate",
      "startTime"
    ];

    if (this.checkEquipmentInput() && this.checkDateTimeFieldsValid()) {
      for (let field of mandatoryFields) {
        if (!this.jobCardInput[field]) {
          this.openSnackBar("請填寫所有必填資料 Please input the mandatory fields.");
          return false;
        }
      }
      return true;
    }

    return false;
  }

  checkEquipmentInput() {
    if (!this.jobCardInput) return false;

    if (this.equipmentOptions.length && !this.jobCardInput.equipment) {
      this.openSnackBar("請選擇設備 Please input equipment.");
      return false;
    }

    return true;
  }

  checkDateTimeFieldsValid() {
    if (!this.jobCardInput) return false;

    for (let field of this.jobCardDateTimeFields) {
      if ((!this.jobCardInput[field.viewDate] && this.jobCardInput[field.viewTime]) || 
          (this.jobCardInput[field.viewDate] && !this.jobCardInput[field.viewTime])) {
          this.openSnackBar("日期未輸入 Date format is not completed");
          return false;
      }
    }
    
    if (this.jobCardInput.completionDate && this.jobCardInput.completionTime &&
      this.jobCardInput.startDate && this.jobCardInput.startTime ) {

      // check complete time > start time
      let differTime = this.getDifferTimeByNgbdatestruct(this.jobCardInput.startDate, 
                                                        this.jobCardInput.startTime,
                                                        this.jobCardInput.completionDate, 
                                                        this.jobCardInput.completionTime);

      if (differTime < 0) {
        this.openSnackBar("工作卡完成時間不能早過工作卡開始時間 The Job Completion Time cannot be earlier than the Job Start Time");
        return false;
      }
      
      if (differTime < (15 * 60 * 1000) /* 15 mins */) {
        this.openSnackBar("最少的工時單位為15分鐘 The minimum job time is at least 15 minutes");
        return false;
      }
    }

    // check end time < current time
    let currentTime = Date.now();

    let completeTime = this.getDateObjectByNgbdatestruct(this.jobCardInput.completionDate,  this.jobCardInput.completionTime);
    if (completeTime.valueOf() >= currentTime.valueOf()) {
      this.openSnackBar("工作卡完成時間必需早過目前時間 Job Completion Time must be earlier than current time");
        return false;
    }

    return true;
  }

  getDateObjectByNgbdatestruct(date, time) {
    let isoString = this.NgbdatestructToISOString(date, time);
    return new Date(isoString);
  }

  getDifferTimeByNgbdatestruct(fromDate, fromTime, toDate, toTime) {
    let from = this.NgbdatestructToISOString(fromDate, fromTime);
    let to = this.NgbdatestructToISOString(toDate, toTime);

    return this.getDifferTimeByISOString(from, to); // return ms
  }

  getDifferTimeByISOString(from, to) {
    let startTime = new Date(from);
    let endTime = new Date(to);

    return endTime.valueOf() - startTime.valueOf(); // return ms
  }

  //===================================================================================================================
  // Event handle functions
  onClickAcknowledge() {
    let handler = () => {
      this.requestAcknowledgeByToken();
    };

    this.popupConfirmAlert("是否確認服務通知 (Do you want to Acknowledge)?", handler);
  }

  onClickReject() {
    let handler = () => {
      this.requestRejectSnByToken();
    };

    this.popupConfirmAlert("是否拒絕服務通知 (Do you want to Reject)?", handler);
  }

  onClickCreateJobCard() {
    let handler = () => {
      this.requestCreateJobCardByToken();
    };

    this.popupConfirmAlert("是否確定建立工作卡 (Do you want to Create Job Card)?", handler);
  }
  
  onClickCompleteJobCard() {
    let handler = () => {
      this.requestCompleteJobCardByToken();
    };

    this.popupConfirmAlert("是否確定完成工作卡 (Do you want to Complete Job Card)?", handler);
  }

  onClickExportPdf() {
    this.isExportPdfButtonLoading = true;
    
    let request:JM.JMRequestNotificationExportSNPDF = new JM.JMRequestNotificationExportSNPDF();
    request.snNumber = this.sn.snNumber;
    request.lang = JMENUM.Language.ZH;
    JM.JMConnector.sendNotificationExportSNPDF(request, (error:JM.JMNetworkError, response:JM.JMResponseNotificationExportSNPDF) => {
      this.isExportPdfButtonLoading = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      let base64 = response.payload.base64;
      let blob = utility.base64ToBlob(base64, response.payload.type);
      saveAs(blob, `${this.sn.snNumber}.pdf`);
    })
  }

  private popupConfirmAlert(message: string, handler: any) {
    let buttons = [
      { name: '是 Yes', handler },
      { name: '否 No' }
    ]
    this.showPopUpAlert(message, "", buttons);
  }

}