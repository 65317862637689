import { Component, Injector, OnInit } from "@angular/core";
import { formatDate } from '@angular/common';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { saveAs } from "file-saver";
import { BasePage } from "src/app/ui/model/base/base";
import { Session }  from 'src/app/services/session';
import { JM, JMENUM, JMUTILITY } from '@ccep/CCEPConnector-ts';
import * as utility from 'src/app/services/utility';
import { JMLanguage } from "src/lib/JMLanguage/JMLanguage";
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { AppDelegate } from 'src/app/AppDelegate';
import { EquipmentTagService } from "@services/equipment-tag.service";

@Component({
  selector: "routing-rules-list",
  templateUrl: "./routing-rules-list.html",
  styleUrls: ["./routing-rules-list.scss"]
})
export class RoutingRulesListComponent extends BasePage implements OnInit {
  
  constructor(
    injector: Injector,
    private equipmentTagService: EquipmentTagService,
  ) {
    super(injector);

    if ( this.selectedWorkCenter && !this.authorizationService.hasAuthorizationForWorkCenter(this.selectedWorkCenter)) {
      AppDelegate.navigate(["/dashboard"]);
      return;
    }
  
  }

  pageTitle: string;

  isExportExcelButtonLoading = false;
  isRefreshAllButtonLoading = false;

  hasEditPermission     : boolean = false;

  ///------------
  selectedWorkCenter    : string; // current selected workcentre
  tablexParam           : {} = {};  // isLoadingTable
  currentPageSize       = 10;
  currentPage           = 1;
  routingRuleGroups     = {}; // the result of getRoutingRulesGroups


  // 
  protected componentName(): string {
    return "equipment-list";
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.routing-rules-list.page-title", [Session.selectedWorkCentre]);
    // set previous page number nad page size for close button
    if(history.state.pageNumber) {
      this.currentPage = history.state.pageNumber;
    }
    if(history.state.pageSize) {
      this.currentPageSize = history.state.pageSize;
    }

    this.selectedWorkCenter = Session.selectedWorkCentre;
    this.checkViewPermission(JMENUM.Permission.WCM_VIEW);
    this.checkWorkCenterPermission(this.selectedWorkCenter);
    this.hasEditPermission = JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.WCM_UPDATE) || 
                            JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.WCM_UPDATE_ALL);

    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : 1,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      headers: [
        {id:'rule_id',                    name   : "pages.routing-rules-list.table-column-rule-id",                    type: TablexColumnType.Text       ,horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
        {id:'equipment_tag',              name   : "pages.routing-rules-list.table-column-equipment-tag",              type: TablexColumnType.BorderLabel  ,horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
        {id:'non_urgent_handling_party',  name   : "pages.routing-rules-list.table-column-non-urgent-handling-party",  type: TablexColumnType.Html  ,horizontalAlign: TablexColumnHorizontalAlign.Left, verticalAlign: TablexColumnVerticalAlign.Top} ,
        {id:'urgent_handling_party',      name   : "pages.routing-rules-list.table-column-urgent-handling-party",      type: TablexColumnType.Html  ,horizontalAlign: TablexColumnHorizontalAlign.Left, verticalAlign: TablexColumnVerticalAlign.Top},
        {id:'emergency_handling_party',   name   : "pages.routing-rules-list.table-column-emergrency-handling-party",  type: TablexColumnType.Html  ,horizontalAlign: TablexColumnHorizontalAlign.Left, verticalAlign: TablexColumnVerticalAlign.Top},        
        {id:'pm_handling_party',          name   : "pages.routing-rules-list.table-column-pm-handling-party",          type: TablexColumnType.Html  ,horizontalAlign: TablexColumnHorizontalAlign.Left, verticalAlign: TablexColumnVerticalAlign.Top},        
        {id:'last_update',                name   : "pages.routing-rules-list.table-column-last-update",                type: TablexColumnType.MultiLine  ,horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle}, 
        {id:'action',                     name   : "pages.routing-rules-list.table-column-action",                     type: TablexColumnType.Buttons    ,horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
      ],
      content: []
    }

    // this.requestRoutingRuleGroup();
    this.requestRoutingRuleSummary();
  }

  ngOnDestroy() {
  }

  //---------------------------------------------------------------------------
  // view functions
  private renderTable() {
    // object of routing rule
    let lang = Session.selectedLanguage;
    let index = (this.currentPage-1) * this.currentPageSize;
    if(!this.routingRuleGroups){
      this.tablexParam['pageCount'] = 0;
      this.tablexParam['content'] = null;
    } else {

      this.tablexParam['pageCount'] = Math.ceil(this.routingRuleGroups['totalCount']/this.currentPageSize);
      // this.tablexParam['content'] = this.routingRuleGroups['groups'].map(group => {
      this.tablexParam['content'] = this.routingRuleGroups['records'].map(rule => {
        index = index + 1;

        let updatedAt = "";
        let updatedBy = "";
        if(rule.updatedAt && rule.updatedAt != null) {
          updatedAt = formatDate(rule.updatedAt, "yyyy/MM/dd HH:mm", 'en-US');
        } else if(rule.createdAt && rule.createdAt != null) {
          updatedAt = formatDate(rule.createdAt, "yyyy/MM/dd HH:mm", 'en-US');
        }
        if(rule.updatedBy && rule.updatedBy != null) {
          updatedBy = rule.updatedBy;
        } else if(rule.createdBy && rule.createdBy != null) {
          updatedBy = rule.createdBy;
        }

        let nonUrgentTeamHtml = "";
        if(rule.teams && rule.teams["3"]) {
          rule.teams["3"].map((team, index) => {
            if(team.workCentre == this.selectedWorkCenter) {
              nonUrgentTeamHtml += "<tr><td class='nowrap'>"+(index+1)+".&nbsp;</td><td>"+FORAMTTER.formatTeamNameContractNumber(team);
            }else{
              nonUrgentTeamHtml += "<tr><td class='nowrap'>"+(index+1)+".&nbsp;</td><td>"+FORAMTTER.formatTeamNameContractNumber(team) + "	&lt;" + team.workCentre + "&gt;";
            }

            if(team.status === JMENUM.TeamStatus.CONTRACT_EXPIRED) {
              nonUrgentTeamHtml += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + JMLanguage.translate('pages.sn.tooltip.team-contract-expired') + "'></i>";
            }

            if(team.status === JMENUM.TeamStatus.CONTRACT_INEFFECTIVE) {
              nonUrgentTeamHtml += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + JMLanguage.translate('pages.sn.tooltip.team-contract-ineffective') + "'></i>";
            }

            nonUrgentTeamHtml += "</td></tr>";
          })
        }
        nonUrgentTeamHtml = "<table class='routing-rule-team-table'>"+nonUrgentTeamHtml+"</table>";

        let urgentTeamHtml = "";
        if(rule.teams && rule.teams["2"]) {
          rule.teams["2"].map((team, index) => {
            if(team.workCentre == this.selectedWorkCenter) {
              urgentTeamHtml += "<tr><td class='nowrap'>"+(index+1)+".&nbsp;</td><td>"+FORAMTTER.formatTeamNameContractNumber(team);
            }else{
              urgentTeamHtml += "<tr><td class='nowrap'>"+(index+1)+".&nbsp;</td><td>"+FORAMTTER.formatTeamNameContractNumber(team) + "	&lt;" + team.workCentre + "&gt;";
            }

            if(team.status === JMENUM.TeamStatus.CONTRACT_EXPIRED) {
              urgentTeamHtml += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + JMLanguage.translate('pages.sn.tooltip.team-contract-expired') + "'></i>";
            }

            if(team.status === JMENUM.TeamStatus.CONTRACT_INEFFECTIVE) {
              urgentTeamHtml += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + JMLanguage.translate('pages.sn.tooltip.team-contract-ineffective') + "'></i>";
            }

            urgentTeamHtml += "</td></tr>";
          })
        }
        urgentTeamHtml = "<table class='routing-rule-team-table'>"+urgentTeamHtml+"</table>";

        let emergencyTeamHtml = "";
        if(rule.teams && rule.teams["1"]) {
          rule.teams["1"].map((team, index) => {
            if(team.workCentre == this.selectedWorkCenter) {
              emergencyTeamHtml += "<tr><td class='nowrap'>"+(index+1)+".&nbsp;</td><td>"+FORAMTTER.formatTeamNameContractNumber(team);
            }else{
              emergencyTeamHtml += "<tr><td class='nowrap'>"+(index+1)+".&nbsp;</td><td>"+FORAMTTER.formatTeamNameContractNumber(team) + "	&lt;" + team.workCentre + "&gt;";
            }

            if(team.status === JMENUM.TeamStatus.CONTRACT_EXPIRED) {
              emergencyTeamHtml += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + JMLanguage.translate('pages.sn.tooltip.team-contract-expired') + "'></i>";
            }

            if(team.status === JMENUM.TeamStatus.CONTRACT_INEFFECTIVE) {
              emergencyTeamHtml += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + JMLanguage.translate('pages.sn.tooltip.team-contract-ineffective') + "'></i>";
            }

            emergencyTeamHtml += "</td></tr>";
          })
        }
        emergencyTeamHtml = "<table class='routing-rule-team-table'>"+emergencyTeamHtml+"</table>";
        
        let pmTeamHtml = "";
        if(rule.teams && rule.teams["pm"]) {
          rule.teams["pm"].map((team, index) => {
            if(team.workCentre == this.selectedWorkCenter) {
              pmTeamHtml += "<tr><td class='nowrap'>"+(index+1)+".&nbsp;</td><td>"+FORAMTTER.formatTeamNameContractNumber(team);
            }else{
              pmTeamHtml += "<tr><td class='nowrap'>"+(index+1)+".&nbsp;</td><td>"+FORAMTTER.formatTeamNameContractNumber(team) + "	&lt;" + team.workCentre + "&gt;";
            }

            if(team.status === JMENUM.TeamStatus.CONTRACT_EXPIRED) {
              pmTeamHtml += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + JMLanguage.translate('pages.sn.tooltip.team-contract-expired') + "'></i>";
            }

            if(team.status === JMENUM.TeamStatus.CONTRACT_INEFFECTIVE) {
              pmTeamHtml += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + JMLanguage.translate('pages.sn.tooltip.team-contract-ineffective') + "'></i>";
            }

            pmTeamHtml += "</td></tr>";
          })
        }
        pmTeamHtml = "<table class='routing-rule-team-table'>"+pmTeamHtml+"</table>";

        let buttonArray = [{ "id": "add", "name": "", "class": "glyph brand-amber", "icon": "fas fa-edit", "onClicked": this.onEditRuleClicked, "object": rule}];
        if(this.hasEditPermission) {
          buttonArray.push({ "id": "delete", "name": "", "class": "glyph brand-red", "icon": "fas fa-times", "onClicked": this.onDeleteRuleClicked, "object": rule});
        }

        return [
          index,
          // group.equipmentTags,
          rule.equipmentTags.map(tag => {
            const showEquipmentTag = tag.equipmentSource && tag.equipmentSource === JMENUM.EquipmentSource.CCS;
            const isUsableTag = this.equipmentTagService.isUsableTag(tag, Session.selectedWorkCentre);

            const tagDescription = this.equipmentTagService.getTagDescription(tag, showEquipmentTag);
            const tagClass = isUsableTag ? 'tag-blue' : 'tag-red';

            return {
              name: tagDescription,
              class: tagClass,
              // onDragStart: this.onDragStart,
              // onDragEnd: this.onDragEnd,
            };
          }),
          nonUrgentTeamHtml,
          urgentTeamHtml,
          emergencyTeamHtml,
          pmTeamHtml,
          [updatedAt, updatedBy],
          buttonArray
        ];
      });
    }
  }

  private requestRefreshAll() {
    let request = new JM.JMRequestRoutingRulesUpdateRoutingRule();
    request.workCentre = this.selectedWorkCenter;
    JM.JMConnector.sendRoutingRulesUpdateRoutingRules(request, (error: JM.JMNetworkError, response: JM.JMResponseRoutingRulesUpdateRoutingRule) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      try {
        this.isRefreshAllButtonLoading = false;
        AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
      } catch (e) {
        AppDelegate.openErrorBar();
        this.isRefreshAllButtonLoading = false;
      }
    });
  }

  private requestExportRoutingRuleSummary() {
    let request:JM.JMRequestRoutingRulesExportRoutingRuleSummary = new JM.JMRequestRoutingRulesExportRoutingRuleSummary();
    request.workCentre = this.selectedWorkCenter;

    JM.JMConnector.sendRoutingRulesExportRoutingRuleSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseRoutingRulesExportRoutingRuleSummary) => {
      if (error) {
        this.handleJMError(error);
        this.isExportExcelButtonLoading = false;
        return;
      }
      try {
        let fileName = "RoutingRules_" + formatDate(new Date(), "yyyy-MM-dd", 'en-US') + ".xlsx";
        let base64 = response.payload.base64;
        let blob = utility.base64ToBlob(base64, response.payload.type);
        saveAs(blob, fileName);
        this.isExportExcelButtonLoading = false;
      } catch(e) {
        AppDelegate.openErrorBar();
        this.isExportExcelButtonLoading = false;
      }
    });
  }

  private requestRoutingRuleSummary() {
    let request = new JM.JMRequestRoutingRulesRoutingRuleSummary();
    request.workCentres = [this.selectedWorkCenter];
    request.parameters = [
      "_id",
      "equipmentTags",
      "teams",
      "updatedAt",
      "updatedBy",
      "workCentre",
    ];
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    JM.JMConnector.sendRoutingRulesRoutingRuleSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseRoutingRulesRoutingRuleSummary) => {
      if (error) {
        this.routingRuleGroups = null;
        this.renderTable();
        this.openErrorBar(error);
        return;
      }
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      this.routingRuleGroups = response.payload;
      this.renderTable();

      if(history.state.scrollPosition) {
        setTimeout(() => {
          window.scrollTo(0, history.state.scrollPosition);
          history.state.scrollPosition = 0;
        }, 100);
      }
    });
  }
  
  
  private requestDeleteRoutingRule(idList){
    let request = new JM.JMRequestRoutingRulesDeleteRoutingRule();
    request.idList = idList;
    JM.JMConnector.sendRoutingRulesDeleteRoutingRules(request, (error: JM.JMNetworkError, response: JM.JMResponseRoutingRulesRoutingRuleSummary) => {
      if (error) {
        this.tablexParam['isLoadingTable'] = false;
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
      
      this.requestRoutingRuleSummary();
      this.tablexParam['isLoadingTable'] = false;
    });
  }

  //-----------------------------------------------------------------------------
  // button call back function
  // header buttons
  onAddRoutingRuleClicked = () => {
    AppDelegate.navigate(["/setting/routing-rules"], {state: {'_id': null}}); 
  }

  onRefreshAllClick = () => {
    let message = JMLanguage.translate("pages.routing-rules-list.refresh-all-warning");
    let handler = () => {
      this.isRefreshAllButtonLoading = true;
      this.requestRefreshAll(); 
    }
    let buttons = [
      {
        name: JMLanguage.translate("pages.routing-rules-list.refresh-all-warning-confirm"),
        handler: handler
      },
      {
        name: JMLanguage.translate("pages.routing-rules-list.refresh-all-warning-no")
      }
    ]
    AppDelegate.showPopUpAlert(message, "", buttons);
  }
  
  onExportExcelClick = () => {
    this.isExportExcelButtonLoading = true;
    this.requestExportRoutingRuleSummary();
  }

  onEditRuleClicked = (button) => {
    let routingRule = button.object;
    // this.router.navigate(["/setting/routing-rules"], {state: {'_id': routingRule._id, pageNumber: this.currentPage, pageSize: this.currentPageSize, scrollPosition: window.pageYOffset || document.documentElement.scrollTop}}); 
    AppDelegate.navigate(["/setting/routing-rules"], {state: {'_id': routingRule._id, pageNumber: this.currentPage, pageSize: this.currentPageSize, scrollPosition: window.pageYOffset || document.documentElement.scrollTop}}); 
  }

  onDeleteRuleClicked = (button) => {
    let routingRule = button.object;
    let message = JMLanguage.translate("pages.routing-rules-list.confirm-delete-rule");
    let handler = () => { this.requestDeleteRoutingRule([routingRule._id]); }
    let buttons = [
      {
        name: JMLanguage.translate("global.yes"),
        handler: handler
      },
      {
        name: JMLanguage.translate("global.no")
      }
    ]
    AppDelegate.showPopUpAlert(message, "", buttons);
  }

  // // drag event
  // // button event
  // private onDragStart(tag) {
  //   //console.log("onDragStart: "+ tag);
  // }

  // private onDragEnd(tag) {
  //   //console.log("onDragEnd: "+ tag);
  // }

  // page function
  onPageNumberClicked = (pageNumber) => {
    this.currentPage = pageNumber;
    this.requestRoutingRuleSummary();
  }

  onPageSizeClicked = (pageSize) => {
    this.currentPage = 1
    this.currentPageSize = pageSize;
    this.requestRoutingRuleSummary();
  }

  // TODO: reload language
  onLanguageChanged() {
    // this.requestRoutingRuleGroup();
  }
}
