<div class="wrapper">
    <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

    <app-sidebar [type]="'workCenter'"></app-sidebar>

    <!-- Page Content -->
    <div id="content">

        <!--Table-->
        <app-tablex id='officer_table' [tablexParam]='tablexParam' class='mt-3'></app-tablex>


    </div>
</div>