<div [ngClass]="{'edit-mode': pageMode !== JMPageMode.VIEW}">
  <div class="font-size-xl m-0 mb-2">
    {{ "component.plan-reminders.title" | translate}}
  </div>
  
  <hr class="underline" />
  <div class="row mx-0 col-12 col-xl-10 reminder-item p-3 mb-2 justify-content-between align-items-center">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="to-be-overdue-reminder_checkbox" [(ngModel)]="toBeOverhaulActiveStatus" [disabled]="pageMode === JMPageMode.VIEW" 
      (ngModelChange)="toggleActiveStatus()">
      <label class="custom-control-label" for="to-be-overdue-reminder_checkbox">
      </label>
      <div class="d-inline-block px-0 ml-15">{{ "component.plan-reminders.to-be-overdue-reminder" | translate }}</div>
    </div>
    <div class="d-inline-flex">
      <div class="mx-4">
        {{ componentPlanRemindersIn }}
        <input #toBeOverhaulDaysEle type="number" class="days-input form-control d-inline px-1 disable-gray-text" *ngIf="pageMode !== JMPageMode.VIEW"
          [(ngModel)]="toBeOverhaulDays" min="0" [class.input-error]="errorFields.toBeOverhaulDays"
          (ngModelChange)="onChangeIntervalAndCheckMin('toBeOverhaulDays', toBeOverhaulDaysEle, 0)" [disabled]="!toBeOverhaulActiveStatus"/>
        <span id="to-be-overhaul-days" *ngIf="plan?.toBeOverdueReminder && (pageMode === JMPageMode.VIEW)" class="px-2">
          {{ plan.toBeOverdueReminder.toBeDueDay }}
        </span>
        {{ "component.plan-reminders.days-to-overdue" | translate }}
      </div>
      <div>
        {{ "component.plan-reminders.send-every" | translate }}
        <input #toBeOverhaulIntervalEle type="number" class="days-input form-control d-inline px-1 disable-gray-text" *ngIf="pageMode !== JMPageMode.VIEW"
          [(ngModel)]="toBeOverhaulInterval" min="0" [class.input-error]="errorFields.toBeOverhaulInterval"
          (ngModelChange)="onChangeIntervalAndCheckMin('toBeOverhaulInterval', toBeOverhaulIntervalEle, 1)" [disabled]="!toBeOverhaulActiveStatus"/>
        <span id="to-be-overdue-interval" *ngIf="plan?.toBeOverdueReminder && (pageMode === JMPageMode.VIEW)"
          class="px-2">
          {{ plan.toBeOverdueReminder.interval }}
        </span>
        {{ "component.plan-reminders.days" | translate }}
      </div>
    </div>
  </div>
  
  <div class="row mx-0 col-12 col-xl-10 reminder-item p-3 justify-content-between align-items-center">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="overdue-period-reminder_checkbox"  [(ngModel)]="overdueActiveStatus" [disabled]="pageMode === JMPageMode.VIEW"
      (ngModelChange)="toggleActiveStatus()">
      <label class="custom-control-label" for="overdue-period-reminder_checkbox">
      </label>
      <div class="d-inline-block px-0 ml-15">{{ "component.plan-reminders.overdue-period-reminder" | translate }}</div>
    </div>
    <div class="d-inline-flex">
      <div>
        {{ "component.plan-reminders.send-every" | translate }}
        <input #overdueIntervalEle type="number" class="days-input form-control d-inline px-1 disable-gray-text" *ngIf="pageMode !== JMPageMode.VIEW"
          [(ngModel)]="overdueInterval" min="0" [class.input-error]="errorFields.overdueInterval"
          (ngModelChange)="onChangeIntervalAndCheckMin('overdueInterval', overdueIntervalEle, 1)"  [disabled]="!overdueActiveStatus"/>
        <span id="overdue-interval" *ngIf="plan?.overduePeriodReminder && (pageMode === JMPageMode.VIEW)" class="px-2">
          {{ plan.overduePeriodReminder.interval }}
        </span>
        {{ "component.plan-reminders.days" | translate }}
      </div>
    </div>
  </div>
  
  <div class="row mx-0 align-items-center ">
    <div class="col-4 col-sm-2 text-left text-sm-right  gray-title m-0 mt-4 mb-4 px-0 pl-1">
      <span class="mandatory-mark" *ngIf="plan && plan.workCentre && (overdueActiveStatus || toBeOverhaulActiveStatus)">*</span>
      {{ "component.plan-reminders.recipients" | translate }}
    </div>
    <div *ngIf="!editable?.reminderRecipients" class="col-8 col-sm-10 text-left font-size-s m-0 mt-4 mb-4 overflow-auto">
      <app-display-text-field id="plan-reminders-recipients" [textField]="plan?.reminderRecipients">
      </app-display-text-field>
    </div>
    <div *ngIf="editable?.reminderRecipients" class="col-8 col-sm-4 col-8 text-left m-0 ">
      <ng-select id="edit-plan-recipients" [multiple]="true" [items]="recipientsOptions" bindLabel="label" [class.input-error]="errorFields?.reminderRecipients"
        bindValue="value" [loading]="isLoadingPost" [disabled]="isDisabledRecipients || (!overdueActiveStatus && !toBeOverhaulActiveStatus)" (scrollToEnd)="onPostScrollToEnd()"
        (search)="onSearchPost($event)" [(ngModel)]="plan.reminderRecipients" >
      </ng-select>
    </div>
  </div>
</div>