<div id="breadcrumb" class="breadcrumbs-wrap">
    <ng-container *ngFor="let item of breadcrumbs; let i = index">
        <ng-container *ngIf="item">
            <ng-container *ngIf="!item.currentPage">
                <div class="m-0 p-0 d-inline" id="{{ item.id }}">
                    <a [routerLink]="item.route" class="breadcrumbs-link">{{ item.name }}</a>
                </div>
            </ng-container>
            <ng-container *ngIf="item.currentPage">
                <div class="m-0 p-0 px-1 pb-2 d-inline current-breadcrumbs" id="{{ item.id }}">
                    {{ item.name }}
                </div>
            </ng-container>
            <span *ngIf="breadcrumbs.length > 1 && i+1 != breadcrumbs.length"> > </span>
        </ng-container>
    </ng-container>
</div>