<div class="wrapper">
    <app-nav [navbarTitle]="'pages.flms.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>

    <app-sidebar [type]="'functionalLocation'"></app-sidebar>

    <ng-container *ngIf="actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
            (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <div id="content" class="flms-pending-main-content">
        <!-- tablex -->
        <app-tablex id="flms-pending-main-table" [tablexParam]='tablexParam'></app-tablex>
    </div>

    <!-- slider panel for edit -->
    <custom-slider-panel #editFunctionalLocationMainPanel [customClass]="'add_dummy_contract_panel'" [widthPx]=630>
        <app-edit-functional-location-panel #editFunctionalLocationMainForm 
            (onSubmit)="onLocationSave($event)" [editMode]="editMode" [locationParam]="selectedLocation" ></app-edit-functional-location-panel>
            <!-- [selectedCount]="selectedCount" [unTecoParam]="unTecoParam" -->
    </custom-slider-panel>
</div>