import { formatDate } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { PostApprovalRequestSummaryRequest } from '@api/model/approval/post-approval-request-summary-request';
import { PostGetTeamsRequest } from '@api/model/contact-group/post-get-teams-request';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { ApprovalService } from '@services/approval.service';
import { ContactGroupService } from '@services/contact-group.service';
import { DelegationRequest } from 'src/app/entity/data-model/delegation-request';
import { Session } from 'src/app/services/session';
import { BasePage } from 'src/app/ui/model/base/base';
import { JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent extends BasePage implements OnInit {

  pageTitle: string;

  hasEditPermission     = false;    

  dateForm              = "dd/MM/yyyy";
  selectedWorkCentre    = Session.selectedWorkCentre;
  approvalTeam          : any;
  approvalWorkCentre    : any;
  requestTypes          = [{ label: "", value: "team" }];
  // ===========================================================================
  // table params

  requestCurrentType              = this.requestTypes[0].value;
  approvalItemsCurrentPageSize    = 10;
  approvalItemsCurrentPage        = 1;
  approvalItemsTotalRequestCount  = 0;
  approvalItemsTablexParam        : {};
  approvalItems                   : DelegationRequest[] = [];

  pendingItemsCurrentPageSize     = 10;
  pendingItemsCurrentPage         = 1;
  pendingItemsTotalRequestCount   = 0;
  pendingItemsTablexParam         : {};
  pendingItems                    : DelegationRequest[] = [];

  constructor(
    injector: Injector,
    private contactGroupService: ContactGroupService,
    private approvalService: ApprovalService
  ) {
    super(injector)
  }

  // ===========================================================================
  // view life cycle functions
  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.authorization.page-title", [Session.selectedWorkCentre]);
    this.initPermission();
    this.initApprovalItemsTable();
    this.initPendingItemsTable();
    this.requestApprovedItems();
    this.requestPendingItems();
    this.onLangChange(); 
  }

  onLangChange(){
    this.requestTypes = [
      { label: this.translate("pages.authorization.type-team"), value: "team" }
    ];
  }

  // ===========================================================================
  // permission function
  initPermission(){
    this.checkViewPermission(JMENUM.Permission.WCM_VIEW);
    this.hasEditPermission = this.authorizationService.hasPermission(JMENUM.Permission.WCM_UPDATE);
  }


  // ===========================================================================
  // api function
  requestApprovedItems() {
    let request                   = {} as PostApprovalRequestSummaryRequest;
    request.pageSize              = 10;
    request.type                  = this.requestCurrentType;
    request.requestWorkCentres    = [this.selectedWorkCentre];
    request.status                = [3];
    request.pageSize              = this.approvalItemsCurrentPageSize;
    request.pageNumber            = this.approvalItemsCurrentPage;
    request.sortOrder             = -1;
    request.sortBy                = "createdAt";

    this.approvalItemsTablexParam["isLoadingTable"] = true;
    this.apiHandler(this.approvalService.getRequestSummary(request),
      result => {

        this.approvalItemsTablexParam["isLoadingTable"] = false;
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }

        this.approvalItemsTotalRequestCount   = result.payload.totalCount;
        this.approvalItems                    = result.payload.records;
        let approvalItems                     = result.payload.records
          .filter(item => item.payload && item.payload.teamId);
        
        if (approvalItems.length == 0) {
          this.approvalItemsTablexParam["content"] = [];
          return;
        } 

        let request       = {} as PostGetTeamsRequest;
        request.teamIds   = approvalItems.map(approval => approval.payload.teamId);
        this.apiHandler(this.contactGroupService.getTeams(request),
          result => {

            this.approvalItemsTablexParam["isLoadingTable"] = false;
            if (!result || !result.code || result.code != 200) {
              this.openErrorBar(result);
              return;
            }

            let teams         = result.payload
            let teamNameMap   = {};
            teams.forEach(team => teamNameMap[team._id] = team.name);
            approvalItems.forEach(item => item["teamName"] = teamNameMap[item.payload.teamId]);

            this.renderApprovalItemsTable();

          }, error => {
            this.approvalItemsTablexParam["content"] = [];
            this.approvalItemsTablexParam["isLoadingTable"] = false;
          })
      }, error => {
        this.approvalItemsTablexParam["content"] = [];
        this.approvalItemsTablexParam["isLoadingTable"] = false;
      })
  }

  requestPendingItems() {
    let request                   = {} as PostApprovalRequestSummaryRequest;
    request.pageSize              = 10;
    request.type                  = this.requestCurrentType;
    request.requestWorkCentres    = [this.selectedWorkCentre];
    request.status                = [1];
    request.pageSize              = this.pendingItemsCurrentPageSize;
    request.pageNumber            = this.pendingItemsCurrentPage;
    request.sortOrder             = -1;
    request.sortBy                = "createdAt";

    this.pendingItemsTablexParam["isLoadingTable"] = true;
    this.apiHandler(this.approvalService.getRequestSummary(request),
      result => {

        this.pendingItemsTablexParam["isLoadingTable"] = false;
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }

        this.pendingItemsTotalRequestCount    = result.payload.totalCount;
        this.pendingItems                     = result.payload.records;
        let pendingItems                      = result.payload.records
          .filter(item => item.payload && item.payload.teamId);
        
        if (pendingItems.length == 0) {
          this.pendingItemsTablexParam["content"] = [];
          return;
        }

        let request = {} as PostGetTeamsRequest;
        request.teamIds = pendingItems.map(pending => pending.payload.teamId);
        this.apiHandler(this.contactGroupService.getTeams(request),
          result => {

            this.pendingItemsTablexParam["isLoadingTable"] = false;
            if (!result || !result.code || result.code != 200) {
              this.openErrorBar(result);
              return;
            }

            let teams         = result.payload
            let teamNameMap   = {};
            teams.forEach(team => teamNameMap[team._id] = team.name);
            pendingItems.forEach(item => item["teamName"] = teamNameMap[item.payload.teamId]);

            this.renderPendingItemsTable();

          }, error => {
            this.pendingItemsTablexParam["content"] = [];
            this.pendingItemsTablexParam["isLoadingTable"] = false;
          })
      }, error => {
        this.pendingItemsTablexParam["content"] = [];
        this.pendingItemsTablexParam["isLoadingTable"] = false;
      })
  }

  // ==================================================================
  // init table function
  initApprovalItemsTable() {
    this.approvalItemsTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.approvalItemsCurrentPageSize,
      currentPage           : this.approvalItemsCurrentPage,
      pageCount             : this.approvalItemsTotalRequestCount,
      onPageNumberClicked   : this.onApprovalItemsTablePageNumberClicked,
      onPageSizeClicked     : this.onApprovalItemsTablePageSizeClicked,
      headers: [
        {
          name: "pages.authorization.id",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.description",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.start-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.end-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.approved-by",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.approved-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },

      ],
      content: []
    }
  }

  initPendingItemsTable() {
    this.pendingItemsTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.pendingItemsCurrentPageSize,
      currentPage           : this.pendingItemsCurrentPage,
      pageCount             : this.pendingItemsTotalRequestCount,
      onPageNumberClicked   : this.onPendingItemsTablePageNumberClicked,
      onPageSizeClicked     : this.onPendingItemsTablePageSizeClicked,
      headers: [
        {
          name: "pages.authorization.id",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.description",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.start-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.end-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.request-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },

      ],
      content: []
    }
  }

  getRequestDescription(request: DelegationRequest): string {
    let description = '';
    switch (request.type) {
      case "team":
        description = this.getTeamRequestDescription(request);
        break;
      case "post":
        description = this.getPostRequestDescription(request);
        break;
      default:
        break;
    }
    return description;
  }

  getTeamRequestDescription(request: DelegationRequest): string {
    let description = this.translate("pages.approval.no-exist-team-message");

    if (request["teamName"]) {
      description = request["teamName"];
      
      if (request.approvalWorkCentres.length > 0) {
        var approvalWorkCentres = request.approvalWorkCentres.join(', ');
        description = `${description} (${approvalWorkCentres})`;
      }
    }

    return description;
  }
  
  getPostRequestDescription(request: DelegationRequest): string {
    let description = '';
    // TODO: 
    return description;
  }

  // ===========================================================================
  // view functions
  private renderApprovalItemsTable() {
    this.approvalItemsTablexParam['pageCount'] = Math.ceil(this.approvalItemsTotalRequestCount / this.approvalItemsCurrentPageSize);
    this.approvalItemsTablexParam["content"] = this.approvalItems.map(data => {
      let description = this.getRequestDescription(data);
      return [
            data.id,
            description,
            this.getDateStr(data.from),
            this.getDateStr(data.to),
            data.approvedBy,
            this.getDateStr(data.updatedAt),
          ];
    })
  }

  private renderPendingItemsTable() {
    this.pendingItemsTablexParam['pageCount'] = Math.ceil(this.pendingItemsTotalRequestCount / this.pendingItemsCurrentPageSize);
    this.pendingItemsTablexParam["content"] = this.pendingItems.map(data => {
      let description = this.getRequestDescription(data);
      return [
            data.id,
            description,
            this.getDateStr(data.from),
            this.getDateStr(data.to),
            this.getDateStr(data.createdAt)
          ];
    })
  }

  // ===========================================================================
  // button callback functions

  onRequestTypeChanged = () => {
    this.requestApprovedItems();
    this.requestPendingItems();
  }

  onAddedNewRequest = () => {
    this.requestApprovedItems();
    this.requestPendingItems();
  }

  onApprovalItemsTablePageNumberClicked = (pageIndex: number) => {
    this.approvalItemsCurrentPage = pageIndex;
    this.requestApprovedItems();
  }

  onApprovalItemsTablePageSizeClicked = (pageSize) => {
    this.approvalItemsCurrentPage       = 1;
    this.approvalItemsCurrentPageSize   = parseInt(pageSize);
    this.requestApprovedItems();
  }

  onPendingItemsTablePageNumberClicked = (pageIndex: number) => {
    this.pendingItemsCurrentPage = pageIndex;
    this.requestPendingItems();
  }

  onPendingItemsTablePageSizeClicked = (pageSize) => {
    this.pendingItemsCurrentPage        = 1;
    this.pendingItemsCurrentPageSize    = parseInt(pageSize);
    this.requestPendingItems();
  }

  // ===========================================================================
  // functions
  private getDateStr(date: Date): string {
    if (date) {
      return formatDate(date, this.dateForm, 'en-US')
    } 
    
    return '';
  }

}
