<h2 class="title">{{'component.sn-batch-complete.stage-header.title' | translate}}
  <a *ngIf="uploadHistoryAccess.permission && isSelectedDownloadTemplate" routerLink="{{uploadHistoryAccess.routing}}"><i
      class="fa fa-history blue"></i></a>
</h2>
<div class="d-flex flex-row align-items-center my-4">
  <!-- TODO: update layout -->
  <div class="step" [class.selected]="isSelectedDownloadTemplate">
    {{'component.sn-batch-complete.stage-header.download-template' | translate}}
  </div>
  <div class="fs-2 arrow-icon">
    <i class="fas fa-arrow-right"></i>
  </div>
  <div class="step" [class.selected]="isSelectedUpload">
    {{'component.sn-batch-complete.stage-header.upload-template' | translate}}
  </div>
  <div class="fs-2 arrow-icon">
    <i class="fas fa-arrow-right"></i>
  </div>
  <div class="step" [class.selected]="isSelectedResult">
    {{'component.sn-batch-complete.stage-header.result' | translate}}
  </div>
</div>
<hr class="underline">