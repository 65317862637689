export enum AttachmentFileType {
    Image = "Image",
    Pdf = "PDF",
    Text = "Text",
    Other = "Other"
}

export enum AttachmentMimeType {
    Json = "application/json",
    Jpeg = "image/jpeg",
    Png = "image/png",
    Pdf = "application/pdf",
    Txt = "text/plain",
    Doc = "application/msword",
    Docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    Xls = "application/vnd.ms-excel",
    Xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    Ppt = "application/vnd.ms-powerpoint",
    Pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    Zip = "application/zip",
    Svg = "image/svg+xml",
    SevenZip = "application/x-7z-compressed"
}
