import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timesheet-batch',
  templateUrl: './timesheet-batch.component.html',
  styleUrls: ['./timesheet-batch.component.scss']
})
export class TimesheetBatchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
