import { Component, Injector, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { BasePage } from '../../model/base/base';
@Component({
  selector: 'reject-sn-form',
  templateUrl: './reject-sn-form.component.html',
  styleUrls: ['./reject-sn-form.component.scss']
})

export class RejectSnFormComponent extends BasePage implements OnInit {
  @Input() options: any[];
  @Input() isLoading: boolean;
  @Output() onSubmit = new EventEmitter<any>();
  reason: any;
  confirmed: boolean;
  remark: string;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngOnChanges() {
  }

  onClickSubmit() {
    if (this.remark) this.remark = this.remark.trim();
    this.onSubmit.emit({rejectCode: this.reason, rejectRemarks: this.remark});
  }

  get getMaxRemarksLength():number{
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }

}
