import { ActionButtonJobCard } from '@enum/action-button';
import { JMENUM } from '@ccep/CCEPConnector-ts';

export enum JobCardDisplayMode {
    edit = 0, 
    view
};

//===================================================================================================================
// Display Action Buttons in different job card status
let view = JMENUM.JMPageMode.VIEW;
let edit = JMENUM.JMPageMode.EDIT;

export const JobCardActionButtions = {
  [JMENUM.JobNature.CM] : {
    [JMENUM.JobCardStatus.ASSIGNED] : {
      [view]: [
        ActionButtonJobCard.accept,
        ActionButtonJobCard.reject,
        ActionButtonJobCard.edit,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close
      ]
    },
    [JMENUM.JobCardStatus.REJECTED] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close
      ]
    },
    [JMENUM.JobCardStatus.IN_PROGRESS] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.reserveStock,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.attach,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
        ActionButtonJobCard.inspect,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    },
    [JMENUM.JobCardStatus.CANCELLING] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.attach,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    },
    [JMENUM.JobCardStatus.COMPLETED] : {
      [view]: [
        ActionButtonJobCard.inspect,
        ActionButtonJobCard.reserveStock,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.jobReopen,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
        ActionButtonJobCard.teco,
        ActionButtonJobCard.withDraw,
        ActionButtonJobCard.unTeco,
      ]
    },
    [JMENUM.JobCardStatus.CANCELLED] : {
      [view]: [
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ]
    },
    [JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION] : {
      [view]: [
        ActionButtonJobCard.approve,
        ActionButtonJobCard.rejectCompletion,
        ActionButtonJobCard.inspect,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ]
    },
    [JMENUM.JobCardStatus.REWORKING] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.reserveStock,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.attach,
        ActionButtonJobCard.inspect,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    }
  },
  [JMENUM.JobNature.PM] : {
    [JMENUM.JobCardStatus.ASSIGNED] : {
      [view]: [
        ActionButtonJobCard.accept,
        ActionButtonJobCard.reject,
        ActionButtonJobCard.edit,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close
      ]
    },
    [JMENUM.JobCardStatus.REJECTED] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close
      ]
    },
    [JMENUM.JobCardStatus.IN_PROGRESS] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.reserveStock,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.attach,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    },
    [JMENUM.JobCardStatus.CANCELLING] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.attach,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    },
    [JMENUM.JobCardStatus.COMPLETED] : {
      [view]: [
        ActionButtonJobCard.inspect,
        ActionButtonJobCard.reserveStock,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.jobReopen,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
        ActionButtonJobCard.teco,
        ActionButtonJobCard.withDraw,
        ActionButtonJobCard.unTeco,
      ]
    },
    [JMENUM.JobCardStatus.CANCELLED] : {
      [view]: [
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ]
    },
    [JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION] : {
      [view]: [
        ActionButtonJobCard.approve,
        ActionButtonJobCard.rejectCompletion,
        ActionButtonJobCard.inspect,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ]
    },
    [JMENUM.JobCardStatus.REWORKING] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.reserveStock,
        ActionButtonJobCard.createDossPo,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.attach,
        ActionButtonJobCard.inspect,
        ActionButtonJobCard.eFax,
        ActionButtonJobCard.exportPdf,
        ActionButtonJobCard.exportHaPdf,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    },
  },
  [JMENUM.JobNature.ENQUIRY]: {
    [JMENUM.JobCardStatus.ASSIGNED] : {
      [view]: [
        ActionButtonJobCard.accept,
        ActionButtonJobCard.reject,
        ActionButtonJobCard.reassign
      ],
    },
    [JMENUM.JobCardStatus.REJECTED] : {
      [view]: [
        ActionButtonJobCard.reassign,
      ],
    },
    [JMENUM.JobCardStatus.IN_PROGRESS] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.reassign
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    },
    [JMENUM.JobCardStatus.CANCELLING] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    },
    [JMENUM.JobCardStatus.REWORKING] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.reassign
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    }
  },
  [JMENUM.JobNature.STANDALONE] : {
    [JMENUM.JobCardStatus.IN_PROGRESS] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.cancel,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.attach,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    },
    [JMENUM.JobCardStatus.COMPLETED] : {
      [view]: [
        ActionButtonJobCard.attach,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
        ActionButtonJobCard.jobReopen,
        ActionButtonJobCard.teco,
        ActionButtonJobCard.unTeco,
      ]
    },
    [JMENUM.JobCardStatus.CANCELLED] : {
      [view]: [
      ]
    },
    [JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION] : {
      [view]: [
        ActionButtonJobCard.approve,
        ActionButtonJobCard.rejectCompletion,
      ]
    },
    [JMENUM.JobCardStatus.REWORKING] : {
      [view]: [
        ActionButtonJobCard.edit,
        ActionButtonJobCard.reassign,
        ActionButtonJobCard.jobComplete,
        ActionButtonJobCard.cancel,
        ActionButtonJobCard.generateCcsSo,
        ActionButtonJobCard.attach,
        ActionButtonJobCard.timeSheet,
        ActionButtonJobCard.timeSheetV2,
      ],
      [edit]: [
        ActionButtonJobCard.save,
        ActionButtonJobCard.close,
      ]
    }
  },
};

export interface CcsSoSubmitFormReturnParam {
  ccsSoOption: 'assign' | 'obtainByEquipment' | 'obtainByLocation', // refer ccsSoOption in ccs-so-submit-form.component.ts
  data: {
    ccsServiceOrderNumber      : string | null,
    equipment                  : string | null,
    serviceLevelAgreementNumber: string | null,
    serviceLevelAgreementItem  : string | null,
    functionalLocation         : string | null
  }
}