enum ReleaseNotePlatform {
  web = 'web',
  android = 'android',
  ios = 'ios',
}

interface ReleaseNoteViewData {
  version: string;
  enMessage: string;
  zhMessage: string;
  latestVersion?: string;
  minVersion?: string;
}
interface ReleaseNoteViewErrorStatus {
  version: boolean;
  enMessage: boolean;
  zhMessage: boolean;
  latestVersion?: boolean;
  minVersion?: boolean;
}

export {
  ReleaseNotePlatform,
  ReleaseNoteViewData,
  ReleaseNoteViewErrorStatus,
}
