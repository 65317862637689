export enum SnBatchCompleteStage {
  DOWNLOAD_TEMPLATE = 'download_template',
  UPLOAD = 'upload',
  VERIFICATION_SUCCESSFUL = 'verification_successful',
  VERIFICATION_FAILED = 'verification_failed',
  SUBMITTED = 'submitted'
}

export const AllowSwitchStage: { [key: string]: SnBatchCompleteStage[] } = {
  [SnBatchCompleteStage.DOWNLOAD_TEMPLATE]: [
    SnBatchCompleteStage.UPLOAD
  ],
  [SnBatchCompleteStage.UPLOAD]: [
    SnBatchCompleteStage.DOWNLOAD_TEMPLATE,
    SnBatchCompleteStage.VERIFICATION_SUCCESSFUL,
    SnBatchCompleteStage.VERIFICATION_FAILED
  ],
  [SnBatchCompleteStage.VERIFICATION_SUCCESSFUL]: [
    SnBatchCompleteStage.SUBMITTED
  ],
  [SnBatchCompleteStage.VERIFICATION_FAILED]: [
    SnBatchCompleteStage.DOWNLOAD_TEMPLATE,
    SnBatchCompleteStage.VERIFICATION_SUCCESSFUL,
  ],
  [SnBatchCompleteStage.SUBMITTED]: [],
}