import { Component, OnInit, ViewChild } from '@angular/core';
import { JmUserSettings } from '@enum/jm-user-settings.enum';
import { TablexColumnFilterOption, TablexColumnFilterType, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Session } from '@services/session';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/AppDelegate';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JobcardJobListAdvSearchComponent } from '../../components/jobcard-job-list-adv-search/jobcard-job-list-adv-search.component';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {
  @ViewChild(JobcardJobListAdvSearchComponent, { static: false }) jobCardSearchCriteriaEl : JobcardJobListAdvSearchComponent;

  language: string;

  // Table
  tablexFilter: any = {};
  tablexParam: {} = {};
  pageSizeOptions: any = [10, 25, 100];
  pageCount = 1;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];
  statusOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  matOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  jobTypeOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  handlingPartyOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  tecoStatusOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  locationOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  funcLocationOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  priorityOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  jobSummary: JMOBJ.JobCard[] = [];
  summaryPageSize: number = 10;
  summaryPageNumber: number = 1;

  snList = [];
  teamNameMap = new Map<string, string>();

  // location options
  locationTotalCount: number;
  locationPageNumber: number = 0;
  locationPageSize: number = 10;
  jobLocationList = [];
  private searchLocationObserver = new Subject<any[]>();
  searchLocationKeywords: string = undefined;

  // functionalLocation options
  funcLocationTotalCount: number;
  funcLocationPageNumber: number = 0;
  funcLocationPageSize: number = 10;
  jobFuncLocationList = [];
  private searchFuncLocationObserver = new Subject<any[]>();
  searchFuncLocationKeywords: string = undefined;

  constructor() { }

  ngOnInit() {
    JMUTILITY.hasPermissions(Session.userInfo, [JMENUM.Permission.JOBCARD_VIEW, JMENUM.Permission.JOBCARD_VIEW_ALL], false);
    this.language = Session.selectedLanguage;
    this.requestLocationSummary();
    this.requestFunctionalLocationSummary();
    this.initAllTableHeader();
    this.initFilterOptions();
    this.initSelectedCol();
    this.initTablex();

    this.searchLocationObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      ).subscribe(() => {
        this.searchLocation();
      }
    );

    this.searchFuncLocationObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      ).subscribe(() => {
        this.searchFunctionalLocation();
      }
    );  
  }

  // ----------- API ----------- //
  async requestUpdateUserSettings() {
    let userSettings = {};
    const request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[JmUserSettings.JOB_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
  }

  private async requestJobPlanList(advancedSearch?) {
    let request: JM.JMRequestJobCardsJobCardSummary = new JM.JMRequestJobCardsJobCardSummary(); // TODO!
    if(this.tablexFilter){
      request = this.renderRequestFilter(request);
    }
    if(advancedSearch){
      request = advancedSearch;
    }

    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];
    request.pageNumber = this.summaryPageNumber;
    request.pageSize = this.summaryPageSize;
    
    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseJobCardsJobCardSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    if (response.payload.records && response.payload.records.length > 0) {
      this.jobSummary = response.payload.records;
      this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.summaryPageSize);
      this.tablexParam['currentPage'] = request.pageNumber;
      const snNumberList = this.jobSummary.filter(job => job.snNumber).map(job => job.snNumber);
      const locationList = this.jobSummary.filter(job => job.location).map(job => job.location);
      const funcLocationList = this.jobSummary.filter(job => job.functionalLocation).map(job => job.functionalLocation);
      const dedupLocationList = Array.from(new Set(locationList.concat(funcLocationList)));
      this.requestLocationByJob(dedupLocationList);
      await this.requestSnSummary(snNumberList);
    } else {
      this.jobSummary = [];
      this.renderTable();
      this.tablexParam['pageCount'] = 0;
      this.tablexParam['currentPage'] = 0;
    }
    this.tablexParam['isLoadingTable'] = false;
  }

  private async requestJobSummary() {
    this.tablexParam['isLoadingTable'] = true;
    let request = new JM.JMRequestJobCardsJobCardSummary();
    request = this.renderRequestFilter(request);
    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];
    request.pageNumber = this.summaryPageNumber;
    request.pageSize = this.summaryPageSize;

    const response: JM.JMResponseJobCardsJobCardSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    if (response.payload.records && response.payload.records.length > 0) {
      this.jobSummary = response.payload.records;
      this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.summaryPageSize);

      const snNumberList = this.jobSummary.filter(job => job.snNumber).map(job => job.snNumber);
      const locationList = this.jobSummary.filter(job => job.location).map(job => job.location);
      const funcLocationList = this.jobSummary.filter(job => job.functionalLocation).map(job => job.functionalLocation);
      const dedupLocationList = Array.from(new Set(locationList.concat(funcLocationList)));

      this.requestLocationByJob(dedupLocationList);
      this.requestSnSummary(snNumberList);
    }else{
      this.jobSummary = [];
      this.renderTable();
    }
  }

  private async requestSnSummary(snNumberList) {
    const request = new JM.JMRequestSnSummary();
    request.snNumberList = snNumberList;

    const response: JM.JMResponseSnSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records && response.payload.records.length > 0) {
      this.snList = response.payload.records;
      this.renderTable();
    }
  }

  private async requestLocationSummary(){
    if (this.locationTotalCount !== null && 
      this.locationTotalCount <= this.locationPageNumber * this.locationPageSize) {
      return;
    }
    this.locationPageNumber++;

    const request = new JM.JMRequestLocationsLocationSummary();
    request.includeSummary = true;
    request.pageSize = this.locationPageSize;
    request.pageNumber = this.locationPageNumber;
    request.locationOnly = true;
    if (this.searchLocationKeywords) {
      request.locationDescription = this.searchLocationKeywords;
    }

    this.locationOptions.isLoading = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.locationOptions.isLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let options = this.generateLocationOptions(response);
    this.locationTotalCount = response.payload.totalCount;
    this.locationOptions.items = this.locationOptions.items.concat(options);
  }

  private async requestFunctionalLocationSummary(){
    if (this.funcLocationTotalCount !== null && 
      this.funcLocationTotalCount <= this.funcLocationPageNumber * this.funcLocationPageSize) {
      return;
    }
    this.funcLocationPageNumber++;

    const request = new JM.JMRequestLocationsLocationSummary();
    request.includeSummary = true;
    request.pageSize = this.funcLocationPageSize;
    request.pageNumber = this.funcLocationPageNumber;
    request.locationOnly = false; // include functionalLocation
    if (this.searchLocationKeywords) {
      request.locationDescription = this.searchLocationKeywords;
    }

    this.funcLocationOptions.isLoading = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.funcLocationOptions.isLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let options = this.generateLocationOptions(response);
    this.funcLocationTotalCount = response.payload.totalCount;
    this.funcLocationOptions.items = this.funcLocationOptions.items.concat(options);
  }

  private generateLocationOptions(response: JM.JMResponseLocationsLocationSummary) {
    return response.payload.records.map(location => {
      let description = location.description[Session.selectedLanguage] ? location.description[Session.selectedLanguage] : location.description["en"];
      let name = description + ' (' + location.code + ')';
      let obj = {
        value: location.code,
        label: name,
        descriptionEn: location.description["en"] ? location.description["en"] : "",
        descriptionZh: location.description["zh"] ? location.description["zh"] : ""
      };
      return obj;
    });
  }

  private async requestLocationByJob(locationList: string[]) {
    const request = new JM.JMRequestLocationsLocationSummary();
    request.parameters = ['code', 'description'];
    request.location = locationList;

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.jobLocationList = response.payload.records;
  }

  async requestTeamName(teamIdList: string[]) {
    const request = new JM.JMRequestTeamsTeamSummary();
    request.idList = teamIdList;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    response.payload.records.forEach(team => this.teamNameMap.set(team._id, team.name));
  }

  // ----------- UI function ----------- //
  initSelectedCol() {
    this.selectedColId = [
      "objId",
      "jobCardNumber",
      "jobDescription",
      "status",
      "handlingParty",
      "ccsServiceOrderNumber",
      "orderType",
      "matType",
      "client",
      "contract",
      "location",
      "officerInCharge",
      "updatedAt",
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[JmUserSettings.JOB_LIST_COLUMNS]) {
      Object.assign(this.selectedColId, cachedUserSettings[JmUserSettings.JOB_LIST_COLUMNS]);
    }

    this.onColFiltered(this.selectedColId);
  }

  renderRequestFilter(request){
    request.filter = {};
    
    for (let key in this.tablexFilter) {
      if(this.tablexFilter[key]){
        switch (key){
          case "status": 
            request.statusList = this.tablexFilter[key];
            break;
          case "handlingParty": 
            request.handlingPartyList = this.tablexFilter[key];
            break;
          case "priority": 
            request.priorityList = this.tablexFilter[key];
            break;
          case "tecoStatus": 
            request.tecoStatusList = this.tablexFilter[key];
            break;
          // case "createdAt": 
          //   request.filter['createdAt'] = moment(this.tablexFilter[key]).format('YYYYMMDD');
          //   break;
          // case "updatedAt": 
          //   request.filter['updatedAt'] = moment(this.tablexFilter[key]).format('YYYYMMDD');
          //   break;
          default : 
            request.filter[key] = this.tablexFilter[key];
            break;
        }
      }
    }
    return request;
  }

  searchLocation() {
    this.locationOptions.items = [];
    this.locationPageNumber = 0;
    this.locationTotalCount = null;
    this.requestLocationSummary();
  }

  searchFunctionalLocation() {
    this.funcLocationOptions.items = [];
    this.funcLocationPageNumber = 0;
    this.funcLocationTotalCount = null;
    this.requestFunctionalLocationSummary();
  }

  // ----------- Tablex UI function ----------- //
  onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();

    if (!Session.userInfo['userSettings'] || (Session.userInfo['userSettings'] && Session.userInfo.userSettings[JmUserSettings.JOB_LIST_COLUMNS] != this.selectedColId)) {
      this.requestUpdateUserSettings();
      let userSettings = {};
      userSettings[JmUserSettings.JOB_LIST_COLUMNS] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  }

  onPageSizeClicked = (pageSize: number) => {
    this.jobCardSearchCriteriaEl.fetchData(1, pageSize);
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.jobCardSearchCriteriaEl.fetchData(pageIndex, null);
  }

  onSortOrderChanged = (header, sortOrder) => {
    this.tablexParam['sortBy'] = header ? header : 'updatedAt';
    this.tablexParam['sortOrder'] = sortOrder;
    this.jobCardSearchCriteriaEl.fetchData();
  }

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if(!filter[key]){
        delete this.tablexFilter[key];
      }else{
        this.tablexFilter[key] = filter[key];
      }
    }

    this.jobCardSearchCriteriaEl.fetchData(1, null);
  }

  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }

    this.jobCardSearchCriteriaEl.fetchData(1, null);
  }

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.jobCardSearchCriteriaEl.fetchData(1, null);
  }

  // ------- location -------
  onSearchLocationOptions = (event) => {
    this.searchLocationKeywords = event.term;
    this.searchLocationObserver.next();
  }
  
  onLocationScrollToEnd = () =>{
    this.requestLocationSummary();
  }

  onFilterLocationOptions = (term: string, item: any) => {
    return item.value.toLowerCase().includes(term.toLowerCase()) || 
            item.descriptionEn.toLowerCase().includes(term.toLowerCase()) || 
            item.descriptionZh.toLowerCase().includes(term.toLowerCase());
  }

  // ------- functional location -------
  onSearchFunctionalLocationOptions = (event) => {
    this.searchFuncLocationKeywords = event.term;
    this.searchFuncLocationObserver.next();
  }
  
  onFunctionalLocationScrollToEnd = () =>{
    this.requestFunctionalLocationSummary();
  }

  onFilterFunctionalLocationOptions = (term: string, item: any) => {
    return item.value.toLowerCase().includes(term.toLowerCase()) || 
            item.descriptionEn.toLowerCase().includes(term.toLowerCase()) || 
            item.descriptionZh.toLowerCase().includes(term.toLowerCase());
  }

  initFilterOptions(){
    //JobCard Status
    this.statusOptions.items = [];
    for (const statusCode of JM.JMConnector.getAllJobCardStatus()) {
      this.statusOptions.items.push({
        label: JMLanguage.translate('jobcard.status.' + statusCode),
        value: statusCode
      });
    }
    this.statusOptions.bindLabel = "label";
    this.statusOptions.bindValue = "value";
    this.statusOptions.multiple = true;
    this.statusOptions.change = this.onOptionFilterChanged;

    //Handling Party
    this.handlingPartyOptions.items = [];
    let handlingPartyEnum = Object.values(JMENUM.HandlingParty);
    for (let i=handlingPartyEnum.length/2; i < handlingPartyEnum.length; i++) { 
      let value = handlingPartyEnum[i];
      this.handlingPartyOptions.items.push({ label: JMLanguage.translate("team.handling-party." + value), value: value });
    }
    this.handlingPartyOptions.bindLabel = "label";
    this.handlingPartyOptions.bindValue = "value";
    this.handlingPartyOptions.multiple = true;
    this.handlingPartyOptions.change = this.onOptionFilterChanged;

    //Job Type
    this.jobTypeOptions.items = [];
    let jobTypeEnum = Object.values(JMENUM.JobType);
    for (let i=0; i < jobTypeEnum.length; i++) {
      let value = jobTypeEnum[i];
      this.jobTypeOptions.items.push({ label: value, value: value });
    }
    this.jobTypeOptions.bindLabel = "label";
    this.jobTypeOptions.bindValue = "value";
    this.jobTypeOptions.change = this.onOptionFilterChanged;

    //MAT
    this.matOptions.items = [];
    let matEnum = Object.values(JMENUM.Mat);
    for (let i=0; i < matEnum.length; i++) {
      let value = matEnum[i];
      this.matOptions.items.push({ label: value, value: value });
    }
    this.matOptions.bindLabel = "label";
    this.matOptions.bindValue = "value";
    this.matOptions.change = this.onOptionFilterChanged;

    //Location
    this.locationOptions.items = [];
    this.locationOptions.bindLabel = "label";
    this.locationOptions.bindValue = "value";
    this.locationOptions.change = this.onOptionFilterChanged;
    this.locationOptions.onScrollToEnd = this.onLocationScrollToEnd;
    this.locationOptions.onSearch = this.onSearchLocationOptions;
    this.locationOptions.searchFn = this.onFilterLocationOptions;

    // Functional Location
    this.funcLocationOptions.items = [];
    this.funcLocationOptions.bindLabel = "label";
    this.funcLocationOptions.bindValue = "value";
    this.funcLocationOptions.change = this.onOptionFilterChanged;
    this.funcLocationOptions.onScrollToEnd = this.onFunctionalLocationScrollToEnd;
    this.funcLocationOptions.onSearch = this.onSearchFunctionalLocationOptions;
    this.funcLocationOptions.searchFn = this.onFilterFunctionalLocationOptions;

    // Priority
    this.priorityOptions.items = [];
    let priorityEnum = Object.values(JMENUM.JMPriority);
    for (let i=priorityEnum.length/2; i < priorityEnum.length; i++) {
      let value = priorityEnum[i];
      this.priorityOptions.items.push({ label: JMLanguage.translate("jobcard.priority." + value), value: value });
    }
    this.priorityOptions.bindLabel = "label";
    this.priorityOptions.bindValue = "value";
    this.priorityOptions.multiple = true;
    this.priorityOptions.change = this.onOptionFilterChanged;

    //TECO Status
    this.tecoStatusOptions.items = [];
    let tecoStatusEnum = Object.values(JMENUM.JobcardTecoStatus);
    for (let i=tecoStatusEnum.length/2; i < tecoStatusEnum.length; i++) {
      let value = tecoStatusEnum[i];
      this.tecoStatusOptions.items.push({ label: JMLanguage.translate("teco.status." + value), value: value });
    }
    this.tecoStatusOptions.bindLabel = "label";
    this.tecoStatusOptions.bindValue = "value";
    this.tecoStatusOptions.multiple = true;
    this.tecoStatusOptions.change = this.onOptionFilterChanged;

  }

  initAllTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: 'pages.job-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none'
      },
      {
        id: 'jobCardNumber',
        name: 'pages.job-list.table-column.job-number',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Hyperlink, 
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'jobDescription',
        name: 'pages.job-list.table-column.job-description',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'status',
        name: 'pages.job-list.table-column.status',
        enableFilter: false,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.statusOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'handlingParty',
        name: 'pages.job-list.table-column.handling-party',
        enableFilter: false,
        enableSort: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.handlingPartyOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'ccsServiceOrderNumber',
        name: 'pages.job-list.table-column.ccs-service-order-number',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'orderType',
        name: 'pages.job-list.table-column.job-type',
        enableFilter: false,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.jobTypeOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'matType',
        name: 'pages.job-list.table-column.mat-type',
        enableFilter: false,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.matOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'client',
        name: 'pages.job-list.table-column.client',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'contract',
        name: 'pages.job-list.table-column.contract',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start appraisal-status',
      },
      {
        id: 'location',
        name: 'pages.job-list.table-column.location',
        enableFilter: false,
        enableSort: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.locationOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'functionalLocation',
        name: 'pages.job-list.table-column.functional-location',
        enableFilter: false,
        enableSort: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.funcLocationOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'officerInCharge',
        name: 'pages.job-list.table-column.officer-in-charge',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'equipment',
        name: 'pages.job-list.table-column.equipment-id',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'priority',
        name: 'pages.job-list.table-column.priority',
        enableFilter: false,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.priorityOptions,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'handledBy',
        name: 'pages.job-list.table-column.handled-by',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'contactNumber',
        name: 'pages.job-list.table-column.contact-number',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'contactPerson',
        name: 'pages.job-list.table-column.contact-person',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'tecoStatus',
        name: 'pages.job-list.table-column.teco-status',
        enableFilter: false,
        enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.tecoStatusOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'updatedAt',
        name: 'pages.job-list.table-column.updated-at',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'createdAt',
        name: 'pages.job-list.table-column.created-at',
        enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      }
    ];
    this.selectedCol = this.allColHeaders;
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: false,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: true,
      sortOrder: -1,
      sortBy: 'updatedAt',
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'job-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.summaryPageSize,
      currentPage: this.summaryPageNumber,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
      customClass: 'expiry'
    };
  }

  async renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter(col => {
      return this.selectedColId.includes(col.id);
    });

    const teamIdList = this.jobSummary.filter(jobcard => {
      return !jobcard.snNumber && jobcard.snTeamId && !this.teamNameMap.has(jobcard.snTeamId)
    }).map(jobcard => jobcard.snTeamId);
    teamIdList.length > 0 && await this.requestTeamName(Array.from(new Set(teamIdList)));

    this.tablexParam['content'] = this.jobSummary.map((data, index) => {
      let sn = this.snList.find(sn => sn.snNumber == data.snNumber) || {};
      let location = data.location ? this.jobLocationList.find(loc => loc.code == data.location) : undefined;
      let functionalLocation = data.functionalLocation ? this.jobLocationList.find(loc => loc.code == data.location) : undefined;
      let jobCardLink = {
        url: '',
        value: ''
      }
      if (data.jobCardNumber) {
        jobCardLink.value = data.jobCardNumber;
        
        jobCardLink.url = `/job-card/view/${data.jobCardNumber}`;
        
      }

      let originRow = [
        data._id,
        jobCardLink,
        data.jobDescription? data.jobDescription : '',
        data.status? JMLanguage.translate('jobcard.status.' + data.status) : '',
        data.handlingParty? JMLanguage.translate('team.handling-party.' + data.handlingParty) : '',
        data.ccsServiceOrderNumber? data.ccsServiceOrderNumber : '',
        data.orderType? data.orderType : '',
        data.matType? data.matType : '',
        data.client? data.client : '',
        (sn && sn.team && sn.team.contractNumber) ? sn.team.contractNumber : '',
        location? location.description[this.language] ? location.description[this.language] : '' : '',
        functionalLocation? functionalLocation.description[this.language] ? functionalLocation.description[this.language] : '' : '',
        data.officerInCharge? data.officerInCharge : '',
        data.equipment? data.equipment : '',
        data.priority? JMLanguage.translate('jobcard.priority.' + data.priority) : '',
        (data.snTeamId && sn && sn.team && sn.team.name)? sn.team.name : this.getTeamNameFromMap(data.snTeamId),
        (sn && sn.contactNumber) ? sn.contactNumber : '',
        (sn && sn.contactPerson) ? sn.contactPerson : '',
        data.tecoStatus? JMLanguage.translate('teco.status.' + data.tecoStatus): '',
        data.updatedAt? moment(data['updatedAt']).format(Constants.DATETIME_FORMAT_2) : '',
        data.createdAt? moment(data['createdAt']).format(Constants.DATETIME_FORMAT_2) : '',
      ];
      let row = []
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
    this.tablexParam['isLoadingTable'] = false;
  }

  getTeamNameFromMap(teamId: string): string {
    const teamName = this.teamNameMap.get(teamId);
    return teamName || "";
  }

  // ------------tablex end ------------
  // ----------- UI function ----------- //
  onAdvancedSearch({advancedSearch, pageNumber, pageSize}){
    if(typeof pageNumber === 'number') {
      this.summaryPageNumber = pageNumber;
    }
    if(typeof pageSize === 'number') {
      this.summaryPageSize = pageSize;
    }
    this.requestJobPlanList(advancedSearch);
  }
}
