<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs" >
  </app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding">
    <div class="mb-4 d-flex justify-content-between">
      <span class="font-size-28">
        {{ 'pages.reserve-stock.page-title' | translate }}
      </span>

      <span *ngIf="requestReservationErrorMsg"
        class="reserve-error"
        [innerHtml]="requestReservationErrorMsg"
      ></span>
    </div>

    <ng-container *ngIf="!isSelectedStockTableEmpty">
      <div class="d-flex justify-content-between">
        <span class="font-size-xl">
          {{ 'pages.reserve-stock.selected-stock-table.title' | translate }}
        </span>
      </div>
      <hr class="underline" />
      <div class="tablexWrapper">
        <app-tablex id="selected-stock-list-table" [tablexParam]="selectedStockTablexParam"></app-tablex>
      </div>
    </ng-container>

    <div class="d-flex justify-content-between mt-4">
      <span class="font-size-xl">
        {{ 'pages.reserve-stock.stock-table.title' | translate }}
      </span>
    </div>
    <hr class="underline" />
    
    <app-tablex id="stock-list-table" [tablexParam]="stockTablexParam"></app-tablex>
  </div>

  <!-- Action Sidebar -->
  <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
    <app-action-sidebar
      [buttonData]="actionButtonData"
      [disabled]="disabledActionSideBar"
      (clickEvent)="actionBtnService.onActionButtonClick($event)">
    </app-action-sidebar>
  </ng-container>

  <custom-slider-panel #reserve_ai_store_slider [widthPx]=600 [fixedPanel]="false">
    <app-reserve-ai-store-panel #reserve_ai_store_panel
      [aiStoreObj]="jobStockReservation && jobStockReservation.aiStore"
      (onSubmit)="onAiStoreSubmit($event)"
    ></app-reserve-ai-store-panel>
  </custom-slider-panel>
</div>
