<div class="contract-form-wrap">

    <div class="title-wrap mx-0 mb-4">
        <h3 class="d-inline">{{ "pages.un-teco.slider-panel.title" | translate }}</h3>
        <div class="button-wrap text-right d-inline-block float-right">
            <app-submit-button [id]="'un-teco-submit-button'" [text]="'global.submit'"
                [buttonClass]="' standard brand-amber mr-0'" [iconClass]="'fa-check'"
                [isDisabled]="!unTecoParam.isAcknowledgeAction||unTecoParam.isPanelSubmitLoading" (onClick)="onSubmitButtonClicked($event)" [isLoading]="unTecoParam.isPanelSubmitLoading">
            </app-submit-button>
        </div>
    </div>


    <div class="mt-3">
        {{ 'pages.un-teco.slider-panel.selected-count' | translate }}{{selectedCount}}
    </div>

    <div class="mt-3">
        <span class="mandatory">*</span>{{ 'pages.un-teco.slider-panel.un-teco-reason' | translate }}
    </div>
    <ng-select id="un-teco-reason" name="un-teco-reason" class="align-middle mt-2" [(ngModel)]="unTecoParam.unTecoReasonCode"
        bindLabel="label" bindValue="value" [items]="unTecoReasonOption" [closeOnSelect]="true"
        placeholder="{{ 'pages.un-teco.slider-panel.un-teco-reason' | translate }}"
        [ngModelOptions]="{standalone: true}" [ngClass]="{'input-error':errorFields.unTecoReasonCode}"
        [disabled]="unTecoParam.isPanelSubmitLoading"></ng-select>


    <!-- approver field -->
    <!-- ////////////// -->

    <div class="mt-3">
        {{ 'pages.un-teco.slider-panel.remarks' | translate }}
    </div>
    <textarea id="un-teco-remarks" name="un-teco-remarks" class="form-control mt-2" rows="5" [(ngModel)]="unTecoParam.unTecoRemarks"
        placeholder="{{ 'pages.un-teco.slider-panel.remarks' | translate }}" [disabled]="unTecoParam.isPanelSubmitLoading"
        maxlength="{{ getMaxRemarksLength }}"
        >
      </textarea>

    <span 
        *ngIf="unTecoParam.unTecoRemarks?.length >= getMaxRemarksLength" 
        class="red small"
    >
        {{ "pages.un-teco.slider-panel.max-length" | translate:[getMaxRemarksLength]}}
    </span>

    <div class="mt-5">
        <span class="red">*</span>{{ 'pages.un-teco.slider-panel.acknowledge-action.message' | translate }}
    </div>

    <div class="mt-3 custom-checkbox custom-control position-relative">
        <input type="checkbox" id="acknowledgeAction" class="checkbox-wrap custom-control-input custom-checkbox"
        [ngModelOptions]="{standalone: true}" [(ngModel)]="unTecoParam.isAcknowledgeAction" [disabled]="unTecoParam.isPanelSubmitLoading">
        <label class="custom-control-label"
            for="acknowledgeAction">{{ 'pages.un-teco.slider-panel.acknowledge-action' | translate }}</label>
    </div>
</div>