import { formatDate } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionButtonDefinition, ActionButtonSn, ActionButtonType } from '@enum/action-button';
import { SnReason } from '@enum/sn-reason';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Session } from '@services/session';
import { saveAs } from 'file-saver';
import { AppDelegate } from 'src/app/AppDelegate';
import { OrderType } from 'src/app/entity/enum/order-type';
import * as utility from 'src/app/services/utility';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ, JMREASON, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { ModalManuelFaxComponent } from '../../components/modal-manuel-fax/modal-manuel-fax.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sn-view',
  templateUrl: './sn-view.component.html',
  styleUrls: ['./sn-view.component.scss']
})
export class SnViewComponent extends BasePage implements OnInit {
  @ViewChild("create_follow_up_job_card_panel", { static: true }) createFollowUpJobCardPanel;
  @ViewChild("cancel_sn_panel", { static: true }) cancelSnPanel;
  @ViewChild("reject_sn_panel", { static: true }) rejectSnPanel;
  @ViewChild("transfer_sn_panel", { static: true }) transferSnPanel;
  @ViewChild("create_job_card_panel", { static: true }) createJobCardPanel;
  @ViewChild("manual_instruction_pre_action_panel", { static: true }) manualInstructionPreActionPanel;
  @ViewChild("associate_sn_panel", { static: true }) associateSnPanel;

  constructor(injector: Injector, private modalService: NgbModal) {
    super(injector);
  }

  pageTitle: string;
  handlingTeam: any = {
    team: undefined,
    workCenter: undefined,
    client: undefined,
    district: undefined,
    location: undefined,
    hashTagId: undefined,
    equipmentType: undefined,
    priority: undefined,
  };

  SnStatus = JMENUM.SnStatus;
  snNumber: string;
  sn: JMOBJ.ServiceNotification = undefined;
  currentPageMode = JMENUM.JMPageMode.VIEW;
  JMPage = JMENUM.JMPage;

  breadcrumbs: any = [];

  actionDefinition: any[] = [];
  actionButtonData = [];

  hasManualHandlingNotification = false;
  hasMiPreAction = false;
  manualInstructionStage = undefined;

  createFollowUpJobCardFormParam = {} as any;
  createFollowUpJobCardPanelFixedPanel = false;

  canelSnPanelFixedPanel = false;
  cancelReasonOptions: any[];
  isCancelSnPanelLoading = false;

  rejectSnPanelFixedPanel = false;
  rejectReasonOptions: any[];
  isRejectSnPanelLoading = false;

  transferSnPanelFixedPanel = false;
  transferWorkCenterOptions: any[];
  isTransferSnPanelLoading = false;

  isAssociateSnPanelInit = false;

  createJobcardPanelFixedPanel = false;

  jobCardOrderTypes = {
    [ActionButtonSn.jobCreateSla]: "ZS01",
    [ActionButtonSn.jobCreateAdHoc]: "ZS02",
    [ActionButtonSn.jobCreateTransfer]: "ZS04",
  };

  firstAttendJobCard = {
    jobNature: '',
    orderType: ''
  };
  selecedJobCardOrderType = "";
  defaultJobCardOrderType: string = undefined;

  // for submit sn with manual instruction preAction
  manualInstructionPreActionFormData: any[];
  manualInstructionPreActionFormTeam = {};
  manualInstructionPreActionFormTeamNextOperationTime: Date;
  manualInstructionPreActionFormHandlingTeamNextOperationTime: Date;
  additionalRemark: string;
  isManualInstructionPreActionPanelLoading = false;

  showAllPostForCreateJob = false;
  workCentrePostList: any[];
  createJobCardFormParam = {} as any;
  selectedAssignPersons = {
    assignedPersons: [],
    officerInCharge: null
  };

  disableActionSideBar = false;

  isFinalReply = false;
  isHaEquipmentOwner: boolean = false;

  async ngOnInit() {
    // get sn number or job card number from route parameters
    this.snNumber = this.route.snapshot.paramMap.get('snNumber');
    this.breadcrumbs = [
      { id: 'breadcrumbs-sn-number', name: this.snNumber, route: '/sn/view/' + this.snNumber, currentPage: true }
    ];

    if (!this.authorizationService.hasPermissions([JMENUM.Permission.SN_VIEW, JMENUM.Permission.SN_VIEW_ALL], false)) {
      this.router.navigate(['']);
    }
    //cache not use?
    await JM.JMConnector.cacheAll().then(async resultArray => {
      await this.requestSn();
    });


    // await this.requestSn();
    await this.requestEquipmentTag();
    await this.requestJobCardSummary();
    await this.requestSnHAOrderReplyXmlLogs();
    this.initActionButton();

  }

  private async requestSn() {
    const request = new JM.JMRequestSnGetSn();
    request.snNumber = this.snNumber;
    request.excludeTeamInformation = false;

    const response: JM.JMResponseSnGetSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.sn = (response.payload) ? response.payload : undefined;
    this.isHaEquipmentOwner = (this.sn && this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA) ? true : false;
    if (this.sn) {
      this.getManualInstructionStage();
      this.hasManualInstruction(false);
    }
  }

  private async requestSnHAOrderReplyXmlLogs() {
    const request = new JM.JMRequestSnHAOrderReplyXmlLogs();
    request.snNumber = this.snNumber;
    request.replyType = [JMENUM.ReplyType.FINAL];

    const response: JM.JMResponseSnHAOrderReplyXmlLogs = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.isFinalReply = false;
      return;
    }

    this.isFinalReply = response.payload.length > 0;
  }

  public hasManualInstruction(viewOnly: boolean, manualInstructionStage?) {
    let permission = viewOnly ? JMENUM.Permission.SN_MANUALINSTRUCTION_VIEW : JMENUM.Permission.SN_MANUALINSTRUCTION_HANDLIE;
    if (!this.sn.team || !this.sn.team.enableManualInstructions ||
      !this.sn.team.manualInstructions || !this.sn.priority ||
      !this.authorizationService.hasPermission(permission)) {
      this.hasManualHandlingNotification = false;
    } else {
      let manualInstructions = this.sn.team.manualInstructions;
      if (!manualInstructionStage) {
        this.hasManualHandlingNotification = (manualInstructions[this.sn.priority] &&
          this.manualInstructionStage &&
          manualInstructions[this.sn.priority][this.manualInstructionStage] &&
          manualInstructions[this.sn.priority][this.manualInstructionStage].length) ? true : false;
      }
      else {
        this.hasMiPreAction = (manualInstructions[this.sn.priority] &&
          manualInstructions[this.sn.priority][JMENUM.ManualInstructionStage.PRE_ACTION] &&
          manualInstructions[this.sn.priority][JMENUM.ManualInstructionStage.PRE_ACTION].length) ? true : false;
      }
    }
  }

  public getManualInstructionStage() {
    switch (this.sn.disseminationStage) {
      case JMENUM.DisseminationStage.PENDING_CONTRACTOR_MANUAL_INSTRUCTION:
        this.manualInstructionStage = JMENUM.ManualInstructionStage.CONTRACTOR_NO_RESPONSE;
        break;
      case JMENUM.DisseminationStage.PENDING_RO_MANUAL_INSTRUCTION:
        this.manualInstructionStage = JMENUM.ManualInstructionStage.RO_NO_RESPONSE;
        break;
      default:
        this.manualInstructionStage = null;
        break;
    }
  }

  public receiveActionClick(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = this.translateService.instant(button.name);
      })
      this.showPopUpAlert(this.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  public initActionButton() {
    this.actionButtonData = [];

    if (!this.sn) return;

    switch (this.sn.status) {
      case JMENUM.SnStatus.DRAFT:
        this.addActionBtn(ActionButtonSn.submit);
        this.addActionBtn(ActionButtonSn.cancel);
        this.addActionBtn(ActionButtonSn.edit);
        this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.haServiceReport);
        break;
      case JMENUM.SnStatus.PENDING_ACKNOWLEDGE:
      case JMENUM.SnStatus.NO_RESPONSE:
        if (this.sn.handlingParty == JMENUM.HandlingParty.INHOUSE) {
          this.addActionBtn(ActionButtonSn.acknowledge);
          this.addActionBtn(ActionButtonSn.reject);
        } else {
          this.addActionBtn(ActionButtonSn.manualAcknowledge);
        }
        this.addActionBtn(ActionButtonSn.reRoute);
        this.addActionBtn(ActionButtonSn.transfer);
        this.addActionBtn(ActionButtonSn.cancel);
        this.addActionBtn(ActionButtonSn.edit);
        this.addActionBtn(ActionButtonSn.associate);
        this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.haServiceReport);
        if (this.checkAttachmentIncludeEForm(this.sn.attachmentList)) {
          this.addActionBtn(ActionButtonSn.eForm);
        }
        break;
      case JMENUM.SnStatus.REJECTED:
        this.addActionBtn(ActionButtonSn.reRoute);
        this.addActionBtn(ActionButtonSn.transfer);
        this.addActionBtn(ActionButtonSn.cancel);
        this.addActionBtn(ActionButtonSn.edit);
        this.addActionBtn(ActionButtonSn.associate);
        break;
      case JMENUM.SnStatus.ACKNOWLEDGED:
        if (this.sn.handlingParty == JMENUM.HandlingParty.INHOUSE) {
          this.addActionBtn(ActionButtonSn.jobCreateSla);
          this.addActionBtn(ActionButtonSn.jobCreateAdHoc);
          this.addActionBtn(ActionButtonSn.jobCreateTransfer);
          this.addActionBtn(ActionButtonSn.createEnquiry);
          this.addActionBtn(ActionButtonSn.reject);
        }
        if (this.sn.handlingParty == JMENUM.HandlingParty.NON_PMSMC) {
          this.addActionBtn(ActionButtonSn.jobCreateSla);
          this.addActionBtn(ActionButtonSn.jobCreateAdHoc);
          this.addActionBtn(ActionButtonSn.jobCreateTransfer);
        }
        if (this.sn.handlingParty != JMENUM.HandlingParty.NON_PMSMC) {
          this.addActionBtn(ActionButtonSn.reRoute);
          this.addActionBtn(ActionButtonSn.transfer);
        }
        this.addActionBtn(ActionButtonSn.cancel);
        this.addActionBtn(ActionButtonSn.edit);
        this.addActionBtn(ActionButtonSn.associate);
        this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.haServiceReport);
        if (this.checkAttachmentIncludeEForm(this.sn.attachmentList)) {
          this.addActionBtn(ActionButtonSn.eForm);
        }
        break;
      case JMENUM.SnStatus.IN_PROGRESS:
        this.addActionBtn(ActionButtonSn.jobCreateFollowUp);
        this.addActionBtn(ActionButtonSn.cancel);
        this.addActionBtn(ActionButtonSn.edit);
        this.addActionBtn(ActionButtonSn.associate);
        this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.haServiceReport);
        if (this.checkAttachmentIncludeEForm(this.sn.attachmentList)) {
          this.addActionBtn(ActionButtonSn.eForm);
        }
        break;
      case JMENUM.SnStatus.COMPLETED:
        this.addActionBtn(ActionButtonSn.jobCreateFollowUp);
        this.addActionBtn(ActionButtonSn.edit);
        this.addActionBtn(ActionButtonSn.associate);
        this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.haServiceReport);
        if (this.checkAttachmentIncludeEForm(this.sn.attachmentList)) {
          this.addActionBtn(ActionButtonSn.eForm);
        }
        break;
      case JMENUM.SnStatus.CANCELLED:
        this.addActionBtn(ActionButtonSn.edit);
        break;
      //Metion that SN have signed off status but just need the default button only
      case JMENUM.SnStatus.SIGNED_OFF:
        this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.haServiceReport);
        if (this.checkAttachmentIncludeEForm(this.sn.attachmentList)) {
          this.addActionBtn(ActionButtonSn.eForm);
        }
        break;
      default:
        break;
    }
    this.addActionBtn(ActionButtonSn.eFax);
    this.addActionBtn(ActionButtonSn.exportPdf);

  }

  checkAttachmentIncludeEForm(attachmentList: any[]): boolean {
    if (attachmentList && Array.isArray(attachmentList)) {
      for (let i = 0; i < attachmentList.length; i++) {
        if (attachmentList[i].attachmentType === JMENUM.SnAttachmentType.E_FORM) {
          return true;
        }
      }
    }
    return false;
  }

  public commonSNButtonCheck
    (createjobButton: boolean,
      permission?: JMENUM.Permission,
      permissionAll?: JMENUM.Permission,
      submittedBy?: boolean,
      createdBy?: boolean,
      teamWorkCentre?: boolean,
      other?: boolean): boolean {
    if (!createjobButton) {
      let permissionCheck = (permission) ? this.authorizationService.hasPermission(permission) : true;
      let permissionWorkCentre = this.sn.workCentre && this.authorizationService.hasAuthorizationForWorkCenter(this.sn.workCentre.toString());
      let permissionTeamWorkCentre = (teamWorkCentre) ? this.sn.team && this.sn.team.workCentre && this.authorizationService.hasAuthorizationForWorkCenter(this.sn.team.workCentre.toString()) : false;
      let permissionAllCheck = (permissionAll) ? this.authorizationService.hasPermission(permissionAll) : false;
      let submittedByCheck = (submittedBy) ? this.sn.submittedBy && this.sn.submittedBy == Session.userInfo.name : false;
      let createdByCheck = (createdBy) ? this.sn.createdBy && this.sn.createdBy == Session.userInfo.name : false;
      return permissionCheck && (permissionWorkCentre || permissionTeamWorkCentre || permissionAllCheck || submittedByCheck || createdByCheck) && other;
    }
  }

  public addActionBtn(buttonStatus: ActionButtonSn) {
    let buttonHandler = () => { };
    let actionButton = ActionButtonDefinition[ActionButtonType.sn][buttonStatus];
    if (this.getSnFreezedActionList().includes(buttonStatus)) { return false; }
    const isHaSn = this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA;

    switch (buttonStatus) {
      case ActionButtonSn.edit:
        if (this.commonSNButtonCheck(false, JMENUM.Permission.SN_UPDATE, JMENUM.Permission.SN_UPDATE_ALL, true, true, true, true)) {
          buttonHandler = () => { this.edit() };
        } else {
          return;
        }
        break;
      case ActionButtonSn.submit:
        if (this.commonSNButtonCheck(false, JMENUM.Permission.SN_SUBMIT, JMENUM.Permission.SN_SUBMIT_ALL, true, true, true, true)) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.submit() };
        } else {
          return;
        }
        break;
      case ActionButtonSn.reRoute:
        if (this.commonSNButtonCheck(false, undefined, JMENUM.Permission.SN_REROUTE_ALL, true, false, true, true)) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.reRoute() };
        } else {
          return;
        }
        break;
      case ActionButtonSn.transfer:
        if (this.commonSNButtonCheck(false, undefined, JMENUM.Permission.SN_REROUTE_ALL, true, false, true, this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA)) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.transfer() };
        } else {
          return;
        }
        break;
      case ActionButtonSn.acknowledge:
        if (this.commonSNButtonCheck(false, JMENUM.Permission.SN_ACKNOWLEDGE, JMENUM.Permission.SN_ACKNOWLEDGE_ALL, false, false, true, true)) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.acknowledge() };
        } else {
          return;
        }
        break;
      case ActionButtonSn.manualAcknowledge:
        if (this.commonSNButtonCheck(false, undefined, JMENUM.Permission.SN_MANUALACKNOWLEDGE_ALL, false, false, true, true)) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.acknowledge() };
        } else {
          return;
        }
        break;
      case ActionButtonSn.reject:
        if (this.commonSNButtonCheck(false, undefined, JMENUM.Permission.SN_REJECT_ALL, false, false, true, this.sn.handlingParty == JMENUM.HandlingParty.INHOUSE)) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.rejectSn() };
        } else {
          return;
        }
        break;
      case ActionButtonSn.cancel:
        if (this.commonSNButtonCheck(false, JMENUM.Permission.SN_CANCEL, JMENUM.Permission.SN_CANCEL_ALL, true, true, false, !this.isFinalReply)) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.cancelSn() };
        }
        else {
          return;
        }
        break;
      case ActionButtonSn.jobCreateSla:
        {
          let showOrderType = true;
          if (this.sn.hashtagId) {
            let noDefaultType = !this.defaultJobCardOrderType || this.defaultJobCardOrderType === '';
            let isSpecificType = this.defaultJobCardOrderType == this.jobCardOrderTypes[buttonStatus];
            showOrderType = noDefaultType || isSpecificType;
          }
          let hasPermission = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CREATE);
          let isTeamMember = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CREATE_ALL) || this.isTeamMember(Session.userInfo.name);

          if (showOrderType && hasPermission) {
            if (this.sn.handlingParty === JMENUM.HandlingParty.INHOUSE && isTeamMember) {
              actionButton.isEnable = !this.isPendingApproval;
              buttonHandler = () => {
                this.selecedJobCardOrderType = this.jobCardOrderTypes[actionButton.action]
                this.initJobCardCreationPanel(true, JMENUM.JobNature.CM);
                this.createJobCardPanel.toggle();
              };
            } else if (this.sn.handlingParty === JMENUM.HandlingParty.NON_PMSMC) {
              actionButton.showPopup = true;
              actionButton.popupTitle = JMLanguage.translate('action.button.popup.create-job-card');
              actionButton.isEnable = !this.isPendingApproval;
              buttonHandler = () => {
                this.requestCreateNonPmsmcJobCard(this.jobCardOrderTypes[actionButton.action]);
              };
            } else {
              return;
            }
          } else {
            return;
          }
          break;
        }
      case ActionButtonSn.jobCreateAdHoc:
      case ActionButtonSn.jobCreateTransfer:
        {
          let showOrderType = true;
          if (this.sn.hashtagId) {
            let noDefaultType = !this.defaultJobCardOrderType || this.defaultJobCardOrderType === '';
            let isSpecificType = this.defaultJobCardOrderType == this.jobCardOrderTypes[buttonStatus];
            showOrderType = noDefaultType || isSpecificType;
          }
          let hasPermission = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CREATE);
          let isTeamMember = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CREATE_ALL) || this.isTeamMember(Session.userInfo.name);

          if (showOrderType && hasPermission && this.sn.maintenanceType != JMENUM.MaintenanceType.PM) {
            if (this.sn.handlingParty === JMENUM.HandlingParty.INHOUSE && isTeamMember) {
              actionButton.isEnable = !this.isPendingApproval;
              buttonHandler = () => {
                this.selecedJobCardOrderType = this.jobCardOrderTypes[actionButton.action]
                this.initJobCardCreationPanel(true, JMENUM.JobNature.CM);
                this.createJobCardPanel.toggle();
              };
            } else if (this.sn.handlingParty === JMENUM.HandlingParty.NON_PMSMC) {
              actionButton.showPopup = true;
              actionButton.popupTitle = JMLanguage.translate('action.button.popup.create-job-card');
              actionButton.isEnable = !this.isPendingApproval;
              buttonHandler = () => {
                this.requestCreateNonPmsmcJobCard(this.jobCardOrderTypes[actionButton.action]);
              };
            } else {
              return;
            }
          } else {
            return;
          }
          break;
        }
      case ActionButtonSn.createEnquiry:
        {
          let hasPermission = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CREATE);
          let isTeamMember = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CREATE_ALL) || this.isTeamMember(Session.userInfo.name);

          if (this.sn.handlingParty == JMENUM.HandlingParty.INHOUSE && this.sn.maintenanceType != JMENUM.MaintenanceType.PM &&
            hasPermission && isTeamMember) {
            actionButton.isEnable = !this.isPendingApproval;
            buttonHandler = () => {
              this.selecedJobCardOrderType = null;
              this.initJobCardCreationPanel(true, JMENUM.JobNature.ENQUIRY);
              this.createJobCardPanel.toggle();
            };
          } else {
            return;
          }
          break;
        }
      case ActionButtonSn.jobCreateFollowUp:
        if (!this.firstAttendJobCard.jobNature) return; // not allow display button before call jobCardSummary
        if (this.firstAttendJobCard.jobNature === JMENUM.JobNature.CM && this.firstAttendJobCard.orderType !== OrderType.SLA_JOB) return;
        if (this.firstAttendJobCard.jobNature === JMENUM.JobNature.ENQUIRY) return;

        if (this.commonSNButtonCheck(false, JMENUM.Permission.JOBCARD_CREATE, JMENUM.Permission.JOBCARD_CREATE_ALL, false, false, false, !this.isFinalReply)) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.createFollowUpJobCard() };
        } else {
          return;
        }
        break;
      case ActionButtonSn.eFax:
        buttonHandler = () => { this.eFax() };
        break;
      case ActionButtonSn.exportPdf:
        // TODO: handle pending approve status
        buttonHandler = () => { this.exportPdf() };
        break;
      case ActionButtonSn.attachment:
        actionButton.isEnable = !this.isPendingApproval;
        buttonHandler = () => { this.attachment() };
        break;
      case ActionButtonSn.haServiceReport:
        if(!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JM_HSD_SERVICE_INTEGRATION)) return;
        if(!isHaSn) return;
        actionButton.isEnable = !this.isPendingApproval;
        buttonHandler = () => { this.toHaServiceReportPage() };
        break;
      case ActionButtonSn.eForm:
        actionButton.isEnable = !this.isPendingApproval;
        buttonHandler = () => { this.showEFormDetail() };
        break;
      case ActionButtonSn.associate:
        const isCmType = this.sn.maintenanceType === JMENUM.MaintenanceType.CM;
        const hasAssetNumber = this.sn.eamData && this.sn.eamData.assetNumber;
        const hasAssocate = (this.sn.associatingSn && this.sn.associatingSn.length) || (this.sn.associatedSn && this.sn.associatedSn.length);
        const hasHaWorkOrderNumber = this.sn.eamData && this.sn.eamData.HAWorkOrderNumber && this.sn.eamData.HAWorkOrderNumber !== '';
        const isValidSn = isHaSn && isCmType && hasAssetNumber && !hasHaWorkOrderNumber && !hasAssocate;
        const hasPermission = this.authorizationService.hasPermissions([JMENUM.Permission.SN_ASSOCIATE, JMENUM.Permission.SN_ASSOCIATE_ALL], false);

        if (isValidSn && hasPermission) {
          actionButton.isEnable = !this.isPendingApproval;
          buttonHandler = () => { this.openSnAssociatePanel(); }
        } else {
          return;
        }
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : this.translateService.instant("global.yes"),
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : this.translateService.instant("global.no"),
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  public isTeamMember(member) {
    if (!this.sn || !this.sn.team || !this.sn.team.members || !this.sn.team.members.length)
      return false;

    // case insensitive
    for (let i = 0; i < this.sn.team.members.length; i++) {
      if (this.sn.team.members[i].toLowerCase() == member.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  public getSnFreezedActionList() {
    if (!this.sn.jobCardTecoStatusList || !this.sn.jobCardTecoStatusList.length) {
      return [];
    }

    let freezedTecoStatusList = [(Number)(JMENUM.TecoStatus.PENDING_FOR_TECO), (Number)(JMENUM.TecoStatus.SUCCEEDED), (Number)(JMENUM.TecoStatus.PENDING_FOR_UNTECO)];
    let freezedActionList = [];

    for (let item of this.sn.jobCardTecoStatusList) {
      if (freezedTecoStatusList.includes((Number)(item.tecoStatus))) {
        freezedActionList.push(ActionButtonSn.cancel);
      }
    }

    if (freezedTecoStatusList.includes((Number)(this.sn.jobCardTecoStatusList[0].tecoStatus))) {
      freezedActionList.push(ActionButtonSn.jobCreateFollowUp);
    }

    return freezedActionList;
  }

  public async requestJobCardSummary() {
    let firstAttendJobNumber = this.findFirstAttendedJobCard(this.sn.jobCards);

    if (!firstAttendJobNumber) return;

    const request = new JM.JMRequestJobCardsJobCardSummary();
    request.jobCardNumberList = [firstAttendJobNumber];
    request.parameters = ['jobNature', 'orderType',];

    const response: JM.JMResponseJobCardsJobCardSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records && response.payload.records.length > 0) {
      this.firstAttendJobCard.jobNature = response.payload.records[0].jobNature;
      this.firstAttendJobCard.orderType = response.payload.records[0].orderType;
    }
  }

  public async requestEquipmentTag() {
    if (this.sn.hashtagId) {
      const request = new JM.JMRequestHashtagsGetHashtags();
      request.hashtagIdList = [this.sn.hashtagId];

      const response: JM.JMResponseHashtagsGetHashtags = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (response.payload.records.length > 0 && response.payload.records[0]) {
        this.defaultJobCardOrderType = response.payload.records[0].defaultOrderType ? response.payload.records[0].defaultOrderType : '';
      }
    }
  }

  private findFirstAttendedJobCard(jobCardList) {
    let firstAttendedJobCard;
    jobCardList.forEach(jobCard => {
      let tmpArr = jobCard.split("-");
      if (tmpArr.length > 1 && tmpArr[1] == '01') {
        firstAttendedJobCard = jobCard;
        return;
      }
    });
    return firstAttendedJobCard;
  }

  private isSnValid(): boolean {
    let fields = [
      'client',
      'district',
      'location',
      'contactPerson',
      'equipmentCategory',
      'equipmentType',
      'faultDetail',
      'team'
    ];

    let haOptionalFields = [
      'equipmentCategory',
      'equipmentType',
    ];

    let hasAssetNumber = this.sn.eamData && this.sn.eamData.assetNumber;
    for (let field of fields) {
      let value = this.sn[field];

      if (haOptionalFields.includes(field) && hasAssetNumber) {
        continue;
      }

      if (!value || value == "") {
        return false;
      }
    }

    if (!this.sn.contactNumber || !this.sn.contactNumber.length)
      return false;

    return true;
  }


  //Export PDF Part-------------------------------------------------------------------------------------------------
  public async exportPdf(): Promise<void> {
    this.disableActionSideBar = true;
    const request = new JM.JMRequestNotificationExportSNPDF();
    request.snNumber = this.sn.snNumber;
    request.lang = Session.selectedLanguage;

    const response: JM.JMResponseNotificationExportSNPDF = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let base64 = response.payload.base64;
    let blob = utility.base64ToBlob(base64, response.payload.type);
    saveAs(blob, `${this.sn.snNumber}.pdf`);
    this.disableActionSideBar = false;
  }
  //----------------------------------------------------------------------------------------------------------------



  //efax Part-------------------------------------------------------------------------------------------------------
  public eFax(): void {
    let detail = {
      teamName: null,
      attention: null,
      faxNumber: null,
    };
    let modalRef = this.modalService.open(ModalManuelFaxComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.faxDetail = detail;
    modalRef.componentInstance.onResendClicked = async (result) => {
      modalRef.componentInstance.setLoading(true);

      this.disableActionSideBar = true;
      const request = new JM.JMRequestNotificationFaxSN();
      request.snNumber = this.sn.snNumber;
      request.team = result.teamName;
      request.attention = detail.attention;
      request.faxNumber = result.faxNumber;
      request.lang = Session.selectedLanguage;
      modalRef.componentInstance.setLoading(false);
      const response: JM.JMResponseNotificationFaxSN = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        this.disableActionSideBar = false;
        return;
      }

      modalRef.close();
      this.disableActionSideBar = false;
    };
  }
  //----------------------------------------------------------------------------------------------------------------



  //Create follow-up jobcard Part-----------------------------------------------------------------------------------
  public createFollowUpJobCard(): void {
    this.initCreateFollowUpJobCardPanel();
    this.createFollowUpJobCardPanel.toggle();
  }

  public initCreateFollowUpJobCardPanel() {
    this.createFollowUpJobCardFormParam = {
      isLoading: false,
      workCentre: this.sn.workCentre,
      jobCardList: this.sn.jobCards,
      onSubmitClicked: (button) => {
        this.requestCreateFollowUpJobCard(button);
      }
    };
  }

  public async requestCreateFollowUpJobCard(param: {
    followUpReasonCode: string,
    followUpRemarks: string,
    selectedContractor: string,
    assignedPersons: string[],
    officerInCharge: string,
  }) {
    this.createFollowUpJobCardPanelFixedPanel = true;

    const request = new JM.JMRequestJobCardsCreateFollowUpJobCard();
    request.snNumber = this.sn.snNumber;
    request.assignedPersons = param.assignedPersons;
    request.followUpReasonCode = param.followUpReasonCode;
    request.followUpRemarks = param.followUpRemarks;
    request.officerInCharge = param.officerInCharge;
    request["selectedContractor"] = param.selectedContractor;

    this.createFollowUpJobCardFormParam.isLoading = true;
    const response: JM.JMResponseJobCardsCreateFollowUpJobCard = await AppDelegate.sendJMRequest(request);
    this.createFollowUpJobCardFormParam.isLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.createFollowUpJobCardPanelFixedPanel = false;
      return;
    }

    AppDelegate.openSnackBar(this.translate("pages.sn.alert-created"));
    this.createFollowUpJobCardPanel.close();
    this.createFollowUpJobCardPanelFixedPanel = false;
    let path = '/job-card/view/' + response.payload.jobCardNumber;
    this.router.navigate([path]);
  }
  //----------------------------------------------------------------------------------------------------------------



  //Canel SN Part---------------------------------------------------------------------------------------------------
  public cancelSn(): void {
    this.initSnCancelPanel();
    this.cancelSnPanel.toggle();
  }

  public async initSnCancelPanel() {
    this.cancelReasonOptions = [];
    let cancelReasons;

    if (this.sn.sourceSystem == JMENUM.SourceSystem.CCEPVP) {
      cancelReasons = JMREASON.SN_CANCEL_REASON_LIST.filterReason([
        JMREASON.Attribute.VP,
      ]);
    } else if (this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA) { // HA SN
      cancelReasons = JMREASON.SN_CANCEL_REASON_LIST.filterReason([
        JMREASON.Attribute.JM,
        JMREASON.Attribute.HA
      ]);
    } else {
      cancelReasons = JMREASON.SN_CANCEL_REASON_LIST.filterReason([
        JMREASON.Attribute.JM,
        JMREASON.Attribute.NON_HA,
      ]);
    }

    for (const reasonObj of cancelReasons) {
      const item = {
        value: reasonObj.code,
        description: Session.selectedLanguage === JMENUM.Language.ZH ? reasonObj.description.zh : reasonObj.description.en
      };
      this.cancelReasonOptions.push(item);
    }
  }

  public async requestCancelSn(param: {
    cancellationCode: string,
    cancelRemarks: string,
    approver: string[]
  }) {
    const request = new JM.JMRequestSnCancelSn();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;
    request.cancellationCode = param.cancellationCode;
    request.cancelRemarks = param.cancelRemarks;
    request.approver = param.approver;

    this.canelSnPanelFixedPanel = true;
    this.isCancelSnPanelLoading = true;
    const response: JM.JMResponseSnCancelSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.canelSnPanelFixedPanel = false;
      this.isCancelSnPanelLoading = false;
      return;
    }

    this.canelSnPanelFixedPanel = false;
    this.isCancelSnPanelLoading = false;
    const translateKey = response.payload.pendingApproval ? "global.pending-approval-cancellation" : "global.cancelled";
    AppDelegate.openSnackBar(this.translate(translateKey));
    this.cancelSnPanel.close();
    await this.requestSn();
    await this.initActionButton();
  }
  //----------------------------------------------------------------------------------------------------------------



  //Reject SN Part--------------------------------------------------------------------------------------------------
  public rejectSn(): void {
    this.initSnRejectPanel();
    this.rejectSnPanel.toggle();
  }

  public initSnRejectPanel() {
    this.rejectReasonOptions = [];

    SnReason.forEach(reason => {
      let item: any = {};
      if (reason.area === 'sn' && reason.orderType === 'reject') {
        item.value = reason.id;
        item.description = (Session.selectedLanguage === JMENUM.Language.ZH ? reason.descriptionZh : reason.descriptionEn);
        this.rejectReasonOptions.push(item);
      }
    });
  }

  public async requestRejectSn(param: {
    rejectCode: string,
    rejectRemarks: string
  }) {
    const request = new JM.JMRequestSnRejectSn();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;
    request.rejectCode = param.rejectCode;
    request.rejectRemarks = param.rejectRemarks;

    this.rejectSnPanelFixedPanel = true;
    this.isRejectSnPanelLoading = true;
    const response: JM.JMResponseSnRejectSn = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.rejectSnPanelFixedPanel = false;
      this.isRejectSnPanelLoading = false;
      return;
    }

    await this.requestSn();
    await this.initActionButton();
    this.rejectSnPanelFixedPanel = false;
    this.isRejectSnPanelLoading = false;
    AppDelegate.openSnackBar(this.translate("global.rejected"));
    this.rejectSnPanel.close();
  }
  //----------------------------------------------------------------------------------------------------------------



  //Acknowledge SN Part---------------------------------------------------------------------------------------------
  public async acknowledge() {
    this.disableActionSideBar = true;
    const request = new JM.JMRequestSnAcknowledgeSn();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;

    const response = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.disableActionSideBar = false;
      return;
    }
    await this.requestSn();
    this.disableActionSideBar = false;
    await this.initActionButton();
    AppDelegate.openSnackBar(this.translate("global.acknowledged"));
  }
  //----------------------------------------------------------------------------------------------------------------



  //Reroute SN Part-------------------------------------------------------------------------------------------------
  public reRoute(): void {
    let path = '/sn/create/' + this.sn.snNumber + '/re-route';
    this.router.navigate([path]);
  }
  //----------------------------------------------------------------------------------------------------------------

  //transfer SN Part-------------------------------------------------------------------------------------------------
  public transfer(): void {
    this.initSnTransferPanel();
    this.transferSnPanel.toggle();
  }

  public async initSnTransferPanel() {
    this.transferWorkCenterOptions = [];

    for (const workCentre of JM.JMConnector.getAllWorkCentre()) {
      if (workCentre && workCentre.enableHAEquipment) {
        this.transferWorkCenterOptions.push(workCentre.workCentreCode);
      }
    }
  }

  private askDeferSn(teamId: string, nextOperationTime?: Date) {
    let buttons = [
      {
        name: this.translateService.instant("global.yes"),
        handler: () => {
          this.requestTransferSn(teamId, true);
        }
      },
      {
        name: (this.translateService.instant("global.no")),
        handler: () => {
          this.requestTransferSn(teamId, false);
        }
      }
    ];
    this.showPopUpAlert(
      this.translate("pages.sn.defer"),
      this.translate("pages.sn.defer-pop-up-message", [formatDate(nextOperationTime, "dd/MM/yyyy HH:mm", 'en-US')]),
      buttons
    );
  }

  public handleTransferSn(data) {
    let { teamId, nextOperationTime } = data;

    if (nextOperationTime) {
      this.askDeferSn(teamId, nextOperationTime);
    } else {
      this.requestTransferSn(teamId, false);
    }
  }

  public async requestTransferSn(teamId, isDefer) {
    this.transferSnPanelFixedPanel = true;
    this.isTransferSnPanelLoading = true;

    let request: JM.JMRequestSnTransfer = new JM.JMRequestSnTransfer();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;
    request.teamId = teamId;
    request.isNotificationDeferred = isDefer || false;

    let response: JM.JMResponseSnTransfer = await AppDelegate.sendJMRequest(request);
    this.transferSnPanelFixedPanel = false;
    this.isTransferSnPanelLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    const translateKey = "global.transferred";
    AppDelegate.openSnackBar(this.translate(translateKey));
    this.transferSnPanel.close();
    await this.requestSn();
    await this.initActionButton();
  }
  //----------------------------------------------------------------------------------------------------------------


  //Edit SN Part----------------------------------------------------------------------------------------------------
  public edit(): void {
    let path = ""
    if (this.sn.status == JMENUM.SnStatus.DRAFT) {
      path = '/sn/create/' + this.sn.snNumber + '/edit';
    }
    else {
      path = '/sn/view/' + this.sn.snNumber + '/edit';
    }
    this.router.navigate([path]);
  }
  //----------------------------------------------------------------------------------------------------------------



  //Submit SN Part--------------------------------------------------------------------------------------------------
  public submit(): void {
    if (!this.isSnValid()) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.sn.fill-in-all-the-mandatory-fields'));
      let path = '/sn/create/' + this.sn.snNumber + '/edit';
      this.router.navigate([path]);
      return;
    }
    this.hasManualInstruction(true, JMENUM.ManualInstructionStage.PRE_ACTION);
    if (this.hasMiPreAction) {
      this.showManualInstructionPreAction();
    } else {
      this.submitSn();
    }
  }

  public async showManualInstructionPreAction() {
    let stage = "preAction";
    this.manualInstructionPreActionFormData = [];
    this.additionalRemark = null;

    this.manualInstructionPreActionFormData = this.sn.team.manualInstructions["" + this.sn.priority][stage];

    await this.requestTeamNextOperationTime();
  }

  public async requestTeamNextOperationTime() {
    const teamId = this.sn.team._id.toString();
    const handlingTeamId = this.sn.team.handlingTeam;

    const request = new JM.JMRequestTeamsGetTeamNextOperationTime();
    request.idList = [teamId];

    if (handlingTeamId) {
      request.idList.push(handlingTeamId);
    }

    const response: JM.JMResponseTeamsGetTeamNextOperationTime = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
      AppDelegate.openErrorBar(response);
      this.disableActionSideBar = false;
      return;
    }

    let teamNextOperationTime = undefined;
    let handlingTeamNextOperationTime = undefined;

    if (response.payload.records[teamId] !== undefined) {
      teamNextOperationTime = response.payload.records[teamId]; // null or date
    }
    if (handlingTeamId && response.payload.records[handlingTeamId] !== undefined) {
      handlingTeamNextOperationTime = response.payload.records[handlingTeamId]; // null or date
    }
    this.manualInstructionPreActionFormTeam = this.sn.team;
    this.manualInstructionPreActionFormTeamNextOperationTime = teamNextOperationTime;
    this.manualInstructionPreActionFormHandlingTeamNextOperationTime = handlingTeamNextOperationTime;
    this.manualInstructionPreActionPanel.toggle();
  }

  public submitSn() {
    let actionButton = ActionButtonDefinition[ActionButtonType.sn][ActionButtonSn.submit];
    let buttons = [
      {
        name: this.translateService.instant("global.yes"),
        handler: () => {
          // this.disableActionButtons();
          this.checkDeferSn();
        }
      },
      {
        name: this.translateService.instant("global.no"), handler: () => {
          // this.enableActionButtons();
        }
      }
    ];

    AppDelegate.showPopUpAlert(this.translate(actionButton.popupTitle), "", buttons);
  }

  public checkDeferSn() {
    if (!this.sn || !this.sn.team || !this.sn.team._id) {
      this.requestSubmitSn();
      return;
    }

    this.requestRoutingRules();
  }

  private async requestSubmitSn(nextOperationTime = null) {
    this.isManualInstructionPreActionPanelLoading = true;
    this.disableActionSideBar = true;
    const request = new JM.JMRequestSnSubmit();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;
    if (this.sn.team && this.sn.team._id) request.teamId = this.sn.team._id;
    request.contactPerson = this.sn.contactPerson;
    request.priority = this.sn.priority;
    request.client = this.sn.client;
    request.contactNumber = this.sn.contactNumber;
    request.district = this.sn.district;
    request.equipmentCategory = this.sn.equipmentCategory;
    request.equipmentType = this.sn.equipmentType;
    request.faultDetail = this.sn.faultDetail;
    request.hashtagId = this.sn.hashtagId;
    request.location = this.sn.location;
    request.selectedRuleSequence = this.sn.selectedRuleSequence;
    request.workCentre = this.sn.workCentre;
    request.email = this.sn.email;
    request.locationDetail = this.sn.locationDetail;
    request.isNotificationDeferred = (nextOperationTime != null);
    if (nextOperationTime) {
      request.disseminationStartTime = nextOperationTime;
    }
    if (this.additionalRemark) {
      request.remarks = [
        {
          remark: this.additionalRemark,
          visibilityType: JMENUM.RemarkVisibility.PUBLIC
        }
      ];
    }

    const response: JM.JMResponseSnSubmit = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.disableActionSideBar = false;
      this.isManualInstructionPreActionPanelLoading = false;
      return;
    }

    await this.requestSn();
    this.disableActionSideBar = false;
    await this.initActionButton();
    this.openSnackBar(this.translate("global.submitted"));
    this.isManualInstructionPreActionPanelLoading = false;
    this.manualInstructionPreActionPanel.close();
  }

  private async requestRoutingRules() {
    const request = new JM.JMRequestTeamsGetTeamNextOperationTime();
    request.idList = [this.sn.team._id];

    const response: JM.JMResponseTeamsGetTeamNextOperationTime = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
      this.openErrorBar(response);
      return;
    }

    if (response.payload.records[this.sn.team._id]) { // non office hour
      let nextOperationTime = response.payload.records[this.sn.team._id];
      let buttons = [
        {
          name: (this.translateService.instant("global.yes")),
          handler: () => {
            this.requestSubmitSn(nextOperationTime);
          }
        },
        {
          name: (this.translateService.instant("global.no")),
          handler: () => {
            this.requestSubmitSn();
          }
        }
      ];
      AppDelegate.showPopUpAlert(this.translate("pages.sn.defer"), this.translate("pages.sn.defer-pop-up-message", [formatDate(nextOperationTime, "dd/MM/yyyy HH:mm", 'en-US')]), buttons);
    } else {
      this.requestSubmitSn();
    }
  }

  public submitSnWithManualInstruction(remark: string) {
    this.additionalRemark = remark;
    this.submitSn();
  }
  //----------------------------------------------------------------------------------------------------------------



  //Reroute SN Part-------------------------------------------------------------------------------------------------
  public initJobCardCreationPanel(isClear = true, jobNature: JMENUM.JobNature) {
    if (this.showAllPostForCreateJob && this.workCentrePostList == null) {
      this.requestWorkCentrePostList(jobNature);
      return;
    }

    let assignedPersons = [];
    let officerInCharge = null;
    let teamMembers = this.showAllPostForCreateJob ? this.workCentrePostList : this.sn.team.members;

    if (isClear) {
      this.selectedAssignPersons.assignedPersons = [];
      this.selectedAssignPersons.officerInCharge = null;
    } else {
      assignedPersons = this.selectedAssignPersons.assignedPersons;
      officerInCharge = this.selectedAssignPersons.officerInCharge;
    }
    teamMembers = Array.from(new Set(teamMembers.concat(this.selectedAssignPersons.assignedPersons)));

    this.createJobCardFormParam = {
      jobNature: jobNature,
      teamMembers: teamMembers,
      assignedPersons: assignedPersons,
      officerInCharge: officerInCharge,
      onSubmitClicked: async (result) => {
        switch (jobNature) {
          case JMENUM.JobNature.ENQUIRY:
            await this.requestCreateEnquiryJobCard(result.assignedPersons, result.officerInCharge);
            break;
          case JMENUM.JobNature.CM:
            this.requestCreateCmJobCard(result.assignedPersons, result.officerInCharge, this.selecedJobCardOrderType);
            break;
        }
      },
      onAddMemberClicked: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      },
      onRemoveMemberClicked: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      },
      onOfficerInChargeChanged: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      }
    };
  }

  public async requestCreateNonPmsmcJobCard(orderType: string) {
    this.createJobcardPanelFixedPanel = true;
    this.createJobCardFormParam.isLoading = true;

    const request = new JM.JMRequestJobCardsCreateNonPmsmcJob();
    request.snNumber = this.sn.snNumber;
    request.orderType = orderType;
    const response: JM.JMResponseJobCardsCreateNonPmsmcJob = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.createJobcardPanelFixedPanel = false;
      this.createJobCardFormParam.isLoading = false;
      return;
    }
    this.createJobcardPanelFixedPanel = false;
    this.createJobCardFormParam.isLoading = false;
    AppDelegate.openSnackBar(this.translate("pages.sn.alert-created"));
    this.createJobCardPanel.close();
    let path = '/job-card/view/' + response.payload.jobCardNumber;
    this.router.navigate([path]);
  }

  public async requestCreateEnquiryJobCard(assignedPersons: string[], officerInCharge: string) {
    this.createJobcardPanelFixedPanel = true;
    this.createJobCardFormParam.isLoading = true;

    const request = new JM.JMRequestJobCardsCreateEnquiry();
    request.snNumber = this.sn.snNumber;
    request.assignedPersons = assignedPersons;
    request.officerInCharge = officerInCharge;

    const response: JM.JMResponseJobCardsCreateEnquiry = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.createJobcardPanelFixedPanel = false;
      this.createJobCardFormParam.isLoading = false;
      return;
    }
    this.createJobcardPanelFixedPanel = false;
    this.createJobCardFormParam.isLoading = false;
    AppDelegate.openSnackBar(this.translate("pages.sn.alert-created"));
    this.createJobCardPanel.close();
    let path = '/job-card/view/' + response.payload.jobCardNumber;
    this.router.navigate([path]);
  }

  public async requestCreateCmJobCard(assignedPersons: string[], officerInCharge: string, orderType: string) {
    this.createJobcardPanelFixedPanel = true;
    this.createJobCardFormParam.isLoading = true;

    const request = new JM.JMRequestJobCardsCreateCmJobCard();
    request.snNumber = this.sn.snNumber
    request.orderType = orderType;
    request.assignedPersons = assignedPersons;
    request.officerInCharge = officerInCharge;
    request.snVersion = this.sn.version;

    const response: JM.JMResponseJobCardsCreateCmJobCard = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.createJobcardPanelFixedPanel = false;
      this.createJobCardFormParam.isLoading = false;
      return;
    }
    this.createJobcardPanelFixedPanel = false;
    this.createJobCardFormParam.isLoading = false;
    AppDelegate.openSnackBar(this.translate("pages.sn.alert-created"));
    this.createJobCardPanel.close();
    let path = '/job-card/view/' + response.payload.jobCardNumber;
    this.router.navigate([path]);
  }

  public async requestWorkCentrePostList(jobNature: JMENUM.JobNature) {
    this.createJobCardFormParam.isLoading = true;
    this.createJobcardPanelFixedPanel = true;

    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = 'CCEPJM';
    request.authorizations = { 'workCenters': this.sn.team.workCentre };
    request.active = JMENUM.RequestActive.ACTIVE;

    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.createJobcardPanelFixedPanel = false;
      this.createJobCardFormParam.isLoading = false;
      this.showAllPostForCreateJob = false;
      return;
    }

    if (!response.payload.records || !response.payload.records.length) {
      this.createJobcardPanelFixedPanel = false;
      this.createJobCardFormParam.isLoading = false;
      this.showAllPostForCreateJob = false;
      return;
    } else {
      this.createJobcardPanelFixedPanel = false;
      this.createJobCardFormParam.isLoading = false;
      this.workCentrePostList = response.payload.records.map(post => { return post.name });
      this.initJobCardCreationPanel(false, jobNature);
    }
  }
  //----------------------------------------------------------------------------------------------------------------

  //SN attachment Part----------------------------------------------------------------------------------------------
  public attachment(): void {
    let path = '/sn/view/' + this.sn.snNumber + '/attachment';
    this.router.navigate([path]);
  }
  //----------------------------------------------------------------------------------------------------------------

  //HSD Service Report Part----------------------------------------------------------------------------------------------
  public toHaServiceReportPage(): void {
    const { userToken, selectedLanguage, userInfo } = Session;
    const hsd_url = new URL(environment.CCEP_HSD_WEB_HOST);

    if(!userToken){
      AppDelegate.navigate(['/login']);
    }

    const searchParams = new URLSearchParams();
    searchParams.set("t", userToken);
    searchParams.set("name", userInfo.name);
    searchParams.set("lang", selectedLanguage);
    searchParams.set("snNumber", this.sn.snNumber);

    const new_hsd_url = new URL(`${hsd_url.origin}/attachment?${searchParams}`);

    this.toHsdWebPlatform(new_hsd_url);
  }

  private toHsdWebPlatform(url){
    window.location.assign(url);
  }

  //E Form Part----------------------------------------------------------------------------------------------
  public showEFormDetail(): void {
    let path = '/sn/view/' + this.sn.snNumber + '/eForm';
    this.router.navigate([path]);
  }
  //----------------------------------------------------------------------------------------------------------------

  //SN associate Part---------------------------------------------------------------------------------------------------
  public openSnAssociatePanel(): void {
    this.initSnAssociatePanel();
    this.associateSnPanel.toggle();
  }

  public async initSnAssociatePanel() {
    this.isAssociateSnPanelInit = true;
  }

  public async closeSnAssociatePanel(event) {
    this.associateSnPanel.close();
    await this.requestSn();
    await this.initActionButton();
  }
  //----------------------------------------------------------------------------------------------------------------

  //==========================================================================
  // getter
  get isPendingApproval(): boolean {
    if (!this.sn) { return false; }

    let isPendingApproval = this.sn.pendingApproval ? true : false;
    if (this.sn.jobCardPendingApprovalList && this.sn.jobCardPendingApprovalList.length) {
      for (let item of this.sn.jobCardPendingApprovalList) {
        if (item.pendingApproval) {
          isPendingApproval = true;
          break;
        }
      }
    }

    return isPendingApproval;
  }

  onLanguageChanged() {

  }
}
