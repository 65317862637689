import { Component, Injector, OnInit, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { PmJobService } from '@services/pm-job.service';

@Component({
    selector: 'app-pm-job-equipment-information',
    templateUrl: './pm-job-equipment-information.component.html',
    styleUrls: []
})
export class PmJobEquipmentInformationComponent extends BasePage implements OnInit {
    @Input() periodId: string;
    @Input() pmJobNumber: string;
    @Input() pageMode: JMENUM.JMPageMode;
    @Input() selectedEquipmentsNumber: number;

    pmJobService: PmJobService

    editable: any = {};

    totalEquipments : number = 0;
    JMPageMode = JMENUM.JMPageMode;

    constructor(injector: Injector, pmJobService: PmJobService) {
        super(injector);
        this.pmJobService = pmJobService;
    }

    ngOnInit() {
        this.fieldsControl();
    }

    ngOnChanges(changes:SimpleChanges){
        if(changes.periodId){
            if(changes.periodId.currentValue) {
                this.requestEquipmentInformation(changes.periodId.currentValue);
            } else {
                this.totalEquipments = 0;
            }
        }
    }

    private fieldsControl(){
        this.editable = {
        }
        // this.mandatory = {...this.editable};
    }

    private async requestEquipmentInformation(periodId : string) {
        let response = await this.pmJobService.getPmJobUnassignedEquipmentList(periodId);
        if(response && response.payload &&response.payload.records ){
            this.totalEquipments = response.payload.records.length;
        }
    }

    equipmentText() {
        // (selectedEquipments ? selectedEquipments : 0) + ' of ' + totalEquipments + ' selected'
        let selected = this.selectedEquipmentsNumber ? this.selectedEquipmentsNumber : 0;
        return JMLanguage.translate('component.equipment-information.number-of-equipments', [selected, this.totalEquipments]);
    }

    onDetailBtnClick = (e:MouseEvent) => {
        e.preventDefault();
        this.router.navigateByUrl(
            `/pm/job/equipment-list/view/${this.periodId}/${this.pmJobNumber}`
          );
    }

}