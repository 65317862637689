<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle"></app-nav>

  <app-sidebar [type]="'personal'"></app-sidebar>

  <!-- Page Content -->
  <div id="content">
    <div class="row justify-content-around">

      <!-- Left Column -->
      <div class="col-12 col-xl-6">
        <!-- <h4 class="title">
          {{"pages.posting.acting-appointment" | translate}}
        </h4>
        <div class="request-window p-2">
          <div class="row justify-content-start no-gutters period-panel mt-1">
            <div>
              <input 
                #start="ngbDatepicker"
                id="settings-posting_acting-start_datepicker" 
                class="form-control date-picker-input-box" 
                placeholder="{{'pages.posting.start-date' | translate}}" 
                name="start"
                ngbDatepicker 
                readonly
                [(ngModel)]="startDate" 
                (click)="start.toggle()" 
              >
            </div>
            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <input 
                #end="ngbDatepicker" 
                id="settings-posting_acting-end_datepicker" 
                class="form-control date-picker-input-box" 
                placeholder="{{'pages.posting.end-date' | translate}}" 
                name="end"
                ngbDatepicker 
                readonly
                [(ngModel)]="endDate" 
                (click)="end.toggle()" 
              >
            </div>
          </div>
          <ng-select 
            class="my-2"
            placeholder="{{'pages.posting.acting-officer' | translate}}" 
            bindLabel="description"
            [items]="actingOfficers" 
            [searchable]="false"
            [clearable]="false" 
            [(ngModel)]="selectedOfficer" 
            [ngModelOptions]="{standalone: true}" 
          ></ng-select>
          <div class="row mt-2">
            <div class="col-md d-flex justify-content-center">
              <app-submit-button 
                [id]="'setting-posting_acting-submit_button'"
                [text]="'pages.posting.submit'"
                [buttonClass]="'standard brand-amber'"
                [iconClass]="'fa-check'"
                [isDisabled]=""
                [isLoading]=""
                (onClick)="onSubmitBtnClicked()" 
              ></app-submit-button>
            </div>
          </div>
        </div> -->
        <h4 class="title">{{"pages.posting.upcoming-schedule" | translate}}</h4>
        <app-table [dataSource]="scheduleTableSource" [columns]="scheduleTableColumns"></app-table>
      </div>

      <!-- Right Column -->
      <div class="col-12 col-xl-6">
        <h4 class="title">
          {{"pages.posting.posting-history" | translate}}
        </h4>
        <app-table [dataSource]="postingHistorytableSource" [columns]="postingHistoryTableColumns"></app-table>
      </div>
    </div>
  </div>
</div>