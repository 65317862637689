import { Component, Injector, OnInit } from '@angular/core';
import { ColumnType } from '@enum/table-column-type';
import { Session } from '@services/session';
import { TableHelper } from 'src/app/ui/components/table/table-helper';
import { BasePage } from 'src/app/ui/model/base/base';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-posting',
  templateUrl: './posting.component.html',
  styleUrls: ['./posting.component.scss']
})
export class PostingComponent extends BasePage implements OnInit {

  pageTitle: string;

  postingHistoryTableColumns: any[];
  postingHistorytableSource: any;
  scheduleTableColumns: any[];
  scheduleTableSource: any;

  // currentType = "leave";

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.posting.page-title", [Session.selectedWorkCentre]);    
    this.initTable();
    this.gePostingHistoryInformation();
    this.getScheduleInformation();
  }

  initTable() {
    this.postingHistoryTableColumns = [
      TableHelper.createTableColumn("date", "pages.posting.date", ColumnType.Text, "acting-date_text", 50),
      TableHelper.createTableColumn("type", "pages.posting.type", ColumnType.Text, "acting-type_text", 50),
      TableHelper.createTableColumn("description", "pages.posting.description", ColumnType.Text, "description_text")

    ]

    this.scheduleTableColumns = [
      TableHelper.createTableColumn("start", "pages.posting.start-date", ColumnType.Text, "start-date_text"),
      TableHelper.createTableColumn("end", "pages.posting.end-date", ColumnType.Text, "end-date_text"),
      TableHelper.createTableColumn("officer", "pages.posting.acting-officer", ColumnType.Text, "acting-off_text"),
      TableHelper.createTableColumn("postActed", "pages.posting.post-acted", ColumnType.Text, "post-acted_text"),
      TableHelper.createTableColumn("action", "pages.posting.action", ColumnType.Button, "cancel_button")
    ]
  }

  gePostingHistoryInformation() {
    //TODO 
    //waiting for structure design
    //do not know come from which service
    let templateInformation = [
      { date: "1/5/19 to 10/5/19", type: "Acting", description: "Acting onWS1 behalf" },
      { date: "14/5/19 to 17/5/19", type: "Leave", description: "WS2 acting on behalf of you" }
    ];

    this.postingHistorytableSource = TableHelper.createTableSource(templateInformation.length, templateInformation, "", "setting-posting_posting-history_table");
  }

  getScheduleInformation() {
    //TODO 
    //waiting for structure design
    //do not know come from which service
    let rejectButton = TableHelper.createButton(this.onButtonClicked, "reject_button", "glyph brand-red", "fas fa-times", null, "reject");

    let templateInformation = [
      { start: "21/5/19", end: "24/5/19", officer: "User1", postActed: "WS1", action: [rejectButton] },
    ];

    this.scheduleTableSource = TableHelper.createTableSource(templateInformation.length, templateInformation, "", "setting-posting_schedule_table");
  }

  onButtonClicked = (type) => {
    //TODO
    let message = "";
    let handler: any;
    switch (type) {
      case "accept":
        message = "Do you want to accept it?"
        handler = () => { console.log("Accept it") };
        break;
      case "reject":
        message = "Do you want to reject it?"
        handler = () => { console.log("reject it") };
        break;
      case "acknowledge":
        message = "Do you want to acknowledge it?"
        handler = () => { console.log("acknowledge it") };
        break;
      default:
        break;
    }
    let buttons = [
      {
        name: this.translateService.instant("global.yes"),
        handler: handler
      },
      {
        name: this.translateService.instant("global.no")
      }
    ]
    this.showPopUpAlert(message, "", buttons);
  }
  // onTabClicked(type:string){
  //   this.currentType = type;
  // }

  optionClicked(group: any, option: any) {
    group.value = option;
  }

  onSubmitBtnClicked() {
    this.openSnackBar("submitted");

  }

}
