import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { pad } from 'src/lib/presenter/Formatter';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements OnInit {
  @Input() timeStringHandler?: (timeString: string) => string = null;
  @Input() eventHandler?: (event: any) => Event = null;
  @Input() customClass = undefined;
  @Input() field = undefined; //field name
  @Input() input = undefined; //ngModel
  @Input() inputId = undefined; // id for HTML use
  @Input() alertMsg = undefined;
  @Input() isError = undefined;
  @Input() disabled = undefined;
  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  errorFields: boolean;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(){
    this.errorFields = this.isError;
  }

  inputNumberWithIndex(event) {
    event.target.value = event.target.value.replace(/[^\d:]/g, '')
  }

  onChangeTime(time){
    let regex = "^([01]?[0-9]|2[0-3])([0-5][0-9]|[0-9])$";
    let timeString = time.replace(":", "");

    if (this.eventHandler != null) {
      timeString = this.eventHandler(timeString)
    }

    if(!timeString || timeString.length <= 0){
      this.input = undefined;
      this.onBlur.emit({field: this.field, data: this.input});
      return;
    }
    if(timeString.length <= 2 && timeString <= 24){
      timeString = pad(timeString.substring(0,2)) + '00';
    }else if(timeString.length <= 2 && timeString > 24){
      this.errorFields = true;
      AppDelegate.openSnackBar(this.alertMsg);
      return;
    }
    if(timeString.length == 3){
      timeString = pad(timeString.substring(0,1)) + pad(timeString.substring(1,3));
    }
    if(timeString.length == 5){
      timeString = timeString.substring(0,4);
    }

    if(timeString == '2400' || timeString.match(regex)){
      this.errorFields = false;
      if(this.timeStringHandler){
        timeString = this.timeStringHandler(timeString)
      }
      this.input = timeString.substring(0,2) + ':' + timeString.substring(2,4);

      this.onBlur.emit({field: this.field, data: this.input});
    }else{
      this.errorFields = true;
      AppDelegate.openSnackBar(this.alertMsg); //Constants.SYSTEM_NAME == JMENUM.SourceSystem.CCEPJM
      this.onBlur.emit({field: this.field, data: undefined});
      // Other System Use
      // AppDelegate.toastMsg().showMsg(this.alertMsg);
    }

  }
}
