<nav #actionSideBar id="action-sidebar">
  <ul class="list-unstyled components" (scroll)="onScroll($event)">
    <ng-container *ngFor="let item of buttons;let i = index">
      <li #subMenu *ngIf="showSubButtonId === item.buttonId && item.subButtons?.length" class="sub-btn-container" >
        <ul class="list-unstyled">
          <li *ngFor="let subButton of item.subButtons;let j = index">
            <button class="btn sub-btn" id="{{subButton.buttonId}}" (click)="click($event, subButton)" 
              [disabled]="disabled || !subButton.isEnable"
              [ngClass]="{'d-none': !subButton.isShow, 'd-block': subButton.isShow }">
              <img src="{{subButton.imageUrl}}" alt="{{subButton.actionDescription | translate}}">
              <div class="btn-desc">{{subButton.actionDescription | translate}}</div>
            </button>
          </li>
        </ul>
      </li>
      <li>
        <button #actionBtn class="btn" id="{{item.buttonId}}" (click)="click($event, item)" 
          [disabled]="disabled || !item.isEnable" [ngClass]="{'d-none': !item.isShow, 'd-block': item.isShow }">
          <img [class]="item.class ? ' ' + item.class : ''" src="{{item.imageUrl}}" alt="{{item.actionDescription | translate}}">
          <div class="btn-desc">{{item.actionDescription | translate}}</div>
        </button>
      </li>
    </ng-container>
  </ul>
</nav>