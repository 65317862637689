import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';
import { Session }  from 'src/app/services/session';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';

const COVERED = "covered";
const ALL = "*";

@Component({
  selector: 'routing-rule-criteria-form',
  templateUrl: './routing-rule-criteria-form.component.html',
  styleUrls: ['./routing-rule-criteria-form.component.scss']
})

export class RoutingRuleCriteriaFormComponent extends BasePage implements OnInit {
  errorFields: any = {};
  uiErrorMessage = [];

  originalCoverage: any = null;
  clientList: any[] = [];
  selectedClient: any = null;

  districtList: any[] = [];
  selectedDistrict: any = null;

  locationList: any[] = [];
  selectedLocation: any[] = null;

  equipmentCategoryList: any[] = [];
  selectedEquipmentCategory: any = null;

  equipmentTypeList: any[] = [];
  selectedEquipmentType: any[] = null;

  isDistrictDisable: Boolean = true;
  isLocationDisable: Boolean = true;
  isEquipmentTypeDisable: Boolean = false;

  isLoadingLocation: Boolean = false;

  districtCodeList: any[] = [];
  locationOptionDict: any = {}; // key: districtCode, value: array of location Option

  servicesCoverageList: any[] = [];

  @Output() onSave = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();

  constructor(
    injector: Injector,
  ) {
    super(injector)
  }

  ngOnInit() {
    this.lang = Session.selectedLanguage;
    // this.requestLocationSummaryAsync();
  }

  initData() {
    this.originalCoverage = null;
    this.isDistrictDisable = true;
    this.isLocationDisable = true;
    this.isEquipmentTypeDisable = false;
    this.selectedClient = null;
    this.selectedDistrict = null;
    this.selectedLocation = null;
    this.selectedEquipmentCategory = null;
    this.selectedEquipmentType = null;

    this.errorFields = {};
    this.uiErrorMessage = [];
    
    this.setClientOption();
    this.setDistrictOption();
    this.setEquipmentCategoryOption();
    this.setEquipmentTypeOption();
  }

  setServicesCoverageList(servicesCoverageList) {
    this.servicesCoverageList = servicesCoverageList;
  }

  private setClientOption() {
    let options = [];
    JM.JMConnector.getAllClient().map(client => {
      options.push({
        label:client.clientShortName,
        value:client.clientShortName
      });
    });
    this.clientList = options;
  }

  private setDistrictOption() {
    let options = [];
    JM.JMConnector.getAllDistrictCode().map(code => {
      this.districtCodeList.push(code);
      options.push({
        label:this.translateService.instant('district.' + code),
        value:code
      });
    });
    this.districtList = options;
  }

  private async setLocationOption() {
    this.isLocationDisable = true;
    if(this.selectedDistrict) {
      let district = this.selectedDistrict.value;
      if (district) {
        if (!this.locationOptionDict[district] || this.locationOptionDict[district].length == 0) {
          // if location option not found, call getLocationSummary api
          await this.requestLocationSummaryAsync();
        }
        if (this.locationOptionDict[district] && this.locationOptionDict[district].length > 0){
          this.locationList = this.locationOptionDict[district];
          this.isLocationDisable = false;
        }
      }
    }
  }

  private setEquipmentCategoryOption() {
    let options = [];
    JM.JMConnector.getAllEquipmentCategoryCode().map(code => { 
      let obj = JM.JMConnector.getEquipmentCategory(code);
      options.push({
        label:code + ' - ' + obj.description[Session.selectedLanguage],
        value:code
      });
    });
    let coveredObj = {
      label: this.translateService.instant('pages.routing-rules.slider-panel.covered-equipment-types'),
      value: COVERED
    };
    let allObj = {
      label:this.translateService.instant('pages.routing-rules.slider-panel.all-categories-types'),
      value: ALL
    };
    options.push(coveredObj);
    options.push(allObj);
    this.equipmentCategoryList = options;
  }

  private setEquipmentTypeOption() {
    let options = [];
    let category = this.selectedEquipmentCategory;
    if (category) {
      if(category.value == COVERED || category.value == ALL) {
        this.equipmentTypeList = null;
        this.isEquipmentTypeDisable = true;
      }else{
        JM.JMConnector.getEquipmentTypeCodeFromCategory(category.value).map(code => { 
          let obj = JM.JMConnector.getEquipmentType(code);
          options.push({
            label:code + ' - ' + obj.description[Session.selectedLanguage],
            value:code
          });
        });
      }
    } else {
      JM.JMConnector.getAllEquipmentTypeCode().map(code => { 
        let obj = JM.JMConnector.getEquipmentType(code);
        options.push({
          label:code + ' - ' + obj.description[Session.selectedLanguage],
          value:code
        });
      });
    }
    this.equipmentTypeList = options;
  }

  private setOriginalCoverage(originalCoverage){
    if(originalCoverage){
      this.originalCoverage = originalCoverage;
    } else {
      this.originalCoverage = null;
    }
  }

  private setClient(client) {
    if(client) {
      this.selectedClient = {
        label:client,
        value:client
      };
    }else{
      this.selectedClient = null;
    }
  }

  private setDistrict(districtCode) {
    if(districtCode) {
      let array = JM.JMConnector.getAllDistrictCode();
      for(let i=0;i<array.length;i++) {
        let code = array[i];
        if(districtCode == code) {
          this.selectedDistrict = {
            label:this.translateService.instant('district.' + code),
            value:code
          };
          this.isDistrictDisable = false;
          break;
        }
      }
    }else{
      this.selectedDistrict = null;
    }
  }

  private async setLocation(locations) {
    this.selectedLocation = null;

    if(locations && locations.length > 0) {
      locations = Array.from(new Set(locations));
      let option = [];

      let hasLocation = true;
      if(Object.keys(this.locationOptionDict).length == 0) {
        hasLocation = false;
      }else{
        let tmpList = [];
        for (let code in this.locationOptionDict) {
          let array = this.locationOptionDict[code];
          for (let i=0;i<array.length;i++) {
            tmpList.push(array[i].value);
          }
        }
        if(locations.some(v => tmpList.indexOf(v) < 0)) {
          hasLocation = false;
        }
      }
      if(!hasLocation) {
        // if location option not found, call getLocationSummary api
        await this.requestLocationSummaryAsync();
      }

      for (let code in this.locationOptionDict) {
        let array = this.locationOptionDict[code];
        for (let i=0;i<array.length;i++) {
          if(locations.includes(array[i].value)) {
            option.push(array[i]);
            this.isLocationDisable = false;
          }
        }
      }
      option = Array.from(new Set(option));
      this.selectedLocation = option;
    }
  }

  private setEquipmentCategory(categoryCode) {
    if(categoryCode == COVERED) {
      this.equipmentTypeList = null;
      this.isEquipmentTypeDisable = true;

      this.selectedEquipmentCategory = {
        label: this.translateService.instant('pages.routing-rules.slider-panel.covered-equipment-types'),
        value: COVERED
      };

    }else if(categoryCode == ALL) {
      this.equipmentTypeList = null;
      this.isEquipmentTypeDisable = true;

      this.selectedEquipmentCategory = {
        label:this.translateService.instant('pages.routing-rules.slider-panel.all-categories-types'),
        value: ALL
      };

    }else if(categoryCode) {
      this.isEquipmentTypeDisable = false;

        let obj = JM.JMConnector.getEquipmentCategory(categoryCode);
        this.selectedEquipmentCategory = {
          label:categoryCode + ' - ' + obj.description[Session.selectedLanguage],
          value:categoryCode
        };

    }else{
      this.selectedEquipmentCategory = null;
    }
  }

  private setEquipmentType(types) {
    if(types && types.length > 0) {
      let options = [];
      for(let i=0;i<types.length;i++) {
        let code = types[i];
        let obj = JM.JMConnector.getEquipmentType(code);
        options.push({
          label:code + ' - ' + obj.description[Session.selectedLanguage],
          value:code
        });
      }
      this.selectedEquipmentType = options;
    }else{
      this.selectedEquipmentType = null;
    }
  }

  private async requestLocationSummaryAsync() {
    await this.requestLocationSummaryWithCode([this.selectedDistrict.value]);
  }

  private async requestLocationSummaryWithCode(districtCodeList) {
    if (!districtCodeList.length)  { return; }

    let newDistrictCodeList = [];
    for (const districtCode of districtCodeList) {
      if (!this.locationOptionDict[districtCode] || this.locationOptionDict[districtCode].length == 0) {
        newDistrictCodeList.push(districtCode);
      }
    }
    this.isLoadingLocation    = true;

    const batchSize           = 2000;

    let request               = new JM.JMRequestLocationsLocationSummary();
    request.active            = JMENUM.RequestActive.ACTIVE;
    request.parameters        = ["code", "description."+Session.selectedLanguage, "districtCode"];
    request.districtCode      = newDistrictCodeList;
    request.locationOnly      = true;
    request.pageSize          = batchSize;
    request.pageNumber        = 1;

    let response = await JM.JMConnector.sendLocationsLocationSummaryPromise(request);

    if (response.code == 200) {
      for (let ll of response.payload.records) {
        this.setLocationToCache(ll);
      }

      // if more than batchSize
      let totalCount = response.payload.totalCount;
      let promiseArray = [];

      for (let i = 2; i <= Math.ceil(totalCount / batchSize); i++) {
        let request2          = new JM.JMRequestLocationsLocationSummary();
        request2.active       = JMENUM.RequestActive.ACTIVE;
        request2.parameters   = ["code", "description."+Session.selectedLanguage, "districtCode"];
        request2.districtCode = newDistrictCodeList;
        request2.locationOnly = true;
        request2.pageSize     = batchSize;
        request2.pageNumber   = i;
        promiseArray.push(JM.JMConnector.sendLocationsLocationSummaryPromise(request2));
      }
      let responseArray = await Promise.all(promiseArray);
      for (let response of responseArray) {
        if (response.code == 200) {
          for (let ll of response.payload.records) {
            this.setLocationToCache(ll);
          }
        } else {
          console.error("error when caching all location: " + response.code + ": " + response.error);
        }
      }
    } else {
      console.error("error when caching all location: " + response.code + ": " + response.error);
    }

    this.isLoadingLocation = false;
  }

  private setLocationToCache(location) {
    let locationOption = {
      label: location.description[Session.selectedLanguage] + " (" + location.code + ")",
      value: location.code
    };
    if (this.locationOptionDict[location.districtCode]) {
      this.locationOptionDict[location.districtCode].push(locationOption);
    } else {
      this.locationOptionDict[location.districtCode] = [locationOption];
    }
  }

  private getLocation(districtCode, locationCode) {
    let array = (districtCode in this.locationOptionDict) ? this.locationOptionDict[districtCode] : null;
    if(array) {
      for(let i=0;i<array.length;i++) {
        if(array[i].value == locationCode) {
          return array[i];
        }
      }
    }
    return null;
  }

  private getAllValues() {
    let obj = {};
    obj["originalCoverage"] = this.originalCoverage;
    obj["client"] = this.selectedClient;
    obj["district"] = this.selectedDistrict;
    obj["location"] = this.selectedLocation;
    obj["equipmentCategory"] = this.selectedEquipmentCategory;
    obj["equipmentType"] = this.selectedEquipmentType;
    return obj;
  }

  checkMandatoryFields() {

    this.uiErrorMessage = [];
    let missingFieldNames = [];
    if(!this.selectedClient){
      this.errorFields.client = true;
      missingFieldNames.push(this.translate("pages.routing-rules.client"));
    }else{
      this.errorFields.client = false;
    }

    if(!this.selectedDistrict){
      this.errorFields.district = true;
      missingFieldNames.push(this.translate("pages.routing-rules.district"));
    }else{
      this.errorFields.district = false;
    }

    if(!this.selectedEquipmentCategory){
      this.errorFields.equipmentCategory = true;
      missingFieldNames.push(this.translate("pages.routing-rules.equipment-category"));
    }else{
      this.errorFields.equipmentCategory = false;
    }

    if (missingFieldNames.length) {
      let errMsg = this.translate("pages.routing-rules.please-input-mandatory") + missingFieldNames.join(", ");
      this.uiErrorMessage.push(errMsg);
      // this.openSnackBar(errMsg);
      return false;
    }
    return true;
  }

  onClickSubmit() {
    if(this.checkMandatoryFields()){
      this.onSave.emit();
    }
  }

  onClickClose() {
    this.onClose.emit();
  }

  onSelectClient() {
    if(this.selectedClient) {
      this.isDistrictDisable = false;
    }else{
      this.selectedDistrict = null;
      this.isDistrictDisable = true;

      this.selectedLocation = null;
      this.isLocationDisable = true;
    }
  }

  async onSelectDistrict() {
    this.selectedLocation = null;
    this.isLocationDisable = true;
    
    if(this.selectedDistrict) {
      let district = this.selectedDistrict.value;
      if (district) {
        if (!this.locationOptionDict[district] || this.locationOptionDict[district].length == 0) {
          // if location option not found, call getLocationSummary api
          await this.requestLocationSummaryAsync();
        }
        if (this.locationOptionDict[district] && this.locationOptionDict[district].length > 0){
          this.locationList = this.locationOptionDict[district];
          this.isLocationDisable = false;
        }
      }
    }
  }

  onSelectLocation() {
  }

  onSelectEquipmentCategory() {
    this.isEquipmentTypeDisable = false;
    this.selectedEquipmentType = null;

    let options = [];
    let category = this.selectedEquipmentCategory;
    if (category) {
      if(category.value == COVERED || category.value == ALL) {
        this.equipmentTypeList = null;
        this.isEquipmentTypeDisable = true;

        // insert the value
        // let options2 = [];
        // if(category.value == ALL) {
        //   JM.JMConnector.getAllEquipmentTypeCode().map(code => { 
        //     let obj = JM.JMConnector.getEquipmentType(code);
        //     options2.push({
        //       label:code + ' - ' + obj.description[Session.selectedLanguage],
        //       value:code
        //     });
        //   });
        // }else if(category.value == COVERED) {
        //   for(let obj of this.servicesCoverageList) {
        //     let obj2 = JM.JMConnector.getEquipmentType(obj.equipmentType);
        //     options2.push({
        //       label:obj.equipmentType + ' - ' + obj2.description[Session.selectedLanguage],
        //       value:obj.equipmentType
        //     });
        //   }
        // }
        // this.selectedEquipmentType = options2;

      }else{
        JM.JMConnector.getEquipmentTypeCodeFromCategory(category.value).map(code => { 
          let obj = JM.JMConnector.getEquipmentType(code);
          options.push({
            label:code + ' - ' + obj.description[Session.selectedLanguage],
            value:code
          });
        });
      }
    } else {
      JM.JMConnector.getAllEquipmentTypeCode().map(code => { 
        let obj = JM.JMConnector.getEquipmentType(code);
        options.push({
          label:code + ' - ' + obj.description[Session.selectedLanguage],
          value:code
        });
      });
    }
    this.equipmentTypeList = options;
  }

  onSelectEquipmentType() {
    if (this.selectedEquipmentType && this.selectedEquipmentType.length > 0) {
      let code = JM.JMConnector.getEquipmentCategoryCodeFromType(this.selectedEquipmentType[0].value);
      if(code.length > 0 && ((!this.selectedEquipmentCategory || !this.selectedEquipmentCategory.value) || !code.includes(this.selectedEquipmentCategory.value))) {
        let obj = JM.JMConnector.getEquipmentCategory(code[0]);
        this.selectedEquipmentCategory = {
          label: code[0] + ' - ' + obj.description[Session.selectedLanguage],
          value: code[0]
        };
      }

      let category = this.selectedEquipmentCategory.value;
      let options = [];
      JM.JMConnector.getEquipmentTypeCodeFromCategory(category).map(code => { 
        let obj = JM.JMConnector.getEquipmentType(code);
        options.push({
          label:code + ' - ' + obj.description[Session.selectedLanguage],
          value:code
        });
      });
      this.equipmentTypeList = options;
    }else{
      // this.selectedEquipmentCategory = null;
      this.setEquipmentTypeOption();
    }
  }
}