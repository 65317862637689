<div class="header">
    <h3>{{ 'pages.sn.slider-panel.transfer-sn' | translate }}</h3>
    <div class="submit-wrap d-inline">
        <app-submit-button 
          [id]="'transfer-sn-panel_submit_button'"
          [text]="'global.submit'"
          [buttonClass]="'standard brand-amber'"
          [iconClass]="'fa-check'"
          [isDisabled]="!submitEnable || isLoading"
          [isLoading]="isLoading"
          (onClick)="onClickSubmit(team)" 
        ></app-submit-button>
    </div>
</div>

<div class="input-field-group ">
    <div class="dropdwon-group">
        <ng-select 
            id="list-sn_team-work-centre_dropdown"
            placeholder="{{'pages.sn.slider-panel.transfer-sn.work-centre' | translate }}"
            [items]="options"
            (change)="onTeamWorkCentreChanged()"
            [(ngModel)]="teamWorkCentre"
            [ngModelOptions]="{standalone: true}" >
        </ng-select>
    </div>
</div>

<div class="input-field-group ">
    <div class="dropdwon-group">
      <ng-select 
        id="list-sn_team_dropdown"
        bindLabel="label"
        bindValue="value"
        placeholder="{{'pages.sn.slider-panel.transfer-sn.team' | translate }}" 
        [searchFn]="onFilterTeamOptions"
        [items]="teamOptions"
        [disabled]="!teamWorkCentre"
        [loading]="isLoadingTeam"
        [ngModelOptions]="{standalone: true}"
        (scrollToEnd)="onTeamScrollToEnd()"
        (clear)="onClearTeam()"
        (search)="onSearchTeamOptions($event)"
        (change)="validateForm()"
        [(ngModel)]="team" >
      </ng-select>
    </div>
  </div>
