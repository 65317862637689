import { Component, Input,  OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import { Constants } from 'src/constants';
import * as FORMATTER from 'src/app/presenter/Formatter';

@Component({
  selector: 'create-team-form',
  templateUrl: './create-team-form.component.html',
  styleUrls: ['./create-team-form.component.scss']
})

export class CreateTeamFormComponent implements OnInit {
  
  uiEnableSaveButton:boolean = true;
  uiTitle:string = '';
  uiTeamName:string = ''
  uiTeamDescription:string = '';
  uiMode:"inhouse"|"contractor" = "inhouse";

  uiEnableFax:boolean = false;
  uiFax:string = '';

  uiEnableEmail:boolean = false;
  uiSelectedEmail:string[] = [];
  
  uiContractorNumber:string = undefined;
  contractArray:JMOBJ.MaintenanceTermContract[] = [];
  uiContractOption:{
    displayName: string,
    value:string
  }[] = [];
  uiInhouseHandlingTeam:string = undefined;
  uiInhouseHandlingTeamArray:JMOBJ.Team[] = [];
  
  uiLoadingContract:boolean = false;
  uiLoadingInhouseHandlingTeam:boolean = false;
  uiLoadingWorkCentreOfficer:boolean = false;

  uiErrorTeamName:string = undefined;
  uiErrorFax:string = undefined;
  uiErrorEmail:string = undefined;
  uiErrorContract:string = undefined;
  uiErrorInhouseHandlingTeam:string = undefined;

  workCentreOfficerArray:string[] = undefined;
  workCentre:JMOBJ.WorkCentre = undefined;
  handlingParty:JMENUM.HandlingParty = undefined;

  @Input() parameters: {
    workCentre: string,
    onSaveButtonClicked: Function,
  };

  

  ngOnInit() {
    
  }

  //----
  clearError() {
    this.uiErrorTeamName = '';
    this.uiErrorFax = '';
    this.uiErrorEmail = '';
    this.uiErrorContract = '';
    this.uiErrorInhouseHandlingTeam = '';
  }
  clear() {
    this.uiTeamName = "";
    this.uiTeamDescription = "";
    this.uiEnableFax = false;
    this.uiFax = "";
    this.uiEnableEmail = false;
    this.uiSelectedEmail = [];
    
    this.uiContractorNumber = "";
    this.contractArray = [];
    this.uiContractOption = [];
    this.uiInhouseHandlingTeam = "";
    this.uiInhouseHandlingTeamArray = [];
    this.uiLoadingContract = false;

    this.clearError();
  }

  setMode(mode:"inhouse"|"contractor") {
    
    this.uiMode = mode;
    this.requestWorkCentre();
    if (this.uiMode == "inhouse") {
      this.uiTitle = JMLanguage.translate("create-team-form.title.inhouse",  [this.parameters.workCentre]);
    } else {
      this.uiTitle = JMLanguage.translate("create-team-form.title.contractor",  [this.parameters.workCentre]);
    }
  }

  validateInput() { 
    this.clearError();

    // validate
    let valid = true;
    if (!this.uiTeamName) {
      this.uiErrorTeamName = JMLanguage.translate("create-team-form.error.invalid-team-name");
      valid = false;
    }
    if (this.uiEnableFax && !this.uiFax) {
      this.uiErrorFax = JMLanguage.translate("create-team-form.error.invalid-fax");
      valid = false;
    }
    if (this.uiEnableEmail && this.uiSelectedEmail.length == 0) {
      this.uiErrorEmail = JMLanguage.translate("create-team-form.error.invalid-email");
      valid = false;
    }
    if(this.uiMode == 'contractor') {
      if (!this.uiContractorNumber) {
        this.uiErrorContract = JMLanguage.translate("create-team-form.error.empty-contract");
        valid = false;
      }
      if (!this.uiInhouseHandlingTeam) {
        this.uiErrorInhouseHandlingTeam = JMLanguage.translate("create-team-form.error.empty-inhouse-handling-team");
        valid = false;
      }
    }

    return valid;
   }

  //----
  async requestNewTeam() {
    // send request
    const request = new JM.JMRequestTeamsCreateTeam();
    request.operationPeriod = this.workCentre.defaultOperationPeriod;
    request.workCentre = this.parameters.workCentre;
    request.description = this.uiTeamDescription;
    request.name = this.uiTeamName;
    request.isActive = true;

    if(this.uiMode == 'inhouse') {
      request.handlingParty = JMENUM.HandlingParty.INHOUSE;
      request.responseTime = {
        "1": this.workCentre.defaultNoResponseTime["1"],
        "2": this.workCentre.defaultNoResponseTime["2"],
        "3": this.workCentre.defaultNoResponseTime["3"],
        "pm": this.workCentre.defaultNoResponseTime["3"]
      }
    } else {
      let handlingTeam = this.uiInhouseHandlingTeamArray.find(team => { return team._id == this.uiInhouseHandlingTeam});
      let selectedContract = this.contractArray.find(c => c.contractNumber == this.uiContractorNumber);
      
      request.handlingParty = (selectedContract.isPmsmcContract)? JMENUM.HandlingParty.PMSMC: JMENUM.HandlingParty.NON_PMSMC;
      request.contractNumber = this.uiContractorNumber;
      request.handlingTeam = this.uiInhouseHandlingTeam;
      request.contactSequences = handlingTeam.contactSequences;
      
      request.responseTime = handlingTeam.responseTime;
      request.responseTime["1"][0] = this.workCentre.defaultNoResponseTime["1"][0];
      request.responseTime["2"][0] = this.workCentre.defaultNoResponseTime["2"][0];
      request.responseTime["3"][0] = this.workCentre.defaultNoResponseTime["3"][0]; 
      request.responseTime["pm"][0] = this.workCentre.defaultNoResponseTime["3"][0];

    }

    
    request.members = [];
    request.fax = this.uiFax;
    request.enableFax = this.uiEnableFax;
    request.email = this.uiSelectedEmail;
    request.enableEmail = this.uiEnableEmail;
    request.isRoTeam = false;
    request.enableManualInstructions = false;

    const response: JM.JMResponseTeamsCreateTeam = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate("global.added"));
    this.parameters.onSaveButtonClicked();
  }

  async requestWorkCentre() {
    this.uiLoadingContract = true;

    const request = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.parameters.workCentre];
    request.active = JMENUM.RequestActive.ACTIVE;
    request.includeSummary = true;

    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || response.payload.records.length == 0) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.workCentre = response.payload.records[0];
    this.requestInhouseTeam();

    if (this.uiMode === 'contractor') {
      this.requestContractList();
    }
  }

  async requestContractList() {
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.expired = JMENUM.RequestExpired.UNEXPIRED;
    request.workCentres = [this.parameters.workCentre];

    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.contractArray = response.payload.records;
    this.uiContractOption = [];
    for (let contract of this.contractArray) {
      this.uiContractOption.push({
        displayName: FORMATTER.formatContractNumber(contract),
        value: contract.contractNumber
      });
    }
    this.uiLoadingContract = false;
  }

  async requestInhouseTeam() {
    const request = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['_id', 'name', 'operationPeriod', 'responseTime', 'contactSequences', 'isRoTeam'];
    request.handlingPartyList = [JMENUM.HandlingParty.INHOUSE];
    request.workCentreList = [this.parameters.workCentre];
    request.active = JMENUM.RequestActive.ACTIVE;
    request.includeApprovedWorkCentre = false;
    request.includeSummary = true;
    request.pageSize = 1000;
    request.pageNumber = 1;
    
    this.uiLoadingInhouseHandlingTeam = true;
    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    this.uiLoadingInhouseHandlingTeam = false;
    if (!response || !response.code || response.code != 200 || !response.payload || response.payload.records.length == 0) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.uiInhouseHandlingTeamArray = response.payload.records.filter(t => { return t.isRoTeam == false }); // filter out ro team
  }

  async requestWorkCentreOfficer() {
    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.active = JMENUM.RequestActive.ACTIVE;
    request.authorizations = {'workCenters': this.parameters.workCentre};
    request.includeSummary = true;
    request.pageSize = 1000;
    request.pageNumber = 1;

    this.uiLoadingWorkCentreOfficer = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.uiLoadingWorkCentreOfficer = false;
    if (!response || !response.code || response.code != 200 || !response.payload || response.payload.records.length == 0) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.workCentreOfficerArray = [];
    for( let post of response.payload.records) {
      this.workCentreOfficerArray.push(post.name);
    }
  }

  //----
  // button  callback
  onEnableFaxChanged(e) {    
    
  }

  onEnableEmailChanged(e) {
    if (this.uiEnableEmail && this.workCentreOfficerArray == undefined) {
      this.requestWorkCentreOfficer();
    }
  }

  onSaveButtonClicked(e) {
    if(this.validateInput()) {
      this.requestNewTeam();
    }    
  }

}