<div *ngIf="jobCard.ccsLastFailedAction" class="alert-bar-wrap px-3 py-2 font-size-l px mb-3">
  {{ jobCard.ccsErrorMessage }}
</div>

<div class="row">
  <div class="col-xl-6 col-12">
    <app-jobcard-job-details #sectionJobDetails
      class="d-block mb-4"
      [jobCard]="jobCard"
      [componentParameters]="componentParameters"
      [pageMode]="pageMode.EDIT"
      (updateJobCardData)="updateJobCardData($event)"
    ></app-jobcard-job-details>

    <app-jobcard-client-information
      class="d-block mb-4"
      [jobCard]="jobCard"
      [componentParameters]="componentParameters" 
      [pageMode]="pageMode.EDIT"
    ></app-jobcard-client-information>

    <app-jobcard-equipment-information
      class="d-block mb-4"
      [jobCard]="jobCard"
      [componentParameters]="componentParameters"
    ></app-jobcard-equipment-information>
  </div>
  
  <div class="custom-tab-content col-xl-6 col-12" >
    <app-jobcard-task-progress
      class="d-block"
      [jobCard]="jobCard"
      [componentParameters]="componentParameters"
      [pageMode]="pageMode.EDIT"
    ></app-jobcard-task-progress>
    <app-jobcard-progress-details
      class="d-block mb-4"
      [jobCard]="jobCard"
      [pageMode]="pageMode.EDIT"
    ></app-jobcard-progress-details>
    <app-jobcard-ccs-information
      class="d-block mb-4"
      [jobCard]="jobCard"
      [componentParameters]="componentParameters"
      [pageMode]="pageMode.EDIT"
    ></app-jobcard-ccs-information>
    <app-attachment-detail
      *ngIf="jobCard.attachments && jobCard.attachments.length > 0"
      [jobCard]="jobCard"
      [page]="JMPage.JOBCARD"
      [currentPageMode]="pageMode.EDIT"
    ></app-attachment-detail>
  </div>
</div>
