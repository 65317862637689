<div id="slider-panel">
  <div *ngIf="title" class="slider-pabel-title m-2">
    {{title | translate}}
  </div>
  <div *ngFor="let group of displayGroups;let i = index">
    <ng-container *ngIf="group.type == sliderPanelRowType.Text">
      <div id="{{group.id}}" class="{{group.css}}" [ngClass]="{'default-text-container': !group.css}">
        <h4>
          {{group.description | translate}}
        </h4>
      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.RadioButton" >
      <div class="form-group justify-content-start group-container">
        <label *ngIf="group.description" class="font-size-xs">
          {{group.description | translate}}
        </label >
        <ng-container *ngFor="let option of group.options">
          <div class="radio-container">
            <input 
              id="{{panelId}}-{{group.id}}-{{option.id}}_radio" 
              type="radio" 
              [(ngModel)]="group.selected"
              name="{{group.group}}" 
              value="{{option.value}}"
              (click)="radioButtonClicked(group);onInputInformationChanged()"
            >
            <label *ngIf="option.label" for="{{panelId}}-{{group.id}}-{{option.id}}_radio" class="mb-0">
              {{option.label | translate}}
            </label >
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.Checkbox">
      <div class="row checkbox-container custom-control custom-checkbox">
        <input type="checkbox" 
          class="custom-control-input"
          id="{{panelId}}-{{group.id}}_checkbox"
          name="{{group.group}}" 
          (click)="onInputInformationChanged()"
          [(ngModel)]="group.value"
          [disabled]="group.isDisabled">
        <label 
          class="custom-control-label {{group.css}}"
          for="{{panelId}}-{{group.id}}_checkbox" >
          {{group.description | translate}}
        </label>
      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.FieldsetCheckbox">
      <fieldset class="row custom-fieldset checkbox-container">
        <legend>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" 
                class="custom-control-input"
                id="{{panelId}}-{{group.id}}_checkbox"
                name="{{group.group}}" 
                (click)="onInputInformationChanged()"
                [(ngModel)]="group.value"
                [disabled]="group.isDisabled">
              <label 
                class="custom-control-label"
                for="{{panelId}}-{{group.id}}_checkbox" >
                {{group.description | translate}}
              </label>
            </div>
        </legend>
        <div class="row">
          <input 
            id="{{panelId}}-{{group.id}}_input" 
            class="form-control my-0" 
            type="text" 
            aria-label="" 
            placeholder="{{group.inputPlaceholder}}"
            [disabled]="!group.value"
            [(ngModel)]="group.inputValue" 
            (blur)="onInputInformationChanged()"
            aria-describedby="basic-addon1">
        </div>
      </fieldset>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.Input">
      <div class="form-group justify-content-start group-container">
        <label *ngIf="group.description" class="font-size-xs" for="{{panelId}}-{{group.id}}_input">
          {{group.description | translate}}
        </label >
        
        <input
          id="{{panelId}}-{{group.id}}_input"  
          class="form-control " 
          type="text" 
          aria-label="" 
          placeholder="" 
          maxlength="{{group.maxlength}}"
          disabled="{{!group.enable}}"
          [(ngModel)]="group.value" 
          (keyup)="group.callback ? group.callback($event) : null"
          (blur)="onInputInformationChanged()"
          aria-describedby="basic-addon1">
        
      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.DropDown">
      <div class="form-group justify-content-start group-container">
        <label *ngIf="group.description" class="font-size-xs" for="{{panelId}}-{{group.id}}_dropdown">
          {{group.description | translate}}
        </label >
        <div *ngIf="group.remarks">
          <span *ngFor="let remark of group.remarks;let i = index" >
            <span class="list-remarks d-inline-flex align-middle pr-4 font-size-xs">
            {{remark | translate}}
            </span><br>
          </span>
        </div>
        <ng-select class="" *ngIf="!group.enableMultipleTypeToSearch" id="{{panelId}}-{{group.id}}_dropdown" 
          bindLabel="description"
          bindValue="value"
          disabled="{{!group.enable}}"
          [items]="group.options"
          [multiple]="group.isMultiple" 
          [closeOnSelect]="true"
          [clearable]="true"
          [(ngModel)]="group.value"
          placeholder="{{(group.placeholder ? (group.placeholder|translate) : '')}}"
          (change)="onInputInformationChanged();group.onChange(group)"
          (focus)="group.onFocus(group)"
          [class.dropdown-error]="group.error"
        >
        </ng-select>
        <ng-select class="" *ngIf="group.enableMultipleTypeToSearch" id="{{panelId}}-{{group.id}}_dropdown" 
          bindLabel="description"
          bindValue="value"
          disabled="{{!group.enable}}"
          [items]="group.options"
          [multiple]="group.isMultiple" 
          [closeOnSelect]="true"
          [clearable]="true"
          [(ngModel)]="group.value"
          placeholder="{{(group.placeholder ? (group.placeholder|translate) : '')}}"
          (change)="onInputInformationChanged();group.onChange(group)"
          (focus)="group.onFocus(group)"
          (search)="onDropDownMultipleTypeToSearch(group, $event)"
          [searchFn]="dropDownMultipleTypeToSearchFilter"
          [class.dropdown-error]="group.error"
        >
        </ng-select>
        <span *ngIf="group.errorMessage" class="dropdown-errorMessage"> {{group.errorMessage}} </span>

      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.Button">
      <div class="row group-container no-gutters"
        [ngClass]="{'justify-content-between': group.titleLabel,'justify-content-center':!group.titleLabel}">
        <div *ngIf="group.titleLabel" class="col-9 justify-content-start font-size-l ">
          {{group.titleLabel | translate}}
        </div>
        <button id="{{panelId}}_{{group.id}}_button" 
          class="{{group.class}}" 
          [disabled]="!group.enable"
          [ngClass]="{'col-3': group.titleLabel,'col-8':!group.titleLabel,'disabled': !group.enable}"
          (click)="buttonClicked(i)"
        >
          <span *ngIf="group.isLoading">
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
          </span>
          <span *ngIf="!group.isLoading">
            <i *ngIf="group.icon" class="{{group.icon}}"></i>{{group.description | translate}}
          </span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.InformationRow">
      <div id="{{panelId}}-{{group.id}}_" class="row justify-content-start information-row">
        <div>{{group.description | translate}}</div>
        <div class="division"></div>
        <div class="col-auto">{{group.value | translate}}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.Expanding">
      <a id="{{panelId}}-{{group.id}}_button" href="#{{panelId}}-{{group.id}}_group" role="button"
        data-toggle="collapse" class="row justify-content-between expand-header" (click)="toggleExpandingGroup(group)">
        <div><i class="fas" [ngClass]="{'fa-chevron-down': group.isExpended,'fa-chevron-up': !group.isExpended}"
            style="margin:0 8px;"></i>
          <span>{{group.description | translate}}</span></div>
        <input id="{{panelId}}-{{group.id}}_checkbox_all" type="checkbox" [(ngModel)]="group.checked"
          (click)="$event.stopPropagation();onExpandingGroupSelectAllClicked(group)">
      </a>
      <div class="collapse expand-cell-container show" id="{{panelId}}-{{group.id}}_group">
        <div class="row justify-content-between checkbox-container" *ngFor="let cell of group.cells">
          <div style="display: flex">
              <button id="{{cell.button.id}}" *ngIf="cell.button" class="expand-cell-button {{cell.button.class}}" (click)="cell.button.callback()">
                <i class="{{cell.button.icon}}"></i>
              </button>
              <span [ngClass]="{'right-form':cell.button}">{{cell.description | translate}}</span>
          </div>
          <input id="{{panelId}}_{{cell.id}}_checkbox" type="checkbox" [(ngModel)]="cell.checked"
            (click)="onExpandingGroupCellClicked(group,cell)">
        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.ImportDataButton">
      <div class="row justify-content-start import-data-button-row">
        <div id="{{panelId}}-{{button.id}}_button" class="col-3" *ngFor="let button of group.buttons" style="cursor: pointer;">
          <img src="{{ button.icon }}">
          <p>{{button.description | translate}}</p>
          <input class="input-file" type="file" (change)="button.handler($event)" multiple="multiple" accept="{{button.acceptType}}"/>
        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.TextArea">
      <textarea maxlength="{{ constants.TEXTAREA_MAX_LENGTH }}" id="{{panelId}}-{{group.id}}_textarea" type="text" class="{{group.css}}" id="{{group.id}}"
        [(ngModel)]="group.value" rows="{{group.rows}}" cols="{{group.columns}}" (blur)="onInputInformationChanged()"
        placeholder="{{group.placeholder | translate}}" style="resize: none;margin: 8px;" [disabled]="group.isDisabled"
        [(ngModel)]="group.value"></textarea>
    </ng-container>

    <ng-container *ngIf="group.type == sliderPanelRowType.Underline">
      <hr class="underline m-4" />
    </ng-container>

  </div>
  <p *ngIf="errorMessage != ''" class="text-danger group-container" style="white-space: pre-wrap">
    <strong>{{errorMessage}}</strong>
  </p>

  <ng-container *ngIf="bottomList && bottomList.length > 0">
    <div class="equipment-list">
      <div class="row justify-content-between">
        <div class="col-6">
          <h4>{{bottomListTitle}}</h4>
        </div>
      </div>
      <mat-list>
        <mat-list-item *ngFor="let item of bottomList">
          <div class="col-3" *ngFor="let key of bottomListKeys;let i = index" [ngClass]="{'col-3': i==0,'col-9': i==1}">
            {{item[key]}}
          </div>
        </mat-list-item>
      </mat-list>
    </div>

  </ng-container>
</div>