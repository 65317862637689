import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session } from 'src/app/services/session';

@Injectable({
  providedIn: 'root',
})
export class PmJobService {
  //Services
  router: Router;
  route: ActivatedRoute;

  invalidEquipmentListErrorCode = 14005;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  initPageMode(mode: string): JMENUM.JMPageMode {
    switch (mode) {
      case 'edit':
        return JMENUM.JMPageMode.EDIT;
      case 'create':
        return JMENUM.JMPageMode.CREATE;
      case 'view':
        return JMENUM.JMPageMode.VIEW;
      default:
        return null;
    }
  }

  async submitPmJob(job: JMOBJ.PmJob): Promise<JM.JMResponseGetPmJob> {
    let request: JM.JMRequestPmJobSubmit = new JM.JMRequestPmJobSubmit();
    request.pmJobNumber = job.pmJobNumber;
    request.pmPlanNumber = job.pmPlan && job.pmPlan['pmPlanNumber'] ? job.pmPlan['pmPlanNumber'] : undefined;
    request.periodId = job.period && job.period['_id'] ? job.period['_id'] : undefined;
    request.client = job.client ? job.client : undefined;
    request.contactNumber = job.contactNumber ? job.contactNumber : undefined;
    request.contactPerson = job.contactPerson ? job.contactPerson : undefined;
    request.email = job.email ? job.email : undefined;
    request.startTime = job.startTime ? job.startTime : undefined;
    request.completionTime = job.completionTime ? job.completionTime : undefined;
    request.progressReport = job.progressReport ? job.progressReport : undefined;
    request.jobDescription = job.jobDescription ? job.jobDescription : undefined;
    request.remarks = job.remarks ? job.remarks : undefined;
    request.additionalInfo = job.additionalInfo ? job.additionalInfo : undefined;
    request.equipmentNumbers = job.equipmentList
      ? job.equipmentList.map((equipment) => equipment['equipmentNumber'])
      : undefined;
    request.functionalLocations = job.functionalLocations ? job.functionalLocations : undefined;
    request.version = job.version;

    let response = await AppDelegate.sendJMRequest(request);

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      if (
        response.code == this.invalidEquipmentListErrorCode &&
        response.payload &&
        response.payload.invalidEquipmentList
      ) {
        AppDelegate.showPopUpAlert(
          JMLanguage.translate('pages.pm-job.popup.error'),
          JMLanguage.translate('pages.pm-job.popup.message', [response.payload.invalidEquipmentList.join(',')]),
          [
            {
              name: JMLanguage.translate('global.ok'),
              handler: () => {
                this.router.navigateByUrl(
                  `/pm/standard-plan/equipment-list/${request.pmPlanNumber}/${request.periodId}`
                );
              },
            },
          ]
        );
      } else {
        AppDelegate.openErrorBar(response);
      }
    }
    return response;
  }

  async savePmJob(job: JMOBJ.PmJob): Promise<JM.JMResponseGetPmJob> {
    let parseStringValue = function(value:string):string{
      if(typeof value!== 'string'){
        return undefined;
      }
      return value;
    }
    let request: JM.JMRequestPmJobDraft = new JM.JMRequestPmJobDraft();
    request.pmJobNumber = parseStringValue(job.pmJobNumber);
    request.pmPlanNumber = job.pmPlan && parseStringValue(job.pmPlan['pmPlanNumber']);
    request.periodId = job.period && parseStringValue(job.period['_id']);
    request.client = parseStringValue(job.client);
    request.contactNumber = parseStringValue(job.contactNumber);
    request.contactPerson = parseStringValue(job.contactPerson);
    request.email = parseStringValue(job.email);
    request.progressReport = parseStringValue(job.progressReport);
    request.jobDescription = parseStringValue(job.jobDescription);
    request.startTime = job.startTime ? job.startTime : undefined;
    request.completionTime = job.completionTime ? job.completionTime : undefined;
    request.remarks = job.remarks ? job.remarks : undefined;
    request.additionalInfo = job.additionalInfo ? job.additionalInfo : undefined;
    request.equipmentNumbers = job.equipmentList
      ? job.equipmentList.map((equipment) => equipment['equipmentNumber'])
      : undefined;
    request.functionalLocations = job.functionalLocations ? job.functionalLocations : undefined;
    request.version = job.version;

    let response: JM.JMResponseGetPmJob = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    return response;
  }

  async reopenPmJob(job: JMOBJ.PmJob): Promise<JM.JMResponsePmJobReopen> {
    if (!job || !job.pmJobNumber) return;
    let request: JM.JMRequestPmJobReopen = new JM.JMRequestPmJobReopen();
    request.pmJobNumber = job.pmJobNumber;
    request.version = job.version;
    let response: JM.JMResponsePmJobReopen = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    return response;
  }

  async getPmJob(pmJobNumber: string): Promise<JM.JMResponseGetPmJob> {
    if (!pmJobNumber) return;
    let request = new JM.JMRequestGetPmJob();
    request.pmJobNumber = pmJobNumber;
    let response: JM.JMResponseGetPmJob = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    return response;
  }

  async generateCcsSoPmJob(job: JMOBJ.PmJob, equipmentNumbers: string[], agreementNumber: string, itemNumber: string): Promise<JM.JMResponseJobCardsObtainCcsSoNumber> {
    if (!job || !job.pmJobNumber) return;
    let request: JM.JMRequestObtainPmJobCcsSoNumber = new JM.JMRequestObtainPmJobCcsSoNumber();
    request.pmJobNumber = job.pmJobNumber;
    request.version = job.version;
    request.equipmentNumbers = equipmentNumbers;
    agreementNumber && (request.agreementNumber = agreementNumber);
    itemNumber && (request.itemNumber = itemNumber);
    let response: JM.JMResponseJobCardsObtainCcsSoNumber = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
    }
    return response;
  }

  async deleteCcsSoEquipment(job: JMOBJ.PmJob, equipmentNumber: string): Promise<JM.JMResponseDeleteEquipmentFromPmJob> {
    if (!job || !job.pmJobNumber || !equipmentNumber) return;
    let request: JM.JMRequestDeleteEquipmentFromPmJob = new JM.JMRequestDeleteEquipmentFromPmJob();
    request.pmJobNumber = job.pmJobNumber;
    request.equipmentNumber = equipmentNumber;
    request.version = job.version;
    let response: JM.JMResponseDeleteEquipmentFromPmJob = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
    }
    return response;
  }

  async cancelPmJob(job: JMOBJ.PmJob, reasonCode: number, remarks:string): Promise<JM.JMResponsePmJobCancel> {
    if (!job || !job.pmJobNumber) return;
    let request: JM.JMRequestPmJobCancel = new JM.JMRequestPmJobCancel();
    request.pmJobNumber = job.pmJobNumber;
    request.cancelReason = reasonCode;
    request.cancelRemarks = remarks;
    request.version = job.version;
    let response: JM.JMResponsePmJobCancel = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
    }
    return response;
  }

  async getPmJobUnassignedEquipmentList(periodId : string):Promise<JM.JMResponseGetPmJobUnassignedEquipmentList> {
    if (!periodId) return;
    let request: JM.JMRequestGetPmJobUnassignedEquipmentList = new JM.JMRequestGetPmJobUnassignedEquipmentList();
    request.periodId = periodId;
    let response: JM.JMResponseGetPmJobUnassignedEquipmentList = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload || 
        !response.payload.records) {
      return;
    }
    return response;

}

  async uploadAttachment(job: JMOBJ.PmJob, file: File, description: string): Promise<JM.JMResponsePmJobUploadAttachment> {
    if (!job || !job.pmJobNumber || !file) return;
    let request: JM.JMRequestPmJobUploadAttachment = new JM.JMRequestPmJobUploadAttachment();

    let formData = new FormData();
    formData.append('uploadData', file, file.name);
    formData.append('pmJobNumber', job.pmJobNumber);
    if(job.version){
      formData.append('version', job.version.toString());
    }
    if (description) {
      formData.append('description', description);
    }

    let response: JM.JMResponsePmJobUploadAttachment = await AppDelegate.sendJMRequestFile(request, formData);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
    }
    return response;
  }

  async removeAttachment(job: JMOBJ.PmJob, attachmentId: string): Promise<JM.JMResponsePmJobRemoveAttachments> {
    if (!job || !job.pmJobNumber || !attachmentId) return;
    let request: JM.JMRequestPmJobRemoveAttachments = new JM.JMRequestPmJobRemoveAttachments();
    request.pmJobNumber = job.pmJobNumber;
    request.attachmentIds = [attachmentId];
    request.version = job.version;
    let response: JM.JMResponsePmJobRemoveAttachments = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
    }
    return response;
  }
}
