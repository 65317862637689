/*
  version 20201123:
   - handle button disable when calling API

  version 20201119:
   - change the checking for timesheet "N" case
   - add CCS SO Number to Tablex
   - new field for jobsummary call to handle NA case

  version 20201117:
   - update jobcard summary api
   - add action button dim control
   - change translate to use JMLanguage

  version 20201109
    - call teco api
    - update summary after teco 

  version 20201105
    - default searching criteria 
    - advanced search handling
    - call job card summary
    - get team name by jobCard?/workCentre?

  version 20201030:
   - create this page
  
*/

/*
  not yet complete
   - get quotationSubmitted
*/

import { formatDate } from '@angular/common';
import { ViewChild } from '@angular/core';
import { Component, Injector, OnInit } from '@angular/core';
import { ActionButtonDefinition, ActionButtonTeco, ActionButtonType } from '@enum/action-button';
import { TablexColumnType, TablexColumnHorizontalAlign, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { ParameterService } from '@services/parameter.service';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import { pad } from 'src/lib/presenter/Formatter';
import { Session }  from 'src/app/services/session';
import { Constants } from 'src/constants';
@Component({
  selector: 'app-teco',
  templateUrl: './teco.component.html',
  styleUrls: ['./teco.component.scss']
})
export class TecoComponent extends BasePage implements OnInit {
  @ViewChild('teco-table', { static: true }) tecoTable;
  @ViewChild('teco-advanced-search-panel', { static: true }) tecoAdvancedSearchPanel;

  actionButtonData = [];
  uiIsLoading: boolean = true;
  lang = JMLanguage.getCurrentLanguage() === 'zh' ? 'zh' : 'en';

  // Table
  selectedTableRows: any = [];
  selectedRows: any = [];
  tablexParam: any = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  commonHeader = {
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col",
  };

  // Dropdown List
  dropdownNameList: string[] = [];
  dropdownAttr: any[] = [];
  dropDownMap: Map<String, Options[]> = new Map();
  usageTypeFilter = ['haEquipment','equipmentTags']
  tableXHeader: any[] = [];

  isExpanded = true;

  jobCardSummary: any = [];

  advancedSearchPara = {};

  searchingcriteria = {};

  //caution: should be change the number after tablex column add or remove
  statusColIndex = 10;

  //constractor Handling Party.....
  constractorHandlingParty: string[] = ["2", "3"];

  constructor(injector: Injector, private parameterService: ParameterService) { super(injector); }

  ngOnInit() {
    if (!this.authorizationService.hasPermissions([JMENUM.Permission.JOBCARD_TECO, JMENUM.Permission.JOBCARD_TECO_ALL], false)) {
      this.router.navigate(["/dashboard"]);
    }
    this.advancedSearchPara = {
      fullTextSearch: false,
      defaultPanelOpen: true,
      title: "pages.teco.page-header",
      isTecoPage: true,
    };
    this.initActionButtons();
  }

  async ngAfterViewInit(){
    await this.requestDropdownSummary();
    this.initTablex();
    this.loadColumnSettingsFromLocal();
    this.requestJobCardSummary();
  }

  //Action Button Function-----------------------------------------------------------------------
  public initActionButtons(): void {
    this.actionButtonData = [];
    this.addActionBtn(ActionButtonTeco.teco);
    this.addActionBtn(ActionButtonTeco.withDraw);
  }

  public addActionBtn(buttonStatus: ActionButtonTeco): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.teco][buttonStatus];
    let buttonHandler = () => { };

    switch (buttonStatus) {
      case ActionButtonTeco.teco:
        buttonHandler = () => {
          this.requestTeco();
        };
        break;
      case ActionButtonTeco.withDraw:
        buttonHandler = () => {
          this.requestWithdraw();
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : "global.yes",
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : "global.no",
        handler: () => { this.uiIsLoading = false }
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  public onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = JMLanguage.translate(button.name);
      });
      if (this.selectedTableRows.length > 0) {
        let subMsg;
        if (this.selectedTableRows.length > 1) {
          subMsg = JMLanguage.translate("popup.selected-job-cards.title") + this.tablexParam['selectedRowCount'];
        }
        else {
          subMsg = JMLanguage.translate("popup.job-card-number.title") + this.selectedTableRows[0][1].value;
        }
        this.showPopUpAlert(JMLanguage.translate(actionButton.popupTitle), subMsg, buttons);
      }
      else {
        this.openSnackBar(JMLanguage.translate("popupError.no-job-card"));
      }

    } else {
      actionButton.buttons[0].handler();
    }
  }

  onAdvancedSearch(event) {
    this.searchingcriteria = event;
    this.currentPage = 1;
    this.requestJobCardSummary();
  }


  //--------------------------------------------------------------------------------------------

  //Tablex Function-----------------------------------------------------------------------------
  public renderTable() {
    this.tablexParam.headers = this.tableXHeader.filter((col) => {
      return this.tablexParam.selectedColId.includes(col.id);
    });
    this.tablexParam['content'] = this.jobCardSummary.map(data => {
      let { 
        eamDropdown,
        equipmentTagDropdown 
      } = data
      let additionalDataList = new Map();
      let additionalValues = [];
      let timesheetSubmitted = JMLanguage.translate('teco.timesheet-submitted.na');
      let timesheetSubmittedClass = "";
      if (data.timesheetSubmitted == true) {
        timesheetSubmitted = JMLanguage.translate('teco.timesheet-submitted.yes');
      }
      else if (this.checkTimesheetRequired(data.timesheetSubmitted, data.handlingParty)) {
        timesheetSubmitted = JMLanguage.translate('teco.timesheet-submitted.no');
        timesheetSubmittedClass = "class='red'";
      }

      this.dropdownNameList.forEach(dropdownName=>{additionalDataList.set(dropdownName,null)}) //init additionalRow with order by dropdownNameList

      //eamDropdown
      if(eamDropdown!=null){
        this.setAdditionalDataList(eamDropdown,additionalDataList)
      }

      //equipmentTagDropdown
      if(equipmentTagDropdown!=null){
        this.setAdditionalDataList(equipmentTagDropdown,additionalDataList)
      }

      additionalDataList.forEach((value, key) => {
        additionalValues.push(value)
      })
      
      let fullRow = [
        data.version,                                                                                 //version
        { url: "/job-card/view/" + data.jobCardNumber, value: data.jobCardNumber },                   //jobCardNumber
        data.ccsServiceOrderNumber,                                                                   //ccs so
        data.handlingParty ? JMLanguage.translate('team.handling-party.' + data.handlingParty) : "",  //handlingParty
        data.workCentre,                                                                              //workCentre
        data.snTeamName,                                                                              //team name
        data.orderType,                                                                               //orderType
        (data.completionTime) ? formatDate(data.completionTime, "dd/MM/yyyy HH:mm", 'en-US') : "",    //completionTime
        ["<span " + timesheetSubmittedClass + ">" + timesheetSubmitted + "</span>"],                  //timesheetSubmitted
        "NA",                                                                                         //TODO: Teco2: not yet get quotationSubmitted
        (data.tecoStatus) ? JMLanguage.translate('teco.status.' + data.tecoStatus) : "",              //tecoStatus                                                                   
        data.tecoErrorMessage                                                                         //tecoErrorMessage
      ]

      fullRow = fullRow.concat(additionalValues)

      let rowFiltered = []
      fullRow.forEach((value, i) => {
        if (this.tablexParam.selectedColId.includes(this.tableXHeader[i].id)) {
          rowFiltered.push(fullRow[i]);
        }
      });
      
      return rowFiltered;
    });
  }

  private setAdditionalDataList(source, dataList){
    for (const [key, value] of Object.entries(source)) {
      if(this.dropDownMap.get(key) != undefined){
        this.dropDownMap.get(key).map(options=>{
          if(options.key === value && options.description[`${this.lang}`]!=undefined){
            dataList.set(key,options.description[`${this.lang}`])
          }
        })
      }
    }
  }

  appendAdditionalDropDownHeaders(tableXHeader){
  
    this.dropdownAttr.forEach((dropdownAttr, index)=>{
      if(dropdownAttr.key != undefined || dropdownAttr.key != null){
        tableXHeader.push({
          id: dropdownAttr.key,
          name: dropdownAttr.description[`${this.lang}`] !=undefined ? dropdownAttr.description[`${this.lang}`] : '',
          ...this.commonHeader
        })
      }
    })

    return tableXHeader

  }

  public initTablex() {
    this.tableXHeader = [
      {
        id: 'objId',
        name: "",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col d-none",
      },
      {
        id: 'jobCardNumber',
        name: "pages.teco.table-column.job-card-number",
        enableFilter: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'ccsSoNumber',
        name: "pages.teco.table-column.ccs-so-number",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'handlingParty',
        name: "pages.teco.table-column.handling-party",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'workCentre',
        name: "pages.teco.table-column.work-centre",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'team',
        name: "pages.teco.table-column.team",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'orderType',
        name: "pages.teco.table-column.order-type",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'completionTime',
        name: "pages.teco.table-column.completion-time",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: "col",
      },
      {
        id: 'timesheetSubmitted',
        name: "pages.teco.table-column.timesheet-submitted",
        enableFilter: false,
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: "col",
      },
      {
        id: 'quotationSubmitted',
        name: "pages.teco.table-column.quotation-submitted",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "d-none",//TODO: Teco2: not yet get quotationSubmitted
      },
      {
        id: 'tecoStatus',
        name: "pages.teco.table-column.teco-status",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'tecoErrorMessage',
        name: "pages.teco.table-column.teco-error-message",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col-2",
      }
    ];

    this.tableXHeader = this.appendAdditionalDropDownHeaders(this.tableXHeader);

    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      minifyButton: true,
      enableSelectedRowCount: true,
      enableSelectedAll: true,
      tableRow: "row",
      tableClass: "teco-table",
      tableWrapperClass: "table-min-width",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      onColFiltered: this.onColFiltered,
      enableStickyHeader: false,
      content: [],
      highlightedRows: [],
      customClassRows: [],
      selectedColId: [

        'jobCardNumber',
        'ccsSoNumber',
        'handlingParty',
        'workCentre',
        'team',
        'orderType',
        'completionTime',
        'timesheetSubmitted',
        'quotationSubmitted',
        'tecoStatus',
        'tecoErrorMessage',
        // 'createdBy',
      ],
      fullColNameList:this.tableXHeader.map((col) => {
        return { id: col.id, name: col.name };
      }),
    };
  }

  onColFiltered = (selectedColId) => {
    this.tablexParam.selectedColId = selectedColId;
    this.renderTable();
    this.saveColumnSettingsToLocal();
  };

  public onRowClicked = (index, row) => {
    let findRow = this.selectedTableRows.find(item => { return item.every((val, index) => val === row[index]); })
    if (this.selectedTableRows.indexOf(findRow) > -1) {
      this.tablexParam['highlightedRows'][index] = false;
      this.selectedTableRows.splice(this.selectedTableRows.indexOf(findRow), 1);
    } else {
      this.tablexParam['highlightedRows'][index] = true;
      this.selectedTableRows.push(row);
    }
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
    //checking to dim the button
    this.checkDimButton();
  }

  public checkDimButton() {
    let tecoButtonOn = true;
    let withdrawButtonOn = true;
    let otherStatusRecord = this.selectedTableRows.find(item => item[this.statusColIndex] != JMLanguage.translate('teco.status.2') && item[this.statusColIndex] != JMLanguage.translate('teco.status.3') && item[this.statusColIndex] != JMLanguage.translate('teco.status.5'));
    let readyForTecoRecord = this.selectedTableRows.find(item => item[this.statusColIndex] == JMLanguage.translate('teco.status.2') || item[this.statusColIndex] == JMLanguage.translate('teco.status.5'));
    let pendingTecoRecord = this.selectedTableRows.find(item => item[this.statusColIndex] == JMLanguage.translate('teco.status.3'));

    if (otherStatusRecord) {
      tecoButtonOn = false;
      withdrawButtonOn = false;
    }
    else {
      if (readyForTecoRecord && pendingTecoRecord) {
        tecoButtonOn = false;
        withdrawButtonOn = false;
      } else if (readyForTecoRecord && !pendingTecoRecord) {
        tecoButtonOn = true;
        withdrawButtonOn = false;
      } else if (!readyForTecoRecord && pendingTecoRecord) {
        tecoButtonOn = false;
        withdrawButtonOn = true;
      }
      else {
        tecoButtonOn = true;
        withdrawButtonOn = true;
      }
    }
    this.handleDisableActionButton(ActionButtonTeco.teco, tecoButtonOn);
    this.handleDisableActionButton(ActionButtonTeco.withDraw, withdrawButtonOn);
  }

  public onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
    this.requestJobCardSummary();
  }

  public onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
    this.requestJobCardSummary();
  }
  //--------------------------------------------------------------------------------------------

  //API CALL------------------------------------------------------------------------------------
  async requestDropdownSummary(key?, deletedRecord?) {
    const request = new JM.JMRequestDropdownSummary();
    request.system = [Constants.SYSTEM_NAME];
    request.usageType = this.usageTypeFilter;
    request.active = true;
    const response: JM.JMResponseDropdownSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload.totalCount <= 0) {
      if (deletedRecord) {
        this.requestDropdownSummary();
      } else {
        this.openSnackBar(JMLanguage.translate("global.no-record-found"));
      }
      return;
    }  else {

      this.dropdownAttr = response.payload.records.map(dropdown => {
        let data = {
          _id: dropdown._id,
          key: dropdown.key,
          descriptionEn: dropdown.description.en,
          descriptionZh: dropdown.description.zh,
          description: dropdown.description,
          usageType: dropdown.usageType,
          mappingType: dropdown.mappingType,
          options: dropdown.options,
          mandatoryJobNature: dropdown.mandatoryJobNatureList,
          active: dropdown.active
        };

        this.dropDownMap.set(dropdown.key,dropdown.options as Options[])

        return data;
      });
      this.dropdownNameList = this.dropdownAttr.map(dropdown => dropdown.key);
    }
  }

  public async requestJobCardSummary() {
    let request: JM.JMRequestJobCardsJobCardSummary = new JM.JMRequestJobCardsJobCardSummary();
    request.parameters = [
      "version",
      "snNumber",
      "jobCardNumber",
      "ccsServiceOrderNumber",
      "handlingParty",
      "workCentre",
      "snTeamName",
      "orderType",
      "completionTime",
      "timesheetSubmitted",
      /*"Quotation submitted?",*/ //TODO: Teco2: not yet get quotationSubmitted
      "tecoStatus",
      "tecoErrorMessage",
      "eamDropdown",
      "equipmentTagDropdown"
    ]

    //default showing status
    request.tecoStatusList = this.parameterService.allTecoPageStatus();
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    request.sortBy = "jobCardNumber";
    request.sortOrder = -1;
    request.statusList = [4];

    //advanced search data handling
    if (this.searchingcriteria) {
      request.filter = {};
      if (this.searchingcriteria["createTimeFrom"]) {
        request.createTimeFrom = new Date(this.searchingcriteria["createTimeFrom"].year + '-' + pad(this.searchingcriteria["createTimeFrom"].month) + '-' + pad(this.searchingcriteria["createTimeFrom"].day) + "T00:00:00.000Z");
      }
      if (this.searchingcriteria["createTimeTo"]) {
        request.createTimeTo = new Date(this.searchingcriteria["createTimeTo"].year + '-' + pad(this.searchingcriteria["createTimeTo"].month) + '-' + pad(this.searchingcriteria["createTimeTo"].day) + "T23:59:59.999Z");
      }
      if (this.searchingcriteria["completionTimeFrom"]) {
        request.completionTimeFrom = new Date(this.searchingcriteria["completionTimeFrom"].year + '-' + pad(this.searchingcriteria["completionTimeFrom"].month) + '-' + pad(this.searchingcriteria["completionTimeFrom"].day) + "T00:00:00.000Z");
      }
      if (this.searchingcriteria["completionTimeTo"]) {
        request.completionTimeTo = new Date(this.searchingcriteria["completionTimeTo"].year + '-' + pad(this.searchingcriteria["completionTimeTo"].month) + '-' + pad(this.searchingcriteria["completionTimeTo"].day) + "T23:59:59.999Z");
      }
      if (this.searchingcriteria["tecoStatus"] && this.searchingcriteria["tecoStatus"].length > 0) {
        request.tecoStatusList = this.searchingcriteria["tecoStatus"];
      }
      if (this.searchingcriteria["workCentre"]) {
        request.workCentre = this.searchingcriteria["workCentre"];
      }
      if (this.searchingcriteria["team"]) {
        request.snTeamIdList = [this.searchingcriteria["team"]];
      }
      if (this.searchingcriteria["assignedPersonList"] && this.searchingcriteria["assignedPersonList"].length > 0) {
        request.assignedPersonList = this.searchingcriteria["assignedPersonList"];
      }
      if (this.searchingcriteria["jobCardNumber"]) {
        request.filter["jobCardNumber"] = this.searchingcriteria["jobCardNumber"];
      }
      if (this.searchingcriteria["ccsServiceOrderNumber"]) {
        request.filter["ccsServiceOrderNumber"] = this.searchingcriteria["ccsServiceOrderNumber"];
      }
      if (this.searchingcriteria["orderType"] && this.searchingcriteria["orderType"].length > 0) {
        request.orderTypeList = this.searchingcriteria["orderType"];
      }
      if (this.searchingcriteria["handlingParty"]) {
        request.handlingPartyList = [this.searchingcriteria["handlingParty"]];
      }
      //handcode to handle timesheetSubmitted
      if (this.searchingcriteria["timesheetSubmitted"]) {
        switch (this.searchingcriteria["timesheetSubmitted"]) {
          case "yes":
            request.timesheetSubmitted = true;
            break;
          case "no":
            request.timesheetSubmitted = false;
            break;
          case "na":
            request.timesheetRequired = false;
            break;
        }
      }
      // request.quotationSubmitted
    }

    this.tablexParam["isLoadingTable"] = true;
    const response: JM.JMResponseJobCardsJobCardSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam["isLoadingTable"] = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.jobCardSummary = response.payload.records;
    this.renderTable();
    if(this.searchingcriteria)
    {
      this.selectedTableRows = [];
      this.tablexParam['highlightedRows'] = [];
      this.tablexParam['selectedRowCount'] = 0;
    }
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
  }

  public async requestTeco() {
    const request: JM.JMRequestJobCardsTeco = new JM.JMRequestJobCardsTeco();
    request.tecoList = this.getSelectedJobCardNumberList();
    
    this.handleDisableActionButton(ActionButtonTeco.teco, false);
    const response: JM.JMResponseJobCardsTeco = await AppDelegate.sendJMRequest(request);
    this.handleDisableActionButton(ActionButtonTeco.teco, true);

    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }
    //update existing tablex
    this.updateExistingJobCardsSummary(response.payload.records);
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.openSnackBar(JMLanguage.translate("pages.teco.message.teco", this.getJobCardsSummaryCount(response.payload.records)));
  }

  public async requestWithdraw() {
    const request: JM.JMRequestJobCardsWithdrawTeco = new JM.JMRequestJobCardsWithdrawTeco();
    request.withdrawList = this.getSelectedJobCardNumberList();
    
    this.handleDisableActionButton(ActionButtonTeco.withDraw, false);
    const response: JM.JMResponseJobCardsWithdrawTeco = await AppDelegate.sendJMRequest(request);
    this.handleDisableActionButton(ActionButtonTeco.withDraw, true);

    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }
    //update existing tablex
    this.updateExistingJobCardsSummary(response.payload.records);
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.openSnackBar(JMLanguage.translate("pages.teco.message.withdraw", [response.payload.totalCount]));
  }
  //--------------------------------------------------------------------------------------------

  //Layout reaction-----------------------------------------------------------------------------
  public onOpenFilterClicked() {
    this.isExpanded = !this.isExpanded;
  }

  public updateExistingJobCardsSummary(records) {
    records.forEach(record => {
      let jobCard = this.jobCardSummary.find(data => { return data.jobCardNumber == record.jobCard.jobCardNumber });
      jobCard.version = record.jobCard.version;
      jobCard.jobCardNumber = record.jobCard.jobCardNumber;
      jobCard.handlingParty = record.jobCard.handlingParty;
      jobCard.workCentre = record.jobCard.workCentre;
      jobCard.snTeamName = record.jobCard.snTeamName;
      jobCard.orderType = record.jobCard.orderType;
      jobCard.completionTime = record.jobCard.completionTime;
      jobCard.tecoStatus = record.jobCard.tecoStatus;
      jobCard.tecoErrorMessage = record.jobCard.tecoErrorMessage;
      jobCard.timesheetSubmitted = record.jobCard.timesheetSubmitted;
      if (record.errorMessage) {
        (jobCard.tecoErrorMessage) ? jobCard.tecoErrorMessage + record.errorMessage : jobCard.tecoErrorMessage = record.errorMessage;
      }
    });
    this.renderTable();
  }

  public getJobCardsSummaryCount(records)
  {
    let fail = 0;
    let success = 0;
    records.forEach(record => {
      if (record.errorMessage) {
        fail +=1;
      }
      else
      {
        success += 1;
      }
    });
    return [success,fail];
  }
  //--------------------------------------------------------------------------------------------

  //Other---------------------------------------------------------------------------------------
  private getSelectedJobCardNumberList() {
    let list = [];
    this.selectedTableRows.forEach(row => {
      list.push({
        version: row[0],           //version
        jobCardNumber: row[1].value//jobCardNumber
      });
    });
    return list;
  }

  onLanguageChanged() {
  }

  handleDisableActionButton(button, isEnable) {
    let actionButton = this.actionButtonData.find(({ action }) => action == button);
    actionButton.isEnable = isEnable;
  }

  //may be change in future
  checkTimesheetRequired(timesheetSubmitted, handlingParty) {
    if ((timesheetSubmitted == false || !timesheetSubmitted) && handlingParty == 1) {
      return true;
    }
    return false;
  }

  private saveColumnSettingsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.tablexParam.selectedColId));
      Session.setTecoJobListSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  private loadColumnSettingsFromLocal = () : void => {
    try {
      let cache = Session.tecoJobListSettingsColumns;
      if (cache) {
        this.tablexParam.selectedColId = JSON.parse(JSON.stringify(cache))
      }
    } catch (e) {
      console.warn(e);
    }
  }

  //--------------------------------------------------------------------------------------------
}
export interface Options{
  key:string
  description:{
    en:string
    zh:string
  }
  active:boolean
}