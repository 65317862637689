import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/AppDelegate';
import * as FORAMTTER from 'src/app/presenter/Formatter';

@Component({
  selector: 'transfer-sn-form',
  templateUrl: './transfer-sn-form.component.html',
  styleUrls: ['./transfer-sn-form.component.scss']
})
export class TransferSnFormComponent implements OnInit {

  @Input() options: any[];
  @Input() isLoading: boolean;
  @Output() onSubmit = new EventEmitter<any>();

  // Options
  teamOptions: OptionsI[] = [];
  teamDict:{[x:string]:any} = {};

  // for searching team use
  private searchTeamObserver = new Subject<any[]>();
  searchTeamKeywords: string;
  teamOptionsPage: number;
  teamOptionsTotalPage: number;

  // UI
  isLoadingTeam: boolean = false;
  submitEnable: boolean = false;

  // Form fields
  teamWorkCentre: string = null;
  team: string = null;

  constructor() {}

  ngOnInit() {
    this.initSearchTeamObserver();
  }

  ngOnDestroy() {}

  ngOnChanges() {}

  private initSearchTeamObserver() {
    // for search team use
    this.searchTeamObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.teamOptions = [];
      this.teamDict = {};
      this.teamOptionsPage = 1;
      if (this.teamWorkCentre && this.teamWorkCentre != '') {
        this.requestTeamSummary(this.teamOptionsPage);
      }
    });
  }

  onTeamWorkCentreChanged() {
    this.team = null;
    this.validateForm();

    this.teamOptions = [];
    this.teamDict = {};
    this.teamOptionsPage = 1;
    if (this.teamWorkCentre && this.teamWorkCentre != '') {
      this.requestTeamSummary(this.teamOptionsPage);
    }
  }

  private async requestTeamSummary(page: number) {
    let request = new JM.JMRequestTeamsTeamSummary();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.parameters = ['_id', 'name', 'workCentre', 'handlingParty', 'contractNumber'];
    request.workCentreList = [this.teamWorkCentre];
    request.includeApprovedWorkCentre = true;
    request.includeNextOperationTime = true;
    request.includeSummary = true;
    request.pageSize = 10;
    request.pageNumber = page || 1;
    if (this.searchTeamKeywords && this.searchTeamKeywords != '') {
      request.filter = { name: this.searchTeamKeywords };
    }

    this.isLoadingTeam = true;
    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (page === 1) {
      let totalTeamCount = response.payload.totalCount;
      this.teamOptionsTotalPage = Math.ceil(totalTeamCount / request.pageSize);
      this.setTeamOptions(response.payload.records);
    } else {
      this.setTeamOptions(response.payload.records, true);
    }
  }

  async onTeamScrollToEnd() {
    if (this.teamOptionsPage < this.teamOptionsTotalPage) {
      this.teamOptionsPage += 1;
      this.requestTeamSummary(this.teamOptionsPage);
    }
  }

  private setTeamOptions(teamDetailArray: JMOBJ.Team[], append = false): void {
    // just append will not trigger ng-selct change detection
    this.teamOptions = append ? JSON.parse(JSON.stringify(this.teamOptions)) : [];

    for (let team of teamDetailArray) {
      let formattedTeamName = FORAMTTER.formatTeamNameContractNumber(team);
      let teamWorkCentre = this.teamWorkCentre && team.workCentre != this.teamWorkCentre ? `<${team.workCentre}>` : '';
      let option = {
        label: `${formattedTeamName} ${teamWorkCentre} `,
        value: team._id,
      };
      this.teamOptions.push(option);
      this.teamDict[team._id] = team['nextOperationTime'];
    }
  }

  onClearTeam() {
    this.searchTeamKeywords = null;
    this.searchTeamObserver.next();
  }

  onSearchTeamOptions(event) {
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver.next();
  }

  onFilterTeamOptions(term: string, item: any) {
    return item.label.toLowerCase().includes(term.toLowerCase());
  }

  //===================================================
  // UI event
  validateForm() {
    if (this.teamWorkCentre && this.team) {
      this.submitEnable = true;
      return;
    }

    this.submitEnable = false;
  }

  onClickSubmit(teamId: string) {
    let nextOperationTime = this.teamDict[teamId];
    this.onSubmit.emit({
      teamId: this.team,
      nextOperationTime
    });
  }
}

interface OptionsI {
  value: string,
  label: string
}
