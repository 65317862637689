<div class="container" *ngIf="remarks && remarks.length">
  <ng-container *ngFor="let remark of remarks; let j = index">
    <div class="record" [id]="'sn-remark-'+j">
      <div class="visibility-type">
        <div [ngClass]="{'selected-visibility': remark.visibilityType == 'invisible'}" >
          <label *ngIf="showVisibilityType.invisible" [ngClass]="{'clickable': editMode}"
            (click)="onVisibilityChanged(j, 'invisible')">{{'remark-history.visibility-invisible' | translate}}</label>
        </div>
        <div [ngClass]="{'selected-visibility': remark.visibilityType == 'private'}" >
          <label *ngIf="showVisibilityType.private" [ngClass]="{'clickable': editMode}"
            (click)="onVisibilityChanged(j, 'private')">{{'remark-history.visibility-private' | translate}}</label>
        </div>
        <div [ngClass]="{'selected-visibility': remark.visibilityType == 'public'}" >
          <label *ngIf="showVisibilityType.public" [ngClass]="{'clickable': editMode}"
            (click)="onVisibilityChanged(j, 'public')">{{'remark-history.visibility-public' | translate}}</label>
        </div>
      </div>
      <div class="content-box">
          <span [id]="'sn-remarks-created-by'+j">{{ remark.createdBy }}</span>
          <span [id]="'sn-remarks-created-at'+j">{{ remark.createdAt | date:"yyyy/MM/dd HH:mm" }}</span>
          <span [id]="'sn-remarks-sn-status'+j">({{ remark.snStatus | snStatus:lang }})</span>
          <div class="remark" [id]="'sn-remarks-remark'+j">
            {{ remark.remark }} 
          </div>
      </div>
    </div>
  </ng-container>
</div>