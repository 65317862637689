<ng-container *ngIf="showTitle">
  <div class="font-size-xl m-0 mb-2 px-2">
    {{ "component.progress-details.title" | translate}}
  </div>

  <hr class="underline" />
</ng-container>

<div class="row align-items-center">
  <ng-container *ngIf="showJobDescription">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{ "component.progress-details.job-description" | translate }}
    </div>
    <div *ngIf="isPageModeView" class="col-10 text-left font-size-s m-0 mb-4 overflow-auto">
      <app-display-text-field id="progress-details-job-description" [textField]="jobCard?.jobDescription" >
      </app-display-text-field>
    </div>
    <div *ngIf="!isPageModeView" class="col-10 mb-4 ">
      <textarea id="ob-description-input" type="text" class="form-control" [maxlength]="constants.TEXTAREA_MAX_LENGTH"
        [(ngModel)]="jobDescription" rows="3" (change)="assignInputToValue('jobDescription',jobDescription)"
      ></textarea>
    </div>
  </ng-container>

  <ng-container *ngIf="showfollowUpRemarks">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{ "pages.sn.follow-up-reason" | translate }}
    </div>
    <div class="col-10 text-left font-size-s m-0 mb-4 overflow-auto">
      <app-display-text-field id="progress-details-followup-remarks" [textField]="followUpRemarks">
      </app-display-text-field>
    </div>
  </ng-container>

  <ng-container *ngIf="showRemarks">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{ reasonRemarks.label }}
    </div>
    <div class="col-10 text-left font-size-s m-0 mb-4 overflow-auto">
      <app-display-text-field id="progress-details-remarks" [textField]="reasonRemarks.remarks">
      </app-display-text-field>
    </div>
  </ng-container>

  <ng-container *ngIf="showReportedByContractor">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{ "component.progress-details.progress-reported-by-contractor" | translate }}
    </div>
    <div class="col-10 text-left font-size-s m-0 mb-4 overflow-auto">
      <app-display-text-field id="progress-details-progress-reported-by-contractor" [textField]="jobCard.contractorRemarks">
      </app-display-text-field>
    </div>
  </ng-container>
  
</div>
