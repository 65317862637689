<div class="wrapper">
    <app-nav
      [showActionSidebarIcon]="true"
      [navbarTitle]="pageTitle"
    ></app-nav>
    <app-sidebar></app-sidebar>
    <div id="content">
        <div class="col-12">
            <div class="mb-2">
                <span class="title">{{ "component.inspection.report.submitted" | translate }}</span>
                <button type="button" class="btn float-right" (click)="onAddInspectionReportClicked($event)">
                    <i class="fas fa-plus blue fa-lg"></i>
                </button>
            </div>
            <app-tablex id="inspection-report-history-table" #inspectionReportHistoryTablex [tablexParam]='tablexParam' class='mt-3'></app-tablex>
        </div>
        
    </div>
</div>