<ng-container *ngIf="!(isPageModeCreate && jobCard.jobNature === JMENUM.JobNature.STANDALONE)">

<div class="font-size-xl m-0 mb-2 px-2">
  {{ "component.ha-information.title" | translate}}
</div>


<hr class="underline" />

<div class="row">
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.ha-information.ha-work-order-number" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ha-info-work-order-number"
      [textField]="sn?.eamData?.HAWorkOrderNumber"
      [emptyHandleDisplay]="emptyHandle"
    ></app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.ha-information.ha-asset-number" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto" *ngIf="!editable.assetNumber">
    <app-display-text-field id="ha-info-asset-number"
      [textField]="componentParameters?.assetNumber"
      [emptyHandleDisplay]="emptyHandle"
      [ngClass]="{'input-error':errorFields?.assetNumber}"
    ></app-display-text-field>
  </div>
  <div class="col-4 mb-4 d-flex" *ngIf="editable.assetNumber" >
    <div class="flex-nowrap align-items-start" [class.input-group]="!(sn.snNumber || !sn.eamData.assetNumber )">
      <input type="text" class="form-control" id="ha-info-asset-number-input"
        [(ngModel)]="sn.eamData.assetNumber" 
        (change)="assignInputToValue('assetNumber', assetNumber)"
        (click)="componentParameters.onClickHaEquipmentNo && componentParameters.onClickHaEquipmentNo()"
        [ngClass]="{'input-error':errorFields?.assetNumber}"
        READONLY
      />
      <button class="btn" (click)="componentParameters.onClearHaEquipmentNumber()" type="button" [ngClass]="{'d-none':sn.snNumber || !sn.eamData.assetNumber}">
          <i class="fa fa-trash-o" aria-hidden="true"></i>
      </button>
    </div>
    
    <div class="btn-ha-details fa-stack ml-2" (click)="onClickHADetails()" [class.inactive]="!sn.eamData.assetNumber">
      <i class="fa fa-square fa-stack-2x"></i>
      <i class="fa fa-info fa-stack-1x fa-inverse"></i>
    </div>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.ha-information.equipment-received-date" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto" *ngIf="!editable.equipmentReceivedDate">
    <app-display-text-field id="ha-info-equipment-received-date"
      [textField]="jobCard?.equipmentReceivedDate | dateTimeWeek "
      [ngClass]="{'input-error': errorFields?.equipmentReceivedDate}">
    </app-display-text-field>
  </div>
  <div class="col-4 mb-4 row mx-0" *ngIf="editable.equipmentReceivedDate">
    <app-date-input class="col-12 m-0 mb-2 p-0" [isError]="errorFields.equipmentReceivedDate"
      [input]="equipmentReceivedDay" [inputId]="'equipmentReceivedDateInput'" [field]="'equipmentReceivedDay'" 
      [dateAlertMsg]="dateAlertMsg" (onBlur)="setDateTime($event)">
    </app-date-input>
    <app-time-input class="col-12 col-lg-8 col-xl-7 p-0" [isError]="errorFields.equipmentReceivedDate" [customClass]="'form-control'"
      [input]="equipmentReceivedTime" [inputId]="'equipmentReceivedTimeInput'" [field]="'equipmentReceivedTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="setDateTime($event)">
    </app-time-input>
  </div>

  <ng-container *ngFor="let dropDown of eamDropdownGroup">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      <span *ngIf="dropDown.mandatory" class="red">#</span>
      {{ dropDown.desc }}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto" *ngIf="!editable.eamDropdownGroup">
      <app-display-text-field [textField]="eamDropdown[dropDown.key]?.description" [emptyHandleDisplay]="emptyHandle"
        [ngClass]="{'input-error': errorFields && errorFields[dropDown.key]}">
      </app-display-text-field>
    </div>
    <div class="col-4 mb-4 " *ngIf="editable.eamDropdownGroup">
      <ng-select [id]="dropDown.desc+'-dropdown'" [items]="dropDown.options" bindLabel="description" bindValue="key"
        [(ngModel)]="eamDropdown[dropDown.key]" (change)="assignDropdownInputToValue(dropDown.key,true)" [disabled]="dropDown.disabled">
        <ng-template ng-option-tmp let-item="item" >
          <div class="drop-down-options" [title]="item.description">
            {{ item.description }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </ng-container>

  <ng-container *ngFor="let dropDown of equipmentTagDropdownGroup">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      <span *ngIf="dropDown.mandatory" class="red">#</span>
      {{ dropDown.desc }}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto" *ngIf="!editable.equipmentTagDropdownGroup">
      <app-display-text-field [textField]="equipmentTagDropdown[dropDown.key]?.description" [emptyHandleDisplay]="emptyHandle"
        [ngClass]="{'input-error': errorFields && errorFields[dropDown.key]}">
      </app-display-text-field>
    </div>
    <div class="col-4 mb-4 " *ngIf="editable.equipmentTagDropdownGroup">
      <ng-select [id]="dropDown.desc+'-dropdown'" [items]="dropDown.options" bindLabel="description" bindValue="key"
        [(ngModel)]="equipmentTagDropdown[dropDown.key]" (change)="assignDropdownInputToValue(dropDown.key,false)" [disabled]="dropDown.disabled">
        <ng-template ng-option-tmp let-item="item" >
          <div class="drop-down-options" [title]="item.description">
            {{ item.description }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </ng-container>

  <ng-container *ngIf="jobCard.jobNature === JMENUM.JobNature.PM">
    <label for="ha-info-reply-ha-input" class="col-2 m-0 text-right mb-4 gray-title pl-0 pr-1">
      {{ "component.ha-information.reply-ha" | translate }}
    </label>
    <div *ngIf="isPageModeView" class="col-4 m-0 text-left mb-4 font-size-s">
      <app-display-text-field id="ha-info-reply-ha" [textField]="sn.eamData.replyToHA  ? textYes : textNo" ></app-display-text-field>
    </div>

    <div *ngIf="isPageModeCreate || isPageModeEdit"
      class="col-4 mb-4" 
    >
      <div class="btn-group">
        <button 
          id="form-reply-to-ha-on"
          class="btn"
          [class.active]="sn.eamData.replyToHA"
          [disabled]="isPageModeView"
          (click)="onReplyToHAChanged(true)"
        >{{ "global.yes" | translate }}</button>
        <button 
          id="form-reply-to-ha-off"
          class="btn"
          [class.active]="!sn.eamData.replyToHA"
          [disabled]="isPageModeView"
          (click)="onReplyToHAChanged(false)"
        >{{ "global.no" | translate }}</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="jobCard?.haComplianceStatus == 'failed'">
    <label for="ha-info-reply-ha-input" class="col-2 m-0 text-right mb-4 gray-title pl-0 pr-1">
      {{ "component.ha-information.haNonComplianceReason" | translate }}
    </label>
    <div class="col-4 m-0 text-left mb-4 font-size-s overflow-hidden">
      <app-display-text-field id="ha-info-reply-ha"
        [textField]="getHaNonComplianceReason()"
      ></app-display-text-field>
    </div>
  </ng-container>
</div>
</ng-container>
