import { formatDate } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { PostApprovalApproveRequestRequest } from '@api/model/approval/post-approval-approve-request-request';
import { PostApprovalRejectRequestRequest } from '@api/model/approval/post-approval-reject-request-request';
import { PostApprovalRequestSummaryRequest } from '@api/model/approval/post-approval-request-summary-request';
import { PostGetTeamsRequest } from '@api/model/contact-group/post-get-teams-request';
import { Permission } from '@enum/permission';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { ApprovalService } from '@services/approval.service';
import { ContactGroupService } from '@services/contact-group.service';
import { AppDelegate } from 'src/app/AppDelegate';
import { DelegationRequest } from 'src/app/entity/data-model/delegation-request';
import { Team } from 'src/app/entity/data-model/team';
import { Session } from 'src/app/services/session';
import { SidebarItemInterface, SIDEBAR_ITEMS } from 'src/app/ui/components/sidebar/sidebar-items';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent extends BasePage implements OnInit {

  pageTitle: string;

  user: JMOBJ.Post = undefined;

  // sidebar
  sideBarItem: SidebarItemInterface[] = [];
  snJobApprovalCount: number = 0;
  pmPlanApprovalCount: number = 0;
  pmJobApprovalCount: number = 0;

  selectedWorkCentre    = Session.selectedWorkCentre;
  dateForm              = "dd/MM/yyyy";
  hasApprovePermission  = this.authorizationService.hasPermission(Permission.requestApprove);
  hasRejectPermission   = this.authorizationService.hasPermission(Permission.requestReject);

  // ===========================================================================
  // table params
  pendingRequestTablexParam             : {};
  pendingRequestTableCurrentPageSize    = 10;
  pendingRequestTableCurrentPage        = 1;
  totalPendingRequestCount              = 0;
  pendingRequests                       : DelegationRequest[] = [];
  pendingRequestTeams                   : Team[] = [];
  
  approvedRequestTablexParam            : {};
  approvedRequestCurrentPageSize        = 10;
  approvedRequestCurrentPage            = 1;
  totalApprovedRequestCount             = 0;
  approvedRequests                      : DelegationRequest[] = [];
  approvedRequestTeams                  : Team[] = [];

  requestTypeOptions = [
    // { label: "Acting", value: "post" },
    { label: "Team", value: "team" },
  ];
  requestType = this.requestTypeOptions[0].value;


  constructor(
    injector: Injector,
    private contactGroupService: ContactGroupService,
    private approvalService: ApprovalService
  ) {
    super(injector)
  }


  // ===========================================================================
  // view life cycle functions
  ngOnInit() {
    this.user = Session.userInfo;

    this.pageTitle = JMLanguage.translate("pages.approval.page-title", [this.selectedWorkCentre]);
    this.initPendingRequestTable();
    this.initApprovedRequestTable();
    this.requestPendingRequests();
    this.requestApprovedRequest();
    this.requestSnJobApprovalCount();
    this.requestPmPlanApprovalCount();
    this.requestPmJobApprovalCount();
  }

  // ==================================================================
  // init table function
  initPendingRequestTable() {
    this.pendingRequestTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : false,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.pendingRequestTableCurrentPageSize,
      currentPage           : this.pendingRequestTableCurrentPage,
      pageCount             : this.totalPendingRequestCount,
      onPageNumberClicked   : this.onPendingRequestTablePageNumberClicked,
      onPageSizeClicked     : this.onPendingRequestTablePageSizeClicked,
      headers: [
        {
          name: "pages.approval.id",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.approval.request-work-centre",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.approval.requester",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.approval.description",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.start-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.end-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.approval.requested-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },

      ],
      content: []
    };

    if (this.hasApprovePermission || this.hasRejectPermission) {
      this.pendingRequestTablexParam["headers"].push(
        {
          name: "pages.approval.action",
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        }
      )
    }
  }

  initApprovedRequestTable() {
    this.approvedRequestTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : false,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.approvedRequestCurrentPageSize,
      currentPage           : this.approvedRequestCurrentPage,
      pageCount             : this.totalApprovedRequestCount,
      onPageNumberClicked   : this.onApprovedRequestTablePageNumberClicked,
      onPageSizeClicked     : this.onApprovedRequestTablePageSizeClicked,
      headers: [
        {
          name: "pages.approval.id",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.approval.request-work-centre",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.approval.description",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.start-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.authorization.end-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.approval.approved-by",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          name: "pages.approval.approved-date",
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },

      ],
      content: []
    };

    if (this.hasRejectPermission) {
      this.approvedRequestTablexParam["headers"].push(
        {
          name: "pages.approval.action",
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        }
      )
    }
  }

  getRequestDescription(request: DelegationRequest): string {
    let description = '';
    switch (request.type) {
      case "team":
        description = this.getTeamRequestDescription(request);
        break;
      case "post":
        description = this.getPostRequestDescription(request);
        break;
      default:
        break;
    }
    return description;
  }

  getTeamRequestDescription(request: DelegationRequest): string {
    let description = this.translate("pages.approval.no-exist-team-message");

    if (request["teamName"]) {
      description = request["teamName"];
      
      if (request.approvalWorkCentres.length > 0) {
        var approvalWorkCentres = request.approvalWorkCentres.join(', ');
        description = `${description} (${approvalWorkCentres})`;
      }
    }

    return description;
  }

  getPostRequestDescription(request: DelegationRequest): string {
    let description = '';
    // TODO: 
    return description;
  }

  // ===========================================================================
  // api function
  requestPendingRequests() {
    let request                   = {} as PostApprovalRequestSummaryRequest;
    request.approvalWorkCentres   = [this.selectedWorkCentre];
    request.status                = [1];
    request.pageSize              = this.pendingRequestTableCurrentPageSize;
    request.pageNumber            = this.pendingRequestTableCurrentPage;
    request.sortBy                = "createdAt";
    request.sortOrder             = -1;

    this.pendingRequestTablexParam["isLoadingTable"] = true;
    this.apiHandler(this.approvalService.getRequestSummary(request),
      result => {

        this.pendingRequestTablexParam["isLoadingTable"] = false;
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }

        this.totalPendingRequestCount   = result.payload.totalCount;
        this.pendingRequests            = result.payload.records;
        let pendingRequests             = result.payload.records.filter(item => item.payload && item.payload.teamId).sort((a, b) => a.payload.teamId > b.payload.teamId ? 1 : -1);
        
        this.updateSideBar();
        if (pendingRequests.length == 0) {
          this.renderPendingRequestTable();
          return;
        }

        let request       = {} as PostGetTeamsRequest;
        request.teamIds   = pendingRequests.map(pendingRequest => pendingRequest.payload.teamId);
        this.apiHandler(this.contactGroupService.getTeams(request),
          result => {

            if (!result || !result.code || result.code != 200) {
              this.openErrorBar(result);
              return;
            }

            this.pendingRequestTeams = result.payload.sort((a, b) => a.id > b.id ? 1 : -1);
            let teams = result.payload;
            let teamNameMap = {};
            teams.forEach(team => teamNameMap[team._id] = team.name);
            pendingRequests.forEach(request => request["teamName"] = teamNameMap[request.payload.teamId]);

            this.renderPendingRequestTable()

          })
      })
  }

  private async requestSnJobApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalCmPending(minPageNumber, minPageSize, needApprovalDetail);

    this.snJobApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  private async requestPmPlanApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalPmPlanPending(minPageNumber, minPageSize, needApprovalDetail);

    this.pmPlanApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  private async requestPmJobApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalPmJobPending(minPageNumber, minPageSize, needApprovalDetail);

    this.pmJobApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  requestApprovedRequest() {
    let request                   = {} as PostApprovalRequestSummaryRequest;
    request.approvalWorkCentres   = [this.selectedWorkCentre];
    request.type                  = this.requestType;
    request.status                = [3];
    request.pageSize              = this.approvedRequestCurrentPageSize;
    request.pageNumber            = this.approvedRequestCurrentPage;
    request.sortBy                = "createdAt";
    request.sortOrder             = -1;

    this.approvedRequestTablexParam["isLoadingTable"] = true;
    this.apiHandler(this.approvalService.getRequestSummary(request),
      result => {

        this.approvedRequestTablexParam["isLoadingTable"] = false;
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }

        this.totalApprovedRequestCount    = result.payload.totalCount;
        this.approvedRequests             = result.payload.records;
        let approvedRequests              = result.payload.records.filter(item => item.payload && item.payload.teamId).sort((a, b) => a.payload.teamId > b.payload.teamId ? 1 : -1);
        
        if (approvedRequests.length == 0) {
          this.renderApprovedRequestTable();
          return;
        }

        let request       = {} as PostGetTeamsRequest;
        request.teamIds   = approvedRequests.map(approvedRequest => approvedRequest.payload.teamId);
        this.apiHandler(this.contactGroupService.getTeams(request),
          result => {
            
            if (!result || !result.code || result.code != 200) {
              this.openErrorBar(result);
              return;
            }

            this.approvedRequestTeams = result.payload.sort((a, b) => a.id > b.id ? 1 : -1);
            let teams = result.payload;
            let teamNameMap = {};
            teams.forEach(team => teamNameMap[team._id] = team.name);
            approvedRequests.forEach(request => request["teamName"] = teamNameMap[request.payload.teamId]);
            this.renderApprovedRequestTable()
          })
      })
  }

  private requestApproveRequest(request) {
    this.apiHandler(this.approvalService.approveRequest(request),
      result => {
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }
        this.openSnackBar(this.translate("pages.approval.accepted"));
        this.requestPendingRequests();
        this.requestApprovedRequest();
      }
    )
  }

  private requestRejectRequest(request) {
    this.apiHandler(this.approvalService.rejectRequest(request),
      result => {
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }
        this.openSnackBar(this.translate("pages.approval.rejected"));
        this.requestPendingRequests();
        this.requestApprovedRequest();
      }
    )
  }


  // ===========================================================================
  // button callback functions
  private renderPendingRequestTable() {
    this.pendingRequestTablexParam['pageCount'] = Math.ceil(this.totalPendingRequestCount / this.pendingRequestTableCurrentPageSize);
    this.pendingRequestTablexParam['content'] = this.pendingRequests.map((data, index) => {
      let params = [
        data.id,
        // data.type,
        data.payload["requestWorkCentre"],
        data.createdBy,
        this.getRequestDescription(data),
        this.getDateStr(data.from),
        this.getDateStr(data.to),
        this.getDateStr(data.createdAt)
      ]

      let buttons = []
      if (this.hasApprovePermission && data["teamName"]) {
        buttons.push({
          "name": "",
          "class": "glyph brand-blue",
          "icon": "fas fa-check",
          "onClicked": this.onAcceptBtnClicked,
          "object": data
        })
      }
      if (this.hasRejectPermission) {
        buttons.push({
          "name": "",
          "class": "glyph brand-red",
          "icon": "fas fa-times",
          "onClicked": this.onRejectBtnClicked,
          "object": data
        })
      }
      if (buttons.length > 0) {
        params.push(buttons)
      }

      return params;
    })
  }

  private renderApprovedRequestTable() {
    this.approvedRequestTablexParam['pageCount'] = Math.ceil(this.totalApprovedRequestCount/this.approvedRequestCurrentPageSize);
    this.approvedRequestTablexParam['content'] = this.approvedRequests.map((data, index) => {
      let params = [
        data.id,
        data.payload["requestWorkCentre"],
        this.getRequestDescription(data),
        this.getDateStr(data.from),
        this.getDateStr(data.to),
        data.approvedBy,
        this.getDateStr(data.updatedAt)

      ]
      
      let buttons = []
      if (this.hasRejectPermission) {
        buttons.push({
          "name": "",
          "class": "glyph brand-red",
          "icon": "fas fa-times",
          "onClicked": this.onRejectBtnClicked,
          "object": data
        })
      }
      
      if (buttons.length > 0) {
        params.push(buttons)
      }

      return params;
    })
  }

  // ===========================================================================
  // button callback functions
  onAcceptBtnClicked = (button) => {
    let data = button.object;
    let message = this.translate("pages.approval.accept-message");
    let buttons = [
      { name: this.translate("pages.approval.accept"), handler: () => { this.requestApproveRequest(request); } },
      { name: this.translate("global.no")}
    ]

    let request   = {} as PostApprovalApproveRequestRequest;
    request.id    = data.id;

    this.showPopUpAlert(message, "", buttons);
  }

  onRejectBtnClicked = (button) => {
    let data = button.object;
    let message = this.translate("pages.approval.reject-message");
    let buttons = [
      { name: this.translate("pages.approval.reject"), handler: () => { this.requestRejectRequest(request); } },
      { name: this.translate("global.no")}
    ]

    let request   = {} as PostApprovalRejectRequestRequest;
    request.id    = data.id;

    this.showPopUpAlert(message, "", buttons);
  }

  onPendingRequestTablePageNumberClicked = (pageIndex: number) => {
    this.pendingRequestTableCurrentPage = pageIndex;
    this.requestPendingRequests();
  }

  onPendingRequestTablePageSizeClicked = (pageSize) => {
    this.pendingRequestTableCurrentPage       = 1;
    this.pendingRequestTableCurrentPageSize   = parseInt(pageSize);
    
    this.requestPendingRequests();
  }

  onApprovedRequestTablePageNumberClicked = (pageIndex: number) => {
    this.approvedRequestCurrentPage = pageIndex;
    this.requestApprovedRequest();
  }

  onApprovedRequestTablePageSizeClicked = (pageSize) => {
    this.approvedRequestCurrentPage       = 1;
    this.approvedRequestCurrentPageSize   = parseInt(pageSize);
    this.requestApprovedRequest();
  }

  // ===========================================================================
  // ui functions
  private updateSideBar() {
    let tempItem = JSON.parse(JSON.stringify(SIDEBAR_ITEMS.approval));
    tempItem = tempItem.filter(item => JMUTILITY.hasPermissions(this.user, item.permission, false));
    
    this.sideBarItem = tempItem.map(item => {
      item.subItems = item.subItems.filter(cell => JMUTILITY.hasPermissions(this.user, cell.permission, false));
      
      for (const subItem of item.subItems) {
        if (subItem.id === 'approval-sn-job') {
          subItem.title = JMLanguage.translate(subItem.title, [this.snJobApprovalCount]);
        }
        if (subItem.id === 'approval-pm-plan') {
          subItem.title = JMLanguage.translate(subItem.title, [this.pmPlanApprovalCount]);
        }
        if (subItem.id === 'approval-pm-job') {
          subItem.title = JMLanguage.translate(subItem.title, [this.pmJobApprovalCount]);
        }
        if (subItem.id === 'approval-team') {
          subItem.title = JMLanguage.translate(subItem.title, [this.totalPendingRequestCount]);
        }
      }
      return item;
    });
  }

  // ===========================================================================
  // functions
  private getDateStr(date: Date): string {
    if (date) {
      return formatDate(date, this.dateForm, 'en-US')
    } 
    
    return '';
  }
}
