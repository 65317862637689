<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'contract'"></app-sidebar>

  <div id="content">
    <div class="row mx-0">
      <div class="col-xl-3 col-12 col-md-4 mb-4 mb-md-0 p-0">
        <div class="contract-list-wrap">
          <div class="heading-wrap d-block mb-3">
            <h4>{{ 'pages.contractors.contract-list-title' | translate }}</h4>
            <span class="list-remarks d-inline-flex align-middle pr-4 font-size-xs">
              P = PMSMC<br>
              N = Non-PMSMC<br>
              ^ = {{ 'pages.contractors.dummy-contrat-prefix' | translate }}<br>
              * = {{ 'pages.contractors.transitional-contrat-prefix' | translate }}
            </span>
            <button type="button" *ngIf="hasEditionPermission" class="rectangle brand-blue add-contract-btn float-right" (click)="onAddContractBtnClicked($event)">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class="contract-list pr-2">
            <!-- <ng-container *ngIf="isLoadingContractOptions; else addedContractList">
              <div class="d-flex align-items-center justify-content-center" style="min-height: 600px;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">{{ "global.loading" | translate }}</span>
                </div>
              </div>
            </ng-container> -->
            <!-- <ng-template #addedContractList> -->
              <ul class="list-group" >
                <li class="heading-li list-group-item d-flex justify-content-between align-items-center px-1">
                  <div class="col-2 font-size-xs ispmsmc-col">
                    {{ "pages.contractors.th-pmsmc" | translate }}
                    <span class="sort-btn arrow-up" 
                      [ngClass]="{'arrow-down': uiContractSort == 'isPmsmcContract-1', 'active': uiSortActive == 'isPmsmcContract'} " 
                      (click)="onSortContractBtnClicked('isPmsmcContract', uiContractSort == 'isPmsmcContract-1'? 1 : -1)">
                    </span>
                  </div>
                  <div class="col-7 font-size-xs contract-number-col">
                    {{ "pages.contractors.th-contract-number" | translate }}
                    <span class="sort-btn arrow-up" 
                      [ngClass]="{'arrow-down': uiContractSort == 'contractNumber-1', 'active': uiSortActive == 'contractNumber'} " 
                      (click)="onSortContractBtnClicked('contractNumber', uiContractSort == 'contractNumber-1'? 1 : -1)">
                    </span>
                  </div>
                  <div class="col-3 font-size-xs contract-days-col">
                    {{ "pages.contractors.th-days-to-expire" | translate }}
                    <span class="sort-btn arrow-up"
                      [ngClass]="{'arrow-down': uiContractSort == 'vendorEndDate-1', 'active': uiSortActive == 'vendorEndDate'} " 
                      (click)="onSortContractBtnClicked('vendorEndDate', uiContractSort == 'vendorEndDate-1'? 1 : -1)">
                    </span>
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center px-1 clickable"
                  [class.active]=" contract.contractNumber == this.selectedContractNumber "
                  attr.id="contract-list-{{contract.contractNumber}}"
                  (click)="onContractChanged(i)" *ngFor="let contract of contractArray; let i = index">
                  <div class="col-1">
                    {{ contract.isPmsmcContract? "P" : "N" }}
                  </div>
                  <div class="col-7 text-break">
                    <span class="list-contract-number">{{ contract.formattedContractNumber }}</span>
                    <i class="expired-icon icon-absolute fas fa-exclamation-circle" *ngIf="dayToExpiry(contract.vendorStartDate) >= 0"
                    [ngbTooltip]="expiredToolTip" placement="bottom" ></i>
                  </div>
                  <div class="col-4 text-right">
                    <ng-container *ngIf="dayToExpiry(contract.vendorEndDate) < 0">
                      {{ "pages.contractor.contract-expired" | translate }}
                    </ng-container>
                    <ng-container *ngIf="dayToExpiry(contract.vendorEndDate) >= 0">
                      {{ dayToExpiry(contract.vendorEndDate) }}
                    </ng-container>
                  </div>
                 
                </li> 
              </ul> 
            <!-- </ng-template> -->
          </div>
        </div>
      </div>
      <div class="contract-detail-wrap col-xl-9 col-12 col-md-8">
        <ng-container *ngIf="isLoadingContractReminder;">
          <div class="d-flex justify-content-center pt-5">
            <div class="spinner-border" role="status">
              <span class="sr-only">{{ "global.loading" | translate }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isLoadingContractReminder">
          <ng-container *ngIf="!selectedContractNumber">
            <div class="d-block">
              {{ 'global.no-record-found' | translate}}
            </div>
          </ng-container>
          <ng-container *ngIf="selectedContractNumber">
            <div class="row my-3">
              <div class="col-3 bold label-name">
                {{ 'pages.contractors.contract-number-placeholder' | translate }}
              </div>
              <div class="col-7">
                <ng-container *ngIf="selectedContract">
                  {{ selectedContract.contractNumber }} 
                  (
                    {{ 'pages.contractors.from' | translate }}
                    {{ uiContractStartDate | date: 'dd/MM/yyyy' }}
                    {{ 'pages.contractors.to' | translate }}
                    {{ uiContractEndDate | date: 'dd/MM/yyyy' }}
                  )
                </ng-container>
              </div>
              <div class="col-2">
                <button type="button" *ngIf="hasEditionPermission" class="standard brand-amber float-right" 
                  (click)="onSaveBtnClicked()">
                  <i class="fas fa-save space"></i>
                  {{'global.save' | translate}}
                </button>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-3 bold label-name ">
                {{ 'pages.contractors.vendor-name' | translate }}
              </div>
              <div class="col-7">
                <ng-container *ngIf="selectedContract">
                  {{ selectedContract.vendorName }} <b>({{ uiSelectedContractHandlingParty }})</b>
                </ng-container>
              </div>
              <div class="col-2" 
                [disableTooltip]="uiEnableRemoveButton"
                [ngbTooltip]="contractLinkedToTeamToolTip"
              >
                <button type="button" *ngIf="hasEditionPermission"
                  class="btn standard brand-red float-right"
                  (click)="onRemoveBtnClicked()"
                  [disabled]="!uiEnableRemoveButton"
                >
                  <i class="fa fa-times text-white"></i>
                  {{'global.remove' | translate}}
                </button>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-3 bold label-name ">
                {{ 'pages.contractors.description' | translate }}
              </div>
              <div class="col-9">
                <ng-container *ngIf="selectedContract">
                  {{ selectedContract.title }}
                </ng-container>
              </div>
            </div>
            
            <div class="row my-3">
              <div class="col-3 bold text-right">
                {{ 'pages.contractors.reminder-1-label' | translate }}
              </div>
              <div class="col-9">
                <div class="d-block checkbox-wrap position-relative custom-control custom-checkbox mb-2">
                  <input type="checkbox" 
                    id="firstReminder"
                    [disabled]="!hasEditionPermission"
                    [(ngModel)]="uiEnableReminder_1"
                    class="checkbox custom-control-input"
                    (change)="onEnableReminder1Changed($event)"
                  >
                  <label for="firstReminder" class="custom-control-label d-block">
                    <input type="number" 
                      [(ngModel)]="uiReminderDay_1" 
                      name="firstReminderDays" 
                      id="firstReminderDays" 
                      class="px-1 py-0 d-inline-block form-control number-input-box reminder-number"
                      [disabled]="!uiEnableReminder_1 || !hasEditionPermission"
                      (keyup)="onReminderDay1Changed($event)"
                    >
                    {{ 'pages.contractors.reminder-day-label' | translate }}
                  </label>
                </div>
                <div >
                  {{"pages.contractors.reminder-date-1-label" | translate}}<b>{{ uiReminderDate_1 | date: 'dd/MM/yyyy HH:mm' }}</b>

                  <i class="expired-icon fas fa-exclamation-circle mx-2" 
                    *ngIf="uiEnableRefreshReminder && hasEditionPermission" 
                    [ngbTooltip]="refreshReminderDateToolTip" 
                    placement="bottom" ></i>
                  <button type="button" 
                    *ngIf="uiEnableRefreshReminder && hasEditionPermission" 
                    class="standard brand-amber" 
                    (click)="onRefreshBtnClicked()">
                    {{'global.refresh' | translate}}
                  </button>                  
                </div>

              </div>
            </div>
            <div class="row my-3">
              <div class="col-3 bold text-right">
                {{ 'pages.contractors.reminder-2-label' | translate }}
              </div>
              <div class="col-9">
                <div class="d-block checkbox-wrap position-relative custom-control custom-checkbox">
                  <input type="checkbox" 
                    id="secondReminder"
                    [disabled]="!hasEditionPermission"
                    [(ngModel)]="uiEnableReminder_2" 
                    class="checkbox custom-control-input" 
                    (change)="onEnableReminder2Changed($event)"
                  >
                  <label for="secondReminder" class="custom-control-label d-block">
                    <input type="number" 
                      [(ngModel)]="uiReminderDay_2" 
                      name="secondReminderDays" 
                      id="secondReminderDays" 
                      class="px-1 py-0 d-inline-block form-control number-input-box reminder-number"
                      [disabled]="!uiEnableReminder_2 || !hasEditionPermission"
                      (keyup)="onReminderDay2Changed($event)"
                    >
                    {{ 'pages.contractors.reminder-day-label' | translate }}
                  </label>
                </div>
                <div >
                  {{"pages.contractors.reminder-date-2-label" | translate}}<b>{{ uiReminderDate_2 | date: 'dd/MM/yyyy HH:mm' }}</b>
                  
                  <i class="expired-icon fas fa-exclamation-circle mx-2" 
                    *ngIf="uiEnableRefreshReminder && hasEditionPermission" 
                    [ngbTooltip]="refreshReminderDateToolTip" 
                    placement="bottom" ></i>
                </div>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-3 bold label-name ">
                {{ 'pages.contractors.responsible-senior-engineer' | translate }}
              </div>
              <div class="col-9">
                {{ selectedContract.responsibleSeniorEngineer}}
              </div>
            </div>

            <div class="row my-3">
              <div class="col-3 bold label-name ">
                {{ 'pages.contractors.responsible-engineer' | translate }}
              </div>
              <div class="col-9">
                {{ selectedContract.responsibleEngineer}}
              </div>
            </div>


            <div class="row my-3">
              <div class="col-3 bold label-name ">
                {{ 'pages.contractors.first-reminder-recipients' | translate }}
              </div>
              <div class="col-9">
                <ng-select 
                  id="contractReminderRecipientArray_1"
                  [(ngModel)]="uiReminderRecipientArray_1"
                  [items]="workCentrePostArray"
                  bindValue="name"
                  bindLabel="name"
                  [multiple]="true" 
                  [closeOnSelect]="false"
                  [searchable]="true" 
                  [disabled]="!uiEnableReminder_1 || !hasEditionPermission"
                >
                </ng-select>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-3 bold label-name ">
                {{ 'pages.contractors.second-reminder-recipients' | translate }}
              </div>
              <div class="col-9">
                <ng-select 
                  id="contractReminderRecipientArray_2"
                  [(ngModel)]="uiReminderRecipientArray_2"
                  [items]="workCentrePostArray"
                  bindValue="name"
                  bindLabel="name"
                  [multiple]="true" 
                  [closeOnSelect]="false"
                  [searchable]="true" 
                  [disabled]="!uiEnableReminder_2 || !hasEditionPermission"
                >
                </ng-select>
              </div>
            </div>

            <hr class="underline my-4">
            
            
            <div class="row my-3">
              <div class="col-3 bold text-right">
                {{ 'pages.contractors.switch-over' | translate}}
              </div>
              <div class="col-9 switch-date-col">
                <!-- <input type="text" class="px-2 d-inline-block form-control  contract-number-input"
                  placeholder="{{'pages.contractors.contract-number-placeholder' | translate}}" 
                  [(ngModel)]="uiNewContract"
                  > -->
                <ng-select 
                  id="newContract"
                  class="col-5 d-inline-block align-middle"
                  [(ngModel)]="uiNewContract"
                  [items]="uiAvailableNewContract"
                  bindLabel="label"
                  bindValue="value"
                  [multiple]="false" 
                  [clearable]="false"
                  [closeOnSelect]="true"
                  [searchable]="true" 
                  [disabled]="!uiEnableNewContract || !hasEditionPermission"
                  (change)="onNewContractChanged($event)"
                >
                </ng-select>
                <div class="d-inline-block px-2">{{'pages.contractors.on' | translate}}</div>

                <!-- <input class="px-2 form-control date-picker-input-box d-inline-block"
                  placeholder="yyyy-mm-dd" 
                  name="date"
                  ngbDatepicker 
                  #date="ngbDatepicker" 
                  (click)="date.toggle()"
                  [(ngModel)]="uiSwtichOverDate"
                  readonly
                > -->
                <span id="switch-start-date">
                  {{ uiSwtichOverDate | date: 'dd/MM/yyyy' }}
                </span>
                
                <i class="expired-icon fas fa-exclamation-circle ml-2" *ngIf="uiShowNoEffectiveContractWarning" 
                  [ngbTooltip]="periodToolTip" placement="bottom" ></i>
                <button type="button" *ngIf="hasEditionPermission"
                  class="standard brand-amber float-right" 
                  [disabled]="!uiEnableNewContract"
                  (click)="onClearBtnClicked()">
                  {{'global.clear' | translate}}
                </button>
              </div>
            </div>

            <ng-container *ngIf="isLoadingSwitchOverContract">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">{{ "global.loading" | translate }}</span>
                </div>              
              </div>
            </ng-container>
  
            <ng-container *ngIf="!isLoadingSwitchOverContract">

              <div class="row my-3">
                <div class="col-3 bold label-name ">
                  {{ 'pages.contractors.vendor-name' | translate }}
                </div>
                <div class="col-9">
                  <ng-container *ngIf="swtichOverContract">
                    {{ swtichOverContract?.vendorName }} <b>({{ uiNewContractHandlingParty }})</b>
                  </ng-container>
                </div>
              </div>
              
              <div class="row my-3">
                <div class="col-3 bold label-name ">
                  {{ 'pages.contractors.effective-date' | translate }}
                </div>
                <div class="col-9">
                  <ng-container *ngIf="swtichOverContract">
                    {{ 'pages.contractors.from' | translate }}
                    {{ uiNewContractStartDate | date: 'dd/MM/yyyy' }}
                    {{ 'pages.contractors.to' | translate }}
                    {{ uiNewContractEndDate | date: 'dd/MM/yyyy' }}
                  </ng-container>
                </div>
              </div>

              <div class="row my-3">
                <div class="col-3 bold label-name ">
                  {{ 'pages.contractors.description' | translate }}
                </div>
                <div class="col-9">
                  <ng-container *ngIf="swtichOverContract">
                    {{ swtichOverContract?.title }}
                  </ng-container>
                </div>
              </div>


            </ng-container>

            <div class="row mx-0 d-inline-block w-100" *ngIf="!hideContent">
              <div class="equipments-table-wrap col-12 col-xl-12">
                <hr class="underline mt-5 mb-4">
                <div class="row ">
                  <div class="col-12 bold">
                    {{ 'pages.contractors.equipment-list' | translate}}
                    ( {{ 'pages.contractors.number-of-equipment' | translate }}: 
                    <ng-container *ngIf="equipmentsList">
                      {{ uiTotalEquipmentCount }}
                    </ng-container>
                    <ng-container *ngIf="!equipmentsList">
                      {{ '-' }}
                    </ng-container>
                    )
                  </div>
                </div>
                <div class="row ">
                  <div class="col-12">
                    <ng-container>
                      <app-tablex id='contract_equipment_table' #contract_equipment_table [tablexParam]="tablexParam" ></app-tablex>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      

    </div>
    <!--Slider panel-->
    <ng-template #expiredToolTip>
      <div class="warning-tooltip-wrap text-center">
        {{ "pages.contractors.expired-message" | translate }}
      </div>
    </ng-template>

    <custom-slider-panel #add_contract_panel [customClass]="'add_contract_panel'">
      <add-contract-form #addContractForm [parameters]="addContractFormParameters"></add-contract-form>
    </custom-slider-panel>

    <!-- tool tip -->
    <ng-template #periodToolTip>
      <div class="warning-tooltip-wrap text-center">
        {{ "pages.contractors.contract-period-message" | translate }}
      </div>
    </ng-template>
    <ng-template #contractLinkedToTeamToolTip>
      <div class="warning-tooltip-wrap text-center">
        {{ "pages.contractors.have-team-using-this-contract" | translate }}
      </div>
    </ng-template>
    <ng-template #refreshReminderDateToolTip>
        <div class="warning-tooltip-wrap text-center">
          {{ "pages.contractors.refresh-reminder-date-tooltip" | translate }}
        </div>
    </ng-template>
  </div>
</div>