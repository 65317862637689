import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { StockReserveListComponent } from './stock-reserve-list.component';

@Injectable({
  providedIn: 'root'
})
export class StockReserveListGuard implements CanDeactivate<StockReserveListComponent> {  
  canDeactivate(
    component: StockReserveListComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean|UrlTree>| Promise<boolean|UrlTree> | boolean | UrlTree {
    if (component.haveUserChanges) {
      const buttons = [
        {
          name: JMLanguage.translate("global.leave-without-submit.confirm"),
          handler: this.pageChangePermitBtnCallback
        },
        {
          name: JMLanguage.translate("global.no"),
          handler: this.pageChangeDeclineBtnCallback
        }
      ];
      const dialogRef = AppDelegate.showPopUpAlert(
        JMLanguage.translate("global.leave-without-submit"), null, buttons
      );
        
      const pageChangeObservable = new Observable<boolean>((subscriber) => {
        dialogRef.afterClosed().subscribe(action => {
          subscriber.next(action());
        })
      });
      return pageChangeObservable;

    } else {
      return !component.haveUserChanges;
    }
  }

  pageChangePermitBtnCallback() {
    return true;
  }

  pageChangeDeclineBtnCallback() {
    return false;
  }
}
