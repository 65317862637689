import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { ActionButtonDefinition, ActionButtonSn, ActionButtonType } from '@enum/action-button';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-sn-edit',
  templateUrl: './sn-edit.component.html',
  styleUrls: ['./sn-edit.component.scss']
})
export class SnEditComponent extends BasePage implements OnInit {

  breadcrumbs: any = [];
  snNumber: string;
  sn: JMOBJ.ServiceNotification = undefined;
  currentPageMode = JMENUM.JMPageMode.EDIT;
  JMPage = JMENUM.JMPage;

  actionButtonData = [];

  additionalRemark: JMOBJ.Remark = new JMOBJ.Remark();

  faultDetailValid: boolean = false;
  haValid: boolean = false;
  notiInfoDetailValid: boolean = false;

  isHaEquipmentOwner: boolean = false;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    this.snNumber = this.route.snapshot.paramMap.get('snNumber');
    this.breadcrumbs = [
      { id: 'breadcrumbs-sn-number', name: this.snNumber, route: '/sn/view/' + this.snNumber },
      { id: 'breadcrumbs-edit', name: "Edit", route: null, currentPage: true }
    ];

    if (!this.authorizationService.hasPermissions([JMENUM.Permission.SN_UPDATE, JMENUM.Permission.SN_UPDATE_ALL], false)) {
      let path = '/sn/view/' + this.snNumber;
      this.router.navigate([path]);
    }
    await this.requestSn();
    this.initActionButton();
  }

  private async requestSn() {
    let request: JM.JMRequestSnGetSn = new JM.JMRequestSnGetSn();
    request.snNumber = this.snNumber;
    request.excludeTeamInformation = false;

    const response:JM.JMResponseSnGetSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.sn = (response.payload) ? response.payload : undefined;
    this.isHaEquipmentOwner = (this.sn && this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA) ? true : false;

    if (this.sn) {
      // this.getManualInstructionStage();
      // this.hasManualInstruction(false);
    }
  }

  onHAValidation(valid) {
    this.haValid = valid;
  }

  onFaultDetailValidation(valid) {
    this.faultDetailValid = valid;
  }

  onNotiInfoDetailValidation(valid) {
    this.notiInfoDetailValid = valid;
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = this.translateService.instant(button.name);
      })
      this.showPopUpAlert(this.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  initActionButton() {
    switch (this.sn.status) {
      case JMENUM.SnStatus.DRAFT:
        this.addActionBtn(ActionButtonSn.save);
        this.addActionBtn(ActionButtonSn.copyInNew);
        this.addActionBtn(ActionButtonSn.submit);
        this.addActionBtn(ActionButtonSn.clear);
        //this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.close);
        break;
      case JMENUM.SnStatus.PENDING_ACKNOWLEDGE:
      case JMENUM.SnStatus.ACKNOWLEDGED:
      case JMENUM.SnStatus.NO_RESPONSE:
      case JMENUM.SnStatus.IN_PROGRESS:
        this.addActionBtn(ActionButtonSn.save);
        this.addActionBtn(ActionButtonSn.saveAndResend);
        this.addActionBtn(ActionButtonSn.copyInNew);
        //this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.close);
        break;
      case JMENUM.SnStatus.REJECTED:
      case JMENUM.SnStatus.COMPLETED:
      case JMENUM.SnStatus.CANCELLED:
      case JMENUM.SnStatus.SIGNED_OFF:
        this.addActionBtn(ActionButtonSn.save);
        this.addActionBtn(ActionButtonSn.copyInNew);
        //this.addActionBtn(ActionButtonSn.attachment);
        this.addActionBtn(ActionButtonSn.close);
        break;
      default:
        this.addActionBtn(ActionButtonSn.close);
        break;
    }
  }


  addActionBtn(buttonStatus: ActionButtonSn): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.sn][buttonStatus];

    if (actionButton.buttons && !this.authorizationService.hasPermission(actionButton.permission))
      return;

    if (buttonStatus == ActionButtonSn.save) {
      if (this.sn && this.sn.status == JMENUM.SnStatus.DRAFT) {
        if (!this.authorizationService.hasPermission(JMENUM.Permission.SN_DRAFT))
          return;
      } else {
        // check is the create user or has sn update permission
        if (!this.authorizationService.hasPermission(JMENUM.Permission.SN_UPDATE))
          return;
      }
    }
    if (buttonStatus == ActionButtonSn.attachment) {
      actionButton.popupTitle = 'pages.sn-edit.msg.popup';
      actionButton.buttons = [{ "name": "global.leave-without-save" }, { "name": "global.no" }];
      actionButton.showPopup = true;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : this.translateService.instant("global.yes"),
        handler: () => {
          switch (buttonStatus) {
            case ActionButtonSn.save:
              this.onClickedActionButtonSave();
              break;
            case ActionButtonSn.saveAndResend:
              this.onClickedActionButtonSaveAndResend();
              break;
            case ActionButtonSn.copyInNew:
              this.onClickedActionButtonCopyInNew();
              break;
            //TODO: enable when this page handle draft and reroute case
            // case ActionButtonSn.submit:
            //   this.onClickedActionButtonSubmit();
            //   break;
            // case ActionButtonSn.clear:
            //   this.onClickedActionButtonClear();
            //   break;
            case ActionButtonSn.attachment:
              this.attachment();
              break;
            case ActionButtonSn.close:
              this.onClickedActionButtonClose();
              break;
            default:
              break;
          }
        }
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : this.translateService.instant("global.no"),
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  onClickedActionButtonSave(): void {
    // if (this.checkSnValidForSave(this.sn)) {
    if ((this.notiInfoDetailValid && this.faultDetailValid && (this.isHaEquipmentOwner ? this.haValid : true)) || this.sn.status == JMENUM.SnStatus.CANCELLED) {
      //TODO: enable when draft and reroute
      // this.saveClientInformation();
      if (this.sn && this.sn.status != JMENUM.SnStatus.DRAFT) {
        this.requestUpdateSN();
      }
      //TODO: enable when draft and reroute
      // } else {
      //   this.requestDraftSn();
      // }
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.error.msg.mandatory-field"));
    }
  }

  onClickedActionButtonSaveAndResend(): void {
    // if (this.checkSnValidForSave(this.sn)) {
    if (this.notiInfoDetailValid && this.faultDetailValid && (this.isHaEquipmentOwner ? this.haValid : true)) {
      //TODO: enable when draft and reroute
      // this.saveClientInformation();
      this.requestUpdateSN(true);
    }
    else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.error.msg.mandatory-field"));
    }
  }

  onClickedActionButtonCopyInNew(): void {
    // if (this.checkSnValidForSave(this.sn)) {
    if (this.notiInfoDetailValid && this.faultDetailValid && (this.isHaEquipmentOwner ? this.haValid : true)) {
      this.requestCopyInNew();
    }
    else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.error.msg.mandatory-field"));
    }
  }

  onClickedActionButtonClose(): void {
    let path = '/sn/view/' + this.sn.snNumber;
    this.router.navigate([path]);
  }

  public attachment(): void {
    let path = '/sn/view/' + this.sn.snNumber + '/edit/attachment';
    this.router.navigate([path]);
  }

  private async requestCopyInNew() {
    let request = new JM.JMRequestSnDraft();

    // no team and remark information
    request.contactPerson = this.sn.contactPerson;
    request.contactNumber = this.sn.contactNumber;
    request.email = this.sn.email ? this.sn.email.trim() : null;
    request.locationDetail = this.sn.locationDetail ? this.sn.locationDetail.trim() : null;
    request.faultDetail = this.sn.faultDetail ? this.sn.faultDetail.trim() : null;
    request.internalRemarks = this.sn.internalRemarks ? this.sn.internalRemarks.trim() : null;
    request.client = this.sn.client ? this.sn.client : null;
    request.district = this.sn.district ? this.sn.district : null;
    request.location = this.sn.location ? this.sn.location : null;
    request.equipmentCategory = this.sn.equipmentCategory ? this.sn.equipmentCategory : null;
    request.equipmentType = this.sn.equipmentType ? this.sn.equipmentType : null;
    request.priority = this.sn.priority;
    request.remarks = [];
    request.assetNumber = this.sn.eamData && this.sn.eamData.assetNumber ? this.sn.eamData.assetNumber.trim() : null;

    if (this.sn.crmData && (!this.sn.status || this.sn.status == JMENUM.SnStatus.DRAFT)) {
      request.crmData = {
        externalRefId: this.sn.crmData.externalRefId,
        externalCallbackUrl: this.sn.crmData.externalCallbackUrl
      }
    }

    const response: JM.JMResponseSnDraft = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let newSn = response.payload;
    window.open('/sn/create/' + newSn.snNumber + '/edit', '_blank');
  }

  private async requestUpdateSN(resend = false) {
    const request: JM.JMRequestSnUpdate = new JM.JMRequestSnUpdate();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;
    request.contactPerson = this.sn.contactPerson ? this.sn.contactPerson.trim() : null;
    request.contactNumber = this.sn.contactNumber;
    request.email = this.sn.email ? this.sn.email.trim() : null;
    request.locationDetail = this.sn.locationDetail ? this.sn.locationDetail.trim() : null;
    request.faultDetail = this.sn.faultDetail ? this.sn.faultDetail.trim() : null;
    request.internalRemarks = this.sn.internalRemarks ? this.sn.internalRemarks.trim() : null;
    request.resendNotification = resend;
    request.fehdVenue = this.sn.fehdVenue ? this.sn.fehdVenue : null;
    request.remarks = [];

    request.eamData = this.sn.eamData;

    // //TODO: edit api not ready for attachment
    // //request.attachmentDataList = this.sn.attachmentList;

    if (this.additionalRemark.remark) {
      this.sn.remarks.unshift(this.additionalRemark);
    }
    request.remarks = this.sn.remarks.map(remark => {
      return {
        _id: remark._id,
        remark: remark.remark,
        visibilityType: remark.visibilityType
      }
    });

    const response: JM.JMResponseSnUpdate = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.msg.saved"));
    this.sn = response.payload;
    this.router.navigate(["/sn/view/", this.sn.snNumber]);
  }

  //==========================================================================
  // getter
  get isPendingApproval(): boolean {
    if (!this.sn) { return false; }

    let isPendingApproval = this.sn.pendingApproval ? true : false;
    if (this.sn.jobCardPendingApprovalList && this.sn.jobCardPendingApprovalList.length) {
      for (let item of this.sn.jobCardPendingApprovalList) {
        if (item.pendingApproval) {
          isPendingApproval = true;
          break;
        }
      }
    }

    return isPendingApproval;
  }
}
