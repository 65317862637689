<div class="wrapper">
  <app-nav [navbarTitle]="'pages.pm-period-list.page-title' | translate">
  </app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="">
    <div class="row mx-0 flex-column-reverse flex-xl-row">
      <div class="col-12 col-xl-9 ">
        <!-- <button class="btn standard brand-blue ml-auto d-block" (click)="onClickCreatePMTask()" [disabled]="!selectedInfo">
          {{ "pages.pm-plan-list.create-pm-job" | translate }}
        </button> -->
        <app-tablex id="pm-period-list-table" #pmPeriodListTable [tablexParam]="tablexParam" class=""></app-tablex>
      </div>
      <div class="col-12 col-xl-3">
        <app-pm-period-search-criteria class="col-12 col-xl-3" (onSearch)="onAdvancedSearch($event)"></app-pm-period-search-criteria>
      </div>
    </div>
  </div>
</div>