<div class="wrapper">
    <app-nav [navbarTitle]="'pages.teco.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>

    <app-sidebar [type]="'default'"></app-sidebar>

    <ng-container *ngIf="actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
            (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <div id="content" class="teco-content">
        <!-- component of Advanced Search -->
        <app-teco-advanced-search id="teco-advanced-search-panel" [parameters]="advancedSearchPara" (onSubmit)="onAdvancedSearch($event)">
        </app-teco-advanced-search>

        <!-- tablex -->
        <app-tablex id="teco-table" [tablexParam]='tablexParam'></app-tablex>
    </div>
</div>