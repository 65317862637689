<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>
  
  <app-sidebar [type]="'workCenter'" ></app-sidebar>

  <div id="content">
    <!--Event Count Layout-->
    <div class="w-100 mx-0 px-0">
      <div class="row justify-content-between">
        <div class="col-auto align-self-center">
          <ng-container *ngIf="hasEditPermission">
            <button
              type="button"
              class="standard brand-blue"
              (click)="onAddRoutingRuleClicked()"
            >
              <i class="fas fa-edit space"></i>
              {{"pages.routing-rules-list.add-routing-rule"|translate}}
            </button>
          </ng-container>
        </div>
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="standard brand-blue float-right"
            (click)="onExportExcelClick()"
            [disabled]="isExportExcelButtonLoading"
          > 
            <span *ngIf="isExportExcelButtonLoading">
              <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
              {{'global.loading' | translate}}
            </span>
            <span *ngIf="!isExportExcelButtonLoading">
              <i class="fas fa-file-download space"></i>
              {{"global.export-excel"|translate}}
            </span>
          </button>
          <ng-container *ngIf="hasEditPermission && false">
            <button
              type="button"
              class="standard brand-amber float-right refresh-all-button"
              (click)="onRefreshAllClick()"
              [disabled]="isRefreshAllButtonLoading"
            > 
              <span *ngIf="isRefreshAllButtonLoading">
                <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                {{'global.loading' | translate}}
              </span>
              <span *ngIf="!isRefreshAllButtonLoading">
                <i class="fas fa-sync space"></i>
                {{"global.refresh-all"|translate}}
              </span>
            </button>
          </ng-container>
        </div>
      </div>
      
      <div class="row">
        <div class="col-12">
            <app-tablex id='routing_rule_table' [tablexParam]='tablexParam' class='mt-3'>
            </app-tablex>
        </div>
      </div>
    </div>

  </div>
</div>
