<div class="wrapper">
  <app-nav (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar></app-sidebar>

  <!-- Page Content -->
  <div id="content">
    <form>
      <div class="row justify-content-between row-space">
        <div class="col-auto align-self-center">
          <h2 class="title">
            {{'pages.report-download.contractor-cm-job' | translate}}
            - {{'pages.report-download.report' | translate}}
          </h2>
        </div>
      </div>
      <hr class="underline" />

      <div id="search-panel" class="collapse show">
        <div class="row row-space">
          <div class="d-flex col-12 flex-wrap flex-wrap">
            <!-- Time Range -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title">
                  {{'pages.report-download.creation-time' | translate}}
                </span>
              </div>
              <div class="input-field-group">
                <input id="report-download_start-date_inputbox" class="form-control date-picker-input-box"
                  name="createTimeFrom" placeholder="{{'pages.report-download.from-date' | translate}}" readonly
                  ngbDatepicker #createTimeFrom="ngbDatepicker" [maxDate]="setTodayDate()"
                  [disabled]="isDisabledNormalTimeRange" [(ngModel)]="filterCriteria.createTimeFrom"
                  (ngModelChange)="onChangeStartDate($event)" (dateSelect)="filterCriteria.createTimeTo = null"
                  (click)="createTimeFrom.toggle()" />
              </div>
              <div class="input-field-group ">
                <input id="report-download_end-date_inputbox" class="form-control date-picker-input-box" name="createTimeTo"
                  placeholder="{{'pages.report-download.to-date' | translate}}" readonly ngbDatepicker
                  #createTimeTo="ngbDatepicker" [minDate]="setMinEndDate(filterCriteria.createTimeFrom)"
                  [maxDate]="createTimeToMaxDate" [disabled]="!filterCriteria.createTimeFrom || isDisabledNormalTimeRange"
                  [(ngModel)]="filterCriteria.createTimeTo" (click)="createTimeTo.toggle()" />
              </div>
              <div class="input-field-group" style="padding: 5px 16px 0; margin-bottom: 0;" >
                <div class="time-slider blue">
                  <ng5-slider [(value)]="normalTimeRangeValue" [options]="normalTimeRangeOptions"
                    (valueChange)="timeRangeChanged()"></ng5-slider>
                </div>
              </div>
            </div>

            <!-- Client and Location -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title">{{'pages.report-download.client-and-location' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_client_dropdown" [items]="clientOption" bindLabel="label"
                    bindValue="value" [(ngModel)]="filterCriteria.client"
                    placeholder="{{'pages.report-download.client-any' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <ng-select id="report-download_search-location" 
                  placeholder="{{'pages.report-download.location' | translate}}" bindLabel="label"
                  bindValue="value" [items]="locationOptions" [loading]="isLoadingLocation" [multiple]="false"
                  (clear)="clearLocationFilter()" (scrollToEnd)="onLocationScrollToEnd()"
                  (search)="onSearchLocation($event)" (open)="clearLocationFilter()"
                  [(ngModel)]="filterCriteria.location" [ngModelOptions]="{standalone: true}">
                </ng-select>
              </div>
            </div>

          </div>

          <div class="d-flex col-12 flex-wrap">
            <!-- Contract -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title">{{'pages.report-download.contracts' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_contractor_dropdown" bindLabel="label" bindValue="value"
                    placeholder="{{ 'pages.report-download.contractor-all' | translate }}" [multiple]="false"
                    [items]="contractorOptions" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="filterCriteria.contractor" (scrollToEnd)="onContractorScrollToEnd()"
                    [loading]="isLoadingContractor"
                    (search)="onSearchContractor($event)"
                    (open)="clearContractorFilter()" >
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_contract-number_dropdown" class="px-0 mb-3" bindLabel="label" bindValue="value"
                    placeholder="{{'pages.report-download.contract-number-all' | translate}}" [multiple]="true"
                    [items]="contractOptions" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="filterCriteria.contractList" (scrollToEnd)="onContractScrollToEnd()"
                    [loading]="isLoadingContract"
                    (search)="onSearchContract($event)" (open)="clearContractFilter()">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_handling-party_dropdown" bindLabel="label" bindValue="value"
                    placeholder="{{'pages.report-download.handling-party-all' | translate}}" [multiple]="false"
                    [items]="handlingPartyOptions" [ngModelOptions]="{standalone: true}" [disabled]="false"
                    [(ngModel)]="filterCriteria.handlingParty">
                  </ng-select>
                </div>
              </div>
            </div>

            <!-- Status -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title">{{'pages.report-download.status' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_job-status_dropdown" [items]="statusOption" bindLabel="label"
                    bindValue="id" [(ngModel)]="filterCriteria.status"
                    placeholder="{{'pages.report-download.status-all' | translate}}"
                    [ngModelOptions]="{standalone: true}" [multiple]='true'>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex col-12 flex-wrap">
            <!-- Compliance Status -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title">{{'pages.report-download.contractor-cm-job.kpi-status' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_response-to-client-time-type_dropdown" [items]="complianceStatusOption"
                    bindLabel="label" bindValue="value"
                    [(ngModel)]="filterCriteria.responseToClientTimeComplianceStatus"
                    placeholder="{{'pages.report-download.response-to-client-time-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_response-time_dropdown" [items]="complianceStatusOption"
                    bindLabel="label" bindValue="value" [(ngModel)]="filterCriteria.responseTimeComplianceStatus"
                    placeholder="{{'pages.report-download.response-time-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_rectification-time_dropdown" [items]="complianceStatusOption"
                    bindLabel="label" bindValue="value" [(ngModel)]="filterCriteria.rectificationTimeComplianceStatus"
                    placeholder="{{'pages.report-download.rectification-time-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center row-space">
          <button id='export_excel_btn' type="button" class="standard brand-blue" (click)="onClickExportExcel()"
            [disabled]="isExportExcelButtonLoading">
            <div *ngIf="isExportExcelButtonLoading" class="d-inline-block loading-spinner spinner-small">
              {{ "global.loading" | translate }}
            </div>
            <span *ngIf="!isExportExcelButtonLoading">
              <i class="fas fa-download space"></i>
              {{'global.export-excel' | translate}}
            </span>
          </button>
        </div>

      </div>
    </form>

  </div>
</div>