

<div class="font-size-xl m-0 mb-2 px-2">
  {{ "component.reserve-stock.title" | translate }}
  <span *ngIf="detailLink">(<a 
    class="stock-detail"
    [routerLink]="detailLink"
    >{{ "component.reserve-stock.detail" | translate }}</a>)</span>
</div>
<hr class="underline" />
<app-tablex id="reserve-table" [tablexParam]="tablexParam" class="mb-4"></app-tablex>

<div class="row align-items-center mt-4">
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.reserve-stock.column.reservation-no" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="ccs-information-sla-item-number"
      [textField]="jobCard.stockReservations[0]?.reservationNumber"
      [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>
</div>
