<div class="mb-2 font-size-xl">{{ "component.gps-location-submitted-table.title" | translate }}</div>
<div class="mb-2">{{ "component.gps-location-submitted-table.remark" | translate }}</div>
<div class="gps-location-table-wrap mb-2" [class.scroll-y]="arrivalGpsParam?.gpsLocations && arrivalGpsParam?.gpsLocations.length > 0">
    <table class="gps-location-table w-100">
        <tr class="table-header">
            <th>{{ "component.gps-location-submitted-table.submission-time" | translate }}</th>
            <th>{{ "component.gps-location-submitted-table.submitted-by" | translate }}</th>
            <th>{{ "component.gps-location-submitted-table.gps-location" | translate }}</th>
        </tr>
        <ng-container *ngIf="arrivalGpsParam?.gpsLocations && arrivalGpsParam?.gpsLocations.length > 0">
            <ng-container *ngFor="let gpsLocation of arrivalGpsParam.gpsLocations; let p = index;">
                <tr>
                    <td> <span *ngIf="gpsLocation?.reportedAt"
                            [ngClass]="{'red-text reportedAt': checkIsReportingArrivalTimeNotMatchInterval(gpsLocation.reportedAt, arrivalGpsParam.startTime, arrivalGpsParam.completionTime) }">{{
                            setSubmittedTime(gpsLocation.reportedAt) }}</span></td>
                    <td> <span *ngIf="gpsLocation?.reportedBy"
                            [ngClass]="{'red-text': checkIsReportingArrivalTimeNotMatchInterval(gpsLocation.reportedAt, arrivalGpsParam.startTime, arrivalGpsParam.completionTime) }">{{
                            gpsLocation.reportedBy }}</span></td>
                    <td> <a *ngIf="gpsLocation?.location" class="wrapper d-flex align-items-center mx-1"
                            href="{{setGpsUrl(gpsLocation.location)}}" target="_blank">{{"action.button.view" |
                            translate}}</a></td>
                </tr>
            </ng-container>
        </ng-container>
    </table>
</div>
<div class="row justify-content-center row-space">
    <app-submit-button [id]="'gps-location-table_close_button'"
    [text]="'global.close'" [buttonClass]="'standard brand-amber m-2 w-2'" (onClick)="onDirectCloseBtnClicked()"></app-submit-button>
</div>
