import { Component, OnInit, Input, Injector } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';

@Component({
  selector: 'app-manual-instruction-detail-panel',
  templateUrl: './manual-instruction-detail-panel.component.html',
  styleUrls: ['./manual-instruction-detail-panel.component.scss']
})
export class ManualInstructionDetailPanelComponent extends BasePage implements OnInit {

  @Input() manualInstructionDetail: any = {
    team: undefined,
    priority: undefined,
  };

  priority = undefined;
  team = undefined;
  contractorResponseTime = 0;
  roResponseTime = 0;
  preActionArray = [];
  contractorNoResponseArray = [];
  roNoResponseArray = [];

  constructor(injector: Injector) { super(injector); }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.manualInstructionDetail.priority) {
      this.priority = this.manualInstructionDetail.priority;
    }
    if (this.manualInstructionDetail.team) {
      this.team = this.manualInstructionDetail.team;
      let responseTime = this.team.responseTime;
      this.contractorResponseTime = (responseTime && responseTime[this.priority][0]) ? this.team.responseTime[this.priority][0] : 0;
      if (responseTime && responseTime[this.priority]) {
        for (let i = 1; i < responseTime[this.priority].length; i++) {
          this.roResponseTime += (responseTime[this.priority][i]) ? responseTime[this.priority][i] : 0;
        }
      }
      let manualInstructions = this.team.manualInstructions;
      if (manualInstructions && manualInstructions[this.priority]) {
        this.getManualInstructionsDetail(manualInstructions, "preAction", this.preActionArray);
        this.getManualInstructionsDetail(manualInstructions, "contractorNoResponse", this.contractorNoResponseArray);
        this.getManualInstructionsDetail(manualInstructions, "roNoResponse", this.roNoResponseArray);
      }
    }


  }

  private getManualInstructionsDetail(manualInstructions, key, array) {
    if (manualInstructions[this.priority][key]) {
      let manualInstructionArray = manualInstructions[this.priority][key];
      manualInstructionArray.forEach(item => {
        if (Array.isArray(item)) {
          item.forEach(subitem => {
            let mi: JMOBJ.ManualInstruction = JM.JMConnector.getManualInstruction(subitem.manualInstructionId);
            if (mi) {
              array.push(mi.description[Session.selectedLanguage] + ((subitem.remarks) ? ' - ' + subitem.remarks : ''));
            }
          })
        }
        else {
          let mi: JMOBJ.ManualInstruction = JM.JMConnector.getManualInstruction(item.manualInstructionId);
          if (mi) {
            array.push(mi.description[Session.selectedLanguage] + ((item.remarks) ? ' - ' + item.remarks : ''));
          }
        }
      });
    }

  }


}
