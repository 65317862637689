<div class="header">
    <h3>{{ 'component.cancel-pm-job-form.title' | translate }}</h3>
    <div class="submit-wrap d-inline">
        <app-submit-button 
          [text]="'global.submit'"
          [buttonClass]="'standard brand-amber'"
          [iconClass]="'fa-check'"
          [isDisabled]="submitDisabled || isLoading"
          [isLoading]="isLoading"
          (onClick)="onClickSubmit()" 
        ></app-submit-button>
    </div>
</div>
<p class="mandatory"><span>*</span>{{ 'component.cancel-pm-job-form.mandatory' | translate }}</p>

<div class="reason-drop-down">
    <ng-select
        placeholder="{{ 'component.cancel-pm-job-form.reason' | translate }}"
        bindLabel="description"
        bindValue="value"
        [items]="reasonItems"
        [(ngModel)]="formFields.reasonCode"
        [closeOnSelect]="true"
        [disabled]="isLoading"
        (change)="validateForm()"
        >
    </ng-select>
</div>
<div>
    <textarea
        maxlength="{{ getMaxRemarksLength }}"
        type="text"
        rows="5"
        placeholder="{{ 'component.cancel-pm-job-form.remarks' | translate }}"
        [(ngModel)]="formFields.remarks"
        [disabled]="isLoading"
        (input)="validateForm()"
    ></textarea>
    <span 
        *ngIf="formFields?.remarks?.length >= getMaxRemarksLength" 
        class="red small"
    >
        {{ "textarea.warning.max-length" | translate:[getMaxRemarksLength]}}
    </span>
</div>
