<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="row mx-0">
    <div class="col-12 col-lg-6 top">
      <div class="mb-2">
        <div class="title mb-2">{{ "component.eForm.eForm-title" | translate }}</div>
        <hr class="underline" />
        <app-tablex id="sn-eForm-table" [tablexParam]='tablexParam' class='mt-3'></app-tablex>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="title mb-2">{{ "component.eForm.preview-title" | translate }}</div>
      <hr class="underline" />
      <attachment-previewer  #eForm_preview id="eForm-preview" class="d-block mt-3" [file]="file" [fileDetails]="fileDetails" [isLoading]="isPreviewerLoading"
      [sortOrder]="valueAscOrder"></attachment-previewer>
    </div>

  </div>
</div>
