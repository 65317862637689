import { isEmailAdress, isNumber } from 'src/app/presenter/validators/validator';
import { Component, Input, OnInit } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';

import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'add-contractor-team-member-form',
  templateUrl: './add-contractor-team-member-form.html',
  styleUrls: ['./add-contractor-team-member-form.scss']
})

export class AddContractorTeamMemberForm implements OnInit {
  @Input() parameters:{
    onSubmitButtonClicked: Function,

  } = {
    onSubmitButtonClicked: undefined,
  };
  
  uiTitle:string = '';
  uiMemberName:string = '';
  uiPhone:string = '';
  uiSMS:string = '';
  uiFax:string = '';
  uiEmail:string = '';
  uiErrorName:string = '';
  uiErrorPhone:string = '';
  uiErrorFax:string = '';
  uiErrorEmail:string = '';
  uiErrorSMS:string = '';
  uiEnableSubmitButton: boolean = true;

  team: JMOBJ.Team;
   

  constructor(){
  }

  ngOnInit() {
  }

  initView(team: JMOBJ.Team,) {
    this.team = team;
    this.uiTitle = JMLanguage.translate('add-contractor-team-member-form.title', [this.team.name]);
    this.uiMemberName = '';
    this.uiPhone = '';
    this.uiSMS = '';
    this.uiFax = '';
    this.uiEmail = '';
    this.uiErrorName = '';
    this.uiErrorPhone = '';
    this.uiErrorFax = '';
    this.uiErrorEmail = '';
    this.uiErrorSMS = '';
    this.uiEnableSubmitButton = true;
  }


  //-----------------------------------------------------------------------
  // button callback function
  onSubmitButtonClicked(event):void {
    let hasError = false;
    this.uiErrorName = '';
    this.uiErrorPhone = '';
    this.uiErrorFax = '';
    this.uiErrorSMS = '';
    this.uiErrorEmail = '';
    // validate
    if (!this.uiMemberName || this.uiMemberName.trim().length <= 0) {
      this.uiErrorName = JMLanguage.translate("add-contractor-team-member-form.error.empty-member-name");
      hasError = true;
    }
    if (this.uiFax && this.uiFax.length > 0 && !isNumber(this.uiFax.trim())) {
      this.uiErrorFax = JMLanguage.translate("global.invalid-fax");
      hasError = true;
    }
    if (this.uiSMS && this.uiSMS.length > 0 && !isNumber(this.uiSMS.trim())) {
      this.uiErrorSMS = JMLanguage.translate("global.invalid-sms");
      hasError = true;
    }
    if (this.uiPhone && this.uiPhone.length > 0 && !isNumber(this.uiPhone.trim())) {
      this.uiErrorPhone = JMLanguage.translate("global.invalid-phone");
      hasError = true;
    }
    if (this.uiEmail && this.uiEmail.length > 0 && !isEmailAdress(this.uiEmail.trim())) {
      this.uiErrorEmail = JMLanguage.translate("global.invalid-email");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    this.uiEnableSubmitButton = false;

    let request:JM.JMRequestTeamsAddContractorMember = new JM.JMRequestTeamsAddContractorMember();
    request.teamId = this.team._id.toString();
    request.name = this.uiMemberName.trim();
    request.phone = this.uiPhone.trim();
    request.mobile = this.uiSMS.trim();
    request.fax = this.uiFax.trim();
    request.email = this.uiEmail.trim();
    JM.JMConnector.sendTeamsAddContractorMember(request, (error:JM.JMNetworkError, result:JM.JMResponseTeamsAddContractorMember) => {
      this.uiEnableSubmitButton = true;
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }
      if (!result || !result.code || result.code != 200 || !result.payload) {
        AppDelegate.openErrorBar(result);
        return;
      }
      AppDelegate.openSnackBar(JMLanguage.translate("global.added"));
        if (this.parameters.onSubmitButtonClicked) {
          this.parameters.onSubmitButtonClicked(); 
        }
    });

    // // create a new contact person
    // let request:JM.JMRequestContactPersonsUpdateContactPerson = new JM.JMRequestContactPersonsUpdateContactPerson();
    // request.name = this.uiMemberName.trim();
    // request.phone = this.uiPhone.trim();
    // request.mobile = this.uiSMS.trim();
    // request.fax = this.uiFax.trim();
    // request.email = this.uiEmail.trim();
    
    // JM.JMConnector.sendContactPersonsUpdateContactPerson(request, (error:JM.JMNetworkError, result:JM.JMResponseContactPersonsUpdateContactPerson) => {
    //   if (error) {
    //     AppDelegate.handleJMError(error);
    //     this.uiEnableSubmitButton = true;
    //     return;
    //   }
    //   if (!result || !result.code || result.code != 200 || !result.payload) {
    //     AppDelegate.openErrorBar(result);
    //     this.uiEnableSubmitButton = true;
    //     return;
    //   }
      
    //   let cp = result.payload;
      
    //   // then add it to the team
    //   let updateTeamRequest:JM.JMRequestTeamsUpdateTeam = new JM.JMRequestTeamsUpdateTeam()
    //   updateTeamRequest._id = this.team._id.toString();
    //   updateTeamRequest.members = this.team.members;
    //   updateTeamRequest.members.push(cp.post);
      
    //   JM.JMConnector.sendTeamsUpdateTeam(updateTeamRequest, (error:JM.JMNetworkError, result:JM.JMResponseTeamsUpdateTeam) => {
    //     this.uiEnableSubmitButton = true;
  
    //     if (error) {
    //       AppDelegate.handleJMError(error);
    //       return;
    //     }
    //     if (!result || !result.code || result.code != 200 || !result.payload) {
    //       AppDelegate.openErrorBar(result);
    //       return;
    //     }
    //     AppDelegate.openSnackBar(JMLanguage.translate("global.added"));
    //     if (this.parameters.onSubmitButtonClicked) {
    //       this.parameters.onSubmitButtonClicked(); 
    //     }
    //   });
    // });
  }
  
}