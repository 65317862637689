import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { AuthorizationService } from '@services/authorization.service';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { TablexComponentParams } from 'src/app/ui/components/tablex/tablex.component'; 
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { PmJobEditComponentRouterStateI } from 'src/app/ui/pages/pm-job-edit/pm-job-edit.component';


@Component({
  selector: 'app-pm-outstanding-equipment-list',
  templateUrl: './pm-outstanding-equipment-list.component.html',
  styleUrls: ['./pm-outstanding-equipment-list.component.scss']
})
export class PmOutstandingEquipmentListComponent implements OnInit {
  // model
  equipmentrRecordList: Array<any> = [];
  equipmentrRecordListSelected: Array<any> = [];
  selectedInfo: PmJobEditComponentRouterStateI = null ;

  // tablex
  tablexParam: TablexComponentParams = undefined;
  allColHeaders: any = [];
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  totalRowCount = 0;
  sortBy = 'updatedAt';
  sortOrder = JMENUM.SortOrder.DESC;
  filter = {};

  constructor(private authorizationService: AuthorizationService, private router: Router) { }

  ngOnInit() {
    this.initTablex();

    this.requestEquipmentList(this.currentPage, this.currentPageSize);
  }

  async requestEquipmentList(pageNumber: number, pageSize: number) {
    const request = new JM.JMRequestPmPlanDashboardGetOutstandingPeriodEquipmentList();

    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      request.workCentreList = JM.JMConnector.getAllWorkCentreCode();
    } else {
      request.workCentreList = this.authorizationService.getWorkCenters();
    }

    request.sortBy = this.sortBy;
    request.sortOrder = this.sortOrder;
    request.pageNumber  = pageNumber;
    request.pageSize = pageSize;
    if (Object.keys(this.filter).length > 0) {
      request.filter = this.filter;
    }
    
    this.tablexParam.isLoadingTable = true;
    const response : JM.JMResponseGetPmPlanAssignedEquipmentList | JM.JMResponseGetPmPlanUnassignedEquipmentList = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response) {
      this.totalRowCount = response.payload.totalCount;
      this.equipmentrRecordList = response.payload.records;
      this.pageCount = Math.ceil(this.totalRowCount / pageSize);
    } else {
      this.totalRowCount = 0;
      this.equipmentrRecordList = [];
      this.pageCount = 1;
    }

    this.renderTable(this.equipmentrRecordList, pageNumber, this.pageCount, this.totalRowCount);
  }
  
  // tablex function
  initTablex() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: 'pages.pm-plan-equipment-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none',
      },
      {
        id: 'equipment.equipmentNumber',
        name: "pages.pm-plan-equipment-list.table-column.equipment-id",
        enableFilter: true,
        type: TablexColumnType.Text,
        enableSort: true,
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'description',
        name: "pages.pm-plan-equipment-list.table-column.description",
        // enableFilter: true,
        type: TablexColumnType.Text,
        enableSort: false,
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'equipment.location',
        name: "pages.pm-plan-equipment-list.table-column.location-code", 
        enableFilter: true,
        type: TablexColumnType.Text,
        enableSort: true,
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'equipment.locationDescription',
        name: "pages.pm-plan-equipment-list.table-column.location-description", 
        type: TablexColumnType.Text, 
        enableSort: false,
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'equipment.clientShortName',
        name: "pages.pm-plan-equipment-list.table-column.client", 
        // enableFilter: true,
        enableSort: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col"
      },
      {
        id: 'equipment.equipmentType',
        name: "pages.pm-plan-equipment-list.table-column.equipment-type",    
        // enableFilter: true,
        enableSort: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'pmPlanNumber',
        name: 'pages.pm-plan-list.table-column.pm-plan-no',
        // enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'period.periodEndDate',
        name: 'pages.pm-plan-list.table-column.end-date',
        // enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
    ];

    this.tablexParam = {
      isLoadingTable: false,
      minifyButton: true,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      
      enablePagination: true,
      enableClearFilter: true,
      enableSetPageSize: true,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSort: true,

      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      tableRow: "row",
      tableClass: "outstanding-equip-table",
      tableWrapperClass: "table-min-width",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      totalRowCount: this.totalRowCount,

      onPageNumberClicked: this.onPageNumberClicked.bind(this),
      onPageSizeClicked: this.onPageSizeClicked.bind(this),
      onRowClicked: this.onApprovalRowClicked.bind(this),
      onSortOrderChanged: this.onSortOrderChanged.bind(this),
      onFilterChanged: this.onFilterChanged.bind(this),
      onFilterClear: this.onFilterClear.bind(this),
      enableStickyHeader: false,

      headers: this.allColHeaders,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  renderTable(records,
    currentPage: number, pageCount: number, totalRowCount: number) {
    this.tablexParam.content = records.map((record) => {
      const equip = record.equipment;
      const { equipmentNumber = '', description = '', location = '', clientShortName = '', equipmentType = '' } = equip;
      const locationDescription = equip.locationDescription ? equip.locationDescription[JMLanguage.getCurrentLanguage()] : "";
      const { pmPlanNumber = '' } = record.pmPlan;
      const { periodEndDate } = record.period;
      const pmPeriodEndDateStr = periodEndDate ? moment(periodEndDate).format(Constants.DATE_FORMAT) : '';
      const isExpired = periodEndDate ? moment().startOf('day').isAfter(moment(periodEndDate)) : false;

      return [
        record._id,
        equipmentNumber,
        description,
        location,
        locationDescription,
        clientShortName,
        equipmentType,
        {
          url: `/pm/standard-plan/view/${pmPlanNumber}`,
          value: pmPlanNumber,
        },
        `<span class="${isExpired?'red':''}">${pmPeriodEndDateStr}</span>`,
      ];
    });

    this.tablexParam.currentPage = currentPage;
    this.tablexParam.pageCount = pageCount;
    this.tablexParam.totalRowCount = totalRowCount;
  }


  // UI handler
  public onPageNumberClicked(pageIndex: number) {
    this.currentPage = pageIndex;

    this.requestEquipmentList(this.currentPage, this.currentPageSize);
  }

  public onPageSizeClicked(pageSize: number) {
    this.currentPage = 1;
    this.currentPageSize = pageSize;

    this.requestEquipmentList(this.currentPage, this.currentPageSize);
  }

  public onFilterChanged(event, index, header, filter) {
    for (const key in filter) {
      if (filter[key]) {
        this.filter[key] = filter[key];
      } else {
        delete this.filter[key];
      }
    }
    this.requestEquipmentList(this.currentPage, this.currentPageSize);
  }

  public onFilterClear() {
    this.tablexParam.filter = {};
    this.filter = {};
    this.requestEquipmentList(this.currentPage, this.currentPageSize);
  }

  public onSortOrderChanged(headerId, sortOrder) {
    headerId && (this.sortBy = headerId);
    sortOrder && (this.sortOrder = sortOrder);

    this.tablexParam.sortBy = this.sortBy;
    this.tablexParam.sortOrder = this.sortOrder;
    this.requestEquipmentList(this.currentPage, this.currentPageSize);
  }

  public onApprovalRowClicked(index, row) {
    const objId = row[0];
    const foundRecord = this.equipmentrRecordList.find(record => record._id === objId);

    if (foundRecord) {
      const isIncludedSelectedList = this.equipmentrRecordListSelected.includes(foundRecord);

      if (isIncludedSelectedList) {
        this.tablexParam.highlightedRows[index] = false;
        this.equipmentrRecordListSelected = [];

        this.selectedInfo = null;
      } else {
        this.tablexParam.highlightedRows = [];
        this.tablexParam.highlightedRows[index] = true;
        this.equipmentrRecordListSelected = [foundRecord];

        this.selectedInfo = {
          periodId : foundRecord.period._id,
          pmPlanId : foundRecord.pmPlan.pmPlanNumber,
          pmPlanStatus : foundRecord.pmPlan.status,
        };
      }
      // this.tablexParam.selectedRowCount = this.equipmentrRecordListSelected.length;
    }
  }

  public onClickCreatePMTask() {
    this.router.navigateByUrl( '/pm/job/create' , { state : this.selectedInfo });
  }
}
