import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JobCardService } from '@services/job-card.service';
import * as moment from 'moment';
import { ngbDateToString, pad, toNgbDate, stringToNgbDate } from 'src/lib/presenter/Formatter';

enum mandatoryState {
  notMandate,
  onCompleteMandate,
  alwayMandate,
}

@Component({
  selector: 'app-jobcard-task-progress',
  templateUrl: './jobcard-task-progress.component.html',
  styleUrls: ['./jobcard-task-progress.component.scss']
})
export class JobcardTaskProgressComponent implements OnInit, OnChanges {
  @Input() jobCard: JMOBJ.JobCard = new JMOBJ.JobCard;
  @Input() componentParameters;
  @Input() pageMode: JMENUM.JMPageMode;
  // @Input() contractSeverity?: JMOBJ.ContractSeverity;
  
  jobNatureEnum = JMENUM.JobNature; 
  
  // input
  responseToClientTimeInput: any = {
    isMandatory: false,
  };
  validationResult = {
    isPassResponseClient: CmTaskComplianceStatus.null,
    isPassResponse: CmTaskComplianceStatus.null,
    isPassRectification: CmTaskComplianceStatus.null,
  };
  complianceStatus = CmTaskComplianceStatus;
  validationCheckList = [
    "responseToClientTime",
    "receivedTime",
    "appointmentTime",
    "startTime",
    "completionTime",
  ];
  
  //For HTML
  JMPageMode = JMENUM.JMPageMode;

  // Validation
  valid: boolean;
  errorFields: any = {};
  timeAlertMsg: string = undefined;
  dateAlertMsg: string = undefined;
  mandatory: any = {};
  editable: any = {};
  arrivalGpsParam: any = undefined;
  EnumMandatory = mandatoryState;

  dateInputSet = {};
  timeInputSet = {};
  jobCardFieldName = {};


  emptyHandleNa = JMLanguage.translate('component.display-text-field.na');

  constructor(private jobCardService: JobCardService) {}

  ngOnInit() {
    this.timeAlertMsg = JMLanguage.translate("global.invalid-time");
    this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
    this.initDateTimeSet();
    this.resetAllErrorFields();
    this.valid = false;
    this.fieldControl();
    this.initArrivalGpsParam();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // No contractSeverity lifecycle changes in JM
    // if(this.isPageModeCreate) {
    //   if(changes.contractSeverity && !changes.contractSeverity.isFirstChange()) {
    //     this.recalComplianceStatus();
    //   }
    // } else if(changes.contractSeverity && changes.contractSeverity.previousValue && changes.contractSeverity.currentValue) {
    //   this.recalComplianceStatus();
    // }
  }

  initDateTimeSet(){
    this.dateInputSet = {
      responseToClientTime: undefined,
      receivedTime: undefined,
      appointmentTime: undefined,
      startTime: undefined,
      plannedCompletionTime: undefined,
      completionTime: undefined,
      malfunctionStartTime: undefined,
      malfunctionEndTime: undefined,
    };
    this.timeInputSet = {
      responseToClientTime: undefined,
      receivedTime: undefined,
      appointmentTime: undefined,
      startTime: undefined,
      plannedCompletionTime: undefined,
      completionTime: undefined,
      malfunctionStartTime: undefined,
      malfunctionEndTime: undefined,
    };
  }

  private fieldControl() {
    let editableFields = {};
    //Only these status can edit: Assigned, Rejected, InProgress, Cancelling, defined on CONST JobCardActionButtions//
      if (this.jobCard.jobNature == this.jobNatureEnum.ENQUIRY) {
        editableFields = {
          responseToClientTime: false,
          receivedTime: false,
          appointmentTime: false,
          startTime: false,
          plannedCompletionTime: false,
          completionTime: false,
          malfunctionStartTime: false,
          malfunctionEndTime: false,
        }
      } else if (this.jobCard.jobNature == this.jobNatureEnum.STANDALONE) {
        editableFields = {
          responseToClientTime: false,
          receivedTime: false,
          appointmentTime: false,
          startTime: true,
          plannedCompletionTime: false,
          completionTime: true,
          malfunctionStartTime: false,
          malfunctionEndTime: false,
        }
      } else {
        switch (this.jobCard.status){
          case JMENUM.JobCardStatus.ASSIGNED:
          case JMENUM.JobCardStatus.REJECTED:
            editableFields = {
              responseToClientTime: false,
              receivedTime: false,
              appointmentTime: false,
              startTime: false,
              plannedCompletionTime: false,
              completionTime: false,
              malfunctionStartTime: false,
              malfunctionEndTime: false,
            }
            break;
          default:
            editableFields = {
              responseToClientTime: true,
              receivedTime: true,
              appointmentTime: true,
              startTime: true,
              plannedCompletionTime: true,
              completionTime: true,
              malfunctionStartTime: (this.jobCard.jobNature == this.jobNatureEnum.CM),
              malfunctionEndTime: (this.jobCard.jobNature == this.jobNatureEnum.CM),
            }
            break;
        }
      }

    if (this.jobCard.jobNature == this.jobNatureEnum.ENQUIRY) {
      if(!this.isPageModeView) {
        this.editable = { ...editableFields };
      }
      this.mandatory = {
        responseToClientTime: mandatoryState.notMandate,
        receivedTime: mandatoryState.notMandate,
        appointmentTime: mandatoryState.notMandate,
        startTime: mandatoryState.notMandate,
        plannedCompletionTime: mandatoryState.notMandate,
        completionTime: mandatoryState.notMandate,
        malfunctionStartTime: mandatoryState.notMandate,
        malfunctionEndTime: mandatoryState.notMandate,
      };
    } else if (this.jobCard.jobNature == this.jobNatureEnum.STANDALONE) {
      if(!this.isPageModeView) {
        this.editable = { ...editableFields };
      }
      this.mandatory = {
        responseToClientTime: mandatoryState.notMandate,
        receivedTime: mandatoryState.notMandate,
        appointmentTime: mandatoryState.notMandate,
        startTime: mandatoryState.alwayMandate,
        plannedCompletionTime: mandatoryState.notMandate,
        completionTime: mandatoryState.alwayMandate,
        malfunctionStartTime: mandatoryState.notMandate,
        malfunctionEndTime: mandatoryState.notMandate,
      };
      this.setValueFromJobCard();
    } else if (this.isPageModeView) {
      // View Mode
      this.mandatory = {
        responseToClientTime: mandatoryState.onCompleteMandate,
        receivedTime: mandatoryState.onCompleteMandate,
        appointmentTime: mandatoryState.onCompleteMandate,
        startTime: mandatoryState.onCompleteMandate,
        plannedCompletionTime: mandatoryState.notMandate,
        completionTime: mandatoryState.onCompleteMandate,
        malfunctionStartTime: this.jobCard['breakdown'] ? mandatoryState.onCompleteMandate : mandatoryState.notMandate,
        malfunctionEndTime: this.jobCard['breakdown'] ? mandatoryState.onCompleteMandate : mandatoryState.notMandate,
      };
    } else {
      // Edit Mode
      this.editable = { ...editableFields };
      
      if (this.isPageModeCreate) {
        this.mandatory = {
          responseToClientTime: mandatoryState.onCompleteMandate,
          receivedTime: mandatoryState.onCompleteMandate,
          appointmentTime: mandatoryState.onCompleteMandate,
          startTime: mandatoryState.onCompleteMandate,
          plannedCompletionTime: mandatoryState.notMandate,
          completionTime: mandatoryState.onCompleteMandate,
          malfunctionStartTime: this.jobCard['breakdown'] ? mandatoryState.onCompleteMandate : mandatoryState.notMandate,
          malfunctionEndTime: this.jobCard['breakdown'] ? mandatoryState.onCompleteMandate : mandatoryState.notMandate,
        }; // first 2 remove mandatory as no default value when no sn created
      } else {
        this.mandatory = {
          responseToClientTime: mandatoryState.onCompleteMandate,
          receivedTime: mandatoryState.onCompleteMandate,
          appointmentTime: mandatoryState.onCompleteMandate,
          startTime: mandatoryState.onCompleteMandate,
          plannedCompletionTime: mandatoryState.notMandate,
          completionTime: mandatoryState.onCompleteMandate,
          malfunctionStartTime: this.jobCard['breakdown'] ? mandatoryState.onCompleteMandate : mandatoryState.notMandate,
          malfunctionEndTime: this.jobCard['breakdown'] ? mandatoryState.onCompleteMandate : mandatoryState.notMandate,
        };
        this.setValueFromJobCard();
      }
    }
    this.jobCard.complianceStatus && this.updateComplianceStatus(this.jobCard.complianceStatus);
  }

  onChangeBreakdown(event) {
    if (event.target.checked) {
      this.mandatory.malfunctionStartTime = mandatoryState.onCompleteMandate;
      this.mandatory.malfunctionEndTime = mandatoryState.onCompleteMandate;
    } else {
      this.mandatory.malfunctionStartTime = mandatoryState.notMandate;
      this.mandatory.malfunctionEndTime = mandatoryState.notMandate;
    }
  }

  initArrivalGpsParam() {
    if (this.jobCard.arrival == undefined) return
    this.arrivalGpsParam = {
      startTime : this.jobCard.startTime,
      completionTime : this.jobCard.completionTime,
      gpsLocations: this.jobCard.arrival,
    }
  }

  setValueFromJobCard() {
    let responseToClientTime: any = this.jobCard.responseToClientTime ? this.jobCard.responseToClientTime : undefined;
    if (responseToClientTime) {
      let time = moment(responseToClientTime);
      this.dateInputSet['responseToClientTime'] = toNgbDate(responseToClientTime);
      this.timeInputSet['responseToClientTime'] = pad(time.hour()) + ':' + pad(time.minute());
    }

    let receivedTime: any = this.jobCard.receivedTime ? this.jobCard.receivedTime : undefined;
    if (receivedTime) {
      let time = moment(receivedTime);
      this.dateInputSet['receivedTime'] = toNgbDate(receivedTime);
      this.timeInputSet['receivedTime'] = pad(time.hour()) + ':' + pad(time.minute());
    }

    let appointmentTime: any = this.jobCard.appointmentTime ? this.jobCard.appointmentTime : undefined;
    if(appointmentTime){
      let time = moment(appointmentTime);
      this.dateInputSet['appointmentTime'] = toNgbDate(appointmentTime);
      this.timeInputSet['appointmentTime'] = pad(time.hour()) + ':' + pad(time.minute());
    }
    
    let startTime: any = this.jobCard.startTime ? this.jobCard.startTime : undefined;
    if(startTime){
      let time = moment(startTime);
      this.dateInputSet['startTime'] = toNgbDate(startTime);
      this.timeInputSet['startTime'] = pad(time.hour()) + ':' + pad(time.minute());
    }

    let plannedCompletionTime: any = this.jobCard.plannedCompletionTime ? this.jobCard.plannedCompletionTime : undefined;
    if (plannedCompletionTime) {
      let time = moment(plannedCompletionTime);
      this.dateInputSet['plannedCompletionTime'] = toNgbDate(plannedCompletionTime);
      this.timeInputSet['plannedCompletionTime'] = pad(time.hour()) + ':' + pad(time.minute());
    }

    let completionTime: any = this.jobCard.completionTime ? this.jobCard.completionTime : undefined;
    if (completionTime) {
      let time = moment(completionTime);
      this.dateInputSet['completionTime'] = toNgbDate(completionTime);
      this.timeInputSet['completionTime'] = pad(time.hour()) + ':' + pad(time.minute());
    }

    let malfunctionStartTime: any = this.jobCard.malfunctionStartTime ? this.jobCard.malfunctionStartTime : undefined;
    if(malfunctionStartTime){
      let time = moment(malfunctionStartTime);
      this.dateInputSet['malfunctionStartTime'] = toNgbDate(malfunctionStartTime);
      this.timeInputSet['malfunctionStartTime'] = pad(time.hour()) + ':' + pad(time.minute());
    }

    let malfunctionEndTime: any = this.jobCard.malfunctionEndTime ? this.jobCard.malfunctionEndTime : undefined;
    if(malfunctionEndTime){
      let time = moment(malfunctionEndTime);
      this.dateInputSet['malfunctionEndTime'] = toNgbDate(malfunctionEndTime);
      this.timeInputSet['malfunctionEndTime'] = pad(time.hour()) + ':' + pad(time.minute());
    }
  }

  public onBlurDateInput(event){
    this.dateInputSet[event.field] = event.data;
    this.setDateTime(event.field);
  }

  public onBlurTimeInput(event){
    this.timeInputSet[event.field] = event.data;
    this.setDateTime(event.field);
  }

  public setDateTime(field){
    this.resetErrorField(field);
    if(this.dateInputSet[field] && this.timeInputSet[field]){
      let date = this.dateInputSet[field];
      let timeArr =  this.timeInputSet[field].split(':');
      let formattedDate = new Date(date.year, date.month - 1, date.day);
      if(timeArr && timeArr.length > 0){
        formattedDate.setHours(timeArr[0]);
        formattedDate.setMinutes(timeArr[1]);
        this.jobCard[field] = new Date(formattedDate);
      }
    } else {
      this.jobCard[field] = undefined;
    }

    if (this.validationCheckList.includes(field)) {
      this.recalComplianceStatus();
    }

    if (this.jobCard.startTime && this.isLateThanCurrent('startTime')) {
      return;
    }
    if (this.jobCard.completionTime && this.isLateThanCurrent('completionTime')) {
      return;
    }
    if (this.jobCard.malfunctionStartTime && this.isLateThanCurrent('malfunctionStartTime')) {
      return;
    }
    if (this.jobCard.malfunctionEndTime && this.isLateThanCurrent('malfunctionEndTime')) {
      return;
    }

    if (this.jobCard.startTime && this.jobCard.completionTime && !this.validateJobDuration()) {
      return;
    }

    if ((this.jobCard.malfunctionStartTime || this.jobCard.malfunctionEndTime) && !this.validateMalfunctionTime()){
      return;
    }

    if (this.jobCard.responseToClientTime && !this.validResponseToClientTime()) {
      return;
    }

    if (this.jobCard.receivedTime && !this.validReceivedTime()) {
      return;
    }
  }

  //======= Validation =======//
  public isLateThanCurrent(field) {
    let currentTime = Date.now();
    let time = new Date(this.jobCard[field]);

    let msgDict = {
      startTime: "pages.sn.alert-job-start-time-than-current",
      completionTime: "pages.sn.alert-job-complete-time-than-current",
      malfunctionStartTime: "pages.sn.alert-malfunction-start-than-current",
      malfunctionEndTime: "pages.sn.alert-malfunction-end-than-current",
    };
    let errorMsg = msgDict[field] ? msgDict[field] : "pages.sn.alert-job-complete-time-than-current";

    // jobCard[field]time cannot later than current time
    if (time.valueOf() >= currentTime.valueOf()) {
      this.errorFields[field] = true;
      AppDelegate.openSnackBar(JMLanguage.translate(errorMsg));
      return true;
    } else {
      return false;
    }
  }

  public dateTimeValidation(field) {
    //Check if have date & time
    if( (this.dateInputSet[field] && !this.timeInputSet[field]) 
      || (this.timeInputSet[field] && !this.dateInputSet[field])
    ){
        this.errorFields[field] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.date-format-not-completed"));
        return false;
    }
    return true;
  }

  public validation = (cmBreakdownDate?) => {
    if(this.jobCard.jobNature != this.jobNatureEnum.ENQUIRY 
      && (this.jobCard.status != JMENUM.JobCardStatus.ASSIGNED && this.jobCard.status != JMENUM.JobCardStatus.REJECTED)){

      if (this.jobCard.startTime && this.isLateThanCurrent('startTime')) {
        return false;
      }
      if (this.jobCard.completionTime && this.isLateThanCurrent('completionTime')) {
        return false;
      }
      if (this.jobCard.malfunctionStartTime && this.isLateThanCurrent('malfunctionStartTime')) {
        return false;
      }
      if (this.jobCard.malfunctionEndTime && this.isLateThanCurrent('malfunctionEndTime')) {
        return false;
      }

      if (this.jobCard.startTime && this.jobCard.completionTime && !this.validateJobDuration()) {
        return false;
      }

      if ((this.jobCard.malfunctionStartTime || this.jobCard.malfunctionEndTime) && !this.validateMalfunctionTime()) {
        return false;
      }

      if (this.jobCard.responseToClientTime && !this.validResponseToClientTime(cmBreakdownDate)) {
        return false;
      }
  
      if (this.jobCard.receivedTime && !this.validReceivedTime()) {
        return false;
      }
    }

    let hasErrorField =  false;
    let fields = {};
    if (!this.isPageModeView) {
      fields = {... this.editable};
    } else {
      // View Mode
      fields = {... this.mandatory};
    }

    // Check fields need to mandatory check
    Object.keys(this.mandatory).filter(key => {
      if (this.mandatory[key] == mandatoryState.alwayMandate) return true;
      if ((this.mandatory[key] == mandatoryState.onCompleteMandate) && this.isPageModeView) return true;
      return false;
    }).forEach(key => {
      if (this.jobCard[key]) {
        this.errorFields[key] = false;
      } else {
        this.errorFields[key] = true;
        hasErrorField = true;
      }
    });

    // validate inputted value
    for(let field in this.editable){
      let dateFormatErr = this.dateTimeValidation(field);
      if((this.editable[field] && this.errorFields[field]) || !dateFormatErr){
        hasErrorField = true;
      }
    }

    if(hasErrorField){
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.error.msg.mandatory-field"));
      return !hasErrorField;
    }

    this.valid = !hasErrorField;
    return this.valid;
  }

  public validateMalfunctionTime(){
    // reset
    this.errorFields['malfunctionStartTime'] = false;
    this.errorFields['malfunctionEndTime'] = false;
    this.errorFields['completionTime'] = false;
    this.errorFields['startTime'] = false;

    // malfunctionStartTime < malfunctionEndTime
    if(this.jobCard['malfunctionStartTime'] && this.jobCard['malfunctionEndTime']){
      let malfunctionStartTime = new Date(this.jobCard['malfunctionStartTime']);
      let malfunctionEndTime = new Date(this.jobCard['malfunctionEndTime']);
      let differTime = malfunctionEndTime.valueOf() - malfunctionStartTime.valueOf();

      if(differTime <= 0){
        this.errorFields['malfunctionStartTime'] = true;
        this.errorFields['malfunctionEndTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.alert-manlfunction-end-than-start"));
        return false;
      }
    }

    // malfunctionStartTime < completionTime
    if(this.jobCard['malfunctionStartTime'] && this.jobCard['completionTime']){
      let malfunctionStartTime = new Date(this.jobCard['malfunctionStartTime']);
      let completionTime = new Date(this.jobCard['completionTime']);
      let differTime = completionTime.valueOf() - malfunctionStartTime.valueOf();

      if(differTime <= 0){
        this.errorFields['malfunctionStartTime'] = true;
        this.errorFields['completionTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.alert-manlfunction-start-than-completion"));
        return false;
      }
    }

    // startTime < malfunctionEndTime
    if(this.jobCard['malfunctionEndTime'] && this.jobCard['startTime']){
      let startTime = new Date(this.jobCard['startTime']);
      let malfunctionEndTime = new Date(this.jobCard['malfunctionEndTime']);
      let differTime = malfunctionEndTime.valueOf() - startTime.valueOf();

      if(differTime <= 0){
        this.errorFields['malfunctionEndTime'] = true;
        this.errorFields['startTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.alert-manlfunction-end-than-job-start"));
        return false;
      }
    }

    // malfunctionEndTime <= completionTime
    if(this.jobCard['malfunctionEndTime'] && this.jobCard['completionTime']){
      let completionTime = new Date(this.jobCard['completionTime']);
      let malfunctionEndTime = new Date(this.jobCard['malfunctionEndTime']);
      let differTime = completionTime.valueOf() - malfunctionEndTime.valueOf();

      if(differTime < 0){
        this.errorFields['malfunctionEndTime'] = true;
        this.errorFields['completionTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.alert-manlfunction-end-than-completion"));
        return false;
      }
    }

    return true;
  }

  public validateJobDuration() {
    // reset
    this.errorFields['completionTime'] = false;
    this.errorFields['startTime'] = false;

    if(this.jobCard['startTime'] && this.jobCard['completionTime']){
      let completionTime = new Date(this.jobCard.completionTime);
      let startTime = new Date(this.jobCard.startTime);
      let differTime = completionTime.valueOf() - startTime.valueOf();

      // check if complete time > start time
      if (differTime < 0) {
        this.errorFields['completionTime'] = true;
        this.errorFields['startTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.alert-job-complete-time"));
        return false;
      }

      // check if job time < 15 mins
      if(differTime < (15 * 60 * 1000) /* 15 mins */){
        this.errorFields['completionTime'] = true;
        this.errorFields['startTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.alert-min-job-time"));
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  public validResponseToClientTime(cmBreakdownDate?) {
    // reset
    this.errorFields['responseToClientTime'] = false;
    this.errorFields['receivedTime'] = false;
    this.errorFields['malfunctionStartTime'] = false;
    this.errorFields['startTime'] = false;

    // 1. responseToClientTime >= receivedTime
    if (this.jobCard['responseToClientTime'] && this.jobCard['receivedTime']) {
      let responseToClientTime = new Date(this.jobCard['responseToClientTime']);
      let receivedTime = new Date(this.jobCard['receivedTime']);
      let differTime = responseToClientTime.valueOf() - receivedTime.valueOf();

      if(differTime < 0){
        this.errorFields['responseToClientTime'] = true;
        this.errorFields['receivedTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.response-client-time-earlier-than-received-time"));
        return false;
      }
    }

    // 3. startTime >= responseToClientTime
    if (this.jobCard['startTime'] && this.jobCard['responseToClientTime']) {
      let startTime = new Date(this.jobCard['startTime']);
      let responseToClientTime = new Date(this.jobCard['responseToClientTime']);
      let differTime = startTime.valueOf() - responseToClientTime.valueOf();

      if(differTime < 0){
        this.errorFields['startTime'] = true;
        this.errorFields['responseToClientTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.start-time-earlier-than-response-client-time"));
        return false;
      }
    }

    // 4. responseToClientTime >= cmBreakdown time
    if (this.jobCard['responseToClientTime'] && cmBreakdownDate) {
      let responseToClientTime = new Date(this.jobCard['responseToClientTime']);
      let cmBreakdownDateObj = new Date(cmBreakdownDate);
      let differTime = responseToClientTime.valueOf() - cmBreakdownDateObj.valueOf();

      if(differTime < 0){
        this.errorFields['responseToClientTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.response-client-time-earlier-than-cm-breakdown"));
        return false;
      }
    }

    // 5. receivedTime >= cmBreakdown time
    if (this.jobCard['receivedTime'] && cmBreakdownDate) {
      let receivedTime = new Date(this.jobCard['receivedTime']);
      let cmBreakdownDateObj = new Date(cmBreakdownDate);
      let differTime = receivedTime.valueOf() - cmBreakdownDateObj.valueOf();

      if(differTime < 0){
        this.errorFields['receivedTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.received-time-earlier-than-cm-breakdown"));
        return false;
      }
    }
    return true;
  }

  public validReceivedTime() {
    // reset
    this.errorFields['receivedTime'] = false;
    this.errorFields['malfunctionStartTime'] = false;
    this.errorFields['appointmentTime'] = false;

    // 2. receivedTime >= malfunctionStartTime
    if (this.jobCard['receivedTime'] && this.jobCard['malfunctionStartTime']) {
      let receivedTime = new Date(this.jobCard['receivedTime']);
      let malfunctionStartTime = new Date(this.jobCard['malfunctionStartTime']);
      let differTime = receivedTime.valueOf() - malfunctionStartTime.valueOf();

      if(differTime < 0){
        this.errorFields['receivedTime'] = true;
        this.errorFields['malfunctionStartTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.received-time-earlier-than-manlfunction-start"));
        return false;
      }
    }

    // equipmentReceivedDate >= receivedTime 
    if (this.jobCard['equipmentReceivedDate'] && this.jobCard['receivedTime']) {
      let equipmentReceivedDate = new Date(this.jobCard['equipmentReceivedDate']);
      let receivedTime = new Date(this.jobCard['receivedTime']);
      let differTime = equipmentReceivedDate.valueOf() - receivedTime.valueOf();

      if(differTime < 0){
        this.errorFields['receivedTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.equipment-received-date-cannot-be-earlier-than-received-time"));
        return false;
      }
    }

    // check Received Time should be <=  Appointed Time
    if (this.jobCard['appointmentTime'] && this.jobCard['receivedTime']) {
      let appointmentTime = new Date(this.jobCard['appointmentTime']);
      let receivedTime = new Date(this.jobCard['receivedTime']);
      let differTime = appointmentTime.valueOf() - receivedTime.valueOf();

      if(differTime < 0){
        this.errorFields['receivedTime'] = true;
        this.errorFields['appointmentTime'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.received-time-later-than-appointment-time"));
        return false;
      }
    }

    return true;
  }

  // compliance Status
  async requestComplianceStatus() {
    const request = new JM.JMRequestJobCardsGetComplianceStatus();
    request.contractSeverityId = this.jobCard.contractSeverity.id;
    request.appointmentTime = this.jobCard.appointmentTime;
    request.completionTime = this.jobCard.completionTime;
    request.receivedTime = this.jobCard.receivedTime;
    request.responseToClientTime = this.jobCard.responseToClientTime;
    request.startTime = this.jobCard.startTime;

    const response: JM.JMResponseJobCardsGetComplianceStatus = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    return response;
  }

  recalComplianceStatus() {
    if (this.needComplianceStatus) {
      this.requestComplianceStatus().then((response) => response && response.payload && this.updateComplianceStatus(response.payload));
    }
  }

  updateComplianceStatus(payload: {
      isResponseToClientTimePassed: Boolean,
      isResponseTimePassed: Boolean,
      isRectificationTimePassed: Boolean,
  }) {

    const validationResult = {
      isPassResponseClient: this.jobCard.contractSeverity &&  this.jobCard.contractSeverity.responseToClient ? translateComplianceStatus(payload.isResponseToClientTimePassed) : CmTaskComplianceStatus.na,
      isPassResponse: translateComplianceStatus(payload.isResponseTimePassed),
      isPassRectification: translateComplianceStatus(payload.isRectificationTimePassed),
    }

    this.validationResult = validationResult;
  }

  isCompliacneStatusFail() {
    return Object.values(this.validationResult).includes(CmTaskComplianceStatus.fail);
  }

  public resetAllErrorFields() {
    this.valid = true;
    this.errorFields = {
      responseToClientTime: false,
      receivedTime: false,
      appointmentTime: false,
      startTime: false,
      plannedCompletionTime: false,
      completionTime: false,
      malfunctionStartTime: false,
      malfunctionEndTime: false,
    }
  }

  public resetErrorField(key) {
    if (this.errorFields[key] != undefined) {
      this.errorFields[key] = false;
    }
  }

  public resetErrorFields(keys: string[]) {
    keys.forEach(key => this.resetErrorField(key));
  }

  resetForm = () => {
    this.dateInputSet['responseToClientTime'] = undefined;
    this.dateInputSet['receivedTime'] = undefined;
    this.dateInputSet['appointmentTime'] = undefined;
    this.dateInputSet['startTime'] = undefined;
    this.dateInputSet['plannedCompletionTime'] = undefined;
    this.dateInputSet['completionTime'] = undefined;
    this.dateInputSet['malfunctionStartTime'] = undefined;
    this.dateInputSet['malfunctionEndTime'] = undefined;

    this.timeInputSet['responseToClientTime'] = undefined;
    this.timeInputSet['receivedTime'] = undefined;
    this.timeInputSet['appointmentTime'] = undefined;
    this.timeInputSet['startTime'] = undefined;
    this.timeInputSet['plannedCompletionTime'] = undefined;
    this.timeInputSet['completionTime'] = undefined;
    this.timeInputSet['malfunctionStartTime'] = undefined;
    this.timeInputSet['malfunctionEndTime'] = undefined;
  }

  get isPageModeCreate() {
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }

  get isPageModeEdit() {
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }

  get isPageModeView() {
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }

  get isPmsmcJob() {
    return this.jobCard.handlingParty === JMENUM.HandlingParty.PMSMC;
  }

  get needComplianceStatus(): boolean {
    const isFirstAttendedJob = this.jobCardService.isFirstAttendedJob(this.jobCard);
    return this.jobCard.handlingParty == JMENUM.HandlingParty.PMSMC && (isFirstAttendedJob == undefined || isFirstAttendedJob == true);
  }
}

export enum CmTaskComplianceStatus {
  pass = 'pass',
  fail = 'fail',
  na = 'na',
  null = 'null',
};

export function translateComplianceStatus(status: Boolean) {
  return status ? CmTaskComplianceStatus.pass : status == false ? CmTaskComplianceStatus.fail : CmTaskComplianceStatus.null;
}
