import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Attachment } from 'src/app/entity/data-model/attachment';
import { saveAs } from "file-saver";
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMCONSTANT, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { AuthorizationService } from '@services/authorization.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { KeyValue } from '@angular/common';


@Component({
  selector: 'app-sn-eForm',
  templateUrl: './sn-eForm.component.html',
  styleUrls: ['./sn-eForm.component.scss']
})
export class SnEFormComponent extends BasePage implements OnInit {
  @ViewChild('eForm_preview', { static: false }) eFormPreview;
  protected authorizationService: AuthorizationService;
  protected route: ActivatedRoute;

  snNumber: string;
  sn: JMOBJ.ServiceNotification;

  file: any;
  fileList: any = {};
  fileDetails: any = {};

  attachment: Attachment;
  selectedAttachment: any;
  attachmentList: any = [];
  modifiedAttachmentList: any = [];

  navbarTitle: string;
  breadcrumbs: any = [];
  tablexParam: any = {};
  haTablexParam: any = {};

  isPreviewerLoading: boolean = false;
  hasViewPermission: boolean = false;
  hasNotificationEmailHostories: boolean = false;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.checkViewPermission(JMENUM.Permission.SN_VIEW);
    this.snNumber = this.route.snapshot.paramMap.get('snNumber');
    this.breadcrumbs = [
      { id: 'breadcrumbs-sn-number', name: this.snNumber, route: '/sn/view/' + this.snNumber },
      { id: 'breadcrumbs-eForm', name: "e-Form", route: null, currentPage: true }
    ];
    this.requestSn();
    this.initTable();

  }

  initPermission() {
    // check has user got SN_UPDATE & SN_UPDATE_ALL
    let hasSnUpdate = this.authorizationService.hasPermission(JMENUM.Permission.SN_UPDATE)
    let hasAdmin = this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)

    let hasTeamWorkCentre = (this.sn.team.workCentre && this.sn.team.workCentre &&
                            this.authorizationService.hasAuthorizationForWorkCenter(this.sn.team.workCentre))
    // check has user got the right Workcenter rights
    let hasWorkCentre = (this.sn.workCentre && // workCenter is not null
                         this.authorizationService.hasAuthorizationForWorkCenter(this.sn.workCentre)) 
    
    // check is user a team member 
    let isTeamMember = (this.sn.team &&
                        this.sn.team.workCentre &&
                        this.authorizationService.hasAuthorizationForWorkCenter(this.sn.team.workCentre.toString()))

    let isSubmitter = (this.sn.submittedBy &&
                        this.sn.submittedBy == Session.userInfo.name)
                        
                        
    let isAuthor = (this.sn.createdBy &&
                        this.sn.createdBy == Session.userInfo.name)

   
    // view only 
    if ((isTeamMember || isSubmitter || isAuthor) && (hasWorkCentre || hasTeamWorkCentre) && hasSnUpdate) {
      this.hasViewPermission = true;
    }
  }

  // ----------- API ----------- //
  private async requestSn() {
    this.tablexParam.isLoadingTable = true;
    this.haTablexParam.isLoadingTable = true;
    let request: JM.JMRequestSnGetSn = new JM.JMRequestSnGetSn();
    request.snNumber = this.snNumber;

    const response : JM.JMResponseSnGetSn = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;
    this.haTablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload || response.error) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload) {
      this.updateSn(response.payload);
      this.initPermission();
      this.renderTable();
    }

  }
  

  //---------- fileServer calls ---------- 

  //---------- Get files---------- 
  private async requestGetFile(attachmentId, download?, button?) {
    if (button) button.isLoading = true;
    this.isPreviewerLoading = download ? false : true;
    let request: JM.JMRequestFilesGetFile = new JM.JMRequestFilesGetFile(attachmentId);
    const response : JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request)

    if (!response || response.code !== 200 || !response.payload){
      AppDelegate.openErrorBar(response.error);
      button.isLoading = false;
      return;
    }
    if(download){
      saveAs(response.payload, attachmentId);
      button.isLoading = false;
    } else {
      this.setPreviewContent(response)
    }
  }

  // ----------- UI function ----------- //
  private setPreviewContent(response) {
    this.file = response.payload;
    this.fileDetails = {
      fileDescription: this.selectedAttachment.description,
      fileSize: this.eFormPreview.getFileSize(this.file.size),
      uploadedBy: this.selectedAttachment.uploadedBy,
      uploadDate: moment(this.selectedAttachment.uploadedAt).format(Constants.DATETIME_FORMAT)
    };
    this.isPreviewerLoading = false
    this.eFormPreview.selectedPage = 1
  }

  private clearPreviewContent(){
    this.file = undefined;
    this.fileDetails = undefined
    this.isPreviewerLoading = false
    this.eFormPreview.selectedPage = 1
  }
  
  // ----------- Button function ----------- //
  updateSn(sn) {
      this.sn = sn;
      if (this.sn.attachmentList) {
          this.attachmentList = this.sn.attachmentList;
          this.modifiedAttachmentList = this.sortAttachmentsByDate(
            this.filterAttachment(this.attachmentList, JMENUM.SnAttachmentType.E_FORM));
          this.selectedAttachment = {}

      if (this.modifiedAttachmentList.length)  {
          this.requestGetFile(this.modifiedAttachmentList[0].attachmentId);
          this.selectedAttachment = this.modifiedAttachmentList[0];
      }
    }
  }

  onDownloadButtonClicked = (button) => {
    this.requestGetFile(button.attachment.attachmentId, true, button)
  }

  // ----------- Tablex UI function ----------- //
  onRowClicked = (index: number, row: any) => {
    this.selectedAttachment = this.modifiedAttachmentList[index];
    this.requestGetFile(this.selectedAttachment.attachmentId);
    // reset Preview selected Page
  }

  renderTable() {
    //other attachment
    this.tablexParam.content = this.modifiedAttachmentList.map(attachment => {
      let buttons = [
        { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
      ];
      return [
        attachment._id,
        attachment.description,
        buttons
      ];
    });
  }

  private filterAttachment(attachmentList: any[], type: JMENUM.SnAttachmentType) {
    return attachmentList.filter(att=> att.attachmentType == type);
  }

  // ------ Tablex ------ //
  initTable() {
    this.tablexParam = {
      isLoadingTable: true,
      tableRow: "row",
      tableClass: "sn-eForm-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: false,
      enablePagination: false,
      onRowClicked: this.onRowClicked,
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'description',
          name: 'component.attachment.fileDescription',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 justify-content-around min-w-px-80"
        }
      ],
      content: []
    };

  }
  // ------ Tablex End ------ //
  valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  sortAttachmentsByDate = (toSortAttachment: any[], isDesc = true): any[] => {
    return toSortAttachment.sort((a: any, b: any): number => {
      const aTime = moment(a.uploadedAt);
      const bTime = moment(b.uploadedAt);
      if(aTime && bTime) {
        if (isDesc) {
          return aTime.isAfter(bTime) ? -1 : 1;
        } else {
          return aTime.isAfter(bTime) ? 1 : -1;
        }
      } else {
        return 0;
      }
    });
  }
}