import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { JM, JMENUM,JMOBJ } from '@ccep/CCEPConnector-ts';
import { Subject } from 'rxjs';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Session } from '@services/session';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppDelegate } from 'src/app/AppDelegate';
import { Constants } from 'src/constants';
import { DropdownControl, DropdownSearch, InputControl } from '../../../shared/form.model';
import { ContractService } from 'src/app/shared/contract.service';
import { PmPlanService } from 'src/app/shared/pm-plan.service';
import { ngbDateToString, stringToNgbDate } from 'src/lib/presenter/Formatter';

@Component({
  selector: 'app-pm-job-list-adv-search',
  templateUrl: './pm-job-list-adv-search.component.html',
  styleUrls: ['./pm-job-list-adv-search.component.scss']
})
export class PmJobListAdvSearchComponent implements OnInit {
  @Output() search: EventEmitter<any> = new EventEmitter();

  isCollapse: boolean = false;

  createTimeFromNgb: any;
  createTimeToNgb: any;
  showCreationTimeErrorMsg:boolean = false;

  searchSelections : {
    contractNumber?: string[];
    pmJobNumber?: string;
    pmPlanNumber?: string;
    pmPeriod?: string;
    equipmentNumber?: string;
    planStatus?: { [ key : string ] : boolean };
    startRange?: 'on-or-before' | 'after';
    ngbStartDate?: NgbDateStruct;
    endRange?: 'on-or-before' | 'after';
    ngbEndDate?: NgbDateStruct;
    jobDescription?: string;
    handlingTeam?: string[];
    createTimeFrom?: string;
    createTimeTo?: string;
    createdBy?: string;
  } = {};

  // unSubObservable
  private unSubNotifier = new Subject();

  teamOptions: {value: string, label: string }[] = [];
  teamOptionsBackUp: {value: string, label: string }[] = [];
  teamOptionsSearchDebouncer;
  teamOptionsIsSearchingKeyword: boolean = false;
  teamOptionsLastSearchKeyword: string;
  teamPageSize: number = 100;
  teamPageSizeKeyword: number = 100;

  teamPageNumber: number = 0;
  teamPageNumberKeyword: number = 0;

  teamTotalCount: number;
  teamTotalCountKeyword: number;

  isLoadingTeam: boolean = false;
  isSearching: boolean = false;
  dateAlertMsg: string = undefined;

  enableInputPmPlan = false;
  enableInputPmPeriod = false;

  // Input
  contractInput = new PmJobSearchContractInput();
  pmPlanInput = new PmJobSearchPmPlanInput();
  pmPeriodInput = new PmJobSearchPmPeriodInput();
  equipmentInput = new PmJobSearchEquipmentInput();
  private contractSearch = new DropdownSearch();
  private pmPlanSearch = new DropdownSearch();
  private pmPeriodSearch = new DropdownSearch();
  private equipmentSearch = new DropdownSearch();

  constructor(   
    private contractService: ContractService,
    private pmPlanService: PmPlanService
  ) { }

  async ngOnInit() {
    this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
    this.initDefaultFilters();
    this.loadFilterOptionsFromLocal();
    this.search.emit(this.searchSelections);
    await this.requestTeamList();
  }

  ngOnDestroy(): void {
    this.unSubNotifier.next();
    this.unSubNotifier.complete();
    Session.pmTaskListGeneralSearchCriteria = null
  }

  private initDefaultFilters(){
    this.searchSelections = {
      contractNumber: [],
      pmJobNumber: null,
      pmPeriod: null,
      pmPlanNumber: null,
      equipmentNumber: null,
      planStatus: {},
      startRange: null,
      ngbStartDate: null,
      endRange: null,
      ngbEndDate: null,
      jobDescription: null,
      createTimeFrom: null,
      createTimeTo: null,
      handlingTeam: [],

    };

    let statusEnum = Object.values(JMENUM.PMJobStatus);
    for (let i = 0; i < statusEnum.length; i++) {
      this.searchSelections.planStatus[statusEnum[i]] = false;
    }
    // contract list init
    this.contractSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetContractDropDown();
      this.searchContracts();
    });
    this.contractSearch.search$.next();
 
    this.pmPlanSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetPmPlanDropDown();
      this.searchSelections.contractNumber && this.searchPmPlans();
    });
    this.pmPeriodSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetPmPeriodDropDown();
      this.searchSelections.pmPlanNumber && this.searchPmPeriods();
    });

    this.equipmentSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetEquipmentDropDown();
      this.searchEquipments();
    });
    this.equipmentSearch.search$.next();
  }
  // Start: Contract ng select handler
  onContractSearch(event) {
    this.contractSearch.keywords = event.term;
    this.contractSearch.search$.next();
  }
  onContractChange(event) {
    this.onPmPlanClear();
    this.onPmPeriodClear();
  }

  onContractClear() {
    this.searchSelections.contractNumber = null;

    this.contractSearch.keywords = null;
    this.contractSearch.search$.next();
  }

  async onContractScrollToEnd() {
    if (this.contractSearch.totalPageNumber && this.contractSearch.pageNumber < this.contractSearch.totalPageNumber) {
      let filter;
      this.contractSearch.pageNumber++;
      this.contractSearch.keywords && (filter = { contractNumber: this.contractSearch.keywords });

      const payload = await this.contractService.requestContractList({
        pageNumber: this.contractSearch.pageNumber,
        pageSize: this.contractSearch.pageSize,
        parameters: ['contractNumber'],
        filter,
      });

      if(payload) {
        this.contractSearch.totalPageNumber = Math.ceil(payload.totalCount / this.contractSearch.pageSize);
        this.contractInput.optionList = this.contractInput.optionList.concat(payload.records);
      }
    }
  }

  resetContractDropDown() {
    this.contractInput.optionList = [];
    this.contractSearch.totalPageNumber = null;
    this.contractSearch.pageNumber = 1;
  }

  async searchContracts() {
    let filter;
    this.contractSearch.keywords && (filter = { contractNumber: this.contractSearch.keywords });

    let payload = await this.contractService.requestContractList({
      pageNumber: this.contractSearch.pageNumber,
      pageSize: this.contractSearch.pageSize,
      parameters: ['contractNumber'],
      filter,
    });

    if(payload) {
      this.contractSearch.totalPageNumber = Math.ceil(payload.totalCount / this.contractSearch.pageSize);
      this.contractInput.optionList = payload.records;
    }
  }
  // Start: plan ng select handler
  onPmPlanSearch(event) {
    this.pmPlanSearch.keywords = event.term;
    this.pmPlanSearch.search$.next();
  }
  
  onPmPlanChange(event) {
    this.onPmPeriodClear();
  }

  onPmPlanClear() {
    this.searchSelections.pmPlanNumber = null;

    this.pmPlanSearch.keywords = null;
    this.pmPlanSearch.search$.next();
  }

  onPmPeriodClear() {
    this.searchSelections.pmPeriod = null;
    this.pmPeriodSearch.keywords = null;
    this.pmPeriodSearch.search$.next();
  }

  private async requestTeamList(){
    if (this.teamTotalCount !== null && 
      this.teamTotalCount <= this.teamPageNumber * this.teamPageSize) {
      return;
    }
    this.isLoadingTeam = true;
    this.teamPageNumber++;

    let request: JM.JMRequestTeamsTeamSummary = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['_id', 'name'];
    request.pageNumber = this.teamPageNumber;
    request.pageSize = this.teamPageSize;

    const response: JM.JMResponseContractsGetVendorTeamList = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;
    
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if(response.payload.records.length > 0){
      let options =  response.payload.records.map(team => {
          let obj = { value: team._id, label: team.name };
          return obj;
        });
      this.teamTotalCount = response.payload.totalCount;
      this.teamOptions = this.teamOptions.concat(options);
      this.teamOptionsBackUp = this.teamOptions;
    }
    
  }

  private async requestTeamListWithKeyword(keyword: string){
    if (this.teamTotalCountKeyword !== null && 
      this.teamTotalCountKeyword <= this.teamPageNumberKeyword * this.teamPageSizeKeyword) {
      return;
    }
    this.isLoadingTeam = true;
    this.teamPageNumberKeyword++;

    let request: JM.JMRequestTeamsTeamSummary = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['_id', 'name'];
    request.filter = {
      name: keyword
    };
    request.pageNumber = this.teamPageNumberKeyword;
    request.pageSize = this.teamPageSizeKeyword;

    const response = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if(response.payload.records.length >= 0){
      let options =  response.payload.records.map(team => {
          let obj = { value: team._id, label: team.name };
          return obj;
        });
      this.teamOptions = this.teamOptions.concat(options);
      this.teamTotalCountKeyword = response.payload.totalCount;
    }
    this.teamOptionsLastSearchKeyword = keyword;
    
  }


  onTeamScrollToEnd(){
    if(this.teamOptionsIsSearchingKeyword){
      this.requestTeamListWithKeyword(this.teamOptionsLastSearchKeyword);
    } else {
      this.requestTeamList();
    }
  }

  onBlurDateInput(event){
    let valid
    switch(event.field) {
      case 'startDate':
        this.searchSelections.ngbStartDate = event.data;
        break;
      case 'endDate':
        this.searchSelections.ngbEndDate = event.data;
        break;
      case 'createTimeFrom':
        this.createTimeFromNgb = event.data;
        this.searchSelections.createTimeFrom = ngbDateToString(event.data);
        valid = this.validateTimePeriod(this.searchSelections.createTimeFrom , this.searchSelections.createTimeTo);
        this.showCreationTimeErrorMsg = !valid;
        break;
      case 'createTimeTo':
        this.createTimeToNgb = event.data;
        this.searchSelections.createTimeTo = ngbDateToString(event.data);
        valid = this.validateTimePeriod(this.searchSelections.createTimeFrom , this.searchSelections.createTimeTo);
        this.showCreationTimeErrorMsg = !valid;
        break;
    }

  }
  async onPmPlanScrollToEnd() {
    if (this.pmPlanSearch.totalPageNumber && this.pmPlanSearch.pageNumber < this.pmPlanSearch.totalPageNumber) {
      let filter;
      this.pmPlanSearch.pageNumber++;
      this.pmPlanSearch.keywords && (filter = { pmPlanNumber: this.pmPlanSearch.keywords });

      const payload = await this.pmPlanService.requestPmPlanList({
        pageNumber: this.pmPlanSearch.pageNumber,
        pageSize: this.pmPlanSearch.pageSize,
        contractNumber: this.searchSelections.contractNumber,
        parameters: ['pmPlanNumber'],
        filter,
      });

      if(payload) {
        this.pmPlanSearch.totalPageNumber = Math.ceil(payload.totalCount / this.pmPlanSearch.pageSize);
        this.pmPlanInput.optionList = this.pmPlanInput.optionList.concat(payload.records);
      }
    }
  }
  validateTimePeriod(fromNgbDate?: String, toNgbDateDate?: String){
    if (fromNgbDate && toNgbDateDate) {
      return toNgbDateDate >= fromNgbDate ? true : false;
    }

    return true;
  }
  resetPmPlanDropDown() {
    this.pmPlanInput.optionList = [];
    this.pmPlanSearch.totalPageNumber = null;
    this.pmPlanSearch.pageNumber = 1;
  }
  async searchPmPlans() {
    let filter;
    this.pmPlanSearch.keywords && (filter = { pmPlanNumber: this.pmPlanSearch.keywords });

    let payload = await this.pmPlanService.requestPmPlanList({
      pageNumber: this.pmPlanSearch.pageNumber,
      pageSize: this.pmPlanSearch.pageSize,
      contractNumber: this.searchSelections.contractNumber,
      parameters: ['pmPlanNumber'],
      filter,
    });

    if(payload) {
      this.pmPlanSearch.totalPageNumber = Math.ceil(payload.totalCount / this.pmPlanSearch.pageSize);
      this.pmPlanInput.optionList = payload.records;
    }
  }
   // Start: plan period ng select handler
   onPmPeriodSearch(event) {
  }

  onPmPeriodChange(event) {
  }

  onPmPeriodScrollToEnd() {
  }

  resetPmPeriodDropDown() {
    this.pmPeriodInput.optionList = [];
    this.pmPeriodSearch.totalPageNumber = null;
    this.pmPeriodSearch.pageNumber = 1;
  }

  async searchPmPeriods() {
    const payload = await this.pmPlanService.requestPmPeriodList({
      pageNumber: this.pmPeriodSearch.pageNumber,
      pageSize: this.pmPeriodSearch.pageSize,
      pmPlanNumber: [this.searchSelections.pmPlanNumber],
      parameters: ['_id', 'periodStartDate', 'periodEndDate'],
    });

    if(payload) {
      this.pmPeriodSearch.totalPageNumber = Math.ceil(payload.totalCount / this.pmPeriodSearch.pageSize);
      this.pmPeriodInput.optionList = payload.records.map((record) => {
        return {
          name: `${record.periodStartDate} - ${record.periodEndDate}`,
          code: record._id,
        }
      });
    }
  }

  // Start: equipment ng select handler
  onEquipmentSearch(event) {
    this.equipmentSearch.keywords = event.term;
    this.equipmentSearch.search$.next();
  }

  onEquipmentChange(event) {
  }

  onEquipmentClear() {
    this.equipmentSearch.keywords = null;
    this.equipmentSearch.search$.next();
  }

  async onEquipmentScrollToEnd() {
    if (this.equipmentSearch.totalPageNumber && this.equipmentSearch.pageNumber < this.equipmentSearch.totalPageNumber) {
      const request = new JM.JMRequestEquipmentsEquipmentSummary();
      this.equipmentSearch.keywords && (request.equipmentSearchKey = this.equipmentSearch.keywords);
      request.parameters = ['equipmentNumber', 'description'];
      request.pageSize = this.equipmentSearch.pageSize;
      request.pageNumber = this.equipmentSearch.pageNumber;
  
      this.equipmentInput.isLoading = true;
      const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
      this.equipmentInput.isLoading = false;
  
      if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if(response.payload) {
        this.equipmentSearch.totalPageNumber = Math.ceil(response.payload.totalCount / this.equipmentSearch.pageSize);
        this.equipmentInput.optionList = this.equipmentInput.optionList.concat(response.payload.records.map((record) => {
          return {
            name: `${record.equipmentNumber} ${record.description}`,
            code: record.equipmentNumber,
          }
        }));
      }
    }
  }

  resetEquipmentDropDown() {
    this.equipmentInput.optionList = [];
    this.equipmentSearch.totalPageNumber = null;
    this.equipmentSearch.pageNumber = 1;
  }

  async searchEquipments() {
    const request = new JM.JMRequestEquipmentsEquipmentSummary();
    this.equipmentSearch.keywords && (request.equipmentSearchKey = this.equipmentSearch.keywords);
    request.parameters = ['equipmentNumber', 'description'];
    request.pageSize = this.equipmentSearch.pageSize;
    request.pageNumber = this.equipmentSearch.pageNumber;

    this.equipmentInput.isLoading = true;
    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.equipmentInput.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if(response.payload) {
      this.equipmentSearch.totalPageNumber = Math.ceil(response.payload.totalCount / this.equipmentSearch.pageSize);
      this.equipmentInput.optionList = response.payload.records.map((record) => {
        return {
          name: `${record.equipmentNumber} ${record.description}`,
          code: record.equipmentNumber,
        }
      });
    }
  }
  onClickSearch(){
    this.search.emit(this.searchSelections);
    if(!this.validateTimePeriod(this.searchSelections.createTimeFrom , this.searchSelections.createTimeTo)){
      return
    }
    this.saveFilterOptionsToLocal();
  }

  onClickClear(){
    this.initDefaultFilters();
    this.resetNgb()
    this.search.emit();
    this.saveFilterOptionsToLocal();
  }
  resetNgb(){
    this.createTimeFromNgb = null;
    this.createTimeToNgb = null;
  }
  onSearchTeam(e){
    clearTimeout(this.teamOptionsSearchDebouncer);
    if(e.term){
      this.teamOptionsIsSearchingKeyword = true;
      this.teamOptionsSearchDebouncer = setTimeout(() => {
        this.teamTotalCountKeyword = null;
        this.teamPageNumberKeyword = 0;
        this.teamOptions = [];
        this.requestTeamListWithKeyword(e.term);
      }, Constants.DEBOUNCE_TIME);
    } else {
      this.teamOptionsIsSearchingKeyword = false;
      this.teamOptions = this.teamOptionsBackUp;
    }
    
  }
  onSearchFn(term: string, item: any){
    return true;
  }

  onTeamDropDownClose(e){
    this.teamOptions = this.teamOptionsBackUp;
    this.teamOptionsIsSearchingKeyword = false;
  }


  private saveFilterOptionsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      Session.setPmJobListSettingsFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  }
  private loadFilterOptionsFromLocal = () : void => {
    try {
      let cache = Session.pmJobListSettingsFilters;
      if (cache) {
        let cacheParsed = JSON.parse(JSON.stringify(cache));
        this.searchSelections = {
          ...this.searchSelections,
          ...cacheParsed,
        };
      }
    } catch (e) {
      console.warn(e);
    }
    if(Session.pmTaskListGeneralSearchCriteria && Session.pmTaskListGeneralSearchCriteria.has("selectedContract") && Session.pmTaskListGeneralSearchCriteria.get("selectedContract").length>0){
      this.searchSelections.contractNumber = Session.pmTaskListGeneralSearchCriteria.get("selectedContract")
    }
    if (this.searchSelections.contractNumber) {
      this.pmPlanSearch.search$.next();
    }
    if (this.searchSelections.pmPlanNumber) {
      this.pmPeriodSearch.search$.next();
    }
    if (this.searchSelections.equipmentNumber) {
      this.equipmentSearch.keywords = this.searchSelections.equipmentNumber;
      this.equipmentSearch.search$.next();
    }
    if (this.searchSelections.createTimeFrom) {
      this.createTimeFromNgb = stringToNgbDate(this.searchSelections.createTimeFrom, 'YYYYMMDD');
    }
    if (this.searchSelections.createTimeTo) {
      this.createTimeToNgb = stringToNgbDate(this.searchSelections.createTimeTo, 'YYYYMMDD');
    }
  }

}
class PmJobSearchContractInput extends DropdownControl {
  value?: string; // JMOBJ.MaintenanceTermContract;
  optionList: JMOBJ.MaintenanceTermContract[] = [];

  constructor() {
    super();
  }
}
class PmJobSearchPmPlanInput extends DropdownControl {
  value?: string; // JMOBJ.PmPlan;
  optionList: JMOBJ.PmPlan[] = [];

  constructor() {
    super();
  }
}

class PmJobSearchPmPeriodInput extends DropdownControl {
  value?: string; // JMOBJ.PmPeriod;
  optionList: any[] = []; // JMOBJ.PmPeriod[] = [];

  constructor() {
    super();
  }
}

class PmJobSearchEquipmentInput extends DropdownControl {
  value?: string; // JMOBJ.PmPeriod;
  optionList: any[] = []; // JMOBJ.PmPeriod[] = [];

  constructor() {
    super();
  }
}
