import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-pending-manual-handling',
  templateUrl: './pending-manual-handling.component.html',
  styleUrls: ['./pending-manual-handling.component.scss']
})
export class PendingManualHandlingComponent implements OnInit {
  @ViewChild("manual_instruction_handling_panel", { static: true }) manualInstructionHandlingPanel;

  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Output() refreshsn: EventEmitter<any> = new EventEmitter();

  // for manual instruction handlingMode
  manualInstructionHandlingFormData: any[];
  manualInstructionHandlingFormTeam = {};
  manualInstructionHandlingFormTeamNextOperationTime: Date;
  manualInstructionHandlingFormHandlingTeamNextOperationTime: Date;
  manualInstructionHandlingStatus: boolean[];
  manualInstructionHandlingRemark: string;

  isManualInstructionHandlingPanel = false;

  manualInstructionStage = undefined;

  fixedPanel = false;


  constructor() {}

  ngOnInit() {
  }

  ngOnChanges() {
    this.getManualInstructionStage();
  }



  getManualInstructionStage() {
    switch (this.sn.disseminationStage) {
      case JMENUM.DisseminationStage.PENDING_CONTRACTOR_MANUAL_INSTRUCTION:
        this.manualInstructionStage = "contractorNoResponse";
        break;
      case JMENUM.DisseminationStage.PENDING_RO_MANUAL_INSTRUCTION:
        this.manualInstructionStage = "roNoResponse";
        break;
      default:
        this.manualInstructionStage = null;
        break;
    }
  }

  public onClickManualHandling() {
    this.manualInstructionHandlingFormData = [];
    this.manualInstructionHandlingStatus = [];
    this.manualInstructionHandlingRemark = '';

    this.manualInstructionHandlingFormData = this.sn.team.manualInstructions[this.sn.priority][this.manualInstructionStage];
    if (this.sn.disseminationStage === JMENUM.DisseminationStage.PENDING_CONTRACTOR_MANUAL_INSTRUCTION) {
      if (this.sn.manualInstructionStatus || this.sn.manualInstructionStatus.contractorNoResponse) {
        let statusObj = this.sn.manualInstructionStatus.contractorNoResponse;
        this.manualInstructionHandlingStatus = statusObj.status ? statusObj.status : [];
        this.manualInstructionHandlingRemark = statusObj.remarks ? statusObj.remarks : '';
      }
    } else if (this.sn.disseminationStage === JMENUM.DisseminationStage.PENDING_RO_MANUAL_INSTRUCTION) { // PENDING_RO_MANUAL_INSTRUCTION
      if (this.sn.manualInstructionStatus || this.sn.manualInstructionStatus.roNoResponse) {
        let statusObj = this.sn.manualInstructionStatus.roNoResponse;
        this.manualInstructionHandlingStatus = statusObj.status ? statusObj.status : [];
        this.manualInstructionHandlingRemark = statusObj.remarks ? statusObj.remarks : '';
      }
    }
    this.requestTeamNextOperationTime();
  }

  private requestTeamNextOperationTime() {
    const teamId = this.sn.team._id.toString();
    const handlingTeamId = this.sn.team.handlingTeam;

    let request = new JM.JMRequestTeamsGetTeamNextOperationTime();
    request.idList = [teamId];

    if (handlingTeamId) {
      request.idList.push(handlingTeamId);
    }

    JM.JMConnector.sendTeamsGetTeamNextOperationTime(request, (error: JM.JMNetworkError, response: JM.JMResponseTeamsGetTeamNextOperationTime) => {
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
        AppDelegate.openErrorBar(response);
        return;
      }

      let teamNextOperationTime = undefined;
      let handlingTeamNextOperationTime = undefined;

      if (response.payload.records[teamId] !== undefined) {
        teamNextOperationTime = response.payload.records[teamId]; // null or date
      }
      if (handlingTeamId && response.payload.records[handlingTeamId] !== undefined) {
        handlingTeamNextOperationTime = response.payload.records[handlingTeamId]; // null or date
      }
      this.manualInstructionHandlingFormTeam = this.sn.team;
      this.manualInstructionHandlingFormTeamNextOperationTime = teamNextOperationTime;
      this.manualInstructionHandlingFormHandlingTeamNextOperationTime = handlingTeamNextOperationTime;
      this.manualInstructionHandlingPanel.toggle();
    });
  }

  public submitManualInstruction(manualInstructionStatus, handlingMode: 'draft' | 'handled') {
    if (!this.sn.manualInstructionStatus) {
      this.sn.manualInstructionStatus = {
        contractorNoResponse: {
          status: [], // Boolean Array
          remarks: undefined,
        },
        roNoResponse: {
          status: [], // Boolean Array
          remarks: undefined
        }
      };
    }

    if (!this.manualInstructionStage) return;

    this.sn.manualInstructionStatus[this.manualInstructionStage] = manualInstructionStatus;

    this.requestPostSnManualInstruction(handlingMode);
  }

  private requestPostSnManualInstruction(handlingMode: 'draft' | 'handled') {
    this.isManualInstructionHandlingPanel = true;
    this.fixedPanel = true;
    let request = new JM.JMRequestSnManualInstruction();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;
    request.type = (this.sn.disseminationStage == JMENUM.DisseminationStage.PENDING_CONTRACTOR_MANUAL_INSTRUCTION) ? "contractor" : "ro";
    request.manualInstructionStatus = this.sn.manualInstructionStatus;
    request.handlingMode = handlingMode;

    JM.JMConnector.sendSnManualInstruction(request, (error: JM.JMNetworkError, response: JM.JMResponseSnManualInstruction) => {
      //for testing fixed Panel
      // setTimeout(() => {
      //   console.log('sleep');
      // }, 60000);
      if (error) {
        AppDelegate.handleJMError(error);
        this.fixedPanel = false;
        this.isManualInstructionHandlingPanel = false;
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        this.fixedPanel = false;
        this.isManualInstructionHandlingPanel = false;
        return;
      }

      this.sn = response.payload;
      AppDelegate.openSnackBar(JMLanguage.translate(handlingMode === 'draft' ? 'global.saved' : 'global.submitted'));

      this.isManualInstructionHandlingPanel = false;
      this.manualInstructionHandlingPanel.close();
      this.fixedPanel = false;
      this.refreshsn.emit();
    });
  }

}
