/*

  version 20201208:
   - create this page
  
*/

import { Injector } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActionButtonDefinition, ActionButtonFlmsPending, ActionButtonType } from '@enum/action-button';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign, TablexColumnFilterType } from '@enum/tablexColumnType';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { ViewChild } from '@angular/core';
import { Session } from '@services/session';

@Component({
  selector: 'app-flms-pending-chinese',
  templateUrl: './flms-pending-chinese.component.html',
  styleUrls: ['./flms-pending-chinese.component.scss']
})
export class FlmsPendingChineseComponent extends BasePage implements OnInit {
  @ViewChild('flms-pending-chinese-table', { static: true }) flmsTable;
  @ViewChild("editFunctionalLocationChinesePanel", { static: true }) editFunctionalLocationChinesePanel;
  @ViewChild("editFunctionalLocationChineseForm", { static: true }) editFunctionalLocationChineseForm;

  // Table
  selectedTableRows: any = [];
  selectedRows: any = [];
  tablexParam: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  tablexFilter: {} = {};

  //action button related
  actionButtonData = [];
  uiIsLoading: boolean = true;

  locationSummary: JMOBJ.Location[] = [];

  selectedLocation: JMOBJ.Location = new JMOBJ.Location();

  importAttachmentParam: any = {};

  editMode = JMENUM.LocationEditMode.CHINESE_LOCATION;

  locationCodeColIndex = 0;

  iconClassList = {
    12000: 'fas fa-paperclip',
    12001: 'fas fa-list-ul',
    12002: 'fas fa-calendar-alt',
    12003: 'fas fa-bolt',
    12004: 'fas fa-bolt',
    12005: 'fas fa-keyboard',
    12006: 'fas fa-key',
    12008: 'fas fa-greater-than'
  }

  constructor(injector: Injector) { super(injector); }

  ngOnInit() {
    if (!this.authorizationService.hasPermissions([JMENUM.Permission.FLMS_VIEW, JMENUM.Permission.FLMS_VIEW_ALL], false)) {
      this.router.navigate(["/dashboard"]);
    }
    this.editMode = JMENUM.LocationEditMode.CHINESE_LOCATION;
    this.initActionButtons();
    this.initAllTableHeader();
    this.initTablex();
    this.requestPendingLocationSummary();
  }

  //Action Button Function-----------------------------------------------------------------------
  public initActionButtons(): void {
    this.actionButtonData = [];
    this.addActionBtn(ActionButtonFlmsPending.edit);
    this.addActionBtn(ActionButtonFlmsPending.discard);
  }

  public addActionBtn(buttonStatus: ActionButtonFlmsPending): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.flmsPending][buttonStatus];
    let buttonHandler = () => { };

    switch (buttonStatus) {
      case ActionButtonFlmsPending.edit:
        buttonHandler = () => {
          if (this.selectedTableRows.length > 0 && this.selectedTableRows.length <= 1) {
            this.requestLocationSummary();
          }
          else if (this.selectedTableRows.length < 0) {
            this.openSnackBar(JMLanguage.translate("popupError.no-location"));
          }
        };
        break;
      case ActionButtonFlmsPending.discard:
        buttonHandler = () => {
          if (this.selectedTableRows.length > 0) {
            const message = this.translate("action.button.popup.discard");
            const buttons = [
              { name: this.translate("global.yes"), handler: () => { this.requestDiscard(); } },
              { name: this.translate('global.no'), handler: () => { } }
            ];
            this.showPopUpAlert(message, '', buttons);
          }
          else if (this.selectedTableRows.length < 0) {
            this.openSnackBar(JMLanguage.translate("popupError.no-location"));
          }
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : "global.yes",
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : "global.no",
        handler: () => { this.uiIsLoading = false }
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  public onActionButtonClicked(actionButton: any) {
    actionButton.buttons[0].handler();
  }
  //--------------------------------------------------------------------------------------------

  //Tablex Function-----------------------------------------------------------------------------
  public renderTable() {
    if (this.locationSummary == null) { return }
    this.tablexParam['content'] = this.locationSummary.map(data => {
      let uploadError = [];

      for (let e = 0; e < data.invalidCode.length; e++) {
        let error = parseInt(data.invalidCode[e]);
        uploadError.push({
          iconClass: this.iconClassList[error],
          id: data.code + '_error-' + error,
          title: JMLanguage.translate("pages.flms.import-files.error-" + error),
          attention: true
        });
      }
      return [
        data.code,
        uploadError,
        data.description.zh
      ];
    });
  }

  public initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: false,
      enableSelectedRowCount: true,
      enableSelectedAll: true,
      enableSort: true,
      tableRow: "row",
      tableClass: "flms-pending-chinese-table",
      tableWrapperClass: "table-min-width",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      headers: this.allColHeaders,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  public initAllTableHeader() {
    this.allColHeaders = [
      {
        id: 'code',
        name: "pages.flms.table-column.functional-location",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'invalidCode',
        name: "pages.flms.table-column.upload-error",
        enableFilter: false,
        type: TablexColumnType.Icon,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'description.zh',
        name: "pages.flms.table-column.description.zh",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      }
    ];
  }

  public onRowClicked = (index, row) => {
    let findRow = this.selectedTableRows.find(item => { return item.every((val, index) => val === row[index]); })
    let haveRow = this.selectedTableRows.indexOf(findRow) > -1;
    if (haveRow) {
      this.tablexParam['highlightedRows'][index] = false;
      this.selectedTableRows.splice(this.selectedTableRows.indexOf(findRow), 1);
    } else {
      this.tablexParam['highlightedRows'][index] = true;
      this.selectedTableRows.push(row);
    }

    if (this.selectedTableRows.length > 1 || this.selectedTableRows.length <= 0) {
      this.handleDisableActionButton(ActionButtonFlmsPending.edit, false);
    } else if (this.selectedTableRows.length == 1) {
      this.handleDisableActionButton(ActionButtonFlmsPending.edit, true);
      let summaryIndex = this.tablexParam['highlightedRows'].findIndex(item => { return item == true });
      this.selectedLocation = this.locationSummary[summaryIndex];
    }
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
  }

  public onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
    this.requestPendingLocationSummary();
  }

  public onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.selectedTableRows = [];
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = this.selectedTableRows.length;
    this.requestPendingLocationSummary();
  }

  onSortOrderChanged = (header, sortOrder) => {
    if (this.tablexParam['sortBy'] == header.id && this.tablexParam['sortOrder'] == sortOrder) {
      this.tablexParam['sortBy'] = null;
    } else if (this.tablexParam['sortBy'] != header.id) {
      this.tablexParam['sortBy'] = header.id;
      this.tablexParam['sortOrder'] = 1;
    } else {
      this.tablexParam['sortOrder'] = sortOrder;
    }

    this.requestPendingLocationSummary();
  }
  //--------------------------------------------------------------------------------------------


  //API CALL------------------------------------------------------------------------------------
  public requestPendingLocationSummary() {
    let request: JM.JMRequestLocationsPendingLocationSummary = new JM.JMRequestLocationsPendingLocationSummary();
    request.fileType = JMENUM.PendingLocationFileType.CHINESE_LOCATION;
    request.parameters = [
      "code",
      "invalidCode",
      "description"
    ];
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    if (this.tablexParam['sortBy']) {
      request.sortBy = this.tablexParam['sortBy'];
      request.sortOrder = this.tablexParam['sortOrder'];
    }

    this.tablexParam["isLoadingTable"] = true;
    JM.JMConnector.sendLocationsPendingLocationSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseLocationsPendingLocationSummary) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      this.locationSummary = response.payload.records;
      this.renderTable();
      this.tablexParam['totalRowCount'] = response.payload.totalCount;
      this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
      this.tablexParam["isLoadingTable"] = false;
      this.selectedTableRows = [];
      this.tablexParam['highlightedRows'] = [];
      this.tablexParam['selectedRowCount'] = 0;
      this.selectedLocation = new JMOBJ.Location();
    });
  }

  public requestUpdatePendingLocation() {
    let request: JM.JMRequestLocationsUpdatePendingLocation = new JM.JMRequestLocationsUpdatePendingLocation();
    request.code = this.selectedLocation.code;
    request.fileType = JMENUM.PendingLocationFileType.CHINESE_LOCATION;
    request.chineseDescription = this.selectedLocation.description.zh;

    JM.JMConnector.sendLocationsUpdatePendingLocations(request, (error: JM.JMNetworkError, response: JM.JMResponseLocationsUpdatePendingLocation) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      this.requestPendingLocationSummary();
    });
  }

  public requestDiscard() {
    let request: JM.JMRequestLocationsDiscardPendingLocations = new JM.JMRequestLocationsDiscardPendingLocations();
    request.code = [];
    this.selectedTableRows.forEach(row => {
      request.code.push(row[this.locationCodeColIndex]);
    });
    request.fileType = JMENUM.PendingLocationFileType.CHINESE_LOCATION;

    JM.JMConnector.sendLocationsDiscardPendingLocations(request, (error: JM.JMNetworkError, response: JM.JMResponseLocationsDiscardPendingLocations) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      if (request.code.length - response.payload.totalCount == 0) {
        this.openSnackBar(JMLanguage.translate("pages.flms.message.discard", [response.payload.totalCount]));
      }
      else {
        this.openSnackBar(JMLanguage.translate("pages.flms.message.discard.haveError", [response.payload.totalCount, request.code.length - response.payload.totalCount]));
      }
      this.requestPendingLocationSummary();
    });
  }

  public requestLocationSummary() {
    let request: JM.JMRequestLocationsLocationSummary = new JM.JMRequestLocationsLocationSummary();
    request.location = [this.selectedLocation.code];
    request.parameters = [
      "code",
      "description",
      "address1",
      "address2",
      "address3",
      "district",
    ];

    JM.JMConnector.sendLocationsLocationSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseLocationsLocationSummary) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      if (response.payload.totalCount > 0) {
        this.selectedLocation.code = response.payload.records[0]["code"];
        this.selectedLocation.description.en = response.payload.records[0]["description"]["en"];
        this.selectedLocation.address1 = response.payload.records[0]["address1"];
        this.selectedLocation.address2 = response.payload.records[0]["address2"];
        this.selectedLocation.address3 = response.payload.records[0]["address3"];
        this.selectedLocation.district = response.payload.records[0]["district"];
        this.editFunctionalLocationChinesePanel.toggle();
      }
      else {
        this.openSnackBar(JMLanguage.translate("pages.flms.message.edit.noLocation"));
      }

    });
  }

  //--------------------------------------------------------------------------------------------

  // button callback functions------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------

  //Other---------------------------------------------------------------------------------------
  public onLocationSave(event) {
    this.editFunctionalLocationChinesePanel.close();
    this.requestUpdatePendingLocation();
  }

  handleDisableActionButton(button, isEnable) {
    let actionButton = this.actionButtonData.find(({ action }) => action == button);
    actionButton.isEnable = isEnable;
  }

  onLanguageChanged() {
  }
  //--------------------------------------------------------------------------------------------

}

