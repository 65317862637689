import { Component, Injector, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ActionButtonDefinition, ActionButtonType, ActionButtonTimeSheet } from '@enum/action-button';
import { ContactGroupService } from '@services/contact-group.service';
import { BasePage } from "src/app/ui/model/base/base";
import { JM, JMENUM, JMOBJ, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { SnService } from '@services/sn.service';
import { Options, LabelType } from "ng5-slider";
import { Permission } from '@enum/permission';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/AppDelegate';
import * as utility from 'src/app/services/utility';
import { Session } from '@services/session';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TimesheetInputFormComponent, TimeSheetOutput, DateRowOutput } from 'src/app/ui/components/timesheet/timesheet-input-form/timesheet-input-form.component'
import { JMLanguage, Language } from "src/lib/JMLanguage/JMLanguage";
import { Constants } from 'src/constants';
import { isEqual } from 'lodash';

@Component({
  selector: "timesheet-job-v2",
  templateUrl: "./timesheet-job-v2.component.html",
  styleUrls: ["./timesheet-job-v2.component.scss"]
})
export class TimesheetJobV2Component extends BasePage implements OnInit {

  @ViewChild("assign_staff_panel", { static: true }) assignStaffPanel;

  actionButtonData = [];
  disabledActionSideBar: boolean;
  isLoading: boolean = true;
  // timeSheetNotSaved: boolean = false;

  selectedJobCardId: string;

  timeSheetInputedStaff = [];
  assignStaffFormParam = {} as any;
  showAllPostForReassignStaff = false;
  workCentrePostList: any[];
  selectedAssignPersons: {
    assignedPersons: string[],
    officerInCharge: string
  } = {
      assignedPersons: [],
      officerInCharge: null
    };

  jobCard: JMOBJ.JobCard = undefined;
  SnTeamData: any;

  // storing the work date of the staff
  employeeWorkDate: {
    [key: string]: string[]
  } = {}; // key: <postName>_<employeeCode, val: YYYY-MM-DD[], the acting date within job period
  lang = Session.selectedLanguage ? Session.selectedLanguage : Language.EN;
  bufferTimeOptions: Options = {};
  bufferTimeValue: number = 0;
  initBufferTimeValue: number = 0;

  taskNumber: { [key: string]: string } = {};  // key is postName
  uiJobDuration: string = '';
  displayHour: number = 0;
  displayMinutes: number = 0;
  jobPeriod: number = 1;
  jobPeriodList: any = [];
  officeHours: any = [];
  staffTimeSheet: {} = {}; //for slider, storing  {startTime: 9, endTime: 21.75}
  staffTimeSheetForm: {} = {}; //for submit, storing  {startTime: Moment, endTime: Moment}
  staffTimeSheetDisplay: {} = {}; //for display, storing  {startTime: "9:00", endTime: "21:45"}
  timesheetTooltip: {} = {}; // key: [postName_employeeCode][day], obj: { show: true, message: ''}
  timeSheetRecords: JMOBJ.Timesheet;

  uiTimesheetSaved: boolean = false;  // if the record has been saved in backend 

  viewOnly: boolean = false;

  timesheet: JMOBJ.JobTimesheet;
  officerList: JMOBJ.JobTimesheetOfficer[] = [];
  jobPeriodDateList: string[] = [];
  timesheetForUI: any;
  initTimeSheet:any;

  searchWord = null;
  isNoRecord = false;

  constructor(
    injector: Injector,
    private contactGroupService: ContactGroupService,
    private snService: SnService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {
    super(injector);
  }

  //===========================================================================
  // view life cycle functions
  ngOnInit() {
    this.checkViewPermission(Permission.timesheetUpdate);
    this.renderBufferTimeSlider();
    this.checkfeatureEnabled();
    this.selectedJobCardId = this.route.snapshot.paramMap.get('jobCardId');
    this.isLoading = true;
    this.requestJobCard().then(res => {
      this.requestTimesheet().finally(() => {
        this.isLoading = false;
        this.initActionButtons();
        this.initJobInfo();
      });
    }).catch(err => {
      this.isLoading = false;
    })
  }

  //===========================================================================
  // view functions

  renderBufferTimeSlider() {
    this.bufferTimeOptions = {
      floor: 0,
      ceil: 2,
      showTicksValues: true,
      showSelectionBar: true,
      animate: false,
      disabled: this.viewOnly,
      translate: (value: number): string => {
        return value + "h";
      },
      stepsArray: [{ value: 0 }, { value: 1 }, { value: 2 }]
    }
  }

  checkfeatureEnabled() {
    if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JOB_CARD_TIMESHEET_V2)) {
      this.router.navigate(['']);
      return;
    }
  }

  initJobInfo() {
    if (this.jobCard.completionTime) {
      let jobDuration = Math.floor(moment(this.jobCard.completionTime.toString()).diff(moment(this.jobCard.startTime.toString()), 'minutes') / 60);
      this.uiJobDuration = jobDuration.toString();
    } else {
      this.uiJobDuration = '';
    }
  }

  initReassignStaffPanel(isClear = true) {
    if (this.showAllPostForReassignStaff && this.workCentrePostList == null) {
      this.requestWorkCentrePostList();
      return;
    }

    let assignedPersons = [];
    let officerInCharge = null;
    let teamMembers = this.showAllPostForReassignStaff ? this.workCentrePostList : this.jobCard.teamMembers;

    if (isClear) {
      assignedPersons = this.jobCard.assignedPersons;
      officerInCharge = this.jobCard.officerInCharge;
      this.selectedAssignPersons.assignedPersons = assignedPersons;
      this.selectedAssignPersons.officerInCharge = officerInCharge;
    } else {
      assignedPersons = this.selectedAssignPersons.assignedPersons;
      officerInCharge = this.selectedAssignPersons.officerInCharge;
    }

    if (this.selectedAssignPersons.assignedPersons != undefined) {
      teamMembers = Array.from(new Set(teamMembers.concat(this.selectedAssignPersons.assignedPersons)));
    }

    this.assignStaffFormParam = {
      teamMembers: teamMembers,
      assignedPersons: assignedPersons,
      officerInCharge: officerInCharge,
      onSubmitClicked: this.onAssignStaffSubmitClicked,
      timeSheetInputedStaff: this.getTimeSheetInputedStaff(),
      onAddMemberClicked: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      },
      onRemoveMemberClicked: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      },
      onOfficerInChargeChanged: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      }
    };
  }


  //===========================================================================
  // api function
  async requestJobCard() {
    const request = new JM.JMRequestJobCardsJobCardSummary();
    request.jobCardNumberList = [this.selectedJobCardId];
    request.parameters = [
      "version",
      "jobCardNumber",
      "status",
      "startTime",
      "completionTime",
      "officerInCharge",
      "teamMembers",
      "assignedPersons",
      "officerInCharge",
      "orderType",
      "workCentre",
      "snWorkCentre",
      "snTeamId",
      "ccsServiceOrderNumber",
      "soObtainedByCcep",
      "freezedActionList",
    ];
    request.includeSummary = true;

    const response: JM.JMResponseJobCardsJobCardSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.jobCard = response.payload.records[0];


    this.assignStaffFormParam.teamMembers = this.jobCard.teamMembers;
  }

  async requestWorkCentrePostList() {
    this.assignStaffFormParam.isLoading = true

    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = 'CCEPJM';
    request.authorizations = { 'workCenters': this.jobCard.workCentre };
    request.active = JMENUM.RequestActive.ACTIVE;

    const response = await AppDelegate.sendJMRequest(request);
    this.assignStaffFormParam.isLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.showAllPostForReassignStaff = false;
      return;
    }

    if (!response.payload.records || !response.payload.records.length) {
      this.showAllPostForReassignStaff = false;
      return;
    }

    this.workCentrePostList = response.payload.records.map(post => post.name);
    this.initReassignStaffPanel(false);
  }

  onClickTimeSheetBtn(officer: JM.JMOBJ.JobTimesheetOfficer, isViewOnly: boolean) {
    const dialogConfig = new MatDialogConfig();
    const { post, postName, employeeName, personnelNumber, taskNumber, workingDays } = officer;
    let latestMoment = this.jobCard.completionTime ? moment(this.jobCard.completionTime).add(4, 'hour') : null;
    if (latestMoment) {
      latestMoment.set({
        minute: Math.ceil(latestMoment.minute() / 15.0) * 15
      });
    }
    let earliestMoment = this.jobCard.startTime ? moment(this.jobCard.startTime).subtract(4, 'hour'): null;
    if (earliestMoment) {
      earliestMoment.set({
        minute: Math.floor(earliestMoment.minute() / 15.0) * 15
      });
    }

    dialogConfig.panelClass = 'timesheet-input-form-dialog-container';
    dialogConfig.data = {
      post: post,
      postName: postName,
      personnelNumber: personnelNumber,
      employeeName: employeeName,
      taskNumber: taskNumber,
      workingDays: workingDays,
      viewOnly: isViewOnly,
      latestMoment: latestMoment,
      earliestMoment: earliestMoment,
      initOfficer: this.getOfficerById(this.initTimeSheet, post, personnelNumber)
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    this.showPopUpTimeSheetEditor(dialogConfig);
  }

  getOfficerById(timeSheet, post, personnelNumber) {
    for (const officer of timeSheet.officers) {
      if (officer.post == post && officer.personnelNumber == personnelNumber) {
        return officer
      }
    }
  }

  getWorkingDayByPostDate(workingDays: JM.JMOBJ.JobTimesheetWorkDay[], postDate: string) {
    for (const workingDay of workingDays) {
      if (workingDay.postDate === postDate) {
        return workingDay
      }
    }
  }

  showPopUpTimeSheetEditor(config: MatDialogConfig) {
    const dialogRef = this.dialog.open(TimesheetInputFormComponent, config);

    dialogRef.afterClosed().subscribe(
      (data: TimeSheetOutput) => {
        if (data != undefined) {
          this.updateOfficerTimeSheet(data)
        }
      }
    );
  }

  isTaskNumberChanged(prevTaskNumber, newTaskNumber) {
    return prevTaskNumber !== newTaskNumber
  }

  updateOfficerTimeSheet(data: TimeSheetOutput) {
    this.timesheetForUI.officers.forEach((officer) => {
      if (officer.post === data.post && officer.personnelNumber === data.personnelNumber) {
        const initOfficer = this.getOfficerById(this.initTimeSheet, officer.post, officer.personnelNumber)
        this.setOfficerTaskNumber(officer, data.taskNumber, this.isTaskNumberChanged(this.initTimeSheet.taskNumber,data.taskNumber));
        this.setOfficerWorkingDays(officer, this.getPeroidMapByOutput(data), this.getPeroidMapByOfficer(initOfficer), this.getEditedDates(initOfficer.workingDays, data.workingDays));
        this.setOfficerTotalHours(officer);
        this.setOfficerErrorMessage(officer);
        this.countChargeableHours();
      }
    })
  }

  getPeroidMapByOfficer(officer){
    let peroidMap = new Map();
    officer.workingDays.forEach((workingDay) => {
      peroidMap.set(workingDay.postDate, workingDay.periods)
    })
    return peroidMap
  }

  setOfficerErrorMessage(officer) {
    officer.workingDays.forEach((workingDay) => {
      workingDay.periods.forEach((period) => {
        if (period.isUpdated) {
          workingDay['isPeriodsUpdated'] = true
        }
      })
    })
  }

  setOfficerTotalHours(officer) {
    officer.workingDays.forEach((workingDay) => {
      let totalMins = 0;
      workingDay.periods.forEach((period: JM.JMOBJ.JobTimesheetWorkPeriod) => {
        totalMins += (period.totalMinutes || 0);
      })
      const hour = Math.floor(totalMins / 60);
      workingDay['totalHours'] = this.getWorkingDayTotalHourString(totalMins);
    })
  }

  setOfficerTaskNumber(officer, taskNumber: string, isTaskNumberChanged: boolean) {
    officer.taskNumber = taskNumber;
    officer.isUpdatedTaskNumber = isTaskNumberChanged;
  }

  getTotalMinutes(startTime, endTime) {
    startTime = moment(startTime, "HH:mm");
    endTime = moment(endTime, "HH:mm");
    return moment(endTime).diff(moment(startTime), 'minutes');
  }

  getEditedDates(prevWorkingDays: DateRowOutput[], newWorkingDays: DateRowOutput[]) {
    const editedDates: string[] = [];
    if (prevWorkingDays.length != newWorkingDays.length) {
      return editedDates;
    }
    for (let i = 0; i < prevWorkingDays.length; i++) {
      if (prevWorkingDays[i].postDate === newWorkingDays[i].postDate) {
        if (!isEqual(this.reduceWorkingDay(prevWorkingDays[i]), (this.reduceWorkingDay(newWorkingDays[i])))) {
          editedDates.push(prevWorkingDays[i].postDate);
        }
      }
    }
    return editedDates
  }

  reduceWorkingDay(workingDay: DateRowOutput) {
    const filterKeys = ['startTime', 'endTime'];
    return workingDay.periods.map(period => {
      return Object.keys(period).filter(key => filterKeys.includes(key)).reduce((temp, key) => {
        temp[key] = period[key];
        return temp;
      }, {});
    })
  }
  
  getPeroidMapByOutput(data: TimeSheetOutput) {
    let peroidMap = new Map();
    data.workingDays.forEach((workingDay) => {
      workingDay.periods = workingDay.periods.map((period) => {
        return {
          ...new JM.JMOBJ.JobTimesheetWorkPeriod(),
          ...period,
          totalMinutes: this.getTotalMinutes(period.startTime, period.endTime),
        }
      })
      peroidMap.set(workingDay.postDate, workingDay.periods)
    })
    return peroidMap
  }

  setOfficerWorkingDays(officer, newPeroidMap, oldPeroidMap, editedDates: string[]) {
    officer.workingDays.forEach((workingDay) => {
      if (newPeroidMap.get(workingDay.postDate) != undefined) {
        if(editedDates.includes(workingDay.postDate)){
          workingDay.periods = newPeroidMap.get(workingDay.postDate);
          workingDay.isPeriodsUpdated = true;
        }else{
          workingDay.periods = oldPeroidMap.get(workingDay.postDate);
          workingDay.isPeriodsUpdated = false;
        }
      }
    })
  }

  getTimeSheetInputedStaff() {
    let timeSheetInputedStaff = []
    if (!this.timesheetForUI || !this.timesheetForUI.officers) {
      return timeSheetInputedStaff
    }

    for (const officer of this.timesheetForUI.officers){
      for (const workingDay of officer.workingDays){
        if(workingDay.periods.length > 0){
          timeSheetInputedStaff.push(officer.postName)
        }
      }
    }
    
    return timeSheetInputedStaff
  }

  async requestTimesheet() {
    const request = new JM.JMRequestFinanceGetTimesheetByJobCard();
    request.jobCardNumber = this.selectedJobCardId;
    const response: JM.JMResponseFinanceGetTimesheetByJobCard = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200) {
      AppDelegate.openErrorBar(response);
      this.router.navigate(['']);
      return;
    }
    if (response.payload) {
      this.renderTimeSheet(response.payload);
    }
  }

  renderTimeSheet(payload: JMOBJ.JobTimesheet, isUpdateInitTimesheet = true) {
    this.timesheet = payload
    this.viewOnly = this.timesheet.operatingMode === JMENUM.TimesheetOperatingMode.VIEW_ONLY;
    this.timesheetForUI = JSON.parse(JSON.stringify(this.timesheet));
    this.bufferTimeValue = this.timesheet.bufferTime;
    this.timesheetForUI.officers.forEach((officer: JM.JMOBJ.JobTimesheetOfficer) => {
      officer.workingDays.forEach((workingDay: JM.JMOBJ.JobTimesheetWorkDay) => {
        if (workingDay.postDate) {
          workingDay['postDate'] = moment(workingDay.postDate).format('YYYY-MM-DD');
          workingDay['weekday'] = this.getWeekdayTranslate(workingDay['postDate']);
        }
        let totalMins = 0;
        workingDay.periods.forEach((period: JM.JMOBJ.JobTimesheetWorkPeriod) => {
          totalMins += (period.totalMinutes || 0);
        })
        workingDay['totalHours'] = this.getWorkingDayTotalHourString(totalMins);
        workingDay.periods.sort((a: JM.JMOBJ.JobTimesheetWorkPeriod, b: JM.JMOBJ.JobTimesheetWorkPeriod) => {
          return parseTimetoInt(a.startTime) - parseTimetoInt(b.startTime);
        });
      });
    });
    this.timesheetForUI['totalChargableHours'] = this.getTotalChargeableHourString(this.timesheetForUI.staffChargeableMinutes);
    if (isUpdateInitTimesheet) {
      this.initBufferTimeValue = this.bufferTimeValue;
      this.initTimeSheet = JSON.parse(JSON.stringify(this.timesheetForUI));
    }
    if (!this.timesheetForUI.officers.length) {
      this.isNoRecord = true;
    }
    if (this.viewOnly) {
      this.renderBufferTimeSlider();
    }
  }

  getTotalChargeableHourString(staffChargeableMinutes: number): string {
    const hour = Math.floor(staffChargeableMinutes / 60);
    return staffChargeableMinutes > 0 ? `${hour === 1 ? hour + ' ' + JMLanguage.translate('pages.timesheet.hour') : hour > 1 ? hour + ' ' +
      JMLanguage.translate('pages.timesheet.hours') : ''} ${staffChargeableMinutes % 60 ? staffChargeableMinutes % 60 + ' ' + JMLanguage.translate('pages.timesheet.minutes') : ''}` : '0';
  }

  getWorkingDayTotalHourString(totalMins: number): string {
    const hour = Math.floor(totalMins / 60);
    return totalMins > 0 ? `${hour === 1 ? hour + ' ' + JMLanguage.translate('pages.timesheet.short-form.hour') : hour > 1 ? hour + ' ' + JMLanguage.translate('pages.timesheet.short-form.hours')
      : ''} ${totalMins % 60 ? totalMins % 60 + ' ' + JMLanguage.translate('pages.timesheet.short-form.mins') : ''}` : '0';
  }

  private getFailTimesheetCount(officerArray: any): number {
    let count = 0;
    for (const officer of officerArray) {
      for (const workPeriod of officer.workPeriodArray) {
        if (!workPeriod.isSuccess || workPeriod.isSuccess === false) count++;
      }
    }
    return count;
  }

  //==================================================================
  // UI function

  initActionButtons(): void {
    this.actionButtonData = [];

    if (this.viewOnly) {
      this.addActionBtn(ActionButtonTimeSheet.exit);
      return;
    }

    this.addActionBtn(ActionButtonTimeSheet.save);
    this.addActionBtn(ActionButtonTimeSheet.reset);
    if (this.jobCard.orderType === JMENUM.OrderType.SLA_JOB) { // ZS01
      this.addActionBtn(ActionButtonTimeSheet.autoFill);
    }
    if (this.jobCard.status == JMENUM.JobCardStatus.IN_PROGRESS) {
      this.addActionBtn(ActionButtonTimeSheet.addStaff);
    }
    this.addActionBtn(ActionButtonTimeSheet.exit);
  }

  addActionBtn(buttonStatus: ActionButtonTimeSheet): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.timeSheet][buttonStatus];
    let buttonHandler = () => { };

    switch (buttonStatus) {
      case ActionButtonTimeSheet.save:
        buttonHandler = () => {
          this.requestSaveTimeSheet();
        };
        break;
      case ActionButtonTimeSheet.reset:
        buttonHandler = () => {
          this.resetBufferTime();
          this.resetOfficersContents();
          this.countChargeableHours();
        };
        break;
      case ActionButtonTimeSheet.autoFill:
        buttonHandler = () => {
          let buttons = [
            {
              name: this.translate("global.yes"),
              handler: () => {
                this.autofillTimesheet();
              }
            },
            { name: this.translateService.instant("global.no") }
          ];

          this.showPopUpAlert(this.translate("pages.timesheet.v2.auto-fill-timesheet"), "", buttons);
        };
        break;
      case ActionButtonTimeSheet.exit:
        buttonHandler = () => {
          this.router.navigate(["/job-card/view/", this.selectedJobCardId]);
        };
        break;
      case ActionButtonTimeSheet.addStaff:
        buttonHandler = () => {
          if (this.isUnsavedChanges(this.timesheetForUI)) {
            let buttons = [
              {
                name: this.translate("global.yes")
              },
              {
                name: this.translateService.instant("global.no"),
                handler: () => {
                  this.initReassignStaffPanel(true);
                  this.assignStaffPanel.toggle();
                }
              }
            ];
            if(this.lang == Language.ZH){
              this.showPopUpAlert(this.translate("job-card-assign-staff-form.confirm-unsaved-timesheet"), "", buttons);
            }else{
              this.showPopUpAlert("", "", buttons, [this.translate("job-card-assign-staff-form.confirm-unsaved-timesheet_1"),this.translate("job-card-assign-staff-form.confirm-unsaved-timesheet_2")]);
            }

          }else{
            this.initReassignStaffPanel(true);
            this.assignStaffPanel.toggle();
          }
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : this.translateService.instant("global.yes"),
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : this.translateService.instant("global.no"),
        handler: () => { this.isLoading = false }
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  async autofillTimesheet() {
    const request: JM.JMRequestFinanceGetAutoFilledTimesheetByJobCard = new JM.JMRequestFinanceGetAutoFilledTimesheetByJobCard();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    const response: JM.JMResponseFinanceGetAutoFilledTimesheetByJobCard = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload) {
      this.renderTimeSheet(response.payload, false);
      this.markOfficersIsUpdated();
    }
  }

  markOfficersIsUpdated() {
    this.timesheetForUI.isUpdatedBufferTime = false;
    this.timesheetForUI.officers.forEach((officer: JM.JMOBJ.JobTimesheetOfficer) => {
      officer['isUpdatedTaskNumber'] = false;
      const initOfficer = this.getOfficerById(this.initTimeSheet, officer.post, officer.personnelNumber);
      const editedDates = this.getEditedDates(initOfficer.workingDays, officer.workingDays)
      officer.workingDays.forEach((workingDay: JM.JMOBJ.JobTimesheetWorkDay) => {
        if (editedDates.indexOf(workingDay.postDate) != -1) {
          workingDay['isPeriodsUpdated'] = true;
        } else {
          workingDay['isPeriodsUpdated'] = false;
        }
      })
    })
  }

  isUnsavedChanges(timesheetForUI){
    const { isUpdatedBufferTime } = timesheetForUI
    if(isUpdatedBufferTime){
      return true
    }
    
    for (const officer of timesheetForUI.officers) {
      if(officer.isUpdatedTaskNumber){
        return true
      }

      for (const workingDay of officer.workingDays) {
        if(workingDay.isPeriodsUpdated){
          return true
        }
      }
    }
    return false
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = this.translateService.instant(button.name);
      })
      this.showPopUpAlert(this.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  isBufferTimeUpdated(){
    return this.initBufferTimeValue !== this.bufferTimeValue
  }

  onChangeBufferTime() {
    if (this.timesheetForUI) {
      this.timesheetForUI.isUpdatedBufferTime = this.isBufferTimeUpdated();
    }
    this.countChargeableHours();
  }

  countChargeableHours() {
    let totalMins = 0;
    this.timesheetForUI.officers.forEach((officer: JM.JMOBJ.JobTimesheetOfficer) => {
      officer.workingDays.forEach((workingDay: JM.JMOBJ.JobTimesheetWorkDay) => {
        let workingDayMins = 0;
        workingDay.periods.forEach((period: JM.JMOBJ.JobTimesheetWorkPeriod) => {
          if (period.totalMinutes) {
            workingDayMins += period.totalMinutes;
          }
        });
        if (workingDayMins) {
          totalMins += (workingDayMins + this.bufferTimeValue * 60)
        }
      });
    });
    this.timesheetForUI.staffChargeableMinutes = totalMins;
    const hour = Math.floor(this.timesheetForUI.staffChargeableMinutes / 60);
    if (JMLanguage.getCurrentLanguage() === Language.EN) {
      this.timesheetForUI['totalChargableHours'] = this.timesheetForUI.staffChargeableMinutes > 0 ? `${hour === 1 ? hour + ' Hour' : hour > 1 ? hour + ' Hours'
        : ''} ${this.timesheetForUI.staffChargeableMinutes % 60 ? this.timesheetForUI.staffChargeableMinutes % 60 + ' Minutes' : ''}` : 0;
    } else {
      this.timesheetForUI['totalChargableHours'] = this.timesheetForUI.staffChargeableMinutes > 0 ? `${hour >= 1 ? hour + ' ' + JMLanguage.translate('pages.timesheet.hours') : ''} ${this.timesheetForUI.staffChargeableMinutes % 60 ? this.timesheetForUI.staffChargeableMinutes % 60 + ' ' + JMLanguage.translate('pages.timesheet.minutes') : ''}` : 0;
    }
  }

  onJobNumberClicked() {
    this.router.navigate(["/job-card/view/", this.selectedJobCardId]);
  }

  onChangeTimeSheet = (point: string, postName: string, employeeCode: string, day: string, dayType: string) => {
    let key = postName + "_" + employeeCode;
    let startHour = parseInt(this.staffTimeSheetDisplay[key][day].startTime.split(":")[0]);
    let startMinute = parseInt(this.staffTimeSheetDisplay[key][day].startTime.split(":")[1]);
    let endHour = parseInt(this.staffTimeSheetDisplay[key][day].endTime.split(":")[0]);
    let endMinute = parseInt(this.staffTimeSheetDisplay[key][day].endTime.split(":")[1]);
    this.staffTimeSheet[key][day] = {
      startTime: startHour + startMinute / 60.0,
      endTime: endHour + endMinute / 60.0,
    };
  }

  updatStaffTimeSheetForm(postName: string, employeeCode: string, day: string, point: string) {
    let key = postName + "_" + employeeCode;
    if (point == 'start') {
      this.staffTimeSheetForm[key][day].startTime = moment(this.staffTimeSheetForm[key][day].startTime).set({
        year: moment(day).year(),
        month: moment(day).month(),
        date: moment(day).date(),
        hour: parseInt(this.staffTimeSheetDisplay[key][day].startTime.split(":")[0]),
        minute: parseInt(this.staffTimeSheetDisplay[key][day].startTime.split(":")[1]),
      });
    } else {
      this.staffTimeSheetForm[key][day].endTime = moment(this.staffTimeSheetForm[key][day].endTime).set({
        year: moment(day).year(),
        month: moment(day).month(),
        date: moment(day).date(),
        hour: parseInt(this.staffTimeSheetDisplay[key][day].endTime.split(":")[0]),
        minute: parseInt(this.staffTimeSheetDisplay[key][day].endTime.split(":")[1]),
      });
    }
    this.countChargeableHours();
  }

  // call this function if slider changes
  onTimesheetSliderChanged = (point: string, postName: string, employeeCode: string, day: string) => {
    let key = postName + "_" + employeeCode;
    this.timesheetTooltip[key][day] = {
      show: true,
      message: this.translate('pages.timesheet.no-timesheet-tooltip')
    };
    if (point == 'start') {
      let startTime: number = this.staffTimeSheet[key][day].startTime;
      let hh_str = this.valueToTimeFormat(startTime).split(":")[0];
      let mm_str = this.valueToTimeFormat(startTime).split(":")[1];

      let startMoment = moment(day + " " + hh_str + ":" + mm_str, "YYYY-MM-DD HH:mm");
      let endMoment = moment(day + " " + this.staffTimeSheetDisplay[key][day].endTime, "YYYY-MM-DD HH:mm");
      let earliestMoment = moment(this.jobCard.startTime).subtract(4, 'hour');
      earliestMoment.set({
        minute: Math.floor(earliestMoment.minute() / 15.0) * 15
      });

      if (startMoment < earliestMoment) {
        let timeValue = earliestMoment.hour() + earliestMoment.minute() / 60.0;
        this.staffTimeSheetDisplay[key][day].startTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].startTime = timeValue;
        this.openSnackBar(this.translateService.instant("pages.timesheet.start-time-max"));

      } else if (startMoment > endMoment) {
        this.staffTimeSheetDisplay[key][day].startTime = this.staffTimeSheetDisplay[key][day].endTime;
        this.staffTimeSheet[key][day].startTime = this.staffTimeSheet[key][day].endTime;

      } else {
        this.staffTimeSheetDisplay[key][day].startTime = this.valueToTimeFormat(startTime);
      }

    } else if (point == 'end') {
      let endTime: number = this.staffTimeSheet[key][day].endTime;
      let hh_str = this.valueToTimeFormat(endTime).split(":")[0];
      let mm_str = this.valueToTimeFormat(endTime).split(":")[1];

      let startMoment = moment(day + " " + this.staffTimeSheetDisplay[key][day].startTime, "YYYY-MM-DD HH:mm");
      let endMoment = moment(day + " " + hh_str + ":" + mm_str, "YYYY-MM-DD HH:mm");
      let latestMoment = this.jobCard.completionTime ? moment(this.jobCard.completionTime).add(4, 'hour') : null;
      if (latestMoment) {
        latestMoment.set({
          minute: Math.ceil(latestMoment.minute() / 15.0) * 15
        });
      }

      if (latestMoment && endMoment > latestMoment) {
        let timeValue = latestMoment.hour() + latestMoment.minute() / 60.0;
        this.staffTimeSheetDisplay[key][day].endTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].endTime = timeValue;
        this.openSnackBar(this.translateService.instant("pages.timesheet.end-time-max"));

      } else if (endMoment < startMoment) {
        this.staffTimeSheetDisplay[key][day].endTime = this.staffTimeSheetDisplay[key][day].startTime;
        this.staffTimeSheet[key][day].endTime = this.staffTimeSheet[key][day].startTime;

      } else {
        this.staffTimeSheetDisplay[key][day].endTime = this.valueToTimeFormat(endTime);
      }
    }
    this.updatStaffTimeSheetForm(postName, employeeCode, day, point);
  };

  resetOfficersContents() {
    this.timesheetForUI.officers.forEach((officer: JM.JMOBJ.JobTimesheetOfficer) => {
      officer.taskNumber = undefined;
      officer['isUpdatedTaskNumber'] = false;
      officer.workingDays.forEach((workingDay: JM.JMOBJ.JobTimesheetWorkDay) => {
        workingDay['periods'] = [];
        let totalMins = 0;
        workingDay.periods.forEach((period: JM.JMOBJ.JobTimesheetWorkPeriod) => {
          totalMins += (period.totalMinutes || 0);
        })
        workingDay['isPeriodsUpdated'] = false;
        workingDay['totalHours'] = this.getWorkingDayTotalHourString(totalMins);
      });
      this.timesheetForUI['totalChargableHours'] = this.getTotalChargeableHourString(this.timesheetForUI.staffChargeableMinutes);
    })
  }

  resetBufferTime() {
    this.bufferTimeValue = 0;
    this.timesheetForUI.isUpdatedBufferTime = this.isBufferTimeUpdated();
  }

  async requestSaveTimeSheet() {
    let officerArray: JMOBJ.JobTimesheetOfficer[] = [];

    for (const employee of this.timesheetForUI.officers) {
      let officer: JMOBJ.JobTimesheetOfficer = new JMOBJ.JobTimesheetOfficer();
      officer.postName = employee.postName
      officer.personnelNumber = employee.personnelNumber
      officer.taskNumber = employee.taskNumber
      officer.workingDays = employee.workingDays.map((workingDay) => {
        return {
          postDate: moment(workingDay.postDate, 'YYYY-MM-DD').format(Constants.API_DATE_FORMAT),
          periods: workingDay.periods.map((period) => {
            return {
              startTime: period.startTime,
              endTime: period.endTime
            }
          })
        }
      })
      officerArray.push(officer);
    };

    const request: JM.JMRequestFinanceSubmitTimesheetByJobCard = new JM.JMRequestFinanceSubmitTimesheetByJobCard();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.bufferTime = this.bufferTimeValue;
    request.version = this.timesheet.version;
    request.officers = officerArray;

    const response: JM.JMResponseFinanceSubmitTimesheetByJobCard = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code) {
      AppDelegate.openErrorBar(response);
      return;
    }

    switch (response.code) {
      case 200:
        this.openSnackBar(this.translateService.instant("pages.timesheet.saved"));
        break;
      case 19009: // Cannot update timesheet in CCS
      case 19010: // Partially saved 
        if (response.payload && response.payload.syncStatus && response.payload.syncStatus.failedCount) {
          this.openSnackBar(this.translate("pages.timesheet.error.fail-upload-timesheet", [response.payload.syncStatus.failedCount]));
        } else {
          this.openErrorBar(response);
        }
        break;
      default:
        this.openErrorBar(response);
    }
    if (response.payload && response.payload.data) {
      this.renderTimeSheet(response.payload.data)
    }
  }

  onKeyUpTime(event, postName: string, employeeCode: string, day: string, point: string) {
    // if input valid -> update slider display and form value
    let key = postName + "_" + employeeCode;

    // set not syn
    this.timesheetTooltip[key][day] = {
      show: true,
      message: this.translate('pages.timesheet.no-timesheet-tooltip')
    };

    if (point == 'start') {
      let startTime: string = this.staffTimeSheetDisplay[key][day].startTime; // in 12:45 format
      let hh_str = startTime.split(":")[0];
      let hh = parseInt(hh_str);
      let mm = Math.floor(parseInt(startTime.split(":")[1]) / 15.0) * 15;
      let mm_str = mm.toString().padStart(2, '0');

      if (Number.isNaN(hh) || hh < 0 || hh > 24) {
        hh = 0;
        hh_str = "00";
      }
      if (Number.isNaN(mm) || mm < 0 || mm > 60) {
        mm = 0;
        mm_str = "00";
      }

      let startMoment = moment(day + " " + hh_str + ":" + mm_str, "YYYY-MM-DD HH:mm");
      let endMoment = moment(day + " " + this.staffTimeSheetDisplay[key][day].endTime, "YYYY-MM-DD HH:mm");
      let earliestMoment = moment(this.jobCard.startTime).subtract(4, 'hour');
      earliestMoment.set({
        "minute": Math.floor(earliestMoment.minute() / 15.0) * 15
      });

      if (startMoment < earliestMoment) {
        let timeValue = earliestMoment.hour() + earliestMoment.minute() / 60.0;
        this.staffTimeSheetDisplay[key][day].startTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].startTime = timeValue;
        this.openSnackBar(this.translateService.instant("pages.timesheet.start-time-max"));

      } else if (startMoment > endMoment) {
        this.staffTimeSheetDisplay[key][day].startTime = this.staffTimeSheetDisplay[key][day].endTime;
        this.staffTimeSheet[key][day].startTime = this.staffTimeSheet[key][day].endTime;

      } else {
        let timeValue = hh + mm / 60.0;
        this.staffTimeSheetDisplay[key][day].startTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].startTime = timeValue;
      }

    } else if (point == 'end') {
      let endTime = this.staffTimeSheetDisplay[key][day].endTime;
      let hh_str = endTime.split(":")[0];
      let hh = parseInt(hh_str);
      let mm = Math.ceil(parseInt(endTime.split(":")[1]) / 15.0) * 15;
      let mm_str = mm.toString().padStart(2, '0');

      if (Number.isNaN(hh) || hh < 0 || hh > 24) {
        hh = 0;
        hh_str = "00";
      }
      if (Number.isNaN(mm) || mm < 0 || mm > 60) {
        mm = 0;
        mm_str = "00";
      }

      let startMoment = moment(day + " " + this.staffTimeSheetDisplay[key][day].startTime, "YYYY-MM-DD HH:mm");
      let endMoment = moment(day + " " + hh_str + ":" + mm_str, "YYYY-MM-DD HH:mm");
      let latestMoment = this.jobCard.completionTime ? moment(this.jobCard.completionTime).add(4, 'hour') : null;
      if (latestMoment) {
        latestMoment.set({
          minute: Math.ceil(latestMoment.minute() / 15.0) * 15
        });
      }

      if (latestMoment && endMoment > latestMoment) {   // the job may be not finish yet 
        let timeValue = latestMoment.hour() + latestMoment.minute() / 60.0;
        this.staffTimeSheetDisplay[key][day].endTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].endTime = timeValue;
        this.openSnackBar(this.translateService.instant("pages.timesheet.end-time-max"));

      } else if (endMoment < startMoment) {
        this.staffTimeSheetDisplay[key][day].endTime = this.staffTimeSheetDisplay[key][day].startTime;
        this.staffTimeSheet[key][day].endTime = this.staffTimeSheet[key][day].startTime;

      } else {
        let timeValue = hh + mm / 60.0;
        this.staffTimeSheetDisplay[key][day].endTime = this.valueToTimeFormat(timeValue);
        this.staffTimeSheet[key][day].endTime = timeValue;
      }
    }

    this.updatStaffTimeSheetForm(postName, employeeCode, day, point);
  }

  requestAssignStaffSubmit = (data) => {
    let request: JM.JMRequestJobCardsReassign = new JM.JMRequestJobCardsReassign();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.assignedPersons = data.assignedPersons;
    request.officerInCharge = data.officerInCharge;

    this.assignStaffPanel.isLoading = true;
    JM.JMConnector.sendJobCardsReassign(request, (error: JM.JMNetworkError, response: JM.JMResponseJobCardsReassign) => {
      this.assignStaffPanel.isLoading = false;
      if (error) {
        this.handleJMError(error);
        return;
      }

      if (!response || !response.code || response.code != 200 || !response.payload) {
        switch (response.code) {
          case 19009: // Cannot update timesheet in CCS
          case 19010: // Partially saved 
            this.assignStaffFormParam = {
              ...this.assignStaffFormParam,
              errorMsg: response.error ? this.translate("job-card-assign-staff-form.failed-remove-staff-msg") + response.error : this.openErrorBar(response)
            }
            return;
          default:
            this.openErrorBar(response);
            return;
        }
      }
      // get the timesheet and job card again
      this.requestJobCard().then(res => {
        this.requestTimesheet().finally(() => {
          this.isLoading = false;
          this.initActionButtons();
          this.initJobInfo();
        });
      }).catch(err => {
        this.isLoading = false;
      })
      this.assignStaffPanel.toggle();
    });
  }

  onAssignStaffSubmitClicked = (data) => {
    this.assignStaffFormParam.errorMsg = undefined
    this.requestAssignStaffSubmit(data);

  }

  // TODO: reload language
  onLanguageChanged() {

  }



  //===========================================================================
  // custom function

  // convert time in decmial to string time format
  // eg: 13.75 => 13:45
  private valueToTimeFormat(value: number): string {
    return Math.floor(value).toString().padStart(2, '0') + ":" + ((value % 1) * 60).toString().padStart(2, '0');
  }

  private getWeekdayTranslate(date): string {
    let weekday = '';
    switch (moment(date).weekday()) {
      case 0:
        weekday = 'global.sunday'
        break;
      case 1:
        weekday = 'global.monday'
        break;
      case 2:
        weekday = 'global.tuesday'
        break;
      case 3:
        weekday = 'global.wednesday'
        break;
      case 4:
        weekday = 'global.thursday'
        break;
      case 5:
        weekday = 'global.friday'
        break;
      case 6:
        weekday = 'global.saturday'
        break;
      default:
        break;
    }
    return this.translate(weekday);
  }

  isClosedDate(date) {
    return false;
    // let month =  moment(date, "YYYY-MM-DD").format("YYYYMM");
    // let thisMonth = moment(new Date()).utc().add(8, 'hour').format("YYYYMM");

    // return month < thisMonth;
  }

  isDisabledTaskNumber(workingDays) {
    if (!workingDays || !workingDays.length) {
      return true;
    }

    for (let date of workingDays) {
      if (!this.isClosedDate(date)) {
        return false;
      }
    }

    return true;
  }

  openResetPopup(officer: JM.JMOBJ.JobTimesheetOfficer, workingDay: JM.JMOBJ.JobTimesheetWorkDay) {
    if (workingDay.periods.length) {
      AppDelegate.showPopUpAlert(JMLanguage.translate('action.button.popup.reset'), null, [
        { name: JMLanguage.translate('global.yes'), handler: () => this.resetRecordByDate(officer, workingDay) },
        { name: JMLanguage.translate('global.no') }
      ]);
    }
  }

  resetRecordByDate(officer: JM.JMOBJ.JobTimesheetOfficer, workingDay: JM.JMOBJ.JobTimesheetWorkDay) {
    const initOfficer = this.getOfficerById(this.initTimeSheet, officer.post, officer.personnelNumber)
    workingDay.periods = [];
    workingDay['isPeriodsUpdated'] = this.getWorkingDayByPostDate(initOfficer.workingDays , workingDay.postDate).periods.length;
    workingDay['totalHours'] = 0;
    this.countChargeableHours();
  }

  searchTimesheet() {
    if (this.searchWord !== "" && this.searchWord !== undefined && this.searchWord !== null) {
      let isNoRecord = true;
      this.timesheetForUI.officers.map((officer: JM.JMOBJ.JobTimesheetOfficer) => {
        if ((officer.postName && officer.postName.toLocaleLowerCase().includes(this.searchWord.toLocaleLowerCase())) ||
          (officer.employeeName && officer.employeeName.toLocaleLowerCase().includes(this.searchWord.toLocaleLowerCase()))) {
          officer['isHide'] = false;
          isNoRecord = false;
        } else {
          officer['isHide'] = true;
        }
      });
      this.isNoRecord = isNoRecord;
    } else {
      this.renderFullOfficerList();
    }
  }

  renderFullOfficerList() {
    this.timesheetForUI.officers.map((officer: JM.JMOBJ.JobTimesheetOfficer) => {
      officer['isHide'] = false;
    });
    this.isNoRecord = !this.timesheetForUI.officers.length;
  }

  checkSearchEmpty() {
    if (this.searchWord === "" || this.searchWord === undefined || this.searchWord === null) {
      this.renderFullOfficerList();
    }
  }

  onTableScroll(args: any, tableHeader: HTMLElement) {
    if (tableHeader) {
      tableHeader.scrollLeft = args.target.scrollLeft;
    }
  }

}

function parseTimetoInt(timeString: string) {
  return parseInt(timeString.replace(":", ''))
}






