<div class="reset-password-page-wrap">
    <div class="page-container text-center px-5">
        <div class="reset-password-wrap text-left d-block d-lg-inline-block">
            <div class="reset-password-card px-4 py-3">
                <div class="underline row justify-content-between m-0 mb-2 pb-2 font-size-m">
                    <div class="col-6 p-0">
                      {{"global.resetPassword" | translate}}
                    </div>
                    <div class="col-6 text-right p-0">
                      {{ systemName }}
                    </div>
                  </div>
                  
                  <div class="resetPassword-form-wrap">
                    <form [formGroup]="resetPasswordForm">
                      <label class="gray-40 mb-0 font-size-xs" for="login_existing-password_inputbox">
                        {{'pages.user-profile.existing-password' | translate}}
                      </label>
                        <div class="input-group mb-2">
                          <input id="login_existing-password_inputbox" type="password" 
                            formControlName="existingPassword" 
                            maxlength="100"
                            class="form-control"
                            placeholder="{{'pages.user-profile.existing-password' | translate}}" 
                            aria-label="Existingpassword" 
                            aria-describedby="basic-addon1"
                          />
                        </div>
                        <label class="gray-40 mb-0 font-size-xs" for="login_new_password_inputbox">
                          {{'pages.user-profile.new-password' | translate}}
                        </label>
                        <div class="input-group mb-2">
                          <input id="login_new_password_inputbox" type="password" 
                            formControlName="newPassword" 
                            maxlength="100"
                            class="form-control"
                            placeholder="{{'pages.user-profile.new-password' | translate}}" 
                            aria-label="newPassword" 
                            aria-describedby="basic-addon1"
                          />
                        </div>
                        <label class="gray-40 mb-0 font-size-xs" for="login_confirm_password_inputbox">
                          {{'pages.user-profile.confirm-password' | translate}}
                        </label>
                        <div class="input-group mb-2">
                          <input id="login_confirm_password_inputbox" type="password" 
                            formControlName="confirmPassword" 
                            maxlength="100"
                            class="form-control"
                            placeholder="{{'pages.user-profile.confirm-password' | translate}}" 
                            aria-label="confirmPassword" 
                            aria-describedby="basic-addon1"
                          />
                        </div>
                    </form>
                    <div class="mt-2">
                        <app-submit-button
                        [id]="'resetPassword_update_button'"
                        [text]="'global.update'"
                        [buttonClass]="'thin theme-red'"
                        [iconClass]="'fa fa-check'"
                        [isDisabled]="isResettingPassword"
                        [isLoading]="isResettingPassword"
                        (onClick)="onUpdateUserPasswordClicked()" 
                        ></app-submit-button>
                        <!--<button id="resetPassword_update_button" 
                        class="thin theme-red"
                        [disabled]="isResettingPassword">
                            <div *ngIf="isLoggingIn" class="d-inline-block loading-spinner spinner-small">
                              {{ "global.loading" | translate }}
                            </div>
                            <ng-container *ngIf="!isResettingPassword">
                                <i class="fa fa-check" aria-hidden="true"></i>{{"global.update" | translate}}
                            </ng-container>
                        </button>-->
                      </div>
                      <div [ngClass]="{hidden: !showPasswordError}" class="mt-3">
                        <app-password-error-list #passwordErrorList >
                        </app-password-error-list>
                      </div>
                  </div>
            </div>
        </div>
    </div>
</div>
