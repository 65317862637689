<div id="custom-fields-form-wrap" class="custom-fields-form-wrap" [class.view-mode]="!parameters.isEditMode">

  <div id="custom-fields-header-wrap" *ngIf="parameters?.header"
    class="custom-fields-header-wrap border-bottom row mx-0 mb-4 justify-content-between align-items-center"
  >
    <ng-container *ngIf="!parameters.header.title">
      <div class="header-title col-auto" *ngIf="!parameters.isEditMode">
        {{ userInput[parameters.header.id] }}
      </div>
      <div class="header-title col px-0" [class.d-none]="!parameters.isEditMode" >
        <input [type]="InputFieldType.text" class="form-control" [ngClass]="parameters.header?.customInputClass" id="headerTitle-input" 
          [(ngModel)]="userInput[parameters.header.id]" [disabled]="parameters.header?.disabled" (change)="parameters.header.onChange(); onChangeFields();"
          [class.input-error]="(parameters.header.mandatory && !userInput[parameters.header.id] && parameters.showFieldsError) || (parameters.header.isError && parameters.showFieldsError)"
        />
      </div>
    </ng-container>
    <ng-container *ngIf="parameters.header.title">
      <div class="header-title col-auto" [ngClass]="parameters.header?.customClass">
        {{ parameters.header.title }}
      </div>
    </ng-container>

    <div class="header-btn-wrap col-auto" *ngIf="parameters.headerBtn" [class.d-none]="parameters.header?.hideBtn">
      <button *ngFor="let button of parameters.headerBtn; let b = index" class="btn"
        [ngClass]="button?.customClass" [type]="button?.type" [id]=" 'header-btn-' + button.id" 
        [disabled]="button?.disabled" (click)="$event.stopPropagation();button.onClick()" 
      >
        <i *ngIf="button.icon" [ngClass]="button.icon" [id]=" 'header-btn-' + button.id+ '-icon' "></i>
        <span [id]=" 'header-btn-' + button.id + '-text'" class="header-btn-text">{{ button.text }}</span>
      </button>
    </div>
  </div>

  <div id="custom-fields-body-wrap" *ngIf="parameters.fieldList" class="custom-fields-body-wrap pb-4 row mx-0">
    <form class="custom-field-body" *ngIf="parameters.fieldList" [ngClass]="styleForm">
      <div *ngIf="parameters.instruction" [ngClass]="styleCustomFieldRow" >
        {{ parameters.instruction }}
      </div>
      <ng-container *ngFor="let field of parameters.fieldList; let f = index">
        <div class="custom-field-row justify-content-center align-items-center" 
          [ngClass]="styleCustomFieldRow +' '+ parameters?.fieldRowClass"
          *ngIf="!field.disable"
        > 
          
          <div class="field-title" [ngClass]="field?.titleCustomClass +' '+ styleFieldTitle">
            <span class="field-title-text">{{ field.name }}</span>
            <span class="field-tip ml-2" *ngIf="field.showTip" [ngbTooltip]="fieldToolTip" placement="bottom">
              <i class="fas fa-question-circle"></i>
              <ng-template #fieldToolTip>
                <div class="warning-tooltip-wrap tooltip-inner text-center large-container">
                  {{ field.tooltipMsg }}
                </div>
              </ng-template>
            </span> 
          </div>

          <!-- Edit Mode -->
          <div class="field-input-wrap" [class.input-error]="(field.type == InputFieldType.radio && field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.type == InputFieldType.radio && field.isError && parameters.showFieldsError)" *ngIf="parameters.isEditMode" [ngClass]="styleFieldInputWrap">
            <ng-container [ngSwitch]="field.type">
              <input *ngSwitchDefault [type]="field.type" class="form-control" [id]="field.id+'-input'" [ngClass]="field?.inputCustomClass" [name]="field.id"
                [(ngModel)]="userInput[field.id]" [disabled]="field?.disabled" [placeholder]="field?.placeholder" 
                [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                (change)="field.onChange(); onChangeFields();" 
              />

              <ng-container *ngSwitchCase="InputFieldType.number">
                <input [type]="field.type" class="form-control" [id]="field.id+'-input'" [ngClass]="field?.inputCustomClass" [name]="field.id"
                  [(ngModel)]="userInput[field.id]" [disabled]="field?.disabled" [placeholder]="field?.placeholder" [min]="field?.min" [max]="field?.max" [step]="field?.step"
                  [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                  (change)="field.onChange(); onChangeFields();" 
                />
              </ng-container>
              
              <ng-container *ngSwitchCase="InputFieldType.checkbox">
                <div class="custom-checkbox position-relative custom-control d-inline-block mr-2" *ngFor="let option of parameters.fieldOptions[field.id]; let o = index">
                  <input type="checkbox" [id]="option.id+'-checkbox'" class="checkbox custom-control-input p-0 " [name]="option.id" [value]="option.key"
                    [(ngModel)]="userInput[field.id][option.key]" [disabled]="option?.disabled" (change)="field.onChange(); onChangeFields();" 
                    [class.input-error]="(field.mandatory && !userInput[field.id][option.key] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                  />
                  <label [for]="option.id+'-checkbox'" class="custom-control-label mb-0">
                    {{ option.label }}
                  </label>
                </div>
              </ng-container>

              <ng-container  *ngSwitchCase="InputFieldType.radio">
                <div class="custom-control custom-radio d-inline-block mr-2" [ngClass]="field?.customClass" *ngFor="let option of parameters.fieldOptions[field.id]; let o = index">
                  <input type="radio" class="custom-control-input mr-1" [id]="option.id+'-radio'" [name]="field.id" [value]="option.value" 
                    [(ngModel)]="userInput[field.id]" [disabled]="option?.disabled" 
                    (change)="field.onChange(); onChangeFields();" 
                  />
                  <!-- [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)" -->
                  
                  <label class="custom-control-label" [for]="option.id+'-radio'">
                    {{ option.label }}
                  </label> 
                </div>
              </ng-container>

              <ng-container  *ngSwitchCase="InputFieldType.dropdown">
                <ng-select [id]="field.id+'-dropdown'" class="" [multiple]="field.multiple" [closeOnSelect]="true" [name]="field.id"
                  [items]="parameters.fieldOptions[field.id]" bindLabel="label" bindValue="value" [(ngModel)]="userInput[field.id]"
                  [disabled]="field?.disabled" [clearable]="field?.clearable" [placeholder]="field?.placeholder" 
                  [closeOnSelect]="field.closeOnSelect" [addTag]="field?.addTag" [loading]="field?.isLoading"
                  (change)="field.onChange(); onChangeFields();" (scrollToEnd)="field.scrollToEnd()" (search)="onDropdownSearch($event, f)"
                  (clear)="clearField(field.id); field.onClear($event); "
                  [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                >
                </ng-select>
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.date">
                <div class="position-relative calendar-input-wrap">
                  <input type="text" [id]="field.id+'-datepicker'" class="form-control date-picker-input-box "
                    [class.input-error]="(field.mandatory && !dateInputList[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                    [placeholder]="field?.placeholder" [name]="field.id" [maxDate]="field?.maxDate" [minDate]="field?.minDate"
                    [(ngModel)]="dateInputList[field.id]" (ngModelChange)="onChangeDate(field.id); field.onChange(); onChangeFields()" 
                    ngbDatepicker #datePicker="ngbDatepicker" autocomplete="off" (click)="datePicker.toggle()"  
                  >
                  <button class="btn calendar" type="button" (click)="datePicker.toggle()" >
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </ng-container>

            </ng-container>
          </div>

          <!-- View Mode -->
          <ng-container [ngSwitch]="field.type">
            <div class="field-input-wrap view-input auto-add-seperate" [class.d-none]="parameters.isEditMode"
              [ngClass]="styleFieldInputWrap"
            >
              <ng-container  *ngSwitchDefault >
                {{ userInput[field.id] }}
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.checkbox">
                <ng-container *ngIf="field.showPlainText">
                  <ng-container *ngIf="parameters.fieldOptions[field.id] && userInput[field.id]">
                    <ng-container *ngFor="let option of parameters.fieldOptions[field.id]; let o = index"> 
                      <ng-container *ngIf="userInput[field.id][option.key]">
                        <span class="view-checkbox seperate-item">{{ option.label }}</span>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!field.showPlainText">
                  <ng-container *ngIf="parameters.fieldOptions[field.id] && userInput[field.id]">
                    <div class="custom-checkbox position-relative custom-control d-inline-block mr-2 view-disabled-input" *ngFor="let option of parameters.fieldOptions[field.id]; let o = index">
                      <input type="checkbox" disabled class="checkbox custom-control-input p-0 " [name]="'view-'+option.id" [value]="option.key"
                        [(ngModel)]="userInput[field.id][option.key]"  
                      />
                      <label class="custom-control-label mb-0">
                        {{ option.label }}
                      </label>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.radio">
                <ng-container *ngIf="field.showPlainText">
                  <ng-container *ngFor="let option of parameters.fieldOptions[field.id]">
                    <ng-container *ngIf="option.value == userInput[field.id]" >
                      {{ option.label }}
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!field.showPlainText">
                  <div class="custom-control custom-radio d-inline-block mr-2 view-disabled-input" [ngClass]="field?.customClass" *ngFor="let option of parameters.fieldOptions[field.id]; let o = index">
                    <input type="radio" disabled class="custom-control-input mr-1" [name]="'view-'+field.id" [value]="option.value" 
                      [(ngModel)]="userInput[field.id]"
                    />
                    <label class="custom-control-label">
                      {{ option.label }}
                    </label>
                  </div>
                </ng-container>
              </ng-container>
              
              <ng-container  *ngSwitchCase="InputFieldType.dropdown">
                <ng-container *ngFor="let option of parameters.fieldOptions[field.id]">
                  <ng-container *ngIf="!field.multiple && option.value == userInput[field.id]">
                      {{ option.label }}
                  </ng-container>
                </ng-container>
              
                <ng-container *ngIf="field.multiple">
                  <ng-container *ngFor="let input of userInput[field.id]; let i = index">
                    <ng-container *ngFor="let option of parameters.fieldOptions[field.id]">
                      <ng-container *ngIf="option.value == input[i]" >
                        <span class="view-checkbox">{{ option.label }}</span>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.date">
                {{userInput[field.id]}}
              </ng-container>
              

            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>

    <div class="col-lg-auto col-12 align-self-center mt-lg-0 mt-4 text-right" *ngIf="isShowOnChangeBtn && parameters.isEditMode"
      [ngClass]="styleOnChangeBtn"
    >
      <button class="btn on-change-btn-text" [id]="button.id" *ngFor="let button of parameters.onChangeBtn" 
        [ngClass]="button?.customClass" [disabled]="button?.disabled" (click)="$event.stopPropagation(); button.onClick()"
      >
        <i [ngClass]="button?.icon" class="on-change-btn-icon"></i>
        <span class="on-change-btn-text">{{ button.text }}</span>
      </button>
    </div>
  </div>
</div> 