import { Component, Injector, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ParameterService } from "@services/parameter.service";
import * as qs from "qs";
import { BasePage } from "src/app/ui/model/base/base";
import { Session } from 'src/app/services/session';
import { Constants, RoutePaths } from "src/constants";
import { environment } from 'src/environments/environment';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from "src/app/AppDelegate";
import { JMLanguage, Language } from 'src/lib/JMLanguage/JMLanguage';
@Component({
  selector: "login",
  templateUrl: "./login.html",
  styleUrls: ["./login.scss"]
})
export class LoginComponent extends BasePage implements OnInit {

  // view variable
  isLoggingIn: boolean = false;
  targetRoute: string;

  systemName: string = Constants.SYSTEM_DISPLAY_NAME;
  systemVersion: string = Constants.SYSTEM_VERSION;
  supportContactInformation: any = null;
  showPassword: boolean = false;
  rememberMe: boolean = false;
  latestNewsMessage: string;
  latestNewsCreationTime: Date;

  get portalLoginSrc(): string {
    return JMLanguage.getCurrentLanguage() === Language.EN ? "/assets/img/portal_login_en.png" : "/assets/img/portal_login_zh.png";
  }

  uiIOSDownloadLink: string = environment.IOS_DOWNLOAD_LINK;
  uiAndroidDownloadLink: string = environment.ANDROID_DOWNLOAD_LINK;
  ccepPortalHost: string = environment.CCEP_PORTAL_HOST;
  ccepPortalSsoKey: string = environment.CCEP_PORTAL_SSO_KEY;
  ccepJmWebHost: string = environment.CCEP_JM_WEB_HOST;

  loginForm: FormGroup;

  private parameterService: ParameterService;

  languageOptions: {
    value: string;
    label: string;
  }[] = [
      {
        value: Language.EN,
        label: 'English',
      },
      { value: Language.ZH, label: '中文' },
    ];
  uiSelectedLanguage: Language;

  constructor(private injector: Injector, private formBuilder: FormBuilder) {
    super(injector, false);
    this.parameterService = injector.get(ParameterService);
  }

  ngOnInit() {
    const { successUrl } = this.route.snapshot.queryParams;
    successUrl && (this.targetRoute = successUrl);

    if (Session.userToken) {
      if (!Session.userInfo.isPasswordExpired) {
        let targetUrl = this.targetRoute || RoutePaths.DASHBOARD;
        AppDelegate.navigate([targetUrl], {
          replaceUrl: true,
        });
      }
    }

    this.supportContactInformation = this.parameterService.getSupportContact();
    this.rememberMe = Session.rememberMe;
    this.initForm();
    this.getLatestNews();

    document.addEventListener('mouseup', this.onMouseUp);

    this.uiSelectedLanguage = JMLanguage.getCurrentLanguage()
  }

  ngOnDestroy() {
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  onMouseUp = (args: any): void => {
    if (this.showPassword) {
      this.onShowPasswordBtnClicked();
    }
  }

  //Initial Information Function
  initForm(): void {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });

    if (Session.rememberMe && Session.rememberUsername) {
      // this.loginForm.controls.username.setValue(Session.rememberUsername); // conflict with Chrome cache login info behavior
    }
  }

  async getLatestNews() {
    const request = new JM.JMRequestSystemConfigsLatestNews();
    request.appname = Constants.SYSTEM_NAME;
    request.platform = "web";
    request.pageSize = 1;
    request.pageNumber = 1;

    const response: JM.JMResponseSystemConfigsLatestNews = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.latestNewsMessage = '';
      return;
    }

    if (Array.isArray(response.payload.records) && response.payload.records.length > 0) {
      this.latestNewsMessage = response.payload.records[0].message;
      this.latestNewsCreationTime = response.payload.records[0].createdAt;
    }
  }

  get loginFormData() {
    return this.loginForm.controls;
  }

  isValid(control: any): boolean {
    return control.dirty && control.errors;
  }

  onClickSsoLogin() {
    const param = {
      key: this.ccepPortalSsoKey,
      successUrl: `${this.ccepJmWebHost}/ccep-portal/loginSuccess`,
      failureUrl: `${this.ccepJmWebHost}/ccep-portal/loginFailure`,
    }
    const queryStr = qs.stringify(param);
    window.location.assign(`${this.ccepPortalHost}/sso?${queryStr}`);
  }

  private getExtraSecond(second: number): string {

    if (Math.floor(second % 60) === 0) {
      return ''
    }

    if (Math.floor(second % 60) > 1 && JMLanguage.getCurrentLanguage() === Language.EN) {
      return Math.floor(second % 60) + JMLanguage.translate("global.second") + "s"
    } else {
      return Math.floor(second % 60) + JMLanguage.translate("global.second")
    }

  }

  private getMinute(second: number): string {

    if (Math.floor(second / 60) === 0) {
      return ''
    }

    if (Math.floor(second / 60) > 1 && JMLanguage.getCurrentLanguage() === Language.EN) {
      return Math.floor(second / 60) + JMLanguage.translate("global.minute") + "s"
    } else {
      return Math.floor(second / 60) + JMLanguage.translate("global.minute")
    }

  }

  async onSubmit() {
    if (!this.loginForm.valid) {
      (<any>Object).values(this.loginFormData).forEach(control => {
        control.markAsDirty();
      });
      return;
    }

    this.isLoggingIn = true;

    const request = new JM.JMRequestAuthLoginV2();
    request.password = this.loginFormData.password.value;
    request.postName = this.loginFormData.username.value;
    request.systemName = Constants.SYSTEM_NAME;

    const response: JM.JMResponseAuthLoginV2 = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {

      switch (response.code) {
        case SpecialResponseCode.EXPIRED_PASSWORD:
          this.isLoggingIn = false;
          if (response.payload.post) {
            JM.JMConnector.setPost(response.payload.post);
            JM.JMConnector.setToken(response.payload.token);
            JM.JMConnector.cacheClear();

            Session.setUserInfo(response.payload.post);
            Session.setUserToken(response.payload.token);
            Session.setAuthSource(JMENUM.SourceSystem.CCEPJM);
            Session.setRememberMe(this.rememberMe);
            Session.setRememberUsername(this.rememberMe ? response.payload.post.name : null);

            AppDelegate.navigate(['/reset/password']);

            return;
          } else {
            AppDelegate.openErrorBar(response.error);
            return;
          }
        case SpecialResponseCode.SUSPENDED_ACCOUNT:
          this.isLoggingIn = false;

          if (response.payload == undefined || response.payload.accountLockSeconds == undefined || isNaN(response.payload.accountLockSeconds) || response.payload.accountLockSeconds <= 0) {
            AppDelegate.openErrorBar(response);
          } else {
            AppDelegate.openSnackBar(JMLanguage.translate('pages.login.error.suspend', [this.getMinute(response.payload.accountLockSeconds) + (this.getMinute(response.payload.accountLockSeconds) != '' && this.getExtraSecond(response.payload.accountLockSeconds) != '' && JMLanguage.getCurrentLanguage() === Language.EN ? ' ' : '') + this.getExtraSecond(response.payload.accountLockSeconds)]));
          }

          return;
        default:
          this.isLoggingIn = false;
          AppDelegate.openErrorBar(response);

          JM.JMConnector.setPost(null);
          JM.JMConnector.setToken(null);
          // get cache when login
          JM.JMConnector.cacheClear();
          return;
      }
    }
    JM.JMConnector.cacheClear();

    if (response.payload.post && response.payload.post.authorizations) {
      JM.JMConnector.setPost(response.payload.post);
      JM.JMConnector.setToken(response.payload.token);

      Session.setUserInfo(response.payload.post);
      Session.setUserToken(response.payload.token);
      Session.setAuthSource(JMENUM.SourceSystem.CCEPJM);
      Session.setRememberMe(this.rememberMe);
      Session.setRememberUsername(this.rememberMe ? response.payload.post.name : null);
      Session.setPmPeriodSavedSearchCriteria(null);

      // save cash
      JM.JMConnector.cacheEquipmentCategory().then((ecat) => {
        Session.setEquipmentCategoryDict(ecat);
      });
      JM.JMConnector.cacheEquipmentType().then((etype) => {
        Session.setEquipmentTypeDict(etype);
      });
      JM.JMConnector.cacheAllClient().then((allClient) => {
        Session.setClientDict(allClient);
      });
      JM.JMConnector.cacheAllWorkCentre().then((allWorkCentre) => {
        Session.setWorkCentreDict(allWorkCentre);
      });
      JM.JMConnector.cacheManualInstruction().then((allMI) => {
        Session.setManualInstructionArray(allMI);
      });
      //
      JM.JMConnector.cachCategoryObj().then((allCategoryObj) => {
        Session.setCategoryObj(allCategoryObj);
      });
      JM.JMConnector.cacheClassificationObj().then((allClassificationObj) => {
        Session.setClassificationObj(allClassificationObj);
      });
      const requestStorageLocationList = new JM.JMRequestInventoryGetStorageLocationList();
      requestStorageLocationList.isAiStore = true;
      AppDelegate.sendJMRequest(requestStorageLocationList).then((res) => {
        Session.setStorageLocationAiList(res.payload.storageLocationList);
      });
      const requestCostCenters = new JM.JMRequestWorkCentresGetCostCentres();
      AppDelegate.sendJMRequest(requestCostCenters).then((res) => {
        Session.setCostCentreList(res.payload.costCentres);
      });

      const request = new JM.JMRequestContactPersonsUpdateContactPerson();
      request.post = Session.userInfo.name;
      request.languagePreference = this.uiSelectedLanguage;
      AppDelegate.sendJMRequest(request);

      const apiToWaitList: Promise<any>[] = [];
      // get feature list
      const requestSystemFeatureList = new JM.JMRequestSystemConfigsGetSystemFeatureList();
      requestSystemFeatureList.systemName = JMENUM.SourceSystem.CCEPJM;

      apiToWaitList.push(AppDelegate.sendJMRequest(requestSystemFeatureList))
      // check jm account review 
      const checkReviewRequest = new JM.JMRequestAuthHalfYearReviewCheckReviewStatus();
      apiToWaitList.push(AppDelegate.sendJMRequest(checkReviewRequest));

      apiToWaitList.push(JM.JMConnector.cacheAll());

      Promise.all(apiToWaitList).then(([res1, res2, res3]) => {
        res1.payload && Session.setFeatureList(res1.payload.records);
        res2.payload && Session.setIsEnableJMAccountReview(res2.payload.enabled);
        this.isLoggingIn = false;
        let targetUrl = this.targetRoute || RoutePaths.DASHBOARD;
        AppDelegate.navigate([targetUrl], {
          replaceUrl: true,
        });
      })

    } else {
      this.isLoggingIn = false;
      AppDelegate.openErrorBar(response);
    }
  }

  //=========================
  onShowPasswordBtnClicked() {
    this.showPassword = !this.showPassword;
  }

  onLanguageChanged() {
    JMLanguage.changeLanguage(this.uiSelectedLanguage);
    Session.setSelectedLanguage(this.uiSelectedLanguage);
    this.translateService.use(Session.selectedLanguage);
    window.location.href = window.location.href;
  }
}

export enum SpecialResponseCode {
  EXPIRED_PASSWORD = 6063,
  SUSPENDED_ACCOUNT = 6065,
}