import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { PostApprovalAddRequestRequest } from '@api/model/approval/post-approval-add-request-request';
import { ApprovalService } from '@services/approval.service';
import { Post } from 'src/app/entity/data-model/post';
import { Session } from 'src/app/services/session';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'app-request-window',
  templateUrl: './request-window.component.html',
  styleUrls: ['./request-window.component.scss']
})
export class RequestWindowComponent extends BasePage implements OnInit {

  @Output() onAddedNewRequest: EventEmitter<any> = new EventEmitter();

  selectedWorkCentre    = Session.selectedWorkCentre;
  approvalTeam          : any;
  approvalWorkCentre    : any;

  // ===========================================================================
  // request window params

  requestWindowGroups = [
    // { description: "Acting", value: "post"},
    { description: "", value: "team"},
  ];
  requestType       = this.requestWindowGroups[0].value;
  startDate         : any;
  endDate           : any;
  teams             : JMOBJ.Team[] = [];
  teamDisabled      : boolean = true;
  officers          : Post[] = [];
  officerDisabled   : boolean = true;
  loadingSubmit     : boolean = false;

  workCentreOptions = [];
  teamOptions       = [];

  // loading
  isLoadingWorkCentre = false;
  isLoadingTeam = false;

  constructor(
    injector: Injector,
    private approvalService: ApprovalService
  ) {
    super(injector)
  }


  // ===========================================================================
  // view life cycle functions
  ngOnInit() {
    this.workCentreOptions = JM.JMConnector.getAllWorkCentreCode().filter(c => c != this.selectedWorkCentre);    // cannot make reques to own work centre
    this.onLangChange();
  }

  onLangChange(){
    this.requestWindowGroups = [
      { description: this.translate("pages.authorization.request-team"), value: "team"},
    ];
  }

  // ===========================================================================
  // api function
   private requestTeams(workCenter?: string) {
    let request                         = new JM.JMRequestTeamsTeamSummary();
    request.workCentreList              = [workCenter];
    request.handlingPartyList           = [JMENUM.HandlingParty.INHOUSE];
    request.parameters                  = ['_id', 'name'];
    request.isRoTeam                    = false;
    request.includeApprovedWorkCentre   = false;
    request.pageSize                    = 1000;
    request.pageNumber                  = 1;

    this.isLoadingTeam = true;

    JM.JMConnector.sendTeamSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseTeamsTeamSummary) => {
      this.isLoadingTeam = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.teams = response.payload.records;
      this.teamOptions = this.teams;
    });
  }
  
  private requestApprovalAddRequest() {
    let request                 = {} as PostApprovalAddRequestRequest;
    request.requestWorkCentre   = this.selectedWorkCentre;
    request.approvalWorkCentres = [this.approvalWorkCentre];
    request.type                = this.requestType;
    request.from                = new Date();
    request.payload             = { type: request.type, teamId: this.approvalTeam, requestWorkCentre: this.selectedWorkCentre, approvalWorkCentre: this.approvalWorkCentre };

    this.loadingSubmit = true;
    this.apiHandler(this.approvalService.submitRequest(request), result => {
      this.loadingSubmit = false;
      if (!result || !result.code || result.code != 200) {
        this.openErrorBar(result);
        return;
      }

      this.openSnackBar(this.translate("pages.authorization.submit-message"));
      this.approvalWorkCentre = null;
      this.approvalTeam = null;
      this.teamDisabled = true;
      // Emit to parent for refresh tablex
      this.onAddedNewRequest.emit({ requestType: this.requestType });
    });
  }

  // ===========================================================================
  // button callback functions
  
  onSubmitBtnClicked() {
    if (!this.approvalWorkCentre) {
      this.openSnackBar(this.translate("pages.authorization.missing-work-centre-message"));
      return;
    } else if (!this.approvalTeam) {
      this.openSnackBar(this.translate("pages.authorization.missing-team-message"));
      return;
    }

    this.requestApprovalAddRequest();
  }

  onWorkCentreOptionChanged = () => {
    if (this.approvalWorkCentre === null) {
      this.teamDisabled = true;
      this.approvalTeam = null;
    } else {
      this.teamDisabled = false;
      this.approvalTeam = null;
      this.requestTeams(this.approvalWorkCentre);
    }
  }

  onRequestTypeChanged = () => {
    // TODO: if post -> hide team ng-select. if team -> hide post ng-select

  }

}
