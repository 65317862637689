
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';

export function formatContractNumber(contract: JMOBJ.MaintenanceTermContract): string {

    if (contract.type) {
        if (contract.type == JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT) {
            return '*' + contract.contractNumber;
        } else if (contract.type == JMENUM.MaintenanceContractType.DUMMY_CONTRACT) {
            return '^' + contract.contractNumber.slice(JM.JMConstant.DUMMY_CONTRACT_PREFIX.length);  // remove DMY_
        }
    }
    return contract.contractNumber;
}
export function formatTeamNameContractNumber(team: JMOBJ.Team): string {
    if (team.contractNumber && team.handlingParty !== JMENUM.HandlingParty.INHOUSE) {
        return team.name + ` (${team.contractNumber})`
    } else {
        return team.name;
    }
}

/*
    break down time format: YYYYMMDDHHmmsssss
    formatted datetime format: YYYY-MM-DD HH:mm:ss.sssZ
*/
export function formatBreakdownTimeToDateString(breakdownTime: string): string {
    if (breakdownTime.length == 17) {
        return breakdownTime.substr(0, 4) + "-" + breakdownTime.substr(4, 2) + "-" + breakdownTime.substr(6, 2) + " " + breakdownTime.substr(8, 2) + ":" + breakdownTime.substr(10, 2) + ":" + breakdownTime.substr(12, 2) + "." + breakdownTime.substr(14) + "Z";
    } else {
        return breakdownTime;
    }
}

export function stringToNgbDate(value: string | undefined): NgbDateStruct | undefined {
    if (value) {
        let date = moment(value);
        return {
            day: parseInt(date.day().toString()),
            month: parseInt(date.month().toString()),
            year: parseInt(date.year().toString())
        };
    }
    return undefined;
}

export function formatAddDashToDate(date:string):string {
    if (date) {
        return date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2);
    } else {
        return null;
    }
}

export function formatRemoveDashToDate(date:string):string {
    if (date) {
        return date.substr(0, 4) + date.substr(5, 2) + date.substr(8, 2);
    } else {
        return null;
    }
}