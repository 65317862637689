<div class="font-size-xl m-0 mb-2 px-2">
  <div class="overflow-hidden">
    <div class="d-inline-block">
      {{ "component.job-description.title" | translate}}
    </div>
    <div class="d-inline-block">
      <span id="job-desc-job-nature" class="maintenance-type mx-1 font-size-s" *ngIf="jobCard.jobNature">
        {{descriptionLabel}}
      </span>
      <span id="job-desc-handling-party" class="handling-party-type mx-1 font-size-s" *ngIf="jobCard.handlingParty">
        {{ "team.handling-party." + jobCard.handlingParty | translate}}
      </span>
    </div>
    <div class="overflow-hidden d-inline-block float-right">
      <app-display-text-field id="job-desc-status" [textField]="jobStatus" [emptyHandleDisplay]="emptyHandle"
          [attr.data-content]="jobCard.status">
      </app-display-text-field>
    </div>
  </div>
</div>
<hr class="underline" />
<ng-content select="[instruction]"></ng-content>
<div class="row align-items-center">
  <ng-container *ngIf="jobCard.handlingParty == JMHandlingParty.PMSMC; then pmsmcMode; else inhouseMode">
  </ng-container>
</div>

<!-- View -->
<ng-template #inhouseMode>
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="red">*</span>
    {{ "component.job-description.priority" | translate }}
  </div>
  <div *ngIf="!editable?.priority" class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-priority" [textField]="'jobcard.priority.' + priority | translate" [ngClass]="{'input-error':errorFields?.priority}">
    </app-display-text-field>
  </div>
  <div class="col-4 mb-2 " *ngIf="editable?.priority">
    <ng-select id="priority-dropdown" [items]="priorityOptions" bindLabel="displayName" bindValue="id" 
      [(ngModel)]="priority"  [ngClass]="{'input-error':errorFields?.priority}"
      (change)="assignInputToValue('priority', priority)">
    </ng-select>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="red">*</span>
    {{ "component.job-description.handled-by" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-handled-by" [textField]="teamName" >
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="red">*</span>
    {{ "component.job-description.job-type" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto" 
    *ngIf="!editable?.orderType" [ngClass]="{'input-error':errorFields?.orderType}">
    <app-display-text-field id="job-desc-job-type" [textField]="getDisplayJobType()">
    </app-display-text-field>
  </div>
  <div class="col-4 mb-2 " *ngIf="editable?.orderType">
    <ng-select id="job-type-dropdown" [items]="orderTypeOptions"
      [(ngModel)]="orderType" [ngClass]="{'input-error':errorFields?.orderType}"
      (change)="onChangeOrderType('orderType', orderType)">
    </ng-select>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.assigned-staff" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-assigned-staff" [textField]="assignedStaff">
    </app-display-text-field>
    <i class="fas fa-info-circle font-size-l blue cursor-pointer ml-2" (click)="componentParameters.onClickViewAssignStaff()" *ngIf="jobCard && jobCard.assignedPersons.length"></i>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.teco-status" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-teco-status" [textField]="tecoStatus" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>

  <!-- to-do manny  if jobCard.handlingParty ==1 -->
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="red">*</span>
    <ng-container *ngIf="jobCard.handlingParty != JMHandlingParty.INHOUSE">  
      {{ "component.job-description.activityCode" | translate }}</ng-container>
    <ng-container *ngIf="jobCard.handlingParty == JMHandlingParty.INHOUSE">  
      {{ "component.job-description.MAT" | translate }}</ng-container>
  </div>
  <div *ngIf="!editable.matType" class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-mat" [textField]="jobCard?.matType" [ngClass]="{'input-error':errorFields.matType}" [emptyHandleDisplay]="emptyHandle">
    </app-display-text-field>
  </div>
  <div class="col-4 mb-2 " *ngIf="editable.matType">
    <ng-select id="mat-dropdown" [items]="matOptions" bindLabel="description" bindValue="value"
      [(ngModel)]="matType" [ngClass]="{'input-error':errorFields.matType}"
      (change)="assignInputToValue('matType', matType)" ></ng-select>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.created-time" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-created-time" [textField]="jobCard.createdAt | dateTimeWeek ">
    </app-display-text-field>
  </div>
  
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.last-updated-time" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-updated-time" [textField]="jobCard.updatedAt | dateTimeWeek" >
    </app-display-text-field>
  </div>
</ng-template>

<ng-template #pmsmcMode>
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="red">*</span>
    {{ "component.job-description.priority" | translate }}
  </div>
  <div *ngIf="!editable?.priority" class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-priority" [textField]="'jobcard.priority.' + priority | translate" [ngClass]="{'input-error':errorFields?.priority}">
    </app-display-text-field>
  </div>
  <div class="col-4 mb-2 " *ngIf="editable?.priority">
    <ng-select id="priority-dropdown" [items]="priorityOptions" bindLabel="displayName" bindValue="id" 
      [(ngModel)]="priority"  [ngClass]="{'input-error':errorFields?.priority}"
      (change)="assignInputToValue('priority', priority)">
    </ng-select>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.contract-number" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-contract-number" [textField]="jobCard?.contract?.contractNumber" >
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.severity" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-severity" [textField]="jobCard?.contractSeverity?.name">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.liftTrapped" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-compliance-status"
      [translateKey]="jobCard?.liftTrapped?'global.yes':'global.no'"> 
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="red">*</span>
    {{ "component.job-description.job-type" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto" 
    *ngIf="!editable?.orderType" [ngClass]="{'input-error':errorFields?.orderType}">
    <app-display-text-field id="job-desc-job-type" [textField]="getDisplayJobType()">
    </app-display-text-field>
  </div>
  <div class="col-4 mb-2 " *ngIf="editable?.orderType">
    <ng-select id="job-type-dropdown" [items]="orderTypeOptions" bindLabel="description" bindValue="value"
      [(ngModel)]="orderType" [ngClass]="{'input-error':errorFields?.orderType}"
      (change)="onChangeOrderType('orderType', orderType)">
    </ng-select>
  </div>

  <!-- to-do manny  if jobCard.handlingParty ==1 -->
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="red">*</span> 
    <ng-container *ngIf="jobCard.handlingParty != JMHandlingParty.INHOUSE">  
      {{ "component.job-description.activityCode" | translate }}</ng-container>
    <ng-container *ngIf="jobCard.handlingParty == JMHandlingParty.INHOUSE"> 
      {{ "component.job-description.MAT" | translate }}</ng-container>
  </div>
  <div *ngIf="!editable.matType" class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-mat" [textField]="jobCard?.matType" [ngClass]="{'input-error':errorFields.matType}" [emptyHandleDisplay]="emptyHandle">
    </app-display-text-field>
  </div>
  <div class="col-4 mb-2 " *ngIf="editable.matType">
    <ng-select id="mat-dropdown" [items]="matOptions" bindLabel="description" bindValue="value"
      [(ngModel)]="matType" [ngClass]="{'input-error':errorFields.matType}"
      (change)="assignInputToValue('matType', matType)" ></ng-select>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.teco-status" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-teco-status" [textField]="tecoStatus" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.compliance-status" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-compliance-status" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.created-time" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-created-time" [textField]="jobCard.createdAt | dateTimeWeek ">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.job-description.last-updated-time" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="job-desc-updated-time" [textField]="jobCard.updatedAt | dateTimeWeek" >
    </app-display-text-field>
  </div>
</ng-template>
