import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-functional-item-list',
  templateUrl: './functional-item-list.component.html',
  styleUrls: ['./functional-item-list.component.scss']
})
export class FunctionalItemListComponent implements OnInit {

  @Input() parameters = {
    headerInput: {
      placeholder: '',
      onKeyUp: undefined,
      onChange: undefined,
    },
    listHeaderBtn: {},
    isHideHiddenContainer: false,
    hiddenContainerInput: {
      customClass: '',
      placeholder: '',
      onChange: undefined
    },
    hiddenContainerBtn: {},
    onScroll: undefined
  };
  @Input() userInput = {};
  @Input() listItem = [];

  @Output() submitClickListItem = new EventEmitter<any>();

  selectedItem: any;


  constructor() { }

  ngOnInit() {
    this.selectedItem = 0;
  }

  onClickHeaderBtn(){

  }

  onClickListItem(index){
    this.selectedItem = index;
    this.submitClickListItem.emit(index);
  }

}
