<div class="wrapper">
    <app-nav [navbarTitle]="'pages.un-teco.page-title' | translate" (languageChanged)="onLanguageChanged()">
    </app-nav>

    <app-sidebar [type]="'default'"></app-sidebar>

    <ng-container *ngIf="actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
            (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <div id="content" class="un-teco-content">
        <!-- component of Advanced Search -->
        <app-teco-advanced-search id="un-teco-advanced-search-panel" [parameters]="advancedSearchPara"  (onSubmit)="onAdvancedSearch($event)">
        </app-teco-advanced-search>

        <!-- tablex -->
        <app-tablex id="un-teco-table" [tablexParam]='tablexParam'></app-tablex>
    </div>

    <!-- silder panel -->
    <custom-slider-panel #unTecoReasonPanel [customClass]="'add_dummy_contract_panel'">
        <app-un-teco-reason #unTecoReasonForm [selectedCount]="selectedCount" [unTecoParam]="unTecoParam" (onSubmit)="onSubmit($event)"></app-un-teco-reason>
        <!-- [parameters]="addContractParameters"   [isLoading]="isPanelSubmitLoading"-->
    </custom-slider-panel>
</div>