
/* 
** Validator function for input checking
*/
export function minLengthValidator(minLength: number, errorMessage: string) {
  return (data) => {
    return (data.length >= minLength) ? "" : errorMessage;
  }
}

export function maxLengthValidator(maxLength: number, errorMessage: string) {
  return (data) => {
    return (data.length <= maxLength) ? "" : errorMessage;
  }
}

export function isInputEmptyValidator(errorMessage: string) {
  return (data) => {
    return (data && data.trim().length) > 0 ? "" : errorMessage
  }
}

export function isTimeStringValidator(errorMessage: string) {
  return (data) => {
    return data.trim().length == 0 || isTimeString(data) ? '' : errorMessage;
  }
}

export function isNumberValidator(errorMessage: string) {
  return (data) => {
    return isPhoneNumber(data) ? '' : errorMessage;
  }
}

export function isPhoneNumberValidator(errorMessage: string) {
  return (data) => {
    return data.trim().length == 0 || isPhoneNumber(data) ? '' : errorMessage;
  }
}

export function isEmailAdressValidator(errorMessage: string) {
  return (data) => {
    return data.trim().length == 0 || isEmailAdress(data) ? '' : errorMessage;
  }
}


/* 
** Value checking
*/
export function isTimeString(value: string) {
  let timeRegex = /^((([0-1][0-9])|([2][0-3]))\:([0-5][0-9]|60)|24:00|[\s]*)$/
  return value.match(timeRegex);
}

export function isNumber(value: string) {
  let numberRegex = /^(\d+)$/g
  return value.match(numberRegex);
}

export function isPhoneNumber(value: string) {
  let phoneNoRegex = /^(\d{1,8})$/g
  return value.match(phoneNoRegex);
}

export function isEmailAdress(value: string) {
  let emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+$/g
  return value.match(emailRegex);
}

export function isEmpty(fieldValue: any): boolean {
  if (typeof fieldValue === 'object' && fieldValue !== null) {
    console.warn('Check isEmpty cannot check "object"');
    return true;
  }
  if (Array.isArray(fieldValue)) {
    return fieldValue.length && isEmpty(fieldValue[0]);
  }
  if (typeof fieldValue === 'undefined' || fieldValue === null) return true;
  // check is not null or undefined then check by converting to string (accept 0 and boolean)
  return !fieldValue.toString().trim();
}
