import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { OrderType } from '@enum/order-type';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/entity/enum/tablexColumnType';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { CcsSoSubmitFormReturnParam } from '../../pages/jobcard-view/jobcard-view-const';

@Component({
  selector: 'ccs-so-submit-form',
  templateUrl: './ccs-so-submit-form.component.html',
  styleUrls: ['./ccs-so-submit-form.component.scss']
})

export class CcsSoSubmitFormComponent extends BasePage implements OnInit {
  @Input() parameters = {
    hasValue                 : false,
    sn                       : null,  // sn Object
    jobCard                  : null,  // jobCard Object
    isLoading                : false, // for ui control
    displayCcsErrorMessage   : null,
  };
  @Output() onSubmit = new EventEmitter<any>();

  constructor(
    injector: Injector,
  ) {
    super(injector)
  }

  // UI Enum
  SnEquipmentOwnerEnum = JMENUM.SnEquipmentOwner;
  OrderTypeEnum        = JMENUM.OrderType;

  // UI control
  submitDisabled   : boolean = true;
  isLoadingCcsSo   : boolean = false;
  isLoadingSla     : boolean = false;
  isLoadingSlaLoc: boolean = false;
  isFetchedHsdCcsEquipmentNumber : boolean = false;

  // option
  ccsSoOption           : 'assign' | 'obtainByEquipment' | 'obtainByLocation';
  equipmentOptionType   : 'equipmentTag' | 'workCentre' | 'all' = 'equipmentTag';
  locationOptionType    : 'equipmentTag' | 'workCentre' | 'all' = 'equipmentTag';
  slaNumberOptions      : any = [];
  slaItemOptions        : any = [];
  slaNumberOptionsLoc: any = [];
  slaItemOptionsLoc: any = [];
  ccsSoNumberOptions    : any;

  // sla map
  slaDetailMap : any = {};
  slaItemDetailMap : any = {};

  // date source
  sn            : JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  jobCard       : JMOBJ.JobCard =  new JMOBJ.JobCard();
  equipmentSlaInfo: any;   // Object include agreementNumber, itemNumber
  locationSlaInfo: any;   // Object include agreementNumber, itemNumber
  equipmentsList: any[]; // Object include equipmentNumber, description, location, locationDescription, clientShortName
  locationList  : any[]; // Object include location, locationDescription, clientShortName

  // for HA SN using
  ccsEquipmentNumber = null; // get HA equipment number to search equipment number

  // ngModel
  displayLocationDescription: string;
  selectedLocationCode      : string | null;    // for obtain by location
  selectedEquipment         : string | null;    // equipmentNumber: for obtain by equipment
  selectedSlaItem           : any;
  selectedSlaNumber         : any;
  selectedSlaNumberLoc: any;
  selectedSlaNumberClientLoc: any;
  selectedSlaItemLoc: any;
  selectedCcsSoNumber       : any;
  ccsAcknowledge            : boolean;
  displayErrorMessage       : string[] = [];

  // equipment tablex
  equipmentTablexFilter        = null;
  equipmentTablexParam         : {} = {};
  equipmentCurrentPageSize     = 10;
  equipmentCurrentPage         = 1;
  equipmentPageCount           = 1;
  equipmentUiTotalCount        = 0;

  // location tablex
  allLocationColHeaders = [];
  selectedLocationColId = [];
  locationTablexFilter        = null;
  locationTablexParam         : {} = {};
  locationCurrentPageSize     = 10;
  locationCurrentPage         = 1;
  locationPageCount           = 1;
  locationUiTotalCount        = 0;

  ngOnInit() {
    this.lang = Session.selectedLanguage;
  }
  
  async ngOnChanges() {
    if (!this.parameters.hasValue) {
      return;
    }

    if (this.parameters.sn)      { this.sn = this.parameters.sn; }
    if (this.parameters.jobCard) { this.jobCard = this.parameters.jobCard; }

    // when open generate CCS SO slider panel
    this.initUi();
    if (this.sn.eamData && this.sn.eamData.assetNumber) {
      await this.requestHsdEquipment();
      this.isFetchedHsdCcsEquipmentNumber = true;
      this.validateForm();
    }

    if (this.jobCard.jobNature != JMENUM.JobNature.STANDALONE) {
      this.requestLocationDescription();
      this.requestServiceOrderNumberList();
    }
    this.initEquipmentTable();
    this.initLocationTable();
  }

  private initUi() {
    this.ccsSoOption = 'obtainByEquipment';
    this.ccsAcknowledge = false;
    
    if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE || this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA) {
      this.equipmentOptionType = 'all';
      this.locationOptionType = 'all';
    } else {
      if (!this.sn.hashtagId) {
        this.equipmentOptionType = 'workCentre';
        this.locationOptionType = 'workCentre';
      }
    }
    this.validateForm();

    // table parameter
    this.equipmentCurrentPage = 1;
    this.locationCurrentPage = 1;
  }

  ngOnDestroy() { }

  // ============== API ==============
  private async requestLocationDescription() {
    if (!this.sn.location) return;

    const request = new JM.JMRequestLocationsLocationSummary();
    request.location = [this.sn.location];

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (!response.payload.records || !response.payload.records.length) return;
    let location = response.payload.records[0];
    let description = location.description[this.lang] ? location.description[this.lang] : location.description['en'];
    this.displayLocationDescription = `${description} (${this.sn.location})`;
  }

  private async requestEquipment() {
    let request        = new JM.JMRequestEquipmentsEquipmentSummary();
    request.parameters = ["equipmentNumber", "description", "clientShortName", "location", "locationDescription"];
    request.pageNumber = this.equipmentCurrentPage;
    request.pageSize   = this.equipmentCurrentPageSize;
    request.filter     = {};

    // check equipment option type
    switch (this.equipmentOptionType) {
      case 'equipmentTag':
        request.active     = JMENUM.RequestActive.ACTIVE;
        request.workCentre = [this.sn.workCentre];

        // if ZS01 job, find cm equipment only
        if (this.jobCard.orderType === JMENUM.OrderType.SLA_JOB) { 
          request.maintenanceType = this.jobCard.jobNature === JMENUM.JobNature.PM ? "pm" : "cm"; 
        }
        if (this.sn.hashtagId) { request.tagId = [this.sn.hashtagId]; }
        break;
      case 'workCentre':
        request.active     = JMENUM.RequestActive.ACTIVE;
        request.workCentre = [this.sn.workCentre];

        // if ZS01 job, find cm equipment only
        if (this.jobCard.orderType === JMENUM.OrderType.SLA_JOB) { 
          request.maintenanceType = this.jobCard.jobNature === JMENUM.JobNature.PM ? "pm" : "cm"; 
        }
        break;
      case 'all':
        request.active     = JMENUM.RequestActive.BOTH;
        break;
      default:
        break;
    }

    if (this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA && this.ccsEquipmentNumber) { // for HA SN and has ccs equipment number only
      request.equipmentNumber = [this.ccsEquipmentNumber];
    } else {
      // add filter to request
      if (this.equipmentTablexFilter) {
        for (let key in this.equipmentTablexFilter) {
          if (this.equipmentTablexFilter[key]) {
            switch (key) {
              case 'equipmentNumber':
              case 'description':
              case 'location':
              case 'clientShortName':
                request.filter[key] = this.equipmentTablexFilter[key];
                break;
              case 'locationDescription':
                request.locationDescription = this.equipmentTablexFilter[key]
                break;
            }
          }
        }
      }
    }

    this.equipmentUiTotalCount = 0;
    this.equipmentsList = [];
    this.equipmentTablexParam['isLoadingTable'] = true;
    this.unselectEquipment();
    
    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.equipmentTablexParam['isLoadingTable'] = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.equipmentUiTotalCount = response.payload.totalCount;
    this.equipmentsList = response.payload.records;
    this.renderEquipmentTable();

    if (this.ccsEquipmentNumber && response.payload.records.length === 1 && response.payload.records[0].equipmentNumber === this.ccsEquipmentNumber) {
      this.equipmentTablexParam['selectedRowIndex'] = 0;
      this.selectedEquipment = this.ccsEquipmentNumber;
      this.onSelectEquipment();
    }
  }

  private async requestSlaDetail(slaInfo) {
    if (!slaInfo || !slaInfo.length) { return; }

    let agreementNumbers: string[] = Array.from(new Set(slaInfo.map(item => item.agreementNumber)));
    if (!agreementNumbers || !agreementNumbers.length) { return; }

    let request: JM.JMRequestEquipmentSLASummary = new JM.JMRequestEquipmentSLASummary();
    request.agreementNumber = agreementNumbers;
    request.parameters = ["agreementNumber", "description", "clientNumber"];
    request.includeExpired = true;

    const response: JM.JMResponseEquipmentSLASummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    for (let sla of response.payload.records) {
      this.slaDetailMap[sla.agreementNumber] = sla;
    }

    switch (this.ccsSoOption) {
      case 'obtainByEquipment':
        this.setEquipmentSlaOptions();
        break;
      case 'obtainByLocation':
        this.setLocationSlaOptions();
        break;
    }
  }

  private async requestEquipmentSlaOptions() {
    const request = new JM.JMRequestEquipmentsSLAItemCoverageByEquipment();
    request.equipmentNumber = [this.selectedEquipment];
    request.jobNature = this.jobCard.jobNature as JMENUM.JobNature;
    request.matType = this.jobCard.matType;

    if (this.jobCard.startTime) {
      request.effectiveStartDate = this.jobCard.startTime;
    }

    this.resetEquipmentSla();
    this.isLoadingSla = true;
    const response: JM.JMResponseEquipmentsSLAItemCoverageByEquipment = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || response.error) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.isLoadingSla = false;
    
    if (response.payload.totalCount > 0) {
      this.equipmentSlaInfo = response.payload.records;
      
      this.requestSlaDetail(this.equipmentSlaInfo);

      this.setEquipmentSlaOptions();
      this.setEquipmentSlaItemOptions();
    }
    this.validateForm();
  }

  private async requestLocationSlaOptions() {
    const request = new JM.JMRequestLocationsSLAItemCoverageByLocation();
    request.location = [this.selectedLocationCode];
    request.jobNature = this.jobCard.jobNature as JMENUM.JobNature;
    request.matType = this.jobCard.matType;

    if (this.jobCard.startTime) {
      request.effectiveStartDate = this.jobCard.startTime;
    }

    this.resetLocationSla();
    this.isLoadingSlaLoc = true;
    const response: JM.JMResponseLocationsSLAItemCoverageByLocation = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || response.error) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.isLoadingSlaLoc = false;
    
    if (response.payload.totalCount > 0) {
      this.locationSlaInfo = response.payload.records;
      
      this.requestSlaDetail(this.locationSlaInfo);

      this.setLocationSlaOptions();
      this.setLocationSlaItemOptions();  
    }
    this.validateForm();
  }

  private requestLocation() {
    // check location option type
    switch (this.locationOptionType) {
      case 'equipmentTag':
      case 'workCentre':
        this.selectedLocationColId = ["functionalLocation", "locationDescription", "clientShortName"];
        this.requestEquipmentsParameters();
        break;
      case 'all':
        this.selectedLocationColId = ["functionalLocation", "locationDescription"];
        this.requestLocationSummary();
        break;
      default:
        break;
    }
  }

  private async requestEquipmentsParameters() {
    const request = new JM.JMRequestEquipmentsParameters();
    request.parameter = ["location", "functionalLocation", "locationDescription", "clientShortName"];
    request.pageNumber = this.locationCurrentPage;
    request.pageSize = this.locationCurrentPageSize;
    request.filter = {};

    // check location option type
    switch (this.locationOptionType) {
      case 'equipmentTag':
        request.active     = JMENUM.RequestActive.ACTIVE;
        request.workCentre = this.sn.workCentre;

        if (this.sn.hashtagId) { request.equipmentTags = [this.sn.hashtagId]; }
        break;
      case 'workCentre':
        request.active     = JMENUM.RequestActive.ACTIVE;
        request.workCentre = this.sn.workCentre;
        break;
      // case 'all':
      //   request.active     = JMENUM.RequestActive.BOTH;
      //   break;
      default:
        break;
    }

    if (this.locationTablexFilter) {
      for (let key in this.locationTablexFilter) {
        if (this.locationTablexFilter[key]) {
          switch (key) {
            case 'functionalLocation':
            case 'clientShortName':
              request.filter[key] = this.locationTablexFilter[key];
              break;
            case 'locationDescription':
              request.locationDescription = this.locationTablexFilter[key];
              break;
          }
        }
      }
    }

    this.locationUiTotalCount = 0;
    this.locationList = [];
    this.locationTablexParam['isLoadingTable'] = true;
    this.unselectLocation();
    
    const response: JM.JMResponseEquipmentsParameters = await AppDelegate.sendJMRequest(request);
    this.locationTablexParam['isLoadingTable'] = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.locationUiTotalCount = response.payload.totalCount;
    for (const item of response.payload.result) {
      // filter null location
      if (item._id['functionalLocation']) {
        let temp = {
          location           : item._id['functionalLocation'],
          locationDescription: "",
          clientShortName    : item._id.clientShortName
        }
        if (item._id.locationDescription) {
          temp.locationDescription = item._id.locationDescription[this.lang] ? item._id.locationDescription[this.lang] : item._id.locationDescription['en']
        }
        this.locationList.push(temp);
      }
    }
    
    this.renderLocationTable();
  }

  private async requestLocationSummary() {
    const request = new JM.JMRequestLocationsLocationSummary();
    request.parameters  = ["code", "description"];
    request.pageNumber = this.locationCurrentPage;
    request.pageSize   = this.locationCurrentPageSize;
    request.filter     = {};

    if (this.locationTablexFilter) {
      for (let key in this.locationTablexFilter) {
        if (this.locationTablexFilter[key]) {
          switch (key) {
            case 'functionalLocation':
              request.filter['code'] = this.locationTablexFilter[key];
              break;
            case 'locationDescription':
              request.locationDescription = this.locationTablexFilter[key];
              break;
          }
        }
      }
    }

    this.locationUiTotalCount = 0;
    this.locationList = [];
    this.locationTablexParam['isLoadingTable'] = true;
    this.unselectLocation();

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.locationTablexParam['isLoadingTable'] = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.locationUiTotalCount = response.payload.totalCount;
    for (const location of response.payload.records) {
      if (location.code) {
        let temp = {
          location: location.code,
          locationDescription: location.description[JMLanguage.getCurrentLanguage()],
          clientShortName: "",
        }
        this.locationList.push(temp);
      }
    }
    this.renderLocationTable();
  }

  private async requestServiceOrderNumberList() {
    const request = new JM.JMRequestJobCardsGetServiceOrderNumberList();
    request.workCentre = this.sn.workCentre;

    this.ccsSoNumberOptions  = [];
    this.selectedCcsSoNumber = null;
    this.isLoadingCcsSo      = true;

    const response: JM.JMResponseJobCardsGetServiceOrderNumberList = await AppDelegate.sendJMRequest(request);
    this.isLoadingCcsSo = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.ccsSoNumberOptions = response.payload;
  }

  private async requestHsdEquipment() {
    let request                = new JM.JMRequestEquipmentsHSDEquipmentSummary();
        request.assetNumber    = [this.sn.eamData.assetNumber];
        request.hasHAEquipment = true;
        request.parameters     = ['assetNumber', 'equipment.equipmentNumber']
        request.pageSize       = 1;
        request.pageNumber     = 1;
        request.sortBy         = 'assetNumber';


    let response: JM.JMResponseEquipmentsHSDEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (!response.payload.records || response.payload.records.length === 0 || 
      !response.payload.records[0].equipment || !response.payload.records[0].equipment.equipmentNumber) { 
      this.ccsSoOption = 'assign';
      return; 
    }

    this.ccsEquipmentNumber = response.payload.records[0].equipment.equipmentNumber;
  }

  // ========== ui event =============
  private resetEquipmentSla() {
    this.selectedSlaItem   = null;
    this.selectedSlaNumber = null;
    this.slaNumberOptions  = [];
    this.slaItemOptions    = [];
  }
  
  private setEquipmentSlaOptions() {
    let agreementNumbers = Array.from(new Set(this.equipmentSlaInfo.map(item => item.agreementNumber)));

    this.slaNumberOptions = agreementNumbers.map(agreementNumber => {
      let label = '' + agreementNumber;
      if (this.slaDetailMap['' + agreementNumber]) {
        label += ' - '  + this.slaDetailMap['' + agreementNumber].description;
      }

      return  { value: agreementNumber, label: label };
    });
    this.selectedSlaNumber = agreementNumbers[0];
  }

  private setEquipmentSlaItemOptions() {
    let selectedSlaItems = this.equipmentSlaInfo
      .filter(info => info.agreementNumber == this.selectedSlaNumber);

    this.slaItemOptions = selectedSlaItems.map(item => {
      let label = `${item.itemNumber}`;

      if (item.description) {
        label += ` - ${item.description}`;
      }

      return  { value: item.itemNumber, label: label };
    });

    if (!this.selectedSlaItem && this.slaItemOptions.length == 1) {
      this.selectedSlaItem = selectedSlaItems[0].itemNumber;
    }
  }


  onEquipmentOptionTypeChange() {
    this.clearEquipmentTable();
    this.requestEquipment();
  }

  onEquipmentSlaNumberChange() {
    if (this.selectedSlaNumber === null) {
      this.selectedSlaItem = null;
    } else {
      this.setEquipmentSlaItemOptions();
    }
  }

  private resetLocationSla() {
    this.selectedSlaNumberLoc = null;
    this.selectedSlaItemLoc   = null;
    this.slaNumberOptionsLoc  = [];
    this.slaItemOptionsLoc = [];
  }

  private setLocationSlaOptions() {
    const agreementNumbers = Array.from(new Set(this.locationSlaInfo.map(item => item.agreementNumber)));

    this.slaNumberOptionsLoc = agreementNumbers.map(agreementNumber => {
      let label = agreementNumber;
      const slaDetail = this.slaDetailMap[agreementNumber as string];
      if (slaDetail) {
        const clientShortName = this.getClientShortName(slaDetail.clientNumber);
        label = `${label} - ${slaDetail.description} [${clientShortName}]`;

        return  { value: agreementNumber, label: label, clientShortName: clientShortName };
      }

      return  { value: agreementNumber, label: label };
    });

    this.selectedSlaNumberLoc = agreementNumbers[0];
    this.selectedSlaNumberClientLoc = this.slaNumberOptionsLoc[0].clientShortName;
  }

  private setLocationSlaItemOptions() {
    let selectedSlaItems = this.locationSlaInfo
    .filter(info => info.agreementNumber == this.selectedSlaNumberLoc);

    this.slaItemOptionsLoc = selectedSlaItems.map(item => {
      let label = `${item.itemNumber}`;

      if (item.description) {
        label += ` - ${item.description}`;
      }

      return  { value: item.itemNumber, label: label };
    });

    if (!this.selectedSlaItemLoc && this.slaItemOptionsLoc.length == 1) {
      this.selectedSlaItemLoc = selectedSlaItems[0].itemNumber;
    }
  }

  onLocationOptionTypeChange() {
    this.clearLocationTable();
    this.requestLocation();
  }

  onLocationSlaNumberChange(model) {
    if (this.selectedSlaNumberLoc === null) {
      this.selectedSlaNumberClientLoc = null;
      this.selectedSlaItemLoc = null;
    } else {
      this.selectedSlaNumberClientLoc = model.clientShortName;
      this.setLocationSlaItemOptions();
    }
  }

  validateForm() {
    let isValid = false;
    let errorMessage: string[] = [];

    if (this.jobCard.jobNature != JMENUM.JobNature.STANDALONE && !this.sn.ccsSnNumber) {
      errorMessage.push(JMLanguage.translate('pages.sn.generate-ccs-so.error-msg.missing-ccs-sn-number'));
    }
    
    if (!this.jobCard.matType || !this.jobCard.orderType) {
      const translateStr = this.jobCard.handlingParty === JMENUM.HandlingParty.INHOUSE ? 'pages.sn.generate-ccs-so.error-msg.missing-job-type-and-mat' : 
       'pages.sn.generate-ccs-so.error-msg.missing-job-type-and-activity-code';
      errorMessage.push(JMLanguage.translate(translateStr));
    }

    // watch isFetchedHsdCcsEquipmentNumber to avoid error before API fetch
    if (this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA && !this.ccsEquipmentNumber && this.isFetchedHsdCcsEquipmentNumber) {
      let assetNumber = this.sn.eamData && this.sn.eamData.assetNumber ? `#${this.sn.eamData.assetNumber}` : '';
      errorMessage.push(JMLanguage.translate('pages.sn.generate-ccs-so.error-msg.no-ccs-equip-for-ha-asset', [assetNumber]));
    }
    
    switch (this.ccsSoOption) {
      case 'assign':
        isValid = this.selectedCcsSoNumber;
        break;
      case 'obtainByEquipment':
        if (this.jobCard.orderType === JMENUM.OrderType.SLA_JOB) { // ZS01
          isValid = this.selectedEquipment && this.selectedSlaNumber && this.selectedSlaItem;
        } else {
          isValid = this.selectedEquipment ? true : false;
        }
        break;
      case 'obtainByLocation':
        if (this.jobCard.orderType !== JMENUM.OrderType.SLA_JOB) { // ZS02, ZS04
          isValid = this.selectedLocationCode ? true : false;
        } else {
          isValid = this.selectedLocationCode && this.selectedSlaNumberLoc && this.selectedSlaItemLoc ? true : false;
        }
        break;
    }

    // contain any error
    if (errorMessage.length > 0) { isValid = false; }

    // check disclaimer
    if (!this.ccsAcknowledge) { isValid = false; }

    this.submitDisabled = !isValid;
    this.displayErrorMessage = errorMessage;
  }

  onClickSubmit() {
    let errorTranslates: string[] = null;

    let confirmHandle = () => {
      let param: CcsSoSubmitFormReturnParam = {
        ccsSoOption: this.ccsSoOption,
        data: {
          ccsServiceOrderNumber      : this.selectedCcsSoNumber ? this.selectedCcsSoNumber  : null,
          equipment                  : this.selectedEquipment ? this.selectedEquipment      : null,
          serviceLevelAgreementNumber: selectedSlaNumber ? selectedSlaNumber : null,
          serviceLevelAgreementItem  : selectedSlaItem ? selectedSlaItem : null,
          functionalLocation         : this.selectedLocationCode ? this.selectedLocationCode: null
        }
      };
      this.onSubmit.emit(param);
    }

    let selectedSlaNumber;
    let selectedSlaItem;
    // compare client and location to decide title
    switch (this.ccsSoOption) {
      case 'obtainByEquipment':
        selectedSlaNumber = this.selectedSlaNumber;
        selectedSlaItem = this.selectedSlaItem;
        let findEquipment = this.equipmentsList.find(item => item.equipmentNumber === this.selectedEquipment);
        if (findEquipment) {
          errorTranslates = this.checkClientAndLocation(findEquipment.clientShortName, findEquipment.location);
        }
        break;
      case 'obtainByLocation':
        selectedSlaNumber = this.selectedSlaNumberLoc;
        selectedSlaItem = this.selectedSlaItemLoc;
        let findLocation = this.locationList.find(item => item.location === this.selectedLocationCode);
        if (findLocation) {
          errorTranslates = this.checkClientAndLocation(findLocation.clientShortName, findLocation.location, this.selectedSlaNumberClientLoc);
        }
        break;
    }

    const titleTranslates = Array.isArray(errorTranslates) && errorTranslates.length > 0 ? errorTranslates : ['pages.sn.popup.confirm-submit-ccsso-number-title'];
    this.popupAlertBox(titleTranslates, confirmHandle);
  }

  private checkClientAndLocation(clientShortName: string, location: string, slaNumberClientShortName?: string): string[] {
    const errorTranslates = [];
    if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE) {
      const clientNotMatchJob = clientShortName && clientShortName !== this.jobCard.client;
      const locationNotMatchJob = !location.includes(this.jobCard.location);
      if (clientNotMatchJob && locationNotMatchJob) {
        errorTranslates.push("component.ccs-so-obtain.popup.jobcard.different-client-and-loaction-title");
      } else if (clientNotMatchJob) {
        errorTranslates.push("component.ccs-so-obtain.popup.jobcard.different-client-title");
      } else if (locationNotMatchJob) {
        errorTranslates.push("component.ccs-so-obtain.popup.jobcard.different-location-title");
      }
    } else {
      const clientNotMatchSn = clientShortName && clientShortName !== this.sn.client;
      const locationNotMatchSn = !location.includes(this.sn.location);
      if (clientNotMatchSn && locationNotMatchSn) {
        errorTranslates.push('pages.sn.popup.different-client-and-loaction-title');
      } else if (clientNotMatchSn) {
        errorTranslates.push('pages.sn.popup.different-client-title');
      } else if (locationNotMatchSn) {
        errorTranslates.push('pages.sn.popup.different-location-title');
      }
    }

    if (slaNumberClientShortName && this.jobCard.client && slaNumberClientShortName != this.jobCard.client) {
      errorTranslates.push("component.ccs-so-obtain.popup.jobcard.different-sla-client-title");
    }

    if (errorTranslates.length > 0) {
      errorTranslates.push("component.ccs-so-obtain.popup.confirm-submit");
    }
    
    return errorTranslates;
  }

  private popupAlertBox(titleTranslates: string[], yesHandler: Function) {
    let title = null;
    let titleArray = null;

    if (titleTranslates.length > 1) {
      titleArray = titleTranslates.map(titleTranslate => JMLanguage.translate(titleTranslate));
    } else if (titleTranslates.length == 1) {
      title = JMLanguage.translate(titleTranslates[0]);
    }

    this.showPopUpAlert(title, "",
    [
      {
        name: JMLanguage.translate("global.yes"),
        handler: () => yesHandler()
      },
      {
        name: JMLanguage.translate("global.no")
      }
    ], titleArray);
  }

  // ================= Equipment tablex ================
  initEquipmentTable() {
    this.equipmentTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : false,
      enablePagination      : true,
      tableRow              : "row",
      tableClass            : "sn-list-table",
      tableWrapperClass     : "table-min-width",
      pageSizeOptions       : [10, 25],
      currentPageSize       : this.equipmentCurrentPageSize,
      currentPage           : this.equipmentCurrentPage,
      pageCount             : this.equipmentPageCount,
      onPageNumberClicked   : this.onEquipmentPageNumberClicked,
      onPageSizeClicked     : this.onEquipmentPageSizeClicked,
      onFilterChanged       : this.onEquipmentFilterChanged,
      onRowClicked          : this.onEquipmentRowClicked,
      filterDebounceTime    : Constants.DEBOUNCE_TIME,
      headers: [ 
        {
          id: 'equipmentNumber',
          name: "pages.sn.generate-ccs-so.table-column-equipment-number",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col col-2 col-lg-2 p-1",
        },
        {
          id: 'description',
          name: "pages.sn.generate-ccs-so.table-column-description",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col col-3 col-lg-3 p-1",
        },
        {
          id: 'location',
          name: "pages.sn.generate-ccs-so.table-column-location-code",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col col-2 col-lg-2 p-1",
        },
        {
          id: 'locationDescription',
          name: "pages.sn.generate-ccs-so.table-column-location-description",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col col-3 col-lg-3 p-1",
        },
        {
          id: 'clientShortName',
          name: "pages.sn.generate-ccs-so.table-column-client",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col col-2 col-lg-2 p-1",
        }
      ]
    }

    if (this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA && this.ccsEquipmentNumber) {
      // Disable filter. Use ccsEquipmentNumber to get Equipment
      for (const headerObj of this.equipmentTablexParam['headers']) {
        headerObj.enableFilter = false;
      }
    } else {
      // The default value of “Client” filter and “Location” filter shall be pre-filled
      let filter = {
        location: this.sn.location,
        clientShortName: this.clientName,
      };
      this.equipmentTablexParam['filter'] = filter;
      this.equipmentTablexFilter = filter;
    }

    this.requestEquipment();
  }

  private renderEquipmentTable() {
    this.equipmentTablexParam['pageCount'] = Math.ceil(this.equipmentUiTotalCount/this.equipmentCurrentPageSize);
    this.equipmentTablexParam['content'] = [];
    this.equipmentsList.forEach(equipment => {
      if (!equipment.equipmentNumber) { return; }

      let locationDescription = '';
      if (equipment.locationDescription) {
        locationDescription = equipment.locationDescription[this.lang] ? equipment.locationDescription[this.lang] : equipment.locationDescription['en'];
      }

      this.equipmentTablexParam['content'].push([
        equipment.equipmentNumber,
        equipment.description,
        equipment.location,
        locationDescription,
        equipment.clientShortName
      ]);
    });
  }

  private clearEquipmentTable() {
    this.equipmentCurrentPage = 1;
    this.equipmentTablexParam['currentPage'] = 1;
    this.equipmentsList = [];
  }

  private onSelectEquipment() {
    if (this.selectedEquipment && this.jobCard.orderType === JMENUM.OrderType.SLA_JOB) {
      this.requestEquipmentSlaOptions();
    } else {
      this.resetEquipmentSla();
    }
  }

  private unselectEquipment() {
    this.selectedEquipment = null;
    this.equipmentTablexParam['selectedRowIndex'] = -1;
    this.resetEquipmentSla();
  }

  onEquipmentFilterChanged = (event, index, header, filter) => {
    this.equipmentCurrentPage  = 1;
    this.equipmentTablexParam['currentPage'] = 1;
    this.equipmentTablexFilter = filter;
    this.requestEquipment();
  }
  
  onEquipmentPageSizeClicked = (pageSize: number) => {
    if (this.equipmentCurrentPageSize !== pageSize) {
      this.equipmentCurrentPage = 1;
      this.equipmentTablexParam['currentPage'] = 1;
      this.equipmentCurrentPageSize = pageSize;
      this.requestEquipment();
    }
  }

  onEquipmentPageNumberClicked = (pageIndex: number) => {
    this.equipmentCurrentPage  = pageIndex;
    this.equipmentTablexParam['currentPage'] = pageIndex;
    this.requestEquipment();
  }

  onEquipmentRowClicked = (index, row: any) => {
    if (Array.isArray(row) && row.length > 0 && row[0] && this.selectedEquipment !== row[0]) { 
      this.selectedEquipment = row[0]; // 0: equipmentNumber column index
      this.validateForm();
      this.onSelectEquipment();
    }
  }

  // ================= Location tablex ================
  initLocationTable() {
    this.allLocationColHeaders = [
      {
        id: 'functionalLocation',
        name: "pages.sn.generate-ccs-so.table-column-functional-location",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-2 col-lg-2 p-1",
      },
      {
        id: 'locationDescription',
        name: "pages.sn.generate-ccs-so.table-column-location-description",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-8 col-lg-8 p-1",
      },
      {
        id: 'clientShortName',
        name: "pages.sn.generate-ccs-so.table-column-client",
        enableFilter: true,
        type: TablexColumnType.Text, 
        horizontalAlign: TablexColumnHorizontalAlign.Center, 
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-2 col-lg-2 p-1",
      }
    ];
    
    this.selectedLocationColId = ["functionalLocation", "locationDescription", "clientShortName"];
    this.locationTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : false,
      enablePagination      : true,
      tableRow              : "row",
      tableClass            : "sn-list-table",
      tableWrapperClass     : "table-min-width",
      pageSizeOptions       : [10, 25],
      currentPageSize       : this.locationCurrentPageSize,
      currentPage           : this.locationCurrentPage,
      pageCount             : this.locationPageCount,
      onPageNumberClicked   : this.onLocationPageNumberClicked,
      onPageSizeClicked     : this.onLocationPageSizeClicked,
      onFilterChanged       : this.onLocationFilterChanged,
      onRowClicked          : this.onLocationRowClicked,
      filterDebounceTime    : Constants.DEBOUNCE_TIME,
      headers: this.allLocationColHeaders,
    }

    // The default value of “Client” filter and “Location” filter shall be pre-filled
    this.locationTablexParam['filter'] = {
      functionalLocation: this.sn.location,
      clientShortName: this.clientName,
    };
    this.locationTablexFilter = this.locationTablexParam['filter'];

    this.requestLocation();
  }

  private renderLocationTable() {
    this.locationTablexParam['pageCount'] = Math.ceil(this.locationUiTotalCount / this.locationCurrentPageSize);
    this.locationTablexParam['headers'] = this.allLocationColHeaders.filter(col => {
      return this.selectedLocationColId.includes(col.id);
    });
    this.locationTablexParam['content'] = this.locationList.map(obj => {
      return [
        obj.location,
        obj.locationDescription,
        obj.clientShortName
      ];
    });
  }

  private clearLocationTable() {
    this.locationCurrentPage = 1;
    this.locationTablexParam['currentPage'] = 1;
    this.locationList = [];
  }

  private onSelectLocation() {
    if (this.selectedLocationCode && this.jobCard.orderType === JMENUM.OrderType.SLA_JOB) {
      this.requestLocationSlaOptions();
    } else {
      this.resetLocationSla();
    }
  }

  private unselectLocation() {
    this.selectedLocationCode = null;
    this.locationTablexParam['selectedRowIndex'] = -1;
    this.resetLocationSla();
  }

  onLocationFilterChanged = (event, index, header, filter) => {
    this.locationCurrentPage  = 1;
    this.locationTablexParam['currentPage'] = 1;
    this.locationTablexFilter = filter;
    this.requestLocation();
  }
  
  onLocationPageSizeClicked = (pageSize: number) => {
    if (this.locationCurrentPageSize !== pageSize) {
      this.locationCurrentPage  = 1;
      this.locationTablexParam['currentPage'] = 1;
      this.locationCurrentPageSize = pageSize;
      this.requestLocation();
    }
  }

  onLocationPageNumberClicked = (pageIndex: number) => {
    this.locationCurrentPage  = pageIndex;
    this.locationTablexParam['currentPage'] = pageIndex;
    this.requestLocation();
  }

  onLocationRowClicked = (index, row: any) => {
    if (Array.isArray(row) && row.length > 0 && row[0] && this.selectedLocationCode !== row[0]) { 
      this.selectedLocationCode = row[0]; // 0: location code column index 
      this.validateForm();
      this.onSelectLocation();
    }
  }

  getClientShortName(clientNumber: string) {
    for (let key in Session.clientDict) {
      if (Session.clientDict[key].clientNumber == clientNumber) {
        return key;
      }
    }
  }

  get clientName() {
    return this.jobCard.jobNature == JMENUM.JobNature.STANDALONE ? this.jobCard.client : this.sn.client;
  }

  get enableObtainSoByLocation() {
    // jobCard.orderType !== OrderTypeEnum.SLA_JOB && (!sn.eamData || !sn.eamData.assetNumber)
    if (this.sn.eamData && this.sn.eamData.assetNumber) return false;
    if (this.jobCard.orderType !== JMENUM.OrderType.SLA_JOB) return true;

    // if JMENUM.OrderType.SLA_JOB
    return [
      JMENUM.Mat.A01,
      JMENUM.Mat.M10,
      JMENUM.Mat.M20
    ].includes(this.jobCard.matType);
  }

  get enableObtainSoByEquipment() {
    return !this.sn.eamData || !this.sn.eamData.assetNumber || this.ccsEquipmentNumber;
  }
}
