import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';
import * as _ from 'underscore';

@Component({
  selector: 'app-action-sidebar',
  templateUrl: './action-sidebar.component.html',
  styleUrls: ['./action-sidebar.component.scss']
})
export class ActionSidebarComponent extends BasePage implements OnInit {
  @ViewChild('subMenu', { static: false }) subMenu;
  @Input() buttonData: any;
  @Input() disabled: boolean;
  @Output() clickEvent = new EventEmitter<any>();

  showSubButtonId = null;


  buttons: any;
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    document.addEventListener('click', this.offClickHandler); // bind on doc
  }

  ngOnChanges(): void {
    if (this.buttonData) {
      this.buttons = this.buttonData;
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.offClickHandler); 
  }

  offClickHandler = (event: any) => {
    if (this.showSubButtonId && this.subMenu && !this.subMenu.nativeElement.contains(event.target)) { // check click outside sub menu
      this.showSubButtonId = null;
    }
  }

  click(args: Event, button: any) {
    args.stopPropagation();
    if (button.subButtons && button.subButtons.length) {
      this.showSubButtonId = this.showSubButtonId === button.buttonId ? null : button.buttonId;
    } else {
      this.showSubButtonId = null; 
      this.clickEvent.emit(button);
    }
  }

  onScroll(args: Event) {
    this.showSubButtonId = null;
  }

  hideSubMenu() {
    this.showSubButtonId = null;
  }

}
