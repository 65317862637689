import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { ActionButtonApproval, ActionButtonDefinition, ActionButtonType } from '@enum/action-button';
import { ApprovalService } from '@services/approval.service';
import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { InformationDialogHelper } from 'src/app/ui/components/information-dialog/information-dialog-helper';
import { InformationDialogComponent } from 'src/app/ui/components/information-dialog/information-dialog.component';
import { SidebarItemInterface, SIDEBAR_ITEMS } from 'src/app/ui/components/sidebar/sidebar-items';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
import { columnsApproval, columnsHistory } from './approval-list.model';

enum TabOption {
  PENDING,
  HISTORY,
}
@Component({
  selector: 'app-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.scss']
})
export class ApprovalListComponent implements OnInit {

  user: JMOBJ.Post = undefined;
  // ngModel
  selectedApprovalList: JMOBJ.Approval[] = [];
  approvalList: JMOBJ.Approval[] = [];
  generalSearchWords: string;

  // action button related
  actionButtonData = [];
  disableActionSideBar = false;

  // sidebar
  sideBarItem: SidebarItemInterface[] = [];
  snJobApprovalCount: number = 0;
  pmPlanApprovalCount: number = 0;
  pmJobApprovalCount: number = 0;
  teamApprovalCount: number = 0;

  // Table
  tablexParamPending: {[x:string]:any} = {};
  tablexParamHistory: {[x:string]:any} = {};
  pageSizeOptions = [10, 20, 50];
  currentPageSize = 10;
  currentPageSizeHistory = 10;
  currentPage = 1;
  currentPageHistory = 1;
  pageCount = 1;
  pageCountHistory = 1;
  allColHeaders: any = [];
  selectedCol: any = [];
  tablexFilter: {} = {}; // useless

  // Tab
  activeTab: TabOption;
  tabOptions = [{
    value: TabOption.PENDING,
    label: JMLanguage.translate('pages.approval-pm-list.tab.pending-approval'),
  }, {
    value: TabOption.HISTORY,
    label: JMLanguage.translate('pages.approval-pm-list.tab.approval-history')
  }];

  constructor(
    private approvalService: ApprovalService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // TODO: fix isLoadingApi
    this.user = Session.userInfo;

    this.requestPmPlanApprovalCount();
    this.requestPmJobApprovalCount();
    const hasShowTeamApprovalPermission = JMUTILITY.hasPermissions(this.user, [JMENUM.Permission.REQUEST_APPROVE, JMENUM.Permission.REQUEST_REJECT], false);
    if (hasShowTeamApprovalPermission) {
      this.requestTeamApprovalCount();
    }

    this.initActionBar();
    this.initPendingTableHeader();
    this.initPendingTablex();
    this.initHistoryTablex();
    this.loadColumnSettingsFromLocal();
    this.initSearchBar();
    this.setActiveTab(this.tabOptions[0].value);
  }

  //==================================================================
  // API function
  private async requestApprovalPendingTable(pageNumber: number, pageSize: number) {
    const needApprovalDetail = true;

    this.isLoadingApi(true);
    let response = await this.approvalService.requestApprovalCmPending(pageNumber, pageSize, needApprovalDetail, this.generalSearchWords);
    this.isLoadingApi(false);

    if (response) {
      this.snJobApprovalCount = response.payload.totalCount;
      this.approvalList = response.payload.records;
      this.pageCount = Math.ceil(this.snJobApprovalCount / pageSize);
    } else {
      this.snJobApprovalCount = 0;
      this.approvalList = [];
      this.pageCount = 1;
    }

    this.tablexParamPending.currentPageSize = pageSize;
    this.tablexParamPending.currentPage = pageNumber;
    this.tablexParamPending.pageCount = this.pageCount;
    this.tablexParamPending.totalRowCount = this.snJobApprovalCount;

    this.renderPendingTable();
    this.updateSideBar();
  }

  private async requestApprovalHistoryTable(pageNumber: number, pageSize: number) {
    const needApprovalDetail = true;

    this.tablexParamHistory.isLoadingTable = true;
    let response = await this.approvalService.requestApprovalCmHistory(pageNumber, pageSize, needApprovalDetail, this.generalSearchWords);
    this.tablexParamHistory.isLoadingTable = false;

    let historyList = [];
    if (response) {
      const totalCount = response.payload.totalCount;
      this.pageCountHistory = Math.ceil(totalCount / pageSize);
      historyList = response.payload.records;
    } else {
      this.pageCountHistory = 1;
    }

    this.renderHistoryTable(historyList, pageNumber, this.pageCountHistory, pageSize);
  }

  private async requestPmPlanApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalPmPlanPending(minPageNumber, minPageSize, needApprovalDetail);

    this.pmPlanApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  private async requestPmJobApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestApprovalPmJobPending(minPageNumber, minPageSize, needApprovalDetail);

    this.pmJobApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  private async requestTeamApprovalCount() {
    const minPageNumber = 1, minPageSize = 1, needApprovalDetail = false;
    let response = await this.approvalService.requestRequestSummary(minPageNumber, minPageSize, needApprovalDetail);

    this.teamApprovalCount = response.payload.totalCount;
    this.updateSideBar();
  }

  private async requestApproveRequest() {
    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = this.selectedApprovalList.map(approval => {
      return {
        approvalAction: JMENUM.ApprovalAction.APPROVE,
        approvalId: approval._id,
      }
    });

    await this.handleBatchProcessRequest(request);
  }

  private async requestRejectRequest() {
    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = this.selectedApprovalList.map(approval => {
      return {
        approvalAction: JMENUM.ApprovalAction.REJECT,
        approvalId: approval._id,
      }
    });

    await this.handleBatchProcessRequest(request);
  }

  private async requestWithdrawRequest() {
    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = this.selectedApprovalList.map(approval => {
      return {
        approvalAction: JMENUM.ApprovalAction.WITHDRAW,
        approvalId: approval._id,
      }
    });
    
    await this.handleBatchProcessRequest(request);
  }

  private async handleBatchProcessRequest(request: JM.JMRequestApprovalsBatchProcessRequest) {
    this.isLoadingApi(true);
    const records = await this.approvalService.requestBatchProcessRequest(request);
    this.isLoadingApi(false);

    if (!records.length) { return; }

    const successCount = records.filter(r => r.success).length;
    const failCount = records.filter(r => !r.success).length;

    AppDelegate.openSnackBar(JMLanguage.translate('global.batch-process-request.result', [successCount, failCount]));
    this.currentPage = this.getLatestPageNumber(this.currentPageSize, this.currentPage, this.tablexParamPending['pageCount'], successCount);
    this.resetSelectRow();
    this.updateActionBar();
    this.requestApprovalPendingTable(this.currentPage, this.currentPageSize);
  }

  //==================================================================
  // Tablex function
  private initPendingTablex() {
    this.tablexParamPending = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      enableSelectedRowCount: true,
      enableSort: false,
      tableRow: 'row',
      tableClass: 'approval-pending-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: this.selectedApprovalList.length,
      totalRowCount: this.snJobApprovalCount,
      selectedColId: [
        'id',
        'description',
        'job-type',
        'contract-number',
        'requester',
        'approver',
        'start-date',
        'requested-date',
        'overall-kpi-status',
      ],
      fullColNameList: this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onPendingTablePageNumberClicked,
      onPageSizeClicked: this.onPendingTablePageSizeClicked,
      // onFilterChanged: this.onFilterChanged,
      onRowClicked: this.onApprovalRowClicked,
      onColFiltered: this.onColFiltered,
      // onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  private initHistoryTablex() {
    this.tablexParamHistory = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSort: false,
      tableRow: 'row',
      tableClass: 'approval-history-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSizeHistory,
      currentPage: this.currentPageHistory,
      pageCount: this.pageCountHistory,
      // selectedRowCount: 0,
      // totalRowCount: 0,
      // selectedColId: this.selectedColId,
      fullColNameList: columnsHistory.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onHistoryTablePageNumberClicked,
      onPageSizeClicked: this.onHistoryTablePageSizeClicked,
      // onFilterChanged: this.onFilterChanged,
      // onRowClicked: this.onApprovalRowClicked,
      // onColFiltered: this.onColFiltered,
      // onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: columnsHistory,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  public initPendingTableHeader() {
    this.allColHeaders = columnsApproval;
  }

  public renderPendingTable() {
    this.tablexParamPending.headers = this.allColHeaders.filter((col) => {
      return this.tablexParamPending.selectedColId.includes(col.id);
    });

    this.tablexParamPending.content = this.approvalList.map((data) => {
      const defaultString = '-';
      const showMoreButton = {
        type: 'buttons',
        buttons: [
          {
            'name': JMLanguage.translate('pages.approval-list.button.show-more'),
            'class': 'popup-link',
            'onClicked': this.onShowMoreApproversClicked,
            'object': data.approver.map(approver => { return { type: 'text', value: approver } }),
            'component': this
          }
        ]
      };
      let approver;
      if (data.approver.length) {
        if (data.approver.length > 2) {
          approver = [
            data.approver.slice(0, 2).join(', '),
            showMoreButton
          ];
        } else {
          approver = [data.approver.join(', ')];
        }
      } else {
        approver = [defaultString];
      }
      // const approver = data.approver.length ? data.approver.join(', ') : defaultString;
      const requestWorkCentre = data.attribute && data.attribute['workCentre'] ? data.attribute['workCentre'] : defaultString;
      const description = this.approvalService.getApprovalDescription(data, defaultString);
      const reason = data.requestRemark ? data.requestRemark : defaultString;
      const startDate = defaultString;
      const endDate = defaultString;
      const requestedDate = formatDateTimeWithWeek(data.createdAt);
      const cutoffDate = defaultString;

      let sn: JMOBJ.ServiceNotification;
      let jobCard: JMOBJ.JobCard;
      let jobType = defaultString;
      let contractNumber = defaultString;
      let overallKpiStatus = defaultString;

      if (data.type == JMENUM.ApprovalType.SN_ASSOCIATE) {
        // no SN Object
        // const customField = data.customField.snAssociate;
      } else if (data.type == JMENUM.ApprovalType.SN_CANCEL) {
        sn = data.customField.snCancel || null;
      } else if (data.type == JMENUM.ApprovalType.JOBCARD_COMPLETE) {
        jobCard = data.customField.jobCardComplete || null;
      }

      if (sn) {
        contractNumber = (sn.contract && sn.contract.contractNumber) || defaultString;
      } else if (jobCard) {
        jobType = jobCard.orderType || defaultString;
        contractNumber = (jobCard.contract && jobCard.contract.contractNumber) || defaultString;
        overallKpiStatus = this.approvalService.getOverallKpiStatus(jobCard, defaultString);
      }

      const row = [
        data.id,
        description,
        jobType,
        requestWorkCentre,
        contractNumber,
        data.requester,
        approver,
        reason,
        startDate,
        endDate,
        requestedDate,
        cutoffDate,
        overallKpiStatus,
      ];

      let rowFiltered = []
      row.forEach((value, i) => {
        if (this.tablexParamPending.selectedColId.includes(this.allColHeaders[i].id)) {
          rowFiltered.push(row[i]);
        }
      });

      return rowFiltered;
    });
  }

  public renderHistoryTable(approvalList: JM.JMOBJ.Approval[], currentPage: number, pageCount: number, pageSize: number) {
    this.tablexParamHistory.content = approvalList.map((data: JMOBJ.Approval) => {
      const defaultString = '-';
      const description = this.approvalService.getApprovalDescription(data, defaultString);
      const approveBy = data.updatedBy ? data.updatedBy : defaultString;
      const requestedDate = formatDateTimeWithWeek(data.createdAt);
      const actionType = data.status && typeof data.status === 'string' ? 
         data.status[0].toUpperCase() + data.status.slice(1) : defaultString;
      const updatedDate = formatDateTimeWithWeek(data.updatedAt);

      let sn: JMOBJ.ServiceNotification;
      let jobCard: JMOBJ.JobCard;
      let jobType = defaultString;
      let contractNumber = defaultString;
      // let overallKpiStatus = defaultString;

      if (data.type == JMENUM.ApprovalType.SN_ASSOCIATE) {
        // no SN Object
        // const customField = data.customField.snAssociate;
      } else if (data.type == JMENUM.ApprovalType.SN_CANCEL) {
        sn = data.customField.snCancel || null;
      } else if (data.type == JMENUM.ApprovalType.JOBCARD_COMPLETE) {
        jobCard = data.customField.jobCardComplete || null;
      }

      if (sn) {
        contractNumber = (sn.contract && sn.contract.contractNumber) || defaultString;
      } else if (jobCard) {
        jobType = jobCard.orderType || defaultString;
        contractNumber = (jobCard.contract && jobCard.contract.contractNumber) || defaultString;
      }

      return [
        data.id,
        description,
        jobType,
        contractNumber,
        data.requester,
        requestedDate,
        actionType,
        approveBy,
        updatedDate
      ];
    });

    this.tablexParamHistory.currentPageSize = pageSize;
    this.tablexParamHistory.currentPage = currentPage;
    this.tablexParamHistory.pageCount = pageCount;
  }

  onApprovalRowClicked = (index, row) => {
    const approvalId = row[0];
    const foundApproval = this.approvalList.find(approval => approval.id === approvalId);

    if (foundApproval) {
      const isIncludedSelectedList = this.selectedApprovalList.includes(foundApproval);
      if (isIncludedSelectedList) {
        this.tablexParamPending['highlightedRows'][index] = false;
        this.selectedApprovalList = this.selectedApprovalList.filter(approval => approval.id !== approvalId);
      } else {
        this.tablexParamPending['highlightedRows'][index] = true;
        this.selectedApprovalList.push(foundApproval);
      }
      this.tablexParamPending['selectedRowCount'] = this.selectedApprovalList.length;
      this.updateActionBar();
    }
  }

  private onPendingTablePageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetSelectedRowParam();
    this.requestApprovalPendingTable(this.currentPage, this.currentPageSize);
  }

  private onHistoryTablePageNumberClicked = (pageIndex: number) => {
    this.currentPageHistory = pageIndex;
    this.requestApprovalHistoryTable(this.currentPageHistory, this.currentPageSizeHistory);
  }

  private onPendingTablePageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetSelectedRowParam();
    this.requestApprovalPendingTable(this.currentPage, this.currentPageSize);
  }

  private onHistoryTablePageSizeClicked = (pageSize: number) => {
    this.currentPageHistory = 1;
    this.currentPageSizeHistory = pageSize;
    this.requestApprovalHistoryTable(this.currentPageHistory, this.currentPageSizeHistory);
  }

  private onColFiltered = (selectedColId) => {
    this.tablexParamPending.selectedColId = selectedColId;
    this.renderPendingTable();
    this.saveColumnSettingsToLocal();
  };

  private resetSelectRow() {
    this.selectedApprovalList = [];
    this.tablexParamPending['highlightedRows'] = [];
    this.tablexParamPending['selectedRowCount'] = 0;
  }

  // onFilterChanged = (event, index, header, filter) => {
  //   this.resetSelectRow();
  //   this.tablexFilter = filter;
  //   this.currentPage = 1;
  //   this.requestApprovalPendingTable();
  // }

  // onSortOrderChanged = (header, sortOrder) => {
  //   if (this.tablexParam['sortBy'] == header.id && this.tablexParam['sortOrder'] == sortOrder) {
  //     this.tablexParam['sortBy'] = null;
  //   } else if (this.tablexParam['sortBy'] != header.id) {
  //     this.tablexParam['sortBy'] = header.id;
  //     this.tablexParam['sortOrder'] = 1;
  //   } else {
  //     this.tablexParam['sortOrder'] = sortOrder;
  //   }

  //   this.requestApprovalPendingTable();
  // }

  getLatestPageNumber(currentPageSize: number, currentPageNumber: number, totalPageCount: number, deletedCount: number) {
    const isReducePageNumber = currentPageSize == deletedCount && currentPageNumber == totalPageCount && currentPageNumber != 1;
    return isReducePageNumber ? currentPageNumber - 1 : currentPageNumber;
  }

  public onShowMoreApproversClicked(button) {
    const component: ApprovalListComponent = button.component;
    const approvers: Object[] = button.object;
    const dialogTitle = JMLanguage.translate('pages.approval-pm-list.approver-full-list-dialog.title');
    component.dialog.open(InformationDialogComponent, InformationDialogHelper.createDialogData(dialogTitle, approvers));
  }

  //==================================================================
  // action button function
  initActionBar(): void {
    this.actionButtonData = [];

    this.addActionBtn(ActionButtonApproval.view);
    this.addActionBtn(ActionButtonApproval.approve);
    this.addActionBtn(ActionButtonApproval.reject);
    this.addActionBtn(ActionButtonApproval.withdraw);

    this.updateActionBar();
  }

  private addActionBtn(buttonStatus: ActionButtonApproval): void {
    const actionButton = ActionButtonDefinition[ActionButtonType.approval][buttonStatus];
    let buttonHandler = () => {};

    switch (buttonStatus) {
      case ActionButtonApproval.view:
        buttonHandler = () => {
          if (this.selectedApprovalList.length) {
            this.viewApprovalDetail(this.selectedApprovalList[0]);
          }
        };
        break;
      case ActionButtonApproval.approve:
        buttonHandler = () => {
          if (this.selectedApprovalList.length) {
            this.requestApproveRequest();
          }
        };
        break;
      case ActionButtonApproval.reject:
        buttonHandler = () => {
          if (this.selectedApprovalList.length) {
            this.requestRejectRequest();
          }
        };
        break;
      case ActionButtonApproval.withdraw:
        buttonHandler = () => {
          if (this.selectedApprovalList.length) {
            this.requestWithdrawRequest();
          }
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : 'global.yes',
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : 'global.no',
        handler: () => {}
      }
    ]

    this.actionButtonData.push(actionButton);
  }

  updateActionBar() {
    const hasRecord = this.selectedApprovalList.length > 0;
    const isSelectedOneRecord = this.selectedApprovalList.length === 1;
    const hasApprover = this.hasRoleInApprovalList(this.selectedApprovalList, 'approver');
    const hasRequester = this.hasRoleInApprovalList(this.selectedApprovalList, 'requester');
    const hasEditPermission = this.hasPermissionInApprovalList(this.selectedApprovalList, 'edit');

    const isEnableApproveButton = hasRecord && (hasApprover || hasEditPermission);
    const isEnableRejectButton = hasRecord && (hasApprover || hasEditPermission);
    const isEnableWithdrawButton = hasRecord && hasRequester;

    this.enableActionButton(ActionButtonApproval.view, isSelectedOneRecord);
    this.enableActionButton(ActionButtonApproval.approve, isEnableApproveButton);
    this.enableActionButton(ActionButtonApproval.reject, isEnableRejectButton);
    this.enableActionButton(ActionButtonApproval.withdraw, isEnableWithdrawButton);
  }

  private enableActionButton(button: ActionButtonApproval, isEnable: boolean = true) {
    ActionButtonDefinition[ActionButtonType.approval][button]['isEnable'] = isEnable;
  }

  private hasRoleInApprovalList(approvalList: JMOBJ.Approval[], type: 'approver' | 'requester') {
    const filteredApprovalList = approvalList.filter(approval => 
      approval[type] && approval[type].includes(this.user.name)
    );
    return (approvalList.length === filteredApprovalList.length);
  }

  private hasPermissionInApprovalList(approvalList: JMOBJ.Approval[], type: 'edit') {
    const filteredApprovalList = approvalList.filter(approval => {
      return approval.permission && approval.permission[type] && JMUTILITY.hasPermissions(this.user, approval.permission[type], false)
      // TODO: Approval flow enhancement - Add coverage permission
      // const hasActionPermission = approval.actionPermission && approval.actionPermission[type] && this.authorizationService.hasPermissions(approval.actionPermission[type], false);
      // const hasCoveragePermission = approval.coveragePermission && approval.coveragePermission[type] && this.authorizationService.hasPermissions(approval.coveragePermission[type], false);
      // return hasActionPermission && hasCoveragePermission;
    });
    return (approvalList.length === filteredApprovalList.length);
  }

  public onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      const buttons = actionButton.buttons;
      
      for (const button of buttons) {
        button.name = JMLanguage.translate(button.name);
      }
      AppDelegate.showPopUpAlert(JMLanguage.translate(actionButton.popupTitle), '', buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  //==================================================================
  // UI function
  private isLoadingApi(isLoading: boolean) {
    this.tablexParamPending['isLoadingTable'] = isLoading;
    this.disableActionSideBar = isLoading;
  }

  private resetSelectedRowParam() {
    this.selectedApprovalList = [];
    this.tablexParamPending.highlightedRows = [];
    this.tablexParamPending.selectedRowCount = this.selectedApprovalList.length;
    this.updateActionBar();
  }

  private updateSideBar() {
    let tempItem = JSON.parse(JSON.stringify(SIDEBAR_ITEMS.approval));
    tempItem = tempItem.filter(item => JMUTILITY.hasPermissions(this.user, item.permission, false));
    
    this.sideBarItem = tempItem.map(item => {
      item.subItems = item.subItems.filter(cell => JMUTILITY.hasPermissions(this.user, cell.permission, false));
      
      for (const subItem of item.subItems) {
        if (subItem.id === 'approval-sn-job') {
          subItem.title = JMLanguage.translate(subItem.title, [this.snJobApprovalCount]);
        }
        if (subItem.id === 'approval-pm-plan') {
          subItem.title = JMLanguage.translate(subItem.title, [this.pmPlanApprovalCount]);
        }
        if (subItem.id === 'approval-pm-job') {
          subItem.title = JMLanguage.translate(subItem.title, [this.pmJobApprovalCount]);
        }
        if (subItem.id === 'approval-team') {
          subItem.title = JMLanguage.translate(subItem.title, [this.teamApprovalCount]);
        }
      }
      return item;
    });
  }

  // open a new tab to view 
  private viewApprovalDetail(data: JMOBJ.Approval) {
    let link: string; 

    switch (data.type) {
      case JMENUM.ApprovalType.SN_CANCEL: {
        const customField = data.customField.snCancel;
        if (customField && customField.snNumber) {
          link = `/sn/view/${customField.snNumber}`;
        }
        break;
      }
      case JMENUM.ApprovalType.SN_ASSOCIATE: {
        const customField = data.customField.snAssociate;
        if (customField && customField.childSnNumberList && customField.childSnNumberList.length) {
          link = `/sn/view/${customField.childSnNumberList[0]}`;
        }
        break;
      }
      case JMENUM.ApprovalType.JOBCARD_COMPLETE: {
        const customField = data.customField.jobCardComplete;
        if (customField && customField.snNumber && customField.jobCardNumber) {
          link = `/job-card/view/${customField.jobCardNumber}`;
        }
        break;
      }
    }

    if (link) {
      window.open(link, '_blank');
    }
  }

  //==================================================================
  // cache function
  private saveColumnSettingsToLocal = () : void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.tablexParamPending.selectedColId));
      Session.setApprovalCmListSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }
  private loadColumnSettingsFromLocal = () : void => {
    try {
      let cache = Session.approvalCmListSettingsColumns;
      if (cache) {
        this.tablexParamPending.selectedColId = JSON.parse(JSON.stringify(cache))
      }
    } catch (e) {
      console.warn(e);
    }
  }

  //=======================================================================================================================
  // Search bar event
  onGeneralSearchClicked() {
    this.resetTable();
  }

  checkGeneralSearchEmpty() {
    if (this.generalSearchWords.length <= 0) {
      this.resetTable();
    }
  }

  onGeneralSearchKeyup = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.resetTable();
    }
  }

  private initSearchBar() {
    this.generalSearchWords = null;
  }

  //=======================================================================================================================
  // Tab event
  onClickedActiveTab = (tab: TabOption) => {
    this.setActiveTab(tab);
  }
  
  setActiveTab(tab: TabOption) {
    this.activeTab = tab;
    this.resetTable();
  }

  private resetTable() {
    this.resetSelectedRowParam();

    switch (this.activeTab) {
      case TabOption.PENDING:
        this.currentPage = 1;
        this.currentPageSize = this.pageSizeOptions[0];
        this.requestApprovalPendingTable(this.currentPage, this.currentPageSize);
        break;
      case TabOption.HISTORY:
        this.currentPageHistory = 1;
        this.currentPageSizeHistory = this.pageSizeOptions[0];
        this.requestApprovalHistoryTable(this.currentPageHistory, this.currentPageSizeHistory);
        break;
    }
  }

  //=======================================================================================================================
  // getter
  get isPendingTab(): boolean { return this.activeTab == TabOption.PENDING; }
  get isHistoryTab(): boolean { return this.activeTab == TabOption.HISTORY; }

  get generalSerachPlaceholder(): string {
    return JMLanguage.translate('pages.approval-list.search-box.placeholder');
  }

}
