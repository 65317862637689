import { Subject } from 'rxjs';
import { Constants } from 'src/constants';

export class InputControl {
    isDisabled: boolean = false;
    isError: boolean = false;
    isMandatory: boolean = false;
  
    constructor() {}
  }
  
export class DropdownControl extends InputControl {
    isLoading: boolean = false;
  
    constructor() {
      super();
    }
}

export class DropdownSearch {
    keywords: string = '';
    search$: Subject<any[]> = new Subject<any[]>();
    totalPageNumber: number = 0;
    pageNumber: number = 1;
    pageSize: number = 100;
  
    constructor() {}
}
  