<div class="wrapper">
    <app-nav [navbarTitle]="'side-bar.ccep-jm-account-review' | translate"></app-nav>

    <app-sidebar></app-sidebar>

    <!-- Page Content -->
    <div id="content">
        <div class="title">{{ 'pages.ccep-jm-account-review.email-history.title' | translate }}</div>
        <div class="table-container">
            <app-tablex [tablexParam]="tablexParam"></app-tablex>
        </div>
    </div>
</div>