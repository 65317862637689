//TODO: https://netbasal.com/create-a-custom-select-component-in-angular-complete-with-virtual-scrolling-c29e24f72006

import { Component, Injector, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ActionButtonDefinition, ActionButtonSn, ActionButtonType } from '@enum/action-button';
import { Permission } from '@enum/permission';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { isEmailVaild } from 'src/app/presenter/check-format-tool';
import { Subject, Observable } from 'rxjs';
import { debounceTime, startWith, map } from 'rxjs/operators';
import { InformationDialogHelper } from 'src/app/ui/components/information-dialog/information-dialog-helper';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
// import axios from 'axios';
import { JM, JMENUM, JMOBJ, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { Session }  from 'src/app/services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { saveAs } from "file-saver";
import { AppDelegate } from 'src/app/AppDelegate';
import { isEnabledFeature } from 'src/app/services/utility';
import { LocationService } from 'src/app/shared/location.service';

@Component({
  selector: 'app-sn-create',
  templateUrl: './sn-create.component.html',
  styleUrls: ['./sn-create.component.scss']
})
export class SnCreateComponent extends BasePage implements OnInit {
  @ViewChild("manual_instruction_form_panel", { static: true }) manualInstructionFormPanel;
  @ViewChild("manual_instruction_list_panel", { static: true }) manualInstructionListPanel;
  @ViewChild("ha_equipment_list_panel", { static: false }) haEquipmentListPanel;
  @ViewChild("ha_equipment_list_form", { static: false }) haEquipmentListForm;
  @ViewChild('anchor', { static: false }) anchor: ElementRef;
  @ViewChild("routing_choices_table", { static: true }) routingChoicesTable;
  /*
  ************************************************************************************

    Remarks:
    Terms - "Routing Choice" mean the relationship between 1 team and 1 routing rule
                            (each option in the table)

  ************************************************************************************
  */

  // default value const
  DEFAULT_PRIORITY = JMENUM.JMPriority.NonUrgent;
  MAX_CONTACT_NUMBER_COUNT = 3;
  HA_EQUIPMENT_NUMBER_OPTION_PAGE_SIZE = 10;


  mode: "new"|"edit"|"reroute" = "new"; // edit mode support draft SN only

  // Filter Options
  isClientOptionUpdated: boolean = false;
  isClientDropdownLoading: boolean = false;
  clientsOptions: {
    value: string,
    label: string,
  }[] = [];

  isDistrictOptionUpdated:boolean = false;
  isDistrictDropdownLoading: boolean = false;
  districtsOptions: {
    value: string,
    label: string,
  }[] = [];

  isLocationOptionUpdated:boolean = false;
  isLocationDropdownLoading:boolean = false;
  isShownAllLocations:boolean = false;
  locationsOptionsPage = 1;
  locationsOptions: LocationsOption[] = [];
  locationDistrictMapping = {};

  isEquipmentCategoryOptionUpdated:boolean = false;
  isEquipmentCategoryDropdownLoading:boolean = false;
  equipmentCategoriesOptions: {
    value: string,
    label: string,
  }[] = [];

  isEquipmentTypeOptionUpdated:boolean = false;
  isEquipmentTypeDropdownLoading:boolean = false;
  equipmentTypesOptions: {
    value: string,
    label: string,
  }[] = [];
  haEquipmentNumbersOptionsTotalPage = 1;
  haEquipmentNumbersOptionsPage = 1;
  haEquipmentNumbersOptions: HaEquipmentOption[] = [];


  snView: {
    snNumber                        : string,
    jobCards                        : string[],
    status                          : JMENUM.SnStatus,
    contactPerson                   : string,
    contactNumber                   : string[],
    email                           : string,
    locationDetail                  : string,
    priority                        : JMENUM.JMPriority,
    faultDetail                     : string,
    workCentre                      : string,
    client                          : string,
    district                        : JMENUM.DistrictCode,
    location                        : string,
    functionalLocation              : string,
    haEquipmentNumber               : string,
    equipmentCategory               : string,
    equipmentType                   : string,
    equipmentTag                    : string,
    handlingParty                   : JMENUM.HandlingParty,
    maintenanceType                 : JMENUM.MaintenanceType,
    serachTagKey                    : string,
    manualInstructionStatus         : any,
    selectedRule                    : any,
    selectedTag                     : JMOBJ.HashTag,
    includeNonOfficeHour            : boolean,
    saveClientInfo                  : boolean,
    unlockClientLocationEquipment   : boolean, // ture = isRoTeam = no need limit the related field options
    remark                          : string,
    remarkVisibility                : JMENUM.RemarkVisibility,
    draftedRemarkId                 : string,
    teamName                        : string,
    attachmentList:{
      name:string,
      data:string
    }[],
    fehdVenue                       : JMENUM.FehdVenue,
    internalRemarks                 : string;
  } = {
    snNumber                        : null,
    jobCards                        : null,
    status                          : null,
    contactPerson                   : null,
    contactNumber                   : [''],
    email                           : null,
    locationDetail                  : null,
    priority                        : null,
    faultDetail                     : null,
    workCentre                      : null,
    client                          : null,
    district                        : null,
    location                        : null,
    functionalLocation              : null,
    haEquipmentNumber               : null,
    equipmentCategory               : null,
    equipmentType                   : null,
    equipmentTag                    : null,
    handlingParty                   : undefined,
    maintenanceType                 : undefined,
    serachTagKey                    : undefined,
    manualInstructionStatus         : null,
    selectedRule                    : undefined,
    selectedTag                     : undefined,
    includeNonOfficeHour            : false,
    saveClientInfo                  : false,
    unlockClientLocationEquipment   : false,
    remark                          : undefined,
    remarkVisibility                : JMENUM.RemarkVisibility.PUBLIC,
    draftedRemarkId                 : undefined,
    teamName                        : null,
    attachmentList                  : [],
    fehdVenue                       : undefined,
    internalRemarks                 : undefined,
  };


  // Selected team information
  selectedSN: JMOBJ.ServiceNotification = undefined;

  // selected ha equipment
  SelectedHaEquipment = {
    assetNumber: undefined,
    assetDescription: undefined,
    HACluster: undefined,
    regpc: undefined,
    ccsNumber: undefined,
    clientNumber: undefined,
    hospitalLocation: undefined,
    hospitalCode:undefined,
    functionalLocation:undefined
  };

  // client info
  clientInformation:{} = null;
  contactNumberOptions = null;
  contactNumberFilteredOptions: Observable<string[]>;

  // for searching use
  private searchTerms = new Subject<any[]>();
  formControl = new FormControl();// for save client detail use

  // UI component use
  actionDefinition: any[] = [];
  actionButtonData = [];

  // disable edit fields
  disableSaveClientDetail = true;
  disableEditTeam = true; // disable input fields and routing rules table
  disableEditContactDetails = true;
  disableEditLocationDetails = true;
  disableEditFaultDetails = true;
  disableEditInternalRemarks= true;
  disableRemarks = true;
  showRemarkHistory = true;
  disablePriority = false;
  disableLocationField = false; //org: true
  hasHaEquipmentNumber = false;
  disableHaEquipmentNumber = false;

  // for loading use
  disableActionSideBar = false;
  isManualInstructionPanelLoading = false;

  // show error field
  errorFields = {
    Client: false,
    District: false,
    Location: false,
    ContactPerson: false,
    ContactNumber: false,
    Email: false,
    HaEquipmentNumber: false,
    EquipmentCategory: false,
    EquipmentType: false,
    FaultDetails: false
  };

  // for remarks use
  additionalRemark: string = undefined;
  showRemarkVisibilityType: any = {};

  //for re-routing expired team to In-house handling team
  reRoutingRemarks: any = {};

  // for manual instruction use
  manualInstructionPreActionFormData: [{
    manualInstructionId: number,
    remarks: string,
  }[]];
  manualInstructionPreActionListData: [{
    manualInstructionId: number,
    remarks: string,
  }[]];

  routingRuleTeamArray = [];
  handlingTeamObject = {};

  // for tablex
  tablexFilter          = {};
  tablexParam           : any = {};
  pageSize              = 10;
  currentPage           = 1;
  lastContentCount      = 0;
  showLoadingWheel      = false;
  uiTagButtonDict       = {}; // key: rule.ruleSequence+team._id+_+tag._id, obj: the button on tablex

  // for external system call
  snSource:string = undefined;
  crmCallbackURL: string = null;
  crmExternalRefId: string = null;

  // for HA SN
  haEquipmentDict: {} = {}; // key: assetNumber, value: HaEquipmentOption

  // for searching HA equipment number use
  // private searchHaEquipmentNumberObserver = new Subject<any[]>();
  // searchHaEquipmentNumberKeywords: string;

  // for searching location use
  private searchLocationObserver = new Subject<any[]>();
  searchLocationKeywords: string;

  // for vender name
  venderNameDict: any = {}; // key: contractNumber, value: venderName

  // for draw snapshot rule use
  snapshotEquipmentTag: any = null;

  // for reload routing table use
  isReloadingRoutingTable = false;

  // for manual instruction panel
  manualInstructionTeam = {};
  manualInstructionTeamNextOperationTime: Date;
  manualInstructionHandlingTeamNextOperationTime: Date;

  //attachment accepted file type
  fileMimeType = [
    "image/bmp",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/gif",
    "image/png",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ];

  // for FEHD venue
  FehdVenue = JMENUM.FehdVenue;
  locationFehdVenueMapping = {};  // key = locationCode; value = fehdVenues

  isVVIP_SRAEnable: boolean = false;
  SRALocationSet : Set<JMOBJ.SpecialRequestRule['location']['code']> = new Set();

  constructor(
    injector: Injector,
    private locationService: LocationService,
  ) {
    super(injector);
    this.isVVIP_SRAEnable = isEnabledFeature(Session,JMCONSTANT.JMFeature.VVIP_SRA);
  }

  ngOnInit() {
    if (!this.authorizationService.hasPermission(JMENUM.Permission.SN_DRAFT)) {
      this.openSnackBar(this.translate("popupError.no-permission"));
      this.router.navigate(['']);
      return;
    }
    // init view
    this.initView();
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true); //third parameter
    document.title = Constants.SYSTEM_DISPLAY_NAME;
  }

  onLangChange() {
    if (this.districtsOptions && this.districtsOptions.length && this.districtsOptions.length > 0 ) {
      this.setDistrictsOptions(this.districtsOptions.map(option => { return option.value }));
    }
  }

  //==================================================================
  //Initial Function

  async initView() {
    // init view
    this.lang = Session.selectedLanguage;
    this.clientInformation = this.loadClientInformation();
    this.contactNumberOptions = this.clientInformation?Object.keys(this.clientInformation).sort(): [];
    this.contactNumberFilteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterContactNumber(value))
    );
    this.snView.priority = this.DEFAULT_PRIORITY;
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : false,
      enablePagination      : false,
      pageSizeOptions       : [10, 25, 50],
      onRowClicked          : this.onRowClicked,
      onFilterChanged       : this.onFilterChanged,
      filterDebounceTime    : Constants.DEBOUNCE_TIME,
      tableRow              : "clickable",
      headers: [
        {
          id: "maintenance-team",
          name: "pages.sn.maintenance-team",
          enableFilter: true,
          placeholder: "pages.sn.maintenance-team.placeholder",
          type: TablexColumnType.Html,
          horizontalAlign: TablexColumnHorizontalAlign.Left,
          verticalAlign: TablexColumnVerticalAlign.Top
        },
        {
          id: "work-centre",
          name: "pages.sn.work-centre",
          enableFilter: true,
          placeholder: "pages.sn.work-centre.placeholder",
          type: TablexColumnType.Html,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Top
        },
        {
          id: "details",
          name: "pages.sn.details",
          enableFilter: true,
          placeholder: "pages.sn.equipment-tag.placeholder",
          type: TablexColumnType.MultiLine,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Top
        },
        {
          id: "more-info",
          name: "pages.sn.more-info",
          enableFilter: false,
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Top
        },
      ],
      content: [],
      highlightedRows: []
    }

    // remark
    let visibilities = [
      {type: "public",    permission: Permission.snRemarksPublicUpdate},
      {type: "private",   permission: Permission.snRemarksPrivateUpdate},
      {type: "invisible", permission: Permission.snRemarksInvisibleUpdate}
    ];
    visibilities.forEach(type => {
      this.showRemarkVisibilityType[type.type] = this.authorizationService.hasPermission(type.permission);
    });
    this.snView.remarkVisibility = JMENUM.RemarkVisibility.PUBLIC;

    // for searching use
    this.searchTerms.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      if (this.snView.serachTagKey == '') {
        this.tablexParam['content'] = [];
        this.tablexParam['highlightedRows'] = [];
      }
      this.onInputUpdated(true);
    });
    window.addEventListener('scroll', this.onScroll, true); //third parameter

    // for search HA equipment number use
    // this.searchHaEquipmentNumberObserver.pipe(
    //   debounceTime(Constants.DEBOUNCE_TIME),
    //   ).subscribe(() => {
    //     this.haEquipmentNumbersOptions = [];
    //     this.haEquipmentNumbersOptionsPage = 1;
    //     this.requestHaEquipmentNumberOptions(false);
    //   }
    // );
    
    //set location code for SRALocationSet for location option to display vvip indicator
    if (this.isVVIP_SRAEnable) {
      await this.setSRALocationSet();
    }
    // for search location use
    // called when user manually input text or clicked clear or deleted text
    this.searchLocationObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.locationsOptions = [];
      this.locationsOptionsPage = 1;
      this.isShownAllLocations = false; 
      this.isLocationOptionUpdated = false;
      if (this.snView.unlockClientLocationEquipment) { // ro team
        this.searchAllLocationDropdown(this.searchLocationKeywords);
      } else {
        this.searchLocationDropdown(this.searchLocationKeywords);
      }
    });

    // get route value
    this.snView.snNumber = this.route.snapshot.paramMap.get('snNumber');
    this.snSource = this.getParameterByName('source');

    try {
      const resultArray = await JM.JMConnector.cacheAll();
    } catch (err) {
      return;
    }

    // Case 1: has sn number
    if (this.snView.snNumber) {
      document.title = this.snView.snNumber + "-" + document.title;
      this.requestSn(); // need sn before setting mode
      return;
    }

      this.setMode("new");
      this.clearInput();
      this.clearOptions();

      // Case 2: no sn number + CRM create SN throught URL
      if (this.snSource && this.snSource == "crm") {

        this.snView.client            = this.isParameterEmpty('client') ? undefined : this.getParameterByName('client');
        this.snView.contactPerson     = this.isParameterEmpty('contactPerson') ? undefined : this.getParameterByName('contactPerson');
        this.snView.email             = this.isParameterEmpty('contactEmail') ? undefined : this.getParameterByName('contactEmail');
        this.snView.locationDetail    = this.isParameterEmpty('location') ? undefined : this.getParameterByName('location');
        this.snView.contactNumber[0]  = this.isParameterEmpty('contactNumber1') ? undefined : this.getParameterByName('contactNumber1');
        this.snView.contactNumber[1]  = this.isParameterEmpty('contactNumber2') ? undefined : this.getParameterByName('contactNumber2');
        this.snView.contactNumber[2]  = this.isParameterEmpty('contactNumber3') ? undefined : this.getParameterByName('contactNumber3');
        this.crmExternalRefId         = this.isParameterEmpty('externalRefId') ? undefined : this.getParameterByName('externalRefId');
        this.crmCallbackURL           = this.isParameterEmpty('externalCallbackURL') ? undefined : this.getParameterByName('externalCallbackURL');
        this.snView.contactNumber     = this.snView.contactNumber.filter(value => value && value.length > 0);

        this.tablexFilter["maintenance-team"] = this.isParameterEmpty('teamName') ? undefined : this.getParameterByName('teamName');
        this.tablexFilter["work-centre"]      = this.isParameterEmpty('workCentre') ? undefined : this.getParameterByName('workCentre');
        this.tablexFilter["details"]          = this.isParameterEmpty('serachTagKey') ? undefined : this.getParameterByName('serachTagKey');
        this.routingChoicesTable.setFilter(this.tablexFilter);

        if (this.snView.contactNumber.length === 0) {
          this.snView.contactNumber = [''];
        }

        this.setDropdown();
        this.updateSnView();
        return;
      }

      let savedSnView = Session.snCreateSnInputForm;

      // Case 3: no sn number + no cache data
      if (!savedSnView) {
        // this.updateSnView();
        return;
      }

      this.snView = savedSnView;

      this.tablexFilter["maintenance-team"] = this.snView.teamName;
      this.tablexFilter["work-centre"] = this.snView.workCentre;
      this.tablexFilter["details"] = this.snView.serachTagKey;
      this.routingChoicesTable.setFilter(this.tablexFilter);

      this.hasHaEquipmentNumber = this.snView.haEquipmentNumber ? true : false; // set ha flag

      // set dropdown 
      this.setDropdown();

      // Case 4: no sn number + has cache data + no selected routing rule
      if (!this.snView.selectedRule) {
        this.updateSnView();
        return;
      }

      // Case 5: no sn number + has cache data + has selected routing rule
      let request                 = new JM.JMRequestRoutingRulesFindRoutingRuleTeam();
          request.ruleSequence    = this.snView.selectedRule.ruleSequence;
          request.priority        = this.snView.priority;
          request.teamId          = this.snView.selectedRule.team._id;
          request.maintenanceType = this.snView.maintenanceType ? this.snView.maintenanceType : JMENUM.MaintenanceType.CM;

      // get the selected rule first
      const response: JM.JMResponseRoutingRulesFindRoutingRuleTeam = await AppDelegate.sendJMRequest(request);
      this.setRoutingChoicesTableLoading(false);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      if (response.payload.teams.length > 0) {
        this.snView.selectedRule = response.payload.teams[0]; // update to latest team info
        this.snView.includeNonOfficeHour = (this.snView.selectedRule.team.nextOperationTime != null && this.snView.selectedRule.team.nextOperationTime != "");
        for (let tag of this.snView.selectedRule.equipmentTags) {
          if (this.snView.selectedTag && tag._id == this.snView.selectedTag._id) {
            this.selectTag(tag);
            break;
          }
        }
      }
      this.updateSnView();
  }

  setMode(newMode:"new"|"edit"|"reroute") {
    this.mode = newMode;
    this.actionButtonData = [];

    if (this.mode == "new") {
      this.disableSaveClientDetail = false;
      this.disableEditTeam = false;
      this.disableEditContactDetails = false;
      this.disableEditLocationDetails = false;
      this.disableEditFaultDetails = false;
      this.disableEditInternalRemarks = false;
      this.disableRemarks = false;
      this.showRemarkHistory = false;

      this.addActionBtn(ActionButtonSn.save);
      this.addActionBtn(ActionButtonSn.copyInNew);
      this.addActionBtn(ActionButtonSn.submit);
      this.addActionBtn(ActionButtonSn.clear);
      this.addActionBtn(ActionButtonSn.close);

    } else if (this.mode == "edit") {
      switch (this.selectedSN.status) {
          case JMENUM.SnStatus.DRAFT:
            this.disableSaveClientDetail = false;
            this.disableEditTeam = false;
            this.disableEditContactDetails = false;
            this.disableEditLocationDetails = false;
            this.disableEditFaultDetails = false;
            this.disableEditInternalRemarks = false;
            this.disableRemarks = false;
            this.showRemarkHistory = false;

            this.addActionBtn(ActionButtonSn.save);
            this.addActionBtn(ActionButtonSn.copyInNew);
            this.addActionBtn(ActionButtonSn.submit);
            this.addActionBtn(ActionButtonSn.clear);
            this.addActionBtn(ActionButtonSn.close);
            break;
          default:
            this.addActionBtn(ActionButtonSn.close);
            break;
        }
    } else if (this.mode == "reroute") {
      this.disableEditTeam = false;
      this.disableEditContactDetails = true;
      this.disableEditLocationDetails = true;
      this.disableEditFaultDetails = true;
      this.disableEditInternalRemarks = true;
      this.disableRemarks = true;
      this.showRemarkHistory = false;

      if (this.isSnSourceByHaXml()) {
        this.disableHaEquipmentNumber = true;
      }

      this.addActionBtn(ActionButtonSn.submit);
      this.addActionBtn(ActionButtonSn.close);
    }
  }

  private enableInput(enable) {
    this.isManualInstructionPanelLoading = !enable;
    this.disableActionSideBar = !enable;
    this.disableEditTeam = !enable;
    this.disableEditContactDetails = !enable;
    this.disableEditLocationDetails = !enable;
    this.disableEditFaultDetails = !enable;
    this.disableEditInternalRemarks = !enable;
    this.disableRemarks = !enable;
  }


  //===================================================================================================================
  // API request functions
  private async requestSn() {
    const request = new JM.JMRequestSnGetSn();
    request.snNumber = this.snView.snNumber;
    request.excludeTeamInformation = false;

    const response:JM.JMResponseSnGetSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

      let newMode: "new" | "edit" | "reroute" = (this.route.snapshot.paramMap.get("mode") == 're-route') ? "reroute" : "edit";
      this.selectedSN = response.payload;
      this.setMode(newMode);

      this.snView.snNumber          = this.selectedSN.snNumber;
      this.snView.handlingParty     = (this.selectedSN.handlingParty) ? this.selectedSN.handlingParty : null;
      this.snView.maintenanceType   = (this.selectedSN.maintenanceType) ? this.selectedSN.maintenanceType : null;
      this.snView.status            = (this.selectedSN.status) ? this.selectedSN.status : null;
      this.snView.client            = (this.selectedSN.client) ? this.selectedSN.client : null;
      this.snView.district          = (this.selectedSN.district) ? this.selectedSN.district : null;
      this.snView.location          = (this.selectedSN.location) ? this.selectedSN.location : null;
      this.snView.locationDetail    = (this.selectedSN.locationDetail) ? this.selectedSN.locationDetail : null;
      this.snView.equipmentCategory = (this.selectedSN.equipmentCategory) ? this.selectedSN.equipmentCategory : null;
      this.snView.equipmentType     = (this.selectedSN.equipmentType) ? this.selectedSN.equipmentType : null;
      this.snView.priority          = (this.selectedSN.priority) ? this.selectedSN.priority : this.DEFAULT_PRIORITY;
      this.snView.contactPerson     = (this.selectedSN.contactPerson) ? this.selectedSN.contactPerson : '';
      this.snView.contactNumber     = (this.selectedSN.contactNumber) ? this.selectedSN.contactNumber : [''];
      this.snView.email             = (this.selectedSN.email) ? this.selectedSN.email : '';
      this.snView.faultDetail       = (this.selectedSN.faultDetail) ? this.selectedSN.faultDetail : '';
      this.snView.internalRemarks   = (this.selectedSN.internalRemarks) ? this.selectedSN.internalRemarks : '';
      this.snView.attachmentList    = (this.selectedSN.attachmentList) ? this.selectedSN.attachmentList : [];
      this.snView.maintenanceType   = (this.selectedSN.maintenanceType) ? this.selectedSN.maintenanceType : JMENUM.MaintenanceType.CM;
      this.snView.fehdVenue         = this.selectedSN.fehdVenue;

      if (this.selectedSN.eamData) {
        this.snView.haEquipmentNumber = this.selectedSN.eamData.assetNumber ? this.selectedSN.eamData.assetNumber : null;
        this.hasHaEquipmentNumber     = this.selectedSN.eamData.assetNumber ? true : false; // set ha flag
      }
      if (this.selectedSN.crmData) {
        this.snSource = "crm";
        this.crmExternalRefId = this.selectedSN.crmData.externalRefId;
        this.crmCallbackURL = this.selectedSN.crmData.externalCallbackUrl;
      }
      if (this.selectedSN.team && this.selectedSN.team.isRoTeam) {
        this.snView.unlockClientLocationEquipment = this.selectedSN.team.isRoTeam;
      }

      this.setDropdown();

      // display remark
      if (this.selectedSN.status == JMENUM.SnStatus.DRAFT) {
        this.updateSnViewRemark();
      }

      // find the selected rule and team
      if (this.selectedSN.selectedRuleSequence != undefined && this.selectedSN.team) {
        let request                 = new JM.JMRequestRoutingRulesFindRoutingRuleTeam();
            request.ruleSequence    = this.selectedSN.selectedRuleSequence;
            request.priority        = this.selectedSN.priority;
            request.teamId          = this.selectedSN.team._id;
            request.showROTeam      = this.snView.unlockClientLocationEquipment;
            request.maintenanceType = this.snView.maintenanceType;

        if (this.selectedSN.eamData && this.selectedSN.eamData.assetNumber) {
          request.assetNumber = this.selectedSN.eamData.assetNumber;
        }

        const response: JM.JMResponseRoutingRulesFindRoutingRuleTeam = await AppDelegate.sendJMRequest(request);
        this.setRoutingChoicesTableLoading(false);
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }

        if (response.payload.teams.length > 0) {
          this.snView.selectedRule = response.payload.teams[0];
          this.snView.includeNonOfficeHour = (this.snView.selectedRule.team.nextOperationTime != null && this.snView.selectedRule.team.nextOperationTime != "");
          for (let tag of this.snView.selectedRule.equipmentTags) {
            if (tag._id == this.selectedSN.hashtagId) {
              this.selectTag(tag);
              break;
            }
          }
        }
        this.reloadRoutingTable();
        // } else {
        //   this.updateSnView();
        // }
      } else {
        this.updateSnView();
      }
  }

  getFilteredRoutingRules(rules: any[]) {
    let filteredRules = [];
    for (let rule of rules) {
      let newTags = [];
      if (rule.equipmentTags && rule.equipmentTags.length > 0) {
        for (let tag of rule.equipmentTags) {
          if ((tag.equipmentType && rule.equipmentTypes.includes(tag.equipmentType)) || !rule.equipmentTypes || rule.equipmentTypes.length === 0) {
            if ( this.snView.serachTagKey) {
              if ((tag.description.en && tag.description.en.toLowerCase().includes(this.snView.serachTagKey.toLowerCase()))
              || ((tag.description.zh && tag.description.zh.includes(this.snView.serachTagKey)))) {
                newTags.push(tag);
              }
            } else {
              newTags.push(tag);
            }
          }
        };
      }

      rule.equipmentTags = newTags;
      filteredRules.push(rule);
    }

    return filteredRules;
  }

  getFindRoutingRuleRequest() {
    let request                      = new JM.JMRequestRoutingRulesFindRoutingRuleTeam();
        request.showROTeam           = this.snView.unlockClientLocationEquipment;
        request.includeNonOfficeHour = this.snView.includeNonOfficeHour;
        request.pageSize             = this.pageSize;
        request.maintenanceType      = this.snView.maintenanceType;

    if (this.snView.haEquipmentNumber) {
      request.assetNumber = this.snView.haEquipmentNumber;
    } else {
      if (this.snView.client)            request.client = this.snView.client;
      if (this.snView.location)          request.location = this.snView.location;
      if (this.snView.district)          request.district = this.snView.district;
      if (this.snView.equipmentCategory) request.equipmentCategory = this.snView.equipmentCategory;
      if (this.snView.equipmentType)     request.equipmentType = this.snView.equipmentType;
      if (this.snView.equipmentTag)      request.equipmentTagDescription = this.snView.equipmentTag;
    }
    if (this.snView.priority)          request.priority = this.snView.priority;

    // filter
    if (this.tablexFilter["maintenance-team"] && this.tablexFilter["maintenance-team"].trim().length) {
      request.team = this.tablexFilter["maintenance-team"].trim();
    }

    if (this.tablexFilter["work-centre"] && this.tablexFilter["work-centre"].trim().length) {
      request.workCentre = this.tablexFilter["work-centre"].trim();
    }

    if (this.tablexFilter["details"] && this.tablexFilter["details"].trim().length) {
      request.equipmentTagDescription = this.tablexFilter["details"].trim();
    }

    return request;
  }

  async reloadRoutingTable() {
    if (this.isReloadingRoutingTable) { return; }
    if (!this.isContinueRequestRoutingChoice()) { return; }

    this.isReloadingRoutingTable = true;
    this.routingRuleTeamArray = [];
    this.handlingTeamObject = {};
    this.currentPage = 1;

    if (this.snView.selectedRule) {
      await this.requestSelectedRoutingChoice();
    }
    this.requestRoutingChoices();
  }

  private isContinueRequestRoutingChoice(): boolean {
    // HA SN case
    if (this.isHaSn()) return true;
    // Show Ro Team case
    if (this.snView.unlockClientLocationEquipment) return true;
    // Input CDLCT, team name, work centre or equipment tag at least 1 field case
    if (this.snView.client
        || this.snView.district
        || this.snView.location
        || this.snView.equipmentCategory
        || this.snView.equipmentType
        || this.snView.teamName
        || this.snView.workCentre
        || this.snView.serachTagKey
      ) {
      return true;
    }
    return false;
  }

  private async requestSelectedRoutingChoice() {
    let request = this.getFindRoutingRuleRequest();
    request.pageNumber = 1;

    // set selected rule objectId
    if (this.snView.selectedRule.ruleSequence != undefined) request.ruleSequence = this.snView.selectedRule.ruleSequence;
    if (this.snView.selectedRule.team && this.snView.selectedRule.team._id) request.teamId = this.snView.selectedRule.team._id;

    this.setRoutingChoicesTableLoading(true);
    try {
      const response:JM.JMResponseRoutingRulesFindRoutingRuleTeam = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.setRoutingChoicesTableLoading(false);
        this.openErrorBar(response);
        return;
      }

      let filteredRules = this.getFilteredRoutingRules(response.payload.teams);
      this.handlingTeamObject = response.payload.handlingTeams ? response.payload.handlingTeams : {};

      // unselect rule if the selected rule is not found
      if (filteredRules.length == 0) {
        this.unselectRoutingChoice();
      }
      
      for (let rule of filteredRules) {
        this.routingRuleTeamArray.push(rule);
      }
    } catch (error) {
      this.setRoutingChoicesTableLoading(false);
      this.handleJMError(error);
    }
  }

  private async requestRoutingChoices() {
    let request            = this.getFindRoutingRuleRequest();
        request.pageNumber = this.currentPage;

    if (!this.isContinueRequestRoutingChoice()) { return; }

    this.setRoutingChoicesTableLoading(true);
    const response:JM.JMResponseRoutingRulesFindRoutingRuleTeam = await AppDelegate.sendJMRequest(request);
    this.setRoutingChoicesTableLoading(false);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.lastContentCount = response.payload.totalCount;

    // filter out those types do not match rule
    let filteredRules = this.getFilteredRoutingRules(response.payload.teams);
    this.handlingTeamObject = response.payload.handlingTeams ? response.payload.handlingTeams : {};

    for (let rule of filteredRules) {
      // not selected rule
      if (!(this.snView.selectedRule && this.snView.selectedRule.ruleSequence == rule.ruleSequence && this.snView.selectedRule.team._id == rule.team._id)) {
        // check duplicate
        let isDuplicated = false;
        if(this.routingRuleTeamArray) {
          for (let obj of this.routingRuleTeamArray) {
            if(JSON.stringify(obj) == JSON.stringify(rule)) {
              isDuplicated = true;
              break;
            }
          }
        }
        if(!isDuplicated) {
          this.routingRuleTeamArray.push(rule);
        }
      }
    }

    if (this.routingRuleTeamArray.length == 0 && (this.currentPage * this.pageSize < this.lastContentCount)) {
      // find until at least one rule is returned
      this.currentPage += 1;
      this.requestRoutingChoices();
    } else {
      this.getVenderNameList();
      this.renderTable();
    }
  }

  async requestEquipmentTagBySnapShot() {
    if (!this.disableEditTeam || !this.selectedSN.hashtagId) { return; }

    const request = new JM.JMRequestHashtagsGetHashtags();
    request.hashtagIdList = [this.selectedSN.hashtagId];
    const response: JM.JMResponseHashtagsGetHashtags = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let records = response.payload.records;
    if (records && records.length > 0) {
      this.snapshotEquipmentTag = response.payload.records[0];
      this.drawRoutingChoiceBySnapshot();
    }
  }

  drawRoutingChoiceBySnapshot() {
    if (!this.disableEditTeam || !this.selectedSN) { return; }
    this.routingRuleTeamArray = [
      {
        clients: [this.selectedSN.client],
        districts: [this.selectedSN.district],
        equipmentCategories: [this.selectedSN.equipmentCategory],
        equipmentTags: this.snapshotEquipmentTag ? [this.snapshotEquipmentTag] : [],
        equipmentTypes: [this.selectedSN.equipmentType],
        locations: [this.selectedSN.location],
        team: this.selectedSN.team,
        workCentre: this.selectedSN.workCentre
      }
    ];

    if (!this.snapshotEquipmentTag) {
      this.requestEquipmentTagBySnapShot();
    }

    this.getVenderNameList();
    this.renderTable();
  }

  renderTable() {
    this.tablexParam.selectedRowIndex = -1;
    this.uiTagButtonDict = {};
    let teamArray = [];
    let highlightArray:boolean[] = [];
    for (let i=0; i<this.routingRuleTeamArray.length; i++) {
      let rule = this.routingRuleTeamArray[i];
      let colText_0 = ""; // col 0
      let colText_1 = "";
      let colText_2 = [];
      let colText_3 = [];  // col 3

      // column 0 ------------------------
      // set row - maintenance Team  -----
      // team name
      let teamName = rule.team.name+" ("+rule.team.workCentre+")";
      colText_0 = this.translate("pages.sn.team");
      colText_0 += "<div class='create-sn-maintenance-team'>" + [teamName];
      if (rule.team.status == JMENUM.TeamStatus.CONTRACT_EXPIRED) {
        colText_0 += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + this.translate('pages.sn.tooltip.team-contract-expired') + "'></i>";
      }
      if (rule.team.status == JMENUM.TeamStatus.CONTRACT_INEFFECTIVE) {
        colText_0 += "&nbsp;&nbsp;&nbsp;<i class='expired-icon fas fa-exclamation-circle' title='" + this.translate('pages.sn.tooltip.team-contract-ineffective') + "'></i>";
      }
      if(rule.team.description && rule.team.description != "") {
        colText_0 += "<br>" + rule.team.description;
      }
      colText_0 += "</div>";

      // contractor
      if (rule.team.handlingParty != JMENUM.HandlingParty.INHOUSE) {
        const contractNumber = rule.team.contractNumber;
        colText_0 += this.translate("pages.sn.contractor");
        colText_0 += "<div class='create-sn-maintenance-team'>";
        colText_0 += contractNumber;
        if (contractNumber && this.venderNameDict[contractNumber]) {
          colText_0 += "<br>" + this.venderNameDict[contractNumber];
        }
        colText_0 += "</div>";
      }

      // next operation time
      // show working hours when the team is out of working hours
      // no defer time if there are no next operation time -> (rule.team.nextOperationTime == "")
      let nonOfficeHour = (rule.team.nextOperationTime != null && rule.team.nextOperationTime != "");
      if (nonOfficeHour) {
        colText_0 += "<div class='create-sn-maintenance-team-operation-time'>";
        let nextOperationTime = formatDate(rule.team.nextOperationTime, "dd/MM/yyyy HH:mm", 'en-US');
        colText_0 += (this.translate("pages.sn.next-operation-time", [nextOperationTime]));
        colText_0 += "</div>";
      }

      // column 1 -----------------------
      // set row - workCentre -----------
      let workCentre:JMOBJ.WorkCentre = JM.JMConnector.getWorkCentre(rule.workCentre);
      colText_1 = rule.workCentre + "<br><div class='create-sn-workcentre-desp'>" + (workCentre ? workCentre.description : '') + "</div>";

      // column 2 -----------------------
      let locationDetail = "";
      for(let location of rule.locations) {
        if(locationDetail != "") {
          locationDetail += "<br>";
        }
        if(rule.locationDescription && rule.locationDescription[location] && rule.locationDescription[location][this.lang]) {
          locationDetail += rule.locationDescription[location][this.lang] + " (" + location + ")";

        }else if(this.snView.selectedRule && this.snView.selectedRule.locationDescription && this.snView.selectedRule.locationDescription[location] && this.snView.selectedRule.locationDescription[location][this.lang]) {
          locationDetail += this.snView.selectedRule.locationDescription[location][this.lang] + " (" + location + ")";

        }else{
          locationDetail += "(" + location + ")";
        }
      }

      let category = "";
      let type = "";
      if (rule.equipmentCategories && rule.equipmentCategories.length) {
        category = rule.equipmentCategories[0];
      }
      for(let equipmentType of rule.equipmentTypes) {
        if(type != "") {
          type += "<br>";
        }
        if (equipmentType && JM.JMConnector.getEquipmentType(equipmentType)) {
          type += category + " (" + JM.JMConnector.getEquipmentType(equipmentType).description[this.lang] + ")";
        }
      }

      // matching tag's type and rule's type
      let tags = rule.equipmentTags;
      // let tags = []; // filtered after getting the rule
      // for (let tag of rule.equipmentTags) {
      //   if (tag.equipmentType && rule.equipmentTypes.includes(tag.equipmentType)) {
      //     tags.push(tag);
      //   }
      // };

      let text = "<table class='create-sn-table'>";

      // client
      text += "<tr>";
      text += "<td><div class='create-sn-details-right'>" + this.translate("pages.sn.routing-choices-table.detail.client") + "</div></td>";
      text += "<td><div class='create-sn-details-left'>" + (rule && rule.clients && rule.clients.length ? rule.clients.join(", ") : "") + "</div></td>";
      text += "</tr>";

      // district
      text += "<tr>";
      text += "<td><div class='create-sn-details-right'>" + this.translate("pages.sn.routing-choices-table.detail.district") + "</div></td>";
      text += "<td><div class='create-sn-details-left'>" + (rule && rule.districts && rule.districts.length ? rule.districts.map(districtId => { return this.translate('district.' + districtId) }).join(", ") : "") + "</div></td>";
      text += "</tr>";

      // location
      text += "<tr>";
      text += "<td><div class='create-sn-details-right'>" + this.translate("pages.sn.routing-choices-table.detail.location") + "</div></td>";
      text += "<td><div class='create-sn-details-left'>" + (locationDetail ? locationDetail : "") + "</div></td>";
      text += "</tr>";

      // equipment type
      text += "<tr>";
      text += "<td><div class='create-sn-details-right'>" + this.translate("pages.sn.routing-choices-table.detail.equipment-category") + "</div></td>";
      text += "<td><div class='create-sn-details-left'>" + (type ? type : "") + "</div></td>";
      text += "</tr>";

      // equipment tag
      let equipmentTagButton = {
        type: "buttons", buttons: tags.map(tag => {
          let isSameTag = (this.snView.selectedRule
                        && this.snView.selectedRule.ruleSequence == rule.ruleSequence
                        && this.snView.selectedRule.team._id == rule.team._id
                        && this.snView.selectedTag
                        && this.snView.selectedTag._id == tag._id);
          let css = (isSameTag) || this.disableEditTeam ? " selected-tag-button" : "tag-button";
          let button = {
            "name": tag.description[Session.selectedLanguage],
            "class": css,
            "onClicked": this.onTagButtonClicked,
            "object": {index:i, rule: rule, tag: tag},
            "disable": this.disableEditTeam,
            "tagId": tag._id,
            "cannotUnselect": (tags.length == 1)  // cannot unselect tag if there are only one tag
          };
          this.uiTagButtonDict[rule.ruleSequence + '_' + rule.team._id+'_'+tag._id] = button;
          return button;
        })
      };
      text += "</table>";
      colText_2.push({ type:"html", value:text });
      colText_2.push(equipmentTagButton);

      // col 3 ------------------
      // set row - moreInfo -----
      if (this.hasManualInstruction(rule.team)) {
        colText_3.push({ "id": "acknowledged-button", "name": "", "class": "glyph brand-amber", "icon": "fas fa-sticky-note", "onClicked": this.onRuleManualInstructionButtonClicked, "object": rule});
      }
      if (rule.team.contactSequences) {
        colText_3.push({ "id": "information-button", "name": "", "class": "glyph brand-blue", "icon": "fas fa-info", "onClicked": this.onInformationButtonClicked, "object": rule.team});
      }

      if (this.snView.selectedRule && this.snView.selectedRule.ruleSequence == rule.ruleSequence && this.snView.selectedRule.team._id == rule.team._id) {
        teamArray.unshift([colText_0, colText_1, colText_2, colText_3]);
        highlightArray.unshift(nonOfficeHour);
        this.tablexParam.selectedRowIndex = i;
      } else {
        teamArray.push([colText_0, colText_1, colText_2, colText_3]);
        highlightArray.push(nonOfficeHour);
      }
    }
    // put selected rule to top
    if (this.tablexParam.selectedRowIndex >= 0) {
      this.routingRuleTeamArray.splice(0, 0, this.routingRuleTeamArray.splice(this.tablexParam.selectedRowIndex, 1)[0]);
      this.tablexParam.selectedRowIndex = 0;
    }
    this.tablexParam['content'] = teamArray;
    this.tablexParam['highlightedRows'] = highlightArray;

    if (this.disableEditTeam) {
      this.tablexParam.selectedRowIndex = 0;
    }
  }

  private getVenderNameList() {
    let contractNumberList = [];
    this.routingRuleTeamArray.forEach(ruleTeam => {
      const contractNumber = ruleTeam.team.contractNumber;
      if (contractNumber && !Object.keys(this.venderNameDict).includes(contractNumber) && !contractNumberList.includes(contractNumber)) {
          contractNumberList.push(contractNumber);
      }
    })

    if (contractNumberList && contractNumberList.length > 0) {
      this.requestVenderNameList(contractNumberList);
    }
  }

  /**
   * For normal SN
   * 1. set options (client, district, location, equipmentCategory, equipmentType, haEquipmentNumber)
   * // no longer required, the location is updated when the dropdown is opened
   * 2. reload the routing tabel
   */
  private updateNormalSnView() {
    if (this.isHaSn()) return;
    // HONG: have no idea when will below run
    // if (this.haEquipmentNumbersOptions.length === 0) {
    //   this.requestHaEquipmentNumberOptions(false);
    // }

    // this.setEquipmentCategoriesOptions(JM.JMConnector.getAllEquipmentCategoryCode());
    // this.setEquipmentTypesOptions(JM.JMConnector.getAllEquipmentTypeCode());
    // if (this.snView.location) this.setLocationOptions([this.snView.location]);  // for display the selected location

    // if (this.snView.unlockClientLocationEquipment) { // show work centre RO Team
    //   // show all options
    //   this.setDistrictsOptions(JM.JMConnector.getAllDistrictCode());
    //   this.setClientsOptions(JM.JMConnector.getAllClient().map(client => client.clientShortName));
      
    // } else {
    //   if (this.snView.client)            this.setClientsOptions([this.snView.client]);
    //   if (this.snView.district)          this.setDistrictsOptions([this.snView.district]);
    // }
    this.reloadRoutingTable();
  }

  /**
   * For HA SN
   * 1. set options (client, district, location, equipmentCategory, equipmentType, haEquipmentNumber)
   * 2. reload the routing tabel
   */
  updateHaSnView(): void {
    if (!this.snView.haEquipmentNumber) return;

    this.setEquipmentCategoriesOptions(JM.JMConnector.getAllEquipmentCategoryCode());
    this.setEquipmentTypesOptions(JM.JMConnector.getAllEquipmentTypeCode());
    this.setDistrictsOptions([this.snView.district]);
    this.setClientsOptions([this.snView.client]);
    this.setLocationOptions([this.snView.location]); 
    // this.requestHaEquipmentNumberOptions(true);

    this.reloadRoutingTable();
  }

  // Call sn api
  private async requestRerouteSn(nextOperationTime:Date = null) {
    const request = new JM.JMRequestSnReroute();
        request.snNumber               = this.selectedSN.snNumber;
        request.version                = this.selectedSN.version;
        request.client                 = this.snView.client;
        request.district               = this.snView.district;
        request.location               = this.snView.location;
        request.equipmentCategory      = this.snView.equipmentCategory;
        request.equipmentType          = this.snView.equipmentType;
        request.priority               = this.snView.priority;
        request.hashtagId              = this.snView.selectedTag ? this.snView.selectedTag._id : undefined;
        request.isNotificationDeferred = nextOperationTime ? true : false;
        request.disseminationStartTime = nextOperationTime;
        request.selectedRuleSequence   = this.snView.selectedRule.ruleSequence;
        request.workCentre             = this.snView.selectedRule.workCentre;
        request.teamId                 = this.snView.selectedRule.team._id;
        request.fehdVenue              = this.snView.fehdVenue;

    if (this.additionalRemark && this.additionalRemark.trim().length > 0) {  // manual instruction
      request.manualInstructionRemark = this.additionalRemark.trim();
    }
    if (this.reRoutingRemarks && this.reRoutingRemarks.remark) {
      request.remarks = [this.reRoutingRemarks];
    }

    if (!this.isSnSourceByHaXml()) {
      request.assetNumber = this.snView.haEquipmentNumber ? this.snView.haEquipmentNumber.trim() : null;
    }

    this.enableInput(false);
    const response: JM.JMResponseSnReroute = await AppDelegate.sendJMRequest(request);
    this.enableInput(true);

    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.selectedSN = response.payload;
    this.router.navigate(["/sn/view/", this.selectedSN.snNumber]);
  }

  // Sumbit SN (new or draft)
  private async requestSubmitSn(nextOperationTime:Date = null) {
    const request = new JM.JMRequestSnSubmit();
        request.sourceSystem      = Constants.SYSTEM_NAME;
        request.sourcePlatform    = Constants.PLATFORM_NAME;
        request.contactPerson     = this.snView.contactPerson ? this.snView.contactPerson.trim() : null;
        request.contactNumber     = this.snView.contactNumber;
        request.email             = this.snView.email ? this.snView.email.trim() : null;
        request.locationDetail    = this.snView.locationDetail ? this.snView.locationDetail.trim() : null;
        request.faultDetail       = this.snView.faultDetail ? this.snView.faultDetail.trim() : null;
        request.internalRemarks   = this.snView.internalRemarks ? this.snView.internalRemarks.trim() : null;
        request.client            = this.snView.client ? this.snView.client : null;
        request.district          = this.snView.district ? this.snView.district : null;
        request.location          = this.snView.location ? this.snView.location : null;
        request.equipmentCategory = this.snView.equipmentCategory ? this.snView.equipmentCategory : null;
        request.equipmentType     = this.snView.equipmentType ? this.snView.equipmentType : null;
        request.priority          = this.snView.priority;
        request.hashtagId         = this.snView.selectedTag ? this.snView.selectedTag._id: undefined;
        request.fehdVenue         = this.snView.fehdVenue;
        request.assetNumber       = this.snView.haEquipmentNumber ? this.snView.haEquipmentNumber.trim() : null;

    request.remarks = [];
    if (this.snView.remark && this.snView.remark.trim().length > 0) {
      request.remarks.push({
        _id           : this.snView.draftedRemarkId,
        visibilityType: this.snView.remarkVisibility,
        remark        : this.snView.remark.trim(),
      });
    }
    if (this.additionalRemark && this.additionalRemark.trim().length > 0) {
      const visibilityType = JMENUM.RemarkVisibility.PUBLIC;
      request.remarks.push({
        _id           : undefined,
        visibilityType: visibilityType,
        remark        : this.additionalRemark.trim(),
      });
    }
    if(this.reRoutingRemarks && this.reRoutingRemarks.remark)
    {
      request.remarks.push(this.reRoutingRemarks);
    }

    request.isNotificationDeferred = nextOperationTime ? true : false;
    request.disseminationStartTime = nextOperationTime;
    request.selectedRuleSequence   = this.snView.selectedRule.ruleSequence;
    request.workCentre             = this.snView.selectedRule.workCentre;
    request.teamId                 = this.snView.selectedRule.team._id;

    if (this.selectedSN) { // for submitting an draft sn
      request.snNumber = this.selectedSN.snNumber;
      request.version = this.selectedSN.version;
    }

    if (this.snSource == "crm") {
      request.crmData = {
        externalRefId: this.crmExternalRefId,
        externalCallbackUrl: this.crmCallbackURL
      }
    }

    //TODO: handle edit mode attachment
    if (this.mode != "edit") {
      request.attachmentDataList = (this.snView.attachmentList) ? this.snView.attachmentList.map(element => { return { name: element.name, data: (<string>element.data).split(',')[1] } }) : [];
    }

    this.enableInput(false);
    const response: JM.JMResponseSnSubmit = await AppDelegate.sendJMRequest(request);
    this.enableInput(true);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.selectedSN = response.payload;
    // callback if it is created from CRM link
    // if(this.snSource == "crm") {
    //   this.sendCRMCallback(this.selectedSN);
    // }
    this.cacheSnInput(null);
    this.router.navigate(["/sn/view/", this.selectedSN.snNumber]);
  }

  // Save new sn to draft or update draft status sn
  private async requestDraftSn() {
    const request = new JM.JMRequestSnDraft();

    // handle initial draft
    request.sourceSystem         = Constants.SYSTEM_NAME;
    request.sourcePlatform       = Constants.PLATFORM_NAME;
    request.contactPerson        = this.snView.contactPerson;
    request.contactNumber        = this.snView.contactNumber;
    request.email                = this.snView.email ? this.snView.email.trim() : null;
    request.locationDetail       = this.snView.locationDetail ? this.snView.locationDetail.trim() : null;
    request.faultDetail          = this.snView.faultDetail ? this.snView.faultDetail.trim() : null;
    request.internalRemarks      = this.snView.internalRemarks ? this.snView.internalRemarks.trim() : null;
    request.client               = this.snView.client ? this.snView.client : null;
    request.district             = this.snView.district ? this.snView.district : null;
    request.location             = this.snView.location ? this.snView.location : null;
    request.equipmentCategory    = this.snView.equipmentCategory ? this.snView.equipmentCategory : null;
    request.equipmentType        = this.snView.equipmentType ? this.snView.equipmentType : null;
    request.priority             = this.snView.priority;
    request.hashtagId            = this.snView.selectedTag ? this.snView.selectedTag._id : undefined;
    request.selectedRuleSequence = this.snView.selectedRule ? this.snView.selectedRule.ruleSequence : undefined;
    request.teamId               = this.snView.selectedRule ? this.snView.selectedRule.team._id: undefined;
    request.fehdVenue            = this.snView.fehdVenue;
    request.assetNumber          = this.snView.haEquipmentNumber ? this.snView.haEquipmentNumber.trim() : null;

    //TODO: handle edit mode attachment
    if(this.mode != "edit") {
      request.attachmentDataList = (this.snView.attachmentList)?this.snView.attachmentList.map(element => {return {name:element.name,data:(<string>element.data).split(',')[1]}}): [];
    }

    request.remarks = [];
    if (this.selectedSN) {  // editing a draft sn
      request.snNumber = this.selectedSN.snNumber;
      request.version = this.selectedSN.version;
    }
    if (this.snView.remark && this.snView.remark.trim().length > 0) {
      request.remarks = [{
        _id: this.snView.draftedRemarkId,
        visibilityType: this.snView.remarkVisibility,
        remark: this.snView.remark.trim(),
      }];
    }
    if (this.snSource == "crm") {
      request.crmData = {
        externalRefId: this.crmExternalRefId,
        externalCallbackUrl: this.crmCallbackURL
      }
    }

    this.enableInput(false);
    const response: JM.JMResponseSnDraft = await AppDelegate.sendJMRequest(request);
    this.enableInput(true);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.selectedSN = response.payload;
    this.cacheSnInput(null);
    this.updateSnViewRemark();

    this.openSnackBar(this.translateService.instant("pages.sn.saved"));
    this.router.navigate(["/sn/create/", this.selectedSN.snNumber, "edit"]);
  }

  private async requestCopyInNew() {
    const request = new JM.JMRequestSnDraft();

    // no team and remark information
    request.sourceSystem      = Constants.SYSTEM_NAME;
    request.sourcePlatform    = Constants.PLATFORM_NAME;
    request.contactPerson     = this.snView.contactPerson;
    request.contactNumber     = this.snView.contactNumber;
    request.email             = this.snView.email? this.snView.email.trim(): null;
    request.locationDetail    = this.snView.locationDetail? this.snView.locationDetail.trim(): null;
    request.faultDetail       = this.snView.faultDetail? this.snView.faultDetail.trim(): null;
    request.internalRemarks   = this.snView.internalRemarks? this.snView.internalRemarks.trim(): null;
    request.client            = this.snView.client ? this.snView.client : null;
    request.district          = this.snView.district ? this.snView.district : null;
    request.location          = this.snView.location ? this.snView.location : null;
    request.equipmentCategory = this.snView.equipmentCategory ? this.snView.equipmentCategory : null;
    request.equipmentType     = this.snView.equipmentType ? this.snView.equipmentType : null;
    request.priority          = this.snView.priority;
    request.remarks           = [];
    request.fehdVenue         = this.snView.fehdVenue;
    request.assetNumber       = this.snView.haEquipmentNumber ? this.snView.haEquipmentNumber.trim() : null;

    if (this.snSource == "crm" && (!this.snView.status || this.snView.status == JMENUM.SnStatus.DRAFT)) {
      request.crmData = {
        externalRefId: this.crmExternalRefId,
        externalCallbackUrl: this.crmCallbackURL
      }
    }

    this.enableInput(false);
    const response: JM.JMResponseSnDraft = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.enableInput(true);
      return;
    }
    this.enableInput(true);

    let newSn = response.payload;
    window.open('/sn/create/' + newSn.snNumber + '/edit', '_blank');
  }

  async requestVenderNameList(contractNumberList): Promise<void> {
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.contractNumber    = contractNumberList;
    request.expired           = JMENUM.RequestExpired.BOTH;
    request.parameters        = ["contractNumber", "vendorName"];


    const response:JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
      this.openErrorBar(response);
      return;
    }

    response.payload.records.forEach(contract => {
      this.venderNameDict[contract.contractNumber] = contract.vendorName;
    });

    this.renderTable();
  }

  // private requestHaEquipmentNumberOptions(isUseSnViewHaEquipmentNumber: boolean) {
  //   this.haEquipmentNumbersOptionsPage = 1;

  //   let request                = new JM.JMRequestEquipmentsHSDEquipmentSummary();
  //       request.hasHAEquipment = true;
  //       request.parameters     = ['assetNumber', 'HAEquipment', 'equipmentNumber']
  //       request.pageSize       = this.HA_EQUIPMENT_NUMBER_OPTION_PAGE_SIZE;
  //       request.pageNumber     = this.haEquipmentNumbersOptionsPage;
  //       request.sortBy         = 'assetNumber';

  //   if (isUseSnViewHaEquipmentNumber && this.snView.haEquipmentNumber) {
  //     request.assetNumber = [this.snView.haEquipmentNumber];
  //   }

  //   if (this.searchHaEquipmentNumberKeywords && this.searchHaEquipmentNumberKeywords != "") {
  //     request.filter = { assetNumber: this.searchHaEquipmentNumberKeywords };
  //   }

  //   JM.JMConnector.sendEquipmentsHSDEquipmentSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseEquipmentsHSDEquipmentSummary) => {
  //     if (error) {
  //       this.handleJMError(error);
  //       return;
  //     }

  //     if (!response || !response.code || response.code != 200 || !response.payload) {
  //       this.openErrorBar(response);
  //       return;
  //     }

  //     let totalCount = response.payload.totalCount;
  //     this.haEquipmentNumbersOptionsTotalPage = Math.ceil(totalCount/request.pageSize);
  //     this.setHaEquipmentNumbersOptions(response.payload.records);
  //     this.onInputUpdated(false);
  //   });
  // }

  //===================================================================================================================
  // Setter
  private setClientsOptions(clientShortNameArray:string[]):void {
    this.clientsOptions = [];
    for (let clientCode of clientShortNameArray) {
      let client = JM.JMConnector.getClient(clientCode);
      if (client) { // if the client is still active
        this.clientsOptions.push({
          value: clientCode,
          label: client.clientShortName + ' - ' + (client.name[this.lang] ? client.name[this.lang] : client.name["en"]),
        })
      } else {
        console.error("a rule is covering an inactive client: " + clientCode);
      }
    }
    this.clientsOptions.sort(function(a,b) {
      return (b.value > a.value)?-1:1;
    });
  }
  private setDistrictsOptions(districtCodeArray): void {
    // this.districtsOptions = districtsResult.map(did => { return {id:did, displayName:this.translate('district.' + did)}});
    this.districtsOptions = [];
    for (let districtCode of districtCodeArray) {
      this.districtsOptions.push({
        value: districtCode,
        label: this.translate('district.' + districtCode),
      })
    }
    this.districtsOptions.sort(function(a,b) {
      return (b.value > a.value)?-1:1;
    });

    if (this.districtsOptions.length == 1) {
      this.snView.district = districtCodeArray[0];
      this.cacheSnInput(this.snView);
    }
    // this.reloadRoutingTable();
  }

  private setLocationsOptions(locationDetailArray: any[], append=false): void {
    // just append will not trigger ng-selct change detection
    this.locationsOptions = append ? JSON.parse(JSON.stringify(this.locationsOptions)) : [];

    for (let location of locationDetailArray) {
      let description = location.description[this.lang] ? location.description[this.lang] : location.description["en"];
      let name = description + ' (' + location.code + ')';
      let locationOption: LocationsOption = {
        value: location.code,
        label: name,
        descriptionEn: location.description["en"] ? location.description["en"] : "",
        descriptionZh: location.description["zh"] ? location.description["zh"] : ""
      }
      if(this.isVVIP_SRAEnable){
        locationOption = {
          ...locationOption,
          isSpecialRequestAlert : this.isSpecialRequestAlert(location.code)
        };
      }
      this.locationsOptions.push(locationOption);
      this.locationDistrictMapping[location.code] = location.districtCode;
      this.locationFehdVenueMapping[location.code] = location.fehdVenues;
    }
  }

  isSpecialRequestAlert = (code:string) : boolean => {
    return this.SRALocationSet.has(code)
  }

  private setHaEquipmentNumbersOptions(hsdEquipments: JMOBJ.HSDEquipment[], append = false): void {
    // just append will not trigger ng-selct change detection
    this.haEquipmentNumbersOptions = append ? JSON.parse(JSON.stringify(this.haEquipmentNumbersOptions)) : [];

    for (let HsdEquipment of hsdEquipments) {
      const haEquipment = {
        assetNumber    : HsdEquipment.assetNumber,
        equipmentNumber: HsdEquipment.equipment ? HsdEquipment.equipment.equipmentNumber: null,
        haHospitalCode : HsdEquipment.HAEquipment.hospitalCode,
        hsdHospitalCode: HsdEquipment.hospitalLocation
      }

      this.haEquipmentNumbersOptions.push(haEquipment);
      this.haEquipmentDict[HsdEquipment.assetNumber] = haEquipment;
    }
  }

  private setEquipmentCategoriesOptions(categoryCodeArray): void {
    // filter according to selected equipment type
    let availableCatArr = null;
    if (this.snView.equipmentType) {
      availableCatArr = JM.JMConnector.getEquipmentCategoryCodeFromType(this.snView.equipmentType);
    }

    this.equipmentCategoriesOptions = [];
    for (let key of categoryCodeArray) {
      if (availableCatArr == null || (availableCatArr && availableCatArr.includes(key))) {
        let cat = JM.JMConnector.getEquipmentCategory(key);
        let item = {
          value: key,
          label: cat.code + ' - ' + cat.description[this.lang],
        }
        this.equipmentCategoriesOptions.push(item);
      }
    }
    this.equipmentCategoriesOptions.sort((a,b) => {
      return (b.value > a.value) ? -1 : 1;
    });
  }

  private setEquipmentTypesOptions(typeCodeArray): void {
    // if equip cat is selected, filter out those not in the cat
    let availableTagArr = null;
    if (this.snView.equipmentCategory) {
      availableTagArr = JM.JMConnector.getEquipmentTypeCodeFromCategory(this.snView.equipmentCategory);
    }

    this.equipmentTypesOptions = [];
    for (let code of typeCodeArray) {
      if (availableTagArr == null || (availableTagArr && availableTagArr.includes(code))) {
        let typeObj = JM.JMConnector.getEquipmentType(code);
        this.equipmentTypesOptions.push({
          value: code,
          label: typeObj.code + ' - ' + typeObj.description[this.lang],
        });
      }
    }
    this.equipmentTypesOptions.sort(function(a,b) {
      return (b.value > a.value)?-1:1;
    });


  }

  private setRoutingChoicesTableLoading(enable: boolean) {
    this.showLoadingWheel = this.currentPage && enable;
    this.tablexParam.isLoadingTable = !this.currentPage && enable;

    // disable row click when table loading
    if (enable) {
      this.tablexParam.onRowClicked = null;
    } else {
      this.tablexParam.onRowClicked = this.disableEditTeam ? null : this.onRowClicked;
      this.isReloadingRoutingTable = false;
    }
  }

  private _filterContactNumber(value: string): string[] {
    const filterValue = value;
    return this.contactNumberOptions.filter(option => option.indexOf(filterValue) === 0);
  }

  inputNumberWithIndex(event: any, index) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    this.snView.contactNumber[index] = event.target.value;
  }

  private cacheSnInput(sn) {
    if (this.mode == 'new') {
      let snInput = sn ? Object.assign({}, sn) : null;
      if (snInput) {
        snInput.attachmentList = [];
      }

      Session.setSnCreateSnInputForm(snInput);
    }
  }

  //===================================================================================================================
  // Action button functions
  onClickedActionButtonSave(): void {
    if (this.checkSnValidForSave(this.snView)) {  // check and remove null contact
      this.saveClientInformation();
      this.requestDraftSn();
    }
  }

  onClickedActionButtonCopyInNew(): void {
    if (this.checkSnValidForSave(this.snView)) {;
      this.requestCopyInNew();
    }
  }

  async requestTeamNextOperationTime() {
    const teamId         = this.snView.selectedRule.team._id;
    const handlingTeamId = this.snView.selectedRule.team.handlingTeam;

    const request = new JM.JMRequestTeamsGetTeamNextOperationTime();
    request.idList = [teamId];

    if (handlingTeamId) {
      request.idList.push(handlingTeamId);
    }

    const response:JM.JMResponseTeamsGetTeamNextOperationTime = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
      this.openErrorBar(response);
      return;
    }

    let teamNextOperationTime = undefined;
    let handlingTeamNextOperationTime = undefined;

    if (response.payload.records[teamId] !== undefined) {
      teamNextOperationTime = response.payload.records[teamId]; // null or date
    }
    if (handlingTeamId && response.payload.records[handlingTeamId] !== undefined) {
      handlingTeamNextOperationTime = response.payload.records[handlingTeamId]; // null or date
    }

    this.manualInstructionPreActionFormData = this.snView.selectedRule.team.manualInstructions[''+this.snView.priority].preAction;
    this.manualInstructionTeam = this.snView.selectedRule.team;
    this.manualInstructionTeamNextOperationTime = teamNextOperationTime;
    this.manualInstructionHandlingTeamNextOperationTime = handlingTeamNextOperationTime;
    this.manualInstructionFormPanel.toggle();
  }

  private onClickedActionButtonSubmit(): void {
    if (this.checkSnValidForSubmit()) {
      this.saveClientInformation();

      if (this.hasManualInstruction(this.snView.selectedRule.team)) {
        // always get latest next operation time
        this.requestTeamNextOperationTime();
      } else {
        if (this.checkSnValidForSubmit()) {
          this.askSubmitSn();
        }
      }
    }
  }

  onClickedActionButtonClear(updateFilterValue = true): void {

    this.clearInput();
    this.clearOptions();
    this.clearCRMInput();

    // remove local storage
    this.cacheSnInput(null);
    this.updateSnView();
  }

  onClickedActionButtonClose(): void {
    this.cacheSnInput(null);
    if (this.snView && this.snView.snNumber) {
      this.router.navigate(["/sn/view/", this.snView.snNumber])
    } else {
      this.router.navigate(['']);
    }
  }
  //-------------
  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = this.translateService.instant(button.name);
      })
      this.showPopUpAlert(this.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  addActionBtn(buttonStatus: ActionButtonSn): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.sn][buttonStatus];

    if (actionButton.buttons && !this.authorizationService.hasPermission(actionButton.permission))
     return;

    if (buttonStatus == ActionButtonSn.save) {
      if (this.selectedSN && this.selectedSN.status == JMENUM.SnStatus.DRAFT) {
        if (!this.authorizationService.hasPermission(Permission.snDraft))
          return;
      } else {
        // check is the create user or has sn update permission
        if (!this.authorizationService.hasPermission(Permission.snUpdate))
          return;
      }
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
              actionButton.buttons[0].name : this.translateService.instant("global.yes"),
        handler: () => {
          switch (buttonStatus) {
            case ActionButtonSn.save:
              this.onClickedActionButtonSave();
              break;
            case ActionButtonSn.copyInNew:
              this.onClickedActionButtonCopyInNew();
              break;
            case ActionButtonSn.submit:
              this.onClickedActionButtonSubmit();
              break;
            case ActionButtonSn.clear:
              this.onClickedActionButtonClear();
              break;
            case ActionButtonSn.close:
              this.onClickedActionButtonClose();
              break;
            default:
              break;
          }
        }
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
              actionButton.buttons[1].name : this.translateService.instant("global.no"),
      }
    ]
    this.actionButtonData.push(actionButton);
  }

    // submit function
  onManualInstructionSubmitClicked(remark: string) {
    if (!remark || remark.trim().length === 0) return;
    this.additionalRemark = remark.trim();
    this.askSubmitSn();
  }

  onRowClicked = (index, row: any) => {
    if (!this.disableEditTeam) {
      this.selectRow(index, null);
    }
  }

  onTagButtonClicked = (button) => {
    button.class = "selected-tag-button"; // onclick effect

    let object = button.object;
    let rule = object.rule;
    let tag = object.tag;
    let index = object.index;

    this.selectRow(index, tag);
  }

  onAddContactNumberButtonClicked() {
    if (this.snView.contactNumber.length < this.MAX_CONTACT_NUMBER_COUNT)
      this.snView.contactNumber.push("");
  }

  onHideContactNumberButtonClicked(index: number) {
    this.snView.contactNumber.splice(index, 1);
  }

  onLocaitonChange() {
    this.snView.fehdVenue = null;
  }

  onEquipmentTypeChanged() {
    let type = this.snView.equipmentType;
    // If the Eq. Cat has a value, system should not change it automatically after select a Eq. Type.
    if (type && !this.snView.equipmentCategory) {
      this.snView.equipmentCategory = JM.JMConnector.getEquipmentCategoryCodeFromType(type)[0];
    }

   this.onInputUpdated(true);
  }

  onFilterChanged = (event, index, header, filter) => {
    this.tablexFilter = filter;

    this.snView.teamName = this.tablexFilter["maintenance-team"];
    this.snView.workCentre = this.tablexFilter["work-centre"];
    this.snView.serachTagKey = this.tablexFilter["details"];

    if (this.mode == 'new') {
      this.cacheSnInput(this.snView);
    }

    if(this.tablexFilter["maintenance-team"] || this.tablexFilter["work-centre"] || this.tablexFilter["details"]) {
      this.reloadRoutingTable();
    }else{
      this.reloadRoutingTable();
      this.tablexParam['content'] = [];
      this.tablexParam['highlightedRows'] = [];
    }
  }

  private async setLocationOptions(location: string[]) {
    if (!location) return;

    const request = new JM.JMRequestLocationsLocationSummary();
    request.location = location;

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    this.setLocationsOptions(response.payload.records);
    // this.onInputUpdated(false);
  }

  async onOpenedClient() {
    if (this.snView.unlockClientLocationEquipment) { // if ro team
      this.setClientsOptions(JM.JMConnector.getAllClient().map(client => client.clientShortName));
    } else {
      if (!this.isClientOptionUpdated) {
        this.isClientDropdownLoading = true;
        const request = new JM.JMRequestRoutingRulesCriteriaClient();
        request.pageSize = 1000;
        request.pageNumber = 1;

        if (this.snView.location)          request.location = this.snView.location;
        if (this.snView.district)          request.district = this.snView.district;
        if (this.snView.equipmentCategory) request.equipmentCategory = this.snView.equipmentCategory;
        if (this.snView.equipmentType)     request.equipmentType = this.snView.equipmentType;

        const response:JM.JMResponseRoutingRulesCriteriaClient = await AppDelegate.sendJMRequest(request);
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }
        this.setClientsOptions(response.payload.clients);
        this.isClientDropdownLoading = false;
        this.isClientOptionUpdated = true;
      }
    }
  }

  async onOpenedDistrict() {
    if (this.snView.unlockClientLocationEquipment) { // if ro team is selected
      this.setDistrictsOptions(JM.JMConnector.getAllDistrictCode());
    } else {
      if (!this.isDistrictOptionUpdated) {
        this.searchDistrictDropdown();
      }
    }
  }

  onClosedLocation() {
    // if last search is by entering key word
    if (this.searchLocationKeywords) {
      this.searchLocationKeywords = null; // clear keyword
      this.isLocationOptionUpdated = false; // and get location list later
      this.locationsOptions = [];
      this.locationsOptionsPage = 1;
    }
  }

  async onOpenedLocation() {
    try{
      if (!this.isLocationOptionUpdated) {
        if (!this.snView.unlockClientLocationEquipment) {
          this.searchLocationDropdown();
          
        } else {
          this.searchAllLocationDropdown();
        }
      }
    } catch(e) {
      console.error(e);
    }
  }

  async onOpenedEquipmentCategory() {
    if (this.snView.unlockClientLocationEquipment) {
      this.setEquipmentCategoriesOptions(JM.JMConnector.getAllEquipmentCategoryCode());

     } else {
      if (!this.isEquipmentCategoryOptionUpdated) {
        this.isEquipmentCategoryDropdownLoading = true;
        const request = new JM.JMRequestRoutingRulesCriteriaEquipmentCategory();
        
        if (this.snView.location)          request.location = this.snView.location;
        if (this.snView.client)            request.client = this.snView.client;
        if (this.snView.district)          request.district = this.snView.district;
        if (this.snView.equipmentType)     request.equipmentType = this.snView.equipmentType;

        const response:JM.JMResponseRoutingRulesCriteriaEquipmentCategory = await AppDelegate.sendJMRequest(request);
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }
        this.setEquipmentCategoriesOptions(response.payload.equipmentCategories);
        this.isEquipmentCategoryDropdownLoading = false;
        this.isEquipmentCategoryOptionUpdated = true;
      }
    }
  }

  async onOpenedEquipmentType() {
    if (this.snView.unlockClientLocationEquipment) {
      this.setEquipmentTypesOptions(JM.JMConnector.getAllEquipmentTypeCode());

    } else if (!this.isEquipmentTypeOptionUpdated) {
      this.isEquipmentTypeDropdownLoading = true;
      const request = new JM.JMRequestRoutingRulesCriteriaEquipmentType();
      
      if (this.snView.location)          request.location = this.snView.location;
      if (this.snView.client)            request.client = this.snView.client;
      if (this.snView.district)          request.district = this.snView.district;
      if (this.snView.equipmentCategory) request.equipmentCategory = this.snView.equipmentCategory;
      
      const response:JM.JMResponseRoutingRulesCriteriaEquipmentType = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      this.setEquipmentTypesOptions(response.payload.equipmentTypes);
      this.isEquipmentTypeDropdownLoading = false;
      this.isEquipmentTypeOptionUpdated = true;
    }
  }

  async onLocaitonChanged() {
    this.clearAllDropdown();
     // if location is changed and the district is not set
    if (this.snView.location || this.searchLocationKeywords) {
      if (this.snView.district == null) {
        await this.searchDistrictDropdown();
      } 
    } else {
      this.setDistrictsOptions([]);
    }
    this.reloadRoutingTable();
  }
  onRuleFilterUpdated() {
    this.clearAllDropdown();    
    this.onInputUpdated(true);
  }

  onInputUpdated(findRule:boolean=false) {
    if (findRule) {
      this.updateSnView();
    }
    if (this.mode == 'new') {
      this.cacheSnInput(this.snView);
    }
  }

  onSearchKeyChanged() {
    this.searchTerms.next();
  }

  onPriorityChanged(priority) {
    this.snView.priority = priority;
    this.onInputUpdated(true);
  }

  onFehdVenueChanged(fehdVenue) {
    this.snView.fehdVenue = fehdVenue;
    this.onInputUpdated(true);
  }

  onInformationButtonClicked = async (button) => {
    const team: JMOBJ.Team = button.object;
    const contactSequences = team.contactSequences[this.snView.priority];
  
    if (!contactSequences) {
      this.openErrorBar();
      return;
    }
  
    const dialogTitle = '';
    const inhouseMembertablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      currentPage: 1,
      pageCount: 1,
      headers: [
        { id: 'contact', name: 'popup.contact-list.column.contact', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'post', name: 'popup.contact-list.column.post', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'name', name: 'popup.contact-list.column.name', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'phone', name: 'popup.contact-list.column.phone', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'mobile', name: 'popup.contact-list.column.mobile', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'fax', name: 'popup.contact-list.column.efax', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'email', name: 'popup.contact-list.column.email', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'remark', name: 'popup.contact-list.column.remark', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      ],
      content: []
    }
    const nonPmsmcMemberTablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      currentPage: 1,
      pageCount: 1,
      headers: [
        { id: 'member-name', name: 'popup.contact-list.column.member-name', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'phone', name: 'popup.contact-list.column.phone', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'sms', name: 'popup.contact-list.column.sms', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'fax', name: 'popup.contact-list.column.fax', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'email', name: 'popup.contact-list.column.email', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      ],
      content: []
    }
    const pmsmcMemberTablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      currentPage: 1,
      pageCount: 1,
      headers: [
        { id: 'member-name', name: 'popup.contact-list.column.member-name', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'team-name', name: 'popup.contact-list.column.team-name', type: TablexColumnType.MultiLine, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'role', name: 'popup.contact-list.column.role', type: TablexColumnType.MultiLine, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'phone', name: 'popup.contact-list.column.phone', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'fax', name: 'popup.contact-list.column.fax', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'email', name: 'popup.contact-list.column.email', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      ],
      content: []
    }
  
    const inhouseHandlingParty = team.handlingParty == JMENUM.HandlingParty.INHOUSE;
    const pmsmcHandlingParty = team.handlingParty == JMENUM.HandlingParty.PMSMC;
    const postNameSet = new Set<string>();
  
    const requestContactPersonList = async (postNameSet: Set<string>) => {
      const request = new JM.JMRequestGetContactPersonsByPost();
      request.posts = Array.from(postNameSet);
  
      const response: JM.JMResponseGetContactPersonsByPost = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
  
      return response.payload;
    };
  
    const requestPmsmcPost = async (contractNumber: string) => {
      const request = new JM.JMRequestPostsGetPostListByContract();
      request.contractNumber = contractNumber;
      request.pageNumber = 1;
      request.pageSize = 1000;
  
      const response: JM.JMResponsePostsGetPostListByContract = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
  
      return response.payload.records;
    };
  
    for (let i = 0; i < 5; i++) {
      if (!contactSequences[i]) { continue; }
      contactSequences[i].forEach((post: string) => {
        postNameSet.add(post);
      });
    }
  
    if (inhouseHandlingParty && postNameSet.size == 0) {
      let content = [InformationDialogHelper.createTablex(inhouseMembertablexParam)];
      this.showInformationDialog(dialogTitle, content);
      return;
    }
  
    let promises: any[] = [requestContactPersonList(postNameSet)];
    if (pmsmcHandlingParty) {
      promises.push(requestPmsmcPost(team.contractNumber))
    }
  
    button.isLoading = true;
    Promise.all(promises).then(values => {
      button.isLoading = false;
      const contactPersonList: JMOBJ.ContactPerson[] = values[0];
      const pmsmcPostList: JMOBJ.Post[] = pmsmcHandlingParty ? values[1] : null;
  
      const contactPersonMap = new Map<string, JMOBJ.ContactPerson>();
      const contactSequenceName = [
        JMLanguage.translate('pages.sn.routing-contact-list-table.contractor'),
        JMLanguage.translate('pages.sn.routing-contact-list-table.1st'),
        JMLanguage.translate('pages.sn.routing-contact-list-table.2nd'),
        JMLanguage.translate('pages.sn.routing-contact-list-table.3rd'),
        JMLanguage.translate('pages.sn.routing-contact-list-table.ro')
      ];
  
      let dialogContent = [];
      let inhouseMembertableContent = [];
      let nonPmsmcMemberTableContent = [];
  
      const getPmsmcMemberContent = (postList: JMOBJ.Post[]) => {
        if (!Array.isArray(postList)) { return []; }
        return postList.map((post) => {
          return [
            post.employeeName,
            post.vendorTeamList.map((team) => team.name),
            post.roles,
            post.phone,
            post.fax,
            post.email,
          ];
        });
      };
  
      contactPersonList.forEach(cp => {
        contactPersonMap.set(cp.post, cp);
      });
  
      for (let i = 0; i < 5; i++) {
        for (let j = 0; j < contactSequences[i].length; j++) {
          const contact = contactPersonMap.get(contactSequences[i][j]);
          if (!contact) { continue; }
          if (i == 0) { // contractor 
            const data = [
              contact.name,
              contact.phone,
              contact.mobile,
              contact.fax,
              contact.email
            ];
            nonPmsmcMemberTableContent.push(data);
          } else {
            const data = [
              contactSequenceName[i],
              contactSequences[i][j],
              contact.name,
              contact.phone,
              contact.mobile,
              contact.fax,
              contact.email,
              contact.remarks
            ];
            inhouseMembertableContent.push(data);
          }
        }
      }
  
      inhouseMembertablexParam.content = inhouseMembertableContent;
      nonPmsmcMemberTablexParam.content = nonPmsmcMemberTableContent;
      pmsmcMemberTablexParam.content = getPmsmcMemberContent(pmsmcPostList);
  
      if (inhouseHandlingParty) {
        dialogContent = [InformationDialogHelper.createTablex(inhouseMembertablexParam)];
      } else {
        const contractMemberTablexParam = pmsmcHandlingParty ? pmsmcMemberTablexParam : nonPmsmcMemberTablexParam;
        dialogContent = [
          InformationDialogHelper.createText(this.translate('pages.sn.routing-contact-list-table.contractor-contact-list')),
          InformationDialogHelper.createTablex(contractMemberTablexParam),
          InformationDialogHelper.createText(this.translate('pages.sn.routing-contact-list-table.inhouse-contact-list')),
          InformationDialogHelper.createTablex(inhouseMembertablexParam)
        ];
      }
  
      this.showInformationDialog(dialogTitle, dialogContent);
    });
  }

  onRuleManualInstructionButtonClicked = (button) => {
    let rule = button.object;
    this.manualInstructionPreActionListData = rule.team.manualInstructions[''+this.snView.priority].preAction;

    // show pre-action state of manual instruction
    this.manualInstructionListPanel.toggle();
  }

  onRemarkVisibilityChanged(visibility) {

    this.snView.remarkVisibility = visibility;
    this.onInputUpdated(false);
  }

  onClearFilterField() {
    this.tablexParam.isLoadingTable = true;
    this.tablexParam['content'] = [];
    this.tablexParam['highlightedRows'] = [];
    this.unselectRoutingChoice();
    this.updateSnView();
  }

  onUnlockClientLocationEquipmentChanged = (event) => {
    this.tablexParam.isLoadingTable = true;
    this.tablexParam['content'] = [];
    this.tablexParam['highlightedRows'] = [];
    this.updateSnView();

    if (this.searchLocationKeywords && this.searchLocationKeywords != null) {
      this.searchLocationKeywords = null;
      this.locationsOptions = [];
      this.locationsOptionsPage = 1;
    }

    Session.setSnCreateSnInputForm(this.snView);  // save using RO team
  }

  onScroll = () => {
    let lastPage = (this.currentPage >= Math.ceil(this.lastContentCount/this.pageSize) + 1);  // + 1 for default rr
    if (
        window.innerHeight + window.scrollY + 1 >= document.body.scrollHeight &&
        !this.disableEditTeam &&
        !this.isRoutingChoicesTableLoading() &&
        !lastPage &&
        !this.showLoadingWheel
      ) {
        if (this.currentPage * this.pageSize < this.lastContentCount) {
          this.currentPage += 1;
          this.requestRoutingChoices();
        }
      }
  }

  //==============================
  // HA equipment number field event
  // onHaEquipmentNumberScrollToEnd = () => {
  //   if (this.haEquipmentNumbersOptionsPage < this.haEquipmentNumbersOptionsTotalPage) {
  //     this.haEquipmentNumbersOptionsPage += 1;

  //     let request                = new JM.JMRequestEquipmentsHSDEquipmentSummary();
  //         request.hasHAEquipment = true;
  //         request.parameters     = ['assetNumber', 'HAEquipment', 'equipmentNumber']
  //         request.pageSize       = this.HA_EQUIPMENT_NUMBER_OPTION_PAGE_SIZE;
  //         request.pageNumber     = this.haEquipmentNumbersOptionsPage;
  //         request.sortBy         = 'assetNumber';

  //     if (this.searchHaEquipmentNumberKeywords && this.searchHaEquipmentNumberKeywords != "") {
  //       request.filter = { assetNumber: this.searchHaEquipmentNumberKeywords };
  //     }

  //     JM.JMConnector.sendEquipmentsHSDEquipmentSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseEquipmentsHSDEquipmentSummary) => {
  //       if (error) {
  //         this.handleJMError(error);
  //         return;
  //       }

  //       if (!response || !response.code || response.code != 200 || !response.payload) {
  //         this.openErrorBar(response);
  //         return;
  //       }

  //       this.setHaEquipmentNumbersOptions(response.payload.records, true);
  //     });
  //   }
  // }

  onClearHaEquipmentNumber() {
    // this.searchHaEquipmentNumberKeywords = null;
    // this.searchHaEquipmentNumberObserver.next();
    this.hasHaEquipmentNumber = false;
    this.SelectedHaEquipment = {
      assetNumber: undefined,
      assetDescription: undefined,
      HACluster: undefined,
      regpc: undefined,
      ccsNumber: undefined,
      clientNumber: undefined,
      hospitalLocation: undefined,
      hospitalCode:undefined,
      functionalLocation:undefined
    };
    this.snView.haEquipmentNumber = undefined;



    // this.onClearFilterField();
  }

  // onSearchHaEquipmentNumberOptions(event) {
  //   this.searchHaEquipmentNumberKeywords = event.term;
  //   this.searchHaEquipmentNumberObserver.next();
  // }

  onFilterHaEquipmentNumberOptions(term: string, item: HaEquipmentOption) {
    return item.assetNumber.toLowerCase().includes(term.toLowerCase());
  }

  async onHaEquipmentNumberUpdated() {
    if (!this.snView.haEquipmentNumber) return;

    this.hasHaEquipmentNumber = true;

    // set default value
    this.snView.client = null;
    this.snView.priority = JMENUM.JMPriority.NonUrgent;
    this.snView.equipmentCategory = null;
    this.snView.equipmentType = null;
    this.snView.district = null;
    this.snView.location = null;

    // auto fill client, district and location (value and options)
    // console.log(this.haEquipmentDict[this.snView.haEquipmentNumber]);
    // const haEquipment: HaEquipmentOption = this.haEquipmentDict[this.snView.haEquipmentNumber];
    // if (haEquipment) {
    //   if (haEquipment.equipmentNumber) {
    //     await this.autoFillByEquipment(haEquipment.equipmentNumber);
    //   } else if (haEquipment.haHospitalCode) {
    //     await this.autoFillByHospitalCode(haEquipment.haHospitalCode);
    //   } else if (haEquipment.hsdHospitalCode) {
    //     await this.autoFillByHospitalCode(haEquipment.hsdHospitalCode);
    //   }
    // }

    if (this.SelectedHaEquipment) {
      if (this.SelectedHaEquipment.ccsNumber) {
        await this.autoFillByEquipment(this.SelectedHaEquipment.ccsNumber);
      } else if (this.SelectedHaEquipment.hospitalCode) {
        await this.autoFillByHospitalCode(this.SelectedHaEquipment.hospitalCode);
      } else if (this.SelectedHaEquipment.hospitalLocation) {
        await this.autoFillByHospitalCode(this.SelectedHaEquipment.hospitalLocation);
      }
    }

    this.onInputUpdated(false);
    this.unselectRoutingChoice();
    this.reloadRoutingTable();
  }

  /*
    auto-fill client, location and district
  */
  async autoFillByEquipment(equipmentNumber: string) {
    const request = new JM.JMRequestEquipmentsEquipmentSummary();
        request.equipmentNumber = [equipmentNumber];
        request.active          = JMENUM.RequestActive.BOTH;
        request.parameters      = ["equipmentNumber", "location", "clientShortName"];
        request.pageNumber      = 1;
        request.pageSize        = 1;

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (!response.payload.records || response.payload.records.length === 0) return;
    const client = response.payload.records[0].clientShortName;
    const location = response.payload.records[0].location;
    if (client) {
      this.snView.client = client;
      this.setClientsOptions([client]);
    }
    if (location) {
      await this.autoFillDistrictLocation(location);
    }
  }

  /*
    auto-fill client, location and district
  */
  async autoFillByHospitalCode(hospitalCode: string) {
    const request = new JM.JMRequestEquipmentsHospitalCodeLocationMapping();
    request.hospitalCode = hospitalCode;

    const response: JM.JMResponseEquipmentsHospitalCodeLocationMapping = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload.length === 0) return;

    const hospitalMapping = response.payload[0];
    if (hospitalMapping.client) {
      this.snView.client = hospitalMapping.client;
      this.setClientsOptions([hospitalMapping.client]);
    }
    if (hospitalMapping.location) {
      await this.autoFillDistrictLocation(hospitalMapping.location);
    }
  }

  /*
    set district and location(value and option)
  */
  async autoFillDistrictLocation(location: string) {
    const request = new JM.JMRequestLocationsLocationSummary();
    request.location = [location];
    request.includeSummary = true;
    request.pageNumber = 1;
    request.pageSize = 1;

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (!response.payload.records || response.payload.records.length === 0) return;

    // set location value and option
    this.snView.location = response.payload.records[0].code;
    this.setLocationsOptions(response.payload.records);

    // set district value and option
    this.snView.district = response.payload.records[0].districtCode;
    this.setDistrictsOptions(JM.JMConnector.getAllDistrictCode());
  }

  //==============================
  // location field event
  onLocationScrollToEnd = async () => {
    if (!this.isShownAllLocations) {
      this.locationsOptionsPage = this.locationsOptionsPage + 1;
      if (this.snView.unlockClientLocationEquipment) { // RO team case
        const request = new JM.JMRequestLocationsLocationSummary();
        if (this.snView.district) request.districtCode = [this.snView.district];
        if (this.searchLocationKeywords) request.locationDescription = this.searchLocationKeywords;
        request.includeSummary = true;
        request.pageSize = 100;
        request.pageNumber = this.locationsOptionsPage;
        request.locationOnly = true;
        request.parameters = ['description', 'code'];

        this.isLocationDropdownLoading = true;
        const scopeLocation = this.searchLocationKeywords;
        const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
        if(this.searchLocationKeywords !== scopeLocation) {
          return;
        }
        this.isLocationDropdownLoading = false;
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }
        
        if (response.payload.records.length > 0) {
          this.setLocationsOptions(response.payload.records, true);
        } else {
          this.isShownAllLocations = true;
        }
      } else { 
        this.searchLocationDropdown(this.searchLocationKeywords); 
      }
    }
  }

  onClearDistrict() {
    this.snView.location = null;
    this.onClearFilterField();
  }

  onClearLocation() {
    this.tablexParam.isLoadingTable = true;
    this.tablexParam['content'] = [];
    this.tablexParam['highlightedRows'] = [];
    this.unselectRoutingChoice();

    this.searchLocationKeywords = null;
    this.searchLocationObserver.next();
  }

  onSearchLocationOptions(event) {
    this.searchLocationKeywords = event.term;
    this.searchLocationObserver.next();
  }

  onFilterLocationOptions(term: string, item: any) {
    return true;
    // return item.value.toLowerCase().includes(term.toLowerCase()) ||
    //         item.descriptionEn.toLowerCase().includes(term.toLowerCase()) ||
    //         item.descriptionZh.toLowerCase().includes(term.toLowerCase());
  }

  onContactNumberChanged = (value:string) => {
    if (value in this.clientInformation) {
      let clientInfo = this.clientInformation[value];
      this.snView.client = clientInfo.client;
      this.snView.contactPerson = clientInfo.contactPerson;
      this.snView.email = clientInfo.email;
      this.snView.district = clientInfo.district;
      this.snView.location = clientInfo.location;
      this.snView.locationDetail = clientInfo.locationDetail;
      this.onInputUpdated(true);
    }
  }

  //--------------------------------------------------------
  // save and load function
  private saveClientInformation() {
    if (this.snView.saveClientInfo && this.snView.contactNumber && this.snView.contactNumber.length > 0) {
      for (const contactNumber of this.snView.contactNumber) {
        if (contactNumber && contactNumber.trim().length > 0) {
          this.clientInformation[contactNumber] = {
            client        : this.snView.client,
            contactPerson : this.snView.contactPerson,
            email         : this.snView.email,
            district      : this.snView.district,
            location      : this.snView.location,
            locationDetail: this.snView.locationDetail,
          };

          Session.setSnCreateClientDetail(this.clientInformation);
        }
      }
    }
  }

  private loadClientInformation() {
    return Session.snCreateClientDetail? Session.snCreateClientDetail: {};
  }

  updateSnView() {
    if (this.snView.haEquipmentNumber) {
      this.updateHaSnView();
    } else {
      this.updateNormalSnView();
    }
  }

  updateSnViewRemark() {
    if (this.selectedSN.remarks && this.selectedSN.remarks.length > 0) {
      let remark = this.selectedSN.remarks[0];

      this.snView.draftedRemarkId  = remark._id;
      this.snView.remark           = remark.remark;
      this.snView.remarkVisibility = remark.visibilityType;
    }
  }

  resetErrorField() {
    this.errorFields = {
      Client: false,
      District: false,
      Location: false,
      ContactPerson: false,
      ContactNumber: false,
      Email: false,
      HaEquipmentNumber: false,
      EquipmentCategory: false,
      EquipmentType: false,
      FaultDetails: false
    };
  }

  scrollToAnchor() {
    this.anchor.nativeElement.scrollIntoView({behavior: "auto"});
  }

  private askSubmitSn() {
    // let actionButton = ActionButtonDefinition[ActionButtonType.sn][ActionButtonSn.submit];

    if (this.snView.selectedRule.team.status == JMENUM.TeamStatus.CONTRACT_EXPIRED) {
      let buttons = [{
        name: this.translateService.instant("global.yes"),
        handler: () => {
          this.reRoutingRemarks = {
            _id: undefined,
            visibilityType: JMENUM.RemarkVisibility.PUBLIC,
            remark        : JMLanguage.translate("pages.sn.remark.msg.re-routing-expired-team",[this.snView.selectedRule.team.contractNumber]),
          }
          this.snView.selectedRule.team._id = this.snView.selectedRule.team.handlingTeam;

          let handlingTeam = this.handlingTeamObject[this.snView.selectedRule.team._id];
          if(handlingTeam && handlingTeam.nextOperationTime && this.snView.priority != JMENUM.JMPriority.Emergency) {
            this.askDeferSn();
          }else{
            this.handleRequestSubmitSn();
          }
        }
      },
      {
        name: this.translateService.instant("global.no"), handler: () => {
          // this.enableInput(true);
        }
      }];
      this.showPopUpAlert(this.translate("pages.sn.popup.route-to-handling-team.title"), "", buttons);

    } else if (this.snView.selectedRule.team.status == JMENUM.TeamStatus.CONTRACT_INEFFECTIVE) {
      let buttons = [
        {
          name: this.translateService.instant("global.yes"),
          handler: () => {
            if (!this.snView.selectedRule.team.nextOperationTime || this.snView.priority == JMENUM.JMPriority.Emergency) {
              // no defer in Emergency
              this.handleRequestSubmitSn();
            } else {
              this.askDeferSn();
            }
          }
        },
        {
          name: this.translateService.instant("global.no"),
          handler: () => { }
        }
      ];
      this.showPopUpAlert(this.translate("action.button.popup.ineffective-sn"), "", buttons);

    } else {
      let buttons = [
        {
          name: this.translateService.instant("global.yes"),
          handler: () => {
            if (!this.snView.selectedRule.team.nextOperationTime || this.snView.priority == JMENUM.JMPriority.Emergency) {
              // no defer in Emergency
              this.handleRequestSubmitSn();
            } else {
              this.askDeferSn();
            }
          }
        },
        {
          name: this.translateService.instant("global.no"),
          handler: () => { }
        }
      ];
      this.showPopUpAlert(this.translate("action.button.popup.submit"), "", buttons);
    }
  }
  private askDeferSn() {
    let nextOperationTime = this.snView.selectedRule.team.nextOperationTime;

    let handlingTeam = this.handlingTeamObject[this.snView.selectedRule.team._id];
    if (handlingTeam && handlingTeam.nextOperationTime && this.snView.priority != JMENUM.JMPriority.Emergency) {
      nextOperationTime = handlingTeam.nextOperationTime;
    }

    let buttons =
    [
      {
        name: this.translateService.instant("global.yes"),
        handler: () => {
          this.handleRequestSubmitSn(nextOperationTime);
        }
      },
      {
        name: (this.translateService.instant("global.no")),
        handler: () => {
          this.handleRequestSubmitSn();
        }
      }
    ];
    this.showPopUpAlert(
      this.translate("pages.sn.defer"),
      this.translate("pages.sn.defer-pop-up-message", [formatDate(nextOperationTime, "dd/MM/yyyy HH:mm", 'en-US')]),
      buttons
    );
  }

  private handleRequestSubmitSn(nextOperationTime:Date = null) {
    if (this.mode == 'new' || this.mode == 'edit') {
      this.requestSubmitSn(nextOperationTime);
    } else if (this.mode == 'reroute'){
      this.requestRerouteSn(nextOperationTime);
    }
  }


  private clearCRMInput() {
    this.crmCallbackURL = null;
    this.crmExternalRefId = null;
    this.snSource = null;
  }

  private clearInput() {
    this.clearAllDropdown();
    this.unselectRoutingChoice();
    this.hasHaEquipmentNumber = false;

    this.snView.snNumber = ''
    this.disablePriority = false;
    this.snView.client = null;
    this.snView.district = null;
    this.snView.location = null;
    this.searchLocationKeywords = null;
    this.snView.haEquipmentNumber = null;
    this.snView.equipmentCategory = null;
    this.snView.equipmentType = null;

    this.snView.priority = this.DEFAULT_PRIORITY;
    this.snView.includeNonOfficeHour = false;

    this.snView.teamName = null;
    this.snView.workCentre = null;
    this.snView.serachTagKey = null;

    this.snView.contactPerson = "";
    this.snView.contactNumber = [""];
    this.snView.email = "";

    this.snView.locationDetail = null;
    this.snView.faultDetail = null;
    this.snView.remark = null;
    this.snView.internalRemarks = null;

    this.snView.unlockClientLocationEquipment = false;

    this.tablexFilter["maintenance-team"] = "";
    this.tablexFilter["work-centre"] = "";
    this.tablexFilter["details"] = ""; // for filter equipment tag

    this.tablexParam['content'] = [];
    this.tablexParam['highlightedRows'] = [];

    this.errorFields = {
      Client: false,
      District: false,
      Location: false,
      ContactPerson: false,
      ContactNumber: false,
      Email: false,
      HaEquipmentNumber: false,
      EquipmentCategory: false,
      EquipmentType: false,
      FaultDetails: false
    };
  }

  private clearOptions() {
    this.haEquipmentNumbersOptions = [];
  }

  private isRoutingChoicesTableLoading(): boolean {
    return this.tablexParam.isLoadingTable || this.showLoadingWheel;
  }
  private clearAllDropdown() {
    this.isClientOptionUpdated = false;
    this.clientsOptions = [];

    this.isDistrictOptionUpdated = false;
    this.districtsOptions = [];

    this.searchLocationKeywords = null;
    this.isLocationOptionUpdated = false;
    this.locationsOptions = [];

    this.isEquipmentCategoryOptionUpdated = false;
    this.equipmentCategoriesOptions = [];

    this.isEquipmentTypeOptionUpdated = false;
    this.equipmentTypesOptions = [];

    this.isShownAllLocations = false; // need to get the location dropdown from page 1 again
    this.locationsOptionsPage = 1;
  }

  // set single entry to dropdown from snView
  private setDropdown() {
    if (this.snView) {
      if (this.snView.client) this.setClientsOptions([this.snView.client]);
      if (this.snView.location) this.setLocationOptions([this.snView.location]);
      if (this.snView.district) this.setDistrictsOptions([this.snView.district]);
      if (this.snView.equipmentCategory) this.setEquipmentCategoriesOptions([this.snView.equipmentCategory]);
      if (this.snView.equipmentType) this.setEquipmentTypesOptions([this.snView.equipmentType]);
    }
  }
  
  private hasManualInstruction(team) {
    let manualInstructions = team.manualInstructions;
    let priority = "" + this.snView.priority;

    return this.authorizationService.hasPermission(Permission.snManualInstructionView) &&
    team.enableManualInstructions &&
    manualInstructions &&
    manualInstructions[priority] &&
    manualInstructions[priority].preAction &&
    manualInstructions[priority].preAction.length;
  }

  // Validation function
  // private checkAllFilterValueEmpty(): boolean {
  //   return this.snView.client == null &&
  //           this.snView.district == null &&
  //           this.snView.location == null &&
  //           this.snView.equipmentCategory == null &&
  //           this.snView.equipmentType == null;
  // }

  private checkContactNumberValid(sn: any): boolean {
    if (!sn.contactNumber || !sn.contactNumber.length) {
      sn.contactNumber = [""];
      return false;
    }

    // remove empty contact number
    let tmpArr = [];
    sn.contactNumber.forEach(number => {
      if (number != "") tmpArr.push(number);
    });
    sn.contactNumber = tmpArr;

    if (!sn.contactNumber.length) {
      sn.contactNumber = [""];
      return false;
    }

    return true;
  }

  private checkSnValidForSave(sn: any): boolean {
    let result = true;
    let errorMsg = "";
    let errorFields = [];
    this.resetErrorField();

    // check email format
    if (this.snView.email && !isEmailVaild(this.snView.email.toString())) {
      errorMsg = "pages.sn.invalid-email-format";
      errorFields.push("Email");
      result = false;
    }

    if (!this.checkContactNumberValid(sn)) {
      errorMsg = "pages.sn.invalid-contact-number";
      errorFields.push("ContactNumber");
      result = false;
    }

    if (!sn.contactPerson || sn.contactPerson == "") {
      errorMsg = "pages.sn.fill-in-contact-person";
      errorFields.push("ContactPerson");
      result = false;
    }


    if (!result) {
      errorFields.forEach(field => {this.errorFields[field] = true});
      this.openSnackBar(this.translate(errorMsg));
      this.enableInput(true);
    }
    return result;
  }

  private checkSnValidForSubmit(): boolean {
    let result = true;
    let errorMsg = "";
    let errorFields = [];
    this.resetErrorField();

    const normalSnValidationFields = [
      {name: "Client",             value:this.snView.client},
      {name: "District",           value:this.snView.district},
      {name: "Location",           value:this.snView.location},
      {name: "ContactPerson",      value:this.snView.contactPerson},
      {name: "EquipmentCategory",  value:this.snView.equipmentCategory},
      {name: "EquipmentType",      value:this.snView.equipmentType},
      {name: "FaultDetails",       value:this.snView.faultDetail},
    ];
    const haSnValidationFields = [
      {name: "ContactPerson",      value:this.snView.contactPerson},
      {name: "FaultDetails",       value:this.snView.faultDetail},
    ];
    const validationFields = this.snView.haEquipmentNumber ? haSnValidationFields : normalSnValidationFields;

    // check selected routing choice
    // if (!this.hasSelectedRoutingChoiceDetail() || !this.selectedRoutingChoiceDetail.team) {
    if (!this.snView.selectedRule) {
      errorMsg = "pages.sn.select-a-team";
      result = false;
    }

    // check email format
    if (this.snView.email && !isEmailVaild(this.snView.email.toString())) {
      errorMsg = "pages.sn.invalid-email-format";
      errorFields.push("Email");
      result = false;
    }

    // check contactNumber
    if (!this.checkContactNumberValid(this.snView)) {
      errorMsg = "pages.sn.invalid-contact-number";
      errorFields.push("ContactNumber");
      result = false;
    }

    // check mandatory fields
    validationFields.forEach(field => {
      let value = field.value;
      if (!value || value == "") {
        errorMsg = "pages.sn.fill-in-all-the-mandatory-fields";
        errorFields.push(field.name);
        result = false;
      }
    });

    if (!result) {
      errorFields.forEach(field => {this.errorFields[field] = true});
      this.openSnackBar(this.translate(errorMsg));
      // this.enableInput(true);
    }
    return result;
  }


  //===================================================================================================================
  private async searchDistrictDropdown() {
    this.isDistrictDropdownLoading = true;
    const request = new JM.JMRequestRoutingRulesCriteriaDistrict();
    
    if (this.snView.location)          request.location = this.snView.location;
    if (this.snView.client)            request.client = this.snView.client;
    if (this.snView.equipmentCategory) request.equipmentCategory = this.snView.equipmentCategory;
    if (this.snView.equipmentType)     request.equipmentType = this.snView.equipmentType;

    const response:JM.JMResponseRoutingRulesCriteriaDistrict = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }
    this.setDistrictsOptions(response.payload.districts);
    this.isDistrictDropdownLoading = false;
    this.isDistrictOptionUpdated = true;
  }

  // search location from location summary
  private async searchAllLocationDropdown(location?:string) {
    const request = new JM.JMRequestLocationsLocationSummary();
    if (location) request.locationDescription = location;
    if (this.snView.district) request.districtCode = [this.snView.district];
    request.includeSummary = true;
    request.pageSize = 100;
    request.pageNumber = this.locationsOptionsPage;
    request.locationOnly = true;
    request.parameters = ['description', 'code'];

    this.isLocationDropdownLoading = true;
    const scopeLocation = location || this.searchLocationKeywords;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if(this.searchLocationKeywords !== scopeLocation) {
      return;
    }
    this.isLocationDropdownLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }

    let append = (this.locationsOptionsPage != 1);
    this.setLocationsOptions(response.payload.records, append);

    this.isLocationOptionUpdated = true;
  }

  // search distinct location from equipment
  private async searchLocationDropdown(location?:string) {
    this.isLocationDropdownLoading = true;
    const request = new JM.JMRequestRoutingRulesCriteriaLocation();
    request.pageSize = 20;
    request.pageNumber = this.locationsOptionsPage;
    
    
    if (location && location.length > 0) request.location = location;
    if (this.snView.client)              request.client = this.snView.client;
    if (this.snView.district)            request.district = this.snView.district;
    if (this.snView.equipmentCategory)   request.equipmentCategory = this.snView.equipmentCategory;
    if (this.snView.equipmentType)       request.equipmentType = this.snView.equipmentType;
    const scopeLocation = location || this.searchLocationKeywords;
    const response:JM.JMResponseRoutingRulesCriteriaLocation = await AppDelegate.sendJMRequest(request);
      // this.tablexParam.isLoadingTable = false;
      // if (error) {
      //   this.handleJMError(error);
      //   return;
      // }
    if(this.searchLocationKeywords !== scopeLocation) {
      return;
    }
    this.isLocationDropdownLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      this.openErrorBar(response);
      return;
    }
    this.isShownAllLocations = (response.payload.locations.length < request.pageSize);
    let append = (this.locationsOptionsPage != 1);
    this.setLocationsOptions(response.payload.locationDetails, append);
    // this.setDistrictsOptions(response.payload.districts);
    this.isLocationOptionUpdated = true;
  }

  private selectRow(index, tag):void {
    this.scrollToAnchor();
    if (index < this.routingRuleTeamArray.length) {
      let clickedRule = this.routingRuleTeamArray[index];
      let clickedTag = tag;
      let isClickSameRule = this.snView.selectedRule && this.snView.selectedRule.ruleSequence != undefined && this.snView.selectedRule.ruleSequence == clickedRule.ruleSequence && this.snView.selectedRule.team._id == clickedRule.team._id;

      if (isClickSameRule) {
        if (tag) { // click on the tag
          if (!this.snView.selectedTag || (this.snView.selectedTag && this.snView.selectedTag._id != tag._id)) {
            if (this.isChangePriorityByTag(clickedTag.defaultPriority, this.snView.priority)) {
              this.changePriorityByTag(clickedTag, clickedRule, index);
            } else {
              this.selectTag(clickedTag);
            }
          } else {
            // cannot unselect tag if the rule has only one tag
            if (this.snView.selectedRule.equipmentTags && this.snView.selectedRule.equipmentTags.length > 1) {
              this.unselectTag();
            }
          }
        } else {
          this.unselectRoutingChoice();
          this.reloadRoutingTable();
        }
      } else {  // click on other rule or first time select rule
        this.unselectTag();

        if (clickedRule.equipmentTags && clickedRule.equipmentTags.length == 1) {
          clickedTag = clickedRule.equipmentTags[0]; // select the tag automatically if the rule has only one tag
        }

        if (clickedTag) {
          if (this.isChangePriorityByTag(clickedTag.defaultPriority, this.snView.priority)) {
            this.changePriorityByTag(clickedTag, clickedRule, index);
          } else {
            this.selectTag(clickedTag);
            this.setSelectedRule(clickedRule, index);
          }
        } else {
          this.unselectTag();
          this.setSelectedRule(clickedRule, index);
        }

        if (this.snView.selectedRule) {
          if (!this.isHaSn()) { // assume HA routing rule no CDLCT
            this.autoFillCdlct();
          }
          if (this.mode == 'new') {
            this.cacheSnInput(this.snView);
          }
          this.updateSnView();
          this.setDropdown();

        } // else : only case if popup
      }
      // this.renderTable();
    } else {
      console.error("selectRoutingChoiceByRowIndex: index of bound");
    }
  }

  private setSelectedRule(clickedRule: any, selectedRowIndex: number) {
    this.snView.selectedRule = clickedRule;
    this.tablexParam.selectedRowIndex = selectedRowIndex;
    if (this.mode == 'new') {
      this.cacheSnInput(this.snView);
    }
  }

  /*
   * Auto fill client, district, location, equipmentCategory and equipment type base on routing rule
   */
  private autoFillCdlct() {
    if ((this.snView.selectedRule.clients && this.snView.selectedRule.clients.length > 0)) {
      this.snView.client = this.snView.selectedRule.clients[0];
    }
    if (this.snView.selectedRule.districts && this.snView.selectedRule.districts.length > 0) {
      this.snView.district = this.snView.selectedRule.districts[0];
    }
    if (this.snView.selectedRule.locations && this.snView.selectedRule.locations.length > 0) {
      if (!this.snView.location || !this.snView.selectedRule.locations.includes(this.snView.location)) {
        this.snView.location = this.snView.selectedRule.locations[0];
      }
    }
    if (this.snView.selectedRule.equipmentCategories && this.snView.selectedRule.equipmentCategories.length > 0) {
      this.snView.equipmentCategory = this.snView.selectedRule.equipmentCategories[0];
    }
    if (this.snView.selectedRule.equipmentTypes && this.snView.selectedRule.equipmentTypes.length > 0) {
      if (!this.snView.equipmentType || !this.snView.selectedRule.equipmentTypes.includes(this.snView.equipmentType)) {
        this.snView.equipmentType = this.snView.selectedRule.equipmentTypes[0];
      }
    }
  }

  private isHaSn(): boolean {
    if (this.snView.haEquipmentNumber) return true;
    return false;
  }

  private isSnSourceByHaXml(): boolean {
    if (this.selectedSN.sourceSystem === JMENUM.SourceSystem.HAEAM) return true;
    return false;
  }

  private isChangePriorityByTag(tagDefaultPriority: number, snViewPriority: number): boolean {
    if (this.isHaSn()) return false;
    if (tagDefaultPriority == null) return false;
    if (tagDefaultPriority == snViewPriority) return false;

    return true;
  }

  /*
    Normal SN Only. HA SN not allow change priority.
  */
  private changePriorityByTag(clickedTag, clickedRule = null, clickedRowIndex = null) {
    if (this.isHaSn()) return;

    let buttons = [
      {
        name: this.translate("pages.sn.continue-with-changing-sn-priority"),
        handler: () => {
          this.selectTag(clickedTag);
          if (clickedRule) { this.snView.selectedRule = clickedRule; }
          if (clickedRowIndex) { this.tablexParam.selectedRowIndex = clickedRowIndex; }
          
          const rule = this.snView.selectedRule;
          if (rule.clients && rule.clients.length > 0) {
            if (!rule.clients.includes(this.snView.client)) {
              this.snView.client = rule.clients[0];
            }
            this.setClientsOptions([this.snView.client]);
          }

          if (rule.districts && rule.districts.length > 0) {
            if (!rule.districts.includes(this.snView.district)) {
              this.snView.district = rule.districts[0];
            }
            this.setDistrictsOptions([this.snView.district]);
          }

          if (rule.locations && rule.locations.length > 0) {
            if (!rule.locations.includes(this.snView.location)) {
              this.snView.location = rule.locations[0];
            }
            this.setLocationOptions([this.snView.location]);
          }

          if (rule.equipmentCategories && rule.equipmentCategories.length > 0) {
            if (!rule.equipmentCategories.includes(this.snView.equipmentCategory)) {
              this.snView.equipmentCategory = rule.equipmentCategories[0];
            }
            this.setEquipmentCategoriesOptions([this.snView.equipmentCategory]);
          }

          if (rule.equipmentTypes && rule.equipmentTypes.length > 0) {
            if (!rule.equipmentTypes.includes(this.snView.equipmentType)) {
              this.snView.equipmentType = rule.equipmentTypes[0];
            }
            this.setEquipmentTypesOptions([this.snView.equipmentType]);
          }

          if (this.mode == 'new') {
            this.cacheSnInput(this.snView);
          }
          this.updateNormalSnView();
        }
      },
      {
        name: (this.translate("global.no")),
        handler: () => {
          this.reloadRoutingTable();
        }
      }
    ];

    this.showPopUpAlert(
      this.translate("pages.sn.continue-with-changing-sn-priority"),
      this.translate("pages.sn.changing-sn-priority-pop-up-description"),
      buttons
    );
  }

  private unselectRoutingChoice() {
    this.unselectTag(); // must un select tag first

    this.snView.selectedRule = null;
    this.tablexParam.selectedRowIndex = -1;
  }

  private selectTag(tag) {
    this.unselectTag();
    this.snView.selectedTag = tag;

    // for normal SN only
    if (!this.snView.haEquipmentNumber) {
      if (this.snView.selectedTag.defaultPriority == null) {
        this.disablePriority = false;
      } else {
        this.disablePriority = true;
        this.snView.priority = this.snView.selectedTag.defaultPriority;
        // this.onInputUpdated(true);
        // this.reloadRoutingTable();
      }
    }
    if (this.mode == 'new') {
      this.cacheSnInput(this.snView);
    }
  }

  private unselectTag() {
    if (this.snView.selectedRule && this.snView.selectedTag) {
      let key = this.snView.selectedRule.ruleSequence + '_' + this.snView.selectedRule.team._id + '_' + this.snView.selectedTag._id;
      if (key in this.uiTagButtonDict) {
        this.uiTagButtonDict[key].class = "tag-button";
      }
    }
    this.snView.selectedTag = null;
    this.disablePriority = false;
  }

  public onClickedAddAttachment(event) {
    if (!event || !event.target || !event.target.files || !event.target.files.length) {
      return;
    }
    let allFileSizeVaild = true;
    let allFileTypeVaild = true;
    let maxFileSize = 0;
    let files = event.target.files;
    if (files.length <= 10 && this.snView.attachmentList.length < 10) { //number of file can upload
      for (let i = 0; i < files.length; i++) {
        let attachment = { name: undefined, data: undefined };
        attachment.name = files[i].name;
        let file = files[i];

        let fileSizeValid, fileTypeValid;

        let reader = new FileReader();
        reader.readAsDataURL(file);

        if (file.size / 1024 / 1024 > 10) {
          allFileSizeVaild = false;
          fileSizeValid = false;
        } else if (this.fileMimeType.indexOf(file.type) < 0) {
          allFileTypeVaild = false;
          fileTypeValid = false;
        } else {
          maxFileSize += file.size / 1024 / 1024;
          fileSizeValid = true;
          fileTypeValid = true;
        }

        if (maxFileSize > 50) {
          this.openSnackBar(JMLanguage.translate("pages.sn.attachment.msg.error.total-file-size"));
          break;
        } else if (fileSizeValid && fileTypeValid) {
          reader.onload = () => {
            attachment.data = reader.result
            this.snView.attachmentList.push(attachment);
          };
        }
      }
    } else {
      this.openSnackBar(JMLanguage.translate("pages.sn.attachment.msg.error.number-of-file"));
    }

    if (!allFileSizeVaild) {
      this.openSnackBar(JMLanguage.translate("pages.sn.attachment.msg.error.max-size"));
    }
    if (!allFileTypeVaild) {
      this.openSnackBar(JMLanguage.translate("pages.sn.attachment.msg.error.file-type"));
    }
  }

  private requestGetFile(index) {
      let attachment = this.snView.attachmentList[index];
      saveAs(attachment.data, attachment.name);
  }

  public onClickedDelAttachment(index) {
    this.snView.attachmentList.splice(index, 1);
  }

  private async requestGetFileFromServer(attachmentId, description) {
    const request = new JM.JMRequestFilesGetFile(attachmentId);

    const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request);
    if (!response || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    saveAs(response.payload, description);
  }

  public onHaEquipmentListPanelSubmit(){
    this.haEquipmentListPanel.close();
    this.snView.haEquipmentNumber = this.SelectedHaEquipment.assetNumber;
    this.onHaEquipmentNumberUpdated();
  }

  public onClickHaEquipmentNo(){
    this.haEquipmentListPanel.toggle();
    this.SelectedHaEquipment.assetNumber = this.snView.haEquipmentNumber;
    this.haEquipmentListForm.refreshTable();
  }

  private async setSRALocationSet() {
    return this.locationService.requestAllSRALocation().then((locationCodeSet: Set<JMOBJ.SpecialRequestRule['location']['code']>) => {
      this.SRALocationSet = locationCodeSet
    }).catch((error) => {
      console.error(error)
    })
  }

  //---------------------------------------------------------------------------
  // crm related function
  // private sendCRMCallback(sn:JMOBJ.ServiceNotification):Promise<boolean> {
  //   if (this.crmCallbackURL) {
  //     let data = {
  //       external_ref_id: this.crmExternalRefId,
  //       sn_number: sn.snNumber,
  //       status: '' + sn.status,
  //       contact_person: sn.contactPerson,
  //     }

  //     if (sn.contactPerson.length > 1)  { data['contact_number_1']              = sn.contactPerson[0]; }
  //     if (sn.contactPerson.length > 2)  { data['contact_number_2']              = sn.contactPerson[1]; }
  //     if (sn.contactPerson.length > 3)  { data['contact_number_3']              = sn.contactPerson[2]; }
  //     if (sn.email)                     { data['contact_email']                 = sn.email; }
  //     if (sn.client)                    { data['client']                        = sn.client; }
  //     if (sn.district)                  { data['district']                      = String(sn.district).padStart(2, '0'); }
  //     if (sn.location)                  { data['location']                      = sn.location; }
  //     if (sn.locationDetail)            { data['location_detail']               = sn.locationDetail; }
  //     // if (sn.trapped_passengers)        { data['trapped_passengers']         = 0; }
  //     if (sn.equipmentType)             { data['equipment_type']                = sn.equipmentType; }
  //     // if (sn.equpiment_sub_type_en)     { data['equpiment_sub_type_en']      = null; }
  //     // if (sn.equpiment_sub_type_chi)    { data['equpiment_sub_type_chi']     = null; }
  //     if (sn.faultDetail)               { data['fault_details_by_client']       = sn.faultDetail; }
  //     if (sn.remarks)                   { data['remarks']                       = JSON.stringify(sn.remarks); }
  //     if (sn.crmData && sn.crmData.externalRefId ) { data['external_ref_id']    = JSON.stringify(sn.crmData.externalRefId); }


  //     if (sn.priority != undefined) {
  //       switch(sn.priority) {
  //         case JMENUM.JMPriority.NonUrgent: data['priority'] = '01'; break;
  //         case JMENUM.JMPriority.Urgent   : data['priority'] = '02'; break;
  //         case JMENUM.JMPriority.Emergency: data['priority'] = '03'; break;
  //       }
  //     }

  //     return axios({
  //       url: this.crmCallbackURL,
  //       data: sn,
  //       method: "post"
  //     }).then((rep) => {
  //       // nothing
  //       return Promise.resolve(true);
  //     }).catch((error) => {
  //       console.error("error when calling crm callback: " + error.message);
  //       this.openSnackBar(this.translate("pages.sn.synchronize-crm-warning"));
  //       return Promise.resolve(false);
  //     });
  //   }
  // }

}


interface HaEquipmentOption {
  assetNumber: string,
  equipmentNumber: string,
  haHospitalCode: string,
  hsdHospitalCode: string
}

interface LocationsOption{
    value: string,
    label: string,
    descriptionEn: string,
    descriptionZh: string,
    isSpecialRequestAlert?: boolean,
  }
