import { Injectable } from '@angular/core';
import { JMENUM } from '@ccep/CCEPConnector-ts';
import { EquipmentTagOrderType } from 'src/app/entity/data-model/equipment-tag-order';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  constructor() { }


  getSupportContact(): any {
    return {
      ldapId: 'CCePJM Support/HQ/EMSD',
      ldapId2: 'CCePJM HSD Support/HQ/EMSD\n(For HSD topic)',
      email: 'ccepjm-support@emsd.gov.hk',
      phone: '28083713'
    };
  }

  // "jobcard.priority.1": "Emergency",
  // "jobcard.priority.2": "Urgent",
  // "jobcard.priority.3": "Non Urgent",	
  allJobCardPriority(): number[] {
    return [1, 2, 3];
  }

  // "sn.priority.1": "Emergency",
  // "sn.priority.2": "Urgent",
  // "sn.priority.3": "Non Urgent",
  allSNPriority(): number[] {
    return [1, 2, 3];
  }


  // "team.initial-party.All": "All",
	// "team.initial-party.CSC": "CSC",
	// "team.initial-party.SBU": "SBU"
  allInitialParty(): string[] {
    return ["All", "CSC", "SBU"];
  }

  // "team.handling-party.1": "In House",
  // "team.handling-party.2": "PMSMC",
  // "team.handling-party.3": "Non-PMSMC",
  allHandlingParty(): number[] {
    return [1, 2, 3];
  }

  // "jobcard.status.1": "已分配",
  // "jobcard.status.2": "被拒絕",
  // "jobcard.status.3": "進行中",
  // "jobcard.status.4": "完成",
  // "jobcard.status.5": "取消中",
  // "jobcard.status.6": "取消",
  // allJobCardStatus(): number[] {
  //   return [1, 2, 3, 4, 5, 6, 7];
  // }

  allTecoPageStatus(): number[]{
    return [1, 2, 3, 5];
  }

  allUnTecoPageStatus(): number[]{
    return [4, 6];
  }

  allSnTecoStatus(): number[]{
    return [1, 2];
  }

  allJobcardTecoStatus(): number[]{
    return [1, 2, 3, 4, 5, 6];
  }

  allTimesheetSubmittedStatus(): string[]{
    return ["yes","no","na"];
  }

  allQuotationSubmittedStatus(): string[]{
    return ["yes","no","na"];
  }

  allUnTecoReasonCode(): number[]{
    return [7100,7110,7120];
  }

  allDistrict(): number[] {
    return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18];
  }

  /* Deprecated */
  getEquipmentTagOrderTypes(): EquipmentTagOrderType[] {
    return [
      {
        description: "User Choose",
        value: "",
        isPmOrderType: true,
        MAT: [
          { description: "User Choose", value: "", firstAttend: false, followUp: false }
        ]
      },
      {
        description: "ZS01",
        value: "ZS01",
        isPmOrderType: true,
        MAT: [
          { description: "User Choose", value: "", firstAttend: false, followUp: false, handlingParty: [] , jobNature : []},
          { description: "C01", value: "C01", firstAttend: true, followUp: false, handlingParty: [1,2,3] , jobNature : [JMENUM.JobNature.CM]},
          { description: "C02", value: "C02", firstAttend: true, followUp: false, handlingParty: [1,2,3] , jobNature : [JMENUM.JobNature.CM]},
          { description: "C03", value: "C03", firstAttend: false, followUp: true, handlingParty: [1,2,3] , jobNature : [JMENUM.JobNature.CM]},
          { description: "Z01", value: "Z01", firstAttend: true, followUp: false, handlingParty: [1] , jobNature : [JMENUM.JobNature.CM]},
          { description: "A01", value: "A01", firstAttend: true, followUp: false, handlingParty: [1] , jobNature : [JMENUM.JobNature.STANDALONE]},
          { description: "M10", value: "M10", firstAttend: true, followUp: false, handlingParty: [1] , jobNature : [JMENUM.JobNature.CM]},
          { description: "M20", value: "M20", firstAttend: false, followUp: true, handlingParty: [1] , jobNature : [JMENUM.JobNature.CM, JMENUM.JobNature.STANDALONE]},
          { description: "P01", value: "P01", firstAttend: true, followUp: false, handlingParty: [1,2,3], isPmMat: true , jobNature : [JMENUM.JobNature.PM]},
          { description: "P02", value: "P02", firstAttend: true, followUp: false, handlingParty: [1,2,3], isPmMat: true , jobNature : [JMENUM.JobNature.PM]},
        ]
      },
      {
        description: "ZS02",
        value: "ZS02",
        isPmOrderType: false,
        MAT: [
          { description: "User Choose", value: "", firstAttend: false, followUp: false, handlingParty: [], jobNature : []},
          { description: "N04", value: "N04", firstAttend: true, followUp: false, handlingParty: [1,2,3], jobNature : [JMENUM.JobNature.CM]},
          { description: "N05", value: "N05", firstAttend: true, followUp: false, handlingParty: [1], jobNature : [JMENUM.JobNature.CM] }
        ]
      },
      {
        description: "ZS04",
        value: "ZS04",
        isPmOrderType: false,
        MAT: [
          { description: "User Choose", value: "", firstAttend: false, followUp: false, handlingParty: [], jobNature : [] },
          { description: "R01", value: "R01", firstAttend: true, followUp: false, handlingParty: [1,2,3], jobNature : [JMENUM.JobNature.CM] },
          { description: "R02", value: "R02", firstAttend: true, followUp: false, handlingParty: [1,2,3], jobNature : [JMENUM.JobNature.CM] },
          { description: "R03", value: "R03", firstAttend: true, followUp: false, handlingParty: [1,2,3], jobNature : [JMENUM.JobNature.CM] },
          { description: "R04", value: "R04", firstAttend: true, followUp: false, handlingParty: [1,2,3], jobNature : [JMENUM.JobNature.CM] },
          { description: "R05", value: "R05", firstAttend: true, followUp: false, handlingParty: [1,2,3], jobNature : [JMENUM.JobNature.CM] }
        ]
      }
    ]
  }
}
