import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JMOBJ } from '@ccep/CCEPConnector-ts';
import { GetJobCardsByJobCardNumberRequest } from 'src/app/api/job-card/get-job-cards-by-job-card-number-request';
import { GetJobCardsByJobCardNumberResponse } from 'src/app/api/job-card/get-job-cards-by-job-card-number-response';
import { PostJobCardsAcceptRequest } from 'src/app/api/job-card/post-job-cards-accept-request';
import { PostJobCardsAcceptResponse } from 'src/app/api/job-card/post-job-cards-accept-response';
import { PostJobCardsCompleteRequest } from 'src/app/api/job-card/post-job-cards-complete-request';
import { PostJobCardsCompleteResponse } from 'src/app/api/job-card/post-job-cards-complete-response';
import { PostJobCardsFollowUpRequest } from 'src/app/api/job-card/post-job-cards-follow-up-request';
import { PostJobCardsFollowUpResponse } from 'src/app/api/job-card/post-job-cards-follow-up-response';
import { PostJobCardsReassignRequest } from 'src/app/api/job-card/post-job-cards-reassign-request';
import { PostJobCardsReassignResponse } from 'src/app/api/job-card/post-job-cards-reassign-response';
import { PostJobCardsRejectRequest } from 'src/app/api/job-card/post-job-cards-reject-request';
import { PostJobCardsRejectResponse } from 'src/app/api/job-card/post-job-cards-reject-response';
import { PostJobCardsReopenRequest } from 'src/app/api/job-card/post-job-cards-reopen-request';
import { PostJobCardsReopenResponse } from 'src/app/api/job-card/post-job-cards-reopen-response';
import { PostJobCardsRequest } from 'src/app/api/job-card/post-job-cards-request';
import { PostJobCardsResponse } from 'src/app/api/job-card/post-job-cards-response';
import { PostJobCardSummaryRequest } from 'src/app/api/job-card/post-job-card-summary-request';
import { PostJobCardSummaryResponse } from 'src/app/api/job-card/post-job-card-summary-response';
import { PutJobCardsRequest } from 'src/app/api/job-card/put-job-cards-request';
import { PutJobCardsResponse } from 'src/app/api/job-card/put-job-cards-response';
import { environment } from 'src/environments/environment';
import { PostJobCardsGetAssignmentRequest } from '@api/model/job-card/post-job-cards-get-assignment-request';
import { PostJobCardsGetAssignmentResponse } from '@api/model/job-card/post-job-cards-get-assignment-response';
import { PostJobCardsAddAttachmentsRequest } from '@api/model/job-card/post-job-cards-add-attachments-request';
import { PostJobCardsAddAttachmentsResponse } from '@api/model/job-card/post-job-cards-add-attachments-response';
import { PostJobCardsRemoveAttachmentsRequest } from '@api/model/job-card/post-job-cards-remove-attachments-request';
import { PostJobCardsRemoveAttachmentsResponse } from '@api/model/job-card/post-job-cards-remove-attachments-response';
import { PostJobCardsChangeAssignmentRequest } from '@api/model/job-card/post-job-cards-change-assignment-request';
import { PostJobCardsChangeAssignmentResponse } from '@api/model/job-card/post-job-cards-change-assignment-response';
import { PostExportJobCardSummaryRequest } from '@api/model/job-card/post-export-job-card-summary-request';
import { PostJobCardCcsErrorSummaryResponse } from '@api/model/job-card/post-job-card-ccs-error-summary-response';
import { PostJobCardCcsErrorSummaryRequest } from '@api/model/job-card/post-job-card-ccs-error-summary-request';
import { PostJobCardRemoveCcsErrorRequest } from '@api/model/job-card/post-job-card-remove-ccs-error-request';
import { PostJobCardCcsResyncRequest } from '@api/model/job-card/post-job-card-ccs-resync-request';
import { PostExportJobCardContractorJobReportRequest } from '@api/model/job-card/post-export-job-card-contractor-job-report-request';

@Injectable({
  providedIn: 'root'
})
export class JobCardService {

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService
  ) { }

  // createJobCard(request: PostJobCardsRequest): Observable<PostJobCardsResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/";

  //   return this.http.post<PostJobCardsResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // acceptJobCard(request: PostJobCardsAcceptRequest): Observable<PostJobCardsAcceptResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/accept/";

  //   return this.http.post<PostJobCardsAcceptResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // rejectJobCard(request: PostJobCardsRejectRequest): Observable<PostJobCardsRejectResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/reject/";

  //   return this.http.post<PostJobCardsRejectResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // completeJobCard(request: PostJobCardsCompleteRequest): Observable<PostJobCardsCompleteResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/complete/";

  //   return this.http.post<PostJobCardsCompleteResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // reopenJobCard(request: PostJobCardsReopenRequest): Observable<PostJobCardsReopenResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/reopen/";

  //   return this.http.post<PostJobCardsReopenResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // createFollowUpJobCard(request: PostJobCardsFollowUpRequest): Observable<PostJobCardsFollowUpResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/followUp/";

  //   return this.http.post<PostJobCardsFollowUpResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // reassignJobCard(request: PostJobCardsReassignRequest): Observable<PostJobCardsReassignResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/reassign/";

  //   return this.http.post<PostJobCardsReassignResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // changeAssignment(request:PostJobCardsChangeAssignmentRequest ): Observable<PostJobCardsChangeAssignmentResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/changeAssignment/";

  //   return this.http.post<PostJobCardsChangeAssignmentResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // getAssignment(request: PostJobCardsGetAssignmentRequest): Observable<PostJobCardsGetAssignmentResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/getAssignment/";

  //   return this.http.post<PostJobCardsGetAssignmentResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // getJobCard(request: any): Observable<any> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/getJobCard/";

  //   return this.http.post<any>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // postJobCardSummary(request: PostJobCardSummaryRequest): Observable<PostJobCardSummaryResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/jobCardSummary/";

  //   return this.http.post<PostJobCardSummaryResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // postJobCardsAddAttachments(request: PostJobCardsAddAttachmentsRequest): Observable<PostJobCardsAddAttachmentsResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/addAttachments/";

  //   return this.http.post<PostJobCardsAddAttachmentsResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // postJobCardsRemoveAttachments(request: PostJobCardsRemoveAttachmentsRequest): Observable<PostJobCardsRemoveAttachmentsResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/removeAttachments/";

  //   return this.http.post<PostJobCardsRemoveAttachmentsResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }
  
  // putJobCards(request: PutJobCardsRequest): Observable<PutJobCardsResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/";

  //   return this.http.put<PutJobCardsResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // getJobCardByNumber(request: GetJobCardsByJobCardNumberRequest): Observable<GetJobCardsByJobCardNumberResponse> {
  //   let httpHeaders = this.authorizationService.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/jobCards/" + request.jobCardNumber;

  //   return this.http.get<GetJobCardsByJobCardNumberResponse>(
  //     url,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }
  
  createByToken(request: any): Observable<any> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/jobCards/createByToken";

    return this.http.post<any>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  completeByToken(request: any): Observable<any> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/jobCards/completeByToken";

    return this.http.post<any>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  postExportJobCardSummaryRequest(request: PostExportJobCardSummaryRequest):Observable<Blob> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/jobCards/exportJobCardSummary";

    return this.http.post(
      url,
      request,
      { 
        headers: httpHeaders,
        responseType: "blob"
      }
    ).pipe(
      catchError(this.handleError)
    )
  }

  postExportJobCardContractorJobReportRequest(request: PostExportJobCardContractorJobReportRequest):Observable<Blob> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/jobCards/exportContractorJobReport";

    return this.http.post(
      url,
      request,
      { 
        headers: httpHeaders,
        responseType: "blob"
      }
    ).pipe(
      catchError(this.handleError)
    )
  }

  getJobCardCcsErrorSummary(request: PostJobCardCcsErrorSummaryRequest): Observable<PostJobCardCcsErrorSummaryResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/jobCards/ccsErrorSummary";

    return this.http.post<PostJobCardCcsErrorSummaryResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  postJobCardCcsResyncRequest(request: PostJobCardCcsResyncRequest){
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/jobCards/ccsResync";

    return this.http.post<any>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  postJobCardRemoveCcsErrorRequest(request: PostJobCardRemoveCcsErrorRequest){
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/jobCards/removeCcsError";

    return this.http.post<any>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }


  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  isFirstAttendedJob(jobCard: JMOBJ.JobCard): boolean {
    if (!jobCard || !jobCard.jobCardNumber) {
      return undefined;
    }

    const tmpArr = jobCard.jobCardNumber.split('-');
    return tmpArr.length > 1 && tmpArr[1] == '01';
  }
}
