<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings.hospital-code.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'haDefaults'"></app-sidebar>

  <div id="content">
    <button id="add-team-btn" class="btn float-right col-auto outline-none brand-blue "type="button" 
        [disabled]="isDisabledAddMapping" (click)="onClickAddMappingBtn()"
      >
        <i class="fa fa-plus"></i>
    </button>
    <app-custom-fields-form #hospital_code_form class="col-md-9 col-12 hospital-code-form-elem" [parameters]="hospitalCodeFormParam" [userInput]="hospitalCodeFormInput" >
    </app-custom-fields-form>

    <div class="hospital-code-records-wrap" *ngIf="hospitalCodeRecords" id="hospital-code-records-wraps">
      <div class="row mx-0 hospital-code-row align-items-center" [id]="record.hospitalCode + '-row'" *ngFor="let record of hospitalCodeRecords; let r = index">
        <div class="row col-10 mx-0 px-0">
          <div class="col-3" [id]="record.hospitalCode + '-hospital-code'">
            {{ record.hospitalCode }}
          </div>
          <div class="col-3" [id]="record.hospitalCode + '-client'">
            {{ record.client }}
          </div>
          <div class="col-3"  [id]="record.hospitalCode + '-location-code'">
            {{ record.location }}
          </div>
          <div class="col-3"  [id]="record.hospitalCode + '-location-description'">
            {{ record.locationDescription }}
          </div>
        </div>
        <div class="col-2 row-button-wrap text-center">
          <button class="btn px-2 font-size-m" [id]="record.hospitalCode + '-edit-btn'" (click)="onClickEditHospitalCode(record, r)" [disabled]="isDisabledEditHospitalCode[r]">
            <i class="fas fa-pen font-size-m" ></i>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>