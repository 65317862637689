import { Component, OnInit, Input } from '@angular/core';
import { TablexColumnType, TablexColumnHorizontalAlign, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { AppDelegate } from 'src/app/AppDelegate';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import {
  translateComplianceStatus
} from 'src/app/ui/components/jobcard-task-progress/jobcard-task-progress.component';

@Component({
  selector: 'app-notification-progress-detail',
  templateUrl: './notification-progress-detail.component.html',
  styleUrls: ['./notification-progress-detail.component.scss']
})
export class NotificationProgressDetailComponent implements OnInit {

  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Input() currentPageMode: JMENUM.JMPageMode = undefined;

  pageMode = JMENUM.JMPageMode;

  jobCardSummary: any = [];

  tablexParam: {} = {};

  constructor() {}

  ngOnInit() {
    this.initTablex();
    this.initAllTableHeader();
  }

  ngOnChanges() {
    // this.snNumber = (this.sn.snNumber)?this.sn.snNumber:undefined;
    if (this.sn.jobCards && this.sn.jobCards.length > 0) {
      this.requestJobCardSummary();
    }
    else {
      this.renderTable();
    }

  }

  public initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      enableColFilter: false,
      enableSelectedRowCount: true,
      tableRow: "row",
      tableClass: "jobcard-table",
      tableWrapperClass: "table-min-width",
      selectedRowCount: 0,
      totalRowCount: 0,
      enableStickyHeader: false,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  public initAllTableHeader() {
    this.tablexParam['headers'] = [
      {
        id: 'jobCardNumber',
        name: "component.notification-progress-detail.job-card-no",
        enableFilter: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'response',
        name: "component.notification-progress-detail.response",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'rectification',
        name: "component.notification-progress-detail.rectification",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'status',
        name: "component.notification-progress-detail.status",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      }
    ];
  }

  public renderTable() {
    this.tablexParam['content'] = this.jobCardSummary.map(jobCard => {
      const jobCardLink = {
        url: `/job-card/view/${jobCard.jobCardNumber}`,
        value: jobCard.jobCardNumber,
      };
      const response = JMLanguage.translate(`component.task-progress.compliance.${
        translateComplianceStatus(jobCard.complianceStatus && jobCard.complianceStatus.isResponseTimePassed)
      }`);
      const rectification = JMLanguage.translate(`component.task-progress.compliance.${
        translateComplianceStatus(jobCard.complianceStatus && jobCard.complianceStatus.isRectificationTimePassed)
      }`);
      const status = jobCard.status ? JMLanguage.translate(`jobcard.status.${jobCard.status}`) : '';

      return [jobCardLink, response, rectification, status];
    });
  }

  public requestJobCardSummary() {
    let request: JM.JMRequestJobCardsJobCardSummary = new JM.JMRequestJobCardsJobCardSummary();
    request.parameters = [
      "snNumber",
      "jobCardNumber",
      "status",
      "complianceStatus"
    ];
    request.sortBy = "jobCardNumber";
    request.jobCardNumberList = (this.sn.jobCards && this.sn.jobCards.length > 0) ? this.sn.jobCards : undefined;

    if (request.jobCardNumberList) {
      this.tablexParam["isLoadingTable"] = true;
      JM.JMConnector.sendJobCardsJobCardSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseJobCardsJobCardSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.openErrorBar(response);
          return;
        }
        this.jobCardSummary = response.payload.records;
        this.renderTable();
        this.tablexParam["isLoadingTable"] = false;
      });
    }
  }



}
