<div class="wrapper">
  <app-nav
    [showActionSidebarIcon]="true"
    [navbarTitle]="pageTitle"
  ></app-nav>
  <app-sidebar></app-sidebar>
  
  <div id="content" class="action-btn-padding">
    <div class="row">
      <div class="col-xl-6 col-12">
        <app-jobcard-job-details #sectionJobDetails
          class="d-block mb-4"
          [jobCard]="jobCardData" 
          [sn]="snData" 
          [componentParameters]="componentParameters"
          [pageMode]="pageMode"
          (teamChange)="onTeamChange($event)"
          (updateSnData)="updateSnData($event)"
          (updateJobCardData)="updateJobCardData($event)"
        ></app-jobcard-job-details>
        <app-jobcard-client-information #sectionClientInfo 
          [jobCard]="jobCardData" 
          [sn]="snData" 
          [componentParameters]="componentParameters"
          [pageMode]="pageMode" 
          class="d-block mb-4" 
          (updateSnData)="updateSnData($event)"
          (updateJobCardData)="updateJobCardData($event)"
        ></app-jobcard-client-information>
        <!-- <app-jobcard-ha-information #sectionHAInfo 
          [jobCard]="jobCardData" 
          [sn]="snData" 
          [componentParameters]="componentParameters" 
          [pageMode]="pageMode"
          class="d-block mb-4"
        ></app-jobcard-ha-information> -->
      </div>

      <div class="custom-tab-content col-xl-6 col-12" >
        <app-jobcard-task-progress #sectionTaskProgress
          [jobCard]="jobCardData"
          [componentParameters]="componentParameters"
          [pageMode]="pageMode"
          class="d-block mb-4" 
        ></app-jobcard-task-progress>
      </div>
    </div>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
      <app-action-sidebar
        [buttonData]="actionButtonData"
        (clickEvent)="actionBtnService.onActionButtonClick($event)">
      </app-action-sidebar>
    </ng-container>

    <!--Slider panel-->
    <custom-slider-panel #assignStaffPanel [widthPx]=600>
      <div class="custom-control custom-checkbox">
        <input 
          type="checkbox" 
          id="show-all-post-checkbox" 
          class="custom-control-input"
          (change)="onShowAllWorkCenterStaffChecked($event)"
          [(ngModel)]="assignStaffFormParam.showWorkCentreStaffs"
          [disabled]="isLoadingWorkCentreStaffs"
        >
        <label for="show-all-post-checkbox" class="custom-control-label">
          <span *ngIf="isLoadingWorkCentreStaffs">
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
          </span>
          <span *ngIf="!isLoadingWorkCentreStaffs">
            {{'pages.jobcard.create.slider-panel.create-job-show-all-staff-checkbox' | translate}}
          </span>
        </label>
      </div>
      <job-card-assign-staff-form #assignStaffForm [parameters]="assignStaffFormParam"></job-card-assign-staff-form>
    </custom-slider-panel>
  </div>
</div>