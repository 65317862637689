import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-manuel-fax',
  templateUrl: './modal-manuel-fax.component.html',
  styleUrls: ['./modal-manuel-fax.component.scss']
})
export class ModalManuelFaxComponent implements OnInit {
  @Input() public faxDetail: {
    teamName: undefined,
    faxNumber: undefined,
    attention: undefined,
  };
  @Input() public onResendClicked: Function;
  @Input() public onCloseClicked: Function;

  uiIsLoading:boolean = false;
  uiShowWarning = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.uiIsLoading = false;
  }

  setLoading(isLoading:boolean) {
    this.uiIsLoading = isLoading;
  }

  validInput(field) {
    return ( field === undefined || (field !== null && field.length > 0));
  }

  onResend() {
    this.uiShowWarning = true;
    if (this.validInput(this.faxDetail.teamName) && this.validInput(this.faxDetail.faxNumber) && this.validInput(this.faxDetail.attention)) {
      if (this.onResendClicked) {
        this.onResendClicked(this.faxDetail);
      }
    }
  }
  onClosed() {
    if (this.onCloseClicked) {
      this.onCloseClicked();
    } else {
      this.activeModal.dismiss();
    }
  }
}