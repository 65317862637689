import { Permission } from '@enum/permission';
import { JMENUM, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

export const ActionButtonDefinition: any = { // TODO: transalte
    'sn': {
        'edit': {
            type: 'sn',
            action: 'edit',
            buttonId: 'right-tool-bar_edit_button',
            imageUrl: '/assets/svg/btn_action_edit.svg',
            actionDescription: 'action.button.edit',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            // permission: Permission.snDraft,
            isEnable: true,
            isShow: true
        },
        'eFax': {
            type: 'sn',
            action: 'eFax',
            buttonId: 'right-tool-bar_efax_button',
            imageUrl: '/assets/svg/btn_action_efax.svg',
            actionDescription: 'action.button.efax',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snView,
            isEnable: true,
            isShow: true
        },
        'exportPdf': {
            type: 'sn',
            action: 'exportPdf',
            buttonId: 'right-tool-bar_export-pdf_button',
            imageUrl: '/assets/svg/btn_action_download.svg',
            actionDescription: 'action.button.export-pdf',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snView,
            isEnable: true,
            isShow: true
        },
        'submit': {
            type: 'sn',
            action: 'submit',
            buttonId: 'right-tool-bar_submit_button',
            imageUrl: '/assets/svg/btn_action_submit.svg',
            actionDescription: 'action.button.submit',
            showPopup: false,
            popupTitle: 'action.button.popup.submit',
            buttons: [],
            permission: Permission.snSubmit,
            isEnable: true,
            isShow: true
        },
        'save': {
            type: 'sn',
            action: 'save',
            buttonId: 'right-tool-bar_save_button',
            imageUrl: '/assets/svg/btn_action_save.svg',
            actionDescription: 'action.button.save',
            showPopup: false,
            popupTitle: 'action.button.popup.save',
            buttons: [],
            // permission: Permission.snDraft,
            isEnable: true,
            isShow: true
        },
        'saveAndResend': {
            type: 'sn',
            action: 'saveAndResend',
            buttonId: 'right-tool-bar_save-and-resend_button',
            imageUrl: '/assets/svg/btn_action_save.svg',
            actionDescription: 'action.button.save-and-resend',
            showPopup: true,
            popupTitle: 'action.button.popup.save-and-resend',
            buttons: [],
            permission: Permission.snUpdate,
            isEnable: true,
            isShow: true
        },
        'copyInNew': {
            type: 'sn',
            action: 'copyInNew',
            buttonId: 'right-tool-bar_copy-into-new_button',
            imageUrl: '/assets/svg/btn_action_copy_into_new.svg',
            actionDescription: 'action.button.copy-in-new',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snDraft,
            isEnable: true,
            isShow: true
        },
        'clear': {
            type: 'sn',
            action: 'clear',
            buttonId: 'right-tool-bar_clear_button',
            imageUrl: '/assets/svg/btn_action_clear.svg',
            actionDescription: 'action.button.clear',
            showPopup: true,
            popupTitle: 'action.button.popup.clear',
            buttons: [],
            permission: Permission.snDraft,
            isEnable: true,
            isShow: true
        },
        'close': {
            type: 'sn',
            action: 'close',
            buttonId: 'right-tool-bar_close_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.close',
            showPopup: true,
            popupTitle: 'action.button.popup.close',
            buttons: [{ "name": "global.close-without-save" }, { "name": "global.no" }],
            permission: Permission.snView,
            isEnable: true,
            isShow: true
        },
        'acknowledge': {
            type: 'sn',
            action: 'acknowledge',
            buttonId: 'right-tool-bar_acknowledge_button',
            imageUrl: '/assets/svg/btn_action_acknowledge.svg',
            actionDescription: 'action.button.acknowledge',
            showPopup: true,
            popupTitle: 'action.button.popup.acknowledge',
            buttons: [],
            permission: Permission.snAcknowledge,
            isEnable: true,
            isShow: true
        },
        'manualAcknowledge': {
            type: 'sn',
            action: 'manualAcknowledge',
            buttonId: 'right-tool-bar_manual-acknowledge_button',
            imageUrl: '/assets/svg/btn_action_acknowledge.svg',
            actionDescription: 'action.button.manual-acknowledge',
            showPopup: true,
            popupTitle: 'action.button.popup.manual-acknowledge',
            buttons: [{ "name": "action.button.acknowledge-on-behalf-of-contractor" }, { "name": "global.no" }],
            permission: Permission.snManualAcknowledge,
            isEnable: true,
            isShow: true
        },
        'reRoute': {
            type: 'sn',
            action: 'reRoute',
            buttonId: 'right-tool-bar_re-route_button',
            imageUrl: '/assets/svg/btn_action_reassign.svg',
            actionDescription: 'action.button.re-route',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snReroute,
            isEnable: true,
            isShow: true
        },
        'transfer': {
            type: 'sn',
            action: 'transfer',
            buttonId: 'right-tool-bar_transfer_button',
            imageUrl: '/assets/svg/btn_action_transfer.svg',
            actionDescription: 'action.button.transfer',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snReroute,
            isEnable: true,
            isShow: true
        },
        'reject': {
            type: 'sn',
            action: 'reject',
            buttonId: 'right-tool-bar_reject_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.reject',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snReject,
            isEnable: true,
            isShow: true
        },
        'cancel': {
            type: 'sn',
            action: 'cancel',
            buttonId: 'right-tool-bar_cancel_button',
            imageUrl: '/assets/svg/btn_action_cancel.svg',
            actionDescription: 'action.button.cancel',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snCancel,
            isEnable: true,
            isShow: true
        },
        'jobCreateAdHoc': {
            type: 'sn',
            action: 'jobCreateAdHoc',
            buttonId: 'right-tool-bar_job-create-adhoc_button',
            imageUrl: '/assets/svg/btn_action_job_create_ad_hoc.svg',
            actionDescription: 'action.button.create-adhoc',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardCreate,
            isEnable: true,
            isShow: true
        },
        'jobCreateSla': {
            type: 'sn',
            action: 'jobCreateSla',
            buttonId: 'right-tool-bar_job-create-sla_button',
            imageUrl: '/assets/svg/btn_action_job_create_sla.svg',
            actionDescription: 'action.button.create-sla-job',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardCreate,
            isEnable: true,
            isShow: true
        },
        'jobCreateTransfer': {
            type: 'sn',
            action: 'jobCreateTransfer',
            buttonId: 'right-tool-bar_job-create-transfer_button',
            imageUrl: '/assets/svg/btn_action_job_create_transfer.svg',
            actionDescription: 'action.button.create-transfer',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardCreate,
            isEnable: true,
            isShow: true
        },
        'jobCreateFollowUp': {
            type: 'jobCard',
            action: 'jobCreateFollowUp',
            buttonId: 'right-tool-bar_job-create-follow-up_button',
            imageUrl: '/assets/svg/btn_action_job_create_follow_up.svg',
            actionDescription: 'action.button.create-follow-up',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardCreate,
            isEnable: true,
            isShow: true
        },
        'createEnquiry': {
            type: 'jobCard',
            action: 'createEnquiry',
            buttonId: 'right-tool-bar_job-create-enquiry_button',
            imageUrl: '/assets/svg/btn_action_job_create_enquiry.svg',
            actionDescription: 'action.button.create-enquiry',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardCreate,
            isEnable: true,
            isShow: true
        },
        'back': {
            type: 'sn',
            action: 'back',
            buttonId: 'right-tool-bar_back_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.back',
            showPopup: true,
            popupTitle: '',
            buttons: [],
            permission: Permission.snView,
            isEnable: true,
            isShow: true
        },
        'attachment': {
            type: 'sn',
            action: 'attachment',
            buttonId: 'right-tool-bar_attachment_button',
            imageUrl: '/assets/svg/btn_action_attach.svg',
            actionDescription: 'action.button.attachment',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snView,
            isEnable: true,
            isShow: true
        },
        'haServiceReport': {
            type: 'sn',
            action: 'haServiceReport',
            buttonId: 'right-tool-bar_haServiceReport_button',
            imageUrl: '/assets/svg/button_HA_service_report.svg',
            actionDescription: 'action.button.haServiceReport',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.SN_VIEW,
            isEnable: true,
            isShow: true
        },
        'eForm': {
            type: 'sn',
            action: 'eForm',
            buttonId: 'right-tool-bar_e-form_button',
            imageUrl: '/assets/svg/btn_action_eForm.svg',
            actionDescription: 'action.button.e-form',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snView,
            isEnable: true,
            isShow: true
        },
        'associate': {
            type: 'sn',
            action: 'associate',
            buttonId: 'right-tool-bar_associate_button',
            imageUrl: '/assets/svg/btn_action_associate.svg',
            actionDescription: 'action.button.associate',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.snView,
            isEnable: true,
            isShow: true
        }
    },
    'jobCard': {
        'create': {
            type: 'jobCard',
            action: 'create',
            buttonId: 'createJobCard',
            imageUrl: '/assets/svg/btn_action_job_create_follow_up.svg',
            actionDescription: 'pages.sn.slider-panel.create-job',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true,
        },
        'clear': {
            type: 'jobCard',
            action: 'clear',
            buttonId: 'clearJobCard',
            imageUrl: '/assets/svg/btn_action_clear.svg',
            actionDescription: 'action.button.clear',
            showPopup: true,
            popupTitle: 'action.button.popup.clear',
            buttons: [],
            isEnable: true,
            isShow: true,
        },
        'edit': {
            type: 'jobCard',
            action: 'edit',
            buttonId: 'right-tool-bar_edit',
            imageUrl: '/assets/svg/btn_action_edit.svg',
            actionDescription: 'action.button.edit',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardUpdate,
            isEnable: true,
            isShow: true
        },
        'save': {
            type: 'jobCard',
            action: 'save',
            buttonId: 'right-tool-bar_save_button',
            imageUrl: '/assets/svg/btn_action_save.svg',
            actionDescription: 'action.button.save',
            showPopup: true,
            popupTitle: 'action.button.popup.save',
            buttons: [],
            permission: Permission.jobcardUpdate,
            isEnable: true,
            isShow: true
        },
        'cancel': {
            type: 'jobCard',
            action: 'cancel',
            buttonId: 'right-tool-bar_cancel',
            imageUrl: '/assets/svg/btn_action_cancel.svg',
            actionDescription: 'action.button.cancel',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardCancel,
            isEnable: true,
            isShow: true
        },
        'accept': {
            type: 'jobCard',
            action: 'accept',
            buttonId: 'right-tool-bar_accept',
            imageUrl: '/assets/svg/btn_action_submit.svg',
            actionDescription: 'action.button.accept',
            showPopup: true,
            popupTitle: 'action.button.popup.accept',
            buttons: [],
            permission: Permission.jobcardAccept,
            isEnable: true,
            isShow: true
        },
        'reassign': {
            type: 'jobCard',
            action: 'reassign',
            buttonId: 'right-tool-bar_reassign',
            imageUrl: '/assets/svg/btn_action_reassign.svg',
            actionDescription: 'action.button.reassign',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardUpdate,
            isEnable: true,
            isShow: true
        },
        'eFax': {
            type: 'jobCard',
            action: 'eFax',
            buttonId: 'right-tool-bar_efax_button',
            imageUrl: '/assets/svg/btn_action_efax.svg',
            actionDescription: 'action.button.efax',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardView,
            isEnable: true,
            isShow: true
        },
        'exportPdf': {
            type: 'jobCard',
            action: 'exportPdf',
            buttonId: 'right-tool-bar_export-pdf_button',
            imageUrl: '/assets/svg/btn_action_download.svg',
            actionDescription: 'action.button.export-pdf',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardView,
            isEnable: true,
            isShow: true
        },
        'exportHaPdf': {
            type: 'jobCard',
            action: 'exportHaPdf',
            buttonId: 'right-tool-bar_export-ha-pdf_button',
            imageUrl: '/assets/svg/btn_action_download.svg',
            actionDescription: 'action.button.export-ha-pdf',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardView,
            isEnable: true,
            isShow: true
        },
        'jobReopen': {
            type: 'jobCard',
            action: 'jobReopen',
            buttonId: 'right-tool-bar_job-reopen_button',
            imageUrl: '/assets/svg/btn_action_job_reopen.svg',
            actionDescription: 'action.button.reopen',
            showPopup: true,
            popupTitle: 'action.button.popup.reopen',
            buttons: [],
            permission: Permission.jobcardReopen,
            isEnable: true,
            isShow: true
        },
        'reject': {
            type: 'jobCard',
            action: 'reject',
            buttonId: 'right-tool-bar_reject',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.reject',
            showPopup: false,
            popupTitle: 'action.button.popup.reject-job',
            buttons: [{ "name": "action.button.reject-the-job" }, { "name": "global.no" }],
            permission: Permission.jobcardReject,
            isEnable: true,
            isShow: true
        },
        'close': {
            type: 'jobCard',
            action: 'close',
            buttonId: 'right-tool-bar_close_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.close',
            showPopup: true,
            popupTitle: 'action.button.popup.close',
            buttons: [{ "name": "global.close-without-save" }, { "name": "global.no" }],
            permission: Permission.jobcardUpdate,
            isEnable: true,
            isShow: true
        },
        'reserveStock': {
            type: 'jobCard',
            action: 'reserveStock',
            buttonId: 'right-tool-bar_reserveStock',
            imageUrl: '/assets/svg/btn_action_job_reserve_stock.svg',
            actionDescription: 'action.button.reserve-stock',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.STOCK_RESERVE,
            isEnable: true,
            isShow: true
        },
        'attach': {
            type: 'jobCard',
            action: 'attach',
            buttonId: 'right-tool-bar_attach',
            imageUrl: '/assets/svg/btn_action_attach.svg',
            actionDescription: 'action.button.attachment',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardUpdate,
            isEnable: true,
            isShow: true
        },
        'jobComplete': {
            type: 'jobCard',
            action: 'jobComplete',
            buttonId: 'right-tool-bar_jobComplete',
            imageUrl: '/assets/svg/btn_action_job_complete.svg',
            actionDescription: 'action.button.complete',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardComplete,
            isEnable: true,
            isShow: true
        },
        'jobCompleteHandled': {
            type: 'jobCard',
            action: 'jobComplete',
            buttonId: 'right-tool-bar_jobComplete_jobComplete',
            imageUrl: '/assets/svg/btn_action_job_complete(handled).svg',
            actionDescription: 'action.button.job.complete',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardComplete,
            isEnable: true,
            isShow: true
        },
        'jobCompleteWithNoAction': {
            type: 'jobCard',
            action: 'jobCompleteWithNoAction',
            buttonId: 'right-tool-bar_jobCompleteMenu_jobCompleteWithNoAction',
            imageUrl: '/assets/svg/btn_action_job_complete(no_action).svg',
            actionDescription: 'action.button.job.complete-with-no-action',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardComplete,
            isEnable: true,
            isShow: true
        },
        'generateCcsSo': {
            type: 'jobCard',
            action: 'generateCcsSo',
            buttonId: 'right-tool-bar_generateCcsSo',
            imageUrl: '/assets/svg/btn_action_obtain_so.svg',
            actionDescription: 'action.button.generate-ccs-so',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: Permission.jobcardUpdate,
            isEnable: true,
            isShow: true
        },
        'createDossPo': {
            type: 'jobCard',
            action: 'createDossPo',
            buttonId: 'right-tool-bar_createDossPo',
            imageUrl: '/assets/svg/btn_action_create_po.svg',
            actionDescription: 'action.button.create-doss-po',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.QA_TEST,
            isEnable: true,
            isShow: true
        },
        'timeSheet': {
            type: 'jobCard',
            action: 'timeSheet',
            buttonId: 'right-tool-bar_timeSheet',
            imageUrl: '/assets/svg/btn_action_job_time_sheet.svg',
            actionDescription: 'action.button.time-sheet',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.TIMESHEET_UPDATE,
            isEnable: true,
            isShow: true,
        },
        'timeSheetV2': {
            type: 'jobCard',
            action: 'timeSheet',
            buttonId: 'right-tool-bar_timeSheet',
            imageUrl: '/assets/svg/btn_action_job_time_sheet.svg',
            actionDescription: 'action.button.time-sheet',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.TIMESHEET_UPDATE,
            isEnable: true,
            isShow: true,
        },
        'teco': {
            type: 'teco',
            action: 'teco',
            buttonId: 'right-tool-bar-teco-button',
            imageUrl: '/assets/svg/teco.svg',
            actionDescription: 'global.teco',
            showPopup: true,
            popupTitle: 'action.button.popup.teco',
            buttons: [],
            permission: [
                JMENUM.Permission.JOBCARD_TECO,
                JMENUM.Permission.JOBCARD_TECO_ALL
            ],
            isEnable: true,
            isShow: true
        },
        'withDraw': {
            type: 'teco',
            action: 'withDraw',
            buttonId: 'right-tool-bar-withDraw-button',
            imageUrl: '/assets/svg/withdraw-teco.svg',
            actionDescription: 'global.withdraw',
            showPopup: true,
            popupTitle: 'action.button.popup.withdraw',
            buttons: [],
            permission: [
                JMENUM.Permission.JOBCARD_TECO,
                JMENUM.Permission.JOBCARD_TECO_ALL
            ],
            isEnable: true,
            isShow: true
        },
        'unTeco': {
            type: 'unTeco',
            action: 'unTeco',
            buttonId: 'right-tool-bar-unTeco-button',
            imageUrl: '/assets/svg/unteco-1.svg',
            actionDescription: 'global.unTeco',
            showPopup: false,
            popupTitle: 'action.button.popup.un-teco',
            buttons: [],
            permission:
                [
                    JMENUM.Permission.JOBCARD_ZS01_UNTECO,
                    JMENUM.Permission.JOBCARD_ZS02_UNTECO,
                    JMENUM.Permission.JOBCARD_ZS04_UNTECO,
                    JMENUM.Permission.JOBCARD_UNTECO_ALL,
                ],
            isEnable: true,
            isShow: true
        },
        'approve': {
            type: 'jobCard',
            action: 'approve',
            buttonId: 'right-tool-bar_approve_button',
            imageUrl: '/assets/svg/btn_action_submit.svg',
            actionDescription: 'action.button.approve',
            showPopup: true,
            popupTitle: 'action.button.popup.approve-approval',
            buttons: [],
            permission: [],
            isEnable: true,
            isShow: true
        },
        'rejectCompletion': {
            type: 'jobCard',
            action: 'rejectCompletion',
            buttonId: 'right-tool-bar_reject_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.reject',
            showPopup: false,
            popupTitle: 'action.button.popup.reject-approval',
            buttons: [],
            permission: [],
            isEnable: true,
            isShow: true
        },
        'inspect': {
            type: 'jobCard',
            action: 'inspect',
            buttonId: 'right-tool-bar_inspect_button',
            imageUrl: '/assets/svg/btn_action_inspect.svg',
            actionDescription: 'action.button.inspect',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.JOBCARD_INSPECTION,
            isEnable: true,
            isShow: true
        },
    },
    'timeSheet': {
        'save': {
            type: 'timeSheet',
            action: 'save',
            buttonId: 'right-tool-bar_save_button',
            imageUrl: '/assets/svg/btn_action_save.svg',
            actionDescription: 'global.save',
            showPopup: true,
            popupTitle: 'action.button.popup.save',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'exit': {
            type: 'timeSheet',
            action: 'exit',
            buttonId: 'right-tool-bar_close_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'global.close',
            showPopup: true,
            popupTitle: 'action.button.popup.close',
            buttons: [{ "name": "global.close-without-save" }, { "name": "global.no" }],
            isEnable: true,
            isShow: true
        },
        'reset': {
            type: 'timeSheet',
            action: 'reset',
            buttonId: 'right-tool-bar_reset_button',
            imageUrl: '/assets/svg/btn_action_clear.svg',
            actionDescription: 'pages.timesheet.reset-timesheet',
            showPopup: true,
            popupTitle: 'action.button.popup.reset',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'addStaff': {
            type: 'timeSheet',
            action: 'addStaff',
            buttonId: 'right-tool-bar_reassign_button',
            imageUrl: '/assets/svg/btn_action_reassign.svg',
            actionDescription: 'pages.timesheet.add-remove-staff',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'autoFill': {
            type: 'timeSheet',
            action: 'autoFill',
            buttonId: 'right-tool-bar_autofill_button',
            imageUrl: '/assets/svg/ic_timesheet.svg',
            actionDescription: 'pages.timesheet.auto-fill-timesheet',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
    },
    'teco': {
        'teco': {
            type: 'teco',
            action: 'teco',
            buttonId: 'right-tool-bar-teco-button',
            imageUrl: '/assets/svg/teco.svg',
            actionDescription: 'global.teco',
            showPopup: true,
            popupTitle: 'action.button.popup.teco',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'withDraw': {
            type: 'teco',
            action: 'withDraw',
            buttonId: 'right-tool-bar-withDraw-button',
            imageUrl: '/assets/svg/withdraw-teco.svg',
            actionDescription: 'global.withdraw',
            showPopup: true,
            popupTitle: 'action.button.popup.withdraw',
            buttons: [],
            isEnable: true,
            isShow: true
        },
    },
    'unTeco': {
        'unTeco': {
            type: 'unTeco',
            action: 'unTeco',
            buttonId: 'right-tool-bar-unTeco-button',
            imageUrl: '/assets/svg/unteco-1.svg',
            actionDescription: 'global.unTeco',
            showPopup: false,
            popupTitle: 'action.button.popup.un-teco',
            buttons: [],
            isEnable: true,
            isShow: true
        },
    },
    'stock': {
        'submit': {
            type: 'stock',
            action: 'submit',
            buttonId: 'right-tool-bar_submit_button',
            imageUrl: '/assets/svg/btn_action_submit.svg',
            actionDescription: 'global.submit',
            showPopup: true,
            permission: JMENUM.Permission.STOCK_RESERVE,
            popupTitle: 'action.button.popup.submit',
            buttons: [],
            isEnable: false,
            isShow: true
        },
        'exit': {
            type: 'stock',
            action: 'exit',
            buttonId: 'right-tool-bar_close_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'global.close',
            showPopup: true,
            popupTitle: 'global.leave-without-submit',
            buttons: [{ "name": "global.leave-without-submit.confirm" }, { "name": "global.no" }],
            isEnable: true,
            isShow: true
        },
    },
    'flms': {
        'edit': {
            type: 'edit',
            action: 'edit',
            buttonId: 'right-tool-bar-edit-button',
            imageUrl: '/assets/svg/btn_action_edit.svg',
            actionDescription: 'global.edit',
            showPopup: false,
            permission: JMENUM.Permission.FLMS_UPDATE || JMENUM.Permission.FLMS_UPDATE_CHI,
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'import': {
            type: 'import',
            action: 'import',
            buttonId: 'right-tool-bar-import-button',
            imageUrl: '/assets/svg/btn_action_file_import.svg',
            actionDescription: 'global.import',
            showPopup: false,
            permission: JMENUM.Permission.FLMS_UPLOAD || JMENUM.Permission.FLMS_UPLOAD_CHI,
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'export': {
            type: 'export',
            action: 'export',
            buttonId: 'right-tool-bar-export-button',
            imageUrl: '/assets/svg/btn_action_download.svg',
            actionDescription: 'global.export',
            showPopup: false,
            permission: [JMENUM.Permission.FLMS_VIEW, JMENUM.Permission.FLMS_VIEW_ALL],
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
    },
    'flmsPending': {
        'edit': {
            type: 'edit',
            action: 'edit',
            buttonId: 'right-tool-bar-edit-button',
            imageUrl: '/assets/svg/btn_action_edit.svg',
            actionDescription: 'global.edit',
            showPopup: false,
            permission: JMENUM.Permission.FLMS_UPDATE,
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'discard': {
            type: 'discard',
            action: 'discard',
            buttonId: 'right-tool-bar-discard-button',
            imageUrl: '/assets/svg/btn_action_clear.svg',
            actionDescription: 'global.discard',
            showPopup: false,
            permission: JMENUM.Permission.FLMS_UPDATE,
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
    },
    'approval': {
        'view': {
            type: 'approval',
            action: 'view',
            buttonId: 'right-tool-bar_view',
            imageUrl: '/assets/svg/btn_action_image.svg',
            actionDescription: 'action.button.view',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: [],
            isEnable: true,
            isShow: true
        },
        'approve': {
            type: 'approval',
            action: 'approve',
            buttonId: 'right-tool-bar_approve_button',
            imageUrl: '/assets/svg/btn_action_submit.svg',
            actionDescription: 'action.button.approve',
            showPopup: true,
            popupTitle: 'action.button.popup.approve-approval',
            buttons: [],
            permission: [],
            isEnable: true,
            isShow: true
        },
        'reject': {
            type: 'approval',
            action: 'reject',
            buttonId: 'right-tool-bar_reject',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.reject',
            showPopup: true,
            popupTitle: 'action.button.popup.reject-approval',
            buttons: [],
            permission: [],
            isEnable: true,
            isShow: true
        },
        'rejectWithReason': {
            type: 'approval',
            action: 'reject',
            buttonId: 'right-tool-bar_reject',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.reject',
            showPopup: false,
            popupTitle: 'action.button.popup.reject-approval',
            buttons: [],
            permission: [],
            isEnable: true,
            isShow: true
        },
        'withdraw': {
            type: 'approval',
            action: 'withdraw',
            buttonId: 'right-tool-bar_withdraw',
            imageUrl: '/assets/svg/btn_action_clear.svg',
            actionDescription: 'global.withdraw',
            showPopup: true,
            popupTitle: 'action.button.popup.withdraw-approval',
            buttons: [],
            permission: [],
            isEnable: true,
            isShow: true
        },
    },
    'pmPlan': {
        'submit': {
            type: 'pmPlan',
            action: 'submit',
            buttonId: 'right-tool-bar_submit_button',
            imageUrl: '/assets/svg/btn_action_submit.svg',
            actionDescription: 'action.button.submit',
            showPopup: true,
            popupTitle: 'action.button.popup.submit',
            buttons: [],
            permission: JMENUM.Permission.PMPLAN_SUBMIT,
            isEnable: true,
            isShow: true
        },
        'cancel': {
            type: 'pmPlan',
            action: 'cancel',
            buttonId: 'right-tool-bar_cancel_button',
            imageUrl: '/assets/svg/btn_action_cancel.svg',
            actionDescription: 'action.button.cancel',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.PMPLAN_CANCEL,
            isEnable: true,
            isShow: true
        },
        'edit': {
            type: 'pmPlan',
            action: 'edit',
            buttonId: 'right-tool-bar-edit-button',
            imageUrl: '/assets/svg/btn_action_edit.svg',
            actionDescription: 'global.edit',
            showPopup: false,
            // permission: 
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'attachment': {
            type: 'pmPlan',
            action: 'attachment',
            buttonId: 'right-tool-bar_attachment_button',
            imageUrl: '/assets/svg/btn_action_attach.svg',
            actionDescription: 'action.button.attachment',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            // permission: 
            isEnable: true,
            isShow: true
        },
        'equipment': {
            type: 'pmPlan',
            action: 'equipment',
            buttonId: 'right-tool-bar_export_button',
            imageUrl: '../assets/svg/btn_action_equipment.svg',
            iconClass: '',
            actionDescription: 'action.button.equipment',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            // // permission: 
            isEnable: true,
            isShow: true
        },
        'location': {
            type: 'pmPlan',
            action: 'location',
            buttonId: 'right-tool-bar_export_button',
            imageUrl: '../assets/svg/btn_action_equipment.svg',
            iconClass: '',
            actionDescription: 'action.button.location',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            // // permission: 
            isEnable: true,
            isShow: true
        },
        'save': {
            type: 'pmPlan',
            action: 'save',
            buttonId: 'right-tool-bar_save_button',
            imageUrl: '/assets/svg/btn_action_save.svg',
            actionDescription: 'action.button.save',
            showPopup: false,
            popupTitle: 'action.button.popup.save',
            buttons: [],
            // permission: JMENUM.Permission.PMPLAN_CREATE, JMENUM.Permission.PMPLAN_UPDATE
            isEnable: true,
            isShow: true
        },
        'clear': {
            type: 'pmPlan',
            action: 'clear',
            buttonId: 'right-tool-bar_clear_button',
            imageUrl: '/assets/svg/btn_action_clear.svg',
            actionDescription: 'action.button.clear',
            showPopup: true,
            popupTitle: 'action.button.popup.clear',
            buttons: [],
            // permission: 
            isEnable: true,
            isShow: true
        },
        'close': {
            type: 'pmPlan',
            action: 'close',
            buttonId: 'right-tool-bar_close_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.close',
            showPopup: true,
            popupTitle: 'action.button.popup.close',
            buttons: [{ "name": "global.close-without-save" }, { "name": "global.no" }],
            // permission: 
            isEnable: true,
            isShow: true
        },
        'createPmJob': {
            type: 'pmPlan',
            action: 'createPmJob',
            buttonId: 'right-tool-bar_create-pm-job-button',
            imageUrl: '/assets/svg/btn_action_job_create_pm_job.svg',
            actionDescription: 'action.button.create-pm-job',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            // permission: Permission.jobcardCreate,
            isEnable: true,
            isShow: true
        },
    },
    'pmJob': {
        'submit': {
            type: 'pmJob',
            action: 'submit',
            buttonId: 'right-tool-bar_submit_button',
            imageUrl: '/assets/svg/btn_action_submit.svg',
            actionDescription: 'action.button.submit',
            showPopup: true,
            popupTitle: 'action.button.popup.submit',
            buttons: [],
            permission: JMENUM.Permission.PMJOB_SUBMIT,
            isEnable: true,
            isShow: true
        },
        'cancel': {
            type: 'pmJob',
            action: 'cancel',
            buttonId: 'right-tool-bar_cancel_button',
            imageUrl: '/assets/svg/btn_action_cancel.svg',
            actionDescription: 'action.button.cancel',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.PMJOB_CANCEL,
            isEnable: true,
            isShow: true
        },
        'edit': {
            type: 'pmJob',
            action: 'edit',
            buttonId: 'right-tool-bar-edit-button',
            imageUrl: '/assets/svg/btn_action_edit.svg',
            actionDescription: 'global.edit',
            showPopup: false,
            permission: JMENUM.Permission.PMJOB_UPDATE,
            popupTitle: '',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'attachment': {
            type: 'pmJob',
            action: 'attachment',
            buttonId: 'right-tool-bar_attachment_button',
            imageUrl: '/assets/svg/btn_action_attach.svg',
            actionDescription: 'action.button.attachment',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.PMJOB_UPDATE,
            isEnable: true,
            isShow: true
        },
        'inspect': {
            type: 'pmJob',
            action: 'inspect',
            buttonId: 'right-tool-bar_inspect_button',
            imageUrl: '/assets/svg/btn_action_inspect.svg',
            actionDescription: 'action.button.inspect',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            permission: JMENUM.Permission.JOBCARD_INSPECTION,
            isEnable: true,
            isShow: true
        },
        'equipment': {
            type: 'pmJob',
            action: 'equipment',
            buttonId: 'right-tool-bar_export_button',
            imageUrl: '../assets/svg/btn_action_equipment.svg',
            iconClass: '',
            actionDescription: 'action.button.equipment',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            // // permission: 
            isEnable: true,
            isShow: true
        },
        'location': {
            type: 'pmJob',
            action: 'location',
            buttonId: 'right-tool-bar_export_button',
            imageUrl: '../assets/svg/btn_action_equipment.svg',
            iconClass: '',
            actionDescription: 'action.button.location',
            showPopup: false,
            popupTitle: '',
            buttons: [],
            // // permission: 
            isEnable: true,
            isShow: true
        },
        'save': {
            type: 'pmJob',
            action: 'save',
            buttonId: 'right-tool-bar_save_button',
            imageUrl: '/assets/svg/btn_action_save.svg',
            actionDescription: 'action.button.save',
            showPopup: false,
            popupTitle: 'action.button.popup.save',
            buttons: [],
            permission: JMENUM.Permission.PMJOB_CREATE,
            isEnable: true,
            isShow: true
        },
        'clear': {
            type: 'pmJob',
            action: 'clear',
            buttonId: 'right-tool-bar_clear_button',
            imageUrl: '/assets/svg/btn_action_clear.svg',
            actionDescription: 'action.button.clear',
            showPopup: true,
            popupTitle: 'action.button.popup.clear',
            buttons: [],
            // permission: 
            isEnable: true,
            isShow: true
        },
        'close': {
            type: 'pmJob',
            action: 'close',
            buttonId: 'right-tool-bar_close_button',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.close',
            showPopup: true,
            popupTitle: 'action.button.popup.close',
            buttons: [],
            // permission: 
            isEnable: true,
            isShow: true
        },
        'generateCcsSo': {
            type: 'pmJob',
            action: 'generateCcsSo',
            buttonId: 'right-tool-bar_generate-ccs-so_button',
            imageUrl: '/assets/svg/btn_action_obtain_so.svg',
            actionDescription: 'action.button.generate-ccs-so',
            showPopup: false,
            popupTitle: 'action.button.popup.obtain-ccs-so',
            buttons: [],
            permission: JMENUM.Permission.PMJOB_UPDATE,
            isEnable: true,
            isShow: true
        },
        'reopen': {
            type: 'pmJob',
            action: 'reopenPmJob',
            buttonId: 'right-tool-bar_reopen_button',
            imageUrl: '/assets/svg/btn_action_job_reopen.svg',
            actionDescription: 'action.button.reopen',
            showPopup: true,
            popupTitle: 'action.button.popup.pm-job.reopen',
            buttons: [],
            permission: JMENUM.Permission.PMJOB_REOPEN,
            isEnable: true,
            isShow: true
        },
        'approveSubmittion': {
            type: 'pmJob',
            action: 'approveSubmittion',
            buttonId: 'right-tool-bar_approve_button',
            imageUrl: '/assets/svg/btn_action_submit.svg',
            actionDescription: 'action.button.approve',
            showPopup: true,
            popupTitle: 'action.button.popup.approve-approval',
            buttons: [],
            isEnable: true,
            isShow: true
        },
        'rejectSubmittion': {
            type: 'pmJob',
            action: 'rejectSubmittion',
            buttonId: 'right-tool-bar_reject',
            imageUrl: '/assets/svg/btn_action_reject.svg',
            actionDescription: 'action.button.reject',
            showPopup: false,
            popupTitle: 'action.button.popup.reject-approval',
            buttons: [],
            isEnable: true,
            isShow: true
        },
    },
    "jmAccountReview": {
        'history': {
            type: 'jmAccountReview',
            action: 'history',
            buttonId: 'right-tool-bar_history_button',
            imageUrl: '/assets/svg/btn_action_history.svg',
            class: 'fix-size-action-btn p-1-0-3-0',
            actionDescription: 'action.button.history',
            showPopup: false,
            buttons: [],
            permission: JMENUM.Permission.ACCOUNT_REVIEW_SEND_EMAIL,
            isEnable: true,
            isShow: true
        },
        'downloadTemplate': {
            type: 'jmAccountReview',
            action: 'downloadTemplate',
            buttonId: 'right-tool-bar_download-template_button',
            imageUrl: '/assets/svg/btn_action_download2.svg',
            class: 'fix-size-action-btn p-1-0-3-0',
            actionDescription: 'action.button.download-template',
            showPopup: false,
            buttons: [],
            permission: JMENUM.Permission.ACCOUNT_REVIEW_CREATE,
            isEnable: true,
            isShow: true
        }
    }
}

export interface ActionButtonContent {
    type: string,
    action: string,
    buttonId: string,
    imageUrl: string,
    actionDescription: string,
    showPopup: boolean,
    popupTitle: string,
    buttons: any[],
    permission: any,
    isEnable: boolean,
    isShow: boolean
}

export enum ActionButtonSn {
    edit = 'edit',
    eFax = 'eFax',
    exportPdf = 'exportPdf',
    submit = 'submit',
    save = 'save',
    saveAndResend = 'saveAndResend',
    copyInNew = 'copyInNew',
    clear = 'clear',
    close = 'close',
    reRoute = 'reRoute',
    transfer = 'transfer',
    reject = 'reject',
    cancel = 'cancel',
    acknowledge = 'acknowledge',
    manualAcknowledge = 'manualAcknowledge',
    jobCreateAdHoc = 'jobCreateAdHoc',
    jobCreateSla = 'jobCreateSla',
    jobCreateTransfer = 'jobCreateTransfer',
    jobCreateFollowUp = 'jobCreateFollowUp',
    createEnquiry = 'createEnquiry',
    back = 'back',
    attachment = 'attachment',
    haServiceReport = 'haServiceReport',
    associate = 'associate',
    eForm = 'eForm'
}

export enum ActionButtonJobCard {
    edit = 'edit',
    save = 'save',
    cancel = 'cancel',
    accept = 'accept',
    reassign = 'reassign',
    eFax = 'eFax',
    exportPdf = 'exportPdf',
    exportHaPdf = 'exportHaPdf',
    jobReopen = 'jobReopen',
    reject = 'reject',
    close = 'close',
    attach = 'attach',
    jobComplete = 'jobComplete',
    jobCompleteHandled = 'jobCompleteHandled',
    jobCompleteWithNoAction = 'jobCompleteWithNoAction',
    reserveStock = 'reserveStock',
    generateCcsSo = 'generateCcsSo',
    createDossPo = 'createDossPo',
    timeSheet = 'timeSheet',
    timeSheetV2 = 'timeSheetV2',
    teco = 'teco',
    withDraw = 'withDraw',
    unTeco = 'unTeco',
    approve = 'approve',
    rejectCompletion = 'rejectCompletion',
    inspect = 'inspect'
}

export enum ActionButtonType {
    sn = 'sn',
    jobCard = 'jobCard',
    timeSheet = 'timeSheet',
    teco = 'teco',
    unTeco = 'unTeco',
    stock = 'stock',
    flms = 'flms',
    approval = 'approval',
    flmsPending = 'flmsPending',
    pmPlan = 'pmPlan',
    pmJob = 'pmJob',
    jmAccountReview = 'jmAccountReview'
}

export enum ActionButtonTypeJobCard {
    create = 'create',
    close = 'close',
    clear = 'clear',
}

export enum ActionButtonTimeSheet {
    save = 'save',
    exit = 'exit',
    autoFill = 'autoFill',
    reset = 'reset',
    addStaff = 'addStaff'
}

export enum ActionButtonTeco {
    teco = 'teco',
    withDraw = 'withDraw'
}

export enum ActionButtonUnTeco {
    unTeco = 'unTeco',
    withDraw = 'withDraw'
}

export enum ActionButtonStock {
    submit = 'submit',
    exit = 'exit',
}

export enum ActionButtonFlms {
    edit = 'edit',
    import = 'import',
    export = 'export'
}

export enum ActionButtonFlmsPending {
    edit = 'edit',
    discard = 'discard'
}

export enum ActionButtonApproval {
    view = 'view',
    approve = 'approve',
    reject = 'reject',
    rejectWithReason = 'rejectWithReason',
    withdraw = 'withdraw',
}


export enum ActionButtonPmPlan {
    submit = 'submit',
    cancel = 'cancel',
    edit = 'edit',
    attachment = 'attachment',
    equipment = 'equipment',
    location = 'location',
    save = 'save',
    clear = 'clear',
    close = 'close',
    createPmJob = 'createPmJob'
}

export enum ActionButtonPmJob {
    submit = 'submit',
    cancel = 'cancel',
    edit = 'edit',
    attachment = 'attachment',
    copyInNew = 'copy in new',
    reject = 'reject',
    generateCcsSo = 'generateCcsSo',
    timesheet = 'timesheet',
    equipment = 'equipment',
    location = 'location',
    save = 'save',
    clear = 'clear',
    close = 'close',
    reopen = 'reopen',
    approveSubmittion = 'approveSubmittion',
    rejectSubmittion = 'rejectSubmittion',
    inspect = 'inspect'
}

export enum ActionButtonJMAccountReview {
    history = 'history',
    downloadTemplate = 'downloadTemplate'
}