"use strict";

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Session } from 'src/app/services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JM } from '@ccep/CCEPConnector-ts';
import { PopUpDialogComponent } from 'src/app/ui/components/pop-up-dialog/pop-up-dialog.component'; //todo remove
import { RoutePaths } from 'src/constants';
import { environment } from 'src/environments/environment';


class AppDelegateClass {
  appInjector = undefined;

  constructor() { }

  logout(reloginSuccessUrl?: string) {
    if (Session.userToken) {
      const request = new JM.JMRequestAuthRevokeAccessToken();
      request.tokenList = [Session.userToken];
      this.sendJMRequest(request);
    }
    
    Session.clear();

    if (reloginSuccessUrl) {
      this.appInjector.get(Router).navigate(['/login'], {
        queryParams: {
          successUrl: reloginSuccessUrl,
        },
      });
    } else {
      this.appInjector.get(Router).navigate(['/login']);
    }
  }

  navigate(newPage, param?) {
    this.appInjector.get(Router).navigate(newPage, param);
  }
  // getSelectedCluster() {
  //   let selectedCluster = null;
  //   let wc = Session.workCentreDict[Session.selectedWorkCentre];
  //   if (wc) {
  //     selectedCluster = wc.HACluster;
  //   }

  //   return selectedCluster;
  // }

  //-----------------------------------------------------------------
  // send request function
  async sendJMRequest(request: JM.JMRequest) {
    try {
      let response = await JM.JMConnector.sendJMRequest(request);
      if (response.code === 99503) {
        const redirUrl = response['jm-url'];
        window.location = redirUrl;
        return null;
      }
      return response;
    } catch (error) {
      if (error.code === 498) {
        this.logout();
      }
      if (error.code === 401) {
        this.ToJmNoPermissionPage();
      }
      return error;
    }
  }

  async sendJMRequestWithFileHost(request: JM.JMRequest) {
    try {
      let response = await JM.JMConnector.sendJMRequest(request, null, JM.JMConnector.getFileHost());
      return {
        payload: response,
        code: 200
      };
    } catch (error) {
      if (error.code === 498) {
        this.logout();
      }
      if (error.code === 401) {
        this.ToJmNoPermissionPage();
      }
      return error;
    }
  }

  async sendJMRequestFile(request: JM.JMRequest, stream: FormData) {
    try {
      let response = await JM.JMConnector.sendJMFileRequest(request, stream);
      return response;
    } catch (error) {
      if (error.code === 498 ) {
        this.logout();
      }
      if (error.code === 401) {
        this.ToJmNoPermissionPage();
      }
      return error;
    }
  }

  async sendJMFileRequestWithFileHost(request: JM.JMRequest, stream: FormData) {
    try {
      let response = await JM.JMConnector.sendJMFileRequest(request, stream, null, JM.JMConnector.getFileHost());
      return {
        payload: response,
        code: 200
      };
    } catch (error) {
      if (error.code === 498) {
        this.logout();
      }
      if (error.code === 401) {
        this.ToJmNoPermissionPage();
      }
      return error;
    }
  }

  //------------------------
  openSnackBar(message: string, customClassName: string = null) {
    this.appInjector.get(MatSnackBar).open(message, JMLanguage.translate("global.close"), {
      duration: 6000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      announcementMessage: ' ',
      ...(customClassName && { panelClass: [customClassName] })
    });
  }

  handleJMError(error: JM.JMNetworkError) {
    console.error(error);
    if (error.code === 498) {
      this.logout();
    }
    if (error.code === 401) {
      this.ToJmNoPermissionPage();
    }
    this.openSnackBar(JMLanguage.translate('popupError.' + error.code + '.title'));
  }

  openErrorBar(error?: Object) {
    let code = (error && error['code']) ? error['code'] : 99999;
    if (code === 498) {
      this.logout();
    }
    if (code === 401) {
      this.ToJmNoPermissionPage();
    }
    let message = JMLanguage.translate("api.error." + code);
    if (message.includes("api.error.")) {
      message = JMLanguage.translate('popupError.' + code + '.title');
    }
    if (error && (message.includes("api.error.") || message.includes("popupError."))) {
      message = error['error'];
    }
    this.openSnackBar(message);
  }

  showPopUpAlert(title?: string, message?: string, buttons: any[] = [{ name: JMLanguage.translate("global.ok") }]) {
    const dialogRef = this.appInjector.get(MatDialog).open(PopUpDialogComponent, {
      data: { title: title, message: message, buttons: buttons }
    });

    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        action();
      }
    });

    dialogRef.disableClose = true;
    return dialogRef;
  }

  getWorkCentreUnderCluster(cluster):string[] {
    let workCentreArray:string[] = [];

    for(let wc of Object.values(Session.workCentreDict)) {
      if (wc.HACluster == cluster) {
        workCentreArray.push(wc.workCentreCode);
      }
    }

    return workCentreArray;    
  }

  ToJmNoPermissionPage() {
    this.appInjector.get(Router).navigate([RoutePaths.JM_NO_PERMISSION_PAGE_PATH]);
  }
}

let AppDelegate = new AppDelegateClass()
export {
  AppDelegate
}

