<div class="wrapper">
  <app-nav [navbarTitle]="'pages.job-list.page-title' | translate">
  </app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="">
    <div class="row mx-0 flex-column-reverse flex-xl-row">
      <div class="col-12 col-xl-9 ">
        <h2 class="title">{{ "pages.job-list.job-list" | translate }}</h2>
        <hr class="underline" />

        <app-tablex id="job-list-table" #jobListTable [tablexParam]="tablexParam" class=""></app-tablex>

      </div>
      <div class="col-12 col-xl-3">
        <app-jobcard-job-list-adv-search (onSearch)="onAdvancedSearch($event)"></app-jobcard-job-list-adv-search>
      </div>
    </div>  


    
  </div>
</div>
