import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-pm-job-remarks',
  templateUrl: './pm-job-remarks.component.html',
  styleUrls: ['./pm-job-remarks.component.scss'],
})
export class PmJobRemarksComponent implements OnInit {
  @Input() job: JMOBJ.PmJob;

  messages: {
    title: string;
    details: string;
  }[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.job) {
      this.parseMessages();
    }
  }

  parseMessages() {
    this.messages = [];

    let cancelCode = this.job && this.job.cancelReason;
    let cancelRemarks = this.job && this.job.cancelRemarks;
    if (cancelCode) {
      let cancelDescription = JMREASON.PM_JOB_CANCEL_REASON_LIST.getDescription(cancelCode);
      let cancelDescriptionWithLang = '';
      if (cancelDescription) {
        cancelDescriptionWithLang =
          Session.selectedLanguage === JMENUM.Language.ZH ? cancelDescription.zh : cancelDescription.en;
      }
      let message = {
        title: JMLanguage.translate('component.pm-job-remarks.message.cancelReason'),
        details: `[${cancelDescriptionWithLang}] ${cancelRemarks}`,
      };

      this.messages.push(message);
    }
  }
}
