<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs" >
  </app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding">
    <div class="reserve-detail">
      <div class="mb-2">
        <span class="font-size-28">
          {{ 'pages.reserve-detail.page-title' | translate }}
        </span>
      </div>
      <div class="mb-4">
        {{ 'pages.reserve-detail.page-remark' | translate }}
      </div>
      
      <div class="d-flex justify-content-between mt-4">
        <span class="font-size-xl">
          {{ 'pages.reserve-detail.stock-table.title' | translate }}
        </span>
      </div>
      <app-tablex id="reserve-table" [tablexParam]="reservationTablexParam" class="mb-4"></app-tablex>
    </div>

    <div *ngIf="stockReservation && stockReservation.aiStore" class="ai-store mt-5">
      <div class="d-flex mb-2 align-items-center">
        <img class="mr-2" [ngStyle]="{'height.px':30,'width.px':30}" src="/assets/svg/ai_store.svg" />
        <span class="font-size-28">
          {{ 'component.reserve-stock.ai-store-panel.title' | translate }}
        </span>
      </div>
      <div class="mb-4">
        {{ 'component.reserve-stock.ai-store-panel.remark' | translate }}
      </div>
      
      <div class="row">
        <div class="col-4">{{ 'component.reserve-stock.ai-store-panel.cost-centre' | translate }}:</div>
        <div class="col-8">{{ stockReservation.aiStore.costCentre }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'component.reserve-stock.ai-store-panel.estimate-pickup-date' | translate }}:</div>
        <div class="col-8">{{ estimatedPickupDate }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'component.reserve-stock.ai-store-panel.consignee-post' | translate }}:</div>
        <div class="col-8">{{ stockReservation.aiStore.consigneePost }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'component.reserve-stock.ai-store-panel.consignee-contact-no' | translate }}:</div>
        <div class="col-8">{{ stockReservation.aiStore.consigneeContactNumber }}</div>
      </div>
    </div>
  </div>
</div>
