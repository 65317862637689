<div class="font-size-xl m-0 mb-2 px-2">
  {{ "component.equipment-information.title" | translate}}
</div>

<hr class="underline" />

<div class="row align-items-center">
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{ "component.equipment-information.equipment-id" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="equip-info-equipment-id" [textField]="jobCard?.equipment" [emptyHandleDisplay]="emptyHandle">
    </app-display-text-field>
  </div>
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.equipment-information.functional-location" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="equip-info-functional-location" [textField]="functionalLocation" [attr.data-content]="jobCard?.functionalLocation" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>

  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.equipment-information.description" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="equip-info-description" [textField]="equipment?.description" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    {{ "component.equipment-information.owner" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="equip-info-owner" [textField]="jobCard?.equipmentClient" [emptyHandleDisplay]="emptyHandleNa">
    </app-display-text-field>
  </div>


  <!-- <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">

    {{ "component.equipment-information.fault-type" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
    <app-display-text-field id="equip-info-fault-type" [textField]="" emptyHandleDisplay="component.display-text-field.na">
    </app-display-text-field>
  </div> -->
</div>


