import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign, TablexColumnFilterType, TablexColumnFilterOption } from 'src/app/entity/enum/tablexColumnType';
import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as FORAMTTER from 'src/app/presenter/Formatter'
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { BasePage } from 'src/app/ui/model/base/base';
import { debounceTime } from 'rxjs/operators';
import { Permission } from '@enum/permission';
import { Constants } from 'src/constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from '@services/session';

enum CONTRACT_TYPE {
  DUMMY = "dummy",
  PMSMC = "pmsmc",
  NON_PMSMC = "nonpmsmc",
  TRANSITIONAL = "transitional",
}

@Component({
  selector: 'dummy-contract',
  templateUrl: './dummy-contract.component.html',
  styleUrls: ['./dummy-contract.component.scss']
})
export class DummyContractComponent extends BasePage implements OnInit {
  // edit ccs contract view
  @ViewChild("edit_ccs_contract_panel", { static: true }) openEditCcsContractPanel;
  @ViewChild("editCcsContractForm", { static: true }) editCcsContractForm;
  editCcsContractParameters: {
    allContractArray: JMOBJ.MaintenanceTermContract[],
    onSaveButtonClicked: Function,
  };

  // add new transitional contract view
  @ViewChild("add_transitional_contract_panel", { static: true }) openAddTransContractPanel;
  @ViewChild("addTransitionalContractForm", { static: true }) addTransitionalContractForm;
  pageTitle: string;
  addTransContractParameters: {
    allContractArray: JMOBJ.MaintenanceTermContract[],
    onSaveButtonClicked: Function,
    onDeleteButtonClicked: Function,
    mode: string,
  };

  // add new dummy contract view
  @ViewChild("add_dummy_contract_panel", { static: true }) openAddDummyContractPanel;
  @ViewChild("addDummyContractForm", { static: true }) addDummyContractForm;
  addContractParameters: {
    allContractArray: JMOBJ.MaintenanceTermContract[],
    onSaveButtonClicked: Function,
    onDeleteButtonClicked: Function,
    mode: string,
  };

  //
  contractArray:JMOBJ.MaintenanceTermContract[] = []; // contain only transitional and dummy contracts
  allContractArray:JMOBJ.MaintenanceTermContract[] = []; // contain all contracts
  contractWorkCentreDict: {
    [id: string]: string[]  // key: contract number, value: work centre code array
  }
  
  // Table
  private searchTerms = new Subject<any[]>();
  searchInputText     : String;
  tablexFilter: {
    contractNumber?: string,
    title?: string,
    vendorEndDate?: string,
    vendorName?: string,
    vendorNumber?: string,
    vendorStartDate?: string,
  } = {};
  tablexParam         : {} = {};
  pageSizeOptions     = [10, 25, 100];
  currentPageSize     = 10;
  currentPage         = 1;
  pageCount           = 1;
  uiTotalEquipmentCount = 0;

  uiIsShowExpired: boolean = false;

  // Form Data
  newDummyContractNumber: string;

  // permissions
  hasDummyTranUpdatePermission: boolean = false;
  hasContractUpdatePermission: boolean = false;

  constructor(
    public injector: Injector,
    public router: Router,
  ){
    super(injector)
  }

  ngOnInit(){
    this.pageTitle = JMLanguage.translate("pages.contract-dummy.page-title");
    this.hasDummyTranUpdatePermission = this.authorizationService.hasPermission(JMENUM.Permission.CONTRACT_TRANSDUMMY_UPDATE);
    this.hasContractUpdatePermission = this.authorizationService.hasPermission(JMENUM.Permission.CONTRACT_UPDATE);
    // this.searchTerms.pipe(
    //   debounceTime(Constants.DEBOUNCE_TIME),
    //   // distinctUntilChanged(),
    // ).subscribe((header) => {
    //   this.onFilterChanged(header);
    // });


    this.contractArray = [];
    this.allContractArray = [];
    this.editCcsContractParameters = {
      allContractArray: [],
      onSaveButtonClicked: this.onCcsContractEditButtonClicked.bind(this),
    };
    this.addContractParameters = {
      allContractArray: [],
      onSaveButtonClicked: null,
      onDeleteButtonClicked: this.onDeleteButtonClicked,
      mode: 'add',
    };
    this.addTransContractParameters = {
      allContractArray: [],
      onSaveButtonClicked: null,
      onDeleteButtonClicked: this.onDeleteButtonClicked,
      mode: 'add',
    };

    const hasPagePermission = this.authorizationService.hasPermission(JMENUM.Permission.CONTRACT_VIEW);
    if (!hasPagePermission) {
      this.router.navigate(['']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
    }

    let contractTypeFilterOption = new TablexColumnFilterOption();
    // contractTypeFilterOption.change
    contractTypeFilterOption.items = [{
        value: CONTRACT_TYPE.DUMMY,
        label: JMLanguage.translate("global.contract-type.dummy"),
      }, {
        value: CONTRACT_TYPE.NON_PMSMC,
        label: JMLanguage.translate("global.contract-type.non-pmsmc"),
      }, {
        value: CONTRACT_TYPE.PMSMC,
        label: JMLanguage.translate("global.contract-type.pmsmc"),
      }, {
        value: CONTRACT_TYPE.TRANSITIONAL,
        label: JMLanguage.translate("global.contract-type.transitional"),
      }];
    contractTypeFilterOption.bindLabel = "label";
    contractTypeFilterOption.bindValue = "value";
    contractTypeFilterOption.change = this.onContractTypeFilterChanged;
    
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      tableRow              : "row",
      tableClass            : "sn-list-table",
      tableWrapperClass     : "table-min-width",
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      // onFilterKeyUp         : this.onFilterKeyUp,
      onFilterChanged       : this.onFilterChanged,
      enableStickyHeader    : false,
      filterDebounceTime    : Constants.DEBOUNCE_TIME,
      headers: [ 
        {
          id: 'contractType',
          name: "pages.contract-dummy.table-column-contract-type",
          enableFilter: true,
          type: TablexColumnType.Text, 
          filterType: TablexColumnFilterType.Dropdown, 
          filterDropdownOption: contractTypeFilterOption,
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 p-1 p-xl-3",
        },
        {
          id: 'contractNumber',
          name: "pages.contract-dummy.table-column-contract-number",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 p-1 p-xl-3",
        },
        {
          id: 'vendorNumber',
          name: "pages.contract-dummy.table-column-contractor-number",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 p-1 p-xl-3",
        },
        {
          id: 'vendorName',
          name: "pages.contract-dummy.table-column-contractor-name",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 p-1 p-xl-3",
        },
        {
          id: 'title',
          name: "pages.contract-dummy.table-column-contract-title",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-3 p-1 p-xl-3",
        },
        {
          id: 'vendorStartDate',
          name: "pages.contract-dummy.table-column-start-date",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 p-1 p-xl-3",
        },
        {
          id: 'vendorEndDate',
          name: "pages.contract-dummy.table-column-end-date",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 p-1 p-xl-3",
        },
        {
          id: 'workCentre',
          name: "pages.contract-dummy.table-column-work-centre",
          enableFilter: false,
          type: TablexColumnType.MultiLine, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 p-1 p-xl-3",
        },
        {
          id: 'rowAction',
          name: "pages.contract-dummy.table-column-action",
          enableFilter: false,
          type: TablexColumnType.Buttons, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1 p-1 p-xl-3",
        }
      ],
      content: []
    }

    this.requestDummyMainContract();
  }

  
  
  //----------------------------------------------------
  // update view function
  renderTable() {
    this.tablexParam['content'] = [];
    let contract:JMOBJ.MaintenanceTermContract;
    for (contract of this.contractArray) {
      let contractType = '';
      if (contract.type == JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT) {
        contractType = JMLanguage.translate("global.contract-type.transitional");
        
      } else if (contract.type == JMENUM.MaintenanceContractType.DUMMY_CONTRACT) {
        contractType = JMLanguage.translate("global.contract-type.dummy");

      } else {
        contractType = (contract.isPmsmcContract) ?
          JMLanguage.translate("global.contract-type.pmsmc"):JMLanguage.translate("global.contract-type.non-pmsmc");
      }
      
      this.tablexParam['content'].push([
        contractType,
        FORAMTTER.formatContractNumber(contract),
        contract.vendorNumber,
        contract.vendorName,
        contract.title,
        moment(contract.vendorStartDate, "YYYYMMDD").format("DD/MM/YYYY"),
        moment(contract.vendorEndDate, "YYYYMMDD").format("DD/MM/YYYY"),
        contract.workCentres,
        [{
          id: "add", 
          name: "", 
          class: "glyph brand-amber", 
          icon: "fas fa-edit", 
          onClicked: this.onEditContractButtonClicked, 
          disable: !this.hasContractUpdatePermission,
          object: contract
        }]
      ]);
    }
  }

  //----------------------------------------------------
  // API functions
  async requestDummyMainContract() {
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    request.filter = {...this.tablexFilter};  // clone a copy
    if(!this.uiIsShowExpired){
      request.expired = JMENUM.RequestExpired.UNEXPIRED;
    }

    request.type = [JMENUM.MaintenanceContractType.CCS_CONTRACT, JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT, JMENUM.MaintenanceContractType.DUMMY_CONTRACT];
    if (request.filter['contractType']) {
      let inputContractType = this.tablexFilter['contractType'];

      if (inputContractType == CONTRACT_TYPE.DUMMY) {
        request.type = [JMENUM.MaintenanceContractType.DUMMY_CONTRACT];
      } else if (inputContractType == CONTRACT_TYPE.TRANSITIONAL) {
        request.type = [JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT];
      } else if (inputContractType == CONTRACT_TYPE.NON_PMSMC) {
        request.type = [JMENUM.MaintenanceContractType.CCS_CONTRACT];
        request.contractType = "NONPMSMC";
      } else if (inputContractType == CONTRACT_TYPE.PMSMC) {
        request.type = [JMENUM.MaintenanceContractType.CCS_CONTRACT];
        request.contractType = "PMSMC";
      }
      delete request.filter['contractType'];
    }

    
    // start date DD/MM/YYYY -> YYYYMMDD
    if (this.tablexFilter['vendorStartDate']) {
      request.filter.vendorStartDate = this.tablexFilter['vendorStartDate'].split("/").reverse().join("");
    }
    if (this.tablexFilter['vendorEndDate']) {
      request.filter.vendorEndDate = this.tablexFilter['vendorEndDate'].split("/").reverse().join("");
    }

    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.contractArray = response.payload.records;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount/this.currentPageSize);
    //this.requestContractWorkCentre();
    this.renderTable();
  }

  async requestAllContract(): Promise<void> {
    let batchSize = 1000;
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.expired = JMENUM.RequestExpired.UNEXPIRED;
    request.includeSummary = false;
    
    this.allContractArray = [];
    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let totalCount = response.payload.totalCount;
    for (let i=0; i<Math.ceil(totalCount/batchSize); i++) {
      const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
      request.expired = JMENUM.RequestExpired.UNEXPIRED;
      request.includeSummary = true;
      request.pageSize = batchSize;
      request.pageNumber = i+1;

      const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      this.allContractArray = this.allContractArray.concat(response.payload.records);
      this.addContractParameters.allContractArray = this.allContractArray;

      let contract :JMOBJ.MaintenanceTermContract;
      let contractorDict = {};
      for (contract of this.addContractParameters.allContractArray) {
        if ((contract.vendorNumber in contractorDict) == false) {
          contractorDict[contract.vendorNumber] = contract.vendorName;
        }
      }
      this.addDummyContractForm.setContractorDict(contractorDict);
      this.addTransitionalContractForm.setContractorDict(contractorDict);
    }
  }
  
  // requestContractWorkCentre() {
  //   let request = new JM.JMRequestGetWorkCentreCodeByContract();
  //   request.contractArray = this.contractArray.map(c => { return c.contractNumber; });
  //   if (request.contractArray.length > 0) {
  //     JM.JMConnector.sendGetWorkCentreCodeByContract(request, (error:JM.JMNetworkError, response:JM.JMResponseGetWorkCentreCodeByContract) => {
  //       if (error) {
  //         AppDelegate.handleJMError(error);
  //         return;
  //       }
  //       if (!response || !response.code || response.code != 200 || !response.payload) {
  //         AppDelegate.openErrorBar(response);
  //         return;
  //       }
  //       this.contractWorkCentreDict = response.payload;
  //       this.renderTable();
  //     });
  //   } else {
  //     this.renderTable();
  //   }
  // }

  //----------------------------------------------------
  // button callback functions
  onContractTypeFilterChanged = (event, i, header)  => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }
    this.currentPage = 1;
    this.requestDummyMainContract();
  }

  onEditContractButtonClicked = async (row) => {
    let contract:JMOBJ.MaintenanceTermContract = row.object;
    if (contract.type === JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT) {
      this.addTransitionalContractForm.clearView();
      this.addTransitionalContractForm.setMode('edit');
      this.addTransContractParameters.onSaveButtonClicked = this.onAddTransContractUpdateButtonClicked;
      this.addTransitionalContractForm.setDeletable(false);

      this.addTransitionalContractForm.uiContractNumber = contract.contractNumber;
      this.addTransitionalContractForm.uiContractorNumber = contract.vendorNumber;
      this.addTransitionalContractForm.uiContractorName = contract.vendorName;
      this.addTransitionalContractForm.uiContractTitle = contract.title;
      this.addTransitionalContractForm.uiLiftTrapped = contract.liftTrapped;
      this.addTransitionalContractForm.uiFindEquipmentBy = contract.findEquipmentBy;
      this.addTransitionalContractForm.setContractStartDate(contract.vendorStartDate);
      this.addTransitionalContractForm.setContractEndDate(contract.vendorEndDate);

      this.openAddTransContractPanel.toggle();
    } else if (contract.type === JMENUM.MaintenanceContractType.DUMMY_CONTRACT) {
      this.addDummyContractForm.clearView();
      this.addDummyContractForm.setMode('edit');
      this.addContractParameters.onSaveButtonClicked = this.onAddDummyContractUpdateButtonClicked;
      this.addDummyContractForm.setDeletable(false);

      this.addDummyContractForm.uiContractNumber = contract.contractNumber.substring(4);
      this.addDummyContractForm.uiContractorNumber = contract.vendorNumber;
      this.addDummyContractForm.uiContractorName = contract.vendorName;
      this.addDummyContractForm.uiContractTitle = contract.title;
      this.addDummyContractForm.uiLiftTrapped = contract.liftTrapped;
      this.addDummyContractForm.uiFindEquipmentBy = contract.findEquipmentBy;
      this.addDummyContractForm.setContractStartDate(contract.vendorStartDate);
      this.addDummyContractForm.setContractEndDate(contract.vendorEndDate);
      
      this.openAddDummyContractPanel.toggle();
    } else if (contract.type === JMENUM.MaintenanceContractType.CCS_CONTRACT) {
      this.editCcsContractForm.clearView();

      this.editCcsContractForm.uiContractNumber = contract.contractNumber;
      this.editCcsContractForm.uiContractorNumber = contract.vendorNumber;
      this.editCcsContractForm.uiContractorName = contract.vendorName;
      this.editCcsContractForm.uiContractType = contract.isPmsmcContract ? 'PMSMC' : 'NONPMSMC';
      this.editCcsContractForm.uiLiftTrapped = contract.liftTrapped;
      this.editCcsContractForm.uiFindEquipmentBy = contract.findEquipmentBy;

      this.openEditCcsContractPanel.toggle();
      return;
    }
  
    // check if the contract can be deleted
    // 1. check team
    // 2. check swtichover
    const teamRequest = new JM.JMRequestTeamsTeamSummary();
    teamRequest.contractNumberList = [contract.contractNumber];
    teamRequest.includeApprovedWorkCentre = false;

    AppDelegate.sendJMRequest(teamRequest).then(response => {
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (contract.type === JMENUM.MaintenanceContractType.DUMMY_CONTRACT) {
        this.addDummyContractForm.setHaveTeam((response.payload.totalCount > 0 ));
      } else {
        this.addTransitionalContractForm.setHaveTeam((response.payload.totalCount > 0 ));
      }
    });

    const reminderRequest = new JM.JMRequestWorkCentresContractReminderSummary();
    reminderRequest.newContractList = [contract.contractNumber];
    reminderRequest.includeSummary = false;

    AppDelegate.sendJMRequest(reminderRequest).then(response => {
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (contract.type === JMENUM.MaintenanceContractType.DUMMY_CONTRACT) {
        this.addDummyContractForm.setHaveSwitchOver(response.payload.totalCount > 0);
      } else {
        this.addTransitionalContractForm.setHaveSwitchOver((response.payload.totalCount > 0 ));
      }
    });
    this.requestAllContract();
  }
  
  onAddTransContractBtnClicked = () => {
    this.addTransitionalContractForm.clearView();
    this.addTransitionalContractForm.setMode('add');
    this.addTransContractParameters.onSaveButtonClicked = this.onAddTransContractSaveButtonClicked;
    this.openAddTransContractPanel.toggle();
    this.requestAllContract();
  }
  
  onAddTransContractSaveButtonClicked = async (view) => {
    let startDate = moment({
      year: view.uiContractStartDate.year, 
      month: view.uiContractStartDate.month - 1, 
      day: view.uiContractStartDate.day,
    });
    let endDate = moment({
      year: view.uiContractEndDate.year, 
      month: view.uiContractEndDate.month - 1, 
      day: view.uiContractEndDate.day,
    });
    const request = new JM.JMRequestContractsAddMaintenanceTermContract();
    request.contractNumber = view.uiContractNumber;
    request.type = JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT;
    request.vendorEndDate = endDate.format('YYYYMMDD');
    request.vendorStartDate = startDate.format('YYYYMMDD');
    request.vendorNumber = view.uiContractorNumber;
    request.vendorName = view.uiContractorName;
    request.title = view.uiContractTitle ? view.uiContractTitle.trim() : view.uiContractTitle;
    request.isPmsmcContract = false;

    this.addTransitionalContractForm.enableSaveButton(false);
    const response: JM.JMResponseContractsAddMaintenanceTermContract = await AppDelegate.sendJMRequest(request);
    this.addTransitionalContractForm.enableSaveButton(true);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.openAddTransContractPanel.toggle();
    this.requestDummyMainContract();
  }

  onAddTransContractUpdateButtonClicked = async (view) => {
    let endDate = moment({
      year: view.uiContractEndDate.year, 
      month: view.uiContractEndDate.month - 1, 
      day: view.uiContractEndDate.day,
    });
    let startDate = moment({
      year: view.uiContractStartDate.year, 
      month: view.uiContractStartDate.month - 1, 
      day: view.uiContractStartDate.day,
    });
    const request = new JM.JMRequestContractsUpdateMaintenanceTermContract();
    request.contractNumber = view.uiContractNumber;
    request.vendorEndDate = endDate.format('YYYYMMDD');
    request.vendorStartDate = startDate.format('YYYYMMDD');
    request.vendorNumber = view.uiContractorNumber;
    request.vendorName = view.uiContractorName;
    request.title = view.uiContractTitle ? view.uiContractTitle.trim() : view.uiContractTitle;
    request.liftTrapped = view.uiLiftTrapped;
    request.findEquipmentBy = view.uiFindEquipmentBy;
    
    this.addTransitionalContractForm.setLoadingSave(true);
    const response: JM.JMResponseContractsUpdateMaintenanceTermContract = await AppDelegate.sendJMRequest(request);
    this.addTransitionalContractForm.setLoadingSave(false);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.openAddTransContractPanel.toggle();
    this.requestDummyMainContract();
  }

  // dummy
  onAddDummyContractBtnClicked = () => {
    this.addDummyContractForm.clearView();
    this.addDummyContractForm.setMode('add');
    this.addContractParameters.onSaveButtonClicked = this.onAddDummyContractSaveButtonClicked;
    this.openAddDummyContractPanel.toggle();
    this.requestAllContract();
  }

  onAddDummyContractSaveButtonClicked = async (view) => {
    let endDate = moment({
      year: view.uiContractEndDate.year, 
      month: view.uiContractEndDate.month - 1, 
      day: view.uiContractEndDate.day,
    });
    let startDate = moment({
      year: view.uiContractStartDate.year, 
      month: view.uiContractStartDate.month - 1, 
      day: view.uiContractStartDate.day,
    });
    
    const request = new JM.JMRequestContractsAddMaintenanceTermContract();
    request.contractNumber = JM.JMConstant.DUMMY_CONTRACT_PREFIX + view.uiContractNumber;
    request.type = JMENUM.MaintenanceContractType.DUMMY_CONTRACT;
    request.vendorEndDate = endDate.format('YYYYMMDD');
    request.vendorStartDate = startDate.format('YYYYMMDD');
    request.vendorNumber = view.uiContractorNumber;
    request.vendorName = view.uiContractorName;
    request.title = view.uiContractTitle ? view.uiContractTitle.trim() : view.uiContractTitle;
    request.isPmsmcContract = false;

    this.addDummyContractForm.setLoadingSave(true);
    const response: JM.JMResponseContractsAddMaintenanceTermContract = await AppDelegate.sendJMRequest(request);
    this.addDummyContractForm.setLoadingSave(false);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.openAddDummyContractPanel.toggle();
    this.requestDummyMainContract();
  }

  onAddDummyContractUpdateButtonClicked = async (view) => {
    let startDate = moment({
      year: view.uiContractStartDate.year, 
      month: view.uiContractStartDate.month - 1, 
      day: view.uiContractStartDate.day,
    });
    let endDate = moment({
      year: view.uiContractEndDate.year, 
      month: view.uiContractEndDate.month - 1, 
      day: view.uiContractEndDate.day,
    });
    
    const request = new JM.JMRequestContractsUpdateMaintenanceTermContract();
    request.contractNumber = JM.JMConstant.DUMMY_CONTRACT_PREFIX + view.uiContractNumber;
    request.vendorEndDate = endDate.format('YYYYMMDD');
    request.vendorStartDate = startDate.format('YYYYMMDD'); // dont update the start date
    request.vendorNumber = view.uiContractorNumber;
    request.vendorName = view.uiContractorName;
    request.title = view.uiContractTitle ? view.uiContractTitle.trim() : view.uiContractTitle;
    request.liftTrapped = view.uiLiftTrapped;
    request.findEquipmentBy = view.uiFindEquipmentBy;
    
    this.addDummyContractForm.enableSaveButton(false);
    const response: JM.JMResponseContractsUpdateMaintenanceTermContract = await AppDelegate.sendJMRequest(request);
    this.addDummyContractForm.enableSaveButton(true);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.openAddDummyContractPanel.toggle();
    this.requestDummyMainContract();
  }

  async onCcsContractEditButtonClicked(view) {
    const udpateHandler = async() => {
      this.editCcsContractForm.enableSaveButton(false);
      const request = new JM.JMRequestContractsUpdateMaintenanceTermContract();
      request.isPmsmcContract = view.uiContractType == "PMSMC" ? true : false;
      request.contractNumber = view.uiContractNumber;
      request.liftTrapped = view.uiLiftTrapped;
      request.findEquipmentBy = view.uiFindEquipmentBy;

      const response: JM.JMResponseContractsUpdateMaintenanceTermContract = await AppDelegate.sendJMRequest(request);
      if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
      this.openEditCcsContractPanel.toggle();
      this.requestDummyMainContract();
    }

    // show pop-up
    const message = JMLanguage.translate('pages.contract-dummy.popup.comfirm-to-save');
    const buttons = [
      {
        name: JMLanguage.translate('global.yes'),
        handler: udpateHandler
      },
      {
        name: JMLanguage.translate('global.no')
      }
    ]
    AppDelegate.showPopUpAlert(message, '', buttons);
  }

  onDeleteButtonClicked = async (contractNumber, contractType) => {
    if (contractType === JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT) {
      this.addTransitionalContractForm.setLoadingDelete(true);
    } else {
      this.addDummyContractForm.setLoadingDelete(true);
    }
    
    const request = new JM.JMRequestContractsDeleteMaintenanceTermContract();
    request.contractNumber = contractNumber;

    const response: JM.JMResponseContractsDeleteMaintenanceTermContract = await AppDelegate.sendJMRequest(request);
    if (contractType === JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT) {
      this.addTransitionalContractForm.setLoadingDelete(false);
    } else {
      this.addDummyContractForm.setLoadingDelete(false);
    }

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.requestDummyMainContract();
    if (contractType === JMENUM.MaintenanceContractType.TRANSITIONAL_CONTRACT) {
      this.openAddTransContractPanel.toggle();
    } else {
      this.openAddDummyContractPanel.toggle();
    }
  }

  // ------------tablex ------------
  // onFilterKeyUp = (index, header, newValue) => {
  //   header['value'] = newValue;

  //   // fire an event, call onFilterChanged after 0.5 second
  //   this.searchTerms.next(header);
  // }

  // onFilterChanged = (event, index, header, filter) => {
  onFilterChanged = (event, index, header, filter) => {
    // if (header.value == null || header.value.length == 0) {
    //   delete this.tablexFilter[header.id];
    // } else {
    //   this.tablexFilter[header.id] = header.value;
    // }
    this.tablexFilter = filter;
    for (let key in this.tablexFilter) {
      let value = this.tablexFilter[key];
      if (!value) {
        delete this.tablexFilter[key];
      }
    }
    
    this.currentPage = 1;
    this.requestDummyMainContract();
  }

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage  = 1;
    this.currentPageSize = pageSize;
    this.requestDummyMainContract();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.requestDummyMainContract();
  }
  // ------------tablex end ------------
  onLanguageChanged(){}
}
