<div class="wrapper">
    <app-nav [breadcrumbs]="breadcrumbs" >
    </app-nav>
  
    <app-sidebar></app-sidebar>
    <div id="content">
        <div class="row justify-content-between no-gutters">
            <h4 class="title">
                {{"pages.pm-plan-equipment-list.select-equipment-title" | translate}}
            </h4>
            <h5>
                {{ "pages.pm-plan-equipment-list.selected-text" | translate:[numberOfRowsSelected,(tablexParam['content'] && tablexParam['content'].length)]}}
            </h5>
        </div>
        <hr class="underline" />

        <div id="multi-tab-content-container" class="container-fluid">
            <ul class="nav nav-tabs">
                <li *ngFor="let tab of tabOptions | keyvalue" class="nav-item">
                  <a class="nav-link" [class.active]="activeTab==tab.value" (click)="onClickSetActiveTab(tab.value)">{{tab.value}}</a>
                </li>
              </ul>
            <div class="content row flex-column">
                <ng-container *ngIf="activeTab && isJmPmPlan">
                    <div class="buttons-wrapper">
                        <app-submit-button 
                            [text]="submitBtnName" [buttonClass]="'btn standard brand-blue'"
                            [isDisabled]="numberOfRowsSelected <= 0 || isSubmitting"
                            [isLoading]="isSubmitting" 
                            (onClick)="onSubmitClick()" 
                            >
                        </app-submit-button>

                        <button 
                            class="btn standard brand-blue" 
                            [disabled]="numberOfRowsSelected >= this.tablexParam?.content?.length"
                            (click)="onSelectAll()" 
                            >
                            {{"pages.pm-plan-equipment-list.button.select-all" | translate}}
                        </button>

                        <button 
                            class="btn standard brand-blue" 
                            [disabled]="numberOfRowsSelected <= 0"
                            (click)="onUnselectAll()"
                            >
                            {{"pages.pm-plan-equipment-list.button.unselect-all" | translate}}
                        </button>
                    </div>
                </ng-container>
                <app-tablex id="equipment-list-table" #equipmentListTable [tablexParam]="tablexParam"></app-tablex>
            </div>
        </div>
    </div>
</div>
