<div id="pmJobTimesheetStepsHeaderComponent">
  <h2 class="title">{{ 'component.pm-job-timesheet-steps-header.title' | translate }}
    <a *ngIf="uploadHistoryAccess.permission && isStageDownload" routerLink="{{uploadHistoryAccess.routing}}"><i
        class="fa fa-history blue"></i></a>
  </h2>
  <div class="d-flex flex-row align-items-center my-4">
    <!-- TODO: update layout -->
    <div class="step" [class.selected]="isStageDownload">
      {{ 'component.pm-job-timesheet-steps-header.download' | translate }}
    </div>
    <div class="fs-2 arrow-icon">
      <i class="fas fa-arrow-right"></i>
    </div>
    <div class="step" [class.selected]="isStageUpload">
      {{ 'component.pm-job-timesheet-steps-header.upload' | translate }}
    </div>
    <div class="fs-2 arrow-icon">
      <i class="fas fa-arrow-right"></i>
    </div>
    <div class="step" [class.selected]="isStageResult">
      {{ 'component.pm-job-timesheet-steps-header.result' | translate }}
    </div>
  </div>
  <hr class="underline" />
</div>
