<div class="wrapper">
    <app-nav [navbarTitle]="title"></app-nav>
    
    <app-sidebar></app-sidebar>
    
    <div id="content">
    <app-sn-batch-submission-stage-header [stage]="stage"></app-sn-batch-submission-stage-header>
     <!-- Upload stage  -->
     <ng-container *ngIf="isStageUpload">
      <div>{{uploadTitle}}</div>
      <div class="my-2 w-40">
        <app-file-selector [fileAcceptType]="'.pdf'" (updatedFile)="onUpdatedFile($event)" [isDisabled]="isDisableUploadBtn"></app-file-selector>
      </div>
      <!-- upload button-->
      <app-submit-button
        [id]="'sn_batch_submission-upload_button'"
        [text]="'component.sn-batch-complete.main.button.upload'"
        [buttonClass]="'standard brand-amber my-1'"
        [iconClass]="'fa-upload'"
        [isDisabled]="uploadIsLoading"
        [isLoading]="uploadIsLoading"
        (onClick)="onClickedUploadButton()"
      ></app-submit-button>
     </ng-container>
     
     <!-- verification stage -->
     <ng-container class="col-12" *ngIf="isStageVerification">
       
       <div class="row col-6 d-inline-block">
         <app-submit-button class="col-2"
         [id]="'sn_batch_submission-submit_button'"
          [text]="'component.sn-batch-complete.main.button.submit'"
          [buttonClass]="'standard brand-blue my-1'"
          [isDisabled]="submitHasError"
          [iconClass]="'fa-send'"
          (onClick)="onClickedSubmitButton()"
        ></app-submit-button>

        <div id="content" class="action-btn-padding m-0 p-0">
            <div class="tablexWrapper">
              <app-tablex id="ocr-result-list-table" [tablexParam]="ocrResultTablexParam"></app-tablex>
            </div>

        </div>
      </div>

       <div class="col-6 align-top d-inline-block">
         <div class="title mb-2">{{ "component.attachment.preview-title" | translate }}</div>
         <hr class="underline" />
           <attachment-previewer  
             #attachment_preview id="attachment-preview" 
             class="d-block mt-3" 
             [file]="file" 
             [isLoading]="isPreviewerLoading">
           </attachment-previewer>
       </div>


     </ng-container>

    <!-- result stage -->
     <ng-container class="col-12" *ngIf="isStageResult">
      <div class="row col-8 d-inline-block">
         <div id="content" class="action-btn-padding m-0 p-0">
            <div>{{ "component.sn-batch-report-submission.message.result" | translate }} <a href="/sn/batch-report-submission/upload-history/{{batchSignedOffId}}">{{ "side-bar.batch-complete.upload-history" | translate }}</a> </div>
        </div> 
      </div>

<!-- 
      <div class="col-6 align-top d-inline-block">
      </div> -->

     </ng-container>

    </div>
  </div>
  