<div class="previewer-container">
    <div *ngIf="isLoading" class="previewer middle-item">
        <span class="spinner-border" role="status" aria-hidden="true"></span>
    </div> 

    <div *ngIf="!isLoading && fileDetails" class="row mx-0 mb-3">
        <div class="col-12 col-xl-6 row mb-3" *ngFor="let details of fileDetails | keyvalue: sortOrder">
            <span class="col-4 gray-title">{{ "component.attachment."+details.key | translate }}</span>
            <app-display-text-field class="col text-break" [id]="'preview-detail-'+details.key" [textField]="details.value"></app-display-text-field>
        </div>
    </div>

    <div *ngIf="!isLoading && file && fileType && previewContent" class="previewer-container" >
        <div *ngIf="fileType == attachmentFileType.Image" class="previewer">
            <div><img [src]="previewContent" /></div>
        </div>
        <div *ngIf="fileType == attachmentFileType.Pdf" class="previewer-container pdf-container"  >
            <pdf-viewer  
                [src]="previewContent" 
                [render-text]="true"
                [stick-to-page]="false"
                [autoresize]="true"
                [original-size]="false"
                [show-all]="false"
                [page]="selectedPage"
                (after-load-complete)="afterLoadComplete($event)"
            ></pdf-viewer>
        </div>  

        <div *ngIf="fileType == attachmentFileType.Pdf" class="pdf-container-page">
            <button class="next-prev-page-btn py-1 px-2 mx-2 mb-0 col-auto text-center align-self-center" (click)="prevPage()" >{{"pages.sn.attachments.prev" | translate }}</button>
             <span>{{ selectedPage }} / {{ totalPages }}</span>
            <button class="next-prev-page-btn py-1 px-2 mx-2 mb-0 col-auto text-center align-self-center" (click)="nextPage()" >{{"pages.sn.attachments.next" | translate }}</button> <br/>
            <div *ngIf="fileType == attachmentFileType.Pdf" class="pdf-container-page">
                <label *ngIf="this.isHaSn" for="upload-file-input" id="choose-file-btn" class="choose-file-btn py-1 px-2 mx-2 mb-0 col-auto text-center align-self-center">
                    {{"pages.sn.attachment.ha-service-report-page-selector" | translate }}
                  </label>
                <input *ngIf="this.isHaSn" type="number" class="pdf-preview-btn" min="1" max="{{totalPages}}" (change)="setSelectedPage($event)" id="attachment-previewer-page-selector" [(ngModel)]='selectedPage' placeholder="1">
            </div>

        </div>
      
        <div *ngIf="fileType == attachmentFileType.Text" class="text-previewer-container">
            <div class="text-previewer">{{ previewContent }}</div>
        </div> 
        <div *ngIf="fileType == attachmentFileType.Other" class="previewer middle-item text-center">
            <div id="not-support-msg">{{ "pages.sn.attachment.not-support-file-format" | translate }}</div>
        </div> 
    </div>
</div>