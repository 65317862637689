
import { AbstractControl, ValidationErrors } from '@angular/forms';
/** to form logic  start */


export function isEmptyInputValue(value: string): boolean {
    return value == null || (typeof value === 'string' && value.length === 0) || value == undefined;
}

export function isEmailVaild(value: string): Boolean {
    try {
        value = value.toLowerCase();
        var isVaild = false;
        if (value != undefined && value.includes("@")) {
            isVaild = true;
        }
        return isVaild;
    } catch (err) {
        return false;
    }

}

export function isNameVaild(username: string): boolean {
    var isVaild = false;
    if (username) {
        isVaild = /^[A-Za-z\s]+$/.test(username);
    }else{
        isVaild = true;
    }
    return isVaild;
}

export function  isOnlyNumberAndLetter(string: string): Boolean {
    var regExp = /[^A-Za-z0-9 /-]/
    var onlyNumberAndLetterAndSpace = false;
    onlyNumberAndLetterAndSpace = !regExp.test(string);
    return onlyNumberAndLetterAndSpace;
}


export function usernameValidation(control: AbstractControl): ValidationErrors | null {
    return isEmailVaild(control.value) || isOnlyNumberAndLetter(control.value) ? null : { 'user format': '' };
}

/** to form logic  end */
