import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() maximumPageCount: number;
  @Input() isLeft: boolean = false;
  @Input() pageIndex = 1;
  @Output() onPageChanged = new EventEmitter<number>();

  currentPageIndex = 1;
  lastPageIndex;
  
  constructor() { }

  ngOnInit() {
    this.lastPageIndex = this.maximumPageCount;
  }

  ngOnChanges() {
    this.lastPageIndex = this.maximumPageCount;
    this.currentPageIndex = this.pageIndex
  }

  gotoPage(pageIndex: number) {
    let newPage = pageIndex;
    if (pageIndex < 1) {
      newPage = 1;
    } else if(pageIndex > this.lastPageIndex) {
      newPage = this.lastPageIndex;
    }
    if (this.currentPageIndex !== newPage && this.lastPageIndex ) {
      this.currentPageIndex = newPage;
      this.onPageChanged.emit(this.currentPageIndex);
    }
  }

  onCurPageKeyup(event) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    if(event.key === "Enter") {
      this.gotoPage(parseInt(event.target.value));
    }
  }

  onCurPageClick() {
  }
  onFirstPageClick() {
    this.gotoPage(1);
  }
  onPreviousPageClick() {
    this.gotoPage(this.currentPageIndex - 1);
  }
  onNextPageClick() {
    this.gotoPage(this.currentPageIndex + 1);
  }
  onLastPageClick() {
    this.gotoPage(this.lastPageIndex);
  }
}
