<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>
  <app-sidebar [customItems]='sideBarItem'></app-sidebar>
  <div id="content">
    <div class="row">
      <div class="col-9">
        <input id="search_input" type="text" class="form-control" [(ngModel)]="searchInputText" (keyup.enter)="onSearchKeyup()" placeholder="{{'pages.equipment-list.search-text-field-title' | translate}}"/>
      </div>
      <div class="col-3 px-0 pr-3">
        <div class="text-right">
          <button id="search_btn" class="standard brand-blue mx-2" (click)="onSearchClicked()">
            <i class="fas fa-search space"></i>{{"pages.equipment-list.search-button" | translate }}
          </button>
        
          <button id="clear_btn" class="standard brand-red mx-2" (click)="onClearSearchClicked()">
              <i class="fas fa-trash space"></i>{{"pages.equipment-list.clear-button" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row mx-0 justify-content-end mt-3">
      <button id="export-excel-btn" type="button" class="standard brand-blue ml-2 mr-0"
        (click)="onClickExportExcel()" [disabled]="isExportExcelBtnLoading || isDisabledExportExcelBtn" > 
        <div *ngIf="isExportExcelBtnLoading" class="d-inline-block loading-spinner spinner-small">
          {{ "global.loading" | translate }}
        </div>
        <span *ngIf="!isExportExcelBtnLoading">
          <i class="fas fa-file-download space"></i>
          {{ "global.export-excel" | translate }}
        </span>
      </button>

      <button id="batch-edit-btn" type="button" class="standard brand-blue ml-2 mr-0"
        (click)="onClickBatchAdd()" [disabled]="isDisabledBatchEditBtn"> 
        <span>
          {{ "pages.equipment-list.batch-add" | translate }}
        </span>
      </button>
      <button id="batch-edit-btn" type="button" class="standard brand-blue ml-2 mr-0"
        (click)="onClickBatchRemove()" [disabled]="isDisabledBatchEditBtn"> 
        <span>
          {{ "pages.equipment-list.batch-remove" | translate }}
        </span>
      </button>

    </div>

    <app-tablex id="equipment-list-table" [class.is-hsd]="isHSDWorkCentre" #equipmentListTable [tablexParam]="tablexParam" class="mt-3" ></app-tablex>
  </div>

  <custom-slider-panel #edit_equipment_panel (onClosePanel)="onCloseEditEquipment()" [widthPx]=900>
    <edit-equipment-form #edit_equipment_form></edit-equipment-form>
  </custom-slider-panel>
   
  <custom-slider-panel #batch_edit_tags_panel [widthPx]=900>
    <batch-edit-equipment-form #batch_edit_tags_form></batch-edit-equipment-form>
  </custom-slider-panel>
</div>