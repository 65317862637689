import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/entity/enum/tablexColumnType';
import { PostPostSummaryRequest } from 'src/app/api/authorization/post-post-summary-request';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import * as FORAMTTER from 'src/app/presenter/Formatter';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session }  from 'src/app/services/session';
import { Permission } from '@enum/permission';
import { Constants } from 'src/constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';


@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.scss']
})
export class ContractorsComponent extends BasePage implements OnInit {
  @ViewChild("add_contract_panel", { static: true }) addContractPanel;
  @ViewChild("addContractForm", { static: true }) addContractForm;

  pageTitle: string;

  addContractFormParameters:{
    workCentreContractArray: string[],
    contractDict: { [key:string]: JMOBJ.MaintenanceTermContract },
    onSubmitButtonClicked: Function,
  };

  allMaintenanceTermContract : string[] = null; // array of all maintenance contract Number 
  selectedWorkCentreCode        : string;
  selectedWorkCentre            = null;
  workCentrePostArray       = [];
  
  selectedContractNumber    : string = null; // contract Number
  selectedContract:JMOBJ.MaintenanceTermContract = null; // contract object
  selectedContractReminder:JMOBJ.ContractReminder = null;
  contractArray             = [];   // detail of contracts that on option list
  
  swtichOverContract        = null;
  equipmentsList            :any;
  
  msgIdEquipment:number = 0;

  uiShowDetial               : boolean = false;
  uiSelectedContractHandlingParty: string = '';
  uiEnableRemoveButton       : boolean = false;
  uiEnableRefreshReminder    : boolean = false;
  uiContractStartDate        : Date = null;
  uiContractEndDate          : Date = null;
  uiEnableReminder_1         : boolean = false;
  uiEnableReminder_2         : boolean = false;
  uiReminderDate_1           : Date = null;
  uiReminderDate_2           : Date = null;
  uiReminderDay_1            : number = null;
  uiReminderDay_2            : number = null;
  uiReminderRecipientArray_1 : string[] = [];
  uiReminderRecipientArray_2 : string[] = [];
  uiContractSort             : string = '';
  uiSortActive               : string = '';

  // new contract
  uiNewContract              : string = '';
  uiNewContractHandlingParty: string = '';
  uiSwtichOverDate           = null;
  uiNewContractStartDate     = null;
  uiNewContractEndDate       = null;
  uiShowNoEffectiveContractWarning = false;
  uiAvailableNewContract: {
    value: string,
    label: string,
  }[] = [];
  uiEnableNewContract: boolean = true;

  // hideContent               : boolean = true;
  isLoadingContractOptions    : boolean = false;
  isLoadingContractReminder   : boolean = false;
  isLoadingPostArray          : boolean = false;
  isLoadingSwitchOverContract : boolean = false;
  isSaving                    : boolean = false;
  isRemoving                  : boolean = false;
  hasEditionPermission        : boolean = false;

  // sliderPanelGroups         : any[] = [];
  contractFieldSearchTerm   = new Subject<string>();
  contractsSearchingWord    : string;

  // Table
  tablexFilter        = null;
  tablexParam         : {} = {};
  pageSizeOptions     = [10, 25, 100];
  currentPageSize     = 10;
  currentPage         = 1;
  pageCount           = 1;
  uiTotalEquipmentCount = 0;
  
  constructor(
    injector                  : Injector,
  ) {
    super(injector);
    this.selectedWorkCentreCode = Session.selectedWorkCentre;
  }

  protected componentName(): string {
    return "contractors";
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.contractors.page-title", [this.selectedWorkCentreCode]);
    this.checkViewPermission(Permission.wcmView);
    this.checkWorkCenterPermission(this.selectedWorkCentreCode);
    this.hasEditionPermission = this.authorizationService.hasPermission(Permission.wcmUpdate);

    this.addContractFormParameters = {
      workCentreContractArray: [],
      contractDict: {},
      onSubmitButtonClicked: this.onAddContractSubmitButtonClicked,
    };

    
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      tableRow              : "row",
      tableClass            : "",
      tableWrapperClass     : "table-min-width",
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      // onFilterKeyUp         : this.onFilterKeyUp,
      onFilterChanged       : this.onFilterChanged,
      filterDebounceTime    : Constants.DEBOUNCE_TIME,
      headers: [ 
        {
          id: 'equipmentNumber',
          name: "pages.contractors.table-column-equipment-number",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2",
        },
        {
          id: 'equipmentType',
          name: "pages.contractors.table-column-type",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-1",
        },
        {
          id: 'description',
          name: "pages.contractors.table-column-description",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-5",
        },
        {
          id: 'clientShortName',
          name: "pages.contractors.table-column-customer",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2",
        },
        {
          id: 'location',
          name: "pages.contractors.table-column-location",
          enableFilter: true,
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2",
        }
      ]
    }
    this.requestPostList();
    this.requsetContractOption();
  }

  ngOnDestroy() {
  }

  //---------------------------------------------------------------------------
  // view functions

  //Initial Information Function
  private renderTable() {
    this.tablexParam['pageCount'] = Math.ceil(this.uiTotalEquipmentCount/this.currentPageSize);
    this.tablexParam['content'] = this.equipmentsList.map(equipment => {
      return [
        equipment.equipmentNumber,
        equipment.equipmentType,
        equipment.description,
        equipment.clientShortName,
        equipment.location
      ];
    });
  }

  updateSwitchOverView(newContract?) {
    if (newContract) {
      let switchMoment = moment(newContract.vendorStartDate, "YYYYMMDD");
      let oldEndMoment = moment(this.selectedContract.vendorEndDate, "YYYYMMDD");
      this.uiNewContract = newContract.contractNumber;
      this.uiSwtichOverDate = switchMoment.toDate();
      this.uiNewContractStartDate = moment(newContract.vendorStartDate, "YYYYMMDD").toDate();
      this.uiNewContractEndDate = moment(newContract.vendorEndDate, "YYYYMMDD").toDate();
      this.uiShowNoEffectiveContractWarning = (switchMoment.diff(oldEndMoment, 'days') > 1);
      this.uiNewContractHandlingParty = (newContract.isPmsmcContract)?this.translate("team.handling-party.2"):this.translate("team.handling-party.3");
    } else {
      this.uiNewContract = '';
      this.uiSwtichOverDate = null;
      this.swtichOverContract = null;
      this.uiShowNoEffectiveContractWarning = false;
      this.uiNewContractHandlingParty = '';
    }
  }

  // expiryDate: string: 2014-02-28 00:00:00.000Z
  dayToExpiry(expiryDate) {
    let exDate = moment(expiryDate, "YYYYMMDD").toDate();
    let today = new Date();
    let dayDiff = (exDate.getTime() - today.getTime())/(60*60*24*1000);
    return Math.floor(dayDiff);
  }

  updateContractView(contract?) {
    if (contract) {
      this.uiContractStartDate = moment(contract.vendorStartDate, "YYYYMMDD").toDate();
      this.uiContractEndDate = moment(contract.vendorEndDate, "YYYYMMDD").toDate();
      this.uiSelectedContractHandlingParty = (contract.isPmsmcContract)?this.translate("team.handling-party.2"):this.translate("team.handling-party.3");
    } else {
      this.uiContractStartDate = null;
      this.uiContractEndDate = null;
      this.uiSelectedContractHandlingParty = '';
    }

    // since checkReminderDateNeedUpdate need both contract and reminder
    // so set update here as well
    this.uiEnableRefreshReminder = this.checkReminderDateNeedUpdate();
  }

  updateContractReminder(reminder?) {
    this.selectedContractReminder = reminder;
    if (this.selectedContractReminder) {
      this.uiEnableReminder_1 = this.selectedContractReminder.enableReminder_1;
      this.uiEnableReminder_2 = this.selectedContractReminder.enableReminder_2;
      this.uiReminderDay_1 = this.selectedContractReminder.reminderDay_1?this.selectedContractReminder.reminderDay_1:null;
      this.uiReminderDay_2 = this.selectedContractReminder.reminderDay_2?this.selectedContractReminder.reminderDay_2:null;
      this.uiReminderDate_1 = this.selectedContractReminder.reminderDate_1?this.selectedContractReminder.reminderDate_1:null;
      this.uiReminderDate_2 = this.selectedContractReminder.reminderDate_2?this.selectedContractReminder.reminderDate_2:null;
      this.uiReminderRecipientArray_1 = this.selectedContractReminder.reminderRecipientArray_1;
      this.uiReminderRecipientArray_2 = this.selectedContractReminder.reminderRecipientArray_2;

      
    } else {
      this.uiEnableReminder_1 = false;
      this.uiEnableReminder_2 = false;
      this.uiReminderDay_1 = null;
      this.uiReminderDay_2 = null;
      this.uiReminderDate_1 = null;
      this.uiReminderDate_2 = null;
      this.uiReminderRecipientArray_1 = [];
      this.uiReminderRecipientArray_2 = [];
      
    }
    this.uiEnableRefreshReminder = this.checkReminderDateNeedUpdate();
  }

  // re-calculate the first reminder date
  updateReminderDate1() {
    if (this.uiEnableReminder_1 && this.uiReminderDay_1) {
      let reminder_1_moment = moment(this.selectedContract.vendorEndDate, "YYYYMMDD").subtract(this.uiReminderDay_1, 'days').add(1, 'hours');
      this.uiReminderDate_1 = reminder_1_moment.toDate();
    } else {
      this.uiReminderDate_1 = null;
    }
  }

  // re-calculate the first reminder date
  updateReminderDate2() {
    if (this.uiEnableReminder_2 && this.uiReminderDay_2) {
      let reminder_2_moment = moment(this.selectedContract.vendorEndDate, "YYYYMMDD").subtract(this.uiReminderDay_2, 'days').add(1, 'hours');
      this.uiReminderDate_2 = reminder_2_moment.toDate();
    } else {
      this.uiReminderDate_2 = null;
    }
  }

  checkExpired(inputDate){
    return this.dayToExpiry(inputDate) <= 0;
  }

  checkContractPeriod(newStartDate, oldEndDate){
    return moment(newStartDate).diff(moment(oldEndDate), 'days') > 0 ? true : false;
  }

  checkReminderDateNeedUpdate() {
    let needUpdate = false;

    if (this.selectedContract && this.selectedContractReminder && this.selectedContractReminder.reminderDate_1) {
      let correctReminderDate = moment(this.selectedContract.vendorEndDate)
        .subtract(this.selectedContractReminder.reminderDay_1, 'days')
        .add(1, 'hours');
      let reminderDate = moment(this.selectedContractReminder.reminderDate_1);
      if (correctReminderDate.isSame(reminderDate) === false) {
        needUpdate = true; 
      }
    }
    return needUpdate;
  }

  async saveContractReminder(): Promise<void> {
    // let name = this.selectedContractNumber;
    // validate
    if (this.uiEnableReminder_1 && (this.uiReminderDay_1 == null || this.uiReminderDay_1 < 0)) {
      this.openSnackBar(this.translate("pages.contractors.message.invalid-reminder-day"));
      return;
    }
    if (this.uiEnableReminder_1 && (this.uiReminderRecipientArray_1 == null || this.uiReminderRecipientArray_1.length <= 0)) {
      this.openSnackBar(this.translate("pages.contractors.message.invalid-reminder-recipient"));
      return;
    }
    if (this.uiEnableReminder_2 && (this.uiReminderDay_2 == null || this.uiReminderDay_2 < 0)) {
      this.openSnackBar(this.translate("pages.contractors.message.invalid-reminder-day"));
      return;
    }
    if (this.uiEnableReminder_2 && (this.uiReminderRecipientArray_2 == null || this.uiReminderRecipientArray_2.length <= 0)) {
      this.openSnackBar(this.translate("pages.contractors.message.invalid-reminder-recipient"));
      return;
    }
    if (this.uiEnableReminder_1 && this.uiEnableReminder_2 && this.uiReminderDay_2 >= this.uiReminderDay_1) {
      this.openSnackBar(this.translate("pages.contractors.message.first-reminder-should-be-earlier-then-second-reminder"));
      return;
    }
    if ((this.uiNewContract == null || this.uiNewContract === '') && this.uiSwtichOverDate) {
      this.openSnackBar(this.translate("pages.contractors.message.please-select-new-contract"));
      return;
    }
    //
    // let message = this.translate("action.button.popup.save");
    // let handler = () => {
      const request = new JM.JMRequestWorkCentresUpdateContractReminder();
      request.workCentre = this.selectedWorkCentreCode;
      request.contract = this.selectedContractNumber;
      
      if (this.uiEnableReminder_1) {
        request.enableReminder_1 = this.uiEnableReminder_1;
        request.reminderDay_1 = this.uiReminderDay_1;
        request.reminderDate_1 = this.uiReminderDate_1;
        request.reminderRecipientArray_1 = this.uiReminderRecipientArray_1;
        
      } else {
        request.enableReminder_1 = false;
        request.reminderDay_1 = null;
        request.reminderDate_1 = null;
        request.reminderRecipientArray_1 = [];
      }
      
      if (this.uiEnableReminder_2) {
        request.enableReminder_2 = this.uiEnableReminder_2;
        request.reminderDay_2 = this.uiReminderDay_2;
        request.reminderDate_2 = this.uiReminderDate_2;
        request.reminderRecipientArray_2 = this.uiReminderRecipientArray_2;
      } else {
        request.enableReminder_2 = false;
        request.reminderDay_2 = null;
        request.reminderDate_2 = null;
        request.reminderRecipientArray_2 = [];
      }

      if (this.uiNewContract) {
        request.newContract = this.uiNewContract;
        request.switchOverDate = this.uiSwtichOverDate; //new Date(this.uiSwtichOverDate.year, this.uiSwtichOverDate.month-1, this.uiSwtichOverDate.day).toISOString();
      } else {
        request.newContract = null;
        request.switchOverDate = null;
      }
      
      this.isSaving = true;
      const response: JM.JMResponseWorkCentresUpdateContractReminder = await AppDelegate.sendJMRequest(request);
      this.isSaving = false;
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
        
      this.updateContractReminder(response.payload);
      this.openSnackBar(this.translate("global.saved"));
      this.requsetContractOption();
  }

  //---------------------------------------------------------------------------
  // api function
  
  // get the contractsof the work centre
  async requsetContractOption(): Promise<void> {
    // this.selectedContractNumber = null;
    // this.selectedContract = null;
    this.contractArray = [];
    this.equipmentsList = [];
    const request = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.selectedWorkCentreCode];
    request.includeSummary = true;

    this.isLoadingContractOptions = true;
    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingContractOptions = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records.length > 0) {
      this.selectedWorkCentre = response.payload.records[0];
      this.getContractList('contractNumber', 1);
      this.requestAllMaintenanceTermContract(); // get all contract for slider panel
    }
  }

  // get details of contract of that work centre which are shown on left option
  async getContractList(col, order): Promise<void> {
    this.uiContractSort = col+order;
    this.uiSortActive = col;

    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    //request.contractNumber = contractNumberArray;
    request.workCentres = [this.selectedWorkCentreCode];
    request.expired = JMENUM.RequestExpired.BOTH;
    request.sortBy = col;
    request.sortOrder = order;
    
    this.isLoadingContractReminder = true;
    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingContractReminder = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.contractArray = response.payload.records.map(c => ({ ...c, formattedContractNumber: FORAMTTER.formatContractNumber(c) }));
    this.contractArray.sort();

    if (response.payload.records.length > 0) {
      if (this.selectedContractNumber == null || !response.payload.records.find(contract=>{return contract.contractNumber==this.selectedContractNumber})) {
        // did not select any contract or the contract has been removed
        this.selectedContractNumber = response.payload.records[0].contractNumber;
      }
      this.addContractForm.clearView();
      this.addContractFormParameters.workCentreContractArray = response.payload.records.map(contract => {return contract.contractNumber});
          
      this.requestContractEquipment(this.selectedContractNumber);
      this.requestContractReminder(this.selectedWorkCentreCode, this.selectedContractNumber);
      this.requestTeamSummary(this.selectedContractNumber);
    }

    this.selectedContract = this.contractArray.find(c => c.contractNumber === this.selectedContractNumber);
    this.updateContractView(this.selectedContract);
    this.requestMaintenanceTermContract();  // request all main contract for slider panel (no use)

    if (this.selectedContract.type !== JMENUM.MaintenanceContractType.DUMMY_CONTRACT && 
      this.selectedContract.vendorNumber && this.selectedContract.vendorNumber.length > 0) {
      this.requestAvilableNewMaintenanceTermContract(this.selectedContract.vendorNumber);
      this.uiEnableNewContract = true;
    } else {
      this.uiEnableNewContract = false;
    }
  }
  
  async requestSwtichOverContractDetail(contractNumber): Promise<void> {
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.contractNumber = [contractNumber];
    request.expired = JMENUM.RequestExpired.BOTH;

    this.isLoadingSwitchOverContract = true;
    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingSwitchOverContract = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let contracts = response.payload.records;
    this.swtichOverContract = contracts.find(c => c.contractNumber === contractNumber);
    this.updateSwitchOverView(this.swtichOverContract);
  }

  async requestContractEquipment(contractNumber: string) {
    const request = new JM.JMRequestEquipmentsGetEquipmentsByContract();
    request.contractNumber = contractNumber;
    request.workCentreList = [this.selectedWorkCentreCode];
    request.active = JMENUM.RequestActive.ACTIVE;
    request.parameters = ['equipmentNumber', 'equipmentType', 'description', 'clientShortName', 'location'];
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;

    for (const key in this.tablexFilter) {
      if (!request.filter) {
        request.filter = {};
      }
      request.filter[key] = !!this.tablexFilter[key] ? this.tablexFilter[key] : undefined;
    }

    this.uiTotalEquipmentCount = 0;
    this.equipmentsList = [];
    
    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseEquipmentsGetEquipmentsByContract = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.uiTotalEquipmentCount = response.payload.totalCount;
    this.equipmentsList = response.payload.records;
    this.renderTable();
  }

  async requestContractReminder(workCentreCode, contractNumber) {
    const request = new JM.JMRequestWorkCentresGetContractReminder();
    request.workCentre = workCentreCode;
    request.contract = contractNumber;

    const response: JM.JMResponseWorkCentresGetContractReminder = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload) {
      this.updateContractReminder(response.payload);
      if (response.payload.newContract) {
        this.requestSwtichOverContractDetail(response.payload.newContract);
      }
    }
  }

  async requestPostList() {
    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.active = JMENUM.RequestActive.ACTIVE;
    request.authorizations = {'workCenters': this.selectedWorkCentreCode};
    request.pageSize = 1000;//this.currentPageSize;
    request.pageNumber = 1;//this.currentPage;

    this.isLoadingPostArray = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingPostArray = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.workCentrePostArray = response.payload.records;
  }

  // get all unexpired contract for slider panel
  async requestMaintenanceTermContract(keyword?: string): Promise<void> {
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.parameters = ['contractNumber'];
    request.expired = JMENUM.RequestExpired.UNEXPIRED;

    if (keyword) {
      request.filter = {
        contractNumber: keyword
      };
    }

    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingSwitchOverContract = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.allMaintenanceTermContract = response.payload.records.map(c => c.contractNumber);
  }

  // get available new contract
  async requestAvilableNewMaintenanceTermContract(vendorNumber): Promise<void> {
    this.uiAvailableNewContract = [];
    
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.parameters = ['contractNumber', 'type'];
    request.vendorNumber = [vendorNumber];
    request.expired = JMENUM.RequestExpired.UNEXPIRED;

    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingSwitchOverContract = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let newlist = [];
    if (Array.isArray(response.payload.records)) {
      newlist = response.payload.records.filter(contract => {
        return contract.contractNumber != this.selectedContractNumber;
      }).map(contract => {
        return {
          value: contract.contractNumber,
          label: FORAMTTER.formatContractNumber(contract),
        }
      });
    }
    this.uiAvailableNewContract = newlist;
  }

  // find if there is any team using this contract
  // for disable the remove button
  async requestTeamSummary(contractNumber:string): Promise<void> {
    this.uiEnableRemoveButton = false;
    const request = new JM.JMRequestTeamsTeamSummary();
    request.workCentreList = [this.selectedWorkCentreCode];
    request.contractNumberList = [contractNumber];
    request.includeApprovedWorkCentre = false;
    request.includeSummary = false;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.uiEnableRemoveButton = (response.payload.totalCount === 0);
  }

  async requestAllMaintenanceTermContract(page:number = 1) {
    // request all maintenance term contract
    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.expired = JMENUM.RequestExpired.UNEXPIRED;
    request.pageNumber = page;
    request.pageSize = 1000;

    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    let contract: JMOBJ.MaintenanceTermContract;
    for (contract of response.payload.records) {
      this.addContractFormParameters.contractDict[contract.contractNumber] = contract;
    }
    // request the remaining contract, 1 got already
    if (page < Math.ceil(response.payload.totalCount / request.pageSize)) {
      this.requestAllMaintenanceTermContract(page + 1);
    }
  }
  
  //-----------------------------------------------------------------------------
  // button call back function
  onContractChanged(index: number): void {
    if (this.contractArray.length > 0) {
      if (this.selectedContractNumber !== this.contractArray[index].contractNumber) {
        this.selectedContractNumber = this.contractArray[index].contractNumber;
        this.selectedContract = this.contractArray[index];
        
        this.updateContractView(this.selectedContract);
        this.updateSwitchOverView();
        this.requestTeamSummary(this.selectedContractNumber);
        this.requestContractEquipment(this.selectedContractNumber);
        this.requestContractReminder(this.selectedWorkCentreCode, this.selectedContractNumber);
        if (this.selectedContract.type !== JMENUM.MaintenanceContractType.DUMMY_CONTRACT && 
          this.selectedContract.vendorNumber && this.selectedContract.vendorNumber.length > 0) {
          this.requestAvilableNewMaintenanceTermContract(this.selectedContract.vendorNumber);
          this.uiEnableNewContract = true;
        } else {
          this.uiEnableNewContract = false;
        }
      }

    } else {
      this.selectedContractNumber = null;
      this.selectedContract = null;
      this.updateSwitchOverView(null);
      this.updateContractView(null);
    }
    
  }

  onSortContractBtnClicked(col, order){
    this.getContractList(col, order)
  }

  onShowDetailBtnClicked(event: any) {
    this.uiShowDetial = !this.uiShowDetial;
  }

  onAddContractBtnClicked(event: any) {
    this.addContractPanel.toggle();
    this.addContractForm.updateView();
  }

  onSaveBtnClicked(): void {
    this.saveContractReminder();
  }
  
  onRemoveBtnClicked(): void {
    let message = this.translate("action.button.popup.remove") + ' ' + this.selectedContractNumber;
    let handler = async () => {
      const request = new JM.JMRequestWorkCentresRemoveContract();
      request.workCentre = this.selectedWorkCentreCode;
      request.contractNumberArray = [this.selectedContractNumber];
      
      this.isRemoving = true;
      const response: JM.JMResponseWorkCentresRemoveContract = await AppDelegate.sendJMRequest(request);
      this.isRemoving = false;
      if (!response || !response.code || response.code != 200) {
        AppDelegate.openErrorBar(response);
        return;
      }
      this.requsetContractOption();
      this.openSnackBar(this.translate("global.removed"));
    };
    
    this.popupConfirmAlert(message, handler);

  }

  onClearBtnClicked(): void {
    this.uiNewContract = '';
    this.uiSwtichOverDate = null;
    this.swtichOverContract = null;
  }

  onNewContractChanged(event): void {
    this.uiNewContract = event.value;
    if (this.uiNewContract && this.uiNewContract.length > 0) {
      this.requestSwtichOverContractDetail(this.uiNewContract);
    } else {
      this.uiSwtichOverDate = null;
      this.swtichOverContract = null;
    }
  }

  onReminderDay1Changed(event): void {
    this.updateReminderDate1();
  }

  onReminderDay2Changed(event): void {
    this.updateReminderDate2();
  }

  onEnableReminder1Changed(event): void {
    // if (this.uiEnableReminder_1 === false) {
    //   this.uiReminderDay_1 = null;
    //   this.uiReminderDate_1 = null;
    //   this.uiReminderRecipientArray_1 = [];
    // }
  }

  onEnableReminder2Changed(event): void {
    // if (this.uiEnableReminder_2 === false) {
    //   this.uiReminderDay_2 = null;
    //   this.uiReminderDate_2 = null;
    //   this.uiReminderRecipientArray_2 = [];
    // }
  }

  onRefreshBtnClicked():void {
    this.updateReminderDate1();
    this.updateReminderDate2();
    this.saveContractReminder();
  }

  private popupConfirmAlert(message: string, handler: any) {
    let buttons = [
      { name: this.translate("global.yes"), handler },
      { name: this.translate("global.no") }
    ]
    this.showPopUpAlert(message, "", buttons);
  }
  

  // ------------ add contract form ------------
  onAddContractSubmitButtonClicked = async (newContractNumberArray) => {
    const request = new JM.JMRequestWorkCentresAddContract();
    request.newContractNumberArray = newContractNumberArray;
    request.workCentre = this.selectedWorkCentreCode;

    const response: JM.JMResponseWorkCentresAddContract = await AppDelegate.sendJMRequest(request);
    this.addContractForm.enabletSubmitbutton(true);
    if (!response || !response.code || response.code != 200) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.requsetContractOption();
    this.openSnackBar(this.translate("global.added"));
    this.addContractPanel.toggle();
  }

  // ------------tablex ------------
  onFilterChanged = (event, index, header, filter) => {
    this.tablexFilter = filter;
    this.requestContractEquipment(this.selectedContractNumber);
  }
  

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage  = 1;
    this.currentPageSize = pageSize;
    this.requestContractEquipment(this.selectedContractNumber);
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage  = pageIndex;
    this.requestContractEquipment(this.selectedContractNumber);
  }
  // ------------tablex end ------------


  //----------------------------
  


  // TODO: reload language
  onLanguageChanged() {
    
  }

}
