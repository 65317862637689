<div class="row">
    <div class="col-7">
        <h3>{{'conponent.manual-instruction.title' | translate}}</h3>
    </div>
    <div class="col-5 ml-auto">
        <div class="col d-flex justify-content-end mx-3 mb-3">
            <app-submit-button 
            [id]="'manual-instruction_view-working-hours_button'"
            [text]="'pages.team-settings.view-office-hours'"
            [buttonClass]="'standard float-right'"
            [isDisabled]="isLoadingTeamSummary"
            [isLoading]="isLoadingTeamSummary"
            (onClick)="onOfficeHoursBtnClicked()" 
            ></app-submit-button>
        </div>
    </div>
</div>
<div *ngIf="pendingManualInstructionTime">
    {{pendingManualInstructionTime | date:"yyyy/MM/dd HH:mm"}}
</div>

<div class="manual-instruction-sub-title">
    {{'conponent.manual-instruction.working-hours' | translate}}
</div>
<ng-container *ngFor="let workingHourGroup of workingHourData.workingHourGroups;let index = index">
    <div 
        class="group-instructions-container row" 
        [style.background]="workingHourGroup.isGroup ? workingHourGroup.groupBackgroundColor : '#FFF'">
        <div class="col-1 font-weight-bold">
            {{ workingHourGroup.groupText }}
        </div>
        <div class="col-10">
            <ng-container *ngFor="let text of workingHourGroup.displayTextList">
                <div> {{ text }} </div>
            </ng-container>
        </div>
        <div class="col-1">
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    id="manual-instruction-action_working-hour-group_checkbox_{{workingHourGroup.groupInstructionIndex}}"
                    class="custom-control-input"
                    [(ngModel)]="handlingStatus[workingHourGroup.groupInstructionIndex]"
                    [disabled]="workingHourGroup.disabled"
                    (change)="onWorkingHourGroupCheckboxChange($event, workingHourGroup, workingHourData.nonWorkingHourGroups, workingHourGroup.groupInstructionIndex)">
                <label for="manual-instruction-action_working-hour-group_checkbox_{{workingHourGroup.groupInstructionIndex}}" class="custom-control-label"></label> 
            </div>      
        </div>
    </div>
</ng-container>

<div class="manual-instruction-sub-title">
    {{'conponent.manual-instruction.non-working-hours' | translate}}
</div>
<ng-container *ngFor="let workingHourGroup of workingHourData.nonWorkingHourGroups;let index = index">
    <div 
        class="group-instructions-container row" 
        [style.background]="workingHourGroup.isGroup ? workingHourGroup.groupBackgroundColor : '#FFF'">
        <div class="col-1 font-weight-bold">
            {{ workingHourGroup.groupText }}
        </div>
        <div class="col-10">
            <ng-container *ngFor="let text of workingHourGroup.displayTextList">
                <div> {{ text }} </div>
            </ng-container>
        </div>
        <div class="col-1">
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    id="manual-instruction-action_non-working-hour-group_checkbox_{{workingHourGroup.groupInstructionIndex}}"
                    class="custom-control-input"
                    [(ngModel)]="handlingStatus[workingHourGroup.groupInstructionIndex]"
                    [disabled]="workingHourGroup.disabled"
                    (change)="onWorkingHourGroupCheckboxChange($event, workingHourGroup, workingHourData.workingHourGroups, workingHourGroup.groupInstructionIndex)">
                <label for="manual-instruction-action_non-working-hour-group_checkbox_{{workingHourGroup.groupInstructionIndex}}" class="custom-control-label"></label> 
            </div>                
        </div>
    </div>
</ng-container>

<textarea maxlength="{{ constants.TEXTAREA_MAX_LENGTH }}" class="manual-instruction-remark" type="text" rows="5" 
    placeholder="{{'pages.sn.remark' | translate}}" [(ngModel)]="remark" [disabled]="isLoading"></textarea>
<div class="manual-instruction-submit">
    <button class="standard brand-amber" (click)="onClickSave()" [disabled]="isLoading">
        <span><i class="fas fa-save space"></i>{{'global.save' | translate}}</span>
    </button>
    <button class="standard brand-amber" (click)="onClickSubmit()" [disabled]="isLoading">
        <span><i class="fas fa-check space"></i>{{'conponent.manual-instruction.handled' | translate}}</span>
    </button>
</div>