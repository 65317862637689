import { Pipe, PipeTransform } from '@angular/core';
import { JMLanguage } from './JMLanguage';

@Pipe({
  name: 'translate'
})
export class JMLanguagePipe implements PipeTransform {

  transform(key:string, args?:any): string {
    let lang = JMLanguage.getCurrentLanguage();
    return JMLanguage.translate(key,args,lang);
  }
}