import { Component, Injector, OnInit } from '@angular/core';
import { Permission } from '@enum/permission';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { BasePage } from 'src/app/ui/model/base/base';
import { Session } from 'src/app/services/session';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'customers',
  templateUrl: './customers.html',
  styleUrls: ['./customers.scss']
})

export class CustomersComponent extends BasePage implements OnInit {

  constructor(injector: Injector) {
    super(injector);
  }

  pageTitle: string;

  ///------------
  selectedWorkCenter: string; // current selected workcentre
  tablexParam: {} = {};  // isLoadingTable
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  totalClientCount = 0;
  clientList: JMOBJ.Client[]; // the result of requestClientList

  // 
  protected componentName(): string {
    return "customers";
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.selectedWorkCenter = Session.selectedWorkCentre;
    this.pageTitle = JMLanguage.translate("pages.customers.page-title", [this.selectedWorkCenter]);
    this.checkViewPermission(Permission.wcmView);
    this.checkWorkCenterPermission(this.selectedWorkCenter);

    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      pageSizeOptions: [10, 25, 100],
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      headers: [
        { id: 'client_number', name: "pages.customers.table-column-number", type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'client_short_name', name: "pages.customers.table-column-short-name", type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'client_name', name: "pages.customers.table-column-name", type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      ],
      content: []
    };
    this.requestClientList();
  }

  ngOnDestroy() {
  }

  //---------------------------------------------------------------------------
  // view functions
  private renderTable() {
    this.tablexParam['pageCount'] = Math.ceil(this.totalClientCount / this.currentPageSize);
    this.tablexParam['content'] = this.clientList.map(client => {
      return [
        client.clientNumber,
        client.clientShortName,
        client.name[Session.selectedLanguage],
      ];
    });
  }

  //---------------------------------------------------------------------------
  // api function
  requestClientList() {
    this.tablexParam['isLoadingTable'] = true;
    this.totalClientCount = 0;
    this.clientList = [];

    let equipmentsRequest: JM.JMRequestEquipmentsParameters = new JM.JMRequestEquipmentsParameters();
    equipmentsRequest.active = JMENUM.RequestActive.ACTIVE;
    equipmentsRequest.workCentre = this.selectedWorkCenter;
    equipmentsRequest.parameter = ["clientShortName"];
    equipmentsRequest.pageSize = this.currentPageSize;
    equipmentsRequest.pageNumber = this.currentPage;


    JM.JMConnector.sendEquipmentsParameters(equipmentsRequest, (error: JM.JMNetworkError, equipmentResult: JM.JMResponseEquipmentsParameters) => {
      if (error) {
        this.tablexParam['isLoadingTable'] = false;
        this.handleJMError(error);
        return;
      }
      if (!equipmentResult || !equipmentResult.code || equipmentResult.code != 200 || !equipmentResult.payload || !equipmentResult.payload.result) {
        this.tablexParam['isLoadingTable'] = false;
        this.openErrorBar(equipmentResult);
        return;
      }

      if (equipmentResult.payload.result.length > 0) {
        const clientShortNameList = equipmentResult.payload.result[0].clientShortName.filter(name => !!name)

        let request = new JM.JMRequestClientsClientSummary();
        request.clientShortName = clientShortNameList;
        request.active = JMENUM.RequestActive.ACTIVE;
        request.parameters = ['clientNumber', "clientShortName", "name"];
        JM.JMConnector.sendClientsClientSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseClientsClientSummary) => {
          this.tablexParam['isLoadingTable'] = false;
          if (error) {
            this.handleJMError(error);
            return;
          }

          if (!response || !response.code || response.code != 200 || !response.payload) {
            this.openErrorBar(response);
            return;
          }

          this.totalClientCount = equipmentResult.payload.totalCount;
          this.clientList = response.payload.records;
          this.renderTable();
        });
      } else {
        this.tablexParam['isLoadingTable'] = false;
      }
    });
  }

  //---------------------------------------------------------------------------
  // page function
  onPageNumberClicked = (pageNumber) => {
    this.currentPage = parseInt(pageNumber);
    this.requestClientList();
  }

  onPageSizeClicked = (pageSize) => {
    this.currentPage = 1;
    this.currentPageSize = parseInt(pageSize);
    this.requestClientList();
  }

  // TODO: reload language
  onLanguageChanged() {

  }

}
