<div class="contract-form-wrap">

  <div class="title-wrap mx-0 mb-4">
    <h3 class="d-inline" *ngIf="parameters.mode=='add'">{{ "add-dummy-contract-form.title.add" | translate }}</h3>
    <h3 class="d-inline" *ngIf="parameters.mode=='edit'">{{ "add-dummy-contract-form.title.edit" | translate }}</h3>
    <div class="button-wrap text-right d-inline-block float-right"
      *ngIf="hasContractUpdatePermission || (parameters.mode === 'add' && hasDummyTranUpdatePermission)">
      <app-submit-button [id]="'dummy_contract_save_button'" [text]="'global.save'"
        [buttonClass]="' standard brand-amber mr-0'" [iconClass]="'fa-save'" [isDisabled]="!uiEnableSaveButton"
        [isLoading]="uiLoadingSaveButton" (onClick)="onSaveButtonClicked($event)"></app-submit-button>
      <ng-container *ngIf="parameters.mode=='edit' && hasContractUpdatePermission">
        <app-submit-button [id]="'dummy_contract_delete_button'" [text]="'global.delete'"
          [buttonClass]="'d-block standard brand-red mr-0 my-1'" [iconClass]="'fa-times'"
          [isDisabled]="!uiEnableDeleteButton" [isLoading]="uiLoadingDeleteButton"
          (onClick)="onDeleteButtonClicked($event)"></app-submit-button>
      </ng-container>
    </div>

    <div class="alert-font font-size-xs d-inline-block w-70"
      *ngIf="uiErrorMessage.length > 0 || uiHaveTeamLink || uiHaveSwitchOver">
      <div *ngIf="uiHaveTeamLink" class="team-using-alert mb-2">
        {{ "pages.contract-dummy.error.have-team-using" | translate }}
      </div>
      <div *ngIf="uiHaveSwitchOver" class="have-contract-switch-over-alert mb-2">
        {{ "pages.contract-dummy.error.have-contract-switch-over" | translate }}
      </div>
      <div *ngFor="let msg of uiErrorMessage;" class="other-error-alert mb-2">
        {{msg}}
      </div>
    </div>

  </div>

  <form id="add-dummy-contract-form">
    <div class="bold mt-3">
      <span class="mandatory">*</span>{{ 'pages.contract-dummy.dummy-contract-number' | translate }}
    </div>
    <span class="alert-font font-size-xs" *ngIf="uiHaveSwitchOver">
      {{ 'pages.contract-dummy.error.cant-be-edited' | translate }}
    </span>
    <input id="id_contract_number" name="contractNumber" type="text" class="form-control"
      [disabled]="parameters.mode=='edit' || !hasContractUpdatePermission" [(ngModel)]="uiContractNumber" (keyup)="onContractNumberKeyup($event)">

    <div class="bold mt-3">
      <span class="mandatory">*</span>{{ 'pages.contract-dummy.dummy-contractor-number' | translate }}
    </div>
    <ng-select id="id_contractor_number" name="contractorNumber" class="align-middle" [(ngModel)]="uiContractorNumber"
      [items]="uiAllContractorNumberArray" [multiple]="false" [closeOnSelect]="true" [searchable]="true"
      [hideSelected]="true" [addTag]="true" [disabled]="!uiEnableContractorNumber || !hasContractUpdatePermission"
      (change)="onContractorNumberChanged($event)"></ng-select>

    <div class="bold mt-3">
      <span class="mandatory">*</span>{{ 'pages.contract-dummy.dummy-contractor-name' | translate }}
    </div>
    <ng-select id="id_contractor_name" name="contractorName" class="align-middle" [(ngModel)]="uiContractorName"
      [items]="uiAllContractorNameArray" [multiple]="false" [closeOnSelect]="true" [searchable]="true"
      [hideSelected]="true" [addTag]="true" [disabled]="!hasContractUpdatePermission" (change)="onContractorNameChanged($event)"></ng-select>
    <div class="bold mt-3">
      <span class="mandatory">*</span>{{ 'pages.contract-dummy.dummy-start-date' | translate }}
    </div>
    <input id="id_contract_start_date" name="contractStartDate" class="form-control date-picker-input-box"
      [(ngModel)]="uiContractStartDate" ngbDatepicker readonly #contractStartDate="ngbDatepicker"
      [disabled]="!uiEnableStartDate || !hasContractUpdatePermission" (click)="contractStartDate.toggle()">

    <div class="bold mt-3">
      <span class="mandatory">*</span>{{ 'pages.contract-dummy.dummy-end-date' | translate }}
    </div>
    <input id="id_contract_end_date" name="contractEndDate" class="form-control date-picker-input-box"
      [(ngModel)]="uiContractEndDate" [minDate]="uiContractMinEndDate" ngbDatepicker readonly
      #contractEndDate="ngbDatepicker" (click)="contractEndDate.toggle()" [disabled]="!hasContractUpdatePermission">

    <div class="bold mt-3">
      {{ 'pages.contract-dummy.dummy-contract-title' | translate }}
    </div>
    <textarea id="id_contract_title" name="contractTitle" class="form-control" [(ngModel)]="uiContractTitle"
    [disabled]="!hasContractUpdatePermission">
    </textarea>

    <div class="bold mt-3">
      {{ "pages.contract-dummy.dummy-lift-contract" | translate }}
    </div>
    <div>
      <div class="mr-2 btn-group btn-group-toggle" id="lift-trapped" ngbRadioGroup name="liftTrapped"
        [(ngModel)]="uiLiftTrapped" [disabled]="!hasContractUpdatePermission">
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="true" id="lift-trapped-yes">
          {{ "global.yes" | translate }}
        </label>
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="false" id="lift-trapped-no">
          {{ "global.no" | translate }}
        </label>
      </div>
    </div>
    <div class="bold mt-3">
      {{ "pages.contract-dummy.dummy-find-equipment-by" | translate }}
    </div>
    <ng-select id="find_equipment_by" name="findEquipmentBy" class="align-middle" bindLabel="name" bindValue="id"
      [(ngModel)]="uiFindEquipmentBy" [items]="uiAllFindEquipmentByOptions" [multiple]="false" [closeOnSelect]="true"
      [searchable]="false" [hideSelected]="false" [addTag]="false" [clearable]="false" [disabled]="!hasContractUpdatePermission"></ng-select>
  </form>
  <p class="remarks mt-5">
    <span class="mandatory">*</span>{{ 'pages.contract-dummy.mandatory' | translate}}
  </p>
</div>