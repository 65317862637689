/*
  version 20191216:
   - changed onFilterChanged -> this is the event after debounce time
  version 20201021:
   - mutiple lines support html
  version 20201022:
   - add method getNgClassObj -> for tablex html part to handle custom class for table row
  version 20201117:
   - added filter options: filter
   - removed unused service
  version 20210413: 
   - add click checkbox to select whole col
  version 20210423:
   - add clear filter button
   - bug fix for dropdown type filter
  version 20210505: 
   - add minify button
  version 20210602: 
   - enhance filter dropdown for multi language search
*/
import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface TablexComponentParams {
  isLoadingTable?: boolean,
  filterDebounceTime?: number,
  minifyButton?: boolean,

  // UI switch
  enableSetPageSize?: boolean,
  enablePagination?: boolean,
  enableStickyHeader?: boolean,
  enableClearFilter?: boolean,
  enableColFilter?: boolean,
  enableSelectedRowCount?: boolean,
  enableSelectedAll?: boolean,
  enableSort?: boolean,
  enableSelectCol?: boolean;

  // pagination params
  pageSizeOptions?: Array<number>,
  currentPageSize?: number,  
  currentPage?: number,
  pageCount?: number,
  isPaginationLeftSide?: boolean,
  totalRowCount?: number,

  // filter
  selectedRowIndex?: number,
  selectedRowCount?: number,
  fullColNameList?: any,  // [{id: xxx, name: xxx}, ...]
  selectedColId?: Array<any>,
  sortOrder?: number,
  sortBy?: string,
  filter?: any,

  // class
  tableClass?: string,
  tableWrapperClass?: string, // class of the table warpper div
  tableRow?: string, // row class
  customClass?: '',

  // handlers
  onPageNumberClicked?: (pageNumber) => void,
  onPageSizeClicked?: (pageSize) => void,
  onHeaderClicked?: (index, header) => void,
  onRowClicked?: (index, row) => void,
  onRowSelected?: (index, row) => void,
  onRowUnselected?: (index, row) => void,
  onFilterChanged?: (event, index, header, filter) => void,
  onFilterKeyUp?: (index, header, newValue) => void,
  onFilterClear?: () => void,
  onColFiltered?: (selectedColId) => void,
  onSortOrderChanged?: (header, sortOrder) => void,

  // table content
  headers?: Array<any>,
  content?: Array<any>,
  customClassRows?: Array<any>,
  highlightedRows?: Array<any>,
  tableRowDataContents?: Array<any>,       // array of attr.data for row use
  displayColId?: Array<string>
}

@Component({
  selector: 'app-tablex',
  templateUrl: './tablex.component.html',
  styleUrls: ['./tablex.component.scss']
})

/*
// if text
content: ["AbC", "AAA"]

//if html
content: ["<b>Title1</b>", "<b>Title2</b>"]

// if multiLine
content: [
	["row1 line1", "row1 line2"],
	["row2 line1", "row2 line2"],
	["row3 line1", "row3 line2"],
]

// if buttons
content: [
	[
		{ "id": "google", "name": "Google", "class": "", "icon": "", "disable": false, "show":false,"onClicked": onClick},
    { "id": "button_2", "name": "Button 2", "class": "", "icon": "", "disable": false, "show":false, "onClicked": onClick}
	],
]

// if link
content: [
	{ "name": "Google", "href": "http://www.google.com"},
	{ "name": "Facebook", "href": "https://www.facebook.com/"},
]

// if BorderLabel
content: [
	{ "name": "Google" "onDragStart": function, "onDragEnd": function}
]

// if BorderLabelAddButton
content: {
  label:[
    { 
      "name": "Google",
      "onDragStart": function, 
      "onDragEnd": function
      "onBorderLabelAddedClicked": function
    }
  ],
  disable: false,
  onBorderLabelAddClicked: function
]

// if input
content: [
  {
    "id": id,
    "placeholder": description,
    "value": value,
    "enable": false,
    "readonly": false,
    "maxlength": 100,
    "onKeyup": onKeyup,
  },
  {

  }
]

*/

export class TablexComponent implements OnInit {
  /*
  tablexParam :{
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      tableRow: "",  // class word table row 
      headers: [
        {
          id: '',
          name: 'header 1', 
          type: TablexColumnType.Text, 
          data: null, // custom object
          enableFilter: false,
          onClicked:function,
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle,
        },
        {
          id: '',
          name: 'header 2', 
          type: TablexColumnType.BorderLabel,
          data: null,
          enableFilter: false,
          onClicked:function,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id: '',
          name   : 'header 4', 
          type: TablexColumnType.Buttons      ,
          data: null, // all custom object
          enableFilter: false,
          onClicked:function ,
          horizontalAlign: TablexColumnHorizontalAlign.Center,  // content hard code to center
          verticalAlign: TablexColumnVerticalAlign.Middle, // content hard code to middle 
          buttons: [
            {
              name: '',
              icon: 'fas fa-plus',
              class: 'glyph brand-blue',
              hidden: false,
              disable: false,
              onClicked: this.onAddNewTagClicked,
            }
          ]
        },
      ],
      content: []
    }
  */
  @Input() tablexParam: {
    isLoadingTable: false,
    filterDebounceTime: null,

    // page
    enableSetPageSize: false,
    enablePagination: false,
    enableStickyHeader: false,
    enableClearFilter: false,
    enableColFilter: false,
    enableSelectedRowCount: false,
    enableSelectedAll: false,
    enableSort: false,
    pageSizeOptions: [10, 25, 100],
    currentPageSize: 10,  
    currentPageIndex: 1,
    isPaginationLeftSide: false,
    minifyButton: false,

    // filter
    selectedRowIndex: -1,
    selectedRowCount: 0,
    fullColNameList: any,  // [{id: xxx, name: xxx}, ...]
    selectedColId: [],
    sortOrder: 1,
    sortBy: null,
    filter: any,

    // class
    tableClass: '',
    tableWrapperClass: '',  // class of the table warpper div
    tableRow: '',       // row class
    onPageNumberClicked: (button)=>{},
    onPageSizeClicked: (button)=>{},
    onHeaderClicked: (index, header) => {},
    onRowClicked: (index, row) => {},
    onRowSelected: (index, row) => {},
    onRowUnselected: (index, row) => {},
    onFilterChanged: (event, index, header, filter) => {},
    onFilterKeyUp: (index, header, newValue) => {},
    onFilterClear: () => {},
    onColFiltered: (selectedColId) => {},
    onSortOrderChanged: (header, sortOrder) => {},
    customClass: '',

    // table content
    headers: [],
    content: [],
    customClassRows: [],
    highlightedRows: [],
    tableRowDataContents: [],       // array of attr.data for row use
    displayColId: []
  };
  
  private searchTerms = new Subject<any[]>();
  selectedColId: any = [];

  
  // Data 
  

  constructor(private injector: Injector) {
    // this.excelService = injector.get(ExcelService);
    // this.translateService = injector.get(TranslateService);
  }

  ngOnChanges(change:SimpleChanges) {
    if( this.tablexParam['enableColFilter'] && this.tablexParam['selectedColId'] != this.tablexParam['fullColNameList'].map(col => col.id) ){
      this.selectedColId = this.tablexParam['selectedColId'];
      if(!this.selectedColId.includes('objId')){
        this.selectedColId.unshift('objId');
        this.tablexParam['selectedColId'] = this.selectedColId;
      }
    }else{
      this.selectedColId = [];
    }

    if (!this.tablexParam.filter) {
      this.tablexParam.filter = {};
    }
  }
  ngOnInit() {
    this.searchTerms.pipe(
      debounceTime(this.tablexParam['filterDebounceTime']),
      // distinctUntilChanged(),
    ).subscribe((data) => {
      this.onFilterDebounce(data);
    });

    if (!this.tablexParam.filter) {
      this.tablexParam.filter = {};
    }
  }
  ngDoCheck() {
  }
  ngAfterContentInit() {
  }
  ngAfterContentChecked() {
  }
  ngAfterViewInit() {
  }
  ngAfterViewChecked() {

  }
  ngOnDestroy() {
  
  }

  //----------------------------------------------
  // i is the row index
  // button is the row object
  onButtonClicked(button, rowIndex) {
    if (button.onClicked) {
      button.onClicked(button, rowIndex);
    }
  }
  
  onDragEnd(item) {
    if (item.onDragEnd) {
      item.onDragEnd(item);
    }
  }

  private onPageSizeClicked(value) {
    this.tablexParam.currentPageSize = value;
    if (this.tablexParam.onPageSizeClicked) {
      this.tablexParam.onPageSizeClicked(this.tablexParam.currentPageSize);
    }
  }

  private onPageNumberClicked(button) {
    if (this.tablexParam.onPageNumberClicked) {
      this.tablexParam.onPageNumberClicked(button);
    }
  }

  private onHeaderClicked(index, header) {
    if (this.tablexParam.onHeaderClicked) {
      this.tablexParam.onHeaderClicked(index, header);
    }
  }

  // private onFilterChanged(index, header) {
  //   if (header.onFilterChanged) {
  //     header.onFilterChanged(index, header);
  //   }
  // }

  private onFilterDebounce(args) {
    let event = args[0];
    let index = args[1];
    let header = args[2];
    
    if (this.tablexParam.onFilterChanged) {
      this.tablexParam.onFilterChanged(event, index, header, this.tablexParam.filter);
    }
  }

  private onFilterKeyUp(event, index, header) {
    if (this.tablexParam.onFilterKeyUp) {
      this.tablexParam.onFilterKeyUp(index, header, event.target.value);
    }
    // if mark 
    if (this.tablexParam.onFilterChanged) {
      let data = [event, index, header];
      this.searchTerms.next(data);
    }
  }
  
  private onFilterClear(){
    if(this.tablexParam.onFilterClear){
      this.tablexParam.headers.forEach(header => {
        if(header['filterDropdownOption']){
          let temp: any = header['filterDropdownOption'];
          temp['selectedValue'] = undefined;
        }
      })
      this.tablexParam.onFilterClear();
    }
  }

  private onRowClicked(index, row) {
    if (!this.isEnableRowClick()) { return; }

    // if enable unselect row
    if (this.tablexParam.onRowUnselected) {
      if (this.tablexParam.selectedRowIndex === index) {
        this.unselectRow(index, row);
      } else {
        this.selectRow(index, row);
      }
    } else {
      this.selectRow(index, row);
    }

    // callback
    if (this.tablexParam.onRowClicked) {
      this.tablexParam.selectedRowIndex = index;
      this.tablexParam.onRowClicked(index, row);
    }
  }

  private unselectRow(index, row) {
    this.tablexParam.selectedRowIndex = -1;
    if (this.tablexParam.onRowUnselected) {
      this.tablexParam.onRowUnselected(index, row);
    }
  }

  private selectRow(index, row) {
    this.tablexParam.selectedRowIndex = index;
    if (this.tablexParam.onRowSelected) {
      this.tablexParam.onRowSelected(index, row);
    }
  }

  private isEnableRowClick() {
    return this.tablexParam.onRowClicked || this.tablexParam.onRowSelected || this.tablexParam.onRowUnselected;
  }

  private onBorderLabelClicked(index, label) {
    if (label.onClicked) {
      label.onClicked(index, label);
    }
  }

  private onBorderLabelAddClicked(row, col, content) {
    if (content[col].onBorderLabelAddClicked) {
      content[col].onBorderLabelAddClicked(row, col, content);
    }
  }

  private onClickSelectAll(){
    if(this.tablexParam.selectedRowCount > 0){
      this.tablexParam.highlightedRows.map((row, r) => { 
        if(row){
          this.onRowClicked(r, this.tablexParam.content[r]);
        } 
      });
    }else{
      for(let i=0; i < this.tablexParam.content.length; i++){
        this.onRowClicked(i, this.tablexParam.content[i]);
      }
    }
  }

  private onClickSelectCol(header, headerIndex){
    if(this.checkAllCheckboxIsTrue(headerIndex)){
      this.setSelectAllCheckboxValue(headerIndex, false);

      for(let i=0; i < this.tablexParam.content.length; i++){
        let checkbox: any = this.tablexParam.content[i][headerIndex]; //avoid error: type = never
        if(!checkbox['isDisabled']){
          checkbox['value'] = false;
          Object.assign(this.tablexParam.content[i][headerIndex], checkbox);
        }
      }
    }else{
      this.setSelectAllCheckboxValue(headerIndex, true);

      for(let i=0; i < this.tablexParam.content.length; i++){
        let checkbox: any = this.tablexParam.content[i][headerIndex]; //avoid error: type = never
        if(!checkbox['isDisabled']){
          checkbox['value'] = true;
          Object.assign(this.tablexParam.content[i][headerIndex], checkbox);
        }
        
      }
    }
  }

  private onChangeCheckbox(header, headerIndex){
    if(this.checkAllCheckboxIsTrue(headerIndex)){
      this.setSelectAllCheckboxValue(headerIndex, true);
    }else{
      this.setSelectAllCheckboxValue(headerIndex, false);
    }
  }

  private checkAllCheckboxIsTrue(headerIndex){
    let filterEnabledCheckbox = this.tablexParam.content.filter(col => !col[headerIndex]['isDisabled']); 
    let hasFalse = filterEnabledCheckbox.find(col => !col[headerIndex]['value']);
    if(hasFalse){
      return false;
    }else{
      return true;
    }
  }

  private setSelectAllCheckboxValue(headerIndex,value: boolean){
    setTimeout(()=>{
      let headerData: any = this.tablexParam.headers[headerIndex];
      headerData['selectAll'] = value; //select all checkbox = value
      Object.assign(this.tablexParam.headers[headerIndex], headerData);
    }, 10); //override ngModel
  }

  private onSelectFilterCol(headerId){
    if(!this.selectedColId.includes('objId')){
      this.selectedColId.unshift('objId');
    }

    if(headerId == 'all'){
      if(this.selectedColId.length != this.tablexParam['fullColNameList'].length){
        this.selectedColId = this.tablexParam['fullColNameList'].map(col => col.id)
      }else{
        this.selectedColId = []; 
      }
      this.tablexParam.onColFiltered(this.selectedColId);
      return;
    }

    !this.selectedColId.includes(headerId) ? this.selectedColId.push(headerId) : this.selectedColId.splice(this.selectedColId.indexOf(headerId), 1);
    
    // update column
    if(this.selectedColId.length > 0){
      this.tablexParam.onColFiltered(this.selectedColId);
    }
    else
    {
      this.tablexParam.onColFiltered(this.tablexParam['fullColNameList'].map(col => col.id));
      this.selectedColId = []; 
    }
  }

  private onSortOrderChanged(header){
    let sortBy = header.id;
    let sortOrder = 1;

    if( this.tablexParam['sortBy'] == header.id && this.tablexParam['sortOrder'] != 1){
      sortOrder = 1;
      sortBy = undefined;
    }

    if( this.tablexParam['sortBy'] == header.id && this.tablexParam['sortOrder'] == 1){
      sortOrder = -1;
      sortBy = header.id;
    }

    this.tablexParam.onSortOrderChanged(sortBy, sortOrder);
  }

  // end of drop down filter
  getNgClassObj(index){
    let customClass;
    if(this.tablexParam.customClassRows)
    {
      customClass = this.tablexParam.customClassRows.find(element => {return element==index;});
    }
    return {
      'selected': index == this.tablexParam.selectedRowIndex,
      'highlight': this.tablexParam.highlightedRows && this.tablexParam.highlightedRows[index],
      [this.tablexParam.customClass]:customClass!=undefined
    };
  }

  //------------------------------------------------------------------------------
  // sort the table by the specified column  
  public sortTable(index) {
    this.tablexParam['content'].sort((row1, row2) => {
      let string1 :string = row1[index];
      let string2 :string = row2[index];
      return string1.localeCompare(string2);
    })
  }

  public clearFilter() {
    this.tablexParam.filter = {}
  }

  public setFilter(filter) {
    this.tablexParam.filter = filter;
  }
}
