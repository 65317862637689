import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
import { InputFieldType } from 'src/lib/ui-component/custom-fields-form/input-field-type.enum';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { Subject } from 'rxjs';
import { Session } from '@services/session';
import { OptionsSubmitType } from 'src/lib/ui-component/custom-dropdown-options/options-submit-type.enum';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-settings-dropdown',
  templateUrl: './settings-dropdown.component.html',
  styleUrls: ['./settings-dropdown.component.scss']
})
export class SettingsDropdownComponent extends BasePage implements OnInit {
  @ViewChild('dropdown_name_list', { static: true }) dropdownNameListElem;
  @ViewChild('dropdown_attr_form', { static: true }) dropdownAttrElem;
  @ViewChild('dropdown_options', { static: true }) dropdownOptionsElem;

  hasEditPermission: boolean;

  // component config & data
  dropdownNameListParam: any = {};
  dropdownNameListInput: any = {};
  dropdownAttrParam: any = {};
  dropdownAttrInput: any = {};
  dropdownNameList: any = [];

  dropdownOptionsParam: any = {};
  dropdownOptionList: any;

  selectedDropdownId: string;
  selectedDropdownIndex: number = 0;
  dropdownAttr: any = [];
  dropdownOptions: any;

  // Dropdown List
  pageNumber: number = 1;
  pageSize: number = 100;

  private searchDropdown = new Subject<any[]>();

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initPermission();
    this.requestDropdownSummary();
    
    this.onLangChange(); //translate service bug

    this.searchDropdown.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.pageNumber = 1;
      this.requestDropdownSummary();
    });
  }
  
  onLanguageChanged() {
    
  }

  onLangChange(){
    this.initAttrFormElem();
    this.initDropdownNameListElem();
    this.initOptionsFormElem();
    this.requestMappingType();
    this.dropdownAttrElem.copyInputData(this.dropdownAttrInput);
    this.updateTranslateText();
  }

  generateOptionsKey(){
    let optionsKeyCount = 0;
    if (this.dropdownAttrInput.options && this.dropdownAttrInput.options.length) {
      for (let option of this.dropdownAttrInput.options) {
        if (option.key && option.key > optionsKeyCount) {
          optionsKeyCount = option.key
        } else {
          optionsKeyCount++;
          option.key = optionsKeyCount;
          option.active = true;
        }
      }
    }
  }

  //----- API -----//
  async requestDropdownExist(){
    const request = new JM.JMRequestDropdownSummary();
    request.system = [Constants.SYSTEM_NAME];
    request.key = [this.dropdownNameListInput.hiddenContainerInput];

    const response: JM.JMResponseDropdownSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.totalCount <= 0) {
      this.dropdownNameList.unshift(this.dropdownNameListInput.hiddenContainerInput);
      this.initDropdownForm(this.dropdownNameListInput.hiddenContainerInput);
      this.dropdownNameListElem.onClickListItem(0);
      this.dropdownNameListInput.hiddenContainerInput = undefined;
      this.onClickAddDropdown();
    } else {
      this.openSnackBar(JMLanguage.translate("pages.settings.dropdown.dropdown-duplicated", [this.dropdownNameListInput.hiddenContainerInput]));
    }
  }

  async requestDropdownSummary(key?, deletedRecord?){
    const request = new JM.JMRequestDropdownSummary();
    request.system = [Constants.SYSTEM_NAME];
    request.pageNumber = this.pageNumber;
    request.pageSize = this.pageSize;
    request.active = true;
    
    if(this.dropdownNameListInput.headerInput){
      request.filter = {'key': this.dropdownNameListInput.headerInput};
    }
    
    const response: JM.JMResponseDropdownSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.totalCount <= 0) {
      if (deletedRecord) {
        this.requestDropdownSummary();
      } else {
        this.openSnackBar(JMLanguage.translate("global.no-record-found"));
      }
      return;
    } else {
      this.dropdownAttr = response.payload.records.map(dropdown => {
        let data = {
          _id: dropdown._id,
          key: dropdown.key,
          descriptionEn: dropdown.description.en,
          descriptionZh: dropdown.description.zh,
          description: dropdown.description,
          usageType: dropdown.usageType,
          mappingType: dropdown.mappingType,
          options: dropdown.options,
          mandatoryJobNature: dropdown.mandatoryJobNatureList,
          active: dropdown.active
        };
        return data;
      });

      if (this.pageNumber == 1) {
        this.dropdownNameList = this.dropdownAttr.map(dropdown => dropdown.key);
      } else {
        this.dropdownNameList = this.dropdownNameList.concat(this.dropdownAttr.map(dropdown => dropdown.key));
      }

      if (key) {
        this.selectedDropdownIndex = this.dropdownNameList.indexOf(key);
      }
      this.dropdownNameListElem.onClickListItem(this.selectedDropdownIndex);
    }
  }

  requestMappingType(mappingType?){
    let request: JM.JMRequestDropdownMappingType = new JM.JMRequestDropdownMappingType();
    request.system = Constants.SYSTEM_NAME;
    
    if(mappingType){
      request.mappingType = [this.dropdownAttrInput.mappingType];
    }
    
    JM.JMConnector.sendDropdownMappingType(request, (error:JM.JMNetworkError, response:JM.JMResponseDropdownMappingType) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      if(mappingType){
        this.dropdownOptionsParam.fieldList = response.payload[0].mappingColumn.map(col => {
          let field = { 
            key: col.key, 
            type: InputFieldType[col.type], 
            titleCustomClass: 'bold',
            placeholder: '',
            mandatory: col.mandatory,
            supportLang: col.supportLang,
            onChange: () => {}, 
            title: col.label[Session.selectedLanguage],
            label: col.label,
          };
          return field;
        });
        this.dropdownOptionsElem.initOptionForm();
      }else{
        this.dropdownAttrParam.fieldOptions.mappingType = response.payload.map(type => {
          return {
            id: type.mappingType,
            key: type.mappingType,
            value: type.mappingType,
            label: type.description[Session.selectedLanguage],
          }
        });
      }
    });
  }

  requestCreateDropdown(){
    if(this.dropdownAttrInput.key && this.dropdownAttrInput.descriptionEn && this.dropdownAttrInput.descriptionZh){
      this.generateOptionsKey();
      this.dropdownOptionsParam.optionBtn.isDisabledSubmitBtn = true;
      let request: JM.JMRequestDropdownCreateDropdown = new JM.JMRequestDropdownCreateDropdown();
      request.system = Constants.SYSTEM_NAME;
      request.key = this.dropdownAttrInput.key;
      request.description = {en: this.dropdownAttrInput.descriptionEn, zh: this.dropdownAttrInput.descriptionZh};
      request.usageType = this.dropdownAttrInput.usageType;
      request.mappingType = this.dropdownAttrInput.mappingType
      request.options = this.dropdownAttrInput.options;
      request.mandatoryJobNatureList = this.dropdownAttrInput.mandatoryJobNature;
      
      JM.JMConnector.sendDropdownCreateDropdown(request, (error:JM.JMNetworkError, response:JM.JMResponseDropdownCreateDropdown) => {
        if (error) {
          this.handleJMError(error);
          return;
        }
        
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }

        this.openSnackBar(JMLanguage.translate("pages.settings.dropdown.dropdown-created"));
        this.requestDropdownSummary(response.payload['key']);
        this.dropdownOptionsParam.optionBtn.isDisabledSubmitBtn = false;
      });
    }else{
      this.openSnackBar(JMLanguage.translate("pages.settings.dropdown.please-fill-in-all-mandatory-fields"));
    }
  }

  requestUpdateDropdown(userInput?){
    this.generateOptionsKey();
    this.dropdownAttrParam.onChangeBtn[0].disabled = true;
    this.dropdownOptionsParam.optionBtn.isDisabledSubmitBtn = true;
    let request: JM.JMRequestDropdownUpdateDropdown = new JM.JMRequestDropdownUpdateDropdown();
    request.system = Constants.SYSTEM_NAME;
    request._id = this.dropdownAttrInput._id;
    request.key = this.dropdownAttrInput.key;
    request.description = {en: this.dropdownAttrInput.descriptionEn, zh: this.dropdownAttrInput.descriptionZh};
    request.usageType = this.dropdownAttrInput.usageType;
    request.mappingType = this.dropdownAttrInput.mappingType
    request.options = this.dropdownAttrInput.options;
    request.mandatoryJobNatureList = this.dropdownAttrInput.mandatoryJobNature;

    JM.JMConnector.sendDropdownUpdateDropdown(request, (error:JM.JMNetworkError, response:JM.JMResponseDropdownUpdateDropdown) => {
      if (error) {
        this.handleJMError(error);
        return;
      }
      
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      this.requestDropdownSummary(response.payload['key']);
      this.openSnackBar(JMLanguage.translate("pages.settings.dropdown.dropdown-updated"));
      this.dropdownOptionsElem.closeInputForm();
      this.dropdownAttrParam.onChangeBtn[0].disabled = false;
      this.dropdownOptionsParam.optionBtn.isDisabledSubmitBtn = false;
      this.resetDropdownForm();
    });
  }

  requestDeleteDropdown(){
    if(!this.dropdownAttrInput._id){
      this.dropdownNameList.splice(0,1);
      this.resetDropdownForm();
      this.onClickDropdownName(0);
    }else{
      this.dropdownOptionsParam.optionBtn.isDisabledDelete = true;
      let request: JM.JMRequestDropdownUpdateDropdown = new JM.JMRequestDropdownUpdateDropdown();
      request.system = Constants.SYSTEM_NAME;
      request._id = this.dropdownAttrInput._id;
      request.key = this.dropdownAttrInput.key;
      request.active = false;
  
      JM.JMConnector.sendDropdownUpdateDropdown(request, (error:JM.JMNetworkError, response:JM.JMResponseDropdownUpdateDropdown) => {
        if (error) {
          this.handleJMError(error);
          return;
        }
        
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }
        this.openSnackBar(JMLanguage.translate("pages.settings.dropdown.dropdown-deleted"));
        this.selectedDropdownIndex = 0;
        this.resetDropdownForm();
        this.dropdownOptionsElem.closeInputForm();
        this.requestDropdownSummary(this.dropdownNameListInput.headerInput);
        this.dropdownOptionsParam.optionBtn.isDisabledDelete = false;
      });
    }
  }

  //----- UI Function -----//
  onDeleteBtnClicked = () => {
    let buttons = [
      {
        name: (JMLanguage.translate("global.delete")),
        handler: () => {
          this.requestDeleteDropdown()
        }
      },
      {name: (JMLanguage.translate("global.abort"))}
    ];
    this.showPopUpAlert("", "", buttons, 
      [
        JMLanguage.translate("pages.settings.dropdown.delete-confirm1", [this.dropdownAttrInput.key]), 
        JMLanguage.translate("pages.settings.dropdown.delete-confirm2")
      ]
    )
  }

  updateTranslateText(){
    this.dropdownNameListParam.hiddenContainerBtn.text = JMLanguage.translate("pages.settings.dropdown.button-create");
  }

  onKeyUpSearchInput = () => {
    this.searchDropdown.next();
  }

  onClickAddDropdown = () => {
    this.dropdownNameListParam.isHideHiddenContainer = !this.dropdownNameListParam.isHideHiddenContainer;

    if(this.dropdownNameListParam.isHideHiddenContainer){
      this.dropdownNameListParam.listHeaderBtn.icon = 'fa fa-plus blue';
      this.dropdownNameListParam.listHeaderBtn.customClass = '';
    }else{
      this.dropdownNameListParam.listHeaderBtn.icon = 'fa fa-plus white';
      this.dropdownNameListParam.listHeaderBtn.customClass = 'brand-blue px-2 py-0';
    }
  }

  onClickCreateDropdown = () => {
    if(this.dropdownNameList.indexOf(this.dropdownNameListInput.hiddenContainerInput) < 0){
      this.requestDropdownExist();
    }else{
      this.openSnackBar(JMLanguage.translate("pages.settings.dropdown.dropdown-duplicated", [this.dropdownNameListInput.hiddenContainerInput]));
    }
  }

  onClickUpdateDropdown = () => {
    this.dropdownAttrParam.showFieldsError = true;
    if(!this.dropdownAttrInput._id){
      this.requestCreateDropdown();
    }else{
      this.requestUpdateDropdown();
    }
  }

  onClickDropdownName(index?){
    this.dropdownAttrParam.onChangeBtn[0].disabled = false;
    this.selectedDropdownId = this.dropdownAttr[index]['_id'];
    this.dropdownAttrInput = this.dropdownAttr.find(dropdown => dropdown._id == this.selectedDropdownId);
    this.dropdownOptionList = this.dropdownAttrInput.options;
    this.dropdownOptionsParam.mappingType = this.dropdownAttrInput.mappingType;
    this.requestMappingType(this.dropdownAttrInput.mappingType);
    this.dropdownAttrElem.copyInputData(this.dropdownAttrInput);
    this.dropdownOptionsElem.closeInputForm();
  }

  onClickDiscardBtn = () => {
    this.dropdownAttrElem.discardForm();
  }

  onLoadDropdownList = (event) => {
    //if user reach bottom, load more work centre
    if(event.srcElement.offsetHeight + event.srcElement.scrollTop == event.srcElement.scrollHeight){
      this.pageNumber += 1;
      this.requestDropdownSummary();
    }
  }

  onChangeMappingType = () => {
    this.requestMappingType(this.dropdownAttrInput.mappingType);
    this.dropdownOptionsParam.mappingType = this.dropdownAttrInput.mappingType;
  }

  onClickAddOption = () => {
    this.dropdownOptionsElem.openInputForm();
    this.dropdownOptionsElem.changeFormActionCreate(OptionsSubmitType.Create);
  }

  onEditOptionRow = (index) => {
    this.dropdownAttrParam.selectedOptionIndex = index;
    this.dropdownOptionsElem.openInputForm();
    this.dropdownOptionsElem.editOption(index);
    this.dropdownOptionsElem.changeFormActionUpdate(OptionsSubmitType.Update);
  }

  onSubmitOptionRow = (userInput, formSubmitType) => {
    switch (formSubmitType){
      case OptionsSubmitType.Create:
        this.dropdownAttrInput.options.push(userInput);
        if(this.dropdownAttrInput._id){
          this.requestUpdateDropdown();
        }
        break;
      case OptionsSubmitType.Update: 
        this.dropdownAttrInput.options[this.dropdownAttrParam.selectedOptionIndex] = userInput;
        this.requestUpdateDropdown();
        break;
    }

  }

  onDeleteOptionRow = (index) => {
    let buttons = [
      {
        name: (JMLanguage.translate("global.yes")),
        handler: () => {
          if(this.dropdownAttrInput.options[index]['active'] == undefined){
            this.dropdownAttrInput.options.splice(index, 1);
          } else {
            this.dropdownAttrInput.options[index]['active'] = false;
            this.requestUpdateDropdown();
          }
        }
      },
      {name: (JMLanguage.translate("global.no"))}
    ];
    this.showPopUpAlert(JMLanguage.translate("pages.settings.dropdown.delete-option-alert"), "", buttons);
  }

  resetDropdownForm(){
    this.dropdownAttrInput = { mandatory: {} };
    this.dropdownAttrParam.showFieldsError = false;
  }

  //------ Init Component ------//
  initPermission(){
    this.checkViewPermission(JMENUM.Permission.DROPDOWN_SETTING_VIEW);
    this.hasEditPermission = this.authorizationService.hasPermission(JMENUM.Permission.DROPDOWN_SETTING_UPDATE);
  }

  initDropdownForm(dropdownCode){
    let newForm = {
      _id: undefined,
      key: dropdownCode,
      descriptionEn: '',
      descriptionZh: '',
      usageType:  this.dropdownAttrParam.fieldOptions.usageType[0].value,
      mappingType: this.dropdownAttrParam.fieldOptions.mappingType[0].value,
      mandatory: { mandatory: false },
      options: [],
    };
    this.dropdownAttr.unshift(newForm);
  }

  initDropdownNameListElem(){
    this.dropdownNameListParam.onScroll = this.onLoadDropdownList;
    this.dropdownNameListParam.headerInput = { 
      placeholder: JMLanguage.translate("global.search"),
      onKeyUp: this.onKeyUpSearchInput,
      onChange: () => {}
    };
    this.dropdownNameListParam.listHeaderBtn = {
      icon: 'fa fa-plus blue',
      onClick: this.onClickAddDropdown
    }
    this.dropdownNameListParam.hiddenContainerBtn = {
      customClass: 'brand-blue ml-xl-2 mt-2 mt-xl-0',
      onClick: this.onClickCreateDropdown
    }
    this.dropdownNameListParam.hiddenContainerInput = {
      customClass: 'w-auto col-xl col-12',
      placeholder: JMLanguage.translate("pages.settings.dropdown.new-dropdown-name"),
      onChange: () => {}
    }
    this.dropdownNameListParam.isHideHiddenContainer = true;
  }

  initAttrFormElem(){
    this.dropdownAttrParam.isEditMode = true;
    this.dropdownAttrParam.showFieldsError = false;

    this.dropdownAttrParam.header = {
      id: 'attrTitle',
      title: JMLanguage.translate("pages.settings.dropdown.attributes"),
      customClass: 'bold font-size-m',
      mandatory: true,
      onChange: () => {}
    };

    this.dropdownAttrParam.headerBtn = [
      { id: 'delete-btn', icon : 'fas fa-trash', disabled: !this.hasEditPermission, onClick: this.onDeleteBtnClicked },
    ];
    
    this.dropdownAttrParam.onChangeBtn = [
      { id: 'update-btn', customClass: 'brand-blue',  onClick: this.onClickUpdateDropdown, text: JMLanguage.translate('pages.setting.dropdown.button.update')},
      { id: 'discard-btn', icon: 'fas fa-times', onClick: this.onClickDiscardBtn }
    ];

    this.dropdownAttrParam.fieldOptions = {
      usageType: [ 
        { id: 'equipment-tags', key: 'equipmentTags', value: JMENUM.dropdownSourceType.EQUIPMENT_TAGS, label: JMLanguage.translate('pages.settings.dropdown.usage-type-options.equipment-tags') },
        { id: 'ha-equipment', key: 'haEquipment', value: JMENUM.dropdownSourceType.HA_EQUIPMENT, label: JMLanguage.translate('pages.settings.dropdown.usage-type-options.ha-equipment') },
        { id: 'others', key: 'others', value: JMENUM.dropdownSourceType.OTHERS, label: JMLanguage.translate('pages.settings.dropdown.usage-type-options.others') },
       
      ],
      mappingType: [ 
        { id: 'sla-with-items', key: 'slaWithItems', value: 'slaWithItems', label: '' },
        { id: 'ha-cause-code', key: 'haCauseCode', value: 'haCauseCode', label: '' },
        { id: 'ha-false-code', key: 'haFalseCode', value: 'haFalseCode', label: '' },
      ],
      mandatoryJobNature: [
        { id: 'CM', key: 'CM', value: JMENUM.JobNature.CM, label: JMLanguage.translate('jobcard.job-nature.CM') },
        { id: 'PM', key: 'PM', value: JMENUM.JobNature.PM, label: JMLanguage.translate('jobcard.job-nature.PM') },
      ]
    };

    this.dropdownAttrParam.fieldList = [
      { 
        id: 'descriptionEn', 
        type: InputFieldType.text, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: () => {}, 
        name: JMLanguage.translate('pages.settings.dropdown.form.description-en')
      },
      { 
        id: 'descriptionZh', 
        type: InputFieldType.text, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: () => {}, 
        name: JMLanguage.translate('pages.settings.dropdown.form.description-zh')
      },
      { 
        id: 'usageType', 
        type: InputFieldType.dropdown, 
        multiple: false, 
        titleCustomClass: 'bold',
        placeholder: '',
        showTip: true,
        tooltipMsg: JMLanguage.translate('pages.settings.dropdown.usage-type-tooltip'),
        onChange: () => {}, 
        onSearch: () => {},
        scrollToEnd: () => {},
        name: JMLanguage.translate('pages.settings.dropdown.form.usage-type'), 
      },
      { 
        id: 'mappingType', 
        type: InputFieldType.dropdown, 
        multiple: false, 
        titleCustomClass: 'bold',
        placeholder: '',
        showTip: true,
        tooltipMsg: JMLanguage.translate('pages.settings.dropdown.mapping-type-tooltip'),
        onChange: this.onChangeMappingType, 
        onSearch: () => {},
        scrollToEnd: () => {},
        name: JMLanguage.translate('pages.settings.dropdown.form.mapping-type'), 
      },
      {
        id: 'mandatoryJobNature', 
        type: InputFieldType.dropdown, 
        multiple: true, 
        titleCustomClass: 'bold',
        placeholder: '',
        onChange: () => {}, 
        onSearch: () => {},
        scrollToEnd: () => {},
        name: JMLanguage.translate('pages.settings.dropdown.form.mandatory'), 
      },
      { 
        id: 'active', 
        type: InputFieldType.hidden, 
        multiple: false, 
        titleCustomClass: 'bold',
        placeholder: '',
        showTip: false,
        onChange: this.onChangeMappingType, 
        onSearch: () => {},
        scrollToEnd: () => {}
      }
    ]
  }

  initOptionsFormElem(){
    this.dropdownOptionsParam.isEditMode = true;
    this.dropdownOptionsElem.closeInputForm();
    this.dropdownOptionsParam.showFieldsError = false;

    this.dropdownOptionsParam.header = {
      id: 'optionsTitle',
      title: JMLanguage.translate("pages.settings.dropdown.options"),
      customClass: 'bold font-size-m',
      mandatory: true,
      onChange: () => {}
    };

    this.dropdownOptionsParam.addRowBtn = {
      customClass: '',
      type: 'button',
      icon: 'fa fa-plus font-size-l blue',
      onClick: this.onClickAddOption
    }

    this.dropdownOptionsParam.optionBtn = {
      editBtn: true,
      onClickEdit: this.onEditOptionRow,
      deleteBtn: true,
      onClickDelete: this.onDeleteOptionRow,
      copyBtn: true,
      onClickCopy: this.onClickAddOption,
      submitBtn: true,
      onClickSubmit: this.onSubmitOptionRow,
    }
  }

}
