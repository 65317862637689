<ng-container *ngIf="tablexParam.content && tablexParam.content.length > 0">
  <div class="font-size-xl m-0 mb-2">
    <div class="overflow-hidden">
      <div class="d-inline-block">
        {{ 'component.pm-job-ccs-information.title' | translate }}
      </div>
    </div>
  </div>

  <hr class="underline mb-0" />

  <app-tablex id="ccs-so-table" [tablexParam]="tablexParam"></app-tablex>
</ng-container>
