import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { JMOBJ, JMENUM } from '@ccep/CCEPConnector-ts';

import { JobcardJobDescriptionComponent } from 'src/app/ui/components/jobcard-job-description/jobcard-job-description.component';
import { JobcardTaskProgressComponent } from 'src/app/ui/components/jobcard-task-progress/jobcard-task-progress.component';

@Component({
  selector: 'app-job-card-standalone-view',
  templateUrl: './job-card-standalone-view.component.html',
  styleUrls: ['./job-card-standalone-view.component.scss']
})
export class JobCardStandaloneViewComponent implements OnInit {
  @Input() jobCard: JMOBJ.JobCard;
  @Input() componentParameters;

  @ViewChild(JobcardJobDescriptionComponent, { static: false }) jobDesciptionElem:JobcardJobDescriptionComponent;
  @ViewChild(JobcardTaskProgressComponent, { static: false }) jobTaskProgressElem: JobcardTaskProgressComponent;

  JMPage = JMENUM.JMPage;
  pageMode = JMENUM.JMPageMode;

  constructor() { }

  ngOnInit() {
  }

  validation(): boolean {
    return this.jobDesciptionElem.validation() && this.jobTaskProgressElem.validation();
  }
}
