import { SliderPanelRowType } from '@enum/slider-panel-row-type';

export class SliderPanelHelper {

    static createSliderPanelGroups(datas: any[], clearValues = true): any[] {
        if (!clearValues) return datas;
        let groups = [];
        for (let data of datas) {
            if (data.type == "dropDown") {
                data.value = null;
            }
            groups.push(data);
        }
        return groups;
    }

    // options:  array of { description: description, value: id }
    static createDropDown(id: string, options: any[] = [], description?: string, placeholder?: string, onChange?:Function, isMultiple?:boolean, enableMultipleTypeToSearch?:boolean, remarks?: string[], onFocus?: Function): any {
        let dropDown = {
            id: id,
            options: options,
            type: SliderPanelRowType.DropDown.valueOf(),
            value: (options && options.length>0)?options[0].value:null,
            enable: true,
            isMultiple : isMultiple ? isMultiple : false,
            enableMultipleTypeToSearch : isMultiple ? (enableMultipleTypeToSearch ? enableMultipleTypeToSearch : false) : false,
        }

        if (description) {
            dropDown["description"] = description;
        }
        if (placeholder) {
            dropDown["placeholder"] = placeholder;
        }
        
        dropDown["onChange"] = onChange?onChange:function() {};
        dropDown["onFocus"] = onFocus?onFocus:function() {};
        if (remarks) {
            dropDown["remarks"] = remarks;
        }
        
        return dropDown;
    }

    static createOption(data: any): any {
        let option = {
            description: data.description,
            value: data.value,
            enable: true
        }

        return option;

    }

    static createButton(id: string, callback: any, icon?: string, css?: string, description?: string, titleLabel?: string, isLoading: boolean=false): any {
        let button = {
            id: id,
            type: SliderPanelRowType.Button.valueOf(),
            callback: callback,
            isLoading: isLoading,
            enable: true
            // relatedGroupsCompleted: (requireCompletedGroupIds ? false : true)
        }

        if (description)    button["description"] = description;
        if (titleLabel)     button["titleLabel"] = titleLabel;
        if (icon)           button["icon"] = icon;
        if (css)            button["class"] = css;
        // if (requireCompletedGroupIds) button["requireCompletedGroupIds"] = requireCompletedGroupIds;

        return button;
    }

    static createInput(id: string, description: string, validators: any[] = [], callback?: any, value: string = '', maxlength: number = 10000): any {
        let input = {
            id: id,
            description: description,
            type: SliderPanelRowType.Input.valueOf(),
            enable: true,
            value: value,
            maxlength: maxlength
        }

        input["validators"] = validators;
        if (callback) input["callback"] = callback;

        return input;
    }

    static createText(id: string, description: string, style?: string): any {
        let text = {
            description: description,
            id: id,
            type: SliderPanelRowType.Text,
            enable: true
        }

        text["css"] = style;

        return text;
    }

    static createRadioButton(id: string, description: string, options: any[], group: string, selected: string ): any {
        let radioButton = {
            description: description,
            options: options,
            id: id,
            group: group,
            selected: selected,
            type: SliderPanelRowType.RadioButton,
            enable: true
        }

        return radioButton;
    }

    static createTextArea(id: string, rows: number, columns: number, placeholder?: string, style?: string, isDisabled?: boolean, vaule?: string): any {
        let textArea = {
            id: id,
            rows: rows,
            columns: columns,
            placeholder: placeholder,
            type: SliderPanelRowType.TextArea,
            isDisabled: isDisabled,
            value: vaule,
            enable: true
        }

        if (style) textArea["css"] = style;

        return textArea;
    }

    static createCheckbox(id: string, description: string, isDisabled: boolean = false, style?: string): any {
        let checkbox = {
            description: description,
            id: id,
            type: SliderPanelRowType.Checkbox,
            isDisabled: isDisabled,
            css: style
        }

        return checkbox;
    }

    static createFieldsetCheckbox(id: string, description: string, isDisabled: boolean = false, inputPlaceholder: string = '', inputValue: string = ''): any {
        let checkbox = {
            description: description,
            id: id,
            type: SliderPanelRowType.FieldsetCheckbox,
            isDisabled: isDisabled,
            inputPlaceholder: inputPlaceholder,
            inputValue: inputValue
        }

        return checkbox;
    }

    static createInformationRowObject(id: string, description: string, value: string) {
        let informationRow = {
            id: id,
            description: description,
            value: value,
            type: SliderPanelRowType.InformationRow,
            enable: true
        }

        return informationRow;
    }

    static createExpandingGroup(id: string, description: string, value: any, cells: any[], checked: boolean = false) {
        let expandingGroup = {
            id: id,
            description: description,
            cells: cells,
            value: value,
            type: SliderPanelRowType.Expanding,
            checked: checked,
            enable: true
        }

        return expandingGroup;
    }

    static createExpandingGroupCell(id: string, description: string, value: any, checked: boolean = false, button?:any) {
        let cell = {
            id: id,
            description: description,
            value: value,
            checked: checked,
            enable: true
        }

        if(button) cell["button"] = button;

        return cell;
    }

    static creatIconButonGroup(buttons: any[]) {
        let group = {
            buttons: buttons,
            type: SliderPanelRowType.ImportDataButton,
            enable: true
        }

        return group;
    }

    static creatImportDataButon(id: string, description, icon: string, handler: any, acceptType:string = "") {
        let importDataButton = {
            id: id,
            description: description,
            icon: icon,
            handler: handler,
            acceptType:acceptType,
            enable: true
        }

        return importDataButton;
    }

    static createUnderline(id: string): any {
        let underline = {
            id: id,
            type: SliderPanelRowType.Underline.valueOf(),
            enable: true
        }
        return underline;
    }

    static toggleSliderPanel(event?:any) {
        if(event){
            event.stopPropagation();
        }
        let sliderPanel = document.getElementById("slider-panel");
        if (sliderPanel.classList.contains("active")) {
            sliderPanel.classList.remove("active");
        } else {
            sliderPanel.classList.add("active");
        }
    }

    static showSliderPanel(event?:any) {
        if(event){
            event.stopPropagation();
        }
        let sliderPanel = document.getElementById("slider-panel");
        if (!sliderPanel.classList.contains("active")) {
            sliderPanel.classList.add("active");
        }
    }

    static closeSliderPanel(event?:any) {
        if(event){
            event.stopPropagation();
        }
        let sliderPanel = document.getElementById("slider-panel");
        if (sliderPanel.classList.contains("active")) {
            sliderPanel.classList.remove("active");
        }
    }

    static enableSlidePanelInput(sliderPanelGroups: any, enable: boolean, element): void {
        for (let group of sliderPanelGroups) {
            group['enable'] = enable;
            if (group['id'] == element) {
                group['isLoading'] = !enable;
            }
        }
    }

}