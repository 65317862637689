<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'team'"></app-sidebar>

  <div id="content">
    <!-- <div class="container"> -->
      <div class="row">
        <div class="col-md-3">
          <team-selector #inhouseTeamSelector 
            [mode]="'inhouse'"
            [workCentre]="selectedWorkCentre"
            (onTeamChanged)="onTeamChanged($event)"
          ></team-selector>
          <team-selector #contractorTeamSelector 
            [mode]="'contractor'"
            [workCentre]="selectedWorkCentre"
            (onTeamChanged)="onTeamChanged($event)"
          ></team-selector>
        </div>

        <div class="col-md">
          <ng-container *ngIf="isLoadingTeams; else contentDetail">
            <div class="row mt-5">
              <div class="col d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">{{"global.loading" | translate}}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #contentDetail>
            <div class="row">
              <div class="col m-3">
                <ng-container *ngIf="!selectedTeam; else teamDetail">
                  <div class="row mt-5">
                    <div class="col d-flex justify-content-center h5">
                      {{ 'pages.team-setting.team-not-found' | translate}}
                    </div>
                  </div>
                </ng-container>
                <ng-template #teamDetail>
                  <div *ngIf="hasEditionPermission && !isPmsmc(selectedTeam.handlingParty)" class="row">
                    <div class="col">
                      <button type="button" class="rectangle brand-blue float-right" (click)="onAddMemberClicked()"
                        id="team-member_in-house-add-member_button">
                        <i class="fas fa-user-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="row" *ngIf="isInhouse(selectedTeam.handlingParty)">
                    <div class="col-12">
                      <app-tablex #id_inhouse_member_table [tablexParam]='inhouseTablexParam' class='mt-3'></app-tablex>
                    </div>
                  </div>
                  <div class="row" *ngIf="isNonPmsmc(selectedTeam.handlingParty)">
                    <div class="col-12">
                      <app-tablex #id_nonpmsmc_member_table [tablexParam]='nonPmsmcTablexParam' class='mt-3'></app-tablex>
                    </div>
                  </div>
                  <div class="row" *ngIf="isPmsmc(selectedTeam.handlingParty)">
                    <div class="col-12">
                      <app-tablex #id_pmsmc_member_table [tablexParam]='pmsmcTablexParam' class='mt-3'></app-tablex>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
          
        </div>
      </div>
  </div>
</div>

<custom-slider-panel #addTeamMemberSliderPanel [widthPx]=600 [customClass]="'addTeamMemberSliderPanel'">
  <add-team-member-form #addTeamMemberForm [parameters]="addMemberFormParameter"></add-team-member-form>
</custom-slider-panel>

<custom-slider-panel #addContractorTeamMemberSliderPanel [widthPx]=600 [customClass]="'addContractorTeamMemberSliderPanel'">
  <add-contractor-team-member-form #addContractorTeamMemberForm [parameters]="addContractorTeamMemberFormParameter"></add-contractor-team-member-form>
</custom-slider-panel>