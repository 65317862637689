import { Component, Injector, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { BasePage } from 'src/app/ui/model/base/base';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { InputFieldType } from 'src/lib/ui-component/custom-fields-form/input-field-type.enum';
import { EquipmentTagService } from '../../services/equipment-tag.service';
@Component({
  selector: 'batch-edit-ha-equipment-form',
  templateUrl: './batch-edit-ha-equipment-form.html',
  styleUrls: ['./batch-edit-ha-equipment-form.scss']
})
export class BatchEditHAEquipmentFormComponent extends BasePage implements OnInit {
  
  
  // Button / Loading Status
  isDisabledBatchAddBtn: boolean = false;
  isLoadingBatchAddBtn: boolean = false;
  isDisabledBatchRemoveBtn: boolean = false;
  isLoadingBatchRemoveBtn: boolean = false;
  
  

  // Config & data of batch_edit_tags_form
  batchEditTagsFormParam: any = {};
  batchEditTagsFormInput: any = {};

  // data object
  equipmenTagList: JMOBJ.HashTag[] = [];
  equipmentList:JMOBJ.HSDEquipment[] = [];
  equipmentFilter:{} = {};
  hasHashtagParam:boolean = undefined;
  hasCCSEquipmentParam:boolean = undefined;
  assetNumberSearchList:string[] = []; 
  userTagGroup:JMOBJ.HashTagGroup[] = [];

  // callbackk
  onSave:Function = null;
  
  mode: BatchEditHaEquipmentFormComponentModes;
  haCluster: string;

  constructor(
    injector: Injector,
    private equipmentTagService: EquipmentTagService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.batchEditTagsFormParam.isEditMode = true;
    this.batchEditTagsFormParam.showFieldsError = false;
    this.batchEditTagsFormParam.fieldOptions = {};
    this.batchEditTagsFormParam.displayAllAsRow = true;
    this.batchEditTagsFormParam.fieldList = [];
  }

  ngOnDestroy() { 
  }

  ngAfterViewInit() {
  }

  onLanguageChanged() {
  }

  
  initBatchEditTagsFormElem(){
    if (this.isAddMode) {
      this.batchEditTagsFormParam.fieldOptions = {
        range: [
          { id: 'all-in-this-page', key: 'all-in-this-page', value: 'all-in-this-page', label: JMLanguage.translate('pages.ha-equipment.all-in-this-page') },
          { id: 'filtered', key: 'filtered', value: 'filtered', label: JMLanguage.translate('pages.ha-equipment.filtered') },
        ]
      };
    } else if (this.isRemoveMode) {
      this.batchEditTagsFormParam.fieldOptions = {
        range: [
          { id: 'all-in-this-page', key: 'all-in-this-page', value: 'all-in-this-page', label: JMLanguage.translate('pages.ha-equipment.all-in-this-page') },
          { id: 'filtered', key: 'filtered', value: 'filtered', label: JMLanguage.translate('pages.ha-equipment.filtered') },
          { id: 'all-equipment', key: 'all-equipment', value: 'all-equipment', label: JMLanguage.translate('pages.ha-equipment.all-equipment') }
        ]
      };
    }

    this.batchEditTagsFormParam.fieldList = [
      {
        id: 'equipmentTag',
        name: JMLanguage.translate("pages.ha-equipment-list.table-column.equipment-tag"),
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        template: true,
        onChange: this.onBatchEquipmentTagsChanged, 
        onSearch: () => {},
        scrollToEnd: () => {},
      },
      {
        id: 'range',
        name: '',
        type: InputFieldType.radio, 
        placeholder: '',
        mandatory: true,
        customClass: 'd-block mr-0 mb-2',
        onChange: this.onBatchEditRangeChanged
      }
    ];

    if (Session.selectedWorkCentre
      || Session.workCentreDict[Session.selectedWorkCentre] 
      || Session.workCentreDict[Session.selectedWorkCentre].HACluster
    ) {
      this.haCluster = Session.workCentreDict[Session.selectedWorkCentre].HACluster
    }

    this.requestTags();
  }

  // ----------- setter function ----------- //
  reset(){
    this.batchEditTagsFormParam.showFieldsError = false;
    this.batchEditTagsFormInput = {};
  }

  validate() {
    if(this.batchEditTagsFormInput.equipmentTag && this.batchEditTagsFormInput.range){
      this.batchEditTagsFormParam.showFieldsError = false;
      return true;
    }else{
      this.batchEditTagsFormParam.showFieldsError = true;
      return false;
    }
  }

  // ----------- API ----------- //
  private async requestTags() {
    if (this.isAddMode) {
      this.requestWorkCentreHashtags();
    } else if (this.isRemoveMode) {
      this.requestHaClusterAssignedHashtag();
    }
  }

  private async requestWorkCentreHashtags() {
    if (!Session.selectedWorkCentre) {
      return;
    }

    let request = new JM.JMRequestWorkCentresHashtags();
    request.equipmentSource = JMENUM.EquipmentSource.HA;
    request.workCentre = Session.selectedWorkCentre;

    const response: JM.JMResponseWorkCentresHashtags = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.equipmenTagList = response.payload;
    this.batchEditTagsFormInput.equipmentTag = null;
    this.parseEquipmentListToTag();
  }

  private async requestHaClusterAssignedHashtag() {
    if (!this.haCluster) {
      return;
    }

    let request = new JM.JMRequestEquipmentsGetHAClusterAssignedHashtag();
    request.HACluster = Session.workCentreDict[Session.selectedWorkCentre].HACluster;

    const response: JM.JMResponseEquipmentsGetHAClusterAssignedHashtag = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.equipmenTagList = response.payload.records;
    this.batchEditTagsFormInput.equipmentTag = null;
    this.parseEquipmentListToTag();
  }

  private async requestBatchRemoveTags() {
    let request = new JM.JMRequestEquipmentsRemoveHAHashtags();
    request.hashtagId = this.batchEditTagsFormInput.equipmentTag;
    request.HACluster = this.haCluster;
    
    if (this.batchEditTagsFormInput.range == 'all-in-this-page') {
      request.assetNumberList = this.equipmentList.map(e => e.assetNumber);
    } else if (this.batchEditTagsFormInput.range == 'filtered') {
      request.filter = this.equipmentFilter;
      if(this.assetNumberSearchList && this.assetNumberSearchList.length){
        request.assetNumberList = this.assetNumberSearchList
      } // has HashTag
      if(this.hasHashtagParam !== undefined){
        request.hasHashtag = this.hasHashtagParam
      } // has CCS Equipment
      if(this.hasCCSEquipmentParam !== undefined){
        request.hasCCSEquipment = this.hasCCSEquipmentParam
      }
    } else if (this.batchEditTagsFormInput.range == 'all-equipment') {
      // no need input parameter
    }

    this.isDisabledBatchAddBtn = true;
    this.isLoadingBatchRemoveBtn = true;
    const response: JM.JMResponseEquipmentsRemoveHAHashtags = await AppDelegate.sendJMRequest(request);
    this.isDisabledBatchAddBtn = false;
    this.isLoadingBatchRemoveBtn = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate('global.removed'));
    if (this.onSave) {
      this.onSave();
    }
  }

  private async requestBatchAddTags() {
    let request = new JM.JMRequestEquipmentsAddHAHashtags();
    request.hashtagId = this.batchEditTagsFormInput.equipmentTag;
    request.HACluster = this.haCluster;
    
    if (this.batchEditTagsFormInput.range == 'all-in-this-page') {
      request.assetNumberList = this.equipmentList.map(e => e.assetNumber);
    } else if (this.batchEditTagsFormInput.range == 'filtered') {
        // General Search filter
         request.filter = this.equipmentFilter;
        // Multiple Asset Number
        if(this.assetNumberSearchList && this.assetNumberSearchList.length){
            request.assetNumberList = this.assetNumberSearchList
        } // has HashTag
        if(this.hasHashtagParam !== undefined){
            request.hasHashtag = this.hasHashtagParam
        } // has CCS Equipment
        if(this.hasCCSEquipmentParam !== undefined){
            request.hasCCSEquipment = this.hasCCSEquipmentParam
        }
    } else {
      // not allow another range
      return;
    }

    this.isDisabledBatchAddBtn = true;
    this.isLoadingBatchRemoveBtn = true;
    const response: JM.JMResponseEquipmentsAddHAHashtags = await AppDelegate.sendJMRequest(request);
    this.isDisabledBatchAddBtn = false;
    this.isLoadingBatchRemoveBtn = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate('global.added'));
    if (this.onSave) {
      this.onSave();
    }
  }

  private parseEquipmentListToTag() {
    if (this.equipmenTagList.length > 0) {
      // map Batch edit all Cat & Type tags
      this.batchEditTagsFormParam.fieldOptions['equipmentTag'] = this.equipmenTagList.map((tag) => {
        return {
          value: tag._id,
          label: this.equipmentTagService.getTagDescription(tag),
        };
      });
    } else {
      this.batchEditTagsFormParam.fieldOptions['equipmentTag'] = [];
    }
  }
  
  // ----------- Button function ----------- //
  onBatchEquipmentTagsChanged = () => {
    // console.log("onBatchEquipmentTagsChanged");
    // if(this.batchEditTagsFormInput.equipmentTag && this.batchEditTagsFormInput.equipmentTag.length <= 0){
    //   delete this.batchEditTagsFormInput.equipmentTag
    // }
    // this.checkEnableBatchEditBtn();
  }

  onBatchEditRangeChanged = () => {
    // this.checkEnableBatchEditBtn();
  }

  onClickAddTags = () => {
    if (this.validate()) {
      this.requestBatchAddTags();
    }
  }

  onClickRemoveTags = () => {
    if(!this.validate()){
      AppDelegate.openSnackBar(JMLanguage.translate("pages.ha-equipment-list.please-fill-in-mandatory-fields"));
    }else{
      let buttons = [
        {
          name: JMLanguage.translate("global.remove"),
          handler: () => {
            this.requestBatchRemoveTags();
          }
        }, 
        {
          name: (JMLanguage.translate("global.cancel")),
          handler: () => {}
        }
      ];
      AppDelegate.showPopUpAlert(JMLanguage.translate("pages.ha-equipment.ask-confirm-remove-tag", 
      ['"' + JMLanguage.translate('pages.ha-equipment.' + this.batchEditTagsFormInput.range) + '"']), 
      "", buttons);
    }
  }
  
  //------------------------
  // get property
  get isAddMode(): boolean { return this.mode === BatchEditHaEquipmentFormComponentModes.ADD; }
  get isRemoveMode(): boolean { return this.mode === BatchEditHaEquipmentFormComponentModes.REMOVE; }
}

export enum BatchEditHaEquipmentFormComponentModes {
  ADD,
  REMOVE
}
