import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';
import { InputFieldType } from 'src/lib/ui-component/custom-fields-form/input-field-type.enum';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { Constants } from 'src/constants';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WorkCentreSource } from '@enum/work-centre-source.enum';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-settings-work-centres',
  templateUrl: './settings-work-centres.component.html',
  styleUrls: ['./settings-work-centres.component.scss']
})
export class SettingsWorkCentresComponent extends BasePage implements OnInit {
  @ViewChild('work_centre_list', { static: true }) workCentreListElem;
  @ViewChild('work_centre_form', { static: true }) workCentreFormElem;

  hasEditPermission: boolean;

  // component config & data
  workCentreListParam: any = {};
  workCentreListInput: any = {};
  workCentreFormParam: any = {};
  workCentreFormInput: any = {}; // same as selected work centre data
  workCentreList: any = [];

  selectedWorkCentreCode: string;
  selectedWorkCentreIndex: number = 0;
  workCentreData: any;

  // workCentre List
  pageNumber: number = 1;
  pageSize: number = 100;
  
  private searchWorkCentre = new Subject<any[]>();

  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.selectedWorkCentreCode = Session.selectedWorkCentre;
  }

  ngOnInit() {
    this.initPermission();
    this.requestWorkCentresSummary();
    this.requestHAClusterSummary();
    this.onLangChange(); //translate service bug

    this.searchWorkCentre.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
    ).subscribe(() => {
      this.pageNumber = 1;
      this.requestWorkCentresSummary();
    });
    
  }

  onLanguageChanged() {
    
  }

  onLangChange(){
    this.initFieldsFormElem();
    this.initWorkCentreListElem();
    this.workCentreFormElem.copyInputData(this.workCentreFormInput);
    this.updateTranslateText();
  }


  //----- API -----//
  async requestHAClusterSummary(){
    const request = new JM.JMRequestSystemConfigsHAClusterSummary();

    const response:JM.JMResponseSystemConfigsHAClusterSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.workCentreFormParam.fieldOptions.haCluster = response.payload.records.map(data => {
      let obj =  {
        id: data.clusterName,
        value: data.clusterName,
        key: data.clusterName,
        label: data.clusterName
      }
      return obj;
    });

    this.workCentreFormParam.fieldOptions.haCluster.unshift({
      id: 'not-applicable', 
      value: 'not-applicable', 
      key: 'notApplicable', 
      label: JMLanguage.translate('pages.settings.work-centres.ha-cluster-options.not-applicable')
    });
  }

  async requestWorkCentreExist(){
    const request = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.workCentreListInput.hiddenContainerInput];

    const response:JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records.length <= 0) {
      this.workCentreList.unshift(this.workCentreListInput.hiddenContainerInput);
      this.initWorkCentreForm(this.workCentreListInput.hiddenContainerInput);
      this.workCentreListElem.onClickListItem(0);
      this.onClickUpdateWorkCentre(); // create workcentre automatically
      this.enableEditMode();
      this.workCentreListInput.hiddenContainerInput = undefined;
      this.onClickAddWorkCentre();
    } else {
      this.openSnackBar(JMLanguage.translate("popupError.13014.title"));
    }
  }

  async requestWorkCentresSummary(deletedRecord?){
    const request = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.pageNumber = this.pageNumber;
    request.pageSize = this.pageSize;
    request.includeSummary = true;
    request.active = JMENUM.RequestActive.BOTH;

    if (this.workCentreListInput.headerInput) {
      request.filter = {
        workCentreCode: this.workCentreListInput.headerInput
      };
    }

    const response:JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records.length <= 0) {
      if (deletedRecord) {
        this.workCentreList = [];
        this.disableFormBtn();
        this.requestWorkCentresSummary();
      } else {
        this.openSnackBar(JMLanguage.translate("global.no-record-found"));
      }
      return;
    } else {
      const workCentreData = response.payload.records.map(workCentre => {
        const haCluster = workCentre['HACluster'] ? workCentre['HACluster'] : 'not-applicable';
        const data = {
          _id: workCentre['_id'],
          workCentreCode: workCentre['workCentreCode'],
          description: workCentre['description'],
          costCentre: workCentre['costCentre'],
          source: workCentre['source'],
          haCluster: haCluster,
          parameters: {
            active: workCentre['isActive'], 
            canObtainSo: workCentre['enableObtainSO'],
            requiredObtainSo: workCentre['requiredObtainSO'],
            timesheet: workCentre['enableTimesheet'],
            haEquipment: workCentre['enableHAEquipment']
          }
        };
        return data;
      });

      if (this.pageNumber == 1) {
        this.workCentreData = workCentreData;
        this.workCentreList = this.workCentreData.map(workCentre => workCentre.workCentreCode);
        this.workCentreFormInput = this.workCentreData.find(workCentre => workCentre.workCentreCode == this.selectedWorkCentreCode);
        this.workCentreListElem.onClickListItem(this.selectedWorkCentreIndex);  
      } else {
        this.workCentreData = this.workCentreData.concat(workCentreData);
        this.workCentreList = this.workCentreList.concat(this.workCentreData.map(workCentre => workCentre.workCentreCode));
      }
    }
  }

  async requestUpdateWorkCentre(){
    if(!this.validateWorkCentreForm()) return;

    const request = new JM.JMRequestWorkCentresUpdateWorkCentreSetting();
    request.code = this.workCentreFormInput.workCentreCode;
    request.description = this.workCentreFormInput.description;
    request.costCentre = this.workCentreFormInput.costCentre;
    request.source = this.workCentreFormInput.source.toUpperCase();
    request.HACluster = this.workCentreFormInput.haCluster == 'not-applicable' ? null : this.workCentreFormInput.haCluster;
    request.isActive = this.workCentreFormInput.parameters.active;
    request.enableTimesheet = this.workCentreFormInput.parameters.timesheet;
    request.enableHAEquipment = this.workCentreFormInput.parameters.haEquipment;
    request.enableObtainSO = this.workCentreFormInput.parameters.canObtainSo;
    request.requiredObtainSO = this.workCentreFormInput.parameters.requiredObtainSo;
    
    this.workCentreFormParam.onChangeBtn[0].disabled = true;
    const response:JM.JMResponseWorkCentresUpdateWorkCentreSetting = await AppDelegate.sendJMRequest(request);
    this.workCentreFormParam.onChangeBtn[0].disabled = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.openSnackBar(JMLanguage.translate("pages.settings.work-centres.work-centre-updated"));
    this.workCentreFormElem.copyInputData(this.workCentreFormInput);
  }

  async requestCreateWorkCentre(){
    if(!this.validateWorkCentreForm()) return;

    const request = new JM.JMRequestWorkCentresCreateWorkCentre();
    request.code = this.workCentreFormInput.workCentreCode;
    request.description = this.workCentreFormInput.description;
    request.costCentre = (this.workCentreFormInput.costCentre)?this.workCentreFormInput.costCentre:undefined;
    request.source = this.workCentreFormInput.source.toUpperCase();
    if(this.workCentreFormInput.haCluster == 'not-applicable'){
      this.workCentreFormInput.haCluster = null;
    }
    request.HACluster = this.workCentreFormInput.haCluster;
    request.isActive = this.workCentreFormInput.parameters.active;
    request.enableTimesheet = this.workCentreFormInput.parameters.timesheet;
    request.enableHAEquipment = this.workCentreFormInput.parameters.haEquipment;
    request.enableObtainSO = this.workCentreFormInput.parameters.canObtainSo;
    request.requiredObtainSO = this.workCentreFormInput.parameters.requiredObtainSo;

    this.workCentreFormParam.onChangeBtn[0].disabled = true;
    const response:JM.JMResponseWorkCentresCreateWorkCentre = await AppDelegate.sendJMRequest(request);
    this.workCentreFormParam.onChangeBtn[0].disabled = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.selectedWorkCentreIndex = 0;
    this.workCentreListInput.headerInput = '';
    //this.requestWorkCentresSummary();
    this.workCentreFormInput._id = response.payload["_id"];
    this.workCentreFormElem.copyInputData(this.workCentreFormInput);
    this.openSnackBar(JMLanguage.translate("pages.settings.work-centres.work-centre-created"));
    this.enableEditMode();
  }

  async requestDeleteWorkCentre(){
    if (!this.workCentreFormInput._id) {
      this.workCentreList.splice(0,1);
      this.resetWorkCentreForm();
      this.onClickWorkCentre(0);
    } else {
      const request = new JM.JMRequestWorkCentresDeleteWorkCentre();
      request._id = [this.workCentreFormInput._id];

      const response:JM.JMResponseWorkCentresDeleteWorkCentre = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if(response.payload.deletedCount > 0){
        this.selectedWorkCentreIndex = 0;
        this.requestWorkCentresSummary(true);
        this.resetWorkCentreForm();
        this.openSnackBar(JMLanguage.translate("pages.settings.work-centres.work-centre-deleted"));
      }
    }
  }


  //----- UI Function -----//
  onDeleteBtnClicked = () => {
    let buttons = [
      {
        name: (JMLanguage.translate("global.remove")),
        handler: () => {
          this.requestDeleteWorkCentre();
        }
      },
      {name: (JMLanguage.translate("global.back"))}
    ];
    this.showPopUpAlert(JMLanguage.translate("pages.settings.work-centres.delete-work-centre-alert", [this.selectedWorkCentreCode]), "", buttons);
  }

  updateTranslateText(){
    this.workCentreListParam.hiddenContainerBtn.text = JMLanguage.translate("pages.settings.work-centres.button-create");
  }

  onKeyUpSearchInput = () => {
    this.searchWorkCentre.next();
  }

  onClickAddWorkCentre = () => {
    this.workCentreListParam.isHideHiddenContainer = !this.workCentreListParam.isHideHiddenContainer;

    if(this.workCentreListParam.isHideHiddenContainer){
      this.workCentreListParam.listHeaderBtn.icon = 'fa fa-plus blue';
      this.workCentreListParam.listHeaderBtn.customClass = '';
    }else{
      this.workCentreListParam.listHeaderBtn.icon = 'fa fa-plus white';
      this.workCentreListParam.listHeaderBtn.customClass = 'brand-blue px-2 py-0';
    }
  }

  onClickCreateWorkCentre = () => {
    if (!this.workCentreListInput.hiddenContainerInput) { 
      this.openSnackBar(JMLanguage.translate("pages.settings.work-centres.please-enter-work-centre-code"));
      return;
    } else {
      this.workCentreListInput.hiddenContainerInput = this.workCentreListInput.hiddenContainerInput.toUpperCase();
      if (this.workCentreList.indexOf(this.workCentreListInput.hiddenContainerInput) < 0) {
        this.requestWorkCentreExist();
        return;
      } else {
        this.openSnackBar(JMLanguage.translate("popupError.13014.title"));
        return;
      }
    }
  }

  onClickUpdateWorkCentre = () => {
    if(!this.workCentreFormInput._id){
      this.requestCreateWorkCentre();
    }else{
      this.requestUpdateWorkCentre();
    }
  }

  onClickWorkCentre(index?){
    this.enableFormBtn();
    this.workCentreFormParam.onChangeBtn[0].disabled = false;
    this.workCentreFormElem.discardForm();
    this.workCentreFormParam.isEditMode = false;
    this.workCentreFormParam.showFieldsError = false;
    this.selectedWorkCentreCode = this.workCentreList[index];
    this.workCentreFormInput = this.workCentreData.find(workCentre => workCentre.workCentreCode == this.selectedWorkCentreCode);
    if(this.workCentreFormInput.haCluster == undefined || this.workCentreFormInput.haCluster == null){
      this.workCentreFormInput.haCluster = this.workCentreFormParam.fieldOptions.haCluster[0].value;
    }
    this.enableCCSFields(this.workCentreFormInput.source == WorkCentreSource.CCS);
    this.workCentreFormElem.copyInputData(this.workCentreFormInput);
  }

  onChangeParameters = () => {
    if(this.workCentreFormInput.parameters['requiredObtainSo'] && !this.workCentreFormInput.parameters['canObtainSo']){
      setTimeout(() => {
        this.workCentreFormInput.parameters['canObtainSo'] = true;
        this.openSnackBar(JMLanguage.translate("pages.settings.work-centres.alert-can-obtain-so-missing"));
      }, 200);
    }
  }

  enableCCSFields(isCCS?){
    if(isCCS){
      // this.workCentreFormParam.header.disabled = true; //Work Centre name
      this.workCentreFormParam.headerBtn.find(btn => btn.id == 'delete-btn').disabled = true;
      this.workCentreFormParam.fieldList.find(field => field.id == 'description').disabled = true;
      this.workCentreFormParam.fieldList.find(field => field.id == 'costCentre').disabled = true;
    }else{
      // this.workCentreFormParam.header.disabled = false;
      this.workCentreFormParam.headerBtn.find(btn => btn.id == 'delete-btn').disabled = false;
      this.workCentreFormParam.fieldList.find(field => field.id == 'description').disabled = false;
      this.workCentreFormParam.fieldList.find(field => field.id == 'costCentre').disabled = false;
    }
  }

  onClickDiscardBtn = () => {
    this.workCentreFormElem.discardForm();
  }

  enableEditMode = () => {
    this.workCentreFormParam.isEditMode = true;
  }

  onLoadWorkCentreList = (event) => {
    //if user reach bottom, load more work centre
    if(event.srcElement.offsetHeight + event.srcElement.scrollTop >= event.srcElement.scrollHeight){
      this.pageNumber += 1;
      this.requestWorkCentresSummary();
    }
  }

  validateWorkCentreForm(){
    if(this.workCentreFormInput.parameters && this.workCentreFormInput.parameters['haEquipment'] && this.workCentreFormInput.haCluster == 'not-applicable' ){
      this.workCentreFormParam.fieldList.find(field => field.id == 'haCluster').isError = true;
      this.openSnackBar(JMLanguage.translate("pages.settings.work-centres.alert-ha-cluster-null"));
      this.workCentreFormParam.showFieldsError = true;
      return false;
    }else if(this.workCentreFormInput.parameters && !this.workCentreFormInput.parameters['haEquipment']){
      this.workCentreFormParam.fieldList.find(field => field.id == 'haCluster').isError = false;
    }
    if(this.workCentreFormInput.parameters && this.workCentreFormInput.parameters['canObtainSo']){
      if(!this.workCentreFormInput.parameters['requiredObtainSo']){
        this.openSnackBar(JMLanguage.translate("pages.settings.work-centres.alert-require-obtain-so-missing"));
      }
    }

    let costCentreCheck =  (this.workCentreFormInput.parameters['timesheet'])?this.workCentreFormInput.costCentre:true;
    if(this.workCentreFormInput.workCentreCode 
      && this.workCentreFormInput.description 
      && costCentreCheck 
      && this.workCentreFormInput.source
    ){
      return true;
    }else{
      this.openSnackBar(JMLanguage.translate("pages.settings.work-centres.fill-in-all-the-mandatory-fields"));
      this.workCentreFormParam.showFieldsError = true;
      return false;
    }
  }

  resetWorkCentreForm(){
    this.workCentreFormInput = {};
  }

  disableFormBtn(){
    this.workCentreFormParam.headerBtn[0].disabled = true;
    this.workCentreFormParam.headerBtn[1].disabled = true;
  }

  enableFormBtn(){
    this.workCentreFormParam.headerBtn[0].disabled = false;
    this.workCentreFormParam.headerBtn[1].disabled = false;
  }

  //------ Init Component ------//
  initPermission(){
    this.checkViewPermission(JMENUM.Permission.WORKCENTRES_SETTING_VIEW);
    this.hasEditPermission = this.authorizationService.hasPermission(JMENUM.Permission.WORKCENTRES_SETTING_UPDATE);
  }

  initWorkCentreForm(workCentreCode){
    let newForm = {
      _id: undefined,
      workCentreCode: workCentreCode,
      description: workCentreCode,
      costCentre: '',
      source: WorkCentreSource.CCEPJM,
      haCluster: this.workCentreFormParam.fieldOptions.haCluster[0].value,
      parameters: {
        active: false, 
        canObtainSo: false,
        requiredObtainSo: false,
        timesheet: false,
        haEquipment: false
      }
    };
    this.workCentreData.unshift(newForm);
  }

  initWorkCentreListElem(){
    this.workCentreListParam.onScroll = this.onLoadWorkCentreList;
    this.workCentreListParam.headerInput = { 
      placeholder: JMLanguage.translate("global.search"),
      onKeyUp: this.onKeyUpSearchInput,
      onChange: () => {}
    };
    this.workCentreListParam.listHeaderBtn = {
      icon: 'fa fa-plus blue',
      onClick: this.onClickAddWorkCentre
    }
    this.workCentreListParam.hiddenContainerBtn = {
      customClass: 'brand-blue ml-xl-2 mt-2 mt-xl-0',
      onClick: this.onClickCreateWorkCentre
    }
    this.workCentreListParam.hiddenContainerInput = {
      customClass: 'w-auto col-xl col-12',
      placeholder: JMLanguage.translate("pages.settings.work-centres.new-work-centre-code"),
      onChange: () => {}
    }
    this.workCentreListParam.isHideHiddenContainer = true;
  }

  initFieldsFormElem(){
    this.workCentreFormParam.showFieldsError = false;
    this.workCentreFormParam.header = {
      id: 'workCentreCode',
      disabled: true,
      onChange: () => {}
    };
    this.workCentreFormParam.headerBtn = [
      { id: 'edit-btn', icon : 'fas fa-pen', disabled: !this.hasEditPermission, onClick: this.enableEditMode },
      { id: 'delete-btn', icon : 'fas fa-trash', disabled: !this.hasEditPermission, onClick: this.onDeleteBtnClicked },
    ];
    
    this.workCentreFormParam.onChangeBtn = [
      { id: 'update-btn', customClass: 'brand-blue',  onClick: this.onClickUpdateWorkCentre, text: JMLanguage.translate('pages.setting.work-centres.button.update')},
      { id: 'discard-btn', icon: 'fas fa-times', onClick: this.onClickDiscardBtn }
    ];

    this.workCentreFormParam.fieldOptions = {
      parameters: [ 
        { id: 'active', key: 'active', value: false, label: JMLanguage.translate('pages.settings.work-centres.parameters-options.active') },
        { id: 'can-option-so', key: 'canObtainSo', value: false, label: JMLanguage.translate('pages.settings.work-centres.parameters-options.can-obtain-so') },
        { id: 'required-obtain-so', key: 'requiredObtainSo', value: false, label: JMLanguage.translate('pages.settings.work-centres.parameters-options.required-obtain-so') },
        { id: 'timesheet', key: 'timesheet', value: false, label: JMLanguage.translate('pages.settings.work-centres.parameters-options.timesheet') },
        { id: 'ha-equipment', key: 'haEquipment', value: false, label: JMLanguage.translate('pages.settings.work-centres.parameters-options.ha-equipment') },
      ]
    };

    this.workCentreFormParam.fieldList = [
      { 
        id: 'description', 
        type: InputFieldType.text, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: () => {}, 
        name: JMLanguage.translate('pages.settings.work-centres.form.description') 
      },
      { 
        id: 'costCentre', 
        type: InputFieldType.text, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: () => {}, 
        name: JMLanguage.translate('pages.settings.work-centres.form.cost-centre') 
      },
      { 
        id: 'source', 
        type: InputFieldType.text, 
        titleCustomClass: 'bold',
        disabled: true,
        placeholder: '',
        onChange: () => {}, 
        name: JMLanguage.translate('pages.settings.work-centres.form.source')
      },
      { 
        id: 'haCluster', 
        type: InputFieldType.dropdown, 
        multiple: false, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        onChange: () => {}, 
        onSearch: () => {},
        scrollToEnd: () => {},
        name: JMLanguage.translate('pages.settings.work-centres.form.ha-cluster'), 
      },
      { 
        id: 'parameters', 
        onChange: this.onChangeParameters, 
        name: JMLanguage.translate('pages.settings.work-centres.form.parameters'), 
        type: InputFieldType.checkbox, 
        titleCustomClass: 'bold',
      }
    ];
  }

}
