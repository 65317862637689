export const DistrictNameTranslate = [
  {en:"", zh:""}, //no 0
  {en:"Central and Western", zh:"中西區"},
  {en:"Eastern", zh:"東區"},
  {en:"Southern", zh:"南區"},
  {en:"Wan Chai", zh:"灣仔區"},
  {en:"Sham Shui Po", zh:"深水埗區"},
  {en:"Kowloon City", zh:"九龍城區"},
  {en:"Kwun Tong", zh:"觀塘區"},
  {en:"Wong Tai Sin", zh:"黃大仙區"},
  {en:"Yau Tsim Mong", zh:"油尖旺區"},
  {en:"Islands", zh:"離島區"},
  {en:"Kwai Tsing", zh:"葵青區"},
  {en:"North", zh:"北區"},
  {en:"Sai Kung", zh:"西貢區"},
  {en:"Sha Tin", zh:"沙田區"},
  {en:"Tai Po", zh:"大埔區"},
  {en:"Tsuen Wan", zh:"荃灣區"},
  {en:"Tuen Mun", zh:"屯門區"},
  {en:"Yuen Long", zh:"元朗區"},
]