<div class="login-page-wrap">
  <div class="page-container text-center px-5">

    <div class="login-wrap text-left d-block d-lg-inline-block">
      <!--Login Card-->
      <div class="login-card px-4 py-3">
        <div class="underline row justify-content-between m-0 mb-2 pb-2 font-size-m">
          <div class="col-3 p-0">
            {{"global.login" | translate}}
          </div>
          <div class="col-6 text-right p-0">
            {{ systemName }}
          </div>
        </div>

        <div class="login-form-wrap">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="input-group my-2">
              <input id="login_user-id_inputbox" type="text" 
                formControlName="username" 
                class="form-control"
                placeholder="{{'pages.login.username-placeholder' | translate}}" 
                aria-label="Username" 
                aria-describedby="basic-addon1"
                [ngClass]="{ 'is-invalid': isValid(loginFormData.username) }"/>
            </div>

            <div class="input-group my-2">
              <input 
                id="login_password_inputbox"
                formControlName="password" 
                class="form-control"
                placeholder="{{'pages.login.password-placeholder' | translate}}" 
                [type]="showPassword ? 'text' : 'password'"
                [ngClass]="{ 'is-invalid': isValid(loginFormData.password) }"/>
              <div class="input-group-append">
                <button
                  class="btn btn-outline-light btn-show-password"
                  id="shwo_login_password"
                  type="button"
                  (mousedown)="onShowPasswordBtnClicked()">
                  <i class="fas"
                    [ngClass]="{
                      'fa-eye-slash': showPassword,
                      'fa-eye': !showPassword
                    }"></i>
                </button>
              </div>
            </div>

            <div class=" my-2 custom-control custom-checkbox">
              <input id="login_remember-me-checkbox" type="checkbox" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}" class="custom-control-input"/>
              <label class="custom-control-label" for="login_remember-me-checkbox">{{"pages.login.remember-message" | translate}}</label>
            </div>
            <div class="custom-row">
              <div class="language-dd">
                <ng-select bindValue="value" bindLabel="label" style="min-width: 100px;" [items]="languageOptions" [(ngModel)]="uiSelectedLanguage" 
                [clearable]="false" (change)="onLanguageChanged()" [ngModelOptions]="{standalone: true}"></ng-select>
              </div>
              <button id="login_login_button" class="thin theme-red" [disabled]="isLoggingIn">
                <div *ngIf="isLoggingIn" class="d-inline-block loading-spinner spinner-small">
                  {{ "global.loading" | translate }}
                </div>
                <ng-container *ngIf="!isLoggingIn">
                  <i class="fa fa-sign-in"></i>{{"global.login" | translate}}
                </ng-container>
              </button>
            </div>
          </form>
        </div>
      </div>

      <div *ngIf="ccepPortalHost && ccepPortalSsoKey"
        class="login-portal-wrapper">
        <div class="d-flex align-items-center">
          <div class="line"></div>
          <div class="text mx-2">{{"global.or" | translate}}</div>
          <div class="line"></div>
        </div>
        <img class="login-portal" 
          [src]="portalLoginSrc" 
          (click)="onClickSsoLogin()" />
      </div>

      <div class="version my-2 my-lg-3">
        {{"pages.login.version" | translate}} {{systemVersion}}
      </div>
        <!--Download App Layout-->
        <div class="download-btn-wrap col-auto p-0">
          <div class="row m-0">
            <a id="login_ios-app_button" class="download-btn ios-btn p-0 pl-1" [attr.href]="uiIOSDownloadLink | safe :'resourceUrl'">
              <img src="/assets/svg/iOS_download_button.svg">
            </a>
            <a id="login_android-app_button" class="download-btn android-btn p-0 pr-1" href="{{ uiAndroidDownloadLink }}">
              <img src="/assets/svg/Android_download_button.svg">
            </a>
          </div>
        </div>
      </div>

      <div class="news-wrap text-left d-block d-lg-inline-block">
        <!--News Layout-->
        <div class="latest-news">
          <h2 class="mb-3">{{"pages.login.news-title" | translate}}</h2>
          <div class="news-list">
            <div *ngIf="latestNewsMessage" [innerHTML]="latestNewsMessage | safe: 'html'"></div>
            <span class="text-right d-block">{{ latestNewsCreationTime | date:'dd/MM/yyyy HH:mm' }}</span>
          </div>
        </div>

        <!--Contact Information-->
        <div class="support-info">
          <div><i class="fas fa-headphones pr-1"></i><span>{{"pages.login.system-support" | translate}}</span></div>
          <div><i class="fas fa-envelope pr-1"></i><span>{{ supportContactInformation.ldapId }}</span></div>
          <div><i class="fas fa-envelope pr-1"></i><span>{{ supportContactInformation.ldapId2 }}</span></div>
          <div><i class="fas fa-envelope pr-1"></i><span>{{ supportContactInformation.email }}</span></div>
          <div><i class="fas fa-phone pr-1"></i><span>{{ supportContactInformation.phone }}</span></div>
        </div>
    </div>
  </div>

</div>