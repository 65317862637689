import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateInfoRequest } from '@api/model/authorization/update-info-request';
import { UpdateInfoResponse } from '@api/model/authorization/update-info-response';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment'; 

import { JMENUM } from '@ccep/CCEPConnector-ts';
import { GetPermissionsRequest } from 'src/app/api/authorization/get-permissions-request';
import { GetPermissionsResponse } from 'src/app/api/authorization/get-permissions-response';
import { GetPostRequest } from 'src/app/api/authorization/get-post-request';
import { GetPostResponse } from 'src/app/api/authorization/get-post-response';
import { GetPostsSummaryRequest } from 'src/app/api/authorization/get-posts-summary-request';
import { GetPostsSummaryResponse } from 'src/app/api/authorization/get-posts-summary-response';
import { GetRoleAllRequest } from 'src/app/api/authorization/get-role-all-request';
import { GetRoleAllResponse } from 'src/app/api/authorization/get-role-all-response';
import { GetRolesRequest } from 'src/app/api/authorization/get-roles-request';
import { GetRolesResponse } from 'src/app/api/authorization/get-roles-response';
import { PostAuthLoginRequest } from 'src/app/api/authorization/post-auth-login-request';
import { PostAuthLoginResponse } from 'src/app/api/authorization/post-auth-login-response';
import { PostAuthRequest } from 'src/app/api/authorization/post-auth-request';
import { PostAuthResponse } from 'src/app/api/authorization/post-auth-response';
import { PostPostSummaryRequest } from 'src/app/api/authorization/post-post-summary-request';
import { PostPostSummaryResponse } from 'src/app/api/authorization/post-post-summary-response';
import { PostPostsActivateRequest } from 'src/app/api/authorization/post-posts-activate-request';
import { PostPostsActivateResponse } from 'src/app/api/authorization/post-posts-activate-response';
import { PostPostsCreateRequest, PostPostsUpdateRequest } from 'src/app/api/authorization/post-posts-create-update-request';
import { PostPostsResponse } from 'src/app/api/authorization/post-posts-response';
import { PostRolesActivateRequest } from 'src/app/api/authorization/post-roles-activate-request';
import { PostRolesActivateResponse } from 'src/app/api/authorization/post-roles-activate-response';
import { PostRolesPermissionsRequest } from 'src/app/api/authorization/post-roles-permissions-request';
import { PostRolesPermissionsResponse } from 'src/app/api/authorization/post-roles-permissions-response';
import { UpdatePasswordRequest } from 'src/app/api/authorization/update-password-request';
import { UpdatePasswordResponse } from 'src/app/api/authorization/update-password-response';
import { environment } from 'src/environments/environment';
import { PostEmployeeSummaryRequest } from '@api/model/authorization/post-employee-summary-request';
import { PostEmployeeSummaryResponse } from '@api/model/authorization/post-employee-summary-response';
import { Session } from './session';

@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {

  private token: string;
  private user: any = null;
  private permissionSet: Set<string>;
  private workCenterSet: Set<string>;
  private DEFAULT_VAILD_PASSWORD_LENGTH:number = 6;
  private DEFAULT_SEQUENCE_LENGTH:number = 6;
  
  constructor(
    private http: HttpClient,
  ) { }
  
  // auth(request: PostAuthRequest): Observable<PostAuthResponse> {
  //   let url = environment.CCEP_API_HOST + "/auth";

  //   return this.http.post<PostAuthResponse>(
  //     url,
  //     request
  //   ).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  // authLogin(request: PostAuthLoginRequest): Observable<PostAuthLoginResponse> {
  //   let url = environment.CCEP_API_HOST + "/auth/login";

  //   return this.http.post<PostAuthLoginResponse>(
  //     url,
  //     request
  //   ).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  getPermissions(request: GetPermissionsRequest): Observable<GetPermissionsResponse> {
    let httpHeaders = this.getHeaders();
    let url = environment.CCEP_API_HOST + "/permissions/" + request.systemName;

    return this.http.get<GetPermissionsResponse>(
      url,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  getPost(request: GetPostRequest): Observable<GetPostResponse> {
    let httpHeaders = this.getHeaders();
    let url = environment.CCEP_API_HOST + "/posts/?postName=" + request.postName + "&systemName=" + request.systemName;

    return this.http.get<GetPostResponse>(
      url,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  postPostSummary(request: PostPostSummaryRequest): Observable<PostPostSummaryResponse> {
    let httpHeaders = this.getHeaders();
    let url = environment.CCEP_API_HOST + "/posts/postSummary/";

    return this.http.post<PostPostSummaryResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  /* Deprecated: use new function postPostSummary */
  // getPostsSummary(request: GetPostsSummaryRequest): Observable<GetPostsSummaryResponse> {
  //   let httpHeaders = this.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/posts/summary/?";

  //   if (request.systemName) { url += "&systemName=" + request.systemName; }
  //   if (request.activeOnly) { url += "&activeOnly=" + request.activeOnly; }
  //   if (request.authorizationKey) { url += "&authorizationKey=" + request.authorizationKey; }
  //   if (request.authorizationValue) { url += "&authorizationValue=" + request.authorizationValue; }
  //   if (request.pageNumber) { url += "&pageNumber=" + request.pageNumber; }
  //   if (request.pageSize) { url += "&pageSize=" + request.pageSize; }
  //   if (request.sortBy) { url += "&sortBy=" + request.sortBy; }
  //   if (request.sortOrder) { url += "&sortOrder=" + request.sortOrder; }

  //   return this.http.get<GetPostsSummaryResponse>(
  //     url,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  getRoleAllBySystemName(request: GetRoleAllRequest): Observable<GetRoleAllResponse> {
    let httpHeaders = this.getHeaders();
    let url = environment.CCEP_API_HOST + "/roles/all/" + request.systemName;

    return this.http.get<GetRoleAllResponse>(
      url,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    )
  }

  // getRolesByRoleId(request: GetRolesRequest): Observable<GetRolesResponse> {
  //   let httpHeaders = this.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/roles/" + request.roleId;

  //   return this.http.get<GetRolesResponse>(
  //     url,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  // postByPostNameActivate(request: PostPostsActivateRequest): Observable<PostPostsActivateResponse> {
  //   let httpHeaders = this.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/posts/" + request.postName + "/activate";

  //   return this.http.post<PostPostsActivateResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   );
  // }
  
  postPostsCreate(request: PostPostsCreateRequest) : Observable<PostPostsResponse> {
    let httpHeaders = this.getHeaders();
    let url = environment.CCEP_API_HOST + "/posts/create";

    return this.http.post<PostPostsResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    );
  }

  postPostsUpdate(request: PostPostsUpdateRequest) : Observable<PostPostsResponse> {
    let httpHeaders = this.getHeaders();
    let url = environment.CCEP_API_HOST + "/posts/update";

    return this.http.post<PostPostsResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    );
  }

  // postRolesByRoleIdActivate(request: PostRolesActivateRequest): Observable<PostRolesActivateResponse> {
  //   let httpHeaders = this.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/roles/" + request.roleId + "/activate";

  //   return this.http.post<PostRolesActivateResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  postRolesByRoleIdPermissions(request: PostRolesPermissionsRequest): Observable<PostRolesPermissionsResponse> {
    let httpHeaders = this.getHeaders();
    let url = environment.CCEP_API_HOST + "/roles/permissions";

    return this.http.post<PostRolesPermissionsResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    );
  }

  // updatePassword(request: UpdatePasswordRequest): Observable<UpdatePasswordResponse> {
  //   let httpHeaders = this.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/posts/updatePassword";

  //   return this.http.post<UpdatePasswordResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  updateInfo(request: UpdateInfoRequest): Observable<UpdateInfoResponse> {
    let httpHeaders = this.getHeaders();
    let url = environment.CCEP_API_HOST + "/posts/updateInfo";

    return this.http.post<UpdateInfoResponse>(
      url,
      request,
      { headers: httpHeaders }
    ).pipe(
      catchError(this.handleError)
    );
  }

  // getEmployeeSummary(request: PostEmployeeSummaryRequest): Observable<PostEmployeeSummaryResponse> {
  //   let httpHeaders = this.getHeaders();
  //   let url = environment.CCEP_API_HOST + "/employees/employeeSummary";

  //   return this.http.post<PostEmployeeSummaryResponse>(
  //     url,
  //     request,
  //     { headers: httpHeaders }
  //   ).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  //---------------------------------------------------------------------------
  setUserInfo(userObject: any, token:string): void {
    try {
      this.token = token;
      this.user = userObject;
      this.permissionSet = new Set(this.user.permissions);
      this.workCenterSet = (this.user.authorizations && this.user.authorizations.workCenters)? new Set(this.user.authorizations.workCenters.sort()): new Set([]);
    } catch (e) {
      console.error(e);
    }
  }

  setUserToken(token) {
    this.token = token;
  }

  getHeaders(): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Authorization', this.token);
    // httpHeaders = httpHeaders.set('Locale', this.languageService.getLocale());

    return httpHeaders;
  }

  getUserPermission(): string[] {
    return Array.from(this.permissionSet).sort((a, b) => a.localeCompare(b));
  }

  hasPermission(permissionKey: string): boolean {
    return permissionKey
      ? (this.user ? this.permissionSet.has(permissionKey) : false)
      : true;
  }

  hasPermissions(permissionList: string[], requiredIncludeAll: boolean): boolean {
    if (!permissionList || permissionList.length === 0) {
      return true;
    }
    if (requiredIncludeAll){
      return permissionList.every( requiredPermission => this.hasPermission(requiredPermission));
    } else {
      return permissionList.some( requiredPermission => this.hasPermission(requiredPermission));
    }
  }

  hasAuthorizationForWorkCenter(workCenter: string) {
    return this.workCenterSet.has(workCenter);
  }

  getWorkCenters(): string[] {
    return (this.user.authorizations && this.user.authorizations.workCenters)? this.user.authorizations.workCenters: [];
  }

  hasOnlyWeatherFormPermission() {
    const hasDashboardPermit = this.hasPermission(JMENUM.Permission.DASHBOARD_CSC) || 
      this.hasPermission(JMENUM.Permission.DASHBOARD_SBU);
    const hasWeatherEformPermit = this.hasPermissions([JMENUM.Permission.WEATHER_PREPARATION_FORM_VIEW, JMENUM.Permission.WEATHER_PREPARATION_FORM_VIEW], false);
    return !hasDashboardPermit && hasWeatherEformPermit;
  }

  // getNumberOfOfficers(workCenter: string): Promise<number> {
  //   let request = {} as PostPostSummaryRequest;
  //   request.systemName = 'CCEPJM';
  //   request.active = 'active';
  //   request.authorizations = {'workCenters': workCenter};
  //   request.includeSummary = false;

  //   return new Promise((resolve, reject) => {
  //     this.postPostSummary(request).subscribe(responseResult => {
  //       let result:any = responseResult;
  //       if (!result || result.code != 200) resolve(0);
  //       resolve(result.payload.totalCount);
  //     }, error => { resolve(0) });
  //   });
  // }

  isPasswordLengthVaild(str:string, validLength:number = this.DEFAULT_VAILD_PASSWORD_LENGTH):boolean{
    return str.length >= validLength
  }

  isPasswordContainNumber(str:string):boolean{
    return /\d/.test(str);
  }

  isPasswordContainNonAlphanumericCharacters(str:string):boolean{
    return /([^a-zA-Z&\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z&\d])+/.test(str)
  }

  isPasswordContainUserName(str:string,username:string):boolean{
    return str.toLowerCase().includes(username.toLowerCase())
  }

  isPasswordContainSequence(str:string,sequences:string[] = []):boolean{
    for(const sequence of sequences){
      if(str.toLowerCase().includes(sequence)){
        return true
      }
    }
    return false
  }
  
  public getNumCharSequence(length:number = this.DEFAULT_SEQUENCE_LENGTH): string[] {
    let result: string[] = []
    let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    let chars = this.getLowerCaseChars();
    this.appendSequence(result, numbers, length);
    this.appendSequence(result, chars, length);
    return result
  }

  private appendSequence(output: string[], input: any[], length) {
    let startpointer = 0
    let endpointer = startpointer + length
    while (1) {
      if (endpointer > input.length) {
        break
      }
      output.push(input.slice(startpointer, endpointer).join(""));
      startpointer++
      endpointer = startpointer + length
    }
  }

  private getLowerCaseChars(): string[] {
    let result: string[] = []
    for (let i = 97; i <= 122; i++) {
      result.push(String.fromCharCode(i))
    }
    return result
  }

  addUserRemindedPwChange(userName: string) {
    if (!userName) return;

    let userlist = Session.pwReminderDisableList;
    const dateKey = moment().format('YYMMDD');

    if (!userlist) userlist = {};
    if (!userlist[dateKey]) {
      userlist = {
        [dateKey]: []
      }
    }

    const tempSet = new Set<string>(userlist[dateKey]);
    tempSet.add(userName);
    userlist[dateKey] = Array.from(tempSet);

    Session.setPwReminder(userlist);
  }
  
  isUserRemindedPwChange(userName: string) {
    if (!userName) return null;

    let userlist = Session.pwReminderDisableList;
    const dateKey = moment().format('YYMMDD');

    if (!userlist) return false;
    if (!userlist[dateKey]) return false;

    return userlist[dateKey].includes(userName);
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
