<div class="wrapper">
    <app-nav [navbarTitle]="'side-bar.ccep-jm-account-review' | translate" [showActionSidebarIcon]="true"></app-nav>

    <app-sidebar #sideBar></app-sidebar>

    <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
            (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <!-- Page Content -->
    <div id="content">
        <ng-container *ngIf="isEnableReview === undefined">
            <div id="fullpageLoading">
                <div>
                    <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                    {{'global.loading' | translate}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isEnableReview === false">
            <div class="custom-row">
                <div class="instruction">{{ "pages.ccep-jm-account-review.send-email-confirmation.send-email-instruction" |
                    translate }}</div>
                <app-submit-button text="global.confirm" buttonClass="standard brand-amber"
                    (onClick)="onConfirmClicked()"></app-submit-button>
            </div>
        </ng-container>
        <ng-container *ngIf="isEnableReview === true">
            <div class="custom-row">
                {{ "pages.ccep-jm-account-review.send-email-confirmation.email-sent-on" | translate }} {{ emailSentDate | date:'dd-MM-yyyy HH:mm' }}
            </div>
            <div class="custom-row">
                {{ "pages.ccep-jm-account-review.send-email-confirmation.completion-date-is" | translate }} {{ completionDate | date:'dd-MM-yyyy' }}
            </div>
        </ng-container>
    </div>
</div>