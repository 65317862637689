import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import * as moment from 'moment';
import { formatHAContractEndDateToDateString, pad, toNgbDate } from 'src/lib/presenter/Formatter';
import { Session } from '@services/session';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalHaEquipmentDetailsComponent } from '../modal-ha-equipment-details/modal-ha-equipment-details.component';

@Component({
  selector: 'app-jobcard-ha-information',
  templateUrl: './jobcard-ha-information.component.html',
  styleUrls: ['./jobcard-ha-information.component.scss']
})
export class JobcardHaInformationComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() jobCard: JMOBJ.JobCard;
  @Input() componentParameters;
  @Input() pageMode: JMENUM.JMPageMode;

  @Output() onJobCardDropdownSummaryReady = new EventEmitter<void>();

  currentLanguage = Session.selectedLanguage;

  timeAlertMsg: string = undefined;
  dateAlertMsg: string = undefined;

  // Validation
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};
  checkDropDownValue: boolean;

  eamDropdownGroup = [];
  equipmentTagDropdownGroup = [];

  eamDropdown = {};
  equipmentTagDropdown = {};
  isJobCardDropDownSummaryFetched = false;

  equipmentReceivedDate = {};
  equipmentReceivedDay = undefined;
  equipmentReceivedTime = undefined;

  hospitalCode = undefined;
  haEquipment: any;
  hsdEquipment: any;
  ccsInfo: any;
  haEquipmentContractExpired = false;

  JMENUM = JMENUM;

  textYes = JMLanguage.translate('global.yes');
  textNo = JMLanguage.translate('global.no');

  emptyHandle = JMLanguage.translate('component.display-text-field.not-selected');

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    //Mock data
    // this.jobCard.eamDropdown = {
    //   "Cause Code": 1,
    //   "Resolution Code": 2,
    //   "Disinfection Status": 1,
    //   "Failure Code": 1,
    //   "Equipment Condition": 1,
    // };
    // this.jobCard.equipmentTagDropdown = {
    //   "Specific Failure Code": 1,
    // }
    this.timeAlertMsg = JMLanguage.translate("global.invalid-time");
    this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
    this.fieldControl();
    this.resetAllErrorFields();
    this.valid = false;

    Promise.all([
      this.requestDropdownSummary(),
      this.requestAddionalDropDown()
    ]).finally(() => {
      this.isJobCardDropDownSummaryFetched = true;
      this.onJobCardDropdownSummaryReady.emit();
    });

    this.setValueFromJobCard();
  }

  ngOnChanges(){
  }

  private fieldControl() {

    if (this.sn.sourceSystem === JMENUM.SourceSystem.HAEAM) {
      if (this.isPageModeCreate || this.isPageModeEdit) {
        this.editable = {
          assetNumber: false,
          equipmentReceivedDate: true,
          eamDropdownGroup: true,
          equipmentTagDropdownGroup: true,
        }
      }
    } else {
      if (this.isPageModeCreate || this.isPageModeEdit) {
        this.editable = {
          assetNumber: true,
          equipmentReceivedDate: true,
          eamDropdownGroup: true,
          equipmentTagDropdownGroup: true,
        }
        
        if (this.componentParameters.isFirstAttendedJobCard !== undefined) {
          this.editable.assetNumber = this.componentParameters.isFirstAttendedJobCard;
        }
      }
      this.mandatory = {
        assetNumber: false,
        equipmentReceivedDate: false,
        eamDropdownGroup: false,
        equipmentTagDropdownGroup: false,
      }
    }

    // check mandatory drop down when complete job card
    this.checkDropDownValue = false;
    if (this.isPageModeView) {
      this.checkDropDownValue = true;
    }
  }

  private async requestDropdownSummary(keys?) {
    const request = new JM.JMRequestDropdownSummary();
    request.system = [Constants.SYSTEM_NAME];
    request.active = true;
    if (!keys) {
      request.usageType = ["haEquipment"];
    }
    else {
      request.id = keys;
    }

    const response: JM.JMResponseDropdownSummary = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.setDropdownOptions(response.payload.records, !keys);
  }

  public async requestAddionalDropDown() {
    let additionalDropdown = [];

    if (this.sn.eamData && this.sn.eamData.assetNumber) {
      const request: JM.JMRequestEquipmentsHSDEquipmentSummary = new JM.JMRequestEquipmentsHSDEquipmentSummary();
      request.assetNumber = [this.sn.eamData.assetNumber];

      const response: JM.JMResponseEquipmentsHSDEquipmentSummary = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (response.payload.records.length == 0 || !response.payload.records[0]) {
        return;
      }

      let equipment = response.payload.records[0];
      if (equipment.hashtags && equipment.hashtags.length) {
        for (let tag of equipment.hashtags) {
          if (tag && tag.additionalDropdown && tag.additionalDropdown.length) {
            for (let dropdown of tag.additionalDropdown) {
              if (dropdown && !additionalDropdown.includes(dropdown)) { additionalDropdown.push(dropdown); }
            }
          }
        }
      }
    } else if (this.sn.hashtagId) {
      const request = new JM.JMRequestHashtagsGetHashtags();
      request.hashtagIdList = [this.sn.hashtagId];

      const response: JM.JMResponseHashtagsGetHashtags = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (response.payload.records.length > 0 && response.payload.records[0]) {
        if (response.payload.records[0].additionalDropdown && response.payload.records[0].additionalDropdown.length > 0) {
          additionalDropdown = response.payload.records[0].additionalDropdown
        }
      }
    }

    if (additionalDropdown && additionalDropdown.length > 0) {
      this.requestDropdownSummary(additionalDropdown);
    }
  }


  public assignInputToValue(key, newValue) {
    this.resetErrorField(key);
    if (newValue) {
      this.jobCard[key] = newValue;
    }
    else {
      this.jobCard[key] = undefined;
      if (this.errorFields[key] != undefined) {
        this.errorFields[key] = true;
      }
    }
  }

  public setDateTime(event){
    this.resetErrorFields(["equipmentReceivedDate"]);
    if(event.field == 'equipmentReceivedDay'){
      if(event.data){
        this.equipmentReceivedDay = event.data;
      }else{
        this.equipmentReceivedDay = undefined;
        this.jobCard.equipmentReceivedDate = undefined;
      }
    }
    if(event.field == 'equipmentReceivedTime'){
      if(event.data){
        this.equipmentReceivedTime = event.data;
      }else{
        this.equipmentReceivedTime = undefined;
        this.jobCard.equipmentReceivedDate = undefined;
      }
    }

    if(this.equipmentReceivedDay && this.equipmentReceivedTime){
      let date = this.equipmentReceivedDay;
      let timeArr =  this.equipmentReceivedTime.split(':');
      let formattedDate = new Date(date.year, date.month - 1, date.day);
      if(timeArr && timeArr.length > 0){
        formattedDate.setHours(timeArr[0]);
        formattedDate.setMinutes(timeArr[1]);
        this.jobCard.equipmentReceivedDate = new Date(formattedDate);
      }
    } else if (!this.equipmentReceivedDay && !this.equipmentReceivedTime) {
      this.errorFields['equipmentReceivedDate'] = false;
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.error.msg.mandatory-field"));
      this.errorFields['equipmentReceivedDate'] = true;
    }
  }

  public assignDropdownInputToValue(key, isHaDropdown) {
    if (isHaDropdown) {
      this.jobCard.eamDropdown[key] = this.eamDropdown[key];
    }
    else {
      this.jobCard.equipmentTagDropdown[key] = this.equipmentTagDropdown[key];
    }
  }

  public validation = () => {
    if (!this.isJobCardDropDownSummaryFetched) {
      AppDelegate.openSnackBar(JMLanguage.translate("global.loading"));
      return false;
    }

    let hasErrorField =  false;
    let fields = {};
    if(this.isPageModeEdit || this.isPageModeCreate){
      fields = {... this.editable};
    } else {
      // View Mode
      fields = {... this.mandatory};
    }

    // Check fields have data
    for(let field in fields){
      if(fields[field] && this.mandatory[field]){
        this.errorFields[field] = this.jobCard[field] ? false : true;
      }
    }
    if (this.isPageModeEdit && !this.isPageModeCreate ) {
      this.errorFields['assetNumber'] = this.componentParameters.assetNumber ? false : true;
    }

    if (this.checkDropDownValue) {
      for (let dropDown of this.eamDropdownGroup) {
        if (!dropDown || !dropDown.mandatory) { continue; }

        let key = dropDown.key;
        this.errorFields[key] = !this.jobCard.eamDropdown || !this.jobCard.eamDropdown[key];
        if (this.errorFields[key]) { hasErrorField = true; }
      }

      for (let dropDown of this.equipmentTagDropdownGroup) {
        if (!dropDown || !dropDown.mandatory) { continue; }

        let key = dropDown.key;
        this.errorFields[key] = !this.jobCard.equipmentTagDropdown || !this.jobCard.equipmentTagDropdown[key];
        if (this.errorFields[key]) { hasErrorField = true; }
      }
    }

    for(let field in fields){
      if(fields[field] && this.errorFields[field]){
        hasErrorField = true;
      }
    }

    if (hasErrorField) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.error.msg.mandatory-field"));
    }
    this.valid = !hasErrorField;
    return this.valid;
  }

  public resetErrorField(key) {
    if (this.errorFields[key] != undefined) {
      this.errorFields[key] = false;
    }
  }

  public resetErrorFields(keys: string[]) {
    keys.forEach(key => this.resetErrorField(key));
  }

  public resetAllErrorFields() {
    this.valid = true;
    this.errorFields = {
      assetNumber: false,
      resolutionCode: false,
      disinfectionStatus: false,
      failureCode: false,
      causeCode: false,
      equipmentCondition: false,
      equipmentReceivedDate: false,
    }
  }

  resetForm = () => {
    this.equipmentReceivedDay = undefined;
    this.equipmentReceivedTime = undefined;
    this.eamDropdown = {}
    this.equipmentTagDropdown = {}
  }

  private setDropdownOptions(records: JMOBJ.Dropdown[], isHaDropdown: boolean) {
    records.forEach(dropDown => {
      let dropDownObj = {
        key: undefined,
        desc: undefined,
        options: undefined,
        mandatory: undefined,
        disabled: dropDown.systemOnly
      };

      dropDownObj.key = dropDown.key;
      dropDownObj.desc = dropDown.description[this.currentLanguage];
      dropDownObj.options = dropDown.options.map(data => {
        return {
          'description': data['description'][this.currentLanguage],
          'key': data['key']
        };
      });

      dropDownObj.mandatory = dropDown.mandatoryJobNatureList.includes(this.jobCard.jobNature as JMENUM.JobNature);

      this.errorFields[dropDown.key];
      (isHaDropdown) ? this.eamDropdownGroup.push(dropDownObj) : this.equipmentTagDropdownGroup.push(dropDownObj);
    });

    this.setValueFromJobCard();
  }

  public setValueFromJobCard() {
    if (this.sn['eamData'] && this.sn['eamData']['assetNumber']) {
      this.componentParameters.assetNumber = this.sn['eamData']['assetNumber'];
    }

    // eamDropdown
    if (this.jobCard.eamDropdown) {
      if (this.isPageModeCreate || this.isPageModeEdit) {
        this.eamDropdown = this.jobCard.eamDropdown;
      } else {
        Object.entries(this.jobCard.eamDropdown).forEach(([key, value]) => {
          let obj = this.eamDropdownGroup.find(dropDown => dropDown.key == key);
          if (obj) {
            let desc = obj["options"].find(option => option.key == value);
            this.eamDropdown[key] = desc ? desc : undefined;
          }
        });
      }
    } else {
      let eamEmptyObj = {};
      Object.values(this.eamDropdownGroup).forEach(eamDropdown => { eamEmptyObj[eamDropdown.key] = undefined; });

      this.eamDropdown = eamEmptyObj;
      this.jobCard.eamDropdown = eamEmptyObj;
    }

    // equipmentTagDropdown
    if (this.jobCard.equipmentTagDropdown) {
      if (this.isPageModeCreate || this.isPageModeEdit) {
        this.equipmentTagDropdown = this.jobCard.equipmentTagDropdown;
      } else {
        Object.entries(this.jobCard.equipmentTagDropdown).forEach(([key, value]) => {
          let obj = this.equipmentTagDropdownGroup.find(dropDown => dropDown.key == key);
          if (obj) {
            let desc = obj["options"].find(option => option.key == value);
            this.equipmentTagDropdown[key] = desc ? desc : undefined;
          }
        });
      }
    } else {
      let equipmentTagEmptyObj = {};
      Object.keys(this.equipmentTagDropdownGroup).forEach(equipmentTagDropdown => { equipmentTagEmptyObj[equipmentTagDropdown] = undefined; });

      this.equipmentTagDropdown = equipmentTagEmptyObj;
      this.jobCard.equipmentTagDropdown = equipmentTagEmptyObj;
    }

    // equipmentReceivedDate
    let equipmentReceivedDate: any = this.jobCard.equipmentReceivedDate ? this.jobCard.equipmentReceivedDate : undefined;
    if (equipmentReceivedDate) {
      let time = moment(equipmentReceivedDate);
      this.equipmentReceivedDay = toNgbDate(equipmentReceivedDate);
      this.equipmentReceivedTime = pad(time.hour()) + ':' + pad(time.minute());
    }
  }


  private async requestHSDEquipmentSummary(callback?: () => void) {
    if (this.sn.eamData.assetNumber) {
      const request: JM.JMRequestEquipmentsHSDEquipmentSummary = new JM.JMRequestEquipmentsHSDEquipmentSummary();
      request.hasHAEquipment = true;
      request.assetNumber = [this.sn.eamData.assetNumber];

      const response: JM.JMResponseEquipmentsHSDEquipmentSummary = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      if (response.payload.totalCount > 0) {
        this.haEquipment = response.payload.records[0]['HAEquipment'];
        this.hsdEquipment = response.payload.records[0];
        this.hospitalCode = this.haEquipment && this.haEquipment.hospitalCode ? this.haEquipment.hospitalCode : this.hsdEquipment.hospitalLocation;
        // this.assetDescription = response.payload.records[0].assetDescription;
        if (this.haEquipment) {
          if (this.haEquipment["contractEndDate"]) {
            let contractEndDate = formatHAContractEndDateToDateString(this.haEquipment["contractEndDate"]);
            let currentDate = new Date();
            let diffTime = currentDate.getTime() - contractEndDate.getTime();
            let diffDays = Math.round(diffTime / (1000 * 3600 * 24));
            this.haEquipmentContractExpired = diffDays > 0;
          }
        }
        if (response.payload.records[0]['equipment'] && response.payload.records[0]['equipment']['equipmentNumber']) {
          this.requestEquipmentSummaryByNumber(response.payload.records[0]['equipment']['equipmentNumber'], callback);
        } else {
          if (typeof callback === 'function') { callback(); }
        }
      }
    }
  }

  public async requestEquipmentSummaryByNumber(equipmentNumber, callback?: () => void) {
    const request: JM.JMRequestEquipmentsEquipmentSummary = new JM.JMRequestEquipmentsEquipmentSummary();
    request.equipmentNumber = [equipmentNumber];
    request.parameters = ["equipmentNumber", "description", "equipmentType", "location", "clientShortName", "isActive"];
    request.active = JMENUM.RequestActive.BOTH;

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.totalCount > 0) {
      this.ccsInfo = response.payload.records[0];
      if (typeof callback === 'function') { callback(); }
    }
  }

  public onClickHADetails(): void {
    if (!this.sn.eamData.assetNumber) {
      return console.warn('There is no AssetNumber selected...');
    }
    let modalRef = this.modalService.open(ModalHaEquipmentDetailsComponent, { backdrop: 'static', centered: true, size: 'xl' });

    modalRef.componentInstance.isLoading = true;
    this.requestHSDEquipmentSummary(() => {
      modalRef.componentInstance.haEquipment = this.haEquipment;
      modalRef.componentInstance.ccsInfo = this.ccsInfo;
      modalRef.componentInstance.isLoading = false;
    });
  }

  onReplyToHAChanged = (toggle: boolean) => {
    if (this.isPageModeView) {
      return console.warn('It is not neither isCreateMode nor isEditMode...');
    }
    if (toggle) {
      this.sn.eamData.replyToHA = true;
    } else {
      this.sn.eamData.replyToHA = false;
    }
  }

  get isPageModeCreate(){
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }

  get isPageModeEdit(){
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }

  get isPageModeView(){
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }

  getHaNonComplianceReason() {
    if (this.jobCard && this.jobCard.haNonComplianceReason) {
      const reason = JMREASON.HA_NON_COMPLIANCE_REASON_LIST.getReason(this.jobCard.haNonComplianceReason);
      
      if (reason) {
        return reason.description[JMLanguage.getCurrentLanguage()];;
      } else {
        return this.jobCard.haNonComplianceReason;
      }
    } else return null;
  }
}
