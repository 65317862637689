<div class="header">
  <h3>{{ 'pages.approval.reject.title' | translate }}</h3>
  <div class="submit-wrap d-inline">
      <app-submit-button 
        [id]="'transfer-sn-panel_submit_button'"
        [text]="'global.submit'"
        [buttonClass]="'standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="!submitEnable || isLoading"
        [isLoading]="isLoading"
        (onClick)="onClickSubmit()" 
      ></app-submit-button>
  </div>
</div>

<div *ngIf="this.approvalList.length > 0 && ApprovalTypeShowingSelectedPlan.includes(approvalList[0].type)">
  <div>{{ selectedPmPlanHeader }}</div>
  <p>{{ selectedPmPlan.join(", ") }}</p>
</div>

<div *ngIf="this.approvalList.length > 0 && (approvalList[0].type == ApprovalType.PMPLAN_SUBMIT || approvalList[0].type == ApprovalType.PMJOB_SUBMIT)" class="input-field-group">
  <div class="dropdwon-group">
    <ng-select 
      id="list-sn_team-work-centre_dropdown"
      bindValue="value"
      placeholder="{{'pages.approval.reject.reason' | translate }}"
      [searchable]="false"
      [ngModelOptions]="{standalone: true}"
      [items]="rejectReasonOptions"
      (change)="validateForm()"
      [(ngModel)]="rejectReasonCode"
    >
    </ng-select>
  </div>
</div>

<div class="input-field-group">
  <textarea
    class="form-control"
    maxlength="{{ getMaxRemarksLength }}"
    type="text"
    rows="5"
    placeholder="{{ 'pages.approval.reject.remarks' | translate }}"
    [disabled]="isLoading"
    (input)="validateForm()"
    [(ngModel)]="rejectReasonRemarks"
  ></textarea>
  <span 
    *ngIf="rejectReasonRemarks?.length >= getMaxRemarksLength" 
    class="red small"
  >{{ "textarea.warning.max-length" | translate:[getMaxRemarksLength]}}</span>
</div>
