<div class="selection-box mb-3">
  <div class="input-border">
    <div class="d-flex justify-content-between align-items-center my-1 mr-1 ml-3 ">
      <div>
        <i class="fas fa-user-friends ccc"></i>
        {{ uiTitle }}
      </div>
      <div *ngIf="showCreateButton && !isLoading">
        <button type="button" class="rectangle brand-blue" id="settings-team_{{mode}}-add-team_button"
          (click)="onCreateTeamBtnClicked()">
          <i class="fas fa-users-cog"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="input-border" id="settings-team_{{mode}}-teams_item-list" style="min-height: 200px;" >
    <ng-container *ngIf="isLoading; else teamListTemplate">
      <div class="d-flex align-items-center justify-content-center" style="min-height: 200px;">
        <div class="spinner-border" role="status">
          <span class="sr-only">{{'global.loading' | translate}}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #teamListTemplate>
      <ul class="list-group" *ngFor="let team of teamList; let i = index">
        <li class="list-group-item d-flex justify-content-between align-items-center active"
          [ngClass]="{ 'active': (selectedTeam && team._id === selectedTeam._id) }"
          (click)="onTeamClicked(team)">
          <span>
            <i *ngIf="showCreateButton && team.workCentre == workCentre" class="fas fa-pen space" (click)="onEditTeamClicked(team)"></i>
            {{ team.name }} {{ team.workCentre != workCentre ? '<' + team.workCentre + '>' : '' }} 
            <i class="expired-icon fas fa-exclamation-circle mx-2" 
              *ngIf="mode=='contractor' && checkTeamExpired(team.status)"
              [ngbTooltip]="teamContractExpiredToolTip" 
              placement="bottom" >
            </i>
            <i class="expired-icon fas fa-exclamation-circle mx-2" 
              *ngIf="mode=='contractor' && checkTeamIneffective(team.status)"
              [ngbTooltip]="teamContractIneffectiveToolTip" 
              placement="bottom" >
            </i>
            <ng-container *ngIf="mode=='contractor'"><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{ team.contractNumber }})</ng-container>
          </span>
          <span class="badge badge-pill">{{ team.handlingParty == handlingParty.PMSMC ? team.memberCount || '' : team.members.length }}
            <ng-container *ngIf="mode=='contractor'"><br><br><br></ng-container>
          </span>
        </li>
      </ul>
    </ng-template>
  </div>
</div>


<!--Slider panel-->

<custom-slider-panel #createTeamSliderPanel [widthPx]=600 [customClass]="'sliderPanel'">
  <create-team-form #createTeamForm [parameters]="createTeamFormParameters"></create-team-form>
</custom-slider-panel>

<custom-slider-panel #updateTeamSliderPanel [widthPx]=600 [customClass]="'sliderPanel'">
  <update-team-form #updateTeamForm [parameters]="updateTeamFormParameters"></update-team-form>
</custom-slider-panel>


<!-- tool tip -->
<ng-template #teamContractExpiredToolTip>
  <div class="warning-tooltip-wrap tooltip-inner text-center">
    {{ "tool-tip.team-contract-expired" | translate }}
  </div>
</ng-template>

<ng-template #teamContractIneffectiveToolTip>
  <div class="warning-tooltip-wrap tooltip-inner text-center">
    {{ "tool-tip.team-contract-ineffective" | translate }}
  </div>
</ng-template>