import { Component, Input, OnInit } from '@angular/core';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { InputFieldType } from '../custom-fields-form/input-field-type.enum';
import { OptionsSubmitType } from './options-submit-type.enum';

@Component({
  selector: 'app-custom-dropdown-options',
  templateUrl: './custom-dropdown-options.component.html',
  styleUrls: ['./custom-dropdown-options.component.scss']
})
export class CustomDropdownOptionsComponent implements OnInit {
  @Input() parameters = {
    isEditMode: false,
    showFieldsError: false,
    headerBtn: [],
    header: {
      id: '',
      title: '',
      mandatory: false,
      customClass: '',
    },
    addRowBtn: {},
    fieldList: [],
    mappingType: '',
    onClickRow: undefined,
    selectedOptionIndex: 0,
  };
  @Input() optionList: any;
  @Input() attrParam: any;

  InputFieldType = InputFieldType;
  userInput: any = {};
  copyFormValue:any = {};
  showInputForm: boolean = false;
  formSubmitType: OptionsSubmitType;
  submitBtnText: string;

  
  constructor() { }

  ngOnInit() {

  }

  public openInputForm(){
    this.showInputForm = true;
  }

  public closeInputForm(){
    this.showInputForm = false;
  }

  public changeFormActionCreate(btnText){
    this.formSubmitType = OptionsSubmitType.Create;
    this.submitBtnText = JMLanguage.translate("pages.settings.dropdown."+btnText);
  }

  public changeFormActionUpdate(btnText){
    this.formSubmitType = OptionsSubmitType.Update;
    this.submitBtnText = JMLanguage.translate("pages.settings.dropdown."+btnText);
  }

  public resetOptionForm(){
    Object.keys(this.userInput).forEach(key => {
      if(typeof(this.userInput[key]) === 'object'){
        Object.keys(this.userInput[key]).forEach(subKey => {
          this.userInput[key][subKey] = '';
        });
      }else{
        this.userInput[key] = '';
      }
    });
  }

  public initOptionForm(){
    this.userInput = {};
    this.parameters.fieldList.forEach(field => {
      if(field.supportLang){
        this.userInput[field.key] = {};
        Object.keys(field.label).forEach(subKey => {
          this.userInput[field.key][subKey] = '';
        });
      }else{
        this.userInput[field.key] = '';
      }
    });

  }

  public editOption(index){
    this.userInput = {};
    Object.keys(this.optionList[index]).forEach((key, i) => {
      if(this.parameters.fieldList[i] && this.parameters.fieldList[i].supportLang){
        this.userInput[key] = {};
        Object.keys(this.optionList[index][key]).forEach(subKey => {
          this.userInput[key][subKey] = this.optionList[index][key][subKey];
        });
      }else{
        this.userInput[key] = this.optionList[index][key];
      }
    });
  }

}
