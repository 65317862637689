import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { BasePage } from '../../model/base/base';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { formatDateTimeWithWeek } from 'src/lib/presenter/Formatter';
import { sortAttachmentsByDate } from 'src/app/services/utility';

@Component({
  selector: 'app-attachment-detail',
  templateUrl: './attachment-detail.component.html',
  styleUrls: ['./attachment-detail.component.scss']
})

export class AttachmentDetailComponent extends BasePage implements OnInit {
  @Input() jobCard: JMOBJ.JobCard;
  @Input() pmJob: JMOBJ.PmJob;
  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Input() currentPageMode: JMENUM.JMPageMode = undefined;
  @Input() page: string = undefined;

  JMPage = JMENUM.JMPage;
  pageMode = JMENUM.JMPageMode;

  tablexParam: any = {};

  attachmentList: any;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.initTable();
    this.renderTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    switch (this.page){
      case this.JMPage.SN: 
        this.attachmentList = (this.sn.attachmentList && this.sn.attachmentList.length) ? sortAttachmentsByDate(this.sn.attachmentList) : [];
        break;

      case this.JMPage.JOBCARD: 
        this.attachmentList = (this.jobCard.attachments && this.jobCard.attachments.length) ? this.jobCard.attachments.reverse() : [];
        break;
      
      case this.JMPage.PMJOB:
        this.attachmentList = (this.pmJob.attachments && this.pmJob.attachments.length) ? this.pmJob.attachments.reverse() : [];
        break;
    }
    this.renderTable();
  }

  renderTable() {
    switch (this.page){
      case this.JMPage.SN: 
        this.tablexParam.content = this.attachmentList.map(attachment => {
          return [
            attachment._id,
            [{ "id": "attachment_" + attachment._id, "name": attachment.description, "class": "attachment-button", "icon": "", "onClicked": this.requestGetFile, "attachment": attachment }],
            attachment.uploadedAt != undefined && attachment.uploadedAt != null ? formatDateTimeWithWeek(attachment.uploadedAt) : JMLanguage.translate(`global.na`)
          ];
        });
        break;

      case this.JMPage.JOBCARD:
        this.tablexParam.content = this.attachmentList.map(attachment => {
          return [
            attachment._id,
            [{ "id": "attachment_" + attachment._id, "name": attachment.description, "class": "attachment-button", "icon": "", "onClicked": this.requestGetFile, "attachment": attachment }],
            attachment.uploadedTime != undefined && attachment.uploadedTime != null ? formatDateTimeWithWeek(attachment.uploadedTime) : JMLanguage.translate(`global.na`)
          ];
        });
        break;
      case this.JMPage.PMJOB:
        this.tablexParam.content = this.attachmentList.map(attachment => {
          return [
            attachment._id,
            [{ "id": "attachment_" + attachment._id, "name": attachment.description, "class": "attachment-button", "icon": "", "onClicked": this.requestGetFile, "attachment": attachment }],
            attachment.uploadedAt != undefined && attachment.uploadedAt != null ? formatDateTimeWithWeek(attachment.uploadedAt): JMLanguage.translate(`global.na`)
          ];
        });
        break;
    }
  }

  initTable() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      enableColFilter: false,
      enableSelectedRowCount: true,
      tableRow: "row",
      tableClass: "attachment-table",
      tableWrapperClass: "table-min-width",
      selectedRowCount: 0,
      totalRowCount: 0,
      enableStickyHeader: false,
      content: [],
      highlightedRows: [],
      customClassRows: [],
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.sn-attachment-detail.file-name',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'uploadedDate',
          name: 'component.attachment.uploadDate',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        // {
        //   id:'action',
        //   name: 'component.attachment.action',
        //   type: TablexColumnType.Buttons, 
        //   horizontalAlign: TablexColumnHorizontalAlign.Center, 
        //   verticalAlign: TablexColumnVerticalAlign.Middle, 
        //   class: "col-1"
        // }
      ]
    };
  }


  private requestGetFile = async (button) => {
    if (button.attachment) {
      let attachment = button.attachment;
      const request = new JM.JMRequestFilesGetFile(attachment.attachmentId);

      const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request);
      if (!response || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      saveAs(response.payload, attachment.description);
    }
  }
}
