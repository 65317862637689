import { Component, Injector, OnInit } from '@angular/core';
import { Session } from 'src/app/services/session';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { InputFieldType } from 'src/lib/ui-component/custom-fields-form/input-field-type.enum';
import { AppDelegate } from 'src/app/AppDelegate';
import { EquipmentTagService } from '@services/equipment-tag.service';
@Component({
  selector: 'batch-edit-equipment-form',
  templateUrl: './batch-edit-equipment-form.html',
  styleUrls: ['./batch-edit-equipment-form.scss']
})
export class BatchEditEquipmentFormComponent extends BasePage implements OnInit {
  
  
  // Button / Loading Status
  isDisabledBatchAddBtn: boolean = false;
  isLoadingBatchAddBtn: boolean = false;
  isDisabledBatchRemoveBtn: boolean = false;
  isLoadingBatchRemoveBtn: boolean = false;
  userTagGroup:JMOBJ.HashTagGroup[] = [];
  

  // Config & data of batch_edit_tags_form
  batchEditTagsFormParam: any = {};
  batchEditTagsFormInput: any = {};

  // data object
  equipmenTagList: JMOBJ.HashTag[] = [];
  equipmentList:JMOBJ.Equipment[] = [];
  equipmentFilter:{} = {};
  equipmentNumberFilter: string[] = [];

  // callbackk
  onSave:Function = null;

  mode: BatchEditEquipmentFormComponentModes;
  batchEditEquipmentFormComponentModes = BatchEditEquipmentFormComponentModes;
  

  constructor(
    injector: Injector,
    private equipmentTagService: EquipmentTagService,
  ) {
    super(injector);
  }

  ngOnInit() {
    // this.initBatchEditTagsFormElem();
    this.batchEditTagsFormParam.isEditMode = true;
    this.batchEditTagsFormParam.showFieldsError = false;
    this.batchEditTagsFormParam.fieldOptions = {};
    this.batchEditTagsFormParam.displayAllAsRow = true;
    this.batchEditTagsFormParam.fieldList = [];
  }

  ngOnDestroy() { 
  }

  ngAfterViewInit() {
  }

  onLanguageChanged() {
  }

  initBatchEditTagsFormElem(){
    if(this.mode === BatchEditEquipmentFormComponentModes.ADD) {
      this.batchEditTagsFormParam.fieldOptions = {
        range: [
          { id: 'all-in-this-page', key: 'all-in-this-page', value: 'all-in-this-page', label: JMLanguage.translate('pages.ha-equipment.all-in-this-page') },
          { id: 'filtered', key: 'filtered', value: 'filtered', label: JMLanguage.translate('pages.ha-equipment.filtered') },
        ]
      };
    }else if(this.mode === BatchEditEquipmentFormComponentModes.REMOVE) {
      this.batchEditTagsFormParam.fieldOptions = {
        range: [
          { id: 'all-in-this-page', key: 'all-in-this-page', value: 'all-in-this-page', label: JMLanguage.translate('pages.ha-equipment.all-in-this-page') },
          { id: 'filtered', key: 'filtered', value: 'filtered', label: JMLanguage.translate('pages.ha-equipment.filtered') },
          { id: 'all-equipment', key: 'all-equipment', value: 'all-equipment', label: JMLanguage.translate('pages.ha-equipment.all-equipment') }
        ]
      };
    }

    this.batchEditTagsFormParam.fieldList = [
      {
        id: 'equipmentTag',
        name: JMLanguage.translate("pages.ha-equipment-list.table-column.equipment-tag"),
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        template: true,
        onChange: this.onBatchEquipmentTagsChanged, 
        onSearch: () => {},
        scrollToEnd: () => {},
      },
      {
        id: 'range',
        name: '',
        type: InputFieldType.radio, 
        placeholder: '',
        mandatory: true,
        customClass: 'd-block mr-0 mb-2',
        onChange: this.onBatchEditRangeChanged
      }
    ];

    this.requestTags();
  }

  // ----------- setter function ----------- //
  reset(){
    this.batchEditTagsFormParam.showFieldsError = false;
    this.batchEditTagsFormInput = {};
  }

  validate() {
    if(this.batchEditTagsFormInput.equipmentTag && this.batchEditTagsFormInput.range){
      this.batchEditTagsFormParam.showFieldsError = false;
      return true;
    }else{
      this.batchEditTagsFormParam.showFieldsError = true;
      return false;
    }
  }

  // ----------- API ----------- //

  async requestTags() {
    if (this.mode === BatchEditEquipmentFormComponentModes.ADD) {
      this.requestWorkCentreHashtags();
    } else if (this.mode === BatchEditEquipmentFormComponentModes.REMOVE) {
      this.requestWorkCentreAssignedHashtags();
    }
  }

  parseEquipmentListToTag(){
    if (this.equipmenTagList.length > 0) {
      // map Batch edit all Cat & Type tags
      this.batchEditTagsFormParam.fieldOptions['equipmentTag'] = this.equipmenTagList.map((tag) => {
        return {
          value: tag._id,
          label: this.equipmentTagService.getTagDescription(tag),
        };
      });
    } else {
      this.batchEditTagsFormParam.fieldOptions['equipmentTag'] = [];
    }
  }


  async requestWorkCentreHashtags(){
    let request = new JM.JMRequestWorkCentresHashtags();
    request.equipmentSource = JMENUM.EquipmentSource.CCS;
    request.workCentre = Session.selectedWorkCentre;

    let response = await AppDelegate.sendJMRequest(request);

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    
    this.equipmenTagList = response.payload;
    
    this.parseEquipmentListToTag();

    this.batchEditTagsFormInput.equipmentTag = null;
  }

  async requestWorkCentreAssignedHashtags(){
    let request = new JM.JMRequestEquipmentsGetWorkCentreAssignedHashtag();
    request.workCentre = Session.selectedWorkCentre;

    let response = await AppDelegate.sendJMRequest(request);

    if (!response || response.error || !response.code || response.code != 200 || !response.payload || !response.payload.records) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.equipmenTagList = response.payload.records;

    this.parseEquipmentListToTag();

    this.batchEditTagsFormInput.equipmentTag = null;
  }
  
  requestBatchRemoveTags() {
    this.isDisabledBatchAddBtn = true;
    this.isLoadingBatchRemoveBtn = true;

    let request: JM.JMRequestEquipmentRemoveHashtags = new JM.JMRequestEquipmentRemoveHashtags();
    request.hashtagId  = this.batchEditTagsFormInput.equipmentTag;
    request.workCentre = Session.selectedWorkCentre;
    
    if(this.batchEditTagsFormInput.range == 'all-in-this-page'){
      request.equipmentNumberList = this.equipmentList.map(e => {
        return e.equipmentNumber;
      });

    }else if(this.batchEditTagsFormInput.range == 'filtered'){
      request.filter = this.equipmentFilter;

    }

    JM.JMConnector.sendEquipmentsRemoveHashtags(request, (error:JM.JMNetworkError, response:JM.JMResponseEquipmentRemoveHashtags) => {      
      this.isDisabledBatchAddBtn = false;
      this.isLoadingBatchRemoveBtn = false;

      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200) {
        this.openErrorBar(response);
        return;
      }
      
      AppDelegate.openSnackBar(JMLanguage.translate("global.removed"));
      if (this.onSave) {
        this.onSave();
      }
    });
  }
  
  requestBatchAddTags() {
    this.isDisabledBatchAddBtn = true;
    this.isLoadingBatchAddBtn = true;

    let request: JM.JMRequestEquipmentsAddHashtags = new JM.JMRequestEquipmentsAddHashtags();
    request.hashtagId = this.batchEditTagsFormInput.equipmentTag;
    request.workCentre = Session.selectedWorkCentre;

    if(this.batchEditTagsFormInput.range == 'all-in-this-page'){
      request.equipmentNumberList = this.equipmentList.map(e => {
        return e.equipmentNumber;
      });
    
    } else if(this.batchEditTagsFormInput.range == 'filtered'){
      request.filter = this.equipmentFilter;
      
    }else {
      return;
    }

    JM.JMConnector.sendEquipmentsAddHashtags(request, (error:JM.JMNetworkError, response:JM.JMResponseEquipmentsAddHashtags) => {
      this.isLoadingBatchAddBtn = false;
      this.isDisabledBatchAddBtn = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      
      AppDelegate.openSnackBar(JMLanguage.translate("global.added"));
      if (this.onSave) {
        this.onSave();
      }
    });
  }


  
  // ----------- Button function ----------- //
  onBatchEquipmentTagsChanged = () => {
    console.log("onBatchEquipmentTagsChanged");
    // if(this.batchEditTagsFormInput.equipmentTag && this.batchEditTagsFormInput.equipmentTag.length <= 0){
    //   delete this.batchEditTagsFormInput.equipmentTag
    // }
    // this.checkEnableBatchEditBtn();
  }

  onBatchEditRangeChanged = () => {
    // this.checkEnableBatchEditBtn();
  }

  onClickAddTags = () => {
    if (this.validate()) {
      this.requestBatchAddTags();
    }
  }

  onClickRemoveTags = () => {
    if(!this.validate()){
      AppDelegate.openSnackBar(JMLanguage.translate("pages.ha-equipment-list.please-fill-in-mandatory-fields"));
    }else{
      let buttons = [
        {
          name: JMLanguage.translate("global.remove"),
          handler: () => {
            this.requestBatchRemoveTags();
          }
        }, 
        {
          name: (JMLanguage.translate("global.cancel")),
          handler: () => {}
        }
      ];
      AppDelegate.showPopUpAlert(
        JMLanguage.translate("pages.ha-equipment.ask-confirm-remove-tag", 
        ['"' + JMLanguage.translate('pages.ha-equipment.' + this.batchEditTagsFormInput.range) + '"']), 
        "", buttons);
    }
  }
  
}

export enum BatchEditEquipmentFormComponentModes {
  ADD,
  REMOVE
}
