import { Session } from '@services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMCONSTANT, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { environment } from 'src/environments/environment';

const isHSDUser = Session && Session.userInfo && Session.userInfo.authorizations && Session.userInfo.authorizations.clusters && Session.userInfo.authorizations.clusters.length;

export const SIDEBAR_ITEMS = {
  default: [
    {
      id: 'ServiceNotification',
      title: "side-bar.service-notification",
      permission: [JMENUM.Permission.SN_VIEW],
      path: '',
      subItems: [
        { id: "sn-create_menu-item", title: "side-bar.create-sn", permission: [JMENUM.Permission.SN_DRAFT], path: '/sn/create' },
        { id: "sn-list_menu-item", title: "side-bar.sn-list", permission: [JMENUM.Permission.SN_VIEW], path: '/sn/list' }
      ]
    },
    {
      id: 'Job',
      title: "side-bar.job",
      permission: [JMENUM.Permission.JOBCARD_VIEW],
      path: '',
      subItems: [
        { 
          id: "job-card-create_menu-item", 
          title: "side-bar.job-card-create", 
          permission: [],
          path: '/job-card/create',
          // path: isHSDUser ? '/job-card/create' : null,
          // onClick: (e: MouseEvent) => {
          //   if (!isHSDUser) {
          //     e.preventDefault();
          //     AppDelegate.openSnackBar(JMLanguage.translate('pages.job-card.create.toast-msg.permission-denied'))
          //   }
          // },
        },
        {
          id: "job-card-create-standalone_menu-item",
          title: "side-bar.job-card-create-standalone",
          permission: [JMENUM.Permission.JOBCARD_CREATE],
          path: '/job-card/create/standalone',
        },
        { id: "job-list_menu-item", title: "side-bar.job-list", permission: [JMENUM.Permission.JOBCARD_VIEW], path: '/job-list' },
        // { id: "timesheet-work-centre-view_menu-item", title: "side-bar.work-centre-view", permission: Permission.snUpdate, path: '/timesheet/workcentre' },
        // { id: "timesheet-non-jm-job-view_menu-item", title: "side-bar.non-jm-job-view", permission: Permission.snUpdate, path: '/timesheet/batch' }
      ]
    },
    {
      id: 'pm',
      title: "side-bar.pm",
      permission: [
        JMENUM.Permission.PMPLAN_VIEW,
      ],
      path: '',
      subItems: [
        { 
          id: "create-standard-plan_menu-item", 
          title: "side-bar.create-standard-plan", 
          permission: [
            JMENUM.Permission.PMPLAN_VIEW,
            JMENUM.Permission.PMPLAN_CREATE
          ], 
          path: '/pm/standard-plan/create'
        },
        { 
          id: "pm-plan-list_menu-item", 
          title: "side-bar.pm-plan-list", 
          permission: [
            JMENUM.Permission.PMPLAN_VIEW,
          ], 
          path: '/pm/plan-list'
        },
        { 
          id: "pm-period-list_menu-item", 
          title: "side-bar.pm-period-list", 
          permission: [
            JMENUM.Permission.PMPLAN_VIEW,
          ], 
          path: '/pm/period-list'
        },
        { 
          id: "create-pm-job_menu-item", 
          title: "side-bar.create-pm-job", 
          permission: [
            JMENUM.Permission.PMJOB_CREATE,
          ], 
          path: '/pm/job/create'
        },
        { 
          id: "pm-job-list_menu-item", 
          title: "side-bar.pm-job-list", 
          permission: [
            JMENUM.Permission.PMJOB_VIEW,
          ], 
          path: '/pm/job-list'
        },
      ]
    },
    {
      id: 'SnBatchUpload',
      title: 'side-bar.batch-complete.batch-upload',
      permission: [JMENUM.Permission.JOBCARD_COMPLETE, JMENUM.Permission.JOBCARD_COMPLETE_ALL, JMENUM.Permission.TIMESHEET_VIEW, JMENUM.Permission.PMJOB_TIMESHEET, JMENUM.Permission.HA_BATCH_SERVICE_REPORT_UPLOAD],
      path: '',
      subItems: [
        {
          id: 'sn-batch-complete_menu-item',
          title: 'side-bar.batch-complete.batch-complete',
          permission: [JMENUM.Permission.JOBCARD_COMPLETE, JMENUM.Permission.JOBCARD_COMPLETE_ALL],
          path: '/sn/batch-complete'
        },{
          id: 'pm-job-timesheet_menu-item',
          title: 'side-bar.pm-job-timesheet',
          permission: [JMENUM.Permission.TIMESHEET_VIEW, JMENUM.Permission.PMJOB_TIMESHEET],
          featurekey: JMCONSTANT.JMFeature.JOB_CARD_TIMESHEET,
          path: '/pm/job/timesheet/'
        },{
          id: 'ha-service-report-batch-submission_menu-item',
          title: 'side-bar.batch-submission.batch-submission',
          permission: [JMENUM.Permission.HA_BATCH_SERVICE_REPORT_UPLOAD],
          featurekeyDisable: JMCONSTANT.JMFeature.JM_HSD_SERVICE_INTEGRATION,
          path: '/sn/batch-report-submission/ocr-submission',
        },{
          id: 'ha-service-report-batch-submission_menu-item-hsd',
          title: "side-bar.batch-submission.batch-submission",
          permission: [JMENUM.Permission.HA_BATCH_SERVICE_REPORT_UPLOAD],
          featurekey: JMCONSTANT.JMFeature.JM_HSD_SERVICE_INTEGRATION,
          href: new URL(`${environment.CCEP_HSD_WEB_HOST}/sn/batch-report-submission/ocr-submission`),
        }
        
      ]
    },
    {
      id: 'Timesheet',
      title: "side-bar.timesheet",
      permission: [JMENUM.Permission.TIMESHEET_VIEW],
      path: '',
      subItems: [
        { id: "timesheet-officer-view_menu-item", title: "side-bar.officer-view", permission: [JMENUM.Permission.TIMESHEET_VIEW], path: '/timesheet/officer' },
        // { id: "timesheet-work-centre-view_menu-item", title: "side-bar.work-centre-view", permission: Permission.snUpdate, path: '/timesheet/workcentre' },
        // { id: "timesheet-non-jm-job-view_menu-item", title: "side-bar.non-jm-job-view", permission: Permission.snUpdate, path: '/timesheet/batch' }
      ]
    },
    {
      id: 'adverse-weather-form-view',
      title: "side-bar.weather.eform",
      permission: [JMENUM.Permission.WEATHER_PREPARATION_FORM_VIEW, JMENUM.Permission.WEATHER_ON_SITE_VIEW],
      featurekey: JMCONSTANT.JMFeature.JM_ADVERSE_WEATHER_FORM,
      href: new URL(`${environment.CCEP_EFORM_WEB_HOST}/form-type`),
    },
    {
      id: 'Inspection',
      title: "side-bar.inspection",
      permission: [JMENUM.Permission.JOBCARD_INSPECTION],
      featurekey: JMCONSTANT.JMFeature.JOB_CARD_INSPECTION,
      path: '/inspection-report',
    },
    {
      id: 'Report',
      title: "side-bar.download-report",
      permission: [JMENUM.Permission.SN_VIEW],
      path: '/report-download',
      // subItems: [
      //   { id: "dowload-report-sn_menu-item", title: "side-bar.download-sn-report", permission: [JMENUM.Permission.SN_VIEW], path: '/report-download/sn' }, // Hard code
      //   { id: "dowload-report-job-card_menu-item", title: "side-bar.download-job-report", permission: [JMENUM.Permission.SN_VIEW], path: '/report-download/job' }, // Hard code
        // { id: "dowload-report-inspection_menu-item", title: "insepction", permission: JMENUM.Permission.SN_VIEW, path: '/report-download/insepction' }
        //   { id: "dowload-report-sn_menu-item", title: "side-bar.download-sn-report", permission: JMENUM.Permission.SN_VIEW, path: '' },
        //   { id: "dowload-report-job-card_menu-item", title: "side-bar.download-job-report", permission: JMENUM.Permission.SN_VIEW, path: '' }
      // ]
    },
    // {
    //   id: 'AdminModule',
    //   title: "side-bar.admin-module",
    //   permission: Permission.adminView',
    //   path: '',
    //   subItems: [
    //     { title: "side-bar.user-management", permission: Permission.adminView', path: '' },
    //     { title: "side-bar.system-parameter", permission: Permission.adminView', path: '' }
    //   ]
    // },
    {
      id: 'Costing',
      title: "side-bar.costing",
      permission: [
        JMENUM.Permission.JOBCARD_TECO,
        JMENUM.Permission.JOBCARD_TECO_ALL,
        JMENUM.Permission.JOBCARD_ZS01_UNTECO,
        JMENUM.Permission.JOBCARD_ZS02_UNTECO,
        JMENUM.Permission.JOBCARD_ZS04_UNTECO,
        JMENUM.Permission.JOBCARD_UNTECO_ALL
      ],
      path: '/costing',
      // subItems: [
      //   { 
      //     id: "teco-menu-item", 
      //     title: "side-bar.teco", 
      //     permission: [
      //       JMENUM.Permission.JOBCARD_TECO,
      //       JMENUM.Permission.JOBCARD_TECO_ALL
      //     ], 
      //     path: '/teco' 
      //   },
      //   {
      //     id: "un-teco-menu-item",
      //     title: "side-bar.un-teco",
      //     permission: 
      //     [
      //       JMENUM.Permission.JOBCARD_ZS01_UNTECO,
      //       JMENUM.Permission.JOBCARD_ZS02_UNTECO,
      //       JMENUM.Permission.JOBCARD_ZS04_UNTECO,
      //       JMENUM.Permission.JOBCARD_UNTECO_ALL,
      //     ],
      //     path: '/un-teco'
      //   },
      // ]
    },
    {
      id: 'CCePJMAccountReview',
      title: 'side-bar.ccep-jm-account-review',
      permission: [JMENUM.Permission.ACCOUNT_REVIEW_CREATE, JMENUM.Permission.ACCOUNT_REVIEW_SEND_EMAIL],
      featurekey: JMCONSTANT.JMFeature.JM_HALF_YEAR_REVIEW_USER_ACCOUNT,
      path: '',
      subItems: [
        {
          id: 'ccep-jm-account-review_send-email-confirmation',
          title: 'side-bar.ccep-jm-account-review.send-email-confirmation',
          permission: [JMENUM.Permission.ACCOUNT_REVIEW_SEND_EMAIL],
          featurekey: JMCONSTANT.JMFeature.JM_HALF_YEAR_REVIEW_USER_ACCOUNT,
          path: '/ccep-jm-account-review/send-email-confirmation'
        },{
          id: 'ccep-jm-account-review_upload-template',
          title: 'side-bar.ccep-jm-account-review.upload-template',
          permission: [JMENUM.Permission.ACCOUNT_REVIEW_CREATE],
          featurekey: JMCONSTANT.JMFeature.JM_HALF_YEAR_REVIEW_USER_ACCOUNT,
          path: '/ccep-jm-account-review/upload-template'
        }
      ]
    },
    {
      id: 'Training',
      title: "side-bar.training",
      permission: [],
      href: Constants.EMSDHOME
    },
  ],
  approval: [
    {
      id: 'Approval',
      title: "side-bar.approval-title",
      permission: [JMENUM.Permission.WCM_VIEW],
      path: '',
      subItems: [
        {
          id: 'approval-sn-job',
          title: "side-bar.approval-sn-job",
          path: '/setting/approvalList',
          permission: [
            JMENUM.Permission.SN_CANCEL_APPROVE,
            JMENUM.Permission.SN_CANCEL_APPROVE_ALL,
            JMENUM.Permission.SN_CANCEL_WITHDRAW,
            JMENUM.Permission.SN_ASSOCIATE_APPROVE,
            JMENUM.Permission.SN_ASSOCIATE_APPROVE_ALL,
            JMENUM.Permission.SN_ASSOCIATE_WITHDRAW,
            JMENUM.Permission.JOBCARD_COMPLETE_APPROVE,
            JMENUM.Permission.JOBCARD_COMPLETE_APPROVE_ALL,
            JMENUM.Permission.JOBCARD_COMPLETE_WITHDRAW,
          ],
        }, {
          id: 'approval-pm-plan',
          title: "side-bar.approval-pm-plan",
          path: '/setting/approvalList/pm/plan',
          permission: [
            JMENUM.Permission.PMPLAN_VIEW,
            JMENUM.Permission.PMPLAN_APPROVE
          ],
        }, {
          id: 'approval-pm-job',
          title: "side-bar.approval-pm-job",
          path: '/setting/approvalList/pm/job',
          permission: [
            JMENUM.Permission.PMJOB_VIEW,
            JMENUM.Permission.PMJOB_APPROVE
          ],
        }, {
          id: 'approval-team',
          title: "side-bar.approval-team",
          path: '/setting/approval',
          permission: [
            JMENUM.Permission.REQUEST_APPROVE,
            JMENUM.Permission.REQUEST_REJECT
          ],
        },
      ]
    }
  ],
  equipment: [
    {
      id: 'Equipment',
      title: "side-bar.equipment",
      permission: [JMENUM.Permission.WCM_VIEW],
      path: '',
      subItems: [
        {
          id: 'equipment-tag',
          title: "side-bar.equipment-tag",
          path: '/setting/equipment-tag'
        }, {
          id: 'equipment-list',
          title: "side-bar.equipment-list",
          path: '/setting/equipment-list'
        }, {
          id: 'ha-equipment-tag-list',
          title: "side-bar.ha-equipment-tag-list",
          path: '/setting/ha-equipment-tag-list'
        } 
      ]
    }
  ],
  contract: [
    {
      id: 'Contract',
      title: "side-bar.contract",
      permission: [JMENUM.Permission.WCM_VIEW],
      path: '',
      subItems: [
        { id: "contract-maintenance_menu-item", title: "side-bar.contract-maintenance", permission: [JMENUM.Permission.WCM_VIEW], path: '/setting/contractor' },
        { id: "transitional-dummy-contract_menu-item", title: "side-bar.transitional-dummy-contract", permission:[JMENUM.Permission.CONTRACT_VIEW], path: '/setting/dummy-contract' }
      ]
    }
  ],
  sla: [
    {
      id: 'SLA',
      title: "side-bar.sla",
      permission: [JMENUM.Permission.WCM_VIEW],
      path: '',
      subItems: [
        { id: "sla_menu-item", title: "side-bar.sla-list", permission: [JMENUM.Permission.WCM_VIEW], path: '/setting/sla' },
        // { id: "sla_report_menu-item", title: "side-bar.sla-report", permission: [JMENUM.Permission.WCM_VIEW], path: '/setting/sla-report' },
        ]
    }
  ],
  workCenter: [
    {
      id: 'workCenterMaintenace',
      title: "side-bar.work-center-maintenance",
      permission: [JMENUM.Permission.WCM_VIEW],
      path: '',
      subItems: [
        { title: "side-bar.teams", path: '/setting/team-settings' },
        { title: "side-bar.officers", path: '/setting/officer-settings' },
        { title: "side-bar.general", path: '/setting/general-settings' },
        { title: "side-bar.routing-rules", path: '/setting/routing-rules-list' },
      ]
    }
  ],
  team: [
    {
      id: 'teamMaintenace',
      title: "side-bar.teams",
      permission: [JMENUM.Permission.WCM_VIEW],
      path: '',
      subItems: [
        { title: "side-bar.teams", path: '/setting/team-settings' },
        { title: "side-bar.team-members", path: '/setting/team-settings-member' },
        { title: "side-bar.team-instruction", path: '/setting/team-settings-instruction' },
      ]
    }
  ],
  personal: [
    {
      id: 'PersonalSettings',
      title: "side-bar.personal-settings",
      path: '',
      subItems: [
        { title: "side-bar.profile", path: '/setting/profile' },
        { title: "side-bar.authorization", path: '/setting/authorization' },
        { title: "side-bar.acting-appointment", path: '/setting/posting' },

      ]
    }
  ],
  haDefaults: [
    {
      id: 'HADefaultSettings',
      title: "side-bar.ha-defaults",
      path: '',
      subItems: [
        { title: "side-bar.ha-defaults", path: '/setting/admin/ha-defaults' },
        { title: "side-bar.hospital-code", path: '/setting/admin/hospital-code' },
      ]
    }
  ],
  functionalLocation: [
    {
      id: 'PersonalSettings',
      title: "side-bar.functional-location",
      path: '',
      subItems: [
        { title: "side-bar.functional-location.list", path: '/setting/admin/functional-location' },
        { title: "side-bar.functional-location.pending-upload.chinese", path: '/setting/admin/functional-location-pending-chinese', permission: [JMENUM.Permission.FLMS_UPLOAD, JMENUM.Permission.FLMS_UPLOAD_CHI] },
        { title: "side-bar.functional-location.pending-upload.main", path: '/setting/admin/functional-location-pending-main', permission: [JMENUM.Permission.FLMS_UPLOAD] },
      ],
    }
  ],
  enquiry: [{
    id: 'Enquiry',
    title: "side-bar.enquiry",
    permission: [JMENUM.Permission.ENQUIRY_VIEW],
    path: '',
    subItems: [
      {
        id: 'enquiry-create_menu-item',
        title: "side-bar.enquiry.create",
        permission: [JMENUM.Permission.ENQUIRY_CREATE],
        path: '/enquiry/create'
      },
      {
        id: 'enquiry-list_menu-item',
        title: "side-bar.enquiry.list",
        permission: [JMENUM.Permission.ENQUIRY_VIEW, JMENUM.Permission.ENQUIRY_VIEW_ALL],
        path: '/enquiry/list'
      },
    ]
  }],
  empty: [

  ]
};

//========================================================================
// Additional subitem
export const SIDEBAR_HA_ENQUIPMENT_SUBITEMS = {
  id: 'ha-equipment-list',
  title: "side-bar.ha-equipment-list",
  path: '/setting/ha-equipment-list',
  permission: [JMENUM.Permission.HSD_EQUIPMENT_VIEW]
}


//=========================================================================
// Interface
export interface SidebarItemInterface {
  id        ?: string,
  title     : string,
  path      ?: string,
  href      ?: string,
  permission?: JMENUM.Permission[],
  subItems  ?: SidebarItemInterface[]
  isHidden  ?: boolean,
}
