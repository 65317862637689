<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding">
    <div class="row justify-content-between no-gutters">
      <h4 class="title">
        {{ 'pages.pm-job-equipment-list.select-equipment-title' | translate }}
      </h4>
      <h5>
        {{
          'pages.pm-job-equipment-list.selected'
            | translate: [numberOfRowsSelected, tablexParam['content'] && tablexParam['content'].length]
        }}
      </h5>
    </div>
    <hr class="underline" />

    <div id="multi-tab-content-container" class="container-fluid">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabOptions | keyvalue" class="nav-item">
          <a class="nav-link" [class.active]="activeTab == tab.value" (click)="onClickSetActiveTab(tab.value)">{{
            tab.value
          }}</a>
        </li>
      </ul>
      <div class="content">
        <ng-container *ngIf="activeTab">
          <div *ngIf="pageMode == JMPageMode.EDIT" class="buttons-wrapper">
            <button
              class="btn standard brand-blue submit-btn"
              (click)="onSubmitClick()"
              [disabled]="numberOfRowsSelected <= 0"
            >
              {{ submitBtnName }}
            </button>

            <button
              class="btn standard brand-blue"
              [disabled]="numberOfRowsSelected >= this.tablexParam?.content?.length"
              (click)="onSelectAll()"
            >
              {{ 'pages.pm-period-equipment-list.button.select-all' | translate }}
            </button>

            <button class="btn standard brand-blue" [disabled]="numberOfRowsSelected <= 0" (click)="onUnselectAll()">
              {{ 'pages.pm-period-equipment-list.button.unselect-all' | translate }}
            </button>
          </div>
          <app-tablex id="equipment-list-table" #equipmentListTable [tablexParam]="tablexParam"></app-tablex>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Action Sidebar -->
  <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
    <app-action-sidebar
      [buttonData]="actionButtonData"
      [disabled]="disabledActionSideBar"
      (clickEvent)="onActionButtonClicked($event)"
    >
    </app-action-sidebar>
  </ng-container>
</div>
