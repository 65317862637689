import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApprovalComponent } from 'src/app/pages/approval/approval.component';
import { ApprovalListPmComponent } from 'src/app/setting-approval/approval-list-pm/approval-list-pm.component';
import { AuthorizationComponent } from 'src/app/pages/authorization/authorization.component';
import { CustomersComponent } from 'src/app/pages/customers/customers';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard';
import { EquipmentListComponent } from 'src/app/pages/equipment-list/equipment-list';
import { GeneralSettingsComponent } from 'src/app/pages/general-settings/general-settings.component';
import { LocationsComponent } from 'src/app/pages/locations/locations';
import { LoginComponent } from 'src/app/pages/login/login';
import { LogoutComponent } from 'src/app/logout/logout.component';
import { ResetPasswordComponent } from 'src/app/pages/reset-password/reset-password.component';
import { PostingComponent } from 'src/app/pages/posting/posting.component';
import { SettingsComponent } from 'src/app/pages/settings/settings';
import { SnCreateComponent } from 'src/app/pages/sn-create/sn-create.component';
import { SnListComponent } from 'src/app/pages/sn-list/sn-list.component';
import { SnViewComponent } from 'src/app/ui/pages/sn-view/sn-view.component';
import { SnNonPmsmcViewComponent } from 'src/app/pages/sn-non-pmsmc-view/sn-non-pmsmc-view';
import { UserManagementRoleComponent } from 'src/app/pages/user-management-role/user-management-role.component';
import { UserManagementUsersComponent } from 'src/app/pages/user-management-users/user-management-users.component';
import { UserProfileComponent } from 'src/app/pages/user-profile/user-profile.component';
import { ContractorsComponent } from 'src/app/ui/pages/contractors/contractors.component';
import { DummyContractComponent } from 'src/app/ui/pages/dummy-contract/dummy-contract.component';
import { OfficerSettingsComponent } from 'src/app/ui/pages/officer-settings/officer-settings.component';
import { ReportDownloadComponent } from 'src/app/ui/pages/report-download/report-download.component';
import { RoutingRulesListComponent } from 'src/app/ui/pages/routing-rules-list/routing-rules-list';
import { RoutingRulesComponent } from 'src/app/ui/pages/routing-rules/routing-rules';
import { TeamSettingsInstructionComponent } from 'src/app/ui/pages/team-settings-instruction/team-settings-instruction';
import { TeamSettingsMemberComponent } from "src/app/ui/pages/team-settings-member/team-settings-member";
import { TeamSettingsComponent } from "src/app/ui/pages/team-settings/team-settings";
import { TimesheetBatchComponent } from 'src/app/ui/pages/timesheet-batch/timesheet-batch.component';
import { TimesheetJobComponent } from 'src/app/ui/pages/timesheet-job/timesheet-job';
import { TimesheetOfficerComponent } from 'src/app/ui/pages/timesheet-officer/timesheet-officer';
import { TimesheetWorkcentreComponent } from 'src/app/ui/pages/timesheet-workcentre/timesheet-workcentre.component';
import { environment } from 'src/environments/environment';
import { CcsErrorLogComponent } from './pages/ccs-error-log/ccs-error-log.component';
import { TecoComponent } from 'src/app/ui/pages/teco/teco.component';
import { UnTecoComponent } from 'src/app/ui/pages/un-teco/un-teco.component';
import { SettingsDropdownComponent } from './pages/settings-dropdown/settings-dropdown.component';
import { SettingsHaDefaultsComponent } from './pages/settings-ha-defaults/settings-ha-defaults.component';
import { SettingsWorkCentresComponent } from './pages/settings-work-centres/settings-work-centres.component';
import { SettingsHospitalCodeComponent } from './pages/settings-hospital-code/settings-hospital-code.component';
import { SettingsSLAComponent } from './ui/pages/sla/sla.component';
import { StockReserveDetailComponent } from './stock-reserve/stock-reserve-detail/stock-reserve-detail.component';
import { StockReserveListComponent } from './stock-reserve/stock-reserve-list/stock-reserve-list.component';
import { StockReserveListGuard } from './stock-reserve/stock-reserve-list/stock-reserve-list.guard';
import { HaEquipmentListComponent } from './pages/ha-equipment-list/ha-equipment-list.component';
import { HAEquipmentTagListComponent } from './pages/ha-equipment-tag-list/ha-equipment-tag-list.component';
import { CCSEquipmentTagListComponent } from './pages/ccs-equipment-tag-list/ccs-equipment-tag-list.component';
import { HashtagGroupComponent } from 'src/app/ui/pages/hashtag-group/hashtag-group.component';
import { FLMSComponent } from './ui/pages/flms/flms.component';
import { FlmsPendingChineseComponent } from './ui/pages/flms-pending-chinese/flms-pending-chinese.component';
import { FlmsPendingMainComponent } from './ui/pages/flms-pending-main/flms-pending-main.component';
import { SnViewOperationLogComponent } from './pages/sn-view-operation-log/sn-view-operation-log.component';
import { SnAttachmentComponent } from './pages/sn-attachment/sn-attachment.component';
import { SnEditComponent } from './ui/pages/sn-edit/sn-edit.component';
import { JobcardAttachmentComponent } from './ui/components/jobcard-attachment/jobcard-attachment.component';
import { JobcardViewComponent } from './ui/pages/jobcard-view/jobcard-view.component';
import { JobcardEditComponent } from './ui/pages/jobcard-edit/jobcard-edit.component';
import { JobcardCreateComponent } from './ui/pages/jobcard-create/jobcard-create.component';
import { JobCardStandaloneCreateComponent } from './job-card/job-card-standalone-create/job-card-standalone-create.component';
import { JobListComponent } from './ui/pages/job-list/job-list.component';
import { EnquiryFormComponent } from 'src/app/pages/enquiry-form/enquiry-form.component';
import { EnquiryListComponent } from 'src/app/pages/enquiry-list/enquiry-list.component';
import { EnquiryViewComponent } from 'src/app/pages/enquiry-view/enquiry-view.component';
import { ApprovalListComponent } from 'src/app/ui/pages/approval-list/approval-list.component';
import { PmPlanListComponent } from './ui/pages/pm-plan-list/pm-plan-list.component';
import { ReportListComponent } from './pages/report-list/report-list.component';
import { CostingListComponent } from './pages/costing-list/costing-list.component';
import { PmPeriodListComponent } from './ui/pages/pm-period-list/pm-period-list.component';
import { PmJobListComponent } from './ui/pages/pm-job-list/pm-job-list.component';
import { PmPlanEquipmentListComponent } from './ui/pages/pm-plan-equipment-list/pm-plan-equipment-list.component';
import { PmPeriodEquipmentListComponent } from './ui/pages/pm-period-equipment-list/pm-period-equipment-list.component';
import { PmOutstandingEquipmentListComponent } from './pm/pm-outstanding-equipment-list/pm-outstanding-equipment-list.component';
import { PmJobAttachmentComponent } from './pm-job/pm-job-attachment/pm-job-attachment.component';
import { PmPlanViewComponent } from './ui/pages/pm-plan-view/pm-plan-view.component';
import { PmPlanEditComponent } from './ui/pages/pm-plan-edit/pm-plan-edit.component';
import { PmJobEditComponent } from './ui/pages/pm-job-edit/pm-job-edit.component';
import { SnBatchCompleteComponent } from './sn-batch-complete/sn-batch-complete/sn-batch-complete.component';
import { SnBatchHistoryComponent } from './sn-batch-complete/sn-batch-history/sn-batch-history.component';
import { SnBatchReportSubmissionHistoryComponent } from './sn-batch-report-submission-history/sn-batch-report-submission-history.component'
import { PmJobEquipmentListComponent } from './ui/pages/pm-job-equipment-list/pm-job-equipment-list.component'
import { PmJobViewComponent } from './ui/pages/pm-job-view/pm-job-view.component';
import { PmJobTimesheetComponent } from './pm-job/pm-job-timesheet/pm-job-timesheet.component';
import { PmJobTimesheetHistoryComponent } from './pm-job/pm-job-timesheet-history/pm-job-timesheet-history.component';
import { SnBatchReportSubmissionComponent } from './sn-batch-report-submission/sn-batch-submission/sn-batch-report-submission.component';
import { CcepPortalSsoLoginSuccessComponent } from './ccep-portal-sso/ccep-portal-sso-login-success/ccep-portal-sso-login-success.component';
import { CcepPortalSsoLogoutSuccessComponent } from './ccep-portal-sso/ccep-portal-sso-logout-success/ccep-portal-sso-logout-success.component';
import { ReleaseNoteComponent } from './setting-release-note/release-note/release-note.component';
import { SnEFormComponent } from './pages/sn-eForm/sn-eForm.component';
import { ContractorCMJobComponent } from './ui/pages/report-download/contractor-cm-job/contractor-cm-job.component';
import { PmsmcStatisticsComponent } from './ui/pages/report-download/pmsmc-statistics/pmsmc-statistics.component';
import { SpecialRequestAlertListComponent } from './ui/pages/special-request-alert/special-request-alert-list/special-request-alert-list.component';
import { InspectionManagementComponent } from './ui/components/inspection-management/inspection-management.component';
import { ContractInspectionReportSubmissionComponent } from './pages/contract-inspection-report-submission/contract-inspection-report-submission.component';
import { ContractInspectionReportSubmissionHistoryComponent } from './pages/contract-inspection-report-submission-history/contract-inspection-report-submission-history.component';
import { PmJobInspectionManagementComponent } from './ui/components/pm-job-inspection-management/pm-job-inspection-management.component';
import { TimesheetInputFormComponent } from './ui/components/timesheet/timesheet-input-form/timesheet-input-form.component';
import { TimesheetJobV2Component } from './ui/pages/timesheet-job-v2/timesheet-job-v2.component';
import { WeatherEformComponent } from './redirects/weather-eform/weather-eform.component';
import { CCePJMAccountReviewSendEmailComponent } from './ui/pages/ccep-jm-account-review/send-email-confirmation/ccep-jm-account-review-send-email.component';
import { CCePJMAccountReviewUploadTemplateComponent } from './ui/pages/ccep-jm-account-review/upload-template/ccep-jm-account-review-upload-template.component';
import { CCePJMAccountReviewEmailHistoryComponent } from './ui/pages/ccep-jm-account-review/review-email-history/ccep-jm-account-review-email-history.component';
import { CCePJMAccountReviewSubmissionHistoryComponent } from './ui/pages/ccep-jm-account-review/review-submission-history/ccep-jm-account-review-submission-history.component';
import { NoPermissionComponent } from './ui/pages/no-permission/no-permission.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  {
    path: 'setting',
    children: [
      { path: '', component: SettingsComponent },
      { path: 'equipment-list', component: EquipmentListComponent },
      { path: 'ccs-equipment-tag-list', component: CCSEquipmentTagListComponent },
      { path: 'ha-equipment-tag-list', component: HAEquipmentTagListComponent },
      // { path: 'hashtag-group', component: HashtagGroupComponent},
      { path: 'ha-equipment-list', component: HaEquipmentListComponent },
      { path: 'profile', component: UserProfileComponent },
      { path: 'authorization', component: AuthorizationComponent },
      { path: 'posting', component: PostingComponent },
      { path: 'approval', component: ApprovalComponent },
      {
        path: 'approvalList', children: [
          { path: '', component: ApprovalListComponent },
          { path: 'pm/:pmType', component: ApprovalListPmComponent },
        ]
      },
      { path: 'routing-rules', component: RoutingRulesComponent },
      { path: 'routing-rules-list', component: RoutingRulesListComponent },
      { path: 'general-settings', component: GeneralSettingsComponent },
      { path: "team-settings", component: TeamSettingsComponent },
      { path: "team-settings-member", component: TeamSettingsMemberComponent },
      { path: "team-settings-instruction", component: TeamSettingsInstructionComponent },
      { path: "customers", component: CustomersComponent },
      { path: "locations", component: LocationsComponent },
      { path: "officer-settings", component: OfficerSettingsComponent },
      { path: "contractor", component: ContractorsComponent },
      { path: "dummy-contract", component: DummyContractComponent },
      { path: 'sla', component: SettingsSLAComponent },
      { path: 'release-note/:platform', component: ReleaseNoteComponent },
      {
        path: 'admin', children: [
          { path: 'role', component: UserManagementRoleComponent },
          { path: 'user', component: UserManagementUsersComponent },
          { path: 'ccs-error-log', component: CcsErrorLogComponent },
          { path: 'dropdown', component: SettingsDropdownComponent },
          { path: 'work-centres', component: SettingsWorkCentresComponent },
          { path: 'ha-defaults', component: SettingsHaDefaultsComponent },
          { path: 'hospital-code', component: SettingsHospitalCodeComponent },
          { path: 'functional-location', component: FLMSComponent },
          { path: 'functional-location-pending-chinese', component: FlmsPendingChineseComponent },
          { path: 'functional-location-pending-main', component: FlmsPendingMainComponent },
          { path: 'hashtag-group', component: HashtagGroupComponent},
          { path: 'special-request-alert', component: SpecialRequestAlertListComponent},
        ]
      },
    ]
  },
  { path: 'inspection-report',
  children: [
    { path: '', component: ContractInspectionReportSubmissionComponent },
    { path: 'upload-history', component: ContractInspectionReportSubmissionHistoryComponent },
  ]
  },
  { path: 'report-download',
    children: [
      { path: '', component: ReportListComponent },
      { path: 'contractor-cm-job', component: ContractorCMJobComponent },
      { path: 'pmsmc-statistics', component: PmsmcStatisticsComponent },
      { path: ':type', component: ReportDownloadComponent },
    ]
   },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'no-permission', component: NoPermissionComponent },
  { path: 'reset/password', component: ResetPasswordComponent },
  { path: "timesheet/job/:jobCardId", component: TimesheetJobComponent },
  { path: "timesheet/job/v2/:jobCardId", component: TimesheetJobV2Component },
  { path: "timesheet/officer", component: TimesheetOfficerComponent },
  { path: "timesheet/workcentre", component: TimesheetWorkcentreComponent },
  { path: "timesheet/batch", component: TimesheetBatchComponent },
  { path: "timesheet/form", component: TimesheetInputFormComponent},
  { path: "costing", component: CostingListComponent },
  { path: "teco", component: TecoComponent },
  { path: "un-teco", component: UnTecoComponent },
  { path: "job-list", component: JobListComponent },
  {
    path: 'sn',
    children: [
      {
        path: 'create', children: [
          { path: '', component: SnCreateComponent },
          { path: ':snNumber/:mode', component: SnCreateComponent },
        ]
      },
      {
        path: 'view/:snNumber',
        children: [
          { path: '', component: SnViewComponent },
          { path: 'operation-log', component: SnViewOperationLogComponent },
          { path: 'attachment', component: SnAttachmentComponent },
          { path: 'eForm', component: SnEFormComponent },
          { path: 'edit', component: SnEditComponent},
          { path: 'edit/attachment', component: SnAttachmentComponent},
        ]
      },
      { path: 'list', component: SnListComponent },
      { path: 'non-pmsmc/view/:snToken', component: SnNonPmsmcViewComponent },
      {
        path: 'batch-complete', children: [
          { path: '', component: SnBatchCompleteComponent },
          { path: 'upload-history', component: SnBatchHistoryComponent },
        ]
      },
      {
        path: 'batch-report-submission', children: [
          { path: 'ocr-submission', component: SnBatchReportSubmissionComponent },
          { path: 'upload-history', component: SnBatchReportSubmissionHistoryComponent },
          { path: 'upload-history/:batchSignedOffResultId', component: SnBatchReportSubmissionHistoryComponent },
        ]
      },
    ]
  },
  {
    path: 'job-card',
    children: [
      {
        path: 'create',
        children: [
          { path: '', component: JobcardCreateComponent },
          { path: 'standalone', component: JobCardStandaloneCreateComponent},
        ],
      },
      {
        path: 'view/:jobCardNumber',
        children: [
          { path: '', component: JobcardViewComponent },
          { path: 'attachment', component: JobcardAttachmentComponent },
          { path: 'stock-detail', component: StockReserveDetailComponent },
          { path: 'stock-reserve', component: StockReserveListComponent, canDeactivate: [StockReserveListGuard] },
        ],
      },
      {
        path: 'edit/:jobCardNumber',
        children: [
          { path: '', component: JobcardEditComponent },
        ],
      },
      {
        path: 'inspect/:jobCardNumber',
        children: [
          { path: '', component: InspectionManagementComponent },
        ],
      },
    ]
  },
  {
    path: 'enquiry',
    children: [
      { path: '', component: EnquiryFormComponent },
      { path: 'create', component: EnquiryFormComponent },
      { path: 'list', component: EnquiryListComponent },
      { path: 'view/:id', component: EnquiryViewComponent },
    ]
  },
  {
    path: 'pm',
    children: [
      {
        path: 'standard-plan', children: [
          { path: 'create', component: PmPlanEditComponent },
          { path: 'view/:pmNumber', component: PmPlanViewComponent },
          { path: 'equipment-list/:pmNumber', component: PmPlanEquipmentListComponent },
          { path: 'equipment-list/:pmNumber/:periodId', component: PmPeriodEquipmentListComponent },
          { path: ':mode/:pmNumber', component: PmPlanEditComponent },
        ]
      },
      {
        path: 'job', children: [
          { path: 'create', component: PmJobEditComponent },
          { path: 'equipment-list/:mode/:periodId/:jobId',  component: PmJobEquipmentListComponent},
          {
            path: 'view/:jobNumber', 
            children: [
              { path: '', component: PmJobViewComponent },
              { path: 'attachment', component: PmJobAttachmentComponent }
            ]
          },
          {
            path: 'inspect/:jobNumber',
            children: [
              { path: '', component: PmJobInspectionManagementComponent },
            ],
          },
          { path: 'equipment-list/:periodId/:jobId',  component: PmJobEquipmentListComponent},
          { path: 'timesheet', children: [
              { path: '', component: PmJobTimesheetComponent },
              { path: 'upload-history', component: PmJobTimesheetHistoryComponent }
            ]
          },
          { path: ':mode/:jobNumber', component: PmJobEditComponent },
        ]
      },
      { path: 'plan-list', component: PmPlanListComponent },
      { path: 'period-list', component: PmPeriodListComponent },
      { path: 'job-list', component: PmJobListComponent },
      { path: 'equipments/outstanding', children: [
        { path: 'inhouse', component: PmOutstandingEquipmentListComponent },
        { path: 'contract', component: PmOutstandingEquipmentListComponent },
      ]}
    ]
  },
  { path: 'ccep-portal', children: [
    { path: 'loginSuccess', component: CcepPortalSsoLoginSuccessComponent },
    { path: 'logoutSuccess', component: CcepPortalSsoLogoutSuccessComponent },
  ]},
  { path: 'weather', children: [
    { path: '**', component: WeatherEformComponent },
  ]},
  { path: 'ccep-jm-account-review', children: [
    { path: 'send-email-confirmation', component: CCePJMAccountReviewSendEmailComponent },
    { path: 'upload-template', component: CCePJMAccountReviewUploadTemplateComponent },
    { path: 'email-history', component: CCePJMAccountReviewEmailHistoryComponent },
    { path: 'submission-history', component: CCePJMAccountReviewSubmissionHistoryComponent },
  ]},
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { useHash: environment.useHash, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
