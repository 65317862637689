<div class="edit-functional-location-wrap">

    <div class="title-wrap mx-0 mb-4">
        <h3 class="d-inline">{{ "pages.edit-functional-location.slider-panel.title" | translate }}</h3>
        <div class="button-wrap text-right d-inline-block float-right">
            <app-submit-button [id]="'edit-functional-location-save-button'" [text]="editMode==locationEditMode.LIST?'global.save':'global.submit'"
                [buttonClass]="' standard brand-amber mr-0'" [iconClass]="'fa-check'"
                (onClick)="onSaveButtonClicked($event)">
                <!-- [isLoading]="editParam.isPanelSubmitLoading" -->
            </app-submit-button>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-4">
            {{ 'pages.edit-functional-location.slider-panel.functional-location' | translate }}
        </div>
        <div class="col-8 pl-0">
            {{locationParam.code}}
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-4">
            {{ 'pages.edit-functional-location.slider-panel.description.en' | translate }}
        </div>
        <div class="col-8 pl-0" *ngIf="locationParam.description">
            {{locationParam.description.en}}
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-4">
            {{ 'pages.edit-functional-location.slider-panel.address1' | translate }}
        </div>
        <div class="col-8 pl-0">
            {{locationParam.address1}}
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-4">
            {{ 'pages.edit-functional-location.slider-panel.address2' | translate }}
        </div>
        <div class="col-8 pl-0">
            {{locationParam.address2}}
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-4">
            {{ 'pages.edit-functional-location.slider-panel.address3' | translate }}
        </div>
        <div class="col-8 pl-0">
            {{locationParam.address3}}
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-4">
            {{ 'pages.edit-functional-location.slider-panel.district' | translate }}
        </div>
        <div class="col-8 pl-0">
            {{locationParam.district}}
        </div>
    </div>

    <hr>

    <ng-container *ngIf="canUpdateDetail()">
        <div class="row mb-3">
            <div class="col-4">
                {{ 'pages.edit-functional-location.slider-panel.category-code' | translate }}
            </div>
            <div class="col-8 pl-0">
                <ng-select id="category-code" name="category-code" [closeOnSelect]="true"
                    [ngModelOptions]="{standalone: true}" bindLabel="name" bindValue="categoryCode"
                    [items]="categoryCodeOption" [(ngModel)]="locationParam.categoryCode"
                    placeholder="{{ 'global.unclassified' | translate }}"></ng-select>
                <!--
                
                [ngClass]="{'input-error':errorFields.unTecoReasonCode}" 
                [(ngModel)]="unTecoParam.unTecoReasonCode" 
            -->
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-4">
                {{ 'pages.edit-functional-location.slider-panel.classification-code' | translate }}
            </div>
            <div class="col-8 pl-0">
                <ng-select id="classification-code" name="classification-code" [closeOnSelect]="true"
                    [ngModelOptions]="{standalone: true}" bindLabel="name" bindValue="classificationCode"
                    [items]="classificationCodeOption" [(ngModel)]="locationParam.classificationCode"
                    placeholder="{{ 'global.unclassified' | translate }}"></ng-select>
                <!--   
                [ngClass]="{'input-error':errorFields.unTecoReasonCode}" 
                [(ngModel)]="unTecoParam.unTecoReasonCode" 
            -->
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-4">
                {{ 'pages.edit-functional-location.slider-panel.level' | translate }}
            </div>
            <div class="col-8 pl-0">
                <ng-select id="level" name="level" [closeOnSelect]="true" [ngModelOptions]="{standalone: true}"
                    bindLabel="label" bindValue="value" [items]="levelOption" [(ngModel)]="locationParam.level"
                    placeholder="{{ 'global.unclassified' | translate }}"></ng-select>
                <!--   
                [ngClass]="{'input-error':errorFields.unTecoReasonCode}" 
                [(ngModel)]="unTecoParam.unTecoReasonCode" 
            -->
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-4">
                {{ 'pages.edit-functional-location.slider-panel.venue' | translate }}
            </div>
            <div class="radio-group col-8">
                <div class="btn-group btn-group-toggle priority-toggle-button" data-toggle="buttons">
                    <button id="venue-yes-button" class="btn radio-wrap yes" (click)="locationParam.isVenue=true"
                        [ngClass]="{'active':locationParam.isVenue == true}">
                        <input type="radio" name="priority-radio-options">
                        <!-- (click)="onRadioChanged(locationParam.venue,true)"   -->
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.yes' | translate}}
                    </button>
                    <button id="venue-no-button" class="btn radio-wrap no" (click)="locationParam.isVenue=false"
                        [ngClass]="{'active':locationParam.isVenue == false}">
                        <!-- [ngClass]="'active':this.snView.priority == false}" -->
                        <input type="radio" name="priority-radio-options">
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.no' | translate}}
                    </button>
                    <button id="venue-undefined-button" class="btn radio-wrap undefined"
                        (click)="locationParam.isVenue=undefined"
                        [ngClass]="{'active':locationParam.isVenue == undefined}">
                        <input type="radio" name="priority-radio-options">
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.undefined' | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-4">
                {{ 'pages.edit-functional-location.slider-panel.building' | translate }}
            </div>
            <div class="radio-group col-8">
                <div class="btn-group btn-group-toggle priority-toggle-button" data-toggle="buttons">
                    <button id="building-yes-button" class="btn radio-wrap yes" (click)="locationParam.isBuilding=true"
                        [ngClass]="{'active':locationParam.isBuilding == true}">
                        <input type="radio" name="priority-radio-options">
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.yes' | translate}}
                    </button>
                    <button id="building-no-button" class="btn radio-wrap no" (click)="locationParam.isBuilding=false"
                        [ngClass]="{'active':locationParam.isBuilding == false}">
                        <input type="radio" name="priority-radio-options">
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.no' | translate}}
                    </button>
                    <button id="building-undefined-button" class="btn radio-wrap undefined"
                        (click)="locationParam.isBuilding=undefined"
                        [ngClass]="{'active':locationParam.isBuilding == undefined}">
                        <input type="radio" name="priority-radio-options">
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.undefined' | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-4">
                {{ 'pages.edit-functional-location.slider-panel.system-venue' | translate }}
            </div>
            <div class="radio-group col-8">
                <div class="btn-group btn-group-toggle priority-toggle-button" data-toggle="buttons">
                    <button id="system-venue-yes-button" class="btn radio-wrap yes"
                        (click)="locationParam.isSystemVenue=true"
                        [ngClass]="{'active':locationParam.isSystemVenue == true}">
                        <input type="radio" name="priority-radio-options">
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.yes' | translate}}
                    </button>
                    <button id="system-venue-no-button" class="btn radio-wrap no"
                        (click)="locationParam.isSystemVenue=false"
                        [ngClass]="{'active':locationParam.isSystemVenue == false}">
                        <input type="radio" name="priority-radio-options">
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.no' | translate}}
                    </button>
                    <button id="system-venue-undefined-button" class="btn radio-wrap undefined"
                        (click)="locationParam.isSystemVenue=undefined"
                        [ngClass]="{'active':locationParam.isSystemVenue == undefined}">
                        <input type="radio" name="priority-radio-options">
                        <!-- [(ngModel)]="this.snView.priority" -->
                        {{ 'global.undefined' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="canUpdateFehdVenues()">
        <div class="row mb-3">
            <div class="col-4">
                {{ 'pages.edit-functional-location.slider-panel.fehd-venues' | translate }}
            </div>
            <div class="custom-checkbox position-relative custom-control d-inline-block mr-4">
                <input type="checkbox" id="fehd-venue-toilet-checkbox" class="custom-control-input"
                    [(ngModel)]="fehdVenuesCheckedList[FehdVenue.TOILET]" [value]="FehdVenue.TOILET" (change)="onFehdVenuesChanged()">
                <label for="fehd-venue-toilet-checkbox" class="custom-control-label">
                {{'fehd-venue.toilet' | translate}}
                </label>
            </div>
            <div class="custom-checkbox position-relative custom-control d-inline-block mr-4">
                <input type="checkbox" id="fehd-venue-market-checkbox" class="custom-control-input" (change)="onFehdVenuesChanged()"
                    [(ngModel)]="fehdVenuesCheckedList[FehdVenue.MARKET]" [value]="FehdVenue.MARKET" (change)="onFehdVenuesChanged()">
                <label for="fehd-venue-market-checkbox" class="custom-control-label">
                {{'fehd-venue.market' | translate}}
                </label>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="canUpdateChineseDescription()">

        <div class="row mb-3">
            <div class="col-4">
                {{ 'pages.edit-functional-location.slider-panel.description.zh' | translate }}
            </div>
            <div class="col-8 pl-0" *ngIf="locationParam.description">
                <textarea id="chinese-description" name="chinese-description" class="form-control mt-2" rows="5"
                    [(ngModel)]="locationParam.description.zh" maxlength="150"></textarea>
                <!--  [disabled]="unTecoParam.isPanelSubmitLoading" -->
            </div>
        </div>

    </ng-container>

</div>