<div class="d-flex justify-content-between mb-2 align-items-center">
  <div class="font-size-xl align-self-end">
    {{ 'pages.pm-job-list.adv-criteria' | translate }}
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn icon-btn brand-amber mr-2 img-btn" (click)="onClickClear()" [disabled]="isSearching">
      <i class="fas fa-undo"></i>
    </button>
    <button class="btn icon-btn brand-blue img-btn" (click)="onClickSearch()" [disabled]="isSearching">
      <div *ngIf="isSearching" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
      <i class="fas fa-search white font-size-l"></i>
    </button>
    <button class="btn icon-btn ml-2 d-inline-block d-xl-none navbar-toggler"
      (click)="isCollapse = !isCollapse"
    >
      <i class="fas fa-chevron-up" [class.d-none]="isCollapse"></i>
      <i class="fas fa-chevron-down" [class.d-none]="!isCollapse"></i>
    </button>
  </div>
</div>
<hr class="underline" />

<div class="navbar navbar-expand-xl px-0">
  <div
    class="row justify-content-between mx-0 collapse navbar-collapse align-items-start"
    id="search-field-wrap"
    [ngbCollapse]="isCollapse">
    <div class="col-12 col-md-6 col-xl-12 pl-0 pr-0 pr-md-3 pr-xl-0">
      <!--<ng-select
      placeholder="{{'pages.pm-job-list.table-column.pm-contract-no' | translate }}"
      bindLabel="contractNumber"
      bindValue="contractNumber"
      [readonly]="false"
      [multiple]="true"
      [items]="contractInput.optionList"
      (search)="onContractSearch($event)"
      (change)="onContractChange($event)"
      (clear)="onContractClear()"
      (scrollToEnd)="onContractScrollToEnd()"
      [(ngModel)]="searchSelections.contractNumber">
    </ng-select>-->
    <ng-select
    class="mt-3"
    placeholder="{{'pages.pm-job-list.table-column.pm-plan-no' | translate }}"
    bindLabel="pmPlanNumber"
    bindValue="pmPlanNumber"
    [readonly]="!searchSelections.contractNumber"
    [items]="pmPlanInput.optionList"
    (search)="onPmPlanSearch($event)"
    (change)="onPmPlanChange($event)"
    (clear)="onPmPlanClear()"
    (scrollToEnd)="onPmPlanScrollToEnd()"
    [(ngModel)]="searchSelections.pmPlanNumber">
  </ng-select>
  <ng-select
    class="mt-3"
    placeholder="{{'pages.pm-job-list.table-column.pm-plan-peroid' | translate }}"
    bindLabel="name"
    bindValue="code"
    [readonly]="!searchSelections.pmPlanNumber"
    [items]="pmPeriodInput.optionList"
    [searchable]="false"
    (search)="onPmPeriodSearch($event)"
    (change)="onPmPeriodChange($event)"
    (clear)="onPmPeriodClear()"
    (scrollToEnd)="onPmPeriodScrollToEnd()"
    [(ngModel)]="searchSelections.pmPeriod">
  </ng-select>
      <input
        class="form-control mb-3 mt-3"
        placeholder="{{ 'pages.pm-job-list.table-column.pm-job-no' | translate }}"
        [(ngModel)]="searchSelections.pmJobNumber"
        id="search-plan-number"
      />
      <input
      class="form-control mb-2 mt-3"
      placeholder="{{ 'pages.pm-job-list.table-column.description' | translate }}"
      [(ngModel)]="searchSelections.jobDescription"
    />
      <ng-select
      class="mt-3"
      placeholder="{{'pages.pm-job-list.table-column.pm-equipment' | translate }}"
      bindLabel="name"
      bindValue="code"
      [items]="equipmentInput.optionList"
      [loading]="equipmentInput.isLoading"
      (search)="onEquipmentSearch($event)"
      (change)="onEquipmentChange($event)"
      (clear)="onEquipmentClear()"
      (scrollToEnd)="onEquipmentScrollToEnd()"
      [(ngModel)]="searchSelections.equipmentNumber">
      </ng-select>
      <ng-select
        class="px-0 mb-2 mt-3"
        placeholder="{{ 'pages.pm-job-list.table-column.handling-team' | translate }}"
        bindLabel="label"
        bindValue="value"
        [items]="teamOptions"
        [loading]="isLoadingTeam"
        [multiple]="true"
        [searchFn]="onSearchFn"
        (search)="onSearchTeam($event)"
        (scrollToEnd)="onTeamScrollToEnd()"
        (close)="onTeamDropDownClose($event)"
        [(ngModel)]="searchSelections.handlingTeam"
      >
      </ng-select>
      <hr class="d-block d-md-none d-xl-block" />

      <div class="mt-3">
        <span class="d-md-block gray-40 mr-2">{{
          'pages.pm-job-list.table-column.status' | translate
        }}</span>
        <div *ngIf="searchSelections.planStatus">
          <label
            class="checkbox-tag brand-blue"
            *ngFor="let status of searchSelections.planStatus | keyvalue"
            [for]="'search-plan-status-' + status.key + '-checkbox'"
            [class.checked]="searchSelections.planStatus[status.key]"
          >
            {{ 'pm.status.' + status.key | translate }}
            <input
              [id]="'search-plan-status-' + status.key + '-checkbox'"
              type="checkbox"
              hidden="true"
              name="plan-status"
              [(ngModel)]="searchSelections.planStatus[status.key]"
            />
          </label>
        </div>
      </div>
      <hr class="d-block d-md-none d-xl-block" />
    </div>

    <div class="col-12 col-md-6 col-xl-12 pr-0 pl-0 pl-md-3 pl-xl-0">
      <div class="d-flex d-md-block d-xl-flex mb-2 align-items-start">
        <span class="d-md-bloc gray-40 col-auto pl-0">{{
          'pages.pm-job-list.table-column.start-date' | translate
        }}</span>
        <div class="d-inline-block">
          <div
            class="btn-group btn-group-toggle"
            ngbRadioGroup
            name="start-range"
            [(ngModel)]="searchSelections.startRange"
          >
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="on-or-before" id="search-start-on-or-before" />{{
                'components.search-criteria.on-or-before' | translate
              }}
            </label>
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="after" id="search-start-after" />{{
                'components.search-criteria.after' | translate
              }}
            </label>
          </div>
          <app-date-input
            #startDateElem
            class="mt-1 d-inline-block"
            [input]="searchSelections.ngbStartDate"
            [inputId]="'startDateInput'"
            [field]="'startDate'"
            (onBlur)="onBlurDateInput($event)"
            [dateAlertMsg]="dateAlertMsg"
          >
          </app-date-input>
        </div>
      </div>
      <div class="d-flex d-md-block d-xl-flex align-items-start mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">{{
          'pages.pm-job-list.table-column.end-date' | translate
        }}</span>
        <div class="d-inline-block">
          <div
            class="btn-group btn-group-toggle"
            ngbRadioGroup
            name="end-range"
            [(ngModel)]="searchSelections.endRange"
          >
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="on-or-before" id="search-end-on-or-before" />{{
                'components.search-criteria.on-or-before' | translate
              }}
            </label>
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="after" id="search-end-after" />{{
                'components.search-criteria.after' | translate
              }}
            </label>
          </div>
          <app-date-input
            class="mt-1 d-inline-block"
            #endDateElem
            [input]="searchSelections.ngbEndDate"
            [inputId]="'endDateInput'"
            [field]="'endDate'"
            (onBlur)="onBlurDateInput($event)"
            [dateAlertMsg]="dateAlertMsg"
          >
          </app-date-input>
        </div>
      </div>
      <hr class="d-block d-md-none d-xl-block" />
      <div class="col-12 col-md-6 col-xl-12  pr-0 pl-0 pl-md-3 pl-xl-0">
        <div class="d-flex d-md-block d-xl-flex mb-2 align-items-start">
          <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">
            {{ "components.search-criteria.job-creation-time-from" | translate }}
          </span>
        </div>
        <app-date-input
          class="mt-1"
          [input]="createTimeFromNgb"
          [inputId]="'createFromDateInput'"
          [field]="'createTimeFrom'" 
          [dateAlertMsg]="dateAlertMsg"
          (onBlur)="onBlurDateInput($event)">
        </app-date-input>
        <div class="d-flex d-md-block d-xl-flex align-items-start mt-3">
          <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">
            {{ "components.search-criteria.job-creation-time-to" | translate }}
          </span>
        </div>
        <app-date-input
          class="mt-1"
          [input]="createTimeToNgb"
          [inputId]="'createToDateInput'"
          [field]="'createTimeTo'" 
          [dateAlertMsg]="dateAlertMsg"
          (onBlur)="onBlurDateInput($event)">
        </app-date-input>
      </div>
      <div *ngIf="showCreationTimeErrorMsg" class="red font-italic">
        {{ 'pages.job-list.search-criteria.error-msg.wrong-creation-time-from' | translate }}
      </div>
      <hr class="d-block d-md-none d-xl-block" />

      <input
        class="form-control mb-2 mt-3"
        placeholder="{{ 'pages.pm-job-list.table-column.created-by' | translate }}"
        [(ngModel)]="searchSelections.createdBy"
      />
    </div>
  </div>
</div>
