import { Component } from '@angular/core';
import { AppDelegate } from "src/app/AppDelegate";
import { RoutePaths } from 'src/constants';
@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss']
})
export class NoPermissionComponent {

  constructor() { }

  navigateToDashBoard() {
    AppDelegate.navigate([RoutePaths.DASHBOARD], {
      replaceUrl: true,
    });
  }

}
