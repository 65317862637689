<div class="row justify-content-between m-0">
  <h3 class="d-inline">{{ 'pages.sn.ccs-interface' | translate }}</h3>
  <div class="submit-wrap d-inline">
    <app-submit-button 
      [text]="'global.submit'"
      [buttonClass]="'standard brand-amber'"
      [iconClass]="'fa-check'"
      [isDisabled]="submitDisabled || isLoading"
      [isLoading]="isLoading"
      (onClick)="onClickSubmit()" 
    ></app-submit-button>
  </div>
</div>

<div class="form-wrap">
  <div class="checkbox-wrap position-relative mb-3 custom-control custom-checkbox col-8">
    <input
      type="checkbox"
      id="ccsAcknowledge"
      class="checkbox custom-control-input"
      [(ngModel)]="ccsAcknowledge"
      (change)="validateForm()"
      >
    <label for="ccsAcknowledge" class="d-block custom-control-label ">{{'pages.sn.ccs-acknowledge' | translate}}</label>
  </div>
</div>

<div class="row m-0">
  <div class="col-8 pl-0">
    <ng-select
      class="my-2"
      placeholder="{{'pages.sn.sla-no' | translate}}"
      [(ngModel)]="slaNumberSelected" 
      [items]="slaNumberOptions"

      [loading]="isLoadingSla"
      [disabled]="isLoadingSla || !slaNumberOptions || slaNumberOptions.length === 0"
      [clearable]="false"
      (change)="onSlaNumberChange(); validateForm()"
    ></ng-select>
  </div>
  <div 
    *ngIf="!isLoadingSla && (!slaNumberOptions || slaNumberOptions.length === 0)"
    class="col-8 align-self-center alert-font">
    {{ 'pages.sn.generate-ccs-so.error-msg.no-effective-sla' | translate}}
  </div>
</div>
<div class="row m-0">
  <div class="col-8 pl-0">   
    <ng-select
      class="my-2"
      placeholder="{{'pages.sn.sla-item' | translate}}"
      [(ngModel)]="slaItemSelected" 
      [items]="slaItemOptions"
      bindLabel="itemNumber" 
      bindValue="itemNumber"
      [loading]="isLoadingSla"
      [disabled]="!slaNumberSelected || isLoadingSla || !slaItemOptions || slaItemOptions.length === 0"
      [clearable]="false"
      (change)="onSlaItemChange(); validateForm()"
    ></ng-select>
  </div>
</div>

<app-tablex [tablexParam]='tablexParam'></app-tablex>
