<div class="row justify-content-between m-0">
  <h3 class="d-inline">{{ 'pages.routing-rules.slider-panel.title' | translate }}</h3>
  <div class="submit-wrap d-inline">
      <app-submit-button 
      [id]="'routing-rule-criteria-form_save_button'"
      [text]="'global.submit'"
      [buttonClass]="'standard brand-amber'"
      [iconClass]="'fa-save'"
      (onClick)="onClickSubmit()"
    ></app-submit-button>
</div>

<div class="alert-font font-size-xs d-inline-block w-100" *ngIf="uiErrorMessage.length > 0">
  <div *ngFor="let msg of uiErrorMessage;" class="other-error-alert mb-2">
    {{msg}}
  </div>
</div>

<div class="mt-4">
  <div class="w-560 mb-4">
    <label for="client-dropDown">
      <span class="red">*</span>{{ 'pages.routing-rules.slider-panel.client' | translate }}
    </label>
    <ng-select
      id="client-dropDown"
      [items]="clientList"
      [multiple]="false"
      [(ngModel)]="selectedClient"
      [clearable]="true"
      (change)="onSelectClient()"
      required [class.input-error]="errorFields.client"
    ></ng-select>
  </div>
  <div class="w-560 mb-4">
    <label for="district-dropDown">
      <span class="red">*</span>{{ 'pages.routing-rules.slider-panel.district' | translate }}
    </label>
    <ng-select
      id="district-dropDown"
      [items]="districtList"
      [multiple]="false"
      [(ngModel)]="selectedDistrict"
      [disabled]="isDistrictDisable"
      [clearable]="true"
      (change)="onSelectDistrict()"
      required [class.input-error]="errorFields.district"
    ></ng-select>
  </div>
  <div class="w-560 mb-4">
    <label for="location-dropDown">
      {{ 'pages.routing-rules.slider-panel.location' | translate }}
    </label>
    <ng-select
      id="location-dropDown"
      [items]="locationList"
      [multiple]="true"
      [(ngModel)]="selectedLocation"
      [disabled]="isLocationDisable"
      [loading]="isLoadingLocation"
      [clearable]="true"
      (change)="onSelectLocation()"
    ></ng-select>
  </div>
  <div class="w-560 mb-4">
    <label for="equipmentCategory-dropDown">
      <span class="red">*</span>{{ 'pages.routing-rules.slider-panel.equipment-category' | translate }}
    </label>
    <ng-select
    id="equipmentCategory-dropDown"
      [items]="equipmentCategoryList"
      [multiple]="false"
      [(ngModel)]="selectedEquipmentCategory"
      [clearable]="true"
      (change)="onSelectEquipmentCategory()"
      required [class.input-error]="errorFields.equipmentCategory"
    ></ng-select>
  </div>
  <div class="w-560 mb-4">
    <label for="equipmentType-dropDown">
      {{ 'pages.routing-rules.slider-panel.equipment-type' | translate }}
    </label>
    <ng-select
      id="equipmentType-dropDown"
      [items]="equipmentTypeList"
      [multiple]="true"
      [(ngModel)]="selectedEquipmentType"
      [disabled]="isEquipmentTypeDisable"
      [clearable]="true"
      (change)="onSelectEquipmentType()"
    ></ng-select>
  </div>
  <div>
      <br>
      <label class="red">*</label><label>{{'pages.routing-rules.slider-panel.mandatory' | translate}}</label>
    </div>
</div>