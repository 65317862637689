export enum Permission {
  authorizationAll          = 'authorization.all',

  snView                    = 'sn.view',
  snDraft                   = 'sn.draft',
  snSubmit                  = 'sn.submit',
  snAcknowledge             = 'sn.acknowledge',
  snManualAcknowledge       = 'sn.manualAcknowledge',
  snCancel                  = 'sn.cancel',
  snReject                  = 'sn.reject',
  snReroute                 = 'sn.reroute',
  snUpdate                  = 'sn.update',
  snManualInstructionView   = 'sn.manualInstruction.view',
  snManualInstructionHandle = 'sn.manualInstruction.handle',

  snRemarksInvisibleView    = 'sn.remarks.invisible.view',
  snRemarksInvisibleUpdate  = 'sn.remarks.invisible.update',
  snRemarksPrivateView      = 'sn.remarks.private.view',
  snRemarksPrivateUpdate    = 'sn.remarks.private.update',
  snRemarksPublicView       = 'sn.remarks.public.view',
  snRemarksPublicUpdate     = 'sn.remarks.public.update',

  snCcsResync               = 'sn.ccs.resync',
  snCcsErrorRemove          = 'sn.ccs.error.remove',
  ccsErrorView              = 'ccs.error.view',

  snAssociate               = 'sn.associate',
  snAssociateApprove        = 'sn.associate.approve',

  jobcardAccept             = 'jobcard.accept',
  jobcardCancel             = 'jobcard.cancel',
  jobcardCreate             = 'jobcard.create',
  jobcardReject             = 'jobcard.reject',
  jobcardUpdate             = 'jobcard.update',
  jobcardView               = 'jobcard.view',
  jobcardComplete           = 'jobcard.complete',
  jobcardReopen             = 'jobcard.reopen',

  jobcardCcsResync          = 'jobcard.ccs.resync',
  jobcardCcsErrorRemove     = 'jobcard.ccs.error.remove',

  jobcardCompleteApprove    = 'jobcard.complete.approve',
  jobcardCancelApprove      = 'jobcard.cancel.approve',

  jobcardTeco               = 'jobcard.teco',
  jobcardTecoAll            = 'jobcard.teco.all',
  jobcardZs01Unteco         = 'jobcard.zs01.unteco',
  jobcardZs02Unteco         = 'jobcard.zs02.unteco',
  jobcardZs04Unteco         = 'jobcard.zs04.unteco',
  jobcardUntecoAll          = 'jobcard.unteco.all',
  jobcardRequestUnteco      = 'jobcard.request.unteco',
  jobcardApproveUnteco      = 'jobcard.approve.unteco',
  
  jobcardCreateAll          = 'jobcard.create.all',
  jobcardCompleteAll        = 'jobcard.complete.all',
  

  wcmUpdate                 = 'wcm.update',
  wcmView                   = 'wcm.view',

  adminUpdate               = 'admin.update',
  adminView                 = 'admin.view',

  contractUpdate            = 'contract.update',
  contractView              = 'contract.view',
  contractTransDummyUpdate  = 'contract.transdummy.update',

  requestApprove            = 'request.approve',
  requestReject             = 'request.reject',

  dashboardCsc              = 'dashboard.csc',
  dashboardSbu              = 'dashboard.sbu',
  
  timesheetView             = 'timesheet.view',
  timesheetUpdate           = 'timesheet.update',

  userSettingUpdate         = 'user.setting.update',
  userSettingCreate         = 'user.setting.create',
  userSettingView           = 'user.setting.view',
  roleSettingView           = 'role.setting.view',
  roleSettingUpdate         = 'role.setting.update',

  dropdownSettingView       = 'dropdown.setting.view',
  dropdownSettingUpdate     = 'dropdown.setting.update',

  workCentreAll             = 'workcentre.all',
  workCentreSettingView     = 'workcentre.setting.view',
  workCentreSettingUpdate   = 'workcentre.setting.update',

  haDefaultsSettingView     = 'ha.defaults.setting.view',
  haDefaultsSettingUpdate   = 'ha.defaults.setting.update',

  hsdEquipmentView          = 'hsd.equipment.view',
  hsdEquipmentUpdate        = 'hsd.equipment.update',

  enquiryCreate             = 'enquiry.create',
  enquiryView               = 'enquiry.view',
  enquiryViewAll            = 'enquiry.view.all',
  enquiryUpdate             = 'enquiry.update',
  enquiryUpdateAll          = 'enquiry.update.all',
  enquiryDiscussionAdd      = 'enquiry.discussion.add',
}