<div class="row justify-content-between m-0">
  <h3 class="d-inline">{{ 'component.associate-sn-panel.title' | translate }}</h3>
  <div class="submit-wrap d-inline">
    <app-submit-button 
      [id]="'associate-sn-panel_submit_button'"
      [text]="'global.submit'"
      [buttonClass]="'standard brand-amber'"
      [iconClass]="'fa-check'"
      [isDisabled]="submitDisabled || isLoadingSubmission"
      [isLoading]="isLoadingSubmission"
      (onClick)="onClickSubmit()" 
    ></app-submit-button>
  </div>
</div>

<div class="row mt-3" *ngIf="!hasApprovePermission">
  <div class="col-12 font-size-m">
    * {{ 'component.associate-sn-panel.approver-input-title' | translate }}
  </div>
  <div class="col-12">
    <ng-select
      class="my-2"
      placeholder="{{'component.associate-sn-panel.approver-input-placeholder' | translate}}"
      [(ngModel)]="selectedApprover"
      [items]="approverListOptions"
      [multiple]="true"
      [loading]="isLoadingApprover"
      [disabled]="isLoadingApprover || isLoadingSubmission"
      [clearable]="false"
      [closeOnSelect]="false"
      (change)="validateForm()"
      >
    </ng-select>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12 font-size-m">
    * {{ 'component.associate-sn-panel.associate-sn-input-title' | translate }}
  </div>
  <div class="col-12 font-size-xs alert-font">
    {{ 'component.associate-sn-panel.associate-sn-input-warning' | translate }}
  </div>
  <div class="col-12">
    <ng-select
      class="my-2"
      placeholder=""
      [(ngModel)]="selectedAssociatingSn"
      [items]="associateSnListOptions"
      [multiple]="isMultipleAssociateSn"
      [loading]="isLoadingAssociateSn"
      [disabled]="isLoadingAssociateSn || isLoadingSubmission"
      [clearable]="false"
      [closeOnSelect]="isMultipleAssociateSn ? false : true"
      (change)="validateForm(); showSnPreviewInfo()"
      >
    </ng-select>
  </div>
</div>
<ng-container *ngFor="let previewInfo of snPreviewInfoArray">
  <div class="row mt-3">
    <div class="col-12 font-size-m bold">
      <u> {{ previewInfo.title }} </u>
    </div>
    <div class="col-12 px-4">
      <div class="row my-1">
        <div class="col-4 bold">{{'component.associate-sn-panel.client' | translate}}</div>
        <div class="col-8">{{ previewInfo.client }}</div>
      </div>
      <div class="row my-1">
        <div class="col-4 bold">{{'component.associate-sn-panel.contact-person' | translate}}</div>
        <div class="col-8">{{ previewInfo.contactPerson }}</div>
      </div>
      <div class="row my-1">
        <div class="col-4 bold">{{'component.associate-sn-panel.contact-number' | translate}}</div>
        <div class="col-8">{{ previewInfo.contactNumber }}</div>
      </div>
      <div class="row my-1">
        <div class="col-4 bold">{{'component.associate-sn-panel.location' | translate}}</div>
        <div class="col-8">{{ previewInfo.location }}</div>
      </div>
      <div class="row my-1">
        <div class="col-4 bold">{{'component.associate-sn-panel.location-details' | translate}}</div>
        <div class="col-8">{{ previewInfo.lcoationDetails }}</div>
      </div>
      <div class="row my-1">
        <div class="col-4 bold">{{'component.associate-sn-panel.fault-details' | translate}}</div>
        <div class="col-8">{{ previewInfo.faultDetails }}</div>
      </div>
    </div>
  </div>
</ng-container>

<!-- <div class="row mt-3">
  <div class="col-12 font-size-m bold">
    <u> {{ previewTitle }} </u>
  </div>
  <div class="col-12 px-4">
    <div class="row my-1">
      <div class="col-4 bold">{{'component.associate-sn-panel.client' | translate}}</div>
      <div class="col-8">{{ client }}</div>
    </div>
    <div class="row my-1">
      <div class="col-4 bold">{{'component.associate-sn-panel.contact-person' | translate}}</div>
      <div class="col-8">{{ contactPerson }}</div>
    </div>
    <div class="row my-1">
      <div class="col-4 bold">{{'component.associate-sn-panel.contact-number' | translate}}</div>
      <div class="col-8">{{ contactNumber }}</div>
    </div>
    <div class="row my-1">
      <div class="col-4 bold">{{'component.associate-sn-panel.location' | translate}}</div>
      <div class="col-8">{{ location }}</div>
    </div>
    <div class="row my-1">
      <div class="col-4 bold">{{'component.associate-sn-panel.location-details' | translate}}</div>
      <div class="col-8">{{ lcoationDetails }}</div>
    </div>
    <div class="row my-1">
      <div class="col-4 bold">{{'component.associate-sn-panel.fault-details' | translate}}</div>
      <div class="col-8">{{ faultDetails }}</div>
    </div>
  </div>
</div> -->