<div class="row justify-content-between m-0">
    <h3 class="d-inline">{{ 'pages.sn.slider-panel.reject-job-card-completion' | translate }}</h3>
    <div class="submit-wrap d-inline">
      <app-submit-button 
        [id]="'reject-job-card-completion-form_submit_button'"
        [text]="'global.submit'"
        [buttonClass]="'standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="isLoading"
        [isLoading]="isLoading"
        (onClick)="onClickSubmit()" 
      ></app-submit-button>
    </div>
  </div>
  
  <div class="form-wrap">
    <div>
        <textarea maxlength="{{ getMaxRemarksLength }}" type="text" rows="5" placeholder="{{ 'pages.sn.remark' | translate }}" [(ngModel)]="remarks" [disabled]="isLoading"></textarea>
        <span 
            *ngIf="remarks?.length >= getMaxRemarksLength" 
            class="red small"
        >
            {{ "pages.sn.slider-panel.reject-job-card-completion.max-length" | translate:[getMaxRemarksLength]}}
        </span>
    </div>
  </div>
  
  