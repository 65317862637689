<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle" (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar [type]="'personal'"></app-sidebar>

  <!-- Page Content -->
  <div id="content">
    <div class="container-fluid">
    <div class="row ">
      <div class="col-md-8">
        <div class="container">
          <div class="row">
            <div class="col-12 p-0">
              <h3 >{{ 'pages.user-profile.account-information'  | translate}}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="row pb-2">
                <div class="col-4 text-right">{{ 'pages.user-profile.post-name'  | translate}}</div>
                <div class="division"></div>
                <div class="col-7" id="settings-user-prof_post-name_text">{{user.name}}</div>
              </div>
              <div *ngIf="!isLoadingUser" class="row pb-2">
                <div class="col-4 text-right">{{ 'pages.user-profile.role'  | translate}}</div>
                <div class="division"></div>
                <div class="col-7" id="settings-user-prof_role_text">
                  <ul class="pl-0 mb-0">
                    <li *ngFor="let role of post?.roles" class="d-block">
                      {{role.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div *ngIf="!isLoadingUser" class="col-md-7">

              <ng-container>
                <form [formGroup]="profileForm">
                  <div class="row align-items-center">
                    <div class="col-4 text-right">{{ 'pages.user-profile.name'  | translate}}</div>
                    <div class="division placeholder"></div>
                    <div class="col-7">
                      <input type="text" class="form-control my-1" formControlName="employeeName">
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-4 text-right">{{ 'pages.user-profile.primary-work-centre'  | translate}}</div>
                    <div class="division placeholder"></div>
                    <div class="col-7">
                      <ng-select
                        [items]="workCentreOptions"
                        [clearable]="false"
                        formControlName="primaryWorkCentre">
                      </ng-select>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-4 text-right">{{ 'pages.user-profile.phone'  | translate}}</div>
                    <div class="division placeholder"></div>
                    <div class="col-7">
                      <input type="text" class="form-control my-1" formControlName="phone" maxlength="8" (keyup)="inputNumber($event)">
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-4 text-right">{{ 'pages.user-profile.mobile'  | translate}}</div>
                    <div class="division placeholder"></div>
                    <div class="col-7">
                      <input type="text" class="form-control my-1" formControlName="mobile" maxlength="8" (keyup)="inputNumber($event)">
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-4 text-right">{{ 'pages.user-profile.fax'  | translate}}</div>
                    <div class="division placeholder"></div>
                    <div class="col-7">
                      <input type="text" class="form-control my-1" formControlName="fax" maxlength="8" (keyup)="inputNumber($event)">
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-4 text-right">{{ 'pages.user-profile.email'  | translate}}</div>
                    <div class="division placeholder"></div>
                    <div class="col-7">
                      <input type="text" class="form-control my-1" formControlName="email" maxlength="100">
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-4 text-right">{{ 'pages.user-profile.change-password'  | translate}}</div>
                    <div class="division placeholder"></div>
                    <div class="col-7">
                      <input type="password" class="form-control my-1" formControlName="existingPassword" maxlength="100" placeholder="{{ 'pages.user-profile.existing-password'  | translate}}">
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-4 text-right"></div>
                    <div class="division placeholder"></div>
                    <div class="col-7">
                      <input type="password" class="form-control my-1" formControlName="newPassword" maxlength="100" placeholder="{{ 'pages.user-profile.new-password'  | translate}}"/>
                    </div>
                  </div>
                  <div class="row align-items-center">
                      <div class="col-4 text-right"></div>
                      <div class="division placeholder"></div>
                      <div class="col-7">
                        <input type="password" class="form-control my-1" formControlName="confirmPassword" maxlength="100" placeholder="{{ 'pages.user-profile.confirm-password'  | translate}}"/>
                      </div>
                    </div>
                </form>
                <div *ngIf="showPasswordError" class="mt-2">
                  <div *ngFor="let error of passwordErrors;" class="mb-2">
                      <div [ngClass]="{'red':error.isError}">
                        <i [ngClass]="error.isError == true ? 'fa fa-times red' : 'fa fa-check blue' "></i>
                        {{error.msg}}
                      </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="!isLoadingUser" class="row">
            <div class="col-12 text-center">
              <app-submit-button 
                [id]="'id_update_password_button'"
                [text]="'pages.user-profile.change-button'"
                [buttonClass]="'standard brand-amber my-1'"
                [iconClass]="'fa-pen'"
                [isDisabled]="isUpdatingUserInfo"
                [isLoading]="isUpdatingUserInfo"
                (onClick)="onUpdateUserInfoClicked()" 
              ></app-submit-button>
            </div>
          </div>
          <div *ngIf="isLoadingUser" class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div *ngIf="!isLoadingUser" class="row">
          <div class="col-6">
            <h3>{{ 'pages.user-profile.work-center'  | translate}}</h3>
          </div>
        </div>
        <div *ngIf="!isLoadingUser" class="row">
          <div class="col-12">
            <app-tablex [tablexParam]='tablexParam'></app-tablex>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="container search-container">
          <div class="row pb-3">
            <div class="col-md-6 p-0">
              <h3>{{ 'pages.user-profile.permissions'  | translate}}</h3>
            </div>
            <div class="col-md-6 input-container">
              <i class="fas fa-search"></i>
              <input type="text" 
                class="form-control" 
                [(ngModel)]="viewPermissionSearchingWord"
                (ngModelChange)="onPermissionSearched()" 
                placeholder="" 
                aria-label="" 
                aria-describedby="basic-addon1"
                id="id_search_inputbox">
            </div>
          </div>
          <div class="row">
            <div *ngIf="isLoadingUser" class="col-md-12 text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngIf="!isLoadingUser" class="col-md-12 permissions-container">
              <div *ngFor="let permission of viewPermissionArray" id="settings-user-prof_permission-list_item-list">
                {{permission}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
