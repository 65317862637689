import { Component, Injector, OnInit } from "@angular/core";
import { BasePage } from "src/app/ui/model/base/base";
import * as moment from 'moment';
import { OrderType } from '@enum/order-type';
import { saveAs } from "file-saver";
import { formatDate } from '@angular/common';
import { Permission } from '@enum/permission';
import { Constants } from 'src/constants';
import { PostPostSummaryRequest } from '@api/model/authorization/post-post-summary-request';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import * as utility from 'src/app/services/utility';
import { Session }  from 'src/app/services/session';

@Component({
  selector: "timesheet-officer",
  templateUrl: "./timesheet-officer.html",
  styleUrls: ["./timesheet-officer.scss"]
})
export class TimesheetOfficerComponent extends BasePage implements OnInit {
  
  isLoading: boolean = false;
  
  staffData: any = [];
  staffOptions: any = [];
  jobCard: any;
  selectedJobCardId: string;
  staffJobCards: any;

  hourQuarters: any;
  timesheetPeriodOptions:any;
  orderTypeOptions: any;
  orderTypeClass: number;
  showCell: any = [];

  selectedPeriod: string;
  selectedStaff: string;
  selectedStaffCode: string;
  timesheetData:any;
  totalChargeableHour: number;
  displayChargeableHour: any;
  totalJobCount: number;
  cloneStaffData: any;
  selectedOrderType: string;
  timesheetConvert: {
    'personnelNumber' : any,   // string
    'jobCardNumber'   : any,   // string
    'jobType'         : any,   // string
    'bufferTime'      : number,// hours
    'workHour'        : any[], // Array of Object { 'startTime': start, 'endTime': end }
    'isoDate'         : any[], // Array of Array  [day.startTime, day.endTime]
    'dayHour'         : any[]  // Array of number [0, 0, 0, 0]
  }[];
  isExportExcelButtonLoading:boolean = false;

  jobTypePmJob = 'PMJOB';

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  workCenterOptions: string[] = [];
  selectedWorkCenter: string = null;

  ngOnInit() {
    this.hourQuarters = {
      0:'00',
      0.25:'15', 
      0.5:'30', 
      0.75:'45'
    };
    this.timesheetPeriodOptions = [
      { name: this.translate('pages.timesheet.this-month'), value: 'this' },
      { name: this.translate('pages.timesheet.last-month'), value: 'last' }
    ];
    this.orderTypeOptions = [
      { name:'All', value: 'all' },
      { name: OrderType.SLA_JOB, value: OrderType.SLA_JOB },
      { name: OrderType.AD_HOC_JOB, value: OrderType.AD_HOC_JOB },
      { name: OrderType.TRANSFERRED_JOB, value: OrderType.TRANSFERRED_JOB },
      { name: 'PM JOB', value: this.jobTypePmJob },
    ];
    this.selectedPeriod = this.timesheetPeriodOptions[0].value;
    this.totalChargeableHour = 0;
    this.displayChargeableHour = [0, 0];
    this.totalJobCount = 0;

    this.checkViewPermission(Permission.timesheetView);
    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.workCenterOptions = JM.JMConnector.getAllWorkCentreCode();
    } else {
      this.workCenterOptions = this.authorizationService.getWorkCenters();
    }
    if (Session.selectedWorkCentre) {
      this.selectedWorkCenter = Session.selectedWorkCentre;
    } else if (this.workCenterOptions.length > 0){
      this.selectedWorkCenter = this.workCenterOptions[0];
      Session.setSelectedWorkCentre(this.selectedWorkCenter);
    }
    this.requestWorkCentreStaff();
  }

  ngAfterContentInit() {}

  onLangChange(){ 
    this.timesheetPeriodOptions = [
      { name: this.translate('pages.timesheet.this-month'), value: 'this' },
      { name:  this.translate('pages.timesheet.last-month'), value: 'last' }
    ];
    this.orderTypeOptions[0].name = this.translate('pages.timesheet.select-all');
  }

  initPage(){
    this.selectedOrderType = this.orderTypeOptions[0].value;
    this.showCell = this.orderTypeOptions.map(o=> o.value);
    this.requestStaffTimeSheet();
  }

  initTimesheetData(){
    this.isLoading = true;
    this.totalChargeableHour = 0;
    this.totalJobCount = 0;
    this.timesheetConvert = [];

    let thisMonth = moment().format('MM');
    let lastMonth = moment().subtract(1, 'months').format('MM');
    let period = this.selectedPeriod == 'last' ? lastMonth : thisMonth;

    for (const jobcard of this.timesheetData) {
      let officers = jobcard.officerArray.filter(o => o.post == this.selectedStaff);

      for (const officer of officers) {
        let temp = {
          'personnelNumber' : officer.personnelNumber,
          'jobCardNumber'   : jobcard.jobCardNumber,
          'jobType'         : jobcard.jobType || this.jobTypePmJob, // workaround: consider as pmJob when no specification
          'bufferTime'      : Math.floor(jobcard.bufferTime / 60),
          'workHour'        : [],
          'isoDate'         : [],
          'dayHour'         : []
        };
        
        for (const day of officer.workPeriodArray) {
          const startTime = moment(day.startTime);
          const endTime   = moment(day.endTime);

          if (startTime.format('MM') == period) {
            //convert time to number
            const start = startTime.hours() + this.minutesToStepFormat(startTime.minutes());
            const end = startTime.isSame(endTime, 'day') ? endTime.hours() + this.minutesToStepFormat(endTime.minutes()) : 24; // handle 24:00 case in momentjs
            const dayHour = Math.abs(start - end);

            temp.workHour.push({ 'startTime': start, 'endTime': end });
            temp.isoDate.push([day.startTime, day.endTime]);
            temp.dayHour.push(dayHour);
          }
        }
        this.timesheetConvert.push(temp);
      }
    }

    this.isLoading = false;
    this.retrieveDataSummary();
  }

  // api function =================
  requestWorkCentreStaff(){
    this.isLoading = true;
    let request = {} as PostPostSummaryRequest;
    request.systemName = Constants.SYSTEM_NAME;
    request.active = 'active';
    request.authorizations = {'workCenters': this.selectedWorkCenter};
    request.pageSize = 1000;
    
    this.apiHandler(this.authorizationService.postPostSummary(request), 
    result => {
      if (result && result.code && result.code == 200
      && result.payload && result.payload.totalCount != undefined) {
        this.staffOptions = [];
        result.payload.records.forEach(r=>{
          this.staffOptions.push(r.name);
        });

        let name = Session.userInfo.name;
        if (this.staffOptions.includes(name)) {
          this.selectedStaff = name;
        } else {
          this.selectedStaff = this.staffOptions[0];
        }
        
        this.selectedStaffCode = result.payload.records.filter(r => r.name == this.selectedStaff).map(r=>r.code);
        this.initPage();
      } else {
        this.isLoading = false;
        this.openErrorBar(result);
      }
    });
  }

  requestStaffTimeSheet(){
    this.isLoading = true;
    this.timesheetData = [];
    let startTime = moment().startOf("month").toDate();
    let endTime = moment().endOf("month").toDate();

    if(this.selectedPeriod == 'last'){
      startTime = moment().subtract(1, 'months').startOf("month").toDate();
      endTime = moment().subtract(1, 'months').endOf("month").toDate();
    }

    const request:JM.JMRequestTimesheetGetTimesheetByOfficer = new JM.JMRequestTimesheetGetTimesheetByOfficer();
    request.postArray = [this.selectedStaff];
    request.startTime = startTime;
    request.endTime = endTime;
 
    JM.JMConnector.sendTimesheetGetTimesheetByOfficer(request, (error:JM.JMNetworkError, response:JM.JMResponseTimesheetGetTimesheetByOfficer) => {
      this.isLoading = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }
      this.timesheetData = response.payload;
      this.staffJobCards = this.timesheetData.map(data => data.jobCardNumber);
      this.initTimesheetData();
    });

  }

  // view functions ======================= 
  onFilterOrderType(type){
    if (this.showCell.indexOf(type) > -1) {
      this.showCell.splice(this.showCell.indexOf(type), 1);
    } else {
      this.showCell.push(type);
    }

    this.retrieveDataSummary();
  }
  convertHoursToDisplay(){
    this.displayChargeableHour[0] = Math.floor(this.totalChargeableHour);
    this.displayChargeableHour[1] = this.hourQuarters[this.totalChargeableHour - this.displayChargeableHour[0]];
  }

  onClickExportExcel() {
    if (this.showCell.length < 2) {
      this.openSnackBar(this.translate("pages.timesheet.select-job-type"));
      return;
    } else {
      this.isExportExcelButtonLoading = true;
      let periodArray = [];
      let jobTypeArray = [];
      let fileName = "TimeSheet_" + formatDate(new Date(), "yyyy-MM-dd", 'en-US') + ".xlsx";
      if (this.selectedPeriod == "this") {
        periodArray.push(moment().format("YYYYMM"));
      } else if (this.selectedPeriod == "last") {
        periodArray.push(moment().subtract(1, "months").format("YYYYMM"));
      }
      for (let i = 1; i < this.showCell.length; i++) {
        jobTypeArray.push(this.showCell[i]);
      }
      let request: JM.JMRequestTimesheetExportTimesheetReport = new JM.JMRequestTimesheetExportTimesheetReport();
      request.postArray = [this.selectedStaff];
      request.periodArray = periodArray;
      request.jobTypeArray = jobTypeArray;
      JM.JMConnector.sendTimesheetExportTimesheetReport(request, (error: JM.JMNetworkError, response: JM.JMResponseTimesheetExportTimesheetReport) => {
        this.isExportExcelButtonLoading = false;
        if (error) {
          this.handleJMError(error);
          return;
        }
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }
        let base64 = response.payload.base64;
        let blob = utility.base64ToBlob(base64, response.payload.type);
        saveAs(blob, fileName);
      })
    }
  }
  
  onChangeWorkCentre() {
    Session.setSelectedWorkCentre(this.selectedWorkCenter);
    this.timesheetData = [];
    this.totalChargeableHour = 0;
    this.totalJobCount = 0;
    this.convertHoursToDisplay();
    this.requestWorkCentreStaff();
  }

  onChangeFilter(){
    this.showCell = this.orderTypeOptions.map(o=> o.value);
    this.totalChargeableHour = 0;
    this.totalJobCount = 0;
    this.convertHoursToDisplay();
    this.requestStaffTimeSheet();
  }

  // TODO: reload language
  onLanguageChanged() {
    
  }

  // custom function ======================
  private minutesToStepFormat(value: any): number {
    value = (parseFloat(value) / 60).toFixed(2);
    
    if(value >= 0.24 && value <= 0.25){
      return 0.25;
    }else if(value >= 0.26 && value <= 0.5){
      return 0.5;
    }else if(value >= 0.51 && value <= 1){
      return 0.75;
    }else{
      return 0;
    }
  }

  private retrieveDataSummary() {
    let jobCardSet = new Set();
    let totalHours = 0;

    const filteredTimesheet = this.timesheetConvert.filter(officer => this.showCell.includes(officer.jobType));
    for (const timesheet of filteredTimesheet) {
      for (const dayHour of timesheet.dayHour) {
        jobCardSet.add(timesheet.jobCardNumber);
        if (dayHour) {
          totalHours += dayHour;
          totalHours += timesheet.bufferTime;
        }
      }
    }

    this.totalJobCount = jobCardSet.size;
    this.totalChargeableHour = totalHours;

    this.convertHoursToDisplay();
  }

}
