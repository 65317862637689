<div class="wrapper">
  <app-nav [navbarTitle]="pageTitle"></app-nav>

  <app-sidebar [type]="'personal'"></app-sidebar>

  <!-- Page Content -->
  <div id="content">
    <div class="row">
      <!-- Left Column -->
      <div *ngIf="hasEditPermission" class="col-12 col-xl-4">
        <h4 class="title">{{"pages.authorization.request-window" | translate}}</h4>
        <app-request-window (onAddedNewRequest)="onAddedNewRequest()"></app-request-window>
      </div>

      <!-- Right Column -->
      <div class="col-12" [ngClass]="{'col-xl-8 mt-4 mt-xl-0': hasEditPermission}">
        <div class="row justify-content-between">
          <div class="col">
            <h4 class="title">{{"pages.authorization.pending-items" | translate}}</h4>
          </div>
          <div class="col">
            <ng-select 
              bindLabel="label" 
              bindValue="value" 
              [items]="requestTypes" 
              [searchable]="false" 
              [clearable]="false"
              [(ngModel)]="requestCurrentType"
              [ngModelOptions]="{standalone: true}" 
              (change)="onRequestTypeChanged()" 
            ></ng-select>
          </div>
        </div>
        <app-tablex [tablexParam]='pendingItemsTablexParam'></app-tablex>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <h4 class="title">{{"pages.authorization.authorized-items" | translate}}</h4>
      </div>
    </div>
    <app-tablex [tablexParam]='approvalItemsTablexParam'></app-tablex>
  </div>
</div>