import { Component, Injector, OnInit } from '@angular/core';
import { NavigationEnd, RouterEvent } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { ContactGroupService } from '@services/contact-group.service';
import { ExcelService } from '@services/excel/excel.service';
import { ParameterService } from '@services/parameter.service';
import * as moment from 'moment';
import { Options } from "ng5-slider";
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { saveAs } from "file-saver";
import { formatDate } from '@angular/common';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ, JMCONSTANT, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { SnService } from '@services/sn.service';
import { JobCardService } from '@services/job-card.service';
import { PostExportJobCardSummaryRequest } from '@api/model/job-card/post-export-job-card-summary-request';
import { Session } from 'src/app/services/session';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { Permission } from '@enum/permission';
import { PostExportJobCardContractorJobReportRequest } from '@api/model/job-card/post-export-job-card-contractor-job-report-request';

@Component({
  selector: 'app-contractor-cm-job',
  templateUrl: './contractor-cm-job.component.html',
  styleUrls: ['./contractor-cm-job.component.scss']
})
export class ContractorCMJobComponent extends BasePage implements OnInit {

  public destroyed = new Subject<any>();

  constructor(
    injector: Injector,
    private parameterService: ParameterService,
    private contactGroupService: ContactGroupService,
    private snService: SnService,
    private jobCardService: JobCardService
  ) {
    super(injector);
    this.excelService = injector.get(ExcelService);
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.fetchData();
    });
  }

  excelService: ExcelService;

  hasAuthorizationAll: boolean = false;
  isDisabledNormalTimeRange: boolean = false;

  timeRangeOptions: Options = {};
  normalTimeRangeOptions: Options = {};
  normalTimeRangeValue: number;
  isDisabledContract: boolean = true;
  filterCriteria: any = {};
  status: any[];

  clientOption: any[];
  statusOption: any[];
  complianceStatusOption: any[];

  isExportExcelButtonLoading: boolean = false;

  parameter: PostExportJobCardContractorJobReportRequest;
  createTimeToMaxDate: NgbDateStruct;

  handlingPartyOptions: any = [];
  // Location Dropdown
  private searchLocationObserver = new Subject<any[]>();
  locationOptions: any = [];
  searchLocationKeyword: string = null;
  locationPageSize: number = 100;
  locationPageNumber: number = 1;
  locationTotalPage: number;
  isLoadingLocation: boolean = false;

  // Contractor Dropdown
  private searchContractorObserver = new Subject<any[]>();
  contractorOptions: any = [];
  searchContractorKeyword: string = null;
  contractorPageSize: number = 100;
  contractorPageNumber: number = 1;
  contractorTotalPage: number;
  isLoadingContractor: boolean = false;

  // Contract Dropdown
  private searchContractObserver = new Subject<any[]>();
  contractOptions: any = [];
  searchContractKeyword: string = null;
  contractPageSize: number = 100;
  contractPageNumber: number = 1;
  contractTotalPage: number;
  isLoadingContract: boolean = false;

  ngOnInit() {
    this.hasAuthorizationAll = this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL);
    this.initAllOption();
    this.initClientOption();
    this.searchContractorObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchContractors.bind(this));
    this.searchLocationObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchLocations.bind(this));
    this.searchContractObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchContracts.bind(this));
  }

  onChangeStartDate(args: any) {
    this.createTimeToMaxDate = this.setMaxEndDateByDays(args, 60);
  }

  initAllOption() {
    this.initTimeRangeOptions();
    this.normalTimeRangeValue = this.normalTimeRangeOptions.floor;
    this.initStatusOption();
    this.initComplianceStatus();
    this.initHandlingPartyOptions();
  }

  initHandlingPartyOptions() {
    this.handlingPartyOptions = [
      {
        value: 0,
        label: JMLanguage.translate("pages.report-download.all")
      },
      {
        value: JMENUM.HandlingParty.PMSMC,
        label: "PMSMC"
      },
      {
        value: JMENUM.HandlingParty.NON_PMSMC,
        label: "Non-PMSMC"
      }
    ];
  }

  fetchData() {
    this.lang = Session.selectedLanguage;  //TODO: remove lang

  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  initClientOption(): void {
    this.clientOption = [];
    for (let client of JM.JMConnector.getAllClient()) {
      let item = {
        value: client.clientShortName,
        label: client.clientShortName + ' - ' + client.name[Session.selectedLanguage],
      }
      this.clientOption.push(item);
    };
  }


  initStatusOption(): void {
    this.statusOption = [];
    for (let id of JM.JMConnector.getAllJobCardStatus()) {
      this.statusOption.push({
        id: id,
        label: JMLanguage.translate("jobcard.status." + id)
      });
    }
  }

  initComplianceStatus(): void {
    this.complianceStatusOption = [];
    for (let id of Object.values(JMENUM.JobcardComplianceStatus)) {
      this.complianceStatusOption.push({
        value: id,
        label: JMLanguage.translate("jobcard.complianceStatus." + id)
      });
    }
  }

  timeRangeChanged(): void {
    var value = this.normalTimeRangeValue;
    var today = this.momentDateToBgbDate(moment());
    switch (value) {
      case 0:
        this.isDisabledNormalTimeRange = false;
        break;
      case 1:
        this.isDisabledNormalTimeRange = true;
        this.filterCriteria.createTimeFrom = today;
        this.filterCriteria.createTimeTo = today;
        break;
      case 2:
        var startDate = this.momentDateToBgbDate(moment().subtract(7, 'days'));
        this.isDisabledNormalTimeRange = true;
        this.filterCriteria.createTimeFrom = startDate;
        this.filterCriteria.createTimeTo = today;
        break;
      case 3:
        var startDate = this.momentDateToBgbDate(moment().subtract(1, 'months'));
        this.isDisabledNormalTimeRange = true;
        this.filterCriteria.createTimeFrom = startDate;
        this.filterCriteria.createTimeTo = today;
        break;
      default:
        break;
    }
    this.createTimeToMaxDate = this.setMaxEndDateByDays(this.filterCriteria.createTimeFrom, 60);
  }


  setParameters() {
    const endDate = moment(this.filterCriteria.createTimeTo.year + '-' + this.filterCriteria.createTimeTo.month + '-' 
    + this.filterCriteria.createTimeTo.day).add(1, 'd').subtract(1, 's');
    this.parameter = {
      jobCreationTimeFrom: new Date(this.filterCriteria.createTimeFrom.year + '-' +
      this.filterCriteria.createTimeFrom.month + '-' + this.filterCriteria.createTimeFrom.day),
      jobCreationTimeTo: new Date(endDate.toString())
    }
    const para = this.parameter;
    if (this.filterCriteria.responseToClientTimeComplianceStatus) {
      para.responseToClientTimeComplianceStatus = this.filterCriteria.responseToClientTimeComplianceStatus;
    }
    if (this.filterCriteria.responseTimeComplianceStatus) {
      para.responseTimeComplianceStatus = this.filterCriteria.responseTimeComplianceStatus;
    }
    if (this.filterCriteria.rectificationTimeComplianceStatus) {
      para.rectificationTimeComplianceStatus = this.filterCriteria.rectificationTimeComplianceStatus;
    }
    if (this.filterCriteria.client) {
      para.clientList = [this.filterCriteria.client];
    }
    if (this.filterCriteria.location) {
      para.locationCodeList = [this.filterCriteria.location];
    }
    if (this.filterCriteria.status && this.filterCriteria.status.length) {
      para.statusList = this.filterCriteria.status;
    }
    if (this.filterCriteria.contractor) {
      para.vendorNumberList = [this.filterCriteria.contractor];
    }
    if (this.filterCriteria.contractList && this.filterCriteria.contractList.length) {
      para.contractNumberList = this.filterCriteria.contractList;
    }
    if (this.filterCriteria.handlingParty || this.filterCriteria.handlingParty === 0) {
      para.handlingPartyList = this.filterCriteria.handlingParty === 0 ? [JMENUM.HandlingParty.PMSMC, JMENUM.HandlingParty.NON_PMSMC] :
      [this.filterCriteria.handlingParty];
    }
  }

  onClickExportExcel(): void {
    
    if (!this.filterCriteria.createTimeFrom || !this.filterCriteria.createTimeFrom) {
      this.openSnackBar(JMLanguage.translate("pages.report-download.contractor-cm-job.please-fill-in-creation-time"));
      return;
    }
    this.setParameters();
    this.getReport();
  }


  getReport() {
    const request: PostExportJobCardContractorJobReportRequest = this.parameter;

    this.isExportExcelButtonLoading = true;
    this.apiHandler(this.jobCardService.postExportJobCardContractorJobReportRequest(request), result => {
      this.isExportExcelButtonLoading = false;
      // "Contractor_Job_Report_[download date : yyyy-mm-dd ]" 
      try {
        if(result && result.type !== "application/json") {
          let fileName = "Contractor_Job_Report_" + formatDate(new Date(), "yyyy-MM-dd", 'en-US') + ".xlsx";
          saveAs(result, fileName);
        } else {
          this.openErrorBar();
        }
      } catch (e) {
        this.openErrorBar();
      }
    });
  }

  // location ng select handler
  onSearchLocation(event) {
    this.searchLocationKeyword = event.term;
    this.searchLocationObserver.next();
  }

  clearLocationFilter() {
    this.searchLocationKeyword = null;
    this.searchLocations();
  }

  async searchLocations() {
    this.locationOptions = [];
    this.locationPageNumber = 1;
    this.locationTotalPage = null;
    this.locationOptions = await this.requestLocationList(this.locationPageNumber);
  }

  async onLocationScrollToEnd() {
    if (this.locationPageNumber < this.locationTotalPage) {
      this.locationPageNumber++;

      let locations = await this.requestLocationList(this.locationPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.locationOptions = this.locationOptions.concat(locations);
    }
  }

  private async requestLocationList(pageNumber?: number) {
    const request = new JM.JMRequestLocationsLocationSummary();

    request.locationOnly = true;
    request.parameters = ['code', 'description'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.locationPageSize;

    if (this.searchLocationKeyword) {
      request.locationDescription = this.searchLocationKeyword;
    }

    this.isLoadingLocation = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingLocation = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      return AppDelegate.openErrorBar(response);
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.locationTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((location) => {
      const lang = Session.selectedLanguage;
      const description = location.description[lang] ? location.description[lang] : location.description.en;
      return {
        value: location.code,
        label: `${description} (${location.code})`,
      };
    });
  }

  // contractor ng select handler
  onSearchContractor(event) {
    this.searchContractorKeyword = event.term;
    this.searchContractorObserver.next();
  }

  clearContractorFilter() {
    this.searchContractorKeyword = null;
    this.searchContractors();
  }

  async searchContractors() {
    this.contractorOptions = [];
    this.contractorPageNumber = 1;
    this.contractorTotalPage = null;
    this.contractorOptions = await this.requestContractorList(this.contractorPageNumber);
  }

  async onContractorScrollToEnd() {
    if (this.contractorPageNumber < this.contractorTotalPage) {
      this.contractorPageNumber++;

      let contractors = await this.requestContractorList(this.contractorPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.contractorOptions = this.contractorOptions.concat(contractors);
    }
  }

  private async requestContractorList(pageNumber?: number) {

    const request = new JM.JMRequestContractsGetJmVendorList();

    request.activeStatusList = ['active', 'inactive'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.contractorPageSize;

    if (this.searchContractorKeyword) {
      request.filter = {
        vendorName: this.searchContractorKeyword,
      };
    }

    this.isLoadingContractor = true;
    const response: JM.JMResponseContractsGetJmVendorList = await AppDelegate.sendJMRequest(request);
    this.isLoadingContractor = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      return AppDelegate.openErrorBar(response);
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.contractorTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    let respondRecords: any = null;
    if (Array.isArray(response.payload.records)) {
      respondRecords = response.payload.records.map(record => {
        return {
          label: record.vendorName,
          value: record.vendorNumber,
        }
      });
    }
    return respondRecords;
  }

  //------------------------
  // contract ng select handler
  onSearchContract(event) {
    this.searchContractKeyword = event.term;
    this.searchContractObserver.next();
  }

  clearContractFilter() {
    this.searchContractKeyword = null;
    this.searchContracts();
  }

  async searchContracts() {
    this.contractOptions = [];
    this.contractPageNumber = 1;
    this.contractTotalPage = null;
    this.contractOptions = await this.requestContractList(this.contractPageNumber);
  }

  async onContractScrollToEnd() {
    if (this.contractPageNumber < this.contractTotalPage) {
      this.contractPageNumber++;

      let contracts = await this.requestContractList(this.contractPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.contractOptions = this.contractOptions.concat(contracts);
    }
  }

  private async requestContractList(pageNumber?: number) {

    const request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    const userInfo = Session.userInfo;
    if (userInfo && !JMUTILITY.hasPermission(userInfo, Permission.workCentreAll) &&
      userInfo.authorizations && userInfo.authorizations.workCentres) {
      request.workCentres = userInfo.authorizations.workCentres;
    }
    if (!this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      request.workCentres = this.authorizationService.getWorkCenters();
    }
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.contractPageSize;

    if (this.searchContractKeyword) {
      request.filter = {
        contractNumber: this.searchContractKeyword,
      };
    }

    this.isLoadingContract = true;
    const response: JM.JMResponseContractsMaintenanceTermContractSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingContract = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return AppDelegate.openErrorBar(response);
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.contractTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    let respondRecords: any = null;
    if (Array.isArray(response.payload.records)) {
      respondRecords = response.payload.records.map(contract => {
        return {
          label: contract.contractNumber,
          value: contract.contractNumber,
        }
      });
    }
    return respondRecords;
  }

  //------------------------


  /*** Custom function for frontend ***/

  private momentDateToBgbDate(momentDate: moment.Moment): any {
    return { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() };
  }

  private initTimeRangeOptions() {
    let label = [
      JMLanguage.translate("pages.report-download.custom"),
      JMLanguage.translate("pages.report-download.today"),
      JMLanguage.translate("pages.report-download.one-week"),
      JMLanguage.translate("pages.report-download.one-month")
    ];
    var options = {
      floor: 0,
      ceil: 3,
      showTicksValues: true,
      showSelectionBar: true,
      stepsArray: [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
      translate: (value: number): string => {
        return label[value];
      }
    };
    this.normalTimeRangeOptions = options;
  }


  setTodayDate(): NgbDateStruct {
    return this.momentDateToBgbDate(moment());
  }

  setMaxEndDate(startDate: NgbDateStruct): NgbDateStruct {
    if (!startDate) return null;
    const date: NgbDateStruct = {
      year: startDate.year,
      month: startDate.month + 1,
      day: startDate.day
    }

    return date;
  }

  setMaxEndDateByDays(startDate: NgbDateStruct, days: number): NgbDateStruct {
    if (!startDate) return null;
    const newDate = moment(startDate.day + "-" + startDate.month + "-" + startDate.year, "DD-MM-YYYY").add(days, 'days');
    const date: NgbDateStruct = {
      year: newDate.year(),
      month: newDate.month() + 1,
      day: newDate.date()
    }
    return date;
  }

  setMinEndDate(startDate: NgbDateStruct): NgbDateStruct {
    if (!startDate) return null;
    const date: NgbDateStruct = {
      year: startDate.year,
      month: startDate.month,
      day: startDate.day
    }

    return date;
  }

  // TODO: reload language
  onLanguageChanged() {
  }
}

