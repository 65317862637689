import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GetPostRequest } from '@api/model/authorization/get-post-request';
import { UpdateInfoRequest } from '@api/model/authorization/update-info-request';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Post } from 'src/app/entity/data-model/post';
import { AuthenticationMode } from 'src/app/entity/enum/authenticationMode';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/entity/enum/tablexColumnType';
import { isEmailAdress } from 'src/app/presenter/validators/validator';
import { BasePage } from 'src/app/ui/model/base/base';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session }  from 'src/app/services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Words } from 'src/wordDictionary';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BasePage implements OnInit {

  pageTitle: string;

  user = Session.userInfo;
  post: JMOBJ.Post;

  userPermissions             : string[] = [];
  permissionFieldSearchTerm   = new Subject<string>();
  readonly vaildPasswordLength = 6;
  readonly numberCharSequenceLength = 6;
  words = Words;
  numberCharSequence:string[] = this.authorizationService.getNumCharSequence(this.numberCharSequenceLength);

  isLoadingUser               : boolean = true;
  isUpdatingUserInfo          : boolean = false;
  showPasswordError           : boolean = false;

  passwordErrors:PasswordError[] = [
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.1")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.2")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.3")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.4")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.5")
    },
    {
      isError: false,
      msg: JMLanguage.translate("pages.user-profile.password.error.6")
    }
  ];

  

  // view variable
  isLocalAccount: boolean;
  viewPermissionSearchingWord: string;
  viewPermissionArray: string[] = [];
  profileForm = this.fb.group({
    employeeName        : [''],
    phone               : [''],
    mobile              : [''],
    fax                 : [''],
    email               : [''],
    primaryWorkCentre   : [''],
    existingPassword    : [''],
    newPassword         : [''],
    confirmPassword     : [''],
  });

  // options
  workCentreOptions: string[] = [];

  // tablex
  //pageSizeOptions     = [10, 25, 100];
  workCentreArray     = [];
  currentPageSize     = 10;
  currentPage         = 1;
  pageCount           = 1;
  tablexParam: {};

  constructor(
    injector: Injector,
    private fb: FormBuilder
  ) {
    super(injector)
  }

  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.user-profile.page-title", [Session.selectedWorkCentre]);
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : false,
      enablePagination      : true,
      // pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      // onPageSizeClicked     : this.onPageSizeClicked,
      headers: [ 
        {
          id: 'code',
          name: "pages.user-profile.workcentre-code",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id: 'workCentre',
          name: "pages.user-profile.workcentre-description",
          type: TablexColumnType.Text, 
          horizontalAlign: TablexColumnHorizontalAlign.Center, 
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        

      ],
      content: []
    }

    this.isLocalAccount = (Session.userInfo.authenticationMode == AuthenticationMode.LOCAL);

    this.workCentreOptions = this.authorizationService.getWorkCenters();
    
    this.permissionFieldSearchTerm.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe((terms) => {
      if (terms == "") {
        this.viewPermissionArray = this.userPermissions;
      } else {
        this.viewPermissionArray = this.userPermissions.filter(permission => permission.includes(terms));
      }
    });

    this.initFormControl();

    this.requstUser();
  }

  
  //---------------------------------------------------------------------------
  // custom function

  initFormControl() {
    if (!this.isLocalAccount) {
      this.profileForm.controls['employeeName'].disable();
      this.profileForm.controls['phone'].disable();
      this.profileForm.controls['mobile'].disable();
      this.profileForm.controls['fax'].disable();
      this.profileForm.controls['email'].disable();
      this.profileForm.controls['existingPassword'].disable();
      this.profileForm.controls['newPassword'].disable();
      this.profileForm.controls['confirmPassword'].disable();
    }
  }

  private renderTable() {
    this.tablexParam['content'] = this.workCentreArray.map(workCentre => {
      return [
        workCentre.workCentreCode,
        workCentre.description
      ];
    });
  }
  
  //---------------------------------------------------------------------------
  // api function
  requstUser() {
    let request = new JM.JMRequestPostsGetPost();
    request.postName = this.user.name;
    request.systemName = Constants.SYSTEM_NAME;

    this.isLoadingUser = true;
    JM.JMConnector.sendAsyncPostsGetPost(request, (error:JM.JMNetworkError, response:JM.JMResponsePostsGetPost) => {
      this.isLoadingUser = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code != 200 || !response.payload) {
        this.openErrorBar(response);
        return;
      }

      this.post                   = response.payload;
      this.viewPermissionArray    = this.post.permissions.map(permission => permission.toString()).sort();
      this.userPermissions        = this.post.permissions.map(permission => permission.toString()).sort();
      this.profileForm.patchValue({
        employeeName    : this.post.employeeName,
        phone           : this.post.phone,
        mobile          : this.post.mobile,
        fax             : this.post.fax,
        email           : this.post.email,
        primaryWorkCentre: this.post.primaryWorkCentre,
      })

      this.post.roles.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
      this.requestWorkCentres();
      
    });
  }

  requestWorkCentres() {
    this.tablexParam['isLoadingTable'] = true;
    const workCentreCodeList = this.post.authorizations.workCenters;
    let workCentreList = [];
    if (workCentreCodeList.length) {
      workCentreList = JM.JMConnector.getWorkCentreList(workCentreCodeList).filter(wc => wc != undefined);
    } else {
      workCentreList = JM.JMConnector.getAllWorkCentre();
    }
    
    const total = workCentreList.length;
    const start = (this.currentPage - 1) * this.currentPageSize;
    const end = this.currentPage * this.currentPageSize + 1;
    
    this.workCentreArray = workCentreList.slice(start, end);
    this.tablexParam['pageCount'] = Math.ceil(total/this.currentPageSize);
    this.renderTable();
    this.tablexParam['isLoadingTable'] = false;
   
  }

  requestUpdateInfo() {
    let request           = {} as UpdateInfoRequest;
    request.name          = this.user.name;
    request.phone         = this.profileForm.value.phone;
    request.mobile        = this.profileForm.value.mobile;
    request.fax           = this.profileForm.value.fax;
    request.email         = this.profileForm.value.email;
    request.employeeName  = this.profileForm.value.employeeName;
    request.primaryWorkCentre  = this.profileForm.value.primaryWorkCentre;

    if (this.profileForm.value.existingPassword && this.profileForm.value.newPassword) {
      request.oldPassword   = this.profileForm.value.existingPassword;
      request.newPassword   = this.profileForm.value.newPassword;
    }

    this.setSubmissionLoading(true);
    this.apiHandler(this.authorizationService.updateInfo(request),
      result => {
        this.setSubmissionLoading(false);
        if (!result || !result.code || result.code != 200) {
          this.openErrorBar(result);
          return;
        }
        this.openSnackBar(this.translate("global.saved"));
        this.profileForm.patchValue({
          existingPassword    : '',
          newPassword         : '',
          confirmPassword     : '',
        })
      },
      error => {
        this.setSubmissionLoading(false);
      })
  }

  isPasswordVaildate(password) {
    let result = true
    

     if(!this.authorizationService.isPasswordLengthVaild(password, this.vaildPasswordLength)){
      this.passwordErrors[0].isError = true;
      result = false;
     }

     if(!this.authorizationService.isPasswordContainNumber(password)){
      this.passwordErrors[1].isError = true;
      result = false;
     }

     if(!this.authorizationService.isPasswordContainNonAlphanumericCharacters(password)){
      this.passwordErrors[2].isError = true;
      result = false;
     }

     if(this.authorizationService.isPasswordContainUserName(password,this.user.name)){
      this.passwordErrors[3].isError = true;
      result = false;
     }

     if(password.length >= this.vaildPasswordLength){
      if(this.authorizationService.isPasswordContainSequence(password, this.numberCharSequence)){
        this.passwordErrors[4].isError = true;
        result = false;
       }
     }
     
     if(this.authorizationService.isPasswordContainSequence(password,this.words.filter(word => word.length <= password.length))){
      this.passwordErrors[5].isError = true;
      result = false;
     }

     this.showPasswordError = !result
     return result
  }

  resetPasswordErrors(){
    this.showPasswordError = false
    for(let error of this.passwordErrors) {
      error.isError = false
    }
  }

  //---------------------------------------------------------------------------
  // button callback event
  onUpdateUserInfoClicked = () => {
    this.resetPasswordErrors();
    if (!this.isLocalAccount) {
      this.requestUpdateInfo();
      return;
    }


    if ((this.profileForm.value.newPassword || this.profileForm.value.newPassword.length > 0) 
        && (this.profileForm.value.existingPassword == null || this.profileForm.value.existingPassword.length == 0)) {
      this.openSnackBar(this.translate("pages.user-profile.error.existing-password-cannot-be-empty"));
      return;
    }

    if ((this.profileForm.value.existingPassword || this.profileForm.value.existingPassword.length > 0 )
        && (this.profileForm.value.newPassword == null || this.profileForm.value.newPassword.length == 0)) {
      this.openSnackBar(this.translate("pages.user-profile.error.new-password-cannot-be-empty"));
      return;
    }

    if ((this.profileForm.value.newPassword || this.profileForm.value.newPassword.length > 0) 
        && (this.profileForm.value.confirmPassword == null || this.profileForm.value.confirmPassword.length == 0)) {
      this.openSnackBar(this.translate("pages.user-profile.error.confirm-password-cannot-be-empty"));
      return;
    }

    if (this.profileForm.value.newPassword 
        && this.profileForm.value.confirmPassword 
        && this.profileForm.value.newPassword !== this.profileForm.value.confirmPassword) {
      this.openSnackBar(this.translate("pages.user-profile.error.password-not-match"));
      return;
    }

    if (this.profileForm.value.email && !isEmailAdress(this.profileForm.value.email)) {
      this.openSnackBar(this.translate("pages.user-profile.error.invalid-email"));
      return;
    }

    

    if (this.profileForm.value.newPassword != null && this.profileForm.value.newPassword != "" && this.profileForm.value.newPassword.length > 0){
      if(!this.isPasswordVaildate(this.profileForm.value.newPassword)){
        return
      }
    }

    this.requestUpdateInfo();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.requestWorkCentres();
  }
 
  
  onPermissionSearched() {
    this.permissionFieldSearchTerm.next(this.viewPermissionSearchingWord);
  }

  // TODO: reload language
  onLanguageChanged() {
    
  }

  setSubmissionLoading(isLoading: boolean) {
    this.isUpdatingUserInfo = isLoading;
    if (isLoading) {
      this.profileForm.disable();
    } else {
      this.profileForm.enable();
      this.initFormControl();
    }
  }
}
export interface PasswordError{
  isError:boolean
  msg:string
}