import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { Permission } from '@enum/permission';
import { GetPermissionsRequest } from 'src/app/api/authorization/get-permissions-request';
import { GetRoleAllRequest } from 'src/app/api/authorization/get-role-all-request';
import { PostRolesPermissionsRequest } from 'src/app/api/authorization/post-roles-permissions-request';
import { BasePage } from 'src/app/ui/model/base/base';
import * as _ from 'underscore';
import { JMENUM } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'app-user-management-role',
  templateUrl: './user-management-role.component.html',
  styleUrls: ['./user-management-role.component.scss']
})
export class UserManagementRoleComponent extends BasePage implements OnInit, AfterViewInit {

  selectedRole              : any;
  roles                     = [];

  givenPermission           = [];
  availablePermission       = [];
  serverPermission          : string[];

  hasViewPermission         : boolean = false;
  hasEditionPermission      : boolean = false;

  isDirty                   : boolean = false;
  isDisabledSaveBtn         : boolean = true;
  isSaving                  : boolean = false;
  isLoadingAllPermissions   : boolean = true;
  isLoadingAllRoles         : boolean = true;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  //===========================================================================
  // view life cycle functions
  ngOnInit() {
    this.checkViewPermission(JMENUM.Permission.ROLE_SETTING_VIEW);
    this.hasEditionPermission = this.authorizationService.hasPermission(JMENUM.Permission.ROLE_SETTING_UPDATE);
  }

  ngAfterViewInit() {
    this.requestAllRoles();
    this.requestAllPermission();
  }

  //===========================================================================
  // api function
  private requestAllPermission() {
    let request                     = {} as GetPermissionsRequest;
    request.systemName              = 'CCEPJM';
    this.isLoadingAllPermissions    = true;

    this.apiHandler(this.authorizationService.getPermissions(request),
      result => {
        this.isLoadingAllPermissions = false;
        this.serverPermission = result.sort();
        this.onRoleChanged(0);
      }, error => {
        this.isLoadingAllPermissions = false;
        this.openErrorBar(error);
      });
  }

  private requestAllRoles() {
    let request               = {} as GetRoleAllRequest;
    request.systemName        = 'CCEPJM';
    this.isLoadingAllRoles    = true;

    this.apiHandler(this.authorizationService.getRoleAllBySystemName(request),
      result => {
        this.isLoadingAllRoles    = false;
        this.roles                = result;
        this.onRoleChanged(0);
      }, error => {
        this.isLoadingAllRoles    = false;
        this.openErrorBar(error);
      });
  }

  private requestUpdatePermission() {
    let request               = {} as PostRolesPermissionsRequest;
    request.systemName        = 'CCEPJM';
    request.roleId            = this.selectedRole.roleId;
    request.permissions       = this.givenPermission.sort();

    this.isDisabledSaveBtn    = true;
    this.isSaving             = true;
    
    this.apiHandler(this.authorizationService.postRolesByRoleIdPermissions(request),
      result => {
        this.isSaving                   = false;
        
        if (!result || !result.code || result.code != 200 || ! result.payload) {
          this.openErrorBar(result);
          return;
        }

        let resultRole                  = result.payload;
        let selectedRoleIndex           = this.roles.findIndex(_role => _role.roleId == resultRole.roleId);
        this.roles[selectedRoleIndex]   = resultRole;

        this.isDirty                    = false;
        this.updateSaveBtnStatus();
        this.openSnackBar(this.translate("global.saved"));

      }, error => {
        this.isSaving = false;
      })
  }

  //===========================================================================
  // ui function
  drop(event: CdkDragDrop<Object[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      this.isDirty = true;
      this.updateSaveBtnStatus();
    }
  }

  onRoleChanged(roleIndex: number): void {
    if (this.isDirty) {
      // this.onDiscard(roleIndex); // TBC: add discard feature
      // this.isDirty = false;
      // this.updateSaveBtnStatus();
 
      let message = this.translate("pages.admin.role.confirm-save-permission",  [this.selectedRole.name]);
      let yesHandler = () => {
        this.requestUpdatePermission();
        this.onRoleChanged(roleIndex)
      };
      let noHandler = () => {
        this.onRoleChanged(roleIndex)
      };

      this.isDirty = false;
      this.isDisabledSaveBtn = true;
      this.popupConfirmAlert(message, yesHandler, noHandler);
    } else {
      this.selectedRole = this.roles[roleIndex];
      this.setGivenPermission();
    }
  }

  setGivenPermission(): void {
    if (!this.selectedRole || !this.selectedRole.permissions) { 
      return; 
    }

    // remove reference 
    this.givenPermission = this.selectedRole.permissions.slice(0);
    this.availablePermission = _.difference(this.serverPermission, this.givenPermission).sort();
  }

  add() {

  }

  onSaveBtnClicked() {
    let message =  this.translate("pages.admin.role.confirm-save-permission",  [this.selectedRole.name]);
    let handler = () => {
      this.requestUpdatePermission();
    };

    this.popupConfirmAlert(message, handler);
  }

  private onDiscard(roleIndex: number) {
    let message = this.translate("pages.admin.role.confirm-discard-change");
    let handler = () => {
      this.isDirty = false;
      this.updateSaveBtnStatus();
      this.onRoleChanged(roleIndex);
    };

    this.popupConfirmAlert(message, handler);
  }

  private popupConfirmAlert(message: string, yesHandler: any, noHandler?) {
    if (!noHandler) { noHandler = () => { }; }

    let buttons = [
      { name: this.translate("global.yes"), handler: yesHandler },
      { name: this.translate("global.no"), handler: noHandler }
    ]
    this.showPopUpAlert(message, "", buttons);
  }

  private updateSaveBtnStatus() {
    if (!this.isDirty) {
      this.isDisabledSaveBtn = true;
    } else {
      this.isDisabledSaveBtn = false;
    }
  }

  // TODO: reload language
  onLanguageChanged() {
    
  }

}
