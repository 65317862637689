import { AfterViewInit, Component, Injector, OnInit, ViewChild } from "@angular/core";
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isEmailAdress, isNumber } from 'src/app/presenter/validators/validator';
import { TablexComponentParams } from 'src/app/ui/components/tablex/tablex.component';
import { BasePage } from "src/app/ui/model/base/base";
import { Session }  from 'src/app/services/session';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from "moment";
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { inHouseTableHeader, nonPmsmcTableHeader, pmsmcTableHeader } from './team-settings-member.model';

@Component({
  selector: "team-settings-member",
  templateUrl: "./team-settings-member.html",
  styleUrls: ["./team-settings-member.scss"]
})
export class TeamSettingsMemberComponent extends BasePage implements OnInit, AfterViewInit {
  @ViewChild("inhouseTeamSelector", { static: true }) inhouseTeamSelectorView;
  @ViewChild("contractorTeamSelector", { static: true }) contractorTeamSelectorView;
  @ViewChild("id_inhouse_member_table", { static: false }) inhouseTable;
  @ViewChild("addTeamMemberSliderPanel", { static: false }) addTeamMemberSliderPanel;
  @ViewChild("addTeamMemberForm", { static: false }) addTeamMemberForm;

  @ViewChild("addContractorTeamMemberSliderPanel", { static: false }) addContractorTeamMemberSliderPanel;
  @ViewChild("addContractorTeamMemberForm", { static: false }) addContractorTeamMemberForm;
  
  pageTitle: string;
  
  uiMode:"inhouse"|"contractor" = "inhouse"

  isLoadingTeams          = false;
  selectedWorkCentre      : string;
  hasCreationPermission   : boolean = false;
  hasEditionPermission    : boolean = false;
  selectedTeam            : JMOBJ.Team = null;
  teamMemberList          : {
    name: string,
    post: string,
    employeeCode: string[],
    phone: string,
    mobile: string,
    fax: string,
    email: string,
    employeeName: string[],
  }[] = []; // before filtering, containing all member info
  filteredTeamMemberList  : {
    name: string,
    post: string,
    employeeCode: string[],
    phone: string,
    mobile: string,
    fax: string,
    email: string,
    employeeName: string[],
  }[] = [];

  contactPersonDict: {} = {}; // key: post, value: contactPerson
  employeeDict: {} = {}; // key: post, value: employeeDict
  employeeNameDict: {} = {}; // key: post, value: employeeDict

  // filter
  private searchTerms     = new Subject<any[]>();
  filterCriteria          : {} = {};

  addMemberFormParameter: {
    onSubmitButtonClicked: Function 
  } = undefined;

  addContractorTeamMemberFormParameter: {
    onSubmitButtonClicked: Function
  } = undefined;

  // tablex
  inhouseTablexParam      : {} = {};
  nonPmsmcTablexParam   : {} = {};
  pmsmcTablexParam: TablexComponentParams = {};
  pageSizeOptions         = [10, 25, 100];
  currentPageSize         = 10;
  currentPage             = 1;
  pageCount               = 1;

  
  constructor(
    injector: Injector,
  ) {
    super(injector);
    
    this.addMemberFormParameter = {
      onSubmitButtonClicked: this.onSubmitButtonClicked
    };
    this.addContractorTeamMemberFormParameter = {
      onSubmitButtonClicked: this.onAddContractorTeamMemberSubmitClicked
    };
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.team-member.page-title", [Session.selectedWorkCentre]);
    this.hasEditionPermission = this.authorizationService.hasPermission(JMENUM.Permission.WCM_UPDATE);
    this.hasCreationPermission = this.authorizationService.hasPermission(JMENUM.Permission.WCM_UPDATE);
    this.selectedWorkCentre = Session.selectedWorkCentre;

    this.checkViewPermission(JMENUM.Permission.WCM_VIEW);
    this.checkWorkCenterPermission(this.selectedWorkCentre);
  }
  
  ngAfterViewInit() {
    this.initInhourseMemberTable();
    this.initNonPmsmcMemberTable();
    this.initPmsmcMemberTable();

    this.searchTerms.pipe(
      debounceTime(1),  // no need to wait network response
      // distinctUntilChanged(),
    ).subscribe((header) => {
      this.onFilterChanged(header);
    });
  }

  //---------------------------------------------------------------------------
  // view functions
  initInhourseMemberTable() {
    let headers = inHouseTableHeader.slice();
    if (this.hasEditionPermission) {
      headers.push({
        id: 'action', // will be used for filter input name
        name: "pages.team-member.action",
        enableFilter: false,
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle
      });
    }

    this.inhouseTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      onFilterKeyUp         : this.onFilterKeyUp,
      headers,
      content: []
    }
  }

  initNonPmsmcMemberTable() {
    let headers = nonPmsmcTableHeader.slice();
    if (this.hasEditionPermission) {
      headers.push({
        id: 'action', // will be used for filter input name
        name: "pages.team-member.action",
        enableFilter: false,
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle
      });
    }

    this.nonPmsmcTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      onFilterKeyUp         : this.onFilterKeyUp,
      headers,
      content: []
    }
  }

  initPmsmcMemberTable() {
    this.pmsmcTablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 100],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPmsmcTablePageNumberClicked,
      onPageSizeClicked     : this.onPmsmcTablePageSizeClicked,
      headers: pmsmcTableHeader,
      content: []
    }
  }

  filterMember() {
    this.filteredTeamMemberList = [];
    for (let member of this.teamMemberList) {
      if (this.isMatchCriteria(member)) {
        this.filteredTeamMemberList.push(member);
      }
    }
  }

  private renderTable() {
    switch(this.selectedTeam.handlingParty) {
      case JMENUM.HandlingParty.INHOUSE:
        this.renderInhouseTable();
        break;
      case JMENUM.HandlingParty.NON_PMSMC:
        this.renderNonPmsmcTable();
        break;
      case JMENUM.HandlingParty.PMSMC:
        break;
    }
  }
  private renderInhouseTable() {
    let startIndex  = (this.currentPage * this.currentPageSize) - this.currentPageSize;
    let endIndex    = (this.currentPage * this.currentPageSize);
    this.inhouseTablexParam['currentPage']    = this.currentPage;
    this.inhouseTablexParam['pageCount']      = Math.ceil(this.filteredTeamMemberList.length / this.currentPageSize);
    this.inhouseTablexParam['content'] = this.filteredTeamMemberList.slice(startIndex, endIndex).map((data, rowIndex) => {
      let content = [
        data.post,
        data.employeeName ? data.employeeName : [data.name],
        data.employeeCode,
        data.phone,
        data.mobile,
        data.email,
        (this.hasEditionPermission && this.selectedTeam.workCentre == this.selectedWorkCentre)? [{ "id": "delete", "class": "glyph brand-red", "icon": "fas fa-times", "onClicked": (data) => this.onDeleteMemberBtnClicked(data), "object": data }]: [],  //// not borrowed team
      ];

      return content;
    });
  }

  private renderNonPmsmcTable() {
    let startIndex  = (this.currentPage * this.currentPageSize) - this.currentPageSize;
    let endIndex    = (this.currentPage * this.currentPageSize);
    this.nonPmsmcTablexParam['currentPage']   = this.currentPage;
    this.nonPmsmcTablexParam['pageCount']     = Math.ceil(this.filteredTeamMemberList.length / this.currentPageSize);
    this.nonPmsmcTablexParam['content'] = this.filteredTeamMemberList.slice(startIndex, endIndex).map((data, rowIndex) => {
      let content = [
        data.name,
        {
          "id": 'phone',
          "placeholder": '',
          "value": data.phone,
          "enable": false,
          "readonly": false,
          "maxlength": 8,
          "onKeyup": (event) => this.inputNumber(event),
        },
        {
          "id": 'mobile',
          "placeholder": '',
          "value": data.mobile,
          "enable": false,
          "readonly": false,
          "maxlength": 8,
          "onKeyup": (event) => this.inputNumber(event),
        },
        {
          "id": 'fax',
          "placeholder": '',
          "value": data.fax,
          "enable": false,
          "readonly": false,
          "maxlength": 8,
          "onKeyup": (event) => this.inputNumber(event),
        },
        {
          "id": 'email',
          "placeholder": '',
          "value": data.email,
          "enable": false,
          "readonly": false,
          "maxlength": null,
          "onKeyup": () => { },
        },
        [],
      ];
        
      if (this.selectedTeam && this.hasEditionPermission && this.selectedTeam.workCentre == this.selectedWorkCentre) {
        content[content.length-1] = [
          { "id": "save", "class": "glyph brand-amber", "icon": "fas fa-save", "onClicked": (data, rowIndex) => this.onSaveMemberBtnClicked(data, rowIndex), "object": data, "show": false },
          { "id": "edit", "class": "glyph brand-amber", "icon": "fas fa-edit", "onClicked": (data, rowIndex) => this.onEditMemberBtnClicked(data, rowIndex), "object": data, "show": true },
          { "id": "delete", "class": "glyph brand-red", "icon": "fas fa-times", "onClicked": (data) => this.onDeleteMemberBtnClicked(data), "object": data, "show": true }
        ];
      }
      return content;
    });
  }

  private renderPmsmcTableContent(records: JM.JMOBJ.Post[]) {
    this.pmsmcTablexParam.content = records.map((data, index) => {
      return [
        data.employeeName,
        data.vendorTeamList.map((team)=>team.name),
        data.roles,
        data.phone,
        data.fax,
        data.email,
      ];;
    });
  }

  private async requestPMSMCTeamMember(pageNumber?: number, pageSize?: number) {
    const request = new JM.JMRequestPostsGetPostListByContract();
    request.contractNumber = this.selectedTeam.contractNumber;
    request.pageNumber = typeof pageNumber === 'number' ? pageNumber : this.pmsmcTablexParam.currentPage;
    request.pageSize = typeof pageNumber === 'number' ? pageSize : this.pmsmcTablexParam.currentPageSize;
    // request.sortBy;
    // request.sortOrder;

    this.pmsmcTablexParam.isLoadingTable = true;
    const response: JM.JMResponsePostsGetPostListByContract = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.pmsmcTablexParam.isLoadingTable = false;

    this.pmsmcTablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
    this.pmsmcTablexParam.currentPage = request.pageNumber;
    this.pmsmcTablexParam.currentPageSize = request.pageSize;
    this.renderPmsmcTableContent(response.payload.records);
  }

  private async requestContactPersons() {
    this.contactPersonDict = {};

    if (this.teamMemberList.length == 0) {
      this.renderTable();
      return;
    }

    const request = new JM.JMRequestGetContactPersonsByPost();
    request.posts = this.selectedTeam.members;

    const response: JM.JMResponseGetContactPersonsByPost = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
      
    for(let cp of response.payload) {
      this.contactPersonDict[cp.post] = cp;
      for(let member of this.teamMemberList) {
        if (member.post == cp.post) {
          member.name = this.contactPersonDict[member.post].name;
          member.phone = this.contactPersonDict[member.post].phone;
          member.mobile = this.contactPersonDict[member.post].mobile;
          member.fax = this.contactPersonDict[member.post].fax;
          member.email = this.contactPersonDict[member.post].email;
          continue;
        }
      }  
    }
    this.renderTable();
  }

  private async requestDeleteTeamMember(row) {
    let team = this.selectedTeam;

    const request = new JM.JMRequestTeamsRemoveTeamMember();
    request.teamId = team._id.toString();
    request.memberPost = row.post;

    const response: JM.JMResponseTeamsUpdateTeam = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate("global.deleted"));
    if (this.selectedTeam.handlingParty == JMENUM.HandlingParty.INHOUSE) {
      this.inhouseTeamSelectorView.reloadTeam();
    } else {
      this.contractorTeamSelectorView.reloadTeam();
    }
  }


  private async requestEmployeeCode() {
    this.employeeDict = {};
    this.employeeNameDict = {};

    if (this.teamMemberList.length == 0) {
      this.renderTable();
      return;
    }

    const request = new JM.JMRequestEmployeesEmployeeSummary();
    let today = moment();
    request.start = moment(today).startOf('day').toDate();
    request.end = moment(today).endOf('day').toDate();
    request.postNameList = this.teamMemberList.map(t => t.post);
    
    const response: JM.JMResponseEmployeesEmployeeSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    response.payload.records.forEach(r => {
      if (r.employees.length != 0) {
        for (let em of r.employees) {
          if (this.employeeDict[r.postName]) {
            this.employeeDict[r.postName].push(em.employeeCode);
          } else {
            this.employeeDict[r.postName] = [em.employeeCode];
          }
          if (this.employeeNameDict[r.postName] && em.employeeName) {
            this.employeeNameDict[r.postName].push(em.employeeName);
          } else if (em.employeeName) {
            this.employeeNameDict[r.postName] = [em.employeeName];
          }
        }
      }
    });

    for(let member of this.teamMemberList) {
      member.employeeCode = this.employeeDict[member.post];
      member.employeeName = this.employeeNameDict[member.post];
    }
    this.renderTable();
  }

  onAddMemberClicked() {
    if(this.selectedTeam.handlingParty == JMENUM.HandlingParty.INHOUSE) {
      this.addTeamMemberForm.initView(this.selectedTeam);
      this.addTeamMemberSliderPanel.toggle();
    } else {
      this.addContractorTeamMemberForm.initView(this.selectedTeam);
      this.addContractorTeamMemberSliderPanel.toggle();
    }
  }


  // --------------------------------------------------------------------------
  // Tablex function
  onFilterChanged(header) {
    if (header.value == null || header.value == '') {
      delete this.filterCriteria[header.id];
    } else {
      this.filterCriteria[header.id] = header.value;
    }
    this.currentPage = 1;
    // this.retrieveMemberData();    
    this.filterMember();
    this.renderTable();
  }

  onFilterKeyUp = (index, header, newValue) => {
    header['value'] = newValue;

    // fire an event, call onFilterChanged after 0.5 second
    this.searchTerms.next(header);
  }

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.renderTable();
    // this.retrieveMemberData();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.renderTable();
    // this.retrieveMemberData();
  }

  onPmsmcTablePageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.requestPMSMCTeamMember(this.currentPage, this.currentPageSize);
  }
  onPmsmcTablePageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.requestPMSMCTeamMember(this.currentPage, this.currentPageSize);
  }

  private onSaveMemberBtnClicked(button, rowIndex) {
    let member = button.object;
    let message = JMLanguage.translate("pages.team-setting.confirm-save-member-name", [member.name]);
    let discardHandler = () => {
      this.setContractorTableEditMode(rowIndex, false);
    }
    let email = '';
    let fax = '';
    let mobile = '';
    let phone = '';
    for (let i = 0; i < this.nonPmsmcTablexParam['content'][rowIndex].length; i++) {
      const element = this.nonPmsmcTablexParam['content'][rowIndex][i];
      // el.id == 'phone' || el.id == 'mobile' || el.id == 'fax' || el.id == 'email'
      if (i == 1 || i == 2 || i == 3 || i == 4) {
        if (element.id == 'fax' && element.value) fax = element.value.trim();
        if (element.id == 'mobile' && element.value) mobile = element.value.trim();
        if (element.id == 'phone' && element.value) phone = element.value.trim();
        if (element.id == 'email' && element.value) email = element.value.trim();
      }
    }

    // validate
    if (fax && fax.length > 0 && !isNumber(fax)) {
      AppDelegate.openSnackBar(JMLanguage.translate("global.invalid-fax"));
      return;
    }
    if (mobile && mobile.length > 0 && !isNumber(mobile)) {
      AppDelegate.openSnackBar(JMLanguage.translate("global.invalid-sms"));
      return;
    }
    if (phone && phone.length > 0 && !isNumber(phone)) {
      AppDelegate.openSnackBar(JMLanguage.translate("global.invalid-phone"));
      return;
    }
    if (email && email.length > 0 && !isEmailAdress(email)) {
      AppDelegate.openSnackBar(JMLanguage.translate("global.invalid-email"));
      return;
    }

    let saveHandler = async () => {
      const request = new JM.JMRequestContactPersonsUpdateContactPerson();
      request.name = member.name;
      request.post = member.post;
      request.phone = phone;
      request.mobile = mobile;
      request.fax = fax;
      request.email = email;
  
      const response: JM.JMResponseContactPersonsUpdateContactPerson = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
      this.reloadFullTeamMember();
      this.setContractorTableEditMode(rowIndex, false);
    }

    let buttons = [
      { name: JMLanguage.translate("global.yes"), handler: () => { saveHandler(); } },
      { name: JMLanguage.translate("global.no"), handler: discardHandler }
    ]
   
    AppDelegate.showPopUpAlert(message, "", buttons);
  }

  private onEditMemberBtnClicked(button, rowIndex) {
    this.setContractorTableEditMode(rowIndex, true);
  }

  private onDeleteMemberBtnClicked(button) {
    let member = button.object;
    let message = JMLanguage.translate("pages.team-setting.confirm-delete-member", [member.name]);
    let buttons = [
      { name: JMLanguage.translate("global.yes"), handler: () => { this.requestDeleteTeamMember(member) } },
      { name: JMLanguage.translate("global.no") }
    ]
    this.showPopUpAlert(message, "", buttons);
  }

  
  private reloadTeamMemberList() {
    this.employeeDict = null;
    this.contactPersonDict = null;

    this.filterCriteria   = {};
    this.teamMemberList = [];
    this.filteredTeamMemberList = [];
    
    for(let post of this.selectedTeam.members) {
      let member = {
        name: null,
        post: post,
        employeeCode: null,
        phone: null,
        mobile: null,
        fax: null,
        email: null,
        employeeName: null
      }
      this.teamMemberList.push(member);
      this.filteredTeamMemberList.push(member);
    }
  }

  private reloadFullTeamMember() {
    this.reloadTeamMemberList();
    this.requestContactPersons();
    this.requestEmployeeCode();
  }

  onTeamChanged = (team: JMOBJ.Team) => {
    this.currentPage = 1;
    this.selectedTeam = team;
    this.hasEditionPermission = (this.selectedTeam.workCentre == this.selectedWorkCentre) && this.authorizationService.hasPermission(JMENUM.Permission.WCM_UPDATE);
    Session.setTeamSettingsSelectedTeamId(team._id.toString());

    switch(team.handlingParty) {
      case JMENUM.HandlingParty.INHOUSE:
        this.uiMode = "inhouse";
        this.contractorTeamSelectorView.deselectTeam();
        this.reloadFullTeamMember();
        break;
      case JMENUM.HandlingParty.NON_PMSMC:
        this.uiMode = "contractor";
        this.inhouseTeamSelectorView.deselectTeam();
        this.reloadFullTeamMember();
        break;
      case JMENUM.HandlingParty.PMSMC:
        this.uiMode = "contractor";
        this.inhouseTeamSelectorView.deselectTeam();
        this.requestPMSMCTeamMember();
        break;
    }
  }

  onSubmitButtonClicked = () => {
    this.addTeamMemberSliderPanel.toggle();
    this.inhouseTeamSelectorView.reloadTeam();
  }

  onAddContractorTeamMemberSubmitClicked = () => {
    this.addContractorTeamMemberSliderPanel.toggle();
    this.contractorTeamSelectorView.reloadTeam();
  }

  private setContractorTableEditMode(rowIndex: number, isEditMode: boolean) {
    for (let i = 0; i < this.nonPmsmcTablexParam['content'][rowIndex].length; i++) {
      const element = this.nonPmsmcTablexParam['content'][rowIndex][i];
      // el.id == 'phone' || el.id == 'mobile' || el.id == 'fax' || el.id == 'email'
      if (i == 1 || i == 2 || i == 3 || i == 4) {
        element['enable'] = isEditMode;
      }

      // Button Action
      if (i == 5) {
        for (const button of element) {
          if (button.id == 'save') button.show = isEditMode;
          if (button.id == 'edit') button.show = !isEditMode;
        }
      }
    }
  }

  // Tablex filter need to match member object key and tablex header id
  private isMatchCriteria(member: any): boolean {
    let totaCount = 0;
    let matchCount = 0;
    for (const key in this.filterCriteria) {
      totaCount++;
      if (this.filterCriteria.hasOwnProperty(key)) {
        let searchValue = this.filterCriteria[key].toLowerCase();
        let patt = new RegExp(searchValue);
        if (member[key] && patt.test(member[key].toLowerCase()))
          matchCount++;
      }
    }
    return totaCount == matchCount;
  }

  isInhouse(handlingParty: JMENUM.HandlingParty): boolean {
    return handlingParty == JMENUM.HandlingParty.INHOUSE;
  }

  isNonPmsmc(handlingParty: JMENUM.HandlingParty): boolean {
    return handlingParty == JMENUM.HandlingParty.NON_PMSMC;
  }

  isPmsmc(handlingParty: JMENUM.HandlingParty): boolean {
    return handlingParty == JMENUM.HandlingParty.PMSMC;
  }

  onLanguageChanged() {
  }
}
