export const Constants = {
  SYSTEM_NAME: "CCEPJM",  // for calling api use
  SYSTEM_VERSION: "v4.8.72-beta-189-g73e7d021",
  SYSTEM_DISPLAY_NAME: "CCeP-JM",
  DEBOUNCE_TIME: 500,  // 0.5 second
  COLOR_LIST: ['#007bff', '#28a745', '#ff9960',  '#346f42', '#a9003a', '#ff7100', '#009a97', '#896fff', '#002065', '#005585', '#ff9c9c','#EDAA2E', '#bb5bbb', '#ce656f', '#b2ce65', '#FFFF00', '#B0FDFB', '#FFC90E', '#C8BFE7', '#FF2525', '#FF7D27', '#9A4ED8', '#52D386','#B46CB9','#d7f431'],
  TEXTAREA_MAX_LENGTH: 1000,
  REMARKS_TEXTAREA_MAX_LENGTH: 100,
  INPUT_NONCOMPLIANCE_REASON_MAX_LENGTH: 150,
  DATETIME_FORMAT: "YYYY-MM-DD(ddd) HH:mm",
  DATETIME_FORMAT_2: "YYYY-MM-DD HH:mm",
  DATE_FORMAT_2: "YYYY-MM-DD(ddd)",
  DATE_FORMAT: "YYYY-MM-DD",
  API_DATE_FORMAT: "YYYYMMDD",
  PLATFORM_NAME: "WEB",  // for calling api use
  ATTACHMENT_SIZE: 10485760, // 10 MB
  EMSDHOME: "http://emsdhome/Pages/IT/E-Services%20information/Customer%20Centric%20e-Platform/Customer%20Centric%20e-Platform%20-%20Job%20Management2.aspx",
  GOOGLE_MAP_URL: "https://www.google.com/maps/search/"
};

export const RoutePaths = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  ACC_REVIEW_SUBMIT_HISTORY: "/ccep-jm-account-review/email-history",
  ACC_REVIEW_UPLOAD: "/ccep-jm-account-review/upload-template",
  JM_NO_PERMISSION_PAGE_PATH: "/no-permission"
}

export const PagesWithReLoginRedirect = [
  RoutePaths.ACC_REVIEW_SUBMIT_HISTORY,
  RoutePaths.ACC_REVIEW_UPLOAD,
];
