<div class="calendar-input-wrap position-relative">
  <input type="text" [id]="inputId" class="form-control date-picker-input-box"
      name="customDatePicker" #c2="ngModel" [(ngModel)]="input" ngbDatepicker placeholder="YYYY-MM-DD"
      (blur)="inputNumberWithIndex($event);onChangeInput($event);"
      (keyup)="inputNumberWithIndex($event);"
      #customDatePicker="ngbDatepicker" autocomplete="off"
      (click)="customDatePicker.toggle();onClickDatePicker(inputId)"
      [class.input-error]="errorFields || isError"
      [disabled]="disabled"
      [markDisabled]="enableDateString && isDisabled.bind(this)"
  >
  <button class="btn calendar" (click)="customDatePicker.toggle();onClickDatePicker(inputId)" type="button">
      <i class="fa fa-calendar" aria-hidden="true"></i>
  </button>
</div>