import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/AppDelegate';
import { TablexParam } from './tablexConfig';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Router } from '@angular/router';
import { AuthorizationService } from '@services/authorization.service';
// import { mockData } from './mockData';

@Component({
  selector: 'app-enquiry-list',
  templateUrl: './enquiry-list.component.html',
  styleUrls: ['./enquiry-list.component.scss']
})

export class EnquiryListComponent implements OnInit {
  enquiryList = [] as JMOBJ.Enquiry[]; // API data
  tablexParam = new TablexParam();
  isLoadingEnquiryList = false;

  constructor(private router: Router, private authorizationService: AuthorizationService) { }

  ngOnInit() {
    if (!this.hasPagePermission()) {
      this.router.navigate(['/dashboard']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    this.requestEnquirySummary();
    this.renderTableRows();
    this.tablexParam.requestTableData = this.requestEnquirySummary;
    this.tablexParam.onRowSelected = this.onRowSelected;
  }

  hasPagePermission = (): boolean => {
    return (
      this.authorizationService.hasPermission(JMENUM.Permission.ENQUIRY_VIEW) ||
      this.authorizationService.hasPermission(JMENUM.Permission.ENQUIRY_VIEW_ALL)
    );
  }

  renderTableRows = (): void => {
    if (!this.enquiryList.length) {
        this.tablexParam.content = [];
    }
    this.tablexParam.content = this.enquiryList.map((data): string[] => [
      data.enquiryNumber || '',
      data.category ? JMLanguage.translate('enquiry.category.' + data.category) : '',
      data.subCategory ? JMLanguage.translate('enquiry.subCategory.' + data.subCategory) : '',
      data.description ? this.trimMessage(data.description) : '',
      data.status ? JMLanguage.translate('enquiry.status.' + data.status) : '',
      (data.updatedBy || '') + (data.updatedAt ? ' ' + moment(data.updatedAt).format(Constants.DATETIME_FORMAT) : ''),
      (data.contactName || data.submittedBy || '') + (data.submittedAt ? ' ' + moment(data.submittedAt).format(Constants.DATETIME_FORMAT) : ''),
    ]);
  }

  trimMessage = (msg: string): string => {
    const maxLength = 160;
    return msg.length > maxLength ? msg.substring(0, maxLength) + '...' : msg;
  }

  onRowSelected = (index: number, rowData: any): void => {
    this.router.navigate(['/enquiry/view/' + rowData[0]]);
  }

  requestEnquirySummary = async (): Promise<void> => {
    // Error handling
    if (this.isLoadingEnquiryList) return console.warn('Debug...Early exit...');

    // Pre request
    this.isLoadingEnquiryList = true;
    this.tablexParam.isLoadingTable = true;

    const request = new JM.JMRequestEnquirySummary();
    request.pageSize = this.tablexParam.currentPageSize;
    request.pageNumber = this.tablexParam.currentPage;
    request.filter = this.tablexParam.filter;

    // Post request
    const response = await JM.JMConnector.sendAsyncEnquirySummary(request)
    this.isLoadingEnquiryList = false;
    this.tablexParam.isLoadingTable = false;

    // Error handling
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      return AppDelegate.openErrorBar({response});
    }
    // Actions
    this.enquiryList = response.payload.records;
    this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / this.tablexParam.currentPageSize);
    this.renderTableRows();
  }
}
