import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  PopUpWithRadioComponent,
  PopUpWithRadioComponentRadiosI,
} from '../ui/components/pop-up-with-radio/pop-up-with-radio.component';

@Injectable({
  providedIn: 'root',
})
export class ModalBootstrapService {
  modalService: NgbModal;
  modalRef: NgbModalRef;

  constructor(modalService: NgbModal) {
    this.modalService = modalService;
  }

  openRadioPopup(
    modalTitle: string,
    modalDescription: string,
    closeBtnName: string,
    dismissBtnName: string,
    radios: PopUpWithRadioComponentRadiosI[],
    successActionCallBack: Function,
    dismissActionCallBack: Function,
  ) {
    this.modalRef = this.modalService.open(PopUpWithRadioComponent, {
      centered: true,
      backdrop: 'static',
    });
    this.modalRef.componentInstance.radios = radios;
    this.modalRef.componentInstance.modalTitle = modalTitle;
    this.modalRef.componentInstance.modalDescription = modalDescription;
    this.modalRef.componentInstance.closeBtnName = closeBtnName;
    this.modalRef.componentInstance.dismissBtnName = dismissBtnName;

    this.modalRef.result.then(
      (el: PopUpWithRadioComponentRadiosI) => {
        successActionCallBack && successActionCallBack(el);
      },
      (reason: string) => {
        dismissActionCallBack && dismissActionCallBack(reason);
      }
    );
  }
  closePopup() {
    this.modalRef.close();
  }
}
