<div class="wrapper">
  <app-nav (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content">
    <div class="container" style="width: 880px;">
      <!--Event Count Layout-->
      <ng-container *ngFor="let group of settingGroups;let i = index">
        <div class="row justify-content-between underline">
          <!-- *ngIf="group.cells.length>0"  -->
          <!-- <div class="d-flex col-md-9 col-8 p-0 mx-1 justify-content-between underline align-items-end"> -->
          <div class="title col-6">
            {{group.description | translate}}
          </div>
          <div *ngIf="i == 0" class="p-0" style="width:350px;">
            <div class="float-right mt-2">
              <p class="title dropdown-label mr-2 d-md-inline d-none">{{ 'pages.setting.work-center'  | translate}}:</p>
              <ng-select class="work-centre-dropdown d-inline-block mr-0" id="settings_work-center_dropdown" [items]="workCenterOptions"
                [(ngModel)]="selectedWorkCenter" (change)="selectCentre()" [clearable]="false" [searchable]="true"
                [ngModelOptions]="{standalone: true}">
              </ng-select>
            </div>
          </div>
          <!-- </div> -->
        </div>

        <div class="row justify-content-start">
          <div *ngFor="let cell of group.cells" class="col-6 col-md-3 card" [ngClass]="{'wcm': group.key == 'work-centre-maintenance',
            'ps': group.key == 'personal-settings',
            'sdm':group.key == 'static-data-maintenance',
            'admin-item-bg':group.key == 'administration'}" routerLink="{{cell.routing}}">
            <div class="row justify-content-centre " style="margin-top:15px;">
              <div class="col-4 image cell-icon" [class.use-icon]="cell.iconClass">
                <img style="width: 64px" src="{{cell.icon}}">
                <i [ngClass]="cell.iconClass" *ngIf="cell.iconClass"></i>
              </div>
              <div class="col-8 align-self-center {{cell.class}}">
                <p class="mb-0 font-size-m {{cell.class}}">{{cell.description | translate}}</p>
                <p class="cell-value m-0" *ngIf="cell.subTitle">{{cell.subTitle}}</p>
              </div>
            </div>

          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>