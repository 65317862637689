import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Session }  from 'src/app/services/session';
import { AuthenticationMode } from 'src/app/entity/enum/authenticationMode';
import { Constants } from "src/constants";
import { PasswordErrorListComponent } from 'src/app/ui/components/password-error-list/password-error-list.component';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('passwordErrorList', { static: false }) passwordErrorList: PasswordErrorListComponent;

  isResettingPassword         : boolean = false;
  systemName                  : string = Constants.SYSTEM_DISPLAY_NAME;
  isLocalAccount              : boolean;
  rememberMe                  : boolean = false;
  resetPasswordForm = this.fb.group({
    existingPassword    : [''],
    newPassword         : [''],
    confirmPassword     : [''],
  });
  showPasswordError       : boolean = false;

  constructor(
    private fb: FormBuilder
    ) { 

  }

  ngOnInit() {
    if(Session.userToken == null){
      AppDelegate.navigate(['/login'], {
        replaceUrl: true,
      });
    }
    this.isLocalAccount = (Session.userInfo.authenticationMode == AuthenticationMode.LOCAL);
    this.setRememberMe();
    this.initFormControl();
  }

  initFormControl(): void {
    if (!this.isLocalAccount) {
      this.resetPasswordForm.controls['existingPassword'].disable();
      this.resetPasswordForm.controls['newPassword'].disable();
      this.resetPasswordForm.controls['confirmPassword'].disable();
    }
  }

  onUpdateUserPasswordClicked = () =>{
    this.resetPasswordClickError()
    if (!this.isLocalAccount) {
      return;
    }

    if (this.resetPasswordForm.value.existingPassword == null || this.resetPasswordForm.value.existingPassword.length == 0) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.user-profile.error.existing-password-cannot-be-empty'));
      return;
    }

    if (this.resetPasswordForm.value.newPassword == null || this.resetPasswordForm.value.newPassword.length == 0) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.user-profile.error.new-password-cannot-be-empty'));
      return;
    }

    if (this.resetPasswordForm.value.existingPassword 
      && this.resetPasswordForm.value.newPassword 
      && this.resetPasswordForm.value.newPassword === this.resetPasswordForm.value.existingPassword) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.user-profile.error.new-password-as-same-as-current-password'));
      return;
    }

    if (this.resetPasswordForm.value.newPassword !== this.resetPasswordForm.value.confirmPassword) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.user-profile.error.password-not-match'));
      return;
    }

    if (this.resetPasswordForm.value.newPassword != null && this.resetPasswordForm.value.newPassword != "" && this.resetPasswordForm.value.newPassword.length > 0){
      if(!this.passwordErrorList.isPasswordVaildate(this.resetPasswordForm.value.newPassword)){
        this.showPasswordError = true;
        return
      }
    }

    this.requestUpdatePassword();

  }

  private async requestUpdatePassword(){
    const request = new JM.JMRequestPostsUpdatePasswordV2();
    request.oldPassword   = this.resetPasswordForm.value.existingPassword;
    request.newPassword   = this.resetPasswordForm.value.newPassword;

    this.setSubmissionLoading(true);
    const response: JM.JMResponsePostsUpdatePasswordV2 = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      this.setSubmissionLoading(false);
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.post && response.payload.post.authorizations) {
      JM.JMConnector.cacheClear();

      JM.JMConnector.setPost(response.payload.post);
      JM.JMConnector.setToken(response.payload.token);

      Session.setUserInfo(response.payload.post);
      Session.setUserToken(response.payload.token);
      Session.setAuthSource(JMENUM.SourceSystem.CCEPJM);
      Session.setRememberMe(this.getRememberMe());
      Session.setRememberUsername(this.getRememberMe() ? response.payload.post.name : null);
      Session.setPmPeriodSavedSearchCriteria(null);

      // save cache
      JM.JMConnector.cacheEquipmentCategory().then((ecat) => {
        Session.setEquipmentCategoryDict(ecat);
      });
      JM.JMConnector.cacheEquipmentType().then((etype) => {
        Session.setEquipmentTypeDict(etype);
      });
      JM.JMConnector.cacheAllClient().then((allClient) => {
        Session.setClientDict(allClient);
      });
      JM.JMConnector.cacheAllWorkCentre().then((allWorkCentre) => {
        Session.setWorkCentreDict(allWorkCentre);
      });
      JM.JMConnector.cacheManualInstruction().then((allMI) => {
        Session.setManualInstructionArray(allMI);
      });
      //
      JM.JMConnector.cachCategoryObj().then((allCategoryObj) => {
        Session.setCategoryObj(allCategoryObj);
      });
      JM.JMConnector.cacheClassificationObj().then((allClassificationObj) => {
        Session.setClassificationObj(allClassificationObj);
      });
      const requestStorageLocationList = new JM.JMRequestInventoryGetStorageLocationList();
      requestStorageLocationList.isAiStore = true;
      AppDelegate.sendJMRequest(requestStorageLocationList).then((res)=>{
        Session.setStorageLocationAiList(res.payload.storageLocationList);
      });
      const requestCostCenters = new JM.JMRequestWorkCentresGetCostCentres();
      AppDelegate.sendJMRequest(requestCostCenters).then((res) => {
        Session.setCostCentreList(res.payload.costCentres);
      });
      
      const request = new JM.JMRequestContactPersonsUpdateContactPerson();
      request.post = response.payload.post.name;
      request.languagePreference = JMLanguage.getCurrentLanguage();
      AppDelegate.sendJMRequest(request);

      const apiToWaitList: Promise<any>[] = [];
      // get feature list
      const requestSystemFeatureList = new JM.JMRequestSystemConfigsGetSystemFeatureList();
      requestSystemFeatureList.systemName = JMENUM.SourceSystem.CCEPJM;

      apiToWaitList.push(AppDelegate.sendJMRequest(requestSystemFeatureList))
      // check jm account review 
      const checkReviewRequest = new JM.JMRequestAuthHalfYearReviewCheckReviewStatus();
      apiToWaitList.push(AppDelegate.sendJMRequest(checkReviewRequest));

      apiToWaitList.push(JM.JMConnector.cacheAll());

      Promise.all(apiToWaitList).then(([res1, res2, res3]) => {
        res1.payload && Session.setFeatureList(res1.payload.records);
        res2.payload && Session.setIsEnableJMAccountReview(res2.payload.enabled);
        AppDelegate.navigate(["/dashboard"], {
          replaceUrl: true,
        });
      })

    } else {
      this.setSubmissionLoading(false);
      AppDelegate.openErrorBar(response);
    }

  }

  private resetPasswordClickError(){
    this.showPasswordError = false
    this.passwordErrorList.resetPasswordErrors();
  }

  private setSubmissionLoading(isLoading: boolean) {
    this.isResettingPassword = isLoading;
    if (isLoading) {
      this.resetPasswordForm.disable();
    } else {
      this.resetPasswordForm.enable();
      this.initFormControl();
    }
  }

  private setRememberMe(){
    this.rememberMe = Session.rememberMe;
  }

  private getRememberMe():boolean{
    return this.rememberMe
  }
}
