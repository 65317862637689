import { Injectable } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';

@Injectable({
  providedIn: 'root',
})
export class PmPlanService {
  async requestPmPlanList(option : { pageNumber: number, pageSize: number, contractNumber?: string[], parameters?: string[], filter? }) {
    const request = new JM.JMRequestGetPmPlanList();
    option && option.pageSize && (request.pageSize = option && option.pageSize);
    option && option.pageNumber && (request.pageNumber = option && option.pageNumber);
    option && option.contractNumber && option.contractNumber.length > 0 && (request.contractNumber = option && option.contractNumber);
    if (option && option.filter) {
      request.filter = {
        ...option.filter
      };
    }
    if (option && option.parameters) {
      request.parameters = option && option.parameters
    }

    const response: JM.JMResponseGetPmPlanList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    return response.payload;
  }

  async requestPmPeriodList(option : { pageNumber: number, pageSize: number, pmPlanNumber: string[], parameters?: string[], filter? }) {
    const request = new JM.JMRequestGetPmPeriodList();
    request.pmPlanNumber = option && option.pmPlanNumber;
    option && option.pageSize && (request.pageSize = option && option.pageSize);
    option && option.pageNumber && (request.pageNumber = option && option.pageNumber);
    if (option && option.filter) {
      request.filter = {
        ...option.filter
      };
    }
    if (option && option.parameters) {
      request.parameters = option && option.parameters
    }

    const response: JM.JMResponseGetPmPeriodList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    return response.payload;
  }
}
