import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Session } from '@services/session';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { TablexComponentParams } from 'src/app/ui/components/tablex/tablex.component';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { formatDate } from 'src/lib/presenter/Formatter';

@Component({
  selector: 'app-stock-reserve-detail',
  templateUrl: './stock-reserve-detail.component.html',
  styleUrls: ['./stock-reserve-detail.component.scss']
})
export class StockReserveDetailComponent implements OnInit {
  breadcrumbs: any = [];
  snNumber: string;
  jobCardNumber: string;
  jobCard: JM.JMOBJ.JobCard;
  stockReservation: JM.JMOBJ.InventoryStockReservation;

  // model
  reservationTablexParam: TablexComponentParams = {};

  constructor(private route: ActivatedRoute) { }

  async ngOnInit() {
    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');

    this.requestJobCard().then(() => this.initBreadcrumbs());
    await this.requestStockReservation();
    this.initReservationTable();
    this.renderReservationTable();
  }

  async requestJobCard() {
    const request = new JM.JMRequestJobCardsGetJobCard();
    request.jobCardNumber = this.jobCardNumber;
    request.isGettingLatestStockNumbers = true;

    const response: JM.JMResponseJobCardsGetJobCard = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }

    if (response.payload.jobCard) {
      this.jobCard = response.payload.jobCard;
    }
  }

  async requestStockReservation() {
    const request = new JM.JMRequestInventoryGetStockReservation();
    request.jobNumber = this.jobCardNumber;
    request.isGettingLastestStockLevel = true;
    request.aiStoreInfoStatus = [JMENUM.RequestActive.ACTIVE];

    const response: JM.JMResponseInventoryGetStockReservation = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }
    
    if (response.payload) {
      this.stockReservation = response.payload;
    }
  }

  initBreadcrumbs() {
    let arr = [
      { id: 'breadcrumbs-job-card-number', name: this.jobCardNumber, route: `/job-card/view/${this.jobCardNumber}` },
      { id: 'breadcrumbs-page-title', name: JMLanguage.translate('pages.reserve-detail.page-title'), currentPage: true },
    ];
    if (this.jobCard && this.jobCard.snNumber) {
      arr.unshift({ id: 'breadcrumbs-sn-number', name: this.jobCard.snNumber, route: `/sn/view/${this.jobCard.snNumber}` })
    }
    this.breadcrumbs = arr;
  }

  initReservationTable() {
    this.reservationTablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      // tableWrapperClass: 'table-min-width',
      // onFilterChanged: this.onFilterChanged,
      // onFilterClear: this.onFilterClear,
      // onPageNumberClicked: this.onActionPageNumberClicked.bind(this),
      // onPageSizeClicked: this.onActionPageSizeClicked.bind(this),
      // onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      content: [],
      highlightedRows: [],
    };
    this.reservationTablexParam.headers = [
      {
        id: 'storageLocation.code',
        name: 'pages.reserve-stock.stock-table.column.location-code',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.TextIcon,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },  {
        id: 'storageLocation.description',
        name: 'pages.reserve-stock.stock-table.column.location-description',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-20',
      },
      {
        id: 'materialNumber',
        name: 'pages.reserve-stock.stock-table.column.material-no',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },
      {
        id: 'description',
        name: 'pages.reserve-stock.stock-table.column.material-description',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-20',
      },
      {
        id: 'batchNumber',
        name: 'pages.reserve-stock.stock-table.column.batch-no',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },
      {
        id: 'stockUnit',
        name: 'pages.reserve-stock.stock-table.column.unit',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-10',
      },
      {
        id: 'qtyRemain',
        name: 'pages.reserve-stock.stock-table.column.qty-remain',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-10',
      },
      {
        id: 'qtyRequired',
        name: 'pages.reserve-stock.stock-table.column.qty-required',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-10',
      },
      {
        id: 'qtyCommitted',
        name: 'pages.reserve-stock.stock-table.column.qty-committed',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.TextIconTooltip,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-10',
      },
      {
        id: 'qtyIssued',
        name: 'pages.reserve-stock.stock-table.column.qty-issued',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-10',
      },
    ];
  }

  renderReservationTable() {
    if (this.stockReservation) {
      const content = [];
      const reservation = this.stockReservation;

      const materials = reservation.material;
      const aiStoreList = Session.storageLocationAiList;

      materials.forEach((material) => {
        const stockUnitList = material.unitStockList;

        const unitStock = stockUnitList && stockUnitList.find(unitStock => {
          return unitStock.unit === material.unit;
        });

        const qtyRemain = unitStock ? unitStock.stock : "";
        const lowStock = material.qtyCommitted > 0 && material.qtyRequired > material.qtyCommitted;
        const isAiStore = aiStoreList && aiStoreList.findIndex(store => store.code == material.storageLocation.code) > -1;
        
        const storeColumn = {
          text: material.storageLocation.code,
          iconStyle: { height: '24px', width: '24px', marginLeft: '2px' },
          iconSrc: isAiStore ? '/assets/svg/ai_store.svg' : null,
        }

        content.push([
          storeColumn,
          material.storageLocation.description,
          material.materialNumber,
          material.description,
          material.batchNumber,
          material.unit,
          qtyRemain,
          material.qtyRequired,
          {
              text: material.qtyCommitted,
              tooltipMsg: lowStock ? JMLanguage.translate('component.reserve-stock.low-stock') : null,
          },
          material.qtyIssued,
          null,
        ]);
      });
        
      this.reservationTablexParam.content = content;
    } else {
      this.reservationTablexParam.content = [];
    }
  }

  get estimatedPickupDate() {
    if(this.stockReservation && this.stockReservation.aiStore) {
      return formatDate(this.stockReservation.aiStore.estimatedPickupDate, "DD/MM/YYYY");
    }
    else {
      return null;
    }
  }
}
