import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign, TablexColumnFilterOption, TablexColumnFilterType } from 'src/app/entity/enum/tablexColumnType';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BasePage } from "src/app/ui/model/base/base";
import { Session } from 'src/app/services/session';
import { Constants } from 'src/constants';
import { saveAs } from "file-saver";
import { formatDate } from '@angular/common';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as utility from 'src/app/services/utility';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';
import { EquipmentTagService } from '@services/equipment-tag.service';
import { ParameterService } from '@services/parameter.service';

const COVERED = "covered";
const ALL = "*";

@Component({
  selector: "routing-rules",
  templateUrl: "./routing-rules.html",
  styleUrls: ["./routing-rules.scss"]
})
export class RoutingRulesComponent extends BasePage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("create_routing_rule_panel", { static: true }) createRoutingRulePanel;
  @ViewChild("createRoutingRuleForm", { static: true }) createRoutingRuleForm;
  @ViewChild("teamScheduleTable", { static: true }) teamScheduleTable;

  priority = JMENUM.JMPriority; // for html
  handlingTeam = JMENUM.HandlingTeam; // for html
  teamStatus = JMENUM.TeamStatus; // for html
  dataBoxType = {
    tag: 'tag',
    team: 'team',
    service: 'service'
  }

  pageTitle: string;
  selectedListType: JMENUM.JMPriority.NonUrgent | JMENUM.JMPriority.Urgent | JMENUM.JMPriority.Emergency = JMENUM.JMPriority.NonUrgent;
  selectedWorkCenter: string;

  workCentreTagList: JMOBJ.HashTag[] = [];  // work centre all tag list
  usedWorkCentreTagIdSet: Set<string> = null;
  uiAvailableTagList: JMOBJ.HashTag[] = []; // left top, available to select tag list
  servicesCoverageList: JMOBJ.HashTag[] = []; // right top, current selected tag list

  supportTeamList: JMOBJ.Team[];
  allTeamList: JMOBJ.Team[];
  locationOptionDict: any = {}; // key: districtCode, value: array of location Option
  hasHATag: boolean = false;

  currentTeamIndex: number = undefined;
  uiHandlingTeamList: any = {};
  teamOnDrag: JMOBJ.Team;
  dragHanglingTeam: JMENUM.JMPriority.NonUrgent | JMENUM.JMPriority.Urgent | JMENUM.JMPriority.Emergency | JMENUM.HandlingTeam.PM = undefined;

  oldServicesCoverageObjString = "";
  oldTeamsEmergencyListObjString = "";
  oldTeamsUrgentListObjString = "";
  oldTeamsNonUrgentListObjString = "";
  oldTeamsPmListObjString = "";

  colorList = Constants.COLOR_LIST;
  colorTable = {};
  selectedTeamList = [];

  // isNewRoutingRulesView: boolean = false;
  uiSelectedCriteriaTab:0|1 = 0;
  hasEditionPermission: boolean = false;

  parentPageNumber = 0;
  parentPageSize = 0;
  parentScrollPosition = 0;

  currentRoutingRule: any = null;
  currentId: any = null;
  existingRoutingRuleCoverages: any[] = [];
  newRoutingRuleCoverages: any[] = [];

  // Routing Rules Table
  tablexFilter:{
    client?: string,
    district?: string,
    locations?: string,
    equipmentCategory?: string,
    equipmentTypes?: string,
  } = {};
  tablexParam: any = {};
  pageSizeOptions = [100, 200, 500];
  currentPageSize = 100;
  currentPage = 1;
  
  
  // unassigned coverage
  unassignedCoverageTablexFilter:{
    client?: string,
    district?: string,
    locations?: string,
    equipmentCategory?: string,
    equipmentTypes?: string[],
  } = {};
  unassignedCoverageTablexParam: any = {};
  unassignedCoveragePageSizeOptions = [100, 200, 500];
  unassignedCoverageCurrentPageSize = 100;
  unassignedCoverageCurrentPage = 1;

  isExportExcelButtonLoading = false;
  isUpdatedCoverageOrTeam = false;
  isUnassigningCoverages:boolean = false;
  isAssigningCoverages:boolean = false;
  isSavingRoutingRule:boolean = false;


  // HA Assets Table
  isAssigningAsset:boolean = false;
  isUnassigningAsset:boolean = false;
  assignedHAAssetList: {
    assetNumber: string,
    functionalLocation: string,
    ccsEquipmentNumber: string,
    ccsEquipmentDescription: string,
    haCluster: string,
    haEquipmentDescription: string,
  }[] = [];
  unassignedHAAssetList: {
    assetNumber: string,
    functionalLocation?: string,
    ccsEquipmentNumber: string,
    ccsEquipmentDescription: string,
    haCluster: string,
    haEquipmentDescription: string,
  }[] = [];

  haTablexParam: any = {};
  haPageSizeOptions = [100, 200, 500];
  haCurrentPageSize = 100;
  haCurrentPage = 1;
  selectedHAAssetList:string[] = []; // clear when changing tab,


  selectedCoverageList:any = [];
  selectedNewCoverageList:any = [];

  injector: Injector;

  constructor(
    injector: Injector,
    private equipmentTagService: EquipmentTagService,
    private parameterService: ParameterService,
  ) {
    super(injector);
    this.injector = injector;
  }

  ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.routing-rules.page-title", [Session.selectedWorkCentre]);

    
    this.uiHandlingTeamList[JMENUM.JMPriority.Emergency] = [];
    this.uiHandlingTeamList[JMENUM.JMPriority.NonUrgent] = [];
    this.uiHandlingTeamList[JMENUM.JMPriority.Urgent] = [];
    this.uiHandlingTeamList[JMENUM.HandlingTeam.PM] = [];
    this.uiHandlingTeamList[JMENUM.HandlingTeam.AVAILABLE] = [];

    this.selectedWorkCenter = Session.selectedWorkCentre;
    this.checkViewPermission(JMENUM.Permission.WCM_VIEW);
    this.checkWorkCenterPermission(this.selectedWorkCenter);
    this.hasEditionPermission = this.authorizationService.hasPermission(JMENUM.Permission.WCM_UPDATE) || this.authorizationService.hasPermission(JMENUM.Permission.WCM_UPDATE_ALL);
    
    this.initHaAssetsTable();
    this.initRoutingRuleTable();
    this.initUnassignCoverageTable();
  }

  async ngAfterViewInit() {
    // set routing rule id
    if(history.state._id) {
      this.currentId = history.state._id;
      Session.setRoutingRuleId(this.currentId);
    }else if(Session.routingRuleId){
      this.currentId = Session.routingRuleId;
    }

    // set previous page number, page size and scroll position for close button
    if(history.state.pageNumber) {
      this.parentPageNumber = history.state.pageNumber;
    }
    if(history.state.pageSize) {
      this.parentPageSize = history.state.pageSize;
    }
    if(history.state.scrollPosition) {
      this.parentScrollPosition = history.state.scrollPosition;
    }

    if (this.currentId) {
      await this.requestRoutingRuleSummary(true);
    }
    this.requestWorkCentresHashtags();
    this.requestTeamsAll();
  }

  ngOnDestroy() {
    Session.setRoutingRuleId(null);
  }

  onLangChange() {

  }

  onLanguageChanged() {

  }

  getTeamCssClass(team: JMOBJ.Team) {
    if (this.selectedWorkCenter == team.workCentre) {
      if (team.handlingParty == JMENUM.HandlingParty.INHOUSE) {
        return "equipment-item brand-blue";
      } else if (team.handlingParty == JMENUM.HandlingParty.PMSMC || team.handlingParty == JMENUM.HandlingParty.NON_PMSMC) {
        return "equipment-item brand-amber";
      }
    } else {
      return "equipment-item brand-red";
    }
  }

  addCssClassToTag(tagElements) {
    for (let element of tagElements) {
      const showEquipmentTag = element.equipmentSource && element.equipmentSource === JMENUM.EquipmentSource.CCS;
      const isUsableTag = this.equipmentTagService.isUsableTag(element, Session.selectedWorkCentre);

      const tagDescription = this.equipmentTagService.getTagDescription(element, showEquipmentTag);
      const tagClass = isUsableTag ? 'tag-blue' : 'tag-red';

      element['displayName'] = tagDescription;
      element['cssClass'] = `equipment-item ${tagClass}`;
    }
    return tagElements;
  }

  getAvailableEquipTagViewData(tagElements) {
    for (let element of tagElements) {
      const showEquipmentTag = element.equipmentSource && element.equipmentSource === JMENUM.EquipmentSource.CCS;
      const isUsableTag = this.equipmentTagService.isUsableTag(element, Session.selectedWorkCentre);
      const isUsedTagOtherServiceCoverage = this.usedWorkCentreTagIdSet.has(element._id);

      const tagDescription = this.equipmentTagService.getTagDescription(element, showEquipmentTag);
      const tagClass = isUsableTag ? 'tag-blue' : 'tag-red';

      element['displayName'] = tagDescription;
      element['cssClass'] = `equipment-item ${tagClass}`;
      element['isUsedTagOtherServiceCoverage'] = isUsedTagOtherServiceCoverage;
    }
    return tagElements;
  }

  //===================================================================================================================
  // API request function
  private async requestWorkCentresHashtags() {
    const request = new JM.JMRequestWorkCentresHashtagsV2();
    request.workCentre = this.selectedWorkCenter;
    request.active = JMENUM.RequestActive.ACTIVE;
    request.isGettingUsedTags = true;

    const response: JM.JMResponseWorkCentresHashtagsV2 = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    //filter the used tag in this rule
    this.uiAvailableTagList = [];
    this.workCentreTagList = response.payload.records;
    this.usedWorkCentreTagIdSet = new Set(response.payload.usedTags);

    let ruleTagIdList = (this.currentRoutingRule)?this.currentRoutingRule.equipmentTags.map(t=> { return t._id}): null;
    
    for (let tag of this.workCentreTagList) {
      if (!ruleTagIdList || !ruleTagIdList.includes(tag._id)) {
        this.uiAvailableTagList.push(tag);
      }
    }
    this.getAvailableEquipTagViewData(this.uiAvailableTagList);
  }

  private async requestTeamsAll() {
    const request = new JM.JMRequestTeamsTeamSummary();
    request.workCentreList = [this.selectedWorkCenter];
    request.includeApprovedWorkCentre = true;
    request.includeSummary = true;
    request.isRoTeam = false;
    request.pageSize = 1000;
    request.pageNumber = 1;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    // TODO: Add Stop spinner and show content 
    this.supportTeamList = [...response.payload.records];
    this.uiHandlingTeamList[JMENUM.HandlingTeam.AVAILABLE] = [...this.supportTeamList];
    this.allTeamList = [...response.payload.records];
  }

  private assignColor() {
    let teams = [];
    for(let teamList in this.uiHandlingTeamList) {
      if(teamList != JMENUM.HandlingTeam.AVAILABLE){
        for(let team in this.uiHandlingTeamList[teamList]) {
          teams.push(this.uiHandlingTeamList[teamList][team]._id);
        }
      }
    }

    // remove duplicate
    teams = teams.filter((item, index) => teams.indexOf(item) == index);

    for(let key in this.colorTable) {
      if(!teams.includes(key)) {
        delete this.colorTable[key];
      }
    }

    for (let teamId of teams) {
      if(!this.colorTable[teamId]) {
        for(let color of this.colorList) {
          if(Object.values(this.colorTable).indexOf(color) < 0) {
            this.colorTable[teamId] = color;
            break;
          }
        }
      }
    }
  }
  private clearHASelection() {
    this.selectedHAAssetList = [];
    for (let i in this.haTablexParam['highlightedRows']) {
      this.haTablexParam['highlightedRows'][i] = false;
    }
    this.haTablexParam.selectedRowCount = 0;
  }

  private clearSelection() {
    this.selectedCoverageList = [];
    for (let i in this.tablexParam['highlightedRows']) {
      this.tablexParam['highlightedRows'][i] = false;
    }
    this.tablexParam.selectedRowCount = 0;
    
    this.selectedNewCoverageList = [];
    for (let i in this.unassignedCoverageTablexParam['highlightedRows']) {
      this.unassignedCoverageTablexParam['highlightedRows'][i] = false;
    }
    this.unassignedCoverageTablexParam.selectedRowCount = 0;
  }

  private initRoutingRuleTable() {
    let clientFilter = new TablexColumnFilterOption();
    clientFilter.items = Object.values(Session.clientDict || {}).map(c => {
      return {
        value: c.clientShortName,
        label: c.clientShortName,
      }
    });
    clientFilter.change = this.onDropdownFilterChanged;
    
    // district filter
    let districtFilter = new TablexColumnFilterOption();
    districtFilter.items = this.parameterService.allDistrict().map(d => {
      return {
        value: d,
        label: JMLanguage.translate(`district.${d}`),
      }
    });
    districtFilter.change = this.onDropdownFilterChanged;

    // equipment category filter
    let catFilter = new TablexColumnFilterOption();
    catFilter.items = Object.values(Session.equipmentCategoryDict).map(c => {
      return {
        value: c.code,
        label: `${c.code} - ${c.description[Session.selectedLanguage]}`,
      }
    });
    catFilter.change = this.onDropdownFilterChanged;
    
    // equipment type filter
    let typeFilter = new TablexColumnFilterOption();
    typeFilter.items = Object.values(Session.equipmentTypeDict).map(t => {
      return {
        value: t.code,
        label: `${t.code} - ${t.description[Session.selectedLanguage]}`,
      }
    });
    typeFilter.change = this.onDropdownFilterChanged;

    this.tablexParam = {
      content:[],
      highlightedRows: [],
      selectedRowCount: 0,
      totalRowCount: 0 ,
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableSelectedRowCount: (this.hasEditionPermission),
      enableSelectedAll: (this.hasEditionPermission),
      enableSort: true,
      tableRow: "row",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: 1,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onSearchFilterChanged,
      onSortOrderChanged: this.onSortOrderChanged,
      onRowClicked: (this.hasEditionPermission)?this.onRowClicked: () => {},
      filterDebounceTime: Constants.DEBOUNCE_TIME,
    };
    this.tablexParam["headers"] = [
      {
        id: 'id',
        name: "pages.routing-rules.id",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 col-lg-1 p-1 p-xl-1",
      },
      {
        id: 'client',
        name: "pages.routing-rules.client",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown, 
        filterDropdownOption: clientFilter,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 col-lg-1 p-1 p-xl-2",
      },
      {
        id: 'district',
        name: "pages.routing-rules.district",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown, 
        filterDropdownOption: districtFilter,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 col-lg-1 p-1 p-xl-2",
      },
      {
        id: 'locations',
        name: "pages.routing-rules.location",
        enableFilter: true,
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-3 col-lg-3 p-3 p-xl-2",
      },
      {
        id: 'equipmentCategory',
        name: "pages.routing-rules.equipment-category",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown, 
        filterDropdownOption: catFilter,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-2 col-lg-2 p-2 p-xl-2",
      },
      {
        id: 'equipmentTypes',
        name: "pages.routing-rules.equipment-type",
        enableFilter: true,
        type: TablexColumnType.MultiLine,
        filterType: TablexColumnFilterType.Dropdown, 
        filterDropdownOption: typeFilter,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-3 col-lg-3 p-3 p-xl-2",
      },
      {
        id: 'action',
        name: "pages.routing-rules.action",
        enableFilter: false,
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 col-lg-1 p-1 p-xl-1",
      }
    ];
  }
  
  private initUnassignCoverageTable() {
    let clientFilter = new TablexColumnFilterOption();
    clientFilter.items = Object.values(Session.clientDict || {}).map(c => {
      return {
        value: c.clientShortName,
        label: c.clientShortName,
      }
    });
    clientFilter.change = this.onUnassignedCoverageDropdownFilterChanged;
    
    // district filter
    let districtFilter = new TablexColumnFilterOption();
    districtFilter.items = this.parameterService.allDistrict().map(d => {
      return {
        value: d,
        label: JMLanguage.translate(`district.${d}`),
      }
    });
    districtFilter.change = this.onUnassignedCoverageDropdownFilterChanged;

    // equipment category filter
    let catFilter = new TablexColumnFilterOption();
    catFilter.items = Object.values(Session.equipmentCategoryDict).map(c => {
      return {
        value: c.code,
        label: `${c.code} - ${c.description[Session.selectedLanguage]}`,
      }
    });
    catFilter.change = this.onUnassignedCoverageDropdownFilterChanged;
    
    // equipment type filter
    let typeFilter = new TablexColumnFilterOption();
    typeFilter.items = Object.values(Session.equipmentTypeDict).map(t => {
      return {
        value: t.code,
        label: `${t.code} - ${t.description[Session.selectedLanguage]}`,
      }
    });
    typeFilter.change = this.onUnassignedCoverageDropdownFilterChanged;

    this.unassignedCoverageTablexParam = {
      content:[],
      highlightedRows: [],
      selectedRowCount: 0,
      totalRowCount: 0 ,
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableSelectedRowCount: (this.hasEditionPermission),
      enableSelectedAll: (this.hasEditionPermission),
      enableSort: true,
      tableRow: "row",
      pageSizeOptions: this.unassignedCoveragePageSizeOptions,
      currentPageSize: this.unassignedCoverageCurrentPageSize,
      currentPage: this.unassignedCoverageCurrentPage,
      pageCount: 1,
      onPageNumberClicked: this.onUnassignedCoveragePageNumberClicked,
      onPageSizeClicked: this.onUnassignedCoveragePageSizeClicked,
      onFilterChanged: this.onUnassignedCoverageSearchFilterChanged,
      onRowClicked: (this.hasEditionPermission)?this.onUnassignedCoverageRowClicked: ()=>{},
      filterDebounceTime: Constants.DEBOUNCE_TIME,
    };
    this.unassignedCoverageTablexParam["headers"] = [
      {
        id: 'id',
        name: "pages.routing-rules.id",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 col-lg-1 p-1 p-xl-1",
      },
      {
        id: 'client',
        name: "pages.routing-rules.client",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown, 
        filterDropdownOption: clientFilter,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 col-lg-1 p-1 p-xl-2",
      },
      {
        id: 'district',
        name: "pages.routing-rules.district",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown, 
        filterDropdownOption: districtFilter,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-2 col-lg-2 p-2 p-xl-2",
      },
      {
        id: 'locations',
        name: "pages.routing-rules.location",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-4 col-lg-4 p-4 p-xl-2",
      },
      {
        id: 'equipmentCategory',
        name: "pages.routing-rules.equipment-category",
        enableFilter: true,
        type: TablexColumnType.MultiLine,// Text,
        filterType: TablexColumnFilterType.Dropdown, 
        filterDropdownOption: catFilter,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-2 col-lg-2 p-2 p-xl-2",
      },
      {
        id: 'equipmentTypes',
        name: "pages.routing-rules.equipment-type",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown, 
        filterDropdownOption: typeFilter,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-2 col-lg-2 p-2 p-xl-2",
      }
    ];
  }

  private reloadCoverageTable() {
    if (this.uiSelectedCriteriaTab == 1) {
      this.requestUnassignedCoverages();
    } else {
      this.requestAssignedCoverages();
    }
  }

  private async checkHaveLocationDescription(coverages) {
    let array = [];
    for(let i = 0; i < coverages.length; i++) {
      let obj = coverages[i];
      if (!obj.locations) continue;
      for (let j = 0; j < obj.locations.length; j++) {
        let code = obj.locations[j];
        let haveValue = false;
        if (this.currentRoutingRule){
          if (this.currentRoutingRule.locationDescription && this.currentRoutingRule.locationDescription[code]) {
            let desp = this.currentRoutingRule.locationDescription[code][Session.selectedLanguage];
            if(desp != "") {
              haveValue = true;
            }
          }
        }
        if(!haveValue) {
          let location = this.createRoutingRuleForm.getLocation(obj.district, code);
          if(!location || !location.label) {
            if(array.indexOf(obj.district) < 0) {
              array.push(obj.district);
            }
          }
        }
      }
    }
    if(array.length > 0) {
      await this.createRoutingRuleForm.requestLocationSummaryWithCode(array);
    }
  }

  private async requestRoutingRuleSummary( requestCoverageTable = false ) {
    const request = new JM.JMRequestRoutingRulesRoutingRuleSummary();
    request.includeSummary = true;
    request.parameters = [
      '_id',
      'workCentre',
      'equipmentTags',
      'teams',
      // 'coverages',
      'locationDescription',
    ];
    request.pageSize = 1;
    request.pageNumber = 1;
    request._id = this.currentId;

    const response: JM.JMResponseRoutingRulesRoutingRuleSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records && response.payload.records.length) {
      this.currentRoutingRule = response.payload.records[0];
      this.retrieveTagsAndTeams();
      this.updateScheduleTable();

      if (this.currentRoutingRule.equipmentTags && this.currentRoutingRule.equipmentTags.length) {
        this.hasHATag = this.currentRoutingRule.equipmentTags[0].equipmentSource == JMENUM.EquipmentSource.HA ? true : false;
      }
    }

    if (requestCoverageTable) {
      if (this.hasHATag) {
        this.reloadHATable();    
      } else {
        this.reloadCoverageTable();
      }
    }
  }

  requestAssignedCoverages = async () => {
    try {
      this.clearSelection();
      if(this.currentId) {
        const request = new JM.JMRequestRoutingRulesGetAssignedRuleCoverages();
        request.ruleId = this.currentId;
        request.pageSize = this.currentPageSize;
        request.pageNumber = this.currentPage;
        request.sortBy = 'ruleSequence';
        request.sortOrder = -1;
        
        if (this.tablexFilter.client)             { request.client            = this.tablexFilter.client; };
        if (this.tablexFilter.district)           { request.district          = this.tablexFilter.district; };
        if (this.tablexFilter.locations)          { request.location          = this.tablexFilter.locations; };
        if (this.tablexFilter.equipmentCategory)  { request.equipmentCategory = this.tablexFilter.equipmentCategory; };
        if (this.tablexFilter.equipmentTypes)     { request.equipmentType     = this.tablexFilter.equipmentTypes; };

        this.tablexParam['isLoadingTable'] = true;
        let response:JM.JMResponseRoutingRulesGetAssignedRuleCoverages = await AppDelegate.sendJMRequest(request);
        this.tablexParam['isLoadingTable'] = false;
        if (!response || !response.code || response.code !== 200 || !response.payload) {
          this.tablexParam['isLoadingTable'] = false;
          AppDelegate.openErrorBar(response);
          return false;
        }
        
        this.existingRoutingRuleCoverages = response.payload.records;
        this.tablexParam.totalRowCount = response.payload.totalCount;
        this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / this.currentPageSize);

        await this.checkHaveLocationDescription(this.existingRoutingRuleCoverages);

        this.renderTable();
        return true;

      } else {
        this.existingRoutingRuleCoverages = [];
        this.renderTable();
        return true;
      }
    } catch (e) {
      AppDelegate.openErrorBar();      
      return false;
    }
  }
  
  private async requestUnassignedCoverages() {
    try {
      this.clearSelection();
      if (this.servicesCoverageList.length === 0) {
        this.newRoutingRuleCoverages = [];
        this.renderUnassignTable();
        return;
      }
      const request = new JM.JMRequestRoutingRulesGetUnassignedRuleCoverages();
      if (this.currentId) request.ruleId = this.currentId;
      request.tagIds = this.servicesCoverageList.map((element) => element["_id"]);
      request.pageSize = this.unassignedCoverageCurrentPageSize;
      request.pageNumber = this.unassignedCoverageCurrentPage;
      request.sortBy = 'ruleSequence';
      request.sortOrder = JMENUM.SortOrder.DESC;

      if (this.unassignedCoverageTablexFilter.client)             { request.clients            = [this.unassignedCoverageTablexFilter.client];              };
      if (this.unassignedCoverageTablexFilter.district)           { request.districts          = [this.unassignedCoverageTablexFilter.district];            };
      if (this.unassignedCoverageTablexFilter.locations)          { request.locations          = [this.unassignedCoverageTablexFilter.locations];           };
      if (this.unassignedCoverageTablexFilter.equipmentCategory)  { request.equipmentCategories  = [this.unassignedCoverageTablexFilter.equipmentCategory];  };
      if (this.unassignedCoverageTablexFilter.equipmentTypes && this.unassignedCoverageTablexFilter.equipmentTypes.length > 0) {
        request.equipmentTypes     = this.unassignedCoverageTablexFilter.equipmentTypes;      
      };

      this.unassignedCoverageTablexParam['isLoadingTable'] = true;
      const response:JM.JMResponseRoutingRulesGetUnassignedRuleCoverages = await AppDelegate.sendJMRequest(request);
      this.unassignedCoverageTablexParam['isLoadingTable'] = false;
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      this.newRoutingRuleCoverages = response.payload.records;
      this.unassignedCoverageTablexParam.totalRowCount = response.payload.totalCount;
      this.unassignedCoverageTablexParam.pageCount = Math.ceil(response.payload.totalCount / this.unassignedCoverageCurrentPageSize);
      

      await this.checkHaveLocationDescription(this.newRoutingRuleCoverages);
      this.renderUnassignTable();

    } catch (e) {
      AppDelegate.openErrorBar();
    }
  }

  // HA related
  requestGetAssignedHACoverages = async () => {
    try {
      this.clearHASelection();
      this.unassignedHAAssetList = [];
      this.haTablexParam.content = [];
      if(this.currentId) {
        const request = new JM.JMRequestRoutingRulesGetAssignedHARuleCoverages();
        request.ruleId = this.currentId;
        request.pageSize = this.haCurrentPageSize;
        request.pageNumber = this.haCurrentPage;
        request.sortBy = 'assetNumber';
        request.sortOrder = JMENUM.SortOrder.ASC;
        
        if (this.haTablexParam.filter.assetNumber)              { request.assetNumber             = this.haTablexParam.filter.assetNumber; };
        if (this.haTablexParam.filter.ccsEquipmentDescription)  { request.ccsEquipmentDescription = this.haTablexParam.filter.ccsEquipmentDescription; };
        if (this.haTablexParam.filter.haEquipmentDescription)   { request.haEquipmentDescription  = this.haTablexParam.filter.haEquipmentDescription; };
        if (this.haTablexParam.filter.haCluster)                { request.haCluster               = this.haTablexParam.filter.haCluster; };
        if (this.haTablexParam.filter.ccsEquipmentNumber)       { request.ccsEquipmentNumber      = this.haTablexParam.filter.ccsEquipmentNumber; };
        if (this.haTablexParam.filter.functionalLocation)       { request.functionalLocation      = this.haTablexParam.filter.functionalLocation; };

        this.haTablexParam['isLoadingTable'] = true;
        const response:JM.JMResponseRoutingRulesGetAssignedHARuleCoverages = await AppDelegate.sendJMRequest(request);
        this.haTablexParam['isLoadingTable'] = false;
        if (!response || !response.code || response.code !== 200 || !response.payload) {
          this.haTablexParam['isLoadingTable'] = false;
          AppDelegate.openErrorBar(response);
          return false;
        }
        
        this.assignedHAAssetList = response.payload.records;
        this.haTablexParam.totalRowCount = response.payload.totalCount;
        this.haTablexParam.pageCount = Math.ceil(response.payload.totalCount / this.haCurrentPageSize);
        for (let asset of this.assignedHAAssetList) {
          this.haTablexParam.content.push([
            asset.assetNumber,
            asset.ccsEquipmentDescription,
            asset.haEquipmentDescription,
            asset.haCluster,
            asset.ccsEquipmentNumber,
            asset.functionalLocation,
          ]);
        };

        return true;

      } else {
        this.existingRoutingRuleCoverages = [];
        this.haTablexParam.totalRowCount = 0;
        this.haTablexParam.pageCount = 1;
        this.haTablexParam['content'] = [];
        return true;
      }
    } catch (e) {
      AppDelegate.openErrorBar();      
      return false;
    }
  }
  
  private async requestGetUnassignedHACoverages() {
    try {
      this.clearHASelection();
      this.unassignedHAAssetList = [];
      this.haTablexParam.content = [];
      if (this.servicesCoverageList.length === 0) {
        return;
      }
      
      const request = new JM.JMRequestEquipmentsGetUnassignedHAAsset();
      if (this.currentId) request.ruleId = this.currentId;
      request.tagIds = this.servicesCoverageList.map((element) => element["_id"]);
      request.pageSize = this.haCurrentPageSize;
      request.pageNumber = this.haCurrentPage;
      request.haCluster = Session.workCentreDict[Session.selectedWorkCentre].HACluster;
      request.sortBy = 'assetNumber';
      request.sortOrder = JMENUM.SortOrder.ASC;

      if (this.haTablexParam.filter.assetNumber)              { request.assetNumber             = this.haTablexParam.filter.assetNumber; };
      if (this.haTablexParam.filter.ccsEquipmentDescription)  { request.ccsEquipmentDescription = this.haTablexParam.filter.ccsEquipmentDescription; };
      if (this.haTablexParam.filter.haEquipmentDescription)   { request.haEquipmentDescription  = this.haTablexParam.filter.haEquipmentDescription; };
      // if (this.haTablexParam.filter.haCluster)                { request.haCluster               = this.haTablexParam.filter.haCluster; };
      if (this.haTablexParam.filter.ccsEquipmentNumber)       { request.ccsEquipmentNumber      = this.haTablexParam.filter.ccsEquipmentNumber; };
      if (this.haTablexParam.filter.functionalLocation)       { request.functionalLocation      = this.haTablexParam.filter.functionalLocation; };


      this.haTablexParam['isLoadingTable'] = true;
      const response:JM.JMResponseEquipmentsGetUnassignedHAAsset = await AppDelegate.sendJMRequest(request);
      this.haTablexParam['isLoadingTable'] = false;
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      this.unassignedHAAssetList = response.payload.records;
      this.haTablexParam.totalRowCount = response.payload.totalCount;
      this.haTablexParam.pageCount = Math.ceil(response.payload.totalCount / this.haCurrentPageSize);
      for (let asset of response.payload.records) {
        this.haTablexParam.content.push([
          asset.assetNumber,
          asset.ccsEquipmentDescription,
          asset.haEquipmentDescription,
          asset.haCluster,
          asset.ccsEquipmentNumber,
          asset.functionalLocation,
        ]);
      }
    } catch (e) {
      AppDelegate.openErrorBar();
    }
  }

  // end of HA related
  

  private async requestCreateRoutingRule(obj?) {
    if (!this.hasEditionPermission) {
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }

    if(this.checkMandatoryFields()) {
      const request = new JM.JMRequestRoutingRulesCreateRoutingRule();
      request.workCentre = this.selectedWorkCenter;
      let teams = JSON.parse(JSON.stringify(this.uiHandlingTeamList)); //clone 2d array
      delete teams[JMENUM.HandlingTeam.AVAILABLE];
      request.teams = teams;
      request.equipmentTags = this.servicesCoverageList.map((element) => element["_id"]);

      this.isSavingRoutingRule = true;
      const response: JM.JMResponseRoutingRulesCreateRoutingRule = await AppDelegate.sendJMRequest(request);
      this.isSavingRoutingRule = false;
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      try {
        AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
        this.currentId = response.payload._id;
        // ugly solution, get again, since the rule obj is not a RoutingRule object
        await this.requestRoutingRuleSummary();
        Session.setRoutingRuleId(this.currentId);
        this.isUpdatedCoverageOrTeam = false;
        
        // if (!this.hasHATag) {
        //   this.requestRoutingRuleSummary(true);
          // this.createRoutingRuleForm.initData();
          // this.createRoutingRuleForm.setServicesCoverageList(this.servicesCoverageList);
          // this.createRoutingRulePanel.toggle();
        // }
      } catch (e) {
        AppDelegate.openErrorBar();
      }
    }
  }

  private async requestUpdateRoutingRule() {
    if (!this.hasEditionPermission) {
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    if (this.checkMandatoryFields()) {
      let request = new JM.JMRequestRoutingRulesUpdateRoutingRule();
      request._id = this.currentId;
      request.workCentre = this.selectedWorkCenter;
      let teams = JSON.parse(JSON.stringify(this.uiHandlingTeamList)); //clone 2d array
      delete teams[JMENUM.HandlingTeam.AVAILABLE];
      request.teams = teams;
      request.equipmentTags = this.servicesCoverageList.map((element) => element["_id"]);

      const response: JM.JMResponseRoutingRulesUpdateRoutingRule = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }

      try {
        this.requestRoutingRuleSummary();
        AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
        this.isUpdatedCoverageOrTeam = false;
      } catch (e) {
        AppDelegate.openErrorBar();
      }
    }
  }

  private async requestsAddRoutingRuleCoverage(obj) {  
    if (!this.hasEditionPermission) {
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }

    let coverages = [];
    // prepare location
    let locationArray = [];
    if(obj.location && obj.location.length){
      for (let i = 0; i < obj.location.length; i++) {
        let location = obj.location[i];
        if (location.value) {
          locationArray.push(location.value);
        }
      }
    }

    // prepare equipment type
    let equipmentTypeArray = [];
    if(obj.equipmentType && obj.equipmentType.length){
      for (let i = 0; i < obj.equipmentType.length; i++) {
        let equipmentType = obj.equipmentType[i];
        if (equipmentType.value) {
          equipmentTypeArray.push(equipmentType.value);
        }
      }
    }

    // prepare equipment category
    if (obj.equipmentCategory.value == COVERED) {
      for (let tag of this.servicesCoverageList) {
        coverages.push({
          client: obj.client.value,
          district: obj.district.value,
          locations: locationArray,
          equipmentCategory: tag.equipmentCategory,
          equipmentTypes: [tag.equipmentType],
          source: JMENUM.RoutingRuleSource.CHILD, // the parent will be created in backend
        });
      }
    } else {
      let equipCat = (obj.equipmentCategory.value != ALL)? obj.equipmentCategory.value: null;
      coverages.push({
        client: obj.client.value,
        district: obj.district.value,
        locations: locationArray,
        equipmentCategory: equipCat,
        equipmentTypes: equipmentTypeArray,
        source: JMENUM.RoutingRuleSource.MANUEL,
      });
    }
    
    const request = new JM.JMRequestRoutingRulesAssignRuleCoverages();
    request.ruleId = this.currentId;
    request.coverages = coverages;

    const response:JM.JMResponseRoutingRulesAssignRuleCoverages = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));
    this.createRoutingRulePanel.toggle();
    this.requestAssignedCoverages();
    
  }

  private async requestUpdateRoutingRuleCoverage(obj) {
    if (!this.hasEditionPermission) {
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    
    const request = new JM.JMRequestRoutingRulesUpdateRoutingRuleCoverage();
    request._id = this.currentId;
    request.oldSource = obj.originalCoverage.source;
    request.oldClient = obj.originalCoverage.client ? obj.originalCoverage.client : null;
    request.oldDistrict = obj.originalCoverage.district ? obj.originalCoverage.district : null;
    request.oldLocations = obj.originalCoverage.locations ? obj.originalCoverage.locations : [];
    request.oldEquipmentCategory = obj.originalCoverage.equipmentCategory && obj.originalCoverage.equipmentCategory != ALL ? obj.originalCoverage.equipmentCategory : null
    request.oldEquipmentTypes = obj.originalCoverage.equipmentTypes ? obj.originalCoverage.equipmentTypes : [];
    request.source = 1;
    request.client = obj.client && obj.client.value? obj.client.value : null;
    request.district = obj.district && obj.district.value ? obj.district.value : null;
   
    let locationArray = [];
    if(obj.location && obj.location.length){
      for (let i = 0; i < obj.location.length; i++) {
        let location = obj.location[i];
        if (location.value) {
          locationArray.push(location.value);
        }
      }
    }
    request.locations = locationArray;

    let equipmentTypeArray = [];
    if(obj.equipmentType && obj.equipmentType.length){
      for (let i = 0; i < obj.equipmentType.length; i++) {
        let equipmentType = obj.equipmentType[i];
        if (equipmentType.value) {
          equipmentTypeArray.push(equipmentType.value);
        }
      }
    }
    request.equipmentTypes = equipmentTypeArray;

    request.equipmentCategory = null;
    if (obj.equipmentCategory && obj.equipmentCategory.value) {
      if (obj.equipmentCategory.value == COVERED) {
        request.source = 3;
      }else if (obj.equipmentCategory.value != ALL) {
        request.equipmentCategory = obj.equipmentCategory.value;
      }
    }

    const response:JM.JMResponseRoutingRulesUpdateRoutingRuleCoverage = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    try {
      this.createRoutingRulePanel.toggle();
      this.requestRoutingRuleSummary(true);
    } catch (e) {
      AppDelegate.openErrorBar();
    }
  }

  private async requestDeleteRoutingRuleCoverage(ruleId, coverageSequenceList) {
    try{ 
      if (!this.hasEditionPermission) {
        AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
        return;
      }

      const request = new JM.JMRequestRoutingRulesUnassignRuleCoverages();
      request.ruleId = ruleId;
      request.coverageIds = coverageSequenceList;

      const response: JM.JMResponseRoutingRulesUnassignRuleCoverages = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return false;
      }

      this.requestAssignedCoverages();
      return true;
    } catch(e) {
      AppDelegate.openErrorBar();
      return false;
    }
  }

  private async requestExportRoutingRuleSummary() {
    const request = new JM.JMRequestRoutingRulesExportRoutingRuleSummary();
    request.workCentre = this.selectedWorkCenter;
    request._id = this.currentId;

    const response: JM.JMResponseRoutingRulesExportRoutingRuleSummary = await AppDelegate.sendJMRequest(request);
    this.isExportExcelButtonLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return false;
    }

    try {
      let fileName = "RoutingRules_" + formatDate(new Date(), "yyyy-MM-dd", 'en-US') + ".xlsx";
      let base64 = response.payload.base64;
      let blob = utility.base64ToBlob(base64, response.payload.type);
      saveAs(blob, fileName);
    } catch (e) {
      AppDelegate.openErrorBar();
    }
  }

  private async requestExportNewRoutingRuleSummary() {
    const request = new JM.JMRequestRoutingRulesExportNewRoutingRuleSummary();
    request.workCentre = this.selectedWorkCenter;
    request._id = this.currentId;
    request.equipmentTags = this.servicesCoverageList.map((element) => element["_id"]);
    let teams = JSON.parse(JSON.stringify(this.uiHandlingTeamList)); //clone 2d array
    delete teams[JMENUM.HandlingTeam.AVAILABLE];
    request.teams = teams;

    const response: JM.JMResponseRoutingRulesExportNewRoutingRuleSummary = await AppDelegate.sendJMRequest(request);
    this.isExportExcelButtonLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return false;
    }

    try {
      let fileName = "RoutingRules_" + formatDate(new Date(), "yyyy-MM-dd", 'en-US') + ".xlsx";
      let base64 = response.payload.base64;
      let blob = utility.base64ToBlob(base64, response.payload.type);
      saveAs(blob, fileName);
    } catch (e) {
      AppDelegate.openErrorBar();
    }
  }

  allowDrop(event) {
    event.preventDefault();
  }
  
  onDragStartTag(event) {
    event.dataTransfer.setData("index", event.target.dataset.index);
    event.dataTransfer.setData("box", event.target.dataset.box);
  }

  onDragStartTeam(event, team, dragHanglingTeam) {
    event.dataTransfer.setData("index", event.target.dataset.index);
    event.dataTransfer.setData("team", event.target.dataset.team);
    
    this.teamOnDrag = team;
    this.dragHanglingTeam = dragHanglingTeam ? dragHanglingTeam : null;
  }
  
  onDropServiceCoverage(event) {
    event.preventDefault();
    let tagIndex = event.dataTransfer.getData("index");
    let fromBox = event.dataTransfer.getData("box");
    if (fromBox === this.dataBoxType.tag) {
      let isHATag = this.uiAvailableTagList[tagIndex]['equipmentSource'] == JMENUM.EquipmentSource.HA ? true : false;
      if (this.servicesCoverageList.length > 0 && this.hasHATag != isHATag) {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.alert-ha-ccs-tag-same-rr"));
        return;
      }

      this.hasHATag = isHATag;
      this.servicesCoverageList.push(this.uiAvailableTagList[tagIndex]);
      this.uiAvailableTagList.splice(tagIndex, 1);
      this.sortTags(this.servicesCoverageList);

      if (this.uiSelectedCriteriaTab == 1) {
        if (this.hasHATag) {
          this.uiHandlingTeamList[JMENUM.JMPriority.Emergency] = [];
          this.uiHandlingTeamList[JMENUM.JMPriority.Urgent] = []
          this.requestGetUnassignedHACoverages();
        } else {
          this.requestUnassignedCoverages();
        }
      }
    }
    this.checkUpdateCoverageAndTeams();
  }

  onDropEquipmentTag(event){
    event.preventDefault();
    let serviceIndex = event.dataTransfer.getData("index");
    let formBox = event.dataTransfer.getData("box");
    if (formBox === this.dataBoxType.service) {
      this.uiAvailableTagList.push(this.servicesCoverageList[serviceIndex]);
      this.servicesCoverageList.splice(serviceIndex, 1);
      this.sortTags(this.uiAvailableTagList);
      if (!this.servicesCoverageList || (this.servicesCoverageList && this.servicesCoverageList.length <= 0)) {
        this.hasHATag = false;
      }

      if (this.hasHATag) {
        // this.requestHSDEquipmentSummary();
        this.reloadHATable();
      } else if (this.uiSelectedCriteriaTab == 1) {
        this.requestUnassignedCoverages();
      }
    }
    this.checkUpdateCoverageAndTeams();
  }

  onDragEnterTeamIndex(targetHandlingTeam, fieldsKey) {
    if (this.dragHanglingTeam !== targetHandlingTeam) { return; }

    this.currentTeamIndex = this.uiHandlingTeamList[targetHandlingTeam].indexOf(this.teamOnDrag);
    let isDuplicated = this.uiHandlingTeamList[targetHandlingTeam] ? this.uiHandlingTeamList[targetHandlingTeam].find(team => team._id == this.teamOnDrag['_id']) : false;
    if (isDuplicated && this.currentTeamIndex != fieldsKey) {
      // For changing team sequence only
      this.uiHandlingTeamList[targetHandlingTeam][this.currentTeamIndex] = this.uiHandlingTeamList[targetHandlingTeam][fieldsKey];
      this.uiHandlingTeamList[targetHandlingTeam][fieldsKey] = this.teamOnDrag;
    }
  }

  onDropTeam(event, targetTeam){
    event.preventDefault();
    let teamIndex = event.dataTransfer.getData("index");
    let teamSource = event.dataTransfer.getData("team");
    if (teamSource) {
      let isDuplicated = this.uiHandlingTeamList[targetTeam]? this.uiHandlingTeamList[targetTeam].find(team => team._id == this.teamOnDrag['_id']) : false;
      let isEmptyContactSequence = targetTeam == JMENUM.HandlingTeam.AVAILABLE ? false : this.isEmptyContactSequencesTeam(this.teamOnDrag, targetTeam);
  
      if (targetTeam != teamSource && !isEmptyContactSequence) {
        if (!isDuplicated) {
          this.uiHandlingTeamList[targetTeam].unshift(this.teamOnDrag);
        } else if (isDuplicated && targetTeam == JMENUM.HandlingTeam.AVAILABLE) {
          this.uiHandlingTeamList[teamSource].splice(teamIndex, 1);
        }
        this.checkUpdateCoverageAndTeams();
      }
  
      setTimeout(() => {
        this.updateScheduleTable(); 
      }, 100);
    }
  }

  private retrieveTagsAndTeams() {
    // set tag drag and drop data
    this.servicesCoverageList = this.addCssClassToTag(this.currentRoutingRule.equipmentTags);
    this.oldServicesCoverageObjString = JSON.stringify(this.servicesCoverageList);

    // remove duplicated items with coverage list
    // for(let index in this.workCentreTagList) {
    //   for(let coverage of this.servicesCoverageList) {
    //     if(this.workCentreTagList[index]._id == coverage._id) {
    //       delete this.workCentreTagList[index];
    //     }
    //   }
    // }

    this.sortTags(this.servicesCoverageList);
    this.sortTags(this.workCentreTagList);

    // set team drag and drop data
    this.uiHandlingTeamList = JSON.parse(JSON.stringify(this.currentRoutingRule.teams)); //clone 2d array
    this.uiHandlingTeamList[JMENUM.HandlingTeam.AVAILABLE] = this.supportTeamList;
    this.oldTeamsEmergencyListObjString = JSON.stringify(this.uiHandlingTeamList[JMENUM.JMPriority.Emergency]);
    this.oldTeamsUrgentListObjString = JSON.stringify(this.uiHandlingTeamList[JMENUM.JMPriority.Urgent]);
    this.oldTeamsNonUrgentListObjString = JSON.stringify(this.uiHandlingTeamList[JMENUM.JMPriority.NonUrgent]);
    this.oldTeamsPmListObjString = JSON.stringify(this.uiHandlingTeamList[JMENUM.HandlingTeam.PM]);

  }

  //===================================================================================================================
  // Heat map related functions

  updateScheduleTable() {
    this.selectedTeamList = [...this.uiHandlingTeamList[this.selectedListType]];
    this.assignColor();
    this.teamScheduleTable.updateSchedule();
  }

  checkUpdateCoverageAndTeams() {   
    if(this.oldServicesCoverageObjString != JSON.stringify(this.servicesCoverageList) || 
      this.oldTeamsEmergencyListObjString != JSON.stringify(this.uiHandlingTeamList[JMENUM.JMPriority.Emergency]) ||
      this.oldTeamsUrgentListObjString != JSON.stringify(this.uiHandlingTeamList[JMENUM.JMPriority.Urgent]) ||
      this.oldTeamsNonUrgentListObjString != JSON.stringify(this.uiHandlingTeamList[JMENUM.HandlingTeam.PM])
    ) {
      this.isUpdatedCoverageOrTeam = true;
    }else{
      this.isUpdatedCoverageOrTeam = false;
    }
  }
  
  // check Teams ContactSequences Empty
  isEmptyContactSequencesTeam(team, priority) {
    if (!team || !team._id) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.invalid-team-info"));
      return true;
    }

    let teamName = team.name ? team.name : "";
    let teamDetail = null;
    teamDetail = team;

    if (teamDetail.handlingParty == JMENUM.HandlingParty.PMSMC ||
      teamDetail.handlingParty == JMENUM.HandlingParty.NON_PMSMC) {

      if (!teamDetail.handlingTeam || teamDetail.handlingTeam == "") {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.please-set-handling-team") + teamName);
        return true;
      }
    }

    if (teamDetail.handlingParty == JMENUM.HandlingParty.NON_PMSMC) {
      if (!teamDetail.members || !teamDetail.members.length) {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.please-add-member-to") + teamName);
        return true;
      }
    }

    let invalidContactSequencesMsg = JMLanguage.translate("pages.routing-rules.please-set") + JMLanguage.translate("sn.priority." + priority) + " " + JMLanguage.translate("pages.routing-rules.contact-sequences-of") + teamName;
    let contactSequences = teamDetail.contactSequences;
    if (!contactSequences || !contactSequences[priority]) {
      AppDelegate.openSnackBar(invalidContactSequencesMsg);
      return true;
    }

    for(let i of Object.keys(contactSequences[priority])){
      if (i === '0') continue; // ignore 0: contractor contactSequences
      if (!contactSequences[priority][i] || !contactSequences[priority][i].length) continue;
      return false;
    }

    AppDelegate.openSnackBar(invalidContactSequencesMsg);
    return true;
    
  }

  private sortTags(tags: JMOBJ.HashTag[]) {
    tags.sort((a, b) =>
      a['displayName'].toLowerCase().localeCompare(b['displayName'].toLowerCase())
    );
  }

  checkMandatoryFields() {
    if (this.servicesCoverageList.length <= 0) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.please-add-services-coverage"));
      return false;
    }

    if (this.hasHATag) { // HA equipment tag
      if (this.uiHandlingTeamList[JMENUM.JMPriority.NonUrgent].length <= 0
        && this.uiHandlingTeamList[JMENUM.HandlingTeam.PM].length <= 0) {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.please-add-handling-team"));
        return false;
      }
    } else { // CCS equipment tag
      if (this.uiHandlingTeamList[JMENUM.JMPriority.NonUrgent].length <= 0
        && this.uiHandlingTeamList[JMENUM.JMPriority.Urgent].length <= 0
        && this.uiHandlingTeamList[JMENUM.JMPriority.Emergency].length <= 0
        && this.uiHandlingTeamList[JMENUM.HandlingTeam.PM].length <= 0) {
        AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.please-add-handling-team"));
        return false;
      }
    }

    return true;
  }

  private getCoveredChild(parent) {
    let child = [];
    let coverages = [];
    if(this.uiSelectedCriteriaTab == 1) {
      coverages = this.newRoutingRuleCoverages;
    }else{
      coverages = this.existingRoutingRuleCoverages;
    }
    for (let coverage of coverages) {
      if (coverage.source === JMENUM.RoutingRuleSource.CHILD) {
        if (coverage.client == parent.client && coverage.district == parent.district && coverage.locations.every(v => parent.locations.includes(v))) {
          child.push(coverage);
        }
      }
    }
    return child;
  }

  // ------------routing rules table------------
  onSaveRoutingRuleCriteriaForm = (event) => {
    if (this.checkMandatoryFields()) {
      let obj = this.createRoutingRuleForm.getAllValues();
            
      if (obj.originalCoverage) {    
        this.requestDeleteRoutingRuleCoverage(this.currentId, [obj.originalCoverage.ruleSequence]);
      }
      this.requestsAddRoutingRuleCoverage(obj);
      
    }
  }

  onCloseRoutingRuleCriteriaForm = (event) => {
    this.createRoutingRulePanel.toggle();
  }

  onCriteriaTabClicked = (index) => {
    if (this.uiSelectedCriteriaTab == index) {
      return;
    }
    
    this.uiSelectedCriteriaTab = index;
    switch (this.uiSelectedCriteriaTab) {
      case 0:
        document.getElementById("existingRoutingRulesButton").classList.remove("inactive-tab");
        document.getElementById("newRoutingRulesButton").classList.remove("active-tab");
        document.getElementById("existingRoutingRulesButton").classList.add("active-tab");
        document.getElementById("newRoutingRulesButton").classList.add("inactive-tab");
        if (this.hasHATag) {
          this.haTablexParam["headers"][3]['enableFilter'] = true;
        }
        break;
      case 1:
        document.getElementById("existingRoutingRulesButton").classList.remove("active-tab");
        document.getElementById("newRoutingRulesButton").classList.remove("inactive-tab");
        document.getElementById("existingRoutingRulesButton").classList.add("inactive-tab");
        document.getElementById("newRoutingRulesButton").classList.add("active-tab");
        if (this.hasHATag) {
          this.haTablexParam["headers"][3]['enableFilter'] = false;
        }
        break;
      default: break;
    }
    if (this.hasHATag) {
      this.haTablexParam.filter = {};
      this.clearHASelection();
      this.reloadHATable();
      
    } else {
      this.reloadCoverageTable();
    }
  }

  onAddRoutingRuleClicked = () => {
    if (!this.currentId) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.not-allow-update-warning"));
      return;
    }
        
    this.createRoutingRuleForm.initData();
    this.createRoutingRuleForm.setServicesCoverageList(this.servicesCoverageList);
    this.createRoutingRulePanel.toggle();
  }

  onExportExcelClick = () => {
    if (this.servicesCoverageList.length === 0) {
      return;
    }
    this.isExportExcelButtonLoading = true;

    if (this.uiSelectedCriteriaTab == 1) {
      if (this.currentId) {
        this.requestExportNewRoutingRuleSummary();
      }
    } else {
      this.requestExportRoutingRuleSummary();
    }
  }

  onEditRuleClicked = (button) => {
    let obj = button.obj;
    this.createRoutingRuleForm.setOriginalCoverage(obj);
    this.createRoutingRuleForm.setClient(obj.client);
    this.createRoutingRuleForm.setDistrict(obj.district);
    this.createRoutingRuleForm.setLocation(obj.locations);
    this.createRoutingRuleForm.setEquipmentCategory(obj.equipmentCategory);
    this.createRoutingRuleForm.setEquipmentType(obj.equipmentTypes);

    this.createRoutingRuleForm.setClientOption();
    this.createRoutingRuleForm.setDistrictOption();
    this.createRoutingRuleForm.setLocationOption();
    this.createRoutingRuleForm.setEquipmentCategoryOption();
    this.createRoutingRuleForm.setEquipmentTypeOption();

    this.createRoutingRulePanel.toggle();
  }

  onDeleteRuleClicked = (button) => {
    let obj = button.obj;
    this.requestDeleteRoutingRuleCoverage(this.currentId, [obj.ruleSequence]);
  }

  onDropdownFilterChanged = (event, i, header)  => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }
    this.currentPage = 1;
    this.requestAssignedCoverages();
  }

  onSearchFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }
    this.currentPage = 1;
    this.requestAssignedCoverages();
  }

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.requestAssignedCoverages();
  }

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.requestAssignedCoverages();
  }

  onUnassignedCoveragePageNumberClicked = (pageIndex: number) => {
    this.unassignedCoverageCurrentPage = pageIndex;
    this.requestUnassignedCoverages();
  }

  onUnassignedCoveragePageSizeClicked = (pageSize: number) => {
    this.unassignedCoverageCurrentPage = 1;
    this.unassignedCoverageCurrentPageSize = pageSize;
    this.requestUnassignedCoverages();
  }

  onUnassignedCoverageDropdownFilterChanged = (event, i, header)  => {
    if (header.filterDropdownOption.selectedValue) {
      this.unassignedCoverageTablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.unassignedCoverageTablexFilter[header.id];
    }
    this.unassignedCoverageCurrentPage = 1;
    this.requestUnassignedCoverages();
  }

  onUnassignedCoverageSearchFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.unassignedCoverageTablexFilter[key];
      } else {
        this.unassignedCoverageTablexFilter[key] = filter[key];
      }
    }
    this.unassignedCoverageCurrentPage = 1;
    this.requestUnassignedCoverages();
  }
  
  onHaFilterChanged = (event, index, header, filter) => {
    this.haCurrentPage = 1;
    this.reloadHATable();
  }

  onHaPageSizeClicked = (pageSize: number) => {
    this.haCurrentPage = 1;
    this.haCurrentPageSize = pageSize;
    this.reloadHATable();
  }

  onHaPageNumberClicked = (pageIndex: number) => {
    this.haCurrentPage = pageIndex;
    this.reloadHATable();
  }

  onHAAssetTableRowClicked = (index, row) => {
    // disable select
    this.haTablexParam['selectedRowIndex'] = -1;
    let pos = this.selectedHAAssetList.indexOf(index);
    if (pos > -1) {
      this.haTablexParam['highlightedRows'][index] = false;
      this.selectedHAAssetList.splice(pos, 1);
    } else {
      this.haTablexParam['highlightedRows'][index] = true;
      this.selectedHAAssetList.push(index);
    }
    this.haTablexParam['selectedRowCount'] = this.selectedHAAssetList.length;
  }

  onHAAssignButtonClicked = async () => {
    if (!this.hasEditionPermission) {
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    if (!this.currentId) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.not-allow-update-warning"));
      return;
    }
    if (this.selectedCoverageList.lenght == 0) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.error.select-at-least-one-coverage"));
      return;
    }
    const request = new JM.JMRequestRoutingRulesAssignHARuleCoverages();
    request.ruleId = this.currentId;
    request.assetNumbers = [];
    for (let i of this.selectedHAAssetList) {
      request.assetNumbers.push(this.unassignedHAAssetList[i].assetNumber);
    }

    try {
      this.isAssigningAsset = true;
      const response: JM.JMResponseRoutingRulesAssignHARuleCoverages = await AppDelegate.sendJMRequest(request);
      this.requestGetUnassignedHACoverages();
      this.isAssigningAsset = false;
    } catch(e) {
      AppDelegate.openErrorBar();
    }
  }

  onHAUnssignButtonClicked = async () => {
    if (!this.hasEditionPermission) {
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    if (!this.currentId) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.not-allow-update-warning"));
      return;
    }
    if (this.selectedCoverageList.lenght == 0) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.error.select-at-least-one-coverage"));
      return;
    }
    const request = new JM.JMRequestRoutingRulesUnassignHARuleCoverages();
    request.ruleId = this.currentId;
    request.assetNumbers = [];
    for (let i of this.selectedHAAssetList) {
      request.assetNumbers.push(this.assignedHAAssetList[i].assetNumber);
    }
    
    try {
      this.isUnassigningAsset = true;
      const response: JM.JMResponseRoutingRulesUnassignHARuleCoverages = await AppDelegate.sendJMRequest(request);
      this.requestGetAssignedHACoverages();
      this.isUnassigningAsset = false;
    } catch(e) {
      AppDelegate.openErrorBar();
    }
  }

  onCloseRoutingRuleClicked = () => {
    AppDelegate.navigate(["/setting/routing-rules-list"], {state: {pageNumber: this.parentPageNumber, pageSize: this.parentPageSize, scrollPosition: this.parentScrollPosition}}); 
  }

  onSaveRoutingRuleClicked = () => {
    if (this.currentId) {
      this.requestUpdateRoutingRule();
    } else{
      this.requestCreateRoutingRule();
    }
  }

  onSortOrderChanged = (header, sortOrder) => {
    if (this.tablexParam['sortBy'] == header.id && this.tablexParam['sortOrder'] == sortOrder) {
      this.tablexParam['sortBy'] = null;
      this.tablexParam['sortOrder'] = -1;

    } else if (this.tablexParam['sortBy'] != header.id) {
      this.tablexParam['sortBy'] = header.id;
      this.tablexParam['sortOrder'] = 1;
      
    } else {
      this.tablexParam['sortOrder'] = sortOrder;
    }

    this.reloadCoverageTable();
  }

  onUnassignedCoverageRowClicked = (index, row) => {
    // disable select
    this.unassignedCoverageTablexParam['selectedRowIndex'] = -1;
    let pos = this.selectedNewCoverageList.indexOf(index);
    if (pos > -1) {
      this.unassignedCoverageTablexParam['highlightedRows'][index] = false;
      this.selectedNewCoverageList.splice(pos, 1);
    } else {
      this.unassignedCoverageTablexParam['highlightedRows'][index] = true;
      this.selectedNewCoverageList.push(index);
    }
    this.unassignedCoverageTablexParam['selectedRowCount'] = this.selectedNewCoverageList.length;
  }


  onRowClicked = (index, row) => {
    // disable select
    this.tablexParam['selectedRowIndex'] = -1;
    let pos = this.selectedCoverageList.indexOf(index);
    if (pos > -1) {
      this.tablexParam['highlightedRows'][index] = false;
      this.selectedCoverageList.splice(pos, 1);
    } else {
      this.tablexParam['highlightedRows'][index] = true;
      this.selectedCoverageList.push(index);
    }
    this.tablexParam['selectedRowCount'] = this.selectedCoverageList.length;
  }

  onUnassignCoverageClicked = () => {
    if (!this.hasEditionPermission) {
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    if (!this.currentId) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.not-allow-update-warning"));
      return;
    }
    if (this.selectedCoverageList.lenght == 0) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.error.select-at-least-one-coverage"));
      return;
    }
    let coverageIdList = [];
    for (let i of this.selectedCoverageList) {
      coverageIdList.push(this.existingRoutingRuleCoverages[i].ruleSequence);
    }
    try {
      this.isUnassigningCoverages = true;
      this.requestDeleteRoutingRuleCoverage(this.currentId, coverageIdList);
      this.isUnassigningCoverages = false;
    } catch(e) {
      AppDelegate.openErrorBar();
    }
  }
  
  onAssignCoverageClicked = async () => {
    if (!this.hasEditionPermission) {
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }
    if (!this.currentId) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.not-allow-update-warning"));
      return;
    }
    if (this.selectedCoverageList.lenght == 0) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.routing-rules.error.select-at-least-one-coverage"));
      return;
    }
    const request = new JM.JMRequestRoutingRulesAssignRuleCoverages();
    request.ruleId = this.currentId;
    request.coverages = [];
    for (let i of this.selectedNewCoverageList) {
      let c = this.newRoutingRuleCoverages[i];
      if (c.equipmentCategory) {
        request.coverages.push({
          client: c.client,
          district: c.district,
          locations: [c.location],
          equipmentCategory: c.equipmentCategory,
          equipmentTypes: [c.equipmentType],
          source: JMENUM.RoutingRuleSource.SYSTEM,
        });
      } else { // unclassified category tab
        let catCodeList = JM.JMConnector.getEquipmentCategoryCodeFromType(c.equipmentType);
        for (let catCode of catCodeList) {
          request.coverages.push({
            client: c.client,
            district: c.district,
            locations: [c.location],
            equipmentCategory: catCode,
            equipmentTypes: [c.equipmentType],
            source: JMENUM.RoutingRuleSource.SYSTEM,
          });
        }
      }
    }
    try {
      this.isAssigningCoverages = true;
      const response: JM.JMResponseRoutingRulesAssignRuleCoverages = await AppDelegate.sendJMRequest(request);
      this.isAssigningCoverages = false;
      if (!response || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
      }

      this.requestUnassignedCoverages();
    } catch(e) {
      AppDelegate.openErrorBar();
    }
  }
  
  private renderUnassignTable() {
    let index = (this.unassignedCoverageCurrentPage - 1) * this.unassignedCoverageCurrentPageSize;
    this.unassignedCoverageTablexParam['content'] = [];
    for (let coverage of this.newRoutingRuleCoverages) {
      index = index + 1;

      let district = coverage.district ? JMLanguage.translate('district.' + coverage.district) : "";
      let equipTypeCode = coverage.equipmentType;
      let equipType = JM.JMConnector.getEquipmentType(equipTypeCode);
      let equipCatList = null;
      if (coverage.equipmentCategory) {
        let cat = Session.equipmentCategoryDict[coverage.equipmentCategory];
        equipCatList = [
          cat.code + ' - ' + cat.description[Session.selectedLanguage]
        ];

      } else { // if null, use cat type mapping
        let catCodeList = JM.JMConnector.getEquipmentCategoryCodeFromType(equipTypeCode);
        equipCatList = [];
        for (let catCode of catCodeList) {
          let cat = Session.equipmentCategoryDict[catCode];
          equipCatList.push(cat.code + ' - ' + cat.description[Session.selectedLanguage]);
        }
      }
      let locationDescription = null;
      //TODO super dislike, have self cache instead
      let location = this.createRoutingRuleForm.getLocation(coverage.district, coverage.location);
      if(location && location.label) {
        locationDescription = location.label;
      }else{
        locationDescription = coverage.location;
      }

      this.unassignedCoverageTablexParam['content'].push([
        index,
        coverage.client,
        district,
        locationDescription,
        equipCatList,
        equipType.code + ' - ' + equipType.description[Session.selectedLanguage],
      ]);
    }
  }

  private renderTable() {
    
    let index = (this.currentPage - 1) * this.currentPageSize;
    this.tablexParam['content'] = this.existingRoutingRuleCoverages.map(obj => {
      index = index + 1;

      let district = obj.district ? JMLanguage.translate('district.' + obj.district) : "";

      let category = "";
      let code = obj.equipmentCategory;

      if(code) {
        if(code == COVERED) {
          category = JMLanguage.translate('pages.routing-rules.slider-panel.covered-equipment-types');
        }else if(code == ALL) {
          category = JMLanguage.translate('pages.routing-rules.slider-panel.all-categories-types');
        }else{
          let catTmp = JM.JMConnector.getEquipmentCategory(code);
          category = catTmp.code; // + ' - ' + catTmp.description[Session.selectedLanguage];
        }
      }

      let typeArray = [];
      if(code && code == COVERED) {
        let tmp = this.getCoveredChild(obj);
        for(let coverage of tmp) {
          let types = coverage.equipmentTypes;
          for (let code of types) {
            if (!code) continue;
            let typeTmp = JM.JMConnector.getEquipmentType(code);
            let type = typeTmp.code + ' - ' + typeTmp.description[Session.selectedLanguage];
            typeArray.push(type);
          }
        }

      }else if(code && code == ALL) {
        typeArray.push(JMLanguage.translate('pages.routing-rules.slider-panel.all-types'));

      }else if(obj.equipmentTypes) {
        if(category == "" && obj.equipmentTypes.length > 0) {
          let array = JM.JMConnector.getEquipmentCategoryCodeFromType(obj.equipmentTypes[0]);
          if(array.length > 0) {
            let code = array[0];
            let catTmp = JM.JMConnector.getEquipmentCategory(code);
            category = catTmp.code; // + ' - ' + catTmp.description[Session.selectedLanguage];
          }
        }
        
        for(let i = 0;i < obj.equipmentTypes.length; i++) {
          let code = obj.equipmentTypes[i];
          let typeTmp = JM.JMConnector.getEquipmentType(code);
          let type = typeTmp.code + ' - ' + typeTmp.description[Session.selectedLanguage];
          typeArray.push(type);
        }
      }

      let locationArray = [];
      if (obj.locations) {
        for (let i = 0; i < obj.locations.length; i++) {
          let code = obj.locations[i];
          let locationDescription = "";
          // get description cache from current routing rule
          if (this.currentRoutingRule){
            if (this.currentRoutingRule.locationDescription && this.currentRoutingRule.locationDescription[code]) {
              locationDescription = this.currentRoutingRule.locationDescription[code][Session.selectedLanguage];
              if(locationDescription != "") {
                locationDescription += " (" + code + ")";
              }
            }
          }
          if(locationDescription == "") {
            // get description cache from criteria form
            let location = this.createRoutingRuleForm.getLocation(obj.district, code);
            if(location && location.label) {
              locationDescription = location.label;
            }else{
              locationDescription = code;
            }
          }
          locationArray.push(locationDescription);
        }
      }

      let row = [
        (obj.source !== JMENUM.RoutingRuleSource.SYSTEM ? "*" : "") + index,
        obj.client,
        district,
        locationArray,
        category,
        typeArray
      ];

      let buttonArray = [];
      if (this.hasEditionPermission) {
        buttonArray.push({ "obj": obj, "name": "", "class": "glyph brand-red", "icon": "fas fa-times", "onClicked": this.onDeleteRuleClicked });
        if (obj.source == JMENUM.RoutingRuleSource.MANUEL ||
            obj.source == JMENUM.RoutingRuleSource.COVERED) {
          buttonArray.splice(0,0, 
            { "obj": obj, "name": "", "class": "glyph brand-amber", "icon": "fas fa-edit", "onClicked": this.onEditRuleClicked },
          );
        }        
      }
      row.push(buttonArray);
      return row;
    });
  }
  // ------------routing rules table end ------------

  // ------------HA assets table ------------
  private initHaAssetsTable() {
    this.haTablexParam = {
      content: [],
      highlightedRows: [],
      selectedRowCount: 0,
      totalRowCount: 0 ,
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableSelectedRowCount: (this.hasEditionPermission),
      enableSelectedAll: (this.hasEditionPermission),
      enableSort: false,
      tableRow: "row",
      pageSizeOptions: this.haPageSizeOptions,
      currentPageSize: this.haCurrentPageSize,
      currentPage: this.haCurrentPage,
      pageCount: 1,
      filter: {},
      onPageNumberClicked: this.onHaPageNumberClicked,
      onPageSizeClicked: this.onHaPageSizeClicked,
      onFilterChanged: this.onHaFilterChanged,
      onRowClicked: (this.hasEditionPermission)? this.onHAAssetTableRowClicked: ()=> {},
      filterDebounceTime: Constants.DEBOUNCE_TIME,
    };

    this.haTablexParam["headers"] = [
      {
        id: 'assetNumber',
        name: "pages.ha-equipment-list.table-column.ha-asset-number",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'ccsEquipmentDescription',
        name: "pages.ha-equipment-list.table-column.ccsEquipmentDescription",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'haEquipmentDescription',
        name: "pages.ha-equipment-list.table-column.description",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'haCluster',
        name: "pages.ha-equipment-list.table-column.cluster",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'ccsEquipmentNumber',
        name: "pages.ha-equipment-list.table-column.ccs-number",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
      {
        id: 'functionalLocation',
        name: "pages.ha-equipment-list.table-column.functional-location",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col ",
      },
    ];
  }
  
  reloadHATable() {
    if (this.uiSelectedCriteriaTab == 0) {
      this.requestGetAssignedHACoverages();
    } else {
      this.requestGetUnassignedHACoverages();
    }
  }
  // ------------HA assets table end ------------
}