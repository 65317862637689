import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';

@Component({
  selector: 'app-office-hours-table',
  templateUrl: './office-hours-table.component.html',
  styleUrls: ['./office-hours-table.component.scss']
})
export class OfficeHoursTableComponent extends BasePage implements OnInit {

  @Input() officeHoursParam: {
    componentId: '',
    isLoadingTable: false,
    isEditMode: false,
    hasEditionPermission: true,
    onEditButtonClicked: () => {},
    // table content
    headers: [],
    content: []
  };

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  checkValidation(event: any){
    // let value = event.target.value;
    // let errorMessage = "";
    // errorMessage  = isTimeStringValidator("Invalid Time")(value);


    // console.log(event.target.attributes.id)

    // let button = {
    //   name: this.translate("global.ok"),
    //   handler: () => {
    //     let inputField = document.getElementById(event.attributes.id);
    //     inputField.focus();
    //   }
    // };
    // if (errorMessage) {
    //   this.showPopUpAlert("", errorMessage, [button]);
    // }
  }

  inputTimeString(event: any) {
    // console.log(event);
    // console.log(event.keyCode);
    // event.target.value = event.target.value.trim().replace(/[^\d]/g, '');
    // if (event.target.value.trim().length == 2 && event.keyCode != 8) {
    //   event.target.value = event.target.value.trim() + ':';
    // }
  }

}
