import { Component, OnInit } from '@angular/core';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/entity/enum/tablexColumnType';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { TablexParam } from 'src/app/pages/enquiry-list/tablexConfig';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { Router } from '@angular/router';
import { AuthorizationService } from '@services/authorization.service';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-sn-batch-history',
  templateUrl: './sn-batch-history.component.html',
  styleUrls: ['./sn-batch-history.component.scss']
})
export class SnBatchHistoryComponent implements OnInit {
  tablexParam = {};
  startPage : number = 1;
  pageSize : number = 10;
  pageSizeOptions : number[] = [10 , 25 , 100];
  pageTitle = JMLanguage.translate('component.sn-batch-complete.main.title');

  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  ngOnInit() {
    if (!this.authorizationService.hasPermissions([JMENUM.Permission.JOBCARD_COMPLETE, JMENUM.Permission.JOBCARD_COMPLETE_ALL] , false)) {
      this.router.navigate(['/']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }

    this.tablexParam['isLoadingTable'] = true;
    this.tablexParam['tableRow'] = 'd-flex align-items-stretch justify-content-center';
    this.tablexParam['onPageNumberClicked'] = this.onPageNumberClicked;
    this.tablexParam['onPageSizeClicked'] = this.onPageSizeClicked;
    this.tablexParam['enablePagination'] = true;
    this.tablexParam['enableSetPageSize'] = true;
    this.tablexParam['pageSizeOptions'] = this.pageSizeOptions;
    this.tablexParam['headers'] = [
      {
        name: JMLanguage.translate('pages.batch-upload-history.table.header.upload-date'),
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-5 d-flex align-items-center justify-content-center',
      },
      {
        name: JMLanguage.translate('pages.batch-upload-history.table.header.result'),
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-5 d-flex align-items-center justify-content-center',
      },
      {
        name: JMLanguage.translate('pages.batch-upload-history.table.header.download'),
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-2 d-flex align-items-center justify-content-center',
      },
    ];
    this.requestBatchUploadHistory( this.startPage , this.pageSize );
  }

  onPageNumberClicked = (pageNumber: number) => {
    this.requestBatchUploadHistory( pageNumber , null );
  };

  onPageSizeClicked = (pageSize: number) => {
    this.requestBatchUploadHistory( this.startPage , pageSize );
  };

  requestBatchUploadHistory = async ( requestPage: number, requestPageSize: number ) => {
    requestPage = requestPage ? requestPage : this.tablexParam['currentPage'];
    requestPageSize = requestPageSize ? requestPageSize : this.tablexParam['currentPageSize'];
  
    this.tablexParam['isLoadingTable'] = true;

    let request: JM.JMRequestJobCardsGetBatchCompleteResultList = new JM.JMRequestJobCardsGetBatchCompleteResultList();
    request.pageNumber = requestPage;
    request.pageSize = requestPageSize;
    request.parameters = ['createdAt','status','successCount','failedCount','reportFileId'];

    const response: JM.JMResponseJobCardsGetBatchCompleteResultList = await AppDelegate.sendJMRequest(request);

    this.tablexParam['isLoadingTable'] = false;

    if ( !response || !response.code || response.code != 200 || !response.payload ) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records && response.payload.records.length > 0) {
      this.tablexParam['content'] = this.parseResponseToTableContent( response.payload.records );
      this.tablexParam['currentPageSize'] = request.pageSize;
      this.tablexParam['pageCount'] = Math.ceil( response.payload.totalCount / this.tablexParam['currentPageSize'] );
      this.tablexParam['currentPage'] = request.pageNumber;
    } else {
      this.tablexParam['content'] = [];
      this.tablexParam['pageCount'] = 0;
      this.tablexParam['currentPage'] = 0;
    }
  };

  parseResponseToTableContent = (records: JMOBJ.BatchCompleteResult[]) => {
    return records.map( el => {
      let downloadLink = environment.CCEP_FILE_HOST + '/files/' + el.reportFileId;
      let statusContent = this.parseStatusToContent( el.status , el.successCount , el.failedCount );
      return [
        el.createdAt ? moment( el.createdAt ).format(Constants.DATETIME_FORMAT_2) : '-',
        statusContent,
        `
          <a class='td-download fa-stack fa-1x brand-blue ${el.reportFileId ? '' : 'disabled'}' href='${downloadLink}'>
            <i class='fa fa-square fa-stack-2x'></i>
            <i class='fa fa-download fa-stack-1x fa-inverse' aria-hidden='true'></i>
          </a>
        `,
      ];
    });
  };

  parseStatusToContent = ( status : JMENUM.BatchCompleteResultStatus , successCount : number , failedCount : number) => {
    switch (status) {
      case JMENUM.BatchCompleteResultStatus.PENDING:
        return JMLanguage.translate('pages.batch-upload-history.table.body.status.pending');
      case JMENUM.BatchCompleteResultStatus.FAILED:
        return JMENUM.BatchCompleteResultStatus.FAILED.toUpperCase();
      case JMENUM.BatchCompleteResultStatus.FINISHED:
        return `
        <div class='td-status'>
          <p>
            <span>${JMLanguage.translate('pages.batch-upload-history.table.body.result.success')}</span>
            <span>:</span>
            <span>${ typeof successCount === 'number' ? successCount : '-' }</span>
          </p>
          <p>
            <span>${JMLanguage.translate('pages.batch-upload-history.table.body.result.fail')}</span>
            <span>:</span>
            <span>${ typeof failedCount === 'number' ? failedCount : '-' }</span>
          </p>
        </div>
        `;
      default:
        return '-';
    }
  }

  private requestGetFile = ( id : string ) => {
    if ( id ) {
      let request = new JM.JMRequestFilesGetFile();
      request.attachmentId = id;

      JM.JMConnector.sendFilesGetFile(request, (error: JM.JMNetworkError, response: JM.JMResponseFilesGetFile) => {
        if (error) {
          return;
        }
        saveAs(response.payload, id);
      });
    }
  }

  toPmJobTimesheetHistory() {
    AppDelegate.navigate(['/pm/job/timesheet/upload-history']);
  }
  toBatchSumissionHistory() {
    AppDelegate.navigate(['/sn/batch-report-submission/upload-history']);
  }
}
