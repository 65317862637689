import {  TablexColumnHorizontalAlign,  TablexColumnType,  TablexColumnVerticalAlign,  TablexColumnFilterType,  TablexColumnFilterOption,} from "src/app/entity/enum/tablexColumnType";
import { Component, OnInit, ViewChild, Injector } from "@angular/core";
import { BasePage } from "src/app/ui/model/base/base";
import { Permission } from "@enum/permission";
import { Constants } from "src/constants";
import { JMLanguage } from "src/lib/JMLanguage/JMLanguage";
import { AppDelegate } from "src/app/AppDelegate";
import { JM, JMENUM, JMOBJ } from "@ccep/CCEPConnector-ts";
import { Session } from 'src/app/services/session';

@Component({
  selector: "hashtag-group",
  templateUrl: "./hashtag-group.component.html",
  styleUrls: ["./hashtag-group.component.scss"],
})
export class HashtagGroupComponent extends BasePage implements OnInit {
  // add new HashtagGroup
  @ViewChild("add_hashtag_group_panel", { static: true })
  openAddHashtagGroupPanel;
  @ViewChild("addHashtagGroupForm", { static: true }) addHashtagGroupForm;

  pageTitle: string;

  addHashtagGroupParameters: {
    onSaveButtonClicked: Function;
    onDeleteButtonClicked: Function;
    mode: string;
  };
  // add new HashtagGroup
  hashTagGroupArray: JMOBJ.HashTagGroup[] = [];
  // Table
  name?: string;
  status?: string;
  type?: string;
  description?: string;
  workCentres?: string[];
  isNameOnDisplay?: boolean;
  tablexParam: {} = {};
  allColHeaders: any = [];
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  uiTotalEquipmentCount = 0;

  uiIsShowExpired: boolean = false;

  // HashtagGroup Status Dropdown
  hashtagGroupIsNameOnDisplayList = [
    {
      value: true,
      label: JMLanguage.translate("hashtagGroup.IsNameOnDisplay.yes"),
    },
    {
      value: false,
      label: JMLanguage.translate("hashtagGroup.IsNameOnDisplay.no"),
    },
  ];
  hashtagGroupStatusList = [
    {
      value: JMENUM.HashtagGroupStatus.ACTIVE,
      label: JMLanguage.translate("hashtagGroup.status.active"),
    },
    {
      value: JMENUM.HashtagGroupStatus.INACTIVE,
      label: JMLanguage.translate("hashtagGroup.status.inactive"),
    },
  ];
  hashtagGroupTypeList = [
    {
      value: JMENUM.HashtagGroupType.WORK_CENTRE,
      label: JMLanguage.translate("hashtagGroup.type.workcentre"),
    },
    {
      value: JMENUM.HashtagGroupType.CUSTOM,
      label: JMLanguage.translate("hashtagGroup.type.custom"),
    },
  ];
  // workCentre Dropdown
  headerWorkCentreDropDown: {
    value: string,
    label: string,
  }[] = null;

  hashtagGroupIsNameOnDisplayOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  hashtagGroupStatusFilterOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  workCenterOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  hashtagGroupTypeOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  searchWorkCentreKeywords: string = undefined;
  searchStatusKeywords: string = undefined;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    this.pageTitle = JMLanguage.translate("pages.hashtag-group.page-title");

    this.addHashtagGroupParameters = {
      onDeleteButtonClicked: this.onDeleteButtonClicked,
      onSaveButtonClicked: this.onAddHashtagGroupSaveButtonClicked,
      mode: "add",
    };

    this.checkViewPermission(Permission.wcmUpdate);
    this.initAllTableHeader();
    this.initTablex();
    this.requestHashtagGroup();
  }

  //----------------------------------------------------
  // update view function
  renderTable() {
    //clear data
    this.tablexParam["content"] = [];
    let tagGroup: JMOBJ.HashTagGroup;
    // Data Mapping
    for (tagGroup of this.hashTagGroupArray) {
      
      let buttonList = [];
      if (tagGroup.type == JMENUM.HashtagGroupType.CUSTOM) {
        buttonList.push({
          id: "add",
          name: "",
          class: "glyph brand-amber",
          icon: "fas fa-edit",
          onClicked: this.onEditHashtagGroupButtonClicked,
          object: tagGroup,
        });
      }

      this.tablexParam["content"].push([
        tagGroup.name,
        tagGroup.description,
        JMLanguage.translate(`hashtagGroup.type.${tagGroup.type}`),
        JMLanguage.translate(`hashtagGroup.status.${tagGroup.status}`),
        (tagGroup.isNameOnDisplay)?JMLanguage.translate("global.yes"):JMLanguage.translate("global.no"),
        tagGroup.workCentres.map((wc) => wc.workCentreCode),
        buttonList,
      ]);
    }
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      tableRow: "row",
      tableClass: "sn-list-table",
      tableWrapperClass: "table-min-width",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onFilterChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      content: [],
    };
  }

  initAllTableHeader() {
    this.hashtagGroupIsNameOnDisplayOption.items =
      this.hashtagGroupIsNameOnDisplayList;
    this.hashtagGroupIsNameOnDisplayOption.bindLabel = "label";
    this.hashtagGroupIsNameOnDisplayOption.bindValue = "value";
    this.hashtagGroupIsNameOnDisplayOption.change =
      this.onHashtagGroupIsNameOnDisplayFilterChanged;

    // HashtagGroup Status Options
    this.hashtagGroupStatusFilterOption.items = this.hashtagGroupStatusList;
    this.hashtagGroupStatusFilterOption.bindLabel = "label";
    this.hashtagGroupStatusFilterOption.bindValue = "value";
    this.hashtagGroupStatusFilterOption.onSearch =
      this.onSearchWorkCentreOptions;
    this.hashtagGroupStatusFilterOption.searchFn = this.onFilterStatusOptions;
    this.hashtagGroupStatusFilterOption.change =
      this.onHashtagGroupStatusFilterChanged;
    // WorkCentre Options
    this.headerWorkCentreDropDown = Object.keys(Session.workCentreDict).map(wc => { return {
      value: wc,
      label: wc,
    }});
    this.workCenterOption.items = this.headerWorkCentreDropDown;
    this.workCenterOption.bindLabel = "label";
    this.workCenterOption.bindValue = "value";
    this.workCenterOption.change = this.onHashtagGroupWorkCentresFilterChanged;
    this.workCenterOption.onSearch = this.onSearchWorkCentreOptions;
    this.workCenterOption.searchFn = this.onFilterWorkCentreOptions;
    this.workCenterOption.multiple = true;

    this.hashtagGroupTypeOption.items = this.hashtagGroupTypeList;
    this.hashtagGroupTypeOption.bindLabel = "label";
    this.hashtagGroupTypeOption.bindValue = "value";
    this.hashtagGroupTypeOption.change = this.onHashtagGroupTypeFilterChanged;

    
    
    this.allColHeaders = [
      {
        id: "name",
        name: "pages.hashtag-group.table-column-hashtag-group-name",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 p-1 p-xl-3",
      },
      {
        id: "description",
        name: "pages.hashtag-group.table-column-hashtag-group-description",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 p-1 p-xl-3",
      },
      {
        id: "type",
        name: "pages.hashtag-group.table-column-hashtag-group-type",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.hashtagGroupTypeOption,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 p-1 p-xl-3",
      },
      {
        id: "status",
        name: "pages.hashtag-group.table-column-hashtag-group-status",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.hashtagGroupStatusFilterOption,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 p-1 p-xl-3",
      },
      {
        id: "isNameOnDisplay",
        name: "pages.hashtag-group.table-column-hashtag-group-isNameOnDisplay",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.hashtagGroupIsNameOnDisplayOption,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 p-1 p-xl-3",
      },
      {
        id: "workCentres",
        name: "pages.hashtag-group.table-column-hashtag-group-workCentres",
        enableFilter: true,
        type: TablexColumnType.MultiLine,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.workCenterOption,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 p-1 p-xl-3",
      },
      {
        id: "rowAction",
        name: "pages.contract-dummy.table-column-action",
        enableFilter: false,
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col col-1 p-1 p-xl-3",
      },
    ];
  }

  // open panel
  onAddHashtagGroupBtnClicked = () => {
    this.addHashtagGroupForm.clearView();
    this.addHashtagGroupForm.setMode("add");
    this.openAddHashtagGroupPanel.toggle();
  };

  onEditHashtagGroupButtonClicked = (row) => {
    this.addHashtagGroupForm.clearView();
    let hashtagGroup: JMOBJ.HashTagGroup = row.object;
    this.addHashtagGroupForm.setMode("edit");
    this.addHashtagGroupForm.setTagGroup(hashtagGroup);
    this.openAddHashtagGroupPanel.toggle();
  };

  // callback close the panel
  onDeleteButtonClicked = () => {
    this.requestHashtagGroup();
    this.openAddHashtagGroupPanel.toggle();
  };
  onAddHashtagGroupSaveButtonClicked = () => {
    this.requestHashtagGroup();
    this.openAddHashtagGroupPanel.toggle();
  };

  onHashtagGroupIsNameOnDisplayFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue !== null) {
      this.isNameOnDisplay = header.filterDropdownOption.selectedValue;
    } else {
      delete this.isNameOnDisplay;
    }
    this.requestHashtagGroup();
  };

  onHashtagGroupStatusFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.status = header.filterDropdownOption.selectedValue;
    } else {
      delete this.status;
    }
    this.requestHashtagGroup();
  };

  onHashtagGroupWorkCentresFilterChanged = (event, i, header) => {
    if (
      header.filterDropdownOption.selectedValue &&
      header.filterDropdownOption.selectedValue.length > 0
    ) {
      this.workCentres = header.filterDropdownOption.selectedValue;
    } else {
      delete this.workCentres;
    }
    this.requestHashtagGroup();
  };

  onHashtagGroupTypeFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.type = header.filterDropdownOption.selectedValue;
    } else {
      delete this.type;
    }
    this.requestHashtagGroup();
  };

  onFilterChanged = (event, idx, header, filter) => {
    if (filter) {
      for (let k in filter) {
        if (k === "description") {
          this.description = filter[k];

          if (!filter[k]) {
            delete this.description;
          }
        }

        if (k === "name") {
          this.name = filter[k];

          if (!filter[k]) {
            delete this.name;
          }
        }
      }
    }
    this.currentPage = 1;
    this.requestHashtagGroup();
  };

  onSearchStatusOptions = (event) => {
    this.searchStatusKeywords = event.trem;
  };

  onFilterStatusOptions = (term: string, item: any) => {
    return item.label.toLowerCase().includes(term.toLocaleLowerCase());
  };

  onSearchWorkCentreOptions = (event) => {
    this.searchWorkCentreKeywords = event.term;
  };

  onFilterWorkCentreOptions = (term: string, item: any) => {
    return item.label.toLowerCase().includes(term.toLocaleLowerCase());
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.requestHashtagGroup();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.requestHashtagGroup();
  };

  onLanguageChanged() {}

  //API CALL------------------------------------------------------------------------------------
  requestHashtagGroup = () => {
    this.tablexParam["isLoadingTable"] = true;

    let request = new JM.JMRequestHashtagGroupSummary();
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    request.name = this.name;
    request.status = this.status;
    request.type = this.type;
    request.description = this.description;

    request.sortBy = "name"; 
    request.sortOrder = JMENUM.SortOrder.ASC; 
    if (this.workCentres && this.workCentres.length >0) {
      request.workCentreList = this.workCentres;
    }
    if (this.isNameOnDisplay !== undefined) {
      request.isNameOnDisplay = this.isNameOnDisplay;
    }

    JM.JMConnector.sendHashtagGroupSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseHashtagGroupSummary) => {
      this.tablexParam["isLoadingTable"] = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (
        !response ||
        !response.code ||
        response.code != 200 ||
        !response.payload
      ) {
        this.openErrorBar(response);
        return;
      }

      this.hashTagGroupArray = response.payload.records;
      this.tablexParam["pageCount"] = Math.ceil(
        response.payload.totalCount / this.currentPageSize
      );
      this.renderTable();
    });
  };
  //--------------------------------------------------------------------------------------------
}
