<div class="font-size-xl m-0 mb-2 px-2">
 	{{ "component.client-information.title" | translate}}
</div>

<hr class="underline" />

<div class="row">

  <!-- Contact Person -->
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <label for="">
      <span *ngIf="!isJobNatureStandalone" class="red">*</span>
      {{ "component.client-information.contact-person" | translate }}
    </label>
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4">
    <app-display-text-field *ngIf="!isPageModeCreate"
      id="client-information-contact-person"
      [emptyHandleDisplay]="isJobNatureStandalone ? emptyHandleNa : null"
      [textField]="sn?.contactPerson"
    ></app-display-text-field>

    <input 
      *ngIf="isPageModeCreate" 
      id="form-item-client-information-contact-person"
      class="form-control" 
      type="text"
      maxlength="35"
      [disabled]="isJobNatureStandalone"
      [class.input-error]="errorFields.contactPerson"
      [(ngModel)]="sn.contactPerson"
      required
    />
  </div>

  <!-- Contact Number -->
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <label for="">
      <span *ngIf="!isJobNatureStandalone" class="red">*</span>
      {{ "component.client-information.contact-number" | translate }}
    </label>
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4 contact-person">
    <app-display-text-field *ngIf="!isPageModeCreate"
      id="client-information-contact-number"
      [emptyHandleDisplay]="isJobNatureStandalone ? emptyHandleNa : null"
      [textField]="sn?.contactNumber"
    ></app-display-text-field>
    <ng-container *ngIf="isPageModeCreate">
      <div class="input-group flex-nowrap" [class.input-error]="errorFields.contactNumber">
        <!-- <mat-form-field class="form-control contact-number-input-text"> -->
        <input 
          id="form-item-client-information-contact-number_0" 
          class="form-control contact-number-input-text"
          type="text" 
          maxlength="8"
          (blur)="inputNumberWithIndex($event,0);"
          (keyup)="inputNumberWithIndex($event,0);"
          [disabled]="isJobNatureStandalone"
          [(ngModel)]="sn.contactNumber[0]"
          required
        />
        <!-- 
          matInput 
          [matAutocomplete]="auto" 
          [matAutocompleteDisabled]="disableEditTeam" 
          [formControl]="formControl" 
        -->
        <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onContactNumberChanged($event.option.value)" >
            <mat-option *ngFor="let option of contactNumberFilteredOptions | async" [value]="option">{{option}}</mat-option>
          </mat-autocomplete>
        </mat-form-field> -->
        <button 
          id="form-item-client-information-contact-number-add-btn_1" 
          class="btn btn-outline-primary btn-contact-number"
          type="button"
          [disabled]="isJobNatureStandalone"
          (click)="onAddContactNumberButtonClicked()">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div *ngIf="sn.contactNumber.length > 1" class="input-group flex-nowrap">
        <input 
          id="form-item-client-information-contact-number_1" 
          class="form-control contact-number-input-text"
          type="text" 
          maxlength="8"
          [disabled]="isJobNatureStandalone"
          (blur)="inputNumberWithIndex($event,1);"
          (keyup)="inputNumberWithIndex($event,1);"
          [(ngModel)]="sn.contactNumber[1]"
          required
        />
        <div class="input-group-append">
          <button class="btn btn-outline-primary btn-contact-number"
            id="form-item-client-information-contact-number-remove-btn_1" 
            type="button"
            [disabled]="isJobNatureStandalone"
            (click)="onHideContactNumberButtonClicked(1)">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div *ngIf="sn.contactNumber.length > 2" class="input-group flex-nowrap">
        <input 
          id="form-item-client-information-contact-number_2" 
          class="form-control contact-number-input-text"
          type="text" 
          maxlength="8"
          (blur)="inputNumberWithIndex($event,2);"
          (keyup)="inputNumberWithIndex($event,2);"
          [disabled]="isJobNatureStandalone"
          [(ngModel)]="sn.contactNumber[2]"
          required
        />
        <div class="input-group-append">
          <button class="btn btn-outline-primary btn-contact-number"
            id="form-item-client-information-contact-number-remove-btn_2" 
            type="button"
            [disabled]="isJobNatureStandalone"
            (click)="onHideContactNumberButtonClicked(2)">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Client -->
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
    <label for="">
      <span class="red">*</span>
      {{ "component.client-information.client" | translate }}
    </label>
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4">
    <app-display-text-field *ngIf="!isPageModeCreate" id="client-information-client" [textField]="jobCard?.client" [attr.data-content]="jobCard?.client"></app-display-text-field>
    <ng-select 
      *ngIf="isPageModeCreate" 
      id="form-item-client-information-client" 
      bindValue="value" 
      [items]="clientOptions.items" 
      [class.input-error]="errorFields.client"
      [disabled]="!!sn.eamData.assetNumber" 
      (click)="onClientSelectClick()"
      (clear)="onClientSelectClear()"
      (search)="onClientSelectSearchDebounce($event)"
      (scrollToEnd)="onClientSelectScrollToEndDebounce()"
      [(ngModel)]="sn.client"
    >
    </ng-select>
  </div>

  <!-- Email Address -->
  <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{ "component.client-information.email-address" | translate }}
  </div>
  <div class="col-4 text-left font-size-s m-0 mb-4">
    <app-display-text-field *ngIf="!isPageModeCreate"
      id="client-information-email-address"
      [emptyHandleDisplay]="isJobNatureStandalone ? emptyHandleNa : null"
      [textField]="sn?.email"
    ></app-display-text-field>
    <input 
      *ngIf="isPageModeCreate"
      id="form-item-client-information-email"  
      class="form-control" 
      type="text" 
      maxlength="50"
      [disabled]="isJobNatureStandalone"
      [class.input-error]="errorFields.email"
      [(ngModel)]="sn.email" 
    />
  </div>

  <!-- Location -->
  <ng-container *ngIf="isPageModeCreate">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      <label for="">
        <span *ngIf="!isJobNatureStandalone" class="red">*</span>
        {{ "component.client-information.location" | translate }}
      </label>
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4">
      <ng-select 
        id="form-item-client-information-location_dropdown" 
        class="location-dropdown"
        bindLabel="label"
        bindValue="value" 
        [items]="locationOptions.items" 
        [disabled]="!!sn.eamData.assetNumber || isJobNatureStandalone"
        [class.input-error]="errorFields.location"
        (clear)="onLocationSelectClear()"
        (search)="onLocationSelectSearchDebounce($event)"
        (scrollToEnd)="onLocationSelectScrollToEndDebounce()"
        [(ngModel)]="sn.location"
      >
      <ng-template ng-label-tmp let-item="item">
        <div style="white-space: normal" title="{{item.label}}">{{item.label}} <app-special-request-alert-icon *ngIf="item.isSpecialRequestAlert"></app-special-request-alert-icon></div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="location-dropdown-item" title="{{item.label}}">{{item.label}} <app-special-request-alert-icon *ngIf="item.isSpecialRequestAlert"></app-special-request-alert-icon></div>
      </ng-template>
      </ng-select>
    </div>
  </ng-container>

  <!-- Location Detail -->
  <ng-container *ngIf="isPageModeCreate">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
      {{ "component.client-information.location-details" | translate }}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4">
      <textarea 
        id="form-item-client-information-location-details"
        class="form-control" 
        rows="3" 
        [disabled]="!!sn.eamData.assetNumber || isJobNatureStandalone" 
        [maxlength]="textareaMaxLength" 
        [class.input-error]="errorFields.locationDetail"
        [(ngModel)]="sn.locationDetail" 
      ></textarea>
    </div>
  </ng-container>
</div>


