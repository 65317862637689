/**
 * This page is copy from and revamp the following pages: 
 * angular\src\app\pages\sn-view-job-card\sn-view-job-card.component.ts
 * angular\src\app\ui\components\job-card\enquiry-job-view\enquiry-job-view.component.ts
 * angular\src\app\ui\components\job-card\cm-job-view\cm-job-view.component.ts
 */

import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { ActionButtonDefinition, ActionButtonJobCard, ActionButtonType } from '@enum/action-button';
import { Session } from '@services/session';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JobCardActionButtions } from '../jobcard-view/jobcard-view-const';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { HaEquipmentListPanelComponent } from 'src/app/ui/components/ha-equipment-list-panel/ha-equipment-list-panel.component';
import { JobcardTaskProgressComponent } from '../../components/jobcard-task-progress/jobcard-task-progress.component';
import { JobcardHaInformationComponent } from '../../components/jobcard-ha-information/jobcard-ha-information.component';

import { JobCardStandaloneEditComponent } from 'src/app/job-card/job-card-standalone-edit/job-card-standalone-edit.component';

@Component({
  selector: 'app-jobcard-edit',
  templateUrl: './jobcard-edit.component.html',
  styleUrls: ['./jobcard-edit.component.scss']
})
export class JobcardEditComponent extends BasePage implements OnInit {
  @ViewChild("assign_staff_panel", { static: true }) assignStaffPanel;
  @ViewChild("patch_ccs_so_number_panel", { static: true }) patchCcsSoNumberPanel;
  @ViewChild('haEquipmentListPanel', { static: false }) haEquipmentListPanel: CustomSliderPanelComponent;
  @ViewChild('haEquipmentListForm', { static: false }) haEquipmentListForm: HaEquipmentListPanelComponent;

  @ViewChild("cmJobDescElem", { static: false }) cmJobDescElem;
  @ViewChild("cmClientInfoElem", { static: false }) cmClientInfoElem;
  @ViewChild("cmEquipInfoElem", { static: false }) cmEquipInfoElem;
  @ViewChild("cmHaInfoElem", { static: false }) cmHaInfoElem: JobcardHaInformationComponent;
  @ViewChild("cmTaskProgressElem", { static: false }) cmTaskProgressElem: JobcardTaskProgressComponent;
  @ViewChild("cmProgressDetailsElem", { static: false }) cmProgressDetailsElem;
  @ViewChild("cmCcsInfoElem", { static: false }) cmCcsInfoElem;
  @ViewChild("cmAdditionalInfoElem", { static: false }) cmAdditionalInfoElem;
  @ViewChild("jobcardAttachmentDetailElem", { static: false }) jobcardAttachmentDetailElem;
  @ViewChild(JobCardStandaloneEditComponent, { static: false }) jobcardStandaloneEditElem: JobCardStandaloneEditComponent;

  @ViewChild("enquiryJobDescElem", { static: false }) enquiryJobDescElem;
  @ViewChild("enquiryClientInfoElem", { static: false }) enquiryClientInfoElem;
  @ViewChild("enquiryTaskProgressElem", { static: false }) enquiryTaskProgressElem;
  @ViewChild("enquiryProgressDetailsElem", { static: false }) enquiryProgressDetailsElem;

  constructor(injector: Injector, private modalService: NgbModal) {
    super(injector);
  }

  pageMode = JMENUM.JMPageMode.EDIT;
  jobNatureEnum = JMENUM.JobNature; 
  JMPage = JMENUM.JMPage;

  breadcrumbs: any = [];
  componentParameters: any = {};
  isJobCcsError  : boolean;
  isHaEquipmentOwner: boolean = false;

  sn: JMOBJ.ServiceNotification = undefined;
  jobCard: JMOBJ.JobCard;
  reserveItemsStockLevelObj: JM.JMOBJ.StockLevelObject[];
  snNumber: string;
  jobCardNumber: string;
  selectedHaEquipment: any;

  // action buttons
  actionButtons: any[];
  disabledActionSideBar: boolean;
  disabledInput: boolean;

  // assign staff
  assignStaffFormParam = {} as any;
  showAllPostForReassign = false;
  workCentrePostList: any[];
  selectedAssignPersons = {
    assignedPersons: [],
    officerInCharge: null
  };

  // work centre attibute
  workCentreAttribute = {} as any;

  async ngOnInit() {
    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');
    
    this.componentParameters = {
      assetNumber: undefined,
      isEditMode: true,
      isFirstAttendedJobCard: this.isFirstAttendedJobCard(),
      disabledBreakdown: true,
      breakdown: false,
      isShowPatchCcsSoNumberButton: this.isShowPatchCcsSoNumberButton,
      setJobCard: this.setJobCard,
      onClickViewAssignStaff: this.onClickViewAssignStaff,
      onClickPatchCcsSoNumber: this.onClickPatchCcsSoNumber,
      onClickHaEquipmentNo: this.onClickHaEquipmentNo,
      onClearHaEquipmentNumber: () => {},
    }

    await this.requestJobCardSummary();
    this.snNumber && await this.requestSn();
    this.initBreadcrumbs();
  }

  private enableBreakdownCheckbox() {
    // break down is not editable for PM job or if HA work order exist
    let hasHaWo = this.sn && this.sn.eamData && this.sn.eamData.HAWorkOrderNumber;
    let isFirstAttendedJob = this.jobCard.jobCardNumber.endsWith('-01');
    let isPmJob = this.jobCard && this.jobCard.jobNature == JMENUM.JobNature.PM;
    this.componentParameters.disabledBreakdown = (hasHaWo && isFirstAttendedJob) || isPmJob;
  }

  private initPage(){
    this.resetActionSideBar();
  }

  private initBreadcrumbs = () => {
    let arr = [];
    this.snNumber && arr.push({ id: 'breadcrumbs-sn-number', name: this.snNumber, route: '/sn/view/' + this.snNumber });
    arr.push({ id: 'breadcrumbs-job-card-number', name: this.jobCardNumber, route: '/job-card/view/'+ this.jobCardNumber });
    arr.push({ id: 'breadcrumbs-edit', name: JMLanguage.translate('action.button.edit'), route: null , currentPage: true });
    this.breadcrumbs = arr;
  }

  private isFirstAttendedJobCard() {
    let tmpArr = this.jobCardNumber.split("-");
    return tmpArr.length > 1 && tmpArr[1] == '01';
  }

  private resetActionSideBar(): void {
    this.disabledActionSideBar = false;
    this.actionButtons = [];
    let jobCardStatus = this.jobCard.status ? ("" + this.jobCard.status) : null;

    if (!jobCardStatus ||
      !JobCardActionButtions[this.jobCard.jobNature] ||
      !JobCardActionButtions[this.jobCard.jobNature][jobCardStatus] ||
      !JobCardActionButtions[this.jobCard.jobNature][jobCardStatus][this.pageMode] ||
      !JobCardActionButtions[this.jobCard.jobNature][jobCardStatus][this.pageMode].length)
      return;

    let buttons = JobCardActionButtions[this.jobCard.jobNature][jobCardStatus][this.pageMode];
    for (let buttonStatus of buttons) {
      if (!this.hasActionButtonPermission(buttonStatus)) continue;

      let actionButton = ActionButtonDefinition[ActionButtonType.jobCard][buttonStatus];
      if (!actionButton.buttons || !actionButton.buttons.length)
        actionButton.buttons = [
          { name: this.translateService.instant("global.yes") },
          { name: this.translateService.instant("global.no") }
        ];

      actionButton.buttons[0].handler = this.getActionButtonCallback(buttonStatus);

      this.actionButtons.push(actionButton);
    }
  }

  private initReassignStaffPanel(isJobCardData = true): void {
    if (this.showAllPostForReassign && this.workCentrePostList == null) {
      this.requestWorkCentrePostList();
      return;
    }

    let assignedPersons = [];
    let officerInCharge = null;
    let teamMembers = this.showAllPostForReassign ? this.workCentrePostList : this.jobCard.teamMembers;

    if (isJobCardData) {
      assignedPersons = this.jobCard.assignedPersons;
      officerInCharge = this.jobCard.officerInCharge;
      this.selectedAssignPersons.assignedPersons = assignedPersons;
      this.selectedAssignPersons.officerInCharge = officerInCharge;
    } else {
      assignedPersons = this.selectedAssignPersons.assignedPersons;
      officerInCharge = this.selectedAssignPersons.officerInCharge;
    }
    teamMembers = Array.from(new Set(teamMembers.concat(this.selectedAssignPersons.assignedPersons)));

  }

  private initViewAssignedStaffPanel(): void {
    this.assignStaffFormParam = {
      teamMembers: this.jobCard.teamMembers,
      assignedPersons: this.jobCard.assignedPersons,
      officerInCharge: this.jobCard.officerInCharge,
      viewOnly: true
    };
  }

  //======= API =======//
  private async requestSn() {
    const request: JM.JMRequestSnGetSn = new JM.JMRequestSnGetSn();
    request.snNumber = this.snNumber;

    const response: JM.JMResponseSnGetSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.sn = response.payload;
    this.isHaEquipmentOwner = (this.sn && this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA) ? true : false;

    if (this.sn.eamData) {
      this.selectedHaEquipment = {
        assetNumber: this.sn.eamData.assetNumber || undefined,
        cmBreakdownDate: this.sn.eamData.cmBreakdownDate || undefined
      };
    }

    this.enableBreakdownCheckbox();
  }

  private async requestJobCardSummary(){
    const request = new JM.JMRequestJobCardsGetJobCard();
    request.jobCardNumber = this.jobCardNumber;
    request.isGettingLatestStockNumbers = true;

    const response: JM.JMResponseJobCardsGetJobCard = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }

    if (response.payload.stockLevel) {
      this.reserveItemsStockLevelObj = response.payload.stockLevel;
    }
    if (response.payload.jobCard) {
      this.jobCard = response.payload.jobCard;
      this.snNumber = this.jobCard.snNumber;

      this.enableBreakdownCheckbox();
      this.initPage();
    }
  }

  private async requestWorkCentrePostList() {
    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = 'CCEPJM';
    request.authorizations = { 'workCenters': this.jobCard.workCentre };
    request.active = JMENUM.RequestActive.ACTIVE;

    this.assignStaffFormParam.isLoading = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.assignStaffFormParam.isLoading = false
    this.showAllPostForReassign = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.workCentrePostList = response.payload.records.map(post => { return post.name });
  }


  private async requestSaveEnquiryJobCard() {
    const request: JM.JMRequestJobCardsUpdateEnquiry = new JM.JMRequestJobCardsUpdateEnquiry();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.priority = this.jobCard.priority;
    request.jobDescription = this.jobCard.jobDescription;

    this.disabledActionSideBar = true;
    const response: JM.JMResponseJobCardsUpdateEnquiry = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.openSnackBar(JMLanguage.translate("global.saved"));
    this.router.navigate(['/job-card/view/'+ this.jobCardNumber]);
  }

  private async requestSaveCmJobCard() {
    let request: JM.JMRequestJobCardsPutJobCards = new JM.JMRequestJobCardsPutJobCards();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.priority = this.jobCard.priority;
    request.matType = this.jobCard.matType;
    request.orderType = this.jobCard.orderType;
    request.jobDescription = this.jobCard.jobDescription;
    request.appointmentTime = this.jobCard.appointmentTime;
    request.startTime = this.jobCard.startTime;
    request.completionTime = this.jobCard.completionTime;
    request.malfunctionStartTime = this.jobCard.malfunctionStartTime;
    request.malfunctionEndTime = this.jobCard.malfunctionEndTime;
    request.equipmentReceivedDate = this.jobCard.equipmentReceivedDate;
    request.breakdown = this.jobCard.breakdown;

    request.responseToClientTime = this.jobCard.responseToClientTime;
    request.receivedTime= this.jobCard.receivedTime;
    request.plannedCompletionTime= this.jobCard.plannedCompletionTime;

    request.eamDropdown = this.jobCard.eamDropdown;
    request.equipmentTagDropdown = this.jobCard.equipmentTagDropdown;

    this.disabledActionSideBar = true;
    const response: JM.JMResponseJobCardsPutJobCards = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;
    
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.router.navigate(['/job-card/view/'+ this.jobCardNumber]);
    this.openSnackBar(JMLanguage.translate("global.saved"));
  }

  private async requestSaveStandaloneJobCard() {
    const request = new JM.JMRequestJobCardsUpdateStandaloneJob();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.jobDescription = this.jobCard.jobDescription;
    request.startTime = this.jobCard.startTime;
    request.completionTime = this.jobCard.completionTime;

    this.disabledActionSideBar = true;
    const response: JM.JMResponseJobCardsPutJobCards = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.router.navigate(['/job-card/view/'+ this.jobCardNumber]);
    this.openSnackBar(JMLanguage.translate("global.saved"));
  }

  private async requestUpdateSnEamData() {
    let request = new JM.JMRequestJobCardsUpdateEamData();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;

    if (this.sn.eamData) {
      if (this.sn.eamData.assetNumber && this.sn.sourceSystem != JMENUM.SourceSystem.HAEAM) {
        request.assetNumber = this.sn.eamData.assetNumber;
      }
  
      if (this.isHaEquipmentOwner && this.sn.maintenanceType === JMENUM.MaintenanceType.PM) {
        request.replyToHA = this.sn.eamData.replyToHA;
      }
    }

    const response: JM.JMResponseJobCardsUpdateEamData = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload && response.payload.sn) {
      this.sn = response.payload.sn;
      this.jobCard.version = response.payload.jobCard.version;
    }
  }

  //========= UI Function =========//
  setJobCard = (jobCard) => {
    this.jobCard = jobCard;
  }

  isShowPatchCcsSoNumberButton = () => {
    return false;
  }
  
  onClickPatchCcsSoNumber = () => {
    this.patchCcsSoNumberPanel.toggle();
  }

  onPatchCcsSoSuccessSubmit(event: JMOBJ.JobCard) {
    this.setJobCard(event);
    this.patchCcsSoNumberPanel.close();
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = JMLanguage.translate(button.name);
      });
      this.showPopUpAlert(this.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  onClickViewAssignStaff = () => {
    this.initViewAssignedStaffPanel();
    this.assignStaffPanel.toggle();
  }

  onSaveButtonClicked = async () => {
    switch (this.jobCard.jobNature) {
      case JMENUM.JobNature.CM:
      case JMENUM.JobNature.PM:
        if (this.isHaEquipmentOwner) {
          let validSectionTaskProgress = this.cmTaskProgressElem.validation(this.selectedHaEquipment.cmBreakdownDate);
          let validSectionHaInfo = this.cmHaInfoElem.validation();
          if (!validSectionTaskProgress || !validSectionHaInfo) return;
          await this.requestUpdateSnEamData();
        } else {
          if (!this.cmTaskProgressElem.validation() || !this.cmJobDescElem.validation()) return;
        }
        this.requestSaveCmJobCard();
        break;
      case JMENUM.JobNature.STANDALONE:
        if (!this.jobcardStandaloneEditElem.validation()) return;
        this.requestSaveStandaloneJobCard();
        break;
      case JMENUM.JobNature.ENQUIRY:
        if(!this.enquiryJobDescElem.validation()) return;
        this.requestSaveEnquiryJobCard();
        break;
    }
  }

  onCloseButtonClicked = () => {
    this.router.navigate(['/job-card/view/' + this.jobCardNumber ]);
  }

  onClickHaEquipmentNo = () => {
    this.haEquipmentListPanel.toggle();
    this.haEquipmentListForm.refreshTable();
  }

  onHaEquipmentListPanelSubmit = () => {
    this.haEquipmentListPanel.close();
    if (this.sn.eamData.assetNumber !== this.selectedHaEquipment.assetNumber) {
      this.sn.eamData.assetNumber = this.selectedHaEquipment.assetNumber;
    }
  }

  public updateExistingJobCard(records) {
    if (records.length > 0) {
      this.jobCard = records[0].jobCard;
    }
  }

  //========= Action Button ========//
  private getJobCardFreezedActionList() {
    let freezedTecoStatusList = [(Number)(JMENUM.TecoStatus.PENDING_FOR_TECO), (Number)(JMENUM.TecoStatus.SUCCEEDED), (Number)(JMENUM.TecoStatus.PENDING_FOR_UNTECO)];
    let freezedActionList = [ActionButtonJobCard.jobReopen, /*ActionButtonJobCard.timeSheet*/]; // timesheet view only
    let freezed = freezedTecoStatusList.includes((Number)(this.jobCard.tecoStatus));

    return freezed ? freezedActionList : [];
  }

  private getActionButtonCallback(buttonStatus: ActionButtonJobCard): any {
    switch (buttonStatus) {
      case ActionButtonJobCard.save:
        return this.onSaveButtonClicked;
      case ActionButtonJobCard.close:
        return this.onCloseButtonClicked;
      default:
        return () => { };
    }
  }

  private hasActionButtonPermission(buttonStatus) {
    let actionButton = ActionButtonDefinition[ActionButtonType.jobCard][buttonStatus];
    if (Array.isArray(actionButton.permission)) {
      if (actionButton.buttons && !this.authorizationService.hasPermissions(actionButton.permission, false)) {
        return false;
      }
    } else {
      if (actionButton.buttons && !this.authorizationService.hasPermission(actionButton.permission)) {
        return false;
      }
    }

    if (this.getJobCardFreezedActionList().includes(buttonStatus)) { return false; }

    return true;
  }

}
