import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { EquipmentTagService } from '@services/equipment-tag.service';
import { Session } from 'src/app/services/session';
import { AddTagFormComponent } from 'src/app/ui/components/add-tag-form/add-tag-form.component';
import { CustomSliderPanelComponent } from 'src/app/ui/components/custom-slider-panel/custom-slider-panel.component';
import { SidebarItemInterface } from 'src/app/ui/components/sidebar/sidebar-items';
import { BasePage } from 'src/app/ui/model/base/base';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'ccs-equipment-tag-list',
  templateUrl: './ccs-equipment-tag-list.component.html',
  styleUrls: ['./ccs-equipment-tag-list.component.scss']
})


export class CCSEquipmentTagListComponent extends BasePage implements OnInit, AfterViewInit {
  @ViewChild(CustomSliderPanelComponent, { static: true }) addTagPanel: CustomSliderPanelComponent;
  @ViewChild(AddTagFormComponent, { static: true }) addTagForm: AddTagFormComponent;

  
  mode:'workcentre'|'cluster'= 'workcentre';
  pageTitle:string = undefined;
  hasEditPermission:boolean = false;
  hasViewPermission:boolean = false;


  selectUsableOption: 'usable' | 'unusable' = 'usable';
  usableOption = {
    usable: 'usable',
    unusable: 'unusable',

  }
  workCentre:JMOBJ.WorkCentre = undefined;
  userWorkCentres: {} = undefined;
  sideBarItem: SidebarItemInterface[] = [];
  hashTagsList:JMOBJ.HashTag[] = [];
  unusbleHashTagsList:JMOBJ.HashTag[] = [];
  userTagGroup:JMOBJ.HashTagGroup[] = [];
  userTagGroupWorkCentreCodes:string[] = undefined;
  tablexParam = undefined;

  constructor(
    injector: Injector,
    private equipmentTagService: EquipmentTagService,
  ) { 
    super(injector);
  }

  //===========================================================================
  // view life cycle functions
  ngOnInit() {
    this.workCentre = Session.workCentreDict[Session.selectedWorkCentre];
    this.userWorkCentres = Session.userInfo;
   

    //TODO: deprecate this.mode but keep this.workCentre.enableHAEquipment checks?
    if (this.workCentre.enableHAEquipment) {
      this.mode = 'cluster';
      this.pageTitle = JMLanguage.translate("ccs-equipment-tag-list.title",[this.workCentre.HACluster])  + ' - ' + [Session.selectedWorkCentre];
    } else {
      this.mode = 'workcentre';
      this.pageTitle = JMLanguage.translate("ccs-equipment-tag-list.title",[Session.selectedWorkCentre]);
    }
    
    this.hasEditPermission = JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.WCM_UPDATE);
    this.hasViewPermission = JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.WCM_VIEW);
    this.initSideBar();
    this.initTablex();
  }

  ngAfterViewInit() {
    
    this.addTagForm.param = {
      onSave: this.onTagFormSaved
    };
    // this.requestTagGroup(this.selectUsableOption);
    // this.requestEquipmentTag();
    this.reloadTable();

  }

  ngOnDestroy() {
  }

  //========================
  private initSideBar() {
    this.sideBarItem = [
      {
        title: "side-bar.equipment",
        subItems:[
          {
            title: "side-bar.equipment-tag",
            path: '/setting/ccs-equipment-tag-list',
          }, {
            title: "side-bar.equipment-list",
            path: '/setting/equipment-list',
          }
        ]
      }
    ];
    let enableHAEquip = Session.workCentreDict[Session.selectedWorkCentre].enableHAEquipment;
    if (enableHAEquip && JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.HSD_EQUIPMENT_VIEW)) {
      this.sideBarItem[0].subItems = this.sideBarItem[0].subItems.concat([
        {
          id: 'equipment-list',
          title: "side-bar.ha-equipment-tag-list",
          path: '/setting/ha-equipment-tag-list'
        }, {
          title: "side-bar.ha-equipment-list",
          path: '/setting/ha-equipment-list',
        }, 
      ]);
    }

  } 

  private initTablex() {
    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : false,
      enablePagination      : false,
      tableRow              : "row",
      tableWrapperClass     : "table-min-width",
      headers: [
        {
          id: 'tagGroup',
          name: 'pages.equipment-tag.col-header.tag-group',
          class: 'col-1',
          type: TablexColumnType.Text,
          enableFilter: false,
          onFilterKeyUp: null,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id: 'workCentre',
          name: 'pages.equipment-tag.col-header.workcentre',
          class: 'col-2',
          type: TablexColumnType.Text,
          enableFilter: false,
          onFilterKeyUp: null,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle
        },
        {
          id: 'tag',
          name: 'pages.equipment-tag.col-header.tag',
          class: 'col-9',
          type: TablexColumnType.BorderLabel,
          enableFilter: false,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          buttons: this.hasEditPermission ? [
            {
              id: 'newTag',
              name: '',
              icon: 'fas fa-plus',
              class: 'glyph brand-blue',
              hidden: false,
              disable: false,
              onClicked: this.onAddNewTagClicked,
            }
          ] : []
        }
      ],
      content: []
    }
  }

  // TODO: remove
  private renderRow(tagType: any, isTagClassified: boolean): any {
    let cat = null;
    let etype = null;

    if (isTagClassified) {
      let obj = JM.JMConnector.getEquipmentCategory(tagType[0]['equipmentCategory']);
      cat = obj.code + " - " + obj.description[Session.selectedLanguage];

      let obj2 = JM.JMConnector.getEquipmentType(tagType[0]['equipmentType']);
      etype = obj2.code + " - "+ obj2.description[Session.selectedLanguage];
    } else {
      cat = JMLanguage.translate("hashtag.category.unclassified");
      etype = JMLanguage.translate("hashtag.type.unclassified");
    }
    return [
      cat,
      etype,
      tagType.map(tag => {
        let tagname = tag.description[Session.selectedLanguage];


        let ccsClass = "tag-blue";
        // tagGroup exist checks
        if(tag.tagGroup){
          let tagGroup = tag.tagGroup['workCentres'];
          // let tagGroupType = tag.tagGroup.type;
          //let tagGroupWorkCentre = tag.tagGroup['workCentres'].find(wc=> wc !== undefined).map(x =>{ x.workCentreCode}); 


          let tagGroupName = tag.tagGroup.name; 
          if(tagGroup && tagGroupName && tag.tagGroup.isNameOnDisplay){
            tagname = tagname + ` <${tagGroupName}>` ;
          }
        }
        

        if (tag.source == JMENUM.HashtagSource.SYSTEM) {
          ccsClass = "tag-amber";
        }
        // if (tag.workCentre != this.workCentre.workCentreCode) {
        //  if (!this.userTagGroupWorkCentreCodes.includes(tag.workCentre)) {
        //   ccsClass = "tag-red";
        //   // tagname = tagname + ` <${tag.workCentre}>` ;
        // }
        
        return {
          name: tagname,
          data: tag,
          class: ccsClass,
          onClicked: this.onTagClicked,
        }
      })
    ];
  }

  private renderTable() {
    // groupby tagGroup
    let tagGroupDict: { [key: string]: JMOBJ.HashTag[] } = {};
    for (const tag of this.hashTagsList) {
      if (tag.tagGroup._id in tagGroupDict) {
        tagGroupDict[tag.tagGroup._id].push(tag);
      } else {
        tagGroupDict[tag.tagGroup._id] = [tag];
      }
    }

    this.tablexParam['content'] = [];

    for (const tagList of Object.values(tagGroupDict)) {
      // assume tag.tagGroup.workCentres are same under same tagGroup
      const tagGroup = tagList[0].tagGroup;
      const tagGroupName = tagGroup.name;
      const wcCodeList = tagGroup.workCentres
        .filter(wc => wc && wc.workCentreCode)
        .map(wc => wc.workCentreCode);
      const labelObjectList = tagList.map(tag => {
        return {
          name: this.equipmentTagService.getTagDescription(tag, true),
          data: tag,
          class: 'tag-blue',
          onClicked: this.onTagClicked,
        };
      })

      this.tablexParam['content'].push([
        tagGroupName,
        wcCodeList.join(', '),
        labelObjectList
      ]);
    }
    // TODO: remove old groupby equipment category and type
    // this.tablexParam['content'] = [];
    // // let tag:JMOBJ.HashTag = null;
    // let tagMap: {} = {}; // key: cat + type, objectL tag
    // let unclassifiedTagMap = [];

    // for (let tag of this.hashTagsList) {
    //   if (!tag['equipmentType']) {
    //     unclassifiedTagMap.push(tag);
    //   } else {
    //     let key: string = tag['equipmentCategory'] + '' + tag['equipmentType'];
    //     if ((key in tagMap) == false) {
    //       tagMap[key] = [];
    //     }
    //     tagMap[key].push(tag);
    //   }
    // }

    // let rows = Object.keys(tagMap).map(tagType => {
    //   return this.renderRow(tagMap[tagType], true);
    // });

    // // sort the list // sort it myself to avoid different order
    // // this.tagTable.sortTable(1);
    // // this.tagTable.sortTable(0);

    // rows.sort((row1, row2) => {
    //   return row1[1].localeCompare(row2[1]);
    // })
    // rows.sort((row1, row2) => {
    //   return row1[0].localeCompare(row2[0]);
    // })

    // // uncalssified equipment on bottom of the table
    // rows.push(this.renderRow(unclassifiedTagMap, false));
    // this.tablexParam['content'] = rows
  }

  private renderUnusableTagTable(){
    // TODO: update groupby logic
    this.tablexParam['content'] = [];
    let tagMap: {} = {};
    let unclassifiedTagMap = [];

    for (let tag of this.unusbleHashTagsList){
      if(!tag['equipmentType']){
        unclassifiedTagMap.push(tag);
      } else {

        let equipmentCat = tag['equipmentCategory'] == undefined ? '*' : tag['equipmentCategory'];
        let equipmentType = tag['equipmentType'] == undefined ? '*' : tag['equipmentType'];


        let key: string = equipmentCat + '' + equipmentType
                         
        if ((key in tagMap) == false) {
          tagMap[key] = [];
        }
        tagMap[key].push(tag);
      }
    }

    let rows =  Object.keys(tagMap).map(tagType => {
      return this.renderUnusableTagRow(tagMap[tagType], true);
    })

    rows.sort((row1, row2) => {
      return row1[1].localeCompare(row2[1]);
    })
    rows.sort((row1, row2) => {
      return row1[0].localeCompare(row2[0]);
    })

    // uncalssified equipment on bottom of the table
    rows.push(this.renderUnusableTagRow(unclassifiedTagMap, false));
    this.tablexParam['content'] = rows
  }

  private renderUnusableTagRow(tagType: any, isTagClassified: boolean): any {
    let cat = null;
    let etype = null;

    if (isTagClassified) {
      let obj = JM.JMConnector.getEquipmentCategory(tagType[0]['equipmentCategory']);
      if(obj){
        cat = obj.code + " - " + obj.description[Session.selectedLanguage];
      } else {
        cat = '*'
      }


      let obj2 = JM.JMConnector.getEquipmentType(tagType[0]['equipmentType']);
      if(obj2){
        etype = obj2.code + " - "+ obj2.description[Session.selectedLanguage];
      }
      etype = '*'

    } else {
      cat = JMLanguage.translate("hashtag.category.unclassified");
      etype = JMLanguage.translate("hashtag.type.unclassified");
    }
    return [
      cat,
      etype,
      tagType.map(tag => {
        let tagname = tag.description[Session.selectedLanguage];
        let ccsClass = "tag-red";
        // tagGroup exist checks
        if(tag.tagGroup){
          let tagGroup = tag.tagGroup['workCentres'];
          let tagGroupName = tag.tagGroup.name; 
          if(tagGroup && tagGroupName && tag.tagGroup.isNameOnDisplay){
            tagname = tagname + ` <${tagGroupName}>` ;
          }
        }
        return {
          name: tagname,
          data: tag,
          class: ccsClass,
          onClicked: this.onTagClicked,
        }
      })
    ];
  }

  //----------------------------------------
  // button callback
  onAddNewTagClicked = () => {
    this.addTagForm.resetForm();
    this.addTagForm.showTag(Session.selectedWorkCentre, null);
    this.addTagPanel.toggle();
  }

  onTagClicked = (rowIndex, button) => {
    let tag = button.data;
    this.addTagForm.resetForm();
    this.addTagForm.showTag(Session.selectedWorkCentre, tag);
    this.addTagPanel.toggle();
  }

  onTagFormSaved = () => {
    // this.requestTagGroup(this.selectUsableOption);
    this.reloadTable();
    this.addTagPanel.toggle();
  }

  onChangeUsableOption = () => {
  // this.requestTagGroup(this.selectUsableOption);
    this.reloadTable();
  }


  //----------------------------------------
  // API function
  private reloadTable() {
    if (this.selectUsableOption === 'usable'){
      this.requestEquipmentTag();
    } else {
      this.requestUnusableEquipmentTag();
    }
  }

  private async requestEquipmentTag() {
    const request = new JM.JMRequestWorkCentresHashtags();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.tagGroupStatusList = [JMENUM.HashtagGroupStatus.ACTIVE];
    request.equipmentSource = JMENUM.EquipmentSource.CCS;
    request.workCentre = Session.selectedWorkCentre;
    
    const response: JM.JMResponseWorkCentresHashtags = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if(response.payload.length > 0){
      this.hashTagsList = response.payload;
      this.renderTable();
    }
  }


  private async requestUnusableEquipmentTag(){
    const request = new JM.JMRequestEquipmentsEquipmentSummary();
    request.workCentre = [Session.selectedWorkCentre]
    // request.hasHashTag = true
    request.pageSize = 1000;

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if(response.payload.totalCount > 0){
      //let tags = response.payload.records.map(e => {e.workCentre !== Session.selectedWorkCentre})
      for(let equipmentHashList of response.payload.records) {
        for(let hash of equipmentHashList.hashtags){
          if(hash['tagGroup']){
            // handle tagGroup logics
          } else {
            // this.unusbleHashTagsList.push(hash)
            if(hash.workCentre !== Session.selectedWorkCentre){
              this.unusbleHashTagsList.push(hash)
            }
          }
        }
      }
      this.renderUnusableTagTable();
    }
  }

  // TODO: TBC - remove
   async requestTagGroup(usableOption: 'usable' | 'unusable' = 'usable') {
    const request = new JM.JMRequestHashtagGroupSummary();
    // Usable Equipment Tag List filter with workCentres
    if(usableOption === 'usable'){
      request.workCentreList = [Session.selectedWorkCentre]
    }
    request.status = JMENUM.RequestActive.ACTIVE

    const response: JM.JMResponseHashtagGroupSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if(response.payload.totalCount > 0){
      this.userTagGroup = response.payload.records;
    }

    if(usableOption === 'usable'){
      this.requestEquipmentTag();
    } else {
      this.requestUnusableEquipmentTag();
    }
  }
}

const getWorkCentresFromHashtagGroup = (tagGroup):string[] => {
  if(!tagGroup || tagGroup.length === 0){
    return
  }
  let codes = new Set<string>();
  for (let grp of tagGroup) {
    for (let wc of grp.workCentres) {
      codes.add(wc.workCentreCode);
    }
  }
  // console.log(codes.keys());
  return Array.from(codes);
  // const workCentreCode = [];
  // tagGroup.map(({workCentres}) => {
  //   workCentres.map((code) => code.workCentreCode).forEach(
  //     (e) => {
  //       workCentreCode.push(e)
  //     }
  //   )
  // })
  // return workCentreCode
}
