import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Permission } from '@enum/permission';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactGroupService } from '@services/contact-group.service';
import { AppDelegate } from 'src/app/AppDelegate';
import { isEmailAdress, isNumber } from 'src/app/presenter/validators/validator';
import { Session } from 'src/app/services/session';
import { ModalOfficeHoursComponent } from 'src/app/ui/components/modal-office-hours/modal-office-hours.component';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: "team-settings-instruction",
  templateUrl: "./team-settings-instruction.html",
  styleUrls: ["./team-settings-instruction.scss"]
})
export class TeamSettingsInstructionComponent extends BasePage implements OnInit, AfterViewInit {
  @ViewChild("inhouseTeamSelector", { static: true }) inhouseTeamSelectorView;
  @ViewChild("contractorTeamSelector", { static: true }) contractorTeamSelectorView;

  pageTitle: string;

  INSTRUCTION_CONFIG_OPTION_LIST_ID = 'configOptionListId'
  
  // manual instruction
  dropConnectedList           : string[] = [];
  instructionConfigList       : any[]    = [];
  instructionConfigDict       : {}       = {};
  instructionConfigOptionList : any[]    = [
    { 
      manualInstructionId   : "",
      remarks               : "",
      description           : "",
      inputType             : ""
    }
  ];

  displayType = {
    both                              : 0,
    inHouse                           : 1,
    contractor                        : 2
  }

  actionType = {
    preAction                         : 'preAction',
    contractorNoResp                  : 'contractorNoResponse',
    roNoResp                          : 'roNoResponse',
  }

  // permission
  hasCreationPermission               : boolean = false;
  hasEditionPermission                : boolean = false;

  // view data
  priorityTitleViewData               : any;
  instructionViewData                 : any;

  uiMode                              :"inhouse"|"contractor" = "inhouse";
  requestButtonDisabled               = false;
  requestUpdateTeamLoading            = false;
  requestCopyTeamLoading              = false;
  isLoadingTeams                      = false;
  isLoadingOfficeHours                = false;
  
  copyTeamList                        : JMOBJ.Team[] = [];
  copyTeamErrorMessage                : string = '';
  enableManualInstruction             : boolean = false;
  selectedWorkCentre                  : string;
  selectedCopyToTeam                  : string;
  selectedTeam                        : JMOBJ.Team = null;

  constructor(
    injector: Injector,
    private contactGroupService: ContactGroupService,
    private modalService: NgbModal,
  ) {
    super(injector);
    
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.selectedWorkCentre = Session.selectedWorkCentre;
    this.pageTitle = JMLanguage.translate("pages.team-instruction.page-title", [this.selectedWorkCentre]);
    this.hasCreationPermission = this.authorizationService.hasPermission(JMENUM.Permission.WCM_UPDATE);
    this.hasEditionPermission = false; //this.authorizationService.hasPermission(Permission.wcmUpdate) && this.selectedTeam.workCentre === this.selectedWorkCentre;

    this.checkViewPermission(JMENUM.Permission.WCM_VIEW);
    this.checkWorkCenterPermission(this.selectedWorkCentre);
  }
  
  ngAfterViewInit() {
    // this.getManualInstructionConfig();
  }

  //---------------------------------------------------------------------------
  // view functions
  private initInstrctionSectionView() {
    this.priorityTitleViewData = [
      {
        text               : 'priority.non-urgent',
        titleCss           : 'non-urgent-label',
        copyFormPriorityKey: '3',
        copyToPriorityKey  : '2',
      }, {
        text               : 'priority.urgent',
        titleCss           : 'urgent-label',
        copyFormPriorityKey: '2',
        copyToPriorityKey  : '1',
      }, {
        text               : 'priority.emergency',
        titleCss           : 'emergency-label',
        copyFormPriorityKey: '1',
        copyToPriorityKey  : '',
      }
    ]

    this.instructionViewData = [
      {
        id         : 'non-urgent',
        priorityKey: '3', // refer manual instruction schema
        buttonCss  : 'brand-blue',
        stateItems : [
          {
            id                        : 'pre-action',
            stateKey                  : 'preAction', // refer manual instruction schema
            text                      : 'pages.team-setting.action-before-creating-sn',
            displayType               : this.displayType.both,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
          {
            id                        : 'contractor-no-response',
            stateKey                  : 'contractorNoResponse',
            text                      : 'pages.team-setting.no-response-of-contractor',
            displayType               : this.displayType.contractor,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
          {
            id                        : 'ro-no-response',
            stateKey                  : 'roNoResponse',
            text                      : 'pages.team-setting.no-response-of-ro',
            displayType               : this.displayType.both,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
        ]
      }, {
        id         : 'urgent',
        priorityKey: '2',
        buttonCss  : 'brand-amber',
        stateItems : [
          {
            id                        : 'pre-action',
            stateKey                  : 'preAction',
            text                      : 'pages.team-setting.action-before-creating-sn',
            displayType               : this.displayType.both,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
          {
            id                        : 'contractor-no-response',
            stateKey                  : 'contractorNoResponse',
            text                      : 'pages.team-setting.no-response-of-contractor',
            displayType               : this.displayType.contractor,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
          {
            id                        : 'ro-no-response',
            stateKey                  : 'roNoResponse',
            text                      : 'pages.team-setting.no-response-of-ro',
            displayType               : this.displayType.both,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
        ]
      }, {
        id         : 'emergency',
        priorityKey: '1',
        buttonCss  : 'brand-red',
        stateItems : [
          {
            id                        : 'pre-action',
            stateKey                  : 'preAction',
            text                      : 'pages.team-setting.action-before-creating-sn',
            displayType               : this.displayType.both,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
          {
            id                        : 'contractor-no-response',
            stateKey                  : 'contractorNoResponse',
            text                      : 'pages.team-setting.no-response-of-contractor',
            displayType               : this.displayType.contractor,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
          {
            id                        : 'ro-no-response',
            stateKey                  : 'roNoResponse',
            text                      : 'pages.team-setting.no-response-of-ro',
            displayType               : this.displayType.both,
            instructionGroupList      : [],
            createInstructionGroupList: []
          },
        ]
      }
    ]

    if (this.selectedTeam) {
      this.instructionViewData = this.instructionViewData.map(priorityItem => {
        priorityItem.stateItems = priorityItem.stateItems.filter(item => {

          if (this.selectedTeam.handlingParty) {
            if (this.selectedTeam.handlingParty == JMENUM.HandlingParty.INHOUSE) {
              return item.displayType == this.displayType.both || item.displayType == this.displayType.inHouse;
            } else {
              return item.displayType == this.displayType.both || item.displayType == this.displayType.contractor;
            }
          }
          return false;
        })
        return priorityItem;
      });
    }
  }

  //---------------------------------------------------------------------------
  // api function
  getManualInstructionConfig() {
    this.instructionConfigList  = [];
    const handlingParty         = this.selectedTeam.handlingParty;
    const tmpInstructionConfigs = JM.JMConnector.getAllManualInstruction().sort((a, b) => a.description.en.toLowerCase().localeCompare(b.description.en.toLowerCase()));
    
    for (const config of tmpInstructionConfigs) {
      // filter manual instruction config
      if (config.handlingParty.includes(handlingParty)) {
        const newConfig = {
          manualInstructionId: config.manualInstructionId,
          description        : config.description[Session.selectedLanguage],
          inputType          : config.inputType,
          groupType          : config.groupType,
          handlingParty      : config.handlingParty,
          workhourType       : config.workHourType
        }
        this.instructionConfigDict[config.manualInstructionId] = newConfig;

        // 7x24 contractor team not allow show "Call Contractor (Working Hours)" and "Call Contractor (Non-Working Hours)" intruction
        if (this.isContractor(handlingParty) 
            && this.selectedTeam.isWorkAroundClock 
            && config.groupType === 1 
            && config.workHourType !== 1 
            && !config.handlingParty.includes(JMENUM.HandlingParty.INHOUSE)
          ) {
          continue;
        }
        // non 7x24 contractor team not allow show "Call Contractor (24 hours)"intruction
        if (this.isContractor(handlingParty) 
            && !this.selectedTeam.isWorkAroundClock 
            && config.groupType === 1 
            && config.workHourType === 1 
            && !config.handlingParty.includes(JMENUM.HandlingParty.INHOUSE)
          ) {
          continue;
        }
        this.instructionConfigList.push(newConfig)
      }
    }

    if (this.instructionConfigList.length > 0) {
      this.instructionConfigOptionList[0]           = Object.assign({}, this.instructionConfigList[0]);
      this.instructionConfigOptionList[0].remarks   = '';
      this.instructionConfigOptionList[0].inputType = this.instructionConfigList[0].inputType ? this.instructionConfigList[0].inputType : 1;  // 1 = free text
    }
    this.bindInstructionSectionViewData();
  }

  async requestCopyTeam() {
    this.copyTeamErrorMessage   = '';
    const request = new JM.JMRequestTeamsCopyManualInstruction();
    request.toTeamId = this.selectedCopyToTeam; 
    request.fromTeamId = this.selectedTeam._id.toString();

    this.setRequestBtnDisabled(true);
    this.requestCopyTeamLoading = true;
    const response: JM.JMResponseTeamsCopyManualInstruction = await AppDelegate.sendJMRequest(request);
    this.setRequestBtnDisabled(false);
    this.requestCopyTeamLoading = false;

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      if ([10051, 10052, 10053].includes(response.code)) {
        let message = this.translate(`api.error.${response.code}`);

        if (response && message.includes(`api.error.`)) {
            message = response.error;
        }
        this.copyTeamErrorMessage = message; // set error message to copyTeamErrorMessage
      } else {
        AppDelegate.openErrorBar(response);
      }
      return;
    }

    this.openSnackBar(this.translate("global.copied"));
  }

  async requestUpdateTeam() {
    const request = new JM.JMRequestTeamsUpdateTeam();

    request._id                         = this.selectedTeam._id;
    request.enableManualInstructions    = this.enableManualInstruction;
    request.manualInstructions = {
      1: { preAction: [], contractorNoResponse: [], roNoResponse: [] },
      2: { preAction: [], contractorNoResponse: [], roNoResponse: [] },
      3: { preAction: [], contractorNoResponse: [], roNoResponse: [] },
    }

    // convert instruction to request format
    for (const priorityItem of this.instructionViewData) {
      for (const stateItem of priorityItem.stateItems) {
        let newInstructionGroups = [];

        for (let i = 0; i < stateItem.instructionGroupList.length; i++) {
          const instructionList = stateItem.instructionGroupList[i];

          newInstructionGroups.push(this.formatInstructionList(instructionList));
          request.manualInstructions[priorityItem.priorityKey][stateItem.stateKey] = newInstructionGroups;
        }
      }
    }

    this.setRequestBtnDisabled(true);
    this.requestUpdateTeamLoading = true;
    const response: JM.JMResponseTeamsCreateTeam = await AppDelegate.sendJMRequest(request);
    this.setRequestBtnDisabled(false);
    this.requestUpdateTeamLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate("global.saved"));  
  }

  private async getCopyTeamList() {
    this.copyTeamList       = [];
    this.selectedCopyToTeam = null;

    const request = new JM.JMRequestTeamsTeamSummary();
    request.workCentreList = [this.selectedWorkCentre];
    request.active = JMENUM.RequestActive.ACTIVE;
    request.isRoTeam = false;
    request.includeSummary = true;
    request.pageSize = 100;
    request.pageNumber = 1;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.copyTeamList = response.payload.records.filter(team => {
      return team._id !== this.selectedTeam._id && team.workCentre === this.selectedTeam.workCentre && !team.isRoTeam
    });

    if (this.copyTeamList.length > 0) {
      this.selectedCopyToTeam = this.copyTeamList[0]._id.toString();
    }
  }

  //---------------------------------------------------------------------------
  // event functions
  onDroppedToInstructionGroup(event: CdkDragDrop<string[]>, instructionGroupList) {
    if (event.previousContainer.id === this.INSTRUCTION_CONFIG_OPTION_LIST_ID) {
      let inputType = this.instructionConfigOptionList[0].inputType;
      let remarks   = this.instructionConfigOptionList[0].remarks;
      
      if (this.isInvalidRemark(inputType, remarks)) {
        return;
      }
    }

    // Move item in same list
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      return;
    }

    // remove object reference
    let currentDataList = event.previousContainer.data.map(object => {
      let newData = Object.assign({}, object);
      if (newData['remarks']) {
        newData['remarks'] = newData['remarks'].trim();
      }
      return newData;
    });
    let targetDataList = event.container.data;
    
    if (currentDataList.length > 0 && targetDataList.length > 0) {
      // check group type instruction
      if (currentDataList[0]['groupType'] !== targetDataList[0]['groupType'] || targetDataList[0]['groupType'] === null) {
        this.openSnackBar(this.translate("pages.team-setting.warning.cannot-drop-into-group"));
        return;
      }
    }
    
    if (targetDataList.length >= 5) {
      this.openSnackBar(this.translate("pages.team-setting.over-maximum-instruction"));
      return;
    }

    // check duplicate remark
    if (currentDataList && currentDataList.length > 0) {
      let sourceInstruction = currentDataList[event.previousIndex];

      if (this.isDuplicateRemark(instructionGroupList, sourceInstruction)) {
        return;
      }
    }

    // Copy item to another list
    copyArrayItem(
      currentDataList,
      targetDataList,
      event.previousIndex,
      event.currentIndex
    );
  }

  onDroppedToNewInstructionGroup(event: CdkDragDrop<string[]>, instructionGroupList) {
    if (event.previousContainer.id === this.INSTRUCTION_CONFIG_OPTION_LIST_ID) {
      let remarks   = this.instructionConfigOptionList[0].remarks;
      let inputType = this.instructionConfigOptionList[0].inputType;
      
      if (this.isInvalidRemark(inputType, remarks)) {
        return;
      }
    }

    // remove object reference
    let currentDataList = event.previousContainer.data.map(object => {
      let newData = Object.assign({}, object);
      if (newData['remarks']) {
        newData['remarks'] = newData['remarks'].trim();
      }
      return newData;
    });

    if (instructionGroupList.length >= 5) {
      this.openSnackBar(this.translate("pages.team-setting.over-maximum-instruction"));
      return;
    }

    // check duplicate remark
    if (currentDataList && currentDataList.length > 0) {
      let sourceInstruction = currentDataList[event.previousIndex];

      if (this.isDuplicateRemark(instructionGroupList, sourceInstruction)) {
        return;
      }
    }

    // copy group list
    instructionGroupList.push(currentDataList);
    this.updateDropConnectedList();
  }

  onInstructionConfigChanged(event) {
    let remarks = this.instructionConfigOptionList[0].remarks;

    this.instructionConfigOptionList[0] = JSON.parse(JSON.stringify(event));
    this.instructionConfigOptionList[0].remarks = remarks ? remarks : '';

    // 0: No need to input
    if (event.inputType == 0) {
      this.instructionConfigOptionList[0].remarks = '';
    }
  }

  onRemoveInstructionClicked(instructionGroupList, instructionGroupIndex, instructionList, instructionIndex) {
    if (instructionList.length === 1) { // remove last instruction
      instructionGroupList.splice(instructionGroupIndex, 1);
    } else {
      instructionList.splice(instructionIndex, 1);
    }
    
    this.updateDropConnectedList();
  }

  onCopyTeamBtnClicked() {
    let message = this.translate("pages.team-settings.popup.copy");
    this.popupConfirmAlert(message, () => this.requestCopyTeam());
  }

  onSaveBtnClicked() {
    let message = this.translate("pages.team-settings.popup.save");
    this.popupConfirmAlert(message, () => this.requestUpdateTeam());
  }

  onClearBtnClicked() {
    for (const priorityItem of this.instructionViewData) {
      for (const stateItem of priorityItem.stateItems) {
        stateItem.instructionGroupList = [];
      }
    }

    this.updateDropConnectedList();
  }

  async onOfficeHoursBtnClicked() {
    const request = new JM.JMRequestTeamsTeamSummary();
    request.idList = [this.selectedTeam._id.toString()];
    request.includeSummary = true;
    
    if (this.isContractor(this.selectedTeam.handlingParty) && this.selectedTeam.handlingTeam) {
      request.idList.push(this.selectedTeam.handlingTeam.toString());
    }
    
    this.isLoadingOfficeHours = true;
    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingOfficeHours = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (this.isInhouse(this.selectedTeam.handlingParty)) {
      if (response.payload.records.length === 1) {
        this.openOfficeHoursModal(response.payload.records[0], null);
      }
    } else if (this.isContractor(this.selectedTeam.handlingParty)) {
      if (this.selectedTeam.handlingTeam && response.payload.records.length === 2) {
        const foundHandlingTeam = response.payload.records.find(t => t._id == this.selectedTeam.handlingTeam);
        const foundContractorTeam = response.payload.records.find(t => t._id == this.selectedTeam._id);

        this.openOfficeHoursModal(foundHandlingTeam, foundContractorTeam);
      } else if (!this.selectedTeam.handlingTeam && response.payload.records.length === 1) {
        const foundContractorTeam = response.payload.records.find(t => t._id == this.selectedTeam._id);

        this.openOfficeHoursModal(null, foundContractorTeam);
      }
    }
  }

  onTeamChanged = async (team:JMOBJ.Team) => {
    // get team detail
    const request = new JM.JMRequestTeamsTeamSummary();
    request.idList = [team._id];
    request.includeSummary = true;
    request.pageSize = 1;
    request.pageNumber = 1;

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || response.payload.records.length <= 0) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.selectedTeam         = response.payload.records[0];
    this.hasEditionPermission = this.authorizationService.hasPermission(Permission.wcmUpdate) && this.selectedTeam.workCentre === this.selectedWorkCentre;
    this.selectedCopyToTeam   = null;
    this.copyTeamErrorMessage = null;

    Session.setTeamSettingsSelectedTeamId(team._id.toString());
    if (team.handlingParty == JMENUM.HandlingParty.INHOUSE) {
      this.uiMode = "inhouse";
      this.contractorTeamSelectorView.deselectTeam();
      
    } else {
      this.uiMode = "contractor";
      this.inhouseTeamSelectorView.deselectTeam();
      
    }
    
    this.getManualInstructionConfig();
    this.bindInstructionSectionViewData();
    this.getCopyTeamList();
    this.updateDropConnectedList();
  }

  onCopyPriorityInstructionGroupClicked(fromPriority, toPriority) {
    const fromPriorityItemIndex = this.instructionViewData.findIndex(priorityItem => priorityItem.priorityKey === fromPriority);
    const toPriorityItemIndex   = this.instructionViewData.findIndex(priorityItem => priorityItem.priorityKey === toPriority);
    const fromPriorityData      = this.instructionViewData[fromPriorityItemIndex];
    const toPriorityData        = this.instructionViewData[toPriorityItemIndex];
    let   message               = this.translate("pages.team-settings.popup.copy");

    this.popupConfirmAlert(message, () => {
      if (fromPriorityData && fromPriorityData) {
        toPriorityData.stateItems   = JSON.parse(JSON.stringify(fromPriorityData.stateItems));
        this.updateDropConnectedList();
      } else {
        console.error(`Copy priority instruction error`);
      }
    });
  }

  // TODO: reload language
  onLanguageChanged() {
    // this.requestManualInstructions();
    // this.team_selector.loadData();
  }

  
  //---------------------------------------------------------------------------
  // ui functions
  private formatInstructionList(instructionList) {
    return instructionList.map(item => {
      return {
        manualInstructionId: item.manualInstructionId,
        remarks            : item.remarks ? item.remarks: ''
      };
    });
  }

  private popupConfirmAlert(message: string, yesHandler: Function, noHandler?: Function) {
    if (!noHandler) { noHandler = () => { }; }

    let buttons = [
      { name: this.translate("global.yes"), handler: yesHandler },
      { name: this.translate("global.no"), handler: noHandler }
    ]
    this.showPopUpAlert(message, "", buttons);
  }

  private bindInstructionSectionViewData() {
    if (this.selectedTeam) {
      this.enableManualInstruction = this.selectedTeam.enableManualInstructions != null ? this.selectedTeam.enableManualInstructions : false;

      this.initInstrctionSectionView();
      this.convertInstructionData();
    }
  }

  private convertInstructionData() {
    let teamManualInstructions = this.selectedTeam.manualInstructions;

    const priorityArray = ['1', '2', '3'];
    const stateArray = (this.isInhouse(this.selectedTeam.handlingParty)) ? ['preAction', 'roNoResponse'] : ['preAction', 'contractorNoResponse','roNoResponse']; // true: inhouse, false: contructor

    // loop team manual instructions -> add manual instruction config and remarks to init view data (instructionViewData) 
    if (teamManualInstructions) { // refer Wiki > [CCeP Micro-service] Contact Group Service > Example of contact manualInstructions
      for (let priority of priorityArray) {
        for (let state of stateArray) {
          const instructionGroupArray = teamManualInstructions[priority][state];
          let newInstructionGroupArray = [];

          for (let groupIndex = 0; groupIndex < instructionGroupArray.length; groupIndex++) {
            const instructionsArray = instructionGroupArray[groupIndex];
            let newInstructionArray = [];

            for (const instruction of instructionsArray) {
              const config = this.instructionConfigDict[instruction.manualInstructionId];
              
              if (config) {
                newInstructionArray.push({
                  manualInstructionId: config.manualInstructionId,
                  description        : config.description,
                  inputType          : config.inputType,
                  groupType          : config.groupType,
                  handlingParty      : config.handlingParty,
                  workhourType       : config.workhourType,
                  remarks            : instruction.remarks ? instruction.remarks : ''
                })
              }
            }
            newInstructionGroupArray.push(newInstructionArray);
          }

          // add instruction group array to init view data
          const foundPriorityItemIndex = this.instructionViewData.findIndex(priorityItem => priorityItem.priorityKey === priority);
          const priorityData = this.instructionViewData[foundPriorityItemIndex];

          if (priorityData) {
            const foundStateItemIndex = priorityData.stateItems.findIndex(stateItem => stateItem.stateKey == state);
            const stateData = priorityData.stateItems[foundStateItemIndex];
            
            if (stateData) {
              stateData.instructionGroupList = newInstructionGroupArray;
            }
          }
        }
      }
    }
  }

  private openOfficeHoursModal(inhouseTeam?, contractorTeam?) {
    let modalRef = this.modalService.open(
      ModalOfficeHoursComponent,
      { 
        backdrop: 'static',
        centered: true,
        size: 'lg'
      }
    );

    if (inhouseTeam) {
      modalRef.componentInstance.inhouseTeam = inhouseTeam;
    }

    if (contractorTeam) {
      modalRef.componentInstance.contractorTeam = contractorTeam;
    }
  }

  private setRequestBtnDisabled(isDisabled: boolean) {
    this.requestButtonDisabled = isDisabled;
  }

  // update drag and drop list
  private updateDropConnectedList() {
    this.dropConnectedList = [];

    // loop the instructionViewData
    for (const priorityItem of this.instructionViewData) {
      for (const stateItem of priorityItem.stateItems) {
        for (let i = 0; i < stateItem.instructionGroupList.length; i++) {
          this.dropConnectedList.push(this.generateGroupId(priorityItem.id, stateItem.id, i));
        }
        this.dropConnectedList.push(this.generateGroupId(priorityItem.id, stateItem.id));
      }
    }
  }

  generateGroupId(priorityItemId: string, stateItemId: string, groupListIndex?: number): string {
    const text = `${priorityItemId}-${stateItemId}`;
    return groupListIndex === undefined ? `${text}-new` : `${text}-${(groupListIndex + 1)}`;
  }

  isInhouse(handlingParty: JMENUM.HandlingParty): boolean {
    return handlingParty == JMENUM.HandlingParty.INHOUSE;
  }

  isContractor(handlingParty: JMENUM.HandlingParty): boolean {
    return handlingParty == JMENUM.HandlingParty.PMSMC || handlingParty == JMENUM.HandlingParty.NON_PMSMC;
  }

  // check remark base on input type
  private isInvalidRemark(inputType: number, remark: string): boolean {
    // 0: No need to input, 1: Free text, 2: Number, 3: Email
    switch (inputType) {
      case 1:
        if (!remark || remark.trim().length === 0) {
          this.openSnackBar(this.translate("pages.team-setting.input-contact-information"));
          return true;
        }
        break;
      case 2:
        if (!remark || (remark && isNumber(remark))) {
          this.openSnackBar(this.translate("pages.team-setting.invalid-number"));
          return true;
        }
        break;
      case 3:
        if (!remark || (remark && isEmailAdress(remark))) {
          this.openSnackBar(this.translate("pages.team-setting.invalid-phone-number"));
          return true;
        }
        break;
    }

    return false;
  }

  // check duplicat remark in same group or stage
  private isDuplicateRemark(targetInstructionGroupList, sourceInstruction): boolean {
    let contentSet = new Set<string>();

    for (const instructionList of targetInstructionGroupList) {
      for (const instruction of instructionList) {
        if (instruction.remarks) {
          contentSet.add(this.getInstructionContent(instruction.manualInstructionId, instruction.remarks));
        }
      }
    }

    const contentArray = Array.from(contentSet);
    const sourceContent = this.getInstructionContent(sourceInstruction.manualInstructionId, sourceInstruction.remarks);

    if (contentArray.includes(sourceContent)) {
      return true;
    }

    return false;
  }

  private getInstructionContent(manualInstructionId: number, remarks: string): string {
    return `${manualInstructionId}-${remarks.trim().toLowerCase()}`;
  } 
}
