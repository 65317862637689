import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { isTimeStringValidator } from 'src/app/presenter/validators/validator';
import { AppDelegate } from 'src/app/AppDelegate';
import { pad } from 'src/lib/presenter/Formatter';
import { JM } from '@ccep/CCEPConnector-ts';
import { inputNumber } from 'src/lib/presenter/Formatter';
import { inputNumberDigit } from 'src/lib/presenter/Formatter';
import * as moment from 'moment';
@Component({
  selector: 'app-timesheet-input-form',
  templateUrl: './timesheet-input-form.component.html',
  styleUrls: ['./timesheet-input-form.component.scss']
})
export class TimesheetInputFormComponent implements OnInit {
  TimeSheetActionMode = TimeSheetActionMode
  inputNumber = inputNumber;
  inputNumberDigit = inputNumberDigit;
  timeSheetInputs: DateRow[] = []
  timeAlertMsg: string = JMLanguage.translate("global.invalid-time");
  officer: JM.JMOBJ.JobTimesheetOfficer = {
    post: undefined,
    postName: undefined,
    personnelNumber: undefined,
    employeeName: undefined,
    activityType: undefined,
    taskNumber: undefined,
    costCentre: undefined,
    workingDays: undefined,
  }
  initOfficer: any
  mode: TimeSheetActionMode = TimeSheetActionMode.VIEW;
  isLoading: boolean = false
  isClosing: boolean = false
  prevTimeSheets: DateRow[] = []

  constructor(
    public dialogRef: MatDialogRef<TimesheetInputFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.initOfficer = data.initOfficer;
    this.officer = {
      ...this.officer,
      post: data.post,
      postName: data.postName,
      employeeName: data.employeeName,
      personnelNumber: data.personnelNumber,
      taskNumber: data.taskNumber,
      workingDays: data.workingDays,
    }
    this.officer['latestMoment'] = data.latestMoment
    this.officer['earliestMoment'] = data.earliestMoment
    this.mode = data.viewOnly ? TimeSheetActionMode.VIEW : TimeSheetActionMode.EDIT
  }

  ngOnInit() {
    this.initData(this.officer.workingDays);
  }

  insertPeriod(inputDateRow: DateRow) {
    /* latestMoment earliestMoment check */
    let startMoment = moment(inputDateRow.date + " " + inputDateRow.periods[0].startTime, "YYYY-MM-DD HH:mm");
    let endMoment = moment(inputDateRow.date + " " + inputDateRow.periods[0].endTime, "YYYY-MM-DD HH:mm");
    if ((this.officer['earliestMoment'] && startMoment < this.officer['earliestMoment']) || (this.officer['latestMoment'] && endMoment > this.officer['latestMoment'])) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.timesheet.error.drag-exist-range"), 'show-white-space-pre-wrap')
      return
    }
    /* latestMoment earliestMoment check */
    const periods: Period[] = this.getPeriodByDate(inputDateRow).periods
    const _timeSheetInput: DateRow = this.getTimeSheetByDate(inputDateRow.date);
    let insertAction: InsertAction = InsertAction.ADD
    for (let i = 0; i < periods.length; i++) {
      if (this.isPeriodValidForMerge(inputDateRow.periods[0])) {
        /* edit existing Period */
        const save = () => {
          /* special handling avoid no row */
          if(periods.length == 0){
            periods.splice(1, 0, {
              ...this.getNewPeriod(),
            });
          }
          /* special handling avoid no row */
          this.sortPeriods(periods)
          this.updatePrevTimeSheet();
          this.timeSheetInputs = this.timeSheetInputs.concat();
          insertAction = InsertAction.REMOVE
        }

        if (moment(inputDateRow.periods[0].startTime, 'hh:mm').isSame(moment(periods[i].startTime, 'hh:mm')) && moment(inputDateRow.periods[0].endTime, 'hh:mm').isSame(moment(periods[i].endTime, 'hh:mm'))) {
          periods.splice(i,1);
          save();
          break
        }

        if (moment(inputDateRow.periods[0].startTime, 'hh:mm').isSame(moment(periods[i].startTime, 'hh:mm')) && !moment(inputDateRow.periods[0].endTime, 'hh:mm').isSame(moment(periods[i].endTime, 'hh:mm'))) {
          periods[i].startTime = inputDateRow.periods[0].endTime
          save();
          break
        }

        if (!moment(inputDateRow.periods[0].startTime, 'hh:mm').isSame(moment(periods[i].startTime, 'hh:mm')) && moment(inputDateRow.periods[0].endTime, 'hh:mm').isSame(moment(periods[i].endTime, 'hh:mm'))) {
          periods[i].endTime = inputDateRow.periods[0].startTime
          save();
          break
        }

        if (moment(inputDateRow.periods[0].startTime, 'hh:mm').isAfter(moment(periods[i].startTime, 'hh:mm')) && moment(inputDateRow.periods[0].endTime, 'hh:mm').isBefore(moment(periods[i].endTime, 'hh:mm'))) {
          const originalEndTime = periods[i].endTime
          periods[i].endTime = inputDateRow.periods[0].startTime
          periods.splice(1, 0, {
            ...this.getNewPeriod(),
            startTime: inputDateRow.periods[0].endTime,
            endTime: originalEndTime
          });
          save();
          break
        }
        /* edit existing Period */
      }
    }
    /*add new Period*/
    if (insertAction === InsertAction.ADD) {
      /* special handling avoid 1 extra empty row */
      if(periods.length == 1){
        if(periods[0].endTime == undefined && periods[0].startTime == undefined){
          periods.splice(0,1);
        }   
      }
      /* special handling avoid 1 extra empty row */
      const newPeriodLength = periods.push({
        ...this.getNewPeriod(),
        startTime: inputDateRow.periods[0].startTime,
        endTime: inputDateRow.periods[0].endTime,
      })
      const newPeriodIndex = newPeriodLength - 1
      this.mergeTimePeriod(_timeSheetInput, newPeriodIndex, true);
    }
    /*add new Period*/

  }

  getPeriodByDate = (input: DateRow) => {
    for (const element of this.timeSheetInputs) {
      if (element.date === input.date) {
        return element
      }
    }
  }

  startTimeHandler(timeString: string) {
    let minute = timeString.substring(2, 4);
    let hour = timeString.substring(0, 2);

    if (parseInt(minute) <= 14 && parseInt(minute) >= 0) {
      minute = "00"
      return hour + minute
    } else if (parseInt(minute) <= 29 && parseInt(minute) >= 15) {
      minute = "15"
      return hour + minute
    } else if (parseInt(minute) <= 44 && parseInt(minute) >= 30) {
      minute = "30"
      return hour + minute
    } else if (parseInt(minute) <= 59 && parseInt(minute) >= 45) {
      minute = "45"
      return hour + minute
    }
    return hour + minute;
  }

  endTimeHandler(timeString: string) {
    let minute = timeString.substring(2, 4);
    let hour = timeString.substring(0, 2);

    if (timeString == "2400") {
      return timeString
    }

    if (parseInt(minute) == 0 || parseInt(minute) == 15 || parseInt(minute) == 30 || parseInt(minute) == 45) {
      return timeString
    }

    if (parseInt(minute) <= 14 && parseInt(minute) >= 1) {
      minute = "15"
      return hour + minute
    }

    if (parseInt(minute) <= 29 && parseInt(minute) >= 16) {
      minute = "30"
      return hour + minute
    }

    if (parseInt(minute) <= 44 && parseInt(minute) >= 31) {
      minute = "45"
      return hour + minute
    }

    if (parseInt(minute) <= 59 && parseInt(minute) >= 46) {
      minute = "00"
      let outputHour = (parseInt(hour) + 1).toString();

      if (outputHour.length == 1) { //9 -> 09
        outputHour = pad(outputHour.substring(0, 1));
      }

      return outputHour + minute
    }

    return hour + minute;
  }

  padTime(timeString: string) {
    let hour = ""
    const minute = timeString.slice(-2);

    switch (timeString.length) {
      case 2:
        hour = "00"
        break;
      case 3:
        hour = pad(timeString.substring(0, 1));
        break;
      case 4:
        hour = timeString.slice(0, 2);
        break;
      default:
        break;
    }

    return hour + minute
  }

  formatTime(timeString: string) {
    let minute = timeString.substring(2, 4);
    let hour = timeString.substring(0, 2);
    return hour + ":" + minute
  }

  getPeriodProperties(periodProperties: string) {
    switch (periodProperties) {
      case PeriodProperties.STARTTIME:
        return PeriodProperties.STARTTIME
      case PeriodProperties.ENDTIME:
        return PeriodProperties.ENDTIME
    }
  }

  inputHandler = (event: any): Event => {
    return event.replace(/[^\d]/g, '')
  }

  isPeriodValid(period: Period) {
    let isValid = true
    period.errorMessages = []
    period.isStartTimeError = false
    period.isEndTimeError = false

    if (this.isStartTimeEqualToEndTime(period)) {
      period.errorMessages.push(JMLanguage.translate("component.timesheet.start-time-can-not-be-equal-to-end-time-for-every-row"));
      period.isStartTimeError = true
      period.isEndTimeError = true
      isValid = false
      return isValid
    }

    if (this.isStartTimeGreaterThanEndTime(period)) {
      period.errorMessages.push(JMLanguage.translate("component.timesheet.error-end-time-must-be-larger-than-start-time"));
      period.isStartTimeError = true
      isValid = false
      return isValid
    }

    return isValid
  }

  buildTimeString(time, timeHandler) {
    let regex = "^([01]?[0-9]|2[0-3])([0-5][0-9]|[0-9])$";
    let timeString = time.replaceAll(":", "");

    if (!timeString || timeString.length <= 0) {
      time = undefined;
      return time
    }
    if (timeString.length <= 2 && timeString <= 24) {
      timeString = pad(timeString.substring(0, 2)) + '00';
    } else if (timeString.length <= 2 && timeString > 24) {
      return time;
    }
    if (timeString.length == 3) {
      timeString = pad(timeString.substring(0, 1)) + pad(timeString.substring(1, 3));
    }
    if (timeString.length == 5) {
      timeString = timeString.substring(0, 4);
    }
    if (timeString == '2400' || timeString.match(regex)) {

      timeString = timeHandler(timeString)

      return timeString.substring(0, 2) + ':' + timeString.substring(2, 4);

    } else {
      return timeString
    }
  }

  onTimeChanged(event, _timeSheetInput: DateRow, index: number, periodProperties: string, timeHandler) {
    let data = event.target.value

    if (data == "" || data == undefined) { // remove
      this.updatePeriod(undefined, _timeSheetInput, index, this.getPeriodProperties(periodProperties))
      this.updatePrevTimeSheet();
      return
    }

    data = this.buildTimeString(data, timeHandler)

    const errorMessage = data != undefined ? isTimeStringValidator(this.timeAlertMsg)(data) : this.timeAlertMsg;
    if (errorMessage != '') {
      AppDelegate.openSnackBar(errorMessage)
      this.fallBack();
      return
    }

    this.updatePeriod(data, _timeSheetInput, index, this.getPeriodProperties(periodProperties))
    /* latestMoment earliestMoment check */
    switch (this.getPeriodProperties(periodProperties)) {
      case PeriodProperties.STARTTIME:
        let startTime: string = _timeSheetInput.periods[index].startTime;
        let startMoment = moment(_timeSheetInput.date + " " + startTime, "YYYY-MM-DD HH:mm");
        if (this.officer['earliestMoment'] && startMoment < this.officer['earliestMoment']) {
          let timeValue = this.officer['earliestMoment'].hour() + this.officer['earliestMoment'].minute() / 60.0;
          this.updatePeriod(this.valueToTimeFormat(timeValue), _timeSheetInput, index, this.getPeriodProperties(periodProperties));
          this.updatePrevTimeSheet();
          AppDelegate.openSnackBar(JMLanguage.translate("pages.timesheet.v2.start-time-max"))
        }
        break;
      case PeriodProperties.ENDTIME:
        let endTime: string = _timeSheetInput.periods[index].endTime;
        let endMoment = moment(_timeSheetInput.date + " " + endTime, "YYYY-MM-DD HH:mm");
        if (this.officer['latestMoment'] && endMoment > this.officer['latestMoment']) {
          let timeValue = this.officer['latestMoment'].hour() + this.officer['latestMoment'].minute() / 60.0;
          this.updatePeriod(this.valueToTimeFormat(timeValue), _timeSheetInput, index, this.getPeriodProperties(periodProperties));
          this.updatePrevTimeSheet();
          AppDelegate.openSnackBar(JMLanguage.translate("pages.timesheet.v2.end-time-max"))
        }
        break;
      default:
        break;
    }
   /* latestMoment earliestMoment check */
    if (!this.isPeriodNull(_timeSheetInput.periods[index])) {
      const isPeriodValid = this.isPeriodValid(_timeSheetInput.periods[index]);
      if (!isPeriodValid) {
        this.updatePrevTimeSheet();
        return
      }
    }
    
    this.mergeTimePeriod(_timeSheetInput, index);
  }

  mergeTimePeriod(_timeSheetInput: DateRow, index: number, isSort:boolean = false) {
    const isReadyforMerge = this.isPeriodValidForMerge(_timeSheetInput.periods[index])
    if (!isReadyforMerge) {
      this.updatePrevTimeSheet();
      this.timeSheetInputs = this.timeSheetInputs.concat();
      return
    }

    if (!this.hasOverLap(_timeSheetInput.periods, index, _timeSheetInput.periods[index])) {
      isSort && this.sortPeriods(_timeSheetInput.periods) // sort for table drag only
      this.updatePrevTimeSheet();
      this.timeSheetInputs = this.timeSheetInputs.concat();
      return
    }

    let buttons = [
      {
        name: (JMLanguage.translate("global.yes")),
        handler: () => {
          let resultPeroid = undefined;
          resultPeroid = this.mergeTimeInterval(_timeSheetInput, index);
          this.timeSheetInputs.forEach(timeSheetInput => {
            if (timeSheetInput.date == _timeSheetInput.date) {
              timeSheetInput.periods = resultPeroid
            }
          })
          this.sortPeriods(_timeSheetInput.periods)
          this.updatePrevTimeSheet();
          this.timeSheetInputs = this.timeSheetInputs.concat();
        }
      },
      {
        name: (JMLanguage.translate("global.no")),
        handler: () => {
          this.fallBack()
        }
      }
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("component.timesheet-overlap.title"), "", buttons);
  }

  private valueToTimeFormat(value: number): string {
    return Math.floor(value).toString().padStart(2, '0') + ":" + ((value % 1) * 60).toString().padStart(2, '0');
  }

  updateTaskNumber(event) {
    let data = event.target.value
    this.officer = {
      ...this.officer,
      taskNumber: data ? data : undefined
    }
  }

  updatePeriod(value: string, timeSheetInput: DateRow, periodIndex: number, periodProperties: PeriodProperties) {
    timeSheetInput.periods[periodIndex][periodProperties] = value
    this.timeSheetInputs = this.timeSheetInputs.concat();
  }

  fallBack() {
    const replacer = (key, value) =>
      typeof value === 'undefined' ? null : value;
    this.timeSheetInputs = JSON.parse(JSON.stringify(this.prevTimeSheets, replacer));
  }

  inputNumberWithIndex(event: any, timeSheetInput: DateRow, periodIndex: number, periodProperties: PeriodProperties) {
    let phasedInput = event.target.value.replace(/[^\d:]/g, '')
    if (phasedInput == '') {
      phasedInput = undefined
    }
    return
  }

  periodTrackBy(index: number, obj: any): any {
    return index
  }

  timeSheetInputTrackBy(index: number, obj: any): any {
    return index
  }

  initData(workingDays: JM.JMOBJ.JobTimesheetWorkDay[]) {
    const reformattedArray = workingDays.map((workingDay) => {
      return {
        date: workingDay.postDate,
        weekday: JMLanguage.translate(workingDay['weekday']),
        periods: workingDay.periods.length > 0 ? workingDay.periods.map((period) => {
          return {
            ...this.getNewPeriod(),
            ...period,
            startTime: period.startTime == null ? undefined : period.startTime,
            endTime: period.endTime == null ? undefined : period.endTime,
          }
        }) : [this.getNewPeriod()]
      }
    })

    this.timeSheetInputs = reformattedArray as DateRow[]
    this.updatePrevTimeSheet();

  };

  updatePrevTimeSheet() {
    const replacer = (key, value) =>
      typeof value === 'undefined' ? null : value;
    this.prevTimeSheets = JSON.parse(JSON.stringify(this.timeSheetInputs, replacer));
  }

  onAddDateRowClicked(timeSheetInput: DateRow) {
    this.addInput(timeSheetInput)
    this.updatePrevTimeSheet();
  }

  onResetButtonClicked() {
    let buttons = [
      {
        name: (JMLanguage.translate("global.yes")),
        handler: () => {
          this.initData(this.officer.workingDays);
        }
      },
      { name: (JMLanguage.translate("global.no")) }
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("component.timesheet-reset.title"), "", buttons);
  }

  isPeriodValidForMerge(period: Period) {

    if (this.isPeriodNull(period)) {
      return false
    }

    if (this.isStartTimeGreaterThanEndTime(period)) {
      return false
    }

    return true

  }

  getTimeSheetByDate(date: string) {
    return this.timeSheetInputs.find(timeSheetInput => timeSheetInput.date === date)
  }

  isPeriodNull(period: Period) {
    return period.endTime == undefined || period.endTime == '' || period.startTime == undefined || period.startTime == ''
  }

  isStartTimeEndTimeNull(period: Period) {
    return (period.endTime == undefined || period.endTime == '') && (period.startTime == undefined || period.startTime == '')
  }

  isTimeEmpty(time: string) {
    return time == undefined || time == ''
  }

  isStartTimeGreaterThanEndTime(period: Period) {
    return moment(period.startTime, 'hh:mm').isAfter(moment(period.endTime, 'hh:mm'))
  }

  isStartTimeEqualToEndTime(period: Period) {
    return moment(period.startTime, 'hh:mm').isSame(moment(period.endTime, 'hh:mm'))
  }

  hasOverLap(sourcePeriod: Period[], index: number, currentPeriod: Period) {
    for (let i = 0; i < sourcePeriod.length; i++) {
      if (this.isPeriodValidForMerge(sourcePeriod[i])) {
        if (i != index) {
          let localPeriods: Period[] = new Array(2);
          localPeriods.push(sourcePeriod[i]);
          localPeriods.push(currentPeriod);
          this.sortPeriods(localPeriods);
          if (moment(localPeriods[0].endTime, 'hh:mm').isSameOrAfter(moment(localPeriods[1].startTime, 'hh:mm'))) {//has overlap
            return true
          }
        }
      }
    }
    return false
  }

  mergePeriod(sourcePeriod: Period[], index: number, currentPeriod: Period) {
    let periodIndexToBeRemoved = new Set()
    for (let i = 0; i < sourcePeriod.length; i++) {
      if (this.isStartTimeEndTimeNull(sourcePeriod[i])) {
        periodIndexToBeRemoved.add(i)
      }
      if (this.isPeriodValidForMerge(sourcePeriod[i])) {
        if (i != index) {
          let localPeriods: Period[] = new Array(2);
          localPeriods.push(sourcePeriod[i]);
          localPeriods.push(currentPeriod);
          this.sortPeriods(localPeriods);
          if (moment(localPeriods[0].endTime, 'hh:mm').isSameOrAfter(moment(localPeriods[1].startTime, 'hh:mm'))) {//has overlap
            let targetStart = localPeriods[0].startTime
            let targetEnd = Math.max(this.parseTimetoInt(sourcePeriod[index].endTime), this.parseTimetoInt(sourcePeriod[i].endTime)).toString()

            sourcePeriod[i].startTime = undefined // set undefined for the origanal start & end time after merge
            sourcePeriod[i].endTime = undefined
            periodIndexToBeRemoved.add(i)

            sourcePeriod[index].startTime = targetStart //set result
            sourcePeriod[index].endTime = this.formatTime(this.padTime(targetEnd))

            currentPeriod.startTime = targetStart //for next comparsion
            currentPeriod.endTime = this.formatTime(this.padTime(targetEnd))
          }
        }
      }
    }
    sourcePeriod = this.getMergedPeriods(sourcePeriod, periodIndexToBeRemoved)
    return sourcePeriod
  }

  getMergedPeriods(sourcePeriod: Period[], periodIndex: any) {
    let mergedPeriods: Period[] = []
    for (let i = 0; i < sourcePeriod.length; i++) {
      if (!periodIndex.has(i)) {
        mergedPeriods.push(sourcePeriod[i])
      }
    }
    return mergedPeriods
  }

  mergeTimeInterval(timeSheetInput: DateRow, index: number) {
    let currentPeriod = timeSheetInput.periods[index]
    return this.mergePeriod(timeSheetInput.periods, index, currentPeriod)
  }

  sortPeriods(periods: Period[]) {
    periods.sort((a, b) => {
      if (a.startTime === undefined) return 1;
      if (b.startTime === undefined) return -1;
      return this.parseTimetoInt(a.startTime) - this.parseTimetoInt(b.startTime)
    })
  }

  addInput(timeSheetInput: DateRow) {
    timeSheetInput.periods.push(this.getNewPeriod())
  }

  parseTimetoInt(timeString: string) {
    return parseInt(timeString.replace(":", ''))
  }

  getNewPeriod() {
    return {
      startTime: undefined,
      endTime: undefined,
      errorMessages: [],
      isStartTimeError: false,
      isEndTimeError: false
    }
  }

  onSaveBtnClicked() {

    if (!this.periodsNotEmpty()) {
      return
    }

    if (!this.periodsLogicallyCorrect()) {
      return
    }

    this.removeExcessEmptyPeriod();

    const outputData: TimeSheetOutput = {
      workingDays: undefined,
      post: undefined,
      personnelNumber: undefined,
      taskNumber: undefined,
    }

    outputData.workingDays = this.timeSheetInputs.map((timeSheetInput) => {
      return {
        postDate: timeSheetInput.date,
        periods: timeSheetInput.periods.filter((period) => period.endTime != undefined && period.startTime != undefined).map((period) => {
          return {
            ...period,
            startTime: period.startTime,
            endTime: period.endTime,
          }
        }).sort((a, b) => this.parseTimetoInt(a.startTime) - this.parseTimetoInt(b.startTime))
      }
    })

    outputData.post = this.officer.post;
    outputData.personnelNumber = this.officer.personnelNumber;
    outputData.taskNumber = this.officer.taskNumber != "" ? this.officer.taskNumber : this.data.taskNumber;
    this.dialogRef.close(outputData);

  }

  periodsNotEmpty() {
    let isAllValid = true;
    this.timeSheetInputs.forEach((timeSheetInput) => {
      for (const period of timeSheetInput.periods) {
        period.isStartTimeError = false
        period.isEndTimeError = false
        period.errorMessages = []
        if (this.isPeriodNull(period)) {
          if (this.isTimeEmpty(period.startTime) && this.isTimeEmpty(period.endTime)) {
            continue;
          }

          if (this.isTimeEmpty(period.startTime)) {
            period.isStartTimeError = true
          }

          if (this.isTimeEmpty(period.endTime)) {
            period.isEndTimeError = true
          }

          if (this.isTimeEmpty(period.startTime) || this.isTimeEmpty(period.endTime)) {
            period.errorMessages.push(JMLanguage.translate("component.timesheet.start-or-end-time-can-not-be-empty"));
          }

          isAllValid = false;
        }
      }
    })
    return isAllValid
  }

  removeExcessEmptyPeriod() {

    this.timeSheetInputs.forEach((timeSheetInput) => {
      let tempPeriods = [];
      for (const period of timeSheetInput.periods) {
        if (!(period.endTime == undefined && period.startTime == undefined)) {
          tempPeriods.push(period)
        }
      }

      if (tempPeriods.length == 0) {
        tempPeriods.push(this.getNewPeriod())
      }

      timeSheetInput.periods = []

      tempPeriods.forEach((tempPeriod: Period) => {
        timeSheetInput.periods.push(tempPeriod)
      })

    })

  }

  periodsLogicallyCorrect() {
    let isAllValid = true;

    this.timeSheetInputs.forEach((timeSheetInput) => {
      for (const period of timeSheetInput.periods) {
        if (this.isTimeEmpty(period.startTime) && this.isTimeEmpty(period.endTime)) {
          continue;
        }
        if (!this.isPeriodValid(period)) {
          isAllValid = false
        }
      }
    })

    return isAllValid
  }

  onDirectCloseBtnClicked() {
    this.dialogRef.close();
  }

  onCloseBtnClicked() {
    let buttons = [
      {
        name: (JMLanguage.translate("global.close-without-save")),
        handler: () => {
          this.dialogRef.close();
        }
      },
      { name: (JMLanguage.translate("global.no")) }
    ];
    AppDelegate.showPopUpAlert(JMLanguage.translate("action.button.popup.close"), "", buttons);
  }

  selectPeriod(args: any) {
    const copyArgs = JSON.parse(JSON.stringify(args));
    if (copyArgs.date && copyArgs.periods) {
      copyArgs.periods.forEach((period) => {
        period.endTime = (moment(period.endTime).isAfter(moment(period.startTime)) && moment(period.endTime).format("HH:mm") === "00:00") ? "24:00" : moment(period.endTime).format("HH:mm");
        period.startTime = moment(period.startTime).format("HH:mm");
      })
      this.insertPeriod(copyArgs);
    }
  }

}

export interface TimeSheetOutput {
  workingDays: DateRowOutput[]
  post: string
  personnelNumber: string
  taskNumber: string
}

export interface DateRowOutput extends BaseDateRow {
  postDate: string
}

interface DateRow extends BaseDateRow {
  date: string
  weekday: string
}

interface BaseDateRow {
  periods: Period[]
}

interface Period {
  startTime: string
  endTime: string
  errorMessages?: string[]
  isStartTimeError?: boolean
  isEndTimeError?: boolean
}

enum PeriodProperties {
  STARTTIME = 'startTime',
  ENDTIME = 'endTime',
}

export enum TimeSheetActionMode {
  EDIT = 'edit',
  VIEW = 'view'
}

enum InsertAction {
  ADD = 'add',
  REMOVE = 'remove'
}