import { formatDate } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { ActionButtonDefinition, ActionButtonSn, ActionButtonType } from '@enum/action-button';
import { OperationLogType } from '@enum/operation-log-type';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Session } from 'src/app/services/session';
import { InformationDialogHelper } from 'src/app/ui/components/information-dialog/information-dialog-helper';
import { SliderPanelHelper } from 'src/app/ui/components/slider-panel/slider-panel-helper';
import { BasePage } from 'src/app/ui/model/base/base';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-sn-view-operation-log',
  templateUrl: './sn-view-operation-log.component.html',
  styleUrls: ['./sn-view-operation-log.component.scss']
})
export class SnViewOperationLogComponent extends BasePage implements OnInit {

  // @Input() sn: Sn;
  breadcrumbs: any = [];
  
  ///------------
  tablexParam: {} = {};  // isLoadingTable
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  tableRowAmount = 0;
  tableContentList: any;
  
  // cache
  operationLogDetailList: any = {};
  
  sliderPaneltitle: string;
  sliderPanelGroups: any[];

  snNumber: string;
  pageTitle: string;
  actionDefinition: any[] = [];
  actionButtonData = [];
  uiIsLoading: boolean = true;

  showSnHistoryOpCodeList = [
    'OP-106700', // SN re-routed
    'OP-101100', // SN in-house team submitted
    'OP-101102', // SN in-house team submitted with deferred notification
    'OP-101200', // SN PMSMC team submitted
    'OP-101202', // SN PMSMC team submitted with deferred notification
    'OP-101400', // SN Non-PMSMC team submitted
    'OP-101402'  // SN Non-PMSMC team submitted with deferred notification
  ]
  
  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.lang = Session.selectedLanguage; 

    this.snNumber = this.route.snapshot.paramMap.get('snNumber');
    this.breadcrumbs = [
      { id: 'breadcrumbs-sn-number', name: this.snNumber, route: '/sn/view/'+this.snNumber },
      { id: 'breadcrumbs-operation-log', name: "Operation Log", route: null , currentPage: true}
    ]; 
    this.initActionButton();
    // init tablex
    this.tablexParam = {
      isLoadingTable      : false,
      enableSetPageSize   : true,
      enablePagination    : true,
      pageSizeOptions     : [10, 25, 50],
      currentPageSize     : this.currentPageSize,
      currentPage         : this.currentPage,
      pageCount           : this.pageCount,
      onPageNumberClicked : this.onPageNumberClicked,
      onPageSizeClicked   : this.onPageSizeClicked,
      headers: [
        { name: 'pages.operation-log.action-date', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { name: 'pages.operation-log.action-officer', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { name: 'pages.operation-log.description', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Left, verticalAlign: TablexColumnVerticalAlign.Middle, class: "w-60" },
        { name: 'pages.operation-log.remarks', type: TablexColumnType.Buttons, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      ],
      content: [],
      tableRowDataContents: [],
    }

    this.requestOperationLogList();
  }

  public initActionButton(){
    this.actionButtonData = [];
    let actionButton = ActionButtonDefinition[ActionButtonType.sn][ActionButtonSn.back];
    let buttonHandler = () => {
      let path = "/sn/view/"+this.snNumber;
      this.router.navigate([path]);
    };
    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : "global.yes",
          handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : "global.no",
        handler: () => { this.uiIsLoading = false }
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  public onActionButtonClicked(actionButton: any) {
    actionButton.buttons[0].handler();
  }
  
  //---------------------------------------------------------------------------
  // view functions
  private renderTable() {
    this.tablexParam['pageCount'] = Math.ceil(this.tableRowAmount / this.currentPageSize);
    this.tablexParam['content'] = this.tableContentList.map(row => {
      let buttonsObjArray = [];
      if (row.type) {
        buttonsObjArray.push({
          "name"     : "",
          "id"       : "info",
          "class"    : "glyph brand-blue",
          "icon"     : "fas fa-info",
          "onClicked": this.onInformationButtonClicked,
          "object"   : row
        });
      }
      if (this.showSnHistoryButton(row.code)) {
        buttonsObjArray.push({
          "name"     : "",
          "id"       : "sn-history",
          "class"    : "glyph brand-blue",
          "icon"     : "fas fa-copy",
          "onClicked": this.onInformationButtonClicked,
          "object"   : row
        });
      }
      return [
        formatDate(row.createdAt, "dd/MM/yyyy HH:mm", 'en-US'),
        row.createdBy,
        row.message,
        buttonsObjArray
      ];
    });

    this.tablexParam['tableRowDataContent'] = this.tableContentList.map(row => {
      return row.code;
    });
  }

  private showSnHistoryButton(operationLogCode: string): boolean {
    return this.showSnHistoryOpCodeList.includes(operationLogCode);
  }

  //---------------------------------------------------------------------------
  // api function
  private async requestOperationLogList() {
    const request = new JM.JMRequestOperationLogsSummary();
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    this.snNumber && (request.snNumber = this.snNumber);

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseOperationLogsSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.tableContentList = response.payload.records;
    this.tableRowAmount = response.payload.totalCount;
    this.renderTable();
  }

  private async requestOperationLogDetail(logSummary: any, showSnHistory: boolean) {
    const request = new JM.JMRequestOperationLogsDetail();
    request.snNumber = this.snNumber;
    request.operationLogId = logSummary._id;

    this.operationLogDetailList[logSummary._id] = logSummary;

    const response: JM.JMResponseOperationLogsDetail = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    const operationLogId = request.operationLogId;
    if (this.operationLogDetailList[operationLogId]) {
      this.operationLogDetailList[operationLogId].payload = response.payload
      this.showOperationLogInformation(this.operationLogDetailList[operationLogId], showSnHistory);
    }
  }

  //-----------------------------------------------------------------------------
  // button call back function
  onInformationButtonClicked = (button) => {
    if (!button || !button.object) return;
    let object = button.object;
    let showSnHistory = (button.id === 'sn-history') ? true : false;

    if (this.operationLogDetailList[object._id]) { // have cache
      this.showOperationLogInformation(this.operationLogDetailList[object._id], showSnHistory);
    } else {
      this.requestOperationLogDetail(object, showSnHistory);
    }
  }

  //-----------------------------------------------------------------------------
  private getTablexParameters(headers: string[], content?: any[]): any {
    let tablexParam = {
      isLoadingTable   : false,
      enableSetPageSize: false,
      enablePagination : false,
      currentPage      : 1,
      pageCount        : 1,
      headers          : [],
      content          : []
    };

    tablexParam.headers = headers.map(header => {
      return {
        name           : header,
        type           : TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign  : TablexColumnVerticalAlign.Middle
      };
    });

    if (content) tablexParam.content = content;
    return tablexParam;
  }

  private showOperationLogInformation(detail, showSnHistory: boolean) {
    let payload = detail.payload;

    if (showSnHistory) {
        this.showSnHistoryInformation(payload);
    } else {
      switch (detail.type) {
        case OperationLogType.InHouseContact:
          this.showContactSequenceInformation(payload);
          break;
        case OperationLogType.ContractorContact:
          if (payload && Array.isArray(payload.vendorTeamMemberList) && payload.vendorTeamMemberList.length > 0) {
            this.showVendorTeamMemberContactInformation(payload);
          } else {
            this.showContractorTeamContactInformation(payload); // show PMSMC/Non-PMSM team which is created by JM
          }
          break;
        case OperationLogType.ManualInstruction:
          this.showManualInstrctionInformation(payload);
          break;
        case OperationLogType.SnCreatorInfo:
          this.showSnCreatorInformation(payload);
          break;
        default:
          break;
      }
    }
  }

  private async showContactSequenceInformation(sn) {
    if (!sn || !sn.priority || !sn.team || !sn.team.contactSequences) { return; }

    let contactSequences: {
      "0": string[],
      "1": string[],
      "2": string[],
      "3": string[],
      "4": string[],
    } = sn.team.contactSequences[sn.priority];
    if (!contactSequences) { return; }

    let dialogTitle = `${sn.team.workCentre} - ${sn.team.name}`;
    let content = [InformationDialogHelper.createText(`${this.translate("pages.operation-log.fax-number")}${sn.team.fax}`)];
    let tablexParam = this.getTablexParameters(
      [
        this.translate("pages.operation-log.sequence"),
        this.translate("pages.operation-log.post"),
        this.translate("pages.operation-log.person-name"),
        this.translate("pages.operation-log.contact-number"),
        this.translate("pages.operation-log.email")
      ]
    );

    // get all post in contact sequences
    let postSet = new Set<string>();
    for (let i = 0; i < 5; i++) {
      if (!contactSequences[i]) continue;
      contactSequences[i].forEach(post => {
        postSet.add(post);
      });
    }

    // no post
    if (postSet.size === 0) {
      content.push(InformationDialogHelper.createTablex(tablexParam));
      this.showInformationDialog(dialogTitle, content);
      return;
    }

    const request = new JM.JMRequestGetContactPersonsByPost();
    request.posts = Array.from(postSet);

    tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseGetContactPersonsByPost = await AppDelegate.sendJMRequest(request);
    tablexParam['isLoadingTable'] = false;
    if (!response || !response.code || response.code != 200 || !response.payload || response.payload.length === 0) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let contactPersonList = {};
    for (const person of response.payload) {
      contactPersonList[person.post] = person;
    }

    let contactSequenceName = ["", "1st", "2nd", "3rd", "RO"];
    let tableContent = [];
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < contactSequences[i].length; j++) {
        let contact = contactPersonList[contactSequences[i][j]];
        if (!contact) contact = {};
        let data = [
          contactSequenceName[i],
          contactSequenceName[i] ? contactSequences[i][j] : JMLanguage.translate("global.nil"),
          contact.name,
          contact.phone,
          contact.email,
        ];
        tableContent.push(data);
      }
    }
    tablexParam.content = tableContent;
    content.push(InformationDialogHelper.createTablex(tablexParam));
    this.showInformationDialog(dialogTitle, content);
  }

  private showContractorTeamContactInformation(sn) {
    if (!sn || !sn.team) { return; }

    let dialogTitle = `${sn.team.workCentre} - ${sn.team.name}`;
    let content = [
      InformationDialogHelper.createText(`${this.translate("pages.operation-log.contract-number")}${sn.team.contractNumber}`),
      InformationDialogHelper.createText(`${this.translate("pages.operation-log.fax-number")}${sn.team.fax}`)
    ];
    let tablexParam = this.getTablexParameters(
      [
        this.translate("pages.operation-log.contractor-name"),
        this.translate("pages.operation-log.contact-number"),
        this.translate("pages.operation-log.sms"),
        this.translate("pages.operation-log.email")
      ]
    );

    // no team member
    if (!sn.team.members || !sn.team.members.length) {
      content.push(InformationDialogHelper.createTablex(tablexParam));
      this.showInformationDialog(dialogTitle, content);
      return;
    }

    // call api
    let request   = new JM.JMRequestGetContactPersonsByPost();
    request.posts = sn.team.members;

    tablexParam['isLoadingTable'] = true;
    JM.JMConnector.sendGetContactPersonsByPost(request, (error: JM.JMNetworkError, response: JM.JMResponseGetContactPersonsByPost) => {
      tablexParam['isLoadingTable'] = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code !== 200 || !response.payload || response.payload.length === 0) {
        this.openErrorBar(response);
        return;
      }

      tablexParam.content = response.payload.map(row => {
        return [row.name, row.phone, row.mobile, row.email];
      });
      content.push(InformationDialogHelper.createTablex(tablexParam));
      this.showInformationDialog(dialogTitle, content);
    });
  }

  private showVendorTeamMemberContactInformation(sn: JMOBJ.ServiceNotification) {
    if (!sn || !sn.vendorTeamMemberList) { return; }

    const dialogTitle = '';
    const pmsmcMemberTablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      currentPage: 1,
      pageCount: 1,
      headers: [
        { id: 'member-name', name: 'popup.contact-list.column.member-name', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'rmarks', name: 'popup.contact-list.column.remark', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'role', name: 'popup.contact-list.column.role', type: TablexColumnType.MultiLine, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'phone', name: 'popup.contact-list.column.phone', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'fax', name: 'popup.contact-list.column.fax', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
        { id: 'email', name: 'popup.contact-list.column.email', type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle },
      ],
      content: []
    }

    const getPmsmcMemberContent = (sn: JMOBJ.ServiceNotification) => {
      if (!Array.isArray(sn.vendorTeamMemberList)) { return []; }
      return sn.vendorTeamMemberList.map((m) => {
        return [
          m.name,
          m.remarks,
          m.roleNameList ? m.roleNameList : [],
          m.phone,
          m.fax,
          m.email,
        ];
      });
    };

    pmsmcMemberTablexParam.content = getPmsmcMemberContent(sn);

    const dialogContent = [
      InformationDialogHelper.createText(this.translate('popup.contact-list.contractor-contact-list')),
      InformationDialogHelper.createTablex(pmsmcMemberTablexParam),
    ];

    this.showInformationDialog(dialogTitle, dialogContent);
  }

  private showManualInstrctionInformation(sn) {
    if (!sn || !sn.priority || !sn.team || !sn.team.manualInstructions) { return; }

    let manualInstructions = sn.team.manualInstructions["" + sn.priority];
    this.initManualInstruction(manualInstructions, sn.priority);
    SliderPanelHelper.toggleSliderPanel();
  }

  private showSnCreatorInformation(sn) {
    let post = sn.createdBy;
    if (!post) { return; }

    let dialogTitle = this.translate("pages.operation-log.sn-creator");
    let content = [];
    let tablexParam = this.getTablexParameters(
      [
        this.translate("pages.operation-log.post"),
        this.translate("pages.operation-log.person-name"),
        this.translate("pages.operation-log.contact-number"),
        this.translate("pages.operation-log.email")
      ]
    );

    // call api
    let request   = new JM.JMRequestGetContactPersonsByPost();
    request.posts = [post];

    tablexParam['isLoadingTable'] = true;
    JM.JMConnector.sendGetContactPersonsByPost(request, (error: JM.JMNetworkError, response: JM.JMResponseGetContactPersonsByPost) => {
      tablexParam['isLoadingTable'] = false;
      if (error) {
        this.handleJMError(error);
        return;
      }
      if (!response || !response.code || response.code !== 200 || !response.payload || response.payload.length === 0) {
        this.openErrorBar(response);
        return;
      }

      tablexParam.content = response.payload.map(row => {
        return [row.post, row.name, row.phone, row.email];
      });
      content.push(InformationDialogHelper.createTablex(tablexParam));
      this.showInformationDialog(dialogTitle, content);
    });
  }

  private showSnHistoryInformation(sn) {
    if (!sn) { return; }

    let dialogTitle = this.translate("pages.sn.sn-history");
    let content = [InformationDialogHelper.createSnHistory(sn)];

    this.showInformationDialog(dialogTitle, content);
  }

  private initManualInstruction(manualInstructions, priority) {
    this.sliderPaneltitle = this.translate("conponent.manual-instruction.title");
    this.sliderPanelGroups = [];

    let priorityText = [
      "",
      this.translate("priority.emergency"),
      this.translate("priority.urgent"),
      this.translate("priority.non-urgent")
    ];
    let priorityStyle = [
      "",
      "priority-emergency",
      "priority-urgent",
      "priority-non-urgent"
    ];
    let tmpData = [SliderPanelHelper.createText("priority", priorityText[priority], `priority ${priorityStyle[priority]}`)];
    let statusArr = [
      "preAction",
      "contractorNoResponse",
      "roNoResponse"
    ];
    let statusDescription = [
      this.translate("pages.operation-log.action-before-creating-sn"),
      this.translate("pages.operation-log.no-response-of-contractor"),
      this.translate("pages.operation-log.no-response-of-ro")
    ];

    statusArr.forEach((status, statusIndex) => {
      const groupInstructions = manualInstructions[status];

      tmpData.push(SliderPanelHelper.createText(`status${statusIndex}`, statusDescription[statusIndex], "status-description"));
      
      // loop the group instruction
      groupInstructions.forEach((instructionList, groupInstructionIndex) => {
        if (instructionList && Array.isArray(instructionList)) {
          instructionList.forEach((instruction, instructionIndex) => {
            const mi: JMOBJ.ManualInstruction = JM.JMConnector.getManualInstruction(instruction.manualInstructionId);
            
            if (mi) {
              const text = `${mi.description[this.lang]}: ${instruction.remarks}`;
              const id   = `action${statusIndex}-${groupInstructionIndex}-${instructionIndex}`;
    
              tmpData.push(SliderPanelHelper.createText(id , text, "action-description"));
            } else {
              console.log(`Not found manualInstructionId: ${instruction.manualInstructionId}`)
            }
          });
        }
      });
    });

    this.sliderPanelGroups = SliderPanelHelper.createSliderPanelGroups(tmpData, false);
  }

  //---------------------------------------------------------------------------
  // page function
  onPageNumberClicked = (pageNumber) => {
    this.currentPage = parseInt(pageNumber);
    this.requestOperationLogList();
  }

  onPageSizeClicked = (pageSize) => {
    this.currentPage = 1;
    this.currentPageSize = parseInt(pageSize);
    this.requestOperationLogList();
  }

}