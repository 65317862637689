import { Injectable } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
   async requestAllSRALocation(): Promise<{}> {
    let locationCodeSet: Set<JMOBJ.SpecialRequestRule['location']['code']> = new Set();
    const batchSize = 1000;
    const pageNumber = 1;
    let request = new JM.JMRequestSnGetSpecialRequestRuleList();
    request.pageNumber = pageNumber;
    request.pageSize = batchSize;

    const response: JM.JMResponseSnGetSpecialRequestRuleList = await AppDelegate.sendJMRequest(request);
    if (response.code == 200) {
      for (const record of response.payload.records) {
        const specialRequestRule: JMOBJ.SpecialRequestRule = record;
        if (specialRequestRule.location != null) {
          locationCodeSet.add(specialRequestRule.location.code);
        }
      }
      const totalCount = response.payload.totalCount;
      let promises = [];
      for (let i = pageNumber + 1; i <= Math.ceil(totalCount / batchSize); i++) {
        let request2 = new JM.JMRequestSnGetSpecialRequestRuleList();
        request2.pageNumber = i;
        request2.pageSize = batchSize;
        promises.push(AppDelegate.sendJMRequest(request2));
      }
      const responses = await Promise.all(promises);
      for (let response of responses) {
        if (response.code == 200) {
          for (let record of response.payload.records) {
            const specialRequestRule: JMOBJ.SpecialRequestRule = record;
            if (specialRequestRule.location != null) {
              locationCodeSet.add(specialRequestRule.location.code);
            }
          }
        } else {
          throw new Error("error whening getting SRA location: " + response.code + ": " + response.error);
        }
      }
    } else {
      throw new Error("error whening getting SRA location: " + response.code + ": " + response.error);
    }
    return locationCodeSet
  }
}
