import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JMENUM, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { AuthorizationService } from '@services/authorization.service';
import { ParameterService } from '@services/parameter.service';
import { Session } from '@services/session';
import { cloneDeep } from 'lodash';
import { ReleaseNotePlatform } from 'src/app/setting-release-note/release-note/release-note.model';
import { SIDEBAR_ITEMS } from 'src/app/ui/components/sidebar/sidebar-items';
import { Constants } from 'src/constants';
import { SidebarItemInterface } from './sidebar-items';
import * as utility from 'src/app/services/utility';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() type                   : string;
  @Input() additionalData         : {
    targetItemId: string,
    subItmes: SidebarItemInterface[];
  }[];
  @Input() customItems: SidebarItemInterface[];

  systemName                      : string = Constants.SYSTEM_DISPLAY_NAME;
  systemVersion                   : string = Constants.SYSTEM_VERSION;
  supportContactInformation       : any = null;

  private HSDBatchSubmissionId    : string = 'ha-service-report-batch-submission_menu-item-hsd';
  private adverseWeatherFormViewId: string = 'adverse-weather-form-view';
  private parameterService        : ParameterService;
  private authorizationService    : AuthorizationService;
  public items                    : SidebarItemInterface[];

  constructor(
    private router: Router,
    injector: Injector
  ) {
    this.parameterService = injector.get(ParameterService);
    this.authorizationService = injector.get(AuthorizationService);
  }

  ngOnInit() {
    this.supportContactInformation = this.parameterService.getSupportContact();
    this.initSideBar();
  }

  ngOnChanges(): void {
    this.initSideBar();
  }

  private getUrlSearchParams() {
    const searchParams = new URLSearchParams();
  
    const { userToken, selectedLanguage, userInfo } = Session;
    searchParams.set("t", userToken);
    searchParams.set("name", userInfo.name);
    searchParams.set("lang", selectedLanguage);
  
    return searchParams;
  }

  private initSideBar() {
    if (this.customItems) {
      this.items = this.customItems;
      
    } else {
      let menuType = this.type ? this.type : "default";
      let filteredSourceItems = SIDEBAR_ITEMS[menuType].filter(item => {
        const isPermitted = this.authorizationService.hasPermissions(item.permission,false)
        if(item.featurekey == undefined){
          return isPermitted
        }
        return isPermitted && utility.isEnabledFeature(Session,item.featurekey)
      })
      let sourceItems = cloneDeep(filteredSourceItems);

      // add additional subitem
      if (this.additionalData && this.additionalData.length > 0) {
        for (const additionalItem of this.additionalData) {
          for (const sourceItem of sourceItems) {
            if (sourceItem.id === additionalItem.targetItemId) {
              sourceItem.subItems = sourceItem.subItems.concat(additionalItem.subItmes);
            }
          }
        }
      }

      // add additional access checking for menu item
      // only HSD user can create job card temperorily
      const idsToRemove: string[] = []; // menu.id or subItems.id
      // const isHSDUser = Session.userInfo.authorizations.clusters && Session.userInfo.authorizations.clusters.length;
      // if (!isHSDUser) { idsToRemove.push('Job.job-card-create_menu-item'); }
      // if (!otherCriteria) { idsToRemove.push('otherMenuItemId'); }
      this.removeMenuItemByIds(idsToRemove.sort(), sourceItems);

      this.items = sourceItems.map(item => {
        switch (item.id) {
          case this.adverseWeatherFormViewId:
            if (item.id === this.adverseWeatherFormViewId && item.href != undefined) {
              item.href = `${item.href}?${this.getUrlSearchParams()}`
            }
            break
          default:
            item.subItems = (item.subItems)?item.subItems.filter(cell => {
              const isPermitted = this.authorizationService.hasPermissions(cell.permission,false)
              if(cell.id === this.HSDBatchSubmissionId && cell.href != undefined){
                cell.href = `${cell.href}?${this.getUrlSearchParams()}`
              }
    
              if(cell.featurekeyDisable != undefined){
                const isDisabled:boolean = false
                if(utility.isEnabledFeature(Session, cell.featurekeyDisable)){
                  return isDisabled
                }
              }
    
              if(cell.featurekey == undefined){
                return isPermitted
              }
    
              return isPermitted && utility.isEnabledFeature(Session,cell.featurekey)
            }):[];
            break;
        }
        return item;
      });
      this.handleJmAccountReviewMenuItem(this.items);
    }
  }

  reRenderSideBar() {
    this.initSideBar();
  }

  removeMenuItemByIds = (idsToRemove, menuItems) => {
    // this accept dot notation string as well to remove inner menu items
    // i.e. ['abc', 'xyz.nested', 'xyz.nested2']
    if (!idsToRemove.length) { return; }
    const toRemove = idsToRemove.shift();

    idsToRemove.filter(item => {
      // remove all its children/nested items in the array
      // 'abc' --> remove 'abc.x', 'abc.y', 'abc.x' 'abc.x.xyz'
      const splited = item.split('.');
      splited.pop();
      return splited.join('.') !== toRemove;
    })

    toRemove.split('.').reduce((arr, str, idx) => {
      if (idx === toRemove.split('.').length - 1) {
        // if it is not a nested item or it is the last part of string
        // remove its target menu item
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id === str) { arr.splice(i, 1); }
        }
        return;
      } else {
        // return the nested item. Continue the reduce function
        for (let i = 0; i < menuItems.length; i++) {
          if (arr[i].id === str) { return arr[i].subItems; }
        }
      }
    }, menuItems);

    if (idsToRemove.length) { this.removeMenuItemByIds(idsToRemove, menuItems); }
  }

  handleJmAccountReviewMenuItem(sourceItems: any[]) {
    const jmAccountReviewMenuItem = sourceItems.find(item => item.id === 'CCePJMAccountReview');
    if (jmAccountReviewMenuItem && jmAccountReviewMenuItem.subItems.length) {
      const uploadTemplateMenuItem = jmAccountReviewMenuItem.subItems.find(item => item.id === 'ccep-jm-account-review_upload-template');
      if (uploadTemplateMenuItem && !Session.isEnableJMAccountReview) {
        jmAccountReviewMenuItem.subItems.splice(jmAccountReviewMenuItem.subItems.findIndex(item => item.id === 'ccep-jm-account-review_upload-template'), 1);
        if (!jmAccountReviewMenuItem.subItems.length) {
          sourceItems.splice(sourceItems.findIndex(item => item.id === 'CCePJMAccountReview'), 1);
        }
      }
    }
  }

  onClickedRealseNoteLink() {
    const hasReleaseNoteUpdatingPermission = this.authorizationService.hasPermission(JMENUM.Permission.RELEASENOTE_UPDATE);
    if (hasReleaseNoteUpdatingPermission) {
      this.router.navigate(['/setting/release-note/' + ReleaseNotePlatform.web]);
    }
  }

}
