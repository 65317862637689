import { Component, OnInit, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { EquipmentTagService } from '@services/equipment-tag.service';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { CustomFieldsFormComponent } from 'src/lib/ui-component/custom-fields-form/custom-fields-form.component';
import { InputFieldType } from 'src/lib/ui-component/custom-fields-form/input-field-type.enum';

@Component({
  selector: 'edit-equipment-form',
  templateUrl: './edit-equipment-form.component.html',
  styleUrls: ['./edit-equipment-form.component.scss']
})
export class EditEquipmentFormComponent implements OnInit {
  @ViewChild(CustomFieldsFormComponent, { static: true }) editEquipmentForm: CustomFieldsFormComponent;

  mode: 'ccs' | 'hsd' = 'ccs';
  equipment?: JMOBJ.Equipment;
  hsdEquipment?: JMOBJ.HSDEquipment;
  assignedTagList: JMOBJ.HashTag[] = [];

  editEquipmentFormParam: any = {};
  editEquipmentFormInput: {
    ccsNumber?: string,
    equipmentTag?: string[], // tagId
  } = {};

  // for ccs equipment dropdown
  equipmentPageNumber: number = 1;
  equipmentPageSize: number = 50;

  uiIsSaving: boolean = false;
  isShowMsgNoEquipment: boolean = false;

  onSave: Function = undefined;

  constructor(
    private equipmentTagService: EquipmentTagService,
  ) {

  }

  ngOnInit() {
    this.editEquipmentFormParam.isEditMode = true;
    this.editEquipmentFormParam.showFieldsError = false;
    this.editEquipmentFormParam.fieldOptions = {};
    this.editEquipmentFormParam.displayAllAsRow = true;
    this.editEquipmentFormParam.fieldOptions = {
      // TODO: seem no use
      // zt: [ 
      //   { id: 'zt-yes', key: 'zt-yes', value: 'Y', label: JMLanguage.translate('global.yes') },
      //   { id: 'zt-no', key: 'zt-no', value: 'N', label: JMLanguage.translate('global.no') },
      // ],
      // maintStatus: [
      //   { id: JMENUM.HaEquipmentStatus.PENDING, key: JMENUM.HaEquipmentStatus.PENDING, value: JMENUM.HaEquipmentStatus.PENDING, label: JMLanguage.translate('pages.ha-equipment-list.pending') },
      //   { id: JMENUM.HaEquipmentStatus.IN_CONTRACT, key: JMENUM.HaEquipmentStatus.IN_CONTRACT, value: JMENUM.HaEquipmentStatus.IN_CONTRACT, label: JMLanguage.translate('pages.ha-equipment-list.in-contract') },
      //   { id: JMENUM.HaEquipmentStatus.ARCHIVED, key: JMENUM.HaEquipmentStatus.ARCHIVED, value: JMENUM.HaEquipmentStatus.ARCHIVED, label: JMLanguage.translate('pages.ha-equipment-list.archived') },
      //   { id: JMENUM.HaEquipmentStatus.DELETE, key: JMENUM.HaEquipmentStatus.DELETE, value: JMENUM.HaEquipmentStatus.DELETE, label: JMLanguage.translate('pages.ha-equipment-list.delete') },
      //   { id: JMENUM.HaEquipmentStatus.CONDEMN, key: JMENUM.HaEquipmentStatus.CONDEMN, value: JMENUM.HaEquipmentStatus.CONDEMN, label: JMLanguage.translate('pages.ha-equipment-list.condemn') },
      //   { id: JMENUM.HaEquipmentStatus.IN_WARRANTY, key: JMENUM.HaEquipmentStatus.IN_WARRANTY, value: JMENUM.HaEquipmentStatus.IN_WARRANTY, label: JMLanguage.translate('pages.ha-equipment-list.in-warranty') },
      //   { id: JMENUM.HaEquipmentStatus.IN_HOUSE, key: JMENUM.HaEquipmentStatus.IN_HOUSE, value: JMENUM.HaEquipmentStatus.IN_HOUSE, label: JMLanguage.translate('pages.ha-equipment-list.in-house') }
      // ]
    };
    this.editEquipmentFormParam.fieldList = [
      {
        id: 'ccsNumber',
        name: JMLanguage.translate('pages.ha-equipment-list.table-column.ccs-number'),
        type: InputFieldType.dropdown,
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: false,
        template: true,
        clearable: true,
        onChange: () => { },
        onClear: () => {
          this.equipmentPageNumber = 1;
          this.editEquipmentFormParam.fieldOptions['ccsNumber'] = [];
          this.requestEquipmentSummary();
        },
        onSearch: (e) => {
          this.equipmentPageNumber = 1;
          this.editEquipmentFormParam.fieldOptions['ccsNumber'] = [];
          this.requestEquipmentSummary(e.term);
        },
        scrollToEnd: () => {
          this.equipmentPageNumber += 1;
          this.requestEquipmentSummary();
        },
      },
      {
        id: 'equipmentTag',
        name: JMLanguage.translate('pages.ha-equipment-list.table-column.equipment-tag'),
        type: InputFieldType.dropdown,
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: false,
        template: true,
        multiple: true,
        onChange: () => { },
        onSearch: () => { },
        scrollToEnd: () => { },
      }
    ];
  }

  //---------------------------------------------------------
  public setEquipment(equipment: JMOBJ.Equipment) {
    this.mode = 'ccs';
    this.equipment = equipment;
    this.assignedTagList = equipment.hashtags ? equipment.hashtags : [];

    this.setFieldListEnable(['equipmentTag']);

    // update editEquipmentFormInput value
    this.editEquipmentFormInput = {};
    this.editEquipmentFormInput.equipmentTag = this.assignedTagList.map(tag => tag._id);

    // reset options
    this.requestWorkCentreHashtags(Session.selectedWorkCentre);
  }

  public setHsdEquipment(hsdEquipment: JMOBJ.HSDEquipment) {
    this.mode = 'hsd';
    this.hsdEquipment = hsdEquipment;
    this.assignedTagList = hsdEquipment.hashtags ? hsdEquipment.hashtags : [];
    this.equipmentPageNumber = 1;

    // only hsd equipment has ccs number settings
    this.setFieldListEnable(['ccsNumber', 'equipmentTag']);

    let equipmentNumber = this.hsdEquipment.equipment ? this.hsdEquipment.equipment.equipmentNumber : null;

    // update editEquipmentFormInput value
    this.editEquipmentFormInput = {};
    this.editEquipmentFormInput.ccsNumber = equipmentNumber;
    this.editEquipmentFormInput.equipmentTag = this.assignedTagList.map(tag => tag._id);

    // reset options
    this.requestEquipmentSummary(equipmentNumber);
    this.requestWorkCentreHashtags(Session.selectedWorkCentre);

  }

  private setFieldListEnable(fieldArray: string[]) {
    for (let field of this.editEquipmentFormParam.fieldList) {
      field.disable = !(fieldArray.includes(field.id));
    }
  }

  //------------------------------------------------
  // TODO: TBC - remove
  sortEquipmentTag(equipmentTag) {
    let sortedTag = [];
    sortedTag = equipmentTag.sort((a, b) => {
      if (a.equipmentType && b.equipmentType) {
        return a.equipmentType.localeCompare(b.equipmentType) || a.equipmentName.localeCompare(b.equipmentName);
      } else {
        return a.equipmentName.localeCompare(b.equipmentName);
      }
    });
    return sortedTag;
  }

  //-------------------------------------------------------------------
  // callback
  onSaveButtonClicked = () => {
    this.editEquipmentFormParam.showFieldsError = false; // reset error display field
    if (this.mode == 'ccs') {
      if (this.equipment && this.equipment.equipmentNumber) {
        this.requestUpdateEquipment();
      }
    } else {
      this.requestUpdateHAEquipment();
    }
  }

  //---------------------------------------------------------------------
  // api function
  private async requestWorkCentreHashtags(workCentre: string) {
    const field = this.editEquipmentFormParam.fieldList.find(field => field.id === 'equipmentTag');

    let request = new JM.JMRequestWorkCentresHashtags();
    request.workCentre = workCentre;
    request.active = JMENUM.RequestActive.ACTIVE;
    request.tagGroupStatusList = [JMENUM.HashtagGroupStatus.ACTIVE];

    if (this.mode === 'ccs') {
      request.equipmentSource = JMENUM.EquipmentSource.CCS;
      request.equipmentType = this.equipment && this.equipment.equipmentType ? [this.equipment.equipmentType] : undefined;
    } else {
      request.equipmentSource = JMENUM.EquipmentSource.HA;
    }

    this.isShowMsgNoEquipment = false;
    field.isLoading = true;
    const response: JM.JMResponseWorkCentresHashtags = await AppDelegate.sendJMRequest(request);
    field.isLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let fullTagList = response.payload;

    if (!fullTagList.length) {
      this.isShowMsgNoEquipment = true;
    }

    for (const tag of this.assignedTagList) {
      const fullTagIdList = fullTagList.map(t => t._id);
      if (!fullTagIdList.includes(tag._id)) {
        fullTagList.push(tag);
      }
    }

    this.editEquipmentFormParam.fieldOptions['equipmentTag'] = fullTagList.map(tag => {
      const showEquipmentTag = this.mode === 'ccs';
      const label = this.equipmentTagService.getTagDescription(tag, showEquipmentTag);
      return {
        value: tag._id,
        label: label,
      };
    });
  }

  requestEquipmentSummary(ccsNumberKeyword?: string) {
    let field = this.editEquipmentFormParam.fieldList.find(field => field.id == 'ccsNumber');

    let request = new JM.JMRequestEquipmentsEquipmentSummary();
    request.pageNumber = this.equipmentPageNumber;
    request.pageSize = this.equipmentPageSize;
    request.parameters = ['equipmentNumber', 'description'];
    request.active = JMENUM.RequestActive.BOTH;

    if (ccsNumberKeyword) {
      request.filter = {
        equipmentNumber: ccsNumberKeyword
      };
    }
    // TODO: no user story 
    //Franky's request restrict CCS Equipment's Client down to HA* only

    field.isLoading = true;
    JM.JMConnector.sendEquipmentsEquipmentSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseEquipmentsEquipmentSummary) => {
      field.isLoading = false;
      if (error) {
        AppDelegate.handleJMError(error);
        return [];
      }
      if (!response || !response.code || response.code !== 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return [];
      }
      if (response.payload.totalCount <= 0) {
        return [];
      }

      let equipmentOptions = response.payload.records.map(equipment => {
        let option = {
          id: equipment.equipmentNumber,
          key: equipment.equipmentNumber,
          value: equipment.equipmentNumber,
          label: equipment.equipmentNumber + ' - ' + equipment.description
        };
        return option;
      });

      if (this.equipmentPageNumber == 1) {
        this.editEquipmentFormParam.fieldOptions['ccsNumber'] = equipmentOptions;
      } else if (response.payload.totalCount > this.editEquipmentFormParam.fieldOptions['ccsNumber'].length) {
        this.editEquipmentFormParam.fieldOptions['ccsNumber'] = this.editEquipmentFormParam.fieldOptions['ccsNumber'].concat(equipmentOptions);
      }
    });
  }

  private async requestUpdateEquipment() {
    let request = new JM.JMRequestEquipmentsUpdateEquipment();
    request.equipmentNumber = this.equipment.equipmentNumber;
    request.hashtagIdList = this.editEquipmentFormInput.equipmentTag;

    this.uiIsSaving = true;
    const response: JM.JMResponseEquipmentsUpdateEquipment = await AppDelegate.sendJMRequest(request);
    this.uiIsSaving = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate('pages.ha-equipment-list.updated-equipment'));
    if (this.onSave) {
      this.onSave(this.equipment);
    }
  }

  private async requestUpdateHAEquipment() {
    let request = new JM.JMRequestEquipmentsUpdateHSDEquipment();
    request._id = this.hsdEquipment._id;
    request.hashtagIdList = this.editEquipmentFormInput.equipmentTag;
    request.equipmentNumber = this.editEquipmentFormInput.ccsNumber;

    // TODO: no user story 
    //check is ccsNumber Client is HA
    // if (!new RegExp('HA*').test(this.hsdEquipment.clientShortName)) {
    //   return
    // }

    this.uiIsSaving = true;
    const response: JM.JMResponseEquipmentsUpdateHSDEquipment = await AppDelegate.sendJMRequest(request);
    this.uiIsSaving = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate('pages.ha-equipment-list.updated-equipment'));
    if (this.onSave) {
      this.onSave(this.equipment);
    }
  }
}