<div class="wrapper">

    <div class="full-screen-loading" *ngIf="isExporting"> 
        <div class="loading-spinner mt-5">
            <span class="ml-2 font-size-l">{{ "global.loading" | translate }}</span>
        </div>
    </div>

    <app-nav [navbarTitle]="'pages.flms.page-title' | translate" (languageChanged)="onLanguageChanged()"></app-nav>

    <app-sidebar [type]="'functionalLocation'"></app-sidebar>

    <ng-container *ngIf="actionButtonData.length > 0">
        <app-action-sidebar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
            (clickEvent)="onActionButtonClicked($event)">
        </app-action-sidebar>
    </ng-container>

    <div id="content" class="flms-content">
        <!-- tablex -->
        <app-tablex id="flms-table" [tablexParam]='tablexParam'></app-tablex>
    </div>

    <!-- slider panel for edit -->
    <custom-slider-panel #editFunctionalLocationPanel [customClass]="'add_dummy_contract_panel'" [widthPx]=630>
        <app-edit-functional-location-panel #editFunctionalLocationForm (onSubmit)="onLocationSave($event)"
            [editMode]="editMode" [locationParam]="selectedLocation"></app-edit-functional-location-panel>
    </custom-slider-panel>

    <custom-slider-panel #flmsImportPanel [customClass]="'add_dummy_contract_panel'">
        <app-flms-import-panel #flmsImportForm [parameters]="importAttachmentParam" (onUpload)="onUploadAttachment()"
            (onClose)="onCloseAttachment()">
        </app-flms-import-panel>
    </custom-slider-panel>
</div>