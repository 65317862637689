/*

  version 20201126:
   - create this page

  version 20201211:
   - edit location api call
  
*/

import { formatDate } from '@angular/common';
import { Injector } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActionButtonDefinition, ActionButtonFlms, ActionButtonType } from '@enum/action-button';
import { TablexColumnFilterOption, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign, TablexColumnFilterType } from '@enum/tablexColumnType';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { BasePage } from '../../model/base/base';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { ViewChild } from '@angular/core';
import { Constants } from 'src/constants';
import { Session } from '@services/session';
import { base64ToBlob } from '@services/utility';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { JmUserSettings } from '@enum/jm-user-settings.enum';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-flms',
  templateUrl: './flms.component.html',
  styleUrls: ['./flms.component.scss']
})
export class FLMSComponent extends BasePage implements OnInit {
  @ViewChild('flms-table', { static: true }) flmsTable;
  @ViewChild("editFunctionalLocationPanel", { static: true }) editFunctionalLocationPanel;
  @ViewChild("editFunctionalLocationForm", { static: true }) editFunctionalLocationForm;
  @ViewChild("flmsImportPanel", { static: true }) flmsImportPanel;
  @ViewChild("flmsImportForm", { static: true }) flmsImportForm;

  // Table
  selectedTableRows: any = undefined;
  tablexParam: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  tablexFilter: {} = {};

  //action button related
  actionButtonData = [];
  uiIsLoading: boolean = true;

  locationSummary: JMOBJ.Location[] = [];

  levelOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  categoryCodeOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  classificationCodeOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  isVenueOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  isBuildingOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  isSystemVenueOption: TablexColumnFilterOption = new TablexColumnFilterOption();
  fehdVenueOption: TablexColumnFilterOption = new TablexColumnFilterOption();

  selectedLocation: JMOBJ.Location = new JMOBJ.Location();

  importAttachmentParam: any = {};

  editMode = JMENUM.LocationEditMode.LIST;

  isExporting = false;

  commonOptionList = [
    {
      value: "true",
      label: JMLanguage.translate("global.yes"),
    }, {
      value: "false",
      label: JMLanguage.translate("global.no"),
    }, {
      value: "null",
      label: JMLanguage.translate("global.undefined"),
    }
  ];

  constructor(injector: Injector) { super(injector); }

  ngOnInit() {
    if (!this.authorizationService.hasPermissions([JMENUM.Permission.FLMS_VIEW, JMENUM.Permission.FLMS_VIEW_ALL], false)) {
      this.router.navigate(["/dashboard"]);
    }
    if(this.authorizationService.hasPermission(JMENUM.Permission.FLMS_UPDATE)){
      this.editMode = JMENUM.LocationEditMode.LIST;
    } else if(this.authorizationService.hasPermission(JMENUM.Permission.FLMS_UPDATE_CHI) &&
    !this.authorizationService.hasPermission(JMENUM.Permission.FLMS_UPDATE)){
      this.editMode = JMENUM.LocationEditMode.CHINESE_LOCATION;
    }
    this.importAttachmentParam['files'] = [];
    this.initActionButtons();
    this.initAllTableHeader();
    this.initSelectedCol();
    this.initTablex();
    this.onColFiltered(this.selectedColId);
    this.requestLocationSummary();
  }

  initSelectedCol() {
    this.selectedColId = [
      "code",
      "superiorLocation",
      "categoryCode",
      "level",
      "classificationCode",
      "isVenue",
      "isBuilding",
      "isSystemVenue",
      "description.zh",
      "description.en",
      "address1",
      "address2",
      "address3",
      "district",
      "updatedBy",
      "updatedAt"
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[JmUserSettings.FLMS_LIST_COLUMNS]) {
      this.selectedColId = cachedUserSettings[JmUserSettings.FLMS_LIST_COLUMNS];
    }
  }

  //Action Button Function-----------------------------------------------------------------------
  public initActionButtons(): void {
    this.actionButtonData = [];
    this.addActionBtn(ActionButtonFlms.edit);
    this.addActionBtn(ActionButtonFlms.import);
    // this.addActionBtn(ActionButtonFlms.export);
  }

  public addActionBtn(buttonStatus: ActionButtonFlms): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.flms][buttonStatus];
    let buttonHandler = () => { };

    switch (buttonStatus) {
      case ActionButtonFlms.edit:
        buttonHandler = () => {
          if (this.selectedTableRows) {//.length > 0
            this.editFunctionalLocationPanel.toggle();
          }
          else {
            this.openSnackBar(JMLanguage.translate("popupError.no-location"));
          }
        };
        break;
      case ActionButtonFlms.import:
        buttonHandler = () => {
          this.flmsImportPanel.toggle();
        };
        break;
      case ActionButtonFlms.export:
        buttonHandler = () => {
          this.requestExportLocationSummary();
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 1) ?
          actionButton.buttons[0].name : "global.yes",
        handler: buttonHandler
      },
      {
        name: (actionButton.buttons && actionButton.buttons.length >= 2) ?
          actionButton.buttons[1].name : "global.no",
        handler: () => { this.uiIsLoading = false }
      }
    ]
    this.actionButtonData.push(actionButton);
  }

  public onActionButtonClicked(actionButton: any) {
    actionButton.buttons[0].handler();
  }
  //--------------------------------------------------------------------------------------------

  //Tablex Function-----------------------------------------------------------------------------
  public renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter(col => {
      return this.selectedColId.includes(col.id);
    });
    if (this.locationSummary == null) { return }
    this.tablexParam['highlightedRows'] = [];
    this.selectedTableRows = undefined;
    this.tablexParam['content'] = this.locationSummary.map(data => {
      let categoryCode = "";
      if (data.categoryCode) {
        categoryCode = (Session.categoryCodeDict[data.categoryCode]) ? Session.categoryCodeDict[data.categoryCode]["name"] : undefined;
      }

      let classificationCode = "";
      if (data.classificationCode) {
        classificationCode = (Session.classificationCodeDict[data.classificationCode]) ? Session.classificationCodeDict[data.classificationCode]["name"] : undefined;
      }

      let originRow = [
        data._id,
        data.code,
        data.superiorLocation,
        categoryCode,
        data.level,
        classificationCode,
        (data.isVenue == undefined || data.isVenue == null) ? JMLanguage.translate('global.undefined') : JMLanguage.translate((data.isVenue) ? 'global.yes' : 'global.no'),
        (data.isBuilding == undefined || data.isBuilding == null) ? JMLanguage.translate('global.undefined') : JMLanguage.translate((data.isBuilding) ? 'global.yes' : 'global.no'),
        (data.isSystemVenue == undefined || data.isSystemVenue == null) ? JMLanguage.translate('global.undefined') : JMLanguage.translate((data.isSystemVenue) ? 'global.yes' : 'global.no'),
        data.description.zh,
        data.description.en,
        data.address1,
        data.address2,
        data.address3,
        data.district,
        data.fehdVenues.map(fehdVenue => JMLanguage.translate('fehd-venue.' + fehdVenue)),
        data.updatedBy,
        formatDate(data.updatedAt, "dd/MM/yyyy HH:mm", 'en-US')
      ];
      let row = []
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
  }

  public initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: true,
      tableRow: "row",
      tableClass: "flms-table",
      tableWrapperClass: "table-min-width",
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map(col => { return { 'id': col.id, 'name': col.name } }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onFilterChanged,
      onRowClicked: this.onRowClicked,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  public onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();
    this.tablexParam['highlightedRows'] = [];
    this.selectedTableRows = undefined;
    if (!Session.userInfo['userSettings'] || (Session.userInfo['userSettings'] && Session.userInfo.userSettings[JmUserSettings.FLMS_LIST_COLUMNS] != this.selectedColId)) {
      this.requestUpdateUserSettings();
      let userSettings = {};
      userSettings[JmUserSettings.FLMS_LIST_COLUMNS] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  }

  public initAllTableHeader() {
    this.isVenueOption.items = this.commonOptionList;
    this.isVenueOption.bindLabel = "label";
    this.isVenueOption.bindValue = "value";
    this.isVenueOption.change = this.onOptionFilterChanged;

    this.isBuildingOption.items = this.commonOptionList;
    this.isBuildingOption.bindLabel = "label";
    this.isBuildingOption.bindValue = "value";
    this.isBuildingOption.change = this.onOptionFilterChanged;

    this.isSystemVenueOption.items = this.commonOptionList;
    this.isSystemVenueOption.bindLabel = "label";
    this.isSystemVenueOption.bindValue = "value";
    this.isSystemVenueOption.change = this.onOptionFilterChanged;

    let fehdVenueList =  Object.values(JMENUM.FehdVenue);
    this.fehdVenueOption.items = fehdVenueList.map(fehdVenue => { return { value: fehdVenue, label: JMLanguage.translate('fehd-venue.' + fehdVenue)} });
    this.fehdVenueOption.bindLabel = "label";
    this.fehdVenueOption.bindValue = "value";
    this.fehdVenueOption.change = this.onOptionFilterChanged;
    this.fehdVenueOption.multiple = true;

    this.levelOption.items = [
      {
        value: JMENUM.LocationLevel.ONE,
        label: JMENUM.LocationLevel.ONE,
      }, {
        value: JMENUM.LocationLevel.TWO,
        label: JMENUM.LocationLevel.TWO,
      }, {
        value: JMENUM.LocationLevel.THREE,
        label: JMENUM.LocationLevel.THREE,
      }
    ];
    this.levelOption.bindLabel = "label";
    this.levelOption.bindValue = "value";
    this.levelOption.change = this.onOptionFilterChanged;

    this.categoryCodeOption.items = [{ categoryCode: JMLanguage.translate('global.unclassified'), name: JMLanguage.translate('global.unclassified') }].concat(Object.values(Session.categoryCodeDict));
    this.categoryCodeOption.bindLabel = "name";
    this.categoryCodeOption.bindValue = "categoryCode";
    this.categoryCodeOption.change = this.onOptionFilterChanged;

    this.classificationCodeOption.items = [{ classificationCode: JMLanguage.translate('global.unclassified'), name: JMLanguage.translate('global.unclassified') }].concat(Object.values(Session.classificationCodeDict));
    this.classificationCodeOption.bindLabel = "name";
    this.classificationCodeOption.bindValue = "classificationCode";
    this.classificationCodeOption.change = this.onOptionFilterChanged;

    //-----------------------------------------------------
    this.allColHeaders = [
      {
        id: 'objId',
        name: '_id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none'
      },
      {
        id: 'code',
        name: "pages.flms.table-column.functional-location",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'superiorLocation',
        name: "pages.flms.table-column.super-functional-location",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'categoryCode',
        name: "pages.flms.table-column.category-code",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.categoryCodeOption,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'level',
        name: "pages.flms.table-column.level",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.levelOption,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'classificationCode',
        name: "pages.flms.table-column.classification-code",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.classificationCodeOption,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'isVenue',
        name: "pages.flms.table-column.venue",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.isVenueOption,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'isBuilding',
        name: "pages.flms.table-column.building",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.isBuildingOption,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'isSystemVenue',
        name: "pages.flms.table-column.system-venue",
        enableFilter: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.isSystemVenueOption,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'description.zh',
        name: "pages.flms.table-column.description.zh",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'description.en',
        name: "pages.flms.table-column.description.en",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'address1',
        name: "pages.flms.table-column.address1",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'address2',
        name: "pages.flms.table-column.address2",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'address3',
        name: "pages.flms.table-column.address3",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'district',
        name: "pages.flms.table-column.district",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'fehdVenues',
        name: "pages.flms.table-column.fehd-venues",
        enableFilter: true,
        type: TablexColumnType.MultiLine,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.fehdVenueOption,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'updatedBy',
        name: "pages.flms.table-column.last-updated-by",
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      },
      {
        id: 'updatedAt',
        name: "pages.flms.table-column.last-updated-date",
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Left,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: "col",
      }
    ];
  }

  public onRowClicked = (index, row) => {
    this.tablexParam['highlightedRows'] = [];
    if (this.selectedTableRows && this.selectedTableRows == row) {
      this.selectedTableRows = undefined;
      this.selectedLocation = new JMOBJ.Location();
      this.tablexParam['selectedRowCount'] = 0;
    } else {
      this.tablexParam['highlightedRows'][index] = true;
      this.selectedTableRows = row;
      this.tablexParam['selectedRowCount'] = 1;
      this.selectedLocation = this.locationSummary[index];
    }
  }

  public onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.selectedTableRows = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = 0;
    this.requestLocationSummary();
  }

  public onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.selectedTableRows = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = 0;
    this.requestLocationSummary();
  }

  onFilterChanged = (event, index, header, filter) => {
    this.resetSelectRow();
    this.tablexFilter = filter;
    this.currentPage = 1;
    this.requestLocationSummary();
  }

  resetSelectRow() {
    this.selectedTableRows = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.tablexParam['selectedRowCount'] = 0;
  }

  onSortOrderChanged = (header, sortOrder) => {
    if (this.tablexParam['sortBy'] == header.id && this.tablexParam['sortOrder'] == sortOrder) {
      this.tablexParam['sortBy'] = null;
    } else if (this.tablexParam['sortBy'] != header.id) {
      this.tablexParam['sortBy'] = header.id;
      this.tablexParam['sortOrder'] = 1;
    } else {
      this.tablexParam['sortOrder'] = sortOrder;
    }

    this.requestLocationSummary();
  }
  //--------------------------------------------------------------------------------------------


  //API CALL------------------------------------------------------------------------------------
  public async requestLocationSummary() {
    const request = new JM.JMRequestLocationsLocationSummary();
    request.parameters = [
      "code",
      "superiorLocation",
      "categoryCode",
      "level",
      "classificationCode",
      "isVenue",
      "isBuilding",
      "isSystemVenue",
      "description",
      "address1",
      "address2",
      "address3",
      "district",
      "fehdVenues",
      "updatedBy",
      "updatedAt"
    ];
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    if (this.tablexParam['sortBy']) {
      request.sortBy = this.tablexParam['sortBy'];
      request.sortOrder = this.tablexParam['sortOrder'];
    }
    if (this.tablexFilter) {
      if (this.tablexFilter["categoryCode"]) {
        if (this.tablexFilter["categoryCode"] == JMLanguage.translate('global.unclassified')) {
          this.tablexFilter["categoryCode"] = null;
        }
      }
      if (this.tablexFilter["classificationCode"]) {
        if (this.tablexFilter["classificationCode"] == JMLanguage.translate('global.unclassified')) {
          this.tablexFilter["classificationCode"] = null;
        }
      }
      if (this.tablexFilter["isVenue"]) {
        switch (this.tablexFilter["isVenue"]) {
          case "null":
            this.tablexFilter["isVenue"] = null;
            break;
          case "true":
            this.tablexFilter["isVenue"] = true;
            break;
          case "false":
            this.tablexFilter["isVenue"] = false;
            break;
        }
      }
      if (this.tablexFilter["isBuilding"]) {
        switch (this.tablexFilter["isBuilding"]) {
          case "null":
            this.tablexFilter["isBuilding"] = null;
            break;
          case "true":
            this.tablexFilter["isBuilding"] = true;
            break;
          case "false":
            this.tablexFilter["isBuilding"] = false;
            break;
        }
      }
      if (this.tablexFilter["isSystemVenue"]) {
        switch (this.tablexFilter["isSystemVenue"]) {
          case "null":
            this.tablexFilter["isSystemVenue"] = null;
            break;
          case "true":
            this.tablexFilter["isSystemVenue"] = true;
            break;
          case "false":
            this.tablexFilter["isSystemVenue"] = false;
            break;
        }
      }

      for (let key in this.tablexFilter) {
          switch (key){
            case "categoryCode": 
            case "classificationCode": 
            case "isVenue": 
            case "isBuilding":
            case "isSystemVenue":
              if (!request.filter) request.filter = {};
              request.filter[key] = this.tablexFilter[key];
              break;
            case "fehdVenues":
              break;
            default : 
              if(this.tablexFilter[key]) { // omit empty string for free text filter
                if (!request.filter) request.filter = {};
                request.filter[key] = this.tablexFilter[key];
              }
              break;
          }
      }

      if (this.tablexFilter['fehdVenues']) {
        request.fehdVenues = this.tablexFilter['fehdVenues'];
      }
    }

    this.tablexParam["isLoadingTable"] = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam["isLoadingTable"] = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.locationSummary = response.payload.records;
    this.renderTable();
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
  }

  public async requestUpdateLocation() {
    const request = new JM.JMRequestUpdateLocation();
    request.code = this.selectedLocation.code;
    request.categoryCode = (this.selectedLocation.categoryCode == undefined) ? null : this.selectedLocation.categoryCode;
    request.classificationCode = (this.selectedLocation.classificationCode == undefined) ? null : this.selectedLocation.classificationCode;
    request.isVenue = (this.selectedLocation.isVenue == undefined) ? null : this.selectedLocation.isVenue;
    request.isBuilding = (this.selectedLocation.isBuilding == undefined) ? null : this.selectedLocation.isBuilding;
    request.isSystemVenue = (this.selectedLocation.isSystemVenue == undefined) ? null : this.selectedLocation.isSystemVenue;
    request.level = (this.selectedLocation.level == undefined) ? null : this.selectedLocation.level;
    request.chineseDescription = (this.selectedLocation.description.zh == undefined) ? null : this.selectedLocation.description.zh;
    request.fehdVenues = this.selectedLocation.fehdVenues ? this.selectedLocation.fehdVenues : [];

    const response: JM.JMResponseUpdateLocation = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.openSnackBar(JMLanguage.translate("pages.flms.message.update", [request.code]));
    this.requestLocationSummary();
    this.editFunctionalLocationPanel.close();
  }

  public async requestExportLocationSummary() {
    const request = new JM.JMRequestExportLocationSummary();
    //request.parameters = this.selectedColId;
    //request.pageNumber = this.currentPage;
    //request.pageSize = this.currentPageSize;
    if (this.tablexParam['sortBy']) {
      request.sortBy = this.tablexParam['sortBy'];
      request.sortOrder = this.tablexParam['sortOrder'];
    }
    if (this.tablexFilter) {
      if (this.tablexFilter["categoryCode"]) {
        if (this.tablexFilter["categoryCode"] == JMLanguage.translate('global.unclassified')) {
          this.tablexFilter["categoryCode"] = null;
        }
      }
      if (this.tablexFilter["classificationCode"]) {
        if (this.tablexFilter["classificationCode"] == JMLanguage.translate('global.unclassified')) {
          this.tablexFilter["classificationCode"] = null;
        }
      }
      request.filter = this.tablexFilter;
    }

    this.isExporting = true;
    const response: JM.JMResponseExportLocationSummary = await AppDelegate.sendJMRequest(request);
    this.isExporting = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    let blob = base64ToBlob(response.payload.base64, response.payload.type);
    saveAs(blob, "Functional_Location_List_" + moment().format("YYYYMMDD") + ".xlsx");
    this.isExporting = false;
  }

  async requestUpdateUserSettings() {
    let userSettings = {};
    const request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    /**
     * should update userSetting for JM & CM : 
     * {
     *    "JM": {
     *            "HaEquipmentListColumns": this.selectedColId,
     *             "FLMS": ....
     *          },
     *    "CM": {
     *            "CertListColumns": ...
     *          }
     * }
     */
    userSettings[JmUserSettings.FLMS_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
  }

  //--------------------------------------------------------------------------------------------

  // button callback functions------------------------------------------------------------------
  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }
    this.currentPage = 1;
    this.requestLocationSummary();
  }
  //--------------------------------------------------------------------------------------------

  //import function-----------------------------------------------------------------------------
  onUploadAttachment() {
    if (this.importAttachmentParam.files.type == 'application/vnd.ms-excel' || this.importAttachmentParam.files.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      let fileSizeGB = this.importAttachmentParam.files.size / 1024 / 1024 / 1024; //B to GB
      if (fileSizeGB >= 1) { //cannot larger than 1 GB
        this.openSnackBar(JMLanguage.translate("pages.flms.import-files.file-size-too-large-alert"));
        return;
      }
      this.importAttachmentParam.isLoading = true;

      if (this.importAttachmentParam.attachmentType == JMENUM.LocationImportType.CHINESE_LOCATION) {
        this.requestUploadChinese();
      } else if (this.importAttachmentParam.attachmentType == JMENUM.LocationImportType.FUNCTIONAL_LOCATION) {
        this.requestUploadMain();
      }

    } else {
      this.importAttachmentParam.files = [];
      this.flmsImportForm.allowReUpload();
      this.openSnackBar(JMLanguage.translate("pages.flms.import-files.please-upload-a-excel-file"));
      return;
    }
  }

  public async requestUploadChinese() {
    const request = new JM.JMRequestLocationsUploadChineseLocationExcel();
    request.uploadData = new FormData;
    request.uploadData.set('files', this.importAttachmentParam.files, this.importAttachmentParam.files.name);

    const response: JM.JMResponseLocationsUploadChineseLocationExcel = await AppDelegate.sendJMRequestFile(request, request.uploadData);
    this.importAttachmentParam.isLoading = false;
    this.importAttachmentParam.files = [];
    this.flmsImportForm.allowReUpload();

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.importAttachmentParam.uploadResult = response.payload;
  }

  public async requestUploadMain() {
    const request = new JM.JMRequestLocationsUploadLocationListExcel();
    request.uploadData = new FormData;
    request.uploadData.set('files', this.importAttachmentParam.files, this.importAttachmentParam.files.name);

    const response: JM.JMResponseLocationsUploadLocationListExcel = await AppDelegate.sendJMRequestFile(request, request.uploadData);
    this.importAttachmentParam.files = [];
    this.importAttachmentParam.isLoading = false;
    this.flmsImportForm.allowReUpload();

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.importAttachmentParam.uploadResult = response.payload;
  }

  onCloseAttachment() {
    this.importAttachmentParam.isLoading = false;

    this.flmsImportPanel.close();
    this.importAttachmentParam = {
      isLoading: false,
      isUploaded: false,
      files: [],
      result: null
    }
  }
  //--------------------------------------------------------------------------------------------

  //Other---------------------------------------------------------------------------------------
  public onLocationSave(event) {
    this.requestUpdateLocation();
  }

  onLanguageChanged() {
  }
  //--------------------------------------------------------------------------------------------

}
