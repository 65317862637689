import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { PostGetTeamsRequest } from "src/app/api/contact-group/post-get-teams-request";
import { PostGetTeamsResponse } from "src/app/api/contact-group/post-get-teams-response";
import { environment } from "src/environments/environment";

@Injectable()
export class ContactGroupService {

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
  ) { }

  // dont use getTeams, use teamSummary instead
  getTeams(request: PostGetTeamsRequest): Observable<PostGetTeamsResponse> {
    let httpHeaders = this.authorizationService.getHeaders();
    let url = environment.CCEP_API_HOST + "/teams/getTeams/";

    return this.http
      .post<PostGetTeamsResponse>(url, request, { headers: httpHeaders })
      .pipe(
        catchError(this.handleError)
      );
  }


  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
