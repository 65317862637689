<div class="ha-equipment-details-modal" id="ha-equipment-details-modal">
  <button type="button" class="btn font-size-l px float-right" (click)="onClickClose()">
    <i class="fa fa-times text-black"></i>
  </button>
  
  <span *ngIf="isLoading">
    <div class="loading-spinner text-center">
      <div>{{'global.loading' | translate}}</div>
    </div>
  </span>

  <div class="details-wrap row pl-3 py-4" [class.invisible]="isLoading">
    <div class="col-6">
      <div class="row justify-content-between mb-3">
        <div class="col">
          {{ "component.ha-details.ha-enrollment" | translate }}
        </div>
        <div class="col-auto bold blue" *ngIf="isHaEnrollment">{{ "global.yes" | translate }}</div>
        <div class="col-auto bold blue" *ngIf="!isHaEnrollment">{{ "global.no" | translate }}</div>
      </div>

      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.hospital-cluster" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="haEquipment?.HACluster"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.hospital-location" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="haAddress"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.asset-supplier-name" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="haEquipment?.assetSupplier"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.manufacturer" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="haEquipment?.manufacturer"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.brand" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="haEquipment?.brand"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.model" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="haEquipment?.model"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.serial-number" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="haEquipment?.serialNumber"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.ha-contract-number" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="haEquipment?.HAContractNumber"></app-display-text-field>
      </div>
    </div>

    <div class="col-6">
      <div class="row justify-content-between mb-3">
        <div class="col">
          {{ "component.ha-details.ccs-information" | translate }}
        </div>
        <div class="col-auto bold blue" *ngIf="ccsInfo">{{ "global.yes" | translate }}</div>
        <div class="col-auto bold blue" *ngIf="!ccsInfo">{{ "global.no" | translate }}</div>
      </div>

      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.equipment-number" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="ccsInfo?.equipmentNumber"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.type" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="ccsInfo?.equipmentType"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.description" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="ccsInfo?.description"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.customer" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="ccsInfo?.clientShortName"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.functional-location" | translate }}</span>
        <app-display-text-field class="col-12" [textField]="ccsInfo?.location"></app-display-text-field>
      </div>
      <div class="row mb-3">
        <span class="col-12 gray-title">{{ "component.ha-details.active" | translate }}</span>
        <ng-container *ngIf="ccsInfo">
          <app-display-text-field class="col-12" [translateKey]="ccsInfo.isActive == true ? 'global.yes' : 'global.no'"></app-display-text-field>
        </ng-container>
        <ng-container *ngIf="!ccsInfo">
          <app-display-text-field class="col-12"></app-display-text-field>
        </ng-container>
      </div>
    </div>

  </div>
</div>
