<div class="row justify-content-between m-0">
  <h3 class="d-inline">{{ uiTitle }}</h3>
  <div class="submit-wrap d-inline">
    <app-submit-button 
        [id]="'save_button'"
        [text]="'global.save'"
        [buttonClass]="' standard brand-amber mr-0'"
        [iconClass]="'fa-save'"
        [isDisabled]="!uiEnableSaveButton"
        [isLoading]="!uiEnableSaveButton"
        (onClick)="onSaveButtonClicked($event)" 
      ></app-submit-button>
  </div>
</div>
<div class="row mt-4 m-0">
  <div class="col-12 px-0 mb-3">
    <label for="update-team-form.team-name" class="mb-1 ">
      {{ 'update-team-form.team-name' | translate }}
    </label>

    <input id="update-team-form.team-name"
      name="uiTeamName"
      type="text"
      class="form-control"
      [(ngModel)]="uiTeamName"
    >
  </div>

  <div class="col-12 px-0 mb-3">
    <label for="update-team-form.team-description" class="mb-1 ">
      {{ 'update-team-form.team-description' | translate }}
    </label>
    <input id="update-team-form.team-description"
      name="uiTeamDescription"
      type="text"
      class="form-control"
      [(ngModel)]="uiTeamDescription"
    >
  </div>

</div>
  


