import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {

  @Input() id             : string = '';
  @Input() text           : string = '';
  @Input() buttonClass    : string = '';
  @Input() iconClass      : string = '';
  @Input() isDisabled     : boolean = false;
  @Input() isLoading      : boolean = false;

  @Output() onClick :EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClicked() {
    this.onClick.emit();
  }

}
