import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';
import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/ui/model/base/base';
import { Permission } from '@enum/permission';
import { Session }  from 'src/app/services/session';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'locations',
  templateUrl: './locations.html',
  styleUrls: ['./locations.scss']
})

export class LocationsComponent extends BasePage implements OnInit {

  constructor(injector: Injector) {
    super(injector);
  }

  pageTitle: string;

  ///------------
  selectedWorkCenter    : string; // current selected workcentre
  tablexParam           : {} = {};  // isLoadingTable
  currentPageSize       = 10;
  currentPage           = 1;
  pageCount             = 1;
  totalLocationCount    = 0;
  locationList          : any; // the result of requestLocationList


  //
  protected componentName(): string {
    return "locations";
  }

  // --------------------------------------------------------------------------
  // view life cycle functions
  ngOnInit() {
    this.selectedWorkCenter = Session.selectedWorkCentre;
    this.pageTitle = JMLanguage.translate("pages.locations.page-title", [this.selectedWorkCenter]);
    this.checkViewPermission(Permission.wcmView);
    this.checkWorkCenterPermission(this.selectedWorkCenter);

    this.tablexParam = {
      isLoadingTable        : false,
      enableSetPageSize     : true,
      enablePagination      : true,
      pageSizeOptions       : [10, 25, 50],
      currentPageSize       : this.currentPageSize,
      currentPage           : this.currentPage,
      pageCount             : this.pageCount,
      onPageNumberClicked   : this.onPageNumberClicked,
      onPageSizeClicked     : this.onPageSizeClicked,
      headers: [ 
        {id:'code', name   : "pages.locations.table-column-code",        type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
        {id:'district', name   : "pages.locations.table-column-district",    type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
        {id:'description', name   : "pages.locations.table-column-description", type: TablexColumnType.Text, horizontalAlign: TablexColumnHorizontalAlign.Center, verticalAlign: TablexColumnVerticalAlign.Middle},
      ],
      content: []
    }
    
    this.requestLocationList();
  }

  ngOnDestroy() {
  }

  //---------------------------------------------------------------------------
  // view functions
  private renderTable() {
    let lang = Session.selectedLanguage;;
    this.tablexParam['pageCount'] = Math.ceil(this.totalLocationCount/this.currentPageSize);
    this.tablexParam['content'] = this.locationList.map(functionalLocation => {
      return [
        functionalLocation.code,
        this.translate("district." + functionalLocation.districtCode), 
        functionalLocation.description[lang],
      ];
     });
  }

  requestLocationList() {
    this.tablexParam['isLoadingTable'] = true;
    this.totalLocationCount = 0;
    this.locationList = [];

    let equipmentsRequest:JM.JMRequestEquipmentsParameters = new JM.JMRequestEquipmentsParameters();
    equipmentsRequest.active = JMENUM.RequestActive.ACTIVE;
    equipmentsRequest.workCentre = this.selectedWorkCenter;
    equipmentsRequest.parameter = ["location"];
    equipmentsRequest.pageSize = this.currentPageSize;
    equipmentsRequest.pageNumber = this.currentPage;
    
    JM.JMConnector.sendEquipmentsParameters(equipmentsRequest, (error:JM.JMNetworkError, equipmentResult:JM.JMResponseEquipmentsParameters) => {
      if (error) {
        this.tablexParam['isLoadingTable'] = false;
        this.handleJMError(error);
        return;
      }
      if (!equipmentResult || !equipmentResult.code || equipmentResult.code != 200 || !equipmentResult.payload) {
        this.tablexParam['isLoadingTable'] = false;
        this.openErrorBar(equipmentResult);
        return;
      }

      if (equipmentResult.payload.result.length === 0) {
        this.tablexParam['isLoadingTable'] = false;
        return;
      }

      let request = new JM.JMRequestLocationsLocationSummary();
      request.location = equipmentResult.payload.result[0].location.filter(code => {return (code)}) // filter null

      JM.JMConnector.sendLocationsLocationSummary(request, (error:JM.JMNetworkError, response:JM.JMResponseLocationsLocationSummary) => {
        this.tablexParam['isLoadingTable'] = false;
        if (error) {
          this.handleJMError(error);
          return;
        }
        if (!response || !response.code || response.code != 200 || !response.payload) {
          this.openErrorBar(response);
          return;
        }

        this.totalLocationCount = equipmentResult.payload.totalCount;
        this.locationList = response.payload.records;
        this.renderTable();
      });
    });

  }

  //---------------------------------------------------------------------------
  // page function
  onPageNumberClicked = (pageNumber) => {
    this.currentPage = parseInt(pageNumber);
    this.requestLocationList();
  }

  onPageSizeClicked = (pageSize) => {
    this.currentPage = 1;
    this.currentPageSize = parseInt(pageSize);
    this.requestLocationList();
  }

  // TODO: reload language
  onLanguageChanged() {
    
  }

}
