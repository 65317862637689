import { Component, EventEmitter, Input, Output, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ, JMREASON, JMUTILITY } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-job-card-cancel-form',
  templateUrl: './job-card-cancel-form.component.html',
  styleUrls: ['./job-card-cancel-form.component.scss']
})
export class JobCardCancelFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobCard: JMOBJ.JobCard;
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onComplete = new EventEmitter<any>();

  constants = Constants;
  isLoading: boolean = false;

  // Options
  approverListOptions: string[] = [];
  options: any[];

  // UI
  hasApprovePermission: boolean = false;
  isLoadingApprover   : boolean = false;
  submitDisabled      : boolean = true;

  // Form fields
  selectedApprover: string[];
  reason          : any;
  confirmed       : boolean;
  remark          : string;

  constructor() { }

  ngOnInit() {
    this.hasApprovePermission = true;
  }

  ngOnChanges() {
  }

  ngOnDestroy() {}

  initForm() {
    this.options = [];
    const cancelReasons = JMREASON.JOB_CANCEL_REASON_LIST.filterReason([
      JMREASON.Attribute.JM,
      JMREASON.Attribute.STANDALONE,
    ]);

    for (const reasonObj of cancelReasons) {
      const item = {
        value      : reasonObj.code,
        description: Session.selectedLanguage === JMENUM.Language.ZH ? reasonObj.description.zh : reasonObj.description.en
      };
      this.options.push(item);
    }
  }

  //===================================================
  // API request
  public async requestCancelJobCard() {
    const request = new JM.JMRequestJobCardsCancelStandaloneJob();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.cancelCode = this.reason;
    request.cancelRemarks = this.remark;
    // request.approver = this.selectedApprover;

    this.isLoading = true;
    const response: JM.JMResponseSnCancelSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.isLoading = false;
      throw new Error("");
    }

    this.isLoading = false;
    AppDelegate.openSnackBar(JMLanguage.translate("global.cancelled"));
  }

  //===================================================
  // UI event
  validateForm() {
    if (!this.hasApprovePermission) {
      if (this.approverListOptions.length === 0 || this.selectedApprover.length === 0){
        this.submitDisabled = true;
        return;
      }
    }

    if (!this.reason || !this.confirmed) {
      this.submitDisabled = true;
      return;
    }

    this.submitDisabled = false;
  }

  onClickSubmit() {
    if (this.remark) this.remark = this.remark.trim();

    this.onSubmit.emit({
      cancellationCode: this.reason,
      cancelRemarks   : this.remark,
      approver        : this.selectedApprover
    });

    this.requestCancelJobCard()
      .then(() => this.onComplete.emit());
  }

  get getMaxRemarksLength():number{
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
