import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-patch-ccs-so-number-form',
  templateUrl: './patch-ccs-so-number-form.component.html',
  styleUrls: ['./patch-ccs-so-number-form.component.scss']
})
export class PatchCcsSoNumberFormComponent implements OnInit {

  @Input() jobCard: JMOBJ.JobCard;        // data source
  @Output() onJobCardUpdated = new EventEmitter<JMOBJ.JobCard>(); 
  @Input() componentParameters;

  ccsSoNumber: string  = '';
  isLoading  : boolean = false;

  constructor() { }

  ngOnInit() {
  }

  //===================================================================================================================
  // API functions
  private async requestPatchCcsSoNumber() {
    const request = new JM.JMRequestJobCardsPatchCcsSoNumber();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.ccsServiceOrderNumber = this.ccsSoNumber;

    this.isLoading = true;
    const response: JM.JMResponseJobCardsPatchCcsSoNumber = await AppDelegate.sendJMRequest(request);
    this.isLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (this.componentParameters && this.componentParameters.setJobCard) {
      this.componentParameters.setJobCard(response.payload);
    }
    this.onJobCardUpdated.emit(response.payload);
  }


  //===================================================================================================================
  // UI function
  onCcsSoNumberInputboxKeyup(event: any) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    this.ccsSoNumber = event.target.value;
  }


  //===================================================================================================================
  // Event callback
  onClickSubmit() {
    this.ccsSoNumber = this.ccsSoNumber.replace(/[^\d]/g, '');

    let buttons = [
      {
        name: JMLanguage.translate("global.yes"),
        handler: () => {      
          if (this.ccsSoNumber.length > 0) {
            this.requestPatchCcsSoNumber();
          }
        }
      },
      { name: JMLanguage.translate("global.no")}
    ];

    let popUpMsg = JMLanguage.translate("pages.sn.popup.patch-ccs-so-number-title");
    AppDelegate.showPopUpAlert(popUpMsg, this.ccsSoNumber, buttons); 
  }

}
