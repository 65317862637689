/**
 * This page is copy from and revamp the following pages:
 * angular\src\app\pages\sn-view-job-card\sn-view-job-card.component.ts
 * angular\src\app\ui\components\job-card\enquiry-job-view\enquiry-job-view.component.ts
 * angular\src\app\ui\components\job-card\cm-job-view\cm-job-view.component.ts
 */

import * as qs from "qs";
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../../model/base/base';
import { JM, JMCONSTANT, JMENUM, JMOBJ, JMUTILITY, JMREASON } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { ActionButtonDefinition, ActionButtonJobCard, ActionButtonTeco, ActionButtonType, ActionButtonUnTeco } from '@enum/action-button';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalManuelFaxComponent } from '../../components/modal-manuel-fax/modal-manuel-fax.component';
import { SnReason } from '@enum/sn-reason';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { saveAs } from 'file-saver';
import * as utility from 'src/app/services/utility';
import { Session } from '@services/session';
import { Constants } from 'src/constants';
import { environment } from 'src/environments/environment';
import { CcsSoSubmitFormReturnParam, JobCardActionButtions } from './jobcard-view-const';
import { ApprovalService } from '@services/approval.service';

import { JobcardJobDescriptionComponent } from '../../components/jobcard-job-description/jobcard-job-description.component';
import { JobcardHaInformationComponent } from '../../components/jobcard-ha-information/jobcard-ha-information.component';
import { JobcardTaskProgressComponent } from '../../components/jobcard-task-progress/jobcard-task-progress.component';
import { JobCardStandaloneViewComponent } from 'src/app/job-card/job-card-standalone-view/job-card-standalone-view.component';
import { JobCardCancelFormComponent } from 'src/app/slider-form/job-card-cancel-form/job-card-cancel-form.component';

@Component({
  selector: 'app-jobcard-view',
  templateUrl: './jobcard-view.component.html',
  styleUrls: ['./jobcard-view.component.scss']
})
export class JobcardViewComponent extends BasePage implements OnInit {
  @ViewChild("reject_job_card_panel", { static: true }) rejectJobCardPanel;
  @ViewChild("cancel_job_card_panel", { static: true }) cancelJobCardPanelSlider;
  @ViewChild(JobCardCancelFormComponent, { static: true }) cancelJobCardPanel: JobCardCancelFormComponent;
  @ViewChild("complete_job_card_panel", { static: true }) completeJobCardPanel;
  @ViewChild("complete_job_card_with_no_action_panel", { static: true }) completeJobCardWithNoActionPanel;
  @ViewChild("obtain_ccs_so_panel", { static: true }) obtainCcsSoPanel;
  @ViewChild("assign_staff_panel", { static: true }) assignStaffPanel;
  @ViewChild("modalEFax", { static: true }) modalEFax;
  @ViewChild("unTecoReasonPanel", { static: true }) openUnTecoReasonPanel;
  @ViewChild("unTecoReasonForm", { static: true }) unTecoReasonForm;
  @ViewChild("patch_ccs_so_number_panel", { static: true }) patchCcsSoNumberPanel;
  @ViewChild("reject_job_card_completion_panel", { static: true }) rejectJobCardCompletionPanel;

  @ViewChild(JobcardJobDescriptionComponent, { static: false }) cmJobDescElem: JobcardJobDescriptionComponent;
  @ViewChild("cmClientInfoElem", { static: false }) cmClientInfoElem;
  @ViewChild("cmEquipInfoElem", { static: false }) cmEquipInfoElem;
  @ViewChild(JobcardHaInformationComponent, { static: false }) cmHaInfoElem: JobcardHaInformationComponent;
  @ViewChild(JobcardTaskProgressComponent, { static: false }) cmTaskProgressElem: JobcardTaskProgressComponent;
  @ViewChild("cmProgressDetailsElem", { static: false }) cmProgressDetailsElem;
  @ViewChild("cmCcsInfoElem", { static: false }) cmCcsInfoElem;
  @ViewChild("cmAdditionalInfoElem", { static: false }) cmAdditionalInfoElem;
  @ViewChild("jobcardAttachmentDetailElem", { static: false }) jobcardAttachmentDetailElem;
  @ViewChild(JobCardStandaloneViewComponent, { static: false }) jobcardStandaloneViewElem: JobCardStandaloneViewComponent;

  @ViewChild("enquiryJobDescElem", { static: false }) enquiryJobDescElem;
  @ViewChild("enquiryClientInfoElem", { static: false }) enquiryClientInfoElem;
  @ViewChild("enquiryTaskProgressElem", { static: false }) enquiryTaskProgressElem;
  @ViewChild("enquiryProgressDetailsElem", { static: false }) enquiryProgressDetailsElem;
  @ViewChild("completeJobCardForm", { static: false }) completeJobCardForm;

  constructor(
    injector: Injector,
    private approvalService: ApprovalService,
    private modalService: NgbModal,
  ) {
    super(injector);
  }

  // const for HTML use
  jobCardStatusEnum = JMENUM.JobCardStatus;
  JMPriority = JMENUM.JMPriority;
  JMPage = JMENUM.JMPage;
  pageMode = JMENUM.JMPageMode.VIEW;
  jobNatureEnum = JMENUM.JobNature;

  componentParameters: any = {};
  selectedLanguage = Session.selectedLanguage;

  sn: JMOBJ.ServiceNotification = undefined;
  eamData: JMOBJ.EamData = undefined;
  jobCard: JMOBJ.JobCard;
  reserveItemsStockLevelObj: JM.JMOBJ.StockLevelObject[];
  snNumber: string;
  jobCardNumber: string;
  isHaEquipmentOwner: boolean = false;
  isJobCcsError: boolean;
  breadcrumbs: any = [];
  isInspectionShowStatus = [
    JMENUM.JobCardStatus.IN_PROGRESS,
    JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION,
    JMENUM.JobCardStatus.COMPLETED,
    JMENUM.JobCardStatus.REWORKING
  ]
  isInspectionHandlingParty = [
    JMENUM.HandlingParty.NON_PMSMC,
    JMENUM.HandlingParty.PMSMC
  ]
  isStandaloneMatType = [
    JMENUM.Mat.A01,
    JMENUM.Mat.M20
  ]
  isPageInitialFetch: boolean = true;
  // action buttons
  actionButtons: any[];
  disabledActionSideBar: boolean;
  disabledInput: boolean;

  // assigned person informations
  officerInChargejobAssignedInfo: any = {};
  teamMemberjobAssignedInfo: any[] = [];

  // reject jobcard panel
  rejectOptions: any[];
  isRejectPanelLoading = false;

  // complete jobcard panel
  completeJobCardFormParam = {} as any;

  // assign staff
  assignStaffFormParam = {} as any;
  showAllPostForReassign = false;
  workCentrePostList: any[];
  selectedAssignPersons = {
    assignedPersons: [],
    officerInCharge: null
  };

  // generate CCS SO slider panel
  generateCcsSoParam = {} as any;

  // attachment table
  fileList: any = {};
  tablexParam: any = {};

  // work centre attibute
  workCentreAttribute = {} as any;

  // teco
  selectedCount = 0;
  unTecoParam = {
    unTecoReasonCode: undefined,
    unTecoRemarks: undefined,
    isPanelSubmitLoading: false,
    isAcknowledgeAction: false,
    popUpSubMessage: ""
  };

  // reject completion panel
  isRejectCompletionPanelLoading = false;

  async ngOnInit() {
    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');

    await this.requestJobCardSummary();
    this.snNumber && await this.requestSn();
    this.isPageInitialFetch = false;

    this.initBreadcrumbs();

    this.componentParameters = {
      onClickViewAssignStaff: this.onClickViewAssignStaff,
      isShowPatchCcsSoNumberButton: this.isShowPatchCcsSoNumberButton,
      onClickPatchCcsSoNumber: this.onClickPatchCcsSoNumber,
      setJobCard: this.setJobCard,
      disabledBreakdown: true,
      breakdown: false,
      // isPassResponse: ,
      // isPassRectification: ,
    }
  }

  initPage() {
    this.resetActionSideBar();
    this.requestworkCentreAttribute();
  }

  
  initBreadcrumbs = () => {
    let arr = [];
    this.snNumber && arr.push({ id: 'breadcrumbs-sn-number', name: this.snNumber, route: '/sn/view/' + this.snNumber });
    arr.push({ id: 'breadcrumbs-job-card-number', name: this.jobCardNumber, route: null, currentPage: true });
    this.breadcrumbs = arr;
  }

  resetActionSideBar(): void {
    if (!this.jobCard) { return; }

    this.disabledActionSideBar = false;
    this.actionButtons = [];
    let jobCardStatus = this.jobCard.status ? ("" + this.jobCard.status) : null;
    const isSnPendingApproval = (this.sn && this.sn.pendingApproval) ? true : false;
    const isEnableActionButton = !isSnPendingApproval;

    let needCompleteWithNoAction = false;
    if (this.completeJobCardForm) {
      needCompleteWithNoAction = this.jobCard.jobNature !== this.jobNatureEnum.ENQUIRY && this.completeJobCardForm.checkHasNoActionOptions({
        jobNature: this.jobCard.jobNature,
        isHaEquipmentOwner: this.isHaEquipmentOwner,
        isFirstAttendedJobCard: this.isFirstAttendedJobCard(),
        hasFollowUpJobCard: this.sn ? this.sn.jobCards.length > 1 : false,
        orderType: this.jobCard.orderType
      });
    }

    if (!jobCardStatus ||
      !JobCardActionButtions[this.jobCard.jobNature] ||
      !JobCardActionButtions[this.jobCard.jobNature][jobCardStatus] ||
      !JobCardActionButtions[this.jobCard.jobNature][jobCardStatus][this.pageMode] ||
      !JobCardActionButtions[this.jobCard.jobNature][jobCardStatus][this.pageMode].length)
      return;

    let buttons = JobCardActionButtions[this.jobCard.jobNature][jobCardStatus][this.pageMode];
    for (let buttonStatus of buttons) {
      if (!this.hasActionButtonPermission(buttonStatus)) continue;

      let actionButton = ActionButtonDefinition[ActionButtonType.jobCard][buttonStatus];

      if (actionButton.action === ActionButtonJobCard.jobComplete) {
        actionButton.subButtons = [];

        if(needCompleteWithNoAction) {
          actionButton.subButtons.push(ActionButtonDefinition[ActionButtonType.jobCard][ActionButtonJobCard.jobCompleteHandled]);
          actionButton.subButtons.push(ActionButtonDefinition[ActionButtonType.jobCard][ActionButtonJobCard.jobCompleteWithNoAction]);
  
          for (let subBtn of actionButton.subButtons) {
            this.addActionButtonHandlerFn(subBtn, subBtn.action, isEnableActionButton);
          }
        } else {
          const enableJobCompleteButton: boolean = !(this.isHaEquipmentOwner && this.isPageInitialFetch);
          this.addActionButtonHandlerFn(actionButton, buttonStatus, enableJobCompleteButton);
        }
      } else {
        this.addActionButtonHandlerFn(actionButton, buttonStatus, isEnableActionButton);
      }
      this.actionButtons.push(actionButton);
    }
  }

  addActionButtonHandlerFn(actionButton: any, buttonStatus: any, isEnable: boolean) {
    if (!actionButton.buttons || !actionButton.buttons.length)
      actionButton.buttons = [
        { name: JMLanguage.translate("global.yes") },
        { name: JMLanguage.translate("global.no") }
      ];

    actionButton.isEnable = isEnable;
    actionButton.buttons[0].handler = this.getActionButtonCallback(buttonStatus);
  }

  initRejectSliderPanel(): void {
    this.rejectOptions = [];
    SnReason.forEach(reason => {
      if (reason.area === 'jobCard' && reason.orderType === 'reject') {
        this.rejectOptions.push({
          value: reason.id,
          description: JMLanguage.translate('jobcard-reason.' + reason.id)
        });
      }
    });
  }

  initCompleteJobCardPanel(): void {
    this.completeJobCardFormParam = {
      jobCardNumber: this.jobCard.jobCardNumber,
      ccsServiceOrderNumber: this.jobCard.ccsServiceOrderNumber,
      jobNature: this.jobCard.jobNature,
      orderType: this.jobCard.orderType,
      workCentre: this.sn && this.sn.team.workCentre,
      isHaEquipmentOwner: this.isHaEquipmentOwner,
      isAssignedPerson: this.isAssignedPerson(),
      isSnMember: this.isSnMember(),
      isFirstAttendedJobCard: this.isFirstAttendedJobCard(),
      isBorrowedTeam: this.isBorrowedTeam(),
      hasFollowUpJobCard: this.sn ? this.sn.jobCards.length > 1 : false,
      workCentreAttribute: this.workCentreAttribute,
      soObtainedByCcep: this.jobCard.soObtainedByCcep,
      requireNonComplianceReason: this.requireNonComplianceReason(),
      nonComplianceReason: this.jobCard.haNonComplianceReason,
      onSubmitClicked: (result) => {
        if (this.jobCard.soObtainedByCcep) {
          if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE) {
            this.requestCompleteStandaloneJobCard(result);
          } else {
            this.requestCompleteJobCard(result);
          }
        } else {
          AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.complete.alert-msg.designated-so"), "", [{
            name: JMLanguage.translate("global.ok"),
            handler: () => {
              if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE) {
                this.requestCompleteStandaloneJobCard(result);
              } else {
                this.requestCompleteJobCard(result);
              }
            }
          }]);
        }
      },
      onObtainSoClicked: () => {
        this.completeJobCardPanel.toggle();
        this.onGenerateCcsSoButtonClicked();
      }
    };
  }

  initObtainCcsSoSliderPanel() {
    this.generateCcsSoParam = {
      hasValue: true,
      jobCard: this.jobCard,
      sn: this.sn,
      isLoading: false,
      displayCcsErrorMessage: null
    };
  }

  initReassignStaffPanel(isJobCardData = true): void {
    if (this.showAllPostForReassign && this.workCentrePostList == null) {
      this.requestWorkCentrePostList();
      return;
    }

    let assignedPersons = [];
    let officerInCharge = null;
    let teamMembers = this.showAllPostForReassign ? this.workCentrePostList : this.jobCard.teamMembers;

    if (isJobCardData) {
      assignedPersons = this.jobCard.assignedPersons;
      officerInCharge = this.jobCard.officerInCharge;
      this.selectedAssignPersons.assignedPersons = assignedPersons;
      this.selectedAssignPersons.officerInCharge = officerInCharge;
    } else {
      assignedPersons = this.selectedAssignPersons.assignedPersons;
      officerInCharge = this.selectedAssignPersons.officerInCharge;
    }
    teamMembers = Array.from(new Set(teamMembers.concat(this.selectedAssignPersons.assignedPersons)));

    this.assignStaffFormParam = {
      teamMembers: teamMembers,
      assignedPersons: assignedPersons,
      officerInCharge: officerInCharge,
      jobCardNumber: this.jobCard.jobCardNumber,
      onSubmitClicked: (result) => {
        const message = JMLanguage.translate("popup.confirm-reassign.title");
        const buttons = [
          { name: JMLanguage.translate("popup.confirm-reassign.yes"), handler: () => { this.requestReassignStaff(result); } },
          { name: JMLanguage.translate('global.no'), handler: () => { this.assignStaffFormParam.isLoading = false; } }
        ];
        AppDelegate.showPopUpAlert(message, '', buttons);
      },
      onAddMemberClicked: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      },
      onRemoveMemberClicked: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      },
      onOfficerInChargeChanged: (result) => {
        this.selectedAssignPersons.assignedPersons = result.assignedPersons;
        this.selectedAssignPersons.officerInCharge = result.officerInCharge;
      }
    };
  }

  initViewAssignedStaffPanel(): void {
    this.assignStaffFormParam = {
      teamMembers: this.jobCard.teamMembers,
      assignedPersons: this.jobCard.assignedPersons,
      officerInCharge: this.jobCard.officerInCharge,
      viewOnly: true
    };
  }

  getJobCardFreezedActionList() {
    let freezedTecoStatusList = [(Number)(JMENUM.TecoStatus.PENDING_FOR_TECO), (Number)(JMENUM.TecoStatus.SUCCEEDED), (Number)(JMENUM.TecoStatus.PENDING_FOR_UNTECO)];
    let freezedActionList = [ActionButtonJobCard.jobReopen, /*ActionButtonJobCard.timeSheet*/]; // timesheet view only
    let freezed = freezedTecoStatusList.includes((Number)(this.jobCard.tecoStatus));

    return freezed ? freezedActionList : [];
  }

  //========= API =========//
  private async requestSn() {
    if (!this.snNumber) return;
    const request = new JM.JMRequestSnGetSn();
    request.snNumber = this.snNumber;

    const response: JM.JMResponseSnGetSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.sn = response.payload;
    this.isHaEquipmentOwner = (this.sn && this.sn.equipmentOwner === JMENUM.SnEquipmentOwner.HA) ? true : false;
    this.resetActionSideBar();
  }

  private async requestJobCardSummary() {
    const request = new JM.JMRequestJobCardsGetJobCard();
    request.jobCardNumber = this.jobCardNumber;
    request.isGettingLatestStockNumbers = true;

    const response: JM.JMResponseJobCardsGetJobCard = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      return;
    }

    if (response.payload.stockLevel) {
      this.reserveItemsStockLevelObj = response.payload.stockLevel;
    }
    if (response.payload.jobCard) {
      this.jobCard = response.payload.jobCard;
      this.snNumber = this.jobCard.snNumber;
      this.initPage();
    }
  }

  private async requestAcceptJobCard() {
    const request = new JM.JMRequestJobCardsAccept();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;

    this.disabledActionSideBar = true;
    const response: JM.JMResponseJobCardsAccept = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.setJobCard(response.payload);
    AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.accepted"));
  }

  private async requestReopenJobCard() {
    const request = new JM.JMRequestJobCardsReopen();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;

    this.disabledActionSideBar = true;
    const response: JM.JMResponseJobCardsReopen = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.setJobCard(response.payload);
    AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.reopened"));
  }

  public async requestRejectJobCard(param: {
    rejectCode: number, rejectRemarks: string
  }) {
    const request = new JM.JMRequestJobCardsReject();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.rejectCode = param.rejectCode;
    request.rejectRemarks = param.rejectRemarks;

    this.isRejectPanelLoading = true;
    this.disabledActionSideBar = true;

    const response: JM.JMResponseJobCardsReject = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;
    this.isRejectPanelLoading = false;

    if (response.error) {
      AppDelegate.openErrorBar(response);
      this.isRejectPanelLoading = false;
      return;
    }
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.setJobCard(response.payload);
    this.rejectJobCardPanel.close();
    this.isRejectPanelLoading = false;
    AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.rejected"));
  }

  private async requestCompleteJobCard(param: {
    completeCode: number,
    completeRemarks: string,
    requireCreateFollowUpJob: boolean,
    followUpReasonCode: number,
    followUpRemarks: string,
    followUpJobassignedPersons: string[],
    followUpJobOfficerInCharge: string,
    approver: string[],
    haNonComplianceReason: JMREASON.HaNonComplianceReason,
  }) {
    if (this.completeJobCardFormParam) this.completeJobCardFormParam.isLoading = true;

    const request = new JM.JMRequestJobCardsComplete();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.completeCode = param.completeCode;
    request.completeRemarks = param.completeRemarks;
    request.requireCreateFollowUpJob = param.requireCreateFollowUpJob;
    request.followUpReasonCode = param.followUpReasonCode;
    request.followUpRemarks = param.followUpRemarks;
    request.followUpJobassignedPersons = param.followUpJobassignedPersons;
    request.followUpJobOfficerInCharge = param.followUpJobOfficerInCharge;
    request.approver = param.approver;
    request.haNonComplianceReason = param.haNonComplianceReason;

    this.disabledActionSideBar = true;
    const response: JM.JMResponseJobCardsComplete = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;
    if (this.completeJobCardFormParam) this.completeJobCardFormParam.isLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.isRejectPanelLoading = false;
      return;
    }
    this.setJobCard(response.payload.completedJobCard);
    let followUpJobCard = response.payload.followUpJobCard;
    if (followUpJobCard) {
      // this.dataChange.emit({ redirectJobCardNumber: followUpJobCard.jobCardNumber, jobCard: followUpJobCard });
    }

    this.completeJobCardPanel.close();
    this.completeJobCardWithNoActionPanel.close();
    if (this.completeJobCardFormParam) this.completeJobCardFormParam.isLoading = false;

    if (this.jobCard.status == JMENUM.JobCardStatus.COMPLETED) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.completed"));
    } else if (this.jobCard.status == JMENUM.JobCardStatus.CANCELLED) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.completed"));
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.waiting-complete"));
    }
  }

  private async requestCompleteStandaloneJobCard(param: {
    completeCode: number,
    completeRemarks: string,
    requireCreateFollowUpJob: boolean,
    followUpReasonCode: number,
    followUpRemarks: string,
    followUpJobassignedPersons: string[],
    followUpJobOfficerInCharge: string,
    approver: string[]
  }) {
    if (this.completeJobCardFormParam) this.completeJobCardFormParam.isLoading = true;

    const request = new JM.JMRequestJobCardsCompleteStandaloneJob();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.completeCode = param.completeCode;
    request.completeRemarks = param.completeRemarks;

    this.disabledActionSideBar = true;
    const response: JM.JMResponseJobCardsCompleteStandaloneJob = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;
    if (this.completeJobCardFormParam) this.completeJobCardFormParam.isLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.isRejectPanelLoading = false;
      return;
    }

    this.setJobCard(response.payload);
    this.completeJobCardPanel.close();
    this.completeJobCardWithNoActionPanel.close();
    if (this.completeJobCardFormParam) this.completeJobCardFormParam.isLoading = false;

    if (this.jobCard.status == JMENUM.JobCardStatus.COMPLETED) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.completed"));
    } else if (this.jobCard.status == JMENUM.JobCardStatus.CANCELLED) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.completed"));
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.waiting-complete"));
    }
  }

  private async requestCompleteEnquiryJobCard() {
    const request = new JM.JMRequestJobCardsCompleteEnquiry();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;

    this.disabledActionSideBar = true;
    const response: JM.JMResponseJobCardsCompleteEnquiry = await AppDelegate.sendJMRequest(request);
    this.disabledActionSideBar = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.isRejectPanelLoading = false;
      return;
    }

    this.setJobCard(response.payload);
    if (this.jobCard.status == JMENUM.JobCardStatus.COMPLETED) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.completed"));
    } else if (this.jobCard.status == JMENUM.JobCardStatus.CANCELLED) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.completed"));
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.waiting-complete"));
    }
  }

  private async requestObtainCcsSo(param: CcsSoSubmitFormReturnParam) {
    const request = new JM.JMRequestJobCardsObtainCcsSoNumber();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    if (param.data.equipment) { request.equipment = param.data.equipment; }
    if (param.data.serviceLevelAgreementNumber) { request.serviceLevelAgreementNumber = param.data.serviceLevelAgreementNumber; }
    if (param.data.serviceLevelAgreementItem) { request.serviceLevelAgreementItem = param.data.serviceLevelAgreementItem; }
    if (param.data.functionalLocation) { request.functionalLocation = param.data.functionalLocation; }

    this.generateCcsSoParam.displayCcsErrorMessage = '';
    this.generateCcsSoParam.isLoading = true;

    const response: JM.JMResponseJobCardsObtainCcsSoNumber = await AppDelegate.sendJMRequest(request);
    this.generateCcsSoParam.isLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      if (response && response.code) {
        switch (response.code) {
          case 8053: // CCS_SO_ALREADY_OBTAINED
            this.obtainCcsSoPanel.close();
            break;
          case 8064: // CCS_SO_NUMBER_OBTAIN_FAILURE
            this.generateCcsSoParam.displayCcsErrorMessage = response.error;
            break;
        }
      }
      AppDelegate.openErrorBar(response);
      return;
    }

    this.setJobCard(response.payload);

    if (this.jobCard.soObtainedByCcep) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.obtained"));
      this.obtainCcsSoPanel.close();
    } else {
      AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.generate-ccs-so.alert-msg.designated-so"), "", [{
        name: JMLanguage.translate("global.ok"),
        handler: () => {
          this.obtainCcsSoPanel.close();
          AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.obtained"));
        }
      }]);
    }
  }

  private async requestAssignCcsSo(param: CcsSoSubmitFormReturnParam) {
    this.generateCcsSoParam.isLoading = true;
    const request = new JM.JMRequestJobCardsAssignCcsSoNumber();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = parseInt(this.jobCard.version.toString());  // Number to number (Object to primitive)
    if (param.data.ccsServiceOrderNumber) { request.ccsServiceOrderNumber = param.data.ccsServiceOrderNumber; }
    this.generateCcsSoParam.displayCcsErrorMessage = '';

    const response: JM.JMResponseJobCardsAssignCcsSoNumber = await AppDelegate.sendJMRequest(request);
    this.generateCcsSoParam.isLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.setJobCard(response.payload);
    if (this.jobCard.soObtainedByCcep) {
      this.obtainCcsSoPanel.close();
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.assigned"));
    } else {
      AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.generate-ccs-so.alert-msg.designated-so"), "", [{
        name: JMLanguage.translate("global.ok"),
        handler: () => {
          this.obtainCcsSoPanel.close();
          AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.assigned"));
        }
      }]);
    }
  }

  private async requestworkCentreAttribute() {
    if (!this.jobCard.workCentre) return;
    const request = new JM.JMRequestWorkCentresWorkCentreSummary();
    request.workCentreCodeList = [this.jobCard.workCentre];
    request.parameters = ['enableObtainSO', 'requiredObtainSO', 'enableTimesheet'];
    request.includeSummary = true;

    const response: JM.JMResponseWorkCentresWorkCentreSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload.records.length > 0) {
      this.workCentreAttribute = response.payload.records[0];
    }

    this.resetActionSideBar();
  }

  private async requestReassignStaff(param: {
    assignedPersons: string[],
    officerInCharge: string
  }) {
    if (this.assignStaffFormParam) this.assignStaffFormParam.isLoading = true;
    const request = new JM.JMRequestJobCardsReassign();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.assignedPersons = param.assignedPersons;
    request.officerInCharge = param.officerInCharge;

    const response: JM.JMResponseJobCardsReassign = await AppDelegate.sendJMRequest(request);
    if (this.assignStaffFormParam) this.assignStaffFormParam.isLoading = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.isRejectPanelLoading = false;
      return;
    }
    this.setJobCard(response.payload);
    this.assignStaffPanel.close();
    AppDelegate.openSnackBar(JMLanguage.translate("pages.sn.reassigned"));
  }

  private async requestWorkCentrePostList() {
    this.assignStaffFormParam.isLoading = true;
    const request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.authorizations = { 'workCenters': this.jobCard.workCentre };
    request.active = JMENUM.RequestActive.ACTIVE;
    request.pageSize = 1000;

    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.assignStaffFormParam.isLoading = false

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.showAllPostForReassign = false;
      this.isRejectPanelLoading = false;
      return;
    }
    if (!response.payload.records || !response.payload.records.length) {
      this.showAllPostForReassign = false;
      return;
    }
    this.workCentrePostList = response.payload.records.map(post => { return post.name });
    this.initReassignStaffPanel(false);
  }

  public async requestTeco() {
    const request = new JM.JMRequestJobCardsTeco();
    request.tecoList = [{ version: this.jobCard.version, jobCardNumber: this.jobCard.jobCardNumber }];

    this.handleDisableActionButton(ActionButtonTeco.teco, false);

    const response: JM.JMResponseJobCardsTeco = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records || response.payload.records.length == 0) {
      AppDelegate.openErrorBar(response);
      return;
    }
    this.updateExistingJobCard(response.payload.records);
    this.handleDisableActionButton(ActionButtonTeco.teco, true);
    this.resetActionSideBar();

    if (response.payload.records[0].errorMessage) {
      AppDelegate.openSnackBar(response.payload.records[0].errorMessage);
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.un-teco.message.teco.success"));
    }
  }

  public async requestWithdrawTeco() {
    const request = new JM.JMRequestJobCardsWithdrawTeco();
    request.withdrawList = [{ version: this.jobCard.version, jobCardNumber: this.jobCard.jobCardNumber }];

    this.handleDisableActionButton(ActionButtonTeco.withDraw, false);

    const response: JM.JMResponseJobCardsWithdrawTeco = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records || response.payload.records.length == 0) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.updateExistingJobCard(response.payload.records);
    this.handleDisableActionButton(ActionButtonTeco.withDraw, true);
    this.resetActionSideBar();

    if (response.payload.records[0].errorMessage) {
      AppDelegate.openSnackBar(response.payload.records[0].errorMessage);
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.un-teco.message.withdraw-teco.success"));
    }
  }

  public async requestUnTeco() {
    const request = new JM.JMRequestJobCardsUnTeco();
    request.unTecoList = [{
      version: this.jobCard.version,
      jobCardNumber: this.jobCard.jobCardNumber,
      unTecoCode: this.unTecoParam.unTecoReasonCode,
      unTecoRemarks: this.unTecoParam.unTecoRemarks
    }];

    this.handleDisableActionButton(ActionButtonUnTeco.unTeco, false);
    this.unTecoParam.isPanelSubmitLoading = true;
    const response: JM.JMResponseJobCardsUnTeco = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload || !response.payload.records || response.payload.records.length == 0) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.updateExistingJobCard(response.payload.records);
    this.unTecoParam = {
      unTecoReasonCode: undefined,
      unTecoRemarks: undefined,
      isPanelSubmitLoading: false,
      isAcknowledgeAction: false,
      popUpSubMessage: ""
    };
    this.openUnTecoReasonPanel.close();
    this.handleDisableActionButton(ActionButtonUnTeco.unTeco, true);
    this.resetActionSideBar();

    if (response.payload.records[0].errorMessage) {
      AppDelegate.openSnackBar(response.payload.records[0].errorMessage);
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.un-teco.message.un-teco.success"));
    }
  }

  private async requestApproveRequest() {
    if (!this.jobCard || !this.jobCard.approvalObject || !this.jobCard.approvalObject._id) {
      return;
    }

    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = [{
      approvalAction: JMENUM.ApprovalAction.APPROVE,
      approvalId: this.jobCard.approvalObject._id,
    }];

    this.handleDisableActionButton(ActionButtonJobCard.approve, true);
    const records = await this.approvalService.requestBatchProcessRequest(request);
    this.handleDisableActionButton(ActionButtonJobCard.approve, false);

    if (!records.length || !records[0].success) {
      let errorMessage = records.length && records[0].errorMessage ? records[0].errorMessage : JMLanguage.translate('api.error.99999');
      AppDelegate.openSnackBar(errorMessage);
      return;
    }

    AppDelegate.openSnackBar(JMLanguage.translate('global.approved'));
    this.requestJobCardSummary();
  }

  public async requestRejectCompletion(remarks: string) {
    if (!this.jobCard || !this.jobCard.approvalObject || !this.jobCard.approvalObject._id) {
      return;
    }

    let request = new JM.JMRequestApprovalsBatchProcessRequest;
    request.requestList = [{
      approvalAction: JMENUM.ApprovalAction.REJECT,
      approvalId: this.jobCard.approvalObject._id,
      customParameters: {
        rejectReason: remarks
      },
    }];

    this.isRejectCompletionPanelLoading = true;
    const records = await this.approvalService.requestBatchProcessRequest(request);
    this.isRejectCompletionPanelLoading = false;

    if (!records.length || !records[0].success) {
      let errorMessage = records.length && records[0].errorMessage ? records[0].errorMessage : JMLanguage.translate('api.error.99999');
      AppDelegate.openSnackBar(errorMessage);
      return;
    }

    this.rejectJobCardCompletionPanel.close();
    AppDelegate.openSnackBar(JMLanguage.translate('global.rejected'));
    this.requestJobCardSummary();
  }

  //========= UI Function =========//
  setJobCard = (jobCard) => {
    this.jobCard = jobCard;
    this.resetActionSideBar();
  }

  onJobCardDropdownSummaryReady() {
    const isSnPendingApproval = (this.sn && this.sn.pendingApproval) ? true : false;
    const isEnableActionButton = !isSnPendingApproval;

    this.handleDisableActionButton(ActionButtonJobCard.jobComplete, isEnableActionButton);
  }

  isShowPatchCcsSoNumberButton = () => {
    if (JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.JOBCARD_UPDATE_ALL)
      && !this.jobCard.ccsServiceOrderNumber
      && [JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION, JMENUM.JobCardStatus.REWORKING, JMENUM.JobCardStatus.IN_PROGRESS, JMENUM.JobCardStatus.CANCELLING].includes(this.jobCard.status)) {
      return true;
    }

    return false;
  }

  onClickPatchCcsSoNumber = () => {
    this.patchCcsSoNumberPanel.toggle();
  }

  onPatchCcsSoSuccessSubmit(event: JMOBJ.JobCard) {
    this.setJobCard(event);
    this.patchCcsSoNumberPanel.close();
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = JMLanguage.translate(button.name);
      });
      AppDelegate.showPopUpAlert(JMLanguage.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  onClickViewAssignStaff = () => {
    this.initViewAssignedStaffPanel();
    this.assignStaffPanel.toggle();
  }

  onSubmitCcsSoForm(param: CcsSoSubmitFormReturnParam) {
    if (!param || !param.data) return;

    switch (param.ccsSoOption) {
      case "assign":
        this.requestAssignCcsSo(param);
        break;
      case "obtainByEquipment":
      case "obtainByLocation":
        this.requestObtainCcsSo(param);
        break;
      default:
        break;
    }
  }

  //========= Action Button Event =========//
  onEditButtonClicked = () => {
    this.router.navigate(['/job-card/edit/' + this.jobCardNumber]);
  }

  onAcceptButtonClicked = () => {
    this.requestAcceptJobCard();
  }

  onRejectButtonClicked = () => {
    this.initRejectSliderPanel();
    this.rejectJobCardPanel.toggle();
  }

  onCancelButtonClicked = () => {
    this.cancelJobCardPanel.initForm();
    this.cancelJobCardPanelSlider.toggle();
  }

  async onJobCardCancelComplete() {
    this.cancelJobCardPanelSlider.close();
    await this.requestJobCardSummary();
    this.snNumber && await this.requestSn();
  }

  onEFaxButtonClicked = () => {
    let detail = {
      teamName: undefined,
      attention: undefined,
      faxNumber: null,
    };
    let modalRef = this.modalService.open(ModalManuelFaxComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.faxDetail = detail;
    modalRef.componentInstance.onResendClicked = async (result) => {
      modalRef.componentInstance.setLoading(true);

      const request = new JM.JMRequestNotificationFaxJobCard();
      request.jobCardNumber = this.jobCard.jobCardNumber;
      request.faxNumber = result.faxNumber;
      request.lang = this.selectedLanguage;

      const response: JM.JMResponseNotificationFaxJobCard = await AppDelegate.sendJMRequest(request);
      modalRef.componentInstance.setLoading(false);
      if (!response || !response.code || response.code != 200 || response.error || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      }
      modalRef.close()
    };
  }

  private async onExportPdfButtonClicked() {
    const request = new JM.JMRequestNotificationExportJobCardPDF();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.lang = Session.selectedLanguage;

    const response = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    let base64 = response.payload.base64;
    let blob = utility.base64ToBlob(base64, response.payload.type);
    saveAs(blob, `${this.jobCard.jobCardNumber}.pdf`);
  }

  private async onExportHaPdfButtonClicked() {
    const request = new JM.JMRequestNotificationExportHAJobCardPDF();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.lang = Session.selectedLanguage;

    const response: JM.JMResponseNotificationExportHAJobCardPDF = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    let base64 = response.payload.base64;
    let blob = utility.base64ToBlob(base64, response.payload.type);
    saveAs(blob, `${this.jobCard.jobCardNumber}.pdf`);
  }

  onReassignButtonClicked = () => {
    this.showAllPostForReassign = false
    this.initReassignStaffPanel(true);
    this.assignStaffPanel.toggle();
  }

  onCompleteButtonClicked = () => {
    switch (this.jobCard.jobNature) {
      case JMENUM.JobNature.CM:
      case JMENUM.JobNature.PM:
        if (this.isHaEquipmentOwner) {
          let validSectionJobDesc = this.cmJobDescElem.validation();
          let validSectionTaskProgress = this.cmTaskProgressElem.validation();
          let validSectionHaInfo = this.cmHaInfoElem.validation();
          if (!validSectionJobDesc || !validSectionTaskProgress || !validSectionHaInfo) {
            return;
          }
          if (!this.sn.eamData) {
            console.error('No EAM data');
            return;
          }
          if (this.sn.maintenanceType === JMENUM.MaintenanceType.CM && !this.sn.eamData.cmBreakdownDate) {
            AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard-view.error-msg.missing-sn-breakdown-time"));
            return;
          }
          if (this.sn.maintenanceType === JMENUM.MaintenanceType.CM && this.sn.eamData.cmBreakdownDate > this.jobCard.equipmentReceivedDate) {
            AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard-view.error-msg.equipment-received-date-earlier-than-breakdown"));
            return;
          }
          if (this.sn.maintenanceType === JMENUM.MaintenanceType.CM && this.sn.eamData.cmBreakdownDate > this.jobCard.startTime) {
            AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard-view.error-msg.job-start-time-earlier-than-breakdown"));
            return;
          }
          if (this.sn.maintenanceType === JMENUM.MaintenanceType.CM && this.jobCard.equipmentReceivedDate > this.jobCard.startTime) {
            AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard-view.error-msg.job-start-time-earlier-than-equipment-received-date"));
            return;
          }
          if (this.sn.maintenanceType === JMENUM.MaintenanceType.PM && this.sn.eamData.replyToHA === true && !this.sn.eamData.pmScheduleDate) {
            AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard-view.error-msg.missing-pm-schedule-time"));
            return;
          }
        } else {
          if (!this.cmJobDescElem.validation() || !this.cmTaskProgressElem.validation()) {
            return;
          }
        }

        this.initCompleteJobCardPanel();
        this.completeJobCardPanel.toggle();
        break;

      case JMENUM.JobNature.STANDALONE:
        if (!this.jobcardStandaloneViewElem.validation()) {
          return;
        }
        this.initCompleteJobCardPanel();
        this.completeJobCardPanel.toggle();
        break;

      case JMENUM.JobNature.ENQUIRY:
        if (!this.enquiryJobDescElem.validation()) return;
        let message = JMLanguage.translate('action.button.popup.complete-job');
        let buttons = [
          { name: JMLanguage.translate('action.button.popup.confirm-complete-job'), handler: () => { this.requestCompleteEnquiryJobCard(); } },
          { name: JMLanguage.translate('global.no') }
        ]

        AppDelegate.showPopUpAlert(message, "", buttons);
        break;
    }
  }

  onCompleteWithNoActionuttonClicked = () => {
    this.initCompleteJobCardPanel();
    this.completeJobCardWithNoActionPanel.toggle();
  }

  onGenerateCcsSoButtonClicked = () => {
    this.initObtainCcsSoSliderPanel();
    this.obtainCcsSoPanel.toggle();
  }

  onCreateDossPoButtonClicked() {
    const param = {
      "sap-language": "EN",
      "sap-client": environment.DOSS_CLIENT_ID,
      "ccep-jobno": this.jobCard.ccsServiceOrderNumber,
    }
    const queryStr = qs.stringify(param);
    window.open(`${environment.DOSS_HOST}/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html?${queryStr}#Z_SEM_PO-create&/poCreate/OneColumn`);
  }

  onReopenButtonClicked = () => {
    this.requestReopenJobCard();
  }

  onReserveStockButtonClicked = () => {
    this.router.navigate([`/job-card/view/${this.jobCardNumber}/stock-reserve`]);
  }

  onAttachmentButtonClicked = () => {
    this.router.navigate(['/job-card/view/' + this.jobCardNumber + '/attachment']);
  }

  onInspectButtonClicked = () => {
    this.router.navigate([`/job-card/inspect/${this.jobCardNumber}`]);
  }

  onTimeSheetButtonClicked = () => {
    if (!this.jobCard.soObtainedByCcep) {
      AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.timesheet.alert-msg.designated-so"), "", [{
        name: JMLanguage.translate("global.ok")
      }]);
    } else if (this.jobCard.startTime && this.jobCard.completionTime) {
      this.router.navigate(["/timesheet/job/", this.jobCard.jobCardNumber]);
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.start-end-time-not-completed"));
    }
  }

  onTimeSheetV2ButtonClicked = () => {
    if (!this.jobCard.soObtainedByCcep) {
      AppDelegate.showPopUpAlert(JMLanguage.translate("pages.sn.timesheet.alert-msg.designated-so"), "", [{
        name: JMLanguage.translate("global.ok")
      }]);
    } else if (this.jobCard.startTime && this.jobCard.completionTime) {
      this.router.navigate(["/timesheet/job/v2/", this.jobCard.jobCardNumber]);
    } else {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.jobcard.error.start-end-time-not-completed"));
    }
  }

  onTecoButtonClicked = () => {
    this.requestTeco();
  }

  onWithdrawTecoButtonClicked = () => {
    this.requestWithdrawTeco();
  }

  public updateExistingJobCard(records) {
    if (records.length > 0) {
      this.jobCard = records[0].jobCard;
    }
  }

  onUnTecoButtonClicked = () => {
    this.selectedCount = 1;
    this.openUnTecoReasonPanel.toggle();
  }

  onApproveButtonClicked = () => {
    this.requestApproveRequest();
  }

  onRejectCompletionButtonClicked = () => {
    this.rejectJobCardCompletionPanel.toggle();
  }

  public onSubmitUnTeco(event) {
    this.requestUnTeco();
  }

  // Return action button callback
  getActionButtonCallback(buttonStatus: ActionButtonJobCard): any {
    switch (buttonStatus) {
      case ActionButtonJobCard.edit:
        return this.onEditButtonClicked;
      case ActionButtonJobCard.accept:
        return this.onAcceptButtonClicked;
      case ActionButtonJobCard.reject:
        return this.onRejectButtonClicked;
      case ActionButtonJobCard.eFax:
        return this.onEFaxButtonClicked;
      case ActionButtonJobCard.exportPdf:
        return () => { this.onExportPdfButtonClicked() };
      case ActionButtonJobCard.exportHaPdf:
        return () => { this.onExportHaPdfButtonClicked() };
      case ActionButtonJobCard.reassign:
        return this.onReassignButtonClicked;
      case ActionButtonJobCard.jobComplete:
        return this.onCompleteButtonClicked;
      case ActionButtonJobCard.jobCompleteHandled:
        return this.onCompleteButtonClicked;
      case ActionButtonJobCard.jobCompleteWithNoAction:
        return this.onCompleteWithNoActionuttonClicked;
      case ActionButtonJobCard.cancel:
        return this.onCancelButtonClicked;
      case ActionButtonJobCard.generateCcsSo:
        return this.onGenerateCcsSoButtonClicked;
      case ActionButtonJobCard.createDossPo:
        return this.onCreateDossPoButtonClicked.bind(this);
      case ActionButtonJobCard.jobReopen:
        return this.onReopenButtonClicked;
      case ActionButtonJobCard.reserveStock:
        return this.onReserveStockButtonClicked;
      case ActionButtonJobCard.attach:
        return this.onAttachmentButtonClicked;
      case ActionButtonJobCard.timeSheet:
        return this.onTimeSheetButtonClicked;
      case ActionButtonJobCard.timeSheetV2:
        return this.onTimeSheetV2ButtonClicked;
      case ActionButtonJobCard.teco:
        return this.onTecoButtonClicked;
      case ActionButtonJobCard.withDraw:
        return this.onWithdrawTecoButtonClicked;
      case ActionButtonJobCard.unTeco:
        return this.onUnTecoButtonClicked;
      case ActionButtonJobCard.approve:
        return this.onApproveButtonClicked;
      case ActionButtonJobCard.rejectCompletion:
        return this.onRejectCompletionButtonClicked;
      case ActionButtonJobCard.inspect:
        return this.onInspectButtonClicked;
      default:
        return () => { };
    }
  }

  private handleDisableActionButton(button, isEnable) {
    let actionButton = this.actionButtons.find(({ action }) => action == button);
    actionButton && (actionButton.isEnable = isEnable);
  }

  private hasActionButtonPermission(buttonStatus) {
    let actionButton = ActionButtonDefinition[ActionButtonType.jobCard][buttonStatus];
    if (Array.isArray(actionButton.permission)) {
      if (actionButton.buttons && !this.authorizationService.hasPermissions(actionButton.permission, false)) {
        return false;
      }
    } else {
      if (actionButton.buttons && !this.authorizationService.hasPermission(actionButton.permission)) {
        return false;
      }
    }

    if (this.getJobCardFreezedActionList().includes(buttonStatus)) { return false; }

    let handlingParty = this.jobCard.handlingParty;
    let status = this.jobCard.status;

    // other permission required
    switch (buttonStatus) {
      case ActionButtonJobCard.generateCcsSo:
        if (!this.workCentreAttribute.enableObtainSO) return false;
        if (this.jobCard.ccsServiceOrderNumber) return false;
        break;
      case ActionButtonJobCard.timeSheet:
        if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JOB_CARD_TIMESHEET)) return false;
        if (!this.workCentreAttribute.enableTimesheet) return false;
        if (this.jobCard.orderType !== JMENUM.OrderType.SLA_JOB) return false;
        if (!this.jobCard.ccsServiceOrderNumber || handlingParty != JMENUM.HandlingParty.INHOUSE) return false;
        break;
      case ActionButtonJobCard.timeSheetV2:
        if (!utility.isEnabledFeature(Session, JMCONSTANT.JMFeature.JOB_CARD_TIMESHEET_V2)) return false;
        if (!this.workCentreAttribute.enableTimesheet) return false;
        if (this.jobCard.orderType !== JMENUM.OrderType.SLA_JOB) return false;
        if (!this.jobCard.ccsServiceOrderNumber || handlingParty != JMENUM.HandlingParty.INHOUSE) return false;
        break;
      case ActionButtonJobCard.edit:
        if (!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_UPDATE_ALL)) return false;
        break;
      case ActionButtonJobCard.accept:
        if ((!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_ACCEPT_ALL)) || handlingParty == JMENUM.HandlingParty.PMSMC) return false;
        break;
      case ActionButtonJobCard.reject:
        if ((!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_REJECT_ALL)) || handlingParty == JMENUM.HandlingParty.PMSMC) return false;
        break;
      case ActionButtonJobCard.reassign:
        if ((!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_UPDATE_ALL)) || handlingParty == JMENUM.HandlingParty.PMSMC || handlingParty == JMENUM.HandlingParty.NON_PMSMC) return false;
        break;
      case ActionButtonJobCard.jobComplete:
        if ((!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_COMPLETE_ALL)) || handlingParty == JMENUM.HandlingParty.PMSMC) return false;
        break;
      case ActionButtonJobCard.cancel:
        if ((!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.TEAM_ALL)) || this.jobCard.jobNature != JMENUM.JobNature.STANDALONE) return false;
        break;
      case ActionButtonJobCard.attach:
        if ((!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_UPDATE_ALL)) || handlingParty == JMENUM.HandlingParty.PMSMC) return false;
        break;
      case ActionButtonJobCard.eFax:
        if (!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_UPDATE_ALL)
          || status == JMENUM.JobCardStatus.CANCELLED) return false;
        // (status == JMENUM.JobCardStatus.COMPLETED || status == JMENUM.JobCardStatus.CANCELLED))
        break;
      case ActionButtonJobCard.exportPdf:
        if (handlingParty != JMENUM.HandlingParty.INHOUSE && status == JMENUM.JobCardStatus.CANCELLED) return false;
        break;
      case ActionButtonJobCard.exportHaPdf:
        if ((handlingParty != JMENUM.HandlingParty.INHOUSE && status == JMENUM.JobCardStatus.CANCELLED) || !this.isHaEquipmentOwner) return false;
        break;
      case ActionButtonJobCard.jobReopen:
        if ((!this.isTeamMember() && !this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_REOPEN_ALL)) &&
          (!this.authorizationService.hasPermission(JMENUM.Permission.PMSMC_JOB_REOPEN) || handlingParty != JMENUM.HandlingParty.PMSMC) ||
          (this.sn !== undefined && this.sn.status === JMENUM.SnStatus.SIGNED_OFF)) return false;
        break;
      case ActionButtonJobCard.reserveStock:
        if (!this.isAllowReserveStock()) return false;
        break;
      case ActionButtonJobCard.createDossPo:
        if (!this.isAllowCreateDossPo()) return false;
        break;
      case ActionButtonJobCard.teco:
        if (!this.isAllowToTeco()) return false;
        break;
      case ActionButtonJobCard.withDraw:
        if (!this.isAllowToWithdrawTeco()) return false;
        break;
      case ActionButtonJobCard.unTeco:
        if (!this.isAllowToUnTeco()) return false;
        let unTecoPermission = "jobcard." + this.jobCard.orderType.toLocaleLowerCase() + ".unteco";
        if (!this.authorizationService.hasPermissions([JMENUM.Permission.JOBCARD_UNTECO_ALL, unTecoPermission], false)) {
          return false;
        }
        break;
      case ActionButtonJobCard.approve:
      case ActionButtonJobCard.rejectCompletion:
        const approvalObject = this.jobCard.approvalObject;
        const hasApprover = this.isApproverByApprovalObject(approvalObject);
        const hasEditPermission = this.hasPermissionByApprovalObject(approvalObject);

        const showButton = hasApprover || hasEditPermission;
        return showButton;
      case ActionButtonJobCard.inspect:
        if (!this.jobCard || !this.jobCard.handlingParty || !this.isInspectionHandlingParty.includes(this.jobCard.handlingParty)) {
          return false;
        }
        if (this.jobCard.orderType !== JMENUM.OrderType.SLA_JOB) return false;
        if (!this.jobCard.ccsServiceOrderNumber) return false;
        if (this.jobCard.jobNature == JMENUM.JobNature.ENQUIRY) return false;
        if (!this.jobCard || !this.jobCard.matType || this.isStandaloneMatType.includes(this.jobCard.matType) ){
          return false
        }
        return utility.isEnabledFeature(Session,JMCONSTANT.JMFeature.JOB_CARD_INSPECTION)  && this.isInspectionShowStatus.includes(status);
      default:
        break;
    }

    return true;
  }

  //========= Validation =========//
  private isFirstAttendedJobCard() {
    let tmpArr = this.jobCard.jobCardNumber.split("-");
    return tmpArr.length > 1 && tmpArr[1] == '01';
  }

  private isTeamMember(member?) {
    if (member == null) member = Session.userInfo.name
    if (!this.jobCard || !this.jobCard.teamMembers || !this.jobCard.teamMembers.length) {
      return false;
    }
    return this.jobCard.teamMembers.includes(member);
  }

  private isAssignedPerson(member?) {
    if (member == null) member = Session.userInfo.name
    if (!this.jobCard || !this.jobCard.assignedPersons || !this.jobCard.assignedPersons.length) {
      return false;
    }
    return this.jobCard.assignedPersons.includes(member);
  }

  private isSnMember(member?) {
    if (member == null) member = Session.userInfo.name
    if (!this.sn || !this.sn.team || !this.sn.team.members || !this.sn.team.members.length) {
      return false;
    }
    return this.sn.team.members.includes(member);
  }

  private requireNonComplianceReason() {
    //this.sn.maintenanceType === JMENUM.MaintenanceType.PM
    if (this.jobCard.jobNature != JMENUM.JobNature.PM) return false;
    if (!this.sn.eamData) return false;

    if (this.jobCard.haComplianceStatus) {
      return this.jobCard.haComplianceStatus == JMENUM.HaComplianceStatus.FAILED;
    } else return false;
  }

  private isAllowReserveStock() {
    if (!this.jobCard.ccsServiceOrderNumber) return false;

    if ([
      JMENUM.JobcardTecoStatus.SUCCEEDED,
      JMENUM.JobcardTecoStatus.PENDING_FOR_TECO,
      JMENUM.JobcardTecoStatus.PENDING_FOR_UNTECO
    ].includes(this.jobCard.tecoStatus)) return false;

    if (this.jobCard.handlingParty !== JMENUM.HandlingParty.INHOUSE) return false;

    return true;
  }

  private isAllowCreateDossPo() {
    if (!this.jobCard.ccsServiceOrderNumber) return false;

    if ([
      JMENUM.JobcardTecoStatus.SUCCEEDED,
    ].includes(this.jobCard.tecoStatus)) return false;

    return true;
  }

  private isAllowToUnTeco() {
    let alreadyCutOff = !this.jobCard.financialCutOffDay || this.jobCard.financialCutOffDay <= moment(new Date()).format('YYYYMMDD');
    if (!this.jobCard || !this.jobCard.tecoStatus || this.jobCard.tecoStatus != JMENUM.JobcardTecoStatus.SUCCEEDED || alreadyCutOff) {
      return false;
    }

    if (!this.isEnableJobCardTecoFeature()) { return false; }
    return true;
  }

  private isAllowToTeco() {
    if (!this.jobCard || !this.jobCard.tecoStatus || (this.jobCard.tecoStatus != JMENUM.JobcardTecoStatus.FAILED && this.jobCard.tecoStatus != JMENUM.JobcardTecoStatus.READY)) {
      return false;
    }

    if (!this.isEnableJobCardTecoFeature()) { return false; }
    return true;
  }

  private isAllowToWithdrawTeco() {
    if (!this.jobCard || !this.jobCard.tecoStatus || (this.jobCard.tecoStatus != JMENUM.JobcardTecoStatus.PENDING_FOR_TECO)) {
      return false;
    }

    if (!this.isEnableJobCardTecoFeature()) { return false; }
    return true;
  }

  private isEnableJobCardTecoFeature(): boolean {
    const jobCardTecoFeature = Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.JOB_CARD_TECO);
    return jobCardTecoFeature && jobCardTecoFeature.enabled;
  }

  private isBorrowedTeam(): boolean {
    return this.jobCard.workCentre && this.jobCard.workCentre !== this.jobCard.snWorkCentre;
  }



  private isApproverByApprovalObject(approval?: JMOBJ.Approval) {
    if (!approval || !approval.approver) {
      return false;
    }
    return approval.approver.includes(Session.userInfo.name);
  }

  private hasPermissionByApprovalObject(approval?: JMOBJ.Approval) {
    if (!approval || !approval.permission || !approval.permission['edit']) {
      return false;
    }
    return JMUTILITY.hasPermissions(Session.userInfo, approval.permission['edit'], false);
  }

}
