<div class="header">
  <h3>{{ 'pages.jobcard-view.slider-panel.cancel-jobcard' | translate }}</h3>
  <div class="submit-wrap d-inline">
      <app-submit-button 
        [id]="'associate-jobcard-panel_submit_button'"
        [text]="'global.submit'"
        [buttonClass]="'standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="submitDisabled || isLoading"
        [isLoading]="isLoading"
        (onClick)="onClickSubmit()" 
      ></app-submit-button>
  </div>
</div>
<div class="row mt-3" *ngIf="!hasApprovePermission">
  <div class="col-12">
    <ng-select
      class="my-2"
      placeholder="{{'component.associate-sn-panel.approver-input-placeholder' | translate}}"
      [(ngModel)]="selectedApprover"
      [items]="approverListOptions"
      [multiple]="true"
      [loading]="isLoadingApprover"
      [disabled]="isLoadingApprover || isLoading"
      [clearable]="false"
      [closeOnSelect]="false"
      (change)="validateForm()"
      >
    </ng-select>
  </div>
</div>
<div class="reason-drop-down">
  <ng-select
      placeholder="{{ 'pages.sn.slider-panel.cancel-sn-reason' | translate }}"
      bindLabel="description"
      bindValue="value"
      [items]="options"
      [(ngModel)]="reason"
      [closeOnSelect]="true"
      [disabled]="isLoading"
      (change)="validateForm()"
      >
  </ng-select>
</div>
<div>
  <textarea
      maxlength="{{ getMaxRemarksLength }}"
      type="text"
      rows="5"
      placeholder="{{ 'pages.sn.slider-panel.remarks' | translate }}"
      [(ngModel)]="remark"
      [disabled]="isLoading"
  ></textarea>
  <span 
      *ngIf="remark?.length >= getMaxRemarksLength" 
      class="red small"
  >{{ "textarea.warning.max-length" | translate:[getMaxRemarksLength]}}</span>
</div>
<div class="confirmation-message">
  <span>{{ 'pages.sn.slider-panel.confirm-msg-cancel-sn' | translate }}</span>
</div>
<div class="custom-control custom-checkbox">
  <input
      type="checkbox"
      id="confirm-cancel-checkbox"
      class="custom-control-checkbox confirmation-checkbox custom-control-input"
      [(ngModel)]="confirmed"
      [disabled]="isLoading"
      (change)="validateForm()"
  >
  <label for="confirm-cancel-checkbox" class="custom-control-label">
      {{ 'pages.sn.slider-panel.ack-cancel-sn' | translate }}
  </label> 
</div>
