import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { PmJobService } from '@services/pm-job.service';
import { AppDelegate } from 'src/app/AppDelegate';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign
} from 'src/app/entity/enum/tablexColumnType';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-pm-job-ccs-information',
  templateUrl: './pm-job-ccs-information.component.html',
  styleUrls: ['./pm-job-ccs-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PmJobCcsInformationComponent implements OnInit {
  @Input() pageMode: JMENUM.JMPageMode;
  @Input() job:JMOBJ.PmJob;
  @Output() onEquipmentRemove: EventEmitter<any> = new EventEmitter();

  
  pmJobService: PmJobService;
  
  tablexParam: any = {};

  constructor(pmJobService: PmJobService) {
    this.pmJobService = pmJobService;
  }
  
  ngOnInit() {
    this.tablexParam.tableRow = 'd-flex align-items-stretch justify-content-center';
    this.renderTableHeader();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.job) {
      this.renderTableContent();
    }
  }

  renderTableHeader(){
    this.tablexParam.headers= [
      {
        name: JMLanguage.translate('component.pm-job-ccs-information.table.header.equipment-id'),
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 d-flex align-items-center justify-content-center',
      },
      {
        name: JMLanguage.translate('component.pm-job-ccs-information.table.header.so'),
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 d-flex align-items-center justify-content-center',
      },
      {
        name: JMLanguage.translate('component.pm-job-ccs-information.table.header.error'),
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 d-flex align-items-center justify-content-center',
      },
      {
        name: JMLanguage.translate('component.pm-job-ccs-information.table.header.delete'),
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 d-flex align-items-center justify-content-center',
      },
    ]
  }

  renderTableContent() {
    if(!this.job || !this.job.equipmentList) return;
    this.tablexParam.content = this.job.equipmentList.filter(element=> element['ccsStatus']).map((element, index) => {
      let timeSheetEquipNumber = this.job.timesheet && this.job.timesheet['equipmentNumber'];
      let elEquipmentNumber = element['equipmentNumber'];
      let isSubmittedTimeSheet = timeSheetEquipNumber === elEquipmentNumber
    
      return [
        elEquipmentNumber,
        element.ccsServiceOrderNumber ? element.ccsServiceOrderNumber : JMLanguage.translate("component.pm-job-ccs-information." + element.ccsStatus),
        element.ccsErrorMessage ? `<p class='error'>Error message: ${element.ccsErrorMessage}</p>` : '',
        [{
          name: isSubmittedTimeSheet ? JMLanguage.translate('component.pm-job-ccs-information.popup.delete-equipment.warning') : '',
          icon: isSubmittedTimeSheet ? '' : 'fa fa-square fa-trash',
          class: isSubmittedTimeSheet ? 'none' : 'rectangle brand-red',
          show: this.job.status === JMENUM.PMJobStatus.REWORKING && element['ccsStatus'] !== JMENUM.PMJobEquipmentCcsStatus.PENDING? true : false,
          disable: isSubmittedTimeSheet,
          onClicked: ()=>this.onTrashButtonClick(element),
        }]
      ];
    });
  }
 
  onTrashButtonClick(equipment){
    let title = '';
    let description = '';
    let buttons = [];

      title = JMLanguage.translate('component.pm-job-ccs-information.popup.delete-equipment.title');
      buttons = [
        {
          name: JMLanguage.translate("global.yes"),
          handler: () => {
            this.requestRemoveEquipment(equipment);
          }
        }, 
        {
          name: (JMLanguage.translate("global.no")),
          handler: () => {

          }
        }
      ]
    AppDelegate.showPopUpAlert(title, description, buttons);
  }

  requestRemoveEquipment = async(equipment) => {
    let response = await this.pmJobService.deleteCcsSoEquipment(this.job, equipment.equipmentNumber);
    if (response && response.payload) {
      this.onEquipmentRemove.emit();
    }
  }
}
