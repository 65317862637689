import { Component, Injector, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { BasePage } from '../../model/base/base';
@Component({
  selector: 'reject-job-card-form',
  templateUrl: './reject-job-card-form.component.html',
  styleUrls: ['./reject-job-card-form.component.scss']
})

export class RejectJobCardFormComponent extends BasePage implements OnInit {
  @Input() options: any[];
  @Input() isLoading: boolean;
  @Output() onSubmit = new EventEmitter<any>();
  reason: any;
  remark: string;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngOnChanges() {
  }

  onClickSubmit() {
    let buttons = [
      {
        name: this.translateService.instant("action.button.reject-the-job"), 
        handler: () => {
          if (this.remark) this.remark = this.remark.trim();
          this.onSubmit.emit({rejectCode: this.reason, rejectRemarks: this.remark});
        }
      }, 
      {name: this.translateService.instant("global.no")}
    ];

    this.showPopUpAlert(this.translate("action.button.popup.reject-job"), "", buttons);
  }

  get getMaxRemarksLength():number{
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }

}
