<div class="wrapper">
  <app-nav></app-nav>

  <app-sidebar></app-sidebar>

  <!-- Page Content -->
  <div id="content" class="sn-content">

    <div class="sn-content-inner-container">

      <div id="toggle_btn" (click)="isCollapsed = !isCollapsed">
        <i *ngIf="isCollapsed" class="fas fa-chevron-left"></i>
        <i *ngIf="!isCollapsed" class="fas fa-chevron-right"></i>
      </div>

      <div id="search-panel"
        class="collapse show rightSection col-xl-3"
        [(ngbCollapse)]="isCollapsed">

        <div class="sn-title-container">
          <div>
            <h2 class="title">{{"pages.sn-list.search.criteria.title" | translate}}</h2>
          </div>
          <div class="filter-btn-container">
            <button class="btn icon-btn brand-amber mr-2 img-btn" (click)="onResetFilterClicked()"
              [disabled]="isSearchingSN">
              <i class="fas fa-undo"></i>
            </button>
            <button class="btn icon-btn brand-blue img-btn" (click)="onSearchClicked()" [disabled]="isSearchingSN">
              <!-- <div *ngIf="isSearchingSN" class="d-flex align-items-center loading-spinner spinner-small blue"></div> -->
              <i class="fas fa-search white font-size-l"></i>
            </button>
            <button class="btn icon-btn ml-2 d-inline-block d-xl-none navbar-toggler"
              (click)="onOpenFilterClicked()">
              <i class="fas fa-chevron-up" [class.d-none]="!isExpanded"></i>
              <i class="fas fa-chevron-down" [class.d-none]="isExpanded"></i>
            </button>
          </div>
        </div>

        <div class="subSection collapse" [ngbCollapse]="!isExpanded">
          <div class="filter-session-container">
            <div class="input-field-group">
              <input type="text" class="form-control" id="list-sn_sn-number_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.snNumber"
                placeholder="{{'pages.sn-list.sn-number' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="50" />
            </div>
            <div class="input-field-group">
              <input type="text" class="form-control " id="list-sn_ccs-sn-number_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.ccsSnNumber"
                placeholder="{{'pages.sn-list.ccs-sn-number' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="50" />
            </div>
          </div>

          <div class="filter-session-container">
            <div class="input-field-group">
              <div class="dropdwon-group">
                <ng-select id="list-sn_client_dropdown" bindLabel="label" bindValue="value"
                  placeholder="{{'pages.sn-list.client' | translate}}" [items]="clientOptions"
                  [(ngModel)]="filterCriteria.client" [ngModelOptions]="{standalone: true}">
                </ng-select>
              </div>
            </div>
            <div class="input-field-group">
              <input type="text" class="form-control " id="list-sn_contact_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.contact"
                placeholder="{{'pages.sn-list.contact' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="50" />
            </div>
            <div class="input-field-group">
              <input type="text" class="form-control " id="list-sn_location_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.location"
                placeholder="{{'pages.sn-list.location' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="50" />
            </div>
          </div>

          <div class="filter-session-container">
            <div class="input-field-group">
              <div class="checkbox-btn-group">
                <div class="label">
                  {{'pages.sn-list.priority' | translate}}
                </div>
                <label [for]="'search-priority-'+option.value+'-checkbox'" *ngFor="let option of priorityOptions"
                  class="checkbox-tag brand-blue"
                  [class.checked]="filterCriteria.priority && filterCriteria.priority.includes(option.value)">
                  {{ option.label | translate }}
                  <input type="checkbox" hidden="true" name="priority"
                    [checked]="filterCriteria.priority && filterCriteria.priority.includes(option.value)"
                    (change)="checkBtnInList($event, 'priority', option.value)"
                    [id]="'search-priority-'+option.value+'-checkbox'" />
                </label>
              </div>
            </div>
            <div class="input-field-group">
              <div class="dropdwon-group">
                <ng-select id="list-sn_equipment-category_dropdown" bindLabel="label" bindValue="value"
                  placeholder="{{'pages.sn-list.equipment-category' | translate}}" [items]="equipmentCategoryOptions"
                  [(ngModel)]="filterCriteria.equipmentCategory" [ngModelOptions]="{standalone: true}"
                  (change)="onEquipmentCategoryChanged($event)">
                </ng-select>
              </div>
            </div>
            <div class="input-field-group">
              <div class="dropdwon-group">
                <ng-select id="list-sn_equipment-type_dropdown" bindLabel="label" bindValue="value"
                  placeholder="{{'pages.sn-list.equipment' | translate}}" [items]="equipmentTypeOptions"
                  [(ngModel)]="filterCriteria.equipmentType" [ngModelOptions]="{standalone: true}"
                  (change)="onEquipmentTypeChanged($event)">
                </ng-select>
              </div>
            </div>
            <div class="input-field-group">
              <input type="text" class="form-control " id="list-sn_fault-detail_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.faultDetail"
                placeholder="{{'pages.sn-list.fault-details' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="50" />
            </div>
          </div>

          <div class="filter-session-container">
            <div class="input-field-group">
              <div class="checkbox-btn-group">
                <div class="label">
                  {{'pages.sn-list.maintenance-type-placeholder' | translate}}
                </div>
                <label [for]="'search-maintenance-type-'+option+'-checkbox'"
                  *ngFor="let option of maintenanceTypeOptions" class="checkbox-tag brand-blue"
                  [class.checked]="filterCriteria.maintenanceType && filterCriteria.maintenanceType.includes(option)">
                  {{ option }}
                  <input type="checkbox" hidden="true" name="maintenanceType"
                    [checked]="filterCriteria.maintenanceType && filterCriteria.maintenanceType.includes(option.value)"
                    (change)="checkBtnInList($event, 'maintenanceType', option)"
                    [id]="'search-maintenance-type-'+option+'-checkbox'" />
                </label>
              </div>
            </div>

            <div class="input-field-group">
              <div class="checkbox-btn-group">
                <div class="label">
                  {{'pages.sn-list.initial-party-placeholder' | translate}}
                </div>
                <label [for]="'search-initial-party-'+option.value+'-checkbox'"
                  *ngFor="let option of initialPartyOptions" class="checkbox-tag brand-blue"
                  [class.checked]="filterCriteria.initialParty && filterCriteria.initialParty.includes(option.value)">
                  {{ option.label | translate }}
                  <input type="checkbox" hidden="true" name="initialParty"
                    [checked]="filterCriteria.initialParty && filterCriteria.initialParty.includes(option.value)"
                    (change)="checkBtnInList($event, 'initialParty', option.value)"
                    [id]="'search-initial-party-'+option.value+'-checkbox'" />
                </label>
              </div>
            </div>

            <div class="input-field-group">
              <div class="checkbox-btn-group">
                <div class="label">
                  {{'pages.sn-list.handling-party-placeholder' | translate}}
                </div>
                <label [for]="'search-handling-party-'+option.value+'-checkbox'"
                  *ngFor="let option of handlingPartyOptions" class="checkbox-tag brand-blue"
                  [class.checked]="filterCriteria.handlingParty && filterCriteria.handlingParty.includes(option.value)">
                  {{ option.label | translate }}
                  <input type="checkbox" hidden="true" name="handlingParty"
                    [checked]="filterCriteria.handlingParty && filterCriteria.handlingParty.includes(option.value)"
                    (change)="checkBtnInList($event, 'handlingParty', option.value)"
                    [id]="'search-handling-party-'+option.value+'-checkbox'" />
                </label>
              </div>
            </div>

            <div class="input-field-group">
              <div class="checkbox-btn-group">
                <div class="label">
                  {{'pages.sn-list.status.sn' | translate}}
                </div>
                <label [for]="'search-sn-status-'+option.value+'-checkbox'" *ngFor="let option of snStatusOptions"
                  class="checkbox-tag brand-blue"
                  [class.checked]="filterCriteria.snStatus && filterCriteria.snStatus.includes(option.value)">
                  {{ option.label }}
                  <input type="checkbox" hidden="true" name="snStatus"
                    [checked]="filterCriteria.snStatus && filterCriteria.snStatus.includes(option.value)"
                    (change)="checkBtnInList($event, 'snStatus', option.value)"
                    [id]="'search-sn-status-'+option.value+'-checkbox'" />
                </label>
              </div>
            </div>

            <div class="input-field-group">
              <div class="checkbox-btn-group">
                <div class="label">
                  {{'pages.sn-list.status.job' | translate}}
                </div>
                <label [for]="'search-job-status-'+option.value+'-checkbox'" *ngFor="let option of jobStatusOptions"
                  class="checkbox-tag brand-blue"
                  [class.checked]="filterCriteria.jobStatus && filterCriteria.jobStatus.includes(option.value)">
                  {{ option.label }}
                  <input type="checkbox" hidden="true" name="jobStatus"
                    [checked]="filterCriteria.jobStatus && filterCriteria.jobStatus.includes(option.value)"
                    (change)="checkBtnInList($event, 'jobStatus', option.value)"
                    [id]="'search-job-status-'+option.value+'-checkbox'" />
                </label>
              </div>
            </div>

            <div class="input-field-group">
              <input type="text" class="form-control " id="list-sn_created_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.created"
                placeholder="{{'pages.sn-list.created' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="50" />
            </div>
          </div>

          <div class="filter-session-container">
            <div class="input-field-group">
              <div class="dropdwon-group">
                <ng-select id="list-sn_team-work-centre_dropdown"
                  placeholder="{{'pages.sn-list.team-work-centre-all' | translate}}" [items]="teamWorkCentreOptions"
                  (change)="onTeamWorkCentreChanged()" [(ngModel)]="filterCriteria.teamWorkCentre"
                  [ngModelOptions]="{standalone: true}">
                </ng-select>
              </div>
            </div>

            <div class="input-field-group">
              <div class="dropdwon-group">
                <ng-select id="list-sn_team_dropdown" bindLabel="label" bindValue="value"
                  placeholder="{{'pages.sn-list.team-all' | translate}}" (scrollToEnd)="onTeamScrollToEnd()"
                  (clear)="onClearTeam()" (search)="onSearchTeamOptions($event)" [searchFn]="onFilterTeamOptions"
                  [items]="teamOptions" [disabled]="!filterCriteria.teamWorkCentre" [loading]="isLoadingTeam"
                  [(ngModel)]="filterCriteria.team" [ngModelOptions]="{standalone: true}">
                </ng-select>
              </div>
            </div>
          </div>
          <!-- sn work centre section -->
          <div class="filter-session-container">
            <div class="input-field-group">
              <div class="dropdwon-group">
                <ng-select id="list-sn_sn-work-centre_dropdown"
                  placeholder="{{'pages.sn-list.sn-work-centre-all' | translate}}" [items]="snWorkCentreOptions"
                  [(ngModel)]="filterCriteria.snWorkCentre" [ngModelOptions]="{standalone: true}">
                </ng-select>
              </div>
            </div>
          </div>
          <!-- end sn work centre section -->
          <div class="filter-session-container">
            <div class="radio-group col-8 ml-2">
              <div class="label">
                {{'pages.sn-list.manual-instruction' | translate}}
              </div>
              <div class="btn-group">
                <button id="mi-yes-button" class="btn radio-wrap yes" (click)="onMiButtonClick(true)"
                  [ngClass]="{'active':filterCriteria.haveManualInstruction}">
                  {{ 'global.yes' | translate}}
                </button>
                <button id="mi-no-button" class="btn radio-wrap no" (click)="onMiButtonClick(false)"
                  [ngClass]="{'active':filterCriteria.haveManualInstruction==false}">
                  {{ 'global.no' | translate}}
                </button>
              </div>
            </div>
          </div>
          <div class="filter-session-container">
            <div class="input-field-group">
              <input type="text" class="form-control" id="list-ha_asset_no_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.assetNumber"
                placeholder="{{'pages.sn-list.ha-asset-no' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="8" />
            </div>
            <div class="input-field-group">
              <input type="text" class="form-control" id="list-ha_work_order_no_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.haWorkOrderNo"
                placeholder="{{'pages.sn-list.ha-work-order-no' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="10" />
            </div>
          </div>
          <div class="filter-session-container">
            <div class="input-field-group ">
              <input type="text" class="form-control" id="list-sn_remarks_inputbox"
                (keyup)="onKeyup($event, 'advancedSearch')" [(ngModel)]="filterCriteria.remarks"
                placeholder="{{'pages.sn-list.remarks' | translate}}" [ngModelOptions]="{standalone: true}"
                maxlength="50" />
            </div>
          </div>
        </div>
      </div>

      <div class="leftSection">
        <div class="sn-title-container">
          <h2 class="title">{{'pages.sn-list.page-title' | translate}}</h2>
          <div class="search-box">
            <i class="fas fa-search" (click)="onGeneralSearchClicked()"></i>
            <input class="form-control" id="list-sn_search_inputbox" type="search"
              (keyup)="onKeyup($event, 'generalSearch')" [(ngModel)]="generalSearchWords"
              placeholder="{{'pages.sn-list.search.placeHolder' | translate}}" (ngModelChange)="checkSearchEmpty()">
          </div>
        </div>

        <div class="d-flex justify-content-end mt-2">
          <app-submit-button class="float-right" [id]="'list-batch-ack'" [text]="'pages.sn-list.batch-ack'"
            [buttonClass]="'standard brand-amber'" [iconClass]="" [isDisabled]="isDisableBatchAck"
            [isLoading]="isAcknowledging" (onClick)="onBatchAcknowledgeClicked()">
          </app-submit-button>
        </div>

        <app-tablex id='sn_table' [tablexParam]='tablexParam'></app-tablex>
      </div>
    </div>
  </div>
</div>
