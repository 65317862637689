import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { ngbDateToString, toNgbDate } from 'src/lib/presenter/Formatter';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-pm-plan-summary',
  templateUrl: './pm-plan-summary.component.html',
  styleUrls: ['./pm-plan-summary.component.scss']
})
export class PmPlanSummaryComponent implements OnInit {
  @ViewChild("startDateElem", { static: false }) startDateElem;
  @ViewChild("endDateElem", { static: false }) endDateElem;
  @ViewChild("secondStartDateElem", { static: false }) secondStartDateElem;
  @Input() plan: JMOBJ.PmPlan;
  @Input() pageMode: JMENUM.JMPageMode;
  @Output() refreshActionButton: EventEmitter<any> = new EventEmitter();
  @Output() onChangeWorkCentreSelection: EventEmitter<any> = new EventEmitter<Event>();
  @Output() onChangeSchTypeAndFreq: EventEmitter<any> = new EventEmitter<Event>();

  //ENUM for HTML
  // PMPlanType = JMENUM.PMPlanType;
  PMStatus = JMENUM.PMStatus;
  // PlanCoverage = JMENUM.PMPlanCoverage;
  ScheduleType = JMENUM.PMScheduleType;
  PlanPeriod = JMENUM.PMPlanPeriod;
  PMPlanFrequency = JMENUM.PMPlanFrequency;
  JMPageMode = JMENUM.JMPageMode;

  //boolean
  isDisabledSecondDate: boolean = false;
  isDisabledSLA: boolean = true;

  // Validation 
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};

  // Input Fields
  workCentre: string;
  agreementNumber: string;
  planDescription: string;
  scheduleType: string;
  startDate: any;
  endDate: any;
  secondStartDate: any;
  frequency: string;
  // planCoverage: string;

  descriptionMaxLength: number = 1000;
  contractStartDate: any;
  contractEndDate: any;

  // Dropdown Options
  slaOptions: any = [];
  frequencyOptions: any = [];
  frequencyUnitList: any;
  workCentreOptions: any = [];

  // User info
  post: any = {};

  dateAlertMsg: string = undefined;

  constructor() { }

  ngOnInit() {
    this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
    this.frequencyUnitList = {
      weekly: { desc: "weekly", timeUnit: "w", frequencyUnit: 1 },
      bi_weekly: { desc: "bi_weekly", timeUnit: "w", frequencyUnit: 2 },
      monthly: { desc: "monthly", timeUnit: "M", frequencyUnit: 1 },
      bi_monthly: { desc: "bi_monthly", timeUnit: "M", frequencyUnit: 2 },
      tetra_monthly: { desc: "tetra_monthly", timeUnit: "M", frequencyUnit: 4 },
      half_yearly: { desc: "half_yearly", timeUnit: "M", frequencyUnit: 6 },
      quarterly: { desc: "quarterly", timeUnit: "M", frequencyUnit: 3 },
      yearly: { desc: "yearly", timeUnit: "y", frequencyUnit: 1 },
      bi_yearly: { desc: "bi_yearly", timeUnit: "y", frequencyUnit: 2 }
    };
  }

  ngOnChanges() {
    if (this.pageMode != JMENUM.JMPageMode.VIEW) {
      this.fieldsControl();
      this.initOptions();

      if (!this.plan.planCoverage) {
        this.plan.planCoverage = JMENUM.PMPlanCoverage.EQUIPMENT;
      }
    }
    this.initPlan();

  }

  // ----------- API ----------- //

  private async requestSLASummary(workCentreCode) {
    const request: JM.JMRequestEquipmentSLASummary = new JM.JMRequestEquipmentSLASummary();
    request.workCentres = [workCentreCode];

    const response: JM.JMResponseEquipmentSLASummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      this.slaOptions = [];
      return;
    }

    this.slaOptions = response.payload.records.map(record => {
      return {
        value: record.agreementNumber,
        label: record.agreementNumber,
        contractStartDate: this.convertDateTimeToHKDateString(record.contractStartDate),
        contractEndDate: this.convertDateTimeToHKDateString(record.contractEndDate)
      };
    });
  }

  private async requestSingleSLASummary(agreementNumber) {

    if (!agreementNumber) return;
    let request: JM.JMRequestEquipmentSLASummary = new JM.JMRequestEquipmentSLASummary();
    request.agreementNumber = [agreementNumber];

    let response: JM.JMResponseEquipmentSLASummary = await AppDelegate.sendJMRequest(request);

    if (!response || response.error || !response.code || response.code != 200 || !response.payload ||
      !response.payload.records || !response.payload.records.length) {
      return;
    }

    this.contractStartDate = this.convertDateTimeToHKDateString(response.payload.records[0].contractStartDate);
    this.contractEndDate = this.convertDateTimeToHKDateString(response.payload.records[0].contractEndDate);
    return;
  }

  private convertDateTimeToHKDateString(datetime) {
    // convert date time to date string in HK timezone
    return moment(datetime).tz("Asia/Hong_Kong").format('YYYYMMDD');
  }

  // ----------- UI function ----------- //
  private initPlan() {
    if (!this.plan) return;
    this.planDescription = this.plan['planDescription'];
    this.scheduleType = this.plan['scheduleType'] ? JMLanguage.translate("pm.schedule-type." + this.plan['scheduleType']) : undefined;
    this.frequency = this.plan.frequency ? JMLanguage.translate("pm.frequency." + this.plan.frequency) : JMLanguage.translate('component.display-text-field.na');
    // this.planCoverage = this.plan['planCoverage'] ? JMLanguage.translate("pm.plan-coverage."+this.plan['planCoverage']) : undefined;
    this.startDate = toNgbDate(this.plan.startDate);
    this.endDate = toNgbDate(this.plan.endDate);
    this.secondStartDate = toNgbDate(this.plan.secondStartDate);
    this.requestSingleSLASummary(this.plan.agreementNumber);
  }

  private initOptions() {
    if (this.pageMode === JMENUM.JMPageMode.EDIT) { // i.e. editing existing pm plan
      if (this.plan.status === JMENUM.PMStatus.DRAFT) {
        this.editable.workCentre = false;
        this.editable.agreementNumber = false;
      } else {
        this.editable.workCentre = false;
        this.editable.agreementNumber = false;
        this.editable.scheduleType = false;
        // this.editable.planCoverage = false;
        this.editable.startDate = false;
        this.editable.endDate = false;
        this.editable.secondStartDate = false;
        this.editable.frequency = false;
      }
    }
    else if (this.pageMode === JMENUM.JMPageMode.CREATE) { // i.e. creating new pm plan
      this.post = JM.JMConnector.getPost();

      if (this.post.roles.find(role => role.roleId === 101)) {
        this.workCentreOptions = JM.JMConnector.getAllWorkCentreCode();
      }
      else {
        this.workCentreOptions = this.post.authorizations.workCenters;
      }
    }
    let frequencyEnum = Object.values(this.PMPlanFrequency);
    this.frequencyOptions = [];
    for (let i = 0; i < frequencyEnum.length; i++) {
      this.frequencyOptions.push({ value: frequencyEnum[i], label: JMLanguage.translate("pm.frequency." + frequencyEnum[i]) });
    }
  }

  private fieldsControl() {
    this.editable = {
      workCentre: true,
      agreementNumber: true,
      planDescription: true,
      scheduleType: true,
      startDate: true,
      endDate: true,
      secondStartDate: false,
      frequency: true,
      // planCoverage: true,
    }
    this.mandatory = { ...this.editable };
    if (!this.plan['scheduleType']) this.plan['scheduleType'] = this.ScheduleType.NORMAL;
    this.updateSecondStartDateAndFrequency();
  }

  private updateSecondStartDateAndFrequency() {
    if (this.plan.scheduleType == this.ScheduleType.OVERHAUL || this.plan.scheduleType == this.ScheduleType.NORMAL) {
      this.editable.secondStartDate = true;
      this.editable.frequency = true;
      this.mandatory.frequency = true;
    } else {
      this.editable.secondStartDate = false;
      this.editable.frequency = false;
      this.mandatory.frequency = false;
      this.plan.secondStartDate = undefined;
      this.secondStartDate = undefined;
      this.plan.frequency = undefined;
    }
  }

  private updateWorkCentre() {
    this.onChangeWorkCentreSelection.emit();
    if (this.plan.workCentre) {
      if (!this.plan.pmPlanNumber) {
        this.isDisabledSLA = false;
        this.plan.agreementNumber = undefined;
        this.requestSLASummary(this.plan.workCentre);
      }
    } else {
      this.isDisabledSLA = true;
      this.plan.agreementNumber = undefined;
    }
  }

  public onChangeWorkCentre(event: Event) {
    this.updateWorkCentre();
  }

  public onChangeSLA(event) {
    if (event) {
      this.contractStartDate = event.contractStartDate;
      this.contractEndDate = event.contractEndDate;
      this.plan.endDate = event.contractEndDate;
      this.endDate = toNgbDate(this.plan.endDate);
    }
  }

  public onChangeDescription() {
    this.planDescription = this.planDescription.trim();
    this.plan['planDescription'] = this.planDescription;
  }

  public onChangeScheduleType() {
    this.updateSecondStartDateAndFrequency();
    this.onChangeSchTypeAndFreq.emit({
      scheduleType: this.plan.scheduleType,
      frequency: this.plan.frequency
    });
  }

  public onChangeFrequency() {
    this.onChangeSchTypeAndFreq.emit({
      scheduleType: this.plan.scheduleType,
      frequency: this.plan.frequency
    });
  } 

  public onBlurDateInput(event) {
    if (event.field == 'startDate') {
      this.plan.startDate = ngbDateToString(event.data);
    } else if (event.field == 'endDate') {
      this.plan.endDate = ngbDateToString(event.data);
    } else if (event.field == 'secondStartDate') {
      this.plan.secondStartDate = ngbDateToString(event.data);
    }
  }

  public onChangeCoverage() {
    this.refreshActionButton.emit();
  }

  public clearAll() {
    for (let field in this.editable) {
      if (this.editable[field]) {
        if (field === "scheduleType") {
          this.plan[field] = this.ScheduleType.NORMAL;
          this.onChangeScheduleType();
        } else {
          this.plan[field] = undefined;
        }
      }
    }
    this.planDescription = undefined;
    this.startDateElem.clearInput();
    this.endDateElem.clearInput();
    if (this.secondStartDateElem)
      this.secondStartDateElem.clearInput();
    this.updateWorkCentre();
  }

  public validateStartEndDate() {
    // show error if startDate > endDate
    if (this.plan.startDate && this.plan.endDate) {
      let differTime = moment(this.plan.endDate).diff(moment(this.plan.startDate), 'days');
      if (differTime < 0) {
        this.errorFields['startDate'] = true;
        this.errorFields['endDate'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-end-earlier-than-start"));
        return false;
      }
    }

    // show error if startDate < contractStartDate
    if (this.plan.startDate) {
      let differTime = moment(this.plan.startDate).diff(moment(this.contractStartDate), 'days');
      if (differTime < 0) {
        this.errorFields['startDate'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-start-earlier-than-contract-start"));
        return false;
      }
    }

    // show error if contractEndDate < endDate
    if (this.plan.endDate) {
      let differTime = moment(this.contractEndDate).diff(moment(this.plan.endDate).startOf('day'), 'days', true);
      if (differTime < 0) {
        this.errorFields['endDate'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-end-later-than-contract-end"));
        return false;
      }
    }

    // show error if endDate - startDate < frequency for normal schedule type
    if (this.plan.startDate && this.plan.endDate && this.plan.scheduleType === this.ScheduleType.NORMAL) {
      let differPeriod: any = moment(this.plan.endDate).diff(moment(this.plan.startDate), this.frequencyUnitList[this.plan.frequency]['timeUnit'], true);
      // e.g.: if unit is bi-weekly, then differPeriod cannot <= 2 (weeks) 
      if (differPeriod <= this.frequencyUnitList[this.plan.frequency]['frequencyUnit']) {
        this.errorFields['startDate'] = true;
        this.errorFields['endDate'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-overall-period-too-short"));
        return false;
      } else {
        this.errorFields['startDate'] = false;
        this.errorFields['endDate'] = false;
      }
    }

    return true;

  }

  public validateSecondStartDate() {
    // show error if startDate > secondStartDate
    if (this.plan.startDate && this.plan.secondStartDate) {
      let differStartTime = moment(this.plan.secondStartDate).diff(moment(this.plan.startDate));
      if (differStartTime <= 0) {
        this.errorFields['startDate'] = true;
        this.errorFields['secondStartDate'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-second-start-earlier-than-start"));
        return false;
      } else {
        this.errorFields['startDate'] = false;
        this.errorFields['secondStartDate'] = false;
      }
    }

    // show error if endDate < secondStartDate
    if (this.plan.endDate && this.plan.secondStartDate) {
      let differEndTime = moment(this.plan.endDate).diff(moment(this.plan.secondStartDate), 'days');
      if (differEndTime <= 0) {
        this.errorFields['endDate'] = true;
        this.errorFields['secondStartDate'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-second-start-earlier-than-end"));
        return false;
      } else {
        this.errorFields['endDate'] = false;
        this.errorFields['secondStartDate'] = false;
      }
    }

    // show error if secondStartDate - startDate > frequency
    if (this.plan.startDate && this.plan.secondStartDate) {
      let differPeriod: any = moment(this.plan.secondStartDate).diff(moment(this.plan.startDate), this.frequencyUnitList[this.plan.frequency]['timeUnit'], true);
      // e.g.: if unit is bi-weekly, then differPeriod cannot > 2 (weeks) 
      if (differPeriod > this.frequencyUnitList[this.plan.frequency]['frequencyUnit']) {
        this.errorFields['startDate'] = true;
        this.errorFields['secondStartDate'] = true;
        AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.alert-first-period-too-long"));
        return false;
      } else {
        this.errorFields['startDate'] = false;
        this.errorFields['secondStartDate'] = false;
      }
    }
    return true;
  }

  public validate() {
    let hasErrorField = false;
    this.errorFields = {};

    // Check fields have data
    for (let field in this.editable) {
      if (field && this.mandatory[field]) {
        this.errorFields[field] = this.plan[field] ? false : true;
      }
    }
    for (let field in this.editable) {
      if (field && this.errorFields[field]) {
        hasErrorField = true;
      }
    }

    if (hasErrorField) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.pm-plan.please-fill-in-mandatory-fields"));
    }

    if (this.plan.secondStartDate) {
      hasErrorField = (!this.validateStartEndDate() || !this.validateSecondStartDate() || hasErrorField) ? true : false;
    } else {
      hasErrorField = (!this.validateStartEndDate() || hasErrorField) ? true : false;
    }

    this.valid = !hasErrorField;
    return this.valid;
  }
}
