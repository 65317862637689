import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppDelegate } from 'src/app/AppDelegate';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    const { reLoginSuccessUrl } = this.activeRoute.snapshot.queryParams;
    AppDelegate.logout(reLoginSuccessUrl);
  }

}
