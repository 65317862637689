<div class="wrapper">
  <app-nav (languageChanged)="onLanguageChanged()"></app-nav>

  <app-sidebar></app-sidebar>

  <!-- Page Content -->
  <div id="content">
    <form>
      <div class="row justify-content-between row-space">
        <div class="col-auto align-self-center">
          <h2 class="title">
            <ng-container *ngIf="selectedReportType === reportType.insepction">
              {{'pages.report-download.inspection' | translate}}
            </ng-container>
            <ng-container *ngIf="selectedReportType === reportType.sn">
              {{'pages.report-download.service-notification' | translate}}
            </ng-container>
            <ng-container *ngIf="selectedReportType === reportType.job">
              {{'pages.report-download.job-card' | translate}}
            </ng-container>
            - {{'pages.report-download.report' | translate}}
          </h2>
        </div>
      </div>
      <hr class="underline" />

      <div id="search-panel" class="collapse show">
        <div class="row row-space">
          <!-- Time Range -->
          <ng-container *ngIf="selectedReportType === reportType.sn 
              || selectedReportType === reportType.job
              || selectedReportType === reportType.insepction">
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division"></div>
                <span class="field-title">
                  {{'pages.report-download.creation-time' | translate}}
                  <ng-container *ngIf="selectedReportType === reportType.job">
                    - {{'pages.report-download.service-notification' | translate}}
                  </ng-container>
                </span>
              </div>
              <div class="input-field-group">
                <input
                  id="report-download_start-date_inputbox"
                  class="form-control date-picker-input-box"
                  name="startdate"
                  placeholder="{{'pages.report-download.from-date' | translate}}"
                  readonly
                  ngbDatepicker
                  #startdate="ngbDatepicker"
                  [maxDate]="setTodayDate()"
                  [disabled]="isDisabledNormalTimeRange"
                  [(ngModel)]="filterCriteria.startDate"
                  (dateSelect)="filterCriteria.endDate = null"
                  (click)="startdate.toggle()"
                />
              </div>
              <div class="input-field-group ">
                <input
                  id="report-download_end-date_inputbox"
                  class="form-control date-picker-input-box"
                  name="enddate"
                  placeholder="{{'pages.report-download.to-date' | translate}}"
                  readonly
                  ngbDatepicker
                  #enddate="ngbDatepicker"
                  [minDate]="setMinEndDate(filterCriteria.startDate)"
                  [maxDate]="setMaxEndDate(filterCriteria.startDate)"
                  [disabled]="!filterCriteria.startDate || isDisabledNormalTimeRange"
                  [(ngModel)]="filterCriteria.endDate"
                  (click)="enddate.toggle()"
                />
              </div>
              <div class="input-field-group">
                <div class="time-slider">
                  <ng5-slider [(value)]="normalTimeRangeValue" [options]="normalTimeRangeOptions"
                    (valueChange)="timeRangeChanged('normal')"></ng5-slider>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedReportType === reportType.sn || selectedReportType === reportType.job">
            <!-- Client and Location -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division"></div>
                <span class="field-title">{{'pages.report-download.client-and-location' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_client_dropdown" [items]="clientOption" bindLabel="label"
                    bindValue="value" [(ngModel)]="filterCriteria.client"
                    placeholder="{{'pages.report-download.client-any' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <input type="text" class="form-control " id="report-download_location-code_inputbox"
                  [(ngModel)]="filterCriteria.locationCode"
                  placeholder="{{'pages.report-download.location-code' | translate}}"
                  [ngModelOptions]="{standalone: true}" maxlength="50" />
              </div>
              <div class="input-field-group ">
                <input type="text" class="form-control " id="report-download_location_inputbox"
                  [(ngModel)]="filterCriteria.location" placeholder="{{'pages.report-download.location' | translate}}"
                  [ngModelOptions]="{standalone: true}" maxlength="50" />
              </div>
            </div>

            <!-- Proiority and Status -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division"></div>
                <span *ngIf="selectedReportType === reportType.sn" class="field-title">
                  {{'pages.report-download.priority-and-status' | translate}}
                </span>
                <span *ngIf="selectedReportType === reportType.job" class="field-title">
                  {{'pages.report-download.sn-priority-and-status' | translate}}
                </span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_priority_dropdown" 
                    [items]="snPriority" 
                    bindLabel="label"
                    bindValue="id" [(ngModel)]="filterCriteria.priorities"
                    placeholder="{{'pages.report-download.priority-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                  <!-- [items]="snPriority | selectOptionsTranslate"  -->
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_status_dropdown" 
                    [items]="statusOption" bindLabel="label"
                    bindValue="id" [(ngModel)]="filterCriteria.status"
                    placeholder="{{'pages.report-download.status-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                  <!-- [items]="statusOption | selectOptionsTranslate" -->
                </div>
              </div>
              <div class="input-field-group " *ngIf="selectedReportType === reportType.sn">
                <div>
                  <ng-select id="report-download-sn-teco-status-dropdown" 
                    [items]="snTecoStatusOption" bindLabel="label"
                    bindValue="value" [(ngModel)]="filterCriteria.snTecoStatus"
                    placeholder="{{'pages.report-download.sn-teco-status-all' | translate}}"
                    [ngModelOptions]="{standalone: true}" [multiple]="true">
                  </ng-select>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedReportType === reportType.insepction">
            <!-- Parties and Venue -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division"></div>
                <span class="field-title">{{'pages.report-download.parties-and-venue' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_venue_dropdown" [items]="venues" bindLabel="displayName"
                    bindValue="venueShortName" [(ngModel)]="filterCriteria.venue"
                    placeholder="{{'pages.report-download.venue' | translate}}" [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <input type="text" class="form-control " id="report-download_officer-in-charge_inputbox"
                  [(ngModel)]="filterCriteria.officerInCharge"
                  placeholder="{{'pages.report-download.officer-in-charge' | translate}}"
                  [ngModelOptions]="{standalone: true}" maxlength="50" />
              </div>
              <div class="input-field-group ">
                <input type="text" class="form-control " id="report-download_contract-no_inputbox"
                  [(ngModel)]="filterCriteria.contractNo"
                  placeholder="{{'pages.report-download.contract-no' | translate}}"
                  [ngModelOptions]="{standalone: true}" maxlength="50" />
              </div>
            </div>

            <!-- Equipment -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division"></div>
                <span class="field-title">{{'pages.report-download.equipment' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_equipment-category_dropdown" [items]="equipmentCategories"
                    bindLabel="displayName" bindValue="code" [(ngModel)]="filterCriteria.equipmentCategory"
                    placeholder="{{'pages.report-download.equipment-type-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_equipment-type_dropdown" [items]="equipmentTypes"
                    bindLabel="displayName" bindValue="code" [(ngModel)]="filterCriteria.equipmentType"
                    placeholder="{{'pages.report-download.equipment-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedReportType === reportType.sn || selectedReportType === reportType.job">
              <!-- Work centre and Team -->
              <div class="col-md-6 col-xl-4">
                <div class="title-container">
                  <div class="division"></div>
                  <span class="field-title">{{'pages.report-download.work-centre-and-team' | translate}}</span>
                </div>
                <div class="input-field-group ">
                  <div class="dropdwon-group">
                    <ng-select
                      id="report-download_work-centre_dropdown"
                      placeholder="{{ setWorkCentrePlaceHolder() }}"
                      [multiple]="true"
                      [maxSelectedItems]="maxSelectedWorkCentres"
                      [closeOnSelect]="false"
                      [items]="workCentreOptions"
                      [(ngModel)]="filterCriteria.workCentres"
                      [ngModelOptions]="{standalone: true}"
                      (change)="getTeams()" >
                    </ng-select>
                  </div>
                </div>
                <div class="input-field-group ">
                  <div class="dropdwon-group">
                    <ng-select id="report-download_team_dropdown" [items]="teams" bindLabel="name"
                      bindValue="_id" [(ngModel)]="filterCriteria.team"
                      placeholder="{{'pages.report-download.team-all' | translate}}"
                      [ngModelOptions]="{standalone: true}"
                      [disabled]="isDisabledTeam">
                    </ng-select>
                  </div>
                </div>
              </div>
          </ng-container>

          <ng-container *ngIf="selectedReportType === reportType.job">
            <!-- Work centre and Team -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division"></div>
                <span class="field-title">{{'pages.report-download.contracts' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select
                    id="report-download_work-centre_dropdown"
                    bindLabel="label"
                    bindValue="value"
                    placeholder="{{ 'pages.report-download.contractor-all' | translate }}"
                    [multiple]="false"
                    [items]="contractorOptions"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="filterCriteria.contractor"
                    (scrollToEnd)="onContractorScrollToEnd()"
                    (search)="onSearchContractor($event)"
                    (change)="onChangeFilterContractor()"
                    (clear)="onClearFilterContractor()" >
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_team_dropdown"
                    bindLabel="label"
                    bindValue="value"
                    placeholder="{{'pages.report-download.contract-number-all' | translate}}"
                    [multiple]="true"
                    [items]="contractOptions"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="isDisabledContract"
                    [(ngModel)]="filterCriteria.contractList" >
                  </ng-select>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="selectedReportType === reportType.job">
          <div class="row row-space">
            <!-- Job Time Range -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title">
                  {{'pages.report-download.creation-time' | translate}} - {{'pages.report-download.job-card' | translate}}
                </span>
              </div>
              <div class="input-field-group">
                <input
                  id="report-download_job-start-date_inputbox"
                  class="form-control date-picker-input-box"
                  name="jobstartdate"
                  placeholder="{{'pages.report-download.from-date' | translate}}"
                  readonly
                  ngbDatepicker
                  #jobstartdate="ngbDatepicker"
                  [maxDate]="setTodayDate()"
                  [disabled]="isDisabledJobTimeRange"
                  [(ngModel)]="filterCriteria.jobStartDate"
                  (dateSelect)="filterCriteria.jobEndDate = null"
                  (click)="jobstartdate.toggle()"
                />
              </div>
              <div class="input-field-group ">
                <input
                  id="report-download_job-end-date_inputbox"
                  class="form-control date-picker-input-box"
                  name="jobenddate"
                  placeholder="{{'pages.report-download.to-date' | translate}}"
                  readonly
                  ngbDatepicker
                  #jobenddate="ngbDatepicker"
                  [minDate]="setMinEndDate(filterCriteria.jobStartDate)"
                  [maxDate]="setMaxEndDate(filterCriteria.jobStartDate)"
                  [disabled]="!filterCriteria.jobStartDate || isDisabledJobTimeRange"
                  [(ngModel)]="filterCriteria.jobEndDate"
                  (click)="jobenddate.toggle()"
                />
              </div>
              <div class="input-field-group">
                <div class="time-slider blue">
                  <ng5-slider [(value)]="jobCardTimeRangeValue" [options]="jobCardTimeRangeOptions"
                    (valueChange)="timeRangeChanged('job')"></ng5-slider>
                </div>
              </div>
            </div>

            <!-- Job MAT and Type -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title">{{'pages.report-download.job-mat-and-type' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_job-type_dropdown" [items]="jobTypes"
                    [(ngModel)]="filterCriteria.jobType"
                    placeholder="{{'pages.report-download.job-type-all' | translate}}"
                    [ngModelOptions]="{standalone: true}"
                    (change)="getJobMat()">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_job-mat_dropdown" [items]="jobMat"
                    [(ngModel)]="filterCriteria.jobMat"
                    placeholder="{{'pages.report-download.job-mat-all' | translate}}"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="isDisabledJobMat">
                  </ng-select>
                </div>
              </div>
            </div>

            <!-- Job Proiority and Status -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title mb-0"> {{'pages.report-download.job-priority-and-status' | translate}} </span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_job-priority_dropdown" [items]="jobPriorityOption" 
                    [(ngModel)]="filterCriteria.jobPriorities" bindLabel="label" bindValue="id"
                    placeholder="{{'pages.report-download.priority-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                  <!-- [items]="jobPriorityOption | selectOptionsTranslate"  -->
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_job-status_dropdown" [items]="jobStatusOption" bindLabel="label"
                    bindValue="id" [(ngModel)]="filterCriteria.jobStatus"
                    placeholder="{{'pages.report-download.status-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                  <!-- [items]="jobStatusOption | selectOptionsTranslate" -->
                </div>
              </div>
              <div class="input-field-group ">
                <div>
                  <ng-select id="report-download-job-teco-status-dropdown" [items]="jobTecoStatusOption" bindLabel="label"
                    bindValue="value" [(ngModel)]="filterCriteria.jobTecoStatus"
                    placeholder="{{'pages.report-download.job-teco-status-all' | translate}}"
                    [ngModelOptions]="{standalone: true}" [multiple]="true">
                  </ng-select>
                </div>
              </div>
            </div>

            <!-- Compliance Status -->
            <div class="col-md-6 col-xl-4">
              <div class="title-container">
                <div class="division blue"></div>
                <span class="field-title">{{'pages.report-download.kpi-status' | translate}}</span>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_response-to-client-time-type_dropdown" [items]="complianceStatusOption" bindLabel="label"
                    bindValue="value" [(ngModel)]="filterCriteria.responseToClientTimeComplianceStatus"
                    placeholder="{{'pages.report-download.response-to-client-time-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_response-time_dropdown" [items]="complianceStatusOption" bindLabel="label"
                    bindValue="value" [(ngModel)]="filterCriteria.responseTimeComplianceStatus"
                    placeholder="{{'pages.report-download.response-time-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
              <div class="input-field-group ">
                <div class="dropdwon-group">
                  <ng-select id="report-download_rectification-time_dropdown" [items]="complianceStatusOption" bindLabel="label"
                    bindValue="value" [(ngModel)]="filterCriteria.rectificationTimeComplianceStatus"
                    placeholder="{{'pages.report-download.rectification-time-all' | translate}}"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
            </div>

          </div>
        </ng-container>

        <div class="row justify-content-center row-space">
          <button id='export_excel_btn' type="button" class="standard brand-blue"
            (click)="onClickExportExcel()" [disabled]="isExportExcelButtonLoading" > 
            <div *ngIf="isExportExcelButtonLoading" class="d-inline-block loading-spinner spinner-small">
              {{ "global.loading" | translate }}
            </div>
            <span *ngIf="!isExportExcelButtonLoading">
              <i class="fas fa-download space"></i>
              {{'global.export-excel' | translate}}
            </span>
          </button>
        </div>

      </div>
    </form>

  </div>
</div>