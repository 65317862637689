<div class="header">
    <h3>{{ (completeWithNoAction ? 'pages.sn.slider-panel.complete-job-no-action' : 'pages.sn.slider-panel.complete-job') | translate }}</h3>
    <button class="standard brand-amber" (click)="onClickSubmit()" [disabled]="parameters.isLoading || !isValidToSubmit()">
        <span *ngIf="parameters.isLoading">
            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
            {{'global.loading' | translate}}
        </span>
        <span *ngIf="!parameters.isLoading"><i class="fas fa-check space"></i>{{'global.submit' | translate}}</span>
    </button>
</div>
<div *ngIf="!completeWithNoAction" class="complete_information">
    <div class="row data-row">
        <div class="col-4 label-name"><span *ngIf="needSoNumber" class="red">* </span>{{'pages.sn.ccs-so-no' | translate}}</div>
        <div class="col-8 text-content">
            <span *ngIf="parameters.ccsServiceOrderNumber">{{ parameters.ccsServiceOrderNumber }}</span>
            <button *ngIf="!parameters.ccsServiceOrderNumber" class="standard brand-blue button-content w-100" 
                (click)="onClickObtainSo()" [disabled]="parameters.isLoading || !parameters.workCentreAttribute || !parameters.workCentreAttribute.enableObtainSO">
                <span>{{ 'pages.sn.slider-panel.obtain-so' | translate }}</span>
            </button>
        </div>
    </div>
    <div class="row data-row" *ngIf="showTimesheetButton">
        <div class="col-4 label-name">{{ 'pages.sn.slider-panel.time-sheet' | translate }}</div>
        <div class="col-8 text-content">
            <button 
                class="standard brand-blue button-content w-100"
                (click)="onClickTimeComplete()"
                [disabled]="parameters.isLoading || isLoadingTimesheet || !parameters.ccsServiceOrderNumber || !parameters.workCentreAttribute || !parameters.workCentreAttribute.enableTimesheet">
                <span *ngIf="!hasTimesheet">{{ 'pages.sn.slider-panel.fill-in' | translate }}</span>
                <span *ngIf="hasTimesheet">{{ 'pages.sn.slider-panel.inputted' | translate }}</span>
            </button>
        </div>
    </div>
    <div class="row data-row" *ngIf="showTimesheetV2Button">
        <div class="col-4 label-name">{{ 'pages.sn.slider-panel.time-sheet' | translate }}</div>
        <div class="col-8 text-content">
            <button 
                class="standard brand-blue button-content w-100"
                (click)="onClickTimeCompleteV2()"
                [disabled]="parameters.isLoading || isLoadingTimesheet || !parameters.ccsServiceOrderNumber || !parameters.workCentreAttribute || !parameters.workCentreAttribute.enableTimesheet">
                <span *ngIf="!hasTimesheet">{{ 'pages.sn.slider-panel.fill-in' | translate }}</span>
                <span *ngIf="hasTimesheet">{{ 'pages.sn.slider-panel.inputted' | translate }}</span>
            </button>
        </div>
    </div>
</div>

<div class="row mt-3" *ngIf="!hasApprovePermission">
    <div class="col-12">
      <ng-select
        class="my-2"
        placeholder="{{'pages.sn.slider-panel.approver-input-placeholder' | translate}}"
        [(ngModel)]="selectedApprover"
        [items]="approverListOptions"
        [multiple]="true"
        [loading]="isLoadingApprover"
        [disabled]="isLoadingApprover || parameters.isLoading"
        [clearable]="false"
        [closeOnSelect]="false"
        >
      </ng-select>
    </div>
</div>

<div class="reason-drop-down">
    <ng-select  bindLabel="description" bindValue="value"
        placeholder="* {{ 'pages.sn.slider-panel.complete-reason' | translate }}" 
        [items]="completeReasonOptions"
        [disabled]="parameters.isLoading || (completeReasonOptions && completeReasonOptions.length == 1) || (needSoNumber && !parameters.ccsServiceOrderNumber && !completeWithNoAction)"
        [closeOnSelect]="true"
        [(ngModel)]="completeReason"
        (change)="onCompleteReasonChanged()">
    </ng-select>
</div>

<div>
    <textarea 
        maxlength="{{ getMaxRemarksLength }}" 
        type="text" 
        rows="5" 
        placeholder="{{ 'pages.sn.complete-remarks' | translate }}" 
        class="form-control" 
        [(ngModel)]="remark" 
        [disabled]="parameters.isLoading || (needSoNumber && !parameters.ccsServiceOrderNumber && !completeWithNoAction)"
    >
    </textarea>
    <span 
        *ngIf="remark?.length >= getMaxRemarksLength" 
        class="red small"
    >
        {{ "textarea.warning.max-length" | translate:[getMaxRemarksLength]}}
    </span>
</div>

<div *ngIf="requireNonComplianceReason"
    class="mt-4">
    <ng-select
        bindLabel="description"
        bindValue="value"
        placeholder="{{ 'pages.jobcard-view.slider-panel.complete-jobcard.non-compliance' | translate }}"
        [items]="haNonComplianceReasonOptions"
        [(ngModel)]="haNonComplianceReason"
        [closeOnSelect]="true"
        [disabled]="parameters.isLoading || (needSoNumber && !parameters.ccsServiceOrderNumber)"
    ></ng-select>
</div>

<div class="checkbox-wrap position-relative custom-control custom-checkbox mt-3">
    <input [id]="'comfirm-checkbox' + idExtension" type="checkbox" class="checkbox custom-control-input" [(ngModel)]="confirmed" 
    [disabled]="parameters.isLoading || needSoNumber && !parameters.ccsServiceOrderNumber && !completeWithNoAction">
    <label [for]="'comfirm-checkbox' + idExtension" class="custom-control-label d-block"><span class="red">* </span>{{ 'pages.sn.slider-panel.complete-job-confirm-checkbox' | translate }}</label> 
</div>

<!-- Follow up -->
<div *ngIf="requireCreateFollowUpJob()">
    <create-follow-up-job-card-form [parameters]="createFollowUpJobCardFormParam"></create-follow-up-job-card-form>
</div>

<div class="mt-3">
    <span class="red">*</span> {{'pages.sn.mandatory-fields' | translate}}
</div>
