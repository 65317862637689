import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/AppDelegate';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-pm-plan-particulars',
  templateUrl: './pm-plan-particulars.component.html',
  styleUrls: ['./pm-plan-particulars.component.scss']
})
export class PmPlanParticularsComponent implements OnInit {
  @Input() plan: JMOBJ.PmPlan;
  @Input() pageMode: JMENUM.JMPageMode;

  JMPageMode = JMENUM.JMPageMode;

  // Validation 
  valid: boolean;
  errorFields: any = {};

  editable: any = {};
  mandatory: any = {};

  // Input Fields
  equipmentCount: number;
  handlingTeam: string;
  teamName: string;

  // Team Dropdown
  teamOptions: any = [];
  private searchTeamObserver = new Subject<any[]>();
  searchTeamKeywords: string = null;
  teamPageSize: number = 100;
  teamPageNumber: number = 0;
  teamTotalCount: number;
  isLoadingTeam: boolean = false;
  isDisabledTeam: boolean = true;

  emptyHandleDisplay = JMLanguage.translate('component.display-text-field.na');
  
  constructor() { }

  ngOnInit() {
    if (this.pageMode !== JMENUM.JMPageMode.VIEW) {
      this.fieldsControl();

      this.searchTeamObserver.pipe(
        debounceTime(Constants.DEBOUNCE_TIME),
      ).subscribe(() => {
        this.searchTeams()
      }
      );
    }
  }

  ngOnChanges() {
    let isDraftEdit: boolean = this.pageMode === JMENUM.JMPageMode.EDIT && this.plan.status === JMENUM.PMStatus.DRAFT;
    if ((this.pageMode === JMENUM.JMPageMode.VIEW && this.plan.pmPlanNumber) || !isDraftEdit) {
      this.requestTeamName();
    }
    if (this.pageMode === JMENUM.JMPageMode.CREATE || isDraftEdit) {
      this.toggleTeamInput();
      this.requestTeamList();
    }
    this.fieldsControl();
  }

  // ----------- API ----------- //
  private async requestTeamName() {
    if (!this.plan || !this.plan.teamId) return;
    const request = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['_id', 'name'];
    request.idList = [this.plan.teamId];

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records.length > 0) {
      this.teamName = response.payload.records[0].name;
    }
  }

  private async requestTeamList() {
    if (this.teamTotalCount !== null &&
      this.teamTotalCount <= this.teamPageNumber * this.teamPageSize || !this.plan.workCentre) {
      return;
    }
    this.teamPageNumber++;

    const request = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['_id', 'name', 'workCentre'];
    request.pageNumber = this.teamPageNumber;
    request.pageSize = this.teamPageSize;
    request.handlingPartyList = [JMENUM.HandlingParty.INHOUSE];
    request.workCentreList = this.plan.workCentre ? [this.plan.workCentre] : [];
    if (this.searchTeamKeywords) {
      request.filter = { name: this.searchTeamKeywords };
    }

    this.isLoadingTeam = true;
    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records.length > 0) {
      const options =
        response.payload.records
          .filter(team => team.workCentre == this.plan.workCentre) // exclude borrowed team
          .map(team => {
            let obj = { value: team._id, label: team.name };
            return obj;
          });
      this.teamTotalCount = response.payload.totalCount;
      this.teamOptions = this.teamOptions.concat(options);
    }
  }

  // ----------- UI function ----------- //
  private fieldsControl() {
    if (this.pageMode === JMENUM.JMPageMode.CREATE) {
      this.editable = {
        equipmentCount: false,
        teamId: true,
      };
    } else if (this.pageMode === JMENUM.JMPageMode.EDIT) {
      if (this.plan.status === JMENUM.PMStatus.DRAFT) {
        this.editable = {
          equipmentCount: false,
          teamId: true,
        };
      } else {
        this.editable = {
          equipmentCount: false,
          teamId: false,
        };
      }
    }
    this.mandatory = { ...this.editable };
  }

  public onTeamScrollToEnd() {
    this.requestTeamList();
  }

  public onSearchTeam(event) {
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver.next();
  }

  public onClearTeam(event) {
    this.searchTeamKeywords = null;
    this.searchTeamObserver.next();
  }

  public searchTeams() {
    this.teamOptions = [];
    this.teamPageNumber = 0;
    this.teamTotalCount = null;
    this.requestTeamList();
  }

  public clearAll() {
    for (let field in this.editable) {
      this.plan[field] = undefined;
    }
  }

  public clearTeam() {
    this.teamOptions = [];
    this.teamPageNumber = 0;
    this.teamTotalCount = null;
    this.searchTeamKeywords = undefined;
    this.plan.teamId = undefined;
  }

  public toggleTeamInput() {
    if (this.plan.workCentre) {
      this.isDisabledTeam = false;
    } else {
      this.isDisabledTeam = true;
    }
  }

  public validate() {
    let hasErrorField = false;

    // Check fields have data
    for (let field in this.editable) {
      if (field && this.mandatory[field]) {
        this.errorFields[field] = this.plan[field] ? false : true;
      }
    }
    for (let field in this.editable) {
      if (field && this.errorFields[field]) {
        hasErrorField = true;
      }
    }

    if (hasErrorField) {
      AppDelegate.openSnackBar(JMLanguage.translate("pages.sn-edit.error.msg.mandatory-field"));
    }

    this.valid = !hasErrorField;
    return this.valid;
  }

  get showPmPlanEquipmentLink(): boolean {
    const equipmentPlanCoverage = this.plan.planCoverage == JMENUM.PMPlanCoverage.EQUIPMENT;
    const matchStatus = [JMENUM.PMStatus.DRAFT, JMENUM.PMStatus.SUBMITTED].includes(this.plan.status as JMENUM.PMStatus);

    if (equipmentPlanCoverage && matchStatus) {
      return true;
    }
    return false;
  }
}
