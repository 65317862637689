<div>
  <div class="modal-body">
    <div class="row">
      <ng-container *ngIf="inhouseTeam">
        <div class="col-lg mt-3">
          <div class="row">
            <div class="col mx-2 my-2 font-weight-bold">
              {{ 'modal-office-hours.inhouse-working-hours' | translate }}
            </div>
          </div>
          <div class="row">
            <app-office-hours-table [officeHoursParam]='inhouseOfficeHoursParam' class='col-md no-gutters'></app-office-hours-table>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="contractorTeam">
        <div class="col-lg mt-3">
          <div class="row">
            <div class="col-12 mx-2 my-2 font-weight-bold">
              {{ 'modal-office-hours.contractor-working-hours' | translate }}
            </div>
          </div>
          <div class="row">
            <app-office-hours-table [officeHoursParam]='contractorOfficeHoursParam' class='col-md no-gutters'></app-office-hours-table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <app-submit-button
      [buttonClass]="'standard brand-red'" 
      [text]="'global.close'"
      [id]="'id-back_button'"
      (onClick)="onClosed()"
      >
    </app-submit-button>
  </div>
</div>