import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from '@enum/tablexColumnType';

export const columnsApproval = [
  {
    id: 'id',
    name: 'pages.approval-list.table-column.id',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'description',
    name: 'pages.approval-list.table-column.description',
    enableFilter: false,
    type: TablexColumnType.Html,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col",
  },
  {
    id: 'requester',
    name: 'pages.approval-list.table-column.requester',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'approver',
    name: 'pages.approval-list.table-column.approver',
    enableFilter: false,
    type: TablexColumnType.MultiLine,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col",
  },
  {
    id: 'schedule-type',
    name: 'pages.approval-list.table-column.schedule-type',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'schedule-frequency',
    name: 'pages.approval-list.table-column.schedule-frequency',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'start-date',
    name: 'pages.approval-list.table-column.start-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'end-date',
    name: 'pages.approval-list.table-column.end-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'requestedDate',
    name: 'pages.approval-list.table-column.requested-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'more-info',
    name: 'pages.approval-list.table-column.more-info',
    enableFilter: false,
    type: TablexColumnType.Buttons,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
];

export const columnsHistory = [
  {
    id: 'id',
    name: 'pages.approval-list.table-column.id',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'description',
    name: 'pages.approval-list.table-column.description',
    enableFilter: false,
    type: TablexColumnType.Html,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col",
  },
  {
    id: 'requester',
    name: 'pages.approval-list.table-column.requester',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'schedule-type',
    name: 'pages.approval-list.table-column.schedule-type',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'schedule-frequency',
    name: 'pages.approval-list.table-column.schedule-frequency',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'start-date',
    name: 'pages.approval-list.table-column.start-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'end-date',
    name: 'pages.approval-list.table-column.end-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'requestedDate',
    name: 'pages.approval-list.table-column.requested-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'action-type',
    name: 'pages.approval-list.table-column.action-type',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'approve-by',
    name: 'pages.approval-list.table-column.approve-by',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'approve-date',
    name: 'pages.approval-list.table-column.approve-date',
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
  {
    id: 'more-info',
    name: 'pages.approval-list.table-column.more-info',
    enableFilter: false,
    type: TablexColumnType.Buttons,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: "col-1",
  },
];
