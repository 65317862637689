<div class="font-size-xl m-0 mb-2">
    <div class="overflow-hidden">
      <div class="d-inline-block">
        {{ "component.client-information.title" | translate}} 
      </div>
    </div>
</div>

<hr class="underline mb-0" />


<ng-container *ngIf="pageMode == JMPageMode.VIEW">

    <div class="col-12 row mx-0 px-0 align-items-center ">

        <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 px-0 pl-1">
            <span class="mandatory-mark">*</span>
            {{ "component.client-information.client" | translate }}
        </div>
        <div class="col-8 col-sm-4 col-xl-4 text-left font-size-s mt-4 overflow-auto">
            <app-display-text-field [textField]="jobClientNameParsed"></app-display-text-field>
        </div>

        <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 px-0 pl-1">
            {{ "component.client-information.contact-number" | translate }}
        </div>
        <div class="col-8 col-sm-4 col-xl-4 text-left font-size-s mt-4 overflow-auto">
            <app-display-text-field [textField]="_job?.contactNumber"></app-display-text-field>
        </div>

        <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 px-0 pl-1">
            {{ "component.client-information.contact-person" | translate }}
        </div>
        <div class="col-8 col-sm-4 col-xl-4 text-left font-size-s mt-4 overflow-auto">
            <app-display-text-field [textField]="_job?.contactPerson"></app-display-text-field>
        </div>

        <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 px-0 pl-1">
            {{ "component.client-information.email-address" | translate }}
        </div>
        <div class="col-8 col-sm-4 col-xl-4 text-left font-size-s mt-4 overflow-auto">
            <app-display-text-field [textField]="_job?.email"></app-display-text-field>
        </div>

    </div>
</ng-container>

<ng-container *ngIf="pageMode == JMPageMode.CREATE || pageMode == JMPageMode.EDIT">
<div class="col-12 row mx-0 px-0 align-items-center ">
    <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.client-information.client" | translate }}
    </div>

    <div *ngIf="inputFieldSettings?.client?.editable" class="col-4 col-sm-4 text-left font-size-s m-0 mt-4 mb-4">
        <ng-select
            id="edit-job-pm-plan"
            class="px-0"
            bindLabel="label"
            bindValue="value"
            [class.input-error]="inputFieldSettings?.client?.failed"
            [items]="clientsOptions"
            [(ngModel)]="_job.client"
        ></ng-select>
    </div>

    <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        {{ "component.client-information.contact-number" | translate }}
    </div>

    <div class="col-4 col-sm-4 text-left font-size-s m-0 mt-4 mb-4">
        <input
            class="form-control" 
            type="tel" 
            maxlength="8"
            [class.input-error]="inputFieldSettings?.contactNumber?.failed"
            [(ngModel)]="_job.contactNumber"
            (input)="inputNumberWithIndex($event,0);"
        />
    </div>

</div>

<div class="col-12 row mx-0 px-0 align-items-center ">
    <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        {{ "component.client-information.contact-person" | translate }}
    </div>

    <div class="col-4 col-sm-4 text-left font-size-s m-0 mt-4 mb-4">
        <input matInput type="text" class="form-control" maxlength="32" [(ngModel)]="_job.contactPerson" />
    </div>

    <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        {{ "component.client-information.email-address" | translate }}
    </div>

    <div class="col-4 col-sm-4 text-left font-size-s m-0 mt-4 mb-4">
        <input 
            matInput 
            class="form-control" 
            type="text" 
            maxlength="50" 
            [class.input-error]="inputFieldSettings?.email?.failed"
            [(ngModel)]="_job.email" />

    </div>

</div>

</ng-container>