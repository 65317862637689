import { Component, EventEmitter, Input, OnInit, SimpleChanges } from '@angular/core';
import { JM, JMCONSTANT, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { Constants } from 'src/constants';
import { debounce } from 'underscore';
import { Output } from '@angular/core';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { ParameterService } from '@services/parameter.service';
import { AuthorizationService } from '@services/authorization.service';
import { Session } from 'src/app/services/session';

interface ISelectOptions {
  value: string;
  label: string;
  workCentre: string;
  [propName: string]: any;
}

interface ITeamAPIRespnse {
  _id: string;
  name: string;
  workCentre: string;
  members: string[];
  handlingParty: JMENUM.HandlingParty;
}

interface IInitOptions {
  items: ISelectOptions[];
  nextPage: number;
  totalPage: number;
  search: '';
  filter: string | {[propName: string]: any} | null;
  reset: () => IInitOptions;
  setFilter: (val: string) => IInitOptions;
  setSearch: (val: string) => IInitOptions;
}

const initOptions: IInitOptions = {
  items: [],
  nextPage: 1,
  totalPage: 1,
  search: '',
  filter: null,
  reset() {
    this.items = [];
    this.nextPage = 1;
    this.totalPage = 1;
    this.search = '';
    this.filter = null;
    return this;
  },
  setFilter(val) {
    this.filter = val;
    return this;
  },
  setSearch(val) {
    this.search = val;
    return this;
  }
};

@Component({
  selector: 'app-jobcard-job-details',
  templateUrl: './jobcard-job-details.component.html',
  styleUrls: ['./jobcard-job-details.component.scss']
})
export class JobcardJobDetailsComponent implements OnInit {
  @Input() sn;
  @Input() jobCard: JMOBJ.JobCard;
  @Input() componentParameters;
  @Input() pageMode: JMENUM.JMPageMode;

  @Output() teamChange = new EventEmitter<{
    workCentre: string;
    members: string[];
    handlingParty: number;
  }>();
  @Output() updateSnData = new EventEmitter<{ [key: string] : any }>();
  @Output() updateJobCardData = new EventEmitter<{ [key: string] : any }>();

  parameterService: ParameterService
  authorizationService: AuthorizationService

  JMENUM = JMENUM;
  textareaMaxLength = Constants.TEXTAREA_MAX_LENGTH;
  workCentreOptions: string[] = [];
  // Options config
  optionsPageSize = 100;
  _teamOptions = initOptions;
  _teamOptionsStandalone = initOptions;
  set teamOptions( tOptions: IInitOptions ){
    if (this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
      this.workCentreOptions = JM.JMConnector.getAllWorkCentreCode();
    } else {
      this.workCentreOptions = this.authorizationService.getWorkCenters();
    }
    this._teamOptions = {...tOptions};
    this._teamOptionsStandalone = {
      ...tOptions,
      items : tOptions.items.filter( item => (item.handlingParty === JMENUM.HandlingParty.INHOUSE && this.workCentreOptions.includes(item.workCentre)
      ))
    };
  }
  get teamOptions(){
    if(this.isJobNatureStandalone){
      return this._teamOptionsStandalone
    } else {
      return this._teamOptions;
    }
  }
  jobTypeOptions = [];
  onTeamSelectScrollToEndDebounce: () => void;
  onTeamSelectSearchDebounce: (e) => void;

  errorFields: { [key: string] : any } = {};
  matOptions = [];

  fields: {[x:string]:any} = {};

  // Need formatting
  jobStatus: string;
  teamName: string;

  constructor(parameterService: ParameterService, authorizationService: AuthorizationService) {
    this.onTeamSelectScrollToEndDebounce = debounce(this.onTeamSelectScrollToEnd, 250);
    this.onTeamSelectSearchDebounce = debounce(this.onTeamSelectSearch, 250);
    this.parameterService = parameterService;
    this.authorizationService = authorizationService;
  }

  ngOnInit() {
    this.fieldControl();
    this.requestTeamSummary();
    this.jobTypeOptions = this.getJobTypeOptions();

    if (this.jobCard.snTeamId) {
      this.requestTeam();
    }
  }

  ngOnChanges(changes: SimpleChanges){
    let { jobCard } = changes;
    if (jobCard) {
      const translationKey = `jobcard.status.${this.jobCard.status}`;
      this.jobStatus = JMLanguage.translate(translationKey);

      this.setMatOption(changes.jobCard.currentValue.orderType); 

      let prevJobNature = jobCard.previousValue && jobCard.previousValue.jobNature;
      let nextJobNature = jobCard.currentValue && jobCard.currentValue.jobNature;
      if (prevJobNature !== nextJobNature) {
        this.clearValidations();
      }
    }
  }

  fieldControl() {
    let fieldsArr;
    if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE) {
      fieldsArr = ["matType", "orderType", "teamId", "jobDescription"];
    } else {
      fieldsArr = ["jobNature", "orderType", "teamId", "jobDescription"];
    }

    fieldsArr.forEach(fieldName => {
      let editable = this.getEditable(fieldName);
      const mandatory = true;

      this.fields[fieldName] = {
        editable,
        mandatory,
      }
    });
  }

  getEditable(fieldName: string) {
    if (this.pageMode == JMENUM.JMPageMode.VIEW) return false;

    if (this.pageMode == JMENUM.JMPageMode.CREATE) {
      if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE && fieldName == "orderType") return false;
      return true;
    }

    if (this.pageMode == JMENUM.JMPageMode.EDIT) {
      if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE && fieldName == "matType") return false;
      if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE && fieldName == "orderType") return false;
      if (this.jobCard.jobNature == JMENUM.JobNature.STANDALONE && fieldName == "teamId") return false;
      return true;
    }

    return false;
  }

  ngModelChangeForJobCard(key:string, value:any){
    let toEmitData:{[key: string] : any} = {
      [key]:value
    };
    if (key === 'orderType') {
      toEmitData.matType = null;
    }
    this.updateJobCardData.emit(toEmitData);
  }

  ngModelChangeForSn(key:string, value:any){
    this.updateSnData.emit({ [key]: value });
  }

  onTeamSelectScrollToEnd = () => {
    this.requestTeamSummary();
  }

  onTeamSelectSearch = (e) => {
    this.teamOptions.reset().setFilter(e.term).setSearch(e.term);
    this.requestTeamSummary();
  }

  onTeamSelectClear = () => {
    this.teamOptions.reset();
    this.teamChange.emit({
      members: [],
      workCentre: '',
      handlingParty: null,
    });
    this.requestTeamSummary();
  }

  onTeamSelectClick = () => {
    if (!this.sn.teamId && !!this.teamOptions.search) this.onTeamSelectClear();
  }

  getJobTypeOptions = () => {
    return Object.keys(JMENUM.JobType).map(key => {
      return {
        value: JMENUM.JobType[key],
        label: JMENUM.JobType[key],
      };
    });
  }

  onMaintenanceTypeButtonClick = (type: JMENUM.JobNature) => {
    let toEmitJobCardData: { [key: string]: any } = {};
    let toEmitSnData: { [key: string]: any } = {};
    toEmitJobCardData.jobNature = type;
    toEmitJobCardData.matType = null;
    toEmitSnData.teamId = null;
    if (type === JMENUM.JobNature.PM) {
      toEmitJobCardData.orderType = JMENUM.OrderType.SLA_JOB;
      toEmitJobCardData.malfunctionStartTime = null;
      toEmitJobCardData.malfunctionEndTime = null;
    } else if (type === JMENUM.JobNature.STANDALONE) {
      toEmitJobCardData.orderType = JMENUM.OrderType.SLA_JOB;
    }
    this.updateJobCardData.emit(toEmitJobCardData);
    this.updateSnData.emit(toEmitSnData);
  }

  private async requestTeam() {
    const request: JM.JMRequestTeamsTeamSummary = new JM.JMRequestTeamsTeamSummary();
    request.idList = [this.jobCard.snTeamId];

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    let team = response.payload.records[0];
    this.teamName = team ? team.name + " (" + team.workCentre + ")" : undefined;
  }
  
  requestTeamSummary = async () => {
    if (this.teamOptions.nextPage > this.teamOptions.totalPage) {
      return console.warn('Team options nextpage is larger than its totalpage...');
    }
    const request = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['name', 'workCentre', 'members', '_id', 'handlingParty'];
    request.pageNumber = this.teamOptions.nextPage;

    request.handlingPartyList = [JMENUM.HandlingParty.INHOUSE];
    this.jobCard.jobNature != JMENUM.JobNature.STANDALONE && request.handlingPartyList.push(JMENUM.HandlingParty.NON_PMSMC);

    request.pageSize = this.optionsPageSize;
    if (typeof this.teamOptions.filter === 'string') {
      request.filter = { name: this.teamOptions.filter };
    }
    if (this.componentParameters.userWorkCenters && this.componentParameters.userWorkCenters.length) {
      request.workCentreList = this.componentParameters.userWorkCenters;
    }

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    // update locationOptions property
    this.teamOptions = {
      ...this.teamOptions,
      items: this.teamOptions.items.concat(this.getNewTeamOptions(response.payload.records)),
      nextPage: this.teamOptions.nextPage + 1,
      totalPage: Math.ceil(response.payload.totalCount / this.optionsPageSize),
    };
  }

  getNewTeamOptions = (locationResArr: ITeamAPIRespnse[]): ISelectOptions[] => {
    return locationResArr.map( ({_id, name, workCentre, members, handlingParty}) => {
      return {
        value: _id,
        label: `${name} (${workCentre})`,
        members,
        workCentre,
        handlingParty
      };
    }).sort( (a, b) => (b.label > a.label) ? -1 : 1 );
  }

  onHandlingTeamSelectChange = (option: ISelectOptions) => {
    if (!option) { return console.log('No Team selected.'); }
    const { value, members, workCentre, handlingParty } = option;
    this.sn.teamId = value;
    this.teamChange.emit({ members, workCentre, handlingParty });
  }

  clearValidations = () =>{
    this.errorFields = {};
  }

  validation = () => {
    this.clearValidations();
    let mandatoryUiFields, mandatoryDataFields;
    if(this.isJobNatureStandalone){
      mandatoryUiFields = ['jobNature', 'jobDescription', 'teamId', 'orderType', 'matType'];
      mandatoryDataFields = ['jobNature', 'jobDescription', 'snTeamId', 'orderType', 'matType'];
    } else {
      mandatoryUiFields = ['jobNature', 'jobDescription', 'teamId', 'orderType'];
      mandatoryDataFields = ['jobNature', 'jobDescription', 'teamId', 'orderType'];
    }
    let isValid = true;
    mandatoryDataFields.forEach((field, index) => {
      const snFieldVal = this.sn && this.sn[field];
      const jobCardFieldVal = this.jobCard && this.jobCard[field];
      const fieldValue = snFieldVal || jobCardFieldVal;
      this.errorFields[mandatoryUiFields[index]] = !hasValue(fieldValue);
      if (!hasValue(fieldValue)) {
        isValid = false;
      }
    });
    if (!isValid) {
      AppDelegate.openSnackBar(JMLanguage.translate('pages.sn-edit.error.msg.mandatory-field'));
    }
    return isValid;

    function hasValue(val) {
      if (Array.isArray(val)) { return !!val.length && hasValue(val[0]); }
      if (typeof val === 'boolean') { return true; }
      return !!val;
    }
    function getObjValue(obj, porpStr) {
      return porpStr.split('.').reduce((o, i) => o[i], obj);
    }
  }
  
  private setMatOption(orderType : JMENUM.OrderType) {
    if (!orderType) return;

    const matList = Object.values(JMCONSTANT.MatDictionary);

    this.matOptions = matList.filter(mat => {
      return mat.jobNatures.includes(this.jobCard.jobNature as JMENUM.JobNature) 
        && mat.orderTypes.includes(this.jobCard.orderType as JMENUM.OrderType)
        && mat.jobFollowTypes.includes(JMENUM.JobFollowType.FIRST_ATTEND)
    }).map(mat => {
      const desc = mat.description[Session.selectedLanguage];
      return {
        description: `${mat.code} - ${desc}`,
        value: mat.code
      }
    }).sort((a, b) => {
      const descA = a.description.toUpperCase();
      const descB = b.description.toUpperCase();
      if (descA < descB) {
        return -1;
      }
      if (descA > descB) {
        return 1;
      }
      return 0;
    });
  }

  get isJobNatureStandalone(){
    return this.jobCard.jobNature === JMENUM.JobNature.STANDALONE;
  }
  get isJobNatureCM(){
    return this.jobCard.jobNature === JMENUM.JobNature.CM;
  }
  get isJobNaturePM(){
    return this.jobCard.jobNature === JMENUM.JobNature.PM;
  }

  get descriptionLabel(){
    return JMLanguage.translate(`jobcard.job-nature.${this.jobCard.jobNature}`);
  }
}
