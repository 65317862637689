<div class="wrapper page-enquiry">
  <app-nav navbarTitle="{{ 'pages.enquiry.title' | translate }}"></app-nav>
  <app-sidebar type='enquiry'></app-sidebar>
  
  <!-- Page Content -->
  <div id="content">
    <div class="row content">
      <article class="col-8">
        <h2 class="title">
          <span *ngIf="data.enquiryNumber" class="enquiryId">{{ data.enquiryNumber }}</span>
          <!-- <span *ngIf="data.status" class="float-right d-flex align-items-center">
            <span *ngIf="isLoading.updateStatus" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            {{ 'enquiry.status.' + data.status | translate }}
          </span> -->
        </h2>
        <hr>
        <main>
          <div class="row my-3" *ngIf="data.category">
            <div class="col-3 label font-weight-bold">{{ 'pages.enquiry.label.category' | translate }}</div>
            <div class="col-9 value">{{ 'enquiry.category.' + data.category | translate }}</div>
          </div>
          <div class="row my-3" *ngIf="data.subCategory">
            <div class="col-3 label font-weight-bold">{{ 'pages.enquiry.label.subCategory' | translate }}</div>
            <div class="col-9 value">{{ 'enquiry.subCategory.' + data.subCategory | translate }}</div>
          </div>
          <div class="row my-3 field-status" *ngIf="data.status">
            <div class="col-3 label font-weight-bold">{{ 'pages.enquiry.label.status' | translate }}</div>
            <div *ngIf="!canUpdateStatus" class="col-9 value">
              <i [className]="'fa fa-circle ' + data.status + ' mr-1'"></i>
              {{ 'enquiry.status.' + data.status | translate }}
            </div>
            <div *ngIf="canUpdateStatus" class="col-9 value">
              <ng-select 
                id="form-item--status" 
                name="form-item--status" 
                bindValue="value"
                bindLabel="label"
                [clearable]="false" 
                [searchable]="false"
                [loading]="isLoading.updateStatus"
                (change)="onStatusChange()"
                [(ngModel)]="data.status"
              >
                <ng-option *ngFor="let option of statusOption" [value]="option.value">
                  <i [className]="'fa fa-circle ' + option.value + ' mr-1'"></i>
                  {{ option.label }}
                </ng-option>
              </ng-select>
              <button 
                *ngIf="hasStatusChanged"
                class="btn brand-blue ml-2"
                (click)="updateStatus()"
              >
                {{ 'pages.enquiry.label.update' | translate }}
              </button>
            </div>
          </div>
          <div class="row my-3" *ngIf="data.description">
            <div class="col-3 label font-weight-bold">{{ 'pages.enquiry.label.description' | translate }}</div>
            <div class="col-9 value text-break" [innerHTML]="renderPlainLongText(data.description)"></div>
          </div>
          <div class="row mt-5">
            <div class="col-3 label font-weight-bold">{{ 'pages.enquiry.label.discussion' | translate }}</div>
            <div class="col-9">
              <!-- Comment Form -->
              <form>
                <div class="mb-3">
                  <textarea 
                    id="form-item--add-comment" 
                    name="form-item--add-comment"
                    class="form-control col"
                    rows="5"
                    placeholder="{{ 'pages.enquiry.placeholder.comment' | translate }}"
                    [class.input-error]="commentErrorFields.comment"
                    [disabled]="!canComment"
                    [(ngModel)]="commentData.comment"
                  ></textarea>
                </div>
                
                <ng-container *ngIf="commentAttachmentsTemp.length">
                  <ul class="attachments list-unstyled">
                    <li *ngFor="let file of commentAttachmentsTemp; let i = index;" class="pl-2 mb-1 d-flex align-items-center">
                      <i [class]="'fas fa-fw fa-lg ' + getFileIconClass(file.name) + ' blue mr-2'"></i>
                      {{file.name}} - {{fileSize(file.size)}}
                      <span class="btn ml-auto" (click)="removeAttachment(i)">×</span>
                    </li>
                  </ul>
                </ng-container>

                <!-- <div class="desc m-2">
                  {{ 'pages.enquiry-form.help-text.allowed-files' | translate }}: {{ allowedFileExtPrint }}.
                </div> -->

                <div class="d-flex">
                  <button class="btn brand-blue mr-2 px-4" (click)="onCommentSubmit()" [disabled]="!canComment">
                    {{ 'pages.enquiry.label.post' | translate }}
                    <span *ngIf="isLoading.addDiscussion" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                  </button>
                  <button class="btn brand-blue" [disabled]="!canComment" (click)="fileInput.click()" [disabled]="!canComment">
                    {{ 'pages.enquiry.label.add-attachments' | translate }}
                  </button>
                  <!-- <button class="btn gray-40" (click)="resetComment()">{{ 'pages.enquiry.label.reset' | translate }}</button> -->
                  <input 
                    #fileInput 
                    multiple 
                    ngDefaultControl 
                    type="file" 
                    class="hidden" 
                    (change)="onFileInput($event)" 
                    [disabled]="!canComment" 
                  />
                </div>
              </form>
              <!-- Comment Rows -->
              <ng-container *ngIf="data.discussion && data.discussion.length">
                <ul class="discussion list-unstyled my-4">
                  <li *ngFor="let comment of sortDiscussion(data.discussion); let i = index;">
                    <hr *ngIf="i !== 0" class="my-4">

                    <div class="author d-flex my-2">
                      <small class="name mr-2 font-weight-bold blue">{{ comment.createdBy }}</small>
                      <small class="date gray-40 mr-2">{{ showCommentTime(comment.createdAt) }}</small>
                    </div>

                    <div class="msg my-2" [innerHTML]="renderPlainLongText(comment.comment)"></div>
                    
                    <ng-container *ngIf="comment.attachments && comment.attachments.length">
                      <ul class="attachments list-unstyled my-2 mx-n2 d-flex flex-wrap">
                        <li 
                          *ngFor="let file of comment.attachments; let i = index;" 
                          class="text-truncate mb-1 p-1 px-2"
                          (click)="downloadFile(file.attachmentId)"
                        >
                          <i [class]="'fas fa-fw fa-lg ' + getFileIconClass(file.description) + ' blue'"></i>
                          <!-- {{file.name}} - {{fileSize(file.size)}} -->
                          {{file.description}}
                        </li>
                      </ul>
                    </ng-container>
                  </li>
                </ul>
              </ng-container>
            </div>
          </div>
        </main>
      </article>

      <aside class="col-4">
        <div class="mb-3" *ngIf="data.updatedBy">
          <div class="label gray-40 mb-1"><small>{{ 'pages.enquiry.label.lastUpdatedBy' | translate }}</small></div>
          <div class="value">{{ data.updatedBy }}</div>
        </div>

        <div class="mb-3" *ngIf="data.updatedAt">
          <div class="label gray-40 mb-1"><small>{{ 'pages.enquiry.label.lastUpdatedAt' | translate }}</small></div>
          <div class="value">{{ moment(data.updatedAt).format(constants.DATETIME_FORMAT) }}</div>
        </div>

        <div class="mb-3" *ngIf="data.contactName || data.submittedBy">
          <div class="label gray-40 mb-1"><small>{{ 'pages.enquiry.label.createdBy' | translate }}</small></div>
          <div class="value">{{ data.contactName || data.submittedBy }}</div>
        </div>
        
        <div class="mb-3" *ngIf="data.submittedAt">
          <div class="label gray-40 mb-1"><small>{{ 'pages.enquiry.label.createdAt' | translate }}</small></div>
          <div class="value">{{ moment(data.submittedAt).format(constants.DATETIME_FORMAT) }}</div>
        </div>
        
        <div class="mb-3" *ngIf="data.contactNumber">
          <div class="label gray-40 mb-1"><small>{{ 'pages.enquiry.label.contactNumber' | translate }}</small></div>
          <div class="value">{{ data.contactNumber }}</div>
        </div>
        
        <div class="mb-3" *ngIf="data.email">
          <div class="label gray-40 mb-1"><small>{{ 'pages.enquiry.label.email' | translate }}</small></div>
          <div class="value"><a [href]="'mailto:' + data.email">{{ data.email }}</a></div>
        </div>
        
        <div class="mb-3" *ngIf="data.ccList && data.ccList.length">
          <div class="label gray-40 mb-1"><small>{{ 'pages.enquiry.label.ccList' | translate }}</small></div>
          <div class="value">
            <ng-container *ngFor="let person of data.ccList; let i = index">
              <span *ngIf="i !== 0">, </span>
              <span>{{ person }}</span>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="data.attachments && data.attachments.length">
          <div class="mb-3">
            <div class="label gray-40 mb-1"><small>{{ 'pages.enquiry.label.attachments' | translate }}</small></div>
            <div class="">
                <ul class="attachments list-unstyled">
                  <li 
                    *ngFor="let file of data.attachments; let i = index;" 
                    class="text-truncate my-1 py-1"
                    (click)="downloadFile(file.attachmentId)"
                  >
                    <i [class]="'fas fa-fw fa-lg ' + getFileIconClass(file.description) + ' blue mr-1'"></i>
                    <!-- {{file.name}} - {{fileSize(file.size)}} -->
                    {{file.description}}
                  </li>
                </ul>
            </div>
          </div>
      </ng-container>
      </aside>
    </div>
  </div>
</div>
