import { Injectable, Injector, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, DefaultLangChangeEvent } from '@ngx-translate/core';
import { AuthorizationService } from '@services/authorization.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { zip } from 'rxjs/operators';
import { InformationDialogHelper } from 'src/app/ui/components/information-dialog/information-dialog-helper';
import { InformationDialogComponent } from 'src/app/ui/components/information-dialog/information-dialog.component';
import { PopUpDialogComponent } from 'src/app/ui/components/pop-up-dialog/pop-up-dialog.component';
import { Session }  from 'src/app/services/session';
import { Constants, RoutePaths, PagesWithReLoginRedirect } from 'src/constants';
import * as jwt_decode from "jwt-decode";
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';

@Injectable()
export class BasePage  implements OnInit {
    protected authorizationService: AuthorizationService;
    public dialog: MatDialog;
    public snackBar: MatSnackBar
    protected translateService: TranslateService;
    protected cookieService: CookieService;
    protected route: ActivatedRoute;
    protected router: Router;

    showSideBar = false;
    lang: string = 'en'; //TODO: remove lang
    token: string = null;

    constants = Constants; // for html use
    
    constructor(injector: Injector, requiredLogin = true) {
        
        this.authorizationService = injector.get(AuthorizationService);
        this.translateService = injector.get(TranslateService);
        this.cookieService = injector.get(CookieService);
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.dialog = injector.get(MatDialog);
        this.snackBar = injector.get(MatSnackBar);

        
        this.decodeToken(); // decode possible token
        // check if the user logged in
        if (requiredLogin) {
            let user = Session.userInfo;
            let token = Session.userToken;
            if (token) {
                this.authorizationService.setUserInfo(user, token);  
                Session.setUserToken(token);
                Session.setUserInfo(user);
                if (window.location.pathname == '/') {
                    this.router.navigate([RoutePaths.DASHBOARD]);
                }
            } else {
                if (PagesWithReLoginRedirect.includes(this.router.url)) {
                    this.router.navigate([RoutePaths.LOGIN], {
                        replaceUrl: true,
                        queryParams: {
                            successUrl: this.router.url,
                        },
                    });
                } else {
                    this.router.navigate([RoutePaths.LOGIN]);
                }
            }
        }

        this.translateService.onLangChange.subscribe((params: DefaultLangChangeEvent) => {
            this.onLangChange();
       })
    }

    ngOnInit() {

    }
    
    //----------------------------------------------------------------
    onLangChange() {}
    onLanguageChanged() {}

    //----------------------------------------------------------------
    getParameterByName(name: any) {
        let url = window.location.href;
        name = name.replace(/[[]]/g, "\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace("/+/g", " "));
    }

    isParameterEmpty(name: any) {
        let parameter = this.getParameterByName(name);
        return !parameter || parameter === '';
    }

    decodeToken() {
        this.token = this.getParameterByName('token');
        // this.token = this.route.snapshot.queryParams['token'];   // just not work
        if (this.token != undefined && this.token != null) {
            let user = jwt_decode(this.token);
            Session.setUserToken(this.token);
            Session.setUserInfo(user);
            this.authorizationService.setUserInfo(user, this.token);
        }
    }

    //----------------------------------------------------------------
    //#region API
    apiHandler(apiCall: Observable<any>, callback: any, errorHandler?: any, completeHandler?: any) {
        apiCall.subscribe(result => {
            callback(result);
        }, error => {
            let isHttpError = error && error.status;
            if (isHttpError) {
                this.openHttpErrorBar(error);
            }
            if (errorHandler) {
                errorHandler(error);
            } else if (!isHttpError) {
                console.error(error);
                this.openErrorBar(null); // unknown error
            }
        });
    }
    //#endregion

    multiApiHandler(apiCalls: Observable<any>[], mergeResultHandler: (arg0: any, arg1: any) => any, callback: (result) => void, errorHandler?: any) {
        if (apiCalls.length == 0) return;
        let mainObservable = apiCalls[0];
        for (let i = 1; i < apiCalls.length; i++) {
            let tmpObservable = apiCalls[i];
            mainObservable = mainObservable.pipe(
                zip(tmpObservable, mergeResultHandler)
            );
        }
        this.apiHandler(mainObservable, callback, errorHandler);
    }

    showInformationDialog(title: string, content: any) {
        this.dialog.open(InformationDialogComponent, InformationDialogHelper.createDialogData(title, content));
    }


    showPopUpAlert(title?: string, message?: string, buttons: any[] = [{ name: this.translate("global.ok") }], messageArray?: any[]) {
        const dialogRef = this.dialog.open(PopUpDialogComponent, {
            data: { title: title, message: message, buttons: buttons, messageArray: messageArray }
        });
        
        dialogRef.afterClosed().subscribe(action => {
            if (action) {
                action();
            }
            
        });
        
        dialogRef.disableClose = true;
    }
    
    showPopUpReminder(config: MatDialogConfig) {
        const dialogRef = this.dialog.open(PopUpDialogComponent, config);

        dialogRef.afterClosed().subscribe(action => {
            if (action) {
                action();
            }
        });
    }

    openHttpErrorBar(error: any) {
        switch (error.status) {
            case 400:
            case 404:
            case 500:
                // do nothing
                this.openSnackBar(this.translate('popupError.' + error.status + '.title'));
                break;
            case 0:
            case 401:
            case 498:   // invalid token
                this.logout();
                this.openSnackBar(this.translate('popupError.' + error.status + '.title'));
                break;
            default:
                break;
        }
    }

    /*
    {
        code: number,
        error: string,
        payload: object
    }
    */
    openErrorBar(error?: Object) {
        let code = (error && error['code']) ? error['code'] : 99999;
        let message = this.translate("api.error." + code);
        if (error && message.includes("api.error.")) {
            message = error['error'];
        }

        this.openSnackBar(message);
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, this.translate("global.close"), {
            duration: 6000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            announcementMessage: ' '
        });
    }

    logout() {
        Session.clear();
        this.router.navigate(['/login']);
    }

    handleJMError(error:JM.JMNetworkError) {
        console.error(error);
        if (error.code === 401 || error.code === 498) {
            this.logout();
        }
        this.openSnackBar(this.translate('popupError.' + error.code + '.title'));
    }

    //-----
    // formating function
    ISOStringToNgbdatestructForDate(isoString: string): any {
        let dateInformation = new Date(isoString);
        return { year: dateInformation.getFullYear(), month: dateInformation.getMonth() + 1, day: dateInformation.getDate() }
    }

    ISOStringToNgbdatestructForTime(isoString: string): any {
        let dateInformation = new Date(isoString);
        return { hour: dateInformation.getHours(), minute: dateInformation.getMinutes() }
    }

    NgbdatestructToISOString(date: any, time: any): string {
        if (date == null || date.year == null || time == null || time.hour == null) return null;
        return new Date(date.year, date.month - 1, date.day, time.hour, time.minute).toISOString();
    }

    checkViewPermission(permission: string) {
        if (!this.authorizationService.hasPermission(permission)) {
            // this.showPopUpAlert(this.translate("popupError.no-permission"));
            this.router.navigate(['']);
            return;
        }
    }

    checkWorkCenterPermission(workCentre: string) {
        if (!this.authorizationService.hasAuthorizationForWorkCenter(workCentre) && !this.authorizationService.hasPermission(JMENUM.Permission.AUTHORIZATION_ALL)) {
            this.openSnackBar(this.translate("error.no-work-centre"));
            this.router.navigate(['']);
            return;
        }
    }

    inputNumber(event: any) {
        event.target.value = event.target.value.replace(/[^\d]/g, '');
    }

    protected componentName(): string {
        return "";
    }

    // start with 0...
    // key: Welcome back ${0}!\nLast login: ${1}.
    // this.translate("Welcome back ${0}!\nLast login: ${1}.", [name, lastLoginTime]
    protected translate(key:string, args=null ) {
        let translated = this.translateService.instant(key);
        if (args) {
            for(let i=0; i<args.length; i++) {
                translated = translated.replace("${"+i+"}", args[i]);
            }
        }
        return translated;
    }

    protected trimString(value: String): String {
        return value ? value.trim() : '';
    }


}