import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { Session } from '@services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';

@Component({
  selector: 'app-pm-plan-remarks',
  templateUrl: './pm-plan-remarks.component.html',
  styleUrls: ['./pm-plan-remarks.component.scss']
})
export class PmPlanRemarksComponent implements OnInit {
  @Input() plan;
  @Input() pageMode : JMENUM.JMPageMode;
  

  // Validation 
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};

  // Input Fields
  remarks: string;
  remarksMaxLength: number = 1000;

  JMPageMode = JMENUM.JMPageMode;
  messages: {
    title?: string;
    details: string;
  }[] = [];

  constructor() { }

  ngOnInit() {
    
  }
  
  ngOnChanges(changes: SimpleChanges){
    if(this.pageMode !== JMENUM.JMPageMode.VIEW){
      this.fieldsControl();
    } else {
      this.parseMessages();
    }

  }

  // ----------- API ----------- //
  
  // ----------- UI function ----------- //
  private fieldsControl(){
    if (this.pageMode == JMENUM.JMPageMode.CREATE) {
      this.editable = {
        remarks: true,
      };
    } else if (this.pageMode == JMENUM.JMPageMode.EDIT) {
      if (this.plan.status === JMENUM.PMStatus.DRAFT) {
        this.editable = {
          remarks: true,
        };
      } else {
        this.editable = {
          remarks: true,
        };
      }
    }
    this.mandatory = {...this.editable};
  }

  onChangeRemarks(){
    this.plan['remarks'] = this.plan['remarks'].trim(); 
  }

  public clearAll(){
    this.plan['remarks'] = undefined;
  }

  parseMessages(){
    this.messages = [];

    if(this.plan){
      let cancelCode = this.plan.cancelReason;
      let cancelRemarks = this.plan.cancelRemarks;
      if (cancelCode) {
        let cancelDescription = JMREASON.PM_PLAN_CANCEL_REASON_LIST.getDescription(cancelCode);
        let cancelDescriptionWithLang = '';
        if (cancelDescription) {
          cancelDescriptionWithLang =
            Session.selectedLanguage === JMENUM.Language.ZH ? cancelDescription.zh : cancelDescription.en;
        }
        let message = {
          title: JMLanguage.translate('component.pm-plan-remarks.message.cancel-reason'),
          details: `[${cancelDescriptionWithLang}] ${cancelRemarks}`,
        };
  
        this.messages.push(message);
      }

      if(this.plan.remarks){
        this.messages.push({
          details: this.plan.remarks,
        })
      }
    }
  }
}
