<div class="wrapper">
  <app-nav [navbarTitle]="'pages.pm-job-list.page-title' | translate"> </app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="">
    <div class="row mx-0 flex-column-reverse flex-xl-row">
      <div class="col-12 col-xl-9">
        <app-tablex id="pm-job-list-table" #pmPlanListTable [tablexParam]="tableXParam" class=""></app-tablex>
      </div>

      <div class="col-12 col-xl-3">
        <app-pm-job-list-adv-search
          (search)="onAdvSearchComponentTriggerSearchAction()"
        ></app-pm-job-list-adv-search>
      </div>
    </div>
  </div>
</div>
