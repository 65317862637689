import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from 'src/app/services/session';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ, JMUTILITY } from '@ccep/CCEPConnector-ts';
@Component({
  selector: 'cancel-sn-form',
  templateUrl: './cancel-sn-form.component.html',
  styleUrls: ['./cancel-sn-form.component.scss']
})

export class CancelSnFormComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() options: any[];
  @Input() isLoading: boolean;
  @Output() onSubmit = new EventEmitter<any>();

  constants = Constants;

  // Options
  approverListOptions: string[] = [];

  // UI
  hasApprovePermission: boolean = false;
  isLoadingApprover   : boolean = false;
  submitDisabled      : boolean = true;

  // Form fields
  selectedApprover: string[];
  reason          : any;
  confirmed       : boolean;
  remark          : string;

  constructor() { }

  ngOnInit() {
    this.hasApprovePermission = JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.SN_CANCEL_APPROVE)
      || JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.SN_CANCEL_APPROVE_ALL);
  }

  ngOnDestroy() {}

  ngOnChanges() {
    if (this.sn) {
      if (!this.hasApprovePermission) {
        this.requestPostSummary();
      }
    }
  }

  //===================================================
  // API request
  private async requestPostSummary() {
    const request = new JM.JMRequestPostsPostSummary();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.systemName = Constants.SYSTEM_NAME;
    request.permissions = [JMENUM.Permission.SN_CANCEL_APPROVE];
    request.authorizations = { 
      workCenters: [this.sn.workCentre]
    }

    this.isLoadingApprover = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingApprover = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (response.payload.records && response.payload.records.length > 0) {
      this.approverListOptions = response.payload.records.filter(post => post.name !== Session.userInfo.name).map(post => post.name);
    }
  }

  //===================================================
  // UI event
  validateForm() {
    if (!this.hasApprovePermission) {
      if (this.approverListOptions.length === 0 || this.selectedApprover.length === 0){
        this.submitDisabled = true;
        return;
      }
    }

    if (!this.reason || !this.confirmed) {
      this.submitDisabled = true;
      return;
    }

    this.submitDisabled = false;
  }

  onClickSubmit() {
    if (this.remark) this.remark = this.remark.trim();

    this.onSubmit.emit({
      cancellationCode: this.reason,
      cancelRemarks   : this.remark,
      approver        : this.selectedApprover
    });
  }

  get getMaxRemarksLength():number{
    return this.constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
