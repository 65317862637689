import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JMENUM } from '@ccep/CCEPConnector-ts';
import { AuthorizationService } from '@services/authorization.service';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { ReleaseNotePlatform, ReleaseNoteViewData, ReleaseNoteViewErrorStatus } from './release-note.model';
import * as releaseNoteApi from './release-note.api';

@Component({
  selector: 'app-release-note',
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.scss']
})
export class ReleaseNoteComponent implements OnInit {

  platform: ReleaseNotePlatform = ReleaseNotePlatform.web;
  tabBar: {
    [key: number]: TabI,
  };

  // left column
  selectedNoteVersion: string;
  releaseNoteList: Array<ReleaseNoteViewData>;
  pageSize: number = 50;
  pageNumber: number = 1;
  pageCount: number = 1;

  // right column
  releaseNoteFormInput: ReleaseNoteViewData = null;
  releaseNoteFormInputError: ReleaseNoteViewErrorStatus = null;
  
  // action buttons
  actionButtons: any[] = [];
  disabledActionSideBar: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService
  ) { }

  async ngOnInit() {
    if (!this.authorizationService.hasPermission(JMENUM.Permission.RELEASENOTE_UPDATE)) {
      this.router.navigate(['/']);
      AppDelegate.openSnackBar(JMLanguage.translate('popupError.no-permission'));
      return;
    }

    this.platform = this.route.snapshot.paramMap.get('platform') as ReleaseNotePlatform;
    this.initTabItem();
    this.resetActionSideBar();

    this.resetTab(this.platform);
    this.resetTabPage();
  }

  initTabItem() {
    this.tabBar = {
      0: {
        selected: true,
        label: JMLanguage.translate('pages.release-note.tab.web'),
        platformType: ReleaseNotePlatform.web,
      },
      1: {
        selected: false,
        label: JMLanguage.translate('pages.release-note.tab.ios'),
        platformType: ReleaseNotePlatform.ios,
      },
      2: {
        selected: false,
        label: JMLanguage.translate('pages.release-note.tab.android'),
        platformType: ReleaseNotePlatform.android,
      },
    }
  }

  switchTab(platform: ReleaseNotePlatform) {
    if (platform == this.platform) return;

    switch (platform) {
      case ReleaseNotePlatform.android:
      case ReleaseNotePlatform.ios:
      case ReleaseNotePlatform.web:
        AppDelegate.navigate([`/setting/release-note/${platform}`]);
        this.platform = platform;
        this.resetTab(this.platform);
        this.resetTabPage();
        break;
      default:
        break;
    }
  }

  resetTabPage() {
    this.initReleaseNoteList();
    this.initReleaseNoteForm();
  }

  private resetTab(platfrom: ReleaseNotePlatform) {
    this.tabBar[0].selected = false;
    this.tabBar[1].selected = false;
    this.tabBar[2].selected = false;

    switch (platfrom) {
      case ReleaseNotePlatform.web:
        this.tabBar[0].selected = true;
        break;
      case ReleaseNotePlatform.ios:
        this.tabBar[1].selected = true;
        break;
      case ReleaseNotePlatform.android:
        this.tabBar[2].selected = true;
        break;
      default:
        this.router.navigate(['/']);
        AppDelegate.openSnackBar('Invalid platform');
        break;
    }
  }
  
  resetActionSideBar(): void {
    const actionButton = {
      type: 'sn',
      action: 'submit',
      buttonId: 'right-tool-bar_submit_button',
      imageUrl: '/assets/svg/btn_action_submit.svg',
      actionDescription: 'action.button.submit',
      showPopup: true,
      popupTitle: 'action.button.popup.submit',
      buttons: [
        {
          name: JMLanguage.translate("global.yes"),
          handler: this.onClickSubmit.bind(this),
        },
        { name: JMLanguage.translate("global.no") }
      ],
      permission: JMENUM.Permission.RELEASENOTE_UPDATE,
      isEnable: true,
      isShow: true
    }
    this.actionButtons.push(actionButton);
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let buttons = actionButton.buttons;
      buttons.forEach(button => {
        button.name = JMLanguage.translate(button.name);
      });
      AppDelegate.showPopUpAlert(JMLanguage.translate(actionButton.popupTitle), "", buttons);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  initReleaseNoteList() {
    this.selectedNoteVersion = "";
    this.disabledActionSideBar = true;
    
    const promiseArray: Array<Promise<any>> = [this.getVersionFullList()];
    if (this.platform != ReleaseNotePlatform.web) {
      promiseArray.push(this.getAppUpdateVersion());
    }

    Promise.all(promiseArray)
    .then((resArray) => { // merge data from 2 API
      if (Array.isArray(this.releaseNoteList) && resArray[1]) {
        this.releaseNoteList.forEach((note) => {
          note.latestVersion = resArray[1].latestVersion;
          note.minVersion = resArray[1].minVersion;
        });
      }
    }).then(() => { // default select top item
      if (Array.isArray(this.releaseNoteList) && this.releaseNoteList.length > 0) {
        this.onClickReleaseNote(null, this.releaseNoteList[0]);
      } else {
        this.onClickAddReleaseNote();
      }
    });
  }

  initReleaseNoteForm() {
    this.releaseNoteFormInput = {
      version: null,
      enMessage: null,
      zhMessage: null,
      latestVersion: null,
      minVersion: null,
    };
    this.clearFormError();
  }

  clearFormError() {
    this.releaseNoteFormInputError = {
      version: false,
      enMessage: false,
      zhMessage: false,
      latestVersion: false,
      minVersion: false,
    }
  }

  async getAppUpdateVersion() {
    const payload = await releaseNoteApi.requestAppUpdateVersion(this.platform);
    if (payload) {
      return {
        latestVersion: payload.latestVersion,
        minVersion: payload.minVersion,
      };
    }
  }

  async getVersionFullList() {
    const releaseNoteRecords = await releaseNoteApi.requestReleaseNoteList(this.platform, {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    });

    if (releaseNoteRecords) {
      this.pageCount = Math.ceil(releaseNoteRecords.totalCount / this.pageSize);

      this.releaseNoteList = releaseNoteRecords.records.map(item => {
        return {
          version: item.version,
          enMessage: item.message.en,
          zhMessage: item.message.zh,
        };
      });
    }

    while (this.pageNumber < this.pageCount) {
      const releaseNoteRecords = await releaseNoteApi.requestReleaseNoteList(this.platform, {
        pageNumber: ++this.pageNumber,
        pageSize: this.pageSize,
      });

      if (releaseNoteRecords) {
        this.releaseNoteList = this.releaseNoteList.concat(releaseNoteRecords.records.map(item => {
          return {
            version: item.version,
            enMessage: item.message.en,
            zhMessage: item.message.zh,
          };
        }));
      }
    }
  }

  onScrollReleaseNoteList(event) {
    //if user reach bottom, load more work centre
    if(event.srcElement.offsetHeight + event.srcElement.scrollTop >= event.srcElement.scrollHeight){
    }
  }

  onKeyUpSearchInput(event) {

  }

  onClickAddReleaseNote(event?) {
    this.selectedNoteVersion = "new";
    this.disabledActionSideBar = false;
    this.clearFormError();

    if (Array.isArray(this.releaseNoteList) && this.releaseNoteList.length > 0) {
      this.preFillReleaseNoteForm({
        ...this.releaseNoteList[0],
        version: "",
      });
    } else {
      this.initReleaseNoteForm();
    }
  }

  onClickReleaseNote(event, releaseNote: ReleaseNoteViewData) {
    this.selectedNoteVersion = releaseNote.version;
    this.disabledActionSideBar = false;
    this.clearFormError();
    
    this.preFillReleaseNoteForm(releaseNote);
  }

  preFillReleaseNoteForm(releaseNote: ReleaseNoteViewData) {
    this.releaseNoteFormInput = releaseNote;
  }

  async onClickSubmit() {
    const isValid = this.validateData();
    if (!isValid) return;
    
    this.disabledActionSideBar = true;
    let latestNote;
    if (this.selectedNoteVersion == "new") {
      latestNote = await releaseNoteApi.requestReleaseNoteCreate(this.releaseNoteFormInput, this.platform);
    } else {
      latestNote = await releaseNoteApi.requestReleaseNoteEdit(this.releaseNoteFormInput, this.platform);
    }
    this.disabledActionSideBar = false;

    if (latestNote) {
      this.resetTabPage();
    }
  }

  validateData(): boolean {
    let isValid = true;
    this.clearFormError();

    if (!this.releaseNoteFormInput.version) {
      this.releaseNoteFormInputError.version = true;
      isValid = false;
    }
    if (!this.releaseNoteFormInput.enMessage) {
      this.releaseNoteFormInputError.enMessage = true;
      isValid = false;
    }
    if (!this.releaseNoteFormInput.zhMessage) {
      this.releaseNoteFormInputError.zhMessage = true;
      isValid = false;
    }
    if (this.platform != ReleaseNotePlatform.web) {
      if (!this.releaseNoteFormInput.latestVersion) {
        this.releaseNoteFormInputError.latestVersion = true;
        isValid = false;
      }
      if (!this.releaseNoteFormInput.minVersion) {
        this.releaseNoteFormInputError.minVersion = true;
        isValid = false;
      }
    }
    return isValid;
  }
}

interface TabI {
  selected: boolean;
  label: string;
  platformType: ReleaseNotePlatform;
}
