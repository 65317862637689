import { Component, Input, OnInit } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/AppDelegate';
import { Session } from '@services/session';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { JMENUM } from '@ccep/CCEPConnector-ts';


@Component({
  selector: 'app-jobcard-equipment-information',
  templateUrl: './jobcard-equipment-information.component.html',
  styleUrls: ['./jobcard-equipment-information.component.scss']
})
export class JobcardEquipmentInformationComponent implements OnInit {
  @Input() jobCard: JMOBJ.JobCard;
  @Input() componentParameters;

  equipment: any;
  functionalLocation: string;

  emptyHandleNa = JMLanguage.translate('component.display-text-field.na');
  emptyHandle = JMLanguage.translate('component.display-text-field.not-selected');

  constructor() { }

  ngOnInit() {
    if(this.jobCard.equipment){
      this.requestEquipmentSummary();
    }
  }

  private async requestEquipmentSummary(){
    const request: JM.JMRequestEquipmentsEquipmentSummary = new JM.JMRequestEquipmentsEquipmentSummary();
    request.equipmentNumber = [this.jobCard.equipment];
    request.active = JMENUM.RequestActive.BOTH
    request.parameters = ["description", "functionalLocation"];

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    this.equipment = response.payload.records[0];
    this.requestLocationSummary();
    
  }

  private async requestLocationSummary() {
    if (!this.equipment.functionalLocation) return;

    const request: JM.JMRequestLocationsLocationSummary = new JM.JMRequestLocationsLocationSummary();
    request.location = [this.equipment.functionalLocation];

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }

    if (!response.payload.records || !response.payload.records.length) return;
    let location = response.payload.records[0];
    let description = location.description[Session.selectedLanguage] ? location.description[Session.selectedLanguage] : location.description["en"];
    this.functionalLocation = description + " (" + this.equipment.functionalLocation + ")";
  }


}
