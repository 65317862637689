<div class="mat-dialog-container">
  <h1 mat-dialog-title *ngIf="data.title">
    <ng-container *ngIf="data.titleIcon">
      <i [class]="data.titleIcon"></i>
    </ng-container>
    {{data.title}}
  </h1>
  <div mat-dialog-content>
    <p *ngIf="data.message">{{data.message}}</p>
    <ng-container *ngFor="let msg of data.messageArray">
      <p class="mb-1">{{msg}}</p>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngFor="let button of data.buttons; let i = index">

      <button [ngClass]="{ 
          'brand-blue': data.buttons.length == 1 || (data.buttons.length == 2 && i == 0),
           'brand-red': data.buttons.length == 2 && i == 1 
          }" (click)="buttonClicked(button)" [ngStyle]="{'backgroundColor': button.backgroundColor}">{{button.name}}</button>
    </ng-container>

  </div>
</div>