<!-- <ng-container *ngIf="currentPageMode==pageMode.VIEW"> -->
<div class="font-size-xl m-0 mb-2 px-2">
    <div class="overflow-hidden">
        <div class="d-inline-block">
            {{ "component.notification-information-detail.title" | translate}}
        </div>
        <div class="d-inline-block">
            <span id="noti-info-maintenance-type" class="maintenance-type mx-1 font-size-s" *ngIf="sn.maintenanceType">
                {{sn.maintenanceType}}
            </span>
            <span id="noti-info-handling-party" class="handling-party-type mx-1 font-size-s" *ngIf="sn.handlingParty">
                {{"team.handling-party." + sn.handlingParty | translate}}
            </span>
        </div>
        <div class="overflow-hidden d-inline-block float-right">
            <app-display-text-field id="noti-info-status" [textField]="snStatus" [emptyHandleDisplay]="emptyHandle"
                [attr.data-content]="sn.status">
            </app-display-text-field>
        </div>
    </div>
</div>
<hr class="underline" />

<div class="row align-items-center">
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">

        {{"component.notification-information-detail.client" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="noti-info-client" [textField]="client" [emptyHandleDisplay]="emptyHandle"
            [attr.data-content]="sn.client">
        </app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.teco-status" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="noti-info-teco-status" [textField]="tecoStatus" [emptyHandleDisplay]="emptyHandleNa"></app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.contact-person" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field *ngIf="!editable.contactPerson" id="noti-info-contact-person"
            [textField]="contactPerson"></app-display-text-field>
        <input type="text" class="form-control" *ngIf="editable.contactPerson" id="noti-info-contact-person-input"
            [(ngModel)]="contactPerson" (change)="assignInputToValue('contactPerson',contactPerson)"
            [ngClass]="{'input-error':errorFields.contactPerson}" />
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.contact-number" | translate}}
    </div>

    <ng-container *ngIf="!editable.contactNumber">
        <div id="noti-info-contact-number"
            class="col-4 text-left font-size-s m-0 mb-4 field-input-wrap view-input auto-add-seperate overflow-auto">
            <app-display-text-field [textFieldArray]="contactNumber"></app-display-text-field>
        </div>
    </ng-container>
    <ng-container *ngIf="editable.contactNumber">
        <div id="noti-info-contact-number" class="col-4 text-left font-size-s m-0 mb-4 field-input-wrap view-input">

            <ng-container *ngFor="let number of contactNumber; let i = index">
                <div class="m-0 p-0 contact-number-wrap position-relative">
                    <input type="text" class="form-control"
                        [ngClass]="{'mb-2': (contactNumber.length-1)!=i,'input-error':errorFields.contactNumber}"
                        [id]="'noti-info-contact-number-input-'+i" [(ngModel)]="number" maxlength="8"
                        (change)="assignInputToValueArray('contactNumber',number,i)"
                        (blur)="inputNumberWithIndex($event);" (keyup)="inputNumberWithIndex($event);" />
                    <button *ngIf="i==0 && contactNumber.length < 3" class="btn" (click)="addContactNumber()"
                        type="button">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                    <button *ngIf="i>0" class="btn" (click)="removeContactNumber(i)" type="button">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                </div>
            </ng-container>

        </div>
    </ng-container>


    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.associated-sn" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field *ngIf="!associatedSn" id="noti-info-associated-sn" [textField]="associatedSn" [emptyHandleDisplay]="emptyHandleNa">
        </app-display-text-field>
        <ng-container *ngIf="associatedSn">
            <ng-container *ngFor="let sn of associatedSn; let i = index">
                <a class="hyper-link" id="noti-info-detail-link" (click)="onClickAssociatedSn(sn)">
                    {{sn}}
                </a>
                {{(i!=associatedSn.length-1)?',':''}}
            </ng-container>
        </ng-container>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.email-address" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field *ngIf="!editable.email" id="noti-info-email" [textField]="email">
        </app-display-text-field>
        <input type="text" class="form-control" *ngIf="editable.email" id="noti-info-email-input" [(ngModel)]="email"
            (change)="assignEmailToValue('email',email)" [ngClass]="{'input-error':errorFields.email}" />
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.operation-log" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <i class="fa fa-file-text font-size-l amber cursor-pointer" id="noti-info-detail-link" (click)="onClickDetails()"></i>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.work-centre" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="noti-info-work-centre" [textField]="workCentre" [emptyHandleDisplay]="emptyHandleNa"></app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.ccs-sn-no" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="noti-info-ccs-sn-number" [textField]="ccsSnNumber" [emptyHandleDisplay]="emptyHandleNa"></app-display-text-field>
    </div>

    <!-- TODO: initial-party not yet ready -->
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.initial-system" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="noti-info-initial-party" [textField]="initialParty" [emptyHandleDisplay]="emptyHandleNa"></app-display-text-field>
    </div>
    <!--  -->

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.create-time" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="noti-info-created-at" [textField]="createdAt"></app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.create-by" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="noti-info-created-by" [textField]="createdBy"></app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.last-update-time" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">

        <app-display-text-field id="noti-info-updated-at" [textField]="updatedAt"></app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.external-ref-no" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="noti-info-externeal-ref-id" [textField]="externalRefId" [emptyHandleDisplay]="emptyHandleNa">
        </app-display-text-field>
    </div>

    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        {{"component.notification-information-detail.sn-completion-time" | translate}}
    </div>
    <div class="col-4 text-left font-size-s m-0 mb-4 overflow-auto">
        <app-display-text-field id="sn-completion-time-id" [textField]="completedAt" [emptyHandleDisplay]="emptyHandleNa">
        </app-display-text-field>
    </div>

    <!--     
    <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
        Public Toilet
    </div>
    <div class="col-4 m-0 mb-4 overflow-auto" *ngIf="true">
        <div class="btn-group">
            <button 
                id="form-is-public-toilet-on"
                class="btn"
                [class.active]="true"
                [disabled]="!true"
                (click)="onXXXchange(true)"
            >{{ "global.yes" | translate }}</button>
            <button 
                id="form-is-public-toilet-off"
                class="btn"
                [class.active]="false"
                [disabled]="!true"
                (click)="onXXXchange(false)"
            >{{ "global.no" | translate }}</button>
        </div>
    </div> 

    -->

</div>
<!-- </ng-container> -->