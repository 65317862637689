import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { JM, JMENUM, JMCONSTANT } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/services/session';
import { AppDelegate } from 'src/app/AppDelegate';
import { JMLanguage } from 'src/lib/JMLanguage/JMLanguage';
import { AuthorizationService } from '@services/authorization.service';
import { JobcardClientInformationComponent } from '../../components/jobcard-client-information/jobcard-client-information.component';
import { CustomSliderPanelComponent } from '../../components/custom-slider-panel/custom-slider-panel.component';
import { JobcardJobDetailsComponent } from '../../components/jobcard-job-details/jobcard-job-details.component';
import { HaEquipmentListPanelComponent } from '../../components/ha-equipment-list-panel/ha-equipment-list-panel.component';
import { JobCardAssignStaffFormComponent } from '../../components/job-card-assign-staff-form/job-card-assign-staff-form.component';
import { Constants } from 'src/constants';
import { JobcardHaInformationComponent } from '../../components/jobcard-ha-information/jobcard-ha-information.component';
import { JobcardTaskProgressComponent } from '../../components/jobcard-task-progress/jobcard-task-progress.component';
import { cloneDeep } from 'lodash';
import { isEnabledFeature } from 'src/app/services/utility';

const initSnData = {
  client: null,
  email: '',
  location: null,
  locationDetail: '',
  contactNumber: [''],
  contactPerson: '',
  eamData: {
    replyToHA: true,
    assetNumber: null,
  },
  teamId: null,
};
const initJobCardData = {
  jobNature: JMENUM.JobNature.CM,
  orderType: JMENUM.OrderType.SLA_JOB,
  jobDescription: '',
  eamDropdown: {},
  equipmentTagDropdown: {},
    equipmentReceivedDate: undefined,
  assignedPersons: [],
  officerInCharge: '',
  breakdown: false,
  // matType: 'P01',
    appointmentTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    malfunctionStartTime: undefined,
    malfunctionEndTime: undefined,
};
const initSelectedHaEquipment = {
  assetNumber: undefined,
  assetDescription: undefined,
  HACluster: undefined,
  regpc: undefined,
  ccsNumber: undefined,
  clientNumber: undefined,
  hospitalLocation: undefined,
  hospitalCode: undefined,
  functionalLocation: undefined,
};
const initAssignStaffFormParam = {
  teamMembers: [],
  isLoading: false,
  viewOnly: false,
  workCentre: '',
  handlingParty: null,
  showWorkCentreStaffs: false,
  selectedTeamMembers: [], // for cache
  workCentreStaffs: [], // for cache
};

@Component({
  selector: 'app-jobcard-create',
  templateUrl: './jobcard-create.component.html',
  styleUrls: ['./jobcard-create.component.scss']
})
export class JobcardCreateComponent implements OnInit {

  pageMode = JMENUM.JMPageMode.CREATE;
  pageTitle = JMLanguage.translate('pages.jobcard.create');

  snData = cloneDeep(initSnData);
  jobCardData:any = cloneDeep(initJobCardData);
  componentParameters = {
    userWorkCenters: [],
    onClickHaEquipmentNo: () => {},
    onClearHaEquipmentNumber: () => {}
  };
  selectedHaEquipment = cloneDeep(initSelectedHaEquipment);

  // action buttons
  sidebarActionButtons = [];

  // assign staff
  assignStaffFormParam = {
    ...cloneDeep(initAssignStaffFormParam),
    onSubmitClicked: (props) => {},
    onAddMemberClicked: (props) => {},
    onRemoveMemberClicked: (props) => {},
    onOfficerInChargeChanged: (props) => {}
  };
  isLoadingWorkCentreStaffs = false;
  isLoadingJobCardSubmit = false;
  isVVIP_SRAEnable: boolean = false;

  @ViewChild('sectionJobDetails', { static: false }) sectionJobDetails: JobcardJobDetailsComponent;
  @ViewChild('sectionClientInfo', { static: false }) sectionClientInfo: JobcardClientInformationComponent;
  @ViewChild('sectionHAInfo', { static: false }) sectionHAInfo: JobcardHaInformationComponent;
  @ViewChild('sectionTaskProgress', { static: false }) sectionTaskProgress: JobcardTaskProgressComponent;
  @ViewChild('haEquipmentListPanel', { static: false }) haEquipmentListPanel: CustomSliderPanelComponent;
  @ViewChild('haEquipmentListForm', { static: false }) haEquipmentListForm: HaEquipmentListPanelComponent;
  @ViewChild('assignStaffPanel', { static: true }) assignStaffPanel: CustomSliderPanelComponent;
  @ViewChild('assignStaffForm', { static: false }) assignStaffForm: JobCardAssignStaffFormComponent;

  constructor(private router: Router, private authorizationService: AuthorizationService) {
    this.componentParameters = {
      ...this.componentParameters,
      userWorkCenters: this.getUserWorkCenterList(),
      onClickHaEquipmentNo: this.onClickHaEquipmentNo,
      onClearHaEquipmentNumber: this.onClearHaEquipmentNumber,
    };

    this.sidebarActionButtons = [
      {
        buttonId: 'createJobCard',
        imageUrl: '/assets/svg/btn_action_job_create_follow_up.svg',
        actionDescription: 'pages.sn.slider-panel.create-job',
        isEnable: true,
        isShow: true,
        action: () => this.onCreateJobBtnClick(),
      }, {
        buttonId: 'closeJobCard',
        imageUrl: '/assets/svg/btn_action_reject.svg',
        actionDescription: 'action.button.close',
        isEnable: true,
        isShow: true,
        action: () => {
          AppDelegate.showPopUpAlert(JMLanguage.translate('action.button.popup.close'), null, [
            { name: JMLanguage.translate('global.yes'), handler: () => this.router.navigate(['/dashboard']) },
            { name: JMLanguage.translate('global.no')}
          ]);
        },
      }, {
        buttonId: 'clearJobCard',
        imageUrl: '/assets/svg/btn_action_clear.svg',
        actionDescription: 'action.button.clear',
        isEnable: true,
        isShow: true,
        action: () => {
          AppDelegate.showPopUpAlert(JMLanguage.translate('action.button.popup.clear'), null, [
            { name: JMLanguage.translate('global.yes'), handler: () => this.resetForm() },
            { name: JMLanguage.translate('global.no')}
          ]);
        },
      }
    ];
    const updateParamWithoutFormReinit = ({assignedPersons, officerInCharge}) => {
      // dont reassign the whole 'jobCardData' obj
      // just reassign the properties or there will be ngChange-->formReinit
      this.jobCardData.assignedPersons = assignedPersons;
      this.jobCardData.officerInCharge = officerInCharge;
    };
    this.assignStaffFormParam = {
      ...this.assignStaffFormParam,
      onAddMemberClicked: updateParamWithoutFormReinit,
      onRemoveMemberClicked: updateParamWithoutFormReinit,
      onOfficerInChargeChanged: updateParamWithoutFormReinit,
      onSubmitClicked: this.onSubmitClicked,
    };
    this.isVVIP_SRAEnable = isEnabledFeature(Session,JMCONSTANT.JMFeature.VVIP_SRA);
  }

  ngOnInit() {
    this.checkPermission();
  }

  onLanguageChanged() {
  }

  checkPermission = () => {
    // In phrase 1, only HSD users can access the “Create Job” page
    // (HSD users are those have “HA Cluster” attributes)
    const isWorkCentreAll = this.authorizationService.hasPermission(JMENUM.Permission.WORKCENTRE_ALL);
    const hasJobCardCreatePermission = this.authorizationService.hasPermission(JMENUM.Permission.JOBCARD_CREATE);
    const isHSDUser = Session.userInfo.authorizations.clusters && Session.userInfo.authorizations.clusters.length;
    if (!isWorkCentreAll && (!hasJobCardCreatePermission || !isHSDUser)) {
      this.router.navigate(['/dashboard']);
      AppDelegate.openSnackBar(JMLanguage.translate('pages.job-card.create.toast-msg.permission-denied'));
    }
  }

  resetForm = () => {
    this.snData = cloneDeep(initSnData);
    this.jobCardData = cloneDeep(initJobCardData);
    this.selectedHaEquipment = {...this.selectedHaEquipment, ...cloneDeep(initSelectedHaEquipment)};
    this.sectionJobDetails.errorFields = {};
    this.sectionClientInfo.errorFields = {};
    // Below components have their own local states. need to reset them as well
    this.sectionHAInfo.resetForm();
    this.sectionHAInfo.resetAllErrorFields();
    this.sectionTaskProgress.resetForm();
    this.sectionTaskProgress.resetAllErrorFields();
  }

  onClickHaEquipmentNo = () => {
    this.haEquipmentListPanel.toggle();
    this.haEquipmentListForm.refreshTable();
  }

  onHaEquipmentListPanelSubmit = () => {
    this.haEquipmentListPanel.close();
    if (this.snData.eamData.assetNumber !== this.selectedHaEquipment.assetNumber) {
      this.snData.eamData.assetNumber = this.selectedHaEquipment.assetNumber;
    }
    this.onHaEquipmentNumberUpdated();
  }

  onHaEquipmentNumberUpdated() {
    // set default value
    this.snData = {
      ...this.snData,
      client: null,
      location: null,
    };

    if (this.selectedHaEquipment) {
      if (this.selectedHaEquipment.ccsNumber) {
        this.autoFillByEquipment(this.selectedHaEquipment.ccsNumber);
      } else if (this.selectedHaEquipment.hospitalCode) {
        this.autoFillByHospitalCode(this.selectedHaEquipment.hospitalCode);
      } else if (this.selectedHaEquipment.hospitalLocation) {
        this.autoFillByHospitalCode(this.selectedHaEquipment.hospitalLocation);
      }
    }
  }
  onClearHaEquipmentNumber = () => {
    this.selectedHaEquipment = cloneDeep(initSelectedHaEquipment);
    this.snData.eamData.assetNumber = null;
  }

  /*
  auto-fill client, location and district
  */
  async autoFillByEquipment(equipmentNumber: string) {
    const request = new JM.JMRequestEquipmentsEquipmentSummary();
    request.equipmentNumber = [equipmentNumber];
    request.active          = JMENUM.RequestActive.BOTH;
    request.parameters      = ['equipmentNumber', 'location', 'clientShortName'];
    request.pageNumber      = 1;
    request.pageSize        = 1;

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (!response.payload.records || response.payload.records.length === 0) { return console.warn('No valid payload data...'); }

    const { clientShortName, location } = response.payload.records[0];
    if (clientShortName) {
      this.snData.client = clientShortName;
      const { name } = Session.clientDict[clientShortName];
      this.sectionClientInfo.clientOptions.reset().items = this.sectionClientInfo.getNewClientOptions([{
        clientShortName, name
      }]);
      //////////////////////////////////////////////////////////////////////////
      // Alternative way to achieve client info (name) by its id via API call
      //
      // this.sectionClientInfo.clientOptions.reset().filter = {clientShortName};
      // this.sectionClientInfo.requestClientsClientSummary(true);
      //
      //////////////////////////////////////////////////////////////////////////
    }
    if (location) {
      this.snData.location = location;
      this.sectionClientInfo.locationOptions.reset();
      this.sectionClientInfo.requestLocationsLocationSummary([location]);
    }
  }

  /*
  auto-fill client, location and district
  */
  async autoFillByHospitalCode(hospitalCode: string) {
    const request = new JM.JMRequestEquipmentsHospitalCodeLocationMapping();
    request.hospitalCode = hospitalCode;

    const response: JM.JMResponseEquipmentsHospitalCodeLocationMapping = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.openErrorBar(response);
      return;
    }
    if (response.payload.length === 0) { return console.warn('No valid payload data...'); }

    const { client, location } = response.payload[0];
    if (client) {
      this.snData.client = client;
      this.sectionClientInfo.clientOptions.reset();
      this.sectionClientInfo.requestClientsClientSummary([client]);
    }
    if (location) {
      this.snData.location = location;
      this.sectionClientInfo.locationOptions.reset();
      this.sectionClientInfo.requestLocationsLocationSummary([location]);
    }
  }

  getUserWorkCenterList = () => {
    const { clusters, workCenters } = Session.userInfo.authorizations;
    const workCentreDict = (Session && Session.workCentreDict) || {};
    const workCentersFromClusters = Object.values(workCentreDict)
      .filter(({HACluster}) => HACluster && clusters.indexOf(HACluster) !== -1)
      .map(({workCentreCode}) => workCentreCode);

    return workCenters.concat(workCentersFromClusters)
      .filter((value, index, self) => self.indexOf(value) === index); // make distinct values
  }

  onTeamChange = ({members, workCentre, handlingParty}) => {
    // clear data
    this.assignStaffFormParam = {
      ...this.assignStaffFormParam,
      ...cloneDeep(initAssignStaffFormParam),
      teamMembers: members,
      selectedTeamMembers: members,
      workCentre,
      handlingParty
    };
    this.jobCardData = {
      ...this.jobCardData,
      assignedPersons: [],
      officerInCharge: null,
      handlingParty: handlingParty
    };
  }

  onCloseTeamPanel = () => {
  }

  onCreateJobBtnClick = () => {
    if (this.validation()) {
      // if the handling team is non-p team, it is not needed to slide a panel for user to assign staff
      if (this.assignStaffFormParam.handlingParty !== JMENUM.HandlingParty.NON_PMSMC) {
        this.assignStaffPanel.toggle();
        this.assignStaffForm.renderTables();
        this.assignStaffForm.requestPostAssignments();
      } else {
        this.onSubmitClicked();
      }
    }
  }

  validation = () => {
    let result = [
      this.sectionJobDetails.validation(),
      this.sectionClientInfo.validation(),
      this.sectionHAInfo.validation(),
      this.sectionTaskProgress.validation(),
    ]
    return result.every(isValid => isValid);
  }

  onShowAllWorkCenterStaffChecked = (e) => {
    if (e.target.checked) {
      // if on, add workCenter staffs with team members tgt
      if (this.assignStaffFormParam.workCentreStaffs.length) {
        // if loaded workCenter staffs, use the cached loaded values to prevent multiple api calls
        this.assignStaffFormParam.teamMembers = this.assignStaffFormParam.selectedTeamMembers.concat(
          this.assignStaffFormParam.workCentreStaffs
          );
        } else {
          this.requestWorkCentrePostList();
        }
      } else {
        // if off, show team members only
        this.assignStaffFormParam.teamMembers = this.assignStaffFormParam.selectedTeamMembers;
      }
    this.assignStaffForm.renderTables();
    }

    private async requestWorkCentrePostList() {
      if (this.isLoadingWorkCentreStaffs) { return console.warn('Is loading workCentreStaffs...'); }
      this.isLoadingWorkCentreStaffs = true;
      const request = new JM.JMRequestPostsPostSummary();
      request.systemName = Constants.SYSTEM_NAME;
      request.authorizations = { workCenters: this.assignStaffFormParam.workCentre };
      request.active = JMENUM.RequestActive.ACTIVE;

      const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
      this.isLoadingWorkCentreStaffs = false;
      if (!response || response.error || response == null || response.code !== 200 || response.payload == null) {
        return AppDelegate.openErrorBar(response);
      }
      if (!response.payload.records || !response.payload.records.length) { return console.warn('No valid payload data...'); }

      const staffIdArr = response.payload.records.map(({name}) => name);
      this.assignStaffFormParam.workCentreStaffs = staffIdArr; // for cache
      this.assignStaffFormParam.teamMembers = this.assignStaffFormParam.selectedTeamMembers.concat(staffIdArr);
      this.assignStaffForm.requestPostAssignments();
      this.assignStaffForm.renderTables();
    }

    onSubmitClicked = () => {
      AppDelegate.showPopUpAlert(JMLanguage.translate('action.button.popup.create-job-card'), null, [
        { name: JMLanguage.translate('global.yes'), handler: () => this.onSubmitClickedCallBack() },
        { name: JMLanguage.translate('global.no')}
      ]);
    }

    onSubmitClickedCallBack = () => {
      if(this.jobCardData.jobNature === JMENUM.JobNature.STANDALONE){
        this.requestCreateStandaloneJob();
      }else {
        this.requestSnCreateByJobCard();
      }
    }

    requestCreateStandaloneJob = async () => {
      if (this.isLoadingJobCardSubmit) { return console.warn('Jobcard is submitting...'); }

      let request = new JM.JMRequestJobCardsCreateStandaloneJob;
      request.matType = this.jobCardData.matType;
      request.jobDescription = this.jobCardData.jobDescription;
      request.teamId = this.snData.teamId;
      request.assignedPersons = this.jobCardData.assignedPersons;
      request.officerInCharge = this.jobCardData.officerInCharge;
      request.client = this.snData.client;

      request.breakdown = this.jobCardData.breakdown;
      request.appointmentTime = this.jobCardData.appointmentTime;
      request.startTime = this.jobCardData.startTime;
      request.completionTime = this.jobCardData.completionTime;
      request.malfunctionStartTime = this.jobCardData.malfunctionStartTime;
      request.malfunctionEndTime = this.jobCardData.malfunctionEndTime;
      request.responseToClientTime = this.jobCardData.responseToClientTime;
      request.receivedTime= this.jobCardData.receivedTime;
      request.plannedCompletionTime= this.jobCardData.plannedCompletionTime;

      let response: JM.JMResponseJobCardsCreateStandaloneJob = await AppDelegate.sendJMRequest(request);
      if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.openErrorBar(response);
        return;
      };

      this.router.navigate(['/job-card/view/' + response.payload.jobCardNumber]);
    }

    requestSnCreateByJobCard = async () => {
      if (this.isLoadingJobCardSubmit) { return console.warn('Jobcard is submitting...'); }
      this.isLoadingJobCardSubmit = true;

      let request = new JM.JMRequestSnCreateByJobCard();
      request = { ...request, ...this.snData, ...this.jobCardData };
      request.contactNumber = request.contactNumber.filter(item => !!item); // Remove empty string item that causes request error
      request.email = request.email && request.email.trim();
      request.jobDescription = request.jobDescription.trim();
      request.locationDetail = request.locationDetail.trim();
      request.contactPerson = request.contactPerson.trim();

      delete request['eamData'];
      if (this.jobCardData.jobNature === JMENUM.JobNature.CM) {
        request.replyToHA = undefined;
      } else if (this.jobCardData.jobNature === JMENUM.JobNature.PM) {
        request.replyToHA = this.snData.eamData.replyToHA;
      }
      request.assetNumber = this.snData.eamData.assetNumber ? this.snData.eamData.assetNumber.trim() : null;

      const response = await AppDelegate.sendJMRequest(request);
      this.isLoadingJobCardSubmit = false;
      if (!response || response.error || response == null || response.code !== 200 || response.payload == null) {
        return AppDelegate.openErrorBar(response);
      }
      if (!response.payload) { return console.warn('No valid payload data...'); }

      this.router.navigate(['/sn/view/' + response.payload.snNumber]);
    }

    updateSnData = ( data : { [key: string] : any } ) => {
      this.snData = {
        ...this.snData,
        ...data
      }
    }
    updateJobCardData = ( data : { [key: string] : any } ) => {
      this.jobCardData = {
        ...this.jobCardData,
        ...data
      };
    }
  
  }
