export class InlineStyleHelper {

    static createinlineStyle(className: string, css: string): HTMLStyleElement {
        let result = '.' + className + css;
        let style = document.createElement('style');
        style.type = 'text/css';
        style.setAttribute("id", className);
        style.appendChild(document.createTextNode(result));

        return style;

    }

    static addStyle(inlineStyle: HTMLStyleElement) {
        let head = document.head || document.getElementsByTagName('head')[0];
        let existedCSS = document.getElementById(inlineStyle.id);
        if (!existedCSS) head.appendChild(inlineStyle);
    }

    static removeStyle(styleId: string) {
        let head = document.head || document.getElementsByTagName('head')[0];
        let inlineStyle = document.getElementById(styleId);
        if(inlineStyle){
            head.removeChild(inlineStyle);
        }
    }

}